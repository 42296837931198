import React from 'react'
import { TenantsProvider } from '../../context/tenants'
import TenantsMain from './tenantsMain'

const Tenants = (props) => {
  return (
    <TenantsProvider>
      <TenantsMain />
    </TenantsProvider>
  )
}

export default Tenants
