// source: src/services/reporting/reporting.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_reporting_pipeline_report_pb = require('../../../src/common/reporting/pipeline_report_pb.js');
goog.object.extend(proto, src_common_reporting_pipeline_report_pb);
var src_common_reporting_futureforecasting_future_forecast_pb = require('../../../src/common/reporting/futureforecasting/future_forecast_pb.js');
goog.object.extend(proto, src_common_reporting_futureforecasting_future_forecast_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_opportunity_opportunity_list_pb = require('../../../src/common/opportunity/opportunity_list_pb.js');
goog.object.extend(proto, src_common_opportunity_opportunity_list_pb);
goog.exportSymbol('proto.loupe.services.reporting.GetForecastSimulationLevelOption', null, global);
goog.exportSymbol('proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse', null, global);
goog.exportSymbol('proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage', null, global);
goog.exportSymbol('proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest', null, global);
goog.exportSymbol('proto.loupe.services.reporting.GetForecastSimulationsResponse', null, global);
goog.exportSymbol('proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest', null, global);
goog.exportSymbol('proto.loupe.services.reporting.GetSavedForecastSimulationsRequest', null, global);
goog.exportSymbol('proto.loupe.services.reporting.IdentificationParams', null, global);
goog.exportSymbol('proto.loupe.services.reporting.RunForecastSimulationMessage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.displayName = 'proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.displayName = 'proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.reporting.RunForecastSimulationMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.reporting.RunForecastSimulationMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.reporting.RunForecastSimulationMessage.displayName = 'proto.loupe.services.reporting.RunForecastSimulationMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.reporting.GetForecastSimulationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.reporting.GetForecastSimulationsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.reporting.GetForecastSimulationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.reporting.GetForecastSimulationsResponse.displayName = 'proto.loupe.services.reporting.GetForecastSimulationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.displayName = 'proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.reporting.IdentificationParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.reporting.IdentificationParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.reporting.IdentificationParams.displayName = 'proto.loupe.services.reporting.IdentificationParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.reporting.GetSavedForecastSimulationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.displayName = 'proto.loupe.services.reporting.GetSavedForecastSimulationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.displayName = 'proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.reporting.GetForecastSimulationLevelOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.reporting.GetForecastSimulationLevelOption.displayName = 'proto.loupe.services.reporting.GetForecastSimulationLevelOption';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    idsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    snapshotDate: (f = msg.getSnapshotDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pageToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 6, 0),
    snapshotStart: (f = msg.getSnapshotStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    snapshotEnd: (f = msg.getSnapshotEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    inputParams: (f = msg.getInputParams()) && src_common_reporting_pipeline_report_pb.TimeWindowedReportInput.toObject(includeInstance, f),
    selectedStartBucket: jspb.Message.getFieldWithDefault(msg, 10, ""),
    selectedEndBucket: jspb.Message.getFieldWithDefault(msg, 11, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest;
  return proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSnapshotDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSnapshotStart(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSnapshotEnd(value);
      break;
    case 9:
      var value = new src_common_reporting_pipeline_report_pb.TimeWindowedReportInput;
      reader.readMessage(value,src_common_reporting_pipeline_report_pb.TimeWindowedReportInput.deserializeBinaryFromReader);
      msg.setInputParams(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectedStartBucket(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectedEndBucket(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSnapshotDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSnapshotStart();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSnapshotEnd();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInputParams();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      src_common_reporting_pipeline_report_pb.TimeWindowedReportInput.serializeBinaryToWriter
    );
  }
  f = message.getSelectedStartBucket();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSelectedEndBucket();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tx_id = 2;
 * @return {string}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string ids = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional google.protobuf.Timestamp snapshot_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.getSnapshotDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
*/
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.setSnapshotDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.clearSnapshotDate = function() {
  return this.setSnapshotDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.hasSnapshotDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string page_token = 5;
 * @return {string}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 limit = 6;
 * @return {number}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp snapshot_start = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.getSnapshotStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
*/
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.setSnapshotStart = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.clearSnapshotStart = function() {
  return this.setSnapshotStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.hasSnapshotStart = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp snapshot_end = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.getSnapshotEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
*/
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.setSnapshotEnd = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.clearSnapshotEnd = function() {
  return this.setSnapshotEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.hasSnapshotEnd = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional loupe.common.reporting.TimeWindowedReportInput input_params = 9;
 * @return {?proto.loupe.common.reporting.TimeWindowedReportInput}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.getInputParams = function() {
  return /** @type{?proto.loupe.common.reporting.TimeWindowedReportInput} */ (
    jspb.Message.getWrapperField(this, src_common_reporting_pipeline_report_pb.TimeWindowedReportInput, 9));
};


/**
 * @param {?proto.loupe.common.reporting.TimeWindowedReportInput|undefined} value
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
*/
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.setInputParams = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.clearInputParams = function() {
  return this.setInputParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.hasInputParams = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string selected_start_bucket = 10;
 * @return {string}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.getSelectedStartBucket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.setSelectedStartBucket = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string selected_end_bucket = 11;
 * @return {string}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.getSelectedEndBucket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.setSelectedEndBucket = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 offset = 12;
 * @return {number}
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} returns this
 */
proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.reporting.IdentificationParams.toObject(includeInstance, f),
    item: (f = msg.getItem()) && src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastRunContainer.toObject(includeInstance, f),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage}
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage;
  return proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage}
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.reporting.IdentificationParams;
      reader.readMessage(value,proto.loupe.services.reporting.IdentificationParams.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = new src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastRunContainer;
      reader.readMessage(value,src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastRunContainer.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.reporting.IdentificationParams.serializeBinaryToWriter
    );
  }
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastRunContainer.serializeBinaryToWriter
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional IdentificationParams identity = 1;
 * @return {?proto.loupe.services.reporting.IdentificationParams}
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.reporting.IdentificationParams} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.reporting.IdentificationParams, 1));
};


/**
 * @param {?proto.loupe.services.reporting.IdentificationParams|undefined} value
 * @return {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage} returns this
*/
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage} returns this
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.reporting.futureforecasting.FutureForecastRunContainer item = 2;
 * @return {?proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer}
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.prototype.getItem = function() {
  return /** @type{?proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} */ (
    jspb.Message.getWrapperField(this, src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastRunContainer, 2));
};


/**
 * @param {?proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer|undefined} value
 * @return {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage} returns this
*/
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage} returns this
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.prototype.hasItem = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string currency_code = 3;
 * @return {string}
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage} returns this
 */
proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.reporting.RunForecastSimulationMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.reporting.RunForecastSimulationMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.reporting.IdentificationParams.toObject(includeInstance, f),
    item: (f = msg.getItem()) && src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastRunContainer.toObject(includeInstance, f),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.reporting.RunForecastSimulationMessage}
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.reporting.RunForecastSimulationMessage;
  return proto.loupe.services.reporting.RunForecastSimulationMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.reporting.RunForecastSimulationMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.reporting.RunForecastSimulationMessage}
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.reporting.IdentificationParams;
      reader.readMessage(value,proto.loupe.services.reporting.IdentificationParams.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = new src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastRunContainer;
      reader.readMessage(value,src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastRunContainer.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.reporting.RunForecastSimulationMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.reporting.RunForecastSimulationMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.reporting.IdentificationParams.serializeBinaryToWriter
    );
  }
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastRunContainer.serializeBinaryToWriter
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional IdentificationParams identity = 1;
 * @return {?proto.loupe.services.reporting.IdentificationParams}
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.reporting.IdentificationParams} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.reporting.IdentificationParams, 1));
};


/**
 * @param {?proto.loupe.services.reporting.IdentificationParams|undefined} value
 * @return {!proto.loupe.services.reporting.RunForecastSimulationMessage} returns this
*/
proto.loupe.services.reporting.RunForecastSimulationMessage.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.reporting.RunForecastSimulationMessage} returns this
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.reporting.futureforecasting.FutureForecastRunContainer item = 2;
 * @return {?proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer}
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.prototype.getItem = function() {
  return /** @type{?proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} */ (
    jspb.Message.getWrapperField(this, src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastRunContainer, 2));
};


/**
 * @param {?proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer|undefined} value
 * @return {!proto.loupe.services.reporting.RunForecastSimulationMessage} returns this
*/
proto.loupe.services.reporting.RunForecastSimulationMessage.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.reporting.RunForecastSimulationMessage} returns this
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.prototype.hasItem = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string currency_code = 3;
 * @return {string}
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.RunForecastSimulationMessage} returns this
 */
proto.loupe.services.reporting.RunForecastSimulationMessage.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.reporting.GetForecastSimulationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.reporting.GetForecastSimulationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.reporting.GetForecastSimulationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.reporting.GetForecastSimulationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetForecastSimulationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastContainer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationsResponse}
 */
proto.loupe.services.reporting.GetForecastSimulationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.reporting.GetForecastSimulationsResponse;
  return proto.loupe.services.reporting.GetForecastSimulationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.reporting.GetForecastSimulationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationsResponse}
 */
proto.loupe.services.reporting.GetForecastSimulationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastContainer;
      reader.readMessage(value,src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastContainer.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.reporting.GetForecastSimulationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.reporting.GetForecastSimulationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.reporting.GetForecastSimulationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetForecastSimulationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastContainer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.reporting.futureforecasting.FutureForecastContainer items = 1;
 * @return {!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer>}
 */
proto.loupe.services.reporting.GetForecastSimulationsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_reporting_futureforecasting_future_forecast_pb.FutureForecastContainer, 1));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer>} value
 * @return {!proto.loupe.services.reporting.GetForecastSimulationsResponse} returns this
*/
proto.loupe.services.reporting.GetForecastSimulationsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer}
 */
proto.loupe.services.reporting.GetForecastSimulationsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.reporting.futureforecasting.FutureForecastContainer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationsResponse} returns this
 */
proto.loupe.services.reporting.GetForecastSimulationsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.reporting.IdentificationParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest}
 */
proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest;
  return proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest}
 */
proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.reporting.IdentificationParams;
      reader.readMessage(value,proto.loupe.services.reporting.IdentificationParams.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.reporting.IdentificationParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional IdentificationParams identity = 1;
 * @return {?proto.loupe.services.reporting.IdentificationParams}
 */
proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.reporting.IdentificationParams} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.reporting.IdentificationParams, 1));
};


/**
 * @param {?proto.loupe.services.reporting.IdentificationParams|undefined} value
 * @return {!proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest} returns this
*/
proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest} returns this
 */
proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.reporting.IdentificationParams.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.reporting.IdentificationParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.reporting.IdentificationParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.IdentificationParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.reporting.IdentificationParams}
 */
proto.loupe.services.reporting.IdentificationParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.reporting.IdentificationParams;
  return proto.loupe.services.reporting.IdentificationParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.reporting.IdentificationParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.reporting.IdentificationParams}
 */
proto.loupe.services.reporting.IdentificationParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.reporting.IdentificationParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.reporting.IdentificationParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.reporting.IdentificationParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.IdentificationParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.reporting.IdentificationParams.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.IdentificationParams} returns this
 */
proto.loupe.services.reporting.IdentificationParams.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.reporting.IdentificationParams.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.IdentificationParams} returns this
 */
proto.loupe.services.reporting.IdentificationParams.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.loupe.services.reporting.IdentificationParams.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.IdentificationParams} returns this
 */
proto.loupe.services.reporting.IdentificationParams.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tx_id = 4;
 * @return {string}
 */
proto.loupe.services.reporting.IdentificationParams.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.IdentificationParams} returns this
 */
proto.loupe.services.reporting.IdentificationParams.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.reporting.GetSavedForecastSimulationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.reporting.IdentificationParams.toObject(includeInstance, f),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    searchText: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.reporting.GetSavedForecastSimulationsRequest}
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.reporting.GetSavedForecastSimulationsRequest;
  return proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.reporting.GetSavedForecastSimulationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.reporting.GetSavedForecastSimulationsRequest}
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.reporting.IdentificationParams;
      reader.readMessage(value,proto.loupe.services.reporting.IdentificationParams.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.reporting.GetSavedForecastSimulationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.reporting.IdentificationParams.serializeBinaryToWriter
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSearchText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional IdentificationParams identity = 1;
 * @return {?proto.loupe.services.reporting.IdentificationParams}
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.reporting.IdentificationParams} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.reporting.IdentificationParams, 1));
};


/**
 * @param {?proto.loupe.services.reporting.IdentificationParams|undefined} value
 * @return {!proto.loupe.services.reporting.GetSavedForecastSimulationsRequest} returns this
*/
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.reporting.GetSavedForecastSimulationsRequest} returns this
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.GetSavedForecastSimulationsRequest} returns this
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string search_text = 3;
 * @return {string}
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.prototype.getSearchText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.GetSavedForecastSimulationsRequest} returns this
 */
proto.loupe.services.reporting.GetSavedForecastSimulationsRequest.prototype.setSearchText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupLevelOptionsList: jspb.Message.toObjectList(msg.getGroupLevelOptionsList(),
    proto.loupe.services.reporting.GetForecastSimulationLevelOption.toObject, includeInstance),
    personLevelOptionsList: jspb.Message.toObjectList(msg.getPersonLevelOptionsList(),
    proto.loupe.services.reporting.GetForecastSimulationLevelOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse;
  return proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.reporting.GetForecastSimulationLevelOption;
      reader.readMessage(value,proto.loupe.services.reporting.GetForecastSimulationLevelOption.deserializeBinaryFromReader);
      msg.addGroupLevelOptions(value);
      break;
    case 2:
      var value = new proto.loupe.services.reporting.GetForecastSimulationLevelOption;
      reader.readMessage(value,proto.loupe.services.reporting.GetForecastSimulationLevelOption.deserializeBinaryFromReader);
      msg.addPersonLevelOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupLevelOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.reporting.GetForecastSimulationLevelOption.serializeBinaryToWriter
    );
  }
  f = message.getPersonLevelOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.services.reporting.GetForecastSimulationLevelOption.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetForecastSimulationLevelOption group_level_options = 1;
 * @return {!Array<!proto.loupe.services.reporting.GetForecastSimulationLevelOption>}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.prototype.getGroupLevelOptionsList = function() {
  return /** @type{!Array<!proto.loupe.services.reporting.GetForecastSimulationLevelOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.reporting.GetForecastSimulationLevelOption, 1));
};


/**
 * @param {!Array<!proto.loupe.services.reporting.GetForecastSimulationLevelOption>} value
 * @return {!proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse} returns this
*/
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.prototype.setGroupLevelOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.reporting.GetForecastSimulationLevelOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.reporting.GetForecastSimulationLevelOption}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.prototype.addGroupLevelOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.reporting.GetForecastSimulationLevelOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse} returns this
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.prototype.clearGroupLevelOptionsList = function() {
  return this.setGroupLevelOptionsList([]);
};


/**
 * repeated GetForecastSimulationLevelOption person_level_options = 2;
 * @return {!Array<!proto.loupe.services.reporting.GetForecastSimulationLevelOption>}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.prototype.getPersonLevelOptionsList = function() {
  return /** @type{!Array<!proto.loupe.services.reporting.GetForecastSimulationLevelOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.reporting.GetForecastSimulationLevelOption, 2));
};


/**
 * @param {!Array<!proto.loupe.services.reporting.GetForecastSimulationLevelOption>} value
 * @return {!proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse} returns this
*/
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.prototype.setPersonLevelOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.services.reporting.GetForecastSimulationLevelOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.reporting.GetForecastSimulationLevelOption}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.prototype.addPersonLevelOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.services.reporting.GetForecastSimulationLevelOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse} returns this
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse.prototype.clearPersonLevelOptionsList = function() {
  return this.setPersonLevelOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOption.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.reporting.GetForecastSimulationLevelOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.reporting.GetForecastSimulationLevelOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationLevelOption}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.reporting.GetForecastSimulationLevelOption;
  return proto.loupe.services.reporting.GetForecastSimulationLevelOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.reporting.GetForecastSimulationLevelOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.reporting.GetForecastSimulationLevelOption}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.reporting.GetForecastSimulationLevelOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.reporting.GetForecastSimulationLevelOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.GetForecastSimulationLevelOption} returns this
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.reporting.GetForecastSimulationLevelOption} returns this
 */
proto.loupe.services.reporting.GetForecastSimulationLevelOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.loupe.services.reporting);
