import React, { useCallback } from 'react'
import Modal from './modal'
import Close from '../icons/close'
import Button from './button'

const ActionModal = (props) => {
  const {
    actionText = 'Action',
    cancelText = 'Cancel',
    modal,
    onAction,
    onCancel,
    slim = false,
    subText,
    text,
    title,
  } = props

  const { open, handleClose } = modal

  const onCancelInternal = useCallback(() => {
    onCancel && onCancel()
    handleClose()
  }, [handleClose, onCancel])

  const onActionInternal = useCallback(() => {
    onAction && onAction()
    handleClose()
  }, [handleClose, onAction])

  if (slim) {
    return (
      <Modal
        maxWidth="xs"
        open={open}
        PaperProps={{
          style: {
            maxWidth: 280
          }
        }}
      >
        <div className="w-full flex flex-col font-normal p-5">
          <div className="text-size-16px font-weight-700">{title}</div>
          <div className="text-size-14px text-color-818e93 font-weight-600 leading-tight mt-6 mb-8">{text}</div>
          <div className="flex items-center justify-center">
            <Button
              backgroundColor="#a6b2cf"
              className="mr-4"
              onClick={onCancelInternal}
              size="xs"
              text={cancelText}
            />
            <Button
              onClick={onActionInternal}
              size="xs"
              text={actionText}
            />
          </div>

        </div>
      </Modal>
    )
  }

  return (
    <Modal
      maxWidth="sm"
      open={open}>
      <div className="w-full flex flex-col font-normal">
        <div className="flex items-center justify-between bg-gradient-dark-gray px-6 py-3">
          <div className="text-size-22px text-color-ffffff font-bold">{title}</div>
          <button onClick={onCancelInternal} className="focus:outline-none"><Close className="flex-shrink-0" /></button>
        </div>
        <div className="overflow-auto" style={{ maxHeight: 'calc(100vh - 207px)' }}>
          <div className="w-full h-full px-10 pt-10">
            <div className="text-size-18px text-color-09242f font-weight-500 leading-tight mb-4">{text}</div>
            <div className="text-size-16px text-color-818e93 font-weight-400 leading-tight mb-4">{subText}</div>
          </div>
        </div>
        <div className="flex items-center justify-center py-6">
          <Button
            text={cancelText}
            onClick={onCancelInternal}
            backgroundColor="#a6b2cf"
            className="mx-4" />
          <Button
            text={actionText}
            onClick={onActionInternal}
            className="mx-4" />
        </div>
      </div>
    </Modal>
  )
}

export default ActionModal
