// source: src/common/realtime/query.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_mapping_mapping_pb = require('../../../src/common/mapping/mapping_pb.js');
goog.object.extend(proto, src_common_mapping_mapping_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.loupe.common.realtime.ComparisonOp', null, global);
goog.exportSymbol('proto.loupe.common.realtime.FieldDefinition', null, global);
goog.exportSymbol('proto.loupe.common.realtime.FieldOptions', null, global);
goog.exportSymbol('proto.loupe.common.realtime.FieldType', null, global);
goog.exportSymbol('proto.loupe.common.realtime.Filter', null, global);
goog.exportSymbol('proto.loupe.common.realtime.FilterCombiner', null, global);
goog.exportSymbol('proto.loupe.common.realtime.FilterParameter', null, global);
goog.exportSymbol('proto.loupe.common.realtime.FilterWrapper', null, global);
goog.exportSymbol('proto.loupe.common.realtime.Group', null, global);
goog.exportSymbol('proto.loupe.common.realtime.GroupParameter', null, global);
goog.exportSymbol('proto.loupe.common.realtime.IntervalType', null, global);
goog.exportSymbol('proto.loupe.common.realtime.LabelValue', null, global);
goog.exportSymbol('proto.loupe.common.realtime.ObjectSource', null, global);
goog.exportSymbol('proto.loupe.common.realtime.ObjectType', null, global);
goog.exportSymbol('proto.loupe.common.realtime.Order', null, global);
goog.exportSymbol('proto.loupe.common.realtime.OrderDirection', null, global);
goog.exportSymbol('proto.loupe.common.realtime.OrderParameter', null, global);
goog.exportSymbol('proto.loupe.common.realtime.ParameterOptions', null, global);
goog.exportSymbol('proto.loupe.common.realtime.ParameterType', null, global);
goog.exportSymbol('proto.loupe.common.realtime.Query', null, global);
goog.exportSymbol('proto.loupe.common.realtime.QueryContract', null, global);
goog.exportSymbol('proto.loupe.common.realtime.QueryDateRangeType', null, global);
goog.exportSymbol('proto.loupe.common.realtime.QueryDependencies', null, global);
goog.exportSymbol('proto.loupe.common.realtime.QueryField', null, global);
goog.exportSymbol('proto.loupe.common.realtime.QueryParameter', null, global);
goog.exportSymbol('proto.loupe.common.realtime.QueryParameters', null, global);
goog.exportSymbol('proto.loupe.common.realtime.QueryStatus', null, global);
goog.exportSymbol('proto.loupe.common.realtime.ResolvedQueryParameter', null, global);
goog.exportSymbol('proto.loupe.common.realtime.SourceOptions', null, global);
goog.exportSymbol('proto.loupe.common.realtime.TimeWindowInterval', null, global);
goog.exportSymbol('proto.loupe.common.realtime.TimeWindowParameter', null, global);
goog.exportSymbol('proto.loupe.common.realtime.TimeWindowRange', null, global);
goog.exportSymbol('proto.loupe.common.realtime.TimeWindowType', null, global);
goog.exportSymbol('proto.loupe.common.realtime.UserQueryParameters', null, global);
goog.exportSymbol('proto.loupe.common.realtime.UserQueryParametersField', null, global);
goog.exportSymbol('proto.loupe.common.realtime.ValueParameter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.Query = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.Query.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.Query, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.Query.displayName = 'proto.loupe.common.realtime.Query';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.QueryContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.QueryContract.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.QueryContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.QueryContract.displayName = 'proto.loupe.common.realtime.QueryContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.FieldDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.FieldDefinition.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.FieldDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.FieldDefinition.displayName = 'proto.loupe.common.realtime.FieldDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.QueryDependencies = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.realtime.QueryDependencies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.QueryDependencies.displayName = 'proto.loupe.common.realtime.QueryDependencies';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.UserQueryParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.realtime.UserQueryParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.UserQueryParameters.displayName = 'proto.loupe.common.realtime.UserQueryParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.QueryParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.QueryParameters.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.QueryParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.QueryParameters.displayName = 'proto.loupe.common.realtime.QueryParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.QueryParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.realtime.QueryParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.QueryParameter.displayName = 'proto.loupe.common.realtime.QueryParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.ParameterOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.ParameterOptions.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.ParameterOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.ParameterOptions.displayName = 'proto.loupe.common.realtime.ParameterOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.SourceOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.SourceOptions.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.SourceOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.SourceOptions.displayName = 'proto.loupe.common.realtime.SourceOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.FieldOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.FieldOptions.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.FieldOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.FieldOptions.displayName = 'proto.loupe.common.realtime.FieldOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.LabelValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.realtime.LabelValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.LabelValue.displayName = 'proto.loupe.common.realtime.LabelValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.FilterParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.realtime.FilterParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.FilterParameter.displayName = 'proto.loupe.common.realtime.FilterParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.FilterWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.FilterWrapper.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.FilterWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.FilterWrapper.displayName = 'proto.loupe.common.realtime.FilterWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.Filter.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.Filter.displayName = 'proto.loupe.common.realtime.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.TimeWindowParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.realtime.TimeWindowParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.TimeWindowParameter.displayName = 'proto.loupe.common.realtime.TimeWindowParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.TimeWindowRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.realtime.TimeWindowRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.TimeWindowRange.displayName = 'proto.loupe.common.realtime.TimeWindowRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.TimeWindowInterval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.realtime.TimeWindowInterval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.TimeWindowInterval.displayName = 'proto.loupe.common.realtime.TimeWindowInterval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.OrderParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.OrderParameter.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.OrderParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.OrderParameter.displayName = 'proto.loupe.common.realtime.OrderParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.realtime.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.Order.displayName = 'proto.loupe.common.realtime.Order';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.GroupParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.GroupParameter.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.GroupParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.GroupParameter.displayName = 'proto.loupe.common.realtime.GroupParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.Group = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.realtime.Group, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.Group.displayName = 'proto.loupe.common.realtime.Group';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.ValueParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.realtime.ValueParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.ValueParameter.displayName = 'proto.loupe.common.realtime.ValueParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.ResolvedQueryParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.ResolvedQueryParameter.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.ResolvedQueryParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.ResolvedQueryParameter.displayName = 'proto.loupe.common.realtime.ResolvedQueryParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.ObjectSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.ObjectSource.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.ObjectSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.ObjectSource.displayName = 'proto.loupe.common.realtime.ObjectSource';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.Query.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.Query.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.Query.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.Query} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.Query.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    namespace: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rawQuery: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    contract: (f = msg.getContract()) && proto.loupe.common.realtime.QueryContract.toObject(includeInstance, f),
    availableParameters: (f = msg.getAvailableParameters()) && proto.loupe.common.realtime.QueryParameters.toObject(includeInstance, f),
    dependencies: (f = msg.getDependencies()) && proto.loupe.common.realtime.QueryDependencies.toObject(includeInstance, f),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
    status: jspb.Message.getFieldWithDefault(msg, 11, 0),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 12, ""),
    version: jspb.Message.getFieldWithDefault(msg, 13, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    objectSource: (f = msg.getObjectSource()) && proto.loupe.common.realtime.ObjectSource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.Query}
 */
proto.loupe.common.realtime.Query.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.Query;
  return proto.loupe.common.realtime.Query.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.Query} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.Query}
 */
proto.loupe.common.realtime.Query.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawQuery(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 7:
      var value = new proto.loupe.common.realtime.QueryContract;
      reader.readMessage(value,proto.loupe.common.realtime.QueryContract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    case 8:
      var value = new proto.loupe.common.realtime.QueryParameters;
      reader.readMessage(value,proto.loupe.common.realtime.QueryParameters.deserializeBinaryFromReader);
      msg.setAvailableParameters(value);
      break;
    case 9:
      var value = new proto.loupe.common.realtime.QueryDependencies;
      reader.readMessage(value,proto.loupe.common.realtime.QueryDependencies.deserializeBinaryFromReader);
      msg.setDependencies(value);
      break;
    case 10:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 11:
      var value = /** @type {!proto.loupe.common.realtime.QueryStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 16:
      var value = new proto.loupe.common.realtime.ObjectSource;
      reader.readMessage(value,proto.loupe.common.realtime.ObjectSource.deserializeBinaryFromReader);
      msg.setObjectSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.Query.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.Query.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.Query} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.Query.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRawQuery();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.common.realtime.QueryContract.serializeBinaryToWriter
    );
  }
  f = message.getAvailableParameters();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.loupe.common.realtime.QueryParameters.serializeBinaryToWriter
    );
  }
  f = message.getDependencies();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.loupe.common.realtime.QueryDependencies.serializeBinaryToWriter
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getObjectSource();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.loupe.common.realtime.ObjectSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.realtime.Query.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.loupe.common.realtime.Query.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.loupe.common.realtime.Query.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string namespace = 4;
 * @return {string}
 */
proto.loupe.common.realtime.Query.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string raw_query = 5;
 * @return {string}
 */
proto.loupe.common.realtime.Query.prototype.getRawQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.setRawQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string tags = 6;
 * @return {!Array<string>}
 */
proto.loupe.common.realtime.Query.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional QueryContract contract = 7;
 * @return {?proto.loupe.common.realtime.QueryContract}
 */
proto.loupe.common.realtime.Query.prototype.getContract = function() {
  return /** @type{?proto.loupe.common.realtime.QueryContract} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.QueryContract, 7));
};


/**
 * @param {?proto.loupe.common.realtime.QueryContract|undefined} value
 * @return {!proto.loupe.common.realtime.Query} returns this
*/
proto.loupe.common.realtime.Query.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.Query.prototype.hasContract = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional QueryParameters available_parameters = 8;
 * @return {?proto.loupe.common.realtime.QueryParameters}
 */
proto.loupe.common.realtime.Query.prototype.getAvailableParameters = function() {
  return /** @type{?proto.loupe.common.realtime.QueryParameters} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.QueryParameters, 8));
};


/**
 * @param {?proto.loupe.common.realtime.QueryParameters|undefined} value
 * @return {!proto.loupe.common.realtime.Query} returns this
*/
proto.loupe.common.realtime.Query.prototype.setAvailableParameters = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.clearAvailableParameters = function() {
  return this.setAvailableParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.Query.prototype.hasAvailableParameters = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional QueryDependencies dependencies = 9;
 * @return {?proto.loupe.common.realtime.QueryDependencies}
 */
proto.loupe.common.realtime.Query.prototype.getDependencies = function() {
  return /** @type{?proto.loupe.common.realtime.QueryDependencies} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.QueryDependencies, 9));
};


/**
 * @param {?proto.loupe.common.realtime.QueryDependencies|undefined} value
 * @return {!proto.loupe.common.realtime.Query} returns this
*/
proto.loupe.common.realtime.Query.prototype.setDependencies = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.clearDependencies = function() {
  return this.setDependencies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.Query.prototype.hasDependencies = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * map<string, string> metadata = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.common.realtime.Query.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};


/**
 * optional QueryStatus status = 11;
 * @return {!proto.loupe.common.realtime.QueryStatus}
 */
proto.loupe.common.realtime.Query.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.realtime.QueryStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.loupe.common.realtime.QueryStatus} value
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string status_message = 12;
 * @return {string}
 */
proto.loupe.common.realtime.Query.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string version = 13;
 * @return {string}
 */
proto.loupe.common.realtime.Query.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.realtime.Query.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.realtime.Query} returns this
*/
proto.loupe.common.realtime.Query.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.Query.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.realtime.Query.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.realtime.Query} returns this
*/
proto.loupe.common.realtime.Query.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.Query.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional ObjectSource object_source = 16;
 * @return {?proto.loupe.common.realtime.ObjectSource}
 */
proto.loupe.common.realtime.Query.prototype.getObjectSource = function() {
  return /** @type{?proto.loupe.common.realtime.ObjectSource} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.ObjectSource, 16));
};


/**
 * @param {?proto.loupe.common.realtime.ObjectSource|undefined} value
 * @return {!proto.loupe.common.realtime.Query} returns this
*/
proto.loupe.common.realtime.Query.prototype.setObjectSource = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.Query} returns this
 */
proto.loupe.common.realtime.Query.prototype.clearObjectSource = function() {
  return this.setObjectSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.Query.prototype.hasObjectSource = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.QueryContract.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.QueryContract.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.QueryContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.QueryContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.QueryContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.loupe.common.realtime.FieldDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.QueryContract}
 */
proto.loupe.common.realtime.QueryContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.QueryContract;
  return proto.loupe.common.realtime.QueryContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.QueryContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.QueryContract}
 */
proto.loupe.common.realtime.QueryContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.realtime.FieldDefinition;
      reader.readMessage(value,proto.loupe.common.realtime.FieldDefinition.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.QueryContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.QueryContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.QueryContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.QueryContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.realtime.FieldDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FieldDefinition fields = 1;
 * @return {!Array<!proto.loupe.common.realtime.FieldDefinition>}
 */
proto.loupe.common.realtime.QueryContract.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.FieldDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.realtime.FieldDefinition, 1));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.FieldDefinition>} value
 * @return {!proto.loupe.common.realtime.QueryContract} returns this
*/
proto.loupe.common.realtime.QueryContract.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.realtime.FieldDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.FieldDefinition}
 */
proto.loupe.common.realtime.QueryContract.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.realtime.FieldDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.QueryContract} returns this
 */
proto.loupe.common.realtime.QueryContract.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.FieldDefinition.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.FieldDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.FieldDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.FieldDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.FieldDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nestedList: jspb.Message.toObjectList(msg.getNestedList(),
    proto.loupe.common.realtime.FieldDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.FieldDefinition}
 */
proto.loupe.common.realtime.FieldDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.FieldDefinition;
  return proto.loupe.common.realtime.FieldDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.FieldDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.FieldDefinition}
 */
proto.loupe.common.realtime.FieldDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.realtime.FieldType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.loupe.common.realtime.FieldDefinition;
      reader.readMessage(value,proto.loupe.common.realtime.FieldDefinition.deserializeBinaryFromReader);
      msg.addNested(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.FieldDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.FieldDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.FieldDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.FieldDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNestedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.common.realtime.FieldDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.realtime.FieldDefinition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.FieldDefinition} returns this
 */
proto.loupe.common.realtime.FieldDefinition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FieldType type = 2;
 * @return {!proto.loupe.common.realtime.FieldType}
 */
proto.loupe.common.realtime.FieldDefinition.prototype.getType = function() {
  return /** @type {!proto.loupe.common.realtime.FieldType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.realtime.FieldType} value
 * @return {!proto.loupe.common.realtime.FieldDefinition} returns this
 */
proto.loupe.common.realtime.FieldDefinition.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated FieldDefinition nested = 3;
 * @return {!Array<!proto.loupe.common.realtime.FieldDefinition>}
 */
proto.loupe.common.realtime.FieldDefinition.prototype.getNestedList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.FieldDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.realtime.FieldDefinition, 3));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.FieldDefinition>} value
 * @return {!proto.loupe.common.realtime.FieldDefinition} returns this
*/
proto.loupe.common.realtime.FieldDefinition.prototype.setNestedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.realtime.FieldDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.FieldDefinition}
 */
proto.loupe.common.realtime.FieldDefinition.prototype.addNested = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.realtime.FieldDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.FieldDefinition} returns this
 */
proto.loupe.common.realtime.FieldDefinition.prototype.clearNestedList = function() {
  return this.setNestedList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.QueryDependencies.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.QueryDependencies.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.QueryDependencies} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.QueryDependencies.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryVersionsMap: (f = msg.getQueryVersionsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.QueryDependencies}
 */
proto.loupe.common.realtime.QueryDependencies.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.QueryDependencies;
  return proto.loupe.common.realtime.QueryDependencies.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.QueryDependencies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.QueryDependencies}
 */
proto.loupe.common.realtime.QueryDependencies.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getQueryVersionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.QueryDependencies.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.QueryDependencies.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.QueryDependencies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.QueryDependencies.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryVersionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> query_versions = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.common.realtime.QueryDependencies.prototype.getQueryVersionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.realtime.QueryDependencies} returns this
 */
proto.loupe.common.realtime.QueryDependencies.prototype.clearQueryVersionsMap = function() {
  this.getQueryVersionsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.UserQueryParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.UserQueryParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.UserQueryParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    queryKey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    parameters: (f = msg.getParameters()) && proto.loupe.common.realtime.QueryParameters.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 10, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 11, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.UserQueryParameters}
 */
proto.loupe.common.realtime.UserQueryParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.UserQueryParameters;
  return proto.loupe.common.realtime.UserQueryParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.UserQueryParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.UserQueryParameters}
 */
proto.loupe.common.realtime.UserQueryParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryKey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = new proto.loupe.common.realtime.QueryParameters;
      reader.readMessage(value,proto.loupe.common.realtime.QueryParameters.deserializeBinaryFromReader);
      msg.setParameters(value);
      break;
    case 9:
      var value = /** @type {!proto.loupe.common.realtime.QueryStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.UserQueryParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.UserQueryParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.UserQueryParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQueryKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getParameters();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.loupe.common.realtime.QueryParameters.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string query_key = 5;
 * @return {string}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getQueryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.setQueryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional QueryParameters parameters = 8;
 * @return {?proto.loupe.common.realtime.QueryParameters}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getParameters = function() {
  return /** @type{?proto.loupe.common.realtime.QueryParameters} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.QueryParameters, 8));
};


/**
 * @param {?proto.loupe.common.realtime.QueryParameters|undefined} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
*/
proto.loupe.common.realtime.UserQueryParameters.prototype.setParameters = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.clearParameters = function() {
  return this.setParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.hasParameters = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional QueryStatus status = 9;
 * @return {!proto.loupe.common.realtime.QueryStatus}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.realtime.QueryStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.loupe.common.realtime.QueryStatus} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string status_message = 10;
 * @return {string}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 sort = 11;
 * @return {number}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
*/
proto.loupe.common.realtime.UserQueryParameters.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
*/
proto.loupe.common.realtime.UserQueryParameters.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.UserQueryParameters} returns this
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.UserQueryParameters.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.QueryParameters.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.QueryParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.QueryParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.QueryParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.QueryParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    parametersList: jspb.Message.toObjectList(msg.getParametersList(),
    proto.loupe.common.realtime.QueryParameter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.QueryParameters}
 */
proto.loupe.common.realtime.QueryParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.QueryParameters;
  return proto.loupe.common.realtime.QueryParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.QueryParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.QueryParameters}
 */
proto.loupe.common.realtime.QueryParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.realtime.QueryParameter;
      reader.readMessage(value,proto.loupe.common.realtime.QueryParameter.deserializeBinaryFromReader);
      msg.addParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.QueryParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.QueryParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.QueryParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.QueryParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.realtime.QueryParameter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QueryParameter parameters = 1;
 * @return {!Array<!proto.loupe.common.realtime.QueryParameter>}
 */
proto.loupe.common.realtime.QueryParameters.prototype.getParametersList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.QueryParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.realtime.QueryParameter, 1));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.QueryParameter>} value
 * @return {!proto.loupe.common.realtime.QueryParameters} returns this
*/
proto.loupe.common.realtime.QueryParameters.prototype.setParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.realtime.QueryParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.QueryParameter}
 */
proto.loupe.common.realtime.QueryParameters.prototype.addParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.realtime.QueryParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.QueryParameters} returns this
 */
proto.loupe.common.realtime.QueryParameters.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.QueryParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.QueryParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.QueryParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.QueryParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    options: (f = msg.getOptions()) && proto.loupe.common.realtime.ParameterOptions.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.loupe.common.realtime.FilterParameter.toObject(includeInstance, f),
    timeWindow: (f = msg.getTimeWindow()) && proto.loupe.common.realtime.TimeWindowParameter.toObject(includeInstance, f),
    order: (f = msg.getOrder()) && proto.loupe.common.realtime.OrderParameter.toObject(includeInstance, f),
    group: (f = msg.getGroup()) && proto.loupe.common.realtime.GroupParameter.toObject(includeInstance, f),
    value: (f = msg.getValue()) && proto.loupe.common.realtime.ValueParameter.toObject(includeInstance, f),
    resolvedQuery: (f = msg.getResolvedQuery()) && proto.loupe.common.realtime.ResolvedQueryParameter.toObject(includeInstance, f),
    raw: jspb.Message.getFieldWithDefault(msg, 12, ""),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.QueryParameter}
 */
proto.loupe.common.realtime.QueryParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.QueryParameter;
  return proto.loupe.common.realtime.QueryParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.QueryParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.QueryParameter}
 */
proto.loupe.common.realtime.QueryParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.common.realtime.ParameterType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = new proto.loupe.common.realtime.ParameterOptions;
      reader.readMessage(value,proto.loupe.common.realtime.ParameterOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 6:
      var value = new proto.loupe.common.realtime.FilterParameter;
      reader.readMessage(value,proto.loupe.common.realtime.FilterParameter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 7:
      var value = new proto.loupe.common.realtime.TimeWindowParameter;
      reader.readMessage(value,proto.loupe.common.realtime.TimeWindowParameter.deserializeBinaryFromReader);
      msg.setTimeWindow(value);
      break;
    case 8:
      var value = new proto.loupe.common.realtime.OrderParameter;
      reader.readMessage(value,proto.loupe.common.realtime.OrderParameter.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 9:
      var value = new proto.loupe.common.realtime.GroupParameter;
      reader.readMessage(value,proto.loupe.common.realtime.GroupParameter.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 10:
      var value = new proto.loupe.common.realtime.ValueParameter;
      reader.readMessage(value,proto.loupe.common.realtime.ValueParameter.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 11:
      var value = new proto.loupe.common.realtime.ResolvedQueryParameter;
      reader.readMessage(value,proto.loupe.common.realtime.ResolvedQueryParameter.deserializeBinaryFromReader);
      msg.setResolvedQuery(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRaw(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.QueryParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.QueryParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.QueryParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.QueryParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.loupe.common.realtime.ParameterOptions.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loupe.common.realtime.FilterParameter.serializeBinaryToWriter
    );
  }
  f = message.getTimeWindow();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.common.realtime.TimeWindowParameter.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.loupe.common.realtime.OrderParameter.serializeBinaryToWriter
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.loupe.common.realtime.GroupParameter.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.loupe.common.realtime.ValueParameter.serializeBinaryToWriter
    );
  }
  f = message.getResolvedQuery();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.loupe.common.realtime.ResolvedQueryParameter.serializeBinaryToWriter
    );
  }
  f = message.getRaw();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ParameterType type = 4;
 * @return {!proto.loupe.common.realtime.ParameterType}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getType = function() {
  return /** @type {!proto.loupe.common.realtime.ParameterType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.common.realtime.ParameterType} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ParameterOptions options = 5;
 * @return {?proto.loupe.common.realtime.ParameterOptions}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getOptions = function() {
  return /** @type{?proto.loupe.common.realtime.ParameterOptions} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.ParameterOptions, 5));
};


/**
 * @param {?proto.loupe.common.realtime.ParameterOptions|undefined} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
*/
proto.loupe.common.realtime.QueryParameter.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.QueryParameter.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FilterParameter filter = 6;
 * @return {?proto.loupe.common.realtime.FilterParameter}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getFilter = function() {
  return /** @type{?proto.loupe.common.realtime.FilterParameter} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.FilterParameter, 6));
};


/**
 * @param {?proto.loupe.common.realtime.FilterParameter|undefined} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
*/
proto.loupe.common.realtime.QueryParameter.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.QueryParameter.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TimeWindowParameter time_window = 7;
 * @return {?proto.loupe.common.realtime.TimeWindowParameter}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getTimeWindow = function() {
  return /** @type{?proto.loupe.common.realtime.TimeWindowParameter} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.TimeWindowParameter, 7));
};


/**
 * @param {?proto.loupe.common.realtime.TimeWindowParameter|undefined} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
*/
proto.loupe.common.realtime.QueryParameter.prototype.setTimeWindow = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.clearTimeWindow = function() {
  return this.setTimeWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.QueryParameter.prototype.hasTimeWindow = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional OrderParameter order = 8;
 * @return {?proto.loupe.common.realtime.OrderParameter}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getOrder = function() {
  return /** @type{?proto.loupe.common.realtime.OrderParameter} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.OrderParameter, 8));
};


/**
 * @param {?proto.loupe.common.realtime.OrderParameter|undefined} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
*/
proto.loupe.common.realtime.QueryParameter.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.QueryParameter.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional GroupParameter group = 9;
 * @return {?proto.loupe.common.realtime.GroupParameter}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getGroup = function() {
  return /** @type{?proto.loupe.common.realtime.GroupParameter} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.GroupParameter, 9));
};


/**
 * @param {?proto.loupe.common.realtime.GroupParameter|undefined} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
*/
proto.loupe.common.realtime.QueryParameter.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.QueryParameter.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ValueParameter value = 10;
 * @return {?proto.loupe.common.realtime.ValueParameter}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getValue = function() {
  return /** @type{?proto.loupe.common.realtime.ValueParameter} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.ValueParameter, 10));
};


/**
 * @param {?proto.loupe.common.realtime.ValueParameter|undefined} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
*/
proto.loupe.common.realtime.QueryParameter.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.QueryParameter.prototype.hasValue = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ResolvedQueryParameter resolved_query = 11;
 * @return {?proto.loupe.common.realtime.ResolvedQueryParameter}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getResolvedQuery = function() {
  return /** @type{?proto.loupe.common.realtime.ResolvedQueryParameter} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.ResolvedQueryParameter, 11));
};


/**
 * @param {?proto.loupe.common.realtime.ResolvedQueryParameter|undefined} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
*/
proto.loupe.common.realtime.QueryParameter.prototype.setResolvedQuery = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.clearResolvedQuery = function() {
  return this.setResolvedQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.QueryParameter.prototype.hasResolvedQuery = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string raw = 12;
 * @return {string}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getRaw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.setRaw = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool visible = 13;
 * @return {boolean}
 */
proto.loupe.common.realtime.QueryParameter.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.realtime.QueryParameter} returns this
 */
proto.loupe.common.realtime.QueryParameter.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.ParameterOptions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.ParameterOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.ParameterOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.ParameterOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.ParameterOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourcesList: jspb.Message.toObjectList(msg.getSourcesList(),
    proto.loupe.common.realtime.SourceOptions.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.ParameterOptions}
 */
proto.loupe.common.realtime.ParameterOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.ParameterOptions;
  return proto.loupe.common.realtime.ParameterOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.ParameterOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.ParameterOptions}
 */
proto.loupe.common.realtime.ParameterOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.realtime.SourceOptions;
      reader.readMessage(value,proto.loupe.common.realtime.SourceOptions.deserializeBinaryFromReader);
      msg.addSources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.ParameterOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.ParameterOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.ParameterOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.ParameterOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.realtime.SourceOptions.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SourceOptions sources = 1;
 * @return {!Array<!proto.loupe.common.realtime.SourceOptions>}
 */
proto.loupe.common.realtime.ParameterOptions.prototype.getSourcesList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.SourceOptions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.realtime.SourceOptions, 1));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.SourceOptions>} value
 * @return {!proto.loupe.common.realtime.ParameterOptions} returns this
*/
proto.loupe.common.realtime.ParameterOptions.prototype.setSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.realtime.SourceOptions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.SourceOptions}
 */
proto.loupe.common.realtime.ParameterOptions.prototype.addSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.realtime.SourceOptions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.ParameterOptions} returns this
 */
proto.loupe.common.realtime.ParameterOptions.prototype.clearSourcesList = function() {
  return this.setSourcesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.SourceOptions.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.SourceOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.SourceOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.SourceOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.SourceOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.loupe.common.realtime.FieldOptions.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.SourceOptions}
 */
proto.loupe.common.realtime.SourceOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.SourceOptions;
  return proto.loupe.common.realtime.SourceOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.SourceOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.SourceOptions}
 */
proto.loupe.common.realtime.SourceOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 2:
      var value = new proto.loupe.common.realtime.FieldOptions;
      reader.readMessage(value,proto.loupe.common.realtime.FieldOptions.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.SourceOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.SourceOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.SourceOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.SourceOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.common.realtime.FieldOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string source = 1;
 * @return {string}
 */
proto.loupe.common.realtime.SourceOptions.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.SourceOptions} returns this
 */
proto.loupe.common.realtime.SourceOptions.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FieldOptions fields = 2;
 * @return {!Array<!proto.loupe.common.realtime.FieldOptions>}
 */
proto.loupe.common.realtime.SourceOptions.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.FieldOptions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.realtime.FieldOptions, 2));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.FieldOptions>} value
 * @return {!proto.loupe.common.realtime.SourceOptions} returns this
*/
proto.loupe.common.realtime.SourceOptions.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.realtime.FieldOptions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.FieldOptions}
 */
proto.loupe.common.realtime.SourceOptions.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.realtime.FieldOptions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.SourceOptions} returns this
 */
proto.loupe.common.realtime.SourceOptions.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.FieldOptions.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.FieldOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.FieldOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.FieldOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.FieldOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    format: jspb.Message.getFieldWithDefault(msg, 4, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.loupe.common.realtime.LabelValue.toObject, includeInstance),
    fieldReference: (f = msg.getFieldReference()) && src_common_mapping_mapping_pb.FieldReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.FieldOptions}
 */
proto.loupe.common.realtime.FieldOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.FieldOptions;
  return proto.loupe.common.realtime.FieldOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.FieldOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.FieldOptions}
 */
proto.loupe.common.realtime.FieldOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.realtime.FieldType} */ (reader.readEnum());
      msg.setFieldType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 5:
      var value = new proto.loupe.common.realtime.LabelValue;
      reader.readMessage(value,proto.loupe.common.realtime.LabelValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 6:
      var value = new src_common_mapping_mapping_pb.FieldReference;
      reader.readMessage(value,src_common_mapping_mapping_pb.FieldReference.deserializeBinaryFromReader);
      msg.setFieldReference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.FieldOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.FieldOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.FieldOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.FieldOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.loupe.common.realtime.LabelValue.serializeBinaryToWriter
    );
  }
  f = message.getFieldReference();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_mapping_mapping_pb.FieldReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.loupe.common.realtime.FieldOptions.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.FieldOptions} returns this
 */
proto.loupe.common.realtime.FieldOptions.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.common.realtime.FieldOptions.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.FieldOptions} returns this
 */
proto.loupe.common.realtime.FieldOptions.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FieldType field_type = 3;
 * @return {!proto.loupe.common.realtime.FieldType}
 */
proto.loupe.common.realtime.FieldOptions.prototype.getFieldType = function() {
  return /** @type {!proto.loupe.common.realtime.FieldType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.realtime.FieldType} value
 * @return {!proto.loupe.common.realtime.FieldOptions} returns this
 */
proto.loupe.common.realtime.FieldOptions.prototype.setFieldType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string format = 4;
 * @return {string}
 */
proto.loupe.common.realtime.FieldOptions.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.FieldOptions} returns this
 */
proto.loupe.common.realtime.FieldOptions.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated LabelValue values = 5;
 * @return {!Array<!proto.loupe.common.realtime.LabelValue>}
 */
proto.loupe.common.realtime.FieldOptions.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.LabelValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.realtime.LabelValue, 5));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.LabelValue>} value
 * @return {!proto.loupe.common.realtime.FieldOptions} returns this
*/
proto.loupe.common.realtime.FieldOptions.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.realtime.LabelValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.LabelValue}
 */
proto.loupe.common.realtime.FieldOptions.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.realtime.LabelValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.FieldOptions} returns this
 */
proto.loupe.common.realtime.FieldOptions.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional loupe.common.mapping.FieldReference field_reference = 6;
 * @return {?proto.loupe.common.mapping.FieldReference}
 */
proto.loupe.common.realtime.FieldOptions.prototype.getFieldReference = function() {
  return /** @type{?proto.loupe.common.mapping.FieldReference} */ (
    jspb.Message.getWrapperField(this, src_common_mapping_mapping_pb.FieldReference, 6));
};


/**
 * @param {?proto.loupe.common.mapping.FieldReference|undefined} value
 * @return {!proto.loupe.common.realtime.FieldOptions} returns this
*/
proto.loupe.common.realtime.FieldOptions.prototype.setFieldReference = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.FieldOptions} returns this
 */
proto.loupe.common.realtime.FieldOptions.prototype.clearFieldReference = function() {
  return this.setFieldReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.FieldOptions.prototype.hasFieldReference = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.LabelValue.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.LabelValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.LabelValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.LabelValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.LabelValue}
 */
proto.loupe.common.realtime.LabelValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.LabelValue;
  return proto.loupe.common.realtime.LabelValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.LabelValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.LabelValue}
 */
proto.loupe.common.realtime.LabelValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.LabelValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.LabelValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.LabelValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.LabelValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.loupe.common.realtime.LabelValue.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.LabelValue} returns this
 */
proto.loupe.common.realtime.LabelValue.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.loupe.common.realtime.LabelValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.LabelValue} returns this
 */
proto.loupe.common.realtime.LabelValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.FilterParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.FilterParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.FilterParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.FilterParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    filters: (f = msg.getFilters()) && proto.loupe.common.realtime.FilterWrapper.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.FilterParameter}
 */
proto.loupe.common.realtime.FilterParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.FilterParameter;
  return proto.loupe.common.realtime.FilterParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.FilterParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.FilterParameter}
 */
proto.loupe.common.realtime.FilterParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.realtime.FilterWrapper;
      reader.readMessage(value,proto.loupe.common.realtime.FilterWrapper.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.FilterParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.FilterParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.FilterParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.FilterParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.common.realtime.FilterWrapper.serializeBinaryToWriter
    );
  }
};


/**
 * optional FilterWrapper filters = 1;
 * @return {?proto.loupe.common.realtime.FilterWrapper}
 */
proto.loupe.common.realtime.FilterParameter.prototype.getFilters = function() {
  return /** @type{?proto.loupe.common.realtime.FilterWrapper} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.FilterWrapper, 1));
};


/**
 * @param {?proto.loupe.common.realtime.FilterWrapper|undefined} value
 * @return {!proto.loupe.common.realtime.FilterParameter} returns this
*/
proto.loupe.common.realtime.FilterParameter.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.FilterParameter} returns this
 */
proto.loupe.common.realtime.FilterParameter.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.FilterParameter.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.FilterWrapper.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.FilterWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.FilterWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.FilterWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.FilterWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.loupe.common.realtime.Filter.toObject(includeInstance, f),
    nestedList: jspb.Message.toObjectList(msg.getNestedList(),
    proto.loupe.common.realtime.FilterWrapper.toObject, includeInstance),
    combiner: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.FilterWrapper}
 */
proto.loupe.common.realtime.FilterWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.FilterWrapper;
  return proto.loupe.common.realtime.FilterWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.FilterWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.FilterWrapper}
 */
proto.loupe.common.realtime.FilterWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.realtime.Filter;
      reader.readMessage(value,proto.loupe.common.realtime.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.loupe.common.realtime.FilterWrapper;
      reader.readMessage(value,proto.loupe.common.realtime.FilterWrapper.deserializeBinaryFromReader);
      msg.addNested(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.realtime.FilterCombiner} */ (reader.readEnum());
      msg.setCombiner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.FilterWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.FilterWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.FilterWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.FilterWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.common.realtime.Filter.serializeBinaryToWriter
    );
  }
  f = message.getNestedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.common.realtime.FilterWrapper.serializeBinaryToWriter
    );
  }
  f = message.getCombiner();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional Filter filter = 1;
 * @return {?proto.loupe.common.realtime.Filter}
 */
proto.loupe.common.realtime.FilterWrapper.prototype.getFilter = function() {
  return /** @type{?proto.loupe.common.realtime.Filter} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.Filter, 1));
};


/**
 * @param {?proto.loupe.common.realtime.Filter|undefined} value
 * @return {!proto.loupe.common.realtime.FilterWrapper} returns this
*/
proto.loupe.common.realtime.FilterWrapper.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.FilterWrapper} returns this
 */
proto.loupe.common.realtime.FilterWrapper.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.FilterWrapper.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FilterWrapper nested = 2;
 * @return {!Array<!proto.loupe.common.realtime.FilterWrapper>}
 */
proto.loupe.common.realtime.FilterWrapper.prototype.getNestedList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.FilterWrapper>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.realtime.FilterWrapper, 2));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.FilterWrapper>} value
 * @return {!proto.loupe.common.realtime.FilterWrapper} returns this
*/
proto.loupe.common.realtime.FilterWrapper.prototype.setNestedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.realtime.FilterWrapper=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.FilterWrapper}
 */
proto.loupe.common.realtime.FilterWrapper.prototype.addNested = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.realtime.FilterWrapper, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.FilterWrapper} returns this
 */
proto.loupe.common.realtime.FilterWrapper.prototype.clearNestedList = function() {
  return this.setNestedList([]);
};


/**
 * optional FilterCombiner combiner = 3;
 * @return {!proto.loupe.common.realtime.FilterCombiner}
 */
proto.loupe.common.realtime.FilterWrapper.prototype.getCombiner = function() {
  return /** @type {!proto.loupe.common.realtime.FilterCombiner} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.realtime.FilterCombiner} value
 * @return {!proto.loupe.common.realtime.FilterWrapper} returns this
 */
proto.loupe.common.realtime.FilterWrapper.prototype.setCombiner = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.Filter.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    comparisonOp: jspb.Message.getFieldWithDefault(msg, 4, 0),
    valuesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    queryDateRangeType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.Filter}
 */
proto.loupe.common.realtime.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.Filter;
  return proto.loupe.common.realtime.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.Filter}
 */
proto.loupe.common.realtime.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.realtime.FieldType} */ (reader.readEnum());
      msg.setFieldType(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.common.realtime.ComparisonOp} */ (reader.readEnum());
      msg.setComparisonOp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    case 6:
      var value = /** @type {!proto.loupe.common.realtime.QueryDateRangeType} */ (reader.readEnum());
      msg.setQueryDateRangeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getComparisonOp();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getQueryDateRangeType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string source = 1;
 * @return {string}
 */
proto.loupe.common.realtime.Filter.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Filter} returns this
 */
proto.loupe.common.realtime.Filter.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field = 2;
 * @return {string}
 */
proto.loupe.common.realtime.Filter.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Filter} returns this
 */
proto.loupe.common.realtime.Filter.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FieldType field_type = 3;
 * @return {!proto.loupe.common.realtime.FieldType}
 */
proto.loupe.common.realtime.Filter.prototype.getFieldType = function() {
  return /** @type {!proto.loupe.common.realtime.FieldType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.realtime.FieldType} value
 * @return {!proto.loupe.common.realtime.Filter} returns this
 */
proto.loupe.common.realtime.Filter.prototype.setFieldType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ComparisonOp comparison_op = 4;
 * @return {!proto.loupe.common.realtime.ComparisonOp}
 */
proto.loupe.common.realtime.Filter.prototype.getComparisonOp = function() {
  return /** @type {!proto.loupe.common.realtime.ComparisonOp} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.common.realtime.ComparisonOp} value
 * @return {!proto.loupe.common.realtime.Filter} returns this
 */
proto.loupe.common.realtime.Filter.prototype.setComparisonOp = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated string values = 5;
 * @return {!Array<string>}
 */
proto.loupe.common.realtime.Filter.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.realtime.Filter} returns this
 */
proto.loupe.common.realtime.Filter.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.Filter} returns this
 */
proto.loupe.common.realtime.Filter.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.Filter} returns this
 */
proto.loupe.common.realtime.Filter.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional QueryDateRangeType query_date_range_type = 6;
 * @return {!proto.loupe.common.realtime.QueryDateRangeType}
 */
proto.loupe.common.realtime.Filter.prototype.getQueryDateRangeType = function() {
  return /** @type {!proto.loupe.common.realtime.QueryDateRangeType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.loupe.common.realtime.QueryDateRangeType} value
 * @return {!proto.loupe.common.realtime.Filter} returns this
 */
proto.loupe.common.realtime.Filter.prototype.setQueryDateRangeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.TimeWindowParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.TimeWindowParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.TimeWindowParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    source: jspb.Message.getFieldWithDefault(msg, 2, ""),
    field: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fieldType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    range: (f = msg.getRange()) && proto.loupe.common.realtime.TimeWindowRange.toObject(includeInstance, f),
    interval: (f = msg.getInterval()) && proto.loupe.common.realtime.TimeWindowInterval.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.TimeWindowParameter}
 */
proto.loupe.common.realtime.TimeWindowParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.TimeWindowParameter;
  return proto.loupe.common.realtime.TimeWindowParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.TimeWindowParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.TimeWindowParameter}
 */
proto.loupe.common.realtime.TimeWindowParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.realtime.TimeWindowType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.common.realtime.FieldType} */ (reader.readEnum());
      msg.setFieldType(value);
      break;
    case 5:
      var value = new proto.loupe.common.realtime.TimeWindowRange;
      reader.readMessage(value,proto.loupe.common.realtime.TimeWindowRange.deserializeBinaryFromReader);
      msg.setRange(value);
      break;
    case 6:
      var value = new proto.loupe.common.realtime.TimeWindowInterval;
      reader.readMessage(value,proto.loupe.common.realtime.TimeWindowInterval.deserializeBinaryFromReader);
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.TimeWindowParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.TimeWindowParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.TimeWindowParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFieldType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getRange();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.loupe.common.realtime.TimeWindowRange.serializeBinaryToWriter
    );
  }
  f = message.getInterval();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loupe.common.realtime.TimeWindowInterval.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeWindowType type = 1;
 * @return {!proto.loupe.common.realtime.TimeWindowType}
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.getType = function() {
  return /** @type {!proto.loupe.common.realtime.TimeWindowType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.realtime.TimeWindowType} value
 * @return {!proto.loupe.common.realtime.TimeWindowParameter} returns this
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string source = 2;
 * @return {string}
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.TimeWindowParameter} returns this
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string field = 3;
 * @return {string}
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.TimeWindowParameter} returns this
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FieldType field_type = 4;
 * @return {!proto.loupe.common.realtime.FieldType}
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.getFieldType = function() {
  return /** @type {!proto.loupe.common.realtime.FieldType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.common.realtime.FieldType} value
 * @return {!proto.loupe.common.realtime.TimeWindowParameter} returns this
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.setFieldType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional TimeWindowRange range = 5;
 * @return {?proto.loupe.common.realtime.TimeWindowRange}
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.getRange = function() {
  return /** @type{?proto.loupe.common.realtime.TimeWindowRange} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.TimeWindowRange, 5));
};


/**
 * @param {?proto.loupe.common.realtime.TimeWindowRange|undefined} value
 * @return {!proto.loupe.common.realtime.TimeWindowParameter} returns this
*/
proto.loupe.common.realtime.TimeWindowParameter.prototype.setRange = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.TimeWindowParameter} returns this
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.clearRange = function() {
  return this.setRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.hasRange = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional TimeWindowInterval interval = 6;
 * @return {?proto.loupe.common.realtime.TimeWindowInterval}
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.getInterval = function() {
  return /** @type{?proto.loupe.common.realtime.TimeWindowInterval} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.TimeWindowInterval, 6));
};


/**
 * @param {?proto.loupe.common.realtime.TimeWindowInterval|undefined} value
 * @return {!proto.loupe.common.realtime.TimeWindowParameter} returns this
*/
proto.loupe.common.realtime.TimeWindowParameter.prototype.setInterval = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.TimeWindowParameter} returns this
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.clearInterval = function() {
  return this.setInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.TimeWindowParameter.prototype.hasInterval = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.TimeWindowRange.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.TimeWindowRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.TimeWindowRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.TimeWindowRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, ""),
    end: jspb.Message.getFieldWithDefault(msg, 2, ""),
    queryDateRangeType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.TimeWindowRange}
 */
proto.loupe.common.realtime.TimeWindowRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.TimeWindowRange;
  return proto.loupe.common.realtime.TimeWindowRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.TimeWindowRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.TimeWindowRange}
 */
proto.loupe.common.realtime.TimeWindowRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnd(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.realtime.QueryDateRangeType} */ (reader.readEnum());
      msg.setQueryDateRangeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.TimeWindowRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.TimeWindowRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.TimeWindowRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.TimeWindowRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQueryDateRangeType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string start = 1;
 * @return {string}
 */
proto.loupe.common.realtime.TimeWindowRange.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.TimeWindowRange} returns this
 */
proto.loupe.common.realtime.TimeWindowRange.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string end = 2;
 * @return {string}
 */
proto.loupe.common.realtime.TimeWindowRange.prototype.getEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.TimeWindowRange} returns this
 */
proto.loupe.common.realtime.TimeWindowRange.prototype.setEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional QueryDateRangeType query_date_range_type = 3;
 * @return {!proto.loupe.common.realtime.QueryDateRangeType}
 */
proto.loupe.common.realtime.TimeWindowRange.prototype.getQueryDateRangeType = function() {
  return /** @type {!proto.loupe.common.realtime.QueryDateRangeType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.realtime.QueryDateRangeType} value
 * @return {!proto.loupe.common.realtime.TimeWindowRange} returns this
 */
proto.loupe.common.realtime.TimeWindowRange.prototype.setQueryDateRangeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.TimeWindowInterval.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.TimeWindowInterval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.TimeWindowInterval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.TimeWindowInterval.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.TimeWindowInterval}
 */
proto.loupe.common.realtime.TimeWindowInterval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.TimeWindowInterval;
  return proto.loupe.common.realtime.TimeWindowInterval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.TimeWindowInterval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.TimeWindowInterval}
 */
proto.loupe.common.realtime.TimeWindowInterval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.realtime.IntervalType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.TimeWindowInterval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.TimeWindowInterval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.TimeWindowInterval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.TimeWindowInterval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional IntervalType type = 1;
 * @return {!proto.loupe.common.realtime.IntervalType}
 */
proto.loupe.common.realtime.TimeWindowInterval.prototype.getType = function() {
  return /** @type {!proto.loupe.common.realtime.IntervalType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.realtime.IntervalType} value
 * @return {!proto.loupe.common.realtime.TimeWindowInterval} returns this
 */
proto.loupe.common.realtime.TimeWindowInterval.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.loupe.common.realtime.TimeWindowInterval.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.realtime.TimeWindowInterval} returns this
 */
proto.loupe.common.realtime.TimeWindowInterval.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.OrderParameter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.OrderParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.OrderParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.OrderParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.OrderParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.loupe.common.realtime.Order.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.OrderParameter}
 */
proto.loupe.common.realtime.OrderParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.OrderParameter;
  return proto.loupe.common.realtime.OrderParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.OrderParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.OrderParameter}
 */
proto.loupe.common.realtime.OrderParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.realtime.Order;
      reader.readMessage(value,proto.loupe.common.realtime.Order.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.OrderParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.OrderParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.OrderParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.OrderParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.realtime.Order.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Order orders = 1;
 * @return {!Array<!proto.loupe.common.realtime.Order>}
 */
proto.loupe.common.realtime.OrderParameter.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.Order>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.realtime.Order, 1));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.Order>} value
 * @return {!proto.loupe.common.realtime.OrderParameter} returns this
*/
proto.loupe.common.realtime.OrderParameter.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.realtime.Order=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.Order}
 */
proto.loupe.common.realtime.OrderParameter.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.realtime.Order, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.OrderParameter} returns this
 */
proto.loupe.common.realtime.OrderParameter.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.Order.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field: jspb.Message.getFieldWithDefault(msg, 2, ""),
    direction: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.Order}
 */
proto.loupe.common.realtime.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.Order;
  return proto.loupe.common.realtime.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.Order}
 */
proto.loupe.common.realtime.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.realtime.OrderDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string source = 1;
 * @return {string}
 */
proto.loupe.common.realtime.Order.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Order} returns this
 */
proto.loupe.common.realtime.Order.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field = 2;
 * @return {string}
 */
proto.loupe.common.realtime.Order.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Order} returns this
 */
proto.loupe.common.realtime.Order.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OrderDirection direction = 3;
 * @return {!proto.loupe.common.realtime.OrderDirection}
 */
proto.loupe.common.realtime.Order.prototype.getDirection = function() {
  return /** @type {!proto.loupe.common.realtime.OrderDirection} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.realtime.OrderDirection} value
 * @return {!proto.loupe.common.realtime.Order} returns this
 */
proto.loupe.common.realtime.Order.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.GroupParameter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.GroupParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.GroupParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.GroupParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.GroupParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.loupe.common.realtime.Group.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.GroupParameter}
 */
proto.loupe.common.realtime.GroupParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.GroupParameter;
  return proto.loupe.common.realtime.GroupParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.GroupParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.GroupParameter}
 */
proto.loupe.common.realtime.GroupParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.realtime.Group;
      reader.readMessage(value,proto.loupe.common.realtime.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.GroupParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.GroupParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.GroupParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.GroupParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.realtime.Group.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Group groups = 1;
 * @return {!Array<!proto.loupe.common.realtime.Group>}
 */
proto.loupe.common.realtime.GroupParameter.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.realtime.Group, 1));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.Group>} value
 * @return {!proto.loupe.common.realtime.GroupParameter} returns this
*/
proto.loupe.common.realtime.GroupParameter.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.realtime.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.Group}
 */
proto.loupe.common.realtime.GroupParameter.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.realtime.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.GroupParameter} returns this
 */
proto.loupe.common.realtime.GroupParameter.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.Group.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.Group.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.Group} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.Group.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field: jspb.Message.getFieldWithDefault(msg, 2, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.Group}
 */
proto.loupe.common.realtime.Group.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.Group;
  return proto.loupe.common.realtime.Group.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.Group} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.Group}
 */
proto.loupe.common.realtime.Group.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.Group.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.Group.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.Group} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.Group.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string source = 1;
 * @return {string}
 */
proto.loupe.common.realtime.Group.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Group} returns this
 */
proto.loupe.common.realtime.Group.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field = 2;
 * @return {string}
 */
proto.loupe.common.realtime.Group.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Group} returns this
 */
proto.loupe.common.realtime.Group.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string alias = 3;
 * @return {string}
 */
proto.loupe.common.realtime.Group.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.Group} returns this
 */
proto.loupe.common.realtime.Group.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.ValueParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.ValueParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.ValueParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.ValueParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.ValueParameter}
 */
proto.loupe.common.realtime.ValueParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.ValueParameter;
  return proto.loupe.common.realtime.ValueParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.ValueParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.ValueParameter}
 */
proto.loupe.common.realtime.ValueParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.realtime.FieldType} */ (reader.readEnum());
      msg.setFieldType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.ValueParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.ValueParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.ValueParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.ValueParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.loupe.common.realtime.ValueParameter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.ValueParameter} returns this
 */
proto.loupe.common.realtime.ValueParameter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FieldType field_type = 2;
 * @return {!proto.loupe.common.realtime.FieldType}
 */
proto.loupe.common.realtime.ValueParameter.prototype.getFieldType = function() {
  return /** @type {!proto.loupe.common.realtime.FieldType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.realtime.FieldType} value
 * @return {!proto.loupe.common.realtime.ValueParameter} returns this
 */
proto.loupe.common.realtime.ValueParameter.prototype.setFieldType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.ResolvedQueryParameter.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.ResolvedQueryParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.ResolvedQueryParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.ResolvedQueryParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.ResolvedQueryParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    queriesList: jspb.Message.toObjectList(msg.getQueriesList(),
    proto.loupe.common.realtime.Query.toObject, includeInstance),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.ResolvedQueryParameter}
 */
proto.loupe.common.realtime.ResolvedQueryParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.ResolvedQueryParameter;
  return proto.loupe.common.realtime.ResolvedQueryParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.ResolvedQueryParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.ResolvedQueryParameter}
 */
proto.loupe.common.realtime.ResolvedQueryParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.realtime.Query;
      reader.readMessage(value,proto.loupe.common.realtime.Query.deserializeBinaryFromReader);
      msg.addQueries(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.ResolvedQueryParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.ResolvedQueryParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.ResolvedQueryParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.ResolvedQueryParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.realtime.Query.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated Query queries = 1;
 * @return {!Array<!proto.loupe.common.realtime.Query>}
 */
proto.loupe.common.realtime.ResolvedQueryParameter.prototype.getQueriesList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.Query>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.realtime.Query, 1));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.Query>} value
 * @return {!proto.loupe.common.realtime.ResolvedQueryParameter} returns this
*/
proto.loupe.common.realtime.ResolvedQueryParameter.prototype.setQueriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.realtime.Query=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.Query}
 */
proto.loupe.common.realtime.ResolvedQueryParameter.prototype.addQueries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.realtime.Query, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.ResolvedQueryParameter} returns this
 */
proto.loupe.common.realtime.ResolvedQueryParameter.prototype.clearQueriesList = function() {
  return this.setQueriesList([]);
};


/**
 * repeated string fields = 2;
 * @return {!Array<string>}
 */
proto.loupe.common.realtime.ResolvedQueryParameter.prototype.getFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.realtime.ResolvedQueryParameter} returns this
 */
proto.loupe.common.realtime.ResolvedQueryParameter.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.ResolvedQueryParameter} returns this
 */
proto.loupe.common.realtime.ResolvedQueryParameter.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.ResolvedQueryParameter} returns this
 */
proto.loupe.common.realtime.ResolvedQueryParameter.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.ObjectSource.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.ObjectSource.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.ObjectSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.ObjectSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.ObjectSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    groupFieldsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    order: (f = msg.getOrder()) && proto.loupe.common.realtime.OrderParameter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.ObjectSource}
 */
proto.loupe.common.realtime.ObjectSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.ObjectSource;
  return proto.loupe.common.realtime.ObjectSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.ObjectSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.ObjectSource}
 */
proto.loupe.common.realtime.ObjectSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.realtime.ObjectType} */ (reader.readEnum());
      msg.setObjectType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupFields(value);
      break;
    case 4:
      var value = new proto.loupe.common.realtime.OrderParameter;
      reader.readMessage(value,proto.loupe.common.realtime.OrderParameter.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.ObjectSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.ObjectSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.ObjectSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.ObjectSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getGroupFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.loupe.common.realtime.OrderParameter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string source = 1;
 * @return {string}
 */
proto.loupe.common.realtime.ObjectSource.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.ObjectSource} returns this
 */
proto.loupe.common.realtime.ObjectSource.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ObjectType object_type = 2;
 * @return {!proto.loupe.common.realtime.ObjectType}
 */
proto.loupe.common.realtime.ObjectSource.prototype.getObjectType = function() {
  return /** @type {!proto.loupe.common.realtime.ObjectType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.realtime.ObjectType} value
 * @return {!proto.loupe.common.realtime.ObjectSource} returns this
 */
proto.loupe.common.realtime.ObjectSource.prototype.setObjectType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated string group_fields = 3;
 * @return {!Array<string>}
 */
proto.loupe.common.realtime.ObjectSource.prototype.getGroupFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.realtime.ObjectSource} returns this
 */
proto.loupe.common.realtime.ObjectSource.prototype.setGroupFieldsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.ObjectSource} returns this
 */
proto.loupe.common.realtime.ObjectSource.prototype.addGroupFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.ObjectSource} returns this
 */
proto.loupe.common.realtime.ObjectSource.prototype.clearGroupFieldsList = function() {
  return this.setGroupFieldsList([]);
};


/**
 * optional OrderParameter order = 4;
 * @return {?proto.loupe.common.realtime.OrderParameter}
 */
proto.loupe.common.realtime.ObjectSource.prototype.getOrder = function() {
  return /** @type{?proto.loupe.common.realtime.OrderParameter} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.OrderParameter, 4));
};


/**
 * @param {?proto.loupe.common.realtime.OrderParameter|undefined} value
 * @return {!proto.loupe.common.realtime.ObjectSource} returns this
*/
proto.loupe.common.realtime.ObjectSource.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.ObjectSource} returns this
 */
proto.loupe.common.realtime.ObjectSource.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.ObjectSource.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.loupe.common.realtime.QueryStatus = {
  QUERY_STATUS_INACTIVE: 0,
  QUERY_STATUS_ACTIVE: 1,
  QUERY_STATUS_ERROR: 2,
  QUERY_STATUS_INVALID: 3
};

/**
 * @enum {number}
 */
proto.loupe.common.realtime.QueryDateRangeType = {
  QUERY_DATE_RANGE_TYPE__CUSTOM: 0,
  QUERY_DATE_RANGE_TYPE__THIS_WEEK: 1,
  QUERY_DATE_RANGE_TYPE__NEXT_WEEK: 2,
  QUERY_DATE_RANGE_TYPE__LAST_WEEK: 3,
  QUERY_DATE_RANGE_TYPE__THIS_MONTH: 4,
  QUERY_DATE_RANGE_TYPE__NEXT_MONTH: 5,
  QUERY_DATE_RANGE_TYPE__LAST_MONTH: 6,
  QUERY_DATE_RANGE_TYPE__THIS_QUARTER: 7,
  QUERY_DATE_RANGE_TYPE__NEXT_QUARTER: 8,
  QUERY_DATE_RANGE_TYPE__LAST_QUARTER: 9,
  QUERY_DATE_RANGE_TYPE__THIS_FISCAL_YEAR: 10,
  QUERY_DATE_RANGE_TYPE__NEXT_FISCAL_YEAR: 11,
  QUERY_DATE_RANGE_TYPE__LAST_FISCAL_YEAR: 12
};

/**
 * @enum {number}
 */
proto.loupe.common.realtime.QueryField = {
  QUERY_FIELD_UNSPECIFIED: 0,
  QUERY_FIELD_KEY: 1,
  QUERY_FIELD_NAME: 2,
  QUERY_FIELD_DESCRIPTION: 3,
  QUERY_FIELD_NAMESPACE: 4,
  QUERY_FIELD_RAW_QUERY: 5,
  QUERY_FIELD_TAGS: 6,
  QUERY_FIELD_CONTRACT: 7,
  QUERY_FIELD_AVAILABLE_PARAMETERS: 8,
  QUERY_FIELD_DEPENDENCIES: 9,
  QUERY_FIELD_METADATA: 10,
  QUERY_FIELD_STATUS: 11,
  QUERY_FIELD_STATUS_MESSAGE: 12,
  QUERY_FIELD_VERSION: 13,
  QUERY_FIELD_CREATED_AT: 14,
  QUERY_FIELD_UPDATED_AT: 15
};

/**
 * @enum {number}
 */
proto.loupe.common.realtime.UserQueryParametersField = {
  SQPF_UNSPECIFIED: 0,
  SQPF_TENANT_ID: 1,
  SQPF_GROUP_ID: 2,
  SQPF_USER_ID: 3,
  SQPF_QUERY_KEY: 4,
  SQPF_NAME: 5,
  SQPF_PARAMETERS: 6,
  SQPF_STATUS: 7,
  SQPF_STATUS_MESSAGE: 8,
  SQPF_CREATED_AT: 9,
  SQPF_UPDATED_AT: 10
};

/**
 * @enum {number}
 */
proto.loupe.common.realtime.ParameterType = {
  PARAMETER_TYPE_UNSPECIFIED: 0,
  PARAMETER_TYPE_FILTER: 1,
  PARAMETER_TYPE_TIME_WINDOW: 2,
  PARAMETER_TYPE_ORDER: 3,
  PARAMETER_TYPE_GROUP: 4,
  PARAMETER_TYPE_VALUE: 5,
  PARAMETER_TYPE_RAW: 6,
  PARAMETER_TYPE_RESOLVED_QUERY: 7
};

/**
 * @enum {number}
 */
proto.loupe.common.realtime.FilterCombiner = {
  AND: 0,
  OR: 1
};

/**
 * @enum {number}
 */
proto.loupe.common.realtime.FieldType = {
  FIELD_TYPE_UNSPECIFIED: 0,
  FIELD_TYPE_STRING: 1,
  FIELD_TYPE_INT: 2,
  FIELD_TYPE_FLOAT: 3,
  FIELD_TYPE_BOOL: 4,
  FIELD_TYPE_DATE: 5,
  FIELD_TYPE_TIMESTAMP: 6,
  FIELD_TYPE_RAW: 7,
  FIELD_TYPE_ARRAY: 8,
  FIELD_TYPE_OBJECT: 9
};

/**
 * @enum {number}
 */
proto.loupe.common.realtime.ComparisonOp = {
  COMPARISON_OP_EQ: 0,
  COMPARISON_OP_NEQ: 1,
  COMPARISON_OP_LT: 2,
  COMPARISON_OP_GT: 3,
  COMPARISON_OP_GE: 4,
  COMPARISON_OP_LE: 5,
  COMPARISON_OP_BETWEEN: 6,
  COMPARISON_OP_OUTSIDE: 7,
  COMPARISON_OP_IN: 8,
  COMPARISON_OP_NIN: 9,
  COMPARISON_OP_REGEX: 10,
  COMPARISON_OP_NREGEX: 11,
  COMPARISON_OP_ISNULL: 12,
  COMPARISON_OP_NISNULL: 13
};

/**
 * @enum {number}
 */
proto.loupe.common.realtime.TimeWindowType = {
  TIME_WINDOW_TYPE_UNSPECIFIED: 0,
  TIME_WINDOW_TYPE_RANGE: 1,
  TIME_WINDOW_TYPE_INTERVAL: 2
};

/**
 * @enum {number}
 */
proto.loupe.common.realtime.IntervalType = {
  INTERVAL_TYPE_UNSPECIFIED: 0,
  INTERVAL_TYPE_HOUR: 1,
  INTERVAL_TYPE_DAY: 2,
  INTERVAL_TYPE_WEEK: 3,
  INTERVAL_TYPE_MONTH: 4,
  INTERVAL_TYPE_QUARTER: 5,
  INTERVAL_TYPE_HALF_YEAR: 6,
  INTERVAL_TYPE_YEAR: 7
};

/**
 * @enum {number}
 */
proto.loupe.common.realtime.OrderDirection = {
  ORDER_DIRECTION_ASC: 0,
  ORDER_DIRECTION_DESC: 1
};

/**
 * @enum {number}
 */
proto.loupe.common.realtime.ObjectType = {
  OBJECT_TYPE_UNSPECIFIED: 0,
  OPPORTUNITY: 1,
  ACTIVITY: 2,
  PERSON: 3,
  LEAD: 4,
  CONTACT: 5,
  ACCOUNT: 6
};

goog.object.extend(exports, proto.loupe.common.realtime);
