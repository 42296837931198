import React, { useMemo, useState, useCallback, useRef } from 'react'
import { useFilterCombiner } from './hooks'
import ChevronDown from '../icons/chevronDown'
import Popover from '../common/popover'

const OperatorSelector = (props) => {
  const {
    empty = (<></>),
    index,
    onChange,
    combiner
  } = props

  const menu = useRef()

  const { AND, OR, getCombiner } = useFilterCombiner()

  const [open, setOpen] = useState(false)

  const groupOpp = useMemo(() => {
    if (!index) {
      return
    }

    const op = getCombiner(combiner)

    return op ?? AND
  }, [index, combiner])

  const isOr = useMemo(() => {
    return groupOpp?.key === OR.key
  }, [groupOpp, OR])

  const altGroupOpt = useMemo(() => {
    return groupOpp === AND ? OR : AND
  }, [groupOpp])

  const handleSelectGroupOpp = useCallback(() => {
    setOpen(false)
    onChange(altGroupOpt.key)
  }, [altGroupOpt, onChange])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <div className="group-opp">
        {(!groupOpp || index === 0) && empty}
        {(groupOpp && index === 1) && (
          <button
            ref={menu}
            className={`button row text-color-5951FF ${isOr && 'is-or'}`}
            onClick={() => setOpen(true)}>
            {groupOpp.label}
            <ChevronDown className="ml-1" fill="#5951FF" size="xs" />
          </button>
        )}
        {(groupOpp && index > 1) && (
          <div className={`button row ${isOr && 'is-or'}`}>
            {groupOpp.label}
          </div>
        )}
      </div>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={menu.current}
        onClose={handleClose}
        placement="bottom"
        timeout={0}>
        <div className="py-1">
          <div
            className="px-4 py-1 text-size-14px text-color-09242f font-normal cursor-pointer hover:bg-color-edeeee"
            onClick={handleSelectGroupOpp}>
            {altGroupOpt.label}
          </div>
        </div>
      </Popover>
    </>
  )
}

export default OperatorSelector
