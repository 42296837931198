import { debounce } from 'lodash'
import { permissionNames } from '../../constants/permissionNames'
import { toBustAuthCacheRequest, toUpdatePersonRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import { useGrpcCallback } from '../../grpc'
import { useJoyride } from '../../context/joyride'
import { useModal } from '../../hooks/useModal'
import { useNotification } from '../../hooks/useNotification'
import { usePermissions } from '../../context/permissions'
import { useSystemRoles } from '../../context/systemRoles'
import { useTextField } from '../../hooks/useTextField'
import { useUsers } from '../../context/users'
import AddEditUserModal from './addEditUserModal'
import Button from '../common/button'
import EmptyState from '../common/emptyState'
import Groups from '../icons/groups'
import Header from '../header/header'
import LoadingState from '../common/loadingState'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SearchBox from '../common/searchBox'
import TextSelectList from '../common/textSelectList'
import UsersList from './usersList'
import { usersGuide } from '../../constants/externalUrls'
import PageDescription from '../pageDescription/pageDescription'

const UsersMain = (props) => {
  const { tenantId } = useAuth()

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessInternalAdmin,
      permissionNames.CanReadUser,
      permissionNames.CanCreateUser
    )
  }, [checkPermissions])

  const { invalidate, isFetching, setPage, setRoleId, setSearch, users } = useUsers()
  const { systemRoles } = useSystemRoles()

  const { notifyError, notifySuccess } = useNotification()

  const { enabled: joyrideEnabled, setJoyride, start: startJoyride } = useJoyride()

  const search = useTextField()

  const configureJoyride = useCallback(() => {
    setJoyride([
      {
        target: '#joyride_users_add',
        title: 'Add a User',
        content: 'When you’re ready, you can add users from your CRM or you can manually add them by entering the needed user information.',
        offset: 0,
        placement: 'left'
      },
      {
        target: '#joyride_users_userListItem .td-menu',
        title: 'Manage Users',
        content: 'You can edit user details or deactivate a user by clicking here.',
        offset: 0,
        placement: 'right'
      }
    ])
    startJoyride()
  }, [setJoyride, startJoyride])

  useEffect(() => {
    if (joyrideEnabled && !isFetching) {
      configureJoyride()
    }
  }, [configureJoyride, isFetching, joyrideEnabled])

  const count = useMemo(() => {
    return users.total
  }, [users])

  const showEmptyState = useMemo(() => {
    return !isFetching && count === 0
  }, [isFetching, count])

  const modal = useModal()

  const [editData, setEditData] = useState(undefined)

  const roleOptions = useMemo(() => {
    return [{
      value: 0,
      label: 'All roles'
    }, ...systemRoles.map((role) => ({
      value: role.id,
      label: role.name
    }))]
  }, [systemRoles])

  const onChangeRole = useCallback((event) => {
    setPage(1)
    setRoleId(event.target.value)
  }, [setPage, setRoleId])

  const onAdd = useCallback(() => {
    setEditData(undefined)
    modal.setOpen(true)
    window.analytics.track('Add User Clicked')
  }, [modal])

  const onEdit = useCallback((e, user) => {
    e && e.preventDefault()
    setEditData(user)
    modal.setOpen(true)
  }, [modal])

  const saveUser = useGrpcCallback({
    onError: () => {
      notifyError('Error removing user!')
    },
    onSuccess: () => {
      invalidate()
    },
    grpcMethod: 'updatePerson',
    debug: false,
  }, [])

  const onDelete = useCallback((e, user) => {
    e && e.preventDefault()

    const request = toUpdatePersonRequest({
      tenantId,
      personId: user.id,
      person: {
        ...user,
        isProvisioned: false,
      },
      onlyFieldsList: ['is_provisioned'],
    })
    saveUser(request)
  }, [tenantId, saveUser])

  const debounceSearch = useMemo(() => {
    return debounce((searchText) => {
      setSearch(searchText)
    }, 350)
  }, [setSearch])

  useEffect(() => {
    debounceSearch(search.value)
  }, [debounceSearch, search.value])

  const [disableClearAuthCache, setDisableClearAuthCache] = useState(false)

  const bustAuthCache = useGrpcCallback({
    onError: () => {
      notifyError('Error clearing auth cache!')
    },
    onSuccess: () => {
      notifySuccess('Auth cache cleared!')
      setDisableClearAuthCache(true)
    },
    grpcMethod: 'bustAuthCache',
    debug: false,
  }, [])

  const onClearAuthCache = useCallback(() => {
    const request = toBustAuthCacheRequest({
      tenantId
    })
    bustAuthCache(request)
    window.analytics.track('Clear Auth Cache Clicked')
  }, [bustAuthCache, tenantId])

  return (
    <div className="flex flex-col w-full h-screen">
      <Header
        title="Users" />
      <div className="flex-grow overflow-auto">
        <PageDescription title="Users in Outreach Commit"
          text="Manage users who can login to Outreach Commit, their assigned roles and activation status.
            User roles in Outreach Commit determine what those individuals can see and are independent of any external system roles."
          link={usersGuide} />

        <div className="px-10 pb-10">
          {permissions.CanReadUser && (
            <>
              <div className="flex items-center justify-between mt-8 mb-3">
                <div className="text-size-20px text-color-51636a font-weight-400">{!isFetching ? pluralize('user', count, true) : ''}</div>
                <div className="flex items-center">
                  <TextSelectList
                    defaultValue={roleOptions[0]?.value}
                    onChange={onChangeRole}
                    options={roleOptions}
                  />
                  <SearchBox
                    className="ml-2"
                    value={search.value}
                    onChange={search.onChange}
                    onClear={search.reset}
                    autoFocus={true} />
                  {permissions.CanCreateUser && (
                    <Button
                      id="joyride_users_add"
                      className="ml-2"
                      size="xs"
                      text="Add User"
                      onClick={onAdd} />
                  )}
                  {permissions.CanAccessInternalAdmin && (
                    <Button
                      className="ml-2"
                      size="xs"
                      text="Clear Auth Cache"
                      onClick={onClearAuthCache}
                      disabled={disableClearAuthCache} />
                  )}
                </div>
              </div>

              {showEmptyState
                ? (
                  <div className="flex justify-center my-10">
                    <EmptyState
                      iconControl={<Groups transform="scale(1.2)" />}
                      header="No Users"
                      subHeader="There are no users to display" />
                  </div>
                )
                : (
                  <>
                    {isFetching
                      ? (
                        <div className="flex justify-center my-10">
                          <LoadingState
                            header="Loading Users"
                            subHeader="Please wait..."
                            animate={true} />
                        </div>
                      )
                      : (
                        <UsersList
                          onEdit={onEdit}
                          onDelete={onDelete} />
                      )}
                  </>
                )}

              <AddEditUserModal
                modal={modal}
                editData={editData} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default UsersMain
