import React, { useState, useCallback } from 'react'
import FieldSelect from './fieldSelect'

const FieldMultiSelect = (props) => {
  const { onChange } = props

  const [selected, setSelected] = useState({})

  const onChangeInternal = useCallback((option) => {
    const [selectedOption] = option
    const { value } = selectedOption

    if (selected[value]) {
      delete selected[value]
    } else {
      selected[value] = selectedOption
    }

    setSelected({ ...selected })
    onChange(Object.values(selected))
  }, [onChange, selected])

  return (
    <FieldSelect
      {...props}
      selected={selected}
      onChange={onChangeInternal}
      multi={true} />
  )
}

export default FieldMultiSelect
