import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toCheckPermissionsRequest } from '../grpc/converters'
import { Permission, PermissionSet } from '../grpc/enums'
import { guid } from '../lib/guid'
import { forEach } from 'lodash'
import { permissionNames } from '../constants/permissionNames'
import { useDebug } from './debug'
import { useNotification } from '../hooks/useNotification'

const PermissionsContext = React.createContext()

export function PermissionsProvider({ children }) {
  const { debug, setCanDebug } = useDebug()

  const { notifyError } = useNotification()

  const [key, setKey] = useState(guid())
  const [permissions, setPermissions] = useState({})
  const [isFetching, setIsFetching] = useState(false)

  const getPermissionCheckObject = useCallback((permissionSet, permission) => {
    return {
      checksList: [
        {
          permissionSet,
          permissionsList: [permission],
        },
      ]
    }
  }, [])

  const checkPermission = useCallback((permissionName) => {
    return permissions[permissionName] === true
  }, [permissions])

  const checkPermissions = useCallback((...permissionNames) => {
    const permissions = {}
    forEach(permissionNames, (p) => {
      permissions[p] = checkPermission(p)
    })
    return permissions
  }, [checkPermission])

  useGrpcEffect({
    request: toCheckPermissionsRequest({
      valsMap: [
        // Internal Admin
        [
          permissionNames.CanAccessInternalAdmin,
          getPermissionCheckObject(PermissionSet.INTERNALADMIN, Permission.ACCESS),
        ],
        // Admin
        [
          permissionNames.CanAccessAdmin,
          getPermissionCheckObject(PermissionSet.ADMIN, Permission.ACCESS),
        ],
        // Tenant
        [
          permissionNames.CanAccessTenant,
          getPermissionCheckObject(PermissionSet.TENANT, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadTenant,
          getPermissionCheckObject(PermissionSet.TENANT, Permission.READ),
        ],
        [
          permissionNames.CanUpdateTenant,
          getPermissionCheckObject(PermissionSet.TENANT, Permission.UPDATE),
        ],
        [
          permissionNames.CanCreateTenant,
          getPermissionCheckObject(PermissionSet.TENANT, Permission.CREATE),
        ],
        // Forecasts
        [
          permissionNames.CanAccessForecasts,
          getPermissionCheckObject(PermissionSet.FORECAST, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadForecasts,
          getPermissionCheckObject(PermissionSet.FORECAST, Permission.READ),
        ],
        [
          permissionNames.CanUpdateForecasts,
          getPermissionCheckObject(PermissionSet.FORECAST, Permission.UPDATE),
        ],
        [
          permissionNames.CanCreateForecasts,
          getPermissionCheckObject(PermissionSet.FORECAST, Permission.CREATE),
        ],
        [
          permissionNames.CanManageForecasts,
          getPermissionCheckObject(PermissionSet.FORECAST, Permission.MANAGE),
        ],
        // Group
        [
          permissionNames.CanAccessGroup,
          getPermissionCheckObject(PermissionSet.GROUP, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadGroup,
          getPermissionCheckObject(PermissionSet.GROUP, Permission.READ),
        ],
        [
          permissionNames.CanUpdateGroup,
          getPermissionCheckObject(PermissionSet.GROUP, Permission.UPDATE),
        ],
        [
          permissionNames.CanCreateGroup,
          getPermissionCheckObject(PermissionSet.GROUP, Permission.CREATE),
        ],
        [
          permissionNames.CanDeleteGroup,
          getPermissionCheckObject(PermissionSet.GROUP, Permission.DELETE),
        ],
        // Goal
        [
          permissionNames.CanAccessGoal,
          getPermissionCheckObject(PermissionSet.GOAL, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadGoal,
          getPermissionCheckObject(PermissionSet.GOAL, Permission.READ),
        ],
        [
          permissionNames.CanUpdateGoal,
          getPermissionCheckObject(PermissionSet.GOAL, Permission.UPDATE),
        ],
        // User
        [
          permissionNames.CanAccessUser,
          getPermissionCheckObject(PermissionSet.USER, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadUser,
          getPermissionCheckObject(PermissionSet.USER, Permission.READ),
        ],
        [
          permissionNames.CanUpdateUser,
          getPermissionCheckObject(PermissionSet.USER, Permission.UPDATE),
        ],
        [
          permissionNames.CanCreateUser,
          getPermissionCheckObject(PermissionSet.USER, Permission.CREATE),
        ],
        [
          permissionNames.CanDeleteUser,
          getPermissionCheckObject(PermissionSet.USER, Permission.DELETE),
        ],
        // Sync
        [
          permissionNames.CanAccessSync,
          getPermissionCheckObject(PermissionSet.SYNC, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadSync,
          getPermissionCheckObject(PermissionSet.SYNC, Permission.READ),
        ],
        [
          permissionNames.CanUpdateSync,
          getPermissionCheckObject(PermissionSet.SYNC, Permission.UPDATE),
        ],
        [
          permissionNames.CanCreateSync,
          getPermissionCheckObject(PermissionSet.SYNC, Permission.CREATE),
        ],
        // System Role
        [
          permissionNames.CanAccessSystemRole,
          getPermissionCheckObject(PermissionSet.SYSTEMROLE, Permission.ACCESS),
        ],
        [
          permissionNames.CanReadSystemRole,
          getPermissionCheckObject(PermissionSet.SYSTEMROLE, Permission.READ),
        ],
        [
          permissionNames.CanUpdateSystemRole,
          getPermissionCheckObject(PermissionSet.SYSTEMROLE, Permission.UPDATE),
        ],
        [
          permissionNames.CanCreateSystemRole,
          getPermissionCheckObject(PermissionSet.SYSTEMROLE, Permission.CREATE),
        ],
        [
          permissionNames.CanDeleteSystemRole,
          getPermissionCheckObject(PermissionSet.SYSTEMROLE, Permission.DELETE),
        ],
        // Sandbox
        [
          permissionNames.CanAccessSandbox,
          getPermissionCheckObject(PermissionSet.SANDBOX, Permission.ACCESS),
        ],
      ],
    }),
    onError: () => {
      notifyError('Error fetching permissions!')
      setIsFetching(false)
    },
    onSuccess: (obj) => {
      const p = {}
      forEach(obj.resultsMap, (m) => {
        if (m.length > 1) {
          p[m[0]] = m[1]
        }
      })
      setPermissions(p)
      setIsFetching(false)

      const canAccessInternalAdmin = p[permissionNames.CanAccessInternalAdmin] === true
      setCanDebug(canAccessInternalAdmin)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'checkPermissions',
    debug: false,
  }, [key, debug])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      permissions,
      checkPermission,
      checkPermissions,
      getPermissionCheckObject,
      key,
      invalidate,
    }
  }, [isFetching, permissions, checkPermission, checkPermissions])

  return <PermissionsContext.Provider value={contextValue}>{children}</PermissionsContext.Provider>
}

export function usePermissions() {
  const context = React.useContext(PermissionsContext)
  if (context === undefined) {
    throw new Error('usePermissions must be used within a PermissionsProvider')
  }
  return context
}
