import React from 'react'

const RefreshDownloadCheck = (props) => {
  const {
    refresh = false,
    download = false,
    check = false,
  } = props

  return (
    <svg width="181" height="42" viewBox="0 0 181 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="38" y1="20" x2="141" y2="20" stroke="#5951FF" strokeWidth="2" />

      <rect x="1" y="1" width="40" height="40" rx="20" fill={refresh ? 'url(#paint2_linear)' : 'white'} stroke="url(#paint0_linear)" strokeWidth="2" />
      <path d="M20.7761 12.2224V10.2587C20.7761 9.76503 20.1838 9.52369 19.8437 9.87473L16.772 12.9354C16.5526 13.1548 16.5526 13.4949 16.772 13.7143L19.8327 16.775C20.1838 17.1151 20.7761 16.8737 20.7761 16.38V14.4164C24.4073 14.4164 27.3583 17.3674 27.3583 20.9985C27.3583 21.8651 27.1937 22.7098 26.8756 23.4668C26.711 23.8617 26.8317 24.3115 27.1279 24.6077C27.6874 25.1672 28.6308 24.9697 28.927 24.2347C29.3329 23.2364 29.5523 22.1394 29.5523 20.9985C29.5523 16.1497 25.625 12.2224 20.7761 12.2224ZM20.7761 27.5806C17.145 27.5806 14.194 24.6296 14.194 20.9985C14.194 20.1319 14.3586 19.2871 14.6767 18.5302C14.8413 18.1353 14.7206 17.6855 14.4244 17.3893C13.8649 16.8298 12.9215 17.0273 12.6253 17.7623C12.2194 18.7606 12 19.8576 12 20.9985C12 25.8473 15.9273 29.7746 20.7761 29.7746V31.7383C20.7761 32.232 21.3685 32.4733 21.7086 32.1223L24.7693 29.0616C24.9887 28.8422 24.9887 28.5021 24.7693 28.2827L21.7086 25.222C21.3685 24.8819 20.7761 25.1233 20.7761 25.6169V27.5806Z" fill={refresh ? 'white' : 'url(#paint1_linear)'} />

      <rect x="72" y="2" width="38" height="38" rx="19" fill={download ? 'url(#paint0_linear)' : 'white'} stroke="url(#paint0_linear)" strokeWidth="2" />
      <path d="M96.2718 18.3529H94.5882V13.0588C94.5882 12.4765 94.1118 12 93.5294 12H89.2941C88.7118 12 88.2353 12.4765 88.2353 13.0588V18.3529H86.5518C85.6094 18.3529 85.1329 19.4965 85.8 20.1635L90.66 25.0235C91.0729 25.4365 91.74 25.4365 92.1529 25.0235L97.0129 20.1635C97.68 19.4965 97.2141 18.3529 96.2718 18.3529ZM84 28.9412C84 29.5235 84.4765 30 85.0588 30H97.7647C98.3471 30 98.8235 29.5235 98.8235 28.9412C98.8235 28.3588 98.3471 27.8824 97.7647 27.8824H85.0588C84.4765 27.8824 84 28.3588 84 28.9412Z" fill={download ? 'white' : 'url(#paint1_linear)'} />

      <rect x="142" y="2" width="38" height="38" rx="19" fill={check ? 'url(#paint2_linear)' : 'white'} stroke="url(#paint0_linear)" strokeWidth="2" />
      <path d="M159.739 28.9838C160.137 28.9124 160.501 28.686 160.735 28.3525L169.724 15.3923C170.204 14.7014 170.04 13.7485 169.372 13.2601C168.716 12.7955 167.731 12.9742 167.274 13.6294L159.165 25.3031L154.407 21.6818C154.137 21.4793 153.832 21.3721 153.504 21.3721C153.024 21.3721 152.59 21.5984 152.309 21.9796C152.063 22.3132 151.957 22.7063 152.016 23.1113C152.074 23.5163 152.285 23.8736 152.602 24.1238L158.602 28.698C158.918 28.9362 159.34 29.0434 159.739 28.9838Z" fill={check ? 'white' : 'url(#paint1_linear)'} />

      <defs>
        <linearGradient id="paint0_linear" x1="21" y1="1" x2="21" y2="41" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5951FF" />
          <stop offset="1" stopColor="#5951FF" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="20.7761" y1="9.7085" x2="20.7761" y2="32.2885" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5951FF" />
          <stop offset="1" stopColor="#5951FF" />
        </linearGradient>
        <linearGradient id="paint2_linear" x1="40" y1="40" x2="40" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5951FF" />
          <stop offset="1" stopColor="#5951FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default RefreshDownloadCheck
