export const forecastingBestPracticesGuide = 'https://support.outreach.io/hc/en-us/articles/8747709942043-Forecasting-Admin-Configuration-in-Outreach-Commit'

export const dataSyncGuide = 'https://support.outreach.io/hc/en-us/articles/5028375724827-How-to-Map-Your-CRM-to-Outreach-Commit-with-Data-Sync'

export const teamsGuide = 'https://support.outreach.io/hc/en-us/articles/4740029730331-How-to-Configure-Teams-Users-and-Goals-in-Outreach-Commit#h_01G8HAQWXJQM8HXPHN9ASMPY65'

export const usersGuide = 'https://support.outreach.io/hc/en-us/articles/4740029730331-How-to-Configure-Teams-Users-and-Goals-in-Outreach-Commit#h_01G8HARAPQDJXHDZQMMP1XQGC9'

export const rolesGuide = 'https://support.outreach.io/hc/en-us/articles/4747724568347-How-to-Add-and-Manage-Roles-in-Outreach-Commit'

export const goalsGuide = 'https://support.outreach.io/hc/en-us/articles/4740029730331-How-to-Configure-Teams-Users-and-Goals-in-Outreach-Commit#h_01G8HARXEQSX1NJXXKP093RJTK'

export const sandboxesGuide = 'https://support.outreach.io/hc/en-us/articles/4739734196763-How-to-Use-Outreach-Commit-Sandboxes'
