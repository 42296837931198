import { forecastingBestPracticesGuide } from '../../constants/externalUrls'
import { permissionNames } from '../../constants/permissionNames'
import { TrendingUpRounded } from '@getoutreach/react-icons'
import { useForecastingConfigs } from '../../context/forecastingConfigs'
import { usePermissions } from '../../context/permissions'
import { useTextField } from '../../hooks/useTextField'
import Button from '../common/button'
import EmptyState from '../common/emptyState'
import ForecastsList from './forecastsList'
import Header from '../header/header'
import LoadingState from '../common/loadingState'
import pluralize from 'pluralize'
import React, { useMemo, useCallback, useEffect } from 'react'
import SearchBox from '../common/searchBox'
import Refresh from '../icons/refresh'
import { useJoyride } from '../../context/joyride'
import { useUserPrefs } from '../../context/userPrefs'
import ForecastSortModal from './forecastSortModal'
import { useModal } from '../../hooks/useModal'

const ForecastsMain = () => {
  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanManageForecasts
    )
  }, [checkPermissions])

  const { isFetching, configs, createConfig, isCreating } = useForecastingConfigs()
  const { enabled: joyrideEnabled, setJoyride, start: startJoyride } = useJoyride()
  const { getPref, savePref } = useUserPrefs()
  const sortModal = useModal()

  const search = useTextField()

  const configureJoyride = useCallback(() => {
    const steps = []
    // We only want to show this step if the user hasn't seen it
    if (!getPref('joyride', 'hasSeenCreateForecast')) {
      steps.push({
        target: '.createForecastBtn',
        title: 'Add a New Forecast',
        content: 'You can add a new forecast by clicking this button. When you\'re ready, you will need to publish a forecast to make it live for your teams.',
        disableBeacon: true,
        placement: 'left',
        offset: 2,
        continuous: false,
        spotlightClicks: true,
        hideTooltipClose: true
      })
      // Update the pref so we don't see this again
      savePref('joyride', 'hasSeenCreateForecast', true)
    }

    if (configs && configs?.length > 0 && !getPref('joyride', 'hasSeenForecastList')) {
      steps.push({
        target: '#joyride_forecasts_forecastsListItem .forecastMenu',
        title: 'Manage Forecast',
        offset: 2,
        content: 'Manage forecast settings or archive a forecast by clicking here.',
        placement: 'right',
        onLoad: handleSavePref.bind(this, 'hasSeenForecastList'),
      })
    }

    if (configs && configs?.length > 0 && !getPref('joyride', 'hasSeenForecastingGuide')) {
      steps.push({
        target: '.learnMoreBtn',
        title: 'Learn More',
        content: 'For more information about forecasting best practices in Outreach Commit click here.',
        placement: 'top',
        offset: 2,
        spotlightClicks: true,
        onLoad: handleSavePref.bind(this, 'hasSeenForecastingGuide'),
        locale: {
          next: 'Got It'
        },
        continuous: true
      })
    }

    setJoyride(steps)
    startJoyride()
  }, [configs, getPref, handleSavePref, savePref, setJoyride, startJoyride])

  const handleSavePref = useCallback((pref) => {
    savePref('joyride', pref, true)
  }, [savePref])

  useEffect(() => {
    if (joyrideEnabled && !isFetching) {
      configureJoyride()
    }
  }, [configureJoyride, isFetching, joyrideEnabled])

  const showEmptyState = useMemo(() => {
    return !isFetching && configs?.length === 0
  }, [isFetching, configs])

  const onCreateForecast = useCallback(() => {
    createConfig()
  }, [createConfig])

  const onSort = useCallback(() => {
    sortModal.setOpen(true)
  }, [sortModal])

  return (
    <div className="flex flex-col w-full h-screen">
      <Header
        title="Forecasts" />
      <div className="flex-grow overflow-auto p-10">

        <div className="p-6 bg-color-ffffff border border-color-2e5bff-08 rounded-lg">
          <div className="text-size-24px text-color-09242f font-weight-700">Forecasting in Outreach Commit</div>
          <div className="text-size-16px text-color-51636a font-weight-400 leading-tight mt-4">
            Forecasts give you a roll-up of your team’s calls using the categories you mapped in your CRM and help you see whether your calls match your pipeline — automatically.
            {' '}
            <a className="text-color-2e5bff text-size-15px font-weight-500 cursor-pointer learnMoreBtn"
              href={forecastingBestPracticesGuide}
              target="_blank"
              rel="noreferrer">
              Learn more
            </a>
          </div>
        </div>

        {permissions.CanManageForecasts && (
          <>
            <div className="flex items-center justify-between mt-8 mb-3">
              <div className="text-size-20px text-color-51636a font-weight-400">{!isFetching ? pluralize('forecasts', configs?.length, true) : ''}</div>
              <div className="flex items-center justify-between">
                <Button
                  text="Reorder"
                  onClick={onSort}
                  variant="text"
                  textColor="5951FF" />
                <ForecastSortModal modal={sortModal} />
                <SearchBox
                  className="my-2 mr-3"
                  value={search.value}
                  onChange={search.onChange}
                  onClear={search.reset}
                  inputWidth={290} />
                {permissions.CanManageForecasts && (
                  <Button
                    className="createForecastBtn"
                    disabled={isCreating}
                    size="xs"
                    text={isCreating
                      ? (
                        <div className="flex flex-row mr-1 text-size-16px">
                          <Refresh fill="#FFFFFF" className="animate-spin" />
                          {' '}
                          Create Forecast
                        </div>
                      )
                      : 'Create Forecast'}
                    onClick={onCreateForecast} />
                )}
              </div>
            </div>

            {showEmptyState
              ? (
                <div className="flex justify-center my-10">
                  <EmptyState
                    iconControl={(
                      <div className="p-2 rounded-full bg-color-5951FF">
                        <TrendingUpRounded htmlColor="#FFFFFF" transform="scale(1.2)" />
                      </div>
                    )}
                    header="No Forecasts"
                    subHeader="You don’t have any forecasts yet. Once you create one, they’ll show up here." />
                </div>
              )
              : (
                <>
                  {isFetching
                    ? (
                      <div className="flex justify-center my-10">
                        <LoadingState
                          header="Loading Forecasts"
                          subHeader="Please wait..."
                          animate={true} />
                      </div>
                    )
                    : (
                      <>
                        <ForecastsList />
                      </>
                    )}
                </>
              )}
          </>
        )}

      </div>
    </div>
  )
}

export default ForecastsMain
