import { useCallback, useState } from 'react'

export function usePopover() {
  const [data, setData] = useState(undefined)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const onClose = useCallback((e) => {
    setAnchorEl(null)
  }, [])

  return {
    anchorEl,
    setAnchorEl,
    open,
    onClose,
    data,
    setData,
  }
}
