import { orderBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGrpcCallback } from '../grpc'
import { toListIntegrationObjectPropertiesRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useAuth } from './auth'
import { useTenantInfo } from './tenantInfo'

const IntegrationObjectPropertiesContext = React.createContext()

export function IntegrationObjectPropertiesProvider({ objectName, children }) {
  const { tenantId } = useAuth()
  const { tenantInfo } = useTenantInfo()
  const { notifyError } = useNotification()

  const [isFetching, setIsFetching] = useState(true)
  const [fetchError, setFetchError] = useState(false)
  const [key, setKey] = useState(guid())
  const [integrationObjectProperties, setIntegrationObjectProperties] = useState([])

  const listIntegrationObjectProperties = useGrpcCallback({
    onError: () => {
      notifyError('Error fetching integration object properties!')
      setIsFetching(false)
      setFetchError(true)
    },
    onSuccess: (obj) => {
      const propertiesList = orderBy(obj.propertiesList, (o) => o.label)
      setIntegrationObjectProperties(propertiesList)
      setIsFetching(false)
      setFetchError(false)
    },
    onFetch: () => {
      setIsFetching(true)
      setFetchError(false)
    },
    grpcMethod: 'listIntegrationObjectProperties',
    debug: false,
  }, [])

  useEffect(() => {
    const { crmType } = tenantInfo
    if (!crmType || !objectName) {
      setIsFetching(false)
      return
    }
    const request = toListIntegrationObjectPropertiesRequest({
      tenantId,
      crmType,
      crmObjectName: objectName,
    })
    listIntegrationObjectProperties(request)
  }, [tenantId, tenantInfo, objectName, key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      setIsFetching,
      fetchError,
      integrationObjectProperties,
      setIntegrationObjectProperties,
      key,
      invalidate,
    }
  }, [isFetching, fetchError, integrationObjectProperties])

  return <IntegrationObjectPropertiesContext.Provider value={contextValue}>{children}</IntegrationObjectPropertiesContext.Provider>
}

export function useIntegrationObjectProperties() {
  const context = React.useContext(IntegrationObjectPropertiesContext)
  if (context === undefined) {
    throw new Error('useIntegrationObjectProperties must be used within a IntegrationObjectPropertiesProvider')
  }
  return context
}
