import React, { useCallback, useMemo, useState } from 'react'
import { useForecastingConfigColumn } from './forecastingConfigColumn'
import { useFilterSourceOptions } from './filterSourceOptions'
import { FilterCombiner } from '../grpc/enums'
import { guid } from '../lib/guid'

const ForecastConfigValueSettingsContext = React.createContext()

export function ForecastConfigValueSettingsProvider({ children }) {
  const { sourceOptions } = useFilterSourceOptions()

  const { configColumn, updateConfigColumn } = useForecastingConfigColumn()

  const { forecastConfigValueSettings = {} } = configColumn

  const { filter: filterInitial = {} } = forecastConfigValueSettings
  const { filters: filtersInitial = {} } = filterInitial

  const [key, setKey] = useState(guid())
  const [filters, setFilters] = useState(filtersInitial)

  const updateFilters = useCallback((updated) => {
    setFilters(updated)
    updateConfigColumn({
      forecastConfigValueSettings: {
        filter: {
          filters: updated
        }
      }
    })
  }, [updateConfigColumn])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const addFilterToNested = useCallback((newFilter, origNestedList) => {
    const insertFilter = (filters) => {
      const { nestedList } = filters
      if (Object.is(nestedList, origNestedList)) {
        return {
          ...filters,
          nestedList: [
            ...nestedList,
            newFilter
          ]
        }
      }

      if (nestedList?.length) {
        return {
          ...filters,
          nestedList: nestedList.map((nf) => insertFilter(nf))
        }
      }
      return filters
    }

    updateFilters(insertFilter(filters))
  }, [filters, updateFilters])

  const addGroupToNested = useCallback((newGroup, origNestedList) => {
    const insertGroup = (filters) => {
      if (!filters) {
        return newGroup
      }

      const { nestedList } = filters

      if (!nestedList?.length && Object.is(filters, origNestedList)) {
        return newGroup
      }

      if (Object.is(nestedList, origNestedList)) {
        if (origNestedList.length && !origNestedList[0].filter) {
          return {
            ...filters,
            nestedList: [
              ...nestedList,
              newGroup
            ]
          }
        }
        const newWrapper = createFilterWrapper()
        newWrapper.nestedList = [...nestedList]
        return {
          ...filters,
          nestedList: [
            newWrapper,
            newGroup
          ]
        }
      }

      if (nestedList?.length) {
        return {
          ...filters,
          nestedList: nestedList.map((nf) => insertGroup(nf))
        }
      }
      return filters
    }

    updateFilters(insertGroup(filters))
  }, [updateFilters, filters, createFilterWrapper])

  const createFilter = useCallback(() => {
    const [{ source }] = sourceOptions
    return {
      combiner: FilterCombiner.AND,
      nestedList: [],
      key: guid(),
      filter: {
        key: guid(),
        source,
        field: null,
        fieldType: null,
        comparisonOp: null,
        valuesList: []
      }
    }
  }, [sourceOptions])

  const createFilterWrapper = useCallback(() => {
    return {
      key: guid(),
      nestedList: [
        createFilter()
      ],
      combiner: FilterCombiner.AND
    }
  }, [createFilter])

  const addGroup = useCallback((targetFilter) => {
    const newFilter = createFilterWrapper()
    addGroupToNested(newFilter, targetFilter)
  }, [addGroupToNested, createFilterWrapper])

  const addFilter = useCallback((targetList) => {
    const newFilter = createFilter()
    addFilterToNested(newFilter, targetList)
  }, [addFilterToNested, createFilter])

  const setCombiner = useCallback((updatedCombiner, origFilters) => {
    const replaceFilter = (filters) => {
      const { nestedList } = filters
      if (Object.is(filters, origFilters)) {
        return {
          ...filters,
          combiner: updatedCombiner
        }
      }

      if (nestedList?.length) {
        return {
          ...filters,
          nestedList: nestedList.map((nf) => replaceFilter(nf))
        }
      }
      return filters
    }

    updateFilters(replaceFilter(filters))
  }, [filters, updateFilters])

  const setFilterValue = useCallback((updatedFilter, origFilter) => {
    const replaceFilter = (filters) => {
      const { filter, nestedList } = filters
      if (Object.is(filter, origFilter)) {
        return {
          ...filters,
          filter: updatedFilter
        }
      }

      if (nestedList?.length) {
        return {
          ...filters,
          nestedList: nestedList.map((nf) => replaceFilter(nf))
        }
      }
      return filters
    }

    updateFilters(replaceFilter(filters))
  }, [filters, updateFilters])

  const removeFilter = useCallback((origFilter) => {
    const replaceFilter = (filters) => {
      const { filter, nestedList } = filters
      if (Object.is(filter, origFilter)) {
        return
      }

      if (nestedList?.length) {
        const newNested = nestedList.map(replaceFilter).filter((f) => f)

        if (!newNested.length && !filters?.filter) {
          return
        }

        if (newNested.length === 1 && !newNested[0]?.filter) {
          return newNested[0]
        }

        return {
          ...filters,
          nestedList: newNested
        }
      }
      return filters
    }

    updateFilters(replaceFilter(filters))
  }, [filters, updateFilters])

  const contextValue = useMemo(() => {
    return {
      key,
      invalidate,
      filters,
      addFilter,
      addGroup,
      setCombiner,
      setFilterValue,
      removeFilter
    }
  }, [
    key,
    invalidate,
    filters,
    addFilter,
    addGroup,
    setCombiner,
    setFilterValue,
    removeFilter
  ])

  return <ForecastConfigValueSettingsContext.Provider value={contextValue}>{children}</ForecastConfigValueSettingsContext.Provider>
}

export function useForecastConfigValueSettings() {
  const context = React.useContext(ForecastConfigValueSettingsContext)
  if (context === undefined) {
    throw new Error('useForecastingConfigColumn must be used within a ForecastConfigValueSettingsProvider')
  }
  return context
}
