import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Tooltip from '../common/tooltip'

const SideMenuItem = (props) => {
  const { disabled, isCurrent, icon, label, onClick, textColor = 'text-color-#09242F', tooltip = '' } = props

  const highlightClass = classNames('z-10 absolute w-full h-full', { 'sidemenu-highlight': isCurrent, 'sidemenu-item-highlight': !disabled })

  const linkClass = classNames(
    textColor,
    'sidemenu-item-link',
    'w-full',
    'z-20',
    'font-medium',
    'hidden',
    'md:flex',
    'items-center',
    'px-6',
    'py-1',
    'border-color-transparent',
    'select-none',
    { 'cursor-pointer': !disabled },
    { 'sidemenu-hover': !isCurrent && !disabled },
    { 'sidemenu-selected': isCurrent }
  )

  const iconClass = 'flex items-center flex-shrink-0 mr-1'

  return (
    <div className="sidemenu-item flex relative">
      <div className={highlightClass} />
      <a className={linkClass} {...!disabled && { onClick }}>
        {icon && <div className={iconClass}>{icon}</div>}
        <Tooltip arrow placement="right" title={tooltip}>
          <span className="pr-2 py-2">{label}</span>
        </Tooltip>
      </a>
    </div>
  )
}

export default SideMenuItem
