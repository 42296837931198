// source: src/common/query/query.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_param_query_param_pb = require('../../../src/common/param/query_param_pb.js');
goog.object.extend(proto, src_common_param_query_param_pb);
var src_common_setting_notification_notification_pb = require('../../../src/common/setting/notification/notification_pb.js');
goog.object.extend(proto, src_common_setting_notification_notification_pb);
var src_common_template_presentation_presentation_pb = require('../../../src/common/template/presentation/presentation_pb.js');
goog.object.extend(proto, src_common_template_presentation_presentation_pb);
var src_common_signal_definition_grouping_grouping_pb = require('../../../src/common/signal/definition/grouping/grouping_pb.js');
goog.object.extend(proto, src_common_signal_definition_grouping_grouping_pb);
goog.exportSymbol('proto.loupe.common.query.CronSettings', null, global);
goog.exportSymbol('proto.loupe.common.query.Query', null, global);
goog.exportSymbol('proto.loupe.common.query.QueryList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.query.Query = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.query.Query.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.query.Query, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.query.Query.displayName = 'proto.loupe.common.query.Query';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.query.QueryList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.query.QueryList.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.query.QueryList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.query.QueryList.displayName = 'proto.loupe.common.query.QueryList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.query.CronSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.query.CronSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.query.CronSettings.displayName = 'proto.loupe.common.query.CronSettings';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.query.Query.repeatedFields_ = [15,18,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.query.Query.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.query.Query.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.query.Query} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.query.Query.toObject = function(includeInstance, msg) {
  var f, obj = {
    raw: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paramsMap: (f = msg.getParamsMap()) ? f.toObject(includeInstance, proto.loupe.common.param.QueryParam.toObject) : [],
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    level: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    key: jspb.Message.getFieldWithDefault(msg, 6, ""),
    title: jspb.Message.getFieldWithDefault(msg, 7, ""),
    category: jspb.Message.getFieldWithDefault(msg, 8, ""),
    revision: jspb.Message.getFieldWithDefault(msg, 9, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    description: jspb.Message.getFieldWithDefault(msg, 13, ""),
    notificationSetting: (f = msg.getNotificationSetting()) && src_common_setting_notification_notification_pb.Notification.toObject(includeInstance, f),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    presentation: (f = msg.getPresentation()) && src_common_template_presentation_presentation_pb.Presentation.toObject(includeInstance, f),
    settingsMap: (f = msg.getSettingsMap()) ? f.toObject(includeInstance, undefined) : [],
    dependenciesList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    cronSettings: (f = msg.getCronSettings()) && proto.loupe.common.query.CronSettings.toObject(includeInstance, f),
    nlgTemplatesMap: (f = msg.getNlgTemplatesMap()) ? f.toObject(includeInstance, undefined) : [],
    grouping: (f = msg.getGrouping()) && src_common_signal_definition_grouping_grouping_pb.Grouping.toObject(includeInstance, f),
    injectablesList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    availability: jspb.Message.getFieldWithDefault(msg, 23, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.query.Query}
 */
proto.loupe.common.query.Query.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.query.Query;
  return proto.loupe.common.query.Query.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.query.Query} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.query.Query}
 */
proto.loupe.common.query.Query.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRaw(value);
      break;
    case 2:
      var value = msg.getParamsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.common.param.QueryParam.deserializeBinaryFromReader, "", new proto.loupe.common.param.QueryParam());
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRevision(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 14:
      var value = new src_common_setting_notification_notification_pb.Notification;
      reader.readMessage(value,src_common_setting_notification_notification_pb.Notification.deserializeBinaryFromReader);
      msg.setNotificationSetting(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoles(value);
      break;
    case 16:
      var value = new src_common_template_presentation_presentation_pb.Presentation;
      reader.readMessage(value,src_common_template_presentation_presentation_pb.Presentation.deserializeBinaryFromReader);
      msg.setPresentation(value);
      break;
    case 17:
      var value = msg.getSettingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addDependencies(value);
      break;
    case 19:
      var value = new proto.loupe.common.query.CronSettings;
      reader.readMessage(value,proto.loupe.common.query.CronSettings.deserializeBinaryFromReader);
      msg.setCronSettings(value);
      break;
    case 20:
      var value = msg.getNlgTemplatesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 21:
      var value = new src_common_signal_definition_grouping_grouping_pb.Grouping;
      reader.readMessage(value,src_common_signal_definition_grouping_grouping_pb.Grouping.deserializeBinaryFromReader);
      msg.setGrouping(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addInjectables(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailability(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.query.Query.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.query.Query.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.query.Query} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.query.Query.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRaw();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.common.param.QueryParam.serializeBinaryToWriter);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLevel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRevision();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getNotificationSetting();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      src_common_setting_notification_notification_pb.Notification.serializeBinaryToWriter
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getPresentation();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      src_common_template_presentation_presentation_pb.Presentation.serializeBinaryToWriter
    );
  }
  f = message.getSettingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(17, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getDependenciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
  f = message.getCronSettings();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.loupe.common.query.CronSettings.serializeBinaryToWriter
    );
  }
  f = message.getNlgTemplatesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(20, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getGrouping();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      src_common_signal_definition_grouping_grouping_pb.Grouping.serializeBinaryToWriter
    );
  }
  f = message.getInjectablesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getAvailability();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
};


/**
 * optional string raw = 1;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getRaw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setRaw = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, loupe.common.param.QueryParam> params = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.common.param.QueryParam>}
 */
proto.loupe.common.query.Query.prototype.getParamsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.common.param.QueryParam>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.loupe.common.param.QueryParam));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.clearParamsMap = function() {
  this.getParamsMap().clear();
  return this;};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string level = 4;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string key = 6;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string title = 7;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string category = 8;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string revision = 9;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getRevision = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setRevision = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string tenant_id = 10;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string group_id = 11;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string user_id = 12;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string description = 13;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional loupe.common.setting.notification.Notification notification_setting = 14;
 * @return {?proto.loupe.common.setting.notification.Notification}
 */
proto.loupe.common.query.Query.prototype.getNotificationSetting = function() {
  return /** @type{?proto.loupe.common.setting.notification.Notification} */ (
    jspb.Message.getWrapperField(this, src_common_setting_notification_notification_pb.Notification, 14));
};


/**
 * @param {?proto.loupe.common.setting.notification.Notification|undefined} value
 * @return {!proto.loupe.common.query.Query} returns this
*/
proto.loupe.common.query.Query.prototype.setNotificationSetting = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.clearNotificationSetting = function() {
  return this.setNotificationSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.query.Query.prototype.hasNotificationSetting = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated string roles = 15;
 * @return {!Array<string>}
 */
proto.loupe.common.query.Query.prototype.getRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional loupe.common.template.presentation.Presentation presentation = 16;
 * @return {?proto.loupe.common.template.presentation.Presentation}
 */
proto.loupe.common.query.Query.prototype.getPresentation = function() {
  return /** @type{?proto.loupe.common.template.presentation.Presentation} */ (
    jspb.Message.getWrapperField(this, src_common_template_presentation_presentation_pb.Presentation, 16));
};


/**
 * @param {?proto.loupe.common.template.presentation.Presentation|undefined} value
 * @return {!proto.loupe.common.query.Query} returns this
*/
proto.loupe.common.query.Query.prototype.setPresentation = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.clearPresentation = function() {
  return this.setPresentation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.query.Query.prototype.hasPresentation = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * map<string, string> settings = 17;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.common.query.Query.prototype.getSettingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 17, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.clearSettingsMap = function() {
  this.getSettingsMap().clear();
  return this;};


/**
 * repeated string dependencies = 18;
 * @return {!Array<string>}
 */
proto.loupe.common.query.Query.prototype.getDependenciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setDependenciesList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.addDependencies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.clearDependenciesList = function() {
  return this.setDependenciesList([]);
};


/**
 * optional CronSettings cron_settings = 19;
 * @return {?proto.loupe.common.query.CronSettings}
 */
proto.loupe.common.query.Query.prototype.getCronSettings = function() {
  return /** @type{?proto.loupe.common.query.CronSettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.query.CronSettings, 19));
};


/**
 * @param {?proto.loupe.common.query.CronSettings|undefined} value
 * @return {!proto.loupe.common.query.Query} returns this
*/
proto.loupe.common.query.Query.prototype.setCronSettings = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.clearCronSettings = function() {
  return this.setCronSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.query.Query.prototype.hasCronSettings = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * map<string, string> nlg_templates = 20;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.common.query.Query.prototype.getNlgTemplatesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 20, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.clearNlgTemplatesMap = function() {
  this.getNlgTemplatesMap().clear();
  return this;};


/**
 * optional loupe.common.signal.definition.grouping.Grouping grouping = 21;
 * @return {?proto.loupe.common.signal.definition.grouping.Grouping}
 */
proto.loupe.common.query.Query.prototype.getGrouping = function() {
  return /** @type{?proto.loupe.common.signal.definition.grouping.Grouping} */ (
    jspb.Message.getWrapperField(this, src_common_signal_definition_grouping_grouping_pb.Grouping, 21));
};


/**
 * @param {?proto.loupe.common.signal.definition.grouping.Grouping|undefined} value
 * @return {!proto.loupe.common.query.Query} returns this
*/
proto.loupe.common.query.Query.prototype.setGrouping = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.clearGrouping = function() {
  return this.setGrouping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.query.Query.prototype.hasGrouping = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * repeated string injectables = 22;
 * @return {!Array<string>}
 */
proto.loupe.common.query.Query.prototype.getInjectablesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setInjectablesList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.addInjectables = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.clearInjectablesList = function() {
  return this.setInjectablesList([]);
};


/**
 * optional string availability = 23;
 * @return {string}
 */
proto.loupe.common.query.Query.prototype.getAvailability = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.Query} returns this
 */
proto.loupe.common.query.Query.prototype.setAvailability = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.query.QueryList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.query.QueryList.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.query.QueryList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.query.QueryList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.query.QueryList.toObject = function(includeInstance, msg) {
  var f, obj = {
    queriesList: jspb.Message.toObjectList(msg.getQueriesList(),
    proto.loupe.common.query.Query.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.query.QueryList}
 */
proto.loupe.common.query.QueryList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.query.QueryList;
  return proto.loupe.common.query.QueryList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.query.QueryList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.query.QueryList}
 */
proto.loupe.common.query.QueryList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.query.Query;
      reader.readMessage(value,proto.loupe.common.query.Query.deserializeBinaryFromReader);
      msg.addQueries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.query.QueryList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.query.QueryList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.query.QueryList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.query.QueryList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.query.Query.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Query queries = 1;
 * @return {!Array<!proto.loupe.common.query.Query>}
 */
proto.loupe.common.query.QueryList.prototype.getQueriesList = function() {
  return /** @type{!Array<!proto.loupe.common.query.Query>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.query.Query, 1));
};


/**
 * @param {!Array<!proto.loupe.common.query.Query>} value
 * @return {!proto.loupe.common.query.QueryList} returns this
*/
proto.loupe.common.query.QueryList.prototype.setQueriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.query.Query=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.query.Query}
 */
proto.loupe.common.query.QueryList.prototype.addQueries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.query.Query, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.query.QueryList} returns this
 */
proto.loupe.common.query.QueryList.prototype.clearQueriesList = function() {
  return this.setQueriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.query.CronSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.query.CronSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.query.CronSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.query.CronSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFieldWithDefault(msg, 1, ""),
    max: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.query.CronSettings}
 */
proto.loupe.common.query.CronSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.query.CronSettings;
  return proto.loupe.common.query.CronSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.query.CronSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.query.CronSettings}
 */
proto.loupe.common.query.CronSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.query.CronSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.query.CronSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.query.CronSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.query.CronSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMax();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string min = 1;
 * @return {string}
 */
proto.loupe.common.query.CronSettings.prototype.getMin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.CronSettings} returns this
 */
proto.loupe.common.query.CronSettings.prototype.setMin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string max = 2;
 * @return {string}
 */
proto.loupe.common.query.CronSettings.prototype.getMax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.query.CronSettings} returns this
 */
proto.loupe.common.query.CronSettings.prototype.setMax = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.loupe.common.query);
