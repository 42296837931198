import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useModal } from '../../hooks/useModal'
import ActionModal from './actionModal'

const BlockNavigation = (props) => {
  const {
    actionModalProps = {},
    canBlock = false,
    promptTitle,
    promptText,
    promptSubText,
    promptActionText,
    promptCancelText,
    onLeaveCancel,
    onLeaveConfirmed,
  } = props

  const unblock = useRef()

  const history = useHistory()

  const modal = useModal()

  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
    setShowPrompt(!modal.open && canBlock)
  }, [modal.open, canBlock])

  useEffect(() => {
    if (showPrompt) {
      unblock.current = history.block((prompt) => {
        setCurrentPath(prompt.pathname)
        modal.setOpen(true)
        return ''
      })
      return () => {
        unblock.current?.()
      }
    }
  }, [history, modal, showPrompt])

  const onLeaveCancelInternal = useCallback(() => {
    onLeaveCancel && onLeaveCancel()
    unblock.current?.()
  }, [onLeaveCancel])

  const onLeaveConfirmedInternal = useCallback(() => {
    onLeaveConfirmed && onLeaveConfirmed()
    unblock.current?.()
    history.push(currentPath)
  }, [currentPath, history, onLeaveConfirmed])

  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (canBlock) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [canBlock])

  return (
    <ActionModal
      modal={modal}
      title={promptTitle}
      text={promptText}
      subText={promptSubText}
      cancelText={promptCancelText}
      actionText={promptActionText}
      onCancel={onLeaveCancelInternal}
      onAction={onLeaveConfirmedInternal}
      {...actionModalProps}
    />
  )
}

export default BlockNavigation
