import { permissionNames } from '../../constants/permissionNames'
import { useHistory } from 'react-router-dom'
import { usePermissions } from '../../context/permissions'
import { usePopover } from '../../hooks/usePopover'
import { useRoutes } from '../../context/routes'
import Copy from '../icons/copy'
import Delete from '../icons/delete'
import Edit from '../icons/edit'
import MoreVertical from '../icons/moreVertical'
import Popover from '../common/popover'
import React, { useCallback, useMemo } from 'react'
import Visibility from '../icons/visibility'

const RoleMenu = (props) => {
  const {
    role,
    onDuplicate,
    onDelete,
  } = props

  const { checkPermissions } = usePermissions()
  const { routes } = useRoutes()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanUpdateSystemRole,
      permissionNames.CanCreateSystemRole,
      permissionNames.CanDeleteSystemRole
    )
  }, [checkPermissions])

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const { isCustom } = role

  const history = useHistory()

  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const onEditInternal = useCallback((e) => {
    const { id } = role
    history.push(routes.role.replace(':roleId', id))
    onClose()
  }, [history, role])

  const onDeleteInternal = useCallback((e) => {
    window.analytics.track('Delete Role Clicked')
    onClose()
    onDelete?.(e, role)
  }, [onDelete, role])

  const onDuplicateInternal = useCallback((e) => {
    window.analytics.track('Duplicate Role Clicked')
    onDuplicate?.(e, role)
    onClose()
  }, [onDuplicate, role])

  return (
    <>
      <button onClick={handleClick}>
        <MoreVertical fill="#151d49" />
      </button>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        placement="right">
        <div className="py-3">
          {isCustom ? (
            <>
              {permissions.CanUpdateSystemRole && (
                <div onClick={onEditInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
                  <div>
                    <Edit fill="#09242f" transform="scale(0.75)" />
                  </div>
                  <div className="flex flex-col leading-tight ml-2">
                    <div className="text-size-15px text-color-151d49 font-weight-400">Edit Role</div>
                    <div className="text-size-13px text-color-4e5d7e font-weight-400">Change details and perimssions for this role</div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div onClick={onEditInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
              <div>
                <Visibility fill="#09242f" transform="scale(0.75)" />
              </div>
              <div className="flex flex-col leading-tight ml-2">
                <div className="text-size-15px text-color-151d49 font-weight-400">View Role</div>
                <div className="text-size-13px text-color-4e5d7e font-weight-400">See details and perimssions for this role</div>
              </div>
            </div>
          )}
          {permissions.CanCreateSystemRole && (
            <div onClick={onDuplicateInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
              <div>
                <Copy fill="#09242f" transform="scale(0.75)" />
              </div>
              <div className="flex flex-col leading-tight ml-2">
                <div className="text-size-15px text-color-151d49 font-weight-400">Duplicate Role</div>
                <div className="text-size-13px text-color-4e5d7e font-weight-400">Make a new role using this role as a foundation</div>
              </div>
            </div>
          )}
          {isCustom && permissions.CanDeleteSystemRole && (
            <div onClick={onDeleteInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
              <div>
                <Delete fill="#09242f" transform="scale(0.75)" />
              </div>
              <div className="flex flex-col leading-tight ml-2">
                <div className="text-size-15px text-color-151d49 font-weight-400">Remove Role</div>
                <div className="text-size-13px text-color-4e5d7e font-weight-400">Delete this Role</div>
              </div>
            </div>
          )}
        </div>
      </Popover>
    </>
  )
}

export default RoleMenu
