import { GroupsErrorProvider } from '../../context/groupsError'
import { OnboardingStep } from '../../grpc/enums'
import { SyncStateProvider } from '../../context/syncState'
import { TenantLicenseInfoProvider } from '../../context/tenantLicenseInfo'
import { TreeDataProvider } from '../../context/treeData'
import GroupsMain from './groupsMain'
import Onboarding from '../onboarding/onboarding'
import React from 'react'

const Groups = (props) => {
  return (
    <GroupsErrorProvider>
      <SyncStateProvider>
        <TreeDataProvider>
          <TenantLicenseInfoProvider>
            <Onboarding step={OnboardingStep.ONBOARDING_STEP_TEAMS}>
              <GroupsMain />
            </Onboarding>
          </TenantLicenseInfoProvider>
        </TreeDataProvider>
      </SyncStateProvider>
    </GroupsErrorProvider>
  )
}

export default Groups
