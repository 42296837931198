import { Close } from '@getoutreach/react-icons'
import { noop } from 'lodash'
import JoyrideSubtitle from './joyrideSubtitle'
import JoyrideTitle from './joyrideTitle'
import React, { useEffect } from 'react'

const JoyrideTooltip = ({
  index,
  size,
  step,
  primaryProps,
  tooltipProps
}) => {
  const { title: buttonText = '', onClick = noop } = primaryProps
  const { continuous = true, content = '', onDismiss = null, onLoad = null, hideProgress = false, title = '', hideTooltipClose } = step

  useEffect(() => {
    onLoad && onLoad()
  }, [])

  return (
    <div className="bg-color-ffffff px-6 py-5 rounded max-w-md shadow-tooltip" {...tooltipProps}>
      <JoyrideTitle>
        {title}
        {!hideTooltipClose
          && (
            <Close
              className="mt-1 ml-6 cursor-pointer focus:outline-none"
              fontSize="small"
              htmlColor="#8F8D9A"
              {...primaryProps}
              {...onDismiss && {
                onClick: (e) => {
                  onDismiss(e)
                  onClick(e)
                }
              }}
            />
          )}
      </JoyrideTitle>
      <JoyrideSubtitle>{content}</JoyrideSubtitle>
      {continuous && (
        <div className="flex items-center justify-end mt-12">
          {!hideProgress && (
            <span className="text-color-8f8d9a text-size-18px font-weight-400 mr-4">
              {`${index + 1} of ${size}`}
            </span>
          )}
          <button className="bg-color-5951FF font-weight-700 leading-tight text-color-ffffff text-size-20px px-4 py-3 rounded-lg focus:outline-none" {...primaryProps}>
            {buttonText}
          </button>
        </div>
      )}
    </div>
  )
}

export default JoyrideTooltip
