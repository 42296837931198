import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useInputFormat } from '../hooks'

const FieldInput = (props) => {
  const {
    format,
    value,
    placeholder,
    onFocus,
    onBlur,
    onChange
  } = props

  const inputRef = useRef()
  const { getFormattingRules } = useInputFormat()

  const formatRules = getFormattingRules(format)
  const ruleFormatter = formatRules?.applyFormat

  const [isFocused, setIsFocused] = useState(false)
  const [valueInternal, setValueInternal] = useState('')
  const [displayValue, setDisplayValue] = useState('')

  useEffect(() => {
    const initialValue = value?.[0]?.value ?? ''
    const initialDisplay = value && ruleFormatter ? ruleFormatter(initialValue) : initialValue
    setValueInternal(initialValue)
    setDisplayValue(initialDisplay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onKeyPress = useCallback((e) => {
    const { key } = e
    if (key === 'Enter') {
      inputRef.current.blur()
      e.preventDefault()
    }
  }, [inputRef])

  const handleChangeInternal = useCallback((e) => {
    const { value } = e.target
    setValueInternal(value)
    setDisplayValue(value)
    onChange([{
      value,
      label: value
    }])
  }, [onChange])

  const onFocusInternal = useCallback((e) => {
    setIsFocused(true)
    setDisplayValue(valueInternal)
    onFocus?.(e)
  }, [onFocus, valueInternal])

  const onBlurInternal = useCallback((e) => {
    setIsFocused(false)
    if (!valueInternal) {
      return
    }
    const formattedValue = ruleFormatter?.(valueInternal) ?? valueInternal
    setDisplayValue(formattedValue)
    onChange([{
      value: valueInternal,
      label: formattedValue
    }])
    onBlur?.(e)
  }, [onBlur, onChange, valueInternal, ruleFormatter])

  return (
    <input
      ref={inputRef}
      required
      value={isFocused ? valueInternal : displayValue}
      className="field"
      onKeyPress={onKeyPress}
      pattern={formatRules?.pattern}
      onBlur={onBlurInternal}
      onFocus={onFocusInternal}
      onChange={handleChangeInternal}
      type="text"
      placeholder={placeholder || 'Enter a value'}
    />
  )
}

export default FieldInput
