import { useFilterSourceOptions } from '../../context/filterSourceOptions'
import FilterParameter from './filterParameter'
import LoadingState from '../common/loadingState'
import React from 'react'

const FilterEditor = (props) => {
  const { isFetching } = useFilterSourceOptions()

  return (
    <div className="filters-main" style={{ alignItems: 'center' }}>
      {isFetching
        ? (
          <LoadingState
            header="Loading Filters"
            subHeader="Please wait..."
            animate={true} />
        )
        : (
          <div className="parameters">
            <div className="parameter-cluster">
              <div className="parameter-group">
                <FilterParameter />
              </div>
            </div>
          </div>
        )}
    </div>
  )
}

export default FilterEditor
