// source: src/common/reporting/futureforecasting/future_forecast.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_nullable_nullable_pb = require('../../../../src/common/nullable/nullable_pb.js');
goog.object.extend(proto, src_common_nullable_nullable_pb);
var src_common_param_query_param_pb = require('../../../../src/common/param/query_param_pb.js');
goog.object.extend(proto, src_common_param_query_param_pb);
var src_common_query_query_pb = require('../../../../src/common/query/query_pb.js');
goog.object.extend(proto, src_common_query_query_pb);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.AggregationType', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.DateRangeOption', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.DateRangeType', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.FutureForecastContainer', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.FutureForecastEquation', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.FutureForecastInputField', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.FutureForecastScenario', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.GlobalLevel', null, global);
goog.exportSymbol('proto.loupe.common.reporting.futureforecasting.RealtimeQuery', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.FutureForecastContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.displayName = 'proto.loupe.common.reporting.futureforecasting.FutureForecastContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.futureforecasting.RealtimeQuery.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.RealtimeQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.RealtimeQuery.displayName = 'proto.loupe.common.reporting.futureforecasting.RealtimeQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.displayName = 'proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.FutureForecastEquation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.displayName = 'proto.loupe.common.reporting.futureforecasting.FutureForecastEquation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.displayName = 'proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.displayName = 'proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.FutureForecastScenario, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.displayName = 'proto.loupe.common.reporting.futureforecasting.FutureForecastScenario';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.displayName = 'proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.displayName = 'proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.displayName = 'proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.FutureForecastInputField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.displayName = 'proto.loupe.common.reporting.futureforecasting.FutureForecastInputField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.displayName = 'proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.displayName = 'proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.repeatedFields_ = [6,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startDatetime: (f = msg.getStartDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDatetime: (f = msg.getEndDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateRangeType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    src_common_param_query_param_pb.QueryParam.toObject, includeInstance),
    level: (f = msg.getLevel()) && proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.toObject(includeInstance, f),
    equation: (f = msg.getEquation()) && proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.toObject(includeInstance, f),
    realtimeQuery: (f = msg.getRealtimeQuery()) && src_common_query_query_pb.Query.toObject(includeInstance, f),
    scenariosList: jspb.Message.toObjectList(msg.getScenariosList(),
    proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.toObject, includeInstance),
    saveId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    createdAt: (f = msg.getCreatedAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    ownerId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 15, 0),
    outputFormat: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.FutureForecastContainer;
  return proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDatetime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDatetime(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.common.reporting.futureforecasting.DateRangeOption} */ (reader.readEnum());
      msg.setDateRangeType(value);
      break;
    case 6:
      var value = new src_common_param_query_param_pb.QueryParam;
      reader.readMessage(value,src_common_param_query_param_pb.QueryParam.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 7:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.deserializeBinaryFromReader);
      msg.setLevel(value);
      break;
    case 8:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastEquation;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.deserializeBinaryFromReader);
      msg.setEquation(value);
      break;
    case 9:
      var value = new src_common_query_query_pb.Query;
      reader.readMessage(value,src_common_query_query_pb.Query.deserializeBinaryFromReader);
      msg.setRealtimeQuery(value);
      break;
    case 10:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastScenario;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.deserializeBinaryFromReader);
      msg.addScenarios(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSaveId(value);
      break;
    case 12:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutputFormat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartDatetime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDatetime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateRangeType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      src_common_param_query_param_pb.QueryParam.serializeBinaryToWriter
    );
  }
  f = message.getLevel();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.serializeBinaryToWriter
    );
  }
  f = message.getEquation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.serializeBinaryToWriter
    );
  }
  f = message.getRealtimeQuery();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      src_common_query_query_pb.Query.serializeBinaryToWriter
    );
  }
  f = message.getScenariosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.serializeBinaryToWriter
    );
  }
  f = message.getSaveId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getOutputFormat();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp start_datetime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getStartDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setStartDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.clearStartDatetime = function() {
  return this.setStartDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.hasStartDatetime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp end_datetime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getEndDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setEndDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.clearEndDatetime = function() {
  return this.setEndDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.hasEndDatetime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DateRangeOption date_range_type = 5;
 * @return {!proto.loupe.common.reporting.futureforecasting.DateRangeOption}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getDateRangeType = function() {
  return /** @type {!proto.loupe.common.reporting.futureforecasting.DateRangeOption} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.common.reporting.futureforecasting.DateRangeOption} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setDateRangeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated loupe.common.param.QueryParam filters = 6;
 * @return {!Array<!proto.loupe.common.param.QueryParam>}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.loupe.common.param.QueryParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_param_query_param_pb.QueryParam, 6));
};


/**
 * @param {!Array<!proto.loupe.common.param.QueryParam>} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.common.param.QueryParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.param.QueryParam}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.common.param.QueryParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional FutureForecastGlobalLevel level = 7;
 * @return {?proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getLevel = function() {
  return /** @type{?proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel, 7));
};


/**
 * @param {?proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setLevel = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.clearLevel = function() {
  return this.setLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional FutureForecastEquation equation = 8;
 * @return {?proto.loupe.common.reporting.futureforecasting.FutureForecastEquation}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getEquation = function() {
  return /** @type{?proto.loupe.common.reporting.futureforecasting.FutureForecastEquation} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastEquation, 8));
};


/**
 * @param {?proto.loupe.common.reporting.futureforecasting.FutureForecastEquation|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setEquation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.clearEquation = function() {
  return this.setEquation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.hasEquation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional loupe.common.query.Query realtime_query = 9;
 * @return {?proto.loupe.common.query.Query}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getRealtimeQuery = function() {
  return /** @type{?proto.loupe.common.query.Query} */ (
    jspb.Message.getWrapperField(this, src_common_query_query_pb.Query, 9));
};


/**
 * @param {?proto.loupe.common.query.Query|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setRealtimeQuery = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.clearRealtimeQuery = function() {
  return this.setRealtimeQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.hasRealtimeQuery = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated FutureForecastScenario scenarios = 10;
 * @return {!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario>}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getScenariosList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastScenario, 10));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario>} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setScenariosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.addScenarios = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.loupe.common.reporting.futureforecasting.FutureForecastScenario, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.clearScenariosList = function() {
  return this.setScenariosList([]);
};


/**
 * optional string save_id = 11;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getSaveId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setSaveId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional loupe.common.nullable.NullTimestamp created_at = 12;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getCreatedAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 12));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional loupe.common.nullable.NullTimestamp updated_at = 13;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getUpdatedAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 13));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string owner_id = 14;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 sort = 15;
 * @return {number}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string output_format = 16;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.getOutputFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainer.prototype.setOutputFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.RealtimeQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.RealtimeQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    level: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.RealtimeQuery}
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.RealtimeQuery;
  return proto.loupe.common.reporting.futureforecasting.RealtimeQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.RealtimeQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.RealtimeQuery}
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.reporting.futureforecasting.GlobalLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addEntityIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.RealtimeQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.RealtimeQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEntityIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional GlobalLevel level = 1;
 * @return {!proto.loupe.common.reporting.futureforecasting.GlobalLevel}
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.prototype.getLevel = function() {
  return /** @type {!proto.loupe.common.reporting.futureforecasting.GlobalLevel} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.reporting.futureforecasting.GlobalLevel} value
 * @return {!proto.loupe.common.reporting.futureforecasting.RealtimeQuery} returns this
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string entity_ids = 2;
 * @return {!Array<string>}
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.prototype.getEntityIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.reporting.futureforecasting.RealtimeQuery} returns this
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.prototype.setEntityIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.futureforecasting.RealtimeQuery} returns this
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.prototype.addEntityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.futureforecasting.RealtimeQuery} returns this
 */
proto.loupe.common.reporting.futureforecasting.RealtimeQuery.prototype.clearEntityIdsList = function() {
  return this.setEntityIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.toObject = function(includeInstance, msg) {
  var f, obj = {
    level: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel;
  return proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.reporting.futureforecasting.GlobalLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addEntityIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEntityIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional GlobalLevel level = 1;
 * @return {!proto.loupe.common.reporting.futureforecasting.GlobalLevel}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.prototype.getLevel = function() {
  return /** @type {!proto.loupe.common.reporting.futureforecasting.GlobalLevel} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.reporting.futureforecasting.GlobalLevel} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string entity_ids = 2;
 * @return {!Array<string>}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.prototype.getEntityIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.prototype.setEntityIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.prototype.addEntityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.prototype.clearEntityIdsList = function() {
  return this.setEntityIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.toObject = function(includeInstance, msg) {
  var f, obj = {
    literal: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paramsList: jspb.Message.toObjectList(msg.getParamsList(),
    proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquation}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.FutureForecastEquation;
  return proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquation}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiteral(value);
      break;
    case 2:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.deserializeBinaryFromReader);
      msg.addParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiteral();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.serializeBinaryToWriter
    );
  }
};


/**
 * optional string literal = 1;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.prototype.getLiteral = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquation} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.prototype.setLiteral = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FutureForecastEquationParam params = 2;
 * @return {!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam>}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.prototype.getParamsList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam, 2));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam>} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquation} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.prototype.setParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.prototype.addParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquation} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    rangeType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0),
    includeWholeWindow: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange;
  return proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.reporting.futureforecasting.DateRangeType} */ (reader.readEnum());
      msg.setRangeType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeWholeWindow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRangeType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getIncludeWholeWindow();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional DateRangeType range_type = 1;
 * @return {!proto.loupe.common.reporting.futureforecasting.DateRangeType}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.prototype.getRangeType = function() {
  return /** @type {!proto.loupe.common.reporting.futureforecasting.DateRangeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.reporting.futureforecasting.DateRangeType} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.prototype.setRangeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool include_whole_window = 3;
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.prototype.getIncludeWholeWindow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.prototype.setIncludeWholeWindow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    format: jspb.Message.getFieldWithDefault(msg, 2, ""),
    randomize: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    group: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    aggType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    rangeType: (f = msg.getRangeType()) && proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.toObject(includeInstance, f),
    subSampleRange: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    label: jspb.Message.getFieldWithDefault(msg, 8, ""),
    objectType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    includeInactiveEntities: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    alternateDataKey: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam;
  return proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRandomize(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGroup(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.common.reporting.futureforecasting.AggregationType} */ (reader.readEnum());
      msg.setAggType(value);
      break;
    case 6:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.deserializeBinaryFromReader);
      msg.setRangeType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubSampleRange(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectType(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeInactiveEntities(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlternateDataKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRandomize();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getGroup();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAggType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getRangeType();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange.serializeBinaryToWriter
    );
  }
  f = message.getSubSampleRange();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getObjectType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIncludeInactiveEntities();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAlternateDataKey();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string format = 2;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool randomize = 3;
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.getRandomize = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.setRandomize = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool group = 4;
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.getGroup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.setGroup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional AggregationType agg_type = 5;
 * @return {!proto.loupe.common.reporting.futureforecasting.AggregationType}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.getAggType = function() {
  return /** @type {!proto.loupe.common.reporting.futureforecasting.AggregationType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.common.reporting.futureforecasting.AggregationType} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.setAggType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional FutureForecastEquationParamEvalRange range_type = 6;
 * @return {?proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.getRangeType = function() {
  return /** @type{?proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange, 6));
};


/**
 * @param {?proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParamEvalRange|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.setRangeType = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.clearRangeType = function() {
  return this.setRangeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.hasRangeType = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool sub_sample_range = 7;
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.getSubSampleRange = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.setSubSampleRange = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string label = 8;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string object_type = 9;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.getObjectType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.setObjectType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool include_inactive_entities = 10;
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.getIncludeInactiveEntities = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.setIncludeInactiveEntities = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string alternate_data_key = 11;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.getAlternateDataKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastEquationParam.prototype.setAlternateDataKey = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    input: (f = msg.getInput()) && proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.toObject(includeInstance, f),
    output: (f = msg.getOutput()) && proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.toObject(includeInstance, f),
    baseline: (f = msg.getBaseline()) && proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.FutureForecastScenario;
  return proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.deserializeBinaryFromReader);
      msg.setInput(value);
      break;
    case 5:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.deserializeBinaryFromReader);
      msg.setOutput(value);
      break;
    case 6:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastScenario;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.deserializeBinaryFromReader);
      msg.setBaseline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getInput();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.serializeBinaryToWriter
    );
  }
  f = message.getOutput();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.serializeBinaryToWriter
    );
  }
  f = message.getBaseline();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.nullable.NullTimestamp created_at = 3;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.getCreatedAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 3));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FutureForecastContainerInput input = 4;
 * @return {?proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.getInput = function() {
  return /** @type{?proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput, 4));
};


/**
 * @param {?proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.setInput = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.clearInput = function() {
  return this.setInput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.hasInput = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FutureForecastContainerOutput output = 5;
 * @return {?proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.getOutput = function() {
  return /** @type{?proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput, 5));
};


/**
 * @param {?proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.setOutput = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.clearOutput = function() {
  return this.setOutput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.hasOutput = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FutureForecastScenario baseline = 6;
 * @return {?proto.loupe.common.reporting.futureforecasting.FutureForecastScenario}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.getBaseline = function() {
  return /** @type{?proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastScenario, 6));
};


/**
 * @param {?proto.loupe.common.reporting.futureforecasting.FutureForecastScenario|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.setBaseline = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.clearBaseline = function() {
  return this.setBaseline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.prototype.hasBaseline = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startDatetime: (f = msg.getStartDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDatetime: (f = msg.getEndDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateRangeType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    src_common_param_query_param_pb.QueryParam.toObject, includeInstance),
    level: (f = msg.getLevel()) && proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.toObject(includeInstance, f),
    equation: (f = msg.getEquation()) && proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.toObject(includeInstance, f),
    realtimeQuery: (f = msg.getRealtimeQuery()) && src_common_query_query_pb.Query.toObject(includeInstance, f),
    scenario: (f = msg.getScenario()) && proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.toObject(includeInstance, f),
    outputFormat: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer;
  return proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDatetime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDatetime(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.common.reporting.futureforecasting.DateRangeOption} */ (reader.readEnum());
      msg.setDateRangeType(value);
      break;
    case 6:
      var value = new src_common_param_query_param_pb.QueryParam;
      reader.readMessage(value,src_common_param_query_param_pb.QueryParam.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 7:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.deserializeBinaryFromReader);
      msg.setLevel(value);
      break;
    case 8:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastEquation;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.deserializeBinaryFromReader);
      msg.setEquation(value);
      break;
    case 9:
      var value = new src_common_query_query_pb.Query;
      reader.readMessage(value,src_common_query_query_pb.Query.deserializeBinaryFromReader);
      msg.setRealtimeQuery(value);
      break;
    case 10:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastScenario;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.deserializeBinaryFromReader);
      msg.setScenario(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutputFormat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartDatetime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDatetime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateRangeType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      src_common_param_query_param_pb.QueryParam.serializeBinaryToWriter
    );
  }
  f = message.getLevel();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel.serializeBinaryToWriter
    );
  }
  f = message.getEquation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastEquation.serializeBinaryToWriter
    );
  }
  f = message.getRealtimeQuery();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      src_common_query_query_pb.Query.serializeBinaryToWriter
    );
  }
  f = message.getScenario();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastScenario.serializeBinaryToWriter
    );
  }
  f = message.getOutputFormat();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp start_datetime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.getStartDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.setStartDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.clearStartDatetime = function() {
  return this.setStartDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.hasStartDatetime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp end_datetime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.getEndDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.setEndDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.clearEndDatetime = function() {
  return this.setEndDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.hasEndDatetime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DateRangeOption date_range_type = 5;
 * @return {!proto.loupe.common.reporting.futureforecasting.DateRangeOption}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.getDateRangeType = function() {
  return /** @type {!proto.loupe.common.reporting.futureforecasting.DateRangeOption} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.common.reporting.futureforecasting.DateRangeOption} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.setDateRangeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated loupe.common.param.QueryParam filters = 6;
 * @return {!Array<!proto.loupe.common.param.QueryParam>}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.loupe.common.param.QueryParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_param_query_param_pb.QueryParam, 6));
};


/**
 * @param {!Array<!proto.loupe.common.param.QueryParam>} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.common.param.QueryParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.param.QueryParam}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.common.param.QueryParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional FutureForecastGlobalLevel level = 7;
 * @return {?proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.getLevel = function() {
  return /** @type{?proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel, 7));
};


/**
 * @param {?proto.loupe.common.reporting.futureforecasting.FutureForecastGlobalLevel|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.setLevel = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.clearLevel = function() {
  return this.setLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional FutureForecastEquation equation = 8;
 * @return {?proto.loupe.common.reporting.futureforecasting.FutureForecastEquation}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.getEquation = function() {
  return /** @type{?proto.loupe.common.reporting.futureforecasting.FutureForecastEquation} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastEquation, 8));
};


/**
 * @param {?proto.loupe.common.reporting.futureforecasting.FutureForecastEquation|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.setEquation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.clearEquation = function() {
  return this.setEquation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.hasEquation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional loupe.common.query.Query realtime_query = 9;
 * @return {?proto.loupe.common.query.Query}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.getRealtimeQuery = function() {
  return /** @type{?proto.loupe.common.query.Query} */ (
    jspb.Message.getWrapperField(this, src_common_query_query_pb.Query, 9));
};


/**
 * @param {?proto.loupe.common.query.Query|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.setRealtimeQuery = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.clearRealtimeQuery = function() {
  return this.setRealtimeQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.hasRealtimeQuery = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional FutureForecastScenario scenario = 10;
 * @return {?proto.loupe.common.reporting.futureforecasting.FutureForecastScenario}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.getScenario = function() {
  return /** @type{?proto.loupe.common.reporting.futureforecasting.FutureForecastScenario} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastScenario, 10));
};


/**
 * @param {?proto.loupe.common.reporting.futureforecasting.FutureForecastScenario|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.setScenario = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.clearScenario = function() {
  return this.setScenario(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.hasScenario = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string output_format = 11;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.getOutputFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastRunContainer.prototype.setOutputFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseline: (f = msg.getBaseline()) && proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.toObject(includeInstance, f),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput;
  return proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.deserializeBinaryFromReader);
      msg.setBaseline(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 3:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseline();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.serializeBinaryToWriter
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional FutureForecastContainerInput baseline = 1;
 * @return {?proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.prototype.getBaseline = function() {
  return /** @type{?proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput, 1));
};


/**
 * @param {?proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput|undefined} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.prototype.setBaseline = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.prototype.clearBaseline = function() {
  return this.setBaseline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.prototype.hasBaseline = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_active = 2;
 * @return {boolean}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated FutureForecastInputCategory categories = 3;
 * @return {!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory>}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory, 3));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory>} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerInput.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory;
  return proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastInputField;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FutureForecastInputField fields = 2;
 * @return {!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField>}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastInputField, 2));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField>} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.reporting.futureforecasting.FutureForecastInputField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputCategory.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    deviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    sort: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.FutureForecastInputField;
  return proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeviation(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double value = 3;
 * @return {number}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double deviation = 4;
 * @return {number}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.prototype.getDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.prototype.setDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int64 sort = 5;
 * @return {number}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastInputField} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastInputField.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    numericValuesList: jspb.Message.toObjectList(msg.getNumericValuesList(),
    proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.toObject, includeInstance),
    descriptionMapMap: (f = msg.getDescriptionMapMap()) ? f.toObject(includeInstance, undefined) : [],
    format: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput;
  return proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult;
      reader.readMessage(value,proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.deserializeBinaryFromReader);
      msg.addNumericValues(value);
      break;
    case 2:
      var value = msg.getDescriptionMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumericValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.serializeBinaryToWriter
    );
  }
  f = message.getDescriptionMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated FutureForecastNumericResult numeric_values = 1;
 * @return {!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult>}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.prototype.getNumericValuesList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult, 1));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult>} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput} returns this
*/
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.prototype.setNumericValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.prototype.addNumericValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.prototype.clearNumericValuesList = function() {
  return this.setNumericValuesList([]);
};


/**
 * map<string, string> description_map = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.prototype.getDescriptionMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.prototype.clearDescriptionMapMap = function() {
  this.getDescriptionMapMap().clear();
  return this;};


/**
 * optional string format = 3;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastContainerOutput.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    count: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    callout: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult;
  return proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCallout();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double count = 2;
 * @return {number}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.prototype.setCount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string callout = 3;
 * @return {string}
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.prototype.getCallout = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult} returns this
 */
proto.loupe.common.reporting.futureforecasting.FutureForecastNumericResult.prototype.setCallout = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.reporting.futureforecasting.DateRangeOption = {
  CUSTOM: 0,
  CURRENT_WEEK: 1,
  NEXT_WEEK: 2,
  CURRENT_MONTH: 3,
  NEXT_MONTH: 4,
  CURRENT_QUARTER: 5,
  NEXT_QUARTER: 6
};

/**
 * @enum {number}
 */
proto.loupe.common.reporting.futureforecasting.GlobalLevel = {
  COMPANY: 0,
  GROUP: 1,
  PERSON: 2
};

/**
 * @enum {number}
 */
proto.loupe.common.reporting.futureforecasting.AggregationType = {
  SUM: 0,
  AVERAGE: 1,
  GEOMETRIC_MEAN: 2
};

/**
 * @enum {number}
 */
proto.loupe.common.reporting.futureforecasting.DateRangeType = {
  NONE: 0,
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
  PERIOD: 4,
  YEAR: 5
};

goog.object.extend(exports, proto.loupe.common.reporting.futureforecasting);
