import React from 'react'

const UploadRefreshCheck = (props) => {
  const {
    upload = false,
    refresh = false,
    check = false,
  } = props

  return (
    <svg width="181" height="42" viewBox="0 0 181 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="38" y1="20" x2="141" y2="20" stroke="#5951FF" strokeWidth="2" />

      <rect x="1" y="1" width="40" height="40" rx="20" fill={upload ? 'url(#paint1_linear)' : 'white'} stroke="url(#paint0_linear)" strokeWidth="2" />
      <path d="M18.6019 26.1085H23.0835C23.6997 26.1085 24.2039 25.6043 24.2039 24.9881V19.3861H25.9853C26.9824 19.3861 27.4866 18.1761 26.7807 17.4703L21.6382 12.3277C21.2012 11.8908 20.4954 11.8908 20.0584 12.3277L14.9159 17.4703C14.21 18.1761 14.703 19.3861 15.7001 19.3861H17.4815V24.9881C17.4815 25.6043 17.9857 26.1085 18.6019 26.1085ZM14.1204 28.3492H27.565C28.1812 28.3492 28.6854 28.8534 28.6854 29.4696C28.6854 30.0858 28.1812 30.59 27.565 30.59H14.1204C13.5042 30.59 13 30.0858 13 29.4696C13 28.8534 13.5042 28.3492 14.1204 28.3492Z" fill={upload ? 'white' : 'url(#paint1_linear)'} />

      <rect x="72" y="2" width="38" height="38" rx="19" fill={refresh ? 'url(#paint1_linear)' : 'white'} stroke="url(#paint0_linear)" strokeWidth="2" />
      <path d="M90.7761 12.2224V10.2587C90.7761 9.76503 90.1838 9.52369 89.8437 9.87473L86.772 12.9354C86.5526 13.1548 86.5526 13.4949 86.772 13.7143L89.8327 16.775C90.1838 17.1151 90.7761 16.8737 90.7761 16.38V14.4164C94.4073 14.4164 97.3583 17.3674 97.3583 20.9985C97.3583 21.8651 97.1937 22.7098 96.8756 23.4668C96.711 23.8617 96.8317 24.3115 97.1279 24.6077C97.6874 25.1672 98.6308 24.9697 98.927 24.2347C99.3329 23.2364 99.5523 22.1394 99.5523 20.9985C99.5523 16.1497 95.625 12.2224 90.7761 12.2224ZM90.7761 27.5806C87.145 27.5806 84.194 24.6296 84.194 20.9985C84.194 20.1319 84.3586 19.2871 84.6767 18.5302C84.8413 18.1353 84.7206 17.6855 84.4244 17.3893C83.8649 16.8298 82.9215 17.0273 82.6253 17.7623C82.2194 18.7606 82 19.8576 82 20.9985C82 25.8473 85.9273 29.7746 90.7761 29.7746V31.7383C90.7761 32.232 91.3685 32.4733 91.7086 32.1223L94.7693 29.0616C94.9887 28.8422 94.9887 28.5021 94.7693 28.2827L91.7086 25.222C91.3685 24.8819 90.7761 25.1233 90.7761 25.6169V27.5806Z" fill={refresh ? 'white' : 'url(#paint1_linear)'} />

      <rect x="142" y="2" width="38" height="38" rx="19" fill={check ? 'url(#paint2_linear)' : 'white'} stroke="url(#paint0_linear)" strokeWidth="2" />
      <path d="M159.739 28.9838C160.137 28.9124 160.501 28.686 160.735 28.3525L169.724 15.3923C170.204 14.7014 170.04 13.7485 169.372 13.2601C168.716 12.7955 167.731 12.9742 167.274 13.6294L159.165 25.3031L154.407 21.6818C154.137 21.4793 153.832 21.3721 153.504 21.3721C153.024 21.3721 152.59 21.5984 152.309 21.9796C152.063 22.3132 151.957 22.7063 152.016 23.1113C152.074 23.5163 152.285 23.8736 152.602 24.1238L158.602 28.698C158.918 28.9362 159.34 29.0434 159.739 28.9838Z" fill={check ? 'white' : 'url(#paint1_linear)'} />

      <defs>
        <linearGradient id="paint0_linear" x1="91" y1="1" x2="91" y2="41" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5951FF" />
          <stop offset="1" stopColor="#5951FF" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="90.7761" y1="9.7085" x2="90.7761" y2="32.2885" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5951FF" />
          <stop offset="1" stopColor="#5951FF" />
        </linearGradient>
        <linearGradient id="paint2_linear" x1="160" y1="0" x2="160" y2="40" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5951FF" />
          <stop offset="1" stopColor="#5951FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default UploadRefreshCheck
