import { useCallback } from 'react'
import { useTenantInfo } from '../context/tenantInfo'

function getLanguage() {
  // if (navigator.languages !== undefined) {
  //   return navigator.languages[0]
  // }
  // return navigator.language

  // until we support multiple languages, just return en-US
  return 'en-US'
}

export function useFormatCurrency(args = {}) {
  const { shortCurrency = false } = args
  const { tenantInfo } = useTenantInfo()

  const formatCurrency = useCallback((value) => {
    const { currencyCode = 'USD' } = tenantInfo
    const style = (currencyCode === 'XXX') ? 'decimal' : 'currency'
    const formatter = new Intl.NumberFormat(getLanguage(), {
      style,
      // currencyDisplay: 'narrowSymbol',
      currencyDisplay: 'symbol',
      currency: currencyCode,
      ...!shortCurrency && {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      },
      ...shortCurrency && {
        notation: 'compact',
        compactDisplay: 'short',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }
    })
    return formatter.format(currencyCode === 'USD' ? Math.floor(value) : value)
  }, [tenantInfo])

  return {
    formatCurrency
  }
}
