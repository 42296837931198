import Button from '../common/button'
import classNames from 'classnames'
import Modal from '../common/modal'
import ModalBody from '../common/modalBody'
import ModalError from '../common/modalError'
import ModalFooter from '../common/modalFooter'
import ModalHeader from '../common/modalHeader'
import React, { useCallback, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import CustomDragLayer from '../dragAndDrop/customDragLayer'
import { useForecastingConfigs } from '../../context/forecastingConfigs'
import ForecastSortItem, { dragRenderer } from './forecastSortItem'

const ForecastSortModal = (props) => {
  const {
    modal,
  } = props

  const { open, handleClose } = modal

  const { activeConfigs, setConfigSortOrder } = useForecastingConfigs()

  const [showError, setShowError] = useState(false)
  const [configIds, setConfigIds] = useState([])
  const [sortedConfigs, setSortedConfigs] = useState([])

  useEffect(() => {
    setSortedConfigs(activeConfigs)
  }, [activeConfigs])

  const onMove = useCallback((dragIndex, hoverIndex) => {
    const dragItem = sortedConfigs[dragIndex]
    const hoverItem = sortedConfigs[hoverIndex]
    setSortedConfigs((configs) => {
      const updatedConfigsList = [...configs]
      updatedConfigsList[dragIndex] = hoverItem
      updatedConfigsList[hoverIndex] = dragItem
      return updatedConfigsList.map((config, index) => ({ ...config, sort: index }))
    })
  }, [sortedConfigs])

  useEffect(() => {
    const configIds = sortedConfigs.map((config) => { return config.id })
    setConfigIds(configIds)
  }, [sortedConfigs])

  const onSort = useCallback(() => {
    setConfigSortOrder(configIds)
  }, [configIds, setConfigSortOrder])

  const handleCloseInternal = useCallback(() => {
    handleClose()
  }, [handleClose])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="md"
      open={open}>

      <ModalHeader
        title="Reorder Forecasts"
        subtitle="Determine the order in which your active forecasts will appear in Commit."
        onClose={handleCloseInternal} />

      <ModalBody>
        {showError && <ModalError text="Unable to sort forecasts." />}
        <div className="mx-4 p-3 relative w-2/3 items-center">
          <DndProvider backend={HTML5Backend}>
            <CustomDragLayer itemRenderer={dragRenderer} data={sortedConfigs} />
            {sortedConfigs.map((config, index) => {
              return (
                <ForecastSortItem
                  key={`ForecastSortItem-${config?.id}`}
                  config={config}
                  id={config.id}
                  index={index}
                  name={config.name}
                  onMove={onMove} />
              )
            })}
          </DndProvider>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          size="xs"
          text="Save"
          onClick={onSort}
        />
      </ModalFooter>

    </Modal>
  )
}

export default ForecastSortModal
