import { ForecastConfigStatus } from '../grpc/enums'
import { guid } from '../lib/guid'
import {
  toAssignForecastConfigToPivotGroupsRequest,
  toGetForecastConfigRequest,
  toListForecastConfigColumnsRequest,
  toPublishForecastConfigDraftRequest,
  toReorderForecastConfigColumnsRequest,
  toRemoveForecastConfigFromPivotGroupsRequest,
  toSaveForecastConfigColumnRequest,
  toSaveForecastConfigRequest,
  toSetForecastConfigStatusRequest
} from '../grpc/converters'
import { useActor } from '../hooks/useActor'
import { useRoutes } from './routes'
import { useGrpcCallback, useGrpcAll } from '../grpc'
import { useNotification } from '../hooks/useNotification'
import { useParams, useHistory } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { isEqual, cloneDeep, sortBy } from 'lodash'

const ForecastConfig = React.createContext()

export function ForecastingConfigProvider({ children }) {
  const { actor } = useActor()
  const { routes } = useRoutes()
  const history = useHistory()

  const { forecastId: id } = useParams()

  const { notifyError, notifySuccess } = useNotification()

  const [isFetching, setIsFetching] = useState(true)
  const [isAddingCategory, setIsAddingCategory] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [key, setKey] = useState(guid())
  const [config, setConfig] = useState()
  const [configColumns, setConfigColumns] = useState([])
  const [unsavedPivotGroups, setUnsavedPivotGroups] = useState(new Map())
  const [lastSaved, setLastSaved] = useState({})
  const [lastSavedConfigColumns, setLastSavedConfigColumns] = useState([])
  const [lastSavedPivotGroups, setLastSavedPivotGroups] = useState(new Map())
  const [didSave, setDidSave] = useState(false)
  const [lastSavedAt, setLastSavedAt] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const isEnabled = useMemo(() => {
    return config?.status === ForecastConfigStatus.ENABLED
  }, [config?.status])

  const configColumnOrderChanged = useMemo(() => {
    const columnIds = sortBy(configColumns, ['sort']).map(({ id }) => id)
    const lastSavedConfigColumnIds = sortBy(lastSavedConfigColumns, ['sort']).map(({ id }) => id)
    return !isEqual(columnIds, lastSavedConfigColumnIds)
  }, [configColumns, lastSavedConfigColumns])

  const hasUnsavedChanges = useMemo(() => {
    return configColumnOrderChanged || !isEqual(lastSaved, config) || !isEqual([...lastSavedPivotGroups?.keys()], [...unsavedPivotGroups?.keys()])
  }, [configColumnOrderChanged, lastSaved, config, lastSavedPivotGroups, unsavedPivotGroups])

  const lastSavedAtRef = useRef(new Date())
  useEffect(() => {
    if (lastSavedAt > lastSavedAtRef.current) {
      setDidSave(true)

      // Flip this back after a couple seconds
      setTimeout(() => {
        setDidSave(false)
      }, [1500])
    }
  }, [lastSavedAt])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const resetPivotGroups = useCallback((pivotGroupsList = []) => {
    const pivotGroups = new Map(pivotGroupsList.map((pg) => ([pg?.groupId, pg])))
    setLastSavedPivotGroups(pivotGroups)
    setUnsavedPivotGroups(pivotGroups)
  }, [])

  const listForecastConfigColumnsRequest = useGrpcCallback({
    onError: () => {
      setIsFetching(false)
      setIsAddingCategory(false)
      setFetchError(true)
      notifyError('Error Loading Forecasting Config!')
    },
    onSuccess: (obj) => {
      const result = obj.configColumnsList.filter(({ status }) => status === ForecastConfigStatus.ENABLED)
      setConfigColumns(result)
      setLastSavedConfigColumns(result)
      setIsFetching(false)
      setFetchError(false)
      setIsAddingCategory(false)
    },
    grpcMethod: 'listForecastConfigColumns',
    debug: false
  }, [])

  const getForecastConfigColumns = useCallback((configId) => {
    const request = toListForecastConfigColumnsRequest({
      actor,
      forecastConfigId: configId
    })
    listForecastConfigColumnsRequest(request)
  }, [actor, listForecastConfigColumnsRequest])

  const getConfigCallback = useGrpcCallback({
    onError: () => {
      setIsFetching(false)
      setFetchError(true)
      notifyError('Error Loading Forecasting Config!')
    },
    onSuccess: (obj) => {
      setConfig(obj)
      setLastSaved(cloneDeep(obj))
      resetPivotGroups(obj.pivotGroupsList)
      getForecastConfigColumns(obj.id)
    },
    onFetch: () => {
      setIsFetching(true)
      setFetchError(false)
    },
    grpcMethod: 'getForecastConfig',
    debug: false
  }, [getForecastConfigColumns, resetPivotGroups])

  useEffect(() => {
    if (!actor) {
      return
    }

    const request = toGetForecastConfigRequest({
      actor,
      forecastConfigId: id
    })
    getConfigCallback(request)
  }, [id, key, actor, getConfigCallback])

  const reorderConfigColumnsRequest = useGrpcCallback({
    onError: () => {
      setIsFetching(false)
      setFetchError(true)
      notifyError('Error Saving Column Sort Order!')
    },
    onSuccess: (obj) => {
      setLastSavedConfigColumns(cloneDeep(configColumns))
      setIsFetching(false)
      setFetchError(false)
      getForecastConfigColumns(config.id)
    },
    onFetch: () => {
      setIsFetching(true)
      setFetchError(false)
    },
    grpcMethod: 'reorderForecastConfigColumns',
    debug: false
  }, [key, configColumns, getForecastConfigColumns, config])

  const reorderConfigColumns = useCallback((sortedColumnList) => {
    if (!sortedColumnList?.length) {
      return
    }
    const itemsList = sortedColumnList.map(({ id, forecastConfigId }) => ({
      forecastConfigId,
      forecastConfigColumnId: id
    }))
    const request = toReorderForecastConfigColumnsRequest({
      actor,
      itemsList
    })
    reorderConfigColumnsRequest(request)
  }, [reorderConfigColumnsRequest, actor])

  const pivotGroupRequests = useMemo(() => {
    if (!config) {
      return []
    }
    const { pivotGroupsList, id: forecastConfigId } = config
    const orig = pivotGroupsList.map(({ groupId }) => groupId)
    const unsaved = [...unsavedPivotGroups.keys()]
    const added = unsaved.filter((v) => !orig.includes(v))
    const removed = orig.filter((v) => !unsaved.includes(v))

    const requests = []

    if (added.length) {
      requests.push({
        grpcMethod: 'assignForecastConfigToPivotGroups',
        request: toAssignForecastConfigToPivotGroupsRequest({
          actor,
          groupsList: added.map((groupId) => ({ forecastConfigId, groupId }))
        })
      })
    }

    if (removed.length) {
      requests.push({
        grpcMethod: 'removeForecastConfigFromPivotGroup',
        request: toRemoveForecastConfigFromPivotGroupsRequest({
          actor,
          forecastConfigId,
          groupIdsList: removed
        })
      })
    }

    return requests
  }, [actor, config, unsavedPivotGroups])

  const [upsertPivotGroupsRequest] = useGrpcAll({
    attemptAll: true,
    onSuccess: (res) => {
      const [_, saveConfigRes] = res
      setIsSaving(false)
      setConfig(saveConfigRes?.responseObject)
      setLastSaved(cloneDeep(saveConfigRes?.responseObject))
      setLastSavedPivotGroups(unsavedPivotGroups)
      setLastSavedAt(new Date())
    },
    onFetch: () => {
      setFetchError(false)
      setIsSaving(true)
    },
    onError: (err) => {
      notifyError('Unable to save config')
      setFetchError(true)
      setIsSaving(false)
    },
  }, [unsavedPivotGroups])

  const saveConfigAndPivotGroups = useCallback((_config) => {
    const forecastConfig = {
      ...config,
      ..._config
    }

    upsertPivotGroupsRequest([
      pivotGroupRequests,
      {
        grpcMethod: 'saveForecastConfig',
        request: toSaveForecastConfigRequest({
          actor,
          forecastConfig
        })
      }
    ], forecastConfig)
  }, [config, upsertPivotGroupsRequest, pivotGroupRequests, actor])

  const updateConfig = useCallback((property, value) => {
    setConfig({
      ...config,
      [property]: value
    })
  }, [config])

  const setPivotGroups = useCallback((pivotGroups) => {
    setUnsavedPivotGroups(new Map(pivotGroups))
  }, [])

  const createConfigColumnRequest = useGrpcCallback({
    onError: () => {
      setIsFetching(false)
      notifyError('Error Creating Column Settings!')
    },
    onSuccess: (obj) => {
      getForecastConfigColumns(config.id)
    },
    grpcMethod: 'saveForecastConfigColumn',
    debug: false
  }, [key, getForecastConfigColumns, config])

  const createConfigColumn = useCallback((forecastConfigColumn) => {
    setIsAddingCategory(true)
    const request = toSaveForecastConfigColumnRequest({
      actor,
      forecastConfigColumn
    })
    createConfigColumnRequest(request)
  }, [createConfigColumnRequest, actor])

  const saveConfigColumnRequest = useGrpcCallback({
    onError: () => {
      setIsFetching(false)
      notifyError('Error Saving Column Settings!')
    },
    onSuccess: (obj) => {
      setIsFetching(false)
      setFetchError(false)
    },
    grpcMethod: 'saveForecastConfigColumn',
    debug: false
  }, [key])

  const saveConfigColumn = useCallback((forecastConfigColumn) => {
    const request = toSaveForecastConfigColumnRequest({
      actor,
      forecastConfigColumn
    })
    saveConfigColumnRequest(request)
  }, [saveConfigColumnRequest, actor])

  const deleteConfigColumnRequest = useGrpcCallback({
    onError: () => {
      setIsFetching(false)
      setFetchError(true)
      notifyError('Error Deleting Category!')
    },
    onSuccess: (obj) => {
      invalidate()
    },
    onFetch: () => {
      setIsFetching(true)
      setFetchError(false)
    },
    grpcMethod: 'saveForecastConfigColumn',
    debug: false
  }, [invalidate])

  const deleteConfigColumn = useCallback((configColumn) => {
    const request = toSaveForecastConfigColumnRequest({
      actor,
      forecastConfigColumn: {
        ...configColumn,
        status: ForecastConfigStatus.DELETED
      }
    })
    deleteConfigColumnRequest(request)
  }, [deleteConfigColumnRequest, actor])

  const [publishConfigRequest] = useGrpcAll({
    onSuccess: () => {
      notifySuccess('Changes Published!')
      invalidate()
    },
    onError: () => {
      setIsFetching(false)
      setFetchError(true)
      notifyError('Error Publishing Config!')
    }
  }, [invalidate])

  const publishConfig = useCallback((config) => {
    let request = [{
      request: toPublishForecastConfigDraftRequest({
        actor,
        forecastConfig: {
          ...config,
          id: config.draftParentId || config.id,
          draftParentId: null
        }
      }),
      grpcMethod: 'publishForecastConfigDraft'
    }]

    if (config.status !== ForecastConfigStatus.ENABLED) {
      request = [{
        grpcMethod: 'saveForecastConfig',
        request: toSaveForecastConfigRequest({
          actor,
          forecastConfig: {
            ...config,
            sort: 999
          }
        })
      },
      ...request
      ]
    }
    publishConfigRequest(request)
  }, [actor, publishConfigRequest])

  const setConfigStatusRequest = useGrpcCallback({
    onError: (err) => {
      notifyError('Error Updating Status!')
      setIsFetching(true)
    },
    onSuccess: (data) => {
      notifySuccess('Forecast Deleted!')
      history.push(routes.forecasts)
      invalidate()
    },
    grpcMethod: 'setForecastConfigStatus',
    debug: false
  }, [invalidate, history])

  const setConfigStatus = useCallback((obj) => {
    const request = toSetForecastConfigStatusRequest({
      actor,
      ...obj
    })
    setConfigStatusRequest(request)
  }, [setConfigStatusRequest, actor])

  const contextValue = useMemo(() => {
    return {
      config,
      configColumns,
      didSave,
      isSaving,
      reorderConfigColumns,
      createConfigColumn,
      setConfig,
      setConfigColumns,
      setConfigStatus,
      saveConfigAndPivotGroups,
      updateConfig,
      isAddingCategory,
      hasUnsavedChanges,
      fetchError,
      invalidate,
      isEnabled,
      isFetching,
      publishConfig,
      setPivotGroups,
      resetPivotGroups,
      deleteConfigColumn,
      saveConfigColumn,
      key,
    }
  }, [
    config,
    configColumns,
    didSave,
    isSaving,
    reorderConfigColumns,
    createConfigColumn,
    setConfig,
    setConfigColumns,
    setConfigStatus,
    saveConfigAndPivotGroups,
    updateConfig,
    isAddingCategory,
    hasUnsavedChanges,
    fetchError,
    invalidate,
    isEnabled,
    isFetching,
    publishConfig,
    setPivotGroups,
    resetPivotGroups,
    deleteConfigColumn,
    saveConfigColumn,
    key,
  ])

  return <ForecastConfig.Provider value={contextValue}>{children}</ForecastConfig.Provider>
}

export function useForecastingConfig() {
  const context = React.useContext(ForecastConfig)
  if (context === undefined) {
    throw new Error('useForecastingConfig must be used within a ForecastingConfigProvider')
  }
  return context
}
