// source: src/services/forecast/v2/forecast.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_forecast_v2_forecast_pb = require('../../../../src/common/forecast/v2/forecast_pb.js');
goog.object.extend(proto, src_common_forecast_v2_forecast_pb);
var src_common_opportunity_opportunity_pb = require('../../../../src/common/opportunity/opportunity_pb.js');
goog.object.extend(proto, src_common_opportunity_opportunity_pb);
var src_common_nullable_nullable_pb = require('../../../../src/common/nullable/nullable_pb.js');
goog.object.extend(proto, src_common_nullable_nullable_pb);
var src_common_filter_filter_pb = require('../../../../src/common/filter/filter_pb.js');
goog.object.extend(proto, src_common_filter_filter_pb);
goog.exportSymbol('proto.loupe.services.forecast.v2.CategoryRollupMode', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.DefaultForecastRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.ForecastDiffRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.ForecastFilter', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.ForecastListResponse', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.ForecastOwnerRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.ForecastRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.ForecastSort', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.ForecastTeamSettings', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.ForecastWithDiffResponse', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.FullForecastInfo', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.GetDefaultForecastList', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.QuotaInfo', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.RehydrateForecastsRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.RehydrateForecastsResponse', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.SaveForecastRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.SearchForecastsRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.SearchForecastsResponse', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.SetCategoryCallsRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.SetTotalCallRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.SortDir', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.TeamInfo', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.displayName = 'proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.displayName = 'proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v2.DefaultForecastRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v2.DefaultForecastRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.DefaultForecastRequest.displayName = 'proto.loupe.services.forecast.v2.DefaultForecastRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.FullForecastInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v2.FullForecastInfo.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v2.FullForecastInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.FullForecastInfo.displayName = 'proto.loupe.services.forecast.v2.FullForecastInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.TeamInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v2.TeamInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.TeamInfo.displayName = 'proto.loupe.services.forecast.v2.TeamInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.QuotaInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v2.QuotaInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.QuotaInfo.displayName = 'proto.loupe.services.forecast.v2.QuotaInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.ForecastRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v2.ForecastRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.ForecastRequest.displayName = 'proto.loupe.services.forecast.v2.ForecastRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.ForecastOwnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v2.ForecastOwnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.ForecastOwnerRequest.displayName = 'proto.loupe.services.forecast.v2.ForecastOwnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v2.ForecastDiffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.ForecastDiffRequest.displayName = 'proto.loupe.services.forecast.v2.ForecastDiffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v2.ForecastWithDiffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.ForecastWithDiffResponse.displayName = 'proto.loupe.services.forecast.v2.ForecastWithDiffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v2.GetDefaultForecastList.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v2.GetDefaultForecastList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.GetDefaultForecastList.displayName = 'proto.loupe.services.forecast.v2.GetDefaultForecastList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.ForecastListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v2.ForecastListResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v2.ForecastListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.ForecastListResponse.displayName = 'proto.loupe.services.forecast.v2.ForecastListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.displayName = 'proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v2.SetCategoryCallsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.SetCategoryCallsRequest.displayName = 'proto.loupe.services.forecast.v2.SetCategoryCallsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v2.SetTotalCallRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.SetTotalCallRequest.displayName = 'proto.loupe.services.forecast.v2.SetTotalCallRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.SaveForecastRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v2.SaveForecastRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v2.SaveForecastRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.SaveForecastRequest.displayName = 'proto.loupe.services.forecast.v2.SaveForecastRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.RehydrateForecastsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v2.RehydrateForecastsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.RehydrateForecastsRequest.displayName = 'proto.loupe.services.forecast.v2.RehydrateForecastsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.RehydrateForecastsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v2.RehydrateForecastsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.RehydrateForecastsResponse.displayName = 'proto.loupe.services.forecast.v2.RehydrateForecastsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v2.SearchForecastsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v2.SearchForecastsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.SearchForecastsRequest.displayName = 'proto.loupe.services.forecast.v2.SearchForecastsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.ForecastFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v2.ForecastFilter.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v2.ForecastFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.ForecastFilter.displayName = 'proto.loupe.services.forecast.v2.ForecastFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.ForecastSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v2.ForecastSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.ForecastSort.displayName = 'proto.loupe.services.forecast.v2.ForecastSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v2.SearchForecastsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v2.SearchForecastsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.SearchForecastsResponse.displayName = 'proto.loupe.services.forecast.v2.SearchForecastsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v2.ForecastTeamSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v2.ForecastTeamSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v2.ForecastTeamSettings.displayName = 'proto.loupe.services.forecast.v2.ForecastTeamSettings';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    acceptedCategoriesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest;
  return proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.forecast.v2.ForecastMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAcceptedCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAcceptedCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.forecast.v2.ForecastMode mode = 3;
 * @return {!proto.loupe.common.forecast.v2.ForecastMode}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.prototype.getMode = function() {
  return /** @type {!proto.loupe.common.forecast.v2.ForecastMode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.forecast.v2.ForecastMode} value
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string accepted_categories = 4;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.prototype.getAcceptedCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.prototype.setAcceptedCategoriesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.prototype.addAcceptedCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest.prototype.clearAcceptedCategoriesList = function() {
  return this.setAcceptedCategoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse;
  return proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse} returns this
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse} returns this
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse} returns this
 */
proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.repeatedFields_ = [4,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.DefaultForecastRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.DefaultForecastRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    acceptedCategoriesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    categoryRollupsMap: (f = msg.getCategoryRollupsMap()) ? f.toObject(includeInstance, undefined) : [],
    self: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isPerson: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    teamSettings: (f = msg.getTeamSettings()) && proto.loupe.services.forecast.v2.ForecastTeamSettings.toObject(includeInstance, f),
    viewableGroupsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.DefaultForecastRequest;
  return proto.loupe.services.forecast.v2.DefaultForecastRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.DefaultForecastRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.forecast.v2.ForecastMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAcceptedCategories(value);
      break;
    case 5:
      var value = msg.getCategoryRollupsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readEnum, null, "", 0);
         });
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelf(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPerson(value);
      break;
    case 8:
      var value = new proto.loupe.services.forecast.v2.ForecastTeamSettings;
      reader.readMessage(value,proto.loupe.services.forecast.v2.ForecastTeamSettings.deserializeBinaryFromReader);
      msg.setTeamSettings(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addViewableGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.DefaultForecastRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.DefaultForecastRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAcceptedCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getCategoryRollupsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeEnum);
  }
  f = message.getSelf();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsPerson();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTeamSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.loupe.services.forecast.v2.ForecastTeamSettings.serializeBinaryToWriter
    );
  }
  f = message.getViewableGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.forecast.v2.ForecastMode mode = 3;
 * @return {!proto.loupe.common.forecast.v2.ForecastMode}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.getMode = function() {
  return /** @type {!proto.loupe.common.forecast.v2.ForecastMode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.forecast.v2.ForecastMode} value
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string accepted_categories = 4;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.getAcceptedCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.setAcceptedCategoriesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.addAcceptedCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.clearAcceptedCategoriesList = function() {
  return this.setAcceptedCategoriesList([]);
};


/**
 * map<string, CategoryRollupMode> category_rollups = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.services.forecast.v2.CategoryRollupMode>}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.getCategoryRollupsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.services.forecast.v2.CategoryRollupMode>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.clearCategoryRollupsMap = function() {
  this.getCategoryRollupsMap().clear();
  return this;};


/**
 * optional bool self = 6;
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.getSelf = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.setSelf = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_person = 7;
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.getIsPerson = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.setIsPerson = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional ForecastTeamSettings team_settings = 8;
 * @return {?proto.loupe.services.forecast.v2.ForecastTeamSettings}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.getTeamSettings = function() {
  return /** @type{?proto.loupe.services.forecast.v2.ForecastTeamSettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v2.ForecastTeamSettings, 8));
};


/**
 * @param {?proto.loupe.services.forecast.v2.ForecastTeamSettings|undefined} value
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
*/
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.setTeamSettings = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.clearTeamSettings = function() {
  return this.setTeamSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.hasTeamSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string viewable_groups = 9;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.getViewableGroupsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.setViewableGroupsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.addViewableGroups = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.DefaultForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.DefaultForecastRequest.prototype.clearViewableGroupsList = function() {
  return this.setViewableGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v2.FullForecastInfo.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.FullForecastInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.FullForecastInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.FullForecastInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    current: (f = msg.getCurrent()) && src_common_forecast_v2_forecast_pb.Forecast.toObject(includeInstance, f),
    previous: (f = msg.getPrevious()) && src_common_forecast_v2_forecast_pb.Forecast.toObject(includeInstance, f),
    diff: (f = msg.getDiff()) && src_common_forecast_v2_forecast_pb.ForecastDiff.toObject(includeInstance, f),
    opportunitiesList: jspb.Message.toObjectList(msg.getOpportunitiesList(),
    src_common_opportunity_opportunity_pb.Opportunity.toObject, includeInstance),
    quota: (f = msg.getQuota()) && proto.loupe.services.forecast.v2.QuotaInfo.toObject(includeInstance, f),
    teamDataList: jspb.Message.toObjectList(msg.getTeamDataList(),
    proto.loupe.services.forecast.v2.TeamInfo.toObject, includeInstance),
    ownerType: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.FullForecastInfo;
  return proto.loupe.services.forecast.v2.FullForecastInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.FullForecastInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_forecast_v2_forecast_pb.Forecast;
      reader.readMessage(value,src_common_forecast_v2_forecast_pb.Forecast.deserializeBinaryFromReader);
      msg.setCurrent(value);
      break;
    case 2:
      var value = new src_common_forecast_v2_forecast_pb.Forecast;
      reader.readMessage(value,src_common_forecast_v2_forecast_pb.Forecast.deserializeBinaryFromReader);
      msg.setPrevious(value);
      break;
    case 3:
      var value = new src_common_forecast_v2_forecast_pb.ForecastDiff;
      reader.readMessage(value,src_common_forecast_v2_forecast_pb.ForecastDiff.deserializeBinaryFromReader);
      msg.setDiff(value);
      break;
    case 4:
      var value = new src_common_opportunity_opportunity_pb.Opportunity;
      reader.readMessage(value,src_common_opportunity_opportunity_pb.Opportunity.deserializeBinaryFromReader);
      msg.addOpportunities(value);
      break;
    case 5:
      var value = new proto.loupe.services.forecast.v2.QuotaInfo;
      reader.readMessage(value,proto.loupe.services.forecast.v2.QuotaInfo.deserializeBinaryFromReader);
      msg.setQuota(value);
      break;
    case 6:
      var value = new proto.loupe.services.forecast.v2.TeamInfo;
      reader.readMessage(value,proto.loupe.services.forecast.v2.TeamInfo.deserializeBinaryFromReader);
      msg.addTeamData(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.FullForecastInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.FullForecastInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.FullForecastInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_forecast_v2_forecast_pb.Forecast.serializeBinaryToWriter
    );
  }
  f = message.getPrevious();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_forecast_v2_forecast_pb.Forecast.serializeBinaryToWriter
    );
  }
  f = message.getDiff();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_forecast_v2_forecast_pb.ForecastDiff.serializeBinaryToWriter
    );
  }
  f = message.getOpportunitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      src_common_opportunity_opportunity_pb.Opportunity.serializeBinaryToWriter
    );
  }
  f = message.getQuota();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.loupe.services.forecast.v2.QuotaInfo.serializeBinaryToWriter
    );
  }
  f = message.getTeamDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.loupe.services.forecast.v2.TeamInfo.serializeBinaryToWriter
    );
  }
  f = message.getOwnerType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional loupe.common.forecast.v2.Forecast current = 1;
 * @return {?proto.loupe.common.forecast.v2.Forecast}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.getCurrent = function() {
  return /** @type{?proto.loupe.common.forecast.v2.Forecast} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_v2_forecast_pb.Forecast, 1));
};


/**
 * @param {?proto.loupe.common.forecast.v2.Forecast|undefined} value
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
*/
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.setCurrent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.clearCurrent = function() {
  return this.setCurrent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.hasCurrent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.forecast.v2.Forecast previous = 2;
 * @return {?proto.loupe.common.forecast.v2.Forecast}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.getPrevious = function() {
  return /** @type{?proto.loupe.common.forecast.v2.Forecast} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_v2_forecast_pb.Forecast, 2));
};


/**
 * @param {?proto.loupe.common.forecast.v2.Forecast|undefined} value
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
*/
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.setPrevious = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.clearPrevious = function() {
  return this.setPrevious(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.hasPrevious = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional loupe.common.forecast.v2.ForecastDiff diff = 3;
 * @return {?proto.loupe.common.forecast.v2.ForecastDiff}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.getDiff = function() {
  return /** @type{?proto.loupe.common.forecast.v2.ForecastDiff} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_v2_forecast_pb.ForecastDiff, 3));
};


/**
 * @param {?proto.loupe.common.forecast.v2.ForecastDiff|undefined} value
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
*/
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.setDiff = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.clearDiff = function() {
  return this.setDiff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.hasDiff = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated loupe.common.opportunity.Opportunity opportunities = 4;
 * @return {!Array<!proto.loupe.common.opportunity.Opportunity>}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.getOpportunitiesList = function() {
  return /** @type{!Array<!proto.loupe.common.opportunity.Opportunity>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_opportunity_opportunity_pb.Opportunity, 4));
};


/**
 * @param {!Array<!proto.loupe.common.opportunity.Opportunity>} value
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
*/
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.setOpportunitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.opportunity.Opportunity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.opportunity.Opportunity}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.addOpportunities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.opportunity.Opportunity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.clearOpportunitiesList = function() {
  return this.setOpportunitiesList([]);
};


/**
 * optional QuotaInfo quota = 5;
 * @return {?proto.loupe.services.forecast.v2.QuotaInfo}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.getQuota = function() {
  return /** @type{?proto.loupe.services.forecast.v2.QuotaInfo} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v2.QuotaInfo, 5));
};


/**
 * @param {?proto.loupe.services.forecast.v2.QuotaInfo|undefined} value
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
*/
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.setQuota = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.clearQuota = function() {
  return this.setQuota(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.hasQuota = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated TeamInfo team_data = 6;
 * @return {!Array<!proto.loupe.services.forecast.v2.TeamInfo>}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.getTeamDataList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v2.TeamInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v2.TeamInfo, 6));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v2.TeamInfo>} value
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
*/
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.setTeamDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.services.forecast.v2.TeamInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.TeamInfo}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.addTeamData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.services.forecast.v2.TeamInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.clearTeamDataList = function() {
  return this.setTeamDataList([]);
};


/**
 * optional string owner_type = 7;
 * @return {string}
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.getOwnerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo} returns this
 */
proto.loupe.services.forecast.v2.FullForecastInfo.prototype.setOwnerType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.TeamInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.TeamInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.TeamInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.TeamInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    info: (f = msg.getInfo()) && proto.loupe.services.forecast.v2.FullForecastInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.TeamInfo}
 */
proto.loupe.services.forecast.v2.TeamInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.TeamInfo;
  return proto.loupe.services.forecast.v2.TeamInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.TeamInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.TeamInfo}
 */
proto.loupe.services.forecast.v2.TeamInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.loupe.services.forecast.v2.FullForecastInfo;
      reader.readMessage(value,proto.loupe.services.forecast.v2.FullForecastInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.TeamInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.TeamInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.TeamInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.TeamInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.forecast.v2.FullForecastInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.TeamInfo.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.TeamInfo} returns this
 */
proto.loupe.services.forecast.v2.TeamInfo.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FullForecastInfo info = 2;
 * @return {?proto.loupe.services.forecast.v2.FullForecastInfo}
 */
proto.loupe.services.forecast.v2.TeamInfo.prototype.getInfo = function() {
  return /** @type{?proto.loupe.services.forecast.v2.FullForecastInfo} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v2.FullForecastInfo, 2));
};


/**
 * @param {?proto.loupe.services.forecast.v2.FullForecastInfo|undefined} value
 * @return {!proto.loupe.services.forecast.v2.TeamInfo} returns this
*/
proto.loupe.services.forecast.v2.TeamInfo.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v2.TeamInfo} returns this
 */
proto.loupe.services.forecast.v2.TeamInfo.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.TeamInfo.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.QuotaInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.QuotaInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.QuotaInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.QuotaInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    quota: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    quotaattainment: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.QuotaInfo}
 */
proto.loupe.services.forecast.v2.QuotaInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.QuotaInfo;
  return proto.loupe.services.forecast.v2.QuotaInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.QuotaInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.QuotaInfo}
 */
proto.loupe.services.forecast.v2.QuotaInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuota(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuotaattainment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.QuotaInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.QuotaInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.QuotaInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.QuotaInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuota();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getQuotaattainment();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double quota = 1;
 * @return {number}
 */
proto.loupe.services.forecast.v2.QuotaInfo.prototype.getQuota = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v2.QuotaInfo} returns this
 */
proto.loupe.services.forecast.v2.QuotaInfo.prototype.setQuota = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double quotaAttainment = 2;
 * @return {number}
 */
proto.loupe.services.forecast.v2.QuotaInfo.prototype.getQuotaattainment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v2.QuotaInfo} returns this
 */
proto.loupe.services.forecast.v2.QuotaInfo.prototype.setQuotaattainment = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.ForecastRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.ForecastRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.ForecastRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.ForecastRequest}
 */
proto.loupe.services.forecast.v2.ForecastRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.ForecastRequest;
  return proto.loupe.services.forecast.v2.ForecastRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.ForecastRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.ForecastRequest}
 */
proto.loupe.services.forecast.v2.ForecastRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.ForecastRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.ForecastRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.ForecastRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.ForecastRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.ForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v2.ForecastRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.ForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.ForecastOwnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.ForecastOwnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.ForecastOwnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastOwnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.ForecastOwnerRequest}
 */
proto.loupe.services.forecast.v2.ForecastOwnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.ForecastOwnerRequest;
  return proto.loupe.services.forecast.v2.ForecastOwnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.ForecastOwnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.ForecastOwnerRequest}
 */
proto.loupe.services.forecast.v2.ForecastOwnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.ForecastOwnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.ForecastOwnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.ForecastOwnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastOwnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.ForecastOwnerRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.ForecastOwnerRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastOwnerRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v2.ForecastOwnerRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.ForecastOwnerRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastOwnerRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.ForecastDiffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.ForecastDiffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    targetId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.ForecastDiffRequest}
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.ForecastDiffRequest;
  return proto.loupe.services.forecast.v2.ForecastDiffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.ForecastDiffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.ForecastDiffRequest}
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.ForecastDiffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.ForecastDiffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTargetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.ForecastDiffRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string source_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest.prototype.getSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.ForecastDiffRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string target_id = 3;
 * @return {string}
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest.prototype.getTargetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.ForecastDiffRequest} returns this
 */
proto.loupe.services.forecast.v2.ForecastDiffRequest.prototype.setTargetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.ForecastWithDiffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.ForecastWithDiffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecast: (f = msg.getForecast()) && src_common_forecast_v2_forecast_pb.Forecast.toObject(includeInstance, f),
    diff: (f = msg.getDiff()) && src_common_forecast_v2_forecast_pb.ForecastDiff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.ForecastWithDiffResponse}
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.ForecastWithDiffResponse;
  return proto.loupe.services.forecast.v2.ForecastWithDiffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.ForecastWithDiffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.ForecastWithDiffResponse}
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_forecast_v2_forecast_pb.Forecast;
      reader.readMessage(value,src_common_forecast_v2_forecast_pb.Forecast.deserializeBinaryFromReader);
      msg.setForecast(value);
      break;
    case 2:
      var value = new src_common_forecast_v2_forecast_pb.ForecastDiff;
      reader.readMessage(value,src_common_forecast_v2_forecast_pb.ForecastDiff.deserializeBinaryFromReader);
      msg.setDiff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.ForecastWithDiffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.ForecastWithDiffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecast();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_forecast_v2_forecast_pb.Forecast.serializeBinaryToWriter
    );
  }
  f = message.getDiff();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_forecast_v2_forecast_pb.ForecastDiff.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.forecast.v2.Forecast forecast = 1;
 * @return {?proto.loupe.common.forecast.v2.Forecast}
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.prototype.getForecast = function() {
  return /** @type{?proto.loupe.common.forecast.v2.Forecast} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_v2_forecast_pb.Forecast, 1));
};


/**
 * @param {?proto.loupe.common.forecast.v2.Forecast|undefined} value
 * @return {!proto.loupe.services.forecast.v2.ForecastWithDiffResponse} returns this
*/
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.prototype.setForecast = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v2.ForecastWithDiffResponse} returns this
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.prototype.clearForecast = function() {
  return this.setForecast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.prototype.hasForecast = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.forecast.v2.ForecastDiff diff = 2;
 * @return {?proto.loupe.common.forecast.v2.ForecastDiff}
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.prototype.getDiff = function() {
  return /** @type{?proto.loupe.common.forecast.v2.ForecastDiff} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_v2_forecast_pb.ForecastDiff, 2));
};


/**
 * @param {?proto.loupe.common.forecast.v2.ForecastDiff|undefined} value
 * @return {!proto.loupe.services.forecast.v2.ForecastWithDiffResponse} returns this
*/
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.prototype.setDiff = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v2.ForecastWithDiffResponse} returns this
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.prototype.clearDiff = function() {
  return this.setDiff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.ForecastWithDiffResponse.prototype.hasDiff = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.repeatedFields_ = [4,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.GetDefaultForecastList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.GetDefaultForecastList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    acceptedCategoriesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    nextPage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sortField: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sortDir: jspb.Message.getFieldWithDefault(msg, 8, 0),
    viewableGroupsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.GetDefaultForecastList;
  return proto.loupe.services.forecast.v2.GetDefaultForecastList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.GetDefaultForecastList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.forecast.v2.ForecastMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAcceptedCategories(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortField(value);
      break;
    case 8:
      var value = /** @type {!proto.loupe.services.forecast.v2.SortDir} */ (reader.readEnum());
      msg.setSortDir(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addViewableGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.GetDefaultForecastList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.GetDefaultForecastList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAcceptedCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getNextPage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSortField();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSortDir();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getViewableGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.forecast.v2.ForecastMode mode = 3;
 * @return {!proto.loupe.common.forecast.v2.ForecastMode}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.getMode = function() {
  return /** @type {!proto.loupe.common.forecast.v2.ForecastMode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.forecast.v2.ForecastMode} value
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string accepted_categories = 4;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.getAcceptedCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.setAcceptedCategoriesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.addAcceptedCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.clearAcceptedCategoriesList = function() {
  return this.setAcceptedCategoriesList([]);
};


/**
 * optional int32 page_size = 5;
 * @return {number}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string next_page = 6;
 * @return {string}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.getNextPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.setNextPage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sort_field = 7;
 * @return {string}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.getSortField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.setSortField = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional SortDir sort_dir = 8;
 * @return {!proto.loupe.services.forecast.v2.SortDir}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.getSortDir = function() {
  return /** @type {!proto.loupe.services.forecast.v2.SortDir} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.loupe.services.forecast.v2.SortDir} value
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.setSortDir = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated string viewable_groups = 9;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.getViewableGroupsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.setViewableGroupsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.addViewableGroups = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.GetDefaultForecastList} returns this
 */
proto.loupe.services.forecast.v2.GetDefaultForecastList.prototype.clearViewableGroupsList = function() {
  return this.setViewableGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v2.ForecastListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.ForecastListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.ForecastListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.ForecastListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastsList: jspb.Message.toObjectList(msg.getForecastsList(),
    proto.loupe.services.forecast.v2.FullForecastInfo.toObject, includeInstance),
    nextPage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.ForecastListResponse}
 */
proto.loupe.services.forecast.v2.ForecastListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.ForecastListResponse;
  return proto.loupe.services.forecast.v2.ForecastListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.ForecastListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.ForecastListResponse}
 */
proto.loupe.services.forecast.v2.ForecastListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.forecast.v2.FullForecastInfo;
      reader.readMessage(value,proto.loupe.services.forecast.v2.FullForecastInfo.deserializeBinaryFromReader);
      msg.addForecasts(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.ForecastListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.ForecastListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.ForecastListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.forecast.v2.FullForecastInfo.serializeBinaryToWriter
    );
  }
  f = message.getNextPage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated FullForecastInfo forecasts = 1;
 * @return {!Array<!proto.loupe.services.forecast.v2.FullForecastInfo>}
 */
proto.loupe.services.forecast.v2.ForecastListResponse.prototype.getForecastsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v2.FullForecastInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v2.FullForecastInfo, 1));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v2.FullForecastInfo>} value
 * @return {!proto.loupe.services.forecast.v2.ForecastListResponse} returns this
*/
proto.loupe.services.forecast.v2.ForecastListResponse.prototype.setForecastsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.forecast.v2.FullForecastInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.FullForecastInfo}
 */
proto.loupe.services.forecast.v2.ForecastListResponse.prototype.addForecasts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.forecast.v2.FullForecastInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.ForecastListResponse} returns this
 */
proto.loupe.services.forecast.v2.ForecastListResponse.prototype.clearForecastsList = function() {
  return this.setForecastsList([]);
};


/**
 * optional string next_page = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v2.ForecastListResponse.prototype.getNextPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.ForecastListResponse} returns this
 */
proto.loupe.services.forecast.v2.ForecastListResponse.prototype.setNextPage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    opportunityIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest}
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest;
  return proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest}
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addOpportunityIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOpportunityIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest} returns this
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest} returns this
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string opportunity_ids = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.prototype.getOpportunityIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest} returns this
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.prototype.setOpportunityIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest} returns this
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.prototype.addOpportunityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest} returns this
 */
proto.loupe.services.forecast.v2.UpdateOpportunitiesRequest.prototype.clearOpportunityIdsList = function() {
  return this.setOpportunityIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.SetCategoryCallsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.SetCategoryCallsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryCallsMap: (f = msg.getCategoryCallsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.SetCategoryCallsRequest}
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.SetCategoryCallsRequest;
  return proto.loupe.services.forecast.v2.SetCategoryCallsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.SetCategoryCallsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.SetCategoryCallsRequest}
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = msg.getCategoryCallsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readDouble, null, "", 0.0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.SetCategoryCallsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.SetCategoryCallsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryCallsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeDouble);
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.SetCategoryCallsRequest} returns this
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.SetCategoryCallsRequest} returns this
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, double> category_calls = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest.prototype.getCategoryCallsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.forecast.v2.SetCategoryCallsRequest} returns this
 */
proto.loupe.services.forecast.v2.SetCategoryCallsRequest.prototype.clearCategoryCallsMap = function() {
  this.getCategoryCallsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.SetTotalCallRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.SetTotalCallRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalCall: (f = msg.getTotalCall()) && src_common_nullable_nullable_pb.NullFloat64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.SetTotalCallRequest}
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.SetTotalCallRequest;
  return proto.loupe.services.forecast.v2.SetTotalCallRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.SetTotalCallRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.SetTotalCallRequest}
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = new src_common_nullable_nullable_pb.NullFloat64;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullFloat64.deserializeBinaryFromReader);
      msg.setTotalCall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.SetTotalCallRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.SetTotalCallRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalCall();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_nullable_nullable_pb.NullFloat64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.SetTotalCallRequest} returns this
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.SetTotalCallRequest} returns this
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.nullable.NullFloat64 total_call = 3;
 * @return {?proto.loupe.common.nullable.NullFloat64}
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.prototype.getTotalCall = function() {
  return /** @type{?proto.loupe.common.nullable.NullFloat64} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullFloat64, 3));
};


/**
 * @param {?proto.loupe.common.nullable.NullFloat64|undefined} value
 * @return {!proto.loupe.services.forecast.v2.SetTotalCallRequest} returns this
*/
proto.loupe.services.forecast.v2.SetTotalCallRequest.prototype.setTotalCall = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v2.SetTotalCallRequest} returns this
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.prototype.clearTotalCall = function() {
  return this.setTotalCall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.SetTotalCallRequest.prototype.hasTotalCall = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.SaveForecastRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.SaveForecastRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    forecast: (f = msg.getForecast()) && src_common_forecast_v2_forecast_pb.Forecast.toObject(includeInstance, f),
    mode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    acceptedCategoriesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    isManager: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    modifierId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    teamSettings: (f = msg.getTeamSettings()) && proto.loupe.services.forecast.v2.ForecastTeamSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.SaveForecastRequest;
  return proto.loupe.services.forecast.v2.SaveForecastRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.SaveForecastRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = new src_common_forecast_v2_forecast_pb.Forecast;
      reader.readMessage(value,src_common_forecast_v2_forecast_pb.Forecast.deserializeBinaryFromReader);
      msg.setForecast(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.common.forecast.v2.ForecastMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAcceptedCategories(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsManager(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifierId(value);
      break;
    case 8:
      var value = new proto.loupe.services.forecast.v2.ForecastTeamSettings;
      reader.readMessage(value,proto.loupe.services.forecast.v2.ForecastTeamSettings.deserializeBinaryFromReader);
      msg.setTeamSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.SaveForecastRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.SaveForecastRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getForecast();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_forecast_v2_forecast_pb.Forecast.serializeBinaryToWriter
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAcceptedCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getIsManager();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getModifierId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTeamSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.loupe.services.forecast.v2.ForecastTeamSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.forecast.v2.Forecast forecast = 3;
 * @return {?proto.loupe.common.forecast.v2.Forecast}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.getForecast = function() {
  return /** @type{?proto.loupe.common.forecast.v2.Forecast} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_v2_forecast_pb.Forecast, 3));
};


/**
 * @param {?proto.loupe.common.forecast.v2.Forecast|undefined} value
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest} returns this
*/
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.setForecast = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.clearForecast = function() {
  return this.setForecast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.hasForecast = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional loupe.common.forecast.v2.ForecastMode mode = 4;
 * @return {!proto.loupe.common.forecast.v2.ForecastMode}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.getMode = function() {
  return /** @type {!proto.loupe.common.forecast.v2.ForecastMode} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.common.forecast.v2.ForecastMode} value
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated string accepted_categories = 5;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.getAcceptedCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.setAcceptedCategoriesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.addAcceptedCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.clearAcceptedCategoriesList = function() {
  return this.setAcceptedCategoriesList([]);
};


/**
 * optional bool is_manager = 6;
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.getIsManager = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.setIsManager = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string modifier_id = 7;
 * @return {string}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.getModifierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.setModifierId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional ForecastTeamSettings team_settings = 8;
 * @return {?proto.loupe.services.forecast.v2.ForecastTeamSettings}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.getTeamSettings = function() {
  return /** @type{?proto.loupe.services.forecast.v2.ForecastTeamSettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v2.ForecastTeamSettings, 8));
};


/**
 * @param {?proto.loupe.services.forecast.v2.ForecastTeamSettings|undefined} value
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest} returns this
*/
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.setTeamSettings = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v2.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.clearTeamSettings = function() {
  return this.setTeamSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.SaveForecastRequest.prototype.hasTeamSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.RehydrateForecastsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.RehydrateForecastsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.RehydrateForecastsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.RehydrateForecastsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.RehydrateForecastsRequest}
 */
proto.loupe.services.forecast.v2.RehydrateForecastsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.RehydrateForecastsRequest;
  return proto.loupe.services.forecast.v2.RehydrateForecastsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.RehydrateForecastsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.RehydrateForecastsRequest}
 */
proto.loupe.services.forecast.v2.RehydrateForecastsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.RehydrateForecastsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.RehydrateForecastsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.RehydrateForecastsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.RehydrateForecastsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.RehydrateForecastsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.RehydrateForecastsRequest} returns this
 */
proto.loupe.services.forecast.v2.RehydrateForecastsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.RehydrateForecastsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.RehydrateForecastsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.RehydrateForecastsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.RehydrateForecastsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.RehydrateForecastsResponse}
 */
proto.loupe.services.forecast.v2.RehydrateForecastsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.RehydrateForecastsResponse;
  return proto.loupe.services.forecast.v2.RehydrateForecastsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.RehydrateForecastsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.RehydrateForecastsResponse}
 */
proto.loupe.services.forecast.v2.RehydrateForecastsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.RehydrateForecastsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.RehydrateForecastsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.RehydrateForecastsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.RehydrateForecastsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.repeatedFields_ = [6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.SearchForecastsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.SearchForecastsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    searchText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.loupe.services.forecast.v2.ForecastFilter.toObject, includeInstance),
    sortOptionsList: jspb.Message.toObjectList(msg.getSortOptionsList(),
    proto.loupe.services.forecast.v2.ForecastSort.toObject, includeInstance),
    viewableGroupsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.SearchForecastsRequest;
  return proto.loupe.services.forecast.v2.SearchForecastsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.SearchForecastsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchText(value);
      break;
    case 6:
      var value = new proto.loupe.services.forecast.v2.ForecastFilter;
      reader.readMessage(value,proto.loupe.services.forecast.v2.ForecastFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 7:
      var value = new proto.loupe.services.forecast.v2.ForecastSort;
      reader.readMessage(value,proto.loupe.services.forecast.v2.ForecastSort.deserializeBinaryFromReader);
      msg.addSortOptions(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addViewableGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.SearchForecastsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.SearchForecastsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSearchText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.loupe.services.forecast.v2.ForecastFilter.serializeBinaryToWriter
    );
  }
  f = message.getSortOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.loupe.services.forecast.v2.ForecastSort.serializeBinaryToWriter
    );
  }
  f = message.getViewableGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest} returns this
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest} returns this
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest} returns this
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 page = 4;
 * @return {number}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest} returns this
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string search_text = 5;
 * @return {string}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.getSearchText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest} returns this
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.setSearchText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated ForecastFilter filters = 6;
 * @return {!Array<!proto.loupe.services.forecast.v2.ForecastFilter>}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v2.ForecastFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v2.ForecastFilter, 6));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v2.ForecastFilter>} value
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest} returns this
*/
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.services.forecast.v2.ForecastFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.ForecastFilter}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.services.forecast.v2.ForecastFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest} returns this
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * repeated ForecastSort sort_options = 7;
 * @return {!Array<!proto.loupe.services.forecast.v2.ForecastSort>}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.getSortOptionsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v2.ForecastSort>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v2.ForecastSort, 7));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v2.ForecastSort>} value
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest} returns this
*/
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.setSortOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.loupe.services.forecast.v2.ForecastSort=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.ForecastSort}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.addSortOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.loupe.services.forecast.v2.ForecastSort, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest} returns this
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.clearSortOptionsList = function() {
  return this.setSortOptionsList([]);
};


/**
 * repeated string viewable_groups = 8;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.getViewableGroupsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest} returns this
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.setViewableGroupsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest} returns this
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.addViewableGroups = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsRequest} returns this
 */
proto.loupe.services.forecast.v2.SearchForecastsRequest.prototype.clearViewableGroupsList = function() {
  return this.setViewableGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v2.ForecastFilter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.ForecastFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.ForecastFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.ForecastFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: msg.getValuesList_asB64(),
    compareop: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.ForecastFilter}
 */
proto.loupe.services.forecast.v2.ForecastFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.ForecastFilter;
  return proto.loupe.services.forecast.v2.ForecastFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.ForecastFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.ForecastFilter}
 */
proto.loupe.services.forecast.v2.ForecastFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addValues(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.common.filter.FilterOperation} */ (reader.readEnum());
      msg.setCompareop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.ForecastFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.ForecastFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.ForecastFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      2,
      f
    );
  }
  f = message.getCompareop();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.ForecastFilter.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.ForecastFilter} returns this
 */
proto.loupe.services.forecast.v2.ForecastFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated bytes values = 2;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.loupe.services.forecast.v2.ForecastFilter.prototype.getValuesList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * repeated bytes values = 2;
 * This is a type-conversion wrapper around `getValuesList()`
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v2.ForecastFilter.prototype.getValuesList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getValuesList()));
};


/**
 * repeated bytes values = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValuesList()`
 * @return {!Array<!Uint8Array>}
 */
proto.loupe.services.forecast.v2.ForecastFilter.prototype.getValuesList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getValuesList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.loupe.services.forecast.v2.ForecastFilter} returns this
 */
proto.loupe.services.forecast.v2.ForecastFilter.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v2.ForecastFilter} returns this
 */
proto.loupe.services.forecast.v2.ForecastFilter.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.ForecastFilter} returns this
 */
proto.loupe.services.forecast.v2.ForecastFilter.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional loupe.common.filter.FilterOperation compareOp = 5;
 * @return {!proto.loupe.common.filter.FilterOperation}
 */
proto.loupe.services.forecast.v2.ForecastFilter.prototype.getCompareop = function() {
  return /** @type {!proto.loupe.common.filter.FilterOperation} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.common.filter.FilterOperation} value
 * @return {!proto.loupe.services.forecast.v2.ForecastFilter} returns this
 */
proto.loupe.services.forecast.v2.ForecastFilter.prototype.setCompareop = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.ForecastSort.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.ForecastSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.ForecastSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    asc: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.ForecastSort}
 */
proto.loupe.services.forecast.v2.ForecastSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.ForecastSort;
  return proto.loupe.services.forecast.v2.ForecastSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.ForecastSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.ForecastSort}
 */
proto.loupe.services.forecast.v2.ForecastSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAsc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.ForecastSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.ForecastSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.ForecastSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAsc();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v2.ForecastSort.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v2.ForecastSort} returns this
 */
proto.loupe.services.forecast.v2.ForecastSort.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool asc = 2;
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.ForecastSort.prototype.getAsc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v2.ForecastSort} returns this
 */
proto.loupe.services.forecast.v2.ForecastSort.prototype.setAsc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.SearchForecastsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.SearchForecastsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastsList: jspb.Message.toObjectList(msg.getForecastsList(),
    src_common_forecast_v2_forecast_pb.Forecast.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsResponse}
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.SearchForecastsResponse;
  return proto.loupe.services.forecast.v2.SearchForecastsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.SearchForecastsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsResponse}
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_forecast_v2_forecast_pb.Forecast;
      reader.readMessage(value,src_common_forecast_v2_forecast_pb.Forecast.deserializeBinaryFromReader);
      msg.addForecasts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.SearchForecastsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.SearchForecastsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_forecast_v2_forecast_pb.Forecast.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated loupe.common.forecast.v2.Forecast forecasts = 1;
 * @return {!Array<!proto.loupe.common.forecast.v2.Forecast>}
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse.prototype.getForecastsList = function() {
  return /** @type{!Array<!proto.loupe.common.forecast.v2.Forecast>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_forecast_v2_forecast_pb.Forecast, 1));
};


/**
 * @param {!Array<!proto.loupe.common.forecast.v2.Forecast>} value
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsResponse} returns this
*/
proto.loupe.services.forecast.v2.SearchForecastsResponse.prototype.setForecastsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.forecast.v2.Forecast=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.v2.Forecast}
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse.prototype.addForecasts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.forecast.v2.Forecast, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsResponse} returns this
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse.prototype.clearForecastsList = function() {
  return this.setForecastsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v2.SearchForecastsResponse} returns this
 */
proto.loupe.services.forecast.v2.SearchForecastsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v2.ForecastTeamSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v2.ForecastTeamSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v2.ForecastTeamSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastTeamSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    simplify: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    useManagerNames: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v2.ForecastTeamSettings}
 */
proto.loupe.services.forecast.v2.ForecastTeamSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v2.ForecastTeamSettings;
  return proto.loupe.services.forecast.v2.ForecastTeamSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v2.ForecastTeamSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v2.ForecastTeamSettings}
 */
proto.loupe.services.forecast.v2.ForecastTeamSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSimplify(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseManagerNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v2.ForecastTeamSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v2.ForecastTeamSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v2.ForecastTeamSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v2.ForecastTeamSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSimplify();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getUseManagerNames();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool simplify = 1;
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.ForecastTeamSettings.prototype.getSimplify = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v2.ForecastTeamSettings} returns this
 */
proto.loupe.services.forecast.v2.ForecastTeamSettings.prototype.setSimplify = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool use_manager_names = 2;
 * @return {boolean}
 */
proto.loupe.services.forecast.v2.ForecastTeamSettings.prototype.getUseManagerNames = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v2.ForecastTeamSettings} returns this
 */
proto.loupe.services.forecast.v2.ForecastTeamSettings.prototype.setUseManagerNames = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.loupe.services.forecast.v2.SortDir = {
  ASC: 0,
  DESC: 1
};

/**
 * @enum {number}
 */
proto.loupe.services.forecast.v2.CategoryRollupMode = {
  DEFAULT: 0,
  ROLLUP_ONLY: 1,
  FORECAST_ONLY: 2
};

goog.object.extend(exports, proto.loupe.services.forecast.v2);
