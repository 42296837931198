import { ForecastConfigStatus } from '../../grpc/enums'
import { MoreVert, Delete, Copy, Edit, Archive } from '@getoutreach/react-icons'
import { permissionNames } from '../../constants/permissionNames'
import { useDebug } from '../../context/debug'
import { useForecastingConfigs } from '../../context/forecastingConfigs'
import { useHistory } from 'react-router-dom'
import { useNotification } from '../../hooks/useNotification'
import { usePermissions } from '../../context/permissions'
import { usePopover } from '../../hooks/usePopover'
import { useRoutes } from '../../context/routes'
import Popover from '../common/popover'
import React, { useCallback, useMemo, useState } from 'react'
import Refresh from '../icons/refresh'
import ActionPopover from '../common/actionPopover'

const ForecastListItemMenu = (props) => {
  const {
    config,
    disabled,
  } = props

  const { debug } = useDebug()
  const { checkPermissions } = usePermissions()
  const { notifyError, notifySuccess } = useNotification()
  const { routes } = useRoutes()
  const history = useHistory()
  const { duplicateConfig, setConfigStatus, invalidate } = useForecastingConfigs()
  const { anchorEl, setAnchorEl, open, onClose } = usePopover()
  const archivePopover = usePopover()
  const deletePopover = usePopover()

  const [duplicating, setDuplicating] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [archiving, setArchiving] = useState(false)

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanManageForecasts
    )
  }, [checkPermissions])

  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget)
  }, [setAnchorEl])

  const onEdit = useCallback(() => {
    history.push(routes.forecast.replace(':forecastId', `${config.id}`))
  }, [routes, history, config])

  const onEditInternal = useCallback((e) => {
    window.analytics.track('Edit Forecast Clicked')
    onEdit()
    onClose()
  }, [onEdit, onClose])

  const onDeleteInternal = useCallback((e) => {
    deletePopover.setAnchorEl(e.currentTarget)
  }, [deletePopover])

  const onDeleteConfirmed = useCallback((e) => {
    window.analytics.track('Delete Forecast Clicked')
    setConfigStatus({
      forecastConfigId: config.id,
      status: ForecastConfigStatus.DELETED
    })
    deletePopover.onClose()
    onClose()
  }, [deletePopover, onClose, setConfigStatus, config])

  const onDuplicateInternal = useCallback((e) => {
    window.analytics.track('Duplicate Forecast Clicked')
    duplicateConfig(config)
    onClose()
  }, [duplicateConfig, config, onClose])

  const onArchiveInternal = useCallback((e) => {
    archivePopover.setAnchorEl(e.currentTarget)
  }, [archivePopover])

  const onArchiveConfirmed = useCallback((e) => {
    window.analytics.track('Archive Forecast Clicked')
    setConfigStatus({
      forecastConfigId: config.id,
      status: ForecastConfigStatus.DISABLED
    })
    archivePopover.onClose()
    onClose()
  }, [archivePopover, onClose, setConfigStatus, config])

  return (
    <>
      <button className="forecastMenu" onClick={handleClick}>
        <MoreVert fill="#151d49" />
      </button>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        placement="right">
        <div className="w-80">
          {permissions.CanManageForecasts && (
            <>
              {!disabled && (
                <div onClick={onEditInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
                  <div className="td-menu">
                    <Edit fill="#09242f" transform="scale(0.75)" />
                  </div>
                  <div className="flex flex-col leading-tight ml-2">
                    <div className="text-size-15px text-color-151d49 font-weight-400">Edit Forecast</div>
                    <div className="text-size-13px text-color-4e5d7e font-weight-400">Make changes to this forecast.</div>
                  </div>
                </div>
              )}
              <div onClick={onDuplicateInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
                <div className="td-menu">
                  {duplicating
                    ? (<Refresh fill="#5951FF" className="animate-spin" />)
                    : (<Copy fill="#09242f" transform="scale(0.75)" />)}
                </div>
                <div className="flex flex-col leading-tight ml-2">
                  <div className="text-size-15px text-color-151d49 font-weight-400">Duplicate Forecast</div>
                  <div className="text-size-13px text-color-4e5d7e font-weight-400">Copy this forecast’s settings to a brand new forecast.</div>
                </div>
              </div>
              {!disabled && (
                <>
                  <div onClick={onArchiveInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
                    <div className="td-menu">
                      {archiving
                        ? (<Refresh fill="#5951FF" className="animate-spin" />)
                        : (<Archive fill="#09242f" transform="scale(0.75)" />)}
                    </div>
                    <div className="flex flex-col leading-tight ml-2">
                      <div className="text-size-15px text-color-151d49 font-weight-400">Archive Forecast</div>
                      <div className="text-size-13px text-color-4e5d7e font-weight-400">Permanently deactivate this forecast but keep it visible in this list.</div>
                    </div>
                  </div>
                  <ActionPopover
                    popover={archivePopover}
                    text="Archive this forecast?"
                    subText={(
                      <>
                        This forecast won’t be visible to any teams, but it’ll stay in this list for future reference.
                        <br />
                        <br />
                        Once a forecasts has been archived, it can’t be reactivated.
                      </>
                    )}
                    actionText="Archive"
                    actionProps={{
                      backgroundColor: '#FB6C6A'
                    }}
                    onAction={onArchiveConfirmed}
                    maxWidth={285}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                  />
                </>
              )}
              <>
                <div onClick={onDeleteInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
                  <div className="td-menu">
                    {deleting
                      ? (<Refresh fill="#5951FF" className="animate-spin" />)
                      : (<Delete fill="#09242f" transform="scale(0.75)" />)}
                  </div>
                  <div className="flex flex-col leading-tight ml-2">
                    <div className="text-size-15px text-color-151d49 font-weight-400">Delete Forecast</div>
                    <div className="text-size-13px text-color-4e5d7e font-weight-400">Permanently deactivate this forecast and remove it from this list.</div>
                  </div>
                </div>
                <ActionPopover
                  popover={deletePopover}
                  text="Delete this forecast?"
                  subText={(
                    <div className="mb-18">
                      This forecast won’t be visible to any teams, and will be permanently deleted from this list.
                    </div>
                  )}
                  actionText="Delete"
                  actionProps={{
                    backgroundColor: '#FB6C6A'
                  }}
                  onAction={onDeleteConfirmed}
                  maxWidth={285}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                />
              </>
            </>
          )}
        </div>
      </Popover>
    </>
  )
}

export default ForecastListItemMenu
