import LoadingStateIcon from './loadingStateIcon'
import React from 'react'

const LoadingState = (props) => {
  const {
    header,
    subHeader,
    animate = false,
    dense = false,
    style = {},
  } = props

  return (
    <div className="flex items-center">
      <div className="text-center">
        <LoadingStateIcon animate={animate} dense={dense} style={style} />
        {header && <div className="text-size-30px font-bold text-color-91959f">{header}</div>}
        {subHeader && <div className="text-size-14px text-color-91959f">{subHeader}</div>}
      </div>
    </div>
  )
}

export default LoadingState
