// source: src/common/forecast/v2/forecast.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_nullable_nullable_pb = require('../../../../src/common/nullable/nullable_pb.js');
goog.object.extend(proto, src_common_nullable_nullable_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.loupe.common.forecast.v2.Forecast', null, global);
goog.exportSymbol('proto.loupe.common.forecast.v2.ForecastDiff', null, global);
goog.exportSymbol('proto.loupe.common.forecast.v2.ForecastMode', null, global);
goog.exportSymbol('proto.loupe.common.forecast.v2.ForecastWithDiff', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.v2.Forecast = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.forecast.v2.Forecast.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.forecast.v2.Forecast, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.v2.Forecast.displayName = 'proto.loupe.common.forecast.v2.Forecast';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.v2.ForecastDiff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.forecast.v2.ForecastDiff.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.forecast.v2.ForecastDiff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.v2.ForecastDiff.displayName = 'proto.loupe.common.forecast.v2.ForecastDiff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.v2.ForecastWithDiff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.forecast.v2.ForecastWithDiff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.v2.ForecastWithDiff.displayName = 'proto.loupe.common.forecast.v2.ForecastWithDiff';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.forecast.v2.Forecast.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.v2.Forecast.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.v2.Forecast} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v2.Forecast.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    periodStart: jspb.Message.getFieldWithDefault(msg, 4, ""),
    periodEnd: jspb.Message.getFieldWithDefault(msg, 5, ""),
    totalCall: (f = msg.getTotalCall()) && src_common_nullable_nullable_pb.NullFloat64.toObject(includeInstance, f),
    categoryCallsMap: (f = msg.getCategoryCallsMap()) ? f.toObject(includeInstance, undefined) : [],
    defaultCategoryCallsMap: (f = msg.getDefaultCategoryCallsMap()) ? f.toObject(includeInstance, undefined) : [],
    opportunityIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    comment: (f = msg.getComment()) && src_common_nullable_nullable_pb.NullString.toObject(includeInstance, f),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
    submittedAt: (f = msg.getSubmittedAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.v2.Forecast}
 */
proto.loupe.common.forecast.v2.Forecast.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.v2.Forecast;
  return proto.loupe.common.forecast.v2.Forecast.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.v2.Forecast} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.v2.Forecast}
 */
proto.loupe.common.forecast.v2.Forecast.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodStart(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodEnd(value);
      break;
    case 6:
      var value = new src_common_nullable_nullable_pb.NullFloat64;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullFloat64.deserializeBinaryFromReader);
      msg.setTotalCall(value);
      break;
    case 7:
      var value = msg.getCategoryCallsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readDouble, null, "", 0.0);
         });
      break;
    case 8:
      var value = msg.getDefaultCategoryCallsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readDouble, null, "", 0.0);
         });
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addOpportunityIds(value);
      break;
    case 10:
      var value = new src_common_nullable_nullable_pb.NullString;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullString.deserializeBinaryFromReader);
      msg.setComment(value);
      break;
    case 11:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 12:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setSubmittedAt(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.v2.Forecast.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.v2.Forecast} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v2.Forecast.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPeriodStart();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPeriodEnd();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTotalCall();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_nullable_nullable_pb.NullFloat64.serializeBinaryToWriter
    );
  }
  f = message.getCategoryCallsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeDouble);
  }
  f = message.getDefaultCategoryCallsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeDouble);
  }
  f = message.getOpportunityIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getComment();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      src_common_nullable_nullable_pb.NullString.serializeBinaryToWriter
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(11, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSubmittedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string owner_id = 3;
 * @return {string}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string period_start = 4;
 * @return {string}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getPeriodStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.setPeriodStart = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string period_end = 5;
 * @return {string}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getPeriodEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.setPeriodEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional loupe.common.nullable.NullFloat64 total_call = 6;
 * @return {?proto.loupe.common.nullable.NullFloat64}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getTotalCall = function() {
  return /** @type{?proto.loupe.common.nullable.NullFloat64} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullFloat64, 6));
};


/**
 * @param {?proto.loupe.common.nullable.NullFloat64|undefined} value
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
*/
proto.loupe.common.forecast.v2.Forecast.prototype.setTotalCall = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.clearTotalCall = function() {
  return this.setTotalCall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.hasTotalCall = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * map<string, double> category_calls = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getCategoryCallsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.clearCategoryCallsMap = function() {
  this.getCategoryCallsMap().clear();
  return this;};


/**
 * map<string, double> default_category_calls = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getDefaultCategoryCallsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.clearDefaultCategoryCallsMap = function() {
  this.getDefaultCategoryCallsMap().clear();
  return this;};


/**
 * repeated string opportunity_ids = 9;
 * @return {!Array<string>}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getOpportunityIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.setOpportunityIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.addOpportunityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.clearOpportunityIdsList = function() {
  return this.setOpportunityIdsList([]);
};


/**
 * optional loupe.common.nullable.NullString comment = 10;
 * @return {?proto.loupe.common.nullable.NullString}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getComment = function() {
  return /** @type{?proto.loupe.common.nullable.NullString} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullString, 10));
};


/**
 * @param {?proto.loupe.common.nullable.NullString|undefined} value
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
*/
proto.loupe.common.forecast.v2.Forecast.prototype.setComment = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.clearComment = function() {
  return this.setComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.hasComment = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * map<string, string> metadata = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 11, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};


/**
 * optional loupe.common.nullable.NullTimestamp submitted_at = 12;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getSubmittedAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 12));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
*/
proto.loupe.common.forecast.v2.Forecast.prototype.setSubmittedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.clearSubmittedAt = function() {
  return this.setSubmittedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.hasSubmittedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
*/
proto.loupe.common.forecast.v2.Forecast.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
*/
proto.loupe.common.forecast.v2.Forecast.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string updated_by = 15;
 * @return {string}
 */
proto.loupe.common.forecast.v2.Forecast.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v2.Forecast} returns this
 */
proto.loupe.common.forecast.v2.Forecast.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.forecast.v2.ForecastDiff.repeatedFields_ = [1,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.v2.ForecastDiff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.v2.ForecastDiff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v2.ForecastDiff.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    totalCall: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    categoryCallsMap: (f = msg.getCategoryCallsMap()) ? f.toObject(includeInstance, undefined) : [],
    defaultCategoryCallsMap: (f = msg.getDefaultCategoryCallsMap()) ? f.toObject(includeInstance, undefined) : [],
    addedOpportunityIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    removedOpportunityIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    comment: jspb.Message.getFieldWithDefault(msg, 7, ""),
    interval: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff}
 */
proto.loupe.common.forecast.v2.ForecastDiff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.v2.ForecastDiff;
  return proto.loupe.common.forecast.v2.ForecastDiff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.v2.ForecastDiff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff}
 */
proto.loupe.common.forecast.v2.ForecastDiff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addForecastIds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalCall(value);
      break;
    case 3:
      var value = msg.getCategoryCallsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readDouble, null, "", 0.0);
         });
      break;
    case 4:
      var value = msg.getDefaultCategoryCallsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readDouble, null, "", 0.0);
         });
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAddedOpportunityIds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addRemovedOpportunityIds(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.v2.ForecastDiff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.v2.ForecastDiff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v2.ForecastDiff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTotalCall();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCategoryCallsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeDouble);
  }
  f = message.getDefaultCategoryCallsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeDouble);
  }
  f = message.getAddedOpportunityIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getRemovedOpportunityIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * repeated string forecast_ids = 1;
 * @return {!Array<string>}
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.getForecastIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.setForecastIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.addForecastIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.clearForecastIdsList = function() {
  return this.setForecastIdsList([]);
};


/**
 * optional double total_call = 2;
 * @return {number}
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.getTotalCall = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.setTotalCall = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * map<string, double> category_calls = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.getCategoryCallsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.clearCategoryCallsMap = function() {
  this.getCategoryCallsMap().clear();
  return this;};


/**
 * map<string, double> default_category_calls = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.getDefaultCategoryCallsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.clearDefaultCategoryCallsMap = function() {
  this.getDefaultCategoryCallsMap().clear();
  return this;};


/**
 * repeated string added_opportunity_ids = 5;
 * @return {!Array<string>}
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.getAddedOpportunityIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.setAddedOpportunityIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.addAddedOpportunityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.clearAddedOpportunityIdsList = function() {
  return this.setAddedOpportunityIdsList([]);
};


/**
 * repeated string removed_opportunity_ids = 6;
 * @return {!Array<string>}
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.getRemovedOpportunityIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.setRemovedOpportunityIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.addRemovedOpportunityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.clearRemovedOpportunityIdsList = function() {
  return this.setRemovedOpportunityIdsList([]);
};


/**
 * optional string comment = 7;
 * @return {string}
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 interval = 8;
 * @return {number}
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.forecast.v2.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastDiff.prototype.setInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.v2.ForecastWithDiff.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.v2.ForecastWithDiff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.v2.ForecastWithDiff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v2.ForecastWithDiff.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecast: (f = msg.getForecast()) && proto.loupe.common.forecast.v2.Forecast.toObject(includeInstance, f),
    diff: (f = msg.getDiff()) && proto.loupe.common.forecast.v2.ForecastDiff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.v2.ForecastWithDiff}
 */
proto.loupe.common.forecast.v2.ForecastWithDiff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.v2.ForecastWithDiff;
  return proto.loupe.common.forecast.v2.ForecastWithDiff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.v2.ForecastWithDiff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.v2.ForecastWithDiff}
 */
proto.loupe.common.forecast.v2.ForecastWithDiff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.forecast.v2.Forecast;
      reader.readMessage(value,proto.loupe.common.forecast.v2.Forecast.deserializeBinaryFromReader);
      msg.setForecast(value);
      break;
    case 2:
      var value = new proto.loupe.common.forecast.v2.ForecastDiff;
      reader.readMessage(value,proto.loupe.common.forecast.v2.ForecastDiff.deserializeBinaryFromReader);
      msg.setDiff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.v2.ForecastWithDiff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.v2.ForecastWithDiff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.v2.ForecastWithDiff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v2.ForecastWithDiff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecast();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.common.forecast.v2.Forecast.serializeBinaryToWriter
    );
  }
  f = message.getDiff();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.common.forecast.v2.ForecastDiff.serializeBinaryToWriter
    );
  }
};


/**
 * optional Forecast forecast = 1;
 * @return {?proto.loupe.common.forecast.v2.Forecast}
 */
proto.loupe.common.forecast.v2.ForecastWithDiff.prototype.getForecast = function() {
  return /** @type{?proto.loupe.common.forecast.v2.Forecast} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.forecast.v2.Forecast, 1));
};


/**
 * @param {?proto.loupe.common.forecast.v2.Forecast|undefined} value
 * @return {!proto.loupe.common.forecast.v2.ForecastWithDiff} returns this
*/
proto.loupe.common.forecast.v2.ForecastWithDiff.prototype.setForecast = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v2.ForecastWithDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastWithDiff.prototype.clearForecast = function() {
  return this.setForecast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v2.ForecastWithDiff.prototype.hasForecast = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ForecastDiff diff = 2;
 * @return {?proto.loupe.common.forecast.v2.ForecastDiff}
 */
proto.loupe.common.forecast.v2.ForecastWithDiff.prototype.getDiff = function() {
  return /** @type{?proto.loupe.common.forecast.v2.ForecastDiff} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.forecast.v2.ForecastDiff, 2));
};


/**
 * @param {?proto.loupe.common.forecast.v2.ForecastDiff|undefined} value
 * @return {!proto.loupe.common.forecast.v2.ForecastWithDiff} returns this
*/
proto.loupe.common.forecast.v2.ForecastWithDiff.prototype.setDiff = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v2.ForecastWithDiff} returns this
 */
proto.loupe.common.forecast.v2.ForecastWithDiff.prototype.clearDiff = function() {
  return this.setDiff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v2.ForecastWithDiff.prototype.hasDiff = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.loupe.common.forecast.v2.ForecastMode = {
  AUTO: 0,
  MANUAL: 1
};

goog.object.extend(exports, proto.loupe.common.forecast.v2);
