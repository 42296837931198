import { noop } from 'lodash'
import Joyride, { STATUS } from 'react-joyride'
import JoyrideBeaconHidden from '../components/joyride/joyrideBeaconHidden'
import JoyrideTooltip from '../components/joyride/joyrideTooltip'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

const JoyrideContext = React.createContext()

export function JoyrideProvider({
  children,
  continuous = true,
  debug = false,
  disableBeacon = true,
  enabled = true,
  initialSteps = [],
  onComplete = noop,
  runOnMount = false,
  ...props
}) {
  const [helpers, setHelpers] = useState()
  const [index, setIndex] = useState(0)
  const [joyride, setJoyride] = useState(initialSteps)
  const [run, setRun] = useState(false)

  useEffect(() => {
    if (enabled && runOnMount) {
      // Run the joyride on mount if enabled
      setRun(true)
    }
  }, [enabled, runOnMount])

  useEffect(() => {
    if (run) {
      setTimeout(() => {
        // Open the Joyride when run is flipped to true
        helpers.open()
      }, 0)
    }
  }, [helpers, run])

  const getHelpers = useCallback((args) => {
    setHelpers(args)
  }, [])

  const handleCallback = useCallback((data) => {
    const { status, type } = data

    if (status === STATUS.FINISHED && type.includes('end')) {
      onComplete()
    }

    setIndex(data.index)
  }, [onComplete])

  const start = useCallback(() => {
    setRun(true)
    helpers?.open()
  }, [helpers])

  const status = useMemo(() => {
    return helpers?.info()
  }, [helpers])

  const stop = useCallback(() => {
    helpers?.close()
    setRun(false)
  }, [helpers])

  const contextValue = useMemo(() => {
    return {
      enabled,
      index,
      joyride,
      run,
      setJoyride,
      setRun,
      start,
      status,
      stop
    }
  }, [
    enabled,
    index,
    joyride,
    run,
    setJoyride,
    setRun,
    start,
    status,
    stop
  ])

  return (
    <JoyrideContext.Provider value={contextValue}>
      {children}
      <Joyride
        callback={handleCallback}
        continuous={continuous}
        debug={debug}
        disableCloseOnEsc
        disableOverlayClose
        disableScrolling
        disableScrollParentFix
        floaterProps={{
          disableAnimation: true,
          styles: {
            floater: {
              zIndex: 300
            }
          }
        }}
        hideBackButton
        hideCloseButton
        locale={{ last: 'Got It' }}
        getHelpers={getHelpers}
        run={enabled && run}
        steps={joyride}
        styles={{
          options: {
            overlayColor: 'rgba(255,255,255,0)',
          },
          overlay: {
            width: '100vw',
            height: '100vh',
            position: 'fixed'
          },
        }}
        tooltipComponent={JoyrideTooltip}
        {...disableBeacon && { beaconComponent: JoyrideBeaconHidden }}
        {...props}
      />
    </JoyrideContext.Provider>
  )
}

export function useJoyride() {
  const context = React.useContext(JoyrideContext)
  if (context === undefined) {
    throw new Error('useJoyride must be used within a JoyrideProvider')
  }
  return context
}
