import React, { useMemo, useEffect, useCallback, useState } from 'react'
import SortableTree from '@nosferatu500/react-sortable-tree'
import VisibilityNodeRenderer from '../tree/visibilityNodeRenderer'
import { getNodeById } from '../tree/getNodeById'
import { useTreeData } from '../../context/treeData'
import { toggleExpandedForAll, isDescendant } from '../tree/utils/tree-data-utils'
import { usePermissions } from '../../context/permissions'

const EditVisibilityControl = (props) => {
  const {
    search,
    user,
    viewableGroups,
    visibilityUpdated
  } = props

  const {
    dataInitialized,
    treeData,
    searchInTree,
    setTreeData
  } = useTreeData()

  const { checkPermission } = usePermissions()

  const [selected, setSelected] = useState(
    new Map(viewableGroups
      .map(({ group }) => {
        const node = getNodeById(treeData, group.id)
        return [group.id, node]
      }))
  )

  const onSelected = useCallback((node, parent) => {
    const selectedKeys = [...selected.keys()]
    selectedKeys.filter((s) => s !== node.group.id).map((s) => selected.delete(s))

    if (!selected.delete(node.group.id)) {
      const parent = [...selected.values()].find((s) => isDescendant(s, node))
      const children = [...selected.values()].filter((s) => isDescendant(node, s))
      if (parent) {
        selected.delete(parent.group.id)
      } else if (children.length) {
        children.forEach((c) => selected.delete(c.group.id))
      }
      selected.set(node.group.id, node)
    }
    setSelected(new Map(selected))
    window.analytics.track('addEditUserModal.teamVisibility.teamChecked', { isChecked: selectedKeys.length === 0 })
  }, [])

  useEffect(() => {
    visibilityUpdated?.(selected)
  }, [selected, visibilityUpdated])

  const onChange = useCallback((data) => {
    setTreeData(data)
  }, [])

  const getTree = useMemo(() => {
    if (!search.length) {
      return treeData
    }

    const newTree = searchInTree(search, treeData[0])
    return [newTree]
  }, [treeData, search])

  const rootGroupId = useMemo(() => {
    return treeData[0]?.group?.id
  }, [treeData.length])

  const defaultAssignedHierarchy = useMemo(() => {
    const defaultAssignedNode = getNodeById(treeData, user?.groupId || rootGroupId)
    return defaultAssignedNode?.group?.groupPath.split('.')
  }, [rootGroupId, user?.groupId])

  useEffect(() => {
    if (dataInitialized) {
      setTreeData(toggleExpandedForAll({ treeData, expanded: true }))
    }
  }, [dataInitialized])

  return (
    <div
      className="flex-grow overflow-auto flex flex-col">
      <div className="relative flex-1 visibility-node-renderer">
        <SortableTree
          treeData={getTree}
          canDrag={false}
          onChange={onChange}
          generateNodeProps={() => ({
            defaultAssigned: user?.groupId,
            defaultAssignedHierarchy,
            selected,
            onSelected,
            onEdit: () => { },
            checkPermission,
          })}
          theme={{
            nodeContentRenderer: VisibilityNodeRenderer,
            scaffoldBlockPxWidth: 25,
            rowHeight: 36,
            slideRegionSize: 50,
          }}
          getNodeKey={({ node, treeIndex }) => node?.group?.id || treeIndex}
          canNodeHaveChildren={() => true} />
      </div>
    </div>
  )
}

export default EditVisibilityControl
