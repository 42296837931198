import React, { useState, useCallback, useMemo } from 'react'
import { guid } from '../lib/guid'

const UserPrefsContext = React.createContext()

export function UserPrefsProvider({ children }) {
  const [key, setKey] = useState(guid())
  const storageAvailable = useMemo(() => {
    return !!window.localStorage
  }, [])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const getStoredObject = useCallback((context = 'global', key) => {
    if (storageAvailable) {
      const item = localStorage.getItem(context)
      if (!item) {
        return null
      }
      const { [key]: keyedValue } = JSON.parse(item) ?? {}
      return keyedValue
    }
  }, [storageAvailable])

  const setStoredObject = useCallback((context = 'global', key, value) => {
    if (storageAvailable) {
      const item = localStorage.getItem(context)
      const toSave = {
        ...JSON.parse(item) ?? {},
        [key]: value
      }

      // If the value is explicitly null or undefined, we need to delete it from storage
      if (value === null || value === undefined) {
        delete toSave[key]
      }

      if (Object.keys(toSave).length) {
        window.localStorage.setItem(context, JSON.stringify(toSave))
      } else {
        window.localStorage.removeItem(context)
      }
    }
  }, [storageAvailable])

  const removeJoyrideLocalState = useCallback(() => {
    if (storageAvailable) {
      window.localStorage.removeItem('joyride')
    }
  }, [storageAvailable])

  const savePref = useCallback((context, key, value) => {
    setStoredObject(context, key, value)
  }, [setStoredObject])

  const getPref = useCallback((context, key) => {
    return getStoredObject(context, key)
  }, [getStoredObject])

  const deletePref = useCallback((context, key) => {
    setStoredObject(context, key, null)
  }, [setStoredObject])

  const clearCacheAndReload = async () => {
    if (window.caches) {
      const keys = await caches.keys()
      keys.forEach((name) => window.caches.delete(name))
      window.location.reload(true)
    }
  }

  const contextValue = useMemo(() => {
    return {
      clearCacheAndReload,
      deletePref,
      getPref,
      key,
      invalidate,
      savePref,
      storageAvailable,
      removeJoyrideLocalState
    }
  }, [
    clearCacheAndReload,
    deletePref,
    getPref,
    key,
    invalidate,
    savePref,
    storageAvailable,
    removeJoyrideLocalState
  ])

  return <UserPrefsContext.Provider value={contextValue}>{children}</UserPrefsContext.Provider>
}

export function useUserPrefs() {
  const context = React.useContext(UserPrefsContext)
  if (context === undefined) {
    throw new Error('useUserPrefs must be used within a UserPrefsProvider')
  }
  return context
}
