import { typeCheck } from 'type-check'
import { camelCase, upperFirst } from 'lodash'

// only converts standard js types (numbers, strings and booleans or arrays of each type)
export function protoFromObject(ProtoClass, obj) {
  const newObj = new ProtoClass()
  for (const prop in obj) {
    const setter = `set${upperFirst(camelCase(prop))}`
    const value = obj[prop]
    if (typeCheck('Function', newObj[setter])) {
      if (typeCheck('Number | String | Boolean', value)) {
        newObj[setter](value)
      } else if (typeCheck('[Number | String | Boolean]', value)) {
        newObj[setter](value.map((v) => v))
      }
    }
  }
  return newObj
}
