import React, { useCallback } from 'react'
import { useTenantInfo } from '../../context/tenantInfo'
import { useAuth } from '../../context/auth'
import ExitToApp from '../icons/exitToApp'

const SandboxBanner = () => {
  const { isSandbox, tenantInfo } = useTenantInfo()

  const { setTenantContext } = useAuth()

  const { name } = tenantInfo

  const onExit = useCallback(() => {
    setTenantContext(undefined, '/sandboxes')
  }, [setTenantContext])

  return (
    <>
      {isSandbox && (
        <div style={{ zIndex: 300 }} className="flex justify-between items-center p-4 bg-color-5951FF text-color-ffffff fixed w-full t-0">
          <div>
            <span className="font-weight-200">{'Sandbox name: '}</span>
            <span>{name}</span>
          </div>
          <span>You are currently in a Sandbox.</span>
          <button onClick={onExit} className="flex">
            <span className="px-2">Exit Sandbox</span>
            <ExitToApp transform="scale(0.85)" />
          </button>
        </div>
      )}
    </>
  )
}

export default SandboxBanner
