import React, { useMemo } from 'react'
import { useCanonicalObjectMapping } from '../../context/canonicalObjectMapping'
import { useIntegrationObjectProperties } from '../../context/integrationObjectProperties'
import ObjectTabButtons from './objectTabButtons'
import LoadingState from '../common/loadingState'

const CrmObjectMapper = (props) => {
  const {
    children,
  } = props

  const { isFetching: isFetchingCanonicalObjectMapping } = useCanonicalObjectMapping()
  const { isFetching: isFetchingIntegrationObjectProperties } = useIntegrationObjectProperties()

  const isFetching = useMemo(() => {
    return isFetchingCanonicalObjectMapping || isFetchingIntegrationObjectProperties
  }, [isFetchingCanonicalObjectMapping, isFetchingIntegrationObjectProperties])

  return (
    <>
      <div className="flex items-center justify-between mx-10">
        <ObjectTabButtons isFetching={isFetching} />
      </div>

      {isFetching
        ? (
          <div className="flex justify-center my-10">
            <LoadingState
              header="Loading CRM Object"
              subHeader="Please wait..."
              animate={true} />
          </div>
        )
        : (
          <div className="justify-between mx-10 pb-10">
            {children}
          </div>
        )}
    </>
  )
}

export default CrmObjectMapper
