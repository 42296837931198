import { OnboardingStep } from '../../grpc/enums'
import { SystemRolesProvider } from '../../context/systemRoles'
import { useSetup } from '../../context/setup'
import Onboarding from '../onboarding/onboarding'
import React, { useCallback } from 'react'
import RolesMain from './rolesMain'

const Roles = (props) => {
  const { completeStep, isFetching, isStepComplete } = useSetup()

  const handleCompleteJoyride = useCallback(() => {
    completeStep(OnboardingStep.ONBOARDING_STEP_ROLES)
  }, [completeStep])

  return (
    <SystemRolesProvider>
      <Onboarding step={OnboardingStep.ONBOARDING_STEP_ROLES} onComplete={handleCompleteJoyride}>
        <RolesMain />
      </Onboarding>
    </SystemRolesProvider>
  )
}

export default Roles
