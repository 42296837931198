import Checkbox from '../common/checkbox'
import classnames from './utils/classnames'
import React, { Component } from 'react'

class ForecastVisibilityNodeRenderer extends Component {
  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      title,
      subtitle,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      buttons,
      className,
      style,
      didDrop,
      treeId,
      isOver, // Not needed, but preserved for other renderers
      parentNode, // Needed for dndManager
      rowDirection,
      onSelected,
      selected,
      // selectedParentIds,
      ...otherProps
    } = this.props

    const rowDirectionClass = null

    const isSelected = [...selected.keys()].includes(node?.group?.id)

    const isAncestor = !![...selected.values()].find((s) => {
      return !isSelected && s.path.includes(node?.group?.id)
    })

    const isDescendant = !![...selected.values()].find((s) => {
      return !isSelected && path.includes(s.group.id)
    })

    // const isSiblingToSelectedNode = selectedParentIds.length > 0 && !selectedParentIds.includes(parentNode?.group?.id)
    const isSiblingToSelectedNode = false

    const disabled = isDescendant || (!isAncestor && !isSelected && isSiblingToSelectedNode)

    return (
      <div {...otherProps}>
        <div className={classnames('custom__rowWrapper group', rowDirectionClass)}>
          {connectDragPreview(
            <div
              className={classnames(
                'custom__row',
                isSearchMatch && 'custom__rowSearchMatch',
                isSearchFocus && 'custom__rowSearchFocus',
                rowDirectionClass,
                className
              )}
              style={style}>

              <div className={classnames('custom__rowContents', rowDirectionClass)}>
                <div className={classnames('custom__rowLabel', rowDirectionClass)}>
                  <Checkbox
                    disabled={disabled}
                    checked={isSelected || isDescendant || isAncestor}
                    intermediate={isAncestor}
                    onChange={() => onSelected({ node, parentNode, path })}
                    label={node.title}
                    labelProps={{
                      className: classnames(
                        'text-size-16px text-color-09242f font-weight-400',
                        disabled && 'text-color-c9ced0'
                      )
                    }} />
                </div>
              </div>

            </div>
          )}
        </div>

      </div>
    )
  }
}

export default ForecastVisibilityNodeRenderer
