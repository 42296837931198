import { usePopover } from '../../hooks/usePopover'
import Edit from '../icons/edit'
import Link from '../icons/link'
import MoreVertical from '../icons/moreVertical'
import Popover from '../common/popover'
import React, { useCallback } from 'react'

const TenantMenu = (props) => {
  const {
    tenant,
    onEditTenant,
    onSalesforceAuthLink,
    onHubspotAuthLink,
  } = props

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const onEditTenantInternal = useCallback((e) => {
    onClose()
    onEditTenant && onEditTenant(tenant)
  }, [tenant, onEditTenant])

  const onSalesforceAuthLinkInternal = useCallback((e) => {
    onClose()
    onSalesforceAuthLink && onSalesforceAuthLink(tenant)
  }, [tenant, onSalesforceAuthLink])

  const onHubspotAuthLinkInternal = useCallback((e) => {
    onClose()
    onHubspotAuthLink && onHubspotAuthLink(tenant)
  }, [tenant, onHubspotAuthLink])

  return (
    <>
      <button onClick={handleClick}>
        <MoreVertical fill="#151d49" />
      </button>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        placement="right">
        <div className="py-3">
          <div onClick={onEditTenantInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
            <div>
              <Edit fill="#09242f" transform="scale(0.75)" />
            </div>
            <div className="flex flex-col leading-tight ml-2">
              <div className="text-size-15px text-color-151d49 font-weight-400">Edit Tenant</div>
              <div className="text-size-13px text-color-4e5d7e font-weight-400">Modify the tenant name or status</div>
            </div>
          </div>
          <div onClick={onSalesforceAuthLinkInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
            <div>
              <Link fill="#09242f" transform="scale(0.75)" />
            </div>
            <div className="flex flex-col leading-tight ml-2">
              <div className="text-size-15px text-color-151d49 font-weight-400">Salesforce Auth Link</div>
              <div className="text-size-13px text-color-4e5d7e font-weight-400">Generate link and copy to clipboard</div>
            </div>
          </div>
          <div onClick={onHubspotAuthLinkInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
            <div>
              <Link fill="#09242f" transform="scale(0.75)" />
            </div>
            <div className="flex flex-col leading-tight ml-2">
              <div className="text-size-15px text-color-151d49 font-weight-400">Hubspot Auth Link</div>
              <div className="text-size-13px text-color-4e5d7e font-weight-400">Generate link and copy to clipboard</div>
            </div>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default TenantMenu
