import React, { useMemo, useCallback } from 'react'
import PermissionListItem from './permissionListItem'
import { orderBy } from 'lodash'

const PermissionsList = (props) => {
  const {
    permissionsList,
    onChange,
    overrideCollapsed,
    overrideExpanded,
    canEdit
  } = props

  const permissionListItems = useMemo(() => {
    if (permissionsList) {
      return orderBy(permissionsList, (p) => p.name)
    }
    return []
  }, [permissionsList])

  return (
    <>
      <div className="flex-table">
        <div className="thead">
          <div className="tr">
            <div className="td-menu" />
            <div className="td w-1/5">Name</div>
            <div className="td w-3/5 text-left">Description</div>
            <div className="td" />
          </div>
        </div>
        <div className="tbody">
          {permissionListItems.map((permission, i) => (
            <PermissionListItem
              key={`RoleListItem-${permission.permissionSet}-${i}`}
              canEdit={canEdit}
              onChange={onChange}
              overrideCollapsed={overrideCollapsed}
              overrideExpanded={overrideExpanded}
              permission={permission} />
          ))}
        </div>
      </div>
    </>
  )
}

export default PermissionsList
