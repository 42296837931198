import React, { useRef, useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '../common/modal'
import ModalHeader from '../common/modalHeader'
import ModalError from '../common/modalError'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import Button from '../common/button'
import { useGrpcCallback } from '../../grpc'
import { useSystemRoles } from '../../context/systemRoles'
import LoadingState from '../common/loadingState'
import SelectList from '../common/selectList'
import { toSystemRole } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import Label from '../common/label'
import { Popper } from '@material-ui/core'

const AddRoleModal = (props) => {
  const {
    modal
  } = props

  const { roleId = 0 } = modal?.data ?? {}

  const { tenantId } = useAuth()
  const { systemRoles, invalidate } = useSystemRoles()

  const { open, handleClose } = modal

  const [showError, setShowError] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [roleName, setRoleName] = useState('')
  const [description, setDescription] = useState('')

  const [selectedRole, setSelectedRole] = useState(0)
  const [duplicateRole, setDuplicateRole] = useState(null)
  const [validError, setValidError] = useState(null)

  const inputRef = useRef()

  useEffect(() => {
    if (open) {
      const toDuplicate = systemRoles.find(({ id }) => id === roleId)
      setSelectedRole(roleId)
      setDuplicateRole(toDuplicate)
    } else {
      setRoleName('')
      setDescription('')
      setSelectedRole(0)
    }
  }, [open, roleId, systemRoles])

  const roleOptions = useMemo(() => {
    let initialOption = {
      value: 0,
      label: '-- Select a base role --'
    }

    if (roleId && duplicateRole) {
      initialOption = {
        value: roleId,
        label: duplicateRole?.name
      }
    }

    const _roles = [initialOption]
    systemRoles.forEach((systemRole) => {
      const { id, name, isCustom } = systemRole
      if (!isCustom) {
        _roles.push({
          value: id,
          label: name
        })
      }
    })
    return _roles
  }, [systemRoles, roleId, duplicateRole])

  const onChange = useCallback((option) => {
    if (option) {
      setSelectedRole(option.value)
    }
  }, [])

  const nameIsUnique = useCallback((_name) => {
    const systemRoleNames = systemRoles.map(({ name }) => name.toLowerCase())
    return !systemRoleNames.includes(_name?.toLowerCase?.())
  }, [systemRoles])

  const onChangeName = useCallback(({ target }) => {
    setRoleName(target.value)
    if (!nameIsUnique(target.value)) {
      setValidError('Role name already exists')
      return
    }
    setValidError(null)
  }, [nameIsUnique])

  const onChangeDescription = useCallback(({ target }) => {
    setDescription(target.value)
  }, [])

  const handleCloseInternal = useCallback(() => {
    setShowError(false)
    modal.setData(undefined)
    handleClose()
  }, [modal])

  const saveDisabled = useMemo(() => {
    return selectedRole === 0
      || !roleName
      || showLoader
      || !nameIsUnique(roleName)
  }, [selectedRole, roleName, showLoader, nameIsUnique])

  const createRole = useGrpcCallback({
    onError: (e) => {
      setShowError('Unable to create role')
      setShowLoader(false)
    },
    onSuccess: () => {
      setShowLoader(false)
      handleCloseInternal()
      invalidate()
    },
    onFetch: () => {
      setShowLoader(true)
    },
    grpcMethod: 'createSystemRole',
    debug: false,
  }, [handleCloseInternal, invalidate])

  const onAdd = useCallback(() => {
    const request = toSystemRole({
      tenantId,
      name: roleName,
      description,
      baseRoleId: selectedRole,
    })
    createRole(request)
  }, [createRole, tenantId, roleName, description, selectedRole])

  const title = useMemo(() => {
    return (roleId) ? 'Duplicate Role' : 'Add Role'
  }, [roleId])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="sm"
      open={open}>

      <ModalHeader
        title={title}
        onClose={handleCloseInternal} />

      <ModalBody>
        {showError && <ModalError text={showError} />}

        {showLoader
          ? (
            <div className="flex justify-center w-full h-full my-10">
              <div className="text-center">
                <div className="flex justify-center my-3">
                  <LoadingState
                    header="Creating Role"
                    subHeader="Please wait..."
                    animate={true} />
                </div>
              </div>
            </div>
          )
          : (
            <div className="w-full h-full px-10 pt-10">

              <div className="flex items-center">
                <Label text="Role Name" />
                <span className="text-color-fb6c6a pl-1">*</span>
              </div>
              <input
                ref={inputRef}
                className="border border-color-d6d9e6 rounded w-full p-2 mb-6"
                value={roleName}
                placeholder="Select"
                onChange={onChangeName} />
              <Popper
                style={{
                  zIndex: 10000
                }}
                className="bg-color-ffffff shadow-lg rounded"
                open={!!validError}
                anchorEl={inputRef.current}
                placement="bottom-start">
                <div className="p-4">{validError}</div>
              </Popper>

              <div className="flex items-center">
                <Label text="Type" />
                <span className="text-color-fb6c6a pl-1">*</span>
              </div>
              <div className="mb-6">
                <SelectList
                  disabled={!!roleId}
                  value={selectedRole}
                  onChange={onChange}
                  options={roleOptions} />
              </div>

              <div className="text-size-16px text-color-51636a font-weight-500 leading-loose">Description</div>
              <textarea
                className="border border-color-d6d9e6 rounded w-full p-2"
                value={description}
                onChange={onChangeDescription}
                placeholder="(optional)" />

            </div>
          )}

      </ModalBody>

      <ModalFooter>
        <Button
          size="xs"
          text="Add"
          onClick={onAdd}
          disabled={saveDisabled} />
      </ModalFooter>

    </Modal>
  )
}

export default AddRoleModal
