import React from 'react'

const Asterisk = (props) => {
  const {
    fill = '#ffffff',
  } = props

  return (
    <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...{ ...props, fill }}>
      <path d="M3.16878 6.94796H4.55478L4.44478 4.52796L6.46878 5.82596L7.17278 4.63796L5.03878 3.53796L7.17278 2.43796L6.46878 1.22796L4.44478 2.52596L4.55478 0.105957H3.16878L3.27878 2.52596L1.23278 1.22796L0.550781 2.43796L2.68478 3.53796L0.550781 4.63796L1.23278 5.82596L3.27878 4.52796L3.16878 6.94796Z" />
    </svg>
  )
}

export default Asterisk
