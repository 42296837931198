// source: src/common/integration/object/fieldsetting/fieldsetting.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.loupe.common.integration.object.fieldsetting.FilterableObject', null, global);
goog.exportSymbol('proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField', null, global);
goog.exportSymbol('proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject', null, global);
goog.exportSymbol('proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap', null, global);
goog.exportSymbol('proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption', null, global);
goog.exportSymbol('proto.loupe.common.integration.object.fieldsetting.ObjectType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap.displayName = 'proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.displayName = 'proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.displayName = 'proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.displayName = 'proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesMap: (f = msg.getValuesMap()) ? f.toObject(includeInstance, proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap;
  return proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getValuesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.deserializeBinaryFromReader, "", new proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.serializeBinaryToWriter);
  }
};


/**
 * map<string, IntegrationFilterDisplayObject> values = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject>}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap.prototype.getValuesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObjectMap.prototype.clearValuesMap = function() {
  this.getValuesMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    canopyObjectKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject;
  return proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanopyObjectKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = new proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField;
      reader.readMessage(value,proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanopyObjectKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string canopy_object_key = 1;
 * @return {string}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.prototype.getCanopyObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.prototype.setCanopyObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_key = 2;
 * @return {string}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.prototype.getObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.prototype.setObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated IntegrationFilterDisplayField properties = 4;
 * @return {!Array<!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField>}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField, 4));
};


/**
 * @param {!Array<!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField>} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject} returns this
*/
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.toObject, includeInstance),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    custom: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    originaltype: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isprimaryid: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    iscalculated: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    originalname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    createable: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    updatedable: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField;
  return proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    case 5:
      var value = new proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption;
      reader.readMessage(value,proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustom(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginaltype(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsprimaryid(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscalculated(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalname(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateable(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdatedable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.serializeBinaryToWriter
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCustom();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getOriginaltype();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsprimaryid();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIscalculated();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getOriginalname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreateable();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getUpdatedable();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 sort = 4;
 * @return {number}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated IntegrationFilterDisplayPicklistOption options = 5;
 * @return {!Array<!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption>}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption, 5));
};


/**
 * @param {!Array<!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption>} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
*/
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional bool required = 6;
 * @return {boolean}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool custom = 7;
 * @return {boolean}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string originalType = 8;
 * @return {string}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getOriginaltype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setOriginaltype = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool isPrimaryId = 9;
 * @return {boolean}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getIsprimaryid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setIsprimaryid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool isCalculated = 10;
 * @return {boolean}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getIscalculated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setIscalculated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string originalName = 11;
 * @return {string}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getOriginalname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setOriginalname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool createable = 12;
 * @return {boolean}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getCreateable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setCreateable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool updatedable = 13;
 * @return {boolean}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.getUpdatedable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayField.prototype.setUpdatedable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 3, 0),
    defaultvalue: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption;
  return proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultvalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDefaultvalue();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 sort = 3;
 * @return {number}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool defaultValue = 4;
 * @return {boolean}
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.prototype.getDefaultvalue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption} returns this
 */
proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayPicklistOption.prototype.setDefaultvalue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.integration.object.fieldsetting.FilterableObject = {
  ACCOUNT: 0,
  CONTACT: 1,
  EVENT: 2,
  EVENTRELATION: 3,
  LEAD: 4,
  LEADHISTORY: 5,
  OPPORTUNITY: 6,
  OPPORTUNITYHISTORY: 7,
  OPPORTUNITYCOMPETITOR: 8,
  OPPORTUNITYCONTACTROLE: 9,
  OPPORTUNITYFIELDHISTORY: 10,
  OPPORTUNITYLINEITEM: 11,
  OPPORTUNITYSTAGE: 12,
  TASK: 13,
  USER: 14,
  USERROLE: 15,
  RECORDTYPE: 16,
  ORGANIZATION: 17,
  ACTIVITY: 18
};

/**
 * @enum {number}
 */
proto.loupe.common.integration.object.fieldsetting.ObjectType = {
  STRING: 0,
  BYTES: 1,
  INTEGER: 2,
  FLOAT: 3,
  BOOLEAN: 4,
  TIMESTAMP: 5,
  RECORD: 6,
  DATE: 7,
  TIME: 8,
  DATETIME: 9,
  NUMERIC: 10,
  GEOGRAPHY: 11,
  BASE64: 12,
  ANY: 13
};

goog.object.extend(exports, proto.loupe.common.integration.object.fieldsetting);
