import { useCallback, useState } from 'react'

export function useModal() {
  const [data, setData] = useState(undefined)
  const [open, setOpen] = useState(false)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return {
    open,
    setOpen,
    handleClose,
    data,
    setData,
  }
}
