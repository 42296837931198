import Button from '../common/button'
import classNames from 'classnames'
import IFrame from '../common/iFrame'
import Modal from '../common/modal'
import ModalBody from '../common/modalBody'
import ModalError from '../common/modalError'
import ModalFooter from '../common/modalFooter'
import ModalHeader from '../common/modalHeader'
import React, { useCallback, useEffect, useState } from 'react'

const ForecastConfigPreviewModal = (props) => {
  const {
    modal,
  } = props

  const { open, handleClose, data: modalData } = modal

  const [showLoader, setShowLoader] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showIFrame, setShowIFrame] = useState(false)

  useEffect(() => {
    setShowIFrame((open && modalData?.url))
  }, [modalData, open])

  const handleCloseInternal = useCallback(() => {
    handleClose()
  }, [handleClose])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="lg"
      open={open}>

      <ModalHeader
        title="Forecast Preview"
        subtitle="See how your forecast looks in Commit before publishing."
        onClose={handleCloseInternal} />

      <ModalBody>
        {showError && <ModalError text="Unable to preview forecast." />}

        <div
          className={classNames('w-full h-full px-10 flex flex-row', { 'pointer-events-none': showLoader })}
          style={{ height: 'calc(100vh - 260px)' }}>
          {showIFrame && (
            <IFrame
              className="h-full w-full flex flex-1"
              src={modalData.url}
              height="calc(-260px + 100vh)"
              width="calc(100% - 40px)"
              title="Forecast Config Preview"
              frameBorder="0"
              allowFullScreen />
          )}
        </div>

      </ModalBody>

      <ModalFooter>
        <Button
          size="xs"
          text="Close Preview"
          onClick={handleCloseInternal}
        />
      </ModalFooter>

    </Modal>
  )
}

export default ForecastConfigPreviewModal
