import React, { useRef, useState, useMemo, useCallback } from 'react'
import Chip from '@material-ui/core/Chip'
import { Popover } from '@material-ui/core'
import { v4 as guid } from 'uuid'
import ChevronDown from '../../icons/chevronDown'

const FieldMultiInput = (props) => {
  const {
    value: initialValue,
    onChange,
    delimiter = ';'
  } = props

  const menu = useRef()

  const [value, setValue] = useState('')
  const [items, setItems] = useState(initialValue ?? [])
  const [open, setOpen] = useState(false)

  const placeholder = useMemo(() => {
    return `Enter terms, separated by a ${delimiter}`
  }, [delimiter])

  const updateInternal = useCallback((update) => {
    setItems(update)
    onChange(update)
  }, [onChange])

  const handleChangeInternal = useCallback(({ target }) => {
    const { value = '' } = target
    const parts = value.split(delimiter)
    const last = parts.pop()

    if (last) {
      setValue(last)
    } else {
      setValue('')
    }

    updateInternal([
      ...items,
      ...parts.map((label) => ({ key: guid(), label, value: label }))
    ])
  }, [delimiter, items, updateInternal])

  const itemsDisplay = useMemo(() => {
    if (!items.length) {
      return
    }

    return items.map(({ label }) => label).join(', ')
  }, [items])

  const width = useMemo(() => {
    if (menu.current) {
      return `${menu.current.clientWidth - 40}px`
    }
    return '100%'
  }, [menu.current])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const removeChip = useCallback((removeKey) => {
    const toRemove = items.findIndex(({ key }) => key === removeKey)

    items.splice(toRemove, 1)
    updateInternal([...items])
  }, [items, updateInternal])

  return (
    <>
      <button
        className="field field-select"
        ref={menu}
        onClick={handleOpen}>
        <div style={{ width }} className="overflow-ellip">
          {itemsDisplay || (<span>Enter a value</span>)}
        </div>
        <ChevronDown fill="#A6B2CF" className="ml-4" />

      </button>
      {menu && (
        <Popover
          open={open}
          anchorEl={menu.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <div className="multi-input">
            <input
              focus={open}
              onFocus={() => setOpen(true)}
              value={value}
              className="field"
              onChange={handleChangeInternal}
              placeholder={placeholder} />
            <div className="chip-box" style={{ width }}>
              {items.map((item) => {
                const { label, key } = item
                return (
                  <Chip
                    key={key}
                    label={label}
                    onDelete={() => removeChip(key)} />
                )
              })}
            </div>
          </div>
        </Popover>
      )}
    </>
  )
}

export default FieldMultiInput
