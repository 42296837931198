import { toGetObjectMappingsSourceOptionsRequest } from '../grpc/converters'
import { useActor } from '../hooks/useActor'
import { useDebug } from './debug'
import { useGrpcCallback } from '../grpc'
import { useNotification } from '../hooks/useNotification'
import { v4 as guid } from 'uuid'
import React, { useMemo, useState, useCallback, useEffect } from 'react'

const FilterSourceOptionsContext = React.createContext()

export const FilterSourceOptionsProvider = ({ isModalOpen, children }) => {
  const { notifyError } = useNotification()
  const { debug } = useDebug()
  const { actor } = useActor()

  const [key, setKey] = useState(guid())
  const [isFetching, setIsFetching] = useState(false)
  const [sourceOptions, setSourceOptions] = useState([])

  const getObjectMappingsSourceOptions = useGrpcCallback({
    onSuccess: ({ resultsList }) => {
      setSourceOptions(resultsList)
      setIsFetching(false)
    },
    onError: (err) => {
      setIsFetching(false)
      notifyError('Error getting mapping source options')
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getObjectMappingsSourceOptions',
    debug
  }, [debug])

  useEffect(() => {
    if (!actor) {
      return
    }
    const requestedOptions = [{
      fieldsList: [],
      source: 'opportunity'
    }]
    const request = toGetObjectMappingsSourceOptionsRequest({
      actor,
      requestedOptions
    })
    if (isModalOpen) {
      getObjectMappingsSourceOptions(request)
    }
  }, [key, actor, isModalOpen, getObjectMappingsSourceOptions])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      key,
      isFetching,
      sourceOptions,
      invalidate
    }
  }, [
    key,
    isFetching,
    sourceOptions,
    invalidate
  ])

  return <FilterSourceOptionsContext.Provider value={contextValue}>{children}</FilterSourceOptionsContext.Provider>
}

export const useFilterSourceOptions = () => {
  const context = React.useContext(FilterSourceOptionsContext)
  if (context === undefined) {
    throw new Error('useFilterSourceOptions must be used within a FilterSourceOptionsProvider')
  }
  return context
}
