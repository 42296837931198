const domain = 'https://loupe.co'

export const claimNames = {
  organizationId: `${domain}/org_id`,
  permissions: `${domain}/permissions`,
  roles: `${domain}/roles`,
  tenantId: `${domain}/ten_id`,
  tenantRegion: `${domain}/ten_region`,
  tenantContexts: `${domain}/ten_ctxs`,
  personId: `${domain}/per_id`
}
