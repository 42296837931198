// source: src/services/activity/activity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_activity_activity_pb = require('../../../src/common/activity/activity_pb.js');
goog.object.extend(proto, src_common_activity_activity_pb);
var src_common_opportunity_opportunity_pb = require('../../../src/common/opportunity/opportunity_pb.js');
goog.object.extend(proto, src_common_opportunity_opportunity_pb);
var src_common_diff_diff_pb = require('../../../src/common/diff/diff_pb.js');
goog.object.extend(proto, src_common_diff_diff_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.loupe.services.activity.GetActivityDateCursorRequest', null, global);
goog.exportSymbol('proto.loupe.services.activity.GetActivityDateCursorResponse', null, global);
goog.exportSymbol('proto.loupe.services.activity.GetActivityDetailsRequest', null, global);
goog.exportSymbol('proto.loupe.services.activity.GetActivityDetailsResponse', null, global);
goog.exportSymbol('proto.loupe.services.activity.GetActivityTimelineRequest', null, global);
goog.exportSymbol('proto.loupe.services.activity.GetActivityTimelineResponse', null, global);
goog.exportSymbol('proto.loupe.services.activity.OpportunityChange', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.activity.GetActivityTimelineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.activity.GetActivityTimelineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.activity.GetActivityTimelineRequest.displayName = 'proto.loupe.services.activity.GetActivityTimelineRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.activity.GetActivityTimelineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.activity.GetActivityTimelineResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.activity.GetActivityTimelineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.activity.GetActivityTimelineResponse.displayName = 'proto.loupe.services.activity.GetActivityTimelineResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.activity.OpportunityChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.activity.OpportunityChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.activity.OpportunityChange.displayName = 'proto.loupe.services.activity.OpportunityChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.activity.GetActivityDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.activity.GetActivityDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.activity.GetActivityDetailsRequest.displayName = 'proto.loupe.services.activity.GetActivityDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.activity.GetActivityDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.activity.GetActivityDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.activity.GetActivityDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.activity.GetActivityDetailsResponse.displayName = 'proto.loupe.services.activity.GetActivityDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.activity.GetActivityDateCursorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.activity.GetActivityDateCursorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.activity.GetActivityDateCursorRequest.displayName = 'proto.loupe.services.activity.GetActivityDateCursorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.activity.GetActivityDateCursorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.activity.GetActivityDateCursorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.activity.GetActivityDateCursorResponse.displayName = 'proto.loupe.services.activity.GetActivityDateCursorResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.activity.GetActivityTimelineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.activity.GetActivityTimelineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.activity.GetActivityTimelineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.GetActivityTimelineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.activity.GetActivityTimelineRequest}
 */
proto.loupe.services.activity.GetActivityTimelineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.activity.GetActivityTimelineRequest;
  return proto.loupe.services.activity.GetActivityTimelineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.activity.GetActivityTimelineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.activity.GetActivityTimelineRequest}
 */
proto.loupe.services.activity.GetActivityTimelineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpportunityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.activity.GetActivityTimelineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.activity.GetActivityTimelineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.activity.GetActivityTimelineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.GetActivityTimelineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpportunityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.activity.GetActivityTimelineRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.activity.GetActivityTimelineRequest} returns this
 */
proto.loupe.services.activity.GetActivityTimelineRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string opportunity_id = 2;
 * @return {string}
 */
proto.loupe.services.activity.GetActivityTimelineRequest.prototype.getOpportunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.activity.GetActivityTimelineRequest} returns this
 */
proto.loupe.services.activity.GetActivityTimelineRequest.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string start_date = 3;
 * @return {string}
 */
proto.loupe.services.activity.GetActivityTimelineRequest.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.activity.GetActivityTimelineRequest} returns this
 */
proto.loupe.services.activity.GetActivityTimelineRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end_date = 4;
 * @return {string}
 */
proto.loupe.services.activity.GetActivityTimelineRequest.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.activity.GetActivityTimelineRequest} returns this
 */
proto.loupe.services.activity.GetActivityTimelineRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.activity.GetActivityTimelineResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.activity.GetActivityTimelineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.activity.GetActivityTimelineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.GetActivityTimelineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    src_common_activity_activity_pb.Activity.toObject, includeInstance),
    opportunitiesList: jspb.Message.toObjectList(msg.getOpportunitiesList(),
    proto.loupe.services.activity.OpportunityChange.toObject, includeInstance),
    nextActivityDate: (f = msg.getNextActivityDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    previousActivityDate: (f = msg.getPreviousActivityDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.activity.GetActivityTimelineResponse}
 */
proto.loupe.services.activity.GetActivityTimelineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.activity.GetActivityTimelineResponse;
  return proto.loupe.services.activity.GetActivityTimelineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.activity.GetActivityTimelineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.activity.GetActivityTimelineResponse}
 */
proto.loupe.services.activity.GetActivityTimelineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_activity_activity_pb.Activity;
      reader.readMessage(value,src_common_activity_activity_pb.Activity.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    case 2:
      var value = new proto.loupe.services.activity.OpportunityChange;
      reader.readMessage(value,proto.loupe.services.activity.OpportunityChange.deserializeBinaryFromReader);
      msg.addOpportunities(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNextActivityDate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPreviousActivityDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.activity.GetActivityTimelineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.activity.GetActivityTimelineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.GetActivityTimelineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_activity_activity_pb.Activity.serializeBinaryToWriter
    );
  }
  f = message.getOpportunitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.services.activity.OpportunityChange.serializeBinaryToWriter
    );
  }
  f = message.getNextActivityDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPreviousActivityDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.activity.Activity activities = 1;
 * @return {!Array<!proto.loupe.common.activity.Activity>}
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.loupe.common.activity.Activity>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_activity_activity_pb.Activity, 1));
};


/**
 * @param {!Array<!proto.loupe.common.activity.Activity>} value
 * @return {!proto.loupe.services.activity.GetActivityTimelineResponse} returns this
*/
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.activity.Activity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.activity.Activity}
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.activity.Activity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.activity.GetActivityTimelineResponse} returns this
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};


/**
 * repeated OpportunityChange opportunities = 2;
 * @return {!Array<!proto.loupe.services.activity.OpportunityChange>}
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.getOpportunitiesList = function() {
  return /** @type{!Array<!proto.loupe.services.activity.OpportunityChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.activity.OpportunityChange, 2));
};


/**
 * @param {!Array<!proto.loupe.services.activity.OpportunityChange>} value
 * @return {!proto.loupe.services.activity.GetActivityTimelineResponse} returns this
*/
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.setOpportunitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.services.activity.OpportunityChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.activity.OpportunityChange}
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.addOpportunities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.services.activity.OpportunityChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.activity.GetActivityTimelineResponse} returns this
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.clearOpportunitiesList = function() {
  return this.setOpportunitiesList([]);
};


/**
 * optional google.protobuf.Timestamp next_activity_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.getNextActivityDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.activity.GetActivityTimelineResponse} returns this
*/
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.setNextActivityDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.activity.GetActivityTimelineResponse} returns this
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.clearNextActivityDate = function() {
  return this.setNextActivityDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.hasNextActivityDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp previous_activity_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.getPreviousActivityDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.activity.GetActivityTimelineResponse} returns this
*/
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.setPreviousActivityDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.activity.GetActivityTimelineResponse} returns this
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.clearPreviousActivityDate = function() {
  return this.setPreviousActivityDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.activity.GetActivityTimelineResponse.prototype.hasPreviousActivityDate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.activity.OpportunityChange.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.activity.OpportunityChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.activity.OpportunityChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.OpportunityChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    opportunity: (f = msg.getOpportunity()) && src_common_opportunity_opportunity_pb.Opportunity.toObject(includeInstance, f),
    diff: (f = msg.getDiff()) && src_common_diff_diff_pb.Diff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.activity.OpportunityChange}
 */
proto.loupe.services.activity.OpportunityChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.activity.OpportunityChange;
  return proto.loupe.services.activity.OpportunityChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.activity.OpportunityChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.activity.OpportunityChange}
 */
proto.loupe.services.activity.OpportunityChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_opportunity_opportunity_pb.Opportunity;
      reader.readMessage(value,src_common_opportunity_opportunity_pb.Opportunity.deserializeBinaryFromReader);
      msg.setOpportunity(value);
      break;
    case 2:
      var value = new src_common_diff_diff_pb.Diff;
      reader.readMessage(value,src_common_diff_diff_pb.Diff.deserializeBinaryFromReader);
      msg.setDiff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.activity.OpportunityChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.activity.OpportunityChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.activity.OpportunityChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.OpportunityChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpportunity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_opportunity_opportunity_pb.Opportunity.serializeBinaryToWriter
    );
  }
  f = message.getDiff();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_diff_diff_pb.Diff.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.opportunity.Opportunity opportunity = 1;
 * @return {?proto.loupe.common.opportunity.Opportunity}
 */
proto.loupe.services.activity.OpportunityChange.prototype.getOpportunity = function() {
  return /** @type{?proto.loupe.common.opportunity.Opportunity} */ (
    jspb.Message.getWrapperField(this, src_common_opportunity_opportunity_pb.Opportunity, 1));
};


/**
 * @param {?proto.loupe.common.opportunity.Opportunity|undefined} value
 * @return {!proto.loupe.services.activity.OpportunityChange} returns this
*/
proto.loupe.services.activity.OpportunityChange.prototype.setOpportunity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.activity.OpportunityChange} returns this
 */
proto.loupe.services.activity.OpportunityChange.prototype.clearOpportunity = function() {
  return this.setOpportunity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.activity.OpportunityChange.prototype.hasOpportunity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.diff.Diff diff = 2;
 * @return {?proto.loupe.common.diff.Diff}
 */
proto.loupe.services.activity.OpportunityChange.prototype.getDiff = function() {
  return /** @type{?proto.loupe.common.diff.Diff} */ (
    jspb.Message.getWrapperField(this, src_common_diff_diff_pb.Diff, 2));
};


/**
 * @param {?proto.loupe.common.diff.Diff|undefined} value
 * @return {!proto.loupe.services.activity.OpportunityChange} returns this
*/
proto.loupe.services.activity.OpportunityChange.prototype.setDiff = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.activity.OpportunityChange} returns this
 */
proto.loupe.services.activity.OpportunityChange.prototype.clearDiff = function() {
  return this.setDiff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.activity.OpportunityChange.prototype.hasDiff = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.activity.GetActivityDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.activity.GetActivityDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.GetActivityDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    withFullThread: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    nextPage: jspb.Message.getFieldWithDefault(msg, 5, ""),
    crmType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    partitionTs: (f = msg.getPartitionTs()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.activity.GetActivityDetailsRequest}
 */
proto.loupe.services.activity.GetActivityDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.activity.GetActivityDetailsRequest;
  return proto.loupe.services.activity.GetActivityDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.activity.GetActivityDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.activity.GetActivityDetailsRequest}
 */
proto.loupe.services.activity.GetActivityDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithFullThread(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmType(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPartitionTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.activity.GetActivityDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.activity.GetActivityDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.GetActivityDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWithFullThread();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNextPage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCrmType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPartitionTs();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.activity.GetActivityDetailsRequest} returns this
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string activity_id = 2;
 * @return {string}
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.activity.GetActivityDetailsRequest} returns this
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool with_full_thread = 3;
 * @return {boolean}
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.getWithFullThread = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.activity.GetActivityDetailsRequest} returns this
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.setWithFullThread = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int64 limit = 4;
 * @return {number}
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.activity.GetActivityDetailsRequest} returns this
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string next_page = 5;
 * @return {string}
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.getNextPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.activity.GetActivityDetailsRequest} returns this
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.setNextPage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string crm_type = 6;
 * @return {string}
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.getCrmType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.activity.GetActivityDetailsRequest} returns this
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.setCrmType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp partition_ts = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.getPartitionTs = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.activity.GetActivityDetailsRequest} returns this
*/
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.setPartitionTs = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.activity.GetActivityDetailsRequest} returns this
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.clearPartitionTs = function() {
  return this.setPartitionTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.activity.GetActivityDetailsRequest.prototype.hasPartitionTs = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.activity.GetActivityDetailsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.activity.GetActivityDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.activity.GetActivityDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.activity.GetActivityDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.GetActivityDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activity: (f = msg.getActivity()) && src_common_activity_activity_pb.FullActivity.toObject(includeInstance, f),
    threadList: jspb.Message.toObjectList(msg.getThreadList(),
    src_common_activity_activity_pb.FullActivity.toObject, includeInstance),
    nextPage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.activity.GetActivityDetailsResponse}
 */
proto.loupe.services.activity.GetActivityDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.activity.GetActivityDetailsResponse;
  return proto.loupe.services.activity.GetActivityDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.activity.GetActivityDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.activity.GetActivityDetailsResponse}
 */
proto.loupe.services.activity.GetActivityDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_activity_activity_pb.FullActivity;
      reader.readMessage(value,src_common_activity_activity_pb.FullActivity.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    case 2:
      var value = new src_common_activity_activity_pb.FullActivity;
      reader.readMessage(value,src_common_activity_activity_pb.FullActivity.deserializeBinaryFromReader);
      msg.addThread(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.activity.GetActivityDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.activity.GetActivityDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.activity.GetActivityDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.GetActivityDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_activity_activity_pb.FullActivity.serializeBinaryToWriter
    );
  }
  f = message.getThreadList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_activity_activity_pb.FullActivity.serializeBinaryToWriter
    );
  }
  f = message.getNextPage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional loupe.common.activity.FullActivity activity = 1;
 * @return {?proto.loupe.common.activity.FullActivity}
 */
proto.loupe.services.activity.GetActivityDetailsResponse.prototype.getActivity = function() {
  return /** @type{?proto.loupe.common.activity.FullActivity} */ (
    jspb.Message.getWrapperField(this, src_common_activity_activity_pb.FullActivity, 1));
};


/**
 * @param {?proto.loupe.common.activity.FullActivity|undefined} value
 * @return {!proto.loupe.services.activity.GetActivityDetailsResponse} returns this
*/
proto.loupe.services.activity.GetActivityDetailsResponse.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.activity.GetActivityDetailsResponse} returns this
 */
proto.loupe.services.activity.GetActivityDetailsResponse.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.activity.GetActivityDetailsResponse.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated loupe.common.activity.FullActivity thread = 2;
 * @return {!Array<!proto.loupe.common.activity.FullActivity>}
 */
proto.loupe.services.activity.GetActivityDetailsResponse.prototype.getThreadList = function() {
  return /** @type{!Array<!proto.loupe.common.activity.FullActivity>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_activity_activity_pb.FullActivity, 2));
};


/**
 * @param {!Array<!proto.loupe.common.activity.FullActivity>} value
 * @return {!proto.loupe.services.activity.GetActivityDetailsResponse} returns this
*/
proto.loupe.services.activity.GetActivityDetailsResponse.prototype.setThreadList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.activity.FullActivity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.activity.FullActivity}
 */
proto.loupe.services.activity.GetActivityDetailsResponse.prototype.addThread = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.activity.FullActivity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.activity.GetActivityDetailsResponse} returns this
 */
proto.loupe.services.activity.GetActivityDetailsResponse.prototype.clearThreadList = function() {
  return this.setThreadList([]);
};


/**
 * optional string next_page = 3;
 * @return {string}
 */
proto.loupe.services.activity.GetActivityDetailsResponse.prototype.getNextPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.activity.GetActivityDetailsResponse} returns this
 */
proto.loupe.services.activity.GetActivityDetailsResponse.prototype.setNextPage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.activity.GetActivityDateCursorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.activity.GetActivityDateCursorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pivotTs: (f = msg.getPivotTs()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    directionFilter: jspb.Message.getFieldWithDefault(msg, 4, 0),
    activityOnly: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.activity.GetActivityDateCursorRequest}
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.activity.GetActivityDateCursorRequest;
  return proto.loupe.services.activity.GetActivityDateCursorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.activity.GetActivityDateCursorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.activity.GetActivityDateCursorRequest}
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpportunityId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPivotTs(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.common.activity.ActivityDirection} */ (reader.readEnum());
      msg.setDirectionFilter(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActivityOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.activity.GetActivityDateCursorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.activity.GetActivityDateCursorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpportunityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPivotTs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDirectionFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getActivityOnly();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.activity.GetActivityDateCursorRequest} returns this
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string opportunity_id = 2;
 * @return {string}
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.getOpportunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.activity.GetActivityDateCursorRequest} returns this
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp pivot_ts = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.getPivotTs = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.activity.GetActivityDateCursorRequest} returns this
*/
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.setPivotTs = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.activity.GetActivityDateCursorRequest} returns this
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.clearPivotTs = function() {
  return this.setPivotTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.hasPivotTs = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional loupe.common.activity.ActivityDirection direction_filter = 4;
 * @return {!proto.loupe.common.activity.ActivityDirection}
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.getDirectionFilter = function() {
  return /** @type {!proto.loupe.common.activity.ActivityDirection} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.common.activity.ActivityDirection} value
 * @return {!proto.loupe.services.activity.GetActivityDateCursorRequest} returns this
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.setDirectionFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool activity_only = 5;
 * @return {boolean}
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.getActivityOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.activity.GetActivityDateCursorRequest} returns this
 */
proto.loupe.services.activity.GetActivityDateCursorRequest.prototype.setActivityOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.activity.GetActivityDateCursorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.activity.GetActivityDateCursorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.activity.GetActivityDateCursorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.GetActivityDateCursorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cursorTs: (f = msg.getCursorTs()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.activity.GetActivityDateCursorResponse}
 */
proto.loupe.services.activity.GetActivityDateCursorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.activity.GetActivityDateCursorResponse;
  return proto.loupe.services.activity.GetActivityDateCursorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.activity.GetActivityDateCursorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.activity.GetActivityDateCursorResponse}
 */
proto.loupe.services.activity.GetActivityDateCursorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCursorTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.activity.GetActivityDateCursorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.activity.GetActivityDateCursorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.activity.GetActivityDateCursorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.activity.GetActivityDateCursorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCursorTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp cursor_ts = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.activity.GetActivityDateCursorResponse.prototype.getCursorTs = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.activity.GetActivityDateCursorResponse} returns this
*/
proto.loupe.services.activity.GetActivityDateCursorResponse.prototype.setCursorTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.activity.GetActivityDateCursorResponse} returns this
 */
proto.loupe.services.activity.GetActivityDateCursorResponse.prototype.clearCursorTs = function() {
  return this.setCursorTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.activity.GetActivityDateCursorResponse.prototype.hasCursorTs = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.loupe.services.activity);
