import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { claimNames } from '../constants/claimNames'
import { getClaims } from '../lib/auth0'
import { useRoutes } from './routes'

const AuthContext = React.createContext()

export function GetTenantRegion() {
  let tenantContext
  try {
    tenantContext = JSON.parse(localStorage.getItem('tenantContext'))
  } catch {
    // ignore
  }
  const region = tenantContext?.region ?? ''  
  if (region) {
    return tenantContext.region
  } else {
    const claims = getClaims()
    return claims[claimNames.tenantRegion] || ''
  }
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [tenantContext, setTenantContext] = useState(undefined)
  const { routeTenantId, invalidate } = useRoutes()

  const setTenantContextInternal = useCallback((context, route) => {
    if (context) {
      localStorage.setItem('tenantContext', JSON.stringify(context))
    } else {
      localStorage.removeItem('tenantContext')
      invalidate(route)
    }
    setTenantContext(context)
  }, [invalidate])

  const resetAuth = useCallback(() => {
    setIsAuthenticated(false)
    setTenantContextInternal(undefined)
  }, [setTenantContextInternal])

  useEffect(() => {
    if (routeTenantId) {
      setTenantContext({ id: routeTenantId })
      return
    }
    try {
      const tenContext = localStorage.getItem('tenantContext')
      if (tenContext) {
        setTenantContext(JSON.parse(tenContext))
      }
    } catch (err) {
      console.log('Error parsing tenantContext')
    }
  }, [routeTenantId])

  const tenantId = useMemo(() => {
    if (tenantContext && tenantContext.id) {
      return tenantContext.id
    } else {
      const claims = getClaims()
      return claims[claimNames.tenantId] || ''
    }
  }, [isAuthenticated, tenantContext])

  const contextValue = useMemo(() => {
    return {
      isAuthenticated,
      setIsAuthenticated,
      tenantId,
      tenantContext,
      setTenantContext: setTenantContextInternal,
      resetAuth,
    }
  }, [isAuthenticated, tenantId, tenantContext])

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
