import { Popover } from '@material-ui/core'
import React, { useRef, useState, useMemo, useCallback } from 'react'
import FieldSelectButton from './fieldSelectButton'
import FieldSelectList from './fieldSelectList'
import { ReferenceFieldOptionsLabelsProvider } from '../../../context/referenceFieldOptionsLabels'
import { ReferenceFieldOptionsListProvider } from '../../../context/referenceFieldOptionsList'

const FieldSelect = (props) => {
  const {
    fullWidth = false,
    multi = false,
    onChange,
    selectedDisplay = '',
    getIsSelected,
    values = [],
    fieldReference
  } = props

  const menu = useRef()
  const popoverActions = useRef()

  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClearSearch = useCallback(() => {
    setSearch('')
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const lazyLoad = useMemo(() => {
    return !!fieldReference?.objectName
  }, [fieldReference])

  const lazyLoadValues = useMemo(() => {
    return [...selectedDisplay.split(', ')]
  }, [selectedDisplay])

  return (
    <>
      <ReferenceFieldOptionsLabelsProvider
        isEnabled={lazyLoad}
        fieldReference={fieldReference}
        lazyLoadValues={lazyLoadValues}>
        <FieldSelectButton
          buttonRef={menu}
          fullWidth={fullWidth}
          selectedDisplay={selectedDisplay}
          handleOpen={handleOpen}
          lazyLoad={lazyLoad} />
      </ReferenceFieldOptionsLabelsProvider>
      <ReferenceFieldOptionsListProvider
        limit={50}
        isEnabled={open && lazyLoad}
        fieldReference={fieldReference}
        search={search}>
        <Popover
          action={popoverActions}
          open={open}
          anchorEl={menu.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          TransitionProps={{
            timeout: 0,
            onExited: handleClearSearch
          }}>
          <FieldSelectList
            open={open}
            setOpen={setOpen}
            search={search}
            setSearch={setSearch}
            multi={multi}
            onChange={onChange}
            getIsSelected={getIsSelected}
            values={values}
            lazyLoad={lazyLoad}
            popoverActions={popoverActions} />
        </Popover>
      </ReferenceFieldOptionsListProvider>
    </>
  )
}

export default FieldSelect
