import React from 'react'
import { ObjectDefinitionExtendedProvider } from '../../context/objectDefinitionExtended'
import { ReportingSettingsProvider } from '../../context/reportingSettings'
import { ReportingOptionsSettingsProvider } from '../../context/reportingOptionsSettings'
import AnalyticsSettingsMain from './analyticsSettingsMain'

const AnalyticsSettings = (props) => {
  return (
    <ObjectDefinitionExtendedProvider objectName="opportunity">
      <ReportingSettingsProvider>
        <ReportingOptionsSettingsProvider>
          <AnalyticsSettingsMain />
        </ReportingOptionsSettingsProvider>
      </ReportingSettingsProvider>
    </ObjectDefinitionExtendedProvider>
  )
}

export default AnalyticsSettings
