import React, { useEffect } from 'react'
import ChevronDown from '../../icons/chevronDown'

const FieldSelectItemCategory = (props) => {
  const { children, label, isOpen, measure, onClick } = props

  useEffect(() => {
    measure && measure()
  }, [label])

  return (
    <div
      className="select-item-category"
      onClick={onClick}>
      <div className="select-category-handle">
        {label}
        <ChevronDown rotate={isOpen ? '180' : '0'} />
      </div>
      {children}
    </div>
  )
}

export default FieldSelectItemCategory
