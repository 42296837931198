import React, { useEffect } from 'react'
import { Dialog, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'inherit', // inhert moves the modal content to the top.  the default is vertically centered.
  },
  paper: {
    borderRadius: 10,
  },
}))

const Modal = (props) => {
  const {
    maxWidth = 'md',
    open,
    handleClose,
    children,
    ...rest
  } = props

  const classes = useStyles()

  useEffect(() => {
    if (open) {
      const documentWidth = document.documentElement.clientWidth
      const windowWidth = window.innerWidth
      const scrollBarWidth = windowWidth - documentWidth
      document.body.style.paddingRight = `${scrollBarWidth}px`
      document.body.classList.add('no-scrollbar')
    } else {
      document.body.style.paddingRight = 0
      document.body.classList.remove('no-scrollbar')
    }
  }, [open])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      disableScrollLock={true}
      scroll="paper"
      BackdropProps={{
        style: {
          pointerEvents: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          // backdropFilter: 'blur(3px)',
        },
      }}
      classes={{
        container: classes.container,
        paper: classes.paper,
      }}
      {...rest}>
      {open && (
        <div className="pointer-events-auto">
          {children}
        </div>
      )}
    </Dialog>
  )
}

export default Modal
