import { useTenantInfo } from '../../context/tenantInfo'
import classNames from 'classnames'
import React from 'react'
import SandboxBanner from '../sandbox/banner'
import SideMenu from '../menus/sideMenu'
import VersionChecker from '../common/versionChecker'
import MainTemplateErrorBoundary from './mainTemplateErrorBoundary'

const MainTemplate = ({ component: Component, dock: Dock, ...rest }) => {
  const { isSandbox } = useTenantInfo()

  return (
    <div className="flex flex-wrap flex-col items-stretch md:min-h-screen">
      <SandboxBanner />
      <div className={
        classNames(
          'flex items-stretch md:min-h-screen',
          { 'pt-16': isSandbox }
        )
      }>
        <VersionChecker />
        <SideMenu />
        <div className="flex-grow md:flex-grow-0 md:w-4/5">
          <MainTemplateErrorBoundary key={rest.history.location.pathname}>
            <Component {...rest} />
          </MainTemplateErrorBoundary>
        </div>
      </div>
    </div>
  )
}

export default MainTemplate
