// source: src/services/crm-data-access/crm-data-access.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_signal_signal_pb = require('../../../src/common/signal/signal_pb.js');
goog.object.extend(proto, src_common_signal_signal_pb);
var src_common_insight_insight_pb = require('../../../src/common/insight/insight_pb.js');
goog.object.extend(proto, src_common_insight_insight_pb);
var src_common_notification_audit_pb = require('../../../src/common/notification/audit_pb.js');
goog.object.extend(proto, src_common_notification_audit_pb);
var src_common_setting_notification_notification_pb = require('../../../src/common/setting/notification/notification_pb.js');
goog.object.extend(proto, src_common_setting_notification_notification_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_forecast_forecast_entry_pb = require('../../../src/common/forecast/forecast_entry_pb.js');
goog.object.extend(proto, src_common_forecast_forecast_entry_pb);
var src_common_forecast_forecast_item_pb = require('../../../src/common/forecast/forecast_item_pb.js');
goog.object.extend(proto, src_common_forecast_forecast_item_pb);
var src_common_opportunity_opportunity_pb = require('../../../src/common/opportunity/opportunity_pb.js');
goog.object.extend(proto, src_common_opportunity_opportunity_pb);
var src_common_orchard_person_pb = require('../../../src/common/orchard/person_pb.js');
goog.object.extend(proto, src_common_orchard_person_pb);
var src_common_orchard_crm_role_pb = require('../../../src/common/orchard/crm_role_pb.js');
goog.object.extend(proto, src_common_orchard_crm_role_pb);
goog.exportSymbol('proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.FetchInsightsResponse', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.FetchNotificationAuditRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.FetchNotificationAuditResponse', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.FetchSignalFilters', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.FetchSignalsRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.FetchSignalsResponse', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.ForecastListForTenantRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.ForecastListForTenantResponse', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.ForecastListItem', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.ForecastListRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.ForecastListTimeGroup', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.GetForecastRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.GetLatestPeopleRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.GetLatestPeopleResponse', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.NotificationAuditWrapper', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.NotificationAuditWrappers', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.NotificationDataTypes', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.SaveForecastEntryRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.SaveForecastItemsRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.WriteNotificationAuditRequest', null, global);
goog.exportSymbol('proto.loupe.services.crm_data_access.WriteNotificationAuditResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.FetchSignalFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.crm_data_access.FetchSignalFilters.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.crm_data_access.FetchSignalFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.FetchSignalFilters.displayName = 'proto.loupe.services.crm_data_access.FetchSignalFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.FetchSignalsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.FetchSignalsRequest.displayName = 'proto.loupe.services.crm_data_access.FetchSignalsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.crm_data_access.FetchSignalsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.crm_data_access.FetchSignalsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.FetchSignalsResponse.displayName = 'proto.loupe.services.crm_data_access.FetchSignalsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.crm_data_access.FetchInsightsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.crm_data_access.FetchInsightsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.FetchInsightsResponse.displayName = 'proto.loupe.services.crm_data_access.FetchInsightsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.WriteNotificationAuditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.displayName = 'proto.loupe.services.crm_data_access.WriteNotificationAuditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.WriteNotificationAuditResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.WriteNotificationAuditResponse.displayName = 'proto.loupe.services.crm_data_access.WriteNotificationAuditResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.displayName = 'proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.displayName = 'proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.NotificationAuditWrappers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.crm_data_access.NotificationAuditWrappers.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.crm_data_access.NotificationAuditWrappers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.NotificationAuditWrappers.displayName = 'proto.loupe.services.crm_data_access.NotificationAuditWrappers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.NotificationAuditWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.NotificationAuditWrapper.displayName = 'proto.loupe.services.crm_data_access.NotificationAuditWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.FetchNotificationAuditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.displayName = 'proto.loupe.services.crm_data_access.FetchNotificationAuditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.crm_data_access.FetchNotificationAuditResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.displayName = 'proto.loupe.services.crm_data_access.FetchNotificationAuditResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.GetForecastRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.GetForecastRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.GetForecastRequest.displayName = 'proto.loupe.services.crm_data_access.GetForecastRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.ForecastListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.ForecastListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.ForecastListRequest.displayName = 'proto.loupe.services.crm_data_access.ForecastListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.ForecastListForTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.ForecastListForTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.ForecastListForTenantRequest.displayName = 'proto.loupe.services.crm_data_access.ForecastListForTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.ForecastListForTenantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.ForecastListForTenantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.ForecastListForTenantResponse.displayName = 'proto.loupe.services.crm_data_access.ForecastListForTenantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.SaveForecastItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.SaveForecastItemsRequest.displayName = 'proto.loupe.services.crm_data_access.SaveForecastItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.SaveForecastEntryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.SaveForecastEntryRequest.displayName = 'proto.loupe.services.crm_data_access.SaveForecastEntryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.ForecastListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.crm_data_access.ForecastListItem.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.crm_data_access.ForecastListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.ForecastListItem.displayName = 'proto.loupe.services.crm_data_access.ForecastListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.ForecastListTimeGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.ForecastListTimeGroup.displayName = 'proto.loupe.services.crm_data_access.ForecastListTimeGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.displayName = 'proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse.displayName = 'proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.displayName = 'proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse.displayName = 'proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.displayName = 'proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.displayName = 'proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.GetLatestPeopleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.GetLatestPeopleRequest.displayName = 'proto.loupe.services.crm_data_access.GetLatestPeopleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.crm_data_access.GetLatestPeopleResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.crm_data_access.GetLatestPeopleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.GetLatestPeopleResponse.displayName = 'proto.loupe.services.crm_data_access.GetLatestPeopleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.displayName = 'proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.displayName = 'proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.FetchSignalFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.FetchSignalFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.toObject = function(includeInstance, msg) {
  var f, obj = {
    txId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fingerprint: jspb.Message.getFieldWithDefault(msg, 3, ""),
    category: jspb.Message.getFieldWithDefault(msg, 4, ""),
    level: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    statusExclusionList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    importance: jspb.Message.getFieldWithDefault(msg, 9, 0),
    idFingerprintMapMap: (f = msg.getIdFingerprintMapMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.FetchSignalFilters;
  return proto.loupe.services.crm_data_access.FetchSignalFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.FetchSignalFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFingerprint(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addStatusExclusion(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImportance(value);
      break;
    case 10:
      var value = msg.getIdFingerprintMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.FetchSignalFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.FetchSignalFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFingerprint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLevel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatusExclusionList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getImportance();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getIdFingerprintMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string tx_id = 1;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fingerprint = 3;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.getFingerprint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.setFingerprint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string category = 4;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string level = 5;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.getLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.setLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string status_exclusion = 8;
 * @return {!Array<string>}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.getStatusExclusionList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.setStatusExclusionList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.addStatusExclusion = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.clearStatusExclusionList = function() {
  return this.setStatusExclusionList([]);
};


/**
 * optional int64 importance = 9;
 * @return {number}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.getImportance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.setImportance = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * map<string, string> id_fingerprint_map = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.getIdFingerprintMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.crm_data_access.FetchSignalFilters} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalFilters.prototype.clearIdFingerprintMapMap = function() {
  this.getIdFingerprintMapMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.FetchSignalsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.FetchSignalsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filters: (f = msg.getFilters()) && proto.loupe.services.crm_data_access.FetchSignalFilters.toObject(includeInstance, f),
    pageToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsRequest}
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.FetchSignalsRequest;
  return proto.loupe.services.crm_data_access.FetchSignalsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.FetchSignalsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsRequest}
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 3:
      var value = new proto.loupe.services.crm_data_access.FetchSignalFilters;
      reader.readMessage(value,proto.loupe.services.crm_data_access.FetchSignalFilters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.FetchSignalsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.FetchSignalsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.loupe.services.crm_data_access.FetchSignalFilters.serializeBinaryToWriter
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tx_id = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FetchSignalFilters filters = 3;
 * @return {?proto.loupe.services.crm_data_access.FetchSignalFilters}
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.getFilters = function() {
  return /** @type{?proto.loupe.services.crm_data_access.FetchSignalFilters} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.crm_data_access.FetchSignalFilters, 3));
};


/**
 * @param {?proto.loupe.services.crm_data_access.FetchSignalFilters|undefined} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsRequest} returns this
*/
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string page_token = 4;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 limit = 5;
 * @return {number}
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.FetchSignalsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.FetchSignalsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    signalsList: jspb.Message.toObjectList(msg.getSignalsList(),
    src_common_signal_signal_pb.Signal.toObject, includeInstance),
    nextPage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsResponse}
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.FetchSignalsResponse;
  return proto.loupe.services.crm_data_access.FetchSignalsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.FetchSignalsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsResponse}
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_signal_signal_pb.Signal;
      reader.readMessage(value,src_common_signal_signal_pb.Signal.deserializeBinaryFromReader);
      msg.addSignals(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.FetchSignalsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.FetchSignalsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_signal_signal_pb.Signal.serializeBinaryToWriter
    );
  }
  f = message.getNextPage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated loupe.common.signal.Signal signals = 1;
 * @return {!Array<!proto.loupe.common.signal.Signal>}
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse.prototype.getSignalsList = function() {
  return /** @type{!Array<!proto.loupe.common.signal.Signal>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_signal_signal_pb.Signal, 1));
};


/**
 * @param {!Array<!proto.loupe.common.signal.Signal>} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsResponse} returns this
*/
proto.loupe.services.crm_data_access.FetchSignalsResponse.prototype.setSignalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.signal.Signal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.signal.Signal}
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse.prototype.addSignals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.signal.Signal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsResponse} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse.prototype.clearSignalsList = function() {
  return this.setSignalsList([]);
};


/**
 * optional string next_page = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse.prototype.getNextPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchSignalsResponse} returns this
 */
proto.loupe.services.crm_data_access.FetchSignalsResponse.prototype.setNextPage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.FetchInsightsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.FetchInsightsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    insightsList: jspb.Message.toObjectList(msg.getInsightsList(),
    src_common_insight_insight_pb.Insight.toObject, includeInstance),
    nextPage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.FetchInsightsResponse}
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.FetchInsightsResponse;
  return proto.loupe.services.crm_data_access.FetchInsightsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.FetchInsightsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.FetchInsightsResponse}
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_insight_insight_pb.Insight;
      reader.readMessage(value,src_common_insight_insight_pb.Insight.deserializeBinaryFromReader);
      msg.addInsights(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.FetchInsightsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.FetchInsightsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInsightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_insight_insight_pb.Insight.serializeBinaryToWriter
    );
  }
  f = message.getNextPage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated loupe.common.insight.Insight insights = 1;
 * @return {!Array<!proto.loupe.common.insight.Insight>}
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse.prototype.getInsightsList = function() {
  return /** @type{!Array<!proto.loupe.common.insight.Insight>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_insight_insight_pb.Insight, 1));
};


/**
 * @param {!Array<!proto.loupe.common.insight.Insight>} value
 * @return {!proto.loupe.services.crm_data_access.FetchInsightsResponse} returns this
*/
proto.loupe.services.crm_data_access.FetchInsightsResponse.prototype.setInsightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.insight.Insight=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.insight.Insight}
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse.prototype.addInsights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.insight.Insight, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.crm_data_access.FetchInsightsResponse} returns this
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse.prototype.clearInsightsList = function() {
  return this.setInsightsList([]);
};


/**
 * optional string next_page = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse.prototype.getNextPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchInsightsResponse} returns this
 */
proto.loupe.services.crm_data_access.FetchInsightsResponse.prototype.setNextPage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.WriteNotificationAuditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    audit: (f = msg.getAudit()) && src_common_notification_audit_pb.NotificationAudit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.WriteNotificationAuditRequest}
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.WriteNotificationAuditRequest;
  return proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.WriteNotificationAuditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.WriteNotificationAuditRequest}
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_notification_audit_pb.NotificationAudit;
      reader.readMessage(value,src_common_notification_audit_pb.NotificationAudit.deserializeBinaryFromReader);
      msg.setAudit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.WriteNotificationAuditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_notification_audit_pb.NotificationAudit.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.notification.NotificationAudit audit = 1;
 * @return {?proto.loupe.common.notification.NotificationAudit}
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.prototype.getAudit = function() {
  return /** @type{?proto.loupe.common.notification.NotificationAudit} */ (
    jspb.Message.getWrapperField(this, src_common_notification_audit_pb.NotificationAudit, 1));
};


/**
 * @param {?proto.loupe.common.notification.NotificationAudit|undefined} value
 * @return {!proto.loupe.services.crm_data_access.WriteNotificationAuditRequest} returns this
*/
proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.prototype.setAudit = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.WriteNotificationAuditRequest} returns this
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.prototype.clearAudit = function() {
  return this.setAudit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditRequest.prototype.hasAudit = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.WriteNotificationAuditResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.WriteNotificationAuditResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.WriteNotificationAuditResponse}
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.WriteNotificationAuditResponse;
  return proto.loupe.services.crm_data_access.WriteNotificationAuditResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.WriteNotificationAuditResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.WriteNotificationAuditResponse}
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.WriteNotificationAuditResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.WriteNotificationAuditResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.WriteNotificationAuditResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataTypesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    onlyNew: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    pageToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 6, 0),
    filters: (f = msg.getFilters()) && proto.loupe.services.crm_data_access.FetchSignalFilters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest;
  return proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDataTypes(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyNew(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 7:
      var value = new proto.loupe.services.crm_data_access.FetchSignalFilters;
      reader.readMessage(value,proto.loupe.services.crm_data_access.FetchSignalFilters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOnlyNew();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.services.crm_data_access.FetchSignalFilters.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tx_id = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string data_types = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.getDataTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.setDataTypesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.addDataTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.clearDataTypesList = function() {
  return this.setDataTypesList([]);
};


/**
 * optional bool only_new = 4;
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.getOnlyNew = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.setOnlyNew = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string page_token = 5;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 limit = 6;
 * @return {number}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional FetchSignalFilters filters = 7;
 * @return {?proto.loupe.services.crm_data_access.FetchSignalFilters}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.getFilters = function() {
  return /** @type{?proto.loupe.services.crm_data_access.FetchSignalFilters} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.crm_data_access.FetchSignalFilters, 7));
};


/**
 * @param {?proto.loupe.services.crm_data_access.FetchSignalFilters|undefined} value
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} returns this
*/
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsByDataTypeMap: (f = msg.getResultsByDataTypeMap()) ? f.toObject(includeInstance, proto.loupe.services.crm_data_access.NotificationAuditWrappers.toObject) : [],
    nextPage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse;
  return proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getResultsByDataTypeMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.services.crm_data_access.NotificationAuditWrappers.deserializeBinaryFromReader, "", new proto.loupe.services.crm_data_access.NotificationAuditWrappers());
         });
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsByDataTypeMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.services.crm_data_access.NotificationAuditWrappers.serializeBinaryToWriter);
  }
  f = message.getNextPage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * map<string, NotificationAuditWrappers> results_by_data_type = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.services.crm_data_access.NotificationAuditWrappers>}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.prototype.getResultsByDataTypeMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.services.crm_data_access.NotificationAuditWrappers>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.loupe.services.crm_data_access.NotificationAuditWrappers));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse} returns this
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.prototype.clearResultsByDataTypeMap = function() {
  this.getResultsByDataTypeMap().clear();
  return this;};


/**
 * optional string next_page = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.prototype.getNextPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse} returns this
 */
proto.loupe.services.crm_data_access.FetchDeliverableNotificationsResponse.prototype.setNextPage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.crm_data_access.NotificationAuditWrappers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrappers.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.NotificationAuditWrappers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.NotificationAuditWrappers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.NotificationAuditWrappers.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.loupe.services.crm_data_access.NotificationAuditWrapper.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrappers}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrappers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.NotificationAuditWrappers;
  return proto.loupe.services.crm_data_access.NotificationAuditWrappers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.NotificationAuditWrappers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrappers}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrappers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.crm_data_access.NotificationAuditWrapper;
      reader.readMessage(value,proto.loupe.services.crm_data_access.NotificationAuditWrapper.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrappers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.NotificationAuditWrappers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.NotificationAuditWrappers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.NotificationAuditWrappers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.crm_data_access.NotificationAuditWrapper.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NotificationAuditWrapper values = 1;
 * @return {!Array<!proto.loupe.services.crm_data_access.NotificationAuditWrapper>}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrappers.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.loupe.services.crm_data_access.NotificationAuditWrapper>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.crm_data_access.NotificationAuditWrapper, 1));
};


/**
 * @param {!Array<!proto.loupe.services.crm_data_access.NotificationAuditWrapper>} value
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrappers} returns this
*/
proto.loupe.services.crm_data_access.NotificationAuditWrappers.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.crm_data_access.NotificationAuditWrapper=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrapper}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrappers.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.crm_data_access.NotificationAuditWrapper, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrappers} returns this
 */
proto.loupe.services.crm_data_access.NotificationAuditWrappers.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.NotificationAuditWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.NotificationAuditWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    shellAudit: (f = msg.getShellAudit()) && src_common_notification_audit_pb.NotificationAudit.toObject(includeInstance, f),
    lastDeliveredAt: (f = msg.getLastDeliveredAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    notificationSetting: (f = msg.getNotificationSetting()) && src_common_setting_notification_notification_pb.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrapper}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.NotificationAuditWrapper;
  return proto.loupe.services.crm_data_access.NotificationAuditWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.NotificationAuditWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrapper}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_notification_audit_pb.NotificationAudit;
      reader.readMessage(value,src_common_notification_audit_pb.NotificationAudit.deserializeBinaryFromReader);
      msg.setShellAudit(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastDeliveredAt(value);
      break;
    case 3:
      var value = new src_common_setting_notification_notification_pb.Notification;
      reader.readMessage(value,src_common_setting_notification_notification_pb.Notification.deserializeBinaryFromReader);
      msg.setNotificationSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.NotificationAuditWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.NotificationAuditWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShellAudit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_notification_audit_pb.NotificationAudit.serializeBinaryToWriter
    );
  }
  f = message.getLastDeliveredAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNotificationSetting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_setting_notification_notification_pb.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.notification.NotificationAudit shell_audit = 1;
 * @return {?proto.loupe.common.notification.NotificationAudit}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.getShellAudit = function() {
  return /** @type{?proto.loupe.common.notification.NotificationAudit} */ (
    jspb.Message.getWrapperField(this, src_common_notification_audit_pb.NotificationAudit, 1));
};


/**
 * @param {?proto.loupe.common.notification.NotificationAudit|undefined} value
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrapper} returns this
*/
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.setShellAudit = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrapper} returns this
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.clearShellAudit = function() {
  return this.setShellAudit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.hasShellAudit = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp last_delivered_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.getLastDeliveredAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrapper} returns this
*/
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.setLastDeliveredAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrapper} returns this
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.clearLastDeliveredAt = function() {
  return this.setLastDeliveredAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.hasLastDeliveredAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional loupe.common.setting.notification.Notification notification_setting = 3;
 * @return {?proto.loupe.common.setting.notification.Notification}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.getNotificationSetting = function() {
  return /** @type{?proto.loupe.common.setting.notification.Notification} */ (
    jspb.Message.getWrapperField(this, src_common_setting_notification_notification_pb.Notification, 3));
};


/**
 * @param {?proto.loupe.common.setting.notification.Notification|undefined} value
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrapper} returns this
*/
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.setNotificationSetting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.NotificationAuditWrapper} returns this
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.clearNotificationSetting = function() {
  return this.setNotificationSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.NotificationAuditWrapper.prototype.hasNotificationSetting = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.FetchNotificationAuditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.FetchNotificationAuditRequest}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.FetchNotificationAuditRequest;
  return proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.FetchNotificationAuditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.FetchNotificationAuditRequest}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.FetchNotificationAuditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchNotificationAuditRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tx_id = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchNotificationAuditRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.FetchNotificationAuditRequest} returns this
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.FetchNotificationAuditResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    current: (f = msg.getCurrent()) && src_common_notification_audit_pb.NotificationAudit.toObject(includeInstance, f),
    trailList: jspb.Message.toObjectList(msg.getTrailList(),
    src_common_notification_audit_pb.NotificationAudit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.FetchNotificationAuditResponse}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.FetchNotificationAuditResponse;
  return proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.FetchNotificationAuditResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.FetchNotificationAuditResponse}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_notification_audit_pb.NotificationAudit;
      reader.readMessage(value,src_common_notification_audit_pb.NotificationAudit.deserializeBinaryFromReader);
      msg.setCurrent(value);
      break;
    case 2:
      var value = new src_common_notification_audit_pb.NotificationAudit;
      reader.readMessage(value,src_common_notification_audit_pb.NotificationAudit.deserializeBinaryFromReader);
      msg.addTrail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.FetchNotificationAuditResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_notification_audit_pb.NotificationAudit.serializeBinaryToWriter
    );
  }
  f = message.getTrailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_notification_audit_pb.NotificationAudit.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.notification.NotificationAudit current = 1;
 * @return {?proto.loupe.common.notification.NotificationAudit}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.prototype.getCurrent = function() {
  return /** @type{?proto.loupe.common.notification.NotificationAudit} */ (
    jspb.Message.getWrapperField(this, src_common_notification_audit_pb.NotificationAudit, 1));
};


/**
 * @param {?proto.loupe.common.notification.NotificationAudit|undefined} value
 * @return {!proto.loupe.services.crm_data_access.FetchNotificationAuditResponse} returns this
*/
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.prototype.setCurrent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.FetchNotificationAuditResponse} returns this
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.prototype.clearCurrent = function() {
  return this.setCurrent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.prototype.hasCurrent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated loupe.common.notification.NotificationAudit trail = 2;
 * @return {!Array<!proto.loupe.common.notification.NotificationAudit>}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.prototype.getTrailList = function() {
  return /** @type{!Array<!proto.loupe.common.notification.NotificationAudit>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_notification_audit_pb.NotificationAudit, 2));
};


/**
 * @param {!Array<!proto.loupe.common.notification.NotificationAudit>} value
 * @return {!proto.loupe.services.crm_data_access.FetchNotificationAuditResponse} returns this
*/
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.prototype.setTrailList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.notification.NotificationAudit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.notification.NotificationAudit}
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.prototype.addTrail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.notification.NotificationAudit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.crm_data_access.FetchNotificationAuditResponse} returns this
 */
proto.loupe.services.crm_data_access.FetchNotificationAuditResponse.prototype.clearTrailList = function() {
  return this.setTrailList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.GetForecastRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.GetForecastRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.GetForecastRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetForecastRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    submittedAt: (f = msg.getSubmittedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.GetForecastRequest}
 */
proto.loupe.services.crm_data_access.GetForecastRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.GetForecastRequest;
  return proto.loupe.services.crm_data_access.GetForecastRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.GetForecastRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.GetForecastRequest}
 */
proto.loupe.services.crm_data_access.GetForecastRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSubmittedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.GetForecastRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.GetForecastRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.GetForecastRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetForecastRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubmittedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetForecastRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetForecastRequest} returns this
 */
proto.loupe.services.crm_data_access.GetForecastRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp submitted_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.crm_data_access.GetForecastRequest.prototype.getSubmittedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.crm_data_access.GetForecastRequest} returns this
*/
proto.loupe.services.crm_data_access.GetForecastRequest.prototype.setSubmittedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.GetForecastRequest} returns this
 */
proto.loupe.services.crm_data_access.GetForecastRequest.prototype.clearSubmittedAt = function() {
  return this.setSubmittedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.GetForecastRequest.prototype.hasSubmittedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetForecastRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetForecastRequest} returns this
 */
proto.loupe.services.crm_data_access.GetForecastRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tx_id = 4;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetForecastRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetForecastRequest} returns this
 */
proto.loupe.services.crm_data_access.GetForecastRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.ForecastListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.ForecastListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.ForecastListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeCurrent: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    pagingToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tenantId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.ForecastListRequest}
 */
proto.loupe.services.crm_data_access.ForecastListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.ForecastListRequest;
  return proto.loupe.services.crm_data_access.ForecastListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.ForecastListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.ForecastListRequest}
 */
proto.loupe.services.crm_data_access.ForecastListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCurrent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPagingToken(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.ForecastListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.ForecastListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.ForecastListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeCurrent();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPagingToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.ForecastListRequest} returns this
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_current = 2;
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.getIncludeCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.crm_data_access.ForecastListRequest} returns this
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.setIncludeCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string paging_token = 3;
 * @return {string}
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.getPagingToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.ForecastListRequest} returns this
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.setPagingToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 limit = 4;
 * @return {number}
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.crm_data_access.ForecastListRequest} returns this
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string tenant_id = 5;
 * @return {string}
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.ForecastListRequest} returns this
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tx_id = 6;
 * @return {string}
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.ForecastListRequest} returns this
 */
proto.loupe.services.crm_data_access.ForecastListRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.ForecastListForTenantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.ForecastListForTenantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.ForecastListForTenantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.ForecastListForTenantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.ForecastListForTenantRequest}
 */
proto.loupe.services.crm_data_access.ForecastListForTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.ForecastListForTenantRequest;
  return proto.loupe.services.crm_data_access.ForecastListForTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.ForecastListForTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.ForecastListForTenantRequest}
 */
proto.loupe.services.crm_data_access.ForecastListForTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.ForecastListForTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.ForecastListForTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.ForecastListForTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.ForecastListForTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.crm_data_access.ForecastListForTenantRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.ForecastListForTenantRequest} returns this
 */
proto.loupe.services.crm_data_access.ForecastListForTenantRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tx_id = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.ForecastListForTenantRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.ForecastListForTenantRequest} returns this
 */
proto.loupe.services.crm_data_access.ForecastListForTenantRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.ForecastListForTenantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.ForecastListForTenantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.ForecastListForTenantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.ForecastListForTenantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, proto.loupe.services.crm_data_access.ForecastListItem.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.ForecastListForTenantResponse}
 */
proto.loupe.services.crm_data_access.ForecastListForTenantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.ForecastListForTenantResponse;
  return proto.loupe.services.crm_data_access.ForecastListForTenantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.ForecastListForTenantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.ForecastListForTenantResponse}
 */
proto.loupe.services.crm_data_access.ForecastListForTenantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.services.crm_data_access.ForecastListItem.deserializeBinaryFromReader, "", new proto.loupe.services.crm_data_access.ForecastListItem());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.ForecastListForTenantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.ForecastListForTenantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.ForecastListForTenantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.ForecastListForTenantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.services.crm_data_access.ForecastListItem.serializeBinaryToWriter);
  }
};


/**
 * map<string, ForecastListItem> items = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.services.crm_data_access.ForecastListItem>}
 */
proto.loupe.services.crm_data_access.ForecastListForTenantResponse.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.services.crm_data_access.ForecastListItem>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.loupe.services.crm_data_access.ForecastListItem));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.crm_data_access.ForecastListForTenantResponse} returns this
 */
proto.loupe.services.crm_data_access.ForecastListForTenantResponse.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.SaveForecastItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.SaveForecastItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    items: (f = msg.getItems()) && src_common_forecast_forecast_item_pb.ForecastItemList.toObject(includeInstance, f),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.SaveForecastItemsRequest}
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.SaveForecastItemsRequest;
  return proto.loupe.services.crm_data_access.SaveForecastItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.SaveForecastItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.SaveForecastItemsRequest}
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_forecast_forecast_item_pb.ForecastItemList;
      reader.readMessage(value,src_common_forecast_forecast_item_pb.ForecastItemList.deserializeBinaryFromReader);
      msg.setItems(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.SaveForecastItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.SaveForecastItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItems();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_forecast_forecast_item_pb.ForecastItemList.serializeBinaryToWriter
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional loupe.common.forecast.ForecastItemList items = 1;
 * @return {?proto.loupe.common.forecast.ForecastItemList}
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.prototype.getItems = function() {
  return /** @type{?proto.loupe.common.forecast.ForecastItemList} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_forecast_item_pb.ForecastItemList, 1));
};


/**
 * @param {?proto.loupe.common.forecast.ForecastItemList|undefined} value
 * @return {!proto.loupe.services.crm_data_access.SaveForecastItemsRequest} returns this
*/
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.prototype.setItems = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.SaveForecastItemsRequest} returns this
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.prototype.clearItems = function() {
  return this.setItems(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.prototype.hasItems = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.SaveForecastItemsRequest} returns this
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tx_id = 3;
 * @return {string}
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.SaveForecastItemsRequest} returns this
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string owner_id = 4;
 * @return {string}
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.SaveForecastItemsRequest} returns this
 */
proto.loupe.services.crm_data_access.SaveForecastItemsRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.SaveForecastEntryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.SaveForecastEntryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entry: (f = msg.getEntry()) && src_common_forecast_forecast_entry_pb.ForecastEntry.toObject(includeInstance, f),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.SaveForecastEntryRequest}
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.SaveForecastEntryRequest;
  return proto.loupe.services.crm_data_access.SaveForecastEntryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.SaveForecastEntryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.SaveForecastEntryRequest}
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_forecast_forecast_entry_pb.ForecastEntry;
      reader.readMessage(value,src_common_forecast_forecast_entry_pb.ForecastEntry.deserializeBinaryFromReader);
      msg.setEntry(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.SaveForecastEntryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.SaveForecastEntryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntry();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_forecast_forecast_entry_pb.ForecastEntry.serializeBinaryToWriter
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional loupe.common.forecast.ForecastEntry entry = 1;
 * @return {?proto.loupe.common.forecast.ForecastEntry}
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.prototype.getEntry = function() {
  return /** @type{?proto.loupe.common.forecast.ForecastEntry} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_forecast_entry_pb.ForecastEntry, 1));
};


/**
 * @param {?proto.loupe.common.forecast.ForecastEntry|undefined} value
 * @return {!proto.loupe.services.crm_data_access.SaveForecastEntryRequest} returns this
*/
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.prototype.setEntry = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.SaveForecastEntryRequest} returns this
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.prototype.clearEntry = function() {
  return this.setEntry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.prototype.hasEntry = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.SaveForecastEntryRequest} returns this
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tx_id = 3;
 * @return {string}
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.SaveForecastEntryRequest} returns this
 */
proto.loupe.services.crm_data_access.SaveForecastEntryRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.crm_data_access.ForecastListItem.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.ForecastListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.ForecastListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.ForecastListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.ForecastListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.loupe.services.crm_data_access.ForecastListTimeGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.ForecastListItem}
 */
proto.loupe.services.crm_data_access.ForecastListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.ForecastListItem;
  return proto.loupe.services.crm_data_access.ForecastListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.ForecastListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.ForecastListItem}
 */
proto.loupe.services.crm_data_access.ForecastListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.crm_data_access.ForecastListTimeGroup;
      reader.readMessage(value,proto.loupe.services.crm_data_access.ForecastListTimeGroup.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.ForecastListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.ForecastListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.ForecastListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.ForecastListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.crm_data_access.ForecastListTimeGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ForecastListTimeGroup groups = 1;
 * @return {!Array<!proto.loupe.services.crm_data_access.ForecastListTimeGroup>}
 */
proto.loupe.services.crm_data_access.ForecastListItem.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.loupe.services.crm_data_access.ForecastListTimeGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.crm_data_access.ForecastListTimeGroup, 1));
};


/**
 * @param {!Array<!proto.loupe.services.crm_data_access.ForecastListTimeGroup>} value
 * @return {!proto.loupe.services.crm_data_access.ForecastListItem} returns this
*/
proto.loupe.services.crm_data_access.ForecastListItem.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.crm_data_access.ForecastListTimeGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.crm_data_access.ForecastListTimeGroup}
 */
proto.loupe.services.crm_data_access.ForecastListItem.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.crm_data_access.ForecastListTimeGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.crm_data_access.ForecastListItem} returns this
 */
proto.loupe.services.crm_data_access.ForecastListItem.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.ForecastListTimeGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.ForecastListTimeGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    ts: (f = msg.getTs()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    entry: (f = msg.getEntry()) && src_common_forecast_forecast_entry_pb.ForecastEntry.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.ForecastListTimeGroup}
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.ForecastListTimeGroup;
  return proto.loupe.services.crm_data_access.ForecastListTimeGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.ForecastListTimeGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.ForecastListTimeGroup}
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTs(value);
      break;
    case 2:
      var value = new src_common_forecast_forecast_entry_pb.ForecastEntry;
      reader.readMessage(value,src_common_forecast_forecast_entry_pb.ForecastEntry.deserializeBinaryFromReader);
      msg.setEntry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.ForecastListTimeGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.ForecastListTimeGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEntry();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_forecast_forecast_entry_pb.ForecastEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp ts = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup.prototype.getTs = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.crm_data_access.ForecastListTimeGroup} returns this
*/
proto.loupe.services.crm_data_access.ForecastListTimeGroup.prototype.setTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.ForecastListTimeGroup} returns this
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup.prototype.clearTs = function() {
  return this.setTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup.prototype.hasTs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.forecast.ForecastEntry entry = 2;
 * @return {?proto.loupe.common.forecast.ForecastEntry}
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup.prototype.getEntry = function() {
  return /** @type{?proto.loupe.common.forecast.ForecastEntry} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_forecast_entry_pb.ForecastEntry, 2));
};


/**
 * @param {?proto.loupe.common.forecast.ForecastEntry|undefined} value
 * @return {!proto.loupe.services.crm_data_access.ForecastListTimeGroup} returns this
*/
proto.loupe.services.crm_data_access.ForecastListTimeGroup.prototype.setEntry = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.ForecastListTimeGroup} returns this
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup.prototype.clearEntry = function() {
  return this.setEntry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.ForecastListTimeGroup.prototype.hasEntry = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest}
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest;
  return proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest}
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest} returns this
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_id = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.prototype.getObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest} returns this
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.prototype.setObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string object_name = 3;
 * @return {string}
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest} returns this
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tx_id = 4;
 * @return {string}
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest} returns this
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse}
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse;
  return proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse}
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.HardDeleteByObjectTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest}
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest;
  return proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest}
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest} returns this
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_id = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.prototype.getObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest} returns this
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.prototype.setObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string object_name = 3;
 * @return {string}
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest} returns this
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tx_id = 4;
 * @return {string}
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest} returns this
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse}
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse;
  return proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse}
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.SoftDeleteByObjectTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    idsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    viewAt: (f = msg.getViewAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest;
  return proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setViewAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getViewAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} returns this
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tx_id = 2;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} returns this
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} returns this
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 limit = 4;
 * @return {number}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} returns this
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated string ids = 5;
 * @return {!Array<string>}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} returns this
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} returns this
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} returns this
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional google.protobuf.Timestamp view_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.getViewAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} returns this
*/
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.setViewAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} returns this
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.clearViewAt = function() {
  return this.setViewAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest.prototype.hasViewAt = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    opportunitiesList: jspb.Message.toObjectList(msg.getOpportunitiesList(),
    src_common_opportunity_opportunity_pb.Opportunity.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextPage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse;
  return proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_opportunity_opportunity_pb.Opportunity;
      reader.readMessage(value,src_common_opportunity_opportunity_pb.Opportunity.deserializeBinaryFromReader);
      msg.addOpportunities(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpportunitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_opportunity_opportunity_pb.Opportunity.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNextPage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated loupe.common.opportunity.Opportunity opportunities = 1;
 * @return {!Array<!proto.loupe.common.opportunity.Opportunity>}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.prototype.getOpportunitiesList = function() {
  return /** @type{!Array<!proto.loupe.common.opportunity.Opportunity>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_opportunity_opportunity_pb.Opportunity, 1));
};


/**
 * @param {!Array<!proto.loupe.common.opportunity.Opportunity>} value
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse} returns this
*/
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.prototype.setOpportunitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.opportunity.Opportunity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.opportunity.Opportunity}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.prototype.addOpportunities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.opportunity.Opportunity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse} returns this
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.prototype.clearOpportunitiesList = function() {
  return this.setOpportunitiesList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse} returns this
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string next_page = 3;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.prototype.getNextPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse} returns this
 */
proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse.prototype.setNextPage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.GetLatestPeopleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.GetLatestPeopleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    changeSince: (f = msg.getChangeSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    token: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleRequest}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.GetLatestPeopleRequest;
  return proto.loupe.services.crm_data_access.GetLatestPeopleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.GetLatestPeopleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleRequest}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setChangeSince(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.GetLatestPeopleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.GetLatestPeopleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChangeSince();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleRequest} returns this
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp change_since = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.prototype.getChangeSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleRequest} returns this
*/
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.prototype.setChangeSince = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleRequest} returns this
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.prototype.clearChangeSince = function() {
  return this.setChangeSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.prototype.hasChangeSince = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleRequest} returns this
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string token = 4;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleRequest} returns this
 */
proto.loupe.services.crm_data_access.GetLatestPeopleRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.GetLatestPeopleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.GetLatestPeopleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    latestPeopleList: jspb.Message.toObjectList(msg.getLatestPeopleList(),
    src_common_orchard_person_pb.Person.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleResponse}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.GetLatestPeopleResponse;
  return proto.loupe.services.crm_data_access.GetLatestPeopleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.GetLatestPeopleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleResponse}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.addLatestPeople(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.GetLatestPeopleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.GetLatestPeopleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatestPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNextToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated loupe.common.orchard.Person latest_people = 1;
 * @return {!Array<!proto.loupe.common.orchard.Person>}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.prototype.getLatestPeopleList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_person_pb.Person, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Person>} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleResponse} returns this
*/
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.prototype.setLatestPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.prototype.addLatestPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleResponse} returns this
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.prototype.clearLatestPeopleList = function() {
  return this.setLatestPeopleList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleResponse} returns this
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string next_token = 3;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.prototype.getNextToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestPeopleResponse} returns this
 */
proto.loupe.services.crm_data_access.GetLatestPeopleResponse.prototype.setNextToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    changeSince: (f = msg.getChangeSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    token: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest;
  return proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setChangeSince(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChangeSince();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest} returns this
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp change_since = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.prototype.getChangeSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest} returns this
*/
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.prototype.setChangeSince = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest} returns this
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.prototype.clearChangeSince = function() {
  return this.setChangeSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.prototype.hasChangeSince = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest} returns this
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string token = 4;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest} returns this
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    latestRolesList: jspb.Message.toObjectList(msg.getLatestRolesList(),
    src_common_orchard_crm_role_pb.CRMRole.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse;
  return proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_crm_role_pb.CRMRole;
      reader.readMessage(value,src_common_orchard_crm_role_pb.CRMRole.deserializeBinaryFromReader);
      msg.addLatestRoles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatestRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_orchard_crm_role_pb.CRMRole.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNextToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated loupe.common.orchard.CRMRole latest_roles = 1;
 * @return {!Array<!proto.loupe.common.orchard.CRMRole>}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.prototype.getLatestRolesList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.CRMRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_crm_role_pb.CRMRole, 1));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.CRMRole>} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse} returns this
*/
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.prototype.setLatestRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.orchard.CRMRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.CRMRole}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.prototype.addLatestRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.orchard.CRMRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse} returns this
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.prototype.clearLatestRolesList = function() {
  return this.setLatestRolesList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse} returns this
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string next_token = 3;
 * @return {string}
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.prototype.getNextToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse} returns this
 */
proto.loupe.services.crm_data_access.GetLatestCRMRolesResponse.prototype.setNextToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.loupe.services.crm_data_access.NotificationDataTypes = {
  SIGNAL: 0,
  METRIC: 1
};

goog.object.extend(exports, proto.loupe.services.crm_data_access);
