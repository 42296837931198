import LoadingState from './loadingState'
import React, { useCallback, useEffect, useState } from 'react'
import { useMessages } from '../../hooks/useMessages'
import { useAuth } from '../../context/auth'
import { useLoggedInUser } from '../../context/loggedInUser'

const targetOrigin = process.env.CANOPY_URL

const IFrame = (props) => {
  const { title } = props

  const [isLoading, setIsLoading] = useState(true)

  const { tenantId, tenantContext } = useAuth()
  const { loggedInUser } = useLoggedInUser()

  const {
    attach,
    postAuth,
    receiverReady
  } = useMessages(targetOrigin)

  useEffect(() => {
    if (receiverReady) {
      postAuth({
        user: {
          id: loggedInUser.id,
          name: loggedInUser.name
        },
        tenantContext,
        tenantId
      })
    }
  }, [loggedInUser.id, loggedInUser.name, postAuth, receiverReady, tenantContext, tenantId])

  const onLoad = useCallback((event) => {
    const { target } = event
    attach(target?.contentWindow)
    setIsLoading(false)
  }, [attach])

  return (
    <>
      <iframe
        onLoad={onLoad}
        title={title}
        {...props} />
      {isLoading && (
        <div className="flex justify-center absolute bg-color-ffffff" style={{ width: props.width, height: props.height }}>
          <LoadingState
            header="Loading Preview"
            subHeader="Please wait..."
            animate={true} />
        </div>
      )}
    </>
  )
}

export default IFrame
