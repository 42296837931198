import React, { useCallback, useState } from 'react'
import Checkbox from '../common/checkbox'
import classNames from 'classnames'

const PermissionListItemFlag = (props) => {
  const {
    flag,
    onChange,
    canEdit
  } = props

  const {
    permission,
    hasPermission,
    name,
    description
  } = flag

  const [valueInternal, setValueInternal] = useState(hasPermission)

  const onChangeInternal = useCallback(({ target }) => {
    setValueInternal(target.checked)
    onChange({
      ...flag,
      hasPermission: target.checked
    })
  }, [onChange, flag])

  return (
    <div className="tr hover:bg-color-51636a-05 border-b-0" style={{ border: 0 }}>
      <div className="td-menu" />
      <div
        className={classNames('td w-1/5', { 'opacity-50': !canEdit })}
        style={{ paddingTop: 2, paddingBottom: 2 }}>
        <Checkbox
          disabled={!canEdit}
          onChange={onChangeInternal}
          checked={valueInternal}
          label={name} />
      </div>
      <div
        className={classNames('td w-3/5 text-color-818e93 text-left text-size-14px font-weight-300', { 'opacity-50': !canEdit })}
        style={{ paddingTop: 2, paddingBottom: 2 }}>
        {description}
      </div>
      <div className="td" />
    </div>
  )
}

export default PermissionListItemFlag
