import { addStyles, EditableMathField, StaticMathField } from 'react-mathquill'
import { Close } from '@getoutreach/react-icons'
import { useForecastingConfig } from '../../../context/forecastingConfig'
import { useForecastingConfigColumn } from '../../../context/forecastingConfigColumn'
import evaluatex from 'evaluatex'
import Label from '../../common/label'
import React, { useMemo, useEffect, useState, useCallback } from 'react'
import Tooltip from '../../common/tooltip'
import Info from '../../icons/info'
import SelectList from '../../common/selectList'
import { useDependencySearch } from '../hooks'

addStyles()

const ForecastConfigCategoriesModalFormulas = (props) => {
  const {
    configColumn,
    updateConfigColumn,
    cannotBePublished,
    setIsInvalidFormula
  } = useForecastingConfigColumn()

  const { configColumns } = useForecastingConfig()
  const { displayFormula, readOnly } = configColumn

  const { hasDependency } = useDependencySearch(configColumns)

  const columnData = configColumns.map((col) => {
    const { id, name } = col
    const colName = configColumn.id === id ? configColumn?.name : name
    let disabled
    try {
      disabled = hasDependency(col, configColumn)
    } catch (e) {
      disabled = true
    }

    const label = (
      <div className="w-72 truncate">
        {colName}
        {disabled && (
          <Tooltip
            arrow
            placement="right"
            title="This value causes a circular reference and cannot be used here">
            <Info
              htmlColor="#5951FF"
              transform="scale(0.8)"
              className="pointer-events-auto mb-1 ml-2" />
          </Tooltip>
        )}
      </div>
    )
    return {
      id,
      label,
      value: id,
      disabled
    }
  })

  // string value of textarea (working draft)
  const [expression, setExpression] = useState(displayFormula?.latexFormula)
  // evalFn with current expression
  const [evalFn, setEvalFn] = useState(null)
  const [mapping, setMapping] = useState(new Map(displayFormula?.variableMappingMap))
  const [expressionError, setExpressionError] = useState(null)

  const mappedVars = useMemo(() => {
    const vars = {};
    [...mapping].forEach(([varName], i) => {
      vars[varName] = i
    })
    return vars
  }, [mapping])

  const getVarForIndex = useCallback((i) => {
    // ascii dec for characters a-z => 97-122
    return String.fromCharCode(97 + i)
  }, [])

  const validateExpression = useCallback(() => {
    if ((!expression || expression === '') && (!mapping || mapping.size === 0)) {
      setExpressionError(null)
      setIsInvalidFormula(false)
      updateConfigColumn({
        displayFormula: {
          latexFormula: '',
          variableMappingMap: []
        }
      })
      return
    }

    if (!expression) {
      setExpressionError(null)
      setIsInvalidFormula(false)
      updateConfigColumn({
        displayFormula: {
          variableMappingMap: Array.from(mapping)
        }
      })
      return
    }

    let fn
    // apply the generated latex string to evaluation fn
    try {
      fn = evaluatex(expression, {}, { latex: true })
    } catch (err) {
      setIsInvalidFormula(true)
      setExpressionError('Invalid Expression')
      setEvalFn(null)
      return
    }

    // ensure mapped variables exist in defined config cols
    try {
      fn(mappedVars)
    } catch (err) {
      setIsInvalidFormula(true)
      setExpressionError(err?.message ?? 'Variable mismatch in formula')
      setEvalFn(null)
      return
    }

    setIsInvalidFormula(false)
    setExpressionError(null)
    setEvalFn(evalFn)
    updateConfigColumn({
      displayFormula: {
        latexFormula: expression,
        variableMappingMap: Array.from(mapping)
      }
    })
  }, [evalFn, expression, mappedVars, mapping, setIsInvalidFormula, updateConfigColumn])

  useEffect(() => {
    validateExpression()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedVars])

  const mapVariables = useCallback((_mapping) => {
    const newMapping = [..._mapping.values()].map((id, i) => [getVarForIndex(i), id])
    setMapping(new Map(newMapping))
  }, [getVarForIndex])

  const addValue = useCallback(() => {
    mapping.set('z', columnData[0].value)
    mapVariables(mapping)
  }, [mapping, columnData, mapVariables])

  const updateMapping = useMemo(() => {
    return (id, varName) => {
      mapping.set(varName, id)
      mapVariables(mapping)
    }
  }, [mapping, mapVariables])

  const removeMapping = useCallback((removeVar) => {
    mapping.delete(removeVar)
    mapVariables(mapping)
  }, [mapping, mapVariables])

  const updateExpression = useCallback((e) => {
    let value
    if (e?.latex) {
      value = e.latex().replace(/\\\s/g, '')
    } else {
      ({ value } = e.target)
    }
    setExpression(value)
    if (expressionError) {
      validateExpression()
    }
  }, [expressionError, validateExpression])

  return (
    <div className="max-w-md">
      {!readOnly && (
        <div className="bg-color-b3afff rounded p-2 mb-4 bg-opacity-25 flex">
          <div className="font-weight-700 text-size-12px uppercase mt-1">Note:</div>
          <div className="font-weight-400 pl-2">Formulas are disabled. If you want to create a formula, make forecast entries read-only in the General tab.</div>
        </div>
      )}
      <div className={`max-w-md ${!readOnly ? 'opacity-50' : ''}`}>
        <div className="mb-4">
          <Label
            text="Values"
            className="text-size-20px font-weight-700" />
          <Label
            text="Set the values for your formula."
            className="text-color-51636a font-weight-400" />
          <div
            className="bg-color-edeeee opacity-60 py-2 w-full rounded mt-2">
            {[...mapping].map(([variable, id]) => {
              const { value } = columnData.find((d) => d.id === id) ?? {}
              return (
                <div
                  key={`variable-${variable}`}
                  className="flex flex-row items-center justify-between my-2">
                  <div className="px-4 text-color-818e93 text-size-14px">{variable}</div>
                  <SelectList
                    disabled={!readOnly}
                    onChange={(selected) => {
                      const { id, disabled } = selected
                      if (disabled) { return }
                      updateMapping(id, variable)
                    }}
                    options={columnData}
                    value={value ?? columnData[0].value} />
                  <button className="px-3" disabled={!readOnly} onClick={() => removeMapping(variable)}>
                    <Close className="p-1" htmlColor="#818e93" />
                  </button>
                </div>
              )
            })}
            <button
              disabled={!readOnly}
              className="text-color-5951FF text-size-14px ml-11 font-weight-600"
              onClick={addValue}>
              + Add value
            </button>
          </div>
        </div>

        <div className="mb-4">
          <Label text="Formula" />
          <Label
            text="Use the values defined above to create your formula, we support all standard operators."
            className="font-weight-400 w-120 mb-2 text-color-51636a" />
          <div className="flex flex-col items-start">
            {!readOnly ? (
              <StaticMathField
                className="mq-root-block mq-empty w-full rounded border"
                style={{
                  minHeight: '66px',
                  fontSize: '1.5rem',
                  borderColor: '#d6d9e6',
                  padding: '6px'
                }}>
                {expression}
              </StaticMathField>
            )
              : (
                <div className="flex flex-row w-full">
                  <EditableMathField
                    className="mq-root-block mq-empty w-full rounded"
                    style={{
                      minHeight: '66px',
                      fontSize: '1.5rem',
                      borderColor: expressionError ? '#fa6d6b' : '#d6d9e6',
                      padding: '6px'
                    }}
                    latex={expression || ''}
                    onChange={updateExpression}
                    onBlur={validateExpression} />
                  {cannotBePublished && (
                    <div className="-mr-6 mt-4">
                      <Tooltip
                        arrow
                        placement="right"
                        title="Read-only metrics must have a filter or a formula before this forecast can be published.">
                        <Info htmlColor="#5951FF" transform="scale(0.8)" />
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
            {!!expressionError && (
              <div className="text-color-fa6d6b text-size-12px">{expressionError}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForecastConfigCategoriesModalFormulas
