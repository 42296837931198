// source: src/services/snoop/snoop.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_actor_pb = require('../../../src/common/actor_pb.js');
goog.object.extend(proto, src_common_actor_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.loupe.services.snoop.Action', null, global);
goog.exportSymbol('proto.loupe.services.snoop.DeleteFilterRequest', null, global);
goog.exportSymbol('proto.loupe.services.snoop.Diff', null, global);
goog.exportSymbol('proto.loupe.services.snoop.Empty', null, global);
goog.exportSymbol('proto.loupe.services.snoop.Filter', null, global);
goog.exportSymbol('proto.loupe.services.snoop.GetActionRequest', null, global);
goog.exportSymbol('proto.loupe.services.snoop.GetActionResponse', null, global);
goog.exportSymbol('proto.loupe.services.snoop.ListActionsRequest', null, global);
goog.exportSymbol('proto.loupe.services.snoop.ListActionsResponse', null, global);
goog.exportSymbol('proto.loupe.services.snoop.ListDirection', null, global);
goog.exportSymbol('proto.loupe.services.snoop.ListFiltersRequest', null, global);
goog.exportSymbol('proto.loupe.services.snoop.ListFiltersResponse', null, global);
goog.exportSymbol('proto.loupe.services.snoop.ListSortField', null, global);
goog.exportSymbol('proto.loupe.services.snoop.Params', null, global);
goog.exportSymbol('proto.loupe.services.snoop.Resource', null, global);
goog.exportSymbol('proto.loupe.services.snoop.ResourceType', null, global);
goog.exportSymbol('proto.loupe.services.snoop.SaveFilterRequest', null, global);
goog.exportSymbol('proto.loupe.services.snoop.SendActionRequest', null, global);
goog.exportSymbol('proto.loupe.services.snoop.SnoopDateTimeType', null, global);
goog.exportSymbol('proto.loupe.services.snoop.Status', null, global);
goog.exportSymbol('proto.loupe.services.snoop.StatusType', null, global);
goog.exportSymbol('proto.loupe.services.snoop.TimeWindow', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.snoop.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.Empty.displayName = 'proto.loupe.services.snoop.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.Diff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.snoop.Diff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.Diff.displayName = 'proto.loupe.services.snoop.Diff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.snoop.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.Status.displayName = 'proto.loupe.services.snoop.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.Resource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.snoop.Resource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.Resource.displayName = 'proto.loupe.services.snoop.Resource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.snoop.Action.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.snoop.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.Action.displayName = 'proto.loupe.services.snoop.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.SendActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.snoop.SendActionRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.snoop.SendActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.SendActionRequest.displayName = 'proto.loupe.services.snoop.SendActionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.TimeWindow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.snoop.TimeWindow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.TimeWindow.displayName = 'proto.loupe.services.snoop.TimeWindow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.Params = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.snoop.Params.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.snoop.Params, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.Params.displayName = 'proto.loupe.services.snoop.Params';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.ListActionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.snoop.ListActionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.ListActionsRequest.displayName = 'proto.loupe.services.snoop.ListActionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.ListActionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.snoop.ListActionsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.snoop.ListActionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.ListActionsResponse.displayName = 'proto.loupe.services.snoop.ListActionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.GetActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.snoop.GetActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.GetActionRequest.displayName = 'proto.loupe.services.snoop.GetActionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.GetActionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.snoop.GetActionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.GetActionResponse.displayName = 'proto.loupe.services.snoop.GetActionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.snoop.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.Filter.displayName = 'proto.loupe.services.snoop.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.SaveFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.snoop.SaveFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.SaveFilterRequest.displayName = 'proto.loupe.services.snoop.SaveFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.ListFiltersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.snoop.ListFiltersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.ListFiltersRequest.displayName = 'proto.loupe.services.snoop.ListFiltersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.ListFiltersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.snoop.ListFiltersResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.snoop.ListFiltersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.ListFiltersResponse.displayName = 'proto.loupe.services.snoop.ListFiltersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.snoop.DeleteFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.snoop.DeleteFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.snoop.DeleteFilterRequest.displayName = 'proto.loupe.services.snoop.DeleteFilterRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.Empty}
 */
proto.loupe.services.snoop.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.Empty;
  return proto.loupe.services.snoop.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.Empty}
 */
proto.loupe.services.snoop.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.Diff.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.Diff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.Diff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Diff.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oldValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newValue: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.Diff}
 */
proto.loupe.services.snoop.Diff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.Diff;
  return proto.loupe.services.snoop.Diff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.Diff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.Diff}
 */
proto.loupe.services.snoop.Diff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.Diff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.Diff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.Diff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Diff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOldValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.loupe.services.snoop.Diff.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Diff} returns this
 */
proto.loupe.services.snoop.Diff.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string old_value = 2;
 * @return {string}
 */
proto.loupe.services.snoop.Diff.prototype.getOldValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Diff} returns this
 */
proto.loupe.services.snoop.Diff.prototype.setOldValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string new_value = 3;
 * @return {string}
 */
proto.loupe.services.snoop.Diff.prototype.getNewValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Diff} returns this
 */
proto.loupe.services.snoop.Diff.prototype.setNewValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    msg: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.Status}
 */
proto.loupe.services.snoop.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.Status;
  return proto.loupe.services.snoop.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.Status}
 */
proto.loupe.services.snoop.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.services.snoop.StatusType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional StatusType type = 1;
 * @return {!proto.loupe.services.snoop.StatusType}
 */
proto.loupe.services.snoop.Status.prototype.getType = function() {
  return /** @type {!proto.loupe.services.snoop.StatusType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.services.snoop.StatusType} value
 * @return {!proto.loupe.services.snoop.Status} returns this
 */
proto.loupe.services.snoop.Status.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string msg = 2;
 * @return {string}
 */
proto.loupe.services.snoop.Status.prototype.getMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Status} returns this
 */
proto.loupe.services.snoop.Status.prototype.setMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.Resource.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.Resource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.Resource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Resource.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.Resource}
 */
proto.loupe.services.snoop.Resource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.Resource;
  return proto.loupe.services.snoop.Resource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.Resource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.Resource}
 */
proto.loupe.services.snoop.Resource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.services.snoop.ResourceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.Resource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.Resource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.Resource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Resource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.snoop.Resource.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Resource} returns this
 */
proto.loupe.services.snoop.Resource.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ResourceType type = 2;
 * @return {!proto.loupe.services.snoop.ResourceType}
 */
proto.loupe.services.snoop.Resource.prototype.getType = function() {
  return /** @type {!proto.loupe.services.snoop.ResourceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.services.snoop.ResourceType} value
 * @return {!proto.loupe.services.snoop.Resource} returns this
 */
proto.loupe.services.snoop.Resource.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.snoop.Action.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    txId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eventName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    metadata: jspb.Message.getFieldWithDefault(msg, 6, ""),
    diffsList: jspb.Message.toObjectList(msg.getDiffsList(),
    proto.loupe.services.snoop.Diff.toObject, includeInstance),
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    proto.loupe.services.snoop.Resource.toObject, includeInstance),
    actionTime: (f = msg.getActionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.loupe.services.snoop.Status.toObject(includeInstance, f),
    tenantName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    groupName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    impersonatorUserName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    impersonatorGroupName: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.Action}
 */
proto.loupe.services.snoop.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.Action;
  return proto.loupe.services.snoop.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.Action}
 */
proto.loupe.services.snoop.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    case 2:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadata(value);
      break;
    case 7:
      var value = new proto.loupe.services.snoop.Diff;
      reader.readMessage(value,proto.loupe.services.snoop.Diff.deserializeBinaryFromReader);
      msg.addDiffs(value);
      break;
    case 8:
      var value = new proto.loupe.services.snoop.Resource;
      reader.readMessage(value,proto.loupe.services.snoop.Resource.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActionTime(value);
      break;
    case 10:
      var value = new proto.loupe.services.snoop.Status;
      reader.readMessage(value,proto.loupe.services.snoop.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setImpersonatorUserName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setImpersonatorGroupName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMetadata();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDiffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.loupe.services.snoop.Diff.serializeBinaryToWriter
    );
  }
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.loupe.services.snoop.Resource.serializeBinaryToWriter
    );
  }
  f = message.getActionTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.loupe.services.snoop.Status.serializeBinaryToWriter
    );
  }
  f = message.getTenantName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getImpersonatorUserName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getImpersonatorGroupName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.loupe.services.snoop.Action.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.Actor actor = 2;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.snoop.Action.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 2));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.snoop.Action} returns this
*/
proto.loupe.services.snoop.Action.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.Action.prototype.hasActor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string tx_id = 3;
 * @return {string}
 */
proto.loupe.services.snoop.Action.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string event_name = 4;
 * @return {string}
 */
proto.loupe.services.snoop.Action.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string service_name = 5;
 * @return {string}
 */
proto.loupe.services.snoop.Action.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string metadata = 6;
 * @return {string}
 */
proto.loupe.services.snoop.Action.prototype.getMetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.setMetadata = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated Diff diffs = 7;
 * @return {!Array<!proto.loupe.services.snoop.Diff>}
 */
proto.loupe.services.snoop.Action.prototype.getDiffsList = function() {
  return /** @type{!Array<!proto.loupe.services.snoop.Diff>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.snoop.Diff, 7));
};


/**
 * @param {!Array<!proto.loupe.services.snoop.Diff>} value
 * @return {!proto.loupe.services.snoop.Action} returns this
*/
proto.loupe.services.snoop.Action.prototype.setDiffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.loupe.services.snoop.Diff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Diff}
 */
proto.loupe.services.snoop.Action.prototype.addDiffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.loupe.services.snoop.Diff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.clearDiffsList = function() {
  return this.setDiffsList([]);
};


/**
 * repeated Resource resources = 8;
 * @return {!Array<!proto.loupe.services.snoop.Resource>}
 */
proto.loupe.services.snoop.Action.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.loupe.services.snoop.Resource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.snoop.Resource, 8));
};


/**
 * @param {!Array<!proto.loupe.services.snoop.Resource>} value
 * @return {!proto.loupe.services.snoop.Action} returns this
*/
proto.loupe.services.snoop.Action.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.loupe.services.snoop.Resource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Resource}
 */
proto.loupe.services.snoop.Action.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.loupe.services.snoop.Resource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};


/**
 * optional google.protobuf.Timestamp action_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.snoop.Action.prototype.getActionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.snoop.Action} returns this
*/
proto.loupe.services.snoop.Action.prototype.setActionTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.clearActionTime = function() {
  return this.setActionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.Action.prototype.hasActionTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Status status = 10;
 * @return {?proto.loupe.services.snoop.Status}
 */
proto.loupe.services.snoop.Action.prototype.getStatus = function() {
  return /** @type{?proto.loupe.services.snoop.Status} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.snoop.Status, 10));
};


/**
 * @param {?proto.loupe.services.snoop.Status|undefined} value
 * @return {!proto.loupe.services.snoop.Action} returns this
*/
proto.loupe.services.snoop.Action.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.Action.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string tenant_name = 11;
 * @return {string}
 */
proto.loupe.services.snoop.Action.prototype.getTenantName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.setTenantName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string user_name = 12;
 * @return {string}
 */
proto.loupe.services.snoop.Action.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string group_name = 13;
 * @return {string}
 */
proto.loupe.services.snoop.Action.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string impersonator_user_name = 14;
 * @return {string}
 */
proto.loupe.services.snoop.Action.prototype.getImpersonatorUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.setImpersonatorUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string impersonator_group_name = 15;
 * @return {string}
 */
proto.loupe.services.snoop.Action.prototype.getImpersonatorGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Action} returns this
 */
proto.loupe.services.snoop.Action.prototype.setImpersonatorGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.snoop.SendActionRequest.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.SendActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.SendActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.SendActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    txId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    metadata: jspb.Message.getFieldWithDefault(msg, 5, ""),
    diffsList: jspb.Message.toObjectList(msg.getDiffsList(),
    proto.loupe.services.snoop.Diff.toObject, includeInstance),
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    proto.loupe.services.snoop.Resource.toObject, includeInstance),
    actionTime: (f = msg.getActionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.loupe.services.snoop.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.SendActionRequest}
 */
proto.loupe.services.snoop.SendActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.SendActionRequest;
  return proto.loupe.services.snoop.SendActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.SendActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.SendActionRequest}
 */
proto.loupe.services.snoop.SendActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadata(value);
      break;
    case 6:
      var value = new proto.loupe.services.snoop.Diff;
      reader.readMessage(value,proto.loupe.services.snoop.Diff.deserializeBinaryFromReader);
      msg.addDiffs(value);
      break;
    case 7:
      var value = new proto.loupe.services.snoop.Resource;
      reader.readMessage(value,proto.loupe.services.snoop.Resource.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActionTime(value);
      break;
    case 9:
      var value = new proto.loupe.services.snoop.Status;
      reader.readMessage(value,proto.loupe.services.snoop.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.SendActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.SendActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.SendActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMetadata();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDiffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.loupe.services.snoop.Diff.serializeBinaryToWriter
    );
  }
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.loupe.services.snoop.Resource.serializeBinaryToWriter
    );
  }
  f = message.getActionTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.loupe.services.snoop.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
*/
proto.loupe.services.snoop.SendActionRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
 */
proto.loupe.services.snoop.SendActionRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string tx_id = 2;
 * @return {string}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
 */
proto.loupe.services.snoop.SendActionRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string event_name = 3;
 * @return {string}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
 */
proto.loupe.services.snoop.SendActionRequest.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string service_name = 4;
 * @return {string}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
 */
proto.loupe.services.snoop.SendActionRequest.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string metadata = 5;
 * @return {string}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.getMetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
 */
proto.loupe.services.snoop.SendActionRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Diff diffs = 6;
 * @return {!Array<!proto.loupe.services.snoop.Diff>}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.getDiffsList = function() {
  return /** @type{!Array<!proto.loupe.services.snoop.Diff>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.snoop.Diff, 6));
};


/**
 * @param {!Array<!proto.loupe.services.snoop.Diff>} value
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
*/
proto.loupe.services.snoop.SendActionRequest.prototype.setDiffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.services.snoop.Diff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Diff}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.addDiffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.services.snoop.Diff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
 */
proto.loupe.services.snoop.SendActionRequest.prototype.clearDiffsList = function() {
  return this.setDiffsList([]);
};


/**
 * repeated Resource resources = 7;
 * @return {!Array<!proto.loupe.services.snoop.Resource>}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.loupe.services.snoop.Resource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.snoop.Resource, 7));
};


/**
 * @param {!Array<!proto.loupe.services.snoop.Resource>} value
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
*/
proto.loupe.services.snoop.SendActionRequest.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.loupe.services.snoop.Resource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Resource}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.loupe.services.snoop.Resource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
 */
proto.loupe.services.snoop.SendActionRequest.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};


/**
 * optional google.protobuf.Timestamp action_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.getActionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
*/
proto.loupe.services.snoop.SendActionRequest.prototype.setActionTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
 */
proto.loupe.services.snoop.SendActionRequest.prototype.clearActionTime = function() {
  return this.setActionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.hasActionTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Status status = 9;
 * @return {?proto.loupe.services.snoop.Status}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.getStatus = function() {
  return /** @type{?proto.loupe.services.snoop.Status} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.snoop.Status, 9));
};


/**
 * @param {?proto.loupe.services.snoop.Status|undefined} value
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
*/
proto.loupe.services.snoop.SendActionRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.SendActionRequest} returns this
 */
proto.loupe.services.snoop.SendActionRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.SendActionRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.TimeWindow.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.TimeWindow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.TimeWindow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.TimeWindow.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.TimeWindow}
 */
proto.loupe.services.snoop.TimeWindow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.TimeWindow;
  return proto.loupe.services.snoop.TimeWindow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.TimeWindow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.TimeWindow}
 */
proto.loupe.services.snoop.TimeWindow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.TimeWindow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.TimeWindow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.TimeWindow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.TimeWindow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.snoop.TimeWindow.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.snoop.TimeWindow} returns this
*/
proto.loupe.services.snoop.TimeWindow.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.TimeWindow} returns this
 */
proto.loupe.services.snoop.TimeWindow.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.TimeWindow.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp end = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.snoop.TimeWindow.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.snoop.TimeWindow} returns this
*/
proto.loupe.services.snoop.TimeWindow.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.TimeWindow} returns this
 */
proto.loupe.services.snoop.TimeWindow.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.TimeWindow.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.snoop.Params.repeatedFields_ = [1,2,3,4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.Params.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.Params.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.Params} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Params.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    groupIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    impersonatorUserIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    txIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    eventNamesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    diffsList: jspb.Message.toObjectList(msg.getDiffsList(),
    proto.loupe.services.snoop.Diff.toObject, includeInstance),
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    proto.loupe.services.snoop.Resource.toObject, includeInstance),
    timeWindow: (f = msg.getTimeWindow()) && proto.loupe.services.snoop.TimeWindow.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 9, ""),
    datetimeType: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.Params}
 */
proto.loupe.services.snoop.Params.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.Params;
  return proto.loupe.services.snoop.Params.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.Params} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.Params}
 */
proto.loupe.services.snoop.Params.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addImpersonatorUserIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTxIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addEventNames(value);
      break;
    case 6:
      var value = new proto.loupe.services.snoop.Diff;
      reader.readMessage(value,proto.loupe.services.snoop.Diff.deserializeBinaryFromReader);
      msg.addDiffs(value);
      break;
    case 7:
      var value = new proto.loupe.services.snoop.Resource;
      reader.readMessage(value,proto.loupe.services.snoop.Resource.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    case 8:
      var value = new proto.loupe.services.snoop.TimeWindow;
      reader.readMessage(value,proto.loupe.services.snoop.TimeWindow.deserializeBinaryFromReader);
      msg.setTimeWindow(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 10:
      var value = /** @type {!proto.loupe.services.snoop.SnoopDateTimeType} */ (reader.readEnum());
      msg.setDatetimeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.Params.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.Params.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.Params} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Params.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getImpersonatorUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getTxIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getEventNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getDiffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.loupe.services.snoop.Diff.serializeBinaryToWriter
    );
  }
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.loupe.services.snoop.Resource.serializeBinaryToWriter
    );
  }
  f = message.getTimeWindow();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.loupe.services.snoop.TimeWindow.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDatetimeType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.loupe.services.snoop.Params.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * repeated string group_ids = 2;
 * @return {!Array<string>}
 */
proto.loupe.services.snoop.Params.prototype.getGroupIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.setGroupIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.addGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.clearGroupIdsList = function() {
  return this.setGroupIdsList([]);
};


/**
 * repeated string impersonator_user_ids = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.snoop.Params.prototype.getImpersonatorUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.setImpersonatorUserIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.addImpersonatorUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.clearImpersonatorUserIdsList = function() {
  return this.setImpersonatorUserIdsList([]);
};


/**
 * repeated string tx_ids = 4;
 * @return {!Array<string>}
 */
proto.loupe.services.snoop.Params.prototype.getTxIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.setTxIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.addTxIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.clearTxIdsList = function() {
  return this.setTxIdsList([]);
};


/**
 * repeated string event_names = 5;
 * @return {!Array<string>}
 */
proto.loupe.services.snoop.Params.prototype.getEventNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.setEventNamesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.addEventNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.clearEventNamesList = function() {
  return this.setEventNamesList([]);
};


/**
 * repeated Diff diffs = 6;
 * @return {!Array<!proto.loupe.services.snoop.Diff>}
 */
proto.loupe.services.snoop.Params.prototype.getDiffsList = function() {
  return /** @type{!Array<!proto.loupe.services.snoop.Diff>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.snoop.Diff, 6));
};


/**
 * @param {!Array<!proto.loupe.services.snoop.Diff>} value
 * @return {!proto.loupe.services.snoop.Params} returns this
*/
proto.loupe.services.snoop.Params.prototype.setDiffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.services.snoop.Diff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Diff}
 */
proto.loupe.services.snoop.Params.prototype.addDiffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.services.snoop.Diff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.clearDiffsList = function() {
  return this.setDiffsList([]);
};


/**
 * repeated Resource resources = 7;
 * @return {!Array<!proto.loupe.services.snoop.Resource>}
 */
proto.loupe.services.snoop.Params.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.loupe.services.snoop.Resource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.snoop.Resource, 7));
};


/**
 * @param {!Array<!proto.loupe.services.snoop.Resource>} value
 * @return {!proto.loupe.services.snoop.Params} returns this
*/
proto.loupe.services.snoop.Params.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.loupe.services.snoop.Resource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Resource}
 */
proto.loupe.services.snoop.Params.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.loupe.services.snoop.Resource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};


/**
 * optional TimeWindow time_window = 8;
 * @return {?proto.loupe.services.snoop.TimeWindow}
 */
proto.loupe.services.snoop.Params.prototype.getTimeWindow = function() {
  return /** @type{?proto.loupe.services.snoop.TimeWindow} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.snoop.TimeWindow, 8));
};


/**
 * @param {?proto.loupe.services.snoop.TimeWindow|undefined} value
 * @return {!proto.loupe.services.snoop.Params} returns this
*/
proto.loupe.services.snoop.Params.prototype.setTimeWindow = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.clearTimeWindow = function() {
  return this.setTimeWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.Params.prototype.hasTimeWindow = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string search = 9;
 * @return {string}
 */
proto.loupe.services.snoop.Params.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional SnoopDateTimeType datetime_type = 10;
 * @return {!proto.loupe.services.snoop.SnoopDateTimeType}
 */
proto.loupe.services.snoop.Params.prototype.getDatetimeType = function() {
  return /** @type {!proto.loupe.services.snoop.SnoopDateTimeType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.loupe.services.snoop.SnoopDateTimeType} value
 * @return {!proto.loupe.services.snoop.Params} returns this
 */
proto.loupe.services.snoop.Params.prototype.setDatetimeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.ListActionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.ListActionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.ListActionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    params: (f = msg.getParams()) && proto.loupe.services.snoop.Params.toObject(includeInstance, f),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 5, 0),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.ListActionsRequest}
 */
proto.loupe.services.snoop.ListActionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.ListActionsRequest;
  return proto.loupe.services.snoop.ListActionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.ListActionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.ListActionsRequest}
 */
proto.loupe.services.snoop.ListActionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.snoop.Params;
      reader.readMessage(value,proto.loupe.services.snoop.Params.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.services.snoop.ListSortField} */ (reader.readEnum());
      msg.setSort(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.services.snoop.ListDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 6:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.ListActionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.ListActionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.ListActionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.snoop.Params.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSort();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * optional Params params = 1;
 * @return {?proto.loupe.services.snoop.Params}
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.getParams = function() {
  return /** @type{?proto.loupe.services.snoop.Params} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.snoop.Params, 1));
};


/**
 * @param {?proto.loupe.services.snoop.Params|undefined} value
 * @return {!proto.loupe.services.snoop.ListActionsRequest} returns this
*/
proto.loupe.services.snoop.ListActionsRequest.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.ListActionsRequest} returns this
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.hasParams = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 page = 2;
 * @return {number}
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.snoop.ListActionsRequest} returns this
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 page_size = 3;
 * @return {number}
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.snoop.ListActionsRequest} returns this
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ListSortField sort = 4;
 * @return {!proto.loupe.services.snoop.ListSortField}
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.getSort = function() {
  return /** @type {!proto.loupe.services.snoop.ListSortField} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.services.snoop.ListSortField} value
 * @return {!proto.loupe.services.snoop.ListActionsRequest} returns this
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ListDirection direction = 5;
 * @return {!proto.loupe.services.snoop.ListDirection}
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.getDirection = function() {
  return /** @type {!proto.loupe.services.snoop.ListDirection} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.services.snoop.ListDirection} value
 * @return {!proto.loupe.services.snoop.ListActionsRequest} returns this
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional loupe.common.Actor actor = 6;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 6));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.snoop.ListActionsRequest} returns this
*/
proto.loupe.services.snoop.ListActionsRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.ListActionsRequest} returns this
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.ListActionsRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.snoop.ListActionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.ListActionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.ListActionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.ListActionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.ListActionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.loupe.services.snoop.Action.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.ListActionsResponse}
 */
proto.loupe.services.snoop.ListActionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.ListActionsResponse;
  return proto.loupe.services.snoop.ListActionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.ListActionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.ListActionsResponse}
 */
proto.loupe.services.snoop.ListActionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.snoop.Action;
      reader.readMessage(value,proto.loupe.services.snoop.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.ListActionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.ListActionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.ListActionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.ListActionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.snoop.Action.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated Action actions = 1;
 * @return {!Array<!proto.loupe.services.snoop.Action>}
 */
proto.loupe.services.snoop.ListActionsResponse.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.loupe.services.snoop.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.snoop.Action, 1));
};


/**
 * @param {!Array<!proto.loupe.services.snoop.Action>} value
 * @return {!proto.loupe.services.snoop.ListActionsResponse} returns this
*/
proto.loupe.services.snoop.ListActionsResponse.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.snoop.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Action}
 */
proto.loupe.services.snoop.ListActionsResponse.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.snoop.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.ListActionsResponse} returns this
 */
proto.loupe.services.snoop.ListActionsResponse.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.loupe.services.snoop.ListActionsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.snoop.ListActionsResponse} returns this
 */
proto.loupe.services.snoop.ListActionsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 count = 3;
 * @return {number}
 */
proto.loupe.services.snoop.ListActionsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.snoop.ListActionsResponse} returns this
 */
proto.loupe.services.snoop.ListActionsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.GetActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.GetActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.GetActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.GetActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.GetActionRequest}
 */
proto.loupe.services.snoop.GetActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.GetActionRequest;
  return proto.loupe.services.snoop.GetActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.GetActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.GetActionRequest}
 */
proto.loupe.services.snoop.GetActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.GetActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.GetActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.GetActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.GetActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.loupe.services.snoop.GetActionRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.GetActionRequest} returns this
 */
proto.loupe.services.snoop.GetActionRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.GetActionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.GetActionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.GetActionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.GetActionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: (f = msg.getAction()) && proto.loupe.services.snoop.Action.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.GetActionResponse}
 */
proto.loupe.services.snoop.GetActionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.GetActionResponse;
  return proto.loupe.services.snoop.GetActionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.GetActionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.GetActionResponse}
 */
proto.loupe.services.snoop.GetActionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.snoop.Action;
      reader.readMessage(value,proto.loupe.services.snoop.Action.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.GetActionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.GetActionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.GetActionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.GetActionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.snoop.Action.serializeBinaryToWriter
    );
  }
};


/**
 * optional Action action = 1;
 * @return {?proto.loupe.services.snoop.Action}
 */
proto.loupe.services.snoop.GetActionResponse.prototype.getAction = function() {
  return /** @type{?proto.loupe.services.snoop.Action} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.snoop.Action, 1));
};


/**
 * @param {?proto.loupe.services.snoop.Action|undefined} value
 * @return {!proto.loupe.services.snoop.GetActionResponse} returns this
*/
proto.loupe.services.snoop.GetActionResponse.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.GetActionResponse} returns this
 */
proto.loupe.services.snoop.GetActionResponse.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.GetActionResponse.prototype.hasAction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    request: (f = msg.getRequest()) && proto.loupe.services.snoop.ListActionsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.Filter}
 */
proto.loupe.services.snoop.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.Filter;
  return proto.loupe.services.snoop.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.Filter}
 */
proto.loupe.services.snoop.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.loupe.services.snoop.ListActionsRequest;
      reader.readMessage(value,proto.loupe.services.snoop.ListActionsRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.loupe.services.snoop.ListActionsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.snoop.Filter.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Filter} returns this
 */
proto.loupe.services.snoop.Filter.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.loupe.services.snoop.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.Filter} returns this
 */
proto.loupe.services.snoop.Filter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ListActionsRequest request = 3;
 * @return {?proto.loupe.services.snoop.ListActionsRequest}
 */
proto.loupe.services.snoop.Filter.prototype.getRequest = function() {
  return /** @type{?proto.loupe.services.snoop.ListActionsRequest} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.snoop.ListActionsRequest, 3));
};


/**
 * @param {?proto.loupe.services.snoop.ListActionsRequest|undefined} value
 * @return {!proto.loupe.services.snoop.Filter} returns this
*/
proto.loupe.services.snoop.Filter.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.Filter} returns this
 */
proto.loupe.services.snoop.Filter.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.Filter.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.SaveFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.SaveFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.SaveFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    request: (f = msg.getRequest()) && proto.loupe.services.snoop.ListActionsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.SaveFilterRequest}
 */
proto.loupe.services.snoop.SaveFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.SaveFilterRequest;
  return proto.loupe.services.snoop.SaveFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.SaveFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.SaveFilterRequest}
 */
proto.loupe.services.snoop.SaveFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = new proto.loupe.services.snoop.ListActionsRequest;
      reader.readMessage(value,proto.loupe.services.snoop.ListActionsRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.SaveFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.SaveFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.SaveFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loupe.services.snoop.ListActionsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.SaveFilterRequest} returns this
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.SaveFilterRequest} returns this
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.SaveFilterRequest} returns this
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string group_id = 4;
 * @return {string}
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.SaveFilterRequest} returns this
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.SaveFilterRequest} returns this
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ListActionsRequest request = 6;
 * @return {?proto.loupe.services.snoop.ListActionsRequest}
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.getRequest = function() {
  return /** @type{?proto.loupe.services.snoop.ListActionsRequest} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.snoop.ListActionsRequest, 6));
};


/**
 * @param {?proto.loupe.services.snoop.ListActionsRequest|undefined} value
 * @return {!proto.loupe.services.snoop.SaveFilterRequest} returns this
*/
proto.loupe.services.snoop.SaveFilterRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.snoop.SaveFilterRequest} returns this
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.snoop.SaveFilterRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.ListFiltersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.ListFiltersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.ListFiltersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.ListFiltersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.ListFiltersRequest}
 */
proto.loupe.services.snoop.ListFiltersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.ListFiltersRequest;
  return proto.loupe.services.snoop.ListFiltersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.ListFiltersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.ListFiltersRequest}
 */
proto.loupe.services.snoop.ListFiltersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.ListFiltersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.ListFiltersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.ListFiltersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.ListFiltersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.loupe.services.snoop.ListFiltersRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.ListFiltersRequest} returns this
 */
proto.loupe.services.snoop.ListFiltersRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.snoop.ListFiltersRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.ListFiltersRequest} returns this
 */
proto.loupe.services.snoop.ListFiltersRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.loupe.services.snoop.ListFiltersRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.ListFiltersRequest} returns this
 */
proto.loupe.services.snoop.ListFiltersRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.snoop.ListFiltersResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.ListFiltersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.ListFiltersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.ListFiltersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.ListFiltersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.loupe.services.snoop.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.ListFiltersResponse}
 */
proto.loupe.services.snoop.ListFiltersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.ListFiltersResponse;
  return proto.loupe.services.snoop.ListFiltersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.ListFiltersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.ListFiltersResponse}
 */
proto.loupe.services.snoop.ListFiltersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = new proto.loupe.services.snoop.Filter;
      reader.readMessage(value,proto.loupe.services.snoop.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.ListFiltersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.ListFiltersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.ListFiltersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.ListFiltersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.loupe.services.snoop.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.loupe.services.snoop.ListFiltersResponse.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.ListFiltersResponse} returns this
 */
proto.loupe.services.snoop.ListFiltersResponse.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.snoop.ListFiltersResponse.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.ListFiltersResponse} returns this
 */
proto.loupe.services.snoop.ListFiltersResponse.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.loupe.services.snoop.ListFiltersResponse.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.ListFiltersResponse} returns this
 */
proto.loupe.services.snoop.ListFiltersResponse.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Filter filters = 4;
 * @return {!Array<!proto.loupe.services.snoop.Filter>}
 */
proto.loupe.services.snoop.ListFiltersResponse.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.loupe.services.snoop.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.snoop.Filter, 4));
};


/**
 * @param {!Array<!proto.loupe.services.snoop.Filter>} value
 * @return {!proto.loupe.services.snoop.ListFiltersResponse} returns this
*/
proto.loupe.services.snoop.ListFiltersResponse.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.services.snoop.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.snoop.Filter}
 */
proto.loupe.services.snoop.ListFiltersResponse.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.services.snoop.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.snoop.ListFiltersResponse} returns this
 */
proto.loupe.services.snoop.ListFiltersResponse.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.snoop.DeleteFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.snoop.DeleteFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.snoop.DeleteFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.DeleteFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.snoop.DeleteFilterRequest}
 */
proto.loupe.services.snoop.DeleteFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.snoop.DeleteFilterRequest;
  return proto.loupe.services.snoop.DeleteFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.snoop.DeleteFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.snoop.DeleteFilterRequest}
 */
proto.loupe.services.snoop.DeleteFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.snoop.DeleteFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.snoop.DeleteFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.snoop.DeleteFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.snoop.DeleteFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.snoop.DeleteFilterRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.DeleteFilterRequest} returns this
 */
proto.loupe.services.snoop.DeleteFilterRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.snoop.DeleteFilterRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.snoop.DeleteFilterRequest} returns this
 */
proto.loupe.services.snoop.DeleteFilterRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.loupe.services.snoop.StatusType = {
  STATUS_TYPE_UNSPECIFIED: 0,
  SUCCESS: 1,
  PENDING: 2,
  FAILURE: 3
};

/**
 * @enum {number}
 */
proto.loupe.services.snoop.ResourceType = {
  RESOURCE_TYPE_UNSPECIFIED: 0,
  USER: 1,
  TENANT: 2,
  GROUP: 3,
  OPPORTUNITY: 4,
  SIGNAL: 5,
  FORECASTENTRY: 6,
  FORECASTITEM: 7,
  REPORT: 8,
  FILE: 9,
  NOTE: 10,
  SNOOP_FILTER: 11
};

/**
 * @enum {number}
 */
proto.loupe.services.snoop.SnoopDateTimeType = {
  SNOOP_DATE_TIME_TYPE_CUSTOM: 0,
  SNOOP_DATE_TIME_TYPE_15_MINS: 1,
  SNOOP_DATE_TIME_TYPE_30_MINS: 2,
  SNOOP_DATE_TIME_TYPE_LAST_HOUR: 3,
  SNOOP_DATE_TIME_TYPE_LAST_24_HOURS: 4,
  SNOOP_DATE_TIME_TYPE_TODAY: 5,
  SNOOP_DATE_TIME_TYPE_YESTERDAY: 6,
  SNOOP_DATE_TIME_TYPE_LAST_7_DAYS: 7,
  SNOOP_DATE_TIME_TYPE_CURRENT_WEEK: 8,
  SNOOP_DATE_TIME_TYPE_PREVIOUS_WEEK: 9,
  SNOOP_DATE_TIME_TYPE_RETENTION_PERIOD: 10
};

/**
 * @enum {number}
 */
proto.loupe.services.snoop.ListSortField = {
  LIST_SORT_FIELD_UNSPECIFIED: 0,
  LIST_SORT_FIELD_USER_NAME: 1,
  LIST_SORT_FIELD_GROUP_NAME: 2,
  LIST_SORT_FIELD_IMPERSONATOR_USER_NAME: 3,
  LIST_SORT_FIELD_IMPERSONATOR_GROUP_NAME: 4,
  LIST_SORT_FIELD_EVENT_NAME: 5,
  LIST_SORT_FIELD_SERVICE_NAME: 6,
  LIST_SORT_FIELD_TIMESTAMP: 7
};

/**
 * @enum {number}
 */
proto.loupe.services.snoop.ListDirection = {
  LIST_DIRECTION_UNSPECIFIED: 0,
  LIST_DIRECTION_ASCENDING: 1,
  LIST_DIRECTION_DESCENDING: 2
};

goog.object.extend(exports, proto.loupe.services.snoop);
