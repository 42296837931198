import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '../common/modal'
import ModalHeader from '../common/modalHeader'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import SearchBox from '../common/searchBox'
import Label from '../common/label'
import { useTextField } from '../../hooks/useTextField'
import Button from '../common/button'
import Checkbox from '../common/checkbox'
import { filter, find, orderBy, some, uniqBy } from 'lodash'
import { AutoSizer, List } from 'react-virtualized'
import { useVirtualizedList } from '../virtualized/useVirtualizedList'
import { useCanonicalObjectMapping } from '../../context/canonicalObjectMapping'
import { useIntegrationObjectTypes } from '../../context/integrationObjectTypes'
import { useIntegrationObjectProperties } from '../../context/integrationObjectProperties'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const MapCrmObjectsModal = (props) => {
  const {
    modal,
    ...rest
  } = props

  const { open, handleClose } = modal

  const { checkPermission } = usePermissions()

  const search = useTextField()

  const [selectedCrmObjects, setSelectedCrmObjects] = useState([])

  const { setIsFetching: setIsFetchingCanonicalObjectMapping, setCrmObjectNamesList, canonicalObjectMapping } = useCanonicalObjectMapping()
  const { invalidate: invalidateIntegrationObjectProperties } = useIntegrationObjectProperties()
  const { integrationObjectTypes } = useIntegrationObjectTypes()

  const { crmObjectsList = [] } = canonicalObjectMapping

  const handleCloseInternal = useCallback(() => {
    search.reset()
    handleClose()
  }, [modal])

  const onMapInternal = useCallback(() => {
    const crmObjectNamesList = selectedCrmObjects.map((o) => o.name)
    setCrmObjectNamesList(crmObjectNamesList)
    setIsFetchingCanonicalObjectMapping(true)
    invalidateIntegrationObjectProperties()
    handleCloseInternal()
  }, [selectedCrmObjects])

  useEffect(() => {
    if (open) {
      if (canonicalObjectMapping && canonicalObjectMapping.crmObjectsList) {
        setSelectedCrmObjects(canonicalObjectMapping.crmObjectsList.map((o) => ({ name: o.objectName, label: o.label })))
      } else {
        setSelectedCrmObjects([])
      }
    }
  }, [open, canonicalObjectMapping])

  const onCheckboxChange = useCallback((e, crmObject) => {
    if (e.target.checked) {
      setSelectedCrmObjects([
        ...selectedCrmObjects,
        crmObject,
      ])
    } else {
      setSelectedCrmObjects([
        ...filter(selectedCrmObjects, (o) => o.name !== crmObject.name)
      ])
    }
  }, [selectedCrmObjects])

  const crmObjects = useMemo(() => {
    const objList = crmObjectsList.map((o) => {
      const obj = find(integrationObjectTypes, (iot) => iot.name === o.objectName)
      const label = obj?.label ?? o.label
      return {
        name: o.objectName,
        label,
        selected: true,
      }
    })
    const objects = [
      ...orderBy(objList, (o) => o.label),
      ...integrationObjectTypes,
    ].map((o) => ({
      ...o,
      selected: some(selectedCrmObjects, (crmObject) => o.name === crmObject.name),
    }))
    return uniqBy(objects, (o) => o.name)
  }, [integrationObjectTypes, crmObjectsList, selectedCrmObjects])

  const filteredCrmObjects = useMemo(() => {
    if (search.value) {
      return filter(crmObjects, (o) => {
        const regex = new RegExp(search.escapedValue.toLowerCase())
        return o.label && regex.test(o.label.toLowerCase())
      })
    }
    return crmObjects
  }, [crmObjects, search.value])

  const renderUser = useCallback(({ rowRendererArgs, cellMeasurerArgs }) => {
    const { index, style } = rowRendererArgs
    const { registerChild } = cellMeasurerArgs
    const crmObject = filteredCrmObjects[index]
    const { name, label, selected = false } = crmObject
    return (
      <div ref={registerChild} style={style}>
        <div key={`SystemRole-${name}-${index}`} className="px-3">
          <Checkbox
            checked={selected}
            disabled={!checkPermission(permissionNames.CanUpdateSync)}
            onChange={(e) => onCheckboxChange(e, crmObject)}
            label={label}
            labelProps={{ className: 'text-size-16px text-color-09242f font-weight-400' }} />
        </div>
      </div>
    )
  }, [filteredCrmObjects, checkPermission])

  const { cellMeasurerCache, rowRenderer } = useVirtualizedList({
    defaultHeight: 42,
    rowRenderer: renderUser,
  })

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="sm"
      open={open}
      {...rest}>

      <ModalHeader
        title="Map CRM Objects"
        onClose={handleCloseInternal} />

      <ModalBody>

        <div className="w-full h-full px-10 pt-10">
          <div className="text-size-16px text-color-51636a font-weight-400 leading-tight mb-4">
            Choose below which object(s) to map into this Commit object. You can select multiple objects, but must map all required fields for each CRM object.
            {' '}
            This is often done when you want to merge multiple objects into a single Commit object (e.g. Activity from both Task and Event).
          </div>

          <Label text="CRM Objects" />
          <div className="w-full">
            <SearchBox
              className="mt-2 mb-3"
              value={search.value}
              onChange={search.onChange}
              onClear={search.reset}
              autoFocus={true} />
            <div className="flex flex-col m-0 p-0 rounded border border-color-d6d9e6 overflow-auto" style={{ height: 300 }}>
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    className="focus:outline-none"
                    width={width - 2}
                    height={height - 2}
                    deferredMeasurementCache={cellMeasurerCache}
                    rowHeight={cellMeasurerCache.rowHeight}
                    rowCount={filteredCrmObjects.length}
                    rowRenderer={rowRenderer} />
                )}
              </AutoSizer>
            </div>
          </div>
        </div>

      </ModalBody>

      <ModalFooter>
        {checkPermission(permissionNames.CanUpdateSync) && (
          <Button
            text="Map"
            onClick={onMapInternal} />
        )}
      </ModalFooter>

    </Modal>
  )
}

export default MapCrmObjectsModal
