import React, { useCallback } from 'react'
import { Popover } from '@material-ui/core'
import Button from './button'

const ActionPopover = (props) => {
  const {
    popover,
    text,
    subText,
    cancelText = 'Cancel',
    actionText = 'Action',
    onCancel,
    onAction,
    maxWidth,
    actionProps,
    ...rest
  } = props

  const { anchorEl, open, onClose } = popover

  const onCancelInternal = useCallback(() => {
    onCancel && onCancel()
    onClose()
  }, [onCancel])

  const onActionInternal = useCallback(() => {
    onAction && onAction()
    onClose()
  }, [onAction])

  return (
    <Popover
      style={{ zIndex: 10000 }}
      open={open}
      anchorEl={anchorEl}
      onClose={onCancelInternal}
      disableScrollLock={true}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...rest}
    >
      <div className="px-7 py-6 font-normal cursor-default" style={maxWidth && { maxWidth }}>
        <div className="text-size-16px font-weight-600 leading-tight">{text}</div>
        <div className="mt-3 mb-7 text-size-14px text-color-818e93 leading-tight">{subText}</div>
        <div className="flex justify-center">
          <div className="flex items-center">
            <Button
              size="xs"
              text={cancelText}
              onClick={onCancelInternal}
              backgroundColor="#a6b2cf"
              className="mx-2" />
            <Button
              size="xs"
              text={actionText}
              onClick={onActionInternal}
              className="mx-2"
              {...actionProps} />
          </div>
        </div>
      </div>
    </Popover>
  )
}

export default ActionPopover
