import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toListSandboxesRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useAuth } from './auth'
import { useSettingsList } from '../components/settings/hooks'

const SandboxesContext = React.createContext()

export function SandboxesProvider({ children }) {
  const { notifyError } = useNotification()

  const { tenantId } = useAuth()
  const { value: maxSandboxes = 0 } = useSettingsList({ key: 'feature.sandbox', name: 'numAllowed' })

  const [isFetching, setIsFetching] = useState(true)
  const [key, setKey] = useState(guid())
  const [sandboxes, setSandboxes] = useState([])

  useGrpcEffect({
    request: toListSandboxesRequest({
      tenantId
    }),
    onError: () => {
      notifyError('Error fetching sandboxes!')
      setIsFetching(false)
    },
    onSuccess: (obj) => {
      const { sandboxesList } = obj

      setSandboxes(sandboxesList)
      setIsFetching(false)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'listSandboxes'
  }, [key, tenantId])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      sandboxes,
      invalidate,
      maxSandboxes
    }
  }, [isFetching, sandboxes, maxSandboxes, invalidate])

  return <SandboxesContext.Provider value={contextValue}>{children}</SandboxesContext.Provider>
}

export function useSandboxes() {
  const context = React.useContext(SandboxesContext)
  if (context === undefined) {
    throw new Error('useSandboxes must be used within a SandboxesProvider')
  }
  return context
}
