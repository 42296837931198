// source: src/services/setting/setting.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_setting_setting_pb = require('../../../src/common/setting/setting_pb.js');
goog.object.extend(proto, src_common_setting_setting_pb);
var src_common_setting_settings_list_pb = require('../../../src/common/setting/settings_list_pb.js');
goog.object.extend(proto, src_common_setting_settings_list_pb);
var src_common_setting_settings_bundle_pb = require('../../../src/common/setting/settings_bundle_pb.js');
goog.object.extend(proto, src_common_setting_settings_bundle_pb);
goog.exportSymbol('proto.loupe.services.setting.CloneTenantSettingsRequest', null, global);
goog.exportSymbol('proto.loupe.services.setting.CloneTenantSettingsResponse', null, global);
goog.exportSymbol('proto.loupe.services.setting.DeleteAllTenantSettingsRequest', null, global);
goog.exportSymbol('proto.loupe.services.setting.DeleteAllTenantSettingsResponse', null, global);
goog.exportSymbol('proto.loupe.services.setting.GetSettingsCommonRequest', null, global);
goog.exportSymbol('proto.loupe.services.setting.UpsertSettingsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.setting.UpsertSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.setting.UpsertSettingsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.setting.UpsertSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.setting.UpsertSettingsRequest.displayName = 'proto.loupe.services.setting.UpsertSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.setting.GetSettingsCommonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.setting.GetSettingsCommonRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.setting.GetSettingsCommonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.setting.GetSettingsCommonRequest.displayName = 'proto.loupe.services.setting.GetSettingsCommonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.setting.CloneTenantSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.setting.CloneTenantSettingsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.setting.CloneTenantSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.setting.CloneTenantSettingsRequest.displayName = 'proto.loupe.services.setting.CloneTenantSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.setting.CloneTenantSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.setting.CloneTenantSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.setting.CloneTenantSettingsResponse.displayName = 'proto.loupe.services.setting.CloneTenantSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.setting.DeleteAllTenantSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.setting.DeleteAllTenantSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.setting.DeleteAllTenantSettingsRequest.displayName = 'proto.loupe.services.setting.DeleteAllTenantSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.setting.DeleteAllTenantSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.setting.DeleteAllTenantSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.setting.DeleteAllTenantSettingsResponse.displayName = 'proto.loupe.services.setting.DeleteAllTenantSettingsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.setting.UpsertSettingsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.setting.UpsertSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.setting.UpsertSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.setting.UpsertSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.setting.UpsertSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    settingsList: jspb.Message.toObjectList(msg.getSettingsList(),
    src_common_setting_setting_pb.Setting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.setting.UpsertSettingsRequest}
 */
proto.loupe.services.setting.UpsertSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.setting.UpsertSettingsRequest;
  return proto.loupe.services.setting.UpsertSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.setting.UpsertSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.setting.UpsertSettingsRequest}
 */
proto.loupe.services.setting.UpsertSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_setting_setting_pb.Setting;
      reader.readMessage(value,src_common_setting_setting_pb.Setting.deserializeBinaryFromReader);
      msg.addSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.setting.UpsertSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.setting.UpsertSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.setting.UpsertSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.setting.UpsertSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_setting_setting_pb.Setting.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.setting.Setting settings = 1;
 * @return {!Array<!proto.loupe.common.setting.Setting>}
 */
proto.loupe.services.setting.UpsertSettingsRequest.prototype.getSettingsList = function() {
  return /** @type{!Array<!proto.loupe.common.setting.Setting>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_setting_setting_pb.Setting, 1));
};


/**
 * @param {!Array<!proto.loupe.common.setting.Setting>} value
 * @return {!proto.loupe.services.setting.UpsertSettingsRequest} returns this
*/
proto.loupe.services.setting.UpsertSettingsRequest.prototype.setSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.setting.Setting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.setting.Setting}
 */
proto.loupe.services.setting.UpsertSettingsRequest.prototype.addSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.setting.Setting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.setting.UpsertSettingsRequest} returns this
 */
proto.loupe.services.setting.UpsertSettingsRequest.prototype.clearSettingsList = function() {
  return this.setSettingsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.setting.GetSettingsCommonRequest.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.setting.GetSettingsCommonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.setting.GetSettingsCommonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.setting.GetSettingsCommonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    key: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fullKeysList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    relatedTypesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest}
 */
proto.loupe.services.setting.GetSettingsCommonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.setting.GetSettingsCommonRequest;
  return proto.loupe.services.setting.GetSettingsCommonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.setting.GetSettingsCommonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest}
 */
proto.loupe.services.setting.GetSettingsCommonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addFullKeys(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addRelatedTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.setting.GetSettingsCommonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.setting.GetSettingsCommonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.setting.GetSettingsCommonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFullKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getRelatedTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest} returns this
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest} returns this
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest} returns this
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest} returns this
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string key = 5;
 * @return {string}
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest} returns this
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string full_keys = 6;
 * @return {!Array<string>}
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.getFullKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest} returns this
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.setFullKeysList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest} returns this
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.addFullKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest} returns this
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.clearFullKeysList = function() {
  return this.setFullKeysList([]);
};


/**
 * repeated string related_types = 7;
 * @return {!Array<string>}
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.getRelatedTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest} returns this
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.setRelatedTypesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest} returns this
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.addRelatedTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.setting.GetSettingsCommonRequest} returns this
 */
proto.loupe.services.setting.GetSettingsCommonRequest.prototype.clearRelatedTypesList = function() {
  return this.setRelatedTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.setting.CloneTenantSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.setting.CloneTenantSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldTenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newTenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupIdMapMap: (f = msg.getGroupIdMapMap()) ? f.toObject(includeInstance, undefined) : [],
    userIdMapMap: (f = msg.getUserIdMapMap()) ? f.toObject(includeInstance, undefined) : [],
    keysList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.setting.CloneTenantSettingsRequest}
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.setting.CloneTenantSettingsRequest;
  return proto.loupe.services.setting.CloneTenantSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.setting.CloneTenantSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.setting.CloneTenantSettingsRequest}
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewTenantId(value);
      break;
    case 3:
      var value = msg.getGroupIdMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = msg.getUserIdMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.setting.CloneTenantSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.setting.CloneTenantSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupIdMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getUserIdMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string old_tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.getOldTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.setting.CloneTenantSettingsRequest} returns this
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.setOldTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.getNewTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.setting.CloneTenantSettingsRequest} returns this
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.setNewTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, string> group_id_map = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.getGroupIdMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.setting.CloneTenantSettingsRequest} returns this
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.clearGroupIdMapMap = function() {
  this.getGroupIdMapMap().clear();
  return this;};


/**
 * map<string, string> user_id_map = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.getUserIdMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.setting.CloneTenantSettingsRequest} returns this
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.clearUserIdMapMap = function() {
  this.getUserIdMapMap().clear();
  return this;};


/**
 * repeated string keys = 5;
 * @return {!Array<string>}
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.getKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.setting.CloneTenantSettingsRequest} returns this
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.setKeysList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.setting.CloneTenantSettingsRequest} returns this
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.addKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.setting.CloneTenantSettingsRequest} returns this
 */
proto.loupe.services.setting.CloneTenantSettingsRequest.prototype.clearKeysList = function() {
  return this.setKeysList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.setting.CloneTenantSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.setting.CloneTenantSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.setting.CloneTenantSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.setting.CloneTenantSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.setting.CloneTenantSettingsResponse}
 */
proto.loupe.services.setting.CloneTenantSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.setting.CloneTenantSettingsResponse;
  return proto.loupe.services.setting.CloneTenantSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.setting.CloneTenantSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.setting.CloneTenantSettingsResponse}
 */
proto.loupe.services.setting.CloneTenantSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.setting.CloneTenantSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.setting.CloneTenantSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.setting.CloneTenantSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.setting.CloneTenantSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.setting.DeleteAllTenantSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.setting.DeleteAllTenantSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.setting.DeleteAllTenantSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.setting.DeleteAllTenantSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.setting.DeleteAllTenantSettingsRequest}
 */
proto.loupe.services.setting.DeleteAllTenantSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.setting.DeleteAllTenantSettingsRequest;
  return proto.loupe.services.setting.DeleteAllTenantSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.setting.DeleteAllTenantSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.setting.DeleteAllTenantSettingsRequest}
 */
proto.loupe.services.setting.DeleteAllTenantSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.setting.DeleteAllTenantSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.setting.DeleteAllTenantSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.setting.DeleteAllTenantSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.setting.DeleteAllTenantSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.setting.DeleteAllTenantSettingsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.setting.DeleteAllTenantSettingsRequest} returns this
 */
proto.loupe.services.setting.DeleteAllTenantSettingsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.setting.DeleteAllTenantSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.setting.DeleteAllTenantSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.setting.DeleteAllTenantSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.setting.DeleteAllTenantSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.setting.DeleteAllTenantSettingsResponse}
 */
proto.loupe.services.setting.DeleteAllTenantSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.setting.DeleteAllTenantSettingsResponse;
  return proto.loupe.services.setting.DeleteAllTenantSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.setting.DeleteAllTenantSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.setting.DeleteAllTenantSettingsResponse}
 */
proto.loupe.services.setting.DeleteAllTenantSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.setting.DeleteAllTenantSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.setting.DeleteAllTenantSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.setting.DeleteAllTenantSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.setting.DeleteAllTenantSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.loupe.services.setting);
