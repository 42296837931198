import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toGetGroupSyncStateRequest } from '../grpc/converters'
import { GroupSyncStatus } from '../grpc/enums'
import { guid } from '../lib/guid'
import { useAuth } from './auth'
import { useGroupsError } from './groupsError'

const SyncStateContext = React.createContext()

export function SyncStateProvider({ children }) {
  const { tenantId } = useAuth()

  const [key, setKey] = useState(guid())
  const [syncStatus, setSyncStatus] = useState(GroupSyncStatus.INACTIVE)

  const { handleFailedPreconditionError } = useGroupsError()

  useGrpcEffect({
    request: toGetGroupSyncStateRequest({
      tenantId,
    }),
    onError: (err) => {
      handleFailedPreconditionError(err)
    },
    onSuccess: (obj) => setSyncStatus(obj.status),
    grpcMethod: 'getGroupSyncState',
    debug: false,
  }, [tenantId, key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      syncStatus,
      setSyncStatus,
      key,
      invalidate,
    }
  }, [syncStatus])

  return <SyncStateContext.Provider value={contextValue}>{children}</SyncStateContext.Provider>
}

export function useSyncState() {
  const context = React.useContext(SyncStateContext)
  if (context === undefined) {
    throw new Error('useSyncState must be used within a SyncStateProvider')
  }
  return context
}
