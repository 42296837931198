import React, { useCallback } from 'react'
import Label from '../../common/label'
import FilterEditor from '../../filter/filterEditor'
import { useRoutes } from '../../../context/routes'
import Tooltip from '../../common/tooltip'
import Info from '../../icons/info'
import { Link } from 'react-router-dom'
import { useForecastingConfigColumn } from '../../../context/forecastingConfigColumn'

const ForecastConfigCategoriesModalFilter = (props) => {
  const { routes } = useRoutes()
  const { cannotBePublished } = useForecastingConfigColumn()

  return (
    <div>
      <div className="mb-4">
        <div className="flex flex-row">
          <Label
            text="Filters"
            className="text-size-20px font-weight-700" />
          {cannotBePublished && (
            <Tooltip
              arrow
              placement="right"
              title="Read-only metrics must have a filter or a formula before this forecast can be published.">
              <Info htmlColor="#5951FF" transform="scale(0.8)" />
            </Tooltip>
          )}
        </div>
        <div className="text-color-51636a font-weight-400">
          Choose the filters you’d like to apply to this metric, including any forecast categories or additional fields.
          You can manage filter and field choices from the
          {' '}
          <Link className="font-weight-500 text-color-5951FF" to={routes.dataSync}>Data Sync</Link>
          {' '}
          page.
        </div>

        <div className="my-4 flex flex-row justify-between">
          <FilterEditor />
        </div>
      </div>

    </div>
  )
}

export default ForecastConfigCategoriesModalFilter
