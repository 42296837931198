import { useCallback, useEffect, useState } from 'react'

export function useSearchString(props) {
  const { search } = window.location
  const [searchParams, setSearchParams] = useState(new Map())

  useEffect(() => {
    const searchPairs = search.substring(1).split('&').map((s) => s.split('='))
    setSearchParams(new Map(searchPairs))
  }, [search])

  const encodeParams = useCallback(() => {
    [...searchParams].map((s) => s.join('=')).join('&')
  }, [searchParams])

  return {
    encodeParams,
    searchParams
  }
}
