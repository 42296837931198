/**
 * @fileoverview gRPC-Web generated client stub for loupe.services.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.0
// 	protoc              v3.20.3
// source: src/services/api/api.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var src_common_quota_quota_pb = require('../../../src/common/quota/quota_pb.js')

var src_common_actor_pb = require('../../../src/common/actor_pb.js')

var src_common_empty_message_pb = require('../../../src/common/empty_message_pb.js')

var src_common_data_request_pb = require('../../../src/common/data_request_pb.js')

var src_common_person_people_list_pb = require('../../../src/common/person/people_list_pb.js')

var src_common_person_person_pb = require('../../../src/common/person/person_pb.js')

var src_common_tenant_tenant_pb = require('../../../src/common/tenant/tenant_pb.js')

var src_common_organization_organization_pb = require('../../../src/common/organization/organization_pb.js')

var src_common_opportunity_opportunity_list_pb = require('../../../src/common/opportunity/opportunity_list_pb.js')

var src_common_opportunity_oppsetting_oppsetting_pb = require('../../../src/common/opportunity/oppsetting/oppsetting_pb.js')

var src_common_insight_insights_list_pb = require('../../../src/common/insight/insights_list_pb.js')

var src_common_pipeline_pipeline_data_container_pb = require('../../../src/common/pipeline/pipeline_data_container_pb.js')

var src_common_notification_notification_pb = require('../../../src/common/notification/notification_pb.js')

var src_services_setting_setting_pb = require('../../../src/services/setting/setting_pb.js')

var src_common_setting_settings_list_pb = require('../../../src/common/setting/settings_list_pb.js')

var src_common_signal_signal_pb = require('../../../src/common/signal/signal_pb.js')

var src_services_forecast_v2_forecast_pb = require('../../../src/services/forecast/v2/forecast_pb.js')

var src_common_forecast_v2_forecast_pb = require('../../../src/common/forecast/v2/forecast_pb.js')

var src_services_forecast_v3_forecast_pb = require('../../../src/services/forecast/v3/forecast_pb.js')

var src_common_forecast_v3_forecast_pb = require('../../../src/common/forecast/v3/forecast_pb.js')

var src_common_nullable_nullable_pb = require('../../../src/common/nullable/nullable_pb.js')

var src_common_reporting_pipeline_report_pb = require('../../../src/common/reporting/pipeline_report_pb.js')

var src_services_reporting_reporting_pb = require('../../../src/services/reporting/reporting_pb.js')

var src_services_tenant_tenant_pb = require('../../../src/services/tenant/tenant_pb.js')

var src_services_mapping_mapping_pb = require('../../../src/services/mapping/mapping_pb.js')

var src_services_crm$data$access_crm$data$access_pb = require('../../../src/services/crm-data-access/crm-data-access_pb.js')

var src_services_data$resolver_data$resolver_pb = require('../../../src/services/data-resolver/data-resolver_pb.js')

var src_services_orchard_orchard_pb = require('../../../src/services/orchard/orchard_pb.js')

var src_services_liftoff_liftoff_pb = require('../../../src/services/liftoff/liftoff_pb.js')

var src_common_orchard_group_pb = require('../../../src/common/orchard/group_pb.js')

var src_common_orchard_person_pb = require('../../../src/common/orchard/person_pb.js')

var src_common_orchard_system_role_pb = require('../../../src/common/orchard/system_role_pb.js')

var src_common_orchard_crm_role_pb = require('../../../src/common/orchard/crm_role_pb.js')

var src_services_notes_notes_pb = require('../../../src/services/notes/notes_pb.js')

var src_services_activity_activity_pb = require('../../../src/services/activity/activity_pb.js')

var src_services_bouncer_bouncer_pb = require('../../../src/services/bouncer/bouncer_pb.js')

var src_common_notes_notes_pb = require('../../../src/common/notes/notes_pb.js')

var src_common_integration_object_fieldsetting_fieldsetting_pb = require('../../../src/common/integration/object/fieldsetting/fieldsetting_pb.js')

var src_services_integration$invoker_integration$invoker_pb = require('../../../src/services/integration-invoker/integration-invoker_pb.js')

var src_common_integration_type_type_pb = require('../../../src/common/integration/type/type_pb.js')

var src_common_search_search_pb = require('../../../src/common/search/search_pb.js')

var src_common_search_item_item_pb = require('../../../src/common/search/item/item_pb.js')

var src_common_gml_gml_pb = require('../../../src/common/gml/gml_pb.js')

var src_common_filter_filter_pb = require('../../../src/common/filter/filter_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var src_services_goal$manager_goal$manager_pb = require('../../../src/services/goal-manager/goal-manager_pb.js')

var src_common_auth_auth_pb = require('../../../src/common/auth/auth_pb.js')

var src_common_setting_app_feature_hierarchy_pb = require('../../../src/common/setting/app/feature/hierarchy_pb.js')

var src_common_setting_app_feature_outreach_settings_pb = require('../../../src/common/setting/app/feature/outreach_settings_pb.js')

var src_common_setting_app_feature_pipeline_pb = require('../../../src/common/setting/app/feature/pipeline_pb.js')

var src_common_setting_app_feature_reporting_pb = require('../../../src/common/setting/app/feature/reporting_pb.js')

var src_common_setting_app_feature_reporting_options_pb = require('../../../src/common/setting/app/feature/reporting_options_pb.js')

var src_common_setting_app_feature_scenario_planner_pb = require('../../../src/common/setting/app/feature/scenario_planner_pb.js')

var src_common_setting_app_feature_forecasting_forecasting_pb = require('../../../src/common/setting/app/feature/forecasting/forecasting_pb.js')

var src_common_canvas_canvas_pb = require('../../../src/common/canvas/canvas_pb.js')

var src_common_realtime_query_pb = require('../../../src/common/realtime/query_pb.js')

var src_common_canvas_canvas_tile_pb = require('../../../src/common/canvas/canvas_tile_pb.js')

var src_services_mosaic_mosaic_pb = require('../../../src/services/mosaic/mosaic_pb.js')

var src_services_hotspring_hotspring_pb = require('../../../src/services/hotspring/hotspring_pb.js')

var src_services_snoop_snoop_pb = require('../../../src/services/snoop/snoop_pb.js')

var src_services_tightbeam_global_tightbeam_global_pb = require('../../../src/services/tightbeam_global/tightbeam_global_pb.js')

var src_services_dscds_cc_cc_pb = require('../../../src/services/dscds/cc/cc_pb.js')
const proto = {};
proto.loupe = {};
proto.loupe.services = {};
proto.loupe.services.api = require('./api_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.loupe.services.api.ApiClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.loupe.services.api.ApiPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.UpsertUserQuotasRequest,
 *   !proto.loupe.services.api.UpsertUserQuotasResponse>}
 */
const methodDescriptor_Api_UpsertUserQuotas = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertUserQuotas',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.UpsertUserQuotasRequest,
  proto.loupe.services.api.UpsertUserQuotasResponse,
  /**
   * @param {!proto.loupe.services.api.UpsertUserQuotasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.UpsertUserQuotasResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.UpsertUserQuotasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.UpsertUserQuotasResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.UpsertUserQuotasResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertUserQuotas =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertUserQuotas',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertUserQuotas,
      callback);
};


/**
 * @param {!proto.loupe.services.api.UpsertUserQuotasRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.UpsertUserQuotasResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertUserQuotas =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertUserQuotas',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertUserQuotas);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.person.PeopleList>}
 */
const methodDescriptor_Api_GetManagers = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetManagers',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_person_people_list_pb.PeopleList,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_person_people_list_pb.PeopleList.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.person.PeopleList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.person.PeopleList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getManagers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetManagers',
      request,
      metadata || {},
      methodDescriptor_Api_GetManagers,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.person.PeopleList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getManagers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetManagers',
      request,
      metadata || {},
      methodDescriptor_Api_GetManagers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.person.PeopleList>}
 */
const methodDescriptor_Api_GetTeam = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetTeam',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_person_people_list_pb.PeopleList,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_person_people_list_pb.PeopleList.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.person.PeopleList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.person.PeopleList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetTeam',
      request,
      metadata || {},
      methodDescriptor_Api_GetTeam,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.person.PeopleList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetTeam',
      request,
      metadata || {},
      methodDescriptor_Api_GetTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.person.Person>}
 */
const methodDescriptor_Api_GetFullPersonPayload = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetFullPersonPayload',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_person_person_pb.Person,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_person_person_pb.Person.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.person.Person)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.person.Person>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getFullPersonPayload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetFullPersonPayload',
      request,
      metadata || {},
      methodDescriptor_Api_GetFullPersonPayload,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.person.Person>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getFullPersonPayload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetFullPersonPayload',
      request,
      metadata || {},
      methodDescriptor_Api_GetFullPersonPayload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.person.PeopleList>}
 */
const methodDescriptor_Api_GetAllPeople = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetAllPeople',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_person_people_list_pb.PeopleList,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_person_people_list_pb.PeopleList.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.person.PeopleList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.person.PeopleList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getAllPeople =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetAllPeople',
      request,
      metadata || {},
      methodDescriptor_Api_GetAllPeople,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.person.PeopleList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getAllPeople =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetAllPeople',
      request,
      metadata || {},
      methodDescriptor_Api_GetAllPeople);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.person.Person>}
 */
const methodDescriptor_Api_GetSelf = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSelf',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_person_person_pb.Person,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_person_person_pb.Person.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.person.Person)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.person.Person>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSelf =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSelf',
      request,
      metadata || {},
      methodDescriptor_Api_GetSelf,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.person.Person>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSelf =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSelf',
      request,
      metadata || {},
      methodDescriptor_Api_GetSelf);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.opportunity.OpportunityList>}
 */
const methodDescriptor_Api_GetOpportunitiesByTeam = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetOpportunitiesByTeam',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_opportunity_opportunity_list_pb.OpportunityList,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_opportunity_opportunity_list_pb.OpportunityList.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.opportunity.OpportunityList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.opportunity.OpportunityList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getOpportunitiesByTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetOpportunitiesByTeam',
      request,
      metadata || {},
      methodDescriptor_Api_GetOpportunitiesByTeam,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.opportunity.OpportunityList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getOpportunitiesByTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetOpportunitiesByTeam',
      request,
      metadata || {},
      methodDescriptor_Api_GetOpportunitiesByTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.opportunity.oppsetting.TeamOppSettings>}
 */
const methodDescriptor_Api_GetOppSettingsByTeam = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetOppSettingsByTeam',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_opportunity_oppsetting_oppsetting_pb.TeamOppSettings,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_opportunity_oppsetting_oppsetting_pb.TeamOppSettings.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.opportunity.oppsetting.TeamOppSettings)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.opportunity.oppsetting.TeamOppSettings>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getOppSettingsByTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetOppSettingsByTeam',
      request,
      metadata || {},
      methodDescriptor_Api_GetOppSettingsByTeam,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.opportunity.oppsetting.TeamOppSettings>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getOppSettingsByTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetOppSettingsByTeam',
      request,
      metadata || {},
      methodDescriptor_Api_GetOppSettingsByTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest,
 *   !proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse>}
 */
const methodDescriptor_Api_GetOpportunitiesById = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetOpportunitiesById',
  grpc.web.MethodType.UNARY,
  src_services_crm$data$access_crm$data$access_pb.GetOpportunitiesByIdRequest,
  src_services_crm$data$access_crm$data$access_pb.GetOpportunitiesByIdResponse,
  /**
   * @param {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_crm$data$access_crm$data$access_pb.GetOpportunitiesByIdResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getOpportunitiesById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetOpportunitiesById',
      request,
      metadata || {},
      methodDescriptor_Api_GetOpportunitiesById,
      callback);
};


/**
 * @param {!proto.loupe.services.crm_data_access.GetOpportunitiesByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.crm_data_access.GetOpportunitiesByIdResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getOpportunitiesById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetOpportunitiesById',
      request,
      metadata || {},
      methodDescriptor_Api_GetOpportunitiesById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.insight.InsightsList>}
 */
const methodDescriptor_Api_GetInsightsByTeam = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetInsightsByTeam',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_insight_insights_list_pb.InsightsList,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_insight_insights_list_pb.InsightsList.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.insight.InsightsList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.insight.InsightsList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getInsightsByTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetInsightsByTeam',
      request,
      metadata || {},
      methodDescriptor_Api_GetInsightsByTeam,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.insight.InsightsList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getInsightsByTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetInsightsByTeam',
      request,
      metadata || {},
      methodDescriptor_Api_GetInsightsByTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.insight.InsightsList>}
 */
const methodDescriptor_Api_GetInsightsByUser = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetInsightsByUser',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_insight_insights_list_pb.InsightsList,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_insight_insights_list_pb.InsightsList.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.insight.InsightsList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.insight.InsightsList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getInsightsByUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetInsightsByUser',
      request,
      metadata || {},
      methodDescriptor_Api_GetInsightsByUser,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.insight.InsightsList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getInsightsByUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetInsightsByUser',
      request,
      metadata || {},
      methodDescriptor_Api_GetInsightsByUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.insight.InsightsList>}
 */
const methodDescriptor_Api_GetInsightsByOpportunity = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetInsightsByOpportunity',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_insight_insights_list_pb.InsightsList,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_insight_insights_list_pb.InsightsList.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.insight.InsightsList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.insight.InsightsList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getInsightsByOpportunity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetInsightsByOpportunity',
      request,
      metadata || {},
      methodDescriptor_Api_GetInsightsByOpportunity,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.insight.InsightsList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getInsightsByOpportunity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetInsightsByOpportunity',
      request,
      metadata || {},
      methodDescriptor_Api_GetInsightsByOpportunity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.insight.InsightsList>}
 */
const methodDescriptor_Api_GetStrengthsByUser = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetStrengthsByUser',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_insight_insights_list_pb.InsightsList,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_insight_insights_list_pb.InsightsList.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.insight.InsightsList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.insight.InsightsList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getStrengthsByUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetStrengthsByUser',
      request,
      metadata || {},
      methodDescriptor_Api_GetStrengthsByUser,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.insight.InsightsList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getStrengthsByUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetStrengthsByUser',
      request,
      metadata || {},
      methodDescriptor_Api_GetStrengthsByUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.insight.InsightsList>}
 */
const methodDescriptor_Api_GetWeaknessesByUser = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetWeaknessesByUser',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_insight_insights_list_pb.InsightsList,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_insight_insights_list_pb.InsightsList.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.insight.InsightsList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.insight.InsightsList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getWeaknessesByUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetWeaknessesByUser',
      request,
      metadata || {},
      methodDescriptor_Api_GetWeaknessesByUser,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.insight.InsightsList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getWeaknessesByUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetWeaknessesByUser',
      request,
      metadata || {},
      methodDescriptor_Api_GetWeaknessesByUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.EmptyMessage,
 *   !proto.loupe.common.insight.InsightsList>}
 */
const methodDescriptor_Api_GetInsightsDistinct = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetInsightsDistinct',
  grpc.web.MethodType.UNARY,
  src_common_empty_message_pb.EmptyMessage,
  src_common_insight_insights_list_pb.InsightsList,
  /**
   * @param {!proto.loupe.common.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_insight_insights_list_pb.InsightsList.deserializeBinary
);


/**
 * @param {!proto.loupe.common.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.insight.InsightsList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.insight.InsightsList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getInsightsDistinct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetInsightsDistinct',
      request,
      metadata || {},
      methodDescriptor_Api_GetInsightsDistinct,
      callback);
};


/**
 * @param {!proto.loupe.common.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.insight.InsightsList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getInsightsDistinct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetInsightsDistinct',
      request,
      metadata || {},
      methodDescriptor_Api_GetInsightsDistinct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.DataRequest,
 *   !proto.loupe.common.pipeline.PipelineDataContainer>}
 */
const methodDescriptor_Api_GetTeamPipelineData = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetTeamPipelineData',
  grpc.web.MethodType.UNARY,
  src_common_data_request_pb.DataRequest,
  src_common_pipeline_pipeline_data_container_pb.PipelineDataContainer,
  /**
   * @param {!proto.loupe.common.DataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_pipeline_pipeline_data_container_pb.PipelineDataContainer.deserializeBinary
);


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.pipeline.PipelineDataContainer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.pipeline.PipelineDataContainer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getTeamPipelineData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetTeamPipelineData',
      request,
      metadata || {},
      methodDescriptor_Api_GetTeamPipelineData,
      callback);
};


/**
 * @param {!proto.loupe.common.DataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.pipeline.PipelineDataContainer>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getTeamPipelineData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetTeamPipelineData',
      request,
      metadata || {},
      methodDescriptor_Api_GetTeamPipelineData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.notification.Notification,
 *   !proto.loupe.common.EmptyMessage>}
 */
const methodDescriptor_Api_SendNotification = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SendNotification',
  grpc.web.MethodType.UNARY,
  src_common_notification_notification_pb.Notification,
  src_common_empty_message_pb.EmptyMessage,
  /**
   * @param {!proto.loupe.common.notification.Notification} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_empty_message_pb.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.loupe.common.notification.Notification} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.sendNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SendNotification',
      request,
      metadata || {},
      methodDescriptor_Api_SendNotification,
      callback);
};


/**
 * @param {!proto.loupe.common.notification.Notification} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.sendNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SendNotification',
      request,
      metadata || {},
      methodDescriptor_Api_SendNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.SignalDataRequest,
 *   !proto.loupe.common.signal.SignalList>}
 */
const methodDescriptor_Api_GetSignalsByTeam = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSignalsByTeam',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.SignalDataRequest,
  src_common_signal_signal_pb.SignalList,
  /**
   * @param {!proto.loupe.services.api.SignalDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_signal_signal_pb.SignalList.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.SignalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.signal.SignalList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.signal.SignalList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSignalsByTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSignalsByTeam',
      request,
      metadata || {},
      methodDescriptor_Api_GetSignalsByTeam,
      callback);
};


/**
 * @param {!proto.loupe.services.api.SignalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.signal.SignalList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSignalsByTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSignalsByTeam',
      request,
      metadata || {},
      methodDescriptor_Api_GetSignalsByTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.SignalDataRequest,
 *   !proto.loupe.common.signal.SignalList>}
 */
const methodDescriptor_Api_GetSignalsByUser = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSignalsByUser',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.SignalDataRequest,
  src_common_signal_signal_pb.SignalList,
  /**
   * @param {!proto.loupe.services.api.SignalDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_signal_signal_pb.SignalList.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.SignalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.signal.SignalList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.signal.SignalList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSignalsByUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSignalsByUser',
      request,
      metadata || {},
      methodDescriptor_Api_GetSignalsByUser,
      callback);
};


/**
 * @param {!proto.loupe.services.api.SignalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.signal.SignalList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSignalsByUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSignalsByUser',
      request,
      metadata || {},
      methodDescriptor_Api_GetSignalsByUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.SignalDataRequest,
 *   !proto.loupe.common.signal.SignalList>}
 */
const methodDescriptor_Api_GetSignalsByOpportunity = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSignalsByOpportunity',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.SignalDataRequest,
  src_common_signal_signal_pb.SignalList,
  /**
   * @param {!proto.loupe.services.api.SignalDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_signal_signal_pb.SignalList.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.SignalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.signal.SignalList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.signal.SignalList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSignalsByOpportunity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSignalsByOpportunity',
      request,
      metadata || {},
      methodDescriptor_Api_GetSignalsByOpportunity,
      callback);
};


/**
 * @param {!proto.loupe.services.api.SignalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.signal.SignalList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSignalsByOpportunity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSignalsByOpportunity',
      request,
      metadata || {},
      methodDescriptor_Api_GetSignalsByOpportunity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.SignalDataRequest,
 *   !proto.loupe.common.signal.SignalList>}
 */
const methodDescriptor_Api_GetSignalStrengthsByUser = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSignalStrengthsByUser',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.SignalDataRequest,
  src_common_signal_signal_pb.SignalList,
  /**
   * @param {!proto.loupe.services.api.SignalDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_signal_signal_pb.SignalList.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.SignalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.signal.SignalList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.signal.SignalList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSignalStrengthsByUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSignalStrengthsByUser',
      request,
      metadata || {},
      methodDescriptor_Api_GetSignalStrengthsByUser,
      callback);
};


/**
 * @param {!proto.loupe.services.api.SignalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.signal.SignalList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSignalStrengthsByUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSignalStrengthsByUser',
      request,
      metadata || {},
      methodDescriptor_Api_GetSignalStrengthsByUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.SignalDataRequest,
 *   !proto.loupe.common.signal.SignalList>}
 */
const methodDescriptor_Api_GetSignalWeaknessesByUser = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSignalWeaknessesByUser',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.SignalDataRequest,
  src_common_signal_signal_pb.SignalList,
  /**
   * @param {!proto.loupe.services.api.SignalDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_signal_signal_pb.SignalList.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.SignalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.signal.SignalList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.signal.SignalList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSignalWeaknessesByUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSignalWeaknessesByUser',
      request,
      metadata || {},
      methodDescriptor_Api_GetSignalWeaknessesByUser,
      callback);
};


/**
 * @param {!proto.loupe.services.api.SignalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.signal.SignalList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSignalWeaknessesByUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSignalWeaknessesByUser',
      request,
      metadata || {},
      methodDescriptor_Api_GetSignalWeaknessesByUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.EmptyMessage,
 *   !proto.loupe.common.signal.SignalList>}
 */
const methodDescriptor_Api_GetSignalsDistinct = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSignalsDistinct',
  grpc.web.MethodType.UNARY,
  src_common_empty_message_pb.EmptyMessage,
  src_common_signal_signal_pb.SignalList,
  /**
   * @param {!proto.loupe.common.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_signal_signal_pb.SignalList.deserializeBinary
);


/**
 * @param {!proto.loupe.common.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.signal.SignalList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.signal.SignalList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSignalsDistinct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSignalsDistinct',
      request,
      metadata || {},
      methodDescriptor_Api_GetSignalsDistinct,
      callback);
};


/**
 * @param {!proto.loupe.common.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.signal.SignalList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSignalsDistinct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSignalsDistinct',
      request,
      metadata || {},
      methodDescriptor_Api_GetSignalsDistinct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetTenantOrganizationRequest,
 *   !proto.loupe.services.api.GetTenantOrganizationResponse>}
 */
const methodDescriptor_Api_GetTenantOrganization = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetTenantOrganization',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetTenantOrganizationRequest,
  proto.loupe.services.api.GetTenantOrganizationResponse,
  /**
   * @param {!proto.loupe.services.api.GetTenantOrganizationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetTenantOrganizationResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetTenantOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetTenantOrganizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetTenantOrganizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getTenantOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantOrganization',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantOrganization,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetTenantOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetTenantOrganizationResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getTenantOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantOrganization',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetTenantLicenseInfoRequest,
 *   !proto.loupe.services.api.GetTenantLicenseInfoResponse>}
 */
const methodDescriptor_Api_GetTenantLicenseInfo = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetTenantLicenseInfo',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetTenantLicenseInfoRequest,
  proto.loupe.services.api.GetTenantLicenseInfoResponse,
  /**
   * @param {!proto.loupe.services.api.GetTenantLicenseInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetTenantLicenseInfoResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetTenantLicenseInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetTenantLicenseInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetTenantLicenseInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getTenantLicenseInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantLicenseInfo',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantLicenseInfo,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetTenantLicenseInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetTenantLicenseInfoResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getTenantLicenseInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantLicenseInfo',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantLicenseInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.setting.GetSettingsCommonRequest,
 *   !proto.loupe.common.setting.SettingsList>}
 */
const methodDescriptor_Api_GetSettings = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSettings',
  grpc.web.MethodType.UNARY,
  src_services_setting_setting_pb.GetSettingsCommonRequest,
  src_common_setting_settings_list_pb.SettingsList,
  /**
   * @param {!proto.loupe.services.setting.GetSettingsCommonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_setting_settings_list_pb.SettingsList.deserializeBinary
);


/**
 * @param {!proto.loupe.services.setting.GetSettingsCommonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.setting.SettingsList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.setting.SettingsList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSettings',
      request,
      metadata || {},
      methodDescriptor_Api_GetSettings,
      callback);
};


/**
 * @param {!proto.loupe.services.setting.GetSettingsCommonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.setting.SettingsList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSettings',
      request,
      metadata || {},
      methodDescriptor_Api_GetSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetDataSyncIntervalRequest,
 *   !proto.loupe.services.api.GetDataSyncIntervalResponse>}
 */
const methodDescriptor_Api_GetDataSyncInterval = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetDataSyncInterval',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetDataSyncIntervalRequest,
  proto.loupe.services.api.GetDataSyncIntervalResponse,
  /**
   * @param {!proto.loupe.services.api.GetDataSyncIntervalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetDataSyncIntervalResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetDataSyncIntervalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetDataSyncIntervalResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetDataSyncIntervalResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getDataSyncInterval =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetDataSyncInterval',
      request,
      metadata || {},
      methodDescriptor_Api_GetDataSyncInterval,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetDataSyncIntervalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetDataSyncIntervalResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getDataSyncInterval =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetDataSyncInterval',
      request,
      metadata || {},
      methodDescriptor_Api_GetDataSyncInterval);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.SetDataSyncIntervalRequest,
 *   !proto.loupe.services.api.SetDataSyncIntervalResponse>}
 */
const methodDescriptor_Api_SetDataSyncInterval = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SetDataSyncInterval',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.SetDataSyncIntervalRequest,
  proto.loupe.services.api.SetDataSyncIntervalResponse,
  /**
   * @param {!proto.loupe.services.api.SetDataSyncIntervalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.SetDataSyncIntervalResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.SetDataSyncIntervalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.SetDataSyncIntervalResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.SetDataSyncIntervalResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.setDataSyncInterval =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SetDataSyncInterval',
      request,
      metadata || {},
      methodDescriptor_Api_SetDataSyncInterval,
      callback);
};


/**
 * @param {!proto.loupe.services.api.SetDataSyncIntervalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.SetDataSyncIntervalResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.setDataSyncInterval =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SetDataSyncInterval',
      request,
      metadata || {},
      methodDescriptor_Api_SetDataSyncInterval);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetPipelineSettingRequest,
 *   !proto.loupe.services.api.GetPipelineSettingResponse>}
 */
const methodDescriptor_Api_GetPipelineSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetPipelineSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetPipelineSettingRequest,
  proto.loupe.services.api.GetPipelineSettingResponse,
  /**
   * @param {!proto.loupe.services.api.GetPipelineSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetPipelineSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetPipelineSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetPipelineSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetPipelineSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getPipelineSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetPipelineSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetPipelineSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetPipelineSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetPipelineSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getPipelineSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetPipelineSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetPipelineSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.UpsertPipelineSettingRequest,
 *   !proto.loupe.services.api.UpsertPipelineSettingResponse>}
 */
const methodDescriptor_Api_UpsertPipelineSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertPipelineSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.UpsertPipelineSettingRequest,
  proto.loupe.services.api.UpsertPipelineSettingResponse,
  /**
   * @param {!proto.loupe.services.api.UpsertPipelineSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.UpsertPipelineSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.UpsertPipelineSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.UpsertPipelineSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.UpsertPipelineSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertPipelineSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertPipelineSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertPipelineSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.UpsertPipelineSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.UpsertPipelineSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertPipelineSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertPipelineSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertPipelineSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetReportingSettingRequest,
 *   !proto.loupe.services.api.GetReportingSettingResponse>}
 */
const methodDescriptor_Api_GetReportingSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetReportingSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetReportingSettingRequest,
  proto.loupe.services.api.GetReportingSettingResponse,
  /**
   * @param {!proto.loupe.services.api.GetReportingSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetReportingSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetReportingSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetReportingSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetReportingSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getReportingSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetReportingSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetReportingSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetReportingSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetReportingSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getReportingSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetReportingSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetReportingSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.UpsertReportingSettingRequest,
 *   !proto.loupe.services.api.UpsertReportingSettingResponse>}
 */
const methodDescriptor_Api_UpsertReportingSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertReportingSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.UpsertReportingSettingRequest,
  proto.loupe.services.api.UpsertReportingSettingResponse,
  /**
   * @param {!proto.loupe.services.api.UpsertReportingSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.UpsertReportingSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.UpsertReportingSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.UpsertReportingSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.UpsertReportingSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertReportingSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertReportingSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertReportingSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.UpsertReportingSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.UpsertReportingSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertReportingSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertReportingSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertReportingSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetReportingOptionsSettingRequest,
 *   !proto.loupe.services.api.GetReportingOptionsSettingResponse>}
 */
const methodDescriptor_Api_GetReportingOptionsSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetReportingOptionsSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetReportingOptionsSettingRequest,
  proto.loupe.services.api.GetReportingOptionsSettingResponse,
  /**
   * @param {!proto.loupe.services.api.GetReportingOptionsSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetReportingOptionsSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetReportingOptionsSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetReportingOptionsSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetReportingOptionsSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getReportingOptionsSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetReportingOptionsSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetReportingOptionsSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetReportingOptionsSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetReportingOptionsSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getReportingOptionsSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetReportingOptionsSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetReportingOptionsSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.UpsertReportingOptionsSettingRequest,
 *   !proto.loupe.services.api.UpsertReportingOptionsSettingResponse>}
 */
const methodDescriptor_Api_UpsertReportingOptionsSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertReportingOptionsSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.UpsertReportingOptionsSettingRequest,
  proto.loupe.services.api.UpsertReportingOptionsSettingResponse,
  /**
   * @param {!proto.loupe.services.api.UpsertReportingOptionsSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.UpsertReportingOptionsSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.UpsertReportingOptionsSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.UpsertReportingOptionsSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.UpsertReportingOptionsSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertReportingOptionsSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertReportingOptionsSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertReportingOptionsSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.UpsertReportingOptionsSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.UpsertReportingOptionsSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertReportingOptionsSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertReportingOptionsSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertReportingOptionsSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetScenarioPlannerSettingRequest,
 *   !proto.loupe.services.api.GetScenarioPlannerSettingResponse>}
 */
const methodDescriptor_Api_GetScenarioPlannerSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetScenarioPlannerSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetScenarioPlannerSettingRequest,
  proto.loupe.services.api.GetScenarioPlannerSettingResponse,
  /**
   * @param {!proto.loupe.services.api.GetScenarioPlannerSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetScenarioPlannerSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetScenarioPlannerSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetScenarioPlannerSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetScenarioPlannerSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getScenarioPlannerSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetScenarioPlannerSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetScenarioPlannerSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetScenarioPlannerSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetScenarioPlannerSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getScenarioPlannerSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetScenarioPlannerSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetScenarioPlannerSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.UpsertScenarioPlannerSettingRequest,
 *   !proto.loupe.services.api.UpsertScenarioPlannerSettingResponse>}
 */
const methodDescriptor_Api_UpsertScenarioPlannerSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertScenarioPlannerSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.UpsertScenarioPlannerSettingRequest,
  proto.loupe.services.api.UpsertScenarioPlannerSettingResponse,
  /**
   * @param {!proto.loupe.services.api.UpsertScenarioPlannerSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.UpsertScenarioPlannerSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.UpsertScenarioPlannerSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.UpsertScenarioPlannerSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertScenarioPlannerSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertScenarioPlannerSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertScenarioPlannerSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.UpsertScenarioPlannerSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.UpsertScenarioPlannerSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertScenarioPlannerSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertScenarioPlannerSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertScenarioPlannerSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetForecastingSettingRequest,
 *   !proto.loupe.services.api.GetForecastingSettingResponse>}
 */
const methodDescriptor_Api_GetForecastingSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetForecastingSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetForecastingSettingRequest,
  proto.loupe.services.api.GetForecastingSettingResponse,
  /**
   * @param {!proto.loupe.services.api.GetForecastingSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetForecastingSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetForecastingSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetForecastingSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetForecastingSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getForecastingSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastingSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastingSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetForecastingSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetForecastingSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getForecastingSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastingSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastingSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.UpsertForecastingSettingRequest,
 *   !proto.loupe.services.api.UpsertForecastingSettingResponse>}
 */
const methodDescriptor_Api_UpsertForecastingSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertForecastingSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.UpsertForecastingSettingRequest,
  proto.loupe.services.api.UpsertForecastingSettingResponse,
  /**
   * @param {!proto.loupe.services.api.UpsertForecastingSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.UpsertForecastingSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.UpsertForecastingSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.UpsertForecastingSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.UpsertForecastingSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertForecastingSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertForecastingSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertForecastingSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.UpsertForecastingSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.UpsertForecastingSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertForecastingSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertForecastingSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertForecastingSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetWritebackSettingRequest,
 *   !proto.loupe.services.api.GetWritebackSettingResponse>}
 */
const methodDescriptor_Api_GetWritebackSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetWritebackSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetWritebackSettingRequest,
  proto.loupe.services.api.GetWritebackSettingResponse,
  /**
   * @param {!proto.loupe.services.api.GetWritebackSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetWritebackSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetWritebackSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetWritebackSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetWritebackSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getWritebackSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetWritebackSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetWritebackSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetWritebackSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetWritebackSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getWritebackSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetWritebackSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetWritebackSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.UpsertWritebackSettingRequest,
 *   !proto.loupe.services.api.UpsertWritebackSettingResponse>}
 */
const methodDescriptor_Api_UpsertWritebackSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertWritebackSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.UpsertWritebackSettingRequest,
  proto.loupe.services.api.UpsertWritebackSettingResponse,
  /**
   * @param {!proto.loupe.services.api.UpsertWritebackSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.UpsertWritebackSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.UpsertWritebackSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.UpsertWritebackSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.UpsertWritebackSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertWritebackSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertWritebackSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertWritebackSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.UpsertWritebackSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.UpsertWritebackSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertWritebackSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertWritebackSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertWritebackSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetOutreachSettingRequest,
 *   !proto.loupe.services.api.GetOutreachSettingResponse>}
 */
const methodDescriptor_Api_GetOutreachSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetOutreachSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetOutreachSettingRequest,
  proto.loupe.services.api.GetOutreachSettingResponse,
  /**
   * @param {!proto.loupe.services.api.GetOutreachSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetOutreachSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetOutreachSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetOutreachSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetOutreachSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getOutreachSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetOutreachSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetOutreachSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetOutreachSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetOutreachSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getOutreachSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetOutreachSetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetOutreachSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.UpsertOutreachSettingRequest,
 *   !proto.loupe.services.api.UpsertOutreachSettingResponse>}
 */
const methodDescriptor_Api_UpsertOutreachSetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertOutreachSetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.UpsertOutreachSettingRequest,
  proto.loupe.services.api.UpsertOutreachSettingResponse,
  /**
   * @param {!proto.loupe.services.api.UpsertOutreachSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.UpsertOutreachSettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.UpsertOutreachSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.UpsertOutreachSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.UpsertOutreachSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertOutreachSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertOutreachSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertOutreachSetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.UpsertOutreachSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.UpsertOutreachSettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertOutreachSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertOutreachSetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertOutreachSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetHierarchySettingRequest,
 *   !proto.loupe.services.api.GetHierarchySettingResponse>}
 */
const methodDescriptor_Api_GetHierarchySetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetHierarchySetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetHierarchySettingRequest,
  proto.loupe.services.api.GetHierarchySettingResponse,
  /**
   * @param {!proto.loupe.services.api.GetHierarchySettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetHierarchySettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetHierarchySettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetHierarchySettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetHierarchySettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getHierarchySetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetHierarchySetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetHierarchySetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetHierarchySettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetHierarchySettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getHierarchySetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetHierarchySetting',
      request,
      metadata || {},
      methodDescriptor_Api_GetHierarchySetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.UpsertHierarchySettingRequest,
 *   !proto.loupe.services.api.UpsertHierarchySettingResponse>}
 */
const methodDescriptor_Api_UpsertHierarchySetting = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertHierarchySetting',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.UpsertHierarchySettingRequest,
  proto.loupe.services.api.UpsertHierarchySettingResponse,
  /**
   * @param {!proto.loupe.services.api.UpsertHierarchySettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.UpsertHierarchySettingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.UpsertHierarchySettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.UpsertHierarchySettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.UpsertHierarchySettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertHierarchySetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertHierarchySetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertHierarchySetting,
      callback);
};


/**
 * @param {!proto.loupe.services.api.UpsertHierarchySettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.UpsertHierarchySettingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertHierarchySetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertHierarchySetting',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertHierarchySetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v2.SaveForecastRequest,
 *   !proto.loupe.services.forecast.v2.FullForecastInfo>}
 */
const methodDescriptor_Api_SaveForecast = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SaveForecast',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v2_forecast_pb.SaveForecastRequest,
  src_services_forecast_v2_forecast_pb.FullForecastInfo,
  /**
   * @param {!proto.loupe.services.forecast.v2.SaveForecastRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v2_forecast_pb.FullForecastInfo.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v2.SaveForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v2.FullForecastInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v2.FullForecastInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.saveForecast =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SaveForecast',
      request,
      metadata || {},
      methodDescriptor_Api_SaveForecast,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v2.SaveForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v2.FullForecastInfo>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.saveForecast =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SaveForecast',
      request,
      metadata || {},
      methodDescriptor_Api_SaveForecast);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v2.SaveForecastRequest,
 *   !proto.loupe.services.forecast.v2.FullForecastInfo>}
 */
const methodDescriptor_Api_SubmitForecast = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SubmitForecast',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v2_forecast_pb.SaveForecastRequest,
  src_services_forecast_v2_forecast_pb.FullForecastInfo,
  /**
   * @param {!proto.loupe.services.forecast.v2.SaveForecastRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v2_forecast_pb.FullForecastInfo.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v2.SaveForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v2.FullForecastInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v2.FullForecastInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.submitForecast =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SubmitForecast',
      request,
      metadata || {},
      methodDescriptor_Api_SubmitForecast,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v2.SaveForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v2.FullForecastInfo>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.submitForecast =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SubmitForecast',
      request,
      metadata || {},
      methodDescriptor_Api_SubmitForecast);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v2.DefaultForecastRequest,
 *   !proto.loupe.services.forecast.v2.FullForecastInfo>}
 */
const methodDescriptor_Api_GetCurrentForecast = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetCurrentForecast',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v2_forecast_pb.DefaultForecastRequest,
  src_services_forecast_v2_forecast_pb.FullForecastInfo,
  /**
   * @param {!proto.loupe.services.forecast.v2.DefaultForecastRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v2_forecast_pb.FullForecastInfo.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v2.DefaultForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v2.FullForecastInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v2.FullForecastInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getCurrentForecast =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetCurrentForecast',
      request,
      metadata || {},
      methodDescriptor_Api_GetCurrentForecast,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v2.DefaultForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v2.FullForecastInfo>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getCurrentForecast =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetCurrentForecast',
      request,
      metadata || {},
      methodDescriptor_Api_GetCurrentForecast);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v2.DefaultForecastRequest,
 *   !proto.loupe.services.forecast.v2.FullForecastInfo>}
 */
const methodDescriptor_Api_GetCurrentManagerForecast = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetCurrentManagerForecast',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v2_forecast_pb.DefaultForecastRequest,
  src_services_forecast_v2_forecast_pb.FullForecastInfo,
  /**
   * @param {!proto.loupe.services.forecast.v2.DefaultForecastRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v2_forecast_pb.FullForecastInfo.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v2.DefaultForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v2.FullForecastInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v2.FullForecastInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getCurrentManagerForecast =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetCurrentManagerForecast',
      request,
      metadata || {},
      methodDescriptor_Api_GetCurrentManagerForecast,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v2.DefaultForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v2.FullForecastInfo>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getCurrentManagerForecast =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetCurrentManagerForecast',
      request,
      metadata || {},
      methodDescriptor_Api_GetCurrentManagerForecast);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest,
 *   !proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse>}
 */
const methodDescriptor_Api_GetForecastedOpportunities = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetForecastedOpportunities',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v2_forecast_pb.ForecastOpportunityIdsRequest,
  src_services_forecast_v2_forecast_pb.ForecastOpportunityIdsResponse,
  /**
   * @param {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v2_forecast_pb.ForecastOpportunityIdsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getForecastedOpportunities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastedOpportunities',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastedOpportunities,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v2.ForecastOpportunityIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v2.ForecastOpportunityIdsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getForecastedOpportunities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastedOpportunities',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastedOpportunities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest,
 *   !proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse>}
 */
const methodDescriptor_Api_GetRawForecastCategoriesForUsers = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetRawForecastCategoriesForUsers',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest,
  proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse,
  /**
   * @param {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getRawForecastCategoriesForUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetRawForecastCategoriesForUsers',
      request,
      metadata || {},
      methodDescriptor_Api_GetRawForecastCategoriesForUsers,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getRawForecastCategoriesForUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetRawForecastCategoriesForUsers',
      request,
      metadata || {},
      methodDescriptor_Api_GetRawForecastCategoriesForUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v2.GetDefaultForecastList,
 *   !proto.loupe.services.forecast.v2.ForecastListResponse>}
 */
const methodDescriptor_Api_GetForecastListByManagerId = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetForecastListByManagerId',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v2_forecast_pb.GetDefaultForecastList,
  src_services_forecast_v2_forecast_pb.ForecastListResponse,
  /**
   * @param {!proto.loupe.services.forecast.v2.GetDefaultForecastList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v2_forecast_pb.ForecastListResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v2.GetDefaultForecastList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v2.ForecastListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v2.ForecastListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getForecastListByManagerId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastListByManagerId',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastListByManagerId,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v2.GetDefaultForecastList} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v2.ForecastListResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getForecastListByManagerId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastListByManagerId',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastListByManagerId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v2.DefaultForecastRequest,
 *   !proto.loupe.common.forecast.v2.Forecast>}
 */
const methodDescriptor_Api_GetNextPeriodForecast = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetNextPeriodForecast',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v2_forecast_pb.DefaultForecastRequest,
  src_common_forecast_v2_forecast_pb.Forecast,
  /**
   * @param {!proto.loupe.services.forecast.v2.DefaultForecastRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_forecast_v2_forecast_pb.Forecast.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v2.DefaultForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.forecast.v2.Forecast)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.forecast.v2.Forecast>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getNextPeriodForecast =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetNextPeriodForecast',
      request,
      metadata || {},
      methodDescriptor_Api_GetNextPeriodForecast,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v2.DefaultForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.forecast.v2.Forecast>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getNextPeriodForecast =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetNextPeriodForecast',
      request,
      metadata || {},
      methodDescriptor_Api_GetNextPeriodForecast);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetWeightedForecastAmountsRequest,
 *   !proto.loupe.services.api.GetWeightedForecastAmountsResponse>}
 */
const methodDescriptor_Api_GetWeightedForecastAmounts = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetWeightedForecastAmounts',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetWeightedForecastAmountsRequest,
  proto.loupe.services.api.GetWeightedForecastAmountsResponse,
  /**
   * @param {!proto.loupe.services.api.GetWeightedForecastAmountsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetWeightedForecastAmountsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetWeightedForecastAmountsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetWeightedForecastAmountsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetWeightedForecastAmountsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getWeightedForecastAmounts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetWeightedForecastAmounts',
      request,
      metadata || {},
      methodDescriptor_Api_GetWeightedForecastAmounts,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetWeightedForecastAmountsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetWeightedForecastAmountsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getWeightedForecastAmounts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetWeightedForecastAmounts',
      request,
      metadata || {},
      methodDescriptor_Api_GetWeightedForecastAmounts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v2.SearchForecastsRequest,
 *   !proto.loupe.services.forecast.v2.SearchForecastsResponse>}
 */
const methodDescriptor_Api_SearchForecasts = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SearchForecasts',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v2_forecast_pb.SearchForecastsRequest,
  src_services_forecast_v2_forecast_pb.SearchForecastsResponse,
  /**
   * @param {!proto.loupe.services.forecast.v2.SearchForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v2_forecast_pb.SearchForecastsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v2.SearchForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v2.SearchForecastsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v2.SearchForecastsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.searchForecasts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SearchForecasts',
      request,
      metadata || {},
      methodDescriptor_Api_SearchForecasts,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v2.SearchForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v2.SearchForecastsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.searchForecasts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SearchForecasts',
      request,
      metadata || {},
      methodDescriptor_Api_SearchForecasts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.SaveForecastConfigRequest,
 *   !proto.loupe.services.forecast.v3.ForecastConfig>}
 */
const methodDescriptor_Api_SaveForecastConfig = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SaveForecastConfig',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.SaveForecastConfigRequest,
  src_services_forecast_v3_forecast_pb.ForecastConfig,
  /**
   * @param {!proto.loupe.services.forecast.v3.SaveForecastConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.ForecastConfig.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.SaveForecastConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.ForecastConfig)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.ForecastConfig>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.saveForecastConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SaveForecastConfig',
      request,
      metadata || {},
      methodDescriptor_Api_SaveForecastConfig,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.SaveForecastConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.ForecastConfig>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.saveForecastConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SaveForecastConfig',
      request,
      metadata || {},
      methodDescriptor_Api_SaveForecastConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest,
 *   !proto.loupe.services.forecast.v3.ForecastConfigColumn>}
 */
const methodDescriptor_Api_SaveForecastConfigColumn = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SaveForecastConfigColumn',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.SaveForecastConfigColumnRequest,
  src_services_forecast_v3_forecast_pb.ForecastConfigColumn,
  /**
   * @param {!proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.ForecastConfigColumn.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.ForecastConfigColumn)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.ForecastConfigColumn>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.saveForecastConfigColumn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SaveForecastConfigColumn',
      request,
      metadata || {},
      methodDescriptor_Api_SaveForecastConfigColumn,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.ForecastConfigColumn>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.saveForecastConfigColumn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SaveForecastConfigColumn',
      request,
      metadata || {},
      methodDescriptor_Api_SaveForecastConfigColumn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.ListForecastConfigsRequest,
 *   !proto.loupe.services.forecast.v3.ListForecastConfigsResponse>}
 */
const methodDescriptor_Api_ListForecastConfigs = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListForecastConfigs',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.ListForecastConfigsRequest,
  src_services_forecast_v3_forecast_pb.ListForecastConfigsResponse,
  /**
   * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.ListForecastConfigsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.ListForecastConfigsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.ListForecastConfigsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listForecastConfigs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListForecastConfigs',
      request,
      metadata || {},
      methodDescriptor_Api_ListForecastConfigs,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.ListForecastConfigsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listForecastConfigs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListForecastConfigs',
      request,
      metadata || {},
      methodDescriptor_Api_ListForecastConfigs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest,
 *   !proto.loupe.services.forecast.v3.ListForecastConfigsResponse>}
 */
const methodDescriptor_Api_ListForecastConfigsByGroupId = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListForecastConfigsByGroupId',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.ListForecastConfigsByGroupIdRequest,
  src_services_forecast_v3_forecast_pb.ListForecastConfigsResponse,
  /**
   * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.ListForecastConfigsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.ListForecastConfigsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.ListForecastConfigsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listForecastConfigsByGroupId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListForecastConfigsByGroupId',
      request,
      metadata || {},
      methodDescriptor_Api_ListForecastConfigsByGroupId,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.ListForecastConfigsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listForecastConfigsByGroupId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListForecastConfigsByGroupId',
      request,
      metadata || {},
      methodDescriptor_Api_ListForecastConfigsByGroupId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest,
 *   !proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse>}
 */
const methodDescriptor_Api_ListForecastConfigColumns = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListForecastConfigColumns',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.ListForecastConfigColumnsRequest,
  src_services_forecast_v3_forecast_pb.ListForecastConfigColumnsResponse,
  /**
   * @param {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.ListForecastConfigColumnsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listForecastConfigColumns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListForecastConfigColumns',
      request,
      metadata || {},
      methodDescriptor_Api_ListForecastConfigColumns,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listForecastConfigColumns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListForecastConfigColumns',
      request,
      metadata || {},
      methodDescriptor_Api_ListForecastConfigColumns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.GetForecastConfigRequest,
 *   !proto.loupe.services.forecast.v3.ForecastConfig>}
 */
const methodDescriptor_Api_GetForecastConfig = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetForecastConfig',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.GetForecastConfigRequest,
  src_services_forecast_v3_forecast_pb.ForecastConfig,
  /**
   * @param {!proto.loupe.services.forecast.v3.GetForecastConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.ForecastConfig.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.GetForecastConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.ForecastConfig)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.ForecastConfig>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getForecastConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastConfig',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastConfig,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.GetForecastConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.ForecastConfig>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getForecastConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastConfig',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest,
 *   !proto.loupe.services.forecast.v3.ForecastConfigColumn>}
 */
const methodDescriptor_Api_GetForecastConfigColumn = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetForecastConfigColumn',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.GetForecastConfigColumnRequest,
  src_services_forecast_v3_forecast_pb.ForecastConfigColumn,
  /**
   * @param {!proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.ForecastConfigColumn.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.ForecastConfigColumn)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.ForecastConfigColumn>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getForecastConfigColumn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastConfigColumn',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastConfigColumn,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.ForecastConfigColumn>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getForecastConfigColumn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastConfigColumn',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastConfigColumn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest,
 *   !proto.loupe.services.forecast.v3.Empty>}
 */
const methodDescriptor_Api_AssignForecastConfigToPivotGroups = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/AssignForecastConfigToPivotGroups',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.AssignForecastConfigToPivotGroupsRequest,
  src_services_forecast_v3_forecast_pb.Empty,
  /**
   * @param {!proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.assignForecastConfigToPivotGroups =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/AssignForecastConfigToPivotGroups',
      request,
      metadata || {},
      methodDescriptor_Api_AssignForecastConfigToPivotGroups,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.assignForecastConfigToPivotGroups =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/AssignForecastConfigToPivotGroups',
      request,
      metadata || {},
      methodDescriptor_Api_AssignForecastConfigToPivotGroups);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest,
 *   !proto.loupe.services.forecast.v3.Empty>}
 */
const methodDescriptor_Api_RemoveForecastConfigFromPivotGroup = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/RemoveForecastConfigFromPivotGroup',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.RemoveForecastConfigFromPivotGroupsRequest,
  src_services_forecast_v3_forecast_pb.Empty,
  /**
   * @param {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.removeForecastConfigFromPivotGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/RemoveForecastConfigFromPivotGroup',
      request,
      metadata || {},
      methodDescriptor_Api_RemoveForecastConfigFromPivotGroup,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.removeForecastConfigFromPivotGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/RemoveForecastConfigFromPivotGroup',
      request,
      metadata || {},
      methodDescriptor_Api_RemoveForecastConfigFromPivotGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest,
 *   !proto.loupe.services.forecast.v3.ForecastConfig>}
 */
const methodDescriptor_Api_SetForecastConfigStatus = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SetForecastConfigStatus',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.SetForecastConfigStatusRequest,
  src_services_forecast_v3_forecast_pb.ForecastConfig,
  /**
   * @param {!proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.ForecastConfig.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.ForecastConfig)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.ForecastConfig>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.setForecastConfigStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SetForecastConfigStatus',
      request,
      metadata || {},
      methodDescriptor_Api_SetForecastConfigStatus,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.ForecastConfig>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.setForecastConfigStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SetForecastConfigStatus',
      request,
      metadata || {},
      methodDescriptor_Api_SetForecastConfigStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest,
 *   !proto.loupe.services.forecast.v3.ForecastConfigColumn>}
 */
const methodDescriptor_Api_SetForecastConfigColumnStatus = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SetForecastConfigColumnStatus',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.SetForecastConfigColumnStatusRequest,
  src_services_forecast_v3_forecast_pb.ForecastConfigColumn,
  /**
   * @param {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.ForecastConfigColumn.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.ForecastConfigColumn)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.ForecastConfigColumn>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.setForecastConfigColumnStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SetForecastConfigColumnStatus',
      request,
      metadata || {},
      methodDescriptor_Api_SetForecastConfigColumnStatus,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.ForecastConfigColumn>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.setForecastConfigColumnStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SetForecastConfigColumnStatus',
      request,
      metadata || {},
      methodDescriptor_Api_SetForecastConfigColumnStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest,
 *   !proto.loupe.services.forecast.v3.Empty>}
 */
const methodDescriptor_Api_ReorderForecastConfigs = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ReorderForecastConfigs',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.ReorderForecastConfigsRequest,
  src_services_forecast_v3_forecast_pb.Empty,
  /**
   * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.reorderForecastConfigs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ReorderForecastConfigs',
      request,
      metadata || {},
      methodDescriptor_Api_ReorderForecastConfigs,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.reorderForecastConfigs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ReorderForecastConfigs',
      request,
      metadata || {},
      methodDescriptor_Api_ReorderForecastConfigs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest,
 *   !proto.loupe.services.forecast.v3.Empty>}
 */
const methodDescriptor_Api_ReorderForecastConfigColumns = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ReorderForecastConfigColumns',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.ReorderForecastConfigColumnsRequest,
  src_services_forecast_v3_forecast_pb.Empty,
  /**
   * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.reorderForecastConfigColumns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ReorderForecastConfigColumns',
      request,
      metadata || {},
      methodDescriptor_Api_ReorderForecastConfigColumns,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.reorderForecastConfigColumns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ReorderForecastConfigColumns',
      request,
      metadata || {},
      methodDescriptor_Api_ReorderForecastConfigColumns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.GetForecastRequest,
 *   !proto.loupe.services.forecast.v3.GetForecastResponse>}
 */
const methodDescriptor_Api_GetManagerForecastV3 = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetManagerForecastV3',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.GetForecastRequest,
  src_services_forecast_v3_forecast_pb.GetForecastResponse,
  /**
   * @param {!proto.loupe.services.forecast.v3.GetForecastRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.GetForecastResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.GetForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.GetForecastResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.GetForecastResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getManagerForecastV3 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetManagerForecastV3',
      request,
      metadata || {},
      methodDescriptor_Api_GetManagerForecastV3,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.GetForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.GetForecastResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getManagerForecastV3 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetManagerForecastV3',
      request,
      metadata || {},
      methodDescriptor_Api_GetManagerForecastV3);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.GetForecastRequest,
 *   !proto.loupe.services.forecast.v3.GetForecastResponse>}
 */
const methodDescriptor_Api_GetRepForecastV3 = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetRepForecastV3',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.GetForecastRequest,
  src_services_forecast_v3_forecast_pb.GetForecastResponse,
  /**
   * @param {!proto.loupe.services.forecast.v3.GetForecastRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.GetForecastResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.GetForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.GetForecastResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.GetForecastResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getRepForecastV3 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetRepForecastV3',
      request,
      metadata || {},
      methodDescriptor_Api_GetRepForecastV3,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.GetForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.GetForecastResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getRepForecastV3 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetRepForecastV3',
      request,
      metadata || {},
      methodDescriptor_Api_GetRepForecastV3);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.SaveForecastRequest,
 *   !proto.loupe.common.forecast.v3.Forecast>}
 */
const methodDescriptor_Api_SubmitForecastV3 = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SubmitForecastV3',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.SaveForecastRequest,
  src_common_forecast_v3_forecast_pb.Forecast,
  /**
   * @param {!proto.loupe.services.forecast.v3.SaveForecastRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_forecast_v3_forecast_pb.Forecast.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.SaveForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.forecast.v3.Forecast)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.forecast.v3.Forecast>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.submitForecastV3 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SubmitForecastV3',
      request,
      metadata || {},
      methodDescriptor_Api_SubmitForecastV3,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.SaveForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.forecast.v3.Forecast>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.submitForecastV3 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SubmitForecastV3',
      request,
      metadata || {},
      methodDescriptor_Api_SubmitForecastV3);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.SaveForecastRequest,
 *   !proto.loupe.common.forecast.v3.Forecast>}
 */
const methodDescriptor_Api_SaveForecastV3 = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SaveForecastV3',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.SaveForecastRequest,
  src_common_forecast_v3_forecast_pb.Forecast,
  /**
   * @param {!proto.loupe.services.forecast.v3.SaveForecastRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_forecast_v3_forecast_pb.Forecast.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.SaveForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.forecast.v3.Forecast)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.forecast.v3.Forecast>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.saveForecastV3 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SaveForecastV3',
      request,
      metadata || {},
      methodDescriptor_Api_SaveForecastV3,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.SaveForecastRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.forecast.v3.Forecast>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.saveForecastV3 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SaveForecastV3',
      request,
      metadata || {},
      methodDescriptor_Api_SaveForecastV3);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.SearchRequest,
 *   !proto.loupe.services.forecast.v3.SearchResponse>}
 */
const methodDescriptor_Api_SearchForecastsV3 = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SearchForecastsV3',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.SearchRequest,
  src_services_forecast_v3_forecast_pb.SearchResponse,
  /**
   * @param {!proto.loupe.services.forecast.v3.SearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.SearchResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.SearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.SearchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.SearchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.searchForecastsV3 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SearchForecastsV3',
      request,
      metadata || {},
      methodDescriptor_Api_SearchForecastsV3,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.SearchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.SearchResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.searchForecastsV3 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SearchForecastsV3',
      request,
      metadata || {},
      methodDescriptor_Api_SearchForecastsV3);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest,
 *   !proto.loupe.services.forecast.v3.Empty>}
 */
const methodDescriptor_Api_PublishForecastConfigDraft = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/PublishForecastConfigDraft',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.PublishForecastConfigDraftRequest,
  src_services_forecast_v3_forecast_pb.Empty,
  /**
   * @param {!proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.publishForecastConfigDraft =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/PublishForecastConfigDraft',
      request,
      metadata || {},
      methodDescriptor_Api_PublishForecastConfigDraft,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.publishForecastConfigDraft =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/PublishForecastConfigDraft',
      request,
      metadata || {},
      methodDescriptor_Api_PublishForecastConfigDraft);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest,
 *   !proto.loupe.services.forecast.v3.ForecastConfig>}
 */
const methodDescriptor_Api_DuplicateForecastConfig = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DuplicateForecastConfig',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.DuplicateForecastConfigRequest,
  src_services_forecast_v3_forecast_pb.ForecastConfig,
  /**
   * @param {!proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.ForecastConfig.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.ForecastConfig)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.ForecastConfig>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.duplicateForecastConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DuplicateForecastConfig',
      request,
      metadata || {},
      methodDescriptor_Api_DuplicateForecastConfig,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.ForecastConfig>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.duplicateForecastConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DuplicateForecastConfig',
      request,
      metadata || {},
      methodDescriptor_Api_DuplicateForecastConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest,
 *   !proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse>}
 */
const methodDescriptor_Api_ExportSubmissionHistory = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ExportSubmissionHistory',
  grpc.web.MethodType.UNARY,
  src_services_forecast_v3_forecast_pb.ExportSubmissionHistoryRequest,
  src_services_forecast_v3_forecast_pb.ExportSubmissionHistoryResponse,
  /**
   * @param {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_forecast_v3_forecast_pb.ExportSubmissionHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.exportSubmissionHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ExportSubmissionHistory',
      request,
      metadata || {},
      methodDescriptor_Api_ExportSubmissionHistory,
      callback);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.exportSubmissionHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ExportSubmissionHistory',
      request,
      metadata || {},
      methodDescriptor_Api_ExportSubmissionHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.reporting.PipelineReport,
 *   !proto.loupe.common.reporting.PipelineReport>}
 */
const methodDescriptor_Api_GetPipelineReport = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetPipelineReport',
  grpc.web.MethodType.UNARY,
  src_common_reporting_pipeline_report_pb.PipelineReport,
  src_common_reporting_pipeline_report_pb.PipelineReport,
  /**
   * @param {!proto.loupe.common.reporting.PipelineReport} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_reporting_pipeline_report_pb.PipelineReport.deserializeBinary
);


/**
 * @param {!proto.loupe.common.reporting.PipelineReport} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.reporting.PipelineReport)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.reporting.PipelineReport>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getPipelineReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetPipelineReport',
      request,
      metadata || {},
      methodDescriptor_Api_GetPipelineReport,
      callback);
};


/**
 * @param {!proto.loupe.common.reporting.PipelineReport} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.reporting.PipelineReport>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getPipelineReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetPipelineReport',
      request,
      metadata || {},
      methodDescriptor_Api_GetPipelineReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetPipelineReportOptionsRequest,
 *   !proto.loupe.common.reporting.ReportOptions>}
 */
const methodDescriptor_Api_GetPipelineReportOptions = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetPipelineReportOptions',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetPipelineReportOptionsRequest,
  src_common_reporting_pipeline_report_pb.ReportOptions,
  /**
   * @param {!proto.loupe.services.api.GetPipelineReportOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_reporting_pipeline_report_pb.ReportOptions.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetPipelineReportOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.reporting.ReportOptions)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.reporting.ReportOptions>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getPipelineReportOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetPipelineReportOptions',
      request,
      metadata || {},
      methodDescriptor_Api_GetPipelineReportOptions,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetPipelineReportOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.reporting.ReportOptions>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getPipelineReportOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetPipelineReportOptions',
      request,
      metadata || {},
      methodDescriptor_Api_GetPipelineReportOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest,
 *   !proto.loupe.common.opportunity.OpportunityList>}
 */
const methodDescriptor_Api_GetOpportunitiesSnapshot = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetOpportunitiesSnapshot',
  grpc.web.MethodType.UNARY,
  src_services_reporting_reporting_pb.GetOpportunitiesSnapshotRequest,
  src_common_opportunity_opportunity_list_pb.OpportunityList,
  /**
   * @param {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_opportunity_opportunity_list_pb.OpportunityList.deserializeBinary
);


/**
 * @param {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.opportunity.OpportunityList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.opportunity.OpportunityList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getOpportunitiesSnapshot =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetOpportunitiesSnapshot',
      request,
      metadata || {},
      methodDescriptor_Api_GetOpportunitiesSnapshot,
      callback);
};


/**
 * @param {!proto.loupe.services.reporting.GetOpportunitiesSnapshotRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.opportunity.OpportunityList>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getOpportunitiesSnapshot =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetOpportunitiesSnapshot',
      request,
      metadata || {},
      methodDescriptor_Api_GetOpportunitiesSnapshot);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetUserLastReportInputRequest,
 *   !proto.loupe.common.reporting.TimeWindowedReportInput>}
 */
const methodDescriptor_Api_GetUserLastReportInput = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetUserLastReportInput',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetUserLastReportInputRequest,
  src_common_reporting_pipeline_report_pb.TimeWindowedReportInput,
  /**
   * @param {!proto.loupe.services.api.GetUserLastReportInputRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_reporting_pipeline_report_pb.TimeWindowedReportInput.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetUserLastReportInputRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.reporting.TimeWindowedReportInput)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.reporting.TimeWindowedReportInput>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getUserLastReportInput =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetUserLastReportInput',
      request,
      metadata || {},
      methodDescriptor_Api_GetUserLastReportInput,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetUserLastReportInputRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.reporting.TimeWindowedReportInput>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getUserLastReportInput =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetUserLastReportInput',
      request,
      metadata || {},
      methodDescriptor_Api_GetUserLastReportInput);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage,
 *   !proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage>}
 */
const methodDescriptor_Api_GetForecastSimulationReportOptions = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetForecastSimulationReportOptions',
  grpc.web.MethodType.UNARY,
  src_services_reporting_reporting_pb.GetForecastSimulationReportOptionsMessage,
  src_services_reporting_reporting_pb.GetForecastSimulationReportOptionsMessage,
  /**
   * @param {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_reporting_reporting_pb.GetForecastSimulationReportOptionsMessage.deserializeBinary
);


/**
 * @param {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getForecastSimulationReportOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastSimulationReportOptions',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastSimulationReportOptions,
      callback);
};


/**
 * @param {!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.reporting.GetForecastSimulationReportOptionsMessage>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getForecastSimulationReportOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastSimulationReportOptions',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastSimulationReportOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.reporting.RunForecastSimulationMessage,
 *   !proto.loupe.services.reporting.RunForecastSimulationMessage>}
 */
const methodDescriptor_Api_RunForecastSimulation = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/RunForecastSimulation',
  grpc.web.MethodType.UNARY,
  src_services_reporting_reporting_pb.RunForecastSimulationMessage,
  src_services_reporting_reporting_pb.RunForecastSimulationMessage,
  /**
   * @param {!proto.loupe.services.reporting.RunForecastSimulationMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_reporting_reporting_pb.RunForecastSimulationMessage.deserializeBinary
);


/**
 * @param {!proto.loupe.services.reporting.RunForecastSimulationMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.reporting.RunForecastSimulationMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.reporting.RunForecastSimulationMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.runForecastSimulation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/RunForecastSimulation',
      request,
      metadata || {},
      methodDescriptor_Api_RunForecastSimulation,
      callback);
};


/**
 * @param {!proto.loupe.services.reporting.RunForecastSimulationMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.reporting.RunForecastSimulationMessage>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.runForecastSimulation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/RunForecastSimulation',
      request,
      metadata || {},
      methodDescriptor_Api_RunForecastSimulation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest,
 *   !proto.loupe.services.reporting.GetForecastSimulationsResponse>}
 */
const methodDescriptor_Api_GetForecastSimulationsAvailable = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetForecastSimulationsAvailable',
  grpc.web.MethodType.UNARY,
  src_services_reporting_reporting_pb.GetForecastSimulationsAvailableRequest,
  src_services_reporting_reporting_pb.GetForecastSimulationsResponse,
  /**
   * @param {!proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_reporting_reporting_pb.GetForecastSimulationsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.reporting.GetForecastSimulationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.reporting.GetForecastSimulationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getForecastSimulationsAvailable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastSimulationsAvailable',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastSimulationsAvailable,
      callback);
};


/**
 * @param {!proto.loupe.services.reporting.GetForecastSimulationsAvailableRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.reporting.GetForecastSimulationsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getForecastSimulationsAvailable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastSimulationsAvailable',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastSimulationsAvailable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.reporting.GetSavedForecastSimulationsRequest,
 *   !proto.loupe.services.reporting.GetForecastSimulationsResponse>}
 */
const methodDescriptor_Api_GetSavedForecastSimulations = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSavedForecastSimulations',
  grpc.web.MethodType.UNARY,
  src_services_reporting_reporting_pb.GetSavedForecastSimulationsRequest,
  src_services_reporting_reporting_pb.GetForecastSimulationsResponse,
  /**
   * @param {!proto.loupe.services.reporting.GetSavedForecastSimulationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_reporting_reporting_pb.GetForecastSimulationsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.reporting.GetSavedForecastSimulationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.reporting.GetForecastSimulationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.reporting.GetForecastSimulationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSavedForecastSimulations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSavedForecastSimulations',
      request,
      metadata || {},
      methodDescriptor_Api_GetSavedForecastSimulations,
      callback);
};


/**
 * @param {!proto.loupe.services.reporting.GetSavedForecastSimulationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.reporting.GetForecastSimulationsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSavedForecastSimulations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSavedForecastSimulations',
      request,
      metadata || {},
      methodDescriptor_Api_GetSavedForecastSimulations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.EmptyMessage,
 *   !proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse>}
 */
const methodDescriptor_Api_GetForecastSimulationLevelOptions = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetForecastSimulationLevelOptions',
  grpc.web.MethodType.UNARY,
  src_common_empty_message_pb.EmptyMessage,
  src_services_reporting_reporting_pb.GetForecastSimulationLevelOptionsResponse,
  /**
   * @param {!proto.loupe.common.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_reporting_reporting_pb.GetForecastSimulationLevelOptionsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.common.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getForecastSimulationLevelOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastSimulationLevelOptions',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastSimulationLevelOptions,
      callback);
};


/**
 * @param {!proto.loupe.common.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.reporting.GetForecastSimulationLevelOptionsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getForecastSimulationLevelOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetForecastSimulationLevelOptions',
      request,
      metadata || {},
      methodDescriptor_Api_GetForecastSimulationLevelOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.NlgTestRequest,
 *   !proto.loupe.services.api.NlgTestResponse>}
 */
const methodDescriptor_Api_TestNlgService = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/TestNlgService',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.NlgTestRequest,
  proto.loupe.services.api.NlgTestResponse,
  /**
   * @param {!proto.loupe.services.api.NlgTestRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.NlgTestResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.NlgTestRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.NlgTestResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.NlgTestResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.testNlgService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/TestNlgService',
      request,
      metadata || {},
      methodDescriptor_Api_TestNlgService,
      callback);
};


/**
 * @param {!proto.loupe.services.api.NlgTestRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.NlgTestResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.testNlgService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/TestNlgService',
      request,
      metadata || {},
      methodDescriptor_Api_TestNlgService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.notes.GetByAttachmentRequest,
 *   !proto.loupe.services.notes.NoteListResponse>}
 */
const methodDescriptor_Api_GetNotesByAttachment = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetNotesByAttachment',
  grpc.web.MethodType.UNARY,
  src_services_notes_notes_pb.GetByAttachmentRequest,
  src_services_notes_notes_pb.NoteListResponse,
  /**
   * @param {!proto.loupe.services.notes.GetByAttachmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_notes_notes_pb.NoteListResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.notes.GetByAttachmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.notes.NoteListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.notes.NoteListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getNotesByAttachment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetNotesByAttachment',
      request,
      metadata || {},
      methodDescriptor_Api_GetNotesByAttachment,
      callback);
};


/**
 * @param {!proto.loupe.services.notes.GetByAttachmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.notes.NoteListResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getNotesByAttachment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetNotesByAttachment',
      request,
      metadata || {},
      methodDescriptor_Api_GetNotesByAttachment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.notes.SaveNoteRequest,
 *   !proto.loupe.common.notes.Note>}
 */
const methodDescriptor_Api_SaveNote = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SaveNote',
  grpc.web.MethodType.UNARY,
  src_services_notes_notes_pb.SaveNoteRequest,
  src_common_notes_notes_pb.Note,
  /**
   * @param {!proto.loupe.services.notes.SaveNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_notes_notes_pb.Note.deserializeBinary
);


/**
 * @param {!proto.loupe.services.notes.SaveNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.notes.Note)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.notes.Note>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.saveNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SaveNote',
      request,
      metadata || {},
      methodDescriptor_Api_SaveNote,
      callback);
};


/**
 * @param {!proto.loupe.services.notes.SaveNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.notes.Note>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.saveNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SaveNote',
      request,
      metadata || {},
      methodDescriptor_Api_SaveNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.notes.DeleteNoteRequest,
 *   !proto.loupe.services.notes.DeleteResponse>}
 */
const methodDescriptor_Api_DeleteNote = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DeleteNote',
  grpc.web.MethodType.UNARY,
  src_services_notes_notes_pb.DeleteNoteRequest,
  src_services_notes_notes_pb.DeleteResponse,
  /**
   * @param {!proto.loupe.services.notes.DeleteNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_notes_notes_pb.DeleteResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.notes.DeleteNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.notes.DeleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.notes.DeleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.deleteNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteNote',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteNote,
      callback);
};


/**
 * @param {!proto.loupe.services.notes.DeleteNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.notes.DeleteResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.deleteNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteNote',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.DeliverNoteRequest,
 *   !proto.loupe.common.EmptyMessage>}
 */
const methodDescriptor_Api_DeliverNote = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DeliverNote',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.DeliverNoteRequest,
  src_common_empty_message_pb.EmptyMessage,
  /**
   * @param {!proto.loupe.services.api.DeliverNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_empty_message_pb.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.DeliverNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.deliverNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DeliverNote',
      request,
      metadata || {},
      methodDescriptor_Api_DeliverNote,
      callback);
};


/**
 * @param {!proto.loupe.services.api.DeliverNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.deliverNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DeliverNote',
      request,
      metadata || {},
      methodDescriptor_Api_DeliverNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.EmptyMessage,
 *   !proto.loupe.services.api.PassportFeatureResponse>}
 */
const methodDescriptor_Api_GetRequestedPassportFeature = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetRequestedPassportFeature',
  grpc.web.MethodType.UNARY,
  src_common_empty_message_pb.EmptyMessage,
  proto.loupe.services.api.PassportFeatureResponse,
  /**
   * @param {!proto.loupe.common.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.PassportFeatureResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.common.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.PassportFeatureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.PassportFeatureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getRequestedPassportFeature =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetRequestedPassportFeature',
      request,
      metadata || {},
      methodDescriptor_Api_GetRequestedPassportFeature,
      callback);
};


/**
 * @param {!proto.loupe.common.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.PassportFeatureResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getRequestedPassportFeature =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetRequestedPassportFeature',
      request,
      metadata || {},
      methodDescriptor_Api_GetRequestedPassportFeature);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.integration_invoker.IntegrationIdentity,
 *   !proto.loupe.services.integration_invoker.IntegrationIdentity>}
 */
const methodDescriptor_Api_AuthIntegration = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/AuthIntegration',
  grpc.web.MethodType.UNARY,
  src_services_integration$invoker_integration$invoker_pb.IntegrationIdentity,
  src_services_integration$invoker_integration$invoker_pb.IntegrationIdentity,
  /**
   * @param {!proto.loupe.services.integration_invoker.IntegrationIdentity} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_integration$invoker_integration$invoker_pb.IntegrationIdentity.deserializeBinary
);


/**
 * @param {!proto.loupe.services.integration_invoker.IntegrationIdentity} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.integration_invoker.IntegrationIdentity)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.integration_invoker.IntegrationIdentity>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.authIntegration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/AuthIntegration',
      request,
      metadata || {},
      methodDescriptor_Api_AuthIntegration,
      callback);
};


/**
 * @param {!proto.loupe.services.integration_invoker.IntegrationIdentity} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.integration_invoker.IntegrationIdentity>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.authIntegration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/AuthIntegration',
      request,
      metadata || {},
      methodDescriptor_Api_AuthIntegration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.integration_invoker.GetRequest,
 *   !proto.loupe.services.integration_invoker.GetResponse>}
 */
const methodDescriptor_Api_GetIntegrationObject = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetIntegrationObject',
  grpc.web.MethodType.UNARY,
  src_services_integration$invoker_integration$invoker_pb.GetRequest,
  src_services_integration$invoker_integration$invoker_pb.GetResponse,
  /**
   * @param {!proto.loupe.services.integration_invoker.GetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_integration$invoker_integration$invoker_pb.GetResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.integration_invoker.GetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.integration_invoker.GetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.integration_invoker.GetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getIntegrationObject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetIntegrationObject',
      request,
      metadata || {},
      methodDescriptor_Api_GetIntegrationObject,
      callback);
};


/**
 * @param {!proto.loupe.services.integration_invoker.GetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.integration_invoker.GetResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getIntegrationObject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetIntegrationObject',
      request,
      metadata || {},
      methodDescriptor_Api_GetIntegrationObject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.integration_invoker.ListRequest,
 *   !proto.loupe.services.integration_invoker.ListResponse>}
 */
const methodDescriptor_Api_ListIntegrationObjects = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListIntegrationObjects',
  grpc.web.MethodType.UNARY,
  src_services_integration$invoker_integration$invoker_pb.ListRequest,
  src_services_integration$invoker_integration$invoker_pb.ListResponse,
  /**
   * @param {!proto.loupe.services.integration_invoker.ListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_integration$invoker_integration$invoker_pb.ListResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.integration_invoker.ListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.integration_invoker.ListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.integration_invoker.ListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listIntegrationObjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_ListIntegrationObjects,
      callback);
};


/**
 * @param {!proto.loupe.services.integration_invoker.ListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.integration_invoker.ListResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listIntegrationObjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_ListIntegrationObjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.integration_invoker.SearchRequest,
 *   !proto.loupe.services.integration_invoker.ListResponse>}
 */
const methodDescriptor_Api_SearchIntegrationObjects = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SearchIntegrationObjects',
  grpc.web.MethodType.UNARY,
  src_services_integration$invoker_integration$invoker_pb.SearchRequest,
  src_services_integration$invoker_integration$invoker_pb.ListResponse,
  /**
   * @param {!proto.loupe.services.integration_invoker.SearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_integration$invoker_integration$invoker_pb.ListResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.integration_invoker.SearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.integration_invoker.ListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.integration_invoker.ListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.searchIntegrationObjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SearchIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_SearchIntegrationObjects,
      callback);
};


/**
 * @param {!proto.loupe.services.integration_invoker.SearchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.integration_invoker.ListResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.searchIntegrationObjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SearchIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_SearchIntegrationObjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.integration_invoker.NextRequest,
 *   !proto.loupe.services.integration_invoker.ListResponse>}
 */
const methodDescriptor_Api_NextIntegrationObjects = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/NextIntegrationObjects',
  grpc.web.MethodType.UNARY,
  src_services_integration$invoker_integration$invoker_pb.NextRequest,
  src_services_integration$invoker_integration$invoker_pb.ListResponse,
  /**
   * @param {!proto.loupe.services.integration_invoker.NextRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_integration$invoker_integration$invoker_pb.ListResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.integration_invoker.NextRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.integration_invoker.ListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.integration_invoker.ListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.nextIntegrationObjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/NextIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_NextIntegrationObjects,
      callback);
};


/**
 * @param {!proto.loupe.services.integration_invoker.NextRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.integration_invoker.ListResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.nextIntegrationObjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/NextIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_NextIntegrationObjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.integration_invoker.DescribeRequest,
 *   !proto.loupe.services.integration_invoker.DescribeResponse>}
 */
const methodDescriptor_Api_DescribeIntegrationObject = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DescribeIntegrationObject',
  grpc.web.MethodType.UNARY,
  src_services_integration$invoker_integration$invoker_pb.DescribeRequest,
  src_services_integration$invoker_integration$invoker_pb.DescribeResponse,
  /**
   * @param {!proto.loupe.services.integration_invoker.DescribeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_integration$invoker_integration$invoker_pb.DescribeResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.integration_invoker.DescribeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.integration_invoker.DescribeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.integration_invoker.DescribeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.describeIntegrationObject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DescribeIntegrationObject',
      request,
      metadata || {},
      methodDescriptor_Api_DescribeIntegrationObject,
      callback);
};


/**
 * @param {!proto.loupe.services.integration_invoker.DescribeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.integration_invoker.DescribeResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.describeIntegrationObject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DescribeIntegrationObject',
      request,
      metadata || {},
      methodDescriptor_Api_DescribeIntegrationObject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.integration_invoker.DescribeManyRequest,
 *   !proto.loupe.services.integration_invoker.DescribeManyResponse>}
 */
const methodDescriptor_Api_DescribeManyIntegrationObjects = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DescribeManyIntegrationObjects',
  grpc.web.MethodType.UNARY,
  src_services_integration$invoker_integration$invoker_pb.DescribeManyRequest,
  src_services_integration$invoker_integration$invoker_pb.DescribeManyResponse,
  /**
   * @param {!proto.loupe.services.integration_invoker.DescribeManyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_integration$invoker_integration$invoker_pb.DescribeManyResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.integration_invoker.DescribeManyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.integration_invoker.DescribeManyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.integration_invoker.DescribeManyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.describeManyIntegrationObjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DescribeManyIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_DescribeManyIntegrationObjects,
      callback);
};


/**
 * @param {!proto.loupe.services.integration_invoker.DescribeManyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.integration_invoker.DescribeManyResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.describeManyIntegrationObjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DescribeManyIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_DescribeManyIntegrationObjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.integration_invoker.InsertRequest,
 *   !proto.loupe.services.integration_invoker.InsertResponse>}
 */
const methodDescriptor_Api_InsertIntegrationObjects = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/InsertIntegrationObjects',
  grpc.web.MethodType.UNARY,
  src_services_integration$invoker_integration$invoker_pb.InsertRequest,
  src_services_integration$invoker_integration$invoker_pb.InsertResponse,
  /**
   * @param {!proto.loupe.services.integration_invoker.InsertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_integration$invoker_integration$invoker_pb.InsertResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.integration_invoker.InsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.integration_invoker.InsertResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.integration_invoker.InsertResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.insertIntegrationObjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/InsertIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_InsertIntegrationObjects,
      callback);
};


/**
 * @param {!proto.loupe.services.integration_invoker.InsertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.integration_invoker.InsertResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.insertIntegrationObjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/InsertIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_InsertIntegrationObjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.integration_invoker.PatchRequest,
 *   !proto.loupe.services.integration_invoker.PatchResponse>}
 */
const methodDescriptor_Api_PatchIntegrationObjects = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/PatchIntegrationObjects',
  grpc.web.MethodType.UNARY,
  src_services_integration$invoker_integration$invoker_pb.PatchRequest,
  src_services_integration$invoker_integration$invoker_pb.PatchResponse,
  /**
   * @param {!proto.loupe.services.integration_invoker.PatchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_integration$invoker_integration$invoker_pb.PatchResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.integration_invoker.PatchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.integration_invoker.PatchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.integration_invoker.PatchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.patchIntegrationObjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/PatchIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_PatchIntegrationObjects,
      callback);
};


/**
 * @param {!proto.loupe.services.integration_invoker.PatchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.integration_invoker.PatchResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.patchIntegrationObjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/PatchIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_PatchIntegrationObjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.integration_invoker.DeleteRequest,
 *   !proto.loupe.services.integration_invoker.DeleteResponse>}
 */
const methodDescriptor_Api_DeleteIntegrationObjects = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DeleteIntegrationObjects',
  grpc.web.MethodType.UNARY,
  src_services_integration$invoker_integration$invoker_pb.DeleteRequest,
  src_services_integration$invoker_integration$invoker_pb.DeleteResponse,
  /**
   * @param {!proto.loupe.services.integration_invoker.DeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_integration$invoker_integration$invoker_pb.DeleteResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.integration_invoker.DeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.integration_invoker.DeleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.integration_invoker.DeleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.deleteIntegrationObjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteIntegrationObjects,
      callback);
};


/**
 * @param {!proto.loupe.services.integration_invoker.DeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.integration_invoker.DeleteResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.deleteIntegrationObjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteIntegrationObjects',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteIntegrationObjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.integration_invoker.GetRequest,
 *   !proto.loupe.services.integration_invoker.GetObjectDescriptionResponse>}
 */
const methodDescriptor_Api_GetIntegrationObjectDescription = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetIntegrationObjectDescription',
  grpc.web.MethodType.UNARY,
  src_services_integration$invoker_integration$invoker_pb.GetRequest,
  src_services_integration$invoker_integration$invoker_pb.GetObjectDescriptionResponse,
  /**
   * @param {!proto.loupe.services.integration_invoker.GetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_integration$invoker_integration$invoker_pb.GetObjectDescriptionResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.integration_invoker.GetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.integration_invoker.GetObjectDescriptionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getIntegrationObjectDescription =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetIntegrationObjectDescription',
      request,
      metadata || {},
      methodDescriptor_Api_GetIntegrationObjectDescription,
      callback);
};


/**
 * @param {!proto.loupe.services.integration_invoker.GetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getIntegrationObjectDescription =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetIntegrationObjectDescription',
      request,
      metadata || {},
      methodDescriptor_Api_GetIntegrationObjectDescription);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest,
 *   !proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse>}
 */
const methodDescriptor_Api_ParseIntegrationObjectRawSchema = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ParseIntegrationObjectRawSchema',
  grpc.web.MethodType.UNARY,
  src_services_integration$invoker_integration$invoker_pb.ParseObjectRawSchemaRequest,
  src_services_integration$invoker_integration$invoker_pb.ParseObjectRawSchemaResponse,
  /**
   * @param {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_integration$invoker_integration$invoker_pb.ParseObjectRawSchemaResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.parseIntegrationObjectRawSchema =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ParseIntegrationObjectRawSchema',
      request,
      metadata || {},
      methodDescriptor_Api_ParseIntegrationObjectRawSchema,
      callback);
};


/**
 * @param {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.parseIntegrationObjectRawSchema =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ParseIntegrationObjectRawSchema',
      request,
      metadata || {},
      methodDescriptor_Api_ParseIntegrationObjectRawSchema);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.GetTenantObjectRequest,
 *   !proto.loupe.services.tenant.GetTenantObjectDefinitionResponse>}
 */
const methodDescriptor_Api_GetIntegrationObjectDefinition = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetIntegrationObjectDefinition',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.GetTenantObjectRequest,
  src_services_tenant_tenant_pb.GetTenantObjectDefinitionResponse,
  /**
   * @param {!proto.loupe.services.tenant.GetTenantObjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.GetTenantObjectDefinitionResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.GetTenantObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.GetTenantObjectDefinitionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getIntegrationObjectDefinition =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetIntegrationObjectDefinition',
      request,
      metadata || {},
      methodDescriptor_Api_GetIntegrationObjectDefinition,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.GetTenantObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getIntegrationObjectDefinition =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetIntegrationObjectDefinition',
      request,
      metadata || {},
      methodDescriptor_Api_GetIntegrationObjectDefinition);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.GetTenantObjectRequest,
 *   !proto.loupe.services.tenant.GetTenantObjectDefinitionResponse>}
 */
const methodDescriptor_Api_GetIntegrationObjectDefinitionExtended = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetIntegrationObjectDefinitionExtended',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.GetTenantObjectRequest,
  src_services_tenant_tenant_pb.GetTenantObjectDefinitionResponse,
  /**
   * @param {!proto.loupe.services.tenant.GetTenantObjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.GetTenantObjectDefinitionResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.GetTenantObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.GetTenantObjectDefinitionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getIntegrationObjectDefinitionExtended =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetIntegrationObjectDefinitionExtended',
      request,
      metadata || {},
      methodDescriptor_Api_GetIntegrationObjectDefinitionExtended,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.GetTenantObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getIntegrationObjectDefinitionExtended =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetIntegrationObjectDefinitionExtended',
      request,
      metadata || {},
      methodDescriptor_Api_GetIntegrationObjectDefinitionExtended);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.GetTenantObjectRequest,
 *   !proto.loupe.services.tenant.GetTenantObjectDefinitionResponse>}
 */
const methodDescriptor_Api_GetIntegrationObjectRawSchema = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetIntegrationObjectRawSchema',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.GetTenantObjectRequest,
  src_services_tenant_tenant_pb.GetTenantObjectDefinitionResponse,
  /**
   * @param {!proto.loupe.services.tenant.GetTenantObjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.GetTenantObjectDefinitionResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.GetTenantObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.GetTenantObjectDefinitionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getIntegrationObjectRawSchema =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetIntegrationObjectRawSchema',
      request,
      metadata || {},
      methodDescriptor_Api_GetIntegrationObjectRawSchema,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.GetTenantObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.GetTenantObjectDefinitionResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getIntegrationObjectRawSchema =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetIntegrationObjectRawSchema',
      request,
      metadata || {},
      methodDescriptor_Api_GetIntegrationObjectRawSchema);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.ListIntegrationObjectTypesRequest,
 *   !proto.loupe.services.api.ListIntegrationObjectTypesResponse>}
 */
const methodDescriptor_Api_ListIntegrationObjectTypes = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListIntegrationObjectTypes',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.ListIntegrationObjectTypesRequest,
  proto.loupe.services.api.ListIntegrationObjectTypesResponse,
  /**
   * @param {!proto.loupe.services.api.ListIntegrationObjectTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.ListIntegrationObjectTypesResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.ListIntegrationObjectTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.ListIntegrationObjectTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.ListIntegrationObjectTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listIntegrationObjectTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListIntegrationObjectTypes',
      request,
      metadata || {},
      methodDescriptor_Api_ListIntegrationObjectTypes,
      callback);
};


/**
 * @param {!proto.loupe.services.api.ListIntegrationObjectTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.ListIntegrationObjectTypesResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listIntegrationObjectTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListIntegrationObjectTypes',
      request,
      metadata || {},
      methodDescriptor_Api_ListIntegrationObjectTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.ListIntegrationObjectPropertiesRequest,
 *   !proto.loupe.services.api.ListIntegrationObjectPropertiesResponse>}
 */
const methodDescriptor_Api_ListIntegrationObjectProperties = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListIntegrationObjectProperties',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.ListIntegrationObjectPropertiesRequest,
  proto.loupe.services.api.ListIntegrationObjectPropertiesResponse,
  /**
   * @param {!proto.loupe.services.api.ListIntegrationObjectPropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.ListIntegrationObjectPropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.ListIntegrationObjectPropertiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.ListIntegrationObjectPropertiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listIntegrationObjectProperties =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListIntegrationObjectProperties',
      request,
      metadata || {},
      methodDescriptor_Api_ListIntegrationObjectProperties,
      callback);
};


/**
 * @param {!proto.loupe.services.api.ListIntegrationObjectPropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.ListIntegrationObjectPropertiesResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listIntegrationObjectProperties =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListIntegrationObjectProperties',
      request,
      metadata || {},
      methodDescriptor_Api_ListIntegrationObjectProperties);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.SearchRequest,
 *   !proto.loupe.common.search.item.SearchItems>}
 */
const methodDescriptor_Api_Search = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/Search',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.SearchRequest,
  src_common_search_item_item_pb.SearchItems,
  /**
   * @param {!proto.loupe.services.api.SearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_search_item_item_pb.SearchItems.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.SearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.search.item.SearchItems)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.search.item.SearchItems>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.search =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/Search',
      request,
      metadata || {},
      methodDescriptor_Api_Search,
      callback);
};


/**
 * @param {!proto.loupe.services.api.SearchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.search.item.SearchItems>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.search =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/Search',
      request,
      metadata || {},
      methodDescriptor_Api_Search);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetSearchItemByIDRequest,
 *   !proto.loupe.common.search.item.SearchItem>}
 */
const methodDescriptor_Api_GetSearchItemById = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSearchItemById',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetSearchItemByIDRequest,
  src_common_search_item_item_pb.SearchItem,
  /**
   * @param {!proto.loupe.services.api.GetSearchItemByIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_search_item_item_pb.SearchItem.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetSearchItemByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.search.item.SearchItem)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.search.item.SearchItem>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSearchItemById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSearchItemById',
      request,
      metadata || {},
      methodDescriptor_Api_GetSearchItemById,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetSearchItemByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.search.item.SearchItem>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSearchItemById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSearchItemById',
      request,
      metadata || {},
      methodDescriptor_Api_GetSearchItemById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.PatchSearchItemRequest,
 *   !proto.loupe.services.api.PatchSearchItemResponse>}
 */
const methodDescriptor_Api_PatchSearchItem = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/PatchSearchItem',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.PatchSearchItemRequest,
  proto.loupe.services.api.PatchSearchItemResponse,
  /**
   * @param {!proto.loupe.services.api.PatchSearchItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.PatchSearchItemResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.PatchSearchItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.PatchSearchItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.PatchSearchItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.patchSearchItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/PatchSearchItem',
      request,
      metadata || {},
      methodDescriptor_Api_PatchSearchItem,
      callback);
};


/**
 * @param {!proto.loupe.services.api.PatchSearchItemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.PatchSearchItemResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.patchSearchItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/PatchSearchItem',
      request,
      metadata || {},
      methodDescriptor_Api_PatchSearchItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.ExportSearchResultsRequest,
 *   !proto.loupe.services.api.ExportSearchResultsResult>}
 */
const methodDescriptor_Api_ExportSearchResults = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ExportSearchResults',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.ExportSearchResultsRequest,
  proto.loupe.services.api.ExportSearchResultsResult,
  /**
   * @param {!proto.loupe.services.api.ExportSearchResultsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.ExportSearchResultsResult.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.ExportSearchResultsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.ExportSearchResultsResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.ExportSearchResultsResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.exportSearchResults =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ExportSearchResults',
      request,
      metadata || {},
      methodDescriptor_Api_ExportSearchResults,
      callback);
};


/**
 * @param {!proto.loupe.services.api.ExportSearchResultsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.ExportSearchResultsResult>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.exportSearchResults =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ExportSearchResults',
      request,
      metadata || {},
      methodDescriptor_Api_ExportSearchResults);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.TestSearchQueryRequest,
 *   !proto.loupe.services.api.TestSearchQueryResponse>}
 */
const methodDescriptor_Api_TestSearchQuery = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/TestSearchQuery',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.TestSearchQueryRequest,
  proto.loupe.services.api.TestSearchQueryResponse,
  /**
   * @param {!proto.loupe.services.api.TestSearchQueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.TestSearchQueryResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.TestSearchQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.TestSearchQueryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.TestSearchQueryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.testSearchQuery =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/TestSearchQuery',
      request,
      metadata || {},
      methodDescriptor_Api_TestSearchQuery,
      callback);
};


/**
 * @param {!proto.loupe.services.api.TestSearchQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.TestSearchQueryResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.testSearchQuery =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/TestSearchQuery',
      request,
      metadata || {},
      methodDescriptor_Api_TestSearchQuery);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetSavedFiltersByKeyRequest,
 *   !proto.loupe.common.filter.SavedFilters>}
 */
const methodDescriptor_Api_GetSavedFiltersByKey = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSavedFiltersByKey',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetSavedFiltersByKeyRequest,
  src_common_filter_filter_pb.SavedFilters,
  /**
   * @param {!proto.loupe.services.api.GetSavedFiltersByKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_filter_filter_pb.SavedFilters.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetSavedFiltersByKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.filter.SavedFilters)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.filter.SavedFilters>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSavedFiltersByKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSavedFiltersByKey',
      request,
      metadata || {},
      methodDescriptor_Api_GetSavedFiltersByKey,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetSavedFiltersByKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.filter.SavedFilters>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSavedFiltersByKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSavedFiltersByKey',
      request,
      metadata || {},
      methodDescriptor_Api_GetSavedFiltersByKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.UpsertSavedFiltersByKeyRequest,
 *   !proto.loupe.common.filter.SavedFilters>}
 */
const methodDescriptor_Api_UpsertSavedFiltersByKey = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertSavedFiltersByKey',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.UpsertSavedFiltersByKeyRequest,
  src_common_filter_filter_pb.SavedFilters,
  /**
   * @param {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_filter_filter_pb.SavedFilters.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.filter.SavedFilters)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.filter.SavedFilters>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertSavedFiltersByKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertSavedFiltersByKey',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertSavedFiltersByKey,
      callback);
};


/**
 * @param {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.filter.SavedFilters>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertSavedFiltersByKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertSavedFiltersByKey',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertSavedFiltersByKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.activity.GetActivityTimelineRequest,
 *   !proto.loupe.services.activity.GetActivityTimelineResponse>}
 */
const methodDescriptor_Api_GetActivityTimeline = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetActivityTimeline',
  grpc.web.MethodType.UNARY,
  src_services_activity_activity_pb.GetActivityTimelineRequest,
  src_services_activity_activity_pb.GetActivityTimelineResponse,
  /**
   * @param {!proto.loupe.services.activity.GetActivityTimelineRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_activity_activity_pb.GetActivityTimelineResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.activity.GetActivityTimelineRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.activity.GetActivityTimelineResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.activity.GetActivityTimelineResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getActivityTimeline =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetActivityTimeline',
      request,
      metadata || {},
      methodDescriptor_Api_GetActivityTimeline,
      callback);
};


/**
 * @param {!proto.loupe.services.activity.GetActivityTimelineRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.activity.GetActivityTimelineResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getActivityTimeline =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetActivityTimeline',
      request,
      metadata || {},
      methodDescriptor_Api_GetActivityTimeline);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.activity.GetActivityDetailsRequest,
 *   !proto.loupe.services.activity.GetActivityDetailsResponse>}
 */
const methodDescriptor_Api_GetActivityDetails = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetActivityDetails',
  grpc.web.MethodType.UNARY,
  src_services_activity_activity_pb.GetActivityDetailsRequest,
  src_services_activity_activity_pb.GetActivityDetailsResponse,
  /**
   * @param {!proto.loupe.services.activity.GetActivityDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_activity_activity_pb.GetActivityDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.activity.GetActivityDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.activity.GetActivityDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.activity.GetActivityDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getActivityDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetActivityDetails',
      request,
      metadata || {},
      methodDescriptor_Api_GetActivityDetails,
      callback);
};


/**
 * @param {!proto.loupe.services.activity.GetActivityDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.activity.GetActivityDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getActivityDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetActivityDetails',
      request,
      metadata || {},
      methodDescriptor_Api_GetActivityDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.activity.GetActivityDateCursorRequest,
 *   !proto.loupe.services.activity.GetActivityDateCursorResponse>}
 */
const methodDescriptor_Api_GetNextActivityDate = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetNextActivityDate',
  grpc.web.MethodType.UNARY,
  src_services_activity_activity_pb.GetActivityDateCursorRequest,
  src_services_activity_activity_pb.GetActivityDateCursorResponse,
  /**
   * @param {!proto.loupe.services.activity.GetActivityDateCursorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_activity_activity_pb.GetActivityDateCursorResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.activity.GetActivityDateCursorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.activity.GetActivityDateCursorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.activity.GetActivityDateCursorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getNextActivityDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetNextActivityDate',
      request,
      metadata || {},
      methodDescriptor_Api_GetNextActivityDate,
      callback);
};


/**
 * @param {!proto.loupe.services.activity.GetActivityDateCursorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.activity.GetActivityDateCursorResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getNextActivityDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetNextActivityDate',
      request,
      metadata || {},
      methodDescriptor_Api_GetNextActivityDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.activity.GetActivityDateCursorRequest,
 *   !proto.loupe.services.activity.GetActivityDateCursorResponse>}
 */
const methodDescriptor_Api_GetPreviousActivityDate = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetPreviousActivityDate',
  grpc.web.MethodType.UNARY,
  src_services_activity_activity_pb.GetActivityDateCursorRequest,
  src_services_activity_activity_pb.GetActivityDateCursorResponse,
  /**
   * @param {!proto.loupe.services.activity.GetActivityDateCursorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_activity_activity_pb.GetActivityDateCursorResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.activity.GetActivityDateCursorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.activity.GetActivityDateCursorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.activity.GetActivityDateCursorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getPreviousActivityDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetPreviousActivityDate',
      request,
      metadata || {},
      methodDescriptor_Api_GetPreviousActivityDate,
      callback);
};


/**
 * @param {!proto.loupe.services.activity.GetActivityDateCursorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.activity.GetActivityDateCursorResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getPreviousActivityDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetPreviousActivityDate',
      request,
      metadata || {},
      methodDescriptor_Api_GetPreviousActivityDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetUserHasActivitySyncedRequest,
 *   !proto.loupe.services.api.GetUserHasActivitySyncedResponse>}
 */
const methodDescriptor_Api_GetUserHasActivitySynced = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetUserHasActivitySynced',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetUserHasActivitySyncedRequest,
  proto.loupe.services.api.GetUserHasActivitySyncedResponse,
  /**
   * @param {!proto.loupe.services.api.GetUserHasActivitySyncedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetUserHasActivitySyncedResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetUserHasActivitySyncedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetUserHasActivitySyncedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetUserHasActivitySyncedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getUserHasActivitySynced =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetUserHasActivitySynced',
      request,
      metadata || {},
      methodDescriptor_Api_GetUserHasActivitySynced,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetUserHasActivitySyncedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetUserHasActivitySyncedResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getUserHasActivitySynced =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetUserHasActivitySynced',
      request,
      metadata || {},
      methodDescriptor_Api_GetUserHasActivitySynced);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.GetGroupSubTreeRequest,
 *   !proto.loupe.services.orchard.GetGroupSubTreeResponse>}
 */
const methodDescriptor_Api_GetGroupSubTree = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetGroupSubTree',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.GetGroupSubTreeRequest,
  src_services_orchard_orchard_pb.GetGroupSubTreeResponse,
  /**
   * @param {!proto.loupe.services.orchard.GetGroupSubTreeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.GetGroupSubTreeResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.GetGroupSubTreeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.GetGroupSubTreeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.GetGroupSubTreeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getGroupSubTree =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetGroupSubTree',
      request,
      metadata || {},
      methodDescriptor_Api_GetGroupSubTree,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.GetGroupSubTreeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.GetGroupSubTreeResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getGroupSubTree =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetGroupSubTree',
      request,
      metadata || {},
      methodDescriptor_Api_GetGroupSubTree);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.GetGroupSubTreeRequest,
 *   !proto.loupe.services.orchard.GetGroupSubTreeResponse>}
 */
const methodDescriptor_Api_GetGroupSubTreeWithContext = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetGroupSubTreeWithContext',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.GetGroupSubTreeRequest,
  src_services_orchard_orchard_pb.GetGroupSubTreeResponse,
  /**
   * @param {!proto.loupe.services.orchard.GetGroupSubTreeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.GetGroupSubTreeResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.GetGroupSubTreeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.GetGroupSubTreeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.GetGroupSubTreeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getGroupSubTreeWithContext =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetGroupSubTreeWithContext',
      request,
      metadata || {},
      methodDescriptor_Api_GetGroupSubTreeWithContext,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.GetGroupSubTreeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.GetGroupSubTreeResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getGroupSubTreeWithContext =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetGroupSubTreeWithContext',
      request,
      metadata || {},
      methodDescriptor_Api_GetGroupSubTreeWithContext);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.UpdateGroupRequest,
 *   !proto.loupe.services.orchard.UpdateGroupResponse>}
 */
const methodDescriptor_Api_UpdateGroup = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpdateGroup',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.UpdateGroupRequest,
  src_services_orchard_orchard_pb.UpdateGroupResponse,
  /**
   * @param {!proto.loupe.services.orchard.UpdateGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.UpdateGroupResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.UpdateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.UpdateGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.UpdateGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.updateGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateGroup',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateGroup,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.UpdateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.UpdateGroupResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.updateGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateGroup',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.IdRequest,
 *   !proto.loupe.services.orchard.Empty>}
 */
const methodDescriptor_Api_DeleteGroupById = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DeleteGroupById',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.IdRequest,
  src_services_orchard_orchard_pb.Empty,
  /**
   * @param {!proto.loupe.services.orchard.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.deleteGroupById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteGroupById',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteGroupById,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.deleteGroupById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteGroupById',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteGroupById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.CreateGroupRequest,
 *   !proto.loupe.services.orchard.CreateGroupResponse>}
 */
const methodDescriptor_Api_CreateGroup = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/CreateGroup',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.CreateGroupRequest,
  src_services_orchard_orchard_pb.CreateGroupResponse,
  /**
   * @param {!proto.loupe.services.orchard.CreateGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.CreateGroupResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.CreateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.CreateGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.CreateGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.createGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/CreateGroup',
      request,
      metadata || {},
      methodDescriptor_Api_CreateGroup,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.CreateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.CreateGroupResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.createGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/CreateGroup',
      request,
      metadata || {},
      methodDescriptor_Api_CreateGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.IdRequest,
 *   !proto.loupe.common.orchard.Group>}
 */
const methodDescriptor_Api_GetGroupById = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetGroupById',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.IdRequest,
  src_common_orchard_group_pb.Group,
  /**
   * @param {!proto.loupe.services.orchard.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_orchard_group_pb.Group.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.orchard.Group)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.orchard.Group>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getGroupById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetGroupById',
      request,
      metadata || {},
      methodDescriptor_Api_GetGroupById,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.orchard.Group>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getGroupById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetGroupById',
      request,
      metadata || {},
      methodDescriptor_Api_GetGroupById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.GetGroupsRequest,
 *   !proto.loupe.services.orchard.GetGroupsResponse>}
 */
const methodDescriptor_Api_GetGroups = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetGroups',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.GetGroupsRequest,
  src_services_orchard_orchard_pb.GetGroupsResponse,
  /**
   * @param {!proto.loupe.services.orchard.GetGroupsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.GetGroupsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.GetGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.GetGroupsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.GetGroupsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getGroups =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetGroups',
      request,
      metadata || {},
      methodDescriptor_Api_GetGroups,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.GetGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.GetGroupsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getGroups =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetGroups',
      request,
      metadata || {},
      methodDescriptor_Api_GetGroups);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.UpdatePersonRequest,
 *   !proto.loupe.services.orchard.UpdatePersonResponse>}
 */
const methodDescriptor_Api_UpdatePerson = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpdatePerson',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.UpdatePersonRequest,
  src_services_orchard_orchard_pb.UpdatePersonResponse,
  /**
   * @param {!proto.loupe.services.orchard.UpdatePersonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.UpdatePersonResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.UpdatePersonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.UpdatePersonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.UpdatePersonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.updatePerson =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpdatePerson',
      request,
      metadata || {},
      methodDescriptor_Api_UpdatePerson,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.UpdatePersonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.UpdatePersonResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.updatePerson =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpdatePerson',
      request,
      metadata || {},
      methodDescriptor_Api_UpdatePerson);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.UpdatePersonWithGroupViewerRequest,
 *   !proto.loupe.services.api.UpdatePersonWithGroupViewerResponse>}
 */
const methodDescriptor_Api_UpdatePersonWithGroupViewer = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpdatePersonWithGroupViewer',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.UpdatePersonWithGroupViewerRequest,
  proto.loupe.services.api.UpdatePersonWithGroupViewerResponse,
  /**
   * @param {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.UpdatePersonWithGroupViewerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.UpdatePersonWithGroupViewerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.updatePersonWithGroupViewer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpdatePersonWithGroupViewer',
      request,
      metadata || {},
      methodDescriptor_Api_UpdatePersonWithGroupViewer,
      callback);
};


/**
 * @param {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.UpdatePersonWithGroupViewerResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.updatePersonWithGroupViewer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpdatePersonWithGroupViewer',
      request,
      metadata || {},
      methodDescriptor_Api_UpdatePersonWithGroupViewer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.CreatePersonRequest,
 *   !proto.loupe.services.orchard.CreatePersonResponse>}
 */
const methodDescriptor_Api_CreatePerson = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/CreatePerson',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.CreatePersonRequest,
  src_services_orchard_orchard_pb.CreatePersonResponse,
  /**
   * @param {!proto.loupe.services.orchard.CreatePersonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.CreatePersonResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.CreatePersonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.CreatePersonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.CreatePersonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.createPerson =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/CreatePerson',
      request,
      metadata || {},
      methodDescriptor_Api_CreatePerson,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.CreatePersonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.CreatePersonResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.createPerson =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/CreatePerson',
      request,
      metadata || {},
      methodDescriptor_Api_CreatePerson);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.CreatePersonWithGroupViewerRequest,
 *   !proto.loupe.services.api.CreatePersonWithGroupViewerResponse>}
 */
const methodDescriptor_Api_CreatePersonWithGroupViewer = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/CreatePersonWithGroupViewer',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.CreatePersonWithGroupViewerRequest,
  proto.loupe.services.api.CreatePersonWithGroupViewerResponse,
  /**
   * @param {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.CreatePersonWithGroupViewerResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.CreatePersonWithGroupViewerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.CreatePersonWithGroupViewerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.createPersonWithGroupViewer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/CreatePersonWithGroupViewer',
      request,
      metadata || {},
      methodDescriptor_Api_CreatePersonWithGroupViewer,
      callback);
};


/**
 * @param {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.CreatePersonWithGroupViewerResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.createPersonWithGroupViewer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/CreatePersonWithGroupViewer',
      request,
      metadata || {},
      methodDescriptor_Api_CreatePersonWithGroupViewer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.SearchPeopleRequest,
 *   !proto.loupe.services.orchard.SearchPeopleResponse>}
 */
const methodDescriptor_Api_SearchPeople = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SearchPeople',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.SearchPeopleRequest,
  src_services_orchard_orchard_pb.SearchPeopleResponse,
  /**
   * @param {!proto.loupe.services.orchard.SearchPeopleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.SearchPeopleResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.SearchPeopleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.SearchPeopleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.SearchPeopleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.searchPeople =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SearchPeople',
      request,
      metadata || {},
      methodDescriptor_Api_SearchPeople,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.SearchPeopleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.SearchPeopleResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.searchPeople =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SearchPeople',
      request,
      metadata || {},
      methodDescriptor_Api_SearchPeople);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.GetGroupMembersRequest,
 *   !proto.loupe.services.orchard.GetGroupMembersResponse>}
 */
const methodDescriptor_Api_GetGroupMembers = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetGroupMembers',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.GetGroupMembersRequest,
  src_services_orchard_orchard_pb.GetGroupMembersResponse,
  /**
   * @param {!proto.loupe.services.orchard.GetGroupMembersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.GetGroupMembersResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.GetGroupMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.GetGroupMembersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.GetGroupMembersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getGroupMembers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetGroupMembers',
      request,
      metadata || {},
      methodDescriptor_Api_GetGroupMembers,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.GetGroupMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.GetGroupMembersResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getGroupMembers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetGroupMembers',
      request,
      metadata || {},
      methodDescriptor_Api_GetGroupMembers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.IdRequest,
 *   !proto.loupe.services.orchard.Empty>}
 */
const methodDescriptor_Api_DeletePersonById = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DeletePersonById',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.IdRequest,
  src_services_orchard_orchard_pb.Empty,
  /**
   * @param {!proto.loupe.services.orchard.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.deletePersonById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DeletePersonById',
      request,
      metadata || {},
      methodDescriptor_Api_DeletePersonById,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.deletePersonById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DeletePersonById',
      request,
      metadata || {},
      methodDescriptor_Api_DeletePersonById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.GetUserTeamRequest,
 *   !proto.loupe.services.orchard.GetUserTeamResponse>}
 */
const methodDescriptor_Api_GetUserTeam = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetUserTeam',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.GetUserTeamRequest,
  src_services_orchard_orchard_pb.GetUserTeamResponse,
  /**
   * @param {!proto.loupe.services.orchard.GetUserTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.GetUserTeamResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.GetUserTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.GetUserTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.GetUserTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getUserTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetUserTeam',
      request,
      metadata || {},
      methodDescriptor_Api_GetUserTeam,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.GetUserTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.GetUserTeamResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getUserTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetUserTeam',
      request,
      metadata || {},
      methodDescriptor_Api_GetUserTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.GetSystemRolesRequest,
 *   !proto.loupe.services.orchard.GetSystemRolesResponse>}
 */
const methodDescriptor_Api_GetSystemRoles = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSystemRoles',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.GetSystemRolesRequest,
  src_services_orchard_orchard_pb.GetSystemRolesResponse,
  /**
   * @param {!proto.loupe.services.orchard.GetSystemRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.GetSystemRolesResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.GetSystemRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.GetSystemRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.GetSystemRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSystemRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSystemRoles',
      request,
      metadata || {},
      methodDescriptor_Api_GetSystemRoles,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.GetSystemRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.GetSystemRolesResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSystemRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSystemRoles',
      request,
      metadata || {},
      methodDescriptor_Api_GetSystemRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.orchard.SystemRole,
 *   !proto.loupe.common.orchard.SystemRoleExtended>}
 */
const methodDescriptor_Api_CreateSystemRole = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/CreateSystemRole',
  grpc.web.MethodType.UNARY,
  src_common_orchard_system_role_pb.SystemRole,
  src_common_orchard_system_role_pb.SystemRoleExtended,
  /**
   * @param {!proto.loupe.common.orchard.SystemRole} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_orchard_system_role_pb.SystemRoleExtended.deserializeBinary
);


/**
 * @param {!proto.loupe.common.orchard.SystemRole} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.orchard.SystemRoleExtended)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.orchard.SystemRoleExtended>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.createSystemRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/CreateSystemRole',
      request,
      metadata || {},
      methodDescriptor_Api_CreateSystemRole,
      callback);
};


/**
 * @param {!proto.loupe.common.orchard.SystemRole} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.orchard.SystemRoleExtended>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.createSystemRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/CreateSystemRole',
      request,
      metadata || {},
      methodDescriptor_Api_CreateSystemRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.IdRequest,
 *   !proto.loupe.services.orchard.Empty>}
 */
const methodDescriptor_Api_DeleteSystemRole = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DeleteSystemRole',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.IdRequest,
  src_services_orchard_orchard_pb.Empty,
  /**
   * @param {!proto.loupe.services.orchard.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.deleteSystemRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteSystemRole',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteSystemRole,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.deleteSystemRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteSystemRole',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteSystemRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.IdRequest,
 *   !proto.loupe.common.orchard.SystemRoleExtended>}
 */
const methodDescriptor_Api_GetSystemRole = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetSystemRole',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.IdRequest,
  src_common_orchard_system_role_pb.SystemRoleExtended,
  /**
   * @param {!proto.loupe.services.orchard.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_orchard_system_role_pb.SystemRoleExtended.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.orchard.SystemRoleExtended)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.orchard.SystemRoleExtended>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getSystemRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetSystemRole',
      request,
      metadata || {},
      methodDescriptor_Api_GetSystemRole,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.orchard.SystemRoleExtended>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getSystemRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetSystemRole',
      request,
      metadata || {},
      methodDescriptor_Api_GetSystemRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.common.orchard.SystemRoleExtended,
 *   !proto.loupe.common.orchard.SystemRoleExtended>}
 */
const methodDescriptor_Api_UpdateSystemRole = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpdateSystemRole',
  grpc.web.MethodType.UNARY,
  src_common_orchard_system_role_pb.SystemRoleExtended,
  src_common_orchard_system_role_pb.SystemRoleExtended,
  /**
   * @param {!proto.loupe.common.orchard.SystemRoleExtended} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_orchard_system_role_pb.SystemRoleExtended.deserializeBinary
);


/**
 * @param {!proto.loupe.common.orchard.SystemRoleExtended} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.orchard.SystemRoleExtended)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.orchard.SystemRoleExtended>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.updateSystemRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateSystemRole',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateSystemRole,
      callback);
};


/**
 * @param {!proto.loupe.common.orchard.SystemRoleExtended} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.orchard.SystemRoleExtended>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.updateSystemRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateSystemRole',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateSystemRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.IsHierarchySyncedRequest,
 *   !proto.loupe.services.orchard.IsHierarchySyncedResponse>}
 */
const methodDescriptor_Api_IsHierarchySynced = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/IsHierarchySynced',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.IsHierarchySyncedRequest,
  src_services_orchard_orchard_pb.IsHierarchySyncedResponse,
  /**
   * @param {!proto.loupe.services.orchard.IsHierarchySyncedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.IsHierarchySyncedResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.IsHierarchySyncedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.IsHierarchySyncedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.IsHierarchySyncedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.isHierarchySynced =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/IsHierarchySynced',
      request,
      metadata || {},
      methodDescriptor_Api_IsHierarchySynced,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.IsHierarchySyncedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.IsHierarchySyncedResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.isHierarchySynced =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/IsHierarchySynced',
      request,
      metadata || {},
      methodDescriptor_Api_IsHierarchySynced);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.IdRequest,
 *   !proto.loupe.common.orchard.CRMRole>}
 */
const methodDescriptor_Api_GetCRMRoleById = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetCRMRoleById',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.IdRequest,
  src_common_orchard_crm_role_pb.CRMRole,
  /**
   * @param {!proto.loupe.services.orchard.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_orchard_crm_role_pb.CRMRole.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.orchard.CRMRole)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.orchard.CRMRole>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getCRMRoleById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetCRMRoleById',
      request,
      metadata || {},
      methodDescriptor_Api_GetCRMRoleById,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.orchard.CRMRole>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getCRMRoleById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetCRMRoleById',
      request,
      metadata || {},
      methodDescriptor_Api_GetCRMRoleById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.IdsRequest,
 *   !proto.loupe.services.orchard.GetCRMRolesByIdsResponse>}
 */
const methodDescriptor_Api_GetCRMRolesByIds = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetCRMRolesByIds',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.IdsRequest,
  src_services_orchard_orchard_pb.GetCRMRolesByIdsResponse,
  /**
   * @param {!proto.loupe.services.orchard.IdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.GetCRMRolesByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.IdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.GetCRMRolesByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.GetCRMRolesByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getCRMRolesByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetCRMRolesByIds',
      request,
      metadata || {},
      methodDescriptor_Api_GetCRMRolesByIds,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.IdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.GetCRMRolesByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getCRMRolesByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetCRMRolesByIds',
      request,
      metadata || {},
      methodDescriptor_Api_GetCRMRolesByIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.GetCRMRolesRequest,
 *   !proto.loupe.services.orchard.GetCRMRolesResponse>}
 */
const methodDescriptor_Api_GetCRMRoles = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetCRMRoles',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.GetCRMRolesRequest,
  src_services_orchard_orchard_pb.GetCRMRolesResponse,
  /**
   * @param {!proto.loupe.services.orchard.GetCRMRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.GetCRMRolesResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.GetCRMRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.GetCRMRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.GetCRMRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getCRMRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetCRMRoles',
      request,
      metadata || {},
      methodDescriptor_Api_GetCRMRoles,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.GetCRMRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.GetCRMRolesResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getCRMRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetCRMRoles',
      request,
      metadata || {},
      methodDescriptor_Api_GetCRMRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest,
 *   !proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse>}
 */
const methodDescriptor_Api_GetUnsyncedCRMRoles = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetUnsyncedCRMRoles',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.GetUnsyncedCRMRolesRequest,
  src_services_orchard_orchard_pb.GetUnsyncedCRMRolesResponse,
  /**
   * @param {!proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.GetUnsyncedCRMRolesResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getUnsyncedCRMRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetUnsyncedCRMRoles',
      request,
      metadata || {},
      methodDescriptor_Api_GetUnsyncedCRMRoles,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.GetUnsyncedCRMRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.GetUnsyncedCRMRolesResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getUnsyncedCRMRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetUnsyncedCRMRoles',
      request,
      metadata || {},
      methodDescriptor_Api_GetUnsyncedCRMRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.GetUngroupedPeopleRequest,
 *   !proto.loupe.services.orchard.GetUngroupedPeopleResponse>}
 */
const methodDescriptor_Api_GetUngroupedPeople = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetUngroupedPeople',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.GetUngroupedPeopleRequest,
  src_services_orchard_orchard_pb.GetUngroupedPeopleResponse,
  /**
   * @param {!proto.loupe.services.orchard.GetUngroupedPeopleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.GetUngroupedPeopleResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.GetUngroupedPeopleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.GetUngroupedPeopleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.GetUngroupedPeopleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getUngroupedPeople =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetUngroupedPeople',
      request,
      metadata || {},
      methodDescriptor_Api_GetUngroupedPeople,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.GetUngroupedPeopleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.GetUngroupedPeopleResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getUngroupedPeople =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetUngroupedPeople',
      request,
      metadata || {},
      methodDescriptor_Api_GetUngroupedPeople);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.ResetHierarchyRequest,
 *   !proto.loupe.services.orchard.ResetHierarchyResponse>}
 */
const methodDescriptor_Api_ResetHierarchy = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ResetHierarchy',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.ResetHierarchyRequest,
  src_services_orchard_orchard_pb.ResetHierarchyResponse,
  /**
   * @param {!proto.loupe.services.orchard.ResetHierarchyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.ResetHierarchyResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.ResetHierarchyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.ResetHierarchyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.ResetHierarchyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.resetHierarchy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ResetHierarchy',
      request,
      metadata || {},
      methodDescriptor_Api_ResetHierarchy,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.ResetHierarchyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.ResetHierarchyResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.resetHierarchy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ResetHierarchy',
      request,
      metadata || {},
      methodDescriptor_Api_ResetHierarchy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.SyncRequest,
 *   !proto.loupe.services.orchard.SyncResponse>}
 */
const methodDescriptor_Api_Sync = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/Sync',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.SyncRequest,
  src_services_orchard_orchard_pb.SyncResponse,
  /**
   * @param {!proto.loupe.services.orchard.SyncRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.SyncResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.SyncRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.SyncResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.SyncResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.sync =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/Sync',
      request,
      metadata || {},
      methodDescriptor_Api_Sync,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.SyncRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.SyncResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.sync =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/Sync',
      request,
      metadata || {},
      methodDescriptor_Api_Sync);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetGroupSyncStateRequest,
 *   !proto.loupe.services.api.GetGroupSyncStateResponse>}
 */
const methodDescriptor_Api_GetGroupSyncState = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetGroupSyncState',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetGroupSyncStateRequest,
  proto.loupe.services.api.GetGroupSyncStateResponse,
  /**
   * @param {!proto.loupe.services.api.GetGroupSyncStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetGroupSyncStateResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetGroupSyncStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetGroupSyncStateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetGroupSyncStateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getGroupSyncState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetGroupSyncState',
      request,
      metadata || {},
      methodDescriptor_Api_GetGroupSyncState,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetGroupSyncStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetGroupSyncStateResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getGroupSyncState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetGroupSyncState',
      request,
      metadata || {},
      methodDescriptor_Api_GetGroupSyncState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.UpdateGroupSyncStateRequest,
 *   !proto.loupe.services.orchard.UpdateGroupSyncStateResponse>}
 */
const methodDescriptor_Api_UpdateGroupSyncState = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpdateGroupSyncState',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.UpdateGroupSyncStateRequest,
  src_services_orchard_orchard_pb.UpdateGroupSyncStateResponse,
  /**
   * @param {!proto.loupe.services.orchard.UpdateGroupSyncStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.UpdateGroupSyncStateResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.UpdateGroupSyncStateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.UpdateGroupSyncStateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.updateGroupSyncState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateGroupSyncState',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateGroupSyncState,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.UpdateGroupSyncStateResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.updateGroupSyncState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateGroupSyncState',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateGroupSyncState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest,
 *   !proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse>}
 */
const methodDescriptor_Api_UpdateGroupSyncMetadata = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpdateGroupSyncMetadata',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.UpdateGroupSyncMetadataRequest,
  src_services_orchard_orchard_pb.UpdateGroupSyncMetadataResponse,
  /**
   * @param {!proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.UpdateGroupSyncMetadataResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.updateGroupSyncMetadata =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateGroupSyncMetadata',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateGroupSyncMetadata,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.UpdateGroupSyncMetadataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.UpdateGroupSyncMetadataResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.updateGroupSyncMetadata =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateGroupSyncMetadata',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateGroupSyncMetadata);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.orchard.IdRequest,
 *   !proto.loupe.services.orchard.ReprovisionPeopleResponse>}
 */
const methodDescriptor_Api_ReprovisionPeople = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ReprovisionPeople',
  grpc.web.MethodType.UNARY,
  src_services_orchard_orchard_pb.IdRequest,
  src_services_orchard_orchard_pb.ReprovisionPeopleResponse,
  /**
   * @param {!proto.loupe.services.orchard.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_orchard_orchard_pb.ReprovisionPeopleResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.orchard.ReprovisionPeopleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.orchard.ReprovisionPeopleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.reprovisionPeople =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ReprovisionPeople',
      request,
      metadata || {},
      methodDescriptor_Api_ReprovisionPeople,
      callback);
};


/**
 * @param {!proto.loupe.services.orchard.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.orchard.ReprovisionPeopleResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.reprovisionPeople =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ReprovisionPeople',
      request,
      metadata || {},
      methodDescriptor_Api_ReprovisionPeople);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.data_resolver.ResolveDataRequest,
 *   !proto.loupe.services.data_resolver.ResolveDataResponse>}
 */
const methodDescriptor_Api_ResolveData = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ResolveData',
  grpc.web.MethodType.UNARY,
  src_services_data$resolver_data$resolver_pb.ResolveDataRequest,
  src_services_data$resolver_data$resolver_pb.ResolveDataResponse,
  /**
   * @param {!proto.loupe.services.data_resolver.ResolveDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_data$resolver_data$resolver_pb.ResolveDataResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.data_resolver.ResolveDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.data_resolver.ResolveDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.data_resolver.ResolveDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.resolveData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ResolveData',
      request,
      metadata || {},
      methodDescriptor_Api_ResolveData,
      callback);
};


/**
 * @param {!proto.loupe.services.data_resolver.ResolveDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.data_resolver.ResolveDataResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.resolveData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ResolveData',
      request,
      metadata || {},
      methodDescriptor_Api_ResolveData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.goal_manager.GenerateSpreadsheetRequest,
 *   !proto.loupe.services.goal_manager.GenerateSpreadsheetResponse>}
 */
const methodDescriptor_Api_GenerateGoalSpreadsheet = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GenerateGoalSpreadsheet',
  grpc.web.MethodType.UNARY,
  src_services_goal$manager_goal$manager_pb.GenerateSpreadsheetRequest,
  src_services_goal$manager_goal$manager_pb.GenerateSpreadsheetResponse,
  /**
   * @param {!proto.loupe.services.goal_manager.GenerateSpreadsheetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_goal$manager_goal$manager_pb.GenerateSpreadsheetResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.goal_manager.GenerateSpreadsheetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.goal_manager.GenerateSpreadsheetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.goal_manager.GenerateSpreadsheetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.generateGoalSpreadsheet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GenerateGoalSpreadsheet',
      request,
      metadata || {},
      methodDescriptor_Api_GenerateGoalSpreadsheet,
      callback);
};


/**
 * @param {!proto.loupe.services.goal_manager.GenerateSpreadsheetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.goal_manager.GenerateSpreadsheetResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.generateGoalSpreadsheet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GenerateGoalSpreadsheet',
      request,
      metadata || {},
      methodDescriptor_Api_GenerateGoalSpreadsheet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.goal_manager.UploadSpreadsheetRequest,
 *   !proto.loupe.services.goal_manager.UploadSpreadsheetResponse>}
 */
const methodDescriptor_Api_UploadGoalSpreadsheet = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UploadGoalSpreadsheet',
  grpc.web.MethodType.UNARY,
  src_services_goal$manager_goal$manager_pb.UploadSpreadsheetRequest,
  src_services_goal$manager_goal$manager_pb.UploadSpreadsheetResponse,
  /**
   * @param {!proto.loupe.services.goal_manager.UploadSpreadsheetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_goal$manager_goal$manager_pb.UploadSpreadsheetResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.goal_manager.UploadSpreadsheetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.goal_manager.UploadSpreadsheetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.goal_manager.UploadSpreadsheetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.uploadGoalSpreadsheet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UploadGoalSpreadsheet',
      request,
      metadata || {},
      methodDescriptor_Api_UploadGoalSpreadsheet,
      callback);
};


/**
 * @param {!proto.loupe.services.goal_manager.UploadSpreadsheetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.goal_manager.UploadSpreadsheetResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.uploadGoalSpreadsheet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UploadGoalSpreadsheet',
      request,
      metadata || {},
      methodDescriptor_Api_UploadGoalSpreadsheet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.goal_manager.ProcessSpreadsheetRequest,
 *   !proto.loupe.services.goal_manager.ProcessSpreadsheetResponse>}
 */
const methodDescriptor_Api_ProcessGoalSpreadsheet = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ProcessGoalSpreadsheet',
  grpc.web.MethodType.UNARY,
  src_services_goal$manager_goal$manager_pb.ProcessSpreadsheetRequest,
  src_services_goal$manager_goal$manager_pb.ProcessSpreadsheetResponse,
  /**
   * @param {!proto.loupe.services.goal_manager.ProcessSpreadsheetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_goal$manager_goal$manager_pb.ProcessSpreadsheetResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.goal_manager.ProcessSpreadsheetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.goal_manager.ProcessSpreadsheetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.goal_manager.ProcessSpreadsheetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.processGoalSpreadsheet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ProcessGoalSpreadsheet',
      request,
      metadata || {},
      methodDescriptor_Api_ProcessGoalSpreadsheet,
      callback);
};


/**
 * @param {!proto.loupe.services.goal_manager.ProcessSpreadsheetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.goal_manager.ProcessSpreadsheetResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.processGoalSpreadsheet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ProcessGoalSpreadsheet',
      request,
      metadata || {},
      methodDescriptor_Api_ProcessGoalSpreadsheet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.goal_manager.GetGoalCategoriesRequest,
 *   !proto.loupe.services.goal_manager.GetGoalCategoriesResponse>}
 */
const methodDescriptor_Api_GetGoalCategories = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetGoalCategories',
  grpc.web.MethodType.UNARY,
  src_services_goal$manager_goal$manager_pb.GetGoalCategoriesRequest,
  src_services_goal$manager_goal$manager_pb.GetGoalCategoriesResponse,
  /**
   * @param {!proto.loupe.services.goal_manager.GetGoalCategoriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_goal$manager_goal$manager_pb.GetGoalCategoriesResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.goal_manager.GetGoalCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.goal_manager.GetGoalCategoriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.goal_manager.GetGoalCategoriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getGoalCategories =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetGoalCategories',
      request,
      metadata || {},
      methodDescriptor_Api_GetGoalCategories,
      callback);
};


/**
 * @param {!proto.loupe.services.goal_manager.GetGoalCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.goal_manager.GetGoalCategoriesResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getGoalCategories =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetGoalCategories',
      request,
      metadata || {},
      methodDescriptor_Api_GetGoalCategories);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.goal_manager.ListGoalsPerTenantRequest,
 *   !proto.loupe.services.goal_manager.ListGoalsPerTenantResponse>}
 */
const methodDescriptor_Api_ListGoalsPerTenant = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListGoalsPerTenant',
  grpc.web.MethodType.UNARY,
  src_services_goal$manager_goal$manager_pb.ListGoalsPerTenantRequest,
  src_services_goal$manager_goal$manager_pb.ListGoalsPerTenantResponse,
  /**
   * @param {!proto.loupe.services.goal_manager.ListGoalsPerTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_goal$manager_goal$manager_pb.ListGoalsPerTenantResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.goal_manager.ListGoalsPerTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.goal_manager.ListGoalsPerTenantResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.goal_manager.ListGoalsPerTenantResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listGoalsPerTenant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListGoalsPerTenant',
      request,
      metadata || {},
      methodDescriptor_Api_ListGoalsPerTenant,
      callback);
};


/**
 * @param {!proto.loupe.services.goal_manager.ListGoalsPerTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.goal_manager.ListGoalsPerTenantResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listGoalsPerTenant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListGoalsPerTenant',
      request,
      metadata || {},
      methodDescriptor_Api_ListGoalsPerTenant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.CheckPermissionsRequest,
 *   !proto.loupe.services.api.CheckPermissionsResponse>}
 */
const methodDescriptor_Api_CheckPermissions = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/CheckPermissions',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.CheckPermissionsRequest,
  proto.loupe.services.api.CheckPermissionsResponse,
  /**
   * @param {!proto.loupe.services.api.CheckPermissionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.CheckPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.CheckPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.CheckPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.CheckPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.checkPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/CheckPermissions',
      request,
      metadata || {},
      methodDescriptor_Api_CheckPermissions,
      callback);
};


/**
 * @param {!proto.loupe.services.api.CheckPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.CheckPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.checkPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/CheckPermissions',
      request,
      metadata || {},
      methodDescriptor_Api_CheckPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.bouncer.BustAuthCacheRequest,
 *   !proto.loupe.services.bouncer.BustAuthCacheResponse>}
 */
const methodDescriptor_Api_BustAuthCache = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/BustAuthCache',
  grpc.web.MethodType.UNARY,
  src_services_bouncer_bouncer_pb.BustAuthCacheRequest,
  src_services_bouncer_bouncer_pb.BustAuthCacheResponse,
  /**
   * @param {!proto.loupe.services.bouncer.BustAuthCacheRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_bouncer_bouncer_pb.BustAuthCacheResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.bouncer.BustAuthCacheRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.bouncer.BustAuthCacheResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.bouncer.BustAuthCacheResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.bustAuthCache =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/BustAuthCache',
      request,
      metadata || {},
      methodDescriptor_Api_BustAuthCache,
      callback);
};


/**
 * @param {!proto.loupe.services.bouncer.BustAuthCacheRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.bouncer.BustAuthCacheResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.bustAuthCache =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/BustAuthCache',
      request,
      metadata || {},
      methodDescriptor_Api_BustAuthCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.GetTenantsRequest,
 *   !proto.loupe.services.tenant.GetTenantsResponse>}
 */
const methodDescriptor_Api_GetTenants = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetTenants',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.GetTenantsRequest,
  src_services_tenant_tenant_pb.GetTenantsResponse,
  /**
   * @param {!proto.loupe.services.tenant.GetTenantsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.GetTenantsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.GetTenantsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.GetTenantsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.GetTenantsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getTenants =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenants',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenants,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.GetTenantsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.GetTenantsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getTenants =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenants',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenants);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.GetTenantInfoRequest,
 *   !proto.loupe.services.tenant.GetTenantInfoResponse>}
 */
const methodDescriptor_Api_GetTenantInfo = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetTenantInfo',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.GetTenantInfoRequest,
  src_services_tenant_tenant_pb.GetTenantInfoResponse,
  /**
   * @param {!proto.loupe.services.tenant.GetTenantInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.GetTenantInfoResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.GetTenantInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.GetTenantInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.GetTenantInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getTenantInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantInfo',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantInfo,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.GetTenantInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.GetTenantInfoResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getTenantInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantInfo',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.GetTenantByIdRequest,
 *   !proto.loupe.services.tenant.GetTenantByIdResponse>}
 */
const methodDescriptor_Api_GetTenantById = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetTenantById',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.GetTenantByIdRequest,
  src_services_tenant_tenant_pb.GetTenantByIdResponse,
  /**
   * @param {!proto.loupe.services.tenant.GetTenantByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.GetTenantByIdResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.GetTenantByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.GetTenantByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.GetTenantByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getTenantById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantById',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantById,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.GetTenantByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.GetTenantByIdResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getTenantById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantById',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.GetTenantLastSyncRequest,
 *   !proto.loupe.services.tenant.GetTenantLastSyncResponse>}
 */
const methodDescriptor_Api_GetTenantLastSync = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetTenantLastSync',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.GetTenantLastSyncRequest,
  src_services_tenant_tenant_pb.GetTenantLastSyncResponse,
  /**
   * @param {!proto.loupe.services.tenant.GetTenantLastSyncRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.GetTenantLastSyncResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.GetTenantLastSyncRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.GetTenantLastSyncResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.GetTenantLastSyncResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getTenantLastSync =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantLastSync',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantLastSync,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.GetTenantLastSyncRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.GetTenantLastSyncResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getTenantLastSync =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantLastSync',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantLastSync);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest,
 *   !proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse>}
 */
const methodDescriptor_Api_GetTenantIntegrationObjectFilterOptions = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetTenantIntegrationObjectFilterOptions',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest,
  proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse,
  /**
   * @param {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getTenantIntegrationObjectFilterOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantIntegrationObjectFilterOptions',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantIntegrationObjectFilterOptions,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getTenantIntegrationObjectFilterOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantIntegrationObjectFilterOptions',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantIntegrationObjectFilterOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.UpdateTenantStatusRequest,
 *   !proto.loupe.services.tenant.UpdateTenantStatusResponse>}
 */
const methodDescriptor_Api_UpdateTenantStatus = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpdateTenantStatus',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.UpdateTenantStatusRequest,
  src_services_tenant_tenant_pb.UpdateTenantStatusResponse,
  /**
   * @param {!proto.loupe.services.tenant.UpdateTenantStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.UpdateTenantStatusResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.UpdateTenantStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.UpdateTenantStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.UpdateTenantStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.updateTenantStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateTenantStatus',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateTenantStatus,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.UpdateTenantStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.UpdateTenantStatusResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.updateTenantStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateTenantStatus',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateTenantStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.GetTenantDataSyncStatusRequest,
 *   !proto.loupe.services.tenant.GetTenantDataSyncStatusResponse>}
 */
const methodDescriptor_Api_GetTenantDataSyncStatus = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetTenantDataSyncStatus',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.GetTenantDataSyncStatusRequest,
  src_services_tenant_tenant_pb.GetTenantDataSyncStatusResponse,
  /**
   * @param {!proto.loupe.services.tenant.GetTenantDataSyncStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.GetTenantDataSyncStatusResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.GetTenantDataSyncStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.GetTenantDataSyncStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getTenantDataSyncStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantDataSyncStatus',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantDataSyncStatus,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.GetTenantDataSyncStatusResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getTenantDataSyncStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantDataSyncStatus',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantDataSyncStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest,
 *   !proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse>}
 */
const methodDescriptor_Api_GetTenantDataSyncHistory = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetTenantDataSyncHistory',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.GetTenantDataSyncHistoryRequest,
  src_services_tenant_tenant_pb.GetTenantDataSyncHistoryResponse,
  /**
   * @param {!proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.GetTenantDataSyncHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getTenantDataSyncHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantDataSyncHistory',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantDataSyncHistory,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.GetTenantDataSyncHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.GetTenantDataSyncHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getTenantDataSyncHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantDataSyncHistory',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantDataSyncHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.TriggerDataSyncRequest,
 *   !proto.loupe.services.tenant.TriggerDataSyncResponse>}
 */
const methodDescriptor_Api_TriggerDataSync = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/TriggerDataSync',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.TriggerDataSyncRequest,
  src_services_tenant_tenant_pb.TriggerDataSyncResponse,
  /**
   * @param {!proto.loupe.services.tenant.TriggerDataSyncRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.TriggerDataSyncResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.TriggerDataSyncRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.TriggerDataSyncResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.TriggerDataSyncResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.triggerDataSync =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/TriggerDataSync',
      request,
      metadata || {},
      methodDescriptor_Api_TriggerDataSync,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.TriggerDataSyncRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.TriggerDataSyncResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.triggerDataSync =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/TriggerDataSync',
      request,
      metadata || {},
      methodDescriptor_Api_TriggerDataSync);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.CreateTenantRequest,
 *   !proto.loupe.services.tenant.CreateTenantResponse>}
 */
const methodDescriptor_Api_CreateTenant = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/CreateTenant',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.CreateTenantRequest,
  src_services_tenant_tenant_pb.CreateTenantResponse,
  /**
   * @param {!proto.loupe.services.tenant.CreateTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.CreateTenantResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.CreateTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.CreateTenantResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.CreateTenantResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.createTenant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/CreateTenant',
      request,
      metadata || {},
      methodDescriptor_Api_CreateTenant,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.CreateTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.CreateTenantResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.createTenant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/CreateTenant',
      request,
      metadata || {},
      methodDescriptor_Api_CreateTenant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.UpdateTenantRequest,
 *   !proto.loupe.services.tenant.UpdateTenantResponse>}
 */
const methodDescriptor_Api_UpdateTenant = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpdateTenant',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.UpdateTenantRequest,
  src_services_tenant_tenant_pb.UpdateTenantResponse,
  /**
   * @param {!proto.loupe.services.tenant.UpdateTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.UpdateTenantResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.UpdateTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.UpdateTenantResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.UpdateTenantResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.updateTenant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateTenant',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateTenant,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.UpdateTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.UpdateTenantResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.updateTenant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateTenant',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateTenant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest,
 *   !proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse>}
 */
const methodDescriptor_Api_GetLinkedAccountAuthLink = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetLinkedAccountAuthLink',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.GetLinkedAccountAuthLinkRequest,
  src_services_tenant_tenant_pb.GetLinkedAccountAuthLinkResponse,
  /**
   * @param {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.GetLinkedAccountAuthLinkResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getLinkedAccountAuthLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetLinkedAccountAuthLink',
      request,
      metadata || {},
      methodDescriptor_Api_GetLinkedAccountAuthLink,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.GetLinkedAccountAuthLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.GetLinkedAccountAuthLinkResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getLinkedAccountAuthLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetLinkedAccountAuthLink',
      request,
      metadata || {},
      methodDescriptor_Api_GetLinkedAccountAuthLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tightbeam_global.ListOrgsRequest,
 *   !proto.loupe.services.tightbeam_global.ListOrgsResponse>}
 */
const methodDescriptor_Api_ListOutreachOrgs = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListOutreachOrgs',
  grpc.web.MethodType.UNARY,
  src_services_tightbeam_global_tightbeam_global_pb.ListOrgsRequest,
  src_services_tightbeam_global_tightbeam_global_pb.ListOrgsResponse,
  /**
   * @param {!proto.loupe.services.tightbeam_global.ListOrgsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tightbeam_global_tightbeam_global_pb.ListOrgsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tightbeam_global.ListOrgsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tightbeam_global.ListOrgsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tightbeam_global.ListOrgsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listOutreachOrgs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListOutreachOrgs',
      request,
      metadata || {},
      methodDescriptor_Api_ListOutreachOrgs,
      callback);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.ListOrgsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tightbeam_global.ListOrgsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listOutreachOrgs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListOutreachOrgs',
      request,
      metadata || {},
      methodDescriptor_Api_ListOutreachOrgs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest,
 *   !proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse>}
 */
const methodDescriptor_Api_SearchOutreachOpportunities = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SearchOutreachOpportunities',
  grpc.web.MethodType.UNARY,
  src_services_tightbeam_global_tightbeam_global_pb.SearchOpportunitiesRequest,
  src_services_tightbeam_global_tightbeam_global_pb.SearchOpportunitiesResponse,
  /**
   * @param {!proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tightbeam_global_tightbeam_global_pb.SearchOpportunitiesResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.searchOutreachOpportunities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SearchOutreachOpportunities',
      request,
      metadata || {},
      methodDescriptor_Api_SearchOutreachOpportunities,
      callback);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.searchOutreachOpportunities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SearchOutreachOpportunities',
      request,
      metadata || {},
      methodDescriptor_Api_SearchOutreachOpportunities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.liftoff.GetTenantLiftoffStatusRequest,
 *   !proto.loupe.services.liftoff.GetTenantLiftoffStatusResponse>}
 */
const methodDescriptor_Api_GetTenantLiftoffStatus = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetTenantLiftoffStatus',
  grpc.web.MethodType.UNARY,
  src_services_liftoff_liftoff_pb.GetTenantLiftoffStatusRequest,
  src_services_liftoff_liftoff_pb.GetTenantLiftoffStatusResponse,
  /**
   * @param {!proto.loupe.services.liftoff.GetTenantLiftoffStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_liftoff_liftoff_pb.GetTenantLiftoffStatusResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.liftoff.GetTenantLiftoffStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.liftoff.GetTenantLiftoffStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.liftoff.GetTenantLiftoffStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getTenantLiftoffStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantLiftoffStatus',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantLiftoffStatus,
      callback);
};


/**
 * @param {!proto.loupe.services.liftoff.GetTenantLiftoffStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.liftoff.GetTenantLiftoffStatusResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getTenantLiftoffStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetTenantLiftoffStatus',
      request,
      metadata || {},
      methodDescriptor_Api_GetTenantLiftoffStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.liftoff.UpdateOnboardingStateRequest,
 *   !proto.loupe.services.liftoff.UpdateOnboardingStateResponse>}
 */
const methodDescriptor_Api_UpdateOnboardingState = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpdateOnboardingState',
  grpc.web.MethodType.UNARY,
  src_services_liftoff_liftoff_pb.UpdateOnboardingStateRequest,
  src_services_liftoff_liftoff_pb.UpdateOnboardingStateResponse,
  /**
   * @param {!proto.loupe.services.liftoff.UpdateOnboardingStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_liftoff_liftoff_pb.UpdateOnboardingStateResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.liftoff.UpdateOnboardingStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.liftoff.UpdateOnboardingStateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.liftoff.UpdateOnboardingStateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.updateOnboardingState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateOnboardingState',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateOnboardingState,
      callback);
};


/**
 * @param {!proto.loupe.services.liftoff.UpdateOnboardingStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.liftoff.UpdateOnboardingStateResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.updateOnboardingState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateOnboardingState',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateOnboardingState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.tenant.CreateSandboxRequest,
 *   !proto.loupe.services.tenant.CreateSandboxResponse>}
 */
const methodDescriptor_Api_CreateSandbox = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/CreateSandbox',
  grpc.web.MethodType.UNARY,
  src_services_tenant_tenant_pb.CreateSandboxRequest,
  src_services_tenant_tenant_pb.CreateSandboxResponse,
  /**
   * @param {!proto.loupe.services.tenant.CreateSandboxRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_tenant_tenant_pb.CreateSandboxResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.tenant.CreateSandboxRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.tenant.CreateSandboxResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.tenant.CreateSandboxResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.createSandbox =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/CreateSandbox',
      request,
      metadata || {},
      methodDescriptor_Api_CreateSandbox,
      callback);
};


/**
 * @param {!proto.loupe.services.tenant.CreateSandboxRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.tenant.CreateSandboxResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.createSandbox =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/CreateSandbox',
      request,
      metadata || {},
      methodDescriptor_Api_CreateSandbox);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.UpdateSandboxRequest,
 *   !proto.loupe.services.api.UpdateSandboxResponse>}
 */
const methodDescriptor_Api_UpdateSandbox = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpdateSandbox',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.UpdateSandboxRequest,
  proto.loupe.services.api.UpdateSandboxResponse,
  /**
   * @param {!proto.loupe.services.api.UpdateSandboxRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.UpdateSandboxResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.UpdateSandboxRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.UpdateSandboxResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.UpdateSandboxResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.updateSandbox =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateSandbox',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateSandbox,
      callback);
};


/**
 * @param {!proto.loupe.services.api.UpdateSandboxRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.UpdateSandboxResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.updateSandbox =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpdateSandbox',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateSandbox);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.DeleteSandboxRequest,
 *   !proto.loupe.services.api.DeleteSandboxResponse>}
 */
const methodDescriptor_Api_DeleteSandbox = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DeleteSandbox',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.DeleteSandboxRequest,
  proto.loupe.services.api.DeleteSandboxResponse,
  /**
   * @param {!proto.loupe.services.api.DeleteSandboxRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.DeleteSandboxResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.DeleteSandboxRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.DeleteSandboxResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.DeleteSandboxResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.deleteSandbox =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteSandbox',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteSandbox,
      callback);
};


/**
 * @param {!proto.loupe.services.api.DeleteSandboxRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.DeleteSandboxResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.deleteSandbox =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteSandbox',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteSandbox);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.ListSandboxesRequest,
 *   !proto.loupe.services.api.ListSandboxesResponse>}
 */
const methodDescriptor_Api_ListSandboxes = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListSandboxes',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.ListSandboxesRequest,
  proto.loupe.services.api.ListSandboxesResponse,
  /**
   * @param {!proto.loupe.services.api.ListSandboxesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.ListSandboxesResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.ListSandboxesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.ListSandboxesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.ListSandboxesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listSandboxes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListSandboxes',
      request,
      metadata || {},
      methodDescriptor_Api_ListSandboxes,
      callback);
};


/**
 * @param {!proto.loupe.services.api.ListSandboxesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.ListSandboxesResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listSandboxes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListSandboxes',
      request,
      metadata || {},
      methodDescriptor_Api_ListSandboxes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest,
 *   !proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse>}
 */
const methodDescriptor_Api_ListCanonicalObjectMappings = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListCanonicalObjectMappings',
  grpc.web.MethodType.UNARY,
  src_services_mapping_mapping_pb.ListCanonicalObjectMappingsRequest,
  src_services_mapping_mapping_pb.ListCanonicalObjectMappingsResponse,
  /**
   * @param {!proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mapping_mapping_pb.ListCanonicalObjectMappingsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listCanonicalObjectMappings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListCanonicalObjectMappings',
      request,
      metadata || {},
      methodDescriptor_Api_ListCanonicalObjectMappings,
      callback);
};


/**
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listCanonicalObjectMappings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListCanonicalObjectMappings',
      request,
      metadata || {},
      methodDescriptor_Api_ListCanonicalObjectMappings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mapping.GetCanonicalObjectMappingRequest,
 *   !proto.loupe.services.mapping.GetCanonicalObjectMappingResponse>}
 */
const methodDescriptor_Api_GetCanonicalObjectMapping = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetCanonicalObjectMapping',
  grpc.web.MethodType.UNARY,
  src_services_mapping_mapping_pb.GetCanonicalObjectMappingRequest,
  src_services_mapping_mapping_pb.GetCanonicalObjectMappingResponse,
  /**
   * @param {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mapping_mapping_pb.GetCanonicalObjectMappingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mapping.GetCanonicalObjectMappingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mapping.GetCanonicalObjectMappingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getCanonicalObjectMapping =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetCanonicalObjectMapping',
      request,
      metadata || {},
      methodDescriptor_Api_GetCanonicalObjectMapping,
      callback);
};


/**
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mapping.GetCanonicalObjectMappingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getCanonicalObjectMapping =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetCanonicalObjectMapping',
      request,
      metadata || {},
      methodDescriptor_Api_GetCanonicalObjectMapping);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest,
 *   !proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse>}
 */
const methodDescriptor_Api_UpsertCanonicalObjectMapping = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertCanonicalObjectMapping',
  grpc.web.MethodType.UNARY,
  src_services_mapping_mapping_pb.UpsertCanonicalObjectMappingRequest,
  src_services_mapping_mapping_pb.UpsertCanonicalObjectMappingResponse,
  /**
   * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mapping_mapping_pb.UpsertCanonicalObjectMappingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertCanonicalObjectMapping =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertCanonicalObjectMapping',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertCanonicalObjectMapping,
      callback);
};


/**
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertCanonicalObjectMapping =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertCanonicalObjectMapping',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertCanonicalObjectMapping);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mapping.ListCanonicalObjectsRequest,
 *   !proto.loupe.services.mapping.ListCanonicalObjectsResponse>}
 */
const methodDescriptor_Api_ListCanonicalObjects = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListCanonicalObjects',
  grpc.web.MethodType.UNARY,
  src_services_mapping_mapping_pb.ListCanonicalObjectsRequest,
  src_services_mapping_mapping_pb.ListCanonicalObjectsResponse,
  /**
   * @param {!proto.loupe.services.mapping.ListCanonicalObjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mapping_mapping_pb.ListCanonicalObjectsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mapping.ListCanonicalObjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mapping.ListCanonicalObjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listCanonicalObjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListCanonicalObjects',
      request,
      metadata || {},
      methodDescriptor_Api_ListCanonicalObjects,
      callback);
};


/**
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mapping.ListCanonicalObjectsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listCanonicalObjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListCanonicalObjects',
      request,
      metadata || {},
      methodDescriptor_Api_ListCanonicalObjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mapping.GetCanonicalObjectRequest,
 *   !proto.loupe.services.mapping.GetCanonicalObjectResponse>}
 */
const methodDescriptor_Api_GetCanonicalObject = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetCanonicalObject',
  grpc.web.MethodType.UNARY,
  src_services_mapping_mapping_pb.GetCanonicalObjectRequest,
  src_services_mapping_mapping_pb.GetCanonicalObjectResponse,
  /**
   * @param {!proto.loupe.services.mapping.GetCanonicalObjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mapping_mapping_pb.GetCanonicalObjectResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mapping.GetCanonicalObjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mapping.GetCanonicalObjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getCanonicalObject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetCanonicalObject',
      request,
      metadata || {},
      methodDescriptor_Api_GetCanonicalObject,
      callback);
};


/**
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mapping.GetCanonicalObjectResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getCanonicalObject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetCanonicalObject',
      request,
      metadata || {},
      methodDescriptor_Api_GetCanonicalObject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest,
 *   !proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse>}
 */
const methodDescriptor_Api_UpsertCanonicalObjectField = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertCanonicalObjectField',
  grpc.web.MethodType.UNARY,
  src_services_mapping_mapping_pb.UpsertCanonicalObjectFieldRequest,
  src_services_mapping_mapping_pb.UpsertCanonicalObjectFieldResponse,
  /**
   * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mapping_mapping_pb.UpsertCanonicalObjectFieldResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertCanonicalObjectField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertCanonicalObjectField',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertCanonicalObjectField,
      callback);
};


/**
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertCanonicalObjectField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertCanonicalObjectField',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertCanonicalObjectField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest,
 *   !proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse>}
 */
const methodDescriptor_Api_DeleteCanonicalObjectField = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DeleteCanonicalObjectField',
  grpc.web.MethodType.UNARY,
  src_services_mapping_mapping_pb.DeleteCanonicalObjectFieldRequest,
  src_services_mapping_mapping_pb.DeleteCanonicalObjectFieldResponse,
  /**
   * @param {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mapping_mapping_pb.DeleteCanonicalObjectFieldResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.deleteCanonicalObjectField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteCanonicalObjectField',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteCanonicalObjectField,
      callback);
};


/**
 * @param {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.deleteCanonicalObjectField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteCanonicalObjectField',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteCanonicalObjectField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest,
 *   !proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse>}
 */
const methodDescriptor_Api_ListCanonicalObjectFieldTypes = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListCanonicalObjectFieldTypes',
  grpc.web.MethodType.UNARY,
  src_services_mapping_mapping_pb.ListCanonicalObjectFieldTypesRequest,
  src_services_mapping_mapping_pb.ListCanonicalObjectFieldTypesResponse,
  /**
   * @param {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mapping_mapping_pb.ListCanonicalObjectFieldTypesResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listCanonicalObjectFieldTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListCanonicalObjectFieldTypes',
      request,
      metadata || {},
      methodDescriptor_Api_ListCanonicalObjectFieldTypes,
      callback);
};


/**
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listCanonicalObjectFieldTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListCanonicalObjectFieldTypes',
      request,
      metadata || {},
      methodDescriptor_Api_ListCanonicalObjectFieldTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest,
 *   !proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse>}
 */
const methodDescriptor_Api_ValidateCanonicalObjectMapping = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ValidateCanonicalObjectMapping',
  grpc.web.MethodType.UNARY,
  src_services_mapping_mapping_pb.ValidateCanonicalObjectMappingRequest,
  src_services_mapping_mapping_pb.ValidateCanonicalObjectMappingResponse,
  /**
   * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mapping_mapping_pb.ValidateCanonicalObjectMappingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.validateCanonicalObjectMapping =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ValidateCanonicalObjectMapping',
      request,
      metadata || {},
      methodDescriptor_Api_ValidateCanonicalObjectMapping,
      callback);
};


/**
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.validateCanonicalObjectMapping =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ValidateCanonicalObjectMapping',
      request,
      metadata || {},
      methodDescriptor_Api_ValidateCanonicalObjectMapping);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest,
 *   !proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse>}
 */
const methodDescriptor_Api_ValidateCanonicalObjectFieldMapping = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ValidateCanonicalObjectFieldMapping',
  grpc.web.MethodType.UNARY,
  src_services_mapping_mapping_pb.ValidateCanonicalObjectFieldMappingRequest,
  src_services_mapping_mapping_pb.ValidateCanonicalObjectFieldMappingResponse,
  /**
   * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mapping_mapping_pb.ValidateCanonicalObjectFieldMappingResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.validateCanonicalObjectFieldMapping =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ValidateCanonicalObjectFieldMapping',
      request,
      metadata || {},
      methodDescriptor_Api_ValidateCanonicalObjectFieldMapping,
      callback);
};


/**
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.validateCanonicalObjectFieldMapping =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ValidateCanonicalObjectFieldMapping',
      request,
      metadata || {},
      methodDescriptor_Api_ValidateCanonicalObjectFieldMapping);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mosaic.GetCanvasRequest,
 *   !proto.loupe.common.canvas.Canvas>}
 */
const methodDescriptor_Api_GetCanvas = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetCanvas',
  grpc.web.MethodType.UNARY,
  src_services_mosaic_mosaic_pb.GetCanvasRequest,
  src_common_canvas_canvas_pb.Canvas,
  /**
   * @param {!proto.loupe.services.mosaic.GetCanvasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_canvas_canvas_pb.Canvas.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mosaic.GetCanvasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.canvas.Canvas)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.canvas.Canvas>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getCanvas =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetCanvas',
      request,
      metadata || {},
      methodDescriptor_Api_GetCanvas,
      callback);
};


/**
 * @param {!proto.loupe.services.mosaic.GetCanvasRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.canvas.Canvas>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getCanvas =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetCanvas',
      request,
      metadata || {},
      methodDescriptor_Api_GetCanvas);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mosaic.GetCanvasTileRequest,
 *   !proto.loupe.common.canvas.CanvasTile>}
 */
const methodDescriptor_Api_GetCanvasTile = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetCanvasTile',
  grpc.web.MethodType.UNARY,
  src_services_mosaic_mosaic_pb.GetCanvasTileRequest,
  src_common_canvas_canvas_tile_pb.CanvasTile,
  /**
   * @param {!proto.loupe.services.mosaic.GetCanvasTileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_canvas_canvas_tile_pb.CanvasTile.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mosaic.GetCanvasTileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.canvas.CanvasTile)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.canvas.CanvasTile>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getCanvasTile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetCanvasTile',
      request,
      metadata || {},
      methodDescriptor_Api_GetCanvasTile,
      callback);
};


/**
 * @param {!proto.loupe.services.mosaic.GetCanvasTileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.canvas.CanvasTile>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getCanvasTile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetCanvasTile',
      request,
      metadata || {},
      methodDescriptor_Api_GetCanvasTile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mosaic.UpsertCanvasRequest,
 *   !proto.loupe.common.canvas.Canvas>}
 */
const methodDescriptor_Api_UpsertCanvas = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertCanvas',
  grpc.web.MethodType.UNARY,
  src_services_mosaic_mosaic_pb.UpsertCanvasRequest,
  src_common_canvas_canvas_pb.Canvas,
  /**
   * @param {!proto.loupe.services.mosaic.UpsertCanvasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_canvas_canvas_pb.Canvas.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mosaic.UpsertCanvasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.canvas.Canvas)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.canvas.Canvas>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertCanvas =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertCanvas',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertCanvas,
      callback);
};


/**
 * @param {!proto.loupe.services.mosaic.UpsertCanvasRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.canvas.Canvas>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertCanvas =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertCanvas',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertCanvas);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mosaic.UpsertCanvasTileRequest,
 *   !proto.loupe.services.mosaic.Empty>}
 */
const methodDescriptor_Api_UpsertCanvasTile = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertCanvasTile',
  grpc.web.MethodType.UNARY,
  src_services_mosaic_mosaic_pb.UpsertCanvasTileRequest,
  src_services_mosaic_mosaic_pb.Empty,
  /**
   * @param {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mosaic_mosaic_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mosaic.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mosaic.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertCanvasTile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertCanvasTile',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertCanvasTile,
      callback);
};


/**
 * @param {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mosaic.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertCanvasTile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertCanvasTile',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertCanvasTile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest,
 *   !proto.loupe.common.realtime.UserQueryParameters>}
 */
const methodDescriptor_Api_UpsertCanvasTileUserQueryParameters = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/UpsertCanvasTileUserQueryParameters',
  grpc.web.MethodType.UNARY,
  src_services_mosaic_mosaic_pb.UpsertCanvasTileUserQueryParametersRequest,
  src_common_realtime_query_pb.UserQueryParameters,
  /**
   * @param {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_common_realtime_query_pb.UserQueryParameters.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.common.realtime.UserQueryParameters)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.common.realtime.UserQueryParameters>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.upsertCanvasTileUserQueryParameters =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertCanvasTileUserQueryParameters',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertCanvasTileUserQueryParameters,
      callback);
};


/**
 * @param {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.common.realtime.UserQueryParameters>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.upsertCanvasTileUserQueryParameters =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/UpsertCanvasTileUserQueryParameters',
      request,
      metadata || {},
      methodDescriptor_Api_UpsertCanvasTileUserQueryParameters);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mosaic.UpsertCanvasTileRequest,
 *   !proto.loupe.services.mosaic.Empty>}
 */
const methodDescriptor_Api_AdjustCanvasTileLayout = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/AdjustCanvasTileLayout',
  grpc.web.MethodType.UNARY,
  src_services_mosaic_mosaic_pb.UpsertCanvasTileRequest,
  src_services_mosaic_mosaic_pb.Empty,
  /**
   * @param {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mosaic_mosaic_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mosaic.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mosaic.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.adjustCanvasTileLayout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/AdjustCanvasTileLayout',
      request,
      metadata || {},
      methodDescriptor_Api_AdjustCanvasTileLayout,
      callback);
};


/**
 * @param {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mosaic.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.adjustCanvasTileLayout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/AdjustCanvasTileLayout',
      request,
      metadata || {},
      methodDescriptor_Api_AdjustCanvasTileLayout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest,
 *   !proto.loupe.services.mosaic.Empty>}
 */
const methodDescriptor_Api_AdjustOrgCanvasTileLayout = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/AdjustOrgCanvasTileLayout',
  grpc.web.MethodType.UNARY,
  src_services_mosaic_mosaic_pb.AdjustOrgCanvasTileLayoutRequest,
  src_services_mosaic_mosaic_pb.Empty,
  /**
   * @param {!proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mosaic_mosaic_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mosaic.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mosaic.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.adjustOrgCanvasTileLayout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/AdjustOrgCanvasTileLayout',
      request,
      metadata || {},
      methodDescriptor_Api_AdjustOrgCanvasTileLayout,
      callback);
};


/**
 * @param {!proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mosaic.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.adjustOrgCanvasTileLayout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/AdjustOrgCanvasTileLayout',
      request,
      metadata || {},
      methodDescriptor_Api_AdjustOrgCanvasTileLayout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mosaic.DeleteCanvasTileRequest,
 *   !proto.loupe.services.mosaic.Empty>}
 */
const methodDescriptor_Api_DeleteCanvasTile = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DeleteCanvasTile',
  grpc.web.MethodType.UNARY,
  src_services_mosaic_mosaic_pb.DeleteCanvasTileRequest,
  src_services_mosaic_mosaic_pb.Empty,
  /**
   * @param {!proto.loupe.services.mosaic.DeleteCanvasTileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mosaic_mosaic_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mosaic.DeleteCanvasTileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mosaic.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mosaic.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.deleteCanvasTile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteCanvasTile',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteCanvasTile,
      callback);
};


/**
 * @param {!proto.loupe.services.mosaic.DeleteCanvasTileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mosaic.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.deleteCanvasTile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteCanvasTile',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteCanvasTile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest,
 *   !proto.loupe.services.mosaic.Empty>}
 */
const methodDescriptor_Api_DeleteCanvasTileUserQueryParameters = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DeleteCanvasTileUserQueryParameters',
  grpc.web.MethodType.UNARY,
  src_services_mosaic_mosaic_pb.DeleteCanvasTileUserQueryParametersRequest,
  src_services_mosaic_mosaic_pb.Empty,
  /**
   * @param {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_mosaic_mosaic_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.mosaic.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.mosaic.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.deleteCanvasTileUserQueryParameters =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteCanvasTileUserQueryParameters',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteCanvasTileUserQueryParameters,
      callback);
};


/**
 * @param {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.mosaic.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.deleteCanvasTileUserQueryParameters =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteCanvasTileUserQueryParameters',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteCanvasTileUserQueryParameters);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.RunRealtimeQueriesRequest,
 *   !proto.loupe.services.api.RunRealtimeQueriesResponse>}
 */
const methodDescriptor_Api_RunRealtimeQueries = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/RunRealtimeQueries',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.RunRealtimeQueriesRequest,
  proto.loupe.services.api.RunRealtimeQueriesResponse,
  /**
   * @param {!proto.loupe.services.api.RunRealtimeQueriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.RunRealtimeQueriesResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.RunRealtimeQueriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.RunRealtimeQueriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.RunRealtimeQueriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.runRealtimeQueries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/RunRealtimeQueries',
      request,
      metadata || {},
      methodDescriptor_Api_RunRealtimeQueries,
      callback);
};


/**
 * @param {!proto.loupe.services.api.RunRealtimeQueriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.RunRealtimeQueriesResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.runRealtimeQueries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/RunRealtimeQueries',
      request,
      metadata || {},
      methodDescriptor_Api_RunRealtimeQueries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest,
 *   !proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse>}
 */
const methodDescriptor_Api_GetObjectMappingsSourceOptions = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetObjectMappingsSourceOptions',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest,
  proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse,
  /**
   * @param {!proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getObjectMappingsSourceOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetObjectMappingsSourceOptions',
      request,
      metadata || {},
      methodDescriptor_Api_GetObjectMappingsSourceOptions,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getObjectMappingsSourceOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetObjectMappingsSourceOptions',
      request,
      metadata || {},
      methodDescriptor_Api_GetObjectMappingsSourceOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest,
 *   !proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse>}
 */
const methodDescriptor_Api_GetObjectReferenceFieldOptions = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetObjectReferenceFieldOptions',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest,
  proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse,
  /**
   * @param {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getObjectReferenceFieldOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetObjectReferenceFieldOptions',
      request,
      metadata || {},
      methodDescriptor_Api_GetObjectReferenceFieldOptions,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getObjectReferenceFieldOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetObjectReferenceFieldOptions',
      request,
      metadata || {},
      methodDescriptor_Api_GetObjectReferenceFieldOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest,
 *   !proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse>}
 */
const methodDescriptor_Api_ResolveObjectReferenceFieldOptions = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ResolveObjectReferenceFieldOptions',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest,
  proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse,
  /**
   * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.resolveObjectReferenceFieldOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ResolveObjectReferenceFieldOptions',
      request,
      metadata || {},
      methodDescriptor_Api_ResolveObjectReferenceFieldOptions,
      callback);
};


/**
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.resolveObjectReferenceFieldOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ResolveObjectReferenceFieldOptions',
      request,
      metadata || {},
      methodDescriptor_Api_ResolveObjectReferenceFieldOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.hotspring.RunQueryObjectDataRequest,
 *   !proto.loupe.services.hotspring.RunQueryObjectDataResponse>}
 */
const methodDescriptor_Api_GetQueryResultDetails = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetQueryResultDetails',
  grpc.web.MethodType.UNARY,
  src_services_hotspring_hotspring_pb.RunQueryObjectDataRequest,
  src_services_hotspring_hotspring_pb.RunQueryObjectDataResponse,
  /**
   * @param {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_hotspring_hotspring_pb.RunQueryObjectDataResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.hotspring.RunQueryObjectDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.hotspring.RunQueryObjectDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getQueryResultDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetQueryResultDetails',
      request,
      metadata || {},
      methodDescriptor_Api_GetQueryResultDetails,
      callback);
};


/**
 * @param {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.hotspring.RunQueryObjectDataResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getQueryResultDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetQueryResultDetails',
      request,
      metadata || {},
      methodDescriptor_Api_GetQueryResultDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.snoop.ListActionsRequest,
 *   !proto.loupe.services.snoop.ListActionsResponse>}
 */
const methodDescriptor_Api_ListActions = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListActions',
  grpc.web.MethodType.UNARY,
  src_services_snoop_snoop_pb.ListActionsRequest,
  src_services_snoop_snoop_pb.ListActionsResponse,
  /**
   * @param {!proto.loupe.services.snoop.ListActionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_snoop_snoop_pb.ListActionsResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.snoop.ListActionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.snoop.ListActionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.snoop.ListActionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listActions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListActions',
      request,
      metadata || {},
      methodDescriptor_Api_ListActions,
      callback);
};


/**
 * @param {!proto.loupe.services.snoop.ListActionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.snoop.ListActionsResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listActions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListActions',
      request,
      metadata || {},
      methodDescriptor_Api_ListActions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.snoop.GetActionRequest,
 *   !proto.loupe.services.snoop.GetActionResponse>}
 */
const methodDescriptor_Api_GetAction = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetAction',
  grpc.web.MethodType.UNARY,
  src_services_snoop_snoop_pb.GetActionRequest,
  src_services_snoop_snoop_pb.GetActionResponse,
  /**
   * @param {!proto.loupe.services.snoop.GetActionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_snoop_snoop_pb.GetActionResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.snoop.GetActionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.snoop.GetActionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.snoop.GetActionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getAction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetAction',
      request,
      metadata || {},
      methodDescriptor_Api_GetAction,
      callback);
};


/**
 * @param {!proto.loupe.services.snoop.GetActionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.snoop.GetActionResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getAction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetAction',
      request,
      metadata || {},
      methodDescriptor_Api_GetAction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.snoop.SaveFilterRequest,
 *   !proto.loupe.services.snoop.Empty>}
 */
const methodDescriptor_Api_SaveFilter = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/SaveFilter',
  grpc.web.MethodType.UNARY,
  src_services_snoop_snoop_pb.SaveFilterRequest,
  src_services_snoop_snoop_pb.Empty,
  /**
   * @param {!proto.loupe.services.snoop.SaveFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_snoop_snoop_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.snoop.SaveFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.snoop.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.snoop.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.saveFilter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/SaveFilter',
      request,
      metadata || {},
      methodDescriptor_Api_SaveFilter,
      callback);
};


/**
 * @param {!proto.loupe.services.snoop.SaveFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.snoop.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.saveFilter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/SaveFilter',
      request,
      metadata || {},
      methodDescriptor_Api_SaveFilter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.snoop.ListFiltersRequest,
 *   !proto.loupe.services.snoop.ListFiltersResponse>}
 */
const methodDescriptor_Api_ListFilters = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/ListFilters',
  grpc.web.MethodType.UNARY,
  src_services_snoop_snoop_pb.ListFiltersRequest,
  src_services_snoop_snoop_pb.ListFiltersResponse,
  /**
   * @param {!proto.loupe.services.snoop.ListFiltersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_snoop_snoop_pb.ListFiltersResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.snoop.ListFiltersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.snoop.ListFiltersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.snoop.ListFiltersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.listFilters =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/ListFilters',
      request,
      metadata || {},
      methodDescriptor_Api_ListFilters,
      callback);
};


/**
 * @param {!proto.loupe.services.snoop.ListFiltersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.snoop.ListFiltersResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.listFilters =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/ListFilters',
      request,
      metadata || {},
      methodDescriptor_Api_ListFilters);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.snoop.DeleteFilterRequest,
 *   !proto.loupe.services.snoop.Empty>}
 */
const methodDescriptor_Api_DeleteFilter = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/DeleteFilter',
  grpc.web.MethodType.UNARY,
  src_services_snoop_snoop_pb.DeleteFilterRequest,
  src_services_snoop_snoop_pb.Empty,
  /**
   * @param {!proto.loupe.services.snoop.DeleteFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  src_services_snoop_snoop_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.loupe.services.snoop.DeleteFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.snoop.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.snoop.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.deleteFilter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteFilter',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteFilter,
      callback);
};


/**
 * @param {!proto.loupe.services.snoop.DeleteFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.snoop.Empty>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.deleteFilter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/DeleteFilter',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteFilter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.loupe.services.api.GetChurnAndContractionRequest,
 *   !proto.loupe.services.api.GetChurnAndContractionResponse>}
 */
const methodDescriptor_Api_GetChurnAndContraction = new grpc.web.MethodDescriptor(
  '/loupe.services.api.Api/GetChurnAndContraction',
  grpc.web.MethodType.UNARY,
  proto.loupe.services.api.GetChurnAndContractionRequest,
  proto.loupe.services.api.GetChurnAndContractionResponse,
  /**
   * @param {!proto.loupe.services.api.GetChurnAndContractionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.loupe.services.api.GetChurnAndContractionResponse.deserializeBinary
);


/**
 * @param {!proto.loupe.services.api.GetChurnAndContractionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.loupe.services.api.GetChurnAndContractionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.loupe.services.api.GetChurnAndContractionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.loupe.services.api.ApiClient.prototype.getChurnAndContraction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/loupe.services.api.Api/GetChurnAndContraction',
      request,
      metadata || {},
      methodDescriptor_Api_GetChurnAndContraction,
      callback);
};


/**
 * @param {!proto.loupe.services.api.GetChurnAndContractionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.loupe.services.api.GetChurnAndContractionResponse>}
 *     Promise that resolves to the response
 */
proto.loupe.services.api.ApiPromiseClient.prototype.getChurnAndContraction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/loupe.services.api.Api/GetChurnAndContraction',
      request,
      metadata || {},
      methodDescriptor_Api_GetChurnAndContraction);
};


module.exports = proto.loupe.services.api;

