import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcCallback, useGrpcEffect } from '../grpc'
import { toGetPipelineSettingRequest, toGetWritebackSettingRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useAuth } from './auth'

const DealGridSettingsContext = React.createContext()

export function DealGridSettingsProvider({ children }) {
  const { tenantId } = useAuth()

  const { notifyError } = useNotification()

  const [isFetching, setIsFetching] = useState(true)
  const [fetchError, setFetchError] = useState(false)
  const [key, setKey] = useState(guid())
  const [settings, setSettings] = useState(undefined)

  const getWritebackSettingRequest = useGrpcCallback({
    onError: () => {
      setIsFetching(false)
      setFetchError(true)
      notifyError('Error fetching Deal Grid settings!')
    },
    onSuccess: (obj) => {
      const { enabled, pipelineEnabled } = obj
      setSettings((settings) => ({ ...settings, writebackEnabled: !!enabled && !!pipelineEnabled }))
      setIsFetching(false)
      setFetchError(false)
    },
    onFetch: () => {
      setIsFetching(true)
      setFetchError(false)
    },
    grpcMethod: 'getWritebackSetting',
    debug: false,
  }, [])

  const getWritebackSetting = useCallback(() => {
    const request = toGetWritebackSettingRequest({ tenantId })
    getWritebackSettingRequest(request)
  }, [tenantId, toGetWritebackSettingRequest, getWritebackSettingRequest])

  useGrpcEffect({
    request: toGetPipelineSettingRequest({
      tenantId,
    }),
    onError: () => {
      setIsFetching(false)
      setFetchError(true)
      notifyError('Error fetching Deal Grid settings!')
    },
    onSuccess: (obj) => {
      setSettings(obj.setting)
      getWritebackSetting()
    },
    onFetch: () => {
      setIsFetching(true)
      setFetchError(false)
    },
    grpcMethod: 'getPipelineSetting',
    debug: false,
  }, [tenantId, key, getWritebackSetting])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      fetchError,
      settings,
      setSettings,
      key,
      invalidate,
    }
  }, [isFetching, fetchError, settings])

  return <DealGridSettingsContext.Provider value={contextValue}>{children}</DealGridSettingsContext.Provider>
}

export function useDealGridSettings() {
  const context = React.useContext(DealGridSettingsContext)
  if (context === undefined) {
    throw new Error('useDealGridSettings must be used within a DealGridSettingsProvider')
  }
  return context
}
