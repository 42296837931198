import { authorizeGoogle, authorizeOutreach, authorizeSalesforce, getTokenSilently, getIdTokenClaims, setAccessToken, setClaims } from '../../lib/auth0'
import { captureException, setUser } from '../../lib/sentry'
import { has } from 'lodash'
import { Redirect, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import { useRoutes } from '../../context/routes'
import LoginTemplate from './loginTemplate'
import queryString from 'query-string'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

const Login = (props) => {
  const { setIsAuthenticated } = useAuth()

  const location = useLocation()

  const { routes, baseRoutes } = useRoutes()

  const [isCheckingSession, setIsCheckingSession] = useState(true)
  const [isAuthenticatedInternal, setIsAuthenticatedInternal] = useState(false)

  const search = queryString.parse(location.search)

  const authRedirectUri = useMemo(() => {
    let uri = `${window.location.origin}${baseRoutes.authRedirect}`

    if (has(search, 'redirect')) {
      const sandbox = has(search, 'sandbox') ? `&sandbox=${search.sandbox}` : ''
      const redirect = `${search.redirect}${sandbox}${location.hash || ''}`.replace(/\|/g, '%7C')
      uri += `?redirect=${redirect}`
    }
    return uri
  }, [location, search])

  const authenticatedRedirectUri = useMemo(() => {
    const hash = encodeURIComponent(location.hash)
    let redirect = ''

    if (search?.sandbox) {
      redirect += `/${search.sandbox}`
    }

    if (search?.redirect) {
      redirect += `${search.redirect}`
    }

    if (redirect !== '') {
      redirect += hash
      return redirect
    }

    return routes.default
  }, [location, search, routes])

  const onOutreachClick = useCallback(() => {
    authorizeOutreach(authRedirectUri)
  }, [authRedirectUri])

  const onSalesforceClick = useCallback(() => {
    authorizeSalesforce(authRedirectUri)
  }, [authRedirectUri])

  const onGoogleClick = useCallback(() => {
    authorizeGoogle(authRedirectUri)
  }, [authRedirectUri])

  useEffect(() => {
    getTokenSilently({
      onSuccess: ({ accessToken }) => {
        getIdTokenClaims({
          onSuccess: ({ idTokenClaims }) => {
            // console.log('getTokenSilently', accessToken)
            setAccessToken(accessToken)

            // console.log('idTokenClaims', idTokenClaims)
            setClaims(idTokenClaims)

            setUser(idTokenClaims)
            setIsAuthenticatedInternal(true)
            setIsAuthenticated(true)
            setIsCheckingSession(false)
          },
          onError: (err) => {
            setIsAuthenticated(false)
            setIsCheckingSession(false)
            captureException(err, undefined, 'getIdTokenClaims')
          },
        })
      },
      onError: (err) => {
        setIsAuthenticated(false)
        setIsCheckingSession(false)
      },
    })
  }, [])

  const renderContent = useCallback(() => {
    if (isCheckingSession) {
      return <></>
    } else if (isAuthenticatedInternal) {
      return <Redirect to={authenticatedRedirectUri} />
    } else {
      return (
        <LoginTemplate>
          <div className="text-left px-18">
            <div className="text-size-36px text-color-151d49 font-bold">Welcome!</div>
            <div className="text-size-16px text-color-4e5d7e font-normal">Focus on being the best manager you can be - Outreach Commit takes care of the rest.</div>
            <div className="flex flex-wrap">
              <button onClick={onOutreachClick} className="flex flex-no-wrap items-center bg-color-5951FF rounded-full px-5 py-3 mt-10 mr-4 focus:outline-none">
                <span className="text-color-ffffff text-size-14px font-normal whitespace-nowrap">Sign in with Outreach</span>
              </button>
              <button onClick={onSalesforceClick} className="flex flex-no-wrap items-center bg-color-5951FF rounded-full px-5 py-3 mt-10 mr-4 focus:outline-none">
                <span className="text-color-ffffff text-size-14px font-normal whitespace-nowrap">Sign in with Salesforce</span>
              </button>
              <button onClick={onGoogleClick} className="flex flex-no-wrap items-center bg-color-5951FF rounded-full px-5 py-3 mt-10 mr-4 focus:outline-none">
                <span className="text-color-ffffff text-size-14px font-normal whitespace-nowrap">Sign in with Google</span>
              </button>
            </div>
          </div>
        </LoginTemplate>
      )
    }
  }, [isCheckingSession, isAuthenticatedInternal])

  return renderContent()
}

export default Login
