// source: src/common/signal/signal.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_query_query_pb = require('../../../src/common/query/query_pb.js');
goog.object.extend(proto, src_common_query_query_pb);
var src_common_setting_notification_notification_pb = require('../../../src/common/setting/notification/notification_pb.js');
goog.object.extend(proto, src_common_setting_notification_notification_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_signal_relation_relation_pb = require('../../../src/common/signal/relation/relation_pb.js');
goog.object.extend(proto, src_common_signal_relation_relation_pb);
var src_common_signal_interaction_interaction_pb = require('../../../src/common/signal/interaction/interaction_pb.js');
goog.object.extend(proto, src_common_signal_interaction_interaction_pb);
var src_common_signal_definition_definition_pb = require('../../../src/common/signal/definition/definition_pb.js');
goog.object.extend(proto, src_common_signal_definition_definition_pb);
var src_common_signal_result_result_pb = require('../../../src/common/signal/result/result_pb.js');
goog.object.extend(proto, src_common_signal_result_result_pb);
var src_common_template_presentation_presentation_pb = require('../../../src/common/template/presentation/presentation_pb.js');
goog.object.extend(proto, src_common_template_presentation_presentation_pb);
goog.exportSymbol('proto.loupe.common.signal.Signal', null, global);
goog.exportSymbol('proto.loupe.common.signal.SignalList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.signal.Signal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.signal.Signal.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.signal.Signal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.signal.Signal.displayName = 'proto.loupe.common.signal.Signal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.signal.SignalList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.signal.SignalList.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.signal.SignalList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.signal.SignalList.displayName = 'proto.loupe.common.signal.SignalList';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.signal.Signal.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.signal.Signal.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.signal.Signal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.signal.Signal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.signal.Signal.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    importance: jspb.Message.getFieldWithDefault(msg, 4, 0),
    definition: (f = msg.getDefinition()) && src_common_signal_definition_definition_pb.Definition.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    interactions: (f = msg.getInteractions()) && src_common_signal_interaction_interaction_pb.Interactions.toObject(includeInstance, f),
    query: (f = msg.getQuery()) && src_common_query_query_pb.Query.toObject(includeInstance, f),
    result: (f = msg.getResult()) && src_common_signal_result_result_pb.Result.toObject(includeInstance, f),
    relations: (f = msg.getRelations()) && src_common_signal_relation_relation_pb.Relations.toObject(includeInstance, f),
    presentation: (f = msg.getPresentation()) && src_common_template_presentation_presentation_pb.Presentation.toObject(includeInstance, f),
    notificationSetting: (f = msg.getNotificationSetting()) && src_common_setting_notification_notification_pb.Notification.toObject(includeInstance, f),
    siblingsList: jspb.Message.toObjectList(msg.getSiblingsList(),
    proto.loupe.common.signal.Signal.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.signal.Signal}
 */
proto.loupe.common.signal.Signal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.signal.Signal;
  return proto.loupe.common.signal.Signal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.signal.Signal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.signal.Signal}
 */
proto.loupe.common.signal.Signal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImportance(value);
      break;
    case 5:
      var value = new src_common_signal_definition_definition_pb.Definition;
      reader.readMessage(value,src_common_signal_definition_definition_pb.Definition.deserializeBinaryFromReader);
      msg.setDefinition(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = new src_common_signal_interaction_interaction_pb.Interactions;
      reader.readMessage(value,src_common_signal_interaction_interaction_pb.Interactions.deserializeBinaryFromReader);
      msg.setInteractions(value);
      break;
    case 8:
      var value = new src_common_query_query_pb.Query;
      reader.readMessage(value,src_common_query_query_pb.Query.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    case 9:
      var value = new src_common_signal_result_result_pb.Result;
      reader.readMessage(value,src_common_signal_result_result_pb.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 10:
      var value = new src_common_signal_relation_relation_pb.Relations;
      reader.readMessage(value,src_common_signal_relation_relation_pb.Relations.deserializeBinaryFromReader);
      msg.setRelations(value);
      break;
    case 11:
      var value = new src_common_template_presentation_presentation_pb.Presentation;
      reader.readMessage(value,src_common_template_presentation_presentation_pb.Presentation.deserializeBinaryFromReader);
      msg.setPresentation(value);
      break;
    case 12:
      var value = new src_common_setting_notification_notification_pb.Notification;
      reader.readMessage(value,src_common_setting_notification_notification_pb.Notification.deserializeBinaryFromReader);
      msg.setNotificationSetting(value);
      break;
    case 13:
      var value = new proto.loupe.common.signal.Signal;
      reader.readMessage(value,proto.loupe.common.signal.Signal.deserializeBinaryFromReader);
      msg.addSiblings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.signal.Signal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.signal.Signal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.signal.Signal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.signal.Signal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImportance();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDefinition();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_signal_definition_definition_pb.Definition.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInteractions();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      src_common_signal_interaction_interaction_pb.Interactions.serializeBinaryToWriter
    );
  }
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      src_common_query_query_pb.Query.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      src_common_signal_result_result_pb.Result.serializeBinaryToWriter
    );
  }
  f = message.getRelations();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      src_common_signal_relation_relation_pb.Relations.serializeBinaryToWriter
    );
  }
  f = message.getPresentation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      src_common_template_presentation_presentation_pb.Presentation.serializeBinaryToWriter
    );
  }
  f = message.getNotificationSetting();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      src_common_setting_notification_notification_pb.Notification.serializeBinaryToWriter
    );
  }
  f = message.getSiblingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.loupe.common.signal.Signal.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.signal.Signal.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.loupe.common.signal.Signal.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tx_id = 3;
 * @return {string}
 */
proto.loupe.common.signal.Signal.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 importance = 4;
 * @return {number}
 */
proto.loupe.common.signal.Signal.prototype.getImportance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.setImportance = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional definition.Definition definition = 5;
 * @return {?proto.loupe.common.signal.definition.Definition}
 */
proto.loupe.common.signal.Signal.prototype.getDefinition = function() {
  return /** @type{?proto.loupe.common.signal.definition.Definition} */ (
    jspb.Message.getWrapperField(this, src_common_signal_definition_definition_pb.Definition, 5));
};


/**
 * @param {?proto.loupe.common.signal.definition.Definition|undefined} value
 * @return {!proto.loupe.common.signal.Signal} returns this
*/
proto.loupe.common.signal.Signal.prototype.setDefinition = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.clearDefinition = function() {
  return this.setDefinition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.signal.Signal.prototype.hasDefinition = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.signal.Signal.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.signal.Signal} returns this
*/
proto.loupe.common.signal.Signal.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.signal.Signal.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional interaction.Interactions interactions = 7;
 * @return {?proto.loupe.common.signal.interaction.Interactions}
 */
proto.loupe.common.signal.Signal.prototype.getInteractions = function() {
  return /** @type{?proto.loupe.common.signal.interaction.Interactions} */ (
    jspb.Message.getWrapperField(this, src_common_signal_interaction_interaction_pb.Interactions, 7));
};


/**
 * @param {?proto.loupe.common.signal.interaction.Interactions|undefined} value
 * @return {!proto.loupe.common.signal.Signal} returns this
*/
proto.loupe.common.signal.Signal.prototype.setInteractions = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.clearInteractions = function() {
  return this.setInteractions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.signal.Signal.prototype.hasInteractions = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional loupe.common.query.Query query = 8;
 * @return {?proto.loupe.common.query.Query}
 */
proto.loupe.common.signal.Signal.prototype.getQuery = function() {
  return /** @type{?proto.loupe.common.query.Query} */ (
    jspb.Message.getWrapperField(this, src_common_query_query_pb.Query, 8));
};


/**
 * @param {?proto.loupe.common.query.Query|undefined} value
 * @return {!proto.loupe.common.signal.Signal} returns this
*/
proto.loupe.common.signal.Signal.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.signal.Signal.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional result.Result result = 9;
 * @return {?proto.loupe.common.signal.result.Result}
 */
proto.loupe.common.signal.Signal.prototype.getResult = function() {
  return /** @type{?proto.loupe.common.signal.result.Result} */ (
    jspb.Message.getWrapperField(this, src_common_signal_result_result_pb.Result, 9));
};


/**
 * @param {?proto.loupe.common.signal.result.Result|undefined} value
 * @return {!proto.loupe.common.signal.Signal} returns this
*/
proto.loupe.common.signal.Signal.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.signal.Signal.prototype.hasResult = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional relation.Relations relations = 10;
 * @return {?proto.loupe.common.signal.relation.Relations}
 */
proto.loupe.common.signal.Signal.prototype.getRelations = function() {
  return /** @type{?proto.loupe.common.signal.relation.Relations} */ (
    jspb.Message.getWrapperField(this, src_common_signal_relation_relation_pb.Relations, 10));
};


/**
 * @param {?proto.loupe.common.signal.relation.Relations|undefined} value
 * @return {!proto.loupe.common.signal.Signal} returns this
*/
proto.loupe.common.signal.Signal.prototype.setRelations = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.clearRelations = function() {
  return this.setRelations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.signal.Signal.prototype.hasRelations = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional loupe.common.template.presentation.Presentation presentation = 11;
 * @return {?proto.loupe.common.template.presentation.Presentation}
 */
proto.loupe.common.signal.Signal.prototype.getPresentation = function() {
  return /** @type{?proto.loupe.common.template.presentation.Presentation} */ (
    jspb.Message.getWrapperField(this, src_common_template_presentation_presentation_pb.Presentation, 11));
};


/**
 * @param {?proto.loupe.common.template.presentation.Presentation|undefined} value
 * @return {!proto.loupe.common.signal.Signal} returns this
*/
proto.loupe.common.signal.Signal.prototype.setPresentation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.clearPresentation = function() {
  return this.setPresentation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.signal.Signal.prototype.hasPresentation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional loupe.common.setting.notification.Notification notification_setting = 12;
 * @return {?proto.loupe.common.setting.notification.Notification}
 */
proto.loupe.common.signal.Signal.prototype.getNotificationSetting = function() {
  return /** @type{?proto.loupe.common.setting.notification.Notification} */ (
    jspb.Message.getWrapperField(this, src_common_setting_notification_notification_pb.Notification, 12));
};


/**
 * @param {?proto.loupe.common.setting.notification.Notification|undefined} value
 * @return {!proto.loupe.common.signal.Signal} returns this
*/
proto.loupe.common.signal.Signal.prototype.setNotificationSetting = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.clearNotificationSetting = function() {
  return this.setNotificationSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.signal.Signal.prototype.hasNotificationSetting = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated Signal siblings = 13;
 * @return {!Array<!proto.loupe.common.signal.Signal>}
 */
proto.loupe.common.signal.Signal.prototype.getSiblingsList = function() {
  return /** @type{!Array<!proto.loupe.common.signal.Signal>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.signal.Signal, 13));
};


/**
 * @param {!Array<!proto.loupe.common.signal.Signal>} value
 * @return {!proto.loupe.common.signal.Signal} returns this
*/
proto.loupe.common.signal.Signal.prototype.setSiblingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.loupe.common.signal.Signal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.signal.Signal}
 */
proto.loupe.common.signal.Signal.prototype.addSiblings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.loupe.common.signal.Signal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.signal.Signal} returns this
 */
proto.loupe.common.signal.Signal.prototype.clearSiblingsList = function() {
  return this.setSiblingsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.signal.SignalList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.signal.SignalList.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.signal.SignalList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.signal.SignalList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.signal.SignalList.toObject = function(includeInstance, msg) {
  var f, obj = {
    signalsList: jspb.Message.toObjectList(msg.getSignalsList(),
    proto.loupe.common.signal.Signal.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.signal.SignalList}
 */
proto.loupe.common.signal.SignalList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.signal.SignalList;
  return proto.loupe.common.signal.SignalList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.signal.SignalList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.signal.SignalList}
 */
proto.loupe.common.signal.SignalList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.signal.Signal;
      reader.readMessage(value,proto.loupe.common.signal.Signal.deserializeBinaryFromReader);
      msg.addSignals(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.signal.SignalList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.signal.SignalList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.signal.SignalList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.signal.SignalList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.signal.Signal.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Signal signals = 1;
 * @return {!Array<!proto.loupe.common.signal.Signal>}
 */
proto.loupe.common.signal.SignalList.prototype.getSignalsList = function() {
  return /** @type{!Array<!proto.loupe.common.signal.Signal>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.signal.Signal, 1));
};


/**
 * @param {!Array<!proto.loupe.common.signal.Signal>} value
 * @return {!proto.loupe.common.signal.SignalList} returns this
*/
proto.loupe.common.signal.SignalList.prototype.setSignalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.signal.Signal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.signal.Signal}
 */
proto.loupe.common.signal.SignalList.prototype.addSignals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.signal.Signal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.signal.SignalList} returns this
 */
proto.loupe.common.signal.SignalList.prototype.clearSignalsList = function() {
  return this.setSignalsList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.loupe.common.signal.SignalList.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.signal.SignalList} returns this
 */
proto.loupe.common.signal.SignalList.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.loupe.common.signal);
