// source: src/common/search/item/item.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_search_enum_enum_pb = require('../../../../src/common/search/enum/enum_pb.js');
goog.object.extend(proto, src_common_search_enum_enum_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.loupe.common.search.item.SearchItem', null, global);
goog.exportSymbol('proto.loupe.common.search.item.SearchItemField', null, global);
goog.exportSymbol('proto.loupe.common.search.item.SearchItemFieldActual', null, global);
goog.exportSymbol('proto.loupe.common.search.item.SearchItemFieldVersion', null, global);
goog.exportSymbol('proto.loupe.common.search.item.SearchItems', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.search.item.SearchItems = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.search.item.SearchItems.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.search.item.SearchItems, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.search.item.SearchItems.displayName = 'proto.loupe.common.search.item.SearchItems';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.search.item.SearchItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.search.item.SearchItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.search.item.SearchItem.displayName = 'proto.loupe.common.search.item.SearchItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.search.item.SearchItemField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.search.item.SearchItemField.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.search.item.SearchItemField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.search.item.SearchItemField.displayName = 'proto.loupe.common.search.item.SearchItemField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.search.item.SearchItemFieldActual = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.search.item.SearchItemFieldActual, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.search.item.SearchItemFieldActual.displayName = 'proto.loupe.common.search.item.SearchItemFieldActual';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.search.item.SearchItemFieldVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.search.item.SearchItemFieldVersion.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.search.item.SearchItemFieldVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.search.item.SearchItemFieldVersion.displayName = 'proto.loupe.common.search.item.SearchItemFieldVersion';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.search.item.SearchItems.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.search.item.SearchItems.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.search.item.SearchItems.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.search.item.SearchItems} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.item.SearchItems.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.loupe.common.search.item.SearchItem.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    objectName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sumAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.search.item.SearchItems}
 */
proto.loupe.common.search.item.SearchItems.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.search.item.SearchItems;
  return proto.loupe.common.search.item.SearchItems.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.search.item.SearchItems} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.search.item.SearchItems}
 */
proto.loupe.common.search.item.SearchItems.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.search.item.SearchItem;
      reader.readMessage(value,proto.loupe.common.search.item.SearchItem.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSumAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.search.item.SearchItems.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.search.item.SearchItems.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.search.item.SearchItems} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.item.SearchItems.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.search.item.SearchItem.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSumAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * repeated SearchItem values = 1;
 * @return {!Array<!proto.loupe.common.search.item.SearchItem>}
 */
proto.loupe.common.search.item.SearchItems.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.loupe.common.search.item.SearchItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.search.item.SearchItem, 1));
};


/**
 * @param {!Array<!proto.loupe.common.search.item.SearchItem>} value
 * @return {!proto.loupe.common.search.item.SearchItems} returns this
*/
proto.loupe.common.search.item.SearchItems.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.search.item.SearchItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.search.item.SearchItem}
 */
proto.loupe.common.search.item.SearchItems.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.search.item.SearchItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.search.item.SearchItems} returns this
 */
proto.loupe.common.search.item.SearchItems.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.loupe.common.search.item.SearchItems.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.search.item.SearchItems} returns this
 */
proto.loupe.common.search.item.SearchItems.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string object_name = 3;
 * @return {string}
 */
proto.loupe.common.search.item.SearchItems.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.search.item.SearchItems} returns this
 */
proto.loupe.common.search.item.SearchItems.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double sum_amount = 4;
 * @return {number}
 */
proto.loupe.common.search.item.SearchItems.prototype.getSumAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.search.item.SearchItems} returns this
 */
proto.loupe.common.search.item.SearchItems.prototype.setSumAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.search.item.SearchItem.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.search.item.SearchItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.search.item.SearchItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.item.SearchItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsMap: (f = msg.getFieldsMap()) ? f.toObject(includeInstance, proto.loupe.common.search.item.SearchItemField.toObject) : [],
    lastModifiedDateString: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.search.item.SearchItem}
 */
proto.loupe.common.search.item.SearchItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.search.item.SearchItem;
  return proto.loupe.common.search.item.SearchItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.search.item.SearchItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.search.item.SearchItem}
 */
proto.loupe.common.search.item.SearchItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = msg.getFieldsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.common.search.item.SearchItemField.deserializeBinaryFromReader, "", new proto.loupe.common.search.item.SearchItemField());
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastModifiedDateString(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.search.item.SearchItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.search.item.SearchItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.search.item.SearchItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.item.SearchItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.common.search.item.SearchItemField.serializeBinaryToWriter);
  }
  f = message.getLastModifiedDateString();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.search.item.SearchItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.search.item.SearchItem} returns this
 */
proto.loupe.common.search.item.SearchItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, SearchItemField> fields = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.common.search.item.SearchItemField>}
 */
proto.loupe.common.search.item.SearchItem.prototype.getFieldsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.common.search.item.SearchItemField>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.loupe.common.search.item.SearchItemField));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.search.item.SearchItem} returns this
 */
proto.loupe.common.search.item.SearchItem.prototype.clearFieldsMap = function() {
  this.getFieldsMap().clear();
  return this;};


/**
 * optional string last_modified_date_string = 3;
 * @return {string}
 */
proto.loupe.common.search.item.SearchItem.prototype.getLastModifiedDateString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.search.item.SearchItem} returns this
 */
proto.loupe.common.search.item.SearchItem.prototype.setLastModifiedDateString = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.search.item.SearchItemField.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.search.item.SearchItemField.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.search.item.SearchItemField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.search.item.SearchItemField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.item.SearchItemField.toObject = function(includeInstance, msg) {
  var f, obj = {
    instancesList: jspb.Message.toObjectList(msg.getInstancesList(),
    proto.loupe.common.search.item.SearchItemFieldActual.toObject, includeInstance),
    versionsList: jspb.Message.toObjectList(msg.getVersionsList(),
    proto.loupe.common.search.item.SearchItemFieldVersion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.search.item.SearchItemField}
 */
proto.loupe.common.search.item.SearchItemField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.search.item.SearchItemField;
  return proto.loupe.common.search.item.SearchItemField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.search.item.SearchItemField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.search.item.SearchItemField}
 */
proto.loupe.common.search.item.SearchItemField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.search.item.SearchItemFieldActual;
      reader.readMessage(value,proto.loupe.common.search.item.SearchItemFieldActual.deserializeBinaryFromReader);
      msg.addInstances(value);
      break;
    case 2:
      var value = new proto.loupe.common.search.item.SearchItemFieldVersion;
      reader.readMessage(value,proto.loupe.common.search.item.SearchItemFieldVersion.deserializeBinaryFromReader);
      msg.addVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.search.item.SearchItemField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.search.item.SearchItemField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.search.item.SearchItemField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.item.SearchItemField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.search.item.SearchItemFieldActual.serializeBinaryToWriter
    );
  }
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.common.search.item.SearchItemFieldVersion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SearchItemFieldActual instances = 1;
 * @return {!Array<!proto.loupe.common.search.item.SearchItemFieldActual>}
 */
proto.loupe.common.search.item.SearchItemField.prototype.getInstancesList = function() {
  return /** @type{!Array<!proto.loupe.common.search.item.SearchItemFieldActual>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.search.item.SearchItemFieldActual, 1));
};


/**
 * @param {!Array<!proto.loupe.common.search.item.SearchItemFieldActual>} value
 * @return {!proto.loupe.common.search.item.SearchItemField} returns this
*/
proto.loupe.common.search.item.SearchItemField.prototype.setInstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.search.item.SearchItemFieldActual=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.search.item.SearchItemFieldActual}
 */
proto.loupe.common.search.item.SearchItemField.prototype.addInstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.search.item.SearchItemFieldActual, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.search.item.SearchItemField} returns this
 */
proto.loupe.common.search.item.SearchItemField.prototype.clearInstancesList = function() {
  return this.setInstancesList([]);
};


/**
 * repeated SearchItemFieldVersion versions = 2;
 * @return {!Array<!proto.loupe.common.search.item.SearchItemFieldVersion>}
 */
proto.loupe.common.search.item.SearchItemField.prototype.getVersionsList = function() {
  return /** @type{!Array<!proto.loupe.common.search.item.SearchItemFieldVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.search.item.SearchItemFieldVersion, 2));
};


/**
 * @param {!Array<!proto.loupe.common.search.item.SearchItemFieldVersion>} value
 * @return {!proto.loupe.common.search.item.SearchItemField} returns this
*/
proto.loupe.common.search.item.SearchItemField.prototype.setVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.search.item.SearchItemFieldVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.search.item.SearchItemFieldVersion}
 */
proto.loupe.common.search.item.SearchItemField.prototype.addVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.search.item.SearchItemFieldVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.search.item.SearchItemField} returns this
 */
proto.loupe.common.search.item.SearchItemField.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.search.item.SearchItemFieldActual.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.search.item.SearchItemFieldActual} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.item.SearchItemFieldActual.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    source: jspb.Message.getFieldWithDefault(msg, 3, ""),
    value: msg.getValue_asB64(),
    displayValue: msg.getDisplayValue_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.search.item.SearchItemFieldActual}
 */
proto.loupe.common.search.item.SearchItemFieldActual.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.search.item.SearchItemFieldActual;
  return proto.loupe.common.search.item.SearchItemFieldActual.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.search.item.SearchItemFieldActual} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.search.item.SearchItemFieldActual}
 */
proto.loupe.common.search.item.SearchItemFieldActual.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.search.enum.SearchFieldType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDisplayValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.search.item.SearchItemFieldActual.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.search.item.SearchItemFieldActual} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.item.SearchItemFieldActual.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getDisplayValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.search.item.SearchItemFieldActual} returns this
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.search.enum.SearchFieldType type = 2;
 * @return {!proto.loupe.common.search.enum.SearchFieldType}
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.getType = function() {
  return /** @type {!proto.loupe.common.search.enum.SearchFieldType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.search.enum.SearchFieldType} value
 * @return {!proto.loupe.common.search.item.SearchItemFieldActual} returns this
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string source = 3;
 * @return {string}
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.search.item.SearchItemFieldActual} returns this
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bytes value = 4;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.getValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes value = 4;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.common.search.item.SearchItemFieldActual} returns this
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional bytes display_value = 5;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.getDisplayValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes display_value = 5;
 * This is a type-conversion wrapper around `getDisplayValue()`
 * @return {string}
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.getDisplayValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDisplayValue()));
};


/**
 * optional bytes display_value = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDisplayValue()`
 * @return {!Uint8Array}
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.getDisplayValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDisplayValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.common.search.item.SearchItemFieldActual} returns this
 */
proto.loupe.common.search.item.SearchItemFieldActual.prototype.setDisplayValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.search.item.SearchItemFieldVersion.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.search.item.SearchItemFieldVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.search.item.SearchItemFieldVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.search.item.SearchItemFieldVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.item.SearchItemFieldVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    instancesList: jspb.Message.toObjectList(msg.getInstancesList(),
    proto.loupe.common.search.item.SearchItemFieldActual.toObject, includeInstance),
    ts: (f = msg.getTs()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.search.item.SearchItemFieldVersion}
 */
proto.loupe.common.search.item.SearchItemFieldVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.search.item.SearchItemFieldVersion;
  return proto.loupe.common.search.item.SearchItemFieldVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.search.item.SearchItemFieldVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.search.item.SearchItemFieldVersion}
 */
proto.loupe.common.search.item.SearchItemFieldVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.search.item.SearchItemFieldActual;
      reader.readMessage(value,proto.loupe.common.search.item.SearchItemFieldActual.deserializeBinaryFromReader);
      msg.addInstances(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.search.item.SearchItemFieldVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.search.item.SearchItemFieldVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.search.item.SearchItemFieldVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.item.SearchItemFieldVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.search.item.SearchItemFieldActual.serializeBinaryToWriter
    );
  }
  f = message.getTs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SearchItemFieldActual instances = 1;
 * @return {!Array<!proto.loupe.common.search.item.SearchItemFieldActual>}
 */
proto.loupe.common.search.item.SearchItemFieldVersion.prototype.getInstancesList = function() {
  return /** @type{!Array<!proto.loupe.common.search.item.SearchItemFieldActual>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.search.item.SearchItemFieldActual, 1));
};


/**
 * @param {!Array<!proto.loupe.common.search.item.SearchItemFieldActual>} value
 * @return {!proto.loupe.common.search.item.SearchItemFieldVersion} returns this
*/
proto.loupe.common.search.item.SearchItemFieldVersion.prototype.setInstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.search.item.SearchItemFieldActual=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.search.item.SearchItemFieldActual}
 */
proto.loupe.common.search.item.SearchItemFieldVersion.prototype.addInstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.search.item.SearchItemFieldActual, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.search.item.SearchItemFieldVersion} returns this
 */
proto.loupe.common.search.item.SearchItemFieldVersion.prototype.clearInstancesList = function() {
  return this.setInstancesList([]);
};


/**
 * optional google.protobuf.Timestamp ts = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.search.item.SearchItemFieldVersion.prototype.getTs = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.search.item.SearchItemFieldVersion} returns this
*/
proto.loupe.common.search.item.SearchItemFieldVersion.prototype.setTs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.search.item.SearchItemFieldVersion} returns this
 */
proto.loupe.common.search.item.SearchItemFieldVersion.prototype.clearTs = function() {
  return this.setTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.search.item.SearchItemFieldVersion.prototype.hasTs = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.loupe.common.search.item);
