// source: src/common/forecast/v3/forecast.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_nullable_nullable_pb = require('../../../../src/common/nullable/nullable_pb.js');
goog.object.extend(proto, src_common_nullable_nullable_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_opportunity_opportunity_pb = require('../../../../src/common/opportunity/opportunity_pb.js');
goog.object.extend(proto, src_common_opportunity_opportunity_pb);
goog.exportSymbol('proto.loupe.common.forecast.v3.Forecast', null, global);
goog.exportSymbol('proto.loupe.common.forecast.v3.ForecastCall', null, global);
goog.exportSymbol('proto.loupe.common.forecast.v3.ForecastCallDiff', null, global);
goog.exportSymbol('proto.loupe.common.forecast.v3.ForecastDiff', null, global);
goog.exportSymbol('proto.loupe.common.forecast.v3.ForecastMode', null, global);
goog.exportSymbol('proto.loupe.common.forecast.v3.ForecastObject', null, global);
goog.exportSymbol('proto.loupe.common.forecast.v3.ForecastObject.ObjectCase', null, global);
goog.exportSymbol('proto.loupe.common.forecast.v3.ForecastObjectType', null, global);
goog.exportSymbol('proto.loupe.common.forecast.v3.ForecastWithDiff', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.v3.Forecast = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.forecast.v3.Forecast.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.forecast.v3.Forecast, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.v3.Forecast.displayName = 'proto.loupe.common.forecast.v3.Forecast';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.v3.ForecastCall = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.forecast.v3.ForecastCall, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.v3.ForecastCall.displayName = 'proto.loupe.common.forecast.v3.ForecastCall';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.v3.ForecastObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.loupe.common.forecast.v3.ForecastObject.oneofGroups_);
};
goog.inherits(proto.loupe.common.forecast.v3.ForecastObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.v3.ForecastObject.displayName = 'proto.loupe.common.forecast.v3.ForecastObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.v3.ForecastDiff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.forecast.v3.ForecastDiff.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.forecast.v3.ForecastDiff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.v3.ForecastDiff.displayName = 'proto.loupe.common.forecast.v3.ForecastDiff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.v3.ForecastCallDiff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.forecast.v3.ForecastCallDiff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.v3.ForecastCallDiff.displayName = 'proto.loupe.common.forecast.v3.ForecastCallDiff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.v3.ForecastWithDiff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.forecast.v3.ForecastWithDiff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.v3.ForecastWithDiff.displayName = 'proto.loupe.common.forecast.v3.ForecastWithDiff';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.forecast.v3.Forecast.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.v3.Forecast.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.v3.Forecast} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v3.Forecast.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    forecastConfigId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    periodStart: jspb.Message.getFieldWithDefault(msg, 5, ""),
    periodEnd: jspb.Message.getFieldWithDefault(msg, 6, ""),
    callsList: jspb.Message.toObjectList(msg.getCallsList(),
    proto.loupe.common.forecast.v3.ForecastCall.toObject, includeInstance),
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    proto.loupe.common.forecast.v3.ForecastObject.toObject, includeInstance),
    comment: jspb.Message.getFieldWithDefault(msg, 9, ""),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
    submittedAt: (f = msg.getSubmittedAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    submittedBy: jspb.Message.getFieldWithDefault(msg, 12, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 14, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 16, ""),
    goalAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    submittedByName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    objectCount: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.v3.Forecast}
 */
proto.loupe.common.forecast.v3.Forecast.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.v3.Forecast;
  return proto.loupe.common.forecast.v3.Forecast.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.v3.Forecast} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.v3.Forecast}
 */
proto.loupe.common.forecast.v3.Forecast.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodStart(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodEnd(value);
      break;
    case 7:
      var value = new proto.loupe.common.forecast.v3.ForecastCall;
      reader.readMessage(value,proto.loupe.common.forecast.v3.ForecastCall.deserializeBinaryFromReader);
      msg.addCalls(value);
      break;
    case 8:
      var value = new proto.loupe.common.forecast.v3.ForecastObject;
      reader.readMessage(value,proto.loupe.common.forecast.v3.ForecastObject.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 10:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 11:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setSubmittedAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubmittedBy(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGoalAmount(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubmittedByName(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setObjectCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.v3.Forecast.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.v3.Forecast} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v3.Forecast.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getForecastConfigId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPeriodStart();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPeriodEnd();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCallsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.loupe.common.forecast.v3.ForecastCall.serializeBinaryToWriter
    );
  }
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.loupe.common.forecast.v3.ForecastObject.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSubmittedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getSubmittedBy();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getGoalAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getSubmittedByName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getObjectCount();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string forecast_config_id = 2;
 * @return {string}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getForecastConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setForecastConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string owner_id = 4;
 * @return {string}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string period_start = 5;
 * @return {string}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getPeriodStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setPeriodStart = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string period_end = 6;
 * @return {string}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getPeriodEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setPeriodEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated ForecastCall calls = 7;
 * @return {!Array<!proto.loupe.common.forecast.v3.ForecastCall>}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getCallsList = function() {
  return /** @type{!Array<!proto.loupe.common.forecast.v3.ForecastCall>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.forecast.v3.ForecastCall, 7));
};


/**
 * @param {!Array<!proto.loupe.common.forecast.v3.ForecastCall>} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
*/
proto.loupe.common.forecast.v3.Forecast.prototype.setCallsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.loupe.common.forecast.v3.ForecastCall=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.v3.ForecastCall}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.addCalls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.loupe.common.forecast.v3.ForecastCall, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.clearCallsList = function() {
  return this.setCallsList([]);
};


/**
 * repeated ForecastObject objects = 8;
 * @return {!Array<!proto.loupe.common.forecast.v3.ForecastObject>}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.loupe.common.forecast.v3.ForecastObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.forecast.v3.ForecastObject, 8));
};


/**
 * @param {!Array<!proto.loupe.common.forecast.v3.ForecastObject>} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
*/
proto.loupe.common.forecast.v3.Forecast.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.loupe.common.forecast.v3.ForecastObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.v3.ForecastObject}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.loupe.common.forecast.v3.ForecastObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};


/**
 * optional string comment = 9;
 * @return {string}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * map<string, string> metadata = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};


/**
 * optional loupe.common.nullable.NullTimestamp submitted_at = 11;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getSubmittedAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 11));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
*/
proto.loupe.common.forecast.v3.Forecast.prototype.setSubmittedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.clearSubmittedAt = function() {
  return this.setSubmittedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.hasSubmittedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string submitted_by = 12;
 * @return {string}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getSubmittedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setSubmittedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
*/
proto.loupe.common.forecast.v3.Forecast.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string created_by = 14;
 * @return {string}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
*/
proto.loupe.common.forecast.v3.Forecast.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string updated_by = 16;
 * @return {string}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional double goal_amount = 17;
 * @return {number}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getGoalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setGoalAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional string submitted_by_name = 18;
 * @return {string}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getSubmittedByName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setSubmittedByName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional double object_count = 19;
 * @return {number}
 */
proto.loupe.common.forecast.v3.Forecast.prototype.getObjectCount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.forecast.v3.Forecast} returns this
 */
proto.loupe.common.forecast.v3.Forecast.prototype.setObjectCount = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.v3.ForecastCall.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.v3.ForecastCall} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v3.ForecastCall.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    key: jspb.Message.getFieldWithDefault(msg, 3, ""),
    value: (f = msg.getValue()) && src_common_nullable_nullable_pb.NullFloat64.toObject(includeInstance, f),
    pb_default: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    comment: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.v3.ForecastCall}
 */
proto.loupe.common.forecast.v3.ForecastCall.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.v3.ForecastCall;
  return proto.loupe.common.forecast.v3.ForecastCall.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.v3.ForecastCall} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.v3.ForecastCall}
 */
proto.loupe.common.forecast.v3.ForecastCall.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 4:
      var value = new src_common_nullable_nullable_pb.NullFloat64;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullFloat64.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDefault(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.v3.ForecastCall.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.v3.ForecastCall} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v3.ForecastCall.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_nullable_nullable_pb.NullFloat64.serializeBinaryToWriter
    );
  }
  f = message.getDefault();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string forecast_id = 1;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.getForecastId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastCall} returns this
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.setForecastId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastCall} returns this
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 3;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastCall} returns this
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional loupe.common.nullable.NullFloat64 value = 4;
 * @return {?proto.loupe.common.nullable.NullFloat64}
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.getValue = function() {
  return /** @type{?proto.loupe.common.nullable.NullFloat64} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullFloat64, 4));
};


/**
 * @param {?proto.loupe.common.nullable.NullFloat64|undefined} value
 * @return {!proto.loupe.common.forecast.v3.ForecastCall} returns this
*/
proto.loupe.common.forecast.v3.ForecastCall.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v3.ForecastCall} returns this
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.hasValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double default = 5;
 * @return {number}
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.getDefault = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.forecast.v3.ForecastCall} returns this
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.setDefault = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string comment = 6;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastCall} returns this
 */
proto.loupe.common.forecast.v3.ForecastCall.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.loupe.common.forecast.v3.ForecastObject.oneofGroups_ = [[5,6]];

/**
 * @enum {number}
 */
proto.loupe.common.forecast.v3.ForecastObject.ObjectCase = {
  OBJECT_NOT_SET: 0,
  DATA: 5,
  OPPORTUNITY: 6
};

/**
 * @return {proto.loupe.common.forecast.v3.ForecastObject.ObjectCase}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.getObjectCase = function() {
  return /** @type {proto.loupe.common.forecast.v3.ForecastObject.ObjectCase} */(jspb.Message.computeOneofCase(this, proto.loupe.common.forecast.v3.ForecastObject.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.v3.ForecastObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.v3.ForecastObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v3.ForecastObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    callKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    data: msg.getData_asB64(),
    opportunity: (f = msg.getOpportunity()) && src_common_opportunity_opportunity_pb.Opportunity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.v3.ForecastObject}
 */
proto.loupe.common.forecast.v3.ForecastObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.v3.ForecastObject;
  return proto.loupe.common.forecast.v3.ForecastObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.v3.ForecastObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.v3.ForecastObject}
 */
proto.loupe.common.forecast.v3.ForecastObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallKey(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.common.forecast.v3.ForecastObjectType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 6:
      var value = new src_common_opportunity_opportunity_pb.Opportunity;
      reader.readMessage(value,src_common_opportunity_opportunity_pb.Opportunity.deserializeBinaryFromReader);
      msg.setOpportunity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.v3.ForecastObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.v3.ForecastObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v3.ForecastObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCallKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getOpportunity();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_opportunity_opportunity_pb.Opportunity.serializeBinaryToWriter
    );
  }
};


/**
 * optional string forecast_id = 1;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.getForecastId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastObject} returns this
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.setForecastId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_id = 2;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.getObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastObject} returns this
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.setObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string call_key = 3;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.getCallKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastObject} returns this
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.setCallKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ForecastObjectType type = 4;
 * @return {!proto.loupe.common.forecast.v3.ForecastObjectType}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.getType = function() {
  return /** @type {!proto.loupe.common.forecast.v3.ForecastObjectType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.common.forecast.v3.ForecastObjectType} value
 * @return {!proto.loupe.common.forecast.v3.ForecastObject} returns this
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bytes data = 5;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes data = 5;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.common.forecast.v3.ForecastObject} returns this
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.setData = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.loupe.common.forecast.v3.ForecastObject.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.loupe.common.forecast.v3.ForecastObject} returns this
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.clearData = function() {
  return jspb.Message.setOneofField(this, 5, proto.loupe.common.forecast.v3.ForecastObject.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.hasData = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional loupe.common.opportunity.Opportunity opportunity = 6;
 * @return {?proto.loupe.common.opportunity.Opportunity}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.getOpportunity = function() {
  return /** @type{?proto.loupe.common.opportunity.Opportunity} */ (
    jspb.Message.getWrapperField(this, src_common_opportunity_opportunity_pb.Opportunity, 6));
};


/**
 * @param {?proto.loupe.common.opportunity.Opportunity|undefined} value
 * @return {!proto.loupe.common.forecast.v3.ForecastObject} returns this
*/
proto.loupe.common.forecast.v3.ForecastObject.prototype.setOpportunity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.loupe.common.forecast.v3.ForecastObject.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v3.ForecastObject} returns this
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.clearOpportunity = function() {
  return this.setOpportunity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v3.ForecastObject.prototype.hasOpportunity = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.forecast.v3.ForecastDiff.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.v3.ForecastDiff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.v3.ForecastDiff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v3.ForecastDiff.toObject = function(includeInstance, msg) {
  var f, obj = {
    previousForecastId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currentForecastId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    callDiffsList: jspb.Message.toObjectList(msg.getCallDiffsList(),
    proto.loupe.common.forecast.v3.ForecastCallDiff.toObject, includeInstance),
    addedObjectsList: jspb.Message.toObjectList(msg.getAddedObjectsList(),
    proto.loupe.common.forecast.v3.ForecastObject.toObject, includeInstance),
    removedObjectsList: jspb.Message.toObjectList(msg.getRemovedObjectsList(),
    proto.loupe.common.forecast.v3.ForecastObject.toObject, includeInstance),
    commentDiff: jspb.Message.getFieldWithDefault(msg, 6, ""),
    interval: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.v3.ForecastDiff}
 */
proto.loupe.common.forecast.v3.ForecastDiff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.v3.ForecastDiff;
  return proto.loupe.common.forecast.v3.ForecastDiff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.v3.ForecastDiff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.v3.ForecastDiff}
 */
proto.loupe.common.forecast.v3.ForecastDiff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousForecastId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentForecastId(value);
      break;
    case 3:
      var value = new proto.loupe.common.forecast.v3.ForecastCallDiff;
      reader.readMessage(value,proto.loupe.common.forecast.v3.ForecastCallDiff.deserializeBinaryFromReader);
      msg.addCallDiffs(value);
      break;
    case 4:
      var value = new proto.loupe.common.forecast.v3.ForecastObject;
      reader.readMessage(value,proto.loupe.common.forecast.v3.ForecastObject.deserializeBinaryFromReader);
      msg.addAddedObjects(value);
      break;
    case 5:
      var value = new proto.loupe.common.forecast.v3.ForecastObject;
      reader.readMessage(value,proto.loupe.common.forecast.v3.ForecastObject.deserializeBinaryFromReader);
      msg.addRemovedObjects(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommentDiff(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.v3.ForecastDiff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.v3.ForecastDiff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v3.ForecastDiff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreviousForecastId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrentForecastId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCallDiffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.common.forecast.v3.ForecastCallDiff.serializeBinaryToWriter
    );
  }
  f = message.getAddedObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.loupe.common.forecast.v3.ForecastObject.serializeBinaryToWriter
    );
  }
  f = message.getRemovedObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.loupe.common.forecast.v3.ForecastObject.serializeBinaryToWriter
    );
  }
  f = message.getCommentDiff();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string previous_forecast_id = 1;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.getPreviousForecastId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.setPreviousForecastId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string current_forecast_id = 2;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.getCurrentForecastId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.setCurrentForecastId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ForecastCallDiff call_diffs = 3;
 * @return {!Array<!proto.loupe.common.forecast.v3.ForecastCallDiff>}
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.getCallDiffsList = function() {
  return /** @type{!Array<!proto.loupe.common.forecast.v3.ForecastCallDiff>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.forecast.v3.ForecastCallDiff, 3));
};


/**
 * @param {!Array<!proto.loupe.common.forecast.v3.ForecastCallDiff>} value
 * @return {!proto.loupe.common.forecast.v3.ForecastDiff} returns this
*/
proto.loupe.common.forecast.v3.ForecastDiff.prototype.setCallDiffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.forecast.v3.ForecastCallDiff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.v3.ForecastCallDiff}
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.addCallDiffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.forecast.v3.ForecastCallDiff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.v3.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.clearCallDiffsList = function() {
  return this.setCallDiffsList([]);
};


/**
 * repeated ForecastObject added_objects = 4;
 * @return {!Array<!proto.loupe.common.forecast.v3.ForecastObject>}
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.getAddedObjectsList = function() {
  return /** @type{!Array<!proto.loupe.common.forecast.v3.ForecastObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.forecast.v3.ForecastObject, 4));
};


/**
 * @param {!Array<!proto.loupe.common.forecast.v3.ForecastObject>} value
 * @return {!proto.loupe.common.forecast.v3.ForecastDiff} returns this
*/
proto.loupe.common.forecast.v3.ForecastDiff.prototype.setAddedObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.forecast.v3.ForecastObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.v3.ForecastObject}
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.addAddedObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.forecast.v3.ForecastObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.v3.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.clearAddedObjectsList = function() {
  return this.setAddedObjectsList([]);
};


/**
 * repeated ForecastObject removed_objects = 5;
 * @return {!Array<!proto.loupe.common.forecast.v3.ForecastObject>}
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.getRemovedObjectsList = function() {
  return /** @type{!Array<!proto.loupe.common.forecast.v3.ForecastObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.forecast.v3.ForecastObject, 5));
};


/**
 * @param {!Array<!proto.loupe.common.forecast.v3.ForecastObject>} value
 * @return {!proto.loupe.common.forecast.v3.ForecastDiff} returns this
*/
proto.loupe.common.forecast.v3.ForecastDiff.prototype.setRemovedObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.forecast.v3.ForecastObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.v3.ForecastObject}
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.addRemovedObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.forecast.v3.ForecastObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.v3.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.clearRemovedObjectsList = function() {
  return this.setRemovedObjectsList([]);
};


/**
 * optional string comment_diff = 6;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.getCommentDiff = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.setCommentDiff = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 interval = 7;
 * @return {number}
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.forecast.v3.ForecastDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastDiff.prototype.setInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.v3.ForecastCallDiff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.v3.ForecastCallDiff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valueDiff: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    defaultDiff: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    commentDiff: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.v3.ForecastCallDiff}
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.v3.ForecastCallDiff;
  return proto.loupe.common.forecast.v3.ForecastCallDiff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.v3.ForecastCallDiff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.v3.ForecastCallDiff}
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValueDiff(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDefaultDiff(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommentDiff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.v3.ForecastCallDiff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.v3.ForecastCallDiff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValueDiff();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getDefaultDiff();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCommentDiff();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string owner_id = 1;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastCallDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastCallDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double value_diff = 3;
 * @return {number}
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.prototype.getValueDiff = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.forecast.v3.ForecastCallDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.prototype.setValueDiff = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double default_diff = 4;
 * @return {number}
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.prototype.getDefaultDiff = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.forecast.v3.ForecastCallDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.prototype.setDefaultDiff = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string comment_diff = 5;
 * @return {string}
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.prototype.getCommentDiff = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.v3.ForecastCallDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastCallDiff.prototype.setCommentDiff = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.v3.ForecastWithDiff.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.v3.ForecastWithDiff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.v3.ForecastWithDiff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v3.ForecastWithDiff.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecast: (f = msg.getForecast()) && proto.loupe.common.forecast.v3.Forecast.toObject(includeInstance, f),
    diff: (f = msg.getDiff()) && proto.loupe.common.forecast.v3.ForecastDiff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.v3.ForecastWithDiff}
 */
proto.loupe.common.forecast.v3.ForecastWithDiff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.v3.ForecastWithDiff;
  return proto.loupe.common.forecast.v3.ForecastWithDiff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.v3.ForecastWithDiff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.v3.ForecastWithDiff}
 */
proto.loupe.common.forecast.v3.ForecastWithDiff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.forecast.v3.Forecast;
      reader.readMessage(value,proto.loupe.common.forecast.v3.Forecast.deserializeBinaryFromReader);
      msg.setForecast(value);
      break;
    case 2:
      var value = new proto.loupe.common.forecast.v3.ForecastDiff;
      reader.readMessage(value,proto.loupe.common.forecast.v3.ForecastDiff.deserializeBinaryFromReader);
      msg.setDiff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.v3.ForecastWithDiff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.v3.ForecastWithDiff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.v3.ForecastWithDiff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.v3.ForecastWithDiff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecast();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.common.forecast.v3.Forecast.serializeBinaryToWriter
    );
  }
  f = message.getDiff();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.common.forecast.v3.ForecastDiff.serializeBinaryToWriter
    );
  }
};


/**
 * optional Forecast forecast = 1;
 * @return {?proto.loupe.common.forecast.v3.Forecast}
 */
proto.loupe.common.forecast.v3.ForecastWithDiff.prototype.getForecast = function() {
  return /** @type{?proto.loupe.common.forecast.v3.Forecast} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.forecast.v3.Forecast, 1));
};


/**
 * @param {?proto.loupe.common.forecast.v3.Forecast|undefined} value
 * @return {!proto.loupe.common.forecast.v3.ForecastWithDiff} returns this
*/
proto.loupe.common.forecast.v3.ForecastWithDiff.prototype.setForecast = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v3.ForecastWithDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastWithDiff.prototype.clearForecast = function() {
  return this.setForecast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v3.ForecastWithDiff.prototype.hasForecast = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ForecastDiff diff = 2;
 * @return {?proto.loupe.common.forecast.v3.ForecastDiff}
 */
proto.loupe.common.forecast.v3.ForecastWithDiff.prototype.getDiff = function() {
  return /** @type{?proto.loupe.common.forecast.v3.ForecastDiff} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.forecast.v3.ForecastDiff, 2));
};


/**
 * @param {?proto.loupe.common.forecast.v3.ForecastDiff|undefined} value
 * @return {!proto.loupe.common.forecast.v3.ForecastWithDiff} returns this
*/
proto.loupe.common.forecast.v3.ForecastWithDiff.prototype.setDiff = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.v3.ForecastWithDiff} returns this
 */
proto.loupe.common.forecast.v3.ForecastWithDiff.prototype.clearDiff = function() {
  return this.setDiff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.v3.ForecastWithDiff.prototype.hasDiff = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.loupe.common.forecast.v3.ForecastMode = {
  AUTO: 0,
  MANUAL: 1
};

/**
 * @enum {number}
 */
proto.loupe.common.forecast.v3.ForecastObjectType = {
  FORECAST_OBJECT_TYPE_UNSPECIFIED: 0,
  OPPORTUNITY: 1
};

goog.object.extend(exports, proto.loupe.common.forecast.v3);
