import React, { useState, useCallback } from 'react'
import FieldInput from './fieldInput'
import { useInputFormat } from '../hooks'

const FieldInputRange = (props) => {
  const { onChange, format } = props

  const { getFormattingRules } = useInputFormat()

  const formatRules = getFormattingRules(format)
  const defaultValue = formatRules?.defaultValue ?? ''

  const [valueSet, setValueSet] = useState([{
    label: `${defaultValue}`,
    value: `${defaultValue}`
  }])

  const onChangeInternal = useCallback((value, index) => {
    const newValueSet = [...valueSet]
    const [fieldValue] = value
    newValueSet[index] = fieldValue

    setValueSet(newValueSet)
    if (newValueSet.length === 2) {
      onChange(newValueSet)
    }
  }, [onChange, valueSet])

  return (
    <div className="range-fields">
      <FieldInput {...props} onChange={(v) => onChangeInternal(v, 0)} />
      <span className="range-opp">AND</span>
      <FieldInput {...props} onChange={(v) => onChangeInternal(v, 1)} />
    </div>
  )
}

export default FieldInputRange
