import React from 'react'
import { FormControlLabel, Switch as MuiSwitch } from '@material-ui/core'

const Switch = (props) => {
  const {
    checked,
    label,
    onChange,
  } = props

  return (
    <FormControlLabel control={(
      <MuiSwitch
        checked={checked}
        onChange={onChange}
        color="secondary"
        {...props}
      />
    )}
      {...label && { label }}
    />

  )
}

export default Switch
