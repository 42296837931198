import { permissionNames } from '../../constants/permissionNames'
import { useAuth } from '../../context/auth'
import { usePermissions } from '../../context/permissions'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import React, { useCallback, useMemo } from 'react'
import SideMenuItem from './sideMenuItem'
import { useLoggedInUser } from '../../context/loggedInUser'
import { find } from 'lodash'
import { featureNames } from '../constants/featureNames'

const SideMenuDefault = (props) => {
  const { checkPermissions } = usePermissions()
  const { routes } = useRoutes()
  const { tenantContext } = useAuth()
  const history = useHistory()
  const { settings, hasSettings } = useLoggedInUser()

  const forecastManagementEnabled = useMemo(() => {
    const forecastManagementSetting = find(settings, (s) => s.key === featureNames.forecastManagement)
    return forecastManagementSetting?.payload?.enabled
  }, [settings])

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessGroup,
      permissionNames.CanAccessUser,
      permissionNames.CanAccessGoal,
      permissionNames.CanAccessSync,
      permissionNames.CanAccessSystemRole,
      permissionNames.CanAccessSandbox,
      permissionNames.CanAccessForecasts
    )
  }, [checkPermissions])

  const groupsMatch = useRouteMatch(routes.teams)
  const usersMatch = useRouteMatch(routes.users)
  const goalsMatch = useRouteMatch(routes.goals)
  const dataSyncMatch = useRouteMatch(routes.dataSync)
  const dataSyncHistoryMatch = useRouteMatch(routes.dataSyncHistory)
  const dataSyncObjectMappingMatch = useRouteMatch(routes.dataSyncObjectMapping)
  const rolesMatch = useRouteMatch(routes.roles)
  const sandboxMatch = useRouteMatch(routes.sandboxes)
  const forecastsMatch = useRouteMatch(routes.forecasts)

  const handleClick = useCallback((action, route) => {
    window.analytics.track('admin.sideMenuClicked', { action })
    history.push(route)
  }, [])

  return (
    <>
      {permissions.CanAccessSync && (
        <SideMenuItem
          label="Data Sync"
          isCurrent={(dataSyncMatch && dataSyncMatch.isExact)
            || (dataSyncHistoryMatch && dataSyncHistoryMatch.isExact)
            || (dataSyncObjectMappingMatch && dataSyncObjectMappingMatch.isExact)}
          onClick={handleClick.bind(this, 'Data Sync', routes.dataSync)} />
      )}

      {permissions.CanAccessGroup
        && (
          <SideMenuItem
            label="Teams"
            isCurrent={groupsMatch && groupsMatch.isExact}
            onClick={handleClick.bind(this, 'Teams', routes.teams)} />
        )}

      {permissions.CanAccessUser
        && (
          <SideMenuItem
            label="Users"
            isCurrent={usersMatch && usersMatch.isExact}
            onClick={handleClick.bind(this, 'Users', routes.users)} />
        )}

      {permissions.CanAccessSystemRole && (
        <SideMenuItem
          label="Roles"
          isCurrent={rolesMatch && rolesMatch.isExact}
          onClick={handleClick.bind(this, 'Roles', routes.roles)} />
      )}

      {permissions.CanAccessGoal
        && (
          <SideMenuItem
            label="Goals"
            isCurrent={goalsMatch && goalsMatch.isExact}
            onClick={handleClick.bind(this, 'Goals', routes.goals)} />
        )}

      {hasSettings ? (
        <>
          {forecastManagementEnabled && permissions.CanAccessForecasts && (
            <SideMenuItem
              label="Forecasts"
              isCurrent={forecastsMatch}
              onClick={handleClick.bind(this, 'Forecasts', routes.forecasts)} />
          )}
        </>
      ) : (
        <></>
      )}

      {!tenantContext && permissions.CanAccessSandbox && (
        <SideMenuItem
          id="sidemenu-sandbox"
          label="Sandboxes"
          isCurrent={sandboxMatch && sandboxMatch.isExact}
          onClick={handleClick.bind(this, 'Sandbox', routes.sandboxes)} />
      )}
    </>
  )
}

export default SideMenuDefault
