import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import AutocompleteListBox from './autocompleteListBox'
import ChevronDown from '../../icons/chevronDown'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(0.125, 0, 0.25, 1.5)} !important`,
    '& .MuiAutocomplete-endAdornment': {
      right: '0 !important',
    },
    '&:hover $notchedOutline, &.Mui-focused $notchedOutline': {
      borderColor: '#d6d9e6',
      borderWidth: 1,
    }
  },
  notchedOutline: {
    borderColor: '#d6d9e6',
    borderRadius: 4,
  },
  popupIndicator: {
    '&:hover, &:focus': {
      background: 'none'
    }
  }
}))

const Autocomplete = ({ options, ListboxProps, ...props }) => {
  const classes = useStyles()

  return (
    <MuiAutocomplete
      classes={{ popupIndicator: classes.popupIndicator }}
      disableListWrap
      ListboxComponent={AutocompleteListBox}
      ListboxProps={ListboxProps}
      options={options}
      popupIcon={<ChevronDown className="mx-1" fill="#A6B2CF" />}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: classes.root,
              notchedOutline: classes.notchedOutline
            }
          }}
          variant="outlined"
          fullWidth
        />
      )}
      {...props}
    />
  )
}

Autocomplete.defaultProps = {
  ListboxProps: { itemSize: 36 },
  options: []
}

Autocomplete.propTypes = {
  ListboxProps: PropTypes.object,
  options: PropTypes.array
}

export default Autocomplete
