// source: src/common/forecast/forecast_entry.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_forecast_forecast_item_pb = require('../../../src/common/forecast/forecast_item_pb.js');
goog.object.extend(proto, src_common_forecast_forecast_item_pb);
var src_common_nullable_nullable_pb = require('../../../src/common/nullable/nullable_pb.js');
goog.object.extend(proto, src_common_nullable_nullable_pb);
goog.exportSymbol('proto.loupe.common.forecast.ForecastEntry', null, global);
goog.exportSymbol('proto.loupe.common.forecast.ForecastEntryList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.ForecastEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.forecast.ForecastEntry.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.forecast.ForecastEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.ForecastEntry.displayName = 'proto.loupe.common.forecast.ForecastEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.ForecastEntryList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.forecast.ForecastEntryList.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.forecast.ForecastEntryList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.ForecastEntryList.displayName = 'proto.loupe.common.forecast.ForecastEntryList';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.forecast.ForecastEntry.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.ForecastEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.ForecastEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.ForecastEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    submittedAt: (f = msg.getSubmittedAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && src_common_nullable_nullable_pb.NullString.toObject(includeInstance, f),
    note: (f = msg.getNote()) && src_common_nullable_nullable_pb.NullString.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    src_common_forecast_forecast_item_pb.ForecastItem.toObject, includeInstance),
    latestChange: (f = msg.getLatestChange()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    redisScore: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.ForecastEntry}
 */
proto.loupe.common.forecast.ForecastEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.ForecastEntry;
  return proto.loupe.common.forecast.ForecastEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.ForecastEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.ForecastEntry}
 */
proto.loupe.common.forecast.ForecastEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setSubmittedAt(value);
      break;
    case 4:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new src_common_nullable_nullable_pb.NullString;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullString.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 6:
      var value = new src_common_nullable_nullable_pb.NullString;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullString.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    case 7:
      var value = new src_common_forecast_forecast_item_pb.ForecastItem;
      reader.readMessage(value,src_common_forecast_forecast_item_pb.ForecastItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 8:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setLatestChange(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRedisScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.ForecastEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.ForecastEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.ForecastEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubmittedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_nullable_nullable_pb.NullString.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_nullable_nullable_pb.NullString.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      src_common_forecast_forecast_item_pb.ForecastItem.serializeBinaryToWriter
    );
  }
  f = message.getLatestChange();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getRedisScore();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
 */
proto.loupe.common.forecast.ForecastEntry.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
 */
proto.loupe.common.forecast.ForecastEntry.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.nullable.NullTimestamp submitted_at = 3;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.getSubmittedAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 3));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
*/
proto.loupe.common.forecast.ForecastEntry.prototype.setSubmittedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
 */
proto.loupe.common.forecast.ForecastEntry.prototype.clearSubmittedAt = function() {
  return this.setSubmittedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.hasSubmittedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional loupe.common.nullable.NullTimestamp created_at = 4;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.getCreatedAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 4));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
*/
proto.loupe.common.forecast.ForecastEntry.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
 */
proto.loupe.common.forecast.ForecastEntry.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional loupe.common.nullable.NullString status = 5;
 * @return {?proto.loupe.common.nullable.NullString}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.getStatus = function() {
  return /** @type{?proto.loupe.common.nullable.NullString} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullString, 5));
};


/**
 * @param {?proto.loupe.common.nullable.NullString|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
*/
proto.loupe.common.forecast.ForecastEntry.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
 */
proto.loupe.common.forecast.ForecastEntry.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional loupe.common.nullable.NullString note = 6;
 * @return {?proto.loupe.common.nullable.NullString}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.getNote = function() {
  return /** @type{?proto.loupe.common.nullable.NullString} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullString, 6));
};


/**
 * @param {?proto.loupe.common.nullable.NullString|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
*/
proto.loupe.common.forecast.ForecastEntry.prototype.setNote = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
 */
proto.loupe.common.forecast.ForecastEntry.prototype.clearNote = function() {
  return this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.hasNote = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated ForecastItem items = 7;
 * @return {!Array<!proto.loupe.common.forecast.ForecastItem>}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.loupe.common.forecast.ForecastItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_forecast_forecast_item_pb.ForecastItem, 7));
};


/**
 * @param {!Array<!proto.loupe.common.forecast.ForecastItem>} value
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
*/
proto.loupe.common.forecast.ForecastEntry.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.loupe.common.forecast.ForecastItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.ForecastItem}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.loupe.common.forecast.ForecastItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
 */
proto.loupe.common.forecast.ForecastEntry.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional loupe.common.nullable.NullTimestamp latest_change = 8;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.getLatestChange = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 8));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
*/
proto.loupe.common.forecast.ForecastEntry.prototype.setLatestChange = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
 */
proto.loupe.common.forecast.ForecastEntry.prototype.clearLatestChange = function() {
  return this.setLatestChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.hasLatestChange = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 redis_score = 9;
 * @return {number}
 */
proto.loupe.common.forecast.ForecastEntry.prototype.getRedisScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.forecast.ForecastEntry} returns this
 */
proto.loupe.common.forecast.ForecastEntry.prototype.setRedisScore = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.forecast.ForecastEntryList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.ForecastEntryList.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.ForecastEntryList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.ForecastEntryList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.ForecastEntryList.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.loupe.common.forecast.ForecastEntry.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.ForecastEntryList}
 */
proto.loupe.common.forecast.ForecastEntryList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.ForecastEntryList;
  return proto.loupe.common.forecast.ForecastEntryList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.ForecastEntryList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.ForecastEntryList}
 */
proto.loupe.common.forecast.ForecastEntryList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.forecast.ForecastEntry;
      reader.readMessage(value,proto.loupe.common.forecast.ForecastEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.ForecastEntryList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.ForecastEntryList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.ForecastEntryList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.ForecastEntryList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.forecast.ForecastEntry.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated ForecastEntry entries = 1;
 * @return {!Array<!proto.loupe.common.forecast.ForecastEntry>}
 */
proto.loupe.common.forecast.ForecastEntryList.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.loupe.common.forecast.ForecastEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.forecast.ForecastEntry, 1));
};


/**
 * @param {!Array<!proto.loupe.common.forecast.ForecastEntry>} value
 * @return {!proto.loupe.common.forecast.ForecastEntryList} returns this
*/
proto.loupe.common.forecast.ForecastEntryList.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.forecast.ForecastEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.ForecastEntry}
 */
proto.loupe.common.forecast.ForecastEntryList.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.forecast.ForecastEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.ForecastEntryList} returns this
 */
proto.loupe.common.forecast.ForecastEntryList.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.loupe.common.forecast.ForecastEntryList.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.forecast.ForecastEntryList} returns this
 */
proto.loupe.common.forecast.ForecastEntryList.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.loupe.common.forecast);
