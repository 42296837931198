import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DoubleArrow from '../icons/doubleArrow'
import Asterisk from '../icons/asterisk'
import Tooltip from '../common/tooltip'
import { capitalize, filter, find, has } from 'lodash'
import { useIntegrationObjectProperties } from '../../context/integrationObjectProperties'
import { Menu, MenuItem } from '@material-ui/core'
import { usePopover } from '../../hooks/usePopover'
import { useObjectMappingChanges } from '../../context/objectMappingChanges'
import { useCanonicalObjectMapping } from '../../context/canonicalObjectMapping'
import ChevronDown from '../icons/chevronDown'
import ExclamationTriangle from '../icons/exclamationTriangle'
import Error from '../icons/error'
import Checkbox from '../common/checkbox'
import classNames from 'classnames'
import SearchBox from '../common/searchBox'
import { useTextField } from '../../hooks/useTextField'
import { MappingStatusDetailLevel } from '../../grpc/enums'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const writeBackOptions = [
  { label: 'None', value: 0, data: { permissions: { canView: true, canEdit: false, canManagerEdit: false } } },
  { label: 'Owners Only', value: 1, data: { permissions: { canView: true, canEdit: true, canManagerEdit: false } } },
  { label: 'Managers Only', value: 2, data: { permissions: { canView: true, canEdit: false, canManagerEdit: true } } },
  { label: 'Everyone', value: 3, data: { permissions: { canView: true, canEdit: true, canManagerEdit: true } } },
]

const FieldListItem = (props) => {
  const {
    joyrideStepId = '',
    objectWritable,
    field = {},
    onEnter,
    onExited,
    onFieldChange,
    onWriteBackChange,
  } = props

  const {
    label,
    required,
    toType,
    description,
    writable = false,
    managed = false,
    custom = false,
    permissions = {},
    optionsList = [],
  } = field

  const { checkPermission } = usePermissions()

  const search = useTextField()

  const { canonicalObjectMapping, selectedCrmObjectName } = useCanonicalObjectMapping()
  const { objectMappingChanges, writeBackPermissionChanges } = useObjectMappingChanges()
  const { integrationObjectProperties } = useIntegrationObjectProperties()

  const [selectedIntegrationObjectProperty, setSelectedIntegrationObjectProperty] = useState(undefined)
  const [selectedWriteBackOption, setSelectedWriteBackOption] = useState(undefined)

  const customWithNoOptions = useMemo(() => {
    return custom && optionsList.length === 0
  }, [custom, optionsList])

  const disabled = useMemo(() => {
    return managed || customWithNoOptions
  }, [managed, customWithNoOptions])

  const fieldOptions = useMemo(() => {
    const options = [
      { label: '(Select a Field)', value: undefined }
    ]
    const { custom = false, optionsList = [], fromType, format } = field
    if (custom && optionsList.length > 0) {
      options.push(...optionsList.map((o) => ({
        label: o.label,
        value: o.value,
        data: {
          label: o.label,
          name: o.value,
          type: fromType,
          format,
        },
      })))
    } else {
      options.push(...integrationObjectProperties.map((p) => ({
        label: p.label,
        value: p.name,
        data: p,
        writable: p.writable,
      })))
    }
    return options
  }, [field, integrationObjectProperties])

  useEffect(() => {
    if (!selectedCrmObjectName) {
      return
    }
    if (has(objectMappingChanges, selectedCrmObjectName) && has(objectMappingChanges[selectedCrmObjectName], field.toName)) {
      const mappedField = objectMappingChanges[selectedCrmObjectName][field.toName]
      setSelectedIntegrationObjectProperty(mappedField)
    } else {
      const p = find(fieldOptions, (p) => p.value?.toLowerCase() === field.fromName?.toLowerCase())
      setSelectedIntegrationObjectProperty(p)
    }
  }, [selectedCrmObjectName, objectMappingChanges, field, fieldOptions])

  const crmFieldPopover = usePopover()
  const writeBackPopover = usePopover()

  const onFieldClick = useCallback((e) => {
    search.reset()
    crmFieldPopover.setAnchorEl(e.currentTarget)
  }, [])

  const onMenuItemClick = useCallback((option) => {
    crmFieldPopover.setAnchorEl(null)
    onFieldChange && onFieldChange({ field, option })
  }, [field, onFieldChange])

  const onWriteBackClick = useCallback((e) => {
    writeBackPopover.setAnchorEl(e.currentTarget)
  }, [])

  const onWriteBackMenuItemClick = useCallback((option) => {
    writeBackPopover.setAnchorEl(null)
    onWriteBackChange && onWriteBackChange({ field, option })
  }, [field, onWriteBackChange])

  const selectedPermissions = useMemo(() => {
    if (has(writeBackPermissionChanges, selectedCrmObjectName) && has(writeBackPermissionChanges[selectedCrmObjectName], field.toName)) {
      return writeBackPermissionChanges[selectedCrmObjectName][field.toName]
    } else {
      return permissions
    }
  }, [selectedCrmObjectName, permissions, writeBackPermissionChanges])

  useEffect(() => {
    const { canEdit = false, canManagerEdit = false } = selectedPermissions
    if (!writable) {
      setSelectedWriteBackOption(writeBackOptions[0])
    } else if (canEdit && !canManagerEdit) {
      setSelectedWriteBackOption(writeBackOptions[1])
    } else if (!canEdit && canManagerEdit) {
      setSelectedWriteBackOption(writeBackOptions[2])
    } else if (canEdit && canManagerEdit) {
      setSelectedWriteBackOption(writeBackOptions[3])
    } else {
      setSelectedWriteBackOption(writeBackOptions[0])
    }
  }, [writable, selectedPermissions])

  const onWriteBackCheck = useCallback((e) => {
    if (e.target.checked) {
      writeBackPopover.setAnchorEl(e.currentTarget)
    } else {
      const option = writeBackOptions[0]
      setSelectedWriteBackOption(option)
      onWriteBackChange && onWriteBackChange({ field, option })
    }
  }, [field, onWriteBackChange])

  const onMenuClose = useCallback(() => {
    crmFieldPopover.setAnchorEl(null)
    search.reset()
  }, [])

  const filteredFieldOptions = useMemo(() => {
    if (search.value) {
      return filter(fieldOptions, (f) => {
        const regex = new RegExp(search.escapedValue.toLowerCase())
        return f.label && regex.test(f.label.toLowerCase())
      })
    }
    return fieldOptions
  }, [fieldOptions, search])

  const onKeyDown = useCallback((e) => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        break
      default:
        e.stopPropagation()
    }
  }, [])

  const statusDetailsList = useMemo(() => {
    return canonicalObjectMapping.statusDetailsList || []
  }, [canonicalObjectMapping])

  const fieldValidations = useMemo(() => {
    return filter(statusDetailsList, (s) => {
      return selectedCrmObjectName === s.crmObject && field.toName === s.field
    })
  }, [field, selectedCrmObjectName, statusDetailsList])

  const renderValidationIcon = useCallback(() => {
    if (fieldValidations?.length > 0) {
      const validation = fieldValidations[0]
      const { level, status } = validation
      if (level === MappingStatusDetailLevel.LEVEL_ERROR) {
        return (
          <Tooltip placement="top" arrow={true} title={status}>
            <div className="absolute flex-shrink-0 flex-grow-0" style={{ transform: 'translateX(-30px)' }}>
              <ExclamationTriangle fill="#fb6c6a" />
            </div>
          </Tooltip>
        )
      } else if (level === MappingStatusDetailLevel.LEVEL_WARN) {
        return (
          <Tooltip placement="top" arrow={true} title={status}>
            <div className="absolute flex-shrink-0 flex-grow-0" style={{ transform: 'translateX(-30px)' }}>
              <Error fill="#a6aebe" />
            </div>
          </Tooltip>
        )
      }
    }
  }, [fieldValidations])

  const writeBackDisabled = useMemo(() => {
    const disabled = !writable
      || selectedIntegrationObjectProperty === undefined
      || !has(selectedIntegrationObjectProperty, 'label')
      || !selectedIntegrationObjectProperty.writable
    if (disabled) {
      setSelectedWriteBackOption(writeBackOptions[0])
    }
    return disabled
  }, [writable, selectedIntegrationObjectProperty])

  const canUpdateSync = useMemo(() => {
    return checkPermission(permissionNames.CanUpdateSync)
  }, [checkPermission])

  return (
    <Tooltip placement="top" arrow={true} title={disabled ? 'This field is managed by Outreach' : ''}>
      <div className={classNames('tr hover:bg-color-51636a-05 leading-tight', { 'bg-color-51636a-05': crmFieldPopover.open })}>
        <div className={classNames('td w-10 text-size-16px text-color-818e93 font-weight-400', { 'pointer-events-none opacity-25': disabled })}>{label}</div>
        <div className={classNames('td w-10', { 'pointer-events-none opacity-25': managed })}>
          <DoubleArrow fill="#51636a" />
        </div>
        <div className={classNames('td w-10 text-size-16px text-color-09242f font-weight-400', { 'pointer-events-none opacity-25': disabled })}>
          {!customWithNoOptions && (
            <Menu
              anchorEl={crmFieldPopover.anchorEl}
              open={crmFieldPopover.open}
              autoFocus={false}
              getContentAnchorEl={null}
              PaperProps={{
                style: {
                  maxHeight: 500,
                },
              }}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              onClose={onMenuClose}
              TransitionProps={{ timeout: 0, onEnter, onExited }}>
              <div className="px-2 pt-1 pb-2 focus:outline-none" onKeyDown={onKeyDown}>
                <SearchBox
                  autoFocus={true}
                  value={search.value}
                  onChange={search.onChange}
                  onClear={search.reset} />
              </div>
              {filteredFieldOptions.length === 0
                ? <MenuItem disabled={true}>Search returned 0 results</MenuItem>
                : filteredFieldOptions.map((option) => (
                  <MenuItem
                    key={`FieldOption-${option.value}`}
                    {...selectedIntegrationObjectProperty && { selected: option.value === selectedIntegrationObjectProperty.value }}
                    onClick={() => onMenuItemClick(option)}>
                    <div className="flex flex-col leading-tight">
                      {option.label}
                      {option.data && option.data.type && <div className="text-color-818e93">{capitalize(option.data.type)}</div>}
                    </div>
                  </MenuItem>
                ))}
            </Menu>
          )}
          <div
            className={classNames('flex items-center w-full relative cursor-pointer group', { 'pointer-events-none': !canUpdateSync })}
            onClick={onFieldClick}>
            {renderValidationIcon()}
            {customWithNoOptions ? 'Custom' : (
              <>
                <div id={joyrideStepId}>
                  {selectedIntegrationObjectProperty && selectedIntegrationObjectProperty.label
                    ? selectedIntegrationObjectProperty.label
                    : <span className="text-color-818e93">Select a Field</span>}
                </div>
                <div className="invisible group-hover:visible">
                  <ChevronDown fill="#09242f" />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={classNames('td w-10 text-size-16px text-color-818e93 font-weight-400', { 'pointer-events-none opacity-25': disabled })}>
          {required && <Asterisk fill="#fb6c6a" />}
        </div>
        <div className={classNames('td w-10 text-size-16px text-color-818e93 font-weight-400', { 'pointer-events-none opacity-25': disabled })}>
          {capitalize(toType)}
        </div>
        <div className={classNames('td w-20 text-size-16px text-color-818e93 font-weight-400', { 'pointer-events-none opacity-25': disabled })}>
          <Tooltip placement="top" arrow={true} title={description}>
            <div className="max-lines-2">{description}</div>
          </Tooltip>
        </div>
        {objectWritable
          && (
            <div className={classNames('td w-10 text-size-16px text-color-09242f font-weight-400 flex items-center', { 'pointer-events-none opacity-25': managed })}>
              {managed && !custom
                ? <span>Custom</span>
                : (
                  <>
                    <Checkbox
                      disabled={writeBackDisabled || !canUpdateSync}
                      checked={(selectedWriteBackOption && selectedWriteBackOption.value > 0) || false}
                      onChange={onWriteBackCheck} />
                    <div className={classNames('flex items-center cursor-pointer',
                      { 'pointer-events-none opacity-50': writeBackDisabled })}
                      style={{ transform: 'translateX(-18px)' }}>
                      <Menu
                        anchorEl={writeBackPopover.anchorEl}
                        open={writeBackPopover.open}
                        onClose={() => writeBackPopover.setAnchorEl(null)}
                        TransitionProps={{ timeout: 0, onEnter, onExited }}>
                        {writeBackOptions.map((o) => (
                          <MenuItem
                            key={`WriteBackOption-${o.value}`}
                            {...selectedWriteBackOption && { selected: o.value === selectedWriteBackOption.value }}
                            onClick={() => onWriteBackMenuItemClick(o)}>
                            {o.label}
                          </MenuItem>
                        ))}
                      </Menu>
                      <div
                        className={classNames({ 'pointer-events-none cursor-default': !canUpdateSync })}
                        onClick={onWriteBackClick}>
                        {selectedWriteBackOption ? selectedWriteBackOption.label : '\u00a0'}
                      </div>
                      {writable && canUpdateSync && (
                        <div
                          onClick={onWriteBackClick}>
                          <ChevronDown fill="#09242f" />
                        </div>
                      )}
                    </div>
                  </>
                )}
            </div>
          )}
      </div>
    </Tooltip>
  )
}

export default FieldListItem
