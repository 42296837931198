import React from 'react'

const PageDescription = (props) => {
  const {
    title,
    text,
    link,
    footerText,
    footerLink,
    footerLinkText
  } = props

  return (
    <div className="p-6 bg-color-ffffff border border-color-2e5bff-08 rounded-lg mx-10 mt-10">
      <div className="text-size-24px text-color-09242f font-weight-700">{title}</div>
      <div className="text-size-15px text-color-51636a font-weight-400 leading-tight mt-4">
        {text}
        {' '}
        <a className="text-color-2e5bff text-size-15px font-weight-500 cursor-pointer"
          href={link}
          target="_blank"
          rel="noreferrer">
          Learn more
        </a>
        {footerLink && (
          <div className="text-size-15px text-color-51636a font-weight-400 leading-tight mt-4">
            {footerText}
            <a className="text-color-2e5bff text-size-15px font-weight-500 cursor-pointer" href={footerLink}>{footerLinkText}</a>
          </div>
        )}
      </div>
    </div>
  )
}

export default PageDescription
