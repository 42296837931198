import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '../common/modal'
import ModalHeader from '../common/modalHeader'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import Button from '../common/button'
import pluralize from 'pluralize'
import classNames from 'classnames'
import { forEach } from 'lodash'
import { useEnterKeyCallback } from '../../hooks/useEnterKeyCallback'

const DeleteGroupModal = (props) => {
  const {
    modal,
    onDeleteConfirmed,
  } = props

  const { open, handleClose, data } = modal

  const [canDelete, setCanDelete] = useState(false)

  const [value, setValue] = useState('')

  const onChange = useCallback((e) => {
    setValue(e.target.value.toUpperCase())
  }, [])

  useEffect(() => {
    setCanDelete(value === 'DELETE ALL')
  }, [value])

  const handleCloseInternal = useCallback(() => {
    handleClose && handleClose()
    setValue('')
  }, [handleClose])

  const onDeleteConfirmedInternal = useCallback(() => {
    if (canDelete && open) {
      onDeleteConfirmed && onDeleteConfirmed(data)
      handleCloseInternal()
    }
  }, [data, onDeleteConfirmed, canDelete, open])

  useEnterKeyCallback(onDeleteConfirmedInternal)

  const teamName = useMemo(() => {
    if (data && data.node && data.node.group) {
      return data.node.group.name
    } else {
      return ''
    }
  }, [data])

  const deletedTeamsList = useMemo(() => {
    const teams = []
    if (data && data.node) {
      teams.push(data.node)
      function collectTeamRecursively(node) {
        forEach(node.children, (n) => {
          teams.push(n)
          collectTeamRecursively(n)
        })
      }
      collectTeamRecursively(data.node)
    }
    return teams
  }, [data])

  const deletedTeams = useMemo(() => {
    return deletedTeamsList.map(({ group, membersList }) => (
      <li key={`team-${group.id}`} className="text-size-16px font-weight-500 text-color-818e93 leading-tight mx-0 ml-5">
        {group.name}
        {' '}
        (
        {pluralize('Member', membersList.length, true)}
        )
      </li>
    ))
  }, [deletedTeamsList])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="sm"
      open={open}>

      <ModalHeader
        title="Remove Team"
        onClose={handleCloseInternal} />

      <ModalBody>

        <div className="w-full h-full px-8 pt-10">
          <div className="text-size-18px font-weight-700 text-color-fb6c6a leading-tight">This action will significantly alter your data. Read below for more details.</div>
          <div className="text-size-16px font-weight-500 text-color-818e93 leading-tight my-4">
            This action cannot be undone. This will permanently delete
            {' '}
            <span className="font-bold">{teamName}</span>
            {' '}
            — any members, data, goals and forecast history associated with it — as well as any teams below it.
          </div>
          <div className="my-4">
            <div className="text-size-16px font-weight-500 text-color-818e93 leading-tight my-1">
              Teams (
              {deletedTeamsList.length}
              )
            </div>
            <ul className="m-0 p-0 overflow-auto" style={{ listStyleType: 'disc', maxHeight: 160 }}>
              {deletedTeams}
            </ul>
          </div>
          <div className="text-size-16px font-weight-500 text-color-09242f leading-tight my-1">Confirm deletion by typing “DELETE ALL” below</div>
          <input
            className={classNames('w-full px-3 py-2 mb-6 border border-color-d6d9e6 focus:outline-none rounded')}
            value={value}
            onChange={onChange}
            autoFocus={true} />
        </div>

      </ModalBody>

      <ModalFooter>
        <Button
          text="Cancel"
          onClick={handleCloseInternal}
          backgroundColor="#a6b2cf"
          className="mx-4" />
        <Button
          text="Delete"
          onClick={onDeleteConfirmedInternal}
          className="mx-4"
          backgroundColor="#fb6c6a"
          disabled={!canDelete} />
      </ModalFooter>

    </Modal>
  )
}

export default DeleteGroupModal
