import React from 'react'
import SetupMain from './setupMain'
import { DataSyncStatusProvider } from '../../context/dataSyncStatus'

const Setup = (props) => {
  return (
    <DataSyncStatusProvider>
      <SetupMain />
    </DataSyncStatusProvider>
  )
}

export default Setup
