import React from 'react'

const Groups = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 20C0 8.96 8.96 0 20 0C31.04 0 40 8.96 40 20C40 31.04 31.04 40 20 40C8.96 40 0 31.04 0 20ZM14.9091 17.7143C17.0218 17.7143 18.7145 15.9914 18.7145 13.8571C18.7145 11.7229 17.0218 10 14.9091 10C12.7964 10 11.0909 11.7229 11.0909 13.8571C11.0909 15.9914 12.7964 17.7143 14.9091 17.7143ZM25.0909 17.7143C27.2036 17.7143 28.8964 15.9914 28.8964 13.8571C28.8964 11.7229 27.2036 10 25.0909 10C22.9782 10 21.2727 11.7229 21.2727 13.8571C21.2727 15.9914 22.9782 17.7143 25.0909 17.7143ZM6 24.7857C6 21.79 11.9436 20.2857 14.9091 20.2857C17.8745 20.2857 23.8182 21.79 23.8182 24.7857V26.7143C23.8182 27.4214 23.2455 28 22.5455 28H7.27273C6.57273 28 6 27.4214 6 26.7143V24.7857ZM25.0909 20.2857C24.7218 20.2857 24.3018 20.3114 23.8564 20.35C23.8741 20.359 23.8857 20.3742 23.8954 20.3869C23.8996 20.3925 23.9034 20.3975 23.9073 20.4014C25.3582 21.4686 26.3636 22.8957 26.3636 24.7857V26.7143C26.3636 27.1643 26.2745 27.6014 26.1345 28H32.7273C33.4273 28 34 27.4214 34 26.7143V24.7857C34 21.79 28.0564 20.2857 25.0909 20.2857Z" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="0" y1="0" x2="0" y2="40" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5951FF" />
          <stop offset="1" stopColor="#5951FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Groups
