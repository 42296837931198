import React, { useCallback, useMemo } from 'react'
import Plus from '../icons/plus'
import Edit from '../icons/edit'
import Delete from '../icons/delete'
import { usePopover } from '../../hooks/usePopover'
import ActionPopover from '../common/actionPopover'
import { useSyncState } from '../../context/syncState'
import { GroupSyncStatus } from '../../grpc/enums'
import { useTenantInfo } from '../../context/tenantInfo'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const NodeMenu = (props) => {
  const {
    node,
    path,
    treeIndex,
    onAdd,
    onEdit,
    onDelete,
  } = props

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanCreateGroup,
      permissionNames.CanUpdateGroup,
      permissionNames.CanDeleteGroup
    )
  }, [checkPermissions])

  const { crmName } = useTenantInfo()

  const { syncStatus } = useSyncState()

  const syncChangePopover = usePopover()

  const onAddInternal = useCallback((e) => {
    if (syncStatus === GroupSyncStatus.ACTIVE) {
      syncChangePopover.setAnchorEl(e.currentTarget)
    } else {
      onAdd({ node, path, treeIndex })
    }
  }, [syncStatus, node, path, treeIndex, onAdd])

  const onContinueConfirmed = useCallback(() => {
    onAdd({ node, path, treeIndex })
  }, [node, path, treeIndex, onAdd])

  const onEditInternal = useCallback(() => {
    onEdit({ node, path, treeIndex })
  }, [node, path, treeIndex, onEdit])

  const onDeleteInternal = useCallback(() => {
    onDelete({ node, path, treeIndex })
  }, [node, path, treeIndex, onDelete])

  return (
    <>
      {permissions.CanCreateGroup && (
        <button
          onClick={onAddInternal}
          className="focus:outline-none ml-1 first:ml-0 on-add">
          <Plus className="flex-shrink-0" fill="#a0a8bb" />
        </button>
      )}
      {permissions.CanUpdateGroup && (
        <button
          onClick={onEditInternal}
          className="focus:outline-none ml-1 first:ml-0 on-edit">
          <Edit className="flex-shrink-0" fill="#a0a8bb" transform="scale(0.75)" />
        </button>
      )}
      {permissions.CanDeleteGroup && (
        <button
          onClick={onDeleteInternal}
          className="focus:outline-none ml-1 first:ml-0 on-delete">
          <Delete className="flex-shrink-0" fill="#a0a8bb" transform="scale(0.75)" />
        </button>
      )}
      <ActionPopover
        popover={syncChangePopover}
        text={`You are currently syncing your both your hierarchy and your people from ${crmName}.`}
        subText={`If you continue editing groups manually, your groups will no longer update automatically when you make changes in ${crmName}.`}
        actionText="Continue"
        onAction={onContinueConfirmed}
        maxWidth={310} />
    </>
  )
}

export default NodeMenu
