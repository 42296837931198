import { InstanceSetupStatus, OnboardingStep, SandboxType } from '../grpc/enums'
import { permissionNames } from '../constants/permissionNames'
import { toGetTenantLiftoffStatusRequest, toUpdateOnboardingStateRequest } from '../grpc/converters'
import { useAuth } from './auth'
import { useGrpcCallback } from '../grpc'
import { useNotification } from '../hooks/useNotification'
import { usePermissions } from './permissions'
import { useTenantInfo } from './tenantInfo'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useUserPrefs } from './userPrefs'
import { useSandboxes } from './sandboxes'

const SetupContext = React.createContext()

export function SetupProvider({ children }) {
  const { checkPermissions } = usePermissions()
  const { tenantId, tenantContext } = useAuth()
  const { isSandbox, tenantInfo: { setupStatus } } = useTenantInfo()
  const { removeJoyrideLocalState } = useUserPrefs()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessGroup,
      permissionNames.CanAccessUser,
      permissionNames.CanAccessGoal,
      permissionNames.CanAccessSync,
      permissionNames.CanAccessSystemRole,
      permissionNames.CanAccessSandbox,
      permissionNames.CanAccessInternalAdmin,
      permissionNames.CanAccessForecasts
    )
  }, [checkPermissions])

  const { notifyError } = useNotification()

  const [isFetching, setIsFetching] = useState(true)
  const [steps, setSteps] = useState([])

  const isInternalAdmin = useMemo(() => {
    return permissions.CanAccessInternalAdmin
  }, [permissions.CanAccessInternalAdmin])

  const isComplete = useMemo(() => {
    return !!isInternalAdmin || (steps.length > 0 && steps.every(({ completed }) => !!completed))
  }, [isInternalAdmin, steps])

  useEffect(() => {
    if (isComplete) {
      removeJoyrideLocalState()
    }
  }, [isComplete, removeJoyrideLocalState])

  const getStep = useCallback((step) => {
    return steps.find((s) => s.step === step)
  }, [steps])

  const getPrevStep = useCallback((step) => {
    const index = steps.findIndex((s) => s.step === step)
    return steps[index - 1]
  }, [steps])

  const isStepComplete = useCallback((step) => {
    return isComplete || !!getStep(step)?.completed
  }, [getStep, isComplete])

  const setStepsByPermissions = useCallback((steps) => {
    setSteps(steps.filter(({ step }) => {
      switch (step) {
        case OnboardingStep.ONBOARDING_STEP_DATA_SYNC:
          return !!permissions.CanAccessSync

        case OnboardingStep.ONBOARDING_STEP_TEAMS:
          return !!permissions.CanAccessGroup

        case OnboardingStep.ONBOARDING_STEP_USERS:
          return !!permissions.CanAccessUser

        case OnboardingStep.ONBOARDING_STEP_ROLES:
          return !!permissions.CanAccessSystemRole

        case OnboardingStep.ONBOARDING_STEP_GOALS:
          return !!permissions.CanAccessGoal

        case OnboardingStep.ONBOARDING_STEP_FORECASTS:
          return !!permissions.CanAccessForecasts

        case OnboardingStep.ONBOARDING_STEP_SANDBOXES:
          return !tenantContext && permissions.CanAccessSandbox

        default:
          return true
      }
    }))
  }, [permissions, tenantContext])

  const getStatusCallback = useGrpcCallback({
    onError: () => {
      notifyError('Error getting setup status!')
      setIsFetching(false)
    },
    onSuccess: ({ status }) => {
      setIsFetching(false)
      setStepsByPermissions(status?.onboardingList || [])
    },
    grpcMethod: 'getTenantLiftoffStatus',
    debug: false,
  }, [setStepsByPermissions])

  const getStatus = useCallback(() => {
    const request = toGetTenantLiftoffStatusRequest({ tenantId })
    getStatusCallback(request)
  }, [getStatusCallback, tenantId])

  const updateStepCallback = useGrpcCallback({
    onError: (e) => {
      notifyError('Error updating step!')
      setIsFetching(false)
    },
    onSuccess: ({ onboardingList }) => {
      setIsFetching(false)
      setStepsByPermissions(onboardingList)
    },
    grpcMethod: 'updateOnboardingState',
    debug: false,
  }, [setStepsByPermissions])

  const updateStep = useCallback(({ complete, step }) => {
    const request = toUpdateOnboardingStateRequest({ complete, step, tenantId })
    updateStepCallback(request)
  }, [tenantId, updateStepCallback])

  const completeStep = useCallback((step) => {
    updateStep({ step, complete: true })
  }, [updateStep])

  useEffect(() => {
    if (!setupStatus) {
      return
    }

    // Only query for the status if in the appropriate setup state
    if (setupStatus === InstanceSetupStatus.INSTANCE_SETUP_STATUS_READY || setupStatus === InstanceSetupStatus.INSTANCE_SETUP_STATUS_ADMIN_READY) {
      getStatus()
      return
    }

    setIsFetching(false)
  }, [getStatus, setupStatus])

  const contextValue = useMemo(() => {
    return {
      completeStep,
      getStep,
      getPrevStep,
      isComplete,
      isFetching,
      isStepComplete,
      steps,
      updateStep,
    }
  }, [completeStep, getPrevStep, getStep, isComplete, isFetching, isStepComplete, steps, updateStep])

  return <SetupContext.Provider value={contextValue}>{children}</SetupContext.Provider>
}

export function useSetup() {
  const context = React.useContext(SetupContext)
  if (context === undefined) {
    throw new Error('useSetup must be used within a SetupProvider')
  }
  return context
}
