import { OnboardingStep } from '../../grpc/enums'
import { permissionNames } from '../../constants/permissionNames'
import { useGoalCategories } from '../../context/goalCategories'
import { useJoyride } from '../../context/joyride'
import { useModal } from '../../hooks/useModal'
import { usePermissions } from '../../context/permissions'
import { useSetup } from '../../context/setup'
import DownloadGoalsModal from './downloadGoalsModal'
import EmptyState from '../common/emptyState'
import Goals from '../icons/goals'
import GoalsList from './goalsList'
import Header from '../header/header'
import LoadingState from '../common/loadingState'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import UploadGoalsModal from './uploadGoalsModal'
import { goalsGuide } from '../../constants/externalUrls'
import PageDescription from '../pageDescription/pageDescription'

const GoalsMain = (props) => {
  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadGoal,
      permissionNames.CanUpdateGoal
    )
  }, [checkPermissions])

  const downloadModal = useModal()

  const uploadModal = useModal()

  const { isFetching, goalCategories } = useGoalCategories()

  const { enabled: joyrideEnabled, setJoyride, start: startJoyride, stop: stopJoyride } = useJoyride()
  const { completeStep } = useSetup()

  const onCompleteJoyride = useCallback(() => {
    completeStep(OnboardingStep.ONBOARDING_STEP_GOALS)
  }, [completeStep])

  const configureJoyride = useCallback(() => {
    setJoyride([
      {
        target: '#joyride_goals_goalsListItem .download-btn',
        title: 'Goal Templates',
        // eslint-disable-next-line max-len
        content: 'To get started, click Download Template.\n\nFill out the template according to the instructions on the first tab of the spreadsheet. Then use the Upload button to share your completed template so we can begin tracking your goals.',
        continuous: false,
        offset: -10,
        placement: 'left',
        spotlightClicks: true
      },
      {
        target: '#joyride_goals_uploadGoalBtn',
        title: 'Upload Your Goals',
        content: 'Use the Upload button to share your completed template so we can begin tracking your goals.',
        continuous: false,
        offset: 0,
        placement: 'left',
        spotlightClicks: true,
        onDismiss: onCompleteJoyride
      }
    ])
    startJoyride()
  }, [onCompleteJoyride, setJoyride, startJoyride])

  useEffect(() => {
    if (joyrideEnabled && !isFetching) {
      configureJoyride()
    }
  }, [configureJoyride, isFetching, joyrideEnabled])

  const count = useMemo(() => {
    return goalCategories.length
  }, [goalCategories])

  const showEmptyState = useMemo(() => {
    return !isFetching && count === 0
  }, [isFetching, count])

  const onUpload = useCallback(() => {
    window.analytics.track('Goal Upload Clicked')
    uploadModal.setOpen(true)
  }, [uploadModal])

  const onEnter = useCallback(() => {
    if (joyrideEnabled) {
      stopJoyride()
    }
  }, [joyrideEnabled, stopJoyride])

  const onExited = useCallback(() => {
    if (joyrideEnabled) {
      startJoyride()
    }
  }, [joyrideEnabled, startJoyride])

  return (
    <div className="flex flex-col w-full h-screen">
      <Header
        title="Goals" />

      <div className="flex-grow overflow-auto">
        <PageDescription title="Quotas & More"
          text="Use Outreach Commit to track quotas, and other custom KPIs, across your organization.
          Download the spreadsheet for the goal you’d like to track from the list below,
          fill out the targets for each team, and upload it back into Outreach Commit."
          link={goalsGuide} />

        <div className="px-10 pb-10">
          {permissions.CanReadGoal && (
            <>
              {showEmptyState
                ? (
                  <div className="flex justify-center my-10">
                    <EmptyState
                      iconControl={<Goals transform="scale(1.2)" />}
                      header="No Goals"
                      subHeader="There are no goals to display" />
                  </div>
                )
                : (
                  <>
                    {isFetching
                      ? (
                        <div className="flex justify-center my-10">
                          <LoadingState
                            header="Loading Goals"
                            subHeader="Please wait..."
                            animate={true} />
                        </div>
                      )
                      : (
                        <div className="mt-10">
                          <GoalsList
                            downloadModal={downloadModal}
                            uploadModal={uploadModal} />
                        </div>
                      )}
                  </>
                )}

              <UploadGoalsModal modal={uploadModal} TransitionProps={{ onEnter, onExited }} />
              <DownloadGoalsModal modal={downloadModal} TransitionProps={{ onEnter, onExited }} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default GoalsMain
