// source: src/common/reporting/pipeline_report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_nullable_nullable_pb = require('../../../src/common/nullable/nullable_pb.js');
goog.object.extend(proto, src_common_nullable_nullable_pb);
var src_common_param_query_param_pb = require('../../../src/common/param/query_param_pb.js');
goog.object.extend(proto, src_common_param_query_param_pb);
goog.exportSymbol('proto.loupe.common.reporting.CloseDateRange', null, global);
goog.exportSymbol('proto.loupe.common.reporting.CloseDateRangeDisplayOption', null, global);
goog.exportSymbol('proto.loupe.common.reporting.CloseDateRangeOption', null, global);
goog.exportSymbol('proto.loupe.common.reporting.DateRangeDisplayOption', null, global);
goog.exportSymbol('proto.loupe.common.reporting.DateRangeOption', null, global);
goog.exportSymbol('proto.loupe.common.reporting.GroupByDisplayOption', null, global);
goog.exportSymbol('proto.loupe.common.reporting.PipelineFilterOptionItem', null, global);
goog.exportSymbol('proto.loupe.common.reporting.PipelineFilterOptions', null, global);
goog.exportSymbol('proto.loupe.common.reporting.PipelineGroup', null, global);
goog.exportSymbol('proto.loupe.common.reporting.PipelineReport', null, global);
goog.exportSymbol('proto.loupe.common.reporting.PipelineReportGroupingOption', null, global);
goog.exportSymbol('proto.loupe.common.reporting.PipelineReportOutput', null, global);
goog.exportSymbol('proto.loupe.common.reporting.ReportOptions', null, global);
goog.exportSymbol('proto.loupe.common.reporting.RoleFieldInfo', null, global);
goog.exportSymbol('proto.loupe.common.reporting.RoleOption', null, global);
goog.exportSymbol('proto.loupe.common.reporting.RoleUserOption', null, global);
goog.exportSymbol('proto.loupe.common.reporting.TimeWindowedReportInput', null, global);
goog.exportSymbol('proto.loupe.common.reporting.UserOptions', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.PipelineReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.PipelineReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.PipelineReport.displayName = 'proto.loupe.common.reporting.PipelineReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.TimeWindowedReportInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.TimeWindowedReportInput.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.TimeWindowedReportInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.TimeWindowedReportInput.displayName = 'proto.loupe.common.reporting.TimeWindowedReportInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.CloseDateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.CloseDateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.CloseDateRange.displayName = 'proto.loupe.common.reporting.CloseDateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.PipelineReportOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.PipelineReportOutput.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.PipelineReportOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.PipelineReportOutput.displayName = 'proto.loupe.common.reporting.PipelineReportOutput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.PipelineGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.PipelineGroup.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.PipelineGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.PipelineGroup.displayName = 'proto.loupe.common.reporting.PipelineGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.ReportOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.ReportOptions.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.ReportOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.ReportOptions.displayName = 'proto.loupe.common.reporting.ReportOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.DateRangeDisplayOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.DateRangeDisplayOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.DateRangeDisplayOption.displayName = 'proto.loupe.common.reporting.DateRangeDisplayOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.GroupByDisplayOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.GroupByDisplayOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.GroupByDisplayOption.displayName = 'proto.loupe.common.reporting.GroupByDisplayOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.PipelineFilterOptionItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.PipelineFilterOptionItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.PipelineFilterOptionItem.displayName = 'proto.loupe.common.reporting.PipelineFilterOptionItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.PipelineFilterOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.PipelineFilterOptions.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.PipelineFilterOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.PipelineFilterOptions.displayName = 'proto.loupe.common.reporting.PipelineFilterOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.RoleOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.RoleOption.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.RoleOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.RoleOption.displayName = 'proto.loupe.common.reporting.RoleOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.RoleUserOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.RoleUserOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.RoleUserOption.displayName = 'proto.loupe.common.reporting.RoleUserOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.RoleFieldInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.RoleFieldInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.RoleFieldInfo.displayName = 'proto.loupe.common.reporting.RoleFieldInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.CloseDateRangeDisplayOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.reporting.CloseDateRangeDisplayOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.CloseDateRangeDisplayOption.displayName = 'proto.loupe.common.reporting.CloseDateRangeDisplayOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.reporting.UserOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.reporting.UserOptions.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.reporting.UserOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.reporting.UserOptions.displayName = 'proto.loupe.common.reporting.UserOptions';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.PipelineReport.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.PipelineReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.PipelineReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.PipelineReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    txId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: (f = msg.getCreatedAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    input: (f = msg.getInput()) && proto.loupe.common.reporting.TimeWindowedReportInput.toObject(includeInstance, f),
    output: (f = msg.getOutput()) && proto.loupe.common.reporting.PipelineReportOutput.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.PipelineReport}
 */
proto.loupe.common.reporting.PipelineReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.PipelineReport;
  return proto.loupe.common.reporting.PipelineReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.PipelineReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.PipelineReport}
 */
proto.loupe.common.reporting.PipelineReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 4:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new proto.loupe.common.reporting.TimeWindowedReportInput;
      reader.readMessage(value,proto.loupe.common.reporting.TimeWindowedReportInput.deserializeBinaryFromReader);
      msg.setInput(value);
      break;
    case 6:
      var value = new proto.loupe.common.reporting.PipelineReportOutput;
      reader.readMessage(value,proto.loupe.common.reporting.PipelineReportOutput.deserializeBinaryFromReader);
      msg.setOutput(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.PipelineReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.PipelineReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.PipelineReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.PipelineReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getInput();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.loupe.common.reporting.TimeWindowedReportInput.serializeBinaryToWriter
    );
  }
  f = message.getOutput();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loupe.common.reporting.PipelineReportOutput.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.reporting.PipelineReport.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.PipelineReport} returns this
 */
proto.loupe.common.reporting.PipelineReport.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.common.reporting.PipelineReport.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.PipelineReport} returns this
 */
proto.loupe.common.reporting.PipelineReport.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tx_id = 3;
 * @return {string}
 */
proto.loupe.common.reporting.PipelineReport.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.PipelineReport} returns this
 */
proto.loupe.common.reporting.PipelineReport.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional loupe.common.nullable.NullTimestamp created_at = 4;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.reporting.PipelineReport.prototype.getCreatedAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 4));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.reporting.PipelineReport} returns this
*/
proto.loupe.common.reporting.PipelineReport.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.PipelineReport} returns this
 */
proto.loupe.common.reporting.PipelineReport.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.PipelineReport.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TimeWindowedReportInput input = 5;
 * @return {?proto.loupe.common.reporting.TimeWindowedReportInput}
 */
proto.loupe.common.reporting.PipelineReport.prototype.getInput = function() {
  return /** @type{?proto.loupe.common.reporting.TimeWindowedReportInput} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.TimeWindowedReportInput, 5));
};


/**
 * @param {?proto.loupe.common.reporting.TimeWindowedReportInput|undefined} value
 * @return {!proto.loupe.common.reporting.PipelineReport} returns this
*/
proto.loupe.common.reporting.PipelineReport.prototype.setInput = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.PipelineReport} returns this
 */
proto.loupe.common.reporting.PipelineReport.prototype.clearInput = function() {
  return this.setInput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.PipelineReport.prototype.hasInput = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PipelineReportOutput output = 6;
 * @return {?proto.loupe.common.reporting.PipelineReportOutput}
 */
proto.loupe.common.reporting.PipelineReport.prototype.getOutput = function() {
  return /** @type{?proto.loupe.common.reporting.PipelineReportOutput} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.PipelineReportOutput, 6));
};


/**
 * @param {?proto.loupe.common.reporting.PipelineReportOutput|undefined} value
 * @return {!proto.loupe.common.reporting.PipelineReport} returns this
*/
proto.loupe.common.reporting.PipelineReport.prototype.setOutput = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.PipelineReport} returns this
 */
proto.loupe.common.reporting.PipelineReport.prototype.clearOutput = function() {
  return this.setOutput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.PipelineReport.prototype.hasOutput = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.TimeWindowedReportInput.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.TimeWindowedReportInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.TimeWindowedReportInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.TimeWindowedReportInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    startDatetime: (f = msg.getStartDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDatetime: (f = msg.getEndDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupByList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    src_common_param_query_param_pb.QueryParam.toObject, includeInstance),
    dateRangeType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    closeDateRange: (f = msg.getCloseDateRange()) && proto.loupe.common.reporting.CloseDateRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.TimeWindowedReportInput;
  return proto.loupe.common.reporting.TimeWindowedReportInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.TimeWindowedReportInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDatetime(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDatetime(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.loupe.common.reporting.PipelineReportGroupingOption>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGroupBy(values[i]);
      }
      break;
    case 4:
      var value = new src_common_param_query_param_pb.QueryParam;
      reader.readMessage(value,src_common_param_query_param_pb.QueryParam.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.common.reporting.DateRangeOption} */ (reader.readEnum());
      msg.setDateRangeType(value);
      break;
    case 6:
      var value = new proto.loupe.common.reporting.CloseDateRange;
      reader.readMessage(value,proto.loupe.common.reporting.CloseDateRange.deserializeBinaryFromReader);
      msg.setCloseDateRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.TimeWindowedReportInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.TimeWindowedReportInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.TimeWindowedReportInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartDatetime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDatetime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupByList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      src_common_param_query_param_pb.QueryParam.serializeBinaryToWriter
    );
  }
  f = message.getDateRangeType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCloseDateRange();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loupe.common.reporting.CloseDateRange.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp start_datetime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.getStartDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput} returns this
*/
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.setStartDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput} returns this
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.clearStartDatetime = function() {
  return this.setStartDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.hasStartDatetime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp end_datetime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.getEndDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput} returns this
*/
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.setEndDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput} returns this
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.clearEndDatetime = function() {
  return this.setEndDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.hasEndDatetime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated PipelineReportGroupingOption group_by = 3;
 * @return {!Array<!proto.loupe.common.reporting.PipelineReportGroupingOption>}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.getGroupByList = function() {
  return /** @type {!Array<!proto.loupe.common.reporting.PipelineReportGroupingOption>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.PipelineReportGroupingOption>} value
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput} returns this
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.setGroupByList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.loupe.common.reporting.PipelineReportGroupingOption} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput} returns this
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.addGroupBy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput} returns this
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.clearGroupByList = function() {
  return this.setGroupByList([]);
};


/**
 * repeated loupe.common.param.QueryParam filters = 4;
 * @return {!Array<!proto.loupe.common.param.QueryParam>}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.loupe.common.param.QueryParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_param_query_param_pb.QueryParam, 4));
};


/**
 * @param {!Array<!proto.loupe.common.param.QueryParam>} value
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput} returns this
*/
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.param.QueryParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.param.QueryParam}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.param.QueryParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput} returns this
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional DateRangeOption date_range_type = 5;
 * @return {!proto.loupe.common.reporting.DateRangeOption}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.getDateRangeType = function() {
  return /** @type {!proto.loupe.common.reporting.DateRangeOption} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.common.reporting.DateRangeOption} value
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput} returns this
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.setDateRangeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional CloseDateRange close_date_range = 6;
 * @return {?proto.loupe.common.reporting.CloseDateRange}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.getCloseDateRange = function() {
  return /** @type{?proto.loupe.common.reporting.CloseDateRange} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.CloseDateRange, 6));
};


/**
 * @param {?proto.loupe.common.reporting.CloseDateRange|undefined} value
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput} returns this
*/
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.setCloseDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.TimeWindowedReportInput} returns this
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.clearCloseDateRange = function() {
  return this.setCloseDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.TimeWindowedReportInput.prototype.hasCloseDateRange = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.CloseDateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.CloseDateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.CloseDateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.CloseDateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    closeDateRangeType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startDatetime: (f = msg.getStartDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDatetime: (f = msg.getEndDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.CloseDateRange}
 */
proto.loupe.common.reporting.CloseDateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.CloseDateRange;
  return proto.loupe.common.reporting.CloseDateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.CloseDateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.CloseDateRange}
 */
proto.loupe.common.reporting.CloseDateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.reporting.CloseDateRangeOption} */ (reader.readEnum());
      msg.setCloseDateRangeType(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDatetime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDatetime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.CloseDateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.CloseDateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.CloseDateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.CloseDateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloseDateRangeType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStartDatetime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDatetime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional CloseDateRangeOption close_date_range_type = 1;
 * @return {!proto.loupe.common.reporting.CloseDateRangeOption}
 */
proto.loupe.common.reporting.CloseDateRange.prototype.getCloseDateRangeType = function() {
  return /** @type {!proto.loupe.common.reporting.CloseDateRangeOption} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.reporting.CloseDateRangeOption} value
 * @return {!proto.loupe.common.reporting.CloseDateRange} returns this
 */
proto.loupe.common.reporting.CloseDateRange.prototype.setCloseDateRangeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start_datetime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.reporting.CloseDateRange.prototype.getStartDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.reporting.CloseDateRange} returns this
*/
proto.loupe.common.reporting.CloseDateRange.prototype.setStartDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.CloseDateRange} returns this
 */
proto.loupe.common.reporting.CloseDateRange.prototype.clearStartDatetime = function() {
  return this.setStartDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.CloseDateRange.prototype.hasStartDatetime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_datetime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.reporting.CloseDateRange.prototype.getEndDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.reporting.CloseDateRange} returns this
*/
proto.loupe.common.reporting.CloseDateRange.prototype.setEndDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.CloseDateRange} returns this
 */
proto.loupe.common.reporting.CloseDateRange.prototype.clearEndDatetime = function() {
  return this.setEndDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.CloseDateRange.prototype.hasEndDatetime = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.PipelineReportOutput.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.PipelineReportOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.PipelineReportOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.PipelineReportOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.PipelineReportOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    pipelineGroupsList: jspb.Message.toObjectList(msg.getPipelineGroupsList(),
    proto.loupe.common.reporting.PipelineGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.PipelineReportOutput}
 */
proto.loupe.common.reporting.PipelineReportOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.PipelineReportOutput;
  return proto.loupe.common.reporting.PipelineReportOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.PipelineReportOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.PipelineReportOutput}
 */
proto.loupe.common.reporting.PipelineReportOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.reporting.PipelineGroup;
      reader.readMessage(value,proto.loupe.common.reporting.PipelineGroup.deserializeBinaryFromReader);
      msg.addPipelineGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.PipelineReportOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.PipelineReportOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.PipelineReportOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.PipelineReportOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPipelineGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.reporting.PipelineGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PipelineGroup pipeline_groups = 1;
 * @return {!Array<!proto.loupe.common.reporting.PipelineGroup>}
 */
proto.loupe.common.reporting.PipelineReportOutput.prototype.getPipelineGroupsList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.PipelineGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.PipelineGroup, 1));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.PipelineGroup>} value
 * @return {!proto.loupe.common.reporting.PipelineReportOutput} returns this
*/
proto.loupe.common.reporting.PipelineReportOutput.prototype.setPipelineGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.reporting.PipelineGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.PipelineGroup}
 */
proto.loupe.common.reporting.PipelineReportOutput.prototype.addPipelineGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.reporting.PipelineGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.PipelineReportOutput} returns this
 */
proto.loupe.common.reporting.PipelineReportOutput.prototype.clearPipelineGroupsList = function() {
  return this.setPipelineGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.PipelineGroup.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.PipelineGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.PipelineGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.PipelineGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.PipelineGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalDeals: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endGroupsList: jspb.Message.toObjectList(msg.getEndGroupsList(),
    proto.loupe.common.reporting.PipelineGroup.toObject, includeInstance),
    opportunityIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    amountPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    dealPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    sort: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.PipelineGroup}
 */
proto.loupe.common.reporting.PipelineGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.PipelineGroup;
  return proto.loupe.common.reporting.PipelineGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.PipelineGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.PipelineGroup}
 */
proto.loupe.common.reporting.PipelineGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalDeals(value);
      break;
    case 5:
      var value = new proto.loupe.common.reporting.PipelineGroup;
      reader.readMessage(value,proto.loupe.common.reporting.PipelineGroup.deserializeBinaryFromReader);
      msg.addEndGroups(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addOpportunityIds(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmountPercentage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDealPercentage(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.PipelineGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.PipelineGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.PipelineGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.PipelineGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTotalDeals();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getEndGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.loupe.common.reporting.PipelineGroup.serializeBinaryToWriter
    );
  }
  f = message.getOpportunityIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAmountPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getDealPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.reporting.PipelineGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.PipelineGroup} returns this
 */
proto.loupe.common.reporting.PipelineGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.common.reporting.PipelineGroup.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.PipelineGroup} returns this
 */
proto.loupe.common.reporting.PipelineGroup.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double total_amount = 3;
 * @return {number}
 */
proto.loupe.common.reporting.PipelineGroup.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.reporting.PipelineGroup} returns this
 */
proto.loupe.common.reporting.PipelineGroup.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 total_deals = 4;
 * @return {number}
 */
proto.loupe.common.reporting.PipelineGroup.prototype.getTotalDeals = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.reporting.PipelineGroup} returns this
 */
proto.loupe.common.reporting.PipelineGroup.prototype.setTotalDeals = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated PipelineGroup end_groups = 5;
 * @return {!Array<!proto.loupe.common.reporting.PipelineGroup>}
 */
proto.loupe.common.reporting.PipelineGroup.prototype.getEndGroupsList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.PipelineGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.PipelineGroup, 5));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.PipelineGroup>} value
 * @return {!proto.loupe.common.reporting.PipelineGroup} returns this
*/
proto.loupe.common.reporting.PipelineGroup.prototype.setEndGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.reporting.PipelineGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.PipelineGroup}
 */
proto.loupe.common.reporting.PipelineGroup.prototype.addEndGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.reporting.PipelineGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.PipelineGroup} returns this
 */
proto.loupe.common.reporting.PipelineGroup.prototype.clearEndGroupsList = function() {
  return this.setEndGroupsList([]);
};


/**
 * repeated string opportunity_ids = 6;
 * @return {!Array<string>}
 */
proto.loupe.common.reporting.PipelineGroup.prototype.getOpportunityIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.reporting.PipelineGroup} returns this
 */
proto.loupe.common.reporting.PipelineGroup.prototype.setOpportunityIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.PipelineGroup} returns this
 */
proto.loupe.common.reporting.PipelineGroup.prototype.addOpportunityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.PipelineGroup} returns this
 */
proto.loupe.common.reporting.PipelineGroup.prototype.clearOpportunityIdsList = function() {
  return this.setOpportunityIdsList([]);
};


/**
 * optional double amount_percentage = 7;
 * @return {number}
 */
proto.loupe.common.reporting.PipelineGroup.prototype.getAmountPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.reporting.PipelineGroup} returns this
 */
proto.loupe.common.reporting.PipelineGroup.prototype.setAmountPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double deal_percentage = 8;
 * @return {number}
 */
proto.loupe.common.reporting.PipelineGroup.prototype.getDealPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.reporting.PipelineGroup} returns this
 */
proto.loupe.common.reporting.PipelineGroup.prototype.setDealPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 sort = 9;
 * @return {number}
 */
proto.loupe.common.reporting.PipelineGroup.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.reporting.PipelineGroup} returns this
 */
proto.loupe.common.reporting.PipelineGroup.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.ReportOptions.repeatedFields_ = [2,3,4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.ReportOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.ReportOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.ReportOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.ReportOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    minDatetime: (f = msg.getMinDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateRangeOptionsList: jspb.Message.toObjectList(msg.getDateRangeOptionsList(),
    proto.loupe.common.reporting.DateRangeDisplayOption.toObject, includeInstance),
    groupByOptionsList: jspb.Message.toObjectList(msg.getGroupByOptionsList(),
    proto.loupe.common.reporting.GroupByDisplayOption.toObject, includeInstance),
    filterOptionsList: jspb.Message.toObjectList(msg.getFilterOptionsList(),
    proto.loupe.common.reporting.PipelineFilterOptions.toObject, includeInstance),
    roleOptions: (f = msg.getRoleOptions()) && proto.loupe.common.reporting.RoleOption.toObject(includeInstance, f),
    roleFieldInfo: (f = msg.getRoleFieldInfo()) && proto.loupe.common.reporting.RoleFieldInfo.toObject(includeInstance, f),
    closeDateRangeOptionsList: jspb.Message.toObjectList(msg.getCloseDateRangeOptionsList(),
    proto.loupe.common.reporting.CloseDateRangeDisplayOption.toObject, includeInstance),
    userOptions: (f = msg.getUserOptions()) && proto.loupe.common.reporting.UserOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.ReportOptions}
 */
proto.loupe.common.reporting.ReportOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.ReportOptions;
  return proto.loupe.common.reporting.ReportOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.ReportOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.ReportOptions}
 */
proto.loupe.common.reporting.ReportOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMinDatetime(value);
      break;
    case 2:
      var value = new proto.loupe.common.reporting.DateRangeDisplayOption;
      reader.readMessage(value,proto.loupe.common.reporting.DateRangeDisplayOption.deserializeBinaryFromReader);
      msg.addDateRangeOptions(value);
      break;
    case 3:
      var value = new proto.loupe.common.reporting.GroupByDisplayOption;
      reader.readMessage(value,proto.loupe.common.reporting.GroupByDisplayOption.deserializeBinaryFromReader);
      msg.addGroupByOptions(value);
      break;
    case 4:
      var value = new proto.loupe.common.reporting.PipelineFilterOptions;
      reader.readMessage(value,proto.loupe.common.reporting.PipelineFilterOptions.deserializeBinaryFromReader);
      msg.addFilterOptions(value);
      break;
    case 5:
      var value = new proto.loupe.common.reporting.RoleOption;
      reader.readMessage(value,proto.loupe.common.reporting.RoleOption.deserializeBinaryFromReader);
      msg.setRoleOptions(value);
      break;
    case 6:
      var value = new proto.loupe.common.reporting.RoleFieldInfo;
      reader.readMessage(value,proto.loupe.common.reporting.RoleFieldInfo.deserializeBinaryFromReader);
      msg.setRoleFieldInfo(value);
      break;
    case 7:
      var value = new proto.loupe.common.reporting.CloseDateRangeDisplayOption;
      reader.readMessage(value,proto.loupe.common.reporting.CloseDateRangeDisplayOption.deserializeBinaryFromReader);
      msg.addCloseDateRangeOptions(value);
      break;
    case 8:
      var value = new proto.loupe.common.reporting.UserOptions;
      reader.readMessage(value,proto.loupe.common.reporting.UserOptions.deserializeBinaryFromReader);
      msg.setUserOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.ReportOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.ReportOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.ReportOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.ReportOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinDatetime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateRangeOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.common.reporting.DateRangeDisplayOption.serializeBinaryToWriter
    );
  }
  f = message.getGroupByOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.common.reporting.GroupByDisplayOption.serializeBinaryToWriter
    );
  }
  f = message.getFilterOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.loupe.common.reporting.PipelineFilterOptions.serializeBinaryToWriter
    );
  }
  f = message.getRoleOptions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.loupe.common.reporting.RoleOption.serializeBinaryToWriter
    );
  }
  f = message.getRoleFieldInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loupe.common.reporting.RoleFieldInfo.serializeBinaryToWriter
    );
  }
  f = message.getCloseDateRangeOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.loupe.common.reporting.CloseDateRangeDisplayOption.serializeBinaryToWriter
    );
  }
  f = message.getUserOptions();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.loupe.common.reporting.UserOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp min_datetime = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.reporting.ReportOptions.prototype.getMinDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
*/
proto.loupe.common.reporting.ReportOptions.prototype.setMinDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
 */
proto.loupe.common.reporting.ReportOptions.prototype.clearMinDatetime = function() {
  return this.setMinDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.ReportOptions.prototype.hasMinDatetime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DateRangeDisplayOption date_range_options = 2;
 * @return {!Array<!proto.loupe.common.reporting.DateRangeDisplayOption>}
 */
proto.loupe.common.reporting.ReportOptions.prototype.getDateRangeOptionsList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.DateRangeDisplayOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.DateRangeDisplayOption, 2));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.DateRangeDisplayOption>} value
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
*/
proto.loupe.common.reporting.ReportOptions.prototype.setDateRangeOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.reporting.DateRangeDisplayOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.DateRangeDisplayOption}
 */
proto.loupe.common.reporting.ReportOptions.prototype.addDateRangeOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.reporting.DateRangeDisplayOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
 */
proto.loupe.common.reporting.ReportOptions.prototype.clearDateRangeOptionsList = function() {
  return this.setDateRangeOptionsList([]);
};


/**
 * repeated GroupByDisplayOption group_by_options = 3;
 * @return {!Array<!proto.loupe.common.reporting.GroupByDisplayOption>}
 */
proto.loupe.common.reporting.ReportOptions.prototype.getGroupByOptionsList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.GroupByDisplayOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.GroupByDisplayOption, 3));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.GroupByDisplayOption>} value
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
*/
proto.loupe.common.reporting.ReportOptions.prototype.setGroupByOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.reporting.GroupByDisplayOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.GroupByDisplayOption}
 */
proto.loupe.common.reporting.ReportOptions.prototype.addGroupByOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.reporting.GroupByDisplayOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
 */
proto.loupe.common.reporting.ReportOptions.prototype.clearGroupByOptionsList = function() {
  return this.setGroupByOptionsList([]);
};


/**
 * repeated PipelineFilterOptions filter_options = 4;
 * @return {!Array<!proto.loupe.common.reporting.PipelineFilterOptions>}
 */
proto.loupe.common.reporting.ReportOptions.prototype.getFilterOptionsList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.PipelineFilterOptions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.PipelineFilterOptions, 4));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.PipelineFilterOptions>} value
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
*/
proto.loupe.common.reporting.ReportOptions.prototype.setFilterOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.reporting.PipelineFilterOptions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.PipelineFilterOptions}
 */
proto.loupe.common.reporting.ReportOptions.prototype.addFilterOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.reporting.PipelineFilterOptions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
 */
proto.loupe.common.reporting.ReportOptions.prototype.clearFilterOptionsList = function() {
  return this.setFilterOptionsList([]);
};


/**
 * optional RoleOption role_options = 5;
 * @return {?proto.loupe.common.reporting.RoleOption}
 */
proto.loupe.common.reporting.ReportOptions.prototype.getRoleOptions = function() {
  return /** @type{?proto.loupe.common.reporting.RoleOption} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.RoleOption, 5));
};


/**
 * @param {?proto.loupe.common.reporting.RoleOption|undefined} value
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
*/
proto.loupe.common.reporting.ReportOptions.prototype.setRoleOptions = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
 */
proto.loupe.common.reporting.ReportOptions.prototype.clearRoleOptions = function() {
  return this.setRoleOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.ReportOptions.prototype.hasRoleOptions = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RoleFieldInfo role_field_info = 6;
 * @return {?proto.loupe.common.reporting.RoleFieldInfo}
 */
proto.loupe.common.reporting.ReportOptions.prototype.getRoleFieldInfo = function() {
  return /** @type{?proto.loupe.common.reporting.RoleFieldInfo} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.RoleFieldInfo, 6));
};


/**
 * @param {?proto.loupe.common.reporting.RoleFieldInfo|undefined} value
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
*/
proto.loupe.common.reporting.ReportOptions.prototype.setRoleFieldInfo = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
 */
proto.loupe.common.reporting.ReportOptions.prototype.clearRoleFieldInfo = function() {
  return this.setRoleFieldInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.ReportOptions.prototype.hasRoleFieldInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated CloseDateRangeDisplayOption close_date_range_options = 7;
 * @return {!Array<!proto.loupe.common.reporting.CloseDateRangeDisplayOption>}
 */
proto.loupe.common.reporting.ReportOptions.prototype.getCloseDateRangeOptionsList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.CloseDateRangeDisplayOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.CloseDateRangeDisplayOption, 7));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.CloseDateRangeDisplayOption>} value
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
*/
proto.loupe.common.reporting.ReportOptions.prototype.setCloseDateRangeOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.loupe.common.reporting.CloseDateRangeDisplayOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.CloseDateRangeDisplayOption}
 */
proto.loupe.common.reporting.ReportOptions.prototype.addCloseDateRangeOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.loupe.common.reporting.CloseDateRangeDisplayOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
 */
proto.loupe.common.reporting.ReportOptions.prototype.clearCloseDateRangeOptionsList = function() {
  return this.setCloseDateRangeOptionsList([]);
};


/**
 * optional UserOptions user_options = 8;
 * @return {?proto.loupe.common.reporting.UserOptions}
 */
proto.loupe.common.reporting.ReportOptions.prototype.getUserOptions = function() {
  return /** @type{?proto.loupe.common.reporting.UserOptions} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.reporting.UserOptions, 8));
};


/**
 * @param {?proto.loupe.common.reporting.UserOptions|undefined} value
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
*/
proto.loupe.common.reporting.ReportOptions.prototype.setUserOptions = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.reporting.ReportOptions} returns this
 */
proto.loupe.common.reporting.ReportOptions.prototype.clearUserOptions = function() {
  return this.setUserOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.reporting.ReportOptions.prototype.hasUserOptions = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.DateRangeDisplayOption.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.DateRangeDisplayOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.DateRangeDisplayOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.DateRangeDisplayOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.DateRangeDisplayOption}
 */
proto.loupe.common.reporting.DateRangeDisplayOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.DateRangeDisplayOption;
  return proto.loupe.common.reporting.DateRangeDisplayOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.DateRangeDisplayOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.DateRangeDisplayOption}
 */
proto.loupe.common.reporting.DateRangeDisplayOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.reporting.DateRangeOption} */ (reader.readEnum());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.DateRangeDisplayOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.DateRangeDisplayOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.DateRangeDisplayOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.DateRangeDisplayOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.loupe.common.reporting.DateRangeDisplayOption.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.DateRangeDisplayOption} returns this
 */
proto.loupe.common.reporting.DateRangeDisplayOption.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DateRangeOption value = 2;
 * @return {!proto.loupe.common.reporting.DateRangeOption}
 */
proto.loupe.common.reporting.DateRangeDisplayOption.prototype.getValue = function() {
  return /** @type {!proto.loupe.common.reporting.DateRangeOption} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.reporting.DateRangeOption} value
 * @return {!proto.loupe.common.reporting.DateRangeDisplayOption} returns this
 */
proto.loupe.common.reporting.DateRangeDisplayOption.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.GroupByDisplayOption.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.GroupByDisplayOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.GroupByDisplayOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.GroupByDisplayOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.GroupByDisplayOption}
 */
proto.loupe.common.reporting.GroupByDisplayOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.GroupByDisplayOption;
  return proto.loupe.common.reporting.GroupByDisplayOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.GroupByDisplayOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.GroupByDisplayOption}
 */
proto.loupe.common.reporting.GroupByDisplayOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.reporting.PipelineReportGroupingOption} */ (reader.readEnum());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.GroupByDisplayOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.GroupByDisplayOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.GroupByDisplayOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.GroupByDisplayOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.loupe.common.reporting.GroupByDisplayOption.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.GroupByDisplayOption} returns this
 */
proto.loupe.common.reporting.GroupByDisplayOption.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PipelineReportGroupingOption value = 2;
 * @return {!proto.loupe.common.reporting.PipelineReportGroupingOption}
 */
proto.loupe.common.reporting.GroupByDisplayOption.prototype.getValue = function() {
  return /** @type {!proto.loupe.common.reporting.PipelineReportGroupingOption} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.reporting.PipelineReportGroupingOption} value
 * @return {!proto.loupe.common.reporting.GroupByDisplayOption} returns this
 */
proto.loupe.common.reporting.GroupByDisplayOption.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.PipelineFilterOptionItem.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.PipelineFilterOptionItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.PipelineFilterOptionItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.PipelineFilterOptionItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.PipelineFilterOptionItem}
 */
proto.loupe.common.reporting.PipelineFilterOptionItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.PipelineFilterOptionItem;
  return proto.loupe.common.reporting.PipelineFilterOptionItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.PipelineFilterOptionItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.PipelineFilterOptionItem}
 */
proto.loupe.common.reporting.PipelineFilterOptionItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.PipelineFilterOptionItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.PipelineFilterOptionItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.PipelineFilterOptionItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.PipelineFilterOptionItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.reporting.PipelineFilterOptionItem.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.PipelineFilterOptionItem} returns this
 */
proto.loupe.common.reporting.PipelineFilterOptionItem.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.loupe.common.reporting.PipelineFilterOptionItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.PipelineFilterOptionItem} returns this
 */
proto.loupe.common.reporting.PipelineFilterOptionItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.PipelineFilterOptions.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.PipelineFilterOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.PipelineFilterOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.PipelineFilterOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldtype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    delimiter: jspb.Message.getFieldWithDefault(msg, 5, ""),
    optionItemsList: jspb.Message.toObjectList(msg.getOptionItemsList(),
    proto.loupe.common.reporting.PipelineFilterOptionItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.PipelineFilterOptions}
 */
proto.loupe.common.reporting.PipelineFilterOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.PipelineFilterOptions;
  return proto.loupe.common.reporting.PipelineFilterOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.PipelineFilterOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.PipelineFilterOptions}
 */
proto.loupe.common.reporting.PipelineFilterOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldtype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addOptions(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelimiter(value);
      break;
    case 6:
      var value = new proto.loupe.common.reporting.PipelineFilterOptionItem;
      reader.readMessage(value,proto.loupe.common.reporting.PipelineFilterOptionItem.deserializeBinaryFromReader);
      msg.addOptionItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.PipelineFilterOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.PipelineFilterOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.PipelineFilterOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldtype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getDelimiter();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOptionItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.loupe.common.reporting.PipelineFilterOptionItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.PipelineFilterOptions} returns this
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field = 2;
 * @return {string}
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.PipelineFilterOptions} returns this
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fieldType = 3;
 * @return {string}
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.getFieldtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.PipelineFilterOptions} returns this
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.setFieldtype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string options = 4;
 * @return {!Array<string>}
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.getOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.reporting.PipelineFilterOptions} returns this
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.setOptionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.PipelineFilterOptions} returns this
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.addOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.PipelineFilterOptions} returns this
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional string delimiter = 5;
 * @return {string}
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.getDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.PipelineFilterOptions} returns this
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.setDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated PipelineFilterOptionItem option_items = 6;
 * @return {!Array<!proto.loupe.common.reporting.PipelineFilterOptionItem>}
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.getOptionItemsList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.PipelineFilterOptionItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.PipelineFilterOptionItem, 6));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.PipelineFilterOptionItem>} value
 * @return {!proto.loupe.common.reporting.PipelineFilterOptions} returns this
*/
proto.loupe.common.reporting.PipelineFilterOptions.prototype.setOptionItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.common.reporting.PipelineFilterOptionItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.PipelineFilterOptionItem}
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.addOptionItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.common.reporting.PipelineFilterOptionItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.PipelineFilterOptions} returns this
 */
proto.loupe.common.reporting.PipelineFilterOptions.prototype.clearOptionItemsList = function() {
  return this.setOptionItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.RoleOption.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.RoleOption.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.RoleOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.RoleOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.RoleOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    roleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roleName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.loupe.common.reporting.RoleUserOption.toObject, includeInstance),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.loupe.common.reporting.RoleOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.RoleOption}
 */
proto.loupe.common.reporting.RoleOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.RoleOption;
  return proto.loupe.common.reporting.RoleOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.RoleOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.RoleOption}
 */
proto.loupe.common.reporting.RoleOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleName(value);
      break;
    case 3:
      var value = new proto.loupe.common.reporting.RoleUserOption;
      reader.readMessage(value,proto.loupe.common.reporting.RoleUserOption.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 4:
      var value = new proto.loupe.common.reporting.RoleOption;
      reader.readMessage(value,proto.loupe.common.reporting.RoleOption.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.RoleOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.RoleOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.RoleOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.RoleOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoleName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.common.reporting.RoleUserOption.serializeBinaryToWriter
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.loupe.common.reporting.RoleOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string role_id = 1;
 * @return {string}
 */
proto.loupe.common.reporting.RoleOption.prototype.getRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.RoleOption} returns this
 */
proto.loupe.common.reporting.RoleOption.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string role_name = 2;
 * @return {string}
 */
proto.loupe.common.reporting.RoleOption.prototype.getRoleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.RoleOption} returns this
 */
proto.loupe.common.reporting.RoleOption.prototype.setRoleName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RoleUserOption users = 3;
 * @return {!Array<!proto.loupe.common.reporting.RoleUserOption>}
 */
proto.loupe.common.reporting.RoleOption.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.RoleUserOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.RoleUserOption, 3));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.RoleUserOption>} value
 * @return {!proto.loupe.common.reporting.RoleOption} returns this
*/
proto.loupe.common.reporting.RoleOption.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.reporting.RoleUserOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.RoleUserOption}
 */
proto.loupe.common.reporting.RoleOption.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.reporting.RoleUserOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.RoleOption} returns this
 */
proto.loupe.common.reporting.RoleOption.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * repeated RoleOption children = 4;
 * @return {!Array<!proto.loupe.common.reporting.RoleOption>}
 */
proto.loupe.common.reporting.RoleOption.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.RoleOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.RoleOption, 4));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.RoleOption>} value
 * @return {!proto.loupe.common.reporting.RoleOption} returns this
*/
proto.loupe.common.reporting.RoleOption.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.reporting.RoleOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.RoleOption}
 */
proto.loupe.common.reporting.RoleOption.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.reporting.RoleOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.RoleOption} returns this
 */
proto.loupe.common.reporting.RoleOption.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.RoleUserOption.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.RoleUserOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.RoleUserOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.RoleUserOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.RoleUserOption}
 */
proto.loupe.common.reporting.RoleUserOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.RoleUserOption;
  return proto.loupe.common.reporting.RoleUserOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.RoleUserOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.RoleUserOption}
 */
proto.loupe.common.reporting.RoleUserOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.RoleUserOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.RoleUserOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.RoleUserOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.RoleUserOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.reporting.RoleUserOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.RoleUserOption} returns this
 */
proto.loupe.common.reporting.RoleUserOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.loupe.common.reporting.RoleUserOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.RoleUserOption} returns this
 */
proto.loupe.common.reporting.RoleUserOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.RoleFieldInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.RoleFieldInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.RoleFieldInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.RoleFieldInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldtype: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.RoleFieldInfo}
 */
proto.loupe.common.reporting.RoleFieldInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.RoleFieldInfo;
  return proto.loupe.common.reporting.RoleFieldInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.RoleFieldInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.RoleFieldInfo}
 */
proto.loupe.common.reporting.RoleFieldInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.RoleFieldInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.RoleFieldInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.RoleFieldInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.RoleFieldInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldtype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.loupe.common.reporting.RoleFieldInfo.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.RoleFieldInfo} returns this
 */
proto.loupe.common.reporting.RoleFieldInfo.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field = 2;
 * @return {string}
 */
proto.loupe.common.reporting.RoleFieldInfo.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.RoleFieldInfo} returns this
 */
proto.loupe.common.reporting.RoleFieldInfo.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fieldType = 3;
 * @return {string}
 */
proto.loupe.common.reporting.RoleFieldInfo.prototype.getFieldtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.RoleFieldInfo} returns this
 */
proto.loupe.common.reporting.RoleFieldInfo.prototype.setFieldtype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.CloseDateRangeDisplayOption.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.CloseDateRangeDisplayOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.CloseDateRangeDisplayOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.CloseDateRangeDisplayOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.CloseDateRangeDisplayOption}
 */
proto.loupe.common.reporting.CloseDateRangeDisplayOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.CloseDateRangeDisplayOption;
  return proto.loupe.common.reporting.CloseDateRangeDisplayOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.CloseDateRangeDisplayOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.CloseDateRangeDisplayOption}
 */
proto.loupe.common.reporting.CloseDateRangeDisplayOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.reporting.CloseDateRangeOption} */ (reader.readEnum());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.CloseDateRangeDisplayOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.CloseDateRangeDisplayOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.CloseDateRangeDisplayOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.CloseDateRangeDisplayOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.loupe.common.reporting.CloseDateRangeDisplayOption.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.CloseDateRangeDisplayOption} returns this
 */
proto.loupe.common.reporting.CloseDateRangeDisplayOption.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CloseDateRangeOption value = 2;
 * @return {!proto.loupe.common.reporting.CloseDateRangeOption}
 */
proto.loupe.common.reporting.CloseDateRangeDisplayOption.prototype.getValue = function() {
  return /** @type {!proto.loupe.common.reporting.CloseDateRangeOption} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.reporting.CloseDateRangeOption} value
 * @return {!proto.loupe.common.reporting.CloseDateRangeDisplayOption} returns this
 */
proto.loupe.common.reporting.CloseDateRangeDisplayOption.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.reporting.UserOptions.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.reporting.UserOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.reporting.UserOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.reporting.UserOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.UserOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userOptionsList: jspb.Message.toObjectList(msg.getUserOptionsList(),
    proto.loupe.common.reporting.RoleUserOption.toObject, includeInstance),
    fieldtype: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.reporting.UserOptions}
 */
proto.loupe.common.reporting.UserOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.reporting.UserOptions;
  return proto.loupe.common.reporting.UserOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.reporting.UserOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.reporting.UserOptions}
 */
proto.loupe.common.reporting.UserOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 3:
      var value = new proto.loupe.common.reporting.RoleUserOption;
      reader.readMessage(value,proto.loupe.common.reporting.RoleUserOption.deserializeBinaryFromReader);
      msg.addUserOptions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.reporting.UserOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.reporting.UserOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.reporting.UserOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.reporting.UserOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.common.reporting.RoleUserOption.serializeBinaryToWriter
    );
  }
  f = message.getFieldtype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.loupe.common.reporting.UserOptions.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.UserOptions} returns this
 */
proto.loupe.common.reporting.UserOptions.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field = 2;
 * @return {string}
 */
proto.loupe.common.reporting.UserOptions.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.UserOptions} returns this
 */
proto.loupe.common.reporting.UserOptions.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RoleUserOption user_options = 3;
 * @return {!Array<!proto.loupe.common.reporting.RoleUserOption>}
 */
proto.loupe.common.reporting.UserOptions.prototype.getUserOptionsList = function() {
  return /** @type{!Array<!proto.loupe.common.reporting.RoleUserOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.reporting.RoleUserOption, 3));
};


/**
 * @param {!Array<!proto.loupe.common.reporting.RoleUserOption>} value
 * @return {!proto.loupe.common.reporting.UserOptions} returns this
*/
proto.loupe.common.reporting.UserOptions.prototype.setUserOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.reporting.RoleUserOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.reporting.RoleUserOption}
 */
proto.loupe.common.reporting.UserOptions.prototype.addUserOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.reporting.RoleUserOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.reporting.UserOptions} returns this
 */
proto.loupe.common.reporting.UserOptions.prototype.clearUserOptionsList = function() {
  return this.setUserOptionsList([]);
};


/**
 * optional string fieldType = 4;
 * @return {string}
 */
proto.loupe.common.reporting.UserOptions.prototype.getFieldtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.reporting.UserOptions} returns this
 */
proto.loupe.common.reporting.UserOptions.prototype.setFieldtype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.reporting.DateRangeOption = {
  CUSTOM: 0,
  CURRENT_WEEK: 1,
  PREVIOUS_WEEK: 2,
  CURRENT_MONTH: 3,
  PREVIOUS_MONTH: 4,
  CURRENT_QUARTER: 5,
  PREVIOUS_QUARTER: 6
};

/**
 * @enum {number}
 */
proto.loupe.common.reporting.PipelineReportGroupingOption = {
  FORECASTCATEGORY: 0,
  STAGENAME: 1
};

/**
 * @enum {number}
 */
proto.loupe.common.reporting.CloseDateRangeOption = {
  CUSTOM: 0,
  REPORT: 1,
  PERIOD: 2
};

goog.object.extend(exports, proto.loupe.common.reporting);
