import React from 'react'
import { ScenarioPlannerSettingsProvider } from '../../context/scenarioPlannerSettings'
import ScenarioPlannerSettingsMain from './scenarioPlannerSettingsMain'

const ScenarioPlannerSettings = (props) => {
  return (
    <ScenarioPlannerSettingsProvider>
      <ScenarioPlannerSettingsMain />
    </ScenarioPlannerSettingsProvider>
  )
}

export default ScenarioPlannerSettings
