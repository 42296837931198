import React from 'react'
import { useGoalCategories } from '../../context/goalCategories'
import GoalListItem from './goalListItem'

const GoalsList = (props) => {
  const {
    downloadModal,
    uploadModal
  } = props

  const { goalCategories } = useGoalCategories()

  return (
    <>
      <div className="flex-table">
        <div className="thead">
          <div className="tr">
            <div className="td w-48">Name</div>
            <div className="td w-10 text-right" />
          </div>
        </div>
        <div className="tbody">
          {goalCategories.map((goalCategory, index) => (
            <GoalListItem
              key={`GoalListItem-${goalCategory.key}-${index}`}
              goalCategory={goalCategory}
              downloadModal={downloadModal}
              uploadModal={uploadModal}
              {...index === 0 && { id: 'joyride_goals_goalsListItem' }}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default GoalsList
