import {
  FilterCombiner,
  FieldType,
  ComparisonOp
} from '../../protos/src/common/realtime/query_pb'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

export const useFilterCombiner = () => {
  const AND = {
    key: FilterCombiner.AND,
    label: 'And'
  }

  const OR = {
    key: FilterCombiner.OR,
    label: 'Or'
  }

  const getCombiner = (id) => {
    if (id === FilterCombiner.OR) {
      return OR
    }
    return AND
  }

  return {
    getCombiner,
    AND,
    OR
  }
}

export const useComparisonOp = () => {
  const EQUAL_TO = {
    key: ComparisonOp.COMPARISON_OP_EQ,
    label: 'Equals',
    inputTypes: [
      InputType.INPUT,
      InputType.SELECT
    ]
  }
  const NOT_EQUAL_TO = {
    key: ComparisonOp.COMPARISON_OP_NEQ,
    label: 'Not Equal To',
    inputTypes: [
      InputType.INPUT,
      InputType.SELECT
    ]
  }
  const LESS_THAN = {
    key: ComparisonOp.COMPARISON_OP_LT,
    label: 'Less Than',
    inputTypes: [
      InputType.INPUT,
      InputType.SELECT
    ]
  }
  const GREATER_THAN = {
    key: ComparisonOp.COMPARISON_OP_GT,
    label: 'Greater Than',
    inputTypes: [
      InputType.INPUT,
      InputType.SELECT
    ]
  }
  const GREATER_THAN_EQUAL_TO = {
    key: ComparisonOp.COMPARISON_OP_GE,
    label: 'Greater Than Equal To',
    inputTypes: [
      InputType.INPUT,
      InputType.SELECT
    ]
  }
  const LESS_THAN_EQUAL_TO = {
    key: ComparisonOp.COMPARISON_OP_LE,
    label: 'Less Than Equal To',
    inputTypes: [
      InputType.INPUT,
      InputType.SELECT
    ]
  }
  const BETWEEN = {
    key: ComparisonOp.COMPARISON_OP_BETWEEN,
    label: 'Between',
    inputTypes: [
      InputType.RANGE
    ]
  }
  const NOT_BETWEEN = {
    key: ComparisonOp.COMPARISON_OP_OUTSIDE,
    label: 'Not Between',
    inputTypes: [
      InputType.RANGE
    ]
  }
  const IN = {
    key: ComparisonOp.COMPARISON_OP_IN,
    label: 'In',
    inputTypes: [
      InputType.MULTI_INPUT,
      InputType.MULTI_SELECT
    ]
  }
  const NOT_IN = {
    key: ComparisonOp.COMPARISON_OP_NIN,
    label: 'Not In',
    inputTypes: [
      InputType.MULTI_INPUT,
      InputType.MULTI_SELECT
    ]
  }
  const REGEX = {
    key: ComparisonOp.COMPARISON_OP_REGEX,
    label: 'Contains',
    inputTypes: [
      InputType.INPUT
    ]
  }
  const NOT_REGEX = {
    key: ComparisonOp.COMPARISON_OP_NREGEX,
    label: 'Does Not Contain',
    inputTypes: [
      InputType.INPUT
    ]
  }

  const getComparisonOp = (id) => {
    switch (id) {
      case EQUAL_TO.key:
        return EQUAL_TO
      case NOT_EQUAL_TO.key:
        return NOT_EQUAL_TO
      case LESS_THAN.key:
        return LESS_THAN
      case GREATER_THAN.key:
        return GREATER_THAN
      case GREATER_THAN_EQUAL_TO.key:
        return GREATER_THAN_EQUAL_TO
      case LESS_THAN_EQUAL_TO.key:
        return LESS_THAN_EQUAL_TO
      case BETWEEN.key:
        return BETWEEN
      case NOT_BETWEEN.key:
        return NOT_BETWEEN
      case IN.key:
        return IN
      case NOT_IN.key:
        return NOT_IN
      case REGEX.key:
        return REGEX
      case NOT_REGEX.key:
        return NOT_REGEX
      default:
        return EQUAL_TO
    }
  }

  const operators = {
    EQUAL_TO,
    NOT_EQUAL_TO,
    LESS_THAN,
    GREATER_THAN,
    GREATER_THAN_EQUAL_TO,
    LESS_THAN_EQUAL_TO,
    BETWEEN,
    NOT_BETWEEN,
    IN,
    NOT_IN,
    REGEX,
    NOT_REGEX
  }

  return {
    operators,
    ...operators,
    getComparisonOp
  }
}

export const useFieldType = () => {
  const { operators } = useComparisonOp()

  const UNSPECIFIED = {
    key: FieldType.FIELD_TYPE_UNSPECIFIED,
    comparisonOps: []
  }
  const STRING = {
    key: FieldType.FIELD_TYPE_STRING,
    comparisonOps: [
      operators.EQUAL_TO,
      operators.NOT_EQUAL_TO,
      operators.IN,
      operators.NOT_IN,
      operators.REGEX,
      operators.NOT_REGEX
    ]
  }
  const INT = {
    key: FieldType.FIELD_TYPE_INT,
    comparisonOps: [
      operators.EQUAL_TO,
      operators.NOT_EQUAL_TO,
      operators.LESS_THAN,
      operators.GREATER_THAN,
      operators.GREATER_THAN_EQUAL_TO,
      operators.LESS_THAN_EQUAL_TO,
      operators.BETWEEN,
      operators.NOT_BETWEEN
    ]
  }
  const FLOAT = {
    key: FieldType.FIELD_TYPE_FLOAT,
    comparisonOps: [
      operators.EQUAL_TO,
      operators.NOT_EQUAL_TO,
      operators.LESS_THAN,
      operators.GREATER_THAN,
      operators.GREATER_THAN_EQUAL_TO,
      operators.LESS_THAN_EQUAL_TO,
      operators.BETWEEN,
      operators.NOT_BETWEEN
    ]
  }
  const BOOL = {
    key: FieldType.FIELD_TYPE_BOOL,
    options: [{
      label: 'True',
      value: 'true'
    },
    {
      label: 'False',
      value: 'false'
    }],
    comparisonOps: [
      operators.EQUAL_TO,
      operators.NOT_EQUAL_TO
    ]
  }
  const DATE = {
    key: FieldType.FIELD_TYPE_DATE,
    comparisonOps: [
      operators.EQUAL_TO,
      operators.NOT_EQUAL_TO,
      operators.LESS_THAN,
      operators.GREATER_THAN,
      operators.GREATER_THAN_EQUAL_TO,
      operators.LESS_THAN_EQUAL_TO,
      operators.BETWEEN,
      operators.NOT_BETWEEN
    ]
  }
  const TIMESTAMP = {
    key: FieldType.FIELD_TYPE_TIMESTAMP,
    comparisonOps: [
      operators.EQUAL_TO,
      operators.NOT_EQUAL_TO,
      operators.LESS_THAN,
      operators.GREATER_THAN,
      operators.GREATER_THAN_EQUAL_TO,
      operators.LESS_THAN_EQUAL_TO,
      operators.BETWEEN,
      operators.NOT_BETWEEN
    ]
  }
  const RAW = {
    key: FieldType.FIELD_TYPE_RAW,
    comparisonOps: []
  }
  const ARRAY = {
    key: FieldType.FIELD_TYPE_ARRAY,
    comparisonOps: [
      operators.IN,
      operators.NOT_IN,
    ]
  }
  const OBJECT = {
    key: FieldType.FIELD_TYPE_OBJECT,
    comparisonOps: []
  }

  const fieldTypes = {
    UNSPECIFIED,
    STRING,
    INT,
    FLOAT,
    BOOL,
    DATE,
    TIMESTAMP,
    RAW,
    ARRAY,
    OBJECT
  }

  const getFieldType = (field) => {
    const { fieldType } = field ?? {}
    if (!fieldType) {
      return
    }
    return Object.values(fieldTypes).find(({ key }) => key === fieldType)
  }

  return {
    ...fieldTypes,
    getFieldType
  }
}

export const useInputFormat = () => {
  const { formatCurrency } = useFormatCurrency()

  const formattingRules = {
    CURRENCY: {
      key: 'currency',
      pattern: '^\\d*(\\.\\d{0,2})?$',
      re: new RegExp('^\\d*(\\.\\d{0,2})?$', 'gi'),
      applyFormat: formatCurrency,
      // defaultValue: 0
    },
    INT: {
      key: 'int',
      pattern: '^\\d+',
      re: new RegExp('^\\d+$', 'gi'),
      // defaultValue: 0
    },
    FLOAT: {
      key: 'float',
      pattern: '(^\\d*$)|(^\\d?\\.\\d+$)',
      re: new RegExp('(^\\d*$)|(^\\d?\\.\\d+$)', 'gi'),
      // defaultValue: 0
    },
    PERCENT: {
      key: 'percent',
      pattern: '(^\\d{1,2}$)|(^\\d{0,2}\\.\\d{0,2}$)',
      re: new RegExp('(^\\d{1,2}$)|(^\\d{0,2}\\.\\d{0,2}$)', 'gi'),
      applyFormat: (v) => `${v}%`,
      // defaultValue: 0
    }
  }

  const formats = {
    ANY: {
      fieldType: FieldType.FIELD_TYPE_UNSPECIFIED,
      formats: []
    },
    BASE64: {
      fieldType: FieldType.FIELD_TYPE_BYTES,
      formats: []
    },
    BOOLEAN: {
      fieldType: FieldType.FIELD_TYPE_BOOL,
      formats: []
    },
    BYTES: {
      fieldType: FieldType.FIELD_TYPE_RAW,
      formats: []
    },
    DATE: {
      fieldType: FieldType.FIELD_TYPE_DATE,
      formats: [
        'date'
      ]
    },
    DATETIME: {
      fieldType: FieldType.FIELD_TYPE_TIMESTAMP,
      formats: [
        'date'
      ]
    },
    FLOAT: {
      fieldType: FieldType.FIELD_TYPE_FLOAT,
      formats: [
        'float',
        'currency',
        'percent'
      ]
    },
    INTEGER: {
      fieldType: FieldType.FIELD_TYPE_INT,
      formats: [
        'currency',
        'int',
        'percent'
      ]
    },
    LOCATION: {
      fieldType: 'LOCATION',
      formats: [
        'location'
      ]
    },
    OBJECT: {
      fieldType: FieldType.FIELD_TYPE_OBJECT,
      formats: []
    },
    STRING: {
      fieldType: FieldType.FIELD_TYPE_STRING,
      formats: [
        'email',
        'plaintextarea',
        'richtextarea',
        'select',
        'text',
        'url'
      ]
    },
    TIMESTAMP: {
      fieldType: FieldType.FIELD_TYPE_TIMESTAMP,
      formats: [
        'date',
        'timestamp'
      ]
    }
  }

  const getInputFormat = (field) => {
    const { fieldType } = field ?? {}
    if (!fieldType) {
      return
    }
    return Object.values(formats).find((format) => format.fieldType === fieldType)
  }

  const getFormattingRules = (key) => {
    if (!key) {
      return
    }
    return Object.values(formattingRules).find((rule) => rule.key === key)
  }

  return {
    getInputFormat,
    getFormattingRules,
    ...formats,
    formats
  }
}

export const InputType = {
  INPUT: 0,
  MULTI_INPUT: 1,
  SELECT: 2,
  MULTI_SELECT: 3,
  RANGE: 4
}
