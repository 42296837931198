import React from 'react'
import Label from '../common/label'
import Info from '../icons/info'
import Tooltip from '../common/tooltip'

const FieldLabel = (props) => {
  const {
    label,
    tooltip,
  } = props

  return (
    <div className="flex items-center">
      <Label text={label} />
      {tooltip
        && (
          <Tooltip placement="top" arrow={true} title={tooltip}>
            <div style={{ transform: 'translateY(-2px)' }}>
              <Info fill="#818e93" transform="scale(0.8)" />
            </div>
          </Tooltip>
        )}
    </div>
  )
}

export default FieldLabel
