import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { useGrpcCallback } from '../grpc'
import { toResolveObjectReferenceFieldOptionsRequest } from '../grpc/converters'
import { useCache } from './cache'
import { useAuth } from './auth'

const cacheContext = 'referenceFieldOptionsLabels'
const lazyLoadCacheKeyDelimiter = '+'
const ReferenceFieldOptionsContext = React.createContext()

export function ReferenceFieldOptionsLabelsProvider({ isEnabled, fieldReference, lazyLoadValues = [], cacheContext, children }) {
  const { tenantId } = useAuth()
  const { hasCache, setCache, getCache } = useCache()
  const [key, setKey] = useState(guid())
  const [referenceFields, setReferenceFields] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const isProviderEnabled = useMemo(() => {
    return isEnabled && !!fieldReference?.objectName
  }, [isEnabled, fieldReference])

  const objectName = useMemo(() => {
    return fieldReference?.objectName ?? ''
  }, [fieldReference])

  const displayFieldName = useMemo(() => {
    return fieldReference?.displayFieldName ?? ''
  }, [fieldReference])

  const resolveObjectReferenceFieldOptions = useGrpcCallback({
    onSuccess: (obj) => {
      const { resultsList = [] } = obj
      const { resolvedItemsList = [] } = resultsList[0] || {}
      setReferenceFields(resolvedItemsList)
      setIsFetching(false)
      setCache(cacheContext, lazyLoadValues.join(lazyLoadCacheKeyDelimiter), resolvedItemsList)
    },
    onError: (err) => {
      setIsFetching(false)
    },
    onFetch: () => {
      setIsFetching(true)
    },
    grpcMethod: 'resolveObjectReferenceFieldOptions',
    debug: false
  }, [setCache, lazyLoadValues])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  useEffect(() => {
    const values = lazyLoadValues.filter((f) => !!f)
    if (isProviderEnabled && values.length > 0) {
      const lazyLoadCacheKey = values.join(lazyLoadCacheKeyDelimiter)
      if (hasCache(cacheContext, lazyLoadCacheKey)) {
        setReferenceFields(getCache(cacheContext, lazyLoadCacheKey))
        setIsFetching(false)
      } else {
        const request = toResolveObjectReferenceFieldOptionsRequest({
          itemsList: [
            {
              referenceObjectKey: objectName,
              referenceObjectLabelField: displayFieldName,
              keysList: [
                ...values
              ]
            }
          ],
          tenantId
        })
        resolveObjectReferenceFieldOptions(request)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, isProviderEnabled, objectName, displayFieldName, lazyLoadValues, tenantId])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      referenceFields,
      invalidate
    }
  }, [isFetching, referenceFields, invalidate])

  return <ReferenceFieldOptionsContext.Provider value={contextValue}>{children}</ReferenceFieldOptionsContext.Provider>
}

export function useReferenceFieldOptionsLabels() {
  const context = React.useContext(ReferenceFieldOptionsContext)
  if (context === undefined) {
    throw new Error('useReferenceFieldOptionsLabels must be used within a ReferenceFieldOptionsLabelsProvider')
  }
  return context
}
