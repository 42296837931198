import React from 'react'
import { ForecastingSettingsProvider } from '../../context/forecastingSettings'
import { ObjectDefinitionProvider } from '../../context/objectDefinition'
import ForecastingSettingsMain from './forecastingSettingsMain'

const ForecastingSettings = (props) => {
  return (
    <ObjectDefinitionProvider objectName="opportunity">
      <ForecastingSettingsProvider>
        <ForecastingSettingsMain />
      </ForecastingSettingsProvider>
    </ObjectDefinitionProvider>
  )
}

export default ForecastingSettings
