import React from 'react'

const Circle = (props) => {
  const {
    fill = '#ffffff',
  } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" {...{ ...props, fill }}>
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <path d="M12,2C6.47,2,2,6.47,2,12s4.47,10,10,10s10-4.47,10-10S17.53,2,12,2z" />
        </g>
      </g>
    </svg>
  )
}

export default Circle
