import {
  format,
  formatDistanceToNow,
  fromUnixTime,
  getUnixTime,
  parse,
  parseISO,
} from 'date-fns'

export function getTimestampFromDate(date) {
  return {
    seconds: getUnixTime(date),
    nanos: 0,
  }
}

export function getTimestamp(strValue, fmt = 'yyyy-MM-dd') {
  const date = parse(strValue, fmt, new Date())
  return getTimestampFromDate(date)
}

export function secondsToDate(seconds, ignoreTimezoneOffset = true) {
  const date = fromUnixTime(seconds)
  const dateStr = date.toISOString()
  const parsedDate = parseISO(dateStr)
  return ignoreTimezoneOffset ? new Date(parsedDate.valueOf() + parsedDate.getTimezoneOffset() * 60 * 1000) : parsedDate
}

export function secondsToFormattedDate(date, ignoreTimezoneOffset = true, fmt = 'yyyy-MM-dd') {
  return format(secondsToDate(date, ignoreTimezoneOffset), fmt)
}

export function secondsToDistanceToNow(seconds, ignoreTimezoneOffset = true) {
  return formatDistanceToNow(secondsToDate(seconds, ignoreTimezoneOffset), { addSuffix: true })
}

export function parseDate(strValue, format = 'yyyy-MM-dd') {
  return parse(strValue, 'yyyy-MM-dd', new Date())
}

export function reformatDate(strValue, inputFormat = 'yyyy-MM-dd', outputFormat = 'M/d/yyyy') {
  try {
    return format(parseDate(strValue, inputFormat), outputFormat)
  } catch (err) {
    return ''
  }
}
