import { ApiClient as ApiClientCl } from '../protos/src/services/api/api_grpc_web_pb'
import { GetTenantRegion } from '../context/auth'

const maxSize = 4294967295 // Math.pow(2, 32) - 1
const options = {
  'grpc.max_receive_message_length': maxSize,
  'grpc.max_send_message_length': maxSize,
}

const API_CLIENT_EU = new ApiClientCl(process.env.EU_GRPC_URL, undefined, options)
const API_CLIENT = new ApiClientCl(process.env.GRPC_URL, undefined, options)

export const ApiServiceClient = (api) => {
  const actingTenantRegion = GetTenantRegion()
  if (actingTenantRegion.toLowerCase() === 'eu' || api?.toLowerCase() === 'eu') {
    return API_CLIENT_EU
  }
  return API_CLIENT
}

export const getApiServiceClientWithPrefix = (prefix) => {
  const actingTenantRegion = GetTenantRegion()
  if (actingTenantRegion.toLowerCase() === 'eu') {
    return new ApiClientCl(`${process.env.EU_GRPC_URL}/${prefix}`, undefined, options)
  }
  return new ApiClientCl(`${process.env.GRPC_URL}/${prefix}`, undefined, options)
}
