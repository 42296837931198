import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toGetReportingSettingRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useAuth } from './auth'

const ReportingOptionsSettingsContext = React.createContext()

export function ReportingOptionsSettingsProvider({ children }) {
  const { tenantId } = useAuth()

  const { notifyError } = useNotification()

  const [isFetching, setIsFetching] = useState(true)
  const [fetchError, setFetchError] = useState(false)
  const [key, setKey] = useState(guid())
  const [settings, setSettings] = useState(undefined)

  useGrpcEffect({
    request: toGetReportingSettingRequest({
      tenantId,
    }),
    onError: () => {
      setIsFetching(false)
      setFetchError(true)
      notifyError('Error fetching Analytics options!')
    },
    onSuccess: (obj) => {
      setSettings(obj.setting)
      setIsFetching(false)
    },
    onFetch: () => {
      setIsFetching(true)
      setFetchError(false)
    },
    grpcMethod: 'getReportingOptionsSetting',
    debug: false,
  }, [tenantId, key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      fetchError,
      settings,
      setSettings,
      key,
      invalidate,
    }
  }, [isFetching, fetchError, settings])

  return <ReportingOptionsSettingsContext.Provider value={contextValue}>{children}</ReportingOptionsSettingsContext.Provider>
}

export function useReportingOptionsSettings() {
  const context = React.useContext(ReportingOptionsSettingsContext)
  if (context === undefined) {
    throw new Error('useReportingOptionsSettings must be used within a ReportingOptionsSettingsProvider')
  }
  return context
}
