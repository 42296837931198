import { useForecastingConfig } from '../../../context/forecastingConfig'
import { useForecastingConfigColumn } from '../../../context/forecastingConfigColumn'
import { useModal } from '../../../hooks/useModal'
import ActionModal from '../../common/actionModal'
import classNames from 'classnames'
import Input from '../../common/input'
import Label from '../../common/label'
import Radio from '../../common/radio'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import Switch from '../../common/switch'
import SelectList from '../../common/selectList'
import { useCanonicalObjectMapping } from '../../../context/canonicalObjectMapping'
import { permissionNames } from '../../../constants/permissionNames'
import { usePermissions } from '../../../context/permissions'

const ForecastConfigCategoriesModalGeneral = (props) => {
  const modal = useModal()
  const { handleClose } = modal
  const { checkPermissions } = usePermissions()

  const { config } = useForecastingConfig()
  const { objectSettings } = config
  const { rollupField: rollupFieldConfig, periodEvaluationField: dateFieldConfig } = objectSettings

  const { fieldsListIntsAndFloats: amountOptions = [],
    fieldsListDates: dateOptions = [],
    isFetching: isFetchingOptions } = useCanonicalObjectMapping()

  const {
    configColumn,
    updateConfigColumn,
  } = useForecastingConfigColumn()

  const { forecastConfigValueSettings = {} } = configColumn
  const { objectValueSettings = {} } = forecastConfigValueSettings
  const { rollupField, periodEvaluationField: dateField } = objectValueSettings

  const {
    name: columnName = '',
    readOnly,
    autoCalculate,
    displayFormula } = configColumn

  const [selectedAmount, setSelectedAmount] = useState('')
  const [selectedDate, setSelectedDate] = useState('')

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessInternalAdmin
    )
  }, [checkPermissions])

  useEffect(() => {
    const columnAmount = amountOptions.find((opt) => opt.toName === rollupField)?.value
    const configAmount = amountOptions.find((opt) => opt.toName === rollupFieldConfig)?.value
    const defaultAmount = amountOptions.find((opt) => opt.toName === 'amount')?.value
    if (amountOptions && amountOptions.length > 0) {
      if (rollupField && columnAmount) {
        setSelectedAmount(columnAmount)
      } else if (rollupFieldConfig && configAmount) {
        setSelectedAmount(configAmount)
      } else {
        setSelectedAmount(defaultAmount)
      }
    }
  }, [amountOptions, rollupFieldConfig, rollupField])

  useEffect(() => {
    const columnDateField = dateOptions.find((opt) => opt.toName === dateField)?.value
    const configDateField = dateOptions.find((opt) => opt.toName === dateFieldConfig)?.value
    const defaultDateField = dateOptions.find((opt) => opt.toName === 'closeDate')?.value
    if (dateOptions && dateOptions.length > 0) {
      if (rollupField && columnDateField) {
        setSelectedDate(columnDateField)
      } else if (rollupFieldConfig && configDateField) {
        setSelectedDate(configDateField)
      } else {
        setSelectedDate(defaultDateField)
      }
    }
  }, [dateOptions, dateField, dateFieldConfig, rollupField, rollupFieldConfig])

  const setColumnName = useCallback(({ target }) => {
    const { value } = target
    updateConfigColumn({ name: value })
  }, [updateConfigColumn])

  const setAutoCalculate = useCallback(({ target }) => {
    const { checked } = target
    updateConfigColumn({ autoCalculate: checked })
  }, [updateConfigColumn])

  const setRollupAmount = useCallback((amount) => {
    setSelectedAmount(amount.value)
    updateConfigColumn({
      forecastConfigValueSettings: {
        objectValueSettings: {
          ...objectValueSettings,
          rollupField: amount.toName
        }
      }
    })
  }, [objectValueSettings, updateConfigColumn])

  const setRollupDate = useCallback((date) => {
    setSelectedDate(date.value)
    updateConfigColumn({
      forecastConfigValueSettings: {
        objectValueSettings: {
          ...objectValueSettings,
          periodEvaluationField: date.toName
        }
      }
    })
  }, [objectValueSettings, updateConfigColumn])

  const setReadOnly = useCallback(({ target }) => {
    const { value } = target
    const isReadOnly = value === 'true'
    isReadOnly === false && displayFormula && displayFormula.latexFormula !== '' ? modal.setOpen(true) : modal.setOpen(false)
    updateConfigColumn({
      readOnly: isReadOnly,
      autoCalculate: isReadOnly
    })
  }, [displayFormula, modal, updateConfigColumn])

  const onCancel = useCallback(() => {
    updateConfigColumn({
      readOnly: true,
      autoCalculate: true
    })
    handleClose()
  }, [handleClose, updateConfigColumn])

  const onDisableFormulas = useCallback(() => {
    updateConfigColumn({ displayFormula: undefined }, { saveAfterUpdate: true })
    handleClose()
  }, [handleClose, updateConfigColumn])

  return (
    <>
      <div className="mb-2">
        <Label text="Metric Name" className="mb-2" />
        <Input
          className="mb-6"
          onChange={setColumnName}
          placeholder="Name (required)"
          value={columnName}
        />
      </div>

      {permissions.CanAccessInternalAdmin && (
        <div className="mb-2 mt-2 w-2/3">
          <div className="flex flex-row mb-2">
            <Label text="Field" className="" />
            <Label text="System Admin" className="mt-1 ml-2 text-size-12px text-color-a6b2cf uppercase tracking-widest" />
          </div>
          <Label
            text="Choose which amount field to display in this metric."
            className="font-weight-400 w-full text-color-51636a" />
          <SelectList
            className="mb-4 w-1/2"
            isFetching={isFetchingOptions}
            onChange={setRollupAmount}
            options={amountOptions}
            value={selectedAmount} />
          <div className="flex flex-row mb-2">
            <Label text="Date" className="" />
            <Label text="System Admin" className="mt-1 ml-2 text-size-12px text-color-a6b2cf uppercase tracking-widest" />
          </div>
          <Label
            text="Choose the dates of the opportunities attached to this metric."
            className="font-weight-400 w-full text-color-51636a" />
          <SelectList
            className="mb-4 w-1/2"
            isFetching={isFetchingOptions}
            onChange={setRollupDate}
            options={dateOptions}
            value={selectedDate} />
        </div>
      )}

      <div className={classNames('mb-4')}>
        <Label text="Forecast Entry" className="mb-2" />
        <Label
          text="Choose whether this metric will be one your teams will forecast on."
          className="font-weight-400 w-96 text-color-51636a" />
        <div className="flex flex-row items-start mb-4">
          <Radio
            checked={(!readOnly)}
            onChange={setReadOnly}
            value="false"
          />
          <div className="ml-2">
            <Label text="Yes" />
            <Label
              text="This will allow your teams to edit this metric."
              className="font-weight-400 w-96 text-color-51636a" />
          </div>
        </div>
        <div className="flex flex-row items-start mb-4">
          <Radio
            checked={(typeof readOnly === 'boolean' && readOnly)}
            onChange={setReadOnly}
            size="small"
            value="true"
          />
          <div className="ml-2">
            <Label text="No" />
            <Label
              text="This metric will be read-only."
              className="font-weight-400 w-96 text-color-51636a" />
          </div>
        </div>
      </div>

      <div className={classNames('mb-4', { 'opacity-50': readOnly })}>
        <Label text="Automatically Calculate Pipeline Value" />
        <Label
          text="Values input manually will be overridden with the auto-calculated value of this metric."
          className="font-weight-400 w-120 text-color-51636a" />
        <div className="flex flex-row items-center">
          <Switch
            disabled={readOnly}
            className="mt-1"
            onChange={setAutoCalculate}
            checked={(autoCalculate ?? false)} />
          <Label text={autoCalculate ? 'On' : 'Off'} className="mt-1" />
        </div>
      </div>
      <ActionModal
        modal={modal}
        title="Disable Formulas"
        text="Making forecast entries editable will remove your existing formulas. This cannot be undone."
        subText="Do you want to continue?"
        actionText="Yes"
        onCancel={onCancel}
        onAction={onDisableFormulas} />
    </>
  )
}

export default ForecastConfigCategoriesModalGeneral
