import React, { useRef, useState, useEffect, useCallback } from 'react'
import Edit from '../icons/edit'
import useKeyPressEffect from '../../hooks/useKeyPressEffect'
import classNames from 'classnames'
import { Popper } from '@material-ui/core'

const EditableText = (props) => {
  const {
    onComplete,
    onChange,
    disabled = false,
    required = false,
    text,
    validate,
    invalidNote,
    placeholder = '',
    className,
    requiredNote = 'This field cannot be empty'
  } = props

  const [isEditing, setIsEditing] = useState(false)
  const [valueInternal, setValueInternal] = useState('')
  const [fieldError, setFieldError] = useState(null)

  const inputRef = useRef()

  const onChangeInternal = useCallback(({ target }) => {
    const { value } = target
    let isValid = false
    if (value && required) {
      isValid = true
    }
    if (isValid && validate && validate?.(target.value)) {
      isValid = true
    }
    if (isValid) {
      setFieldError(null)
    }
    setValueInternal(value)
    onChange?.(target.value)
  }, [onChange, validate])

  const onBlurInternal = useCallback((e) => {
    const { target } = e
    if (required && !target.value) {
      e.preventDefault()
      setFieldError(requiredNote)
      return
    }
    if (validate && !validate(target.value)) {
      e.preventDefault()
      setFieldError(invalidNote)
      return
    }
    setFieldError(null)
    onComplete?.(target.value)
    setIsEditing(false)
  }, [onComplete, required, validate, invalidNote])

  const onEdit = useCallback(() => {
    setIsEditing(true)
  }, [])

  useEffect(() => {
    setValueInternal(text)
  }, [text])

  useKeyPressEffect({
    targetKey: 'Enter',
    eventTarget: [inputRef.current],
    onDown: onBlurInternal
  }, [inputRef.current])

  return (
    <div className={`${className} flex`}>
      {isEditing
        ? (
          <>
            <input
              ref={inputRef}
              autoFocus={true}
              type={text}
              className={classNames(
                `${className} flex flex-1 mt-4`,
                { 'border border-color-fb6c6a': !!fieldError }
              )}
              onChange={onChangeInternal}
              onBlur={onBlurInternal}
              value={valueInternal}
              required={required}
            />
            <Popper
              style={{
                zIndex: 10000
              }}
              className="bg-color-ffffff shadow-lg rounded"
              open={!!fieldError}
              anchorEl={inputRef.current}
              placement="bottom-start">
              <div className="p-4">{fieldError}</div>
            </Popper>
          </>
        )
        : (
          <>
            {disabled
              ? (
                <div className={classNames(`${className} flex group items-center`)}>
                  {valueInternal}
                </div>
              )
              : (
                <button onClick={onEdit} className={`${className} flex group items-center`}>
                  {valueInternal
                    ? (
                      <div className={classNames(`${className} flex group items-center`)}>
                        {valueInternal}
                      </div>
                    )
                    : (
                      <div className={classNames(`${className} flex group items-center text-color-c9ced0`)}>
                        {placeholder}
                      </div>
                    )}
                  <Edit className="group-hover:visible invisible ml-3" fill="#000" transform="scale(0.6)" />
                </button>
              )}
          </>
        )}
    </div>
  )
}

export default EditableText
