import { Add, Delete, Edit, Lock } from '@getoutreach/react-icons'
import { DndProvider } from 'react-dnd'
import { ForecastConfigStatus, ForecastConfigValueFormat, ForecastConfigColumnDisplayVisibilityOption } from '../../grpc/enums'
import { ForecastingConfigColumnProvider } from '../../context/forecastingConfigColumn'
import { forecastModes, forecastPeriodLengths, forecastConfigColumnDisplayOptions, defaultForecastConfigColumnDisplayOption } from './constants'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Link, useHistory } from 'react-router-dom'
import { ObjectDefinitionProvider } from '../../context/objectDefinition'
import { permissionNames } from '../../constants/permissionNames'
import { useCanonicalObjectMapping } from '../../context/canonicalObjectMapping'
import { useDebug } from '../../context/debug'
import { useDependencySearch } from './hooks'
import { useForecastingConfig } from '../../context/forecastingConfig'
import { useModal } from '../../hooks/useModal'
import { usePermissions } from '../../context/permissions'
import { usePopover } from '../../hooks/usePopover'
import { useRoutes } from '../../context/routes'
import { useTextField } from '../../hooks/useTextField'
import { v4 as guid } from 'uuid'
import ActionModal from '../common/actionModal'
import ActionPopover from '../common/actionPopover'
import BlockNavigation from '../common/blockNavigation'
import Button from '../common/button'
import CustomDragLayer from '../dragAndDrop/customDragLayer'
import ExclamationTriangle from '../icons/exclamationTriangle'
import ForecastConfigCategoriesModal from './forecastConfigCategoriesModal'
import ForecastConfigColumn, { dragRenderer } from './forecastConfigColumn'
import ForecastConfigMenu from './forecastConfigMenu'
import ForecastConfigPreviewModal from './forecastConfigPreviewModal'
import ForecastVisibilityControl from './forecastVisibilityControl'
import Header from '../header/header'
import Input from '../common/input'
import Label from '../common/label'
import LoadingState from '../common/loadingState'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SearchBox from '../common/searchBox'
import SelectList from '../common/selectList'
import Tooltip from '../common/tooltip'
import Info from '../icons/info'
import LoadingStateIcon from '../common/loadingStateIcon'
import Refresh from '../icons/refresh'

const commitUrl = process.env.CANOPY_URL

const ForecastMain = () => {
  const { checkPermissions } = usePermissions()
  const { routes } = useRoutes()
  const history = useHistory()
  const deleteConfigColumnPopover = usePopover()
  const deleteConfigColumnConstraintPopover = usePopover()
  const modal = useModal()
  const previewModal = useModal()
  const publishModal = useModal()
  const deleteModal = useModal()
  const search = useTextField()

  const {
    config,
    configColumns,
    createConfigColumn,
    deleteConfigColumn,
    hasUnsavedChanges,
    isFetching,
    isAddingCategory,
    publishConfig,
    reorderConfigColumns,
    resetPivotGroups,
    saveConfigAndPivotGroups,
    setConfigColumns,
    setConfigStatus,
    didSave,
    isSaving
  } = useForecastingConfig()

  const {
    fieldsListStrings: ownerOptions = [{ value: 0, label: 'Owner' }],
    isFetching: isFetchingOptions
  } = useCanonicalObjectMapping()

  const { isDependency } = useDependencySearch(configColumns)

  const mode = forecastModes[0].value

  const prevLength = useRef(undefined)

  const { debug, grpcPrefix } = useDebug()

  const periodOptions = useMemo(() => {
    return forecastPeriodLengths
  }, [])

  const [name, setName] = useState('')
  const [deleteConfirmed, setDeleteConfirmed] = useState(false)

  const [periodLength, setPeriodLength] = useState(periodOptions[0].value)
  const [selectedOwner, setSelectedOwner] = useState('')
  const [autoFocus, setAutoFocus] = useState(true)
  const [pivotGroupsKey, setPivotGroupsKey] = useState(guid())
  const [formInit, setFormInit] = useState(false)
  const [circularDependencies, setCircularDependencies] = useState([])

  useEffect(() => {
    setCircularDependencies([])
    try {
      configColumns.forEach(isDependency)
    } catch (e) {
      console.log(e)
      setCircularDependencies(e.dependencies.map(({ id }) => id))
    }
  }, [configColumns, isDependency])

  const isActiveIsh = useMemo(() => {
    if (!config) {
      return
    }
    const { status } = config
    return status === ForecastConfigStatus.PENDING || status === ForecastConfigStatus.ENABLED
  }, [config])

  const canPublish = useMemo(() => {
    if (!config) {
      return
    }
    const { columnsList, pivotGroupsList, hasPublishableChanges } = config
    return isActiveIsh && columnsList.length && pivotGroupsList.length && hasPublishableChanges
  }, [config, isActiveIsh])

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanManageForecasts,
      permissionNames.CanAccessInternalAdmin
    )
  }, [checkPermissions])

  const setFormData = useCallback((config) => {
    setName(config.name)
    setPeriodLength(config.periodLength)
    config.objectSettings?.ownershipField ?? setSelectedOwner(config.objectSettings?.ownershipField)
    resetPivotGroups(config.pivotGroupsList)
    setPivotGroupsKey(guid())
  }, [resetPivotGroups])

  // Hydrate state
  useEffect(() => {
    if (config && !formInit) {
      setFormData(config)
      setFormInit(true)
    }
  }, [config, formInit, setFormData])

  useEffect(() => {
    const configOwner = ownerOptions.find((opt) => opt.toName === config?.objectSettings?.ownershipField)?.value
    const defaultOwner = ownerOptions.find((opt) => opt.toName === 'owner')?.value
    if (ownerOptions && ownerOptions.length > 0) {
      if (config?.objectSettings?.ownershipField && configOwner) {
        setSelectedOwner(configOwner)
      } else if (defaultOwner) {
        setSelectedOwner(defaultOwner)
      } else {
        setSelectedOwner(ownerOptions[0].value)
      }
    }
  }, [ownerOptions, config?.objectSettings?.ownershipField])

  useEffect(() => {
    if (!configColumns.length) {
      return
    }

    prevLength.current = configColumns.length
    setConfigColumns((columns) => columns.sort(({ sort: a }, { sort: b }) => a - b))
  }, [configColumns, setConfigColumns])

  const hasLocalUnsavedChanges = useMemo(() => {
    if (isFetching || isFetchingOptions) {
      return false
    }

    const { ownershipField } = config?.objectSettings ?? {}
    const owner = ownerOptions.find((opt) => opt.value === selectedOwner)?.toName
    return (name !== config?.name
      || periodLength !== config?.periodLength
      || owner !== ownershipField
      || hasUnsavedChanges)
  }, [isFetching, isFetchingOptions, config?.objectSettings, config?.name, config?.periodLength, ownerOptions, name, periodLength, hasUnsavedChanges, selectedOwner])

  /** Handlers */
  const onAddCategory = useCallback(() => {
    createConfigColumn({
      status: ForecastConfigStatus.ENABLED,
      name: 'New Metric',
      sort: configColumns.length + 1,
      forecastConfigId: config.id,
      forecastConfigValueSettings: {
        objectValueSettings: {
          rollupFieldFormatEnum: ForecastConfigValueFormat.CURRENCY
        }
      }
    })
  }, [configColumns, createConfigColumn, config])

  const onChangeName = useCallback((evt) => {
    setName(evt.target.value)
  }, [])

  const onChangePeriodLength = useCallback(({ value }) => {
    setPeriodLength(value)
  }, [])

  const onConfirmDeleteConfigColumn = useCallback((column) => {
    deleteConfigColumn(column)
  }, [deleteConfigColumn])

  const onConfirmDeleteForecast = useCallback(() => {
    setDeleteConfirmed(true)
    setConfigStatus({
      forecastConfigId: config?.draftParentId || config.id,
      status: ForecastConfigStatus.DELETED
    })
    deleteModal.setOpen(false)
  }, [setConfigStatus, config, deleteModal])

  const onDelete = useCallback(() => {
    deleteModal.setOpen(true)
  }, [deleteModal])

  const onDeleteConfigColumn = useCallback((evt, column) => {
    const dependencyOf = isDependency(column)
    if (dependencyOf.length) {
      deleteConfigColumnConstraintPopover.setData({ references: dependencyOf })
      deleteConfigColumnConstraintPopover.setAnchorEl(evt.currentTarget)
      return
    }
    deleteConfigColumnPopover.setAnchorEl(evt.currentTarget)
  }, [deleteConfigColumnConstraintPopover, deleteConfigColumnPopover, isDependency])

  const onDiscardChanges = useCallback(() => {
    setFormData(config)
  }, [config, setFormData])

  const onEditConfigColumn = useCallback((column, selectGroup) => {
    modal.setData({ column, selectGroup })
    modal.setOpen(true)
  }, [modal])

  const onMove = useCallback((dragIndex, hoverIndex) => {
    const dragItem = configColumns[dragIndex]
    const hoverItem = configColumns[hoverIndex]
    setConfigColumns((columns) => {
      const updatedColumnsList = columns
      updatedColumnsList[dragIndex] = hoverItem
      updatedColumnsList[hoverIndex] = dragItem
      return updatedColumnsList.map((column, index) => ({ ...column, sort: index }))
    })
  }, [configColumns, setConfigColumns])

  const onSave = useCallback(() => {
    const request = {
      name,
      periodLength,
      mode,
      objectSettings: {
        ...config?.objectSettings,
        ownershipField: ownerOptions.find((opt) => opt.value === selectedOwner)?.toName
      }
    }

    setAutoFocus(false)
    saveConfigAndPivotGroups(request)
    reorderConfigColumns(configColumns)
  }, [name, periodLength, mode, saveConfigAndPivotGroups, config?.objectSettings, ownerOptions, reorderConfigColumns, configColumns, selectedOwner])

  const onPreview = useCallback(() => {
    const debugHash = debug ? '#debug' : ''
    const grpcPrefixParam = grpcPrefix ? `&grpc_prefix=${grpcPrefix}` : ''
    const url = `${commitUrl}/preview/forecasting/${config?.id}?nocache=${new Date().getTime()}${grpcPrefixParam}${debugHash}`

    previewModal.setData({ url })
    previewModal.setOpen(true)
  }, [previewModal, config, debug, grpcPrefix])

  const isInvalidMetric = useCallback((column) => {
    return !column.isManaged
      && column.readOnly
      && (!column.displayFormula || column.displayFormula?.latexFormula === '')
      && (!column.forecastConfigValueSettings?.filter || column.forecastConfigValueSettings?.filter?.filters?.nestedList?.length === 0)
  }, [])

  const onChangeOwner = useCallback((owner) => {
    setSelectedOwner(owner.value)
  }, [])

  const onPublish = useCallback(() => {
    publishModal.setOpen(true)
  }, [publishModal])

  const onPublishInternal = useCallback((e) => {
    publishConfig(config)
    window.analytics.track('Publish Forecast Clicked')
    publishModal.setOpen(false)
  }, [config, publishModal, publishConfig])

  const onCancel = useCallback(() => {
    publishModal.setOpen(false)
  }, [publishModal])

  return (
    <div className="flex flex-col w-full h-screen">
      <Header
        breadcrumbControl={(
          <div className="text-size-12px text-color-a6b2cf font-normal tracking-widest leading-none" style={{ transform: 'translateY(-4px)' }}>
            <Link to={routes.forecasts}>Forecasts</Link>
          </div>
        )}
        onBackClick={() => history.push(routes.forecasts)}
        showBackButton
        title={name}
      />

      <div className="flex-grow overflow-auto p-10">
        <div className="p-6 bg-color-ffffff border border-color-2e5bff-08 rounded-lg">
          <div className="text-size-24px text-color-09242f font-weight-700">Forecast Details</div>
          <div className="text-size-16px text-color-51636a font-weight-400 leading-tight mt-4">
            Set this forecast’s name, how deals are added to it, who can see it, and more.
          </div>
        </div>

        <div className="flex items-center justify-between mt-8 mb-3">
          <div className="text-size-20px text-color-09242f font-weight-700">Forecast Configuration</div>
          <div className="flex items-center justify-between">
            {permissions.CanManageForecasts && (
              <>
                <Button
                  text="Preview"
                  disabled={hasLocalUnsavedChanges}
                  onClick={onPreview}
                  variant="text" />
                <Button
                  text="Save"
                  disabled={!name || !hasLocalUnsavedChanges}
                  onClick={onSave}
                  {...isSaving && {
                    text: (
                      <div className="flex items-center">
                        <LoadingStateIcon animate dense style={{ width: 18, height: 18, marginRight: 4 }} />
                        {' '}
                        Saving
                      </div>
                    )
                  }}
                  {...didSave && {
                    text: 'Saved'
                  }} />
                <ForecastConfigMenu
                  canPublish={canPublish}
                  hasChanges={hasLocalUnsavedChanges}
                  onDiscardChanges={onDiscardChanges}
                  onDelete={onDelete}
                  onPublish={onPublish} />
              </>
            )}
          </div>
        </div>

        {isFetching
          ? (
            <div className="flex justify-center my-10">
              <LoadingState
                header="Loading Forecast"
                subHeader="Please wait..."
                animate={true} />
            </div>
          )
          : (
            <div className="w-full max-w-md">
              <Label text="Forecast Name" />
              <Input
                autoFocus={autoFocus}
                className="mb-6"
                onChange={onChangeName}
                placeholder="Name"
                value={name}
              />
              <div className="mb-6">
                <Label text="Forecast Period" />
                <SelectList
                  onChange={onChangePeriodLength}
                  options={periodOptions}
                  value={periodLength} />
              </div>
              {permissions.CanAccessInternalAdmin && (
                <div className="mb-2 mt-2">
                  <div className="flex flex-row">
                    <Label text="Owner" />
                    <Label
                      text="System Admin"
                      className="mt-1 ml-2 text-size-12px text-color-a6b2cf uppercase tracking-widest" />
                  </div>
                  <Label
                    text="Choose who owns the opportunities attached to this forecast."
                    className="font-weight-400 w-full text-color-51636a" />
                  <SelectList
                    className="mb-4 w-1/2"
                    isFetching={isFetchingOptions}
                    onChange={onChangeOwner}
                    options={ownerOptions}
                    value={selectedOwner} />
                </div>
              )}
              <div className="mb-6">
                <div className="flex flex-row">
                  <Label text="Metrics in Forecast" />
                  {configColumns.length === 0 && (
                    <Tooltip
                      arrow
                      placement="right"
                      title="At least one metric must be added before this forecast can be published.">
                      <Info htmlColor="#5951FF" transform="scale(0.8)" />
                    </Tooltip>
                  )}
                </div>
                {!!circularDependencies.length && (
                  <div
                    className="flex border border-color-fa6d6b rounded items-center justify-center bg-color-ffffff text-size-16px text-color-fa6d6b font-weight-600 p-2 my-2">
                    <ExclamationTriangle fill="#fa6d6b" transform="scale(1.5)" className="m-3" />
                    Circualr dependencies detected in forecast metrics, please review formulas of those indicated below.
                  </div>
                )}
                <div className="my-4 relative">
                  <DndProvider backend={HTML5Backend}>
                    <CustomDragLayer itemRenderer={dragRenderer} data={{ forecastCategories: configColumns }} />
                    {configColumns.map((column, index) => {
                      const displayConfig = forecastConfigColumnDisplayOptions.find((option) => option.value === column.displayOption)

                      let label = displayConfig?.label ?? defaultForecastConfigColumnDisplayOption.label
                      if (column.visibilityOption === ForecastConfigColumnDisplayVisibilityOption.IC) {
                        label = 'Reps Only'
                      }
                      return (
                        <ForecastingConfigColumnProvider
                          key={`forecastConfigColumn-${column.id}`}
                          configId={config?.id}
                          column={column}>
                          <ForecastConfigColumn
                            column={column}
                            id={column.id}
                            index={index}
                            name={column.name}
                            label={label}
                            onMove={onMove}>
                            <div className="flex flex-row">
                              {permissions.CanManageForecasts && (
                                <div className="flex items-center invisible group-hover:visible">
                                  {column.isManaged ? (
                                    <Tooltip arrow placement="top" title="This metric is managed by Outreach.">
                                      <span className="ml-1 first:ml-0">
                                        <Lock className="flex-shrink-0 text-color-a0a8bb" transform="scale(0.75)" />
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    <button className="focus:outline-none ml-1 first:ml-0" onClick={() => onEditConfigColumn(column)}>
                                      <Edit className="flex-shrink-0 text-color-a0a8bb" transform="scale(0.75)" />
                                    </button>
                                  )}
                                  <button className="focus:outline-none ml-1 first:ml-0" onClick={(e) => onDeleteConfigColumn(e, column)}>
                                    <Delete className="flex-shrink-0 text-color-a0a8bb" transform="scale(0.75)" />
                                  </button>
                                  <ActionPopover
                                    disablePortal
                                    popover={deleteConfigColumnPopover}
                                    text="Delete this metric?"
                                    subText="This metric will be permanently deleted. This can’t be undone."
                                    actionText="Delete"
                                    onAction={onConfirmDeleteConfigColumn.bind(this, column)}
                                    maxWidth={285}
                                    anchorOrigin={{
                                      vertical: 'center',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'center',
                                      horizontal: 'left',
                                    }}
                                  />
                                  <ActionPopover
                                    disablePortal
                                    popover={deleteConfigColumnConstraintPopover}
                                    text="Metric is a Constraint"
                                    subText={(
                                      <div>
                                        This metric is being used in the following formulas:
                                        <ul className="list-disc m-3">
                                          {deleteConfigColumnConstraintPopover.data?.references?.map((col) => (
                                            <li key={`config-col-constraint-${col.id}`}>{col.name}</li>
                                          ))}
                                        </ul>
                                        Remove it from the formula and try again.
                                      </div>
                                      )}
                                    actionText="Go To Formula"
                                    onAction={onEditConfigColumn.bind(this, deleteConfigColumnConstraintPopover.data?.references?.[0], 'formulas')}
                                    maxWidth={285}
                                    anchorOrigin={{
                                      vertical: 'center',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'center',
                                      horizontal: 'left',
                                    }}
                                  />
                                </div>
                              )}
                              {isInvalidMetric(column) && (
                                <div className="absolute ml-28 mt-1">
                                  <Tooltip
                                    arrow
                                    placement="right"
                                    title="Read-only metrics must have a filter or a formula before this forecast can be published.">
                                    <Info htmlColor="#5951FF" transform="scale(0.8)" />
                                  </Tooltip>
                                </div>
                              )}
                              {circularDependencies.includes(column.id) && (
                                <div className="absolute ml-28 mt-1">
                                  <ExclamationTriangle fill="#fa6d6b" transform="scale(1)" />
                                </div>
                              )}
                            </div>
                          </ForecastConfigColumn>
                        </ForecastingConfigColumnProvider>
                      )
                    })}
                  </DndProvider>
                </div>
                {permissions.CanManageForecasts && (
                  <button
                    disabled={isAddingCategory}
                    className="flex items-center text-color-5951FF text-size-14px font-weight-600 mt-2"
                    onClick={onAddCategory}>
                    {isAddingCategory
                      ? (<Refresh style={{ fontSize: 16 }} fill="#5951FF" className="animate-spin" />)
                      : (<Add style={{ fontSize: 16 }} className="mr-1" />)}
                    Add Metric
                  </button>
                )}
              </div>
              <div className="mb-6">
                <Label text={config?.pivotGroupsList.length === 0
                  ? (
                    <div>
                      {' '}
                      Visibility
                      <Tooltip
                        arrow
                        placement="right"
                        title="At least one group must be assigned before this forecast can be published.">
                        <Info htmlColor="#5951FF" transform="scale(0.8)" />
                      </Tooltip>
                    </div>
                  )
                  : 'Visibility'}
                  subText="Only teams you select will be able to view this forecast."
                />
                <SearchBox
                  className="my-2"
                  value={search.value}
                  enableKeyboard={false}
                  onChange={search.onChange}
                  onClear={search.reset}
                />
                <div className="flex flex-col mb-2 px-2 rounded-sm bg-color-ffffff border border-color-d6d9e6 overflow-auto" style={{ height: 180 }}>
                  <ForecastVisibilityControl
                    invalidateKey={pivotGroupsKey}
                    configId={config?.id}
                    pivotGroupsList={config?.pivotGroupsList}
                    search={search.value} />
                </div>
              </div>
            </div>
          )}

        <ActionModal
          slim
          modal={deleteModal}
          title="Delete this forecast?"
          text="This forecast won’t be visible to any teams, and will be permanently deleted from this list."
          actionText="Delete"
          actionProps={{ backgroundColor: '#fb6c6a' }}
          onAction={onConfirmDeleteForecast}
        />

        <ActionModal
          slim
          modal={publishModal}
          title="Publish this forecast?"
          text={(
            <>
              This forecast will be visible to any team you’ve selected.
            </>
          )}
          actionText="Publish"
          onCancel={onCancel}
          onAction={onPublishInternal}
        />

        <ForecastConfigPreviewModal modal={previewModal} />

        <ObjectDefinitionProvider objectName="opportunity">
          <ForecastingConfigColumnProvider configId={config?.id} column={modal.data?.column}>
            <ForecastConfigCategoriesModal modal={modal} />
          </ForecastingConfigColumnProvider>
        </ObjectDefinitionProvider>

        <BlockNavigation
          actionModalProps={{ slim: true }}
          canBlock={!deleteConfirmed && hasLocalUnsavedChanges}
          promptTitle="Discard your changes?"
          promptText="If you leave this page without saving, any changes made will be lost."
          promptCancelText="Keep Working"
          promptActionText="Discard" />
      </div>
    </div>
  )
}

export default ForecastMain
