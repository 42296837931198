// source: src/common/person/person.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_quota_quota_summary_pb = require('../../../src/common/quota/quota_summary_pb.js');
goog.object.extend(proto, src_common_quota_quota_summary_pb);
var src_common_person_trend_container_pb = require('../../../src/common/person/trend_container_pb.js');
goog.object.extend(proto, src_common_person_trend_container_pb);
var src_common_person_sales_stats_pb = require('../../../src/common/person/sales_stats_pb.js');
goog.object.extend(proto, src_common_person_sales_stats_pb);
var src_common_goals_goals_pb = require('../../../src/common/goals/goals_pb.js');
goog.object.extend(proto, src_common_goals_goals_pb);
goog.exportSymbol('proto.loupe.common.person.Person', null, global);
goog.exportSymbol('proto.loupe.common.person.PersonGoalContainer', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.person.Person = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.person.Person.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.person.Person, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.person.Person.displayName = 'proto.loupe.common.person.Person';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.person.PersonGoalContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.person.PersonGoalContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.person.PersonGoalContainer.displayName = 'proto.loupe.common.person.PersonGoalContainer';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.person.Person.repeatedFields_ = [19,23,25,27,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.person.Person.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.person.Person.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.person.Person} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.person.Person.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    companyName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    title: jspb.Message.getFieldWithDefault(msg, 8, ""),
    email: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    lastLoginDate: (f = msg.getLastLoginDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    managerId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 13, ""),
    photoUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    createdDate: (f = msg.getCreatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastModifiedDate: (f = msg.getLastModifiedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    quota: jspb.Message.getFieldWithDefault(msg, 17, 0),
    teamId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    quotaDataList: jspb.Message.toObjectList(msg.getQuotaDataList(),
    src_common_quota_quota_summary_pb.QuotaSummary.toObject, includeInstance),
    activityTrend: (f = msg.getActivityTrend()) && src_common_person_trend_container_pb.TrendContainer.toObject(includeInstance, f),
    salesStats: (f = msg.getSalesStats()) && src_common_person_sales_stats_pb.SalesStats.toObject(includeInstance, f),
    hasChildren: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    versionsList: jspb.Message.toObjectList(msg.getVersionsList(),
    proto.loupe.common.person.Person.toObject, includeInstance),
    role: jspb.Message.getFieldWithDefault(msg, 24, ""),
    goalDataList: jspb.Message.toObjectList(msg.getGoalDataList(),
    proto.loupe.common.person.PersonGoalContainer.toObject, includeInstance),
    rank: jspb.Message.getFieldWithDefault(msg, 26, 0),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.loupe.common.person.Person.toObject, includeInstance),
    groupViewerIdsList: (f = jspb.Message.getRepeatedField(msg, 28)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.person.Person}
 */
proto.loupe.common.person.Person.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.person.Person;
  return proto.loupe.common.person.Person.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.person.Person} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.person.Person}
 */
proto.loupe.common.person.Person.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastLoginDate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoUrl(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedDate(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModifiedDate(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuota(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamId(value);
      break;
    case 19:
      var value = new src_common_quota_quota_summary_pb.QuotaSummary;
      reader.readMessage(value,src_common_quota_quota_summary_pb.QuotaSummary.deserializeBinaryFromReader);
      msg.addQuotaData(value);
      break;
    case 20:
      var value = new src_common_person_trend_container_pb.TrendContainer;
      reader.readMessage(value,src_common_person_trend_container_pb.TrendContainer.deserializeBinaryFromReader);
      msg.setActivityTrend(value);
      break;
    case 21:
      var value = new src_common_person_sales_stats_pb.SalesStats;
      reader.readMessage(value,src_common_person_sales_stats_pb.SalesStats.deserializeBinaryFromReader);
      msg.setSalesStats(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasChildren(value);
      break;
    case 23:
      var value = new proto.loupe.common.person.Person;
      reader.readMessage(value,proto.loupe.common.person.Person.deserializeBinaryFromReader);
      msg.addVersions(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 25:
      var value = new proto.loupe.common.person.PersonGoalContainer;
      reader.readMessage(value,proto.loupe.common.person.PersonGoalContainer.deserializeBinaryFromReader);
      msg.addGoalData(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 27:
      var value = new proto.loupe.common.person.Person;
      reader.readMessage(value,proto.loupe.common.person.Person.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupViewerIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.person.Person.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.person.Person.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.person.Person} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.person.Person.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getLastLoginDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getManagerId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPhotoUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCreatedDate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastModifiedDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getQuota();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getTeamId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getQuotaDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      src_common_quota_quota_summary_pb.QuotaSummary.serializeBinaryToWriter
    );
  }
  f = message.getActivityTrend();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      src_common_person_trend_container_pb.TrendContainer.serializeBinaryToWriter
    );
  }
  f = message.getSalesStats();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      src_common_person_sales_stats_pb.SalesStats.serializeBinaryToWriter
    );
  }
  f = message.getHasChildren();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.loupe.common.person.Person.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getGoalDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.loupe.common.person.PersonGoalContainer.serializeBinaryToWriter
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.loupe.common.person.Person.serializeBinaryToWriter
    );
  }
  f = message.getGroupViewerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      28,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string company_name = 7;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string title = 8;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string email = 9;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_active = 10;
 * @return {boolean}
 */
proto.loupe.common.person.Person.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp last_login_date = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.person.Person.prototype.getLastLoginDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.person.Person} returns this
*/
proto.loupe.common.person.Person.prototype.setLastLoginDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.clearLastLoginDate = function() {
  return this.setLastLoginDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.person.Person.prototype.hasLastLoginDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string manager_id = 12;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getManagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setManagerId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string timezone = 13;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string photo_url = 14;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getPhotoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setPhotoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional google.protobuf.Timestamp created_date = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.person.Person.prototype.getCreatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.person.Person} returns this
*/
proto.loupe.common.person.Person.prototype.setCreatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.clearCreatedDate = function() {
  return this.setCreatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.person.Person.prototype.hasCreatedDate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp last_modified_date = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.person.Person.prototype.getLastModifiedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.person.Person} returns this
*/
proto.loupe.common.person.Person.prototype.setLastModifiedDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.clearLastModifiedDate = function() {
  return this.setLastModifiedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.person.Person.prototype.hasLastModifiedDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional int64 quota = 17;
 * @return {number}
 */
proto.loupe.common.person.Person.prototype.getQuota = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setQuota = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string team_id = 18;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getTeamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * repeated loupe.common.quota.QuotaSummary quota_data = 19;
 * @return {!Array<!proto.loupe.common.quota.QuotaSummary>}
 */
proto.loupe.common.person.Person.prototype.getQuotaDataList = function() {
  return /** @type{!Array<!proto.loupe.common.quota.QuotaSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_quota_quota_summary_pb.QuotaSummary, 19));
};


/**
 * @param {!Array<!proto.loupe.common.quota.QuotaSummary>} value
 * @return {!proto.loupe.common.person.Person} returns this
*/
proto.loupe.common.person.Person.prototype.setQuotaDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.loupe.common.quota.QuotaSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.quota.QuotaSummary}
 */
proto.loupe.common.person.Person.prototype.addQuotaData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.loupe.common.quota.QuotaSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.clearQuotaDataList = function() {
  return this.setQuotaDataList([]);
};


/**
 * optional TrendContainer activity_trend = 20;
 * @return {?proto.loupe.common.person.TrendContainer}
 */
proto.loupe.common.person.Person.prototype.getActivityTrend = function() {
  return /** @type{?proto.loupe.common.person.TrendContainer} */ (
    jspb.Message.getWrapperField(this, src_common_person_trend_container_pb.TrendContainer, 20));
};


/**
 * @param {?proto.loupe.common.person.TrendContainer|undefined} value
 * @return {!proto.loupe.common.person.Person} returns this
*/
proto.loupe.common.person.Person.prototype.setActivityTrend = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.clearActivityTrend = function() {
  return this.setActivityTrend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.person.Person.prototype.hasActivityTrend = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional SalesStats sales_stats = 21;
 * @return {?proto.loupe.common.person.SalesStats}
 */
proto.loupe.common.person.Person.prototype.getSalesStats = function() {
  return /** @type{?proto.loupe.common.person.SalesStats} */ (
    jspb.Message.getWrapperField(this, src_common_person_sales_stats_pb.SalesStats, 21));
};


/**
 * @param {?proto.loupe.common.person.SalesStats|undefined} value
 * @return {!proto.loupe.common.person.Person} returns this
*/
proto.loupe.common.person.Person.prototype.setSalesStats = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.clearSalesStats = function() {
  return this.setSalesStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.person.Person.prototype.hasSalesStats = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional bool has_children = 22;
 * @return {boolean}
 */
proto.loupe.common.person.Person.prototype.getHasChildren = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setHasChildren = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * repeated Person versions = 23;
 * @return {!Array<!proto.loupe.common.person.Person>}
 */
proto.loupe.common.person.Person.prototype.getVersionsList = function() {
  return /** @type{!Array<!proto.loupe.common.person.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.person.Person, 23));
};


/**
 * @param {!Array<!proto.loupe.common.person.Person>} value
 * @return {!proto.loupe.common.person.Person} returns this
*/
proto.loupe.common.person.Person.prototype.setVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.loupe.common.person.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.person.Person}
 */
proto.loupe.common.person.Person.prototype.addVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.loupe.common.person.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};


/**
 * optional string role = 24;
 * @return {string}
 */
proto.loupe.common.person.Person.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated PersonGoalContainer goal_data = 25;
 * @return {!Array<!proto.loupe.common.person.PersonGoalContainer>}
 */
proto.loupe.common.person.Person.prototype.getGoalDataList = function() {
  return /** @type{!Array<!proto.loupe.common.person.PersonGoalContainer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.person.PersonGoalContainer, 25));
};


/**
 * @param {!Array<!proto.loupe.common.person.PersonGoalContainer>} value
 * @return {!proto.loupe.common.person.Person} returns this
*/
proto.loupe.common.person.Person.prototype.setGoalDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.loupe.common.person.PersonGoalContainer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.person.PersonGoalContainer}
 */
proto.loupe.common.person.Person.prototype.addGoalData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.loupe.common.person.PersonGoalContainer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.clearGoalDataList = function() {
  return this.setGoalDataList([]);
};


/**
 * optional int64 rank = 26;
 * @return {number}
 */
proto.loupe.common.person.Person.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * repeated Person children = 27;
 * @return {!Array<!proto.loupe.common.person.Person>}
 */
proto.loupe.common.person.Person.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.loupe.common.person.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.person.Person, 27));
};


/**
 * @param {!Array<!proto.loupe.common.person.Person>} value
 * @return {!proto.loupe.common.person.Person} returns this
*/
proto.loupe.common.person.Person.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.loupe.common.person.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.person.Person}
 */
proto.loupe.common.person.Person.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.loupe.common.person.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};


/**
 * repeated string group_viewer_ids = 28;
 * @return {!Array<string>}
 */
proto.loupe.common.person.Person.prototype.getGroupViewerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 28));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.setGroupViewerIdsList = function(value) {
  return jspb.Message.setField(this, 28, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.addGroupViewerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 28, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.person.Person} returns this
 */
proto.loupe.common.person.Person.prototype.clearGroupViewerIdsList = function() {
  return this.setGroupViewerIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.person.PersonGoalContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.person.PersonGoalContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.person.PersonGoalContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.person.PersonGoalContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: (f = msg.getCategory()) && src_common_goals_goals_pb.GoalCategory.toObject(includeInstance, f),
    evaluation: (f = msg.getEvaluation()) && src_common_goals_goals_pb.GoalEvaluated.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.person.PersonGoalContainer}
 */
proto.loupe.common.person.PersonGoalContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.person.PersonGoalContainer;
  return proto.loupe.common.person.PersonGoalContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.person.PersonGoalContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.person.PersonGoalContainer}
 */
proto.loupe.common.person.PersonGoalContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_goals_goals_pb.GoalCategory;
      reader.readMessage(value,src_common_goals_goals_pb.GoalCategory.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 2:
      var value = new src_common_goals_goals_pb.GoalEvaluated;
      reader.readMessage(value,src_common_goals_goals_pb.GoalEvaluated.deserializeBinaryFromReader);
      msg.setEvaluation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.person.PersonGoalContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.person.PersonGoalContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.person.PersonGoalContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.person.PersonGoalContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_goals_goals_pb.GoalCategory.serializeBinaryToWriter
    );
  }
  f = message.getEvaluation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_goals_goals_pb.GoalEvaluated.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.goals.GoalCategory category = 1;
 * @return {?proto.loupe.common.goals.GoalCategory}
 */
proto.loupe.common.person.PersonGoalContainer.prototype.getCategory = function() {
  return /** @type{?proto.loupe.common.goals.GoalCategory} */ (
    jspb.Message.getWrapperField(this, src_common_goals_goals_pb.GoalCategory, 1));
};


/**
 * @param {?proto.loupe.common.goals.GoalCategory|undefined} value
 * @return {!proto.loupe.common.person.PersonGoalContainer} returns this
*/
proto.loupe.common.person.PersonGoalContainer.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.person.PersonGoalContainer} returns this
 */
proto.loupe.common.person.PersonGoalContainer.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.person.PersonGoalContainer.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.goals.GoalEvaluated evaluation = 2;
 * @return {?proto.loupe.common.goals.GoalEvaluated}
 */
proto.loupe.common.person.PersonGoalContainer.prototype.getEvaluation = function() {
  return /** @type{?proto.loupe.common.goals.GoalEvaluated} */ (
    jspb.Message.getWrapperField(this, src_common_goals_goals_pb.GoalEvaluated, 2));
};


/**
 * @param {?proto.loupe.common.goals.GoalEvaluated|undefined} value
 * @return {!proto.loupe.common.person.PersonGoalContainer} returns this
*/
proto.loupe.common.person.PersonGoalContainer.prototype.setEvaluation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.person.PersonGoalContainer} returns this
 */
proto.loupe.common.person.PersonGoalContainer.prototype.clearEvaluation = function() {
  return this.setEvaluation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.person.PersonGoalContainer.prototype.hasEvaluation = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.loupe.common.person);
