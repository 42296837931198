import { defaultGetNodeKey } from '@nosferatu500/react-sortable-tree'
import { walk } from './utils/tree-data-utils'
import { find } from 'lodash'

export function getParentNode(treeData, myNode) {
  let parent = null
  walk({
    treeData,
    getNodeKey: defaultGetNodeKey,
    ignoreCollapsed: false,
    callback: ({ node }) => {
      if (find(node.children, (child) => child.group.id === myNode.group.id)) {
        parent = node
      }
    },
  })
  return parent
}
