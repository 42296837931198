import React, { useMemo } from 'react'
import { useSystemRoles } from '../../context/systemRoles'
import Tooltip from '../common/tooltip'
import UserMenu from './userMenu'
import { orderBy } from 'lodash'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const UserListItem = (props) => {
  const {
    user,
    onEdit,
    onDelete,
    ...rest
  } = props

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanUpdateUser,
      permissionNames.CanDeleteUser
    )
  }, [checkPermissions])

  const { getById } = useSystemRoles()

  const {
    name,
    email,
  } = user

  const userStatus = useMemo(() => {
    const { status } = user
    return status === 1 ? 'Active' : 'Inactive'
  }, [user])

  const role = useMemo(() => {
    const { roleIdsList = [] } = user
    const roleNames = orderBy(roleIdsList.map((id) => {
      const r = getById(id)
      return r ? r.name : 'Internal'
    }), (n) => n)
    const count = roleNames.length
    if (count > 0) {
      return (
        <div className="flex items-center">
          <div className="truncate">{roleNames[0]}</div>
          {count > 1
            && (
              <Tooltip placement="top" arrow={true} title={roleNames.join(', ')}>
                <div
                  style={{ padding: 6, minWidth: 24, minHeight: 24 }}
                  className="ml-2 flex flex-shrink-0 flex-grow-0 items-center justify-center rounded-full
                  bg-color-5951FF text-size-12px text-color-ffffff font-weight-500 leading-none cursor-default">
                  <div style={{ transform: 'translate(-1px, 1px)' }}>{`+${count - 1}`}</div>
                </div>
              </Tooltip>
            )}
        </div>
      )
    } else {
      return '-'
    }
  }, [user, getById])

  return (
    <div className="tr hover:bg-color-51636a-05" {...rest}>
      {(permissions.CanUpdateUser || permissions.CanDeleteUser) && (
        <div className="td-menu">
          <UserMenu
            user={user}
            onEdit={onEdit}
            onDelete={onDelete} />
        </div>
      )}
      <div className="td w-48 leading-tight">
        <div className="text-size-16px text-color-151d49 font-weight-600 truncate">{name}</div>
        <div className="text-size-14px text-color-91959f font-weight-400 truncate">{email}</div>
      </div>
      <div className="td w-10 text-size-15px text-color-09242f font-weight-400 truncate">{userStatus}</div>
      <div className="td w-10 text-size-15px text-color-09242f font-weight-400 truncate">{role}</div>
    </div>
  )
}

export default UserListItem
