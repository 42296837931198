import { guid } from '../lib/guid'
import { toGetTenantLicenseInfoRequest } from '../grpc/converters'
import { useAuth } from './auth'
import { useGrpcEffect } from '../grpc'
import { useNotification } from '../hooks/useNotification'
import React, { useCallback, useMemo, useState } from 'react'

const TenantLicenseInfoContext = React.createContext()

export function TenantLicenseInfoProvider({ children }) {
  const { tenantId } = useAuth()

  const { notifyError } = useNotification()

  const [key, setKey] = useState(guid())
  const [tenantLicenseInfo, setTenantLicenseInfo] = useState({})
  const [isFetching, setIsFetching] = useState(true)

  useGrpcEffect({
    request: toGetTenantLicenseInfoRequest({
      tenantId,
    }),
    onError: () => {
      setIsFetching(false)
      notifyError('Error fetching tenant license info!')
      setTenantLicenseInfo({})
    },
    onSuccess: (obj) => {
      setTenantLicenseInfo(obj)
      setIsFetching(false)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getTenantLicenseInfo',
    debug: false,
  }, [tenantId, key])

  const invalidate = useCallback(() => {
    setKey(guid())
    setTenantLicenseInfo({})
  }, [])

  const contextValue = useMemo(() => {
    return {
      invalidate,
      isFetching,
      key,
      setTenantLicenseInfo,
      tenantLicenseInfo,
    }
  }, [
    invalidate,
    isFetching,
    key,
    setTenantLicenseInfo,
    tenantLicenseInfo,
  ])

  return (
    <TenantLicenseInfoContext.Provider value={contextValue}>
      {children}
    </TenantLicenseInfoContext.Provider>
  )
}

export function useTenantLicenseInfo() {
  const context = React.useContext(TenantLicenseInfoContext)
  if (context === undefined) {
    throw new Error('useTenantLicenseInfo must be used within a TenantLicenseInfoProvider')
  }
  return context
}
