import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Checkbox from '../common/checkbox'
import Button from '../common/button'
import { filter, find, forEach, orderBy, uniq } from 'lodash'
import BlockNavigation from '../common/blockNavigation'
import { useGrpcCallback } from '../../grpc'
import { useNotification } from '../../hooks/useNotification'
import { toUpsertReportingOptionsSettingRequest, toUpsertReportingSettingRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import LoadingState from '../common/loadingState'
import { useReportingSettings } from '../../context/reportingSettings'
import { useReportingOptionsSettings } from '../../context/reportingOptionsSettings'
import FieldLabel from './fieldLabel'
import { dateRangeOptions, groupByOptions } from './constants'
import { useObjectDefinitionExtended } from '../../context/objectDefinitionExtended'
import objectHash from 'object-hash'
import ErrorWithReload from '../common/errorWithReload'
import { IntegrationSourceType } from '../../grpc/enums'

const AnalyticsSettingsMain = (props) => {
  const { tenantId } = useAuth()

  const { notifySuccess, notifyError } = useNotification()

  const [selectedGroupByOptions, setSelectedGroupByOptions] = useState([])
  const [selectedDateRangeOptions, setSelectedDateRangeOptions] = useState([])
  const [selectedFields, setSelectedFields] = useState([])
  const [isSavingReporting, setIsSavingReporting] = useState(false)
  const [isSavingReportingOptions, setIsSavingReportingOptions] = useState(false)
  const [originalSettings, setOriginalSettings] = useState({})
  const [originalOptionsSettings, setOriginalOptionsSettings] = useState({})
  const [isEnabled, setIsEnabled] = useState(false)
  const [upsertReportingError, setUpsertReportingError] = useState(false)
  const [upsertReportingOptionsError, setUpsertReportingOptionsError] = useState(false)

  const {
    isFetching: isFetchingReportingSettings,
    fetchError: fetchErrorReportingSettings,
    settings,
    invalidate: invalidateReportingSettings
  } = useReportingSettings()

  const {
    isFetching: isFetchingReportingOptionsSettings,
    fetchError: fetchErrorReportingOptionsSettings,
    settings: optionsSettings,
    invalidate: invalidateReportingOptionsSettings
  } = useReportingOptionsSettings()

  const { isFetching: isFetchingObjectDefinitionExtended, objectDefinitionExtended } = useObjectDefinitionExtended()

  const fieldsList = useMemo(() => {
    const fields = {}
    if (objectDefinitionExtended && objectDefinitionExtended.fieldsList) {
      // group the fields to the "to" property
      forEach(objectDefinitionExtended.fieldsList, (f) => {
        if (!fields[f.to]) {
          fields[f.to] = {
            label: f.label,
            to: f.to,
            toType: f.toType,
            instances: []
          }
        }
        const instances = fields[f.to].instances
        instances.push(f)
        if (instances.len > 1) {
          fields[f.to].instances = orderBy(instances, (o) => o.integrationSourceType)
        }
      })
    }
    // a valid field must be of type STRING and have options
    let validFields = filter(fields, (f) => {
      const firstInstance = f?.instances[0] ?? undefined
      if (f.toType === 'STRING' && (
        firstInstance
        && firstInstance.options
        && firstInstance.options.itemsList
        && firstInstance.options.itemsList.length > 0
      )) {
        return true
      } else {
        return false
      }
    })

    const extensionFields = filter(validFields, (f) => f.instances[0]?.integrationSourceType === IntegrationSourceType.CUSTOM).map((f) => f.instances[0]?.from)
    // dedupe other fields that may be used in extension fields
    validFields = filter(validFields, (f) => {
      const firstInstance = f.instances[0]
      return !(firstInstance && firstInstance.from === firstInstance.to && extensionFields.includes(firstInstance.from))
    })

    return orderBy(validFields, (f) => f.label)
  }, [objectDefinitionExtended])

  const isFetching = useMemo(() => {
    return isFetchingObjectDefinitionExtended || isFetchingReportingSettings || isFetchingReportingOptionsSettings
  }, [isFetchingObjectDefinitionExtended, isFetchingReportingSettings, isFetchingReportingOptionsSettings])

  const fetchError = useMemo(() => {
    return fetchErrorReportingSettings || fetchErrorReportingOptionsSettings
  }, [fetchErrorReportingSettings, fetchErrorReportingOptionsSettings])

  const upsertError = useMemo(() => {
    return upsertReportingError || upsertReportingOptionsError
  }, [upsertReportingError, upsertReportingOptionsError])

  useEffect(() => {
    if (settings && optionsSettings) {
      setOriginalSettings(settings)
      setOriginalOptionsSettings(optionsSettings)

      const { enabled = false } = settings
      setIsEnabled(enabled)

      const { groupByOptionsList = [], dateRangeOptionsList = [], filterFieldOptionsList = [] } = optionsSettings
      setSelectedGroupByOptions(uniq([
        ...orderBy(groupByOptionsList, (val) => val),
        'stageName'
      ]))
      setSelectedDateRangeOptions(uniq([
        ...orderBy(dateRangeOptionsList, (val) => val),
        'CUSTOM'
      ]))
      setSelectedFields(orderBy(filterFieldOptionsList.map((f) => f.field.replace('oRaw.', '')), (val) => val))
    }
  }, [settings, optionsSettings])

  const hasReportingChanges = useMemo(() => {
    const { enabled = false } = originalSettings || {}
    return enabled !== isEnabled
  }, [originalSettings, isEnabled])

  const hasReportingOptionsChanges = useMemo(() => {
    const { groupByOptionsList = [], dateRangeOptionsList = [], filterFieldOptionsList = [] } = optionsSettings || {}
    return objectHash({ data: orderBy(groupByOptionsList, (val) => val) }) !== objectHash({ data: selectedGroupByOptions })
      || objectHash({ data: orderBy(dateRangeOptionsList, (val) => val) }) !== objectHash({ data: selectedDateRangeOptions })
      || objectHash({ data: orderBy(filterFieldOptionsList.map((f) => f.field.replace('oRaw.', '')), (val) => val) }) !== objectHash({ data: selectedFields })
  }, [originalOptionsSettings, selectedGroupByOptions, selectedDateRangeOptions, selectedFields])

  const hasChanges = useMemo(() => {
    return hasReportingChanges || hasReportingOptionsChanges
  }, [hasReportingChanges, hasReportingOptionsChanges])

  const isSaving = useMemo(() => {
    return isSavingReporting || isSavingReportingOptions
  }, [isSavingReporting, isSavingReportingOptions])

  const upsertReportingSetting = useGrpcCallback({
    onError: () => {
      setIsSavingReporting(false)
      setUpsertReportingError(true)
      notifyError('Error saving Analytics settings!')
    },
    onSuccess: (obj) => {
      invalidateReportingSettings()
      setIsSavingReporting(false)
      setUpsertReportingError(false)
      notifySuccess('Analytics settings were saved!')
    },
    onFetch: () => {
      setIsSavingReporting(true)
      setUpsertReportingError(false)
    },
    grpcMethod: 'upsertReportingSetting',
    debug: false,
  }, [invalidateReportingSettings])

  const upsertReportingOptionsSetting = useGrpcCallback({
    onError: () => {
      setIsSavingReportingOptions(false)
      setUpsertReportingOptionsError(true)
      notifyError('Error saving Analytics options!')
    },
    onSuccess: (obj) => {
      invalidateReportingOptionsSettings()
      setIsSavingReportingOptions(false)
      setUpsertReportingOptionsError(false)
      notifySuccess('Analytics options were saved!')
    },
    onFetch: () => {
      setIsSavingReportingOptions(true)
      setUpsertReportingOptionsError(false)
    },
    grpcMethod: 'upsertReportingOptionsSetting',
    debug: false,
  }, [invalidateReportingOptionsSettings])

  const onSave = useCallback(() => {
    if (hasReportingChanges) {
      const request = toUpsertReportingSettingRequest({
        tenantId,
        setting: {
          ...settings,
          enabled: isEnabled,
        },
      })
      upsertReportingSetting(request)
    }
    if (hasReportingOptionsChanges) {
      const request2 = toUpsertReportingOptionsSettingRequest({
        tenantId,
        setting: {
          ...optionsSettings,
          groupByOptionsList: selectedGroupByOptions,
          dateRangeOptionsList: selectedDateRangeOptions,
          filterFieldOptionsList: selectedFields.filter((f) => f).map((f) => {
            const field = find(fieldsList, (field) => field.to === f)
            return {
              field: field?.to ?? '',
              fieldType: field?.toType ?? '',
              label: field?.label ?? '',
            }
          })
        },
      })
      upsertReportingOptionsSetting(request2)
    }
    // eslint-disable-next-line max-len
  }, [upsertReportingSetting, upsertReportingOptionsSetting, hasReportingChanges, hasReportingOptionsChanges, fieldsList, tenantId, settings, optionsSettings, isEnabled, selectedGroupByOptions, selectedDateRangeOptions, selectedFields])

  const onGroupByCheckChanged = useCallback((option, checked) => {
    if (checked) {
      setSelectedGroupByOptions(orderBy([
        ...selectedGroupByOptions,
        option.value
      ], (val) => val))
    } else {
      setSelectedGroupByOptions(orderBy(filter(selectedGroupByOptions, (o) => o !== option.value), (val) => val))
    }
  }, [selectedGroupByOptions])

  const onDateRangeCheckChanged = useCallback((option, checked) => {
    if (checked) {
      setSelectedDateRangeOptions(orderBy([
        ...selectedDateRangeOptions,
        option.value
      ], (val) => val))
    } else {
      setSelectedDateRangeOptions(orderBy(filter(selectedDateRangeOptions, (o) => o !== option.value), (val) => val))
    }
  }, [selectedDateRangeOptions])

  // const onFieldCheckChanged = useCallback((option, checked) => {
  //   if (checked) {
  //     setSelectedFields(orderBy([
  //       ...selectedFields,
  //       option.to
  //     ], (val) => val))
  //   } else {
  //     setSelectedFields(orderBy(filter(selectedFields, (o) => o !== option.to), (val) => val))
  //   }
  // }, [selectedFields])

  return fetchError || upsertError
    ? (
      <div className="flex justify-center my-10">
        <ErrorWithReload header={fetchError ? 'Error Loading Settings' : 'Error Saving Settings'} />
      </div>
    ) : (
      <>
        {isFetching || isSaving
          ? (
            <div>
              <div className="flex justify-center my-10">
                <LoadingState
                  header={isSaving ? 'Saving' : 'Loading Settings'}
                  subHeader="Please wait..."
                  animate={true} />
              </div>
            </div>
          )
          : (
            <>
              <div className="flex items-center justify-between">
                <div className="text-size-24px text-color-09242f font-weight-700">Analytics Settings</div>
                <Button
                  text="Save Changes"
                  onClick={onSave}
                  disabled={!hasChanges} />
              </div>
              <div className="text-size-15px text-color-51636a font-weight-400 leading-tight my-4">
                These settings control how the Analytics feature in Commit is configured.
              </div>
              <div className="w-full md:w-7/12">
                <div className="mt-5 mb-5 first:mt-0 last:mb-0">
                  <FieldLabel
                    label="Enabled" />
                  <Checkbox
                    checked={isEnabled}
                    onChange={(e) => setIsEnabled(e.target.checked)} />
                </div>
                <div className="mt-5 mb-5 first:mt-0 last:mb-0">
                  <FieldLabel
                    label="Group By Options" />
                  {groupByOptions.map((o) => (
                    <div key={`GroupByOption-${o.value}`}>
                      <Checkbox
                        checked={selectedGroupByOptions.includes(o.value)}
                        onChange={(e) => onGroupByCheckChanged(o, e.target.checked)}
                        label={o.label}
                        disabled={o.disabled} />
                    </div>
                  ))}
                </div>
                <div className="mt-5 mb-5 first:mt-0 last:mb-0">
                  <FieldLabel
                    label="Date Range Options" />
                  {dateRangeOptions.map((o) => (
                    <div key={`DateRangeOption-${o.value}`}>
                      <Checkbox
                        checked={selectedDateRangeOptions.includes(o.value)}
                        onChange={(e) => onDateRangeCheckChanged(o, e.target.checked)}
                        label={o.label}
                        disabled={o.disabled} />
                    </div>
                  ))}
                </div>
                {/* <div className="mt-5 mb-5 first:mt-0 last:mb-0">
                  <FieldLabel
                    label="Filterable Fields" />
                  {fieldsList.map((field) => (
                    <div key={`Field-${field.to}`}>
                      <Checkbox
                        checked={selectedFields.includes(field.to)}
                        onChange={(e) => onFieldCheckChanged(field, e.target.checked)}
                        label={(
                          <div className="leading-tight">
                            <div>{field.label}</div>
                            <div className="text-size-10px text-color-91959f">{field.to}</div>
                          </div>
                        )} />
                    </div>
                  ))}
                </div> */}
              </div>
            </>
          )}
        <BlockNavigation
          canBlock={hasChanges}
          promptTitle="Warning"
          promptText="Are you sure you want to leave?"
          promptSubText="You have unsaved changes that will be lost if you leave now. This cannot be undone."
          promptActionText="Leave" />
      </>
    )
}

export default AnalyticsSettingsMain
