import { JoyrideProvider } from '../../context/joyride'
import { Redirect } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { useSetup } from '../../context/setup'
import React from 'react'

const Onboarding = ({ children, enabled, step, ...props }) => {
  const { isFetching, isComplete, isStepComplete, getPrevStep } = useSetup()
  const { routes } = useRoutes()

  if (isFetching) {
    return null
  }

  if (!isComplete && !getPrevStep(step)?.completed) {
    return <Redirect to={routes.dataSync} />
  }

  return (
    <JoyrideProvider enabled={!isStepComplete(step)} {...props}>
      {children}
    </JoyrideProvider>
  )
}

export default Onboarding
