import {
  toListForecastConfigsRequest,
  toPublishForecastConfigDraftRequest,
  toSaveForecastConfigRequest,
  toSetForecastConfigStatusRequest,
  toReorderForecastConfigsRequest,
  toDuplicateForecastConfigRequest
} from '../grpc/converters'
import { guid } from '../lib/guid'
import { useGrpcAll, useGrpcEffect, useGrpcCallback } from '../grpc'
import { useHistory } from 'react-router-dom'
import { useNotification } from '../hooks/useNotification'
import { useRoutes } from './routes'
import { useActor } from '../hooks/useActor'
import { useTenantInfo } from './tenantInfo'
import { ForecastConfigStatus, ForecastPeriodType } from '../grpc/enums'
import React, { useCallback, useMemo, useState } from 'react'
import { cloneDeep } from 'lodash'

const ForecastingConfigsContext = React.createContext()

export function ForecastingConfigsProvider({ children }) {
  const { actor } = useActor()
  const { routes } = useRoutes()
  const { tenantInfo } = useTenantInfo()

  const history = useHistory()

  const { notifyError, notifySuccess } = useNotification()

  const [isFetching, setIsFetching] = useState(true)
  const [isCreating, setIsCreating] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [key, setKey] = useState(guid())
  const [configs, setConfigs] = useState(undefined)
  const [activeConfigs, setActiveConfigs] = useState([])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  useGrpcEffect({
    request: toListForecastConfigsRequest({ actor }),
    onError: () => {
      setIsFetching(false)
      setFetchError(true)
      notifyError('Error fetching Forecasting settings!')
    },
    onSuccess: (obj) => {
      setConfigs(obj.configsList)
      setActiveConfigs(obj.configsList.filter((config) => config.status === ForecastConfigStatus.ENABLED))
      setIsFetching(false)
      setFetchError(false)
    },
    onFetch: () => {
      setIsFetching(true)
      setFetchError(false)
    },
    grpcMethod: 'listForecastConfigs',
    debug: false
  }, [key, actor])

  const createConfigCallback = useGrpcCallback({
    onError: (e) => {
      setIsFetching(false)
      setIsCreating(false)
      notifyError('Error Creating Forecasting Config!')
    },
    onSuccess: (obj) => {
      setIsFetching(false)
      setIsCreating(false)
      history.push(routes.forecast.replace(':forecastId', obj?.id))
    },
    grpcMethod: 'saveForecastConfig',
    debug: false
  }, [key])

  const createConfig = useCallback((_config) => {
    setIsCreating(true)

    const { monthPeriodRange } = tenantInfo
    let periodLength
    switch (monthPeriodRange) {
      case 1:
        periodLength = ForecastPeriodType.MONTH
        break
      case 6:
        periodLength = ForecastPeriodType.HALF_YEAR
        break
      case 12:
        periodLength = ForecastPeriodType.YEAR
        break
      default:
        periodLength = ForecastPeriodType.QUARTER
        break
    }
    const request = toSaveForecastConfigRequest({
      actor,
      forecastConfig: {
        name: 'New Forecast',
        periodLength
      }
    })
    createConfigCallback(request)
  }, [createConfigCallback, actor, tenantInfo])

  const setConfigStatusRequest = useGrpcCallback({
    onError: (err) => {
      notifyError('Error Updating Status!')
      setIsFetching(true)
    },
    onSuccess: (data) => {
      notifySuccess('Status Saved!')
      // fetch updated configs
      invalidate()
    },
    grpcMethod: 'setForecastConfigStatus',
    debug: false
  }, [invalidate])

  const setConfigStatus = useCallback((obj) => {
    const request = toSetForecastConfigStatusRequest({
      actor,
      ...obj
    })
    setConfigStatusRequest(request)
  }, [setConfigStatusRequest, actor])

  const [publishConfigRequest] = useGrpcAll({
    onError: (err) => {
      notifyError('Error Updating Status!')
      setIsFetching(true)
    },
    onSuccess: (data) => {
      notifySuccess('Status Saved!')
      invalidate()
    }
  }, [])

  const publishConfig = useCallback((config) => {
    let request = [{
      request: toPublishForecastConfigDraftRequest({
        actor,
        forecastConfig: config,
      }),
      grpcMethod: 'publishForecastConfigDraft'
    }]
    if (config.status !== ForecastConfigStatus.ENABLED) {
      request = [{
        grpcMethod: 'saveForecastConfig',
        request: toSaveForecastConfigRequest({
          actor,
          forecastConfig: {
            ...config,
            sort: 999
          }
        })
      },
      ...request
      ]
    }
    publishConfigRequest(request)
  }, [actor, publishConfigRequest])

  const setConfigSortOrderRequest = useGrpcCallback({
    onError: (err) => {
      notifyError('Error Updating Sort!')
      setIsFetching(true)
    },
    onSuccess: (data) => {
      notifySuccess('Sort Saved!')
      invalidate()
    },
    grpcMethod: 'reorderForecastConfigs',
    debug: false
  }, [invalidate])

  const setConfigSortOrder = useCallback((ids) => {
    const request = toReorderForecastConfigsRequest({
      actor,
      forecastConfigIdsList: ids
    })
    setConfigSortOrderRequest(request)
  }, [actor, setConfigSortOrderRequest])

  const duplicateConfigRequest = useGrpcCallback({
    onFetch: (obj) => {
      setIsFetching(true)
    },
    onError: (err) => {
      notifyError('Error Duplicating Config!')
      setIsFetching(false)
    },
    onSuccess: (obj) => {
      notifySuccess('Forecast Duplicated!')
      setIsFetching(false)
      invalidate()
    },
    grpcMethod: 'duplicateForecastConfig',
    debug: false
  }, [])

  const duplicateConfig = useCallback((config) => {
    const request = toDuplicateForecastConfigRequest({
      actor,
      forecastConfig: config
    })
    duplicateConfigRequest(request)
  }, [actor, duplicateConfigRequest])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      fetchError,
      configs,
      activeConfigs,
      key,
      isCreating,
      publishConfig,
      setConfigSortOrder,
      invalidate,
      createConfig,
      duplicateConfig,
      setConfigStatus
    }
  }, [
    isFetching,
    fetchError,
    configs,
    activeConfigs,
    key,
    isCreating,
    publishConfig,
    setConfigSortOrder,
    invalidate,
    createConfig,
    duplicateConfig,
    setConfigStatus
  ])

  return <ForecastingConfigsContext.Provider value={contextValue}>{children}</ForecastingConfigsContext.Provider>
}

export function useForecastingConfigs() {
  const context = React.useContext(ForecastingConfigsContext)
  if (context === undefined) {
    throw new Error('useForecastingConfigs must be used within a ForecastingConfigsProvider')
  }
  return context
}
