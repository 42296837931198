import React from 'react'
import UserMenu from '../menus/userMenu'
import ChevronLeft from '../icons/chevronLeft'

const Header = (props) => {
  const {
    children,
    title,
    showBackButton,
    onBackClick,
    breadcrumbControl,
  } = props

  return (
    <div className="px-10 py-6 flex items-center justify-between bg-color-ffffff border-b border-color-eaeaea z-header">
      {showBackButton && (
        <button className="absolute -ml-5 focus:outline-none"
          style={{ transform: 'translate(-6px, 1px)' }}
          onClick={onBackClick}>
          <ChevronLeft fill="#151d49" transform="scale(1.5)" />
        </button>
      )}
      <div className="flex flex-col flex-shrink-0 leading-none" id="joyride_objectMapping_4">
        {breadcrumbControl && breadcrumbControl}
        <h1>{title}</h1>
      </div>
      <div className="flex items-center">
        {children}
        <UserMenu />
      </div>
    </div>
  )
}

export default Header
