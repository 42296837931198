import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const LoadingStateIcon = ({ animate, dense, style, secondary, ...props }) => (
  <div className={classNames('flex justify-center', { 'my-4': !dense })} {...props}>
    <div style={{ width: 50, height: 50, ...style }}>
      <svg viewBox="0, 0, 50, 50">
        <defs>
          {!secondary && (
            <linearGradient id="grad" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse">
              <stop offset="0%" style={{ stopColor: '#5951FF', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: '#5951FF', stopOpacity: 1 }} />
            </linearGradient>
          )}
        </defs>
        <ellipse cx="25" cy="25" rx="25" ry="25" fill="url(#grad)" />
        <line x1="15" y1="22" x2="15" y2="34" style={{ stroke: secondary ? '#A6B2CF' : '#fff', strokeWidth: 5, strokeLinecap: 'round' }}>
          {animate && <animate attributeName="y1" values="34;14;34" dur="500ms" begin="0ms" repeatCount="indefinite" />}
        </line>
        <line x1="25" y1="14" x2="25" y2="34" style={{ stroke: secondary ? '#A6B2CF' : '#fff', strokeWidth: 5, strokeLinecap: 'round' }}>
          {animate && <animate attributeName="y1" values="34;14;34" dur="500ms" begin="200ms" repeatCount="indefinite" />}
        </line>
        <line x1="35" y1="30" x2="35" y2="34" style={{ stroke: secondary ? '#A6B2CF' : '#fff', strokeWidth: 5, strokeLinecap: 'round' }}>
          {animate && <animate attributeName="y1" values="34;14;34" dur="500ms" begin="100ms" repeatCount="indefinite" />}
        </line>
      </svg>
    </div>
  </div>
)

LoadingStateIcon.defaultProps = {
  animate: false,
  dense: false,
  secondary: false,
  style: {}
}

LoadingStateIcon.propTypes = {
  animate: PropTypes.bool,
  dense: PropTypes.bool,
  style: PropTypes.object,
  secondary: PropTypes.bool
}

export default LoadingStateIcon
