import React, { useMemo, useCallback, useState } from 'react'
import RoleMenu from './roleMenu'
import { useGrpcCallback, useGrpcEffect } from '../../grpc'
import { useHistory } from 'react-router-dom'
import { toIdRequest, toSystemRole, toSearchPeopleRequest } from '../../grpc/converters'
import { useNotification } from '../../hooks/useNotification'
import Refresh from '../icons/refresh'
import classNames from 'classnames'
import { useSystemRoles } from '../../context/systemRoles'
import { useAuth } from '../../context/auth'
import { FilterOp, PersonField } from '../../grpc/enums'
import ActionModal from '../common/actionModal'
import { useModal } from '../../hooks/useModal'
import pluralize from 'pluralize'
import { useRoutes } from '../../context/routes'

const RoleListItem = (props) => {
  const {
    systemRole,
    ...rest
  } = props

  const { tenantId } = useAuth()
  const { name, description, id } = systemRole
  const deleteRoleModal = useModal()
  const { routes } = useRoutes()

  const [fetching, setFetching] = useState(false)
  const [totalUsers, setTotalUsers] = useState(0)

  const history = useHistory()
  const { invalidate } = useSystemRoles()
  const { notifySuccess, notifyError } = useNotification()

  const deleteRequest = toIdRequest({ id })

  const deleteRole = useGrpcCallback({
    onError: () => {
      setFetching(false)
      notifyError('Unable to delete role')
    },
    onSuccess: () => {
      setFetching(false)
      notifySuccess('Role deleted!')
      invalidate()
    },
    onFetch: () => setFetching(true),
    grpcMethod: 'deleteSystemRole'
  }, [notifySuccess, notifyError, invalidate])

  const searchRequest = useMemo(() => {
    return toSearchPeopleRequest({
      tenantId,
      page: 1,
      pageSize: 1,
      filtersList: [
        {
          field: PersonField.ROLEIDS,
          op: FilterOp.EQANY,
          valuesList: [
            btoa(JSON.stringify(id)),
          ],
        }
      ],
    })
  }, [tenantId, id])

  useGrpcEffect({
    request: searchRequest,
    onError: (e) => {
      setFetching(false)
      notifyError('Unable to delete role')
    },
    onSuccess: ({ total }) => {
      setTotalUsers(total)
    },
    grpcMethod: 'searchPeople',
    debug: false,
  }, [searchRequest])

  const onDelete = useCallback(() => {
    if (totalUsers > 0) {
      deleteRoleModal.setData({ totalUsers })
      deleteRoleModal.setOpen(true)
      setFetching(false)
      return
    }
    deleteRole(deleteRequest)
  }, [totalUsers])

  const createRole = useGrpcCallback({
    onError: (e) => {
      notifyError('Unable to create role')
      setFetching(false)
    },
    onSuccess: () => {
      setFetching(false)
      invalidate()
    },
    onFetch: () => {
      setFetching(true)
    },
    grpcMethod: 'createSystemRole',
    debug: false,
  }, [invalidate])

  const onDuplicateInternal = useCallback((e, role) => {
    const request = toSystemRole({
      tenantId,
      name: `${name} copy`,
      description: `Copy of role based on ${name}`,
      baseRoleId: id,
    })
    createRole(request)
  }, [id])

  const onRoleNameClick = useCallback(() => {
    history.push(routes.role.replace(':roleId', id))
  }, [id])

  return (
    <div className="tr hover:bg-color-51636a-05" {...rest}>
      <div className="td-menu">
        {fetching
          ? (<Refresh fill="#5951FF" className="animate-spin" />)
          : (<RoleMenu onDuplicate={onDuplicateInternal} onDelete={onDelete} role={systemRole} />)}
      </div>
      <div
        className={classNames('td w-1/5', { 'text-color-c9ced0 italic': fetching })}>
        <div onClick={onRoleNameClick} className="inline cursor-pointer">{name}</div>
      </div>
      <div className={
        classNames(
          'td w-4/5 text-color-818e93 text-size-14px font-weight-300',
          { 'text-color-c9ced0 italic': fetching }
        )
      }>
        {description}
      </div>

      <ActionModal
        modal={deleteRoleModal}
        title="Remove Role"
        text="Cannot remove a role which has users assigned"
        subText={`${deleteRoleModal?.data?.totalUsers} ${pluralize('user', deleteRoleModal?.data?.totalUsers)} assigned to this role`}
        actionText="Manage Users"
        onAction={() => {
          history.push(routes.users)
        }}
        onCancel={() => {
          deleteRoleModal.setOpen(false)
        }} />
    </div>
  )
}

export default RoleListItem
