import { getTokenSilently, getIdTokenClaims } from '../../lib/auth0'
import { InstanceSetupStatus } from '../../grpc/enums'
import { usePopover } from '../../hooks/usePopover'
import classNames from 'classnames'
import Delete from '../icons/delete'
import Edit from '../icons/edit'
import ExitToApp from '../icons/exitToApp'
import LoadingState from '../common/loadingState'
import MoreVertical from '../icons/moreVertical'
import Popover from '../common/popover'
import React, { useCallback, useMemo, useState } from 'react'
import Visibility from '../icons/visibility'

const canopyUrl = process.env.CANOPY_URL

const SandboxMenu = (props) => {
  const {
    sandbox,
    modal,
    onDelete,
  } = props

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const [loadingApp, setLoadingApp] = useState(false)
  const [loadingAdmin, setLoadingAdmin] = useState(false)

  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const appIsReady = useMemo(() => {
    const { setupStatus } = sandbox
    return setupStatus === InstanceSetupStatus.INSTANCE_SETUP_STATUS_READY
  }, [sandbox, InstanceSetupStatus])

  const onEditInternal = useCallback((e) => {
    window.analytics.track('Edit Sandbox Clicked')
    const { id } = sandbox

    modal.setData({ sandboxId: id })
    modal.setOpen(true)
    onClose()
  }, [sandbox, modal])

  const onDeleteInternal = useCallback((e) => {
    window.analytics.track('Remove Sandbox Clicked')
    onClose()
    onDelete?.(e, sandbox)
  }, [onDelete, sandbox])

  const goToAdmin = useCallback((e) => {
    window.analytics.track('Access Admin Clicked')
    e.preventDefault()
    setLoadingAdmin(true)
    getTokenSilently({
      options: {
        ignoreCache: true
      },
      onSuccess: () => {
        getIdTokenClaims({
          onSuccess: () => {
            setLoadingAdmin(false)
            window.location.href = `/${sandbox?.id}`
          }
        })
      }
    })
  }, [getTokenSilently, getIdTokenClaims])

  const goToApp = useCallback((e) => {
    window.analytics.track('Access App Clicked')
    e.preventDefault()
    setLoadingApp(true)
    getTokenSilently({
      options: {
        ignoreCache: true
      },
      onSuccess: () => {
        getIdTokenClaims({
          onSuccess: () => {
            setLoadingApp(false)
            window.location.href = `${canopyUrl}/${sandbox?.id}/summary`
          }
        })
      }
    })
  }, [getTokenSilently, getIdTokenClaims, canopyUrl])

  return (
    <>
      <button onClick={handleClick}>
        <MoreVertical fill="#151d49" />
      </button>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        placement="right">
        <div className="py-3">
          <div onClick={onEditInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
            <div>
              <Edit fill="#09242f" transform="scale(0.75)" />
            </div>
            <div className="flex flex-col leading-tight ml-2">
              <div className="text-size-15px text-color-151d49 font-weight-400">Edit</div>
              <div className="text-size-13px text-color-4e5d7e font-weight-400">Change details about this sandbox</div>
            </div>
          </div>
          <button onClick={goToAdmin} className="w-full flex text-left items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
            <div>
              {loadingAdmin
                ? (<LoadingState animate={true} style={{ width: '20px', height: '20px' }} />)
                : (<ExitToApp fill="#09242f" transform="scale(0.75)" />)}
            </div>
            <div className="flex flex-col leading-tight ml-2">
              <div className="text-size-15px text-color-151d49 font-weight-400">Access Admin</div>
              <div className="text-size-13px text-color-4e5d7e font-weight-400">Open this sandbox admin app</div>
            </div>
          </button>
          <button
            disabled={!appIsReady}
            onClick={goToApp}
            className={
              classNames(
                'w-full flex text-left items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05',
                { 'opacity-50': !appIsReady }
              )
            }>
            <>
              {loadingApp
                ? (<LoadingState animate={true} style={{ width: '20px', height: '20px' }} />)
                : (<Visibility fill="#09242f" transform="scale(0.75)" />)}
            </>
            <div className="flex flex-col leading-tight ml-2">
              <div className="text-size-15px text-color-151d49 font-weight-400">Access App</div>
              <div className="text-size-13px text-color-4e5d7e font-weight-400">Open this sandbox Commit app</div>
            </div>
          </button>
          <div onClick={onDeleteInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
            <div>
              <Delete fill="#09242f" transform="scale(0.75)" />
            </div>
            <div className="flex flex-col leading-tight ml-2">
              <div className="text-size-15px text-color-151d49 font-weight-400">Remove Sandbox</div>
              <div className="text-size-13px text-color-4e5d7e font-weight-400">Delete this sandbox</div>
            </div>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default SandboxMenu
