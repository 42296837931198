import { useVirtualizedList } from '../../virtualized/useVirtualizedList'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import SearchBox from '../../common/searchBox'
import VirtualizedList from '../../virtualized/virtualizedList'
import FieldSelectItem from './fieldSelectItem'
import FieldSelectItemCategory from './fieldSelectItemCategory'
import { useReferenceFieldOptionsList } from '../../../context/referenceFieldOptionsList'
import { Waypoint } from 'react-waypoint'
import { CircularProgress } from '@material-ui/core'

const DEFAULT_HEIGHT = 31

const FieldSelectList = (props) => {
  const {
    open,
    setOpen,
    search,
    setSearch,
    multi = false,
    onChange,
    getIsSelected,
    values = [],
    lazyLoad = false,
    popoverActions
  } = props

  const { isFetching, referenceFieldOptions, hasMore, nextPage } = useReferenceFieldOptionsList()

  const [openCategories, setOpenCategories] = useState([])

  useEffect(() => {
    // Open the first category on open
    if (open) {
      setOpenCategories([values[0]?.source])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, filteredValues])

  const getIsOpen = useCallback((source) => {
    return openCategories.includes(source) || !!search
  }, [openCategories, search])

  const match = useCallback(({ label = '' }) => {
    return !search || [label, label.toLowerCase()].some((str) => str.includes(search))
  }, [search])

  const filteredValues = useMemo(() => {
    if (lazyLoad) {
      const opts = referenceFieldOptions.map((o) => (
        {
          label: o.label,
          value: o.key
        }
      ))
      if (hasMore) {
        opts.push({ autoLoad: true })
      }
      return opts
    } else {
      return values.reduce((acc, value) => {
        if (value.source) {
          const isOpen = getIsOpen(value.source)
          const matches = isOpen ? value.valuesList.filter(match) : []

          acc.push(
            {
              ...value,
              category: value.source
            },
            ...matches.map((row) => ({
              ...row,
              source: value.source
            }))
          )
        } else if (match(value)) {
          acc.push(value)
        }

        return acc
      }, [])
    }
  }, [getIsOpen, match, values, lazyLoad, hasMore, referenceFieldOptions])

  const handleClearSearch = useCallback(() => {
    setSearch('')
  }, [setSearch])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleSearchChange = useCallback((event) => {
    setSearch(event.target.value)
  }, [setSearch])

  const handleSelectInternal = useCallback((option) => {
    if (!multi) {
      handleClose()
    }
    onChange([option])
  }, [onChange, multi, handleClose])

  const handleToggleCategoryRow = useCallback((source) => {
    if (!openCategories.includes(source)) {
      setOpenCategories((prev) => ([...prev, source]))
    } else {
      setOpenCategories((prev) => prev.filter((category) => category !== source))
    }
  }, [openCategories])

  const handleWaypointEntered = useCallback(() => {
    nextPage()
  }, [nextPage])

  const renderItem = useCallback(({ rowRendererArgs, cellMeasurerArgs }) => {
    const { index, style } = rowRendererArgs
    const { measure, registerChild } = cellMeasurerArgs
    const item = filteredValues[index]
    const showWaypoint = hasMore && !isFetching && item.autoLoad

    return (
      <div ref={registerChild} style={style}>
        {showWaypoint ? (
          <Waypoint onEnter={handleWaypointEntered}>
            <div className="flex justify-center py-2">
              <CircularProgress size={12} color="secondary" />
            </div>
          </Waypoint>
        ) : (
          <>
            {item.category ? (
              <FieldSelectItemCategory
                key={`fieldSelectItemCategory-${index}`}
                isOpen={getIsOpen(item.source)}
                measure={measure}
                onClick={handleToggleCategoryRow.bind(this, item.source)}
                index={index}
                {...item} />
            ) : (
              <FieldSelectItem
                key={`fieldSelectItem-${index}-${index}`}
                measure={measure}
                multi={multi}
                nested={!!item.source}
                onClick={handleSelectInternal.bind(this, item)}
                selected={getIsSelected?.(item)}
                {...item} />
            )}

          </>
        )}
      </div>
    )
  }, [filteredValues, getIsOpen, handleSelectInternal, handleToggleCategoryRow, handleWaypointEntered, getIsSelected, isFetching, hasMore, multi])

  const { cellMeasurerCache, rowRenderer } = useVirtualizedList({
    defaultHeight: DEFAULT_HEIGHT,
    rowRenderer: renderItem,
  })

  useEffect(() => {
    if (popoverActions?.current) {
      popoverActions.current.updatePosition()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredValues])

  return (
    <>
      <SearchBox
        autoFocus
        disableHint
        className="p-3"
        value={search}
        onClear={handleClearSearch}
        onChange={handleSearchChange} />

      {isFetching && filteredValues.length === 0 ? (
        <div className="flex justify-center py-2">
          <CircularProgress size={12} color="secondary" />
        </div>
      ) : (
        <>
          {!filteredValues.length && (
            <div className="select-item hover:bg-color-edeeee">No results</div>
          )}
          <div className="w-full flex-grow" style={{ height: (filteredValues.length * DEFAULT_HEIGHT) + 16, maxHeight: 'calc(100vh - 20vh)' }}>
            <VirtualizedList
              className="focus:outline-none"
              deferredMeasurementCache={cellMeasurerCache}
              rowHeight={cellMeasurerCache.rowHeight}
              rowCount={filteredValues.length}
              rowRenderer={rowRenderer} />
          </div>
        </>
      )}
    </>
  )
}

export default FieldSelectList
