import React, { useCallback, useEffect, useMemo } from 'react'
import Label from '../common/label'
import ChevronLeft from '../icons/chevronLeft'
import { useCanonicalObjectFieldTypes } from '../../context/canonicalObjectFieldTypes'
import { capitalize, find } from 'lodash'
import SelectList from '../common/selectList'
import { defaultFieldType, defaultFormat } from './constants'

const AddEditFieldForm = (props) => {
  const {
    invalidFields = [],
    fieldName,
    description,
    selectedFieldType,
    setSelectedFieldType,
    selectedFormat,
    setSelectedFormat,
    editData,
    setShowForm
  } = props

  const { canonicalObjectFieldTypes } = useCanonicalObjectFieldTypes()

  useEffect(() => {
    if (editData) {
      fieldName.setValue(editData.label)
      description.setValue(editData.description)
      setSelectedFieldType(editData.fieldType)
      setSelectedFormat(editData.format)
    }
  }, [editData])

  const isExtensionField = useMemo(() => {
    return editData?.extensionField ?? false
  }, [editData])

  const fieldTypeOptions = useMemo(() => {
    return [
      defaultFieldType,
      ...canonicalObjectFieldTypes.map((f) => ({ label: capitalize(f.fieldType), value: f.fieldType, formatsList: f.formatsList })),
    ]
  }, [canonicalObjectFieldTypes])

  const formatOptions = useMemo(() => {
    const fieldType = find(canonicalObjectFieldTypes, (f) => f.fieldType === selectedFieldType)
    if (fieldType) {
      const { formatsList = [] } = fieldType
      const options = [
        defaultFormat,
        ...formatsList.map((f) => ({ label: capitalize(f), value: f })),
      ]
      return options
    } else {
      return [defaultFormat]
    }
  }, [selectedFieldType])

  const onTypeChange = useCallback((option) => {
    const { value } = option
    setSelectedFieldType(value)
    setSelectedFormat(defaultFormat.value)
  }, [])

  const onFormatChange = useCallback((option) => {
    const { value } = option
    setSelectedFormat(value)
  }, [])

  return (
    <>
      <div
        onClick={() => setShowForm && setShowForm(false)}
        className="flex items-center cursor-pointer focus:outline-none"
        style={{ width: 60, transform: 'translateX(-8px)' }}>
        <ChevronLeft fill="#09242f" />
        <div className="text-size-14px text-color-09242f font-weight-500">Back</div>
      </div>
      <div className="text-size-16px text-color-51636a font-weight-400 mt-2 mb-5">
        {!editData
          && (
            <>
              Extension fields are custom additional fields that can be added to Commit objects. Provide a name and type for this field. Description is optional for your reference.
              {' '}
              The type you select also determines what CRM fields can be mapped into this Extension Field and cannot be changed later.
            </>
          )}
      </div>
      <div className="flex justify-between">
        <Label text="Field Name" />
        {invalidFields.includes('fieldName') && <Label text="Required" className="text-color-fb6c6a" />}
      </div>
      <input
        className="w-full px-3 py-2 mb-6 border border-color-d6d9e6 focus:outline-none rounded"
        value={fieldName.value}
        onChange={fieldName.onChange}
        autoFocus={true}
        {...editData && { placeholder: editData.originalLabel || '' }} />
      <div className="flex justify-between">
        <Label text="Type" />
        {invalidFields.includes('type') && <Label text="Required" className="text-color-fb6c6a" />}
      </div>
      <div className="mb-6">
        <SelectList
          value={fieldTypeOptions.length > 1 ? selectedFieldType : defaultFieldType.value}
          onChange={onTypeChange}
          options={fieldTypeOptions}
          disabled={editData && !isExtensionField} />
      </div>
      {formatOptions.length > 1
        && (
          <>
            <div className="flex justify-between">
              <Label text="Format" />
              {invalidFields.includes('format') && <Label text="Required" className="text-color-fb6c6a" />}
            </div>
            <div className="mb-6">
              <SelectList
                value={formatOptions.length > 1 ? selectedFormat : defaultFormat.value}
                onChange={onFormatChange}
                options={formatOptions} />
            </div>
          </>
        )}
      <div className="flex justify-between">
        <Label text="Description" />
      </div>
      <textarea
        className="w-full px-3 py-2 mb-6 border border-color-d6d9e6 focus:outline-none rounded"
        value={description.value}
        onChange={description.onChange}
        rows="5" />
    </>
  )
}

export default AddEditFieldForm
