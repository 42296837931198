import classNames from 'classnames'
import React from 'react'

const Input = ({ className, ...props }, ref) => (
  <input
    className={classNames('border border-color-d6d9e6 rounded w-full p-2 mb-6 outline-none', { [className]: !!className })}
    ref={ref}
    type="text"
    {...props}
  />
)

export default React.forwardRef(Input)
