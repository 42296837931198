import React, { useCallback } from 'react'
import classNames from 'classnames'
import Label from '../../common/label'

const DisplayBox = (props) => {
  const {
    disabled = false,
    option,
    selected,
    onSelect } = props

  const { label, description, config, value } = option

  const boxClasses = `
    border
    border-color-b3afff
    rounded
    flex
    flex-col
    items-center
    p-1
    mb-1
    bg-color-ffffff`

  const onSelectInternal = useCallback(() => {
    if (selected || disabled) {
      return
    }

    onSelect(value)
  }, [onSelect, selected, value, disabled])

  return (
    <div
      onClick={onSelectInternal}
      className={
        classNames(
          'flex-1 mr-3 last:mr-0 rounded flex flex-col items-center p-4',
          { 'cursor-pointer hover:bg-color-f1f0fe': !disabled },
          { 'cursor-not-allowed opacity-50': disabled },
          { 'border border-color-2e5bff border-opacity-8 ': !selected },
          { 'border-2 border-color-5951FF border-opacity-100 bg-color-f1f0fe': selected && !disabled }
        )
      }>
      <div className={classNames(boxClasses, 'w-24 h-32')}>
        <div className={
          classNames(
            boxClasses,
            'self-stretch h-6',
            { 'bg-color-5951FF': !!config[0] }
          )
        } />
        <div className={
          classNames(
            boxClasses,
            'self-stretch h-12',
            { 'bg-color-5951FF': !!config[1] }
          )
        } />
      </div>
      <div className="mt-2">
        <Label text={label} />
        <Label
          text={description}
          className="text-size-14px text-color-51636a font-weight-400" />
      </div>
    </div>
  )
}

export default DisplayBox
