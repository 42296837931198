import React, { useCallback, useMemo } from 'react'
import { MappingStatus } from '../../grpc/enums'
import { useHistory } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import classNames from 'classnames'
import Tooltip from '../common/tooltip'
import CircleEmpty from '../icons/circleEmpty'
import Circle from '../icons/circle'
import Asterisk from '../icons/asterisk'

const ObjectMappingListItem = (props) => {
  const {
    activeCount = 0,
    objectMapping,
    ...rest
  } = props

  const history = useHistory()
  const { routes } = useRoutes()

  const name = useMemo(() => {
    return objectMapping.label
  }, [objectMapping])

  const description = useMemo(() => {
    return objectMapping.description
  }, [objectMapping])

  const managed = useMemo(() => {
    return objectMapping.managed
  }, [objectMapping])

  const statusIcon = useMemo(() => {
    switch (objectMapping.status) {
      case MappingStatus.STATUS_INACTIVE:
        return <CircleEmpty fill="#818e93" transform="scale(0.55)" />
      case MappingStatus.STATUS_ACTIVE:
        return <Circle fill="#1dcf83" transform="scale(0.55)" />
      case MappingStatus.STATUS_ERROR:
        return <Circle fill="#fb6c6a" transform="scale(0.55)" />
      default:
        return ''
    }
  }, [objectMapping])

  const statusLabel = useMemo(() => {
    switch (objectMapping.status) {
      case MappingStatus.STATUS_INACTIVE:
        return 'Inactive'
      case MappingStatus.STATUS_ACTIVE:
        return 'Active'
      case MappingStatus.STATUS_ERROR:
        return 'Error'
      default:
        return ''
    }
  }, [objectMapping])

  const onClick = useCallback(() => {
    if (managed) {
      return
    }
    const { label, objectName } = objectMapping
    window.analytics.track('Data Sync Object Clicked', { objectName })
    history.push(
      routes.dataSyncObjectMapping
        .replace(':name', label)
        .replace(':data', btoa(JSON.stringify({ activeCount, label, objectName })))
    )
  }, [activeCount, managed, objectMapping])

  return (
    <div className="md:w-1/3" {...rest}>
      <div className="mr-3 my-2">
        <Tooltip placement="top" arrow={true} title={managed ? 'This object is managed by Outreach.' : ''}>
          <div
            onClick={onClick}
            className={classNames('p-4 flex flex-col rounded-lg bg-color-ffffff border border-color-2e5bff-08 focus:outline-none',
              { 'cursor-pointer': !managed },
              { 'cursor-default opacity-25': managed })}>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="text-color-09242f text-size-16px font-weight-700">{name}</div>
                {objectMapping.required && (
                  <div style={{ transform: 'translate(4px, -4px)' }}>
                    <Asterisk fill="#fb6c6a" />
                  </div>
                )}

              </div>
              <div className="flex items-center">
                {statusIcon}
                <div className="text-color-818e93 text-size-12px font-weight-600" style={{ transform: 'translateY(1px)' }}>{statusLabel}</div>
              </div>
            </div>
            <div className="text-color-51636a text-size-12px font-weight-400 leading-tight">
              {description}
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default ObjectMappingListItem
