import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toGetCanonicalObjectRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useAuth } from './auth'

const CanonicalObjectContext = React.createContext()

export function CanonicalObjectProvider({ objectName, children }) {
  const { tenantId } = useAuth()
  const { notifyError } = useNotification()

  const [isFetching, setIsFetching] = useState(false)
  const [key, setKey] = useState(guid())
  const [canonicalObject, setCanonicalObject] = useState({})

  useGrpcEffect({
    request: toGetCanonicalObjectRequest({
      tenantId,
      objectName,
    }),
    onError: () => {
      notifyError(`Error fetching canonical object mapping ${objectName}!`)
      setIsFetching(false)
    },
    onSuccess: (obj) => {
      setIsFetching(false)
      if (obj.object) {
        setCanonicalObject(obj.object)
      }
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getCanonicalObject',
    debug: false,
  }, [tenantId, objectName, key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      setIsFetching,
      canonicalObject,
      setCanonicalObject,
      key,
      invalidate,
    }
  }, [isFetching, canonicalObject])

  return <CanonicalObjectContext.Provider value={contextValue}>{children}</CanonicalObjectContext.Provider>
}

export function useCanonicalObject() {
  const context = React.useContext(CanonicalObjectContext)
  if (context === undefined) {
    throw new Error('useCanonicalObject must be used within a CanonicalObjectProvider')
  }
  return context
}
