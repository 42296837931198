// source: src/common/gml/gml.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.loupe.common.gml.Content', null, global);
goog.exportSymbol('proto.loupe.common.gml.ControlType', null, global);
goog.exportSymbol('proto.loupe.common.gml.Data', null, global);
goog.exportSymbol('proto.loupe.common.gml.DataType', null, global);
goog.exportSymbol('proto.loupe.common.gml.EmailContentAttributes', null, global);
goog.exportSymbol('proto.loupe.common.gml.Function', null, global);
goog.exportSymbol('proto.loupe.common.gml.GML', null, global);
goog.exportSymbol('proto.loupe.common.gml.Include', null, global);
goog.exportSymbol('proto.loupe.common.gml.UIControl', null, global);
goog.exportSymbol('proto.loupe.common.gml.UIControlAttribute', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.gml.GML = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.gml.GML.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.gml.GML, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.gml.GML.displayName = 'proto.loupe.common.gml.GML';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.gml.Include = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.gml.Include, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.gml.Include.displayName = 'proto.loupe.common.gml.Include';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.gml.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.gml.Data.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.gml.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.gml.Data.displayName = 'proto.loupe.common.gml.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.gml.Function = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.gml.Function, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.gml.Function.displayName = 'proto.loupe.common.gml.Function';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.gml.Content = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.gml.Content.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.gml.Content, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.gml.Content.displayName = 'proto.loupe.common.gml.Content';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.gml.EmailContentAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.gml.EmailContentAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.gml.EmailContentAttributes.displayName = 'proto.loupe.common.gml.EmailContentAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.gml.UIControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.gml.UIControl.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.gml.UIControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.gml.UIControl.displayName = 'proto.loupe.common.gml.UIControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.gml.UIControlAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.gml.UIControlAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.gml.UIControlAttribute.displayName = 'proto.loupe.common.gml.UIControlAttribute';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.gml.GML.repeatedFields_ = [2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.gml.GML.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.gml.GML.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.gml.GML} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.GML.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includedList: jspb.Message.toObjectList(msg.getIncludedList(),
    proto.loupe.common.gml.Include.toObject, includeInstance),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.loupe.common.gml.Data.toObject, includeInstance),
    functionsList: jspb.Message.toObjectList(msg.getFunctionsList(),
    proto.loupe.common.gml.Function.toObject, includeInstance),
    contentsList: jspb.Message.toObjectList(msg.getContentsList(),
    proto.loupe.common.gml.Content.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.gml.GML}
 */
proto.loupe.common.gml.GML.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.gml.GML;
  return proto.loupe.common.gml.GML.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.gml.GML} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.gml.GML}
 */
proto.loupe.common.gml.GML.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 2:
      var value = new proto.loupe.common.gml.Include;
      reader.readMessage(value,proto.loupe.common.gml.Include.deserializeBinaryFromReader);
      msg.addIncluded(value);
      break;
    case 3:
      var value = new proto.loupe.common.gml.Data;
      reader.readMessage(value,proto.loupe.common.gml.Data.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 4:
      var value = new proto.loupe.common.gml.Function;
      reader.readMessage(value,proto.loupe.common.gml.Function.deserializeBinaryFromReader);
      msg.addFunctions(value);
      break;
    case 5:
      var value = new proto.loupe.common.gml.Content;
      reader.readMessage(value,proto.loupe.common.gml.Content.deserializeBinaryFromReader);
      msg.addContents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.gml.GML.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.gml.GML.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.gml.GML} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.GML.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.common.gml.Include.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.common.gml.Data.serializeBinaryToWriter
    );
  }
  f = message.getFunctionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.loupe.common.gml.Function.serializeBinaryToWriter
    );
  }
  f = message.getContentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.loupe.common.gml.Content.serializeBinaryToWriter
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.loupe.common.gml.GML.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.GML} returns this
 */
proto.loupe.common.gml.GML.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Include included = 2;
 * @return {!Array<!proto.loupe.common.gml.Include>}
 */
proto.loupe.common.gml.GML.prototype.getIncludedList = function() {
  return /** @type{!Array<!proto.loupe.common.gml.Include>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.gml.Include, 2));
};


/**
 * @param {!Array<!proto.loupe.common.gml.Include>} value
 * @return {!proto.loupe.common.gml.GML} returns this
*/
proto.loupe.common.gml.GML.prototype.setIncludedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.gml.Include=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.gml.Include}
 */
proto.loupe.common.gml.GML.prototype.addIncluded = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.gml.Include, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.gml.GML} returns this
 */
proto.loupe.common.gml.GML.prototype.clearIncludedList = function() {
  return this.setIncludedList([]);
};


/**
 * repeated Data data = 3;
 * @return {!Array<!proto.loupe.common.gml.Data>}
 */
proto.loupe.common.gml.GML.prototype.getDataList = function() {
  return /** @type{!Array<!proto.loupe.common.gml.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.gml.Data, 3));
};


/**
 * @param {!Array<!proto.loupe.common.gml.Data>} value
 * @return {!proto.loupe.common.gml.GML} returns this
*/
proto.loupe.common.gml.GML.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.gml.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.gml.Data}
 */
proto.loupe.common.gml.GML.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.gml.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.gml.GML} returns this
 */
proto.loupe.common.gml.GML.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * repeated Function functions = 4;
 * @return {!Array<!proto.loupe.common.gml.Function>}
 */
proto.loupe.common.gml.GML.prototype.getFunctionsList = function() {
  return /** @type{!Array<!proto.loupe.common.gml.Function>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.gml.Function, 4));
};


/**
 * @param {!Array<!proto.loupe.common.gml.Function>} value
 * @return {!proto.loupe.common.gml.GML} returns this
*/
proto.loupe.common.gml.GML.prototype.setFunctionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.gml.Function=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.gml.Function}
 */
proto.loupe.common.gml.GML.prototype.addFunctions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.gml.Function, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.gml.GML} returns this
 */
proto.loupe.common.gml.GML.prototype.clearFunctionsList = function() {
  return this.setFunctionsList([]);
};


/**
 * repeated Content contents = 5;
 * @return {!Array<!proto.loupe.common.gml.Content>}
 */
proto.loupe.common.gml.GML.prototype.getContentsList = function() {
  return /** @type{!Array<!proto.loupe.common.gml.Content>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.gml.Content, 5));
};


/**
 * @param {!Array<!proto.loupe.common.gml.Content>} value
 * @return {!proto.loupe.common.gml.GML} returns this
*/
proto.loupe.common.gml.GML.prototype.setContentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.gml.Content=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.gml.Content}
 */
proto.loupe.common.gml.GML.prototype.addContents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.gml.Content, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.gml.GML} returns this
 */
proto.loupe.common.gml.GML.prototype.clearContentsList = function() {
  return this.setContentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.gml.Include.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.gml.Include.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.gml.Include} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.Include.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    href: jspb.Message.getFieldWithDefault(msg, 2, ""),
    includedGml: (f = msg.getIncludedGml()) && proto.loupe.common.gml.GML.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.gml.Include}
 */
proto.loupe.common.gml.Include.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.gml.Include;
  return proto.loupe.common.gml.Include.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.gml.Include} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.gml.Include}
 */
proto.loupe.common.gml.Include.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHref(value);
      break;
    case 3:
      var value = new proto.loupe.common.gml.GML;
      reader.readMessage(value,proto.loupe.common.gml.GML.deserializeBinaryFromReader);
      msg.setIncludedGml(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.gml.Include.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.gml.Include.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.gml.Include} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.Include.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHref();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIncludedGml();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.loupe.common.gml.GML.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.gml.Include.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.Include} returns this
 */
proto.loupe.common.gml.Include.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string href = 2;
 * @return {string}
 */
proto.loupe.common.gml.Include.prototype.getHref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.Include} returns this
 */
proto.loupe.common.gml.Include.prototype.setHref = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional GML included_gml = 3;
 * @return {?proto.loupe.common.gml.GML}
 */
proto.loupe.common.gml.Include.prototype.getIncludedGml = function() {
  return /** @type{?proto.loupe.common.gml.GML} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.gml.GML, 3));
};


/**
 * @param {?proto.loupe.common.gml.GML|undefined} value
 * @return {!proto.loupe.common.gml.Include} returns this
*/
proto.loupe.common.gml.Include.prototype.setIncludedGml = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.gml.Include} returns this
 */
proto.loupe.common.gml.Include.prototype.clearIncludedGml = function() {
  return this.setIncludedGml(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.gml.Include.prototype.hasIncludedGml = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.gml.Data.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.gml.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.gml.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.gml.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.loupe.common.gml.Data.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.gml.Data}
 */
proto.loupe.common.gml.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.gml.Data;
  return proto.loupe.common.gml.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.gml.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.gml.Data}
 */
proto.loupe.common.gml.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.gml.DataType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.loupe.common.gml.Data;
      reader.readMessage(value,proto.loupe.common.gml.Data.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.gml.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.gml.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.gml.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.common.gml.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.gml.Data.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.Data} returns this
 */
proto.loupe.common.gml.Data.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DataType type = 2;
 * @return {!proto.loupe.common.gml.DataType}
 */
proto.loupe.common.gml.Data.prototype.getType = function() {
  return /** @type {!proto.loupe.common.gml.DataType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.gml.DataType} value
 * @return {!proto.loupe.common.gml.Data} returns this
 */
proto.loupe.common.gml.Data.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated Data children = 3;
 * @return {!Array<!proto.loupe.common.gml.Data>}
 */
proto.loupe.common.gml.Data.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.loupe.common.gml.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.gml.Data, 3));
};


/**
 * @param {!Array<!proto.loupe.common.gml.Data>} value
 * @return {!proto.loupe.common.gml.Data} returns this
*/
proto.loupe.common.gml.Data.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.gml.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.gml.Data}
 */
proto.loupe.common.gml.Data.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.gml.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.gml.Data} returns this
 */
proto.loupe.common.gml.Data.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.gml.Function.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.gml.Function.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.gml.Function} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.Function.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    raw: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.gml.Function}
 */
proto.loupe.common.gml.Function.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.gml.Function;
  return proto.loupe.common.gml.Function.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.gml.Function} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.gml.Function}
 */
proto.loupe.common.gml.Function.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRaw(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.gml.Function.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.gml.Function.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.gml.Function} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.Function.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRaw();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.gml.Function.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.Function} returns this
 */
proto.loupe.common.gml.Function.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string raw = 2;
 * @return {string}
 */
proto.loupe.common.gml.Function.prototype.getRaw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.Function} returns this
 */
proto.loupe.common.gml.Function.prototype.setRaw = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.gml.Content.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.gml.Content.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.gml.Content.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.gml.Content} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.Content.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.loupe.common.gml.UIControl.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    emailAttributes: (f = msg.getEmailAttributes()) && proto.loupe.common.gml.EmailContentAttributes.toObject(includeInstance, f),
    channel: jspb.Message.getFieldWithDefault(msg, 5, ""),
    size: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.gml.Content}
 */
proto.loupe.common.gml.Content.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.gml.Content;
  return proto.loupe.common.gml.Content.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.gml.Content} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.gml.Content}
 */
proto.loupe.common.gml.Content.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.loupe.common.gml.UIControl;
      reader.readMessage(value,proto.loupe.common.gml.UIControl.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.loupe.common.gml.EmailContentAttributes;
      reader.readMessage(value,proto.loupe.common.gml.EmailContentAttributes.deserializeBinaryFromReader);
      msg.setEmailAttributes(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.gml.Content.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.gml.Content.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.gml.Content} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.Content.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.common.gml.UIControl.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmailAttributes();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.loupe.common.gml.EmailContentAttributes.serializeBinaryToWriter
    );
  }
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSize();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.gml.Content.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.Content} returns this
 */
proto.loupe.common.gml.Content.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated UIControl children = 2;
 * @return {!Array<!proto.loupe.common.gml.UIControl>}
 */
proto.loupe.common.gml.Content.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.loupe.common.gml.UIControl>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.gml.UIControl, 2));
};


/**
 * @param {!Array<!proto.loupe.common.gml.UIControl>} value
 * @return {!proto.loupe.common.gml.Content} returns this
*/
proto.loupe.common.gml.Content.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.gml.UIControl=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.gml.UIControl}
 */
proto.loupe.common.gml.Content.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.gml.UIControl, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.gml.Content} returns this
 */
proto.loupe.common.gml.Content.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.loupe.common.gml.Content.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.Content} returns this
 */
proto.loupe.common.gml.Content.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional EmailContentAttributes email_attributes = 4;
 * @return {?proto.loupe.common.gml.EmailContentAttributes}
 */
proto.loupe.common.gml.Content.prototype.getEmailAttributes = function() {
  return /** @type{?proto.loupe.common.gml.EmailContentAttributes} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.gml.EmailContentAttributes, 4));
};


/**
 * @param {?proto.loupe.common.gml.EmailContentAttributes|undefined} value
 * @return {!proto.loupe.common.gml.Content} returns this
*/
proto.loupe.common.gml.Content.prototype.setEmailAttributes = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.gml.Content} returns this
 */
proto.loupe.common.gml.Content.prototype.clearEmailAttributes = function() {
  return this.setEmailAttributes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.gml.Content.prototype.hasEmailAttributes = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string channel = 5;
 * @return {string}
 */
proto.loupe.common.gml.Content.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.Content} returns this
 */
proto.loupe.common.gml.Content.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string size = 6;
 * @return {string}
 */
proto.loupe.common.gml.Content.prototype.getSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.Content} returns this
 */
proto.loupe.common.gml.Content.prototype.setSize = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.gml.EmailContentAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.gml.EmailContentAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.EmailContentAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: (f = msg.getSubject()) && proto.loupe.common.gml.UIControlAttribute.toObject(includeInstance, f),
    to: (f = msg.getTo()) && proto.loupe.common.gml.UIControlAttribute.toObject(includeInstance, f),
    cc: (f = msg.getCc()) && proto.loupe.common.gml.UIControlAttribute.toObject(includeInstance, f),
    bcc: (f = msg.getBcc()) && proto.loupe.common.gml.UIControlAttribute.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && proto.loupe.common.gml.UIControlAttribute.toObject(includeInstance, f),
    template: (f = msg.getTemplate()) && proto.loupe.common.gml.UIControlAttribute.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.gml.EmailContentAttributes}
 */
proto.loupe.common.gml.EmailContentAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.gml.EmailContentAttributes;
  return proto.loupe.common.gml.EmailContentAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.gml.EmailContentAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.gml.EmailContentAttributes}
 */
proto.loupe.common.gml.EmailContentAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.gml.UIControlAttribute;
      reader.readMessage(value,proto.loupe.common.gml.UIControlAttribute.deserializeBinaryFromReader);
      msg.setSubject(value);
      break;
    case 2:
      var value = new proto.loupe.common.gml.UIControlAttribute;
      reader.readMessage(value,proto.loupe.common.gml.UIControlAttribute.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 3:
      var value = new proto.loupe.common.gml.UIControlAttribute;
      reader.readMessage(value,proto.loupe.common.gml.UIControlAttribute.deserializeBinaryFromReader);
      msg.setCc(value);
      break;
    case 4:
      var value = new proto.loupe.common.gml.UIControlAttribute;
      reader.readMessage(value,proto.loupe.common.gml.UIControlAttribute.deserializeBinaryFromReader);
      msg.setBcc(value);
      break;
    case 5:
      var value = new proto.loupe.common.gml.UIControlAttribute;
      reader.readMessage(value,proto.loupe.common.gml.UIControlAttribute.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 6:
      var value = new proto.loupe.common.gml.UIControlAttribute;
      reader.readMessage(value,proto.loupe.common.gml.UIControlAttribute.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.gml.EmailContentAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.gml.EmailContentAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.EmailContentAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.common.gml.UIControlAttribute.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.common.gml.UIControlAttribute.serializeBinaryToWriter
    );
  }
  f = message.getCc();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.loupe.common.gml.UIControlAttribute.serializeBinaryToWriter
    );
  }
  f = message.getBcc();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.loupe.common.gml.UIControlAttribute.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.loupe.common.gml.UIControlAttribute.serializeBinaryToWriter
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loupe.common.gml.UIControlAttribute.serializeBinaryToWriter
    );
  }
};


/**
 * optional UIControlAttribute subject = 1;
 * @return {?proto.loupe.common.gml.UIControlAttribute}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.getSubject = function() {
  return /** @type{?proto.loupe.common.gml.UIControlAttribute} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.gml.UIControlAttribute, 1));
};


/**
 * @param {?proto.loupe.common.gml.UIControlAttribute|undefined} value
 * @return {!proto.loupe.common.gml.EmailContentAttributes} returns this
*/
proto.loupe.common.gml.EmailContentAttributes.prototype.setSubject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.gml.EmailContentAttributes} returns this
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.clearSubject = function() {
  return this.setSubject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.hasSubject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UIControlAttribute to = 2;
 * @return {?proto.loupe.common.gml.UIControlAttribute}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.getTo = function() {
  return /** @type{?proto.loupe.common.gml.UIControlAttribute} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.gml.UIControlAttribute, 2));
};


/**
 * @param {?proto.loupe.common.gml.UIControlAttribute|undefined} value
 * @return {!proto.loupe.common.gml.EmailContentAttributes} returns this
*/
proto.loupe.common.gml.EmailContentAttributes.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.gml.EmailContentAttributes} returns this
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.hasTo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UIControlAttribute cc = 3;
 * @return {?proto.loupe.common.gml.UIControlAttribute}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.getCc = function() {
  return /** @type{?proto.loupe.common.gml.UIControlAttribute} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.gml.UIControlAttribute, 3));
};


/**
 * @param {?proto.loupe.common.gml.UIControlAttribute|undefined} value
 * @return {!proto.loupe.common.gml.EmailContentAttributes} returns this
*/
proto.loupe.common.gml.EmailContentAttributes.prototype.setCc = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.gml.EmailContentAttributes} returns this
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.clearCc = function() {
  return this.setCc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.hasCc = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UIControlAttribute bcc = 4;
 * @return {?proto.loupe.common.gml.UIControlAttribute}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.getBcc = function() {
  return /** @type{?proto.loupe.common.gml.UIControlAttribute} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.gml.UIControlAttribute, 4));
};


/**
 * @param {?proto.loupe.common.gml.UIControlAttribute|undefined} value
 * @return {!proto.loupe.common.gml.EmailContentAttributes} returns this
*/
proto.loupe.common.gml.EmailContentAttributes.prototype.setBcc = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.gml.EmailContentAttributes} returns this
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.clearBcc = function() {
  return this.setBcc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.hasBcc = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UIControlAttribute from = 5;
 * @return {?proto.loupe.common.gml.UIControlAttribute}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.getFrom = function() {
  return /** @type{?proto.loupe.common.gml.UIControlAttribute} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.gml.UIControlAttribute, 5));
};


/**
 * @param {?proto.loupe.common.gml.UIControlAttribute|undefined} value
 * @return {!proto.loupe.common.gml.EmailContentAttributes} returns this
*/
proto.loupe.common.gml.EmailContentAttributes.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.gml.EmailContentAttributes} returns this
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UIControlAttribute template = 6;
 * @return {?proto.loupe.common.gml.UIControlAttribute}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.getTemplate = function() {
  return /** @type{?proto.loupe.common.gml.UIControlAttribute} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.gml.UIControlAttribute, 6));
};


/**
 * @param {?proto.loupe.common.gml.UIControlAttribute|undefined} value
 * @return {!proto.loupe.common.gml.EmailContentAttributes} returns this
*/
proto.loupe.common.gml.EmailContentAttributes.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.gml.EmailContentAttributes} returns this
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.gml.EmailContentAttributes.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.gml.UIControl.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.gml.UIControl.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.gml.UIControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.gml.UIControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.UIControl.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    proto.loupe.common.gml.UIControlAttribute.toObject, includeInstance),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.loupe.common.gml.UIControl.toObject, includeInstance),
    text: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.gml.UIControl}
 */
proto.loupe.common.gml.UIControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.gml.UIControl;
  return proto.loupe.common.gml.UIControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.gml.UIControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.gml.UIControl}
 */
proto.loupe.common.gml.UIControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.gml.ControlType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.loupe.common.gml.UIControlAttribute;
      reader.readMessage(value,proto.loupe.common.gml.UIControlAttribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    case 4:
      var value = new proto.loupe.common.gml.UIControl;
      reader.readMessage(value,proto.loupe.common.gml.UIControl.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.gml.UIControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.gml.UIControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.gml.UIControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.UIControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.common.gml.UIControlAttribute.serializeBinaryToWriter
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.loupe.common.gml.UIControl.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.gml.UIControl.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.UIControl} returns this
 */
proto.loupe.common.gml.UIControl.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ControlType type = 2;
 * @return {!proto.loupe.common.gml.ControlType}
 */
proto.loupe.common.gml.UIControl.prototype.getType = function() {
  return /** @type {!proto.loupe.common.gml.ControlType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.gml.ControlType} value
 * @return {!proto.loupe.common.gml.UIControl} returns this
 */
proto.loupe.common.gml.UIControl.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated UIControlAttribute attributes = 3;
 * @return {!Array<!proto.loupe.common.gml.UIControlAttribute>}
 */
proto.loupe.common.gml.UIControl.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.loupe.common.gml.UIControlAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.gml.UIControlAttribute, 3));
};


/**
 * @param {!Array<!proto.loupe.common.gml.UIControlAttribute>} value
 * @return {!proto.loupe.common.gml.UIControl} returns this
*/
proto.loupe.common.gml.UIControl.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.gml.UIControlAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.gml.UIControlAttribute}
 */
proto.loupe.common.gml.UIControl.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.gml.UIControlAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.gml.UIControl} returns this
 */
proto.loupe.common.gml.UIControl.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};


/**
 * repeated UIControl children = 4;
 * @return {!Array<!proto.loupe.common.gml.UIControl>}
 */
proto.loupe.common.gml.UIControl.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.loupe.common.gml.UIControl>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.gml.UIControl, 4));
};


/**
 * @param {!Array<!proto.loupe.common.gml.UIControl>} value
 * @return {!proto.loupe.common.gml.UIControl} returns this
*/
proto.loupe.common.gml.UIControl.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.gml.UIControl=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.gml.UIControl}
 */
proto.loupe.common.gml.UIControl.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.gml.UIControl, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.gml.UIControl} returns this
 */
proto.loupe.common.gml.UIControl.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};


/**
 * optional string text = 5;
 * @return {string}
 */
proto.loupe.common.gml.UIControl.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.UIControl} returns this
 */
proto.loupe.common.gml.UIControl.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.gml.UIControlAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.gml.UIControlAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.gml.UIControlAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.UIControlAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    evaluate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.gml.UIControlAttribute}
 */
proto.loupe.common.gml.UIControlAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.gml.UIControlAttribute;
  return proto.loupe.common.gml.UIControlAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.gml.UIControlAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.gml.UIControlAttribute}
 */
proto.loupe.common.gml.UIControlAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEvaluate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.gml.UIControlAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.gml.UIControlAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.gml.UIControlAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.gml.UIControlAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEvaluate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.gml.UIControlAttribute.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.UIControlAttribute} returns this
 */
proto.loupe.common.gml.UIControlAttribute.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.loupe.common.gml.UIControlAttribute.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.gml.UIControlAttribute} returns this
 */
proto.loupe.common.gml.UIControlAttribute.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool evaluate = 3;
 * @return {boolean}
 */
proto.loupe.common.gml.UIControlAttribute.prototype.getEvaluate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.gml.UIControlAttribute} returns this
 */
proto.loupe.common.gml.UIControlAttribute.prototype.setEvaluate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.gml.DataType = {
  UNKNOWNDATATYPE: 0,
  STRING: 1,
  NUMBER: 2,
  INT: 3,
  FLOAT: 4,
  BOOL: 5,
  DATETIME: 6,
  TIMESTAMP: 7,
  OBJECT: 8,
  ARRAY: 9,
  JSON: 10
};

/**
 * @enum {number}
 */
proto.loupe.common.gml.ControlType = {
  UNKNOWNCONTROLTYPE: 0,
  CONTAINER: 1,
  REPEAT: 2,
  IF: 3,
  TEXT: 4,
  PLURAL: 5,
  DATETIMEMASK: 6,
  NUMBERMASK: 7,
  CALLOUTITEM: 8,
  LEGENDITEM: 9,
  BARCHART: 10,
  PLAINTEXT: 11,
  EVAL: 12,
  BUTTON: 13,
  LINK: 14,
  CHARTTEXT: 15,
  TOOLTIP: 16,
  PLUGIN: 17,
  FUNNELCHART: 18,
  LINECHART: 19,
  DONUTCHART: 20,
  ROW: 21,
  COLUMN: 22,
  TOOLTIPCONTENT: 23,
  ICON: 24,
  FRAGMENT: 25
};

goog.object.extend(exports, proto.loupe.common.gml);
