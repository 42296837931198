import React, { useCallback, useMemo, useState } from 'react'
import Label from '../../common/label'
import SelectList from '../../common/selectList'
import { useForecastingConfigColumn } from '../../../context/forecastingConfigColumn'
import DisplayBox from './displayBox'
import {
  forecastConfigColumnDisplayOptions,
  forecastConfigColumnDisplayVisibilityOptions
} from '../constants'
import { ForecastConfigColumnDisplayVisibilityOption, ForecastConfigValueFormat } from '../../../grpc/enums'

const ForecastConfigCategoriesModalDisplay = (props) => {
  const {
    configColumn,
    updateConfigColumn
  } = useForecastingConfigColumn()

  const {
    displayOption,
    visibilityOption = forecastConfigColumnDisplayOptions[0].value,
    forecastConfigValueSettings = {}
  } = configColumn

  const { objectValueSettings = {} } = forecastConfigValueSettings
  const { rollupFieldFormatEnum = ForecastConfigValueFormat.CURRENCY } = objectValueSettings

  const formatOptions = [
    { value: ForecastConfigValueFormat.CURRENCY, label: 'Currency' },
    { value: ForecastConfigValueFormat.NUMBER, label: 'Number' },
    { value: ForecastConfigValueFormat.PERCENT, label: 'Percent' },
    { value: ForecastConfigValueFormat.COVERAGE, label: 'Ratio' }
  ]

  const displayOptionSelected = useCallback((option) => {
    updateConfigColumn({ displayOption: option })
  }, [updateConfigColumn])

  const visibilityOptionSelected = useCallback(({ value }) => {
    updateConfigColumn({ visibilityOption: value })
  }, [updateConfigColumn])

  const displayOptionDisabled = useMemo(() => {
    return visibilityOption === ForecastConfigColumnDisplayVisibilityOption.IC
  }, [visibilityOption])

  const formatOptionSelected = useCallback((format) => {
    updateConfigColumn({
      forecastConfigValueSettings: {
        objectValueSettings: {
          ...objectValueSettings,
          rollupFieldFormatEnum: format.value
        }
      }
    })
  }, [objectValueSettings, updateConfigColumn])

  return (
    <div>
      <div className="mb-4">
        <Label
          text="Appearance"
          className="text-size-20px font-weight-700" />
        <Label
          text="Choose how this metric appears within a forecast for managers."
          className="text-color-51636a font-weight-400" />

        <div className="my-2 flex flex-row justify-between">
          {forecastConfigColumnDisplayOptions.map((option) => (
            <DisplayBox
              key={option.value}
              disabled={displayOptionDisabled}
              option={option}
              selected={option.value === displayOption || (displayOption === 0 && option.value === 3)}
              onSelect={displayOptionSelected} />
          ))}
        </div>
      </div>

      <div className="mb-4">
        <Label text="Visibility" />
        <Label
          text="Choose who can view this metric within a forecast."
          className="font-weight-400 w-120 mb-2 text-color-51636a" />
        <div className="flex flex-col items-start w-2/3">
          <SelectList
            onChange={visibilityOptionSelected}
            options={forecastConfigColumnDisplayVisibilityOptions}
            value={visibilityOption} />
        </div>
      </div>

      <Label text="Format" className="mt-1" />
      <Label
        text="Choose how this formula will be displayed."
        className="font-weight-400 w-96 text-color-51636a mb-1" />
      <div className="flex flex-col items-start w-2/3">
        <SelectList
          className="mb-3"
          onChange={formatOptionSelected}
          options={formatOptions}
          value={rollupFieldFormatEnum} />
      </div>
    </div>
  )
}

export default ForecastConfigCategoriesModalDisplay
