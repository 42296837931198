import './scss/tailwind.scss'
import React from 'react'
import { render } from 'react-dom'
import App from './app'
import { RoutesProvider } from './context/routes'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'tippy.js/dist/backdrop.css'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/animations/shift-away.css'

render(
  <RoutesProvider>
    <App />
  </RoutesProvider>,
  document.getElementById('root')
)
