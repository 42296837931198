import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toGetCanonicalObjectMappingRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useAuth } from './auth'
import { cloneDeep } from 'lodash'

const CanonicalObjectMappingContext = React.createContext()

export function CanonicalObjectMappingProvider({ objectName, children }) {
  const { tenantId } = useAuth()
  const { notifyError } = useNotification()

  const [isFetching, setIsFetching] = useState(true)
  const [key, setKey] = useState(guid())
  const [crmObjectNamesList, setCrmObjectNamesList] = useState([])
  const [canonicalObjectMapping, setCanonicalObjectMapping] = useState({})
  const [selectedCrmObjectName, setSelectedCrmObjectName] = useState('')
  const [fieldsListIntsAndFloats, setFieldsListIntsAndFloats] = useState([])
  const [fieldsListDates, setFieldsListDates] = useState([])
  const [fieldsListStrings, setFieldsListStrings] = useState([])

  useGrpcEffect({
    request: toGetCanonicalObjectMappingRequest({
      tenantId,
      objectName,
      crmObjectNamesList,
    }),
    onError: () => {
      notifyError(`Error fetching canonical object mapping ${objectName}!`)
      setIsFetching(false)
    },
    onSuccess: (obj) => {
      setIsFetching(false)
      if (obj.object) {
        setCanonicalObjectMapping(obj.object)
        if (obj.object.crmObjectsList && obj.object.crmObjectsList.length > 0) {
          setSelectedCrmObjectName(obj.object.crmObjectsList[0].objectName)
          if (obj.object.crmObjectsList[0].fieldsList && obj.object.crmObjectsList[0].fieldsList.length > 0) {
            setFieldsListIntsAndFloats(obj.object.crmObjectsList[0].fieldsList
              .filter((f) => f.toType === 'FLOAT' || f.toType === 'INT')
              .map((f, i) => { return { ...f, value: i + 1 } }))
            setFieldsListDates(obj.object.crmObjectsList[0].fieldsList
              .filter((f) => f.toType === 'DATE')
              .map((f, i) => { return { ...f, value: i + 1 } }))
            setFieldsListStrings(obj.object.crmObjectsList[0].fieldsList
              .filter((f) => f.toType === 'STRING')
              .map((f, i) => { return { ...f, value: i + 1 } }))
          }
        }
      }
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getCanonicalObjectMapping',
    debug: false,
  }, [tenantId, objectName, crmObjectNamesList, key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const setStatusDetailsList = useCallback((statusDetailsList) => {
    const obj = cloneDeep(canonicalObjectMapping)
    obj.statusDetailsList = statusDetailsList
    setCanonicalObjectMapping(obj)
  }, [canonicalObjectMapping])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      setIsFetching,
      crmObjectNamesList,
      setCrmObjectNamesList,
      canonicalObjectMapping,
      setCanonicalObjectMapping,
      selectedCrmObjectName,
      setSelectedCrmObjectName,
      fieldsListIntsAndFloats,
      fieldsListDates,
      fieldsListStrings,
      key,
      invalidate,
      setStatusDetailsList,
    }
  }, [isFetching,
    crmObjectNamesList,
    canonicalObjectMapping,
    selectedCrmObjectName,
    fieldsListIntsAndFloats,
    fieldsListDates,
    fieldsListStrings,
    key,
    invalidate,
    setStatusDetailsList])

  return <CanonicalObjectMappingContext.Provider value={contextValue}>{children}</CanonicalObjectMappingContext.Provider>
}

export function useCanonicalObjectMapping() {
  const context = React.useContext(CanonicalObjectMappingContext)
  if (context === undefined) {
    throw new Error('useCanonicalObjectMapping must be used within a CanonicalObjectMappingProvider')
  }
  return context
}
