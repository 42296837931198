// source: src/common/tenant/mapping/v2/object_mapping.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_mapping_mapping_pb = require('../../../../../src/common/mapping/mapping_pb.js');
goog.object.extend(proto, src_common_mapping_mapping_pb);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.CurrencyLookupType', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.CurrencySettings', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.CustomFieldSource', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.DiscreteTransform', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.FieldDefinition', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.FieldOptionItem', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.FieldOptions', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.FieldPermission', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.FieldReference', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.FieldType', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.FieldWriteDef', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.FieldWriteDefField', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.IntegrationSourceType', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.ObjectAssociation', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.ObjectAssociationType', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.ObjectFilter', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.ObjectIndex', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.ObjectMapping', null, global);
goog.exportSymbol('proto.loupe.common.tenant.mapping.v2.ObjectPermissions', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.tenant.mapping.v2.ObjectMapping.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.ObjectMapping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.ObjectMapping.displayName = 'proto.loupe.common.tenant.mapping.v2.ObjectMapping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.tenant.mapping.v2.ObjectIndex.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.ObjectIndex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.ObjectIndex.displayName = 'proto.loupe.common.tenant.mapping.v2.ObjectIndex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.ObjectAssociation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.ObjectAssociation.displayName = 'proto.loupe.common.tenant.mapping.v2.ObjectAssociation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.ObjectFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.ObjectFilter.displayName = 'proto.loupe.common.tenant.mapping.v2.ObjectFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.displayName = 'proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.ObjectPermissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.ObjectPermissions.displayName = 'proto.loupe.common.tenant.mapping.v2.ObjectPermissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.tenant.mapping.v2.FieldDefinition.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.FieldDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.FieldDefinition.displayName = 'proto.loupe.common.tenant.mapping.v2.FieldDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.FieldReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.FieldReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.FieldReference.displayName = 'proto.loupe.common.tenant.mapping.v2.FieldReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.tenant.mapping.v2.FieldOptions.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.FieldOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.FieldOptions.displayName = 'proto.loupe.common.tenant.mapping.v2.FieldOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.FieldOptionItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.FieldOptionItem.displayName = 'proto.loupe.common.tenant.mapping.v2.FieldOptionItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.tenant.mapping.v2.FieldWriteDef.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.FieldWriteDef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.FieldWriteDef.displayName = 'proto.loupe.common.tenant.mapping.v2.FieldWriteDef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.displayName = 'proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.FieldWriteDefField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.displayName = 'proto.loupe.common.tenant.mapping.v2.FieldWriteDefField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.FieldPermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.FieldPermission.displayName = 'proto.loupe.common.tenant.mapping.v2.FieldPermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.CurrencySettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.CurrencySettings.displayName = 'proto.loupe.common.tenant.mapping.v2.CurrencySettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.tenant.mapping.v2.CustomFieldSource.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.CustomFieldSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.CustomFieldSource.displayName = 'proto.loupe.common.tenant.mapping.v2.CustomFieldSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.tenant.mapping.v2.DiscreteTransform, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.mapping.v2.DiscreteTransform.displayName = 'proto.loupe.common.tenant.mapping.v2.DiscreteTransform';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.repeatedFields_ = [8,9,12,13,14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.ObjectMapping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, ""),
    to: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    label: jspb.Message.getFieldWithDefault(msg, 4, ""),
    source: jspb.Message.getFieldWithDefault(msg, 5, ""),
    integrationSourceType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    index: (f = msg.getIndex()) && proto.loupe.common.tenant.mapping.v2.ObjectIndex.toObject(includeInstance, f),
    associationsList: jspb.Message.toObjectList(msg.getAssociationsList(),
    proto.loupe.common.tenant.mapping.v2.ObjectAssociation.toObject, includeInstance),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.loupe.common.tenant.mapping.v2.ObjectFilter.toObject, includeInstance),
    current: (f = msg.getCurrent()) && proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.toObject(includeInstance, f),
    permissions: (f = msg.getPermissions()) && proto.loupe.common.tenant.mapping.v2.ObjectPermissions.toObject(includeInstance, f),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.loupe.common.tenant.mapping.v2.FieldDefinition.toObject, includeInstance),
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    proto.loupe.common.tenant.mapping.v2.ObjectMapping.toObject, includeInstance),
    indexedFieldsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    fieldPermissionsList: jspb.Message.toObjectList(msg.getFieldPermissionsList(),
    proto.loupe.common.tenant.mapping.v2.FieldPermission.toObject, includeInstance),
    managerPermissions: (f = msg.getManagerPermissions()) && proto.loupe.common.tenant.mapping.v2.ObjectPermissions.toObject(includeInstance, f),
    managed: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    currencySettings: (f = msg.getCurrencySettings()) && proto.loupe.common.tenant.mapping.v2.CurrencySettings.toObject(includeInstance, f),
    syncStatus: jspb.Message.getFieldWithDefault(msg, 19, 0),
    outreachName: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.ObjectMapping;
  return proto.loupe.common.tenant.mapping.v2.ObjectMapping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 6:
      var value = /** @type {!proto.loupe.common.tenant.mapping.v2.IntegrationSourceType} */ (reader.readEnum());
      msg.setIntegrationSourceType(value);
      break;
    case 7:
      var value = new proto.loupe.common.tenant.mapping.v2.ObjectIndex;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.ObjectIndex.deserializeBinaryFromReader);
      msg.setIndex(value);
      break;
    case 8:
      var value = new proto.loupe.common.tenant.mapping.v2.ObjectAssociation;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.ObjectAssociation.deserializeBinaryFromReader);
      msg.addAssociations(value);
      break;
    case 9:
      var value = new proto.loupe.common.tenant.mapping.v2.ObjectFilter;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.ObjectFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 10:
      var value = new proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.deserializeBinaryFromReader);
      msg.setCurrent(value);
      break;
    case 11:
      var value = new proto.loupe.common.tenant.mapping.v2.ObjectPermissions;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.ObjectPermissions.deserializeBinaryFromReader);
      msg.setPermissions(value);
      break;
    case 12:
      var value = new proto.loupe.common.tenant.mapping.v2.FieldDefinition;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.FieldDefinition.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 13:
      var value = new proto.loupe.common.tenant.mapping.v2.ObjectMapping;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.ObjectMapping.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addIndexedFields(value);
      break;
    case 15:
      var value = new proto.loupe.common.tenant.mapping.v2.FieldPermission;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.FieldPermission.deserializeBinaryFromReader);
      msg.addFieldPermissions(value);
      break;
    case 16:
      var value = new proto.loupe.common.tenant.mapping.v2.ObjectPermissions;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.ObjectPermissions.deserializeBinaryFromReader);
      msg.setManagerPermissions(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManaged(value);
      break;
    case 18:
      var value = new proto.loupe.common.tenant.mapping.v2.CurrencySettings;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.CurrencySettings.deserializeBinaryFromReader);
      msg.setCurrencySettings(value);
      break;
    case 19:
      var value = /** @type {!proto.loupe.common.mapping.SyncStatus} */ (reader.readEnum());
      msg.setSyncStatus(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.ObjectMapping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIntegrationSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getIndex();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.common.tenant.mapping.v2.ObjectIndex.serializeBinaryToWriter
    );
  }
  f = message.getAssociationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.loupe.common.tenant.mapping.v2.ObjectAssociation.serializeBinaryToWriter
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.loupe.common.tenant.mapping.v2.ObjectFilter.serializeBinaryToWriter
    );
  }
  f = message.getCurrent();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.serializeBinaryToWriter
    );
  }
  f = message.getPermissions();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.loupe.common.tenant.mapping.v2.ObjectPermissions.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.loupe.common.tenant.mapping.v2.FieldDefinition.serializeBinaryToWriter
    );
  }
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.loupe.common.tenant.mapping.v2.ObjectMapping.serializeBinaryToWriter
    );
  }
  f = message.getIndexedFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getFieldPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.loupe.common.tenant.mapping.v2.FieldPermission.serializeBinaryToWriter
    );
  }
  f = message.getManagerPermissions();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.loupe.common.tenant.mapping.v2.ObjectPermissions.serializeBinaryToWriter
    );
  }
  f = message.getManaged();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getCurrencySettings();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.loupe.common.tenant.mapping.v2.CurrencySettings.serializeBinaryToWriter
    );
  }
  f = message.getSyncStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getOutreachName();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string from = 1;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to = 2;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string source = 5;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional IntegrationSourceType integration_source_type = 6;
 * @return {!proto.loupe.common.tenant.mapping.v2.IntegrationSourceType}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getIntegrationSourceType = function() {
  return /** @type {!proto.loupe.common.tenant.mapping.v2.IntegrationSourceType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.IntegrationSourceType} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setIntegrationSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ObjectIndex index = 7;
 * @return {?proto.loupe.common.tenant.mapping.v2.ObjectIndex}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getIndex = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.ObjectIndex} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.tenant.mapping.v2.ObjectIndex, 7));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.ObjectIndex|undefined} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
*/
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setIndex = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.clearIndex = function() {
  return this.setIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.hasIndex = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated ObjectAssociation associations = 8;
 * @return {!Array<!proto.loupe.common.tenant.mapping.v2.ObjectAssociation>}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getAssociationsList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.mapping.v2.ObjectAssociation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.tenant.mapping.v2.ObjectAssociation, 8));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.mapping.v2.ObjectAssociation>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
*/
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setAssociationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectAssociation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectAssociation}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.addAssociations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.loupe.common.tenant.mapping.v2.ObjectAssociation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.clearAssociationsList = function() {
  return this.setAssociationsList([]);
};


/**
 * repeated ObjectFilter filters = 9;
 * @return {!Array<!proto.loupe.common.tenant.mapping.v2.ObjectFilter>}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.mapping.v2.ObjectFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.tenant.mapping.v2.ObjectFilter, 9));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.mapping.v2.ObjectFilter>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
*/
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectFilter}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.loupe.common.tenant.mapping.v2.ObjectFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional ObjectCurrentDef current = 10;
 * @return {?proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getCurrent = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef, 10));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef|undefined} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
*/
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setCurrent = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.clearCurrent = function() {
  return this.setCurrent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.hasCurrent = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ObjectPermissions permissions = 11;
 * @return {?proto.loupe.common.tenant.mapping.v2.ObjectPermissions}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getPermissions = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.ObjectPermissions} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.tenant.mapping.v2.ObjectPermissions, 11));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.ObjectPermissions|undefined} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
*/
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setPermissions = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.clearPermissions = function() {
  return this.setPermissions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.hasPermissions = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated FieldDefinition fields = 12;
 * @return {!Array<!proto.loupe.common.tenant.mapping.v2.FieldDefinition>}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.mapping.v2.FieldDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.tenant.mapping.v2.FieldDefinition, 12));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.mapping.v2.FieldDefinition>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
*/
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.loupe.common.tenant.mapping.v2.FieldDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * repeated ObjectMapping objects = 13;
 * @return {!Array<!proto.loupe.common.tenant.mapping.v2.ObjectMapping>}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.mapping.v2.ObjectMapping>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.tenant.mapping.v2.ObjectMapping, 13));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.mapping.v2.ObjectMapping>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
*/
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectMapping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.loupe.common.tenant.mapping.v2.ObjectMapping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};


/**
 * repeated string indexed_fields = 14;
 * @return {!Array<string>}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getIndexedFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setIndexedFieldsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.addIndexedFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.clearIndexedFieldsList = function() {
  return this.setIndexedFieldsList([]);
};


/**
 * repeated FieldPermission field_permissions = 15;
 * @return {!Array<!proto.loupe.common.tenant.mapping.v2.FieldPermission>}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getFieldPermissionsList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.mapping.v2.FieldPermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.tenant.mapping.v2.FieldPermission, 15));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.mapping.v2.FieldPermission>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
*/
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setFieldPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldPermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldPermission}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.addFieldPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.loupe.common.tenant.mapping.v2.FieldPermission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.clearFieldPermissionsList = function() {
  return this.setFieldPermissionsList([]);
};


/**
 * optional ObjectPermissions manager_permissions = 16;
 * @return {?proto.loupe.common.tenant.mapping.v2.ObjectPermissions}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getManagerPermissions = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.ObjectPermissions} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.tenant.mapping.v2.ObjectPermissions, 16));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.ObjectPermissions|undefined} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
*/
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setManagerPermissions = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.clearManagerPermissions = function() {
  return this.setManagerPermissions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.hasManagerPermissions = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool managed = 17;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getManaged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setManaged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional CurrencySettings currency_settings = 18;
 * @return {?proto.loupe.common.tenant.mapping.v2.CurrencySettings}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getCurrencySettings = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.CurrencySettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.tenant.mapping.v2.CurrencySettings, 18));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.CurrencySettings|undefined} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
*/
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setCurrencySettings = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.clearCurrencySettings = function() {
  return this.setCurrencySettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.hasCurrencySettings = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional loupe.common.mapping.SyncStatus sync_status = 19;
 * @return {!proto.loupe.common.mapping.SyncStatus}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getSyncStatus = function() {
  return /** @type {!proto.loupe.common.mapping.SyncStatus} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.loupe.common.mapping.SyncStatus} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setSyncStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string outreach_name = 20;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.getOutreachName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectMapping.prototype.setOutreachName = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.ObjectIndex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectIndex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.toObject = function(includeInstance, msg) {
  var f, obj = {
    primary: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clusterList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    partitionList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectIndex}
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.ObjectIndex;
  return proto.loupe.common.tenant.mapping.v2.ObjectIndex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectIndex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectIndex}
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimary(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCluster(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addPartition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.ObjectIndex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectIndex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrimary();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClusterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getPartitionList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string primary = 1;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.prototype.getPrimary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectIndex} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.prototype.setPrimary = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string cluster = 2;
 * @return {!Array<string>}
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.prototype.getClusterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectIndex} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.prototype.setClusterList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectIndex} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.prototype.addCluster = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectIndex} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.prototype.clearClusterList = function() {
  return this.setClusterList([]);
};


/**
 * repeated string partition = 3;
 * @return {!Array<string>}
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.prototype.getPartitionList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectIndex} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.prototype.setPartitionList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectIndex} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.prototype.addPartition = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectIndex} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectIndex.prototype.clearPartitionList = function() {
  return this.setPartitionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.ObjectAssociation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectAssociation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sourceField: jspb.Message.getFieldWithDefault(msg, 3, ""),
    targetField: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectAssociation}
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.ObjectAssociation;
  return proto.loupe.common.tenant.mapping.v2.ObjectAssociation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectAssociation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectAssociation}
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.tenant.mapping.v2.ObjectAssociationType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceField(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.ObjectAssociation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectAssociation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSourceField();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTargetField();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectAssociation} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ObjectAssociationType type = 2;
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectAssociationType}
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.prototype.getType = function() {
  return /** @type {!proto.loupe.common.tenant.mapping.v2.ObjectAssociationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectAssociationType} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectAssociation} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string source_field = 3;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.prototype.getSourceField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectAssociation} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.prototype.setSourceField = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string target_field = 4;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.prototype.getTargetField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectAssociation} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociation.prototype.setTargetField = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.ObjectFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    raw: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: msg.getValue_asB64(),
    comparisonOp: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectFilter}
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.ObjectFilter;
  return proto.loupe.common.tenant.mapping.v2.ObjectFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectFilter}
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRaw(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComparisonOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.ObjectFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRaw();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getComparisonOp();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string raw = 1;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.prototype.getRaw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectFilter} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.prototype.setRaw = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field = 2;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectFilter} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes value = 3;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.prototype.getValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes value = 3;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectFilter} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional string comparison_op = 4;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.prototype.getComparisonOp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectFilter} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectFilter.prototype.setComparisonOp = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.toObject = function(includeInstance, msg) {
  var f, obj = {
    partitionList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    orderField: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderDirection: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef}
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef;
  return proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef}
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPartition(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartitionList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getOrderField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderDirection();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated string partition = 1;
 * @return {!Array<string>}
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.prototype.getPartitionList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.prototype.setPartitionList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.prototype.addPartition = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.prototype.clearPartitionList = function() {
  return this.setPartitionList([]);
};


/**
 * optional string order_field = 2;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.prototype.getOrderField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.prototype.setOrderField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string order_direction = 3;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.prototype.getOrderDirection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectCurrentDef.prototype.setOrderDirection = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.ObjectPermissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectPermissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    create: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    read: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    update: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    pb_delete: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectPermissions}
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.ObjectPermissions;
  return proto.loupe.common.tenant.mapping.v2.ObjectPermissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectPermissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectPermissions}
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreate(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRead(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.ObjectPermissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectPermissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreate();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRead();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getUpdate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDelete();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool create = 1;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.prototype.getCreate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectPermissions} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.prototype.setCreate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool read = 2;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.prototype.getRead = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectPermissions} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.prototype.setRead = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool update = 3;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.prototype.getUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectPermissions} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.prototype.setUpdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool delete = 4;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.prototype.getDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectPermissions} returns this
 */
proto.loupe.common.tenant.mapping.v2.ObjectPermissions.prototype.setDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.FieldDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    to: jspb.Message.getFieldWithDefault(msg, 3, ""),
    toType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    label: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 7, 0),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    custom: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    canEdit: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    source: jspb.Message.getFieldWithDefault(msg, 12, ""),
    integrationSourceType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    format: jspb.Message.getFieldWithDefault(msg, 14, ""),
    category: jspb.Message.getFieldWithDefault(msg, 15, ""),
    options: (f = msg.getOptions()) && proto.loupe.common.tenant.mapping.v2.FieldOptions.toObject(includeInstance, f),
    write: (f = msg.getWrite()) && proto.loupe.common.tenant.mapping.v2.FieldWriteDef.toObject(includeInstance, f),
    compoundName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    ignore: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    managed: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    referencesList: jspb.Message.toObjectList(msg.getReferencesList(),
    proto.loupe.common.tenant.mapping.v2.FieldReference.toObject, includeInstance),
    syncStatus: jspb.Message.getFieldWithDefault(msg, 22, 0),
    outreachName: jspb.Message.getFieldWithDefault(msg, 23, ""),
    customValue: (f = msg.getCustomValue()) && proto.loupe.common.tenant.mapping.v2.CustomFieldSource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.FieldDefinition;
  return proto.loupe.common.tenant.mapping.v2.FieldDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustom(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanEdit(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 13:
      var value = /** @type {!proto.loupe.common.tenant.mapping.v2.IntegrationSourceType} */ (reader.readEnum());
      msg.setIntegrationSourceType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 16:
      var value = new proto.loupe.common.tenant.mapping.v2.FieldOptions;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.FieldOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 17:
      var value = new proto.loupe.common.tenant.mapping.v2.FieldWriteDef;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.FieldWriteDef.deserializeBinaryFromReader);
      msg.setWrite(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompoundName(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnore(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManaged(value);
      break;
    case 21:
      var value = new proto.loupe.common.tenant.mapping.v2.FieldReference;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.FieldReference.deserializeBinaryFromReader);
      msg.addReferences(value);
      break;
    case 22:
      var value = /** @type {!proto.loupe.common.mapping.SyncStatus} */ (reader.readEnum());
      msg.setSyncStatus(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachName(value);
      break;
    case 24:
      var value = new proto.loupe.common.tenant.mapping.v2.CustomFieldSource;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.CustomFieldSource.deserializeBinaryFromReader);
      msg.setCustomValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.FieldDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getToType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCustom();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCanEdit();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIntegrationSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.loupe.common.tenant.mapping.v2.FieldOptions.serializeBinaryToWriter
    );
  }
  f = message.getWrite();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.loupe.common.tenant.mapping.v2.FieldWriteDef.serializeBinaryToWriter
    );
  }
  f = message.getCompoundName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getIgnore();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getManaged();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.loupe.common.tenant.mapping.v2.FieldReference.serializeBinaryToWriter
    );
  }
  f = message.getSyncStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getOutreachName();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getCustomValue();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.loupe.common.tenant.mapping.v2.CustomFieldSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional string from = 1;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string from_type = 2;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getFromType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setFromType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string to = 3;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setTo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string to_type = 4;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getToType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setToType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string label = 6;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 sort = 7;
 * @return {number}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool required = 8;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool custom = 9;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool visible = 10;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool can_edit = 11;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getCanEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setCanEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string source = 12;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional IntegrationSourceType integration_source_type = 13;
 * @return {!proto.loupe.common.tenant.mapping.v2.IntegrationSourceType}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getIntegrationSourceType = function() {
  return /** @type {!proto.loupe.common.tenant.mapping.v2.IntegrationSourceType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.IntegrationSourceType} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setIntegrationSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string format = 14;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string category = 15;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional FieldOptions options = 16;
 * @return {?proto.loupe.common.tenant.mapping.v2.FieldOptions}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getOptions = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.FieldOptions} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.tenant.mapping.v2.FieldOptions, 16));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.FieldOptions|undefined} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
*/
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional FieldWriteDef write = 17;
 * @return {?proto.loupe.common.tenant.mapping.v2.FieldWriteDef}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getWrite = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.FieldWriteDef} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.tenant.mapping.v2.FieldWriteDef, 17));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.FieldWriteDef|undefined} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
*/
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setWrite = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.clearWrite = function() {
  return this.setWrite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.hasWrite = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string compound_name = 18;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getCompoundName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setCompoundName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional bool ignore = 19;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getIgnore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setIgnore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool managed = 20;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getManaged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setManaged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * repeated FieldReference references = 21;
 * @return {!Array<!proto.loupe.common.tenant.mapping.v2.FieldReference>}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getReferencesList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.mapping.v2.FieldReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.tenant.mapping.v2.FieldReference, 21));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.mapping.v2.FieldReference>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
*/
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldReference}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.addReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.loupe.common.tenant.mapping.v2.FieldReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.clearReferencesList = function() {
  return this.setReferencesList([]);
};


/**
 * optional loupe.common.mapping.SyncStatus sync_status = 22;
 * @return {!proto.loupe.common.mapping.SyncStatus}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getSyncStatus = function() {
  return /** @type {!proto.loupe.common.mapping.SyncStatus} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.loupe.common.mapping.SyncStatus} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setSyncStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional string outreach_name = 23;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getOutreachName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setOutreachName = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional CustomFieldSource custom_value = 24;
 * @return {?proto.loupe.common.tenant.mapping.v2.CustomFieldSource}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.getCustomValue = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.CustomFieldSource} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.tenant.mapping.v2.CustomFieldSource, 24));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.CustomFieldSource|undefined} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
*/
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.setCustomValue = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldDefinition} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.clearCustomValue = function() {
  return this.setCustomValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldDefinition.prototype.hasCustomValue = function() {
  return jspb.Message.getField(this, 24) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.FieldReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    relationshipName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayFieldName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldReference}
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.FieldReference;
  return proto.loupe.common.tenant.mapping.v2.FieldReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldReference}
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelationshipName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayFieldName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.FieldReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelationshipName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayFieldName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string relationship_name = 1;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.prototype.getRelationshipName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldReference} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.prototype.setRelationshipName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_name = 2;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldReference} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string field_name = 3;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldReference} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_field_name = 4;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.prototype.getDisplayFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldReference} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldReference.prototype.setDisplayFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.FieldOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.loupe.common.tenant.mapping.v2.FieldOptionItem.toObject, includeInstance),
    numericRangeList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    dateRangeList: jspb.Message.toObjectList(msg.getDateRangeList(),
    google_protobuf_timestamp_pb.Timestamp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptions}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.FieldOptions;
  return proto.loupe.common.tenant.mapping.v2.FieldOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptions}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.loupe.common.tenant.mapping.v2.FieldOptionItem;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.FieldOptionItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNumericRange(values[i]);
      }
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.addDateRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.FieldOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.common.tenant.mapping.v2.FieldOptionItem.serializeBinaryToWriter
    );
  }
  f = message.getNumericRangeList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
  f = message.getDateRangeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptions} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FieldOptionItem items = 2;
 * @return {!Array<!proto.loupe.common.tenant.mapping.v2.FieldOptionItem>}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.mapping.v2.FieldOptionItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.tenant.mapping.v2.FieldOptionItem, 2));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.mapping.v2.FieldOptionItem>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptions} returns this
*/
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldOptionItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptionItem}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.tenant.mapping.v2.FieldOptionItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptions} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * repeated double numeric_range = 3;
 * @return {!Array<number>}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.getNumericRangeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptions} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.setNumericRangeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptions} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.addNumericRange = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptions} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.clearNumericRangeList = function() {
  return this.setNumericRangeList([]);
};


/**
 * repeated google.protobuf.Timestamp date_range = 4;
 * @return {!Array<!proto.google.protobuf.Timestamp>}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.getDateRangeList = function() {
  return /** @type{!Array<!proto.google.protobuf.Timestamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {!Array<!proto.google.protobuf.Timestamp>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptions} returns this
*/
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.setDateRangeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.protobuf.Timestamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Timestamp}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.addDateRange = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.protobuf.Timestamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptions} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldOptions.prototype.clearDateRangeList = function() {
  return this.setDateRangeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.FieldOptionItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldOptionItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: msg.getValue_asB64(),
    isdefault: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    sort: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptionItem}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.FieldOptionItem;
  return proto.loupe.common.tenant.mapping.v2.FieldOptionItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldOptionItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptionItem}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdefault(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.FieldOptionItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldOptionItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getIsdefault();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptionItem} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptionItem} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes value = 3;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.getValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes value = 3;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptionItem} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bool isDefault = 4;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.getIsdefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptionItem} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.setIsdefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 sort = 5;
 * @return {number}
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldOptionItem} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldOptionItem.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDef.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDef.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.FieldWriteDef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldWriteDef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDef.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDef}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.FieldWriteDef;
  return proto.loupe.common.tenant.mapping.v2.FieldWriteDef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldWriteDef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDef}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.FieldWriteDef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldWriteDef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FieldWriteDefObject objects = 1;
 * @return {!Array<!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject>}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDef.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject, 1));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDef} returns this
*/
proto.loupe.common.tenant.mapping.v2.FieldWriteDef.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDef.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDef} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDef.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject;
  return proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.loupe.common.tenant.mapping.v2.FieldWriteDefField;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FieldWriteDefField fields = 2;
 * @return {!Array<!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField>}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.tenant.mapping.v2.FieldWriteDefField, 2));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject} returns this
*/
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.tenant.mapping.v2.FieldWriteDefField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefObject.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: msg.getValue_asB64(),
    query: jspb.Message.getFieldWithDefault(msg, 3, ""),
    script: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.FieldWriteDefField;
  return proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScript(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScript();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes value = 2;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.prototype.getValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes value = 2;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string query = 3;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string script = 4;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.prototype.getScript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldWriteDefField} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldWriteDefField.prototype.setScript = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.FieldPermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldPermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    canView: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    canEdit: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    canManagerEdit: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    field: jspb.Message.getFieldWithDefault(msg, 4, ""),
    source: jspb.Message.getFieldWithDefault(msg, 5, ""),
    integrationSourceType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldPermission}
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.FieldPermission;
  return proto.loupe.common.tenant.mapping.v2.FieldPermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldPermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldPermission}
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanView(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanEdit(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanManagerEdit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 6:
      var value = /** @type {!proto.loupe.common.tenant.mapping.v2.IntegrationSourceType} */ (reader.readEnum());
      msg.setIntegrationSourceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.FieldPermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.FieldPermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanView();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCanEdit();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCanManagerEdit();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIntegrationSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional bool can_view = 1;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.getCanView = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldPermission} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.setCanView = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool can_edit = 2;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.getCanEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldPermission} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.setCanEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool can_manager_edit = 3;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.getCanManagerEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldPermission} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.setCanManagerEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string field = 4;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldPermission} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string source = 5;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldPermission} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional IntegrationSourceType integration_source_type = 6;
 * @return {!proto.loupe.common.tenant.mapping.v2.IntegrationSourceType}
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.getIntegrationSourceType = function() {
  return /** @type {!proto.loupe.common.tenant.mapping.v2.IntegrationSourceType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.IntegrationSourceType} value
 * @return {!proto.loupe.common.tenant.mapping.v2.FieldPermission} returns this
 */
proto.loupe.common.tenant.mapping.v2.FieldPermission.prototype.setIntegrationSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.CurrencySettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.CurrencySettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dateLookupField: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currencyLookupField: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.CurrencySettings}
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.CurrencySettings;
  return proto.loupe.common.tenant.mapping.v2.CurrencySettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.CurrencySettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.CurrencySettings}
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.tenant.mapping.v2.CurrencyLookupType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateLookupField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyLookupField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.CurrencySettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.CurrencySettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDateLookupField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrencyLookupField();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional CurrencyLookupType type = 1;
 * @return {!proto.loupe.common.tenant.mapping.v2.CurrencyLookupType}
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings.prototype.getType = function() {
  return /** @type {!proto.loupe.common.tenant.mapping.v2.CurrencyLookupType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.CurrencyLookupType} value
 * @return {!proto.loupe.common.tenant.mapping.v2.CurrencySettings} returns this
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string date_lookup_field = 2;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings.prototype.getDateLookupField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.CurrencySettings} returns this
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings.prototype.setDateLookupField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string currency_lookup_field = 3;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings.prototype.getCurrencyLookupField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.CurrencySettings} returns this
 */
proto.loupe.common.tenant.mapping.v2.CurrencySettings.prototype.setCurrencyLookupField = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.CustomFieldSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.CustomFieldSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryExpr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valueMappingsList: jspb.Message.toObjectList(msg.getValueMappingsList(),
    proto.loupe.common.tenant.mapping.v2.DiscreteTransform.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.CustomFieldSource}
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.CustomFieldSource;
  return proto.loupe.common.tenant.mapping.v2.CustomFieldSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.CustomFieldSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.CustomFieldSource}
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryExpr(value);
      break;
    case 3:
      var value = new proto.loupe.common.tenant.mapping.v2.DiscreteTransform;
      reader.readMessage(value,proto.loupe.common.tenant.mapping.v2.DiscreteTransform.deserializeBinaryFromReader);
      msg.addValueMappings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.CustomFieldSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.CustomFieldSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryExpr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValueMappingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.common.tenant.mapping.v2.DiscreteTransform.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query_expr = 1;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.prototype.getQueryExpr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.CustomFieldSource} returns this
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.prototype.setQueryExpr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DiscreteTransform value_mappings = 3;
 * @return {!Array<!proto.loupe.common.tenant.mapping.v2.DiscreteTransform>}
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.prototype.getValueMappingsList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.mapping.v2.DiscreteTransform>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.tenant.mapping.v2.DiscreteTransform, 3));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.mapping.v2.DiscreteTransform>} value
 * @return {!proto.loupe.common.tenant.mapping.v2.CustomFieldSource} returns this
*/
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.prototype.setValueMappingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.DiscreteTransform=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.DiscreteTransform}
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.prototype.addValueMappings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.tenant.mapping.v2.DiscreteTransform, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.mapping.v2.CustomFieldSource} returns this
 */
proto.loupe.common.tenant.mapping.v2.CustomFieldSource.prototype.clearValueMappingsList = function() {
  return this.setValueMappingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.mapping.v2.DiscreteTransform.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.mapping.v2.DiscreteTransform} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.toObject = function(includeInstance, msg) {
  var f, obj = {
    input: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inputExpr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    output: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outputExpr: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.mapping.v2.DiscreteTransform}
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.mapping.v2.DiscreteTransform;
  return proto.loupe.common.tenant.mapping.v2.DiscreteTransform.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.mapping.v2.DiscreteTransform} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.mapping.v2.DiscreteTransform}
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInputExpr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutput(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutputExpr(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.mapping.v2.DiscreteTransform.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.mapping.v2.DiscreteTransform} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInput();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInputExpr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutput();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutputExpr();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDefault();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string input = 1;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.prototype.getInput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.DiscreteTransform} returns this
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.prototype.setInput = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string input_expr = 2;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.prototype.getInputExpr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.DiscreteTransform} returns this
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.prototype.setInputExpr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string output = 3;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.prototype.getOutput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.DiscreteTransform} returns this
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.prototype.setOutput = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string output_expr = 4;
 * @return {string}
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.prototype.getOutputExpr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.mapping.v2.DiscreteTransform} returns this
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.prototype.setOutputExpr = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool default = 5;
 * @return {boolean}
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.mapping.v2.DiscreteTransform} returns this
 */
proto.loupe.common.tenant.mapping.v2.DiscreteTransform.prototype.setDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.tenant.mapping.v2.IntegrationSourceType = {
  UNKNOWN: 0,
  CANOPY: 1,
  CANONICAL: 2,
  RAW: 3,
  CUSTOM: 4
};

/**
 * @enum {number}
 */
proto.loupe.common.tenant.mapping.v2.ObjectAssociationType = {
  INNER: 0,
  LEFT: 1,
  RIGHT: 2,
  FULL: 3
};

/**
 * @enum {number}
 */
proto.loupe.common.tenant.mapping.v2.CurrencyLookupType = {
  CURRENCYLOOKUPTYPE_STANDARD: 0,
  CURRENCYLOOKUPTYPE_DATED: 1
};

/**
 * @enum {number}
 */
proto.loupe.common.tenant.mapping.v2.FieldType = {
  ANY: 0,
  STRING: 1,
  INTEGER: 3,
  FLOAT: 4,
  BOOLEAN: 5,
  OBJECT: 6,
  DATE: 7,
  TIMESTAMP: 8,
  DATETIME: 9,
  LOCATION: 10,
  BYTES: 11,
  BASE64: 12
};

goog.object.extend(exports, proto.loupe.common.tenant.mapping.v2);
