import React, { useCallback, useMemo } from 'react'
import { useUsers } from '../../context/users'
import Paging from '../common/paging'
import UserListItem from './userListItem'
import numeral from 'numeral'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const UsersList = (props) => {
  const {
    onEdit,
    onDelete,
  } = props

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanUpdateUser,
      permissionNames.CanDeleteUser
    )
  }, [checkPermissions])

  const { users, page, setPage, pageSize } = useUsers()

  const { peopleList = [], total } = users

  const onSetPage = useCallback((currentPage) => {
    setPage(currentPage)
  }, [])

  return (
    <>
      <div className="flex-table">
        <div className="thead">
          <div className="tr">
            {(permissions.CanUpdateUser || permissions.CanDeleteUser) && (
              <div className="td-menu" />
            )}
            <div className="td w-48">Name</div>
            <div className="td w-10">Status</div>
            <div className="td w-10">Role</div>
          </div>
        </div>
        <div className="tbody">
          {peopleList.map((user, index) => (
            <UserListItem
              key={`UserListItem-${user.id}-${index}`}
              user={user}
              onEdit={onEdit}
              onDelete={onDelete}
              {...index === 0 && { id: 'joyride_users_userListItem' }}
            />
          ))}
        </div>
      </div>
      <Paging
        className="flex w-full items-center justify-between py-3"
        textClassName="text-color-151d49 text-size-15px font-weight-600"
        navigationClassName="flex items-center"
        total={total}
        limit={pageSize}
        page={page}
        onSetPage={onSetPage}
        textFormatter={(start, end, totalPages) => `${numeral(start).format('0,0')} - ${numeral(end).format('0,0')} of ${numeral(totalPages).format('0,0')}`} />
    </>
  )
}

export default UsersList
