import React, { useCallback, useMemo, useState } from 'react'
import { cloneDeep } from 'lodash'

const ObjectMappingChangesContext = React.createContext()

export function ObjectMappingChangesProvider({ children }) {
  const [objectMappingChanges, setObjectMappingChanges] = useState({})
  const [writeBackPermissionChanges, setWriteBackPermissionChanges] = useState({})

  const updateObjectMappingField = useCallback(({ objectName, fieldName, mappedField }) => {
    const changes = cloneDeep(objectMappingChanges)
    if (!objectMappingChanges[objectName]) {
      changes[objectName] = {}
    }
    if (fieldName) {
      changes[objectName][fieldName] = mappedField
    }
    // console.log('objectMappingChanges', changes)
    setObjectMappingChanges(changes)
  }, [objectMappingChanges])

  const updateWriteBackPermissions = useCallback(({ objectName, fieldName, data }) => {
    const changes = cloneDeep(writeBackPermissionChanges)
    if (!writeBackPermissionChanges[objectName]) {
      changes[objectName] = {}
    }
    if (fieldName) {
      changes[objectName][fieldName] = data?.permissions ?? {}
    }
    setWriteBackPermissionChanges(changes)
  }, [writeBackPermissionChanges])

  const contextValue = useMemo(() => {
    return {
      objectMappingChanges,
      setObjectMappingChanges,
      updateObjectMappingField,
      writeBackPermissionChanges,
      setWriteBackPermissionChanges,
      updateWriteBackPermissions,
    }
  }, [objectMappingChanges, updateObjectMappingField, writeBackPermissionChanges, updateWriteBackPermissions])

  return <ObjectMappingChangesContext.Provider value={contextValue}>{children}</ObjectMappingChangesContext.Provider>
}

export function useObjectMappingChanges() {
  const context = React.useContext(ObjectMappingChangesContext)
  if (context === undefined) {
    throw new Error('useObjectMappingChanges must be used within a ObjectMappingChangesProvider')
  }
  return context
}
