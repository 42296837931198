import React, { useCallback } from 'react'
import ForecastsMain from './forecastsMain'
import { ForecastingConfigsProvider } from '../../context/forecastingConfigs'

import { OnboardingStep } from '../../grpc/enums'
import { JoyrideProvider } from '../../context/joyride'
import { useSetup } from '../../context/setup'

const Forecasts = (props) => {
  const { isFetching, completeStep, isStepComplete } = useSetup()

  const onCompleteJoyride = useCallback(() => {
    completeStep(OnboardingStep.ONBOARDING_STEP_FORECASTS)
  }, [completeStep])

  return (
    <ForecastingConfigsProvider>
      <JoyrideProvider debug={false} enabled={!isFetching && !isStepComplete(OnboardingStep.ONBOARDING_STEP_FORECASTS)} onComplete={onCompleteJoyride}>
        <ForecastsMain />
      </JoyrideProvider>
    </ForecastingConfigsProvider>
  )
}

export default Forecasts
