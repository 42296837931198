// source: src/common/realtime/view.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_realtime_query_pb = require('../../../src/common/realtime/query_pb.js');
goog.object.extend(proto, src_common_realtime_query_pb);
var src_common_gml_gml_pb = require('../../../src/common/gml/gml_pb.js');
goog.object.extend(proto, src_common_gml_gml_pb);
goog.exportSymbol('proto.loupe.common.realtime.View', null, global);
goog.exportSymbol('proto.loupe.common.realtime.ViewDependencies', null, global);
goog.exportSymbol('proto.loupe.common.realtime.ViewStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.View = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.realtime.View.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.realtime.View, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.View.displayName = 'proto.loupe.common.realtime.View';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.realtime.ViewDependencies = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.realtime.ViewDependencies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.realtime.ViewDependencies.displayName = 'proto.loupe.common.realtime.ViewDependencies';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.realtime.View.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.View.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.View.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.View} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.View.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    queryKey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    query: (f = msg.getQuery()) && src_common_realtime_query_pb.Query.toObject(includeInstance, f),
    view: (f = msg.getView()) && src_common_gml_gml_pb.GML.toObject(includeInstance, f),
    nlgMap: (f = msg.getNlgMap()) ? f.toObject(includeInstance, undefined) : [],
    tagsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    contract: (f = msg.getContract()) && src_common_realtime_query_pb.QueryContract.toObject(includeInstance, f),
    dependencies: (f = msg.getDependencies()) && proto.loupe.common.realtime.ViewDependencies.toObject(includeInstance, f),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
    status: jspb.Message.getFieldWithDefault(msg, 12, 0),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 13, ""),
    version: jspb.Message.getFieldWithDefault(msg, 14, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.View}
 */
proto.loupe.common.realtime.View.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.View;
  return proto.loupe.common.realtime.View.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.View} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.View}
 */
proto.loupe.common.realtime.View.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryKey(value);
      break;
    case 5:
      var value = new src_common_realtime_query_pb.Query;
      reader.readMessage(value,src_common_realtime_query_pb.Query.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    case 6:
      var value = new src_common_gml_gml_pb.GML;
      reader.readMessage(value,src_common_gml_gml_pb.GML.deserializeBinaryFromReader);
      msg.setView(value);
      break;
    case 7:
      var value = msg.getNlgMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBytes, null, "", "");
         });
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 9:
      var value = new src_common_realtime_query_pb.QueryContract;
      reader.readMessage(value,src_common_realtime_query_pb.QueryContract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    case 10:
      var value = new proto.loupe.common.realtime.ViewDependencies;
      reader.readMessage(value,proto.loupe.common.realtime.ViewDependencies.deserializeBinaryFromReader);
      msg.setDependencies(value);
      break;
    case 11:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 12:
      var value = /** @type {!proto.loupe.common.realtime.ViewStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.View.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.View.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.View} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.View.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQueryKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_realtime_query_pb.Query.serializeBinaryToWriter
    );
  }
  f = message.getView();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_gml_gml_pb.GML.serializeBinaryToWriter
    );
  }
  f = message.getNlgMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBytes);
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      src_common_realtime_query_pb.QueryContract.serializeBinaryToWriter
    );
  }
  f = message.getDependencies();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.loupe.common.realtime.ViewDependencies.serializeBinaryToWriter
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(11, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.realtime.View.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.loupe.common.realtime.View.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.loupe.common.realtime.View.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string query_key = 4;
 * @return {string}
 */
proto.loupe.common.realtime.View.prototype.getQueryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.setQueryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Query query = 5;
 * @return {?proto.loupe.common.realtime.Query}
 */
proto.loupe.common.realtime.View.prototype.getQuery = function() {
  return /** @type{?proto.loupe.common.realtime.Query} */ (
    jspb.Message.getWrapperField(this, src_common_realtime_query_pb.Query, 5));
};


/**
 * @param {?proto.loupe.common.realtime.Query|undefined} value
 * @return {!proto.loupe.common.realtime.View} returns this
*/
proto.loupe.common.realtime.View.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.View.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional loupe.common.gml.GML view = 6;
 * @return {?proto.loupe.common.gml.GML}
 */
proto.loupe.common.realtime.View.prototype.getView = function() {
  return /** @type{?proto.loupe.common.gml.GML} */ (
    jspb.Message.getWrapperField(this, src_common_gml_gml_pb.GML, 6));
};


/**
 * @param {?proto.loupe.common.gml.GML|undefined} value
 * @return {!proto.loupe.common.realtime.View} returns this
*/
proto.loupe.common.realtime.View.prototype.setView = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.clearView = function() {
  return this.setView(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.View.prototype.hasView = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * map<string, bytes> nlg = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!(string|Uint8Array)>}
 */
proto.loupe.common.realtime.View.prototype.getNlgMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!(string|Uint8Array)>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.clearNlgMap = function() {
  this.getNlgMap().clear();
  return this;};


/**
 * repeated string tags = 8;
 * @return {!Array<string>}
 */
proto.loupe.common.realtime.View.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional QueryContract contract = 9;
 * @return {?proto.loupe.common.realtime.QueryContract}
 */
proto.loupe.common.realtime.View.prototype.getContract = function() {
  return /** @type{?proto.loupe.common.realtime.QueryContract} */ (
    jspb.Message.getWrapperField(this, src_common_realtime_query_pb.QueryContract, 9));
};


/**
 * @param {?proto.loupe.common.realtime.QueryContract|undefined} value
 * @return {!proto.loupe.common.realtime.View} returns this
*/
proto.loupe.common.realtime.View.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.View.prototype.hasContract = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ViewDependencies dependencies = 10;
 * @return {?proto.loupe.common.realtime.ViewDependencies}
 */
proto.loupe.common.realtime.View.prototype.getDependencies = function() {
  return /** @type{?proto.loupe.common.realtime.ViewDependencies} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.realtime.ViewDependencies, 10));
};


/**
 * @param {?proto.loupe.common.realtime.ViewDependencies|undefined} value
 * @return {!proto.loupe.common.realtime.View} returns this
*/
proto.loupe.common.realtime.View.prototype.setDependencies = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.clearDependencies = function() {
  return this.setDependencies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.View.prototype.hasDependencies = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * map<string, string> metadata = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.common.realtime.View.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 11, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};


/**
 * optional ViewStatus status = 12;
 * @return {!proto.loupe.common.realtime.ViewStatus}
 */
proto.loupe.common.realtime.View.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.realtime.ViewStatus} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.loupe.common.realtime.ViewStatus} value
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string status_message = 13;
 * @return {string}
 */
proto.loupe.common.realtime.View.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string version = 14;
 * @return {string}
 */
proto.loupe.common.realtime.View.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.realtime.View.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.realtime.View} returns this
*/
proto.loupe.common.realtime.View.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.View.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.realtime.View.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.realtime.View} returns this
*/
proto.loupe.common.realtime.View.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.realtime.View} returns this
 */
proto.loupe.common.realtime.View.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.realtime.View.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.realtime.ViewDependencies.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.realtime.ViewDependencies.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.realtime.ViewDependencies} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.ViewDependencies.toObject = function(includeInstance, msg) {
  var f, obj = {
    viewVersionsMap: (f = msg.getViewVersionsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.realtime.ViewDependencies}
 */
proto.loupe.common.realtime.ViewDependencies.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.realtime.ViewDependencies;
  return proto.loupe.common.realtime.ViewDependencies.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.realtime.ViewDependencies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.realtime.ViewDependencies}
 */
proto.loupe.common.realtime.ViewDependencies.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getViewVersionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.realtime.ViewDependencies.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.realtime.ViewDependencies.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.realtime.ViewDependencies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.realtime.ViewDependencies.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewVersionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> view_versions = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.common.realtime.ViewDependencies.prototype.getViewVersionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.realtime.ViewDependencies} returns this
 */
proto.loupe.common.realtime.ViewDependencies.prototype.clearViewVersionsMap = function() {
  this.getViewVersionsMap().clear();
  return this;};


/**
 * @enum {number}
 */
proto.loupe.common.realtime.ViewStatus = {
  VIEW_STATUS_INACTIVE: 0,
  VIEW_STATUS_ACTIVE: 1,
  VIEW_STATUS_ERROR: 2,
  VIEW_STATUS_INVALID: 3
};

goog.object.extend(exports, proto.loupe.common.realtime);
