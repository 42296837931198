import { TenantStatus } from '../../grpc/enums'
import { toCreateTenantRequest, toUpdateTenantRequest, toListOrgsRequest } from '../../grpc/converters'
import { useEnterKeyCallback } from '../../hooks/useEnterKeyCallback'
import { useGrpcCallback } from '../../grpc'
import { useNotification } from '../../hooks/useNotification'
import { useTenants } from '../../context/tenants'
import { useTextField } from '../../hooks/useTextField'
import { Autocomplete } from '../common/autocomplete'
import Button from '../common/button'
import Label from '../common/label'
import Modal from '../common/modal'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import ModalHeader from '../common/modalHeader'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SelectList from '../common/selectList'
import { regionOptions as regionTypeOptions } from '../constants/regionOptions'
import FieldDate from '../filter/fields/fieldDate'
import { secondsToDate } from '../../lib/dateFns'

const statusOptions = [
  { label: 'Select a Status', value: '-1' },
  { label: 'Active', value: TenantStatus.STATUS_ACTIVE },
  { label: 'Deleted', value: TenantStatus.STATUS_DELETED },
  { label: 'Pending', value: TenantStatus.STATUS_PENDING },
]

const crmTypeOptions = [
  { label: 'Select a CRM', value: '-1' },
  { label: 'Salesforce', value: 'salesforce' },
  { label: 'Salesforce Test', value: 'salesforce_test' },
  { label: 'HubSpot', value: 'hubspot' },
]

const SaveTenantModal = (props) => {
  const {
    modal,
  } = props

  const { open, handleClose, data } = modal

  const { notifyError, notifySuccess } = useNotification()

  const { invalidate } = useTenants()

  const name = useTextField()
  const bulkApiLimit = useTextField()
  const batchLookUp = useTextField()
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[3].value)
  // Load Data Start: Default = (Today - 2 Years)
  const yearTwoYearsAgo = new Date().getFullYear() - 2
  const twoYearsAgo = new Date(yearTwoYearsAgo, new Date().getMonth(), new Date().getDate())
  const formattedDate = twoYearsAgo.toISOString().slice(0, 10)
  const [selectedDate, setSelectedDate] = useState(formattedDate)
  const [createdAt, setCreatedAt] = useState(new Date())
  const [lastSuccessfulLoadAt, setLastSuccessfulLoadAt] = useState('')
  const [isFullyLoaded, setIsFullyLoaded] = useState(false)
  const [crmType, setCrmType] = useState(crmTypeOptions[0].value)
  const [regionType, setRegionType] = useState(undefined)
  const [invalidFields, setInvalidFields] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [selectedOrg, setSelectedOrg] = useState(data?.outreachOrg || {})
  const [outreachOrgs, setOutreachOrgs] = useState([])

  const handleCloseInternal = useCallback(() => {
    handleClose()
  }, [handleClose])

  const listOutreachOrgs = useGrpcCallback({
    onError: () => {
      notifyError('Error fetching Outreach Orgs!')
      setOutreachOrgs([])
    },
    onSuccess: ({ orgsList }) => {
      setOutreachOrgs(orgsList)
    },
    grpcMethod: 'listOutreachOrgs',
    debug: false,
    useApiRegion: regionType
  }, [regionType])

  const fetchOutreachOrgs = useCallback(() => {
    if (regionType) {
      const request = toListOrgsRequest()
      listOutreachOrgs(request)
    }
  }, [listOutreachOrgs, regionType])

  const onDateChange = (a) => {
    setSelectedDate(a[0].value)
  }

  const createTenant = useGrpcCallback({
    onError: () => {
      notifyError('Error creating tenant!')
      setIsSaving(false)
    },
    onSuccess: () => {
      notifySuccess('Tenant created.')
      handleCloseInternal()
      setIsSaving(false)
      invalidate()
    },
    onFetch: () => setIsSaving(true),
    grpcMethod: 'createTenant',
    debug: false,
    useApiRegion: regionType
  }, [handleCloseInternal, regionType])

  const updateTenant = useGrpcCallback({
    onError: () => {
      notifyError('Error updating tenant!')
      setIsSaving(false)
    },
    onSuccess: () => {
      notifySuccess('Tenant updated.')
      handleCloseInternal()
      setIsSaving(false)
      invalidate()
    },
    onFetch: () => setIsSaving(true),
    grpcMethod: 'updateTenant',
    debug: false,
    useApiRegion: regionType
  }, [handleCloseInternal, regionType])

  const onStatusChange = useCallback((option) => {
    const { value } = option
    setSelectedStatus(value)
  }, [])

  const onCrmTypeChange = useCallback((option) => {
    const { value } = option
    setCrmType(value)
  }, [])

  const onRegionTypeChange = useCallback((option) => {
    const { value } = option
    setRegionType(value)
  }, [])

  const onOrgChange = useCallback((event, value) => {
    event.stopPropagation()
    setSelectedOrg(value)
  }, [])

  const onSave = useCallback(() => {
    if (isSaving || !open) {
      return
    }

    const invalid = []
    if (!name.value.trim()) {
      invalid.push('name')
    }
    if (selectedStatus === statusOptions[0].value) {
      invalid.push('status')
    }
    if (crmType === crmTypeOptions[0].value) {
      invalid.push('CRM')
    }
    if (!bulkApiLimit.value) {
      invalid.push('BulkApiLimit')
    } else if (bulkApiLimit.value < 1) {
      invalid.push('BulkApiLimitConditions')
    }
    if (!batchLookUp.value) {
      invalid.push('BatchLookUp')
    } else if (batchLookUp.value < 1 || batchLookUp.value > 60) {
      invalid.push('BatchLookUpConditions')
    }
    if (selectedDate === '') {
      invalid.push('HistoryDataTillCondition')
    }

    setInvalidFields(invalid)

    const outreachOrg = {
      bento: selectedOrg?.bento || '',
      orgGuid: selectedOrg?.orgGuid || ''
    }

    const getDataSyncSettings = () => {
      const obj = {
        HistoricDataTill: `${selectedDate} 00:00:00.000000`,
        DailyBulkAPILimit: bulkApiLimit.value,
        BatchLookupLengthInDays: batchLookUp.value
      }
      // Convert the object to a JSON string
      return window.btoa(JSON.stringify(obj))
    }

    if (invalid.length === 0) {
      if (data) {
        const request = toUpdateTenantRequest({
          outreachOrg,
          tenantId: data.id,
          name: name.value.trim(),
          status: selectedStatus,
          dataSyncSettings: getDataSyncSettings()
        })
        updateTenant(request)
      } else {
        const request = {
          crmType,
          outreachOrg,
          name: name.value.trim(),
          status: selectedStatus,
          ...crmType === 'salesforce_test' && {
            crmType: 'salesforce',
            isTestInstance: true
          },
          region: regionType,
          dataSyncSettings: getDataSyncSettings()
        }

        window.analytics.track('Create Tenant', { ...request })
        createTenant(toCreateTenantRequest(request))
      }
    }
  }, [data, name, selectedStatus, handleCloseInternal, createTenant, updateTenant, isSaving, open, crmType, regionType, selectedOrg])

  useEffect(() => {
    if (open) {
      fetchOutreachOrgs()
    } else {
      setRegionType(undefined)
    }
  }, [open, fetchOutreachOrgs])

  useEffect(() => {
    if (open) {
      if (data) {
        const encodedDataSyncSettings = data?.dataSyncSettings
        if (encodedDataSyncSettings) {
          const decodedString = window.atob(encodedDataSyncSettings)
          const dataSyncSettings = JSON.parse(decodedString)
          const HistoricData = dataSyncSettings?.HistoricDataTill.split(' ')
          bulkApiLimit.setValue((dataSyncSettings.DailyBulkAPILimit).toString())
          batchLookUp.setValue((dataSyncSettings.BatchLookupLengthInDays).toString())
          setSelectedDate(HistoricData[0])
          const lastSuccessfulLoad = dataSyncSettings?.LastSuccesfulLoadAt
          setLastSuccessfulLoadAt(lastSuccessfulLoad ? lastSuccessfulLoad.split(' ')[0] : '')
          setIsFullyLoaded(lastSuccessfulLoad.split(' ')[0] === HistoricData[0])
        }
        setCrmType(data.crmType)
        setRegionType(data.region)
        name.setValue(data.name)
        const createdDate = secondsToDate(data.createdAt.seconds)
        setCreatedAt(createdDate)
        switch (data.status) {
          case 'active':
            setSelectedStatus(TenantStatus.STATUS_ACTIVE)
            break
          case 'deleted':
            setSelectedStatus(TenantStatus.STATUS_DELETED)
            break
          case 'pending':
            setSelectedStatus(TenantStatus.STATUS_PENDING)
            break
          default:
            break
        }
        setSelectedOrg(data?.outreachOrg || {})
      } else {
        name.reset()
        // Daily Bulk API Limit: Default = 5,000
        bulkApiLimit.setValue((5000).toString())
        // Batch Load Size (days): Default = 60
        batchLookUp.setValue((60).toString())
        setCrmType(crmTypeOptions[0].value)
        setRegionType(regionTypeOptions[0].value)
        setSelectedStatus(statusOptions[3].value)
        setSelectedOrg({})
        setInvalidFields([])
        setCreatedAt(new Date())
      }
    }
  }, [open, data])

  useEnterKeyCallback(onSave)

  const title = useMemo(() => {
    return data ? 'Edit Tenant' : 'Create Tenant'
  }, [data])

  const buttonText = useMemo(() => {
    return data ? 'Edit' : 'Create'
  }, [data])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="sm"
      open={open}>

      <ModalHeader
        title={title}
        onClose={handleCloseInternal} />

      <ModalBody>
        <div className="w-full h-full px-10 pt-10">
          {!data && (
            <>
              <div className="flex justify-between">
                <Label text="Region Type" />
                {regionType === 'eu' && <Label text="Warning: Only select EU if GDPR is required" className="text-color-ff8856" />}
              </div>
              <div className="mb-6">
                <SelectList
                  value={regionType}
                  onChange={onRegionTypeChange}
                  options={regionTypeOptions}
                  disabled={data} />
              </div>
            </>
          )}
          <div className="flex justify-between">
            <Label text="Name" />
            {invalidFields.includes('name') && <Label text="Required" className="text-color-fb6c6a" />}
          </div>
          <input
            className="w-full px-3 py-2 mb-6 border border-color-d6d9e6 focus:outline-none rounded"
            value={name.value}
            onChange={name.onChange}
            autoFocus={true} />
          {!data && (
            <>
              <div className="flex justify-between">
                <Label text="CRM Type" />
                {invalidFields.includes('CRM') && <Label text="Required" className="text-color-fb6c6a" />}
              </div>
              <div className="mb-6">
                <SelectList
                  value={crmType}
                  onChange={onCrmTypeChange}
                  options={crmTypeOptions}
                  disabled={data} />
              </div>
            </>
          )}
          <div className="flex justify-between">
            <Label text="Outreach Org" />
          </div>
          <div className="mb-6">
            <Autocomplete
              getOptionLabel={(option) => option?.shortname || ''}
              getOptionSelected={(option, value) => option.orgGuid === value.orgGuid}
              ListboxProps={{ itemSize: 42 }}
              noOptionsText="No Orgs Found"
              onChange={onOrgChange}
              options={outreachOrgs}
              renderOption={(option) => (
                <div className="flex flex-col leading-tight">
                  <div className="text-size-16px text-color-09242f font-weight-400">{option.shortname}</div>
                  <div className="text-size-12px text-color-51636a font-weight-400">{option.bento}</div>
                </div>
              )}
              value={selectedOrg}
            />
          </div>
          <div className="flex justify-between">
            <Label text="Status" />
            {invalidFields.includes('status') && <Label text="Required" className="text-color-fb6c6a" />}
          </div>
          <div className="mb-6">
            <SelectList
              value={selectedStatus}
              onChange={onStatusChange}
              options={statusOptions}
              disabled={!data} />
          </div>
          <div className="flex justify-between">
            <Label text="Load Data Start" />
            {invalidFields.includes('HistoryDataTillCondition') && <Label text="Required" className="text-color-fb6c6a" />}
          </div>
          <FieldDate
            className="mb-6"
            value={[{ value: selectedDate }]}
            onChange={onDateChange}
            maxDate={createdAt}
            isTodayButtonDisabled={data !== undefined}
          />
          <div className="flex justify-between">
            <Label text="Daily Bulk Api Limit" />
            {invalidFields.includes('BulkApiLimit') && <Label text="Required" className="text-color-fb6c6a" />}
            {invalidFields.includes('BulkApiLimitConditions') && <Label text="Please enter a number greater than or equals to 1" className="text-color-fb6c6a" />}
          </div>
          <input
            className="w-full px-3 py-2 mb-6 border border-color-d6d9e6 focus:outline-none rounded"
            type="number"
            min="1"
            value={bulkApiLimit.value}
            onChange={bulkApiLimit.onChange}
          />
          <div className="flex justify-between">
            <Label text="Batch Load size (days)" />
            {invalidFields.includes('BatchLookUp') && <Label text="Required" className="text-color-fb6c6a" />}
            {invalidFields.includes('BatchLookUpConditions') && <Label text="Please enter a number between 1 and 60" className="text-color-fb6c6a" />}
          </div>
          <input
            className="w-full px-3 py-2 mb-6 border border-color-d6d9e6 focus:outline-none rounded"
            type="number"
            min="1"
            max="60"
            value={batchLookUp.value}
            onChange={batchLookUp.onChange}
             />
          { data && lastSuccessfulLoadAt
              && (isFullyLoaded
                ? <div>Data is fully loaded</div> : (
                  <div className="flex justify-between">
                    Past data is fetched till
                    {' '}
                    {lastSuccessfulLoadAt}
                    , will be fetching till
                    {' '}
                    {selectedDate}
                  </div>
                )
              )}
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          text={buttonText}
          onClick={onSave}
          disabled={isSaving} />
      </ModalFooter>

    </Modal>
  )
}

export default SaveTenantModal
