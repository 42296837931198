import React from 'react'
import { useForecastingConfigs } from '../../context/forecastingConfigs'
import ForecastListItem from './forecastListItem'

const ForecastsList = (props) => {
  const { configs = [] } = useForecastingConfigs()

  const configsSorted = configs.sort((a, b) => a.status - b.status)

  return (
    <div className="flex-table">
      <div className="thead">
        <div className="tr">
          <div className="td ml-5 w-1/4">Name</div>
          <div className="td w-1/4">Status</div>
          <div className="td w-1/4">Last Published</div>
          <div className="td w-1/4" />
        </div>
      </div>
      <div className="tbody">
        {configsSorted.map((config, index) => (
          <ForecastListItem
            key={`ForecastListItem-${config.key}-${index}`}
            config={config}
            {...index === 0 && { id: 'joyride_forecasts_forecastsListItem' }} />
        ))}
      </div>
    </div>
  )
}

export default ForecastsList
