// source: src/services/mapping/mapping.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_mapping_mapping_pb = require('../../../src/common/mapping/mapping_pb.js');
goog.object.extend(proto, src_common_mapping_mapping_pb);
var src_common_tenant_mapping_v2_object_mapping_pb = require('../../../src/common/tenant/mapping/v2/object_mapping_pb.js');
goog.object.extend(proto, src_common_tenant_mapping_v2_object_mapping_pb);
goog.exportSymbol('proto.loupe.services.mapping.CanonicalObjectFieldType', null, global);
goog.exportSymbol('proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.GetCanonicalObjectMappingRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.GetCanonicalObjectMappingResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.GetCanonicalObjectRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.GetCanonicalObjectResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.GetMappingStatusForTenantRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.GetMappingStatusForTenantResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.GetObjectMappingRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.GetObjectMappingResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.GetTenantMappingsRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.GetTenantMappingsResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.InitializeMappingsForTenantRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.InitializeMappingsForTenantResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ListCanonicalObjectsRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ListCanonicalObjectsResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ListObjectMappingsRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ListObjectMappingsResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ObjectMappingWrapper', null, global);
goog.exportSymbol('proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.UpsertCanonicalObjectRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.UpsertCanonicalObjectResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.UpsertObjectMappingRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.UpsertObjectMappingResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest', null, global);
goog.exportSymbol('proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.GetTenantMappingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.GetTenantMappingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.GetTenantMappingsRequest.displayName = 'proto.loupe.services.mapping.GetTenantMappingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.GetTenantMappingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.GetTenantMappingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.GetTenantMappingsResponse.displayName = 'proto.loupe.services.mapping.GetTenantMappingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ListCanonicalObjectsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.ListCanonicalObjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ListCanonicalObjectsRequest.displayName = 'proto.loupe.services.mapping.ListCanonicalObjectsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ListCanonicalObjectsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.mapping.ListCanonicalObjectsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.mapping.ListCanonicalObjectsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ListCanonicalObjectsResponse.displayName = 'proto.loupe.services.mapping.ListCanonicalObjectsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.GetCanonicalObjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.GetCanonicalObjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.GetCanonicalObjectRequest.displayName = 'proto.loupe.services.mapping.GetCanonicalObjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.GetCanonicalObjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.GetCanonicalObjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.GetCanonicalObjectResponse.displayName = 'proto.loupe.services.mapping.GetCanonicalObjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.UpsertCanonicalObjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.UpsertCanonicalObjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.UpsertCanonicalObjectRequest.displayName = 'proto.loupe.services.mapping.UpsertCanonicalObjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.UpsertCanonicalObjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.UpsertCanonicalObjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.UpsertCanonicalObjectResponse.displayName = 'proto.loupe.services.mapping.UpsertCanonicalObjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.displayName = 'proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.displayName = 'proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.displayName = 'proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse.displayName = 'proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.displayName = 'proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.displayName = 'proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.mapping.GetCanonicalObjectMappingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.displayName = 'proto.loupe.services.mapping.GetCanonicalObjectMappingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.GetCanonicalObjectMappingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.displayName = 'proto.loupe.services.mapping.GetCanonicalObjectMappingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.displayName = 'proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.displayName = 'proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.displayName = 'proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse.displayName = 'proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ListObjectMappingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.ListObjectMappingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ListObjectMappingsRequest.displayName = 'proto.loupe.services.mapping.ListObjectMappingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ListObjectMappingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.mapping.ListObjectMappingsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.mapping.ListObjectMappingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ListObjectMappingsResponse.displayName = 'proto.loupe.services.mapping.ListObjectMappingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.GetObjectMappingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.GetObjectMappingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.GetObjectMappingRequest.displayName = 'proto.loupe.services.mapping.GetObjectMappingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.GetObjectMappingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.GetObjectMappingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.GetObjectMappingResponse.displayName = 'proto.loupe.services.mapping.GetObjectMappingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.UpsertObjectMappingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.UpsertObjectMappingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.UpsertObjectMappingRequest.displayName = 'proto.loupe.services.mapping.UpsertObjectMappingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.UpsertObjectMappingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.UpsertObjectMappingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.UpsertObjectMappingResponse.displayName = 'proto.loupe.services.mapping.UpsertObjectMappingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ObjectMappingWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.ObjectMappingWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ObjectMappingWrapper.displayName = 'proto.loupe.services.mapping.ObjectMappingWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.InitializeMappingsForTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.InitializeMappingsForTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.InitializeMappingsForTenantRequest.displayName = 'proto.loupe.services.mapping.InitializeMappingsForTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.InitializeMappingsForTenantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.InitializeMappingsForTenantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.InitializeMappingsForTenantResponse.displayName = 'proto.loupe.services.mapping.InitializeMappingsForTenantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.GetMappingStatusForTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.GetMappingStatusForTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.GetMappingStatusForTenantRequest.displayName = 'proto.loupe.services.mapping.GetMappingStatusForTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.GetMappingStatusForTenantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.GetMappingStatusForTenantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.GetMappingStatusForTenantResponse.displayName = 'proto.loupe.services.mapping.GetMappingStatusForTenantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest.displayName = 'proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.displayName = 'proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.CanonicalObjectFieldType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.mapping.CanonicalObjectFieldType.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.mapping.CanonicalObjectFieldType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.CanonicalObjectFieldType.displayName = 'proto.loupe.services.mapping.CanonicalObjectFieldType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.displayName = 'proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.displayName = 'proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.displayName = 'proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.displayName = 'proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest.displayName = 'proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse.displayName = 'proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.GetTenantMappingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.GetTenantMappingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.GetTenantMappingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetTenantMappingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectNames: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.GetTenantMappingsRequest}
 */
proto.loupe.services.mapping.GetTenantMappingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.GetTenantMappingsRequest;
  return proto.loupe.services.mapping.GetTenantMappingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.GetTenantMappingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.GetTenantMappingsRequest}
 */
proto.loupe.services.mapping.GetTenantMappingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.GetTenantMappingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.GetTenantMappingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.GetTenantMappingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetTenantMappingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectNames();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.GetTenantMappingsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.GetTenantMappingsRequest} returns this
 */
proto.loupe.services.mapping.GetTenantMappingsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_names = 2;
 * @return {string}
 */
proto.loupe.services.mapping.GetTenantMappingsRequest.prototype.getObjectNames = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.GetTenantMappingsRequest} returns this
 */
proto.loupe.services.mapping.GetTenantMappingsRequest.prototype.setObjectNames = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.GetTenantMappingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.GetTenantMappingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.GetTenantMappingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetTenantMappingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mappingsMap: (f = msg.getMappingsMap()) ? f.toObject(includeInstance, proto.loupe.common.tenant.mapping.v2.ObjectMapping.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.GetTenantMappingsResponse}
 */
proto.loupe.services.mapping.GetTenantMappingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.GetTenantMappingsResponse;
  return proto.loupe.services.mapping.GetTenantMappingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.GetTenantMappingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.GetTenantMappingsResponse}
 */
proto.loupe.services.mapping.GetTenantMappingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getMappingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.common.tenant.mapping.v2.ObjectMapping.deserializeBinaryFromReader, "", new proto.loupe.common.tenant.mapping.v2.ObjectMapping());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.GetTenantMappingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.GetTenantMappingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.GetTenantMappingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetTenantMappingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMappingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.common.tenant.mapping.v2.ObjectMapping.serializeBinaryToWriter);
  }
};


/**
 * map<string, loupe.common.tenant.mapping.v2.ObjectMapping> mappings = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.common.tenant.mapping.v2.ObjectMapping>}
 */
proto.loupe.services.mapping.GetTenantMappingsResponse.prototype.getMappingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.common.tenant.mapping.v2.ObjectMapping>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.loupe.common.tenant.mapping.v2.ObjectMapping));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.mapping.GetTenantMappingsResponse} returns this
 */
proto.loupe.services.mapping.GetTenantMappingsResponse.prototype.clearMappingsMap = function() {
  this.getMappingsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ListCanonicalObjectsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ListCanonicalObjectsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListCanonicalObjectsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectNames: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectsRequest}
 */
proto.loupe.services.mapping.ListCanonicalObjectsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ListCanonicalObjectsRequest;
  return proto.loupe.services.mapping.ListCanonicalObjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectsRequest}
 */
proto.loupe.services.mapping.ListCanonicalObjectsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ListCanonicalObjectsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ListCanonicalObjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListCanonicalObjectsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectNames();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.ListCanonicalObjectsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectsRequest} returns this
 */
proto.loupe.services.mapping.ListCanonicalObjectsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_names = 2;
 * @return {string}
 */
proto.loupe.services.mapping.ListCanonicalObjectsRequest.prototype.getObjectNames = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectsRequest} returns this
 */
proto.loupe.services.mapping.ListCanonicalObjectsRequest.prototype.setObjectNames = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.mapping.ListCanonicalObjectsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ListCanonicalObjectsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ListCanonicalObjectsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListCanonicalObjectsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    src_common_mapping_mapping_pb.CanonicalObject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectsResponse}
 */
proto.loupe.services.mapping.ListCanonicalObjectsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ListCanonicalObjectsResponse;
  return proto.loupe.services.mapping.ListCanonicalObjectsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectsResponse}
 */
proto.loupe.services.mapping.ListCanonicalObjectsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_mapping_mapping_pb.CanonicalObject;
      reader.readMessage(value,src_common_mapping_mapping_pb.CanonicalObject.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ListCanonicalObjectsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ListCanonicalObjectsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListCanonicalObjectsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_mapping_mapping_pb.CanonicalObject.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.mapping.CanonicalObject objects = 1;
 * @return {!Array<!proto.loupe.common.mapping.CanonicalObject>}
 */
proto.loupe.services.mapping.ListCanonicalObjectsResponse.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.loupe.common.mapping.CanonicalObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_mapping_mapping_pb.CanonicalObject, 1));
};


/**
 * @param {!Array<!proto.loupe.common.mapping.CanonicalObject>} value
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectsResponse} returns this
*/
proto.loupe.services.mapping.ListCanonicalObjectsResponse.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.mapping.CanonicalObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.mapping.CanonicalObject}
 */
proto.loupe.services.mapping.ListCanonicalObjectsResponse.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.mapping.CanonicalObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectsResponse} returns this
 */
proto.loupe.services.mapping.ListCanonicalObjectsResponse.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.GetCanonicalObjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.GetCanonicalObjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetCanonicalObjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectRequest}
 */
proto.loupe.services.mapping.GetCanonicalObjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.GetCanonicalObjectRequest;
  return proto.loupe.services.mapping.GetCanonicalObjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectRequest}
 */
proto.loupe.services.mapping.GetCanonicalObjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.GetCanonicalObjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.GetCanonicalObjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetCanonicalObjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.GetCanonicalObjectRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectRequest} returns this
 */
proto.loupe.services.mapping.GetCanonicalObjectRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_name = 2;
 * @return {string}
 */
proto.loupe.services.mapping.GetCanonicalObjectRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectRequest} returns this
 */
proto.loupe.services.mapping.GetCanonicalObjectRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.GetCanonicalObjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.GetCanonicalObjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetCanonicalObjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    object: (f = msg.getObject()) && src_common_mapping_mapping_pb.CanonicalObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectResponse}
 */
proto.loupe.services.mapping.GetCanonicalObjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.GetCanonicalObjectResponse;
  return proto.loupe.services.mapping.GetCanonicalObjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectResponse}
 */
proto.loupe.services.mapping.GetCanonicalObjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_mapping_mapping_pb.CanonicalObject;
      reader.readMessage(value,src_common_mapping_mapping_pb.CanonicalObject.deserializeBinaryFromReader);
      msg.setObject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.GetCanonicalObjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.GetCanonicalObjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetCanonicalObjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_mapping_mapping_pb.CanonicalObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.mapping.CanonicalObject object = 1;
 * @return {?proto.loupe.common.mapping.CanonicalObject}
 */
proto.loupe.services.mapping.GetCanonicalObjectResponse.prototype.getObject = function() {
  return /** @type{?proto.loupe.common.mapping.CanonicalObject} */ (
    jspb.Message.getWrapperField(this, src_common_mapping_mapping_pb.CanonicalObject, 1));
};


/**
 * @param {?proto.loupe.common.mapping.CanonicalObject|undefined} value
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectResponse} returns this
*/
proto.loupe.services.mapping.GetCanonicalObjectResponse.prototype.setObject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectResponse} returns this
 */
proto.loupe.services.mapping.GetCanonicalObjectResponse.prototype.clearObject = function() {
  return this.setObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mapping.GetCanonicalObjectResponse.prototype.hasObject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.UpsertCanonicalObjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertCanonicalObjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    object: (f = msg.getObject()) && src_common_mapping_mapping_pb.CanonicalObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectRequest}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.UpsertCanonicalObjectRequest;
  return proto.loupe.services.mapping.UpsertCanonicalObjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectRequest}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_mapping_mapping_pb.CanonicalObject;
      reader.readMessage(value,src_common_mapping_mapping_pb.CanonicalObject.deserializeBinaryFromReader);
      msg.setObject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.UpsertCanonicalObjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertCanonicalObjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_mapping_mapping_pb.CanonicalObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.mapping.CanonicalObject object = 1;
 * @return {?proto.loupe.common.mapping.CanonicalObject}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectRequest.prototype.getObject = function() {
  return /** @type{?proto.loupe.common.mapping.CanonicalObject} */ (
    jspb.Message.getWrapperField(this, src_common_mapping_mapping_pb.CanonicalObject, 1));
};


/**
 * @param {?proto.loupe.common.mapping.CanonicalObject|undefined} value
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectRequest} returns this
*/
proto.loupe.services.mapping.UpsertCanonicalObjectRequest.prototype.setObject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectRequest} returns this
 */
proto.loupe.services.mapping.UpsertCanonicalObjectRequest.prototype.clearObject = function() {
  return this.setObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectRequest.prototype.hasObject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.UpsertCanonicalObjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertCanonicalObjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    object: (f = msg.getObject()) && src_common_mapping_mapping_pb.CanonicalObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectResponse}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.UpsertCanonicalObjectResponse;
  return proto.loupe.services.mapping.UpsertCanonicalObjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectResponse}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_mapping_mapping_pb.CanonicalObject;
      reader.readMessage(value,src_common_mapping_mapping_pb.CanonicalObject.deserializeBinaryFromReader);
      msg.setObject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.UpsertCanonicalObjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertCanonicalObjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_mapping_mapping_pb.CanonicalObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.mapping.CanonicalObject object = 1;
 * @return {?proto.loupe.common.mapping.CanonicalObject}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectResponse.prototype.getObject = function() {
  return /** @type{?proto.loupe.common.mapping.CanonicalObject} */ (
    jspb.Message.getWrapperField(this, src_common_mapping_mapping_pb.CanonicalObject, 1));
};


/**
 * @param {?proto.loupe.common.mapping.CanonicalObject|undefined} value
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectResponse} returns this
*/
proto.loupe.services.mapping.UpsertCanonicalObjectResponse.prototype.setObject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectResponse} returns this
 */
proto.loupe.services.mapping.UpsertCanonicalObjectResponse.prototype.clearObject = function() {
  return this.setObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectResponse.prototype.hasObject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: (f = msg.getField()) && src_common_mapping_mapping_pb.CanonicalObjectField.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest;
  return proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_mapping_mapping_pb.CanonicalObjectField;
      reader.readMessage(value,src_common_mapping_mapping_pb.CanonicalObjectField.deserializeBinaryFromReader);
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_mapping_mapping_pb.CanonicalObjectField.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.mapping.CanonicalObjectField field = 1;
 * @return {?proto.loupe.common.mapping.CanonicalObjectField}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.prototype.getField = function() {
  return /** @type{?proto.loupe.common.mapping.CanonicalObjectField} */ (
    jspb.Message.getWrapperField(this, src_common_mapping_mapping_pb.CanonicalObjectField, 1));
};


/**
 * @param {?proto.loupe.common.mapping.CanonicalObjectField|undefined} value
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest} returns this
*/
proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.prototype.setField = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest} returns this
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.prototype.clearField = function() {
  return this.setField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldRequest.prototype.hasField = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: (f = msg.getField()) && src_common_mapping_mapping_pb.CanonicalObjectField.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse;
  return proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new src_common_mapping_mapping_pb.CanonicalObjectField;
      reader.readMessage(value,src_common_mapping_mapping_pb.CanonicalObjectField.deserializeBinaryFromReader);
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_mapping_mapping_pb.CanonicalObjectField.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.mapping.CanonicalObjectField field = 2;
 * @return {?proto.loupe.common.mapping.CanonicalObjectField}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.prototype.getField = function() {
  return /** @type{?proto.loupe.common.mapping.CanonicalObjectField} */ (
    jspb.Message.getWrapperField(this, src_common_mapping_mapping_pb.CanonicalObjectField, 2));
};


/**
 * @param {?proto.loupe.common.mapping.CanonicalObjectField|undefined} value
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse} returns this
*/
proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.prototype.setField = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse} returns this
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.prototype.clearField = function() {
  return this.setField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectFieldResponse.prototype.hasField = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest}
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest;
  return proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest}
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest} returns this
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest} returns this
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse}
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse;
  return proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse}
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.DeleteCanonicalObjectFieldResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectNames: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest}
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest;
  return proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest}
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectNames();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest} returns this
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_names = 2;
 * @return {string}
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.prototype.getObjectNames = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest} returns this
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsRequest.prototype.setObjectNames = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    src_common_mapping_mapping_pb.CanonicalObjectMapping.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse}
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse;
  return proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse}
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_mapping_mapping_pb.CanonicalObjectMapping;
      reader.readMessage(value,src_common_mapping_mapping_pb.CanonicalObjectMapping.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_mapping_mapping_pb.CanonicalObjectMapping.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.mapping.CanonicalObjectMapping objects = 1;
 * @return {!Array<!proto.loupe.common.mapping.CanonicalObjectMapping>}
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.loupe.common.mapping.CanonicalObjectMapping>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_mapping_mapping_pb.CanonicalObjectMapping, 1));
};


/**
 * @param {!Array<!proto.loupe.common.mapping.CanonicalObjectMapping>} value
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse} returns this
*/
proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.mapping.CanonicalObjectMapping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping}
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.mapping.CanonicalObjectMapping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse} returns this
 */
proto.loupe.services.mapping.ListCanonicalObjectMappingsResponse.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    crmObjectNamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.GetCanonicalObjectMappingRequest;
  return proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCrmObjectNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCrmObjectNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest} returns this
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_name = 2;
 * @return {string}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest} returns this
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string crm_object_names = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.prototype.getCrmObjectNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest} returns this
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.prototype.setCrmObjectNamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest} returns this
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.prototype.addCrmObjectNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectMappingRequest} returns this
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingRequest.prototype.clearCrmObjectNamesList = function() {
  return this.setCrmObjectNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectMappingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    object: (f = msg.getObject()) && src_common_mapping_mapping_pb.CanonicalObjectMapping.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectMappingResponse}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.GetCanonicalObjectMappingResponse;
  return proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectMappingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectMappingResponse}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_mapping_mapping_pb.CanonicalObjectMapping;
      reader.readMessage(value,src_common_mapping_mapping_pb.CanonicalObjectMapping.deserializeBinaryFromReader);
      msg.setObject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.GetCanonicalObjectMappingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_mapping_mapping_pb.CanonicalObjectMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.mapping.CanonicalObjectMapping object = 1;
 * @return {?proto.loupe.common.mapping.CanonicalObjectMapping}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.prototype.getObject = function() {
  return /** @type{?proto.loupe.common.mapping.CanonicalObjectMapping} */ (
    jspb.Message.getWrapperField(this, src_common_mapping_mapping_pb.CanonicalObjectMapping, 1));
};


/**
 * @param {?proto.loupe.common.mapping.CanonicalObjectMapping|undefined} value
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectMappingResponse} returns this
*/
proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.prototype.setObject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mapping.GetCanonicalObjectMappingResponse} returns this
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.prototype.clearObject = function() {
  return this.setObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mapping.GetCanonicalObjectMappingResponse.prototype.hasObject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectMap: (f = msg.getObjectMap()) && src_common_mapping_mapping_pb.CanonicalObjectMapping.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest;
  return proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_mapping_mapping_pb.CanonicalObjectMapping;
      reader.readMessage(value,src_common_mapping_mapping_pb.CanonicalObjectMapping.deserializeBinaryFromReader);
      msg.setObjectMap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectMap();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_mapping_mapping_pb.CanonicalObjectMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.mapping.CanonicalObjectMapping object_map = 1;
 * @return {?proto.loupe.common.mapping.CanonicalObjectMapping}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.prototype.getObjectMap = function() {
  return /** @type{?proto.loupe.common.mapping.CanonicalObjectMapping} */ (
    jspb.Message.getWrapperField(this, src_common_mapping_mapping_pb.CanonicalObjectMapping, 1));
};


/**
 * @param {?proto.loupe.common.mapping.CanonicalObjectMapping|undefined} value
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest} returns this
*/
proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.prototype.setObjectMap = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest} returns this
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.prototype.clearObjectMap = function() {
  return this.setObjectMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingRequest.prototype.hasObjectMap = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    statusDetailsList: jspb.Message.toObjectList(msg.getStatusDetailsList(),
    src_common_mapping_mapping_pb.MappingStatusDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse;
  return proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.mapping.MappingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = new src_common_mapping_mapping_pb.MappingStatusDetail;
      reader.readMessage(value,src_common_mapping_mapping_pb.MappingStatusDetail.deserializeBinaryFromReader);
      msg.addStatusDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStatusDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_mapping_mapping_pb.MappingStatusDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.mapping.MappingStatus status = 1;
 * @return {!proto.loupe.common.mapping.MappingStatus}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.mapping.MappingStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.mapping.MappingStatus} value
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse} returns this
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated loupe.common.mapping.MappingStatusDetail status_details = 2;
 * @return {!Array<!proto.loupe.common.mapping.MappingStatusDetail>}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.prototype.getStatusDetailsList = function() {
  return /** @type{!Array<!proto.loupe.common.mapping.MappingStatusDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_mapping_mapping_pb.MappingStatusDetail, 2));
};


/**
 * @param {!Array<!proto.loupe.common.mapping.MappingStatusDetail>} value
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse} returns this
*/
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.prototype.setStatusDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.mapping.MappingStatusDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.mapping.MappingStatusDetail}
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.prototype.addStatusDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.mapping.MappingStatusDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse} returns this
 */
proto.loupe.services.mapping.UpsertCanonicalObjectMappingResponse.prototype.clearStatusDetailsList = function() {
  return this.setStatusDetailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldNamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    syncStatus: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest}
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest;
  return proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest}
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFieldNames(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.common.mapping.SyncStatus} */ (reader.readEnum());
      msg.setSyncStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSyncStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest} returns this
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_name = 2;
 * @return {string}
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest} returns this
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string field_names = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.prototype.getFieldNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest} returns this
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.prototype.setFieldNamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest} returns this
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.prototype.addFieldNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest} returns this
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.prototype.clearFieldNamesList = function() {
  return this.setFieldNamesList([]);
};


/**
 * optional loupe.common.mapping.SyncStatus sync_status = 4;
 * @return {!proto.loupe.common.mapping.SyncStatus}
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.prototype.getSyncStatus = function() {
  return /** @type {!proto.loupe.common.mapping.SyncStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.common.mapping.SyncStatus} value
 * @return {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest} returns this
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusRequest.prototype.setSyncStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse}
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse;
  return proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse}
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpdateCanonicalObjectMappingSyncStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ListObjectMappingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ListObjectMappingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ListObjectMappingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListObjectMappingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectNames: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ListObjectMappingsRequest}
 */
proto.loupe.services.mapping.ListObjectMappingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ListObjectMappingsRequest;
  return proto.loupe.services.mapping.ListObjectMappingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ListObjectMappingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ListObjectMappingsRequest}
 */
proto.loupe.services.mapping.ListObjectMappingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ListObjectMappingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ListObjectMappingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ListObjectMappingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListObjectMappingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectNames();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.ListObjectMappingsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.ListObjectMappingsRequest} returns this
 */
proto.loupe.services.mapping.ListObjectMappingsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_names = 2;
 * @return {string}
 */
proto.loupe.services.mapping.ListObjectMappingsRequest.prototype.getObjectNames = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.ListObjectMappingsRequest} returns this
 */
proto.loupe.services.mapping.ListObjectMappingsRequest.prototype.setObjectNames = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.mapping.ListObjectMappingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ListObjectMappingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ListObjectMappingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ListObjectMappingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListObjectMappingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    proto.loupe.services.mapping.ObjectMappingWrapper.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ListObjectMappingsResponse}
 */
proto.loupe.services.mapping.ListObjectMappingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ListObjectMappingsResponse;
  return proto.loupe.services.mapping.ListObjectMappingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ListObjectMappingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ListObjectMappingsResponse}
 */
proto.loupe.services.mapping.ListObjectMappingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.mapping.ObjectMappingWrapper;
      reader.readMessage(value,proto.loupe.services.mapping.ObjectMappingWrapper.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ListObjectMappingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ListObjectMappingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ListObjectMappingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListObjectMappingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.mapping.ObjectMappingWrapper.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ObjectMappingWrapper objects = 1;
 * @return {!Array<!proto.loupe.services.mapping.ObjectMappingWrapper>}
 */
proto.loupe.services.mapping.ListObjectMappingsResponse.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.loupe.services.mapping.ObjectMappingWrapper>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.mapping.ObjectMappingWrapper, 1));
};


/**
 * @param {!Array<!proto.loupe.services.mapping.ObjectMappingWrapper>} value
 * @return {!proto.loupe.services.mapping.ListObjectMappingsResponse} returns this
*/
proto.loupe.services.mapping.ListObjectMappingsResponse.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.mapping.ObjectMappingWrapper=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.mapping.ObjectMappingWrapper}
 */
proto.loupe.services.mapping.ListObjectMappingsResponse.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.mapping.ObjectMappingWrapper, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.mapping.ListObjectMappingsResponse} returns this
 */
proto.loupe.services.mapping.ListObjectMappingsResponse.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.GetObjectMappingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.GetObjectMappingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.GetObjectMappingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetObjectMappingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.GetObjectMappingRequest}
 */
proto.loupe.services.mapping.GetObjectMappingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.GetObjectMappingRequest;
  return proto.loupe.services.mapping.GetObjectMappingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.GetObjectMappingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.GetObjectMappingRequest}
 */
proto.loupe.services.mapping.GetObjectMappingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.GetObjectMappingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.GetObjectMappingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.GetObjectMappingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetObjectMappingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.GetObjectMappingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.GetObjectMappingRequest} returns this
 */
proto.loupe.services.mapping.GetObjectMappingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object_name = 2;
 * @return {string}
 */
proto.loupe.services.mapping.GetObjectMappingRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.GetObjectMappingRequest} returns this
 */
proto.loupe.services.mapping.GetObjectMappingRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.GetObjectMappingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.GetObjectMappingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.GetObjectMappingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetObjectMappingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    object: (f = msg.getObject()) && proto.loupe.services.mapping.ObjectMappingWrapper.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.GetObjectMappingResponse}
 */
proto.loupe.services.mapping.GetObjectMappingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.GetObjectMappingResponse;
  return proto.loupe.services.mapping.GetObjectMappingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.GetObjectMappingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.GetObjectMappingResponse}
 */
proto.loupe.services.mapping.GetObjectMappingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.mapping.ObjectMappingWrapper;
      reader.readMessage(value,proto.loupe.services.mapping.ObjectMappingWrapper.deserializeBinaryFromReader);
      msg.setObject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.GetObjectMappingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.GetObjectMappingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.GetObjectMappingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetObjectMappingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.mapping.ObjectMappingWrapper.serializeBinaryToWriter
    );
  }
};


/**
 * optional ObjectMappingWrapper object = 1;
 * @return {?proto.loupe.services.mapping.ObjectMappingWrapper}
 */
proto.loupe.services.mapping.GetObjectMappingResponse.prototype.getObject = function() {
  return /** @type{?proto.loupe.services.mapping.ObjectMappingWrapper} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.mapping.ObjectMappingWrapper, 1));
};


/**
 * @param {?proto.loupe.services.mapping.ObjectMappingWrapper|undefined} value
 * @return {!proto.loupe.services.mapping.GetObjectMappingResponse} returns this
*/
proto.loupe.services.mapping.GetObjectMappingResponse.prototype.setObject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mapping.GetObjectMappingResponse} returns this
 */
proto.loupe.services.mapping.GetObjectMappingResponse.prototype.clearObject = function() {
  return this.setObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mapping.GetObjectMappingResponse.prototype.hasObject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.UpsertObjectMappingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.UpsertObjectMappingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.UpsertObjectMappingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertObjectMappingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    object: (f = msg.getObject()) && proto.loupe.services.mapping.ObjectMappingWrapper.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.UpsertObjectMappingRequest}
 */
proto.loupe.services.mapping.UpsertObjectMappingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.UpsertObjectMappingRequest;
  return proto.loupe.services.mapping.UpsertObjectMappingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.UpsertObjectMappingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.UpsertObjectMappingRequest}
 */
proto.loupe.services.mapping.UpsertObjectMappingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new proto.loupe.services.mapping.ObjectMappingWrapper;
      reader.readMessage(value,proto.loupe.services.mapping.ObjectMappingWrapper.deserializeBinaryFromReader);
      msg.setObject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.UpsertObjectMappingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.UpsertObjectMappingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.UpsertObjectMappingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertObjectMappingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.mapping.ObjectMappingWrapper.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.UpsertObjectMappingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.UpsertObjectMappingRequest} returns this
 */
proto.loupe.services.mapping.UpsertObjectMappingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ObjectMappingWrapper object = 2;
 * @return {?proto.loupe.services.mapping.ObjectMappingWrapper}
 */
proto.loupe.services.mapping.UpsertObjectMappingRequest.prototype.getObject = function() {
  return /** @type{?proto.loupe.services.mapping.ObjectMappingWrapper} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.mapping.ObjectMappingWrapper, 2));
};


/**
 * @param {?proto.loupe.services.mapping.ObjectMappingWrapper|undefined} value
 * @return {!proto.loupe.services.mapping.UpsertObjectMappingRequest} returns this
*/
proto.loupe.services.mapping.UpsertObjectMappingRequest.prototype.setObject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mapping.UpsertObjectMappingRequest} returns this
 */
proto.loupe.services.mapping.UpsertObjectMappingRequest.prototype.clearObject = function() {
  return this.setObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mapping.UpsertObjectMappingRequest.prototype.hasObject = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.UpsertObjectMappingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.UpsertObjectMappingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.UpsertObjectMappingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertObjectMappingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.UpsertObjectMappingResponse}
 */
proto.loupe.services.mapping.UpsertObjectMappingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.UpsertObjectMappingResponse;
  return proto.loupe.services.mapping.UpsertObjectMappingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.UpsertObjectMappingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.UpsertObjectMappingResponse}
 */
proto.loupe.services.mapping.UpsertObjectMappingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.UpsertObjectMappingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.UpsertObjectMappingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.UpsertObjectMappingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.UpsertObjectMappingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ObjectMappingWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ObjectMappingWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ObjectMappingWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ObjectMappingWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mapping: (f = msg.getMapping()) && src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ObjectMappingWrapper}
 */
proto.loupe.services.mapping.ObjectMappingWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ObjectMappingWrapper;
  return proto.loupe.services.mapping.ObjectMappingWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ObjectMappingWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ObjectMappingWrapper}
 */
proto.loupe.services.mapping.ObjectMappingWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 2:
      var value = new src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping;
      reader.readMessage(value,src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.deserializeBinaryFromReader);
      msg.setMapping(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ObjectMappingWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ObjectMappingWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ObjectMappingWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ObjectMappingWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMapping();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional string object_name = 1;
 * @return {string}
 */
proto.loupe.services.mapping.ObjectMappingWrapper.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.ObjectMappingWrapper} returns this
 */
proto.loupe.services.mapping.ObjectMappingWrapper.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.tenant.mapping.v2.ObjectMapping mapping = 2;
 * @return {?proto.loupe.common.tenant.mapping.v2.ObjectMapping}
 */
proto.loupe.services.mapping.ObjectMappingWrapper.prototype.getMapping = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.ObjectMapping} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping, 2));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.ObjectMapping|undefined} value
 * @return {!proto.loupe.services.mapping.ObjectMappingWrapper} returns this
*/
proto.loupe.services.mapping.ObjectMappingWrapper.prototype.setMapping = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mapping.ObjectMappingWrapper} returns this
 */
proto.loupe.services.mapping.ObjectMappingWrapper.prototype.clearMapping = function() {
  return this.setMapping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mapping.ObjectMappingWrapper.prototype.hasMapping = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.InitializeMappingsForTenantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.InitializeMappingsForTenantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.InitializeMappingsForTenantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.InitializeMappingsForTenantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.InitializeMappingsForTenantRequest}
 */
proto.loupe.services.mapping.InitializeMappingsForTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.InitializeMappingsForTenantRequest;
  return proto.loupe.services.mapping.InitializeMappingsForTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.InitializeMappingsForTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.InitializeMappingsForTenantRequest}
 */
proto.loupe.services.mapping.InitializeMappingsForTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.InitializeMappingsForTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.InitializeMappingsForTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.InitializeMappingsForTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.InitializeMappingsForTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.InitializeMappingsForTenantRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.InitializeMappingsForTenantRequest} returns this
 */
proto.loupe.services.mapping.InitializeMappingsForTenantRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.InitializeMappingsForTenantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.InitializeMappingsForTenantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.InitializeMappingsForTenantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.InitializeMappingsForTenantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.InitializeMappingsForTenantResponse}
 */
proto.loupe.services.mapping.InitializeMappingsForTenantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.InitializeMappingsForTenantResponse;
  return proto.loupe.services.mapping.InitializeMappingsForTenantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.InitializeMappingsForTenantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.InitializeMappingsForTenantResponse}
 */
proto.loupe.services.mapping.InitializeMappingsForTenantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.InitializeMappingsForTenantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.InitializeMappingsForTenantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.InitializeMappingsForTenantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.InitializeMappingsForTenantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.GetMappingStatusForTenantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.GetMappingStatusForTenantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.GetMappingStatusForTenantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetMappingStatusForTenantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.GetMappingStatusForTenantRequest}
 */
proto.loupe.services.mapping.GetMappingStatusForTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.GetMappingStatusForTenantRequest;
  return proto.loupe.services.mapping.GetMappingStatusForTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.GetMappingStatusForTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.GetMappingStatusForTenantRequest}
 */
proto.loupe.services.mapping.GetMappingStatusForTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.GetMappingStatusForTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.GetMappingStatusForTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.GetMappingStatusForTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetMappingStatusForTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.GetMappingStatusForTenantRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.GetMappingStatusForTenantRequest} returns this
 */
proto.loupe.services.mapping.GetMappingStatusForTenantRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.GetMappingStatusForTenantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.GetMappingStatusForTenantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.GetMappingStatusForTenantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetMappingStatusForTenantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.GetMappingStatusForTenantResponse}
 */
proto.loupe.services.mapping.GetMappingStatusForTenantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.GetMappingStatusForTenantResponse;
  return proto.loupe.services.mapping.GetMappingStatusForTenantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.GetMappingStatusForTenantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.GetMappingStatusForTenantResponse}
 */
proto.loupe.services.mapping.GetMappingStatusForTenantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.mapping.MappingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.GetMappingStatusForTenantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.GetMappingStatusForTenantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.GetMappingStatusForTenantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.GetMappingStatusForTenantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional loupe.common.mapping.MappingStatus status = 1;
 * @return {!proto.loupe.common.mapping.MappingStatus}
 */
proto.loupe.services.mapping.GetMappingStatusForTenantResponse.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.mapping.MappingStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.mapping.MappingStatus} value
 * @return {!proto.loupe.services.mapping.GetMappingStatusForTenantResponse} returns this
 */
proto.loupe.services.mapping.GetMappingStatusForTenantResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest}
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest;
  return proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest}
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldTypesList: jspb.Message.toObjectList(msg.getFieldTypesList(),
    proto.loupe.services.mapping.CanonicalObjectFieldType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse}
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse;
  return proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse}
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.mapping.CanonicalObjectFieldType;
      reader.readMessage(value,proto.loupe.services.mapping.CanonicalObjectFieldType.deserializeBinaryFromReader);
      msg.addFieldTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.mapping.CanonicalObjectFieldType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CanonicalObjectFieldType field_types = 1;
 * @return {!Array<!proto.loupe.services.mapping.CanonicalObjectFieldType>}
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.prototype.getFieldTypesList = function() {
  return /** @type{!Array<!proto.loupe.services.mapping.CanonicalObjectFieldType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.mapping.CanonicalObjectFieldType, 1));
};


/**
 * @param {!Array<!proto.loupe.services.mapping.CanonicalObjectFieldType>} value
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse} returns this
*/
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.prototype.setFieldTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.mapping.CanonicalObjectFieldType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.mapping.CanonicalObjectFieldType}
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.prototype.addFieldTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.mapping.CanonicalObjectFieldType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse} returns this
 */
proto.loupe.services.mapping.ListCanonicalObjectFieldTypesResponse.prototype.clearFieldTypesList = function() {
  return this.setFieldTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.CanonicalObjectFieldType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.CanonicalObjectFieldType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    formatsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.CanonicalObjectFieldType}
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.CanonicalObjectFieldType;
  return proto.loupe.services.mapping.CanonicalObjectFieldType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.CanonicalObjectFieldType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.CanonicalObjectFieldType}
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFormats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.CanonicalObjectFieldType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.CanonicalObjectFieldType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFormatsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string field_type = 1;
 * @return {string}
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.prototype.getFieldType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.CanonicalObjectFieldType} returns this
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.prototype.setFieldType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string formats = 2;
 * @return {!Array<string>}
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.prototype.getFormatsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.mapping.CanonicalObjectFieldType} returns this
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.prototype.setFormatsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.mapping.CanonicalObjectFieldType} returns this
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.prototype.addFormats = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.mapping.CanonicalObjectFieldType} returns this
 */
proto.loupe.services.mapping.CanonicalObjectFieldType.prototype.clearFormatsList = function() {
  return this.setFormatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectMap: (f = msg.getObjectMap()) && src_common_mapping_mapping_pb.CanonicalObjectMapping.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest;
  return proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_mapping_mapping_pb.CanonicalObjectMapping;
      reader.readMessage(value,src_common_mapping_mapping_pb.CanonicalObjectMapping.deserializeBinaryFromReader);
      msg.setObjectMap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectMap();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_mapping_mapping_pb.CanonicalObjectMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.mapping.CanonicalObjectMapping object_map = 1;
 * @return {?proto.loupe.common.mapping.CanonicalObjectMapping}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.prototype.getObjectMap = function() {
  return /** @type{?proto.loupe.common.mapping.CanonicalObjectMapping} */ (
    jspb.Message.getWrapperField(this, src_common_mapping_mapping_pb.CanonicalObjectMapping, 1));
};


/**
 * @param {?proto.loupe.common.mapping.CanonicalObjectMapping|undefined} value
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest} returns this
*/
proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.prototype.setObjectMap = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest} returns this
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.prototype.clearObjectMap = function() {
  return this.setObjectMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingRequest.prototype.hasObjectMap = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    statusDetailsList: jspb.Message.toObjectList(msg.getStatusDetailsList(),
    src_common_mapping_mapping_pb.MappingStatusDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse;
  return proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.mapping.MappingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = new src_common_mapping_mapping_pb.MappingStatusDetail;
      reader.readMessage(value,src_common_mapping_mapping_pb.MappingStatusDetail.deserializeBinaryFromReader);
      msg.addStatusDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStatusDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_mapping_mapping_pb.MappingStatusDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.mapping.MappingStatus status = 1;
 * @return {!proto.loupe.common.mapping.MappingStatus}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.mapping.MappingStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.mapping.MappingStatus} value
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse} returns this
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated loupe.common.mapping.MappingStatusDetail status_details = 2;
 * @return {!Array<!proto.loupe.common.mapping.MappingStatusDetail>}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.prototype.getStatusDetailsList = function() {
  return /** @type{!Array<!proto.loupe.common.mapping.MappingStatusDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_mapping_mapping_pb.MappingStatusDetail, 2));
};


/**
 * @param {!Array<!proto.loupe.common.mapping.MappingStatusDetail>} value
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse} returns this
*/
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.prototype.setStatusDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.mapping.MappingStatusDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.mapping.MappingStatusDetail}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.prototype.addStatusDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.mapping.MappingStatusDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse} returns this
 */
proto.loupe.services.mapping.ValidateCanonicalObjectMappingResponse.prototype.clearStatusDetailsList = function() {
  return this.setStatusDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldMap: (f = msg.getFieldMap()) && src_common_mapping_mapping_pb.FieldMapping.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest;
  return proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_mapping_mapping_pb.FieldMapping;
      reader.readMessage(value,src_common_mapping_mapping_pb.FieldMapping.deserializeBinaryFromReader);
      msg.setFieldMap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldMap();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_mapping_mapping_pb.FieldMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.mapping.FieldMapping field_map = 1;
 * @return {?proto.loupe.common.mapping.FieldMapping}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.prototype.getFieldMap = function() {
  return /** @type{?proto.loupe.common.mapping.FieldMapping} */ (
    jspb.Message.getWrapperField(this, src_common_mapping_mapping_pb.FieldMapping, 1));
};


/**
 * @param {?proto.loupe.common.mapping.FieldMapping|undefined} value
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest} returns this
*/
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.prototype.setFieldMap = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest} returns this
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.prototype.clearFieldMap = function() {
  return this.setFieldMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingRequest.prototype.hasFieldMap = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    statusDetailsList: jspb.Message.toObjectList(msg.getStatusDetailsList(),
    src_common_mapping_mapping_pb.MappingStatusDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse;
  return proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.mapping.MappingStatusDetailLevel} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = new src_common_mapping_mapping_pb.MappingStatusDetail;
      reader.readMessage(value,src_common_mapping_mapping_pb.MappingStatusDetail.deserializeBinaryFromReader);
      msg.addStatusDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStatusDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_mapping_mapping_pb.MappingStatusDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.mapping.MappingStatusDetailLevel status = 1;
 * @return {!proto.loupe.common.mapping.MappingStatusDetailLevel}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.mapping.MappingStatusDetailLevel} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.mapping.MappingStatusDetailLevel} value
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse} returns this
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated loupe.common.mapping.MappingStatusDetail status_details = 2;
 * @return {!Array<!proto.loupe.common.mapping.MappingStatusDetail>}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.prototype.getStatusDetailsList = function() {
  return /** @type{!Array<!proto.loupe.common.mapping.MappingStatusDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_mapping_mapping_pb.MappingStatusDetail, 2));
};


/**
 * @param {!Array<!proto.loupe.common.mapping.MappingStatusDetail>} value
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse} returns this
*/
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.prototype.setStatusDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.mapping.MappingStatusDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.mapping.MappingStatusDetail}
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.prototype.addStatusDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.mapping.MappingStatusDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse} returns this
 */
proto.loupe.services.mapping.ValidateCanonicalObjectFieldMappingResponse.prototype.clearStatusDetailsList = function() {
  return this.setStatusDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest}
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest;
  return proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest}
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest} returns this
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse}
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse;
  return proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse}
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mapping.EnsureObjectSettingsForTenantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.loupe.services.mapping);
