import { Close } from '@getoutreach/react-icons'
import { noop } from 'lodash'
import JoyrideSubtitle from './joyrideSubtitle'
import JoyrideTitle from './joyrideTitle'
import React, { useCallback } from 'react'

const JoyrideModal = ({
  children,
  continuous,
  hideClose,
  onClick,
  primaryProps,
  step,
  tooltipProps,
}) => {
  const { onClick: onClickInternal = noop, title: buttonText = '' } = primaryProps
  const { content = '', modalTitle = '', title = '' } = step

  const handleClick = useCallback((e) => {
    onClickInternal(e)
    onClick?.()
  }, [onClick, onClickInternal])

  return (
    <div className="h-90vh flex flex-col justify-center">
      <div className="bg-color-ffffff p-24 md:p-36 rounded-lg shadow-tooltip w-screen max-w-812px relative overflow-y-auto" {...tooltipProps}>
        {!hideClose && (
          <button onClick={handleClick} className="absolute top-12 right-12 focus:outline-none">
            <Close htmlColor="#8F8D9A" />
          </button>
        )}
        {modalTitle && (
          <h1 className="text-color-09242f text-size-40px leading-none font-weight-700 mb-12 whitespace-pre-wrap">
            {modalTitle}
          </h1>
        )}
        <JoyrideTitle>{title}</JoyrideTitle>
        <JoyrideSubtitle>{content}</JoyrideSubtitle>
        {children}
        {continuous && (
          <div className="flex items-center justify-center mt-36">
            <button
              {...primaryProps}
              className="bg-color-5951FF font-weight-700 leading-tight text-color-ffffff text-size-20px px-12 py-3 rounded-full shadow-btn focus:outline-none"
              onClick={handleClick}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default JoyrideModal
