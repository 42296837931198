import { GroupsErrorProvider } from '../../context/groupsError'
import { OnboardingStep } from '../../grpc/enums'
import { SyncStateProvider } from '../../context/syncState'
import { SystemRolesProvider } from '../../context/systemRoles'
import { TreeDataProvider } from '../../context/treeData'
import { UsersProvider } from '../../context/users'
import { useSetup } from '../../context/setup'
import Onboarding from '../onboarding/onboarding'
import React, { useCallback } from 'react'
import UsersMain from './usersMain'

const Users = (props) => {
  const { completeStep } = useSetup()

  const handleCompleteJoyride = useCallback(() => {
    completeStep(OnboardingStep.ONBOARDING_STEP_USERS)
  }, [completeStep])

  return (
    <GroupsErrorProvider>
      <SyncStateProvider>
        <UsersProvider>
          <SystemRolesProvider>
            <TreeDataProvider>
              <Onboarding step={OnboardingStep.ONBOARDING_STEP_USERS} onComplete={handleCompleteJoyride}>
                <UsersMain />
              </Onboarding>
            </TreeDataProvider>
          </SystemRolesProvider>
        </UsersProvider>
      </SyncStateProvider>
    </GroupsErrorProvider>
  )
}

export default Users
