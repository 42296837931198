import React from 'react'
import { useSnackbar } from 'notistack'

const CloseButton = ({ snackbarId }) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <button
      className="text-size-12px mr-2"
      onClick={() => closeSnackbar(snackbarId)}>
      x
    </button>
  )
}

export default CloseButton
