import { AutoSizer } from 'react-virtualized'
import { getNodeById } from '../tree/getNodeById'
import { getParentNode } from '../tree/getParentNode'
import { getTimestampFromDate } from '../../lib/dateFns'
import { GroupSyncStatus, OnboardingStep } from '../../grpc/enums'
import { JoyrideProvider, useJoyride } from '../../context/joyride'
import { permissionNames } from '../../constants/permissionNames'
import { toggleExpandedForAll } from '../tree/utils/tree-data-utils'
import { toIdRequest, toSyncRequest, toUpdateGroupRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import { useGroupsError } from '../../context/groupsError'
import { useGrpcCallback } from '../../grpc'
import { useModal } from '../../hooks/useModal'
import { useNotification } from '../../hooks/useNotification'
import { usePermissions } from '../../context/permissions'
import { usePopover } from '../../hooks/usePopover'
import { useSetup } from '../../context/setup'
import { useSyncState } from '../../context/syncState'
import { useTenantInfo } from '../../context/tenantInfo'
import { useTenantLicenseInfo } from '../../context/tenantLicenseInfo'
import { useTreeData } from '../../context/treeData'
import { useUserPrefs } from '../../context/userPrefs'
import ActionModal from '../common/actionModal'
import ActionPopover from '../common/actionPopover'
import AddEditGroupModal from './addEditGroupModal'
import Button from '../common/button'
import classNames from 'classnames'
import CustomNodeContentRenderer from '../tree/customNodeContentRenderer'
import DeleteGroupModal from './deleteGroupModal'
import EmptyState from '../common/emptyState'
import GroupMembersModal from './groupMembersModal'
import Groups from '../icons/groups'
import GroupsError from './groupsError'
import Header from '../header/header'
import Info from '../icons/info'
import JoyrideModal from '../joyride/joyrideModal'
import LoadingState from '../common/loadingState'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SortableTree from '@nosferatu500/react-sortable-tree'
import Tooltip from '../common/tooltip'
import { teamsGuide } from '../../constants/externalUrls'
import PageDescription from '../pageDescription/pageDescription'

const GroupsMain = (props) => {
  const { tenantId } = useAuth()

  const { checkPermission, checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadGroup,
      permissionNames.CanCreateGroup
    )
  }, [checkPermissions])

  const { crmName, isSalesforce } = useTenantInfo()
  const { invalidate: invalidateTenantLicenseInfo, tenantLicenseInfo } = useTenantLicenseInfo()

  const { completeStep } = useSetup()
  const { enabled: joyrideEnabled, setJoyride, start: startJoyride } = useJoyride()
  const { getPref, savePref } = useUserPrefs()

  const addEditModal = useModal()
  const deleteModal = useModal()
  const membersModal = useModal()
  const moveModal = useModal()

  const syncChangePopover = usePopover()

  const { notifyError } = useNotification()

  const { groupsError, handleFailedPreconditionError } = useGroupsError()

  const {
    dataInitialized,
    isFetching: isFetchingTreeData,
    treeData, setTreeData,
    invalidate: invalidateTreeData,
    expandGroupById,
  } = useTreeData()

  useEffect(() => {
    if (dataInitialized) {
      setTreeData(toggleExpandedForAll({ treeData, expanded: true }))
    }
  }, [dataInitialized])

  const { syncStatus, invalidate: invalidateSyncState } = useSyncState()

  const [isDeleting, setIsDeleting] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const [parentId, setParentId] = useState('')
  const [editData, setEditData] = useState(undefined)

  const [membersGroup, setMembersGroup] = useState(undefined)

  const [canRunJoyride, setCanRunJoyride] = useState(getPref('joyride', 'hasSeenTeamsModal'))

  const isFetching = useMemo(() => {
    return isFetchingTreeData || isSyncing || isDeleting || isUpdating
  }, [isFetchingTreeData, isSyncing, isDeleting, isUpdating])

  const handleCloseJoyrideModal = useCallback(() => {
    setCanRunJoyride(true)
  }, [])

  const configureJoyride = useCallback(() => {
    const steps = []
    // We only want to show this step if the user hasn't seen it
    if (!getPref('joyride', 'hasSeenTeamsModal')) {
      steps.push({
        target: 'body',
        title: 'Now let’s set up your teams',
        content: 'Here’s a quick tutorial on setting up and managing teams to help you get started. You will need to set up at least one team to proceed beyond the Teams page.',
        disableBeacon: true,
        locale: { last: 'Get Started', next: 'Get Started' },
        placement: 'center',
        styles: {
          overlay: {
            background: 'rgba(0, 0, 0, 0.3)',
            zIndex: 300
          }
        },
        tooltipComponent: (props) => (
          <JoyrideModal onClick={handleCloseJoyrideModal} {...props}>
            <div className="mt-8 -mb-22 w-full relative overflow-hidden h-0" style={{ paddingBottom: '56.25%' }}>
              <iframe
                className="absolute top-0 left-0 h-full w-full"
                width="560"
                height="315"
                src="https://storage.googleapis.com/outreach-commit-tutorials/Team%20Setup%20Overview.mp4"
                title="Team Overview"
                frameBorder="0"
                allowFullScreen />
            </div>
          </JoyrideModal>
        )
      })
      // Update the pref so we don't see this again
      savePref('joyride', 'hasSeenTeamsModal', true)
    }

    setJoyride(steps)
    startJoyride()
  }, [getPref, handleCloseJoyrideModal, savePref, setJoyride, startJoyride])

  const handleCompleteJoyride = useCallback(() => {
    completeStep(OnboardingStep.ONBOARDING_STEP_TEAMS)
  }, [completeStep])

  useEffect(() => {
    if (joyrideEnabled && !isFetching) {
      configureJoyride()
    }
  }, [configureJoyride, isFetching, joyrideEnabled])

  const invalidate = useCallback((resetDataInitialized = false) => {
    invalidateTenantLicenseInfo()
    invalidateTreeData(resetDataInitialized)
    invalidateSyncState()
  }, [invalidateTenantLicenseInfo, invalidateTreeData, invalidateSyncState])

  const showEmptyState = useMemo(() => {
    return !isFetching && treeData.length === 0
  }, [isFetching, treeData])

  useEffect(() => {
    if (membersModal.open) {
      const updatedGroup = getNodeById(treeData, membersGroup.group.id)
      setMembersGroup(updatedGroup)
    }
  }, [membersModal.open, treeData, membersGroup])

  const onChange = useCallback((data) => {
    setTreeData(data)
  }, [setTreeData])

  const onExpandTeams = useCallback(() => {
    setTreeData(toggleExpandedForAll({ treeData, expanded: true }))
  }, [setTreeData, treeData])

  const onCollapseTeams = useCallback(() => {
    setTreeData(toggleExpandedForAll({ treeData, expanded: false }))
  }, [setTreeData, treeData])

  const onMembersClick = useCallback(({ node, path, treeIndex }) => {
    setMembersGroup(node)
    membersModal.setOpen(true)
  }, [membersModal])

  const onAddRootNode = useCallback((e) => {
    if (syncStatus === GroupSyncStatus.ACTIVE) {
      syncChangePopover.setAnchorEl(e.currentTarget)
    } else {
      setParentId('')
      setEditData(undefined)
      addEditModal.setOpen(true)
    }
    window.analytics.track('Add Team Clicked')
  }, [addEditModal, syncChangePopover, syncStatus])

  const onContinueConfirmed = useCallback((e) => {
    setParentId('')
    setEditData(undefined)
    addEditModal.setOpen(true)
  }, [addEditModal])

  const onAddNode = useCallback(({ node, path, treeIndex }) => {
    expandGroupById({ treeData, node, path, treeIndex })
    setParentId(node.group.id)
    setEditData(undefined)
    addEditModal.setOpen(true)
  }, [addEditModal, expandGroupById, treeData])

  const onEditNode = useCallback(({ node, path, treeIndex }) => {
    setEditData(node)
    addEditModal.setOpen(true)
  }, [addEditModal])

  const deleteGroupById = useGrpcCallback({
    onError: (err) => {
      if (handleFailedPreconditionError(err)) {
        return
      }
      notifyError('Error deleting group!')
      invalidate()
      setIsDeleting(false)
      deleteModal.setData(undefined)
    },
    onSuccess: () => {
      invalidate()
      setIsDeleting(false)
      deleteModal.setData(undefined)
    },
    onFetch: () => setIsDeleting(true),
    grpcMethod: 'deleteGroupById',
    debug: false,
  }, [handleFailedPreconditionError])

  const onDeleteNode = useCallback((data) => {
    deleteModal.setData(data)
    deleteModal.setOpen(true)
  }, [deleteModal])

  const onDeleteConfirmed = useCallback(({ node, path, treeIndex }) => {
    const request = toIdRequest({
      tenantId,
      id: node.group.id,
    })
    deleteGroupById(request)
  }, [deleteGroupById, tenantId])

  const updateGroup = useGrpcCallback({
    onError: (err) => {
      if (handleFailedPreconditionError(err)) {
        return
      }
      notifyError('Error updating group!')
      invalidate()
      setIsUpdating(false)
    },
    onSuccess: () => {
      invalidate()
      setIsUpdating(false)
    },
    onFetch: () => setIsUpdating(true),
    grpcMethod: 'updateGroup',
    debug: false,
  }, [handleFailedPreconditionError])

  const onMoveCancel = useCallback(() => {
    moveModal.setData(undefined)
    invalidateTreeData()
  }, [invalidateTreeData, moveModal])

  const onMoveNode = useCallback((args) => {
    const { treeData: data, node } = args
    const parent = getParentNode(data, node)
    const parentGroupId = parent ? parent.group.id : ''

    if (parentGroupId !== node.group.parentId) {
      if (syncStatus === GroupSyncStatus.ACTIVE) {
        moveModal.setData({
          tenantId,
          groupId: node.group.id,
          group: {
            ...node.group,
            parentId: parentGroupId,
          },
        })
        moveModal.setOpen(true)
      } else {
        const request = toUpdateGroupRequest({
          tenantId,
          groupId: node.group.id,
          group: {
            ...node.group,
            parentId: parentGroupId,
          },
        })
        updateGroup(request)
      }
    }
  }, [moveModal, syncStatus, tenantId, updateGroup])

  const onMoveConfirmed = useCallback(() => {
    if (moveModal.data) {
      const request = toUpdateGroupRequest(moveModal.data)
      updateGroup(request)
    }
  }, [moveModal.data, updateGroup])

  const sync = useGrpcCallback({
    onError: (err) => {
      if (handleFailedPreconditionError(err)) {
        return
      }
      notifyError('Error syncing hierarchy!')
      invalidate()
      setIsSyncing(false)
    },
    onSuccess: () => {
      invalidate(true)
      setIsSyncing(false)
    },
    onFetch: () => setIsSyncing(true),
    grpcMethod: 'sync',
    debug: false,
  }, [handleFailedPreconditionError])

  const onSync = useCallback(() => {
    const request = toSyncRequest({
      tenantId,
      syncSince: getTimestampFromDate(new Date())
    })
    sync(request)
  }, [sync, tenantId])

  return (
    <>
      <div className="flex flex-col w-full h-full overflow-hidden">
        <Header
          title="Teams" />
        <div className="flex flex-grow flex-col w-full">
          <PageDescription title="Teams in Outreach Commit"
            text={`Teams in Outreach Commit are managed using a hierarchy that reflects your organization.
              This hierarchy is used for both pipeline & team visibility, as well as forecast roll-ups.
              ${isSalesforce && `\u00a0You are also able to synchronize your teams from the roles currently configured in ${crmName}.`}`}
            link={teamsGuide} />

          {groupsError
            ? (
              <div className="flex justify-center my-10">
                <GroupsError />
              </div>
            ) : (
              <>
                {permissions.CanReadGroup && (
                  <>
                    <div className="mx-10 mt-6">
                      <div className={classNames('flex items-center', { 'pointer-events-none': isFetching })}>
                        <div className="mr-auto">
                          <div className="text-size-20px text-color-51636a font-weight-400">
                            {tenantLicenseInfo?.filledSeats && (
                              <>
                                {pluralize('licensed team member', tenantLicenseInfo?.filledSeats, true)}
                                <Tooltip
                                  arrow
                                  placement="right"
                                  title="Outreach Commit assigns a license to each person in the team hierarchy.
                                  This count will update itself as users are added or removed. Ensure you have sufficient licenses purchased to cover your teams.">
                                  <Info htmlColor="#818e93" transform="scale(0.8)" />
                                </Tooltip>
                              </>
                            )}
                          </div>
                        </div>
                        <button
                          onClick={onExpandTeams}
                          className={classNames('ml-2 mr-2 first:ml-0 text-size-16px text-color-51636a font-weight-400 focus:outline-none',
                            { 'opacity-50 pointer-events-none': showEmptyState })}>
                          Expand Teams
                        </button>
                        <div className="mx-1 text-size-16px text-color-c9ced0 font-weight-400">|</div>
                        <button
                          onClick={onCollapseTeams}
                          className={classNames('ml-2 mr-2 first:ml-0 text-size-16px text-color-51636a font-weight-400 focus:outline-none',
                            { 'opacity-50 pointer-events-none': showEmptyState })}>
                          Collapse Teams
                        </button>
                        {permissions.CanCreateGroup && !showEmptyState && (
                          <>
                            <div className="mx-1 text-size-16px text-color-c9ced0 font-weight-400">|</div>
                            <button
                              onClick={onAddRootNode}
                              className="ml-2 mr-2 first:ml-0 text-size-16px text-color-51636a font-weight-400 focus:outline-none">
                              Add Teams
                            </button>
                          </>
                        )}
                        <ActionPopover
                          popover={syncChangePopover}
                          text={`You are currently syncing your both your hierarchy and your people from ${crmName}.`}
                          subText={`If you continue editing groups manually, your groups will no longer update automatically when you make changes in ${crmName}.`}
                          actionText="Continue"
                          onAction={onContinueConfirmed}
                          maxWidth={310} />
                      </div>
                    </div>
                    {
                      showEmptyState
                        ? (
                          <div className="flex flex-col my-10">
                            <div className="flex justify-center">
                              <EmptyState
                                iconControl={<Groups transform="scale(1.2)" />}
                                header="No Teams"
                                subHeader={(
                                  <span>
                                    To get started, use the options below
                                    <br />
                                    to either sync teams from your CRM or
                                    <br />
                                    add teams manually.
                                    <br />
                                  </span>
                                )} />
                            </div>
                            {isSalesforce
                              && (
                                <div className="flex justify-center mt-6">
                                  <Button
                                    size="xs"
                                    text="Sync from CRM"
                                    onClick={onSync}
                                    className="mx-2" />
                                </div>
                              )}
                            {permissions.CanCreateGroup && (
                              <>
                                <button
                                  onClick={onAddRootNode}
                                  className="pt-2 ml-2 mr-2 first:ml-0 text-size-16px text-color-5951FF font-weight-700 focus:outline-none">
                                  Add Manually
                                </button>
                              </>
                            )}
                          </div>
                        )
                        : (
                          <div className="flex-grow overflow-auto pl-6 mt-3">
                            <AutoSizer>
                              {({ width, height }) => (
                                <div className="relative" style={{ width: width - 1, height: height - 1 }}>
                                  {isFetching
                                    && (
                                      <div className="absolute z-50" style={{ width: width - 1, height: height - 1 }}>
                                        <div className="flex justify-center my-10">
                                          <LoadingState
                                            header="Loading Groups"
                                            subHeader="Please wait..."
                                            animate={true} />
                                        </div>
                                      </div>
                                    )}
                                  <JoyrideProvider
                                    enabled={joyrideEnabled}
                                    initialSteps={[
                                      {
                                        target: '.joyride_teams .on-manage',
                                        title: 'Manage Team Members',
                                        content: 'Click here to manage team members that are within a team.',
                                        offset: -10
                                      },
                                      {
                                        target: '.joyride_teams .on-add',
                                        title: 'Add Team',
                                        content: 'Click here to add a team within the hierarchy. The new team will be nestled under the selected team.',
                                        offset: -10
                                      },
                                      {
                                        target: '.joyride_teams .on-edit',
                                        title: 'Edit Team',
                                        content: 'Click here to edit the team name and the team structure.',
                                        offset: -10
                                      },
                                      {
                                        target: '.joyride_teams .on-delete',
                                        title: 'Delete',
                                        content: 'Click here to delete a team.',
                                        offset: -10
                                      }
                                    ]}
                                    onComplete={handleCompleteJoyride}
                                    runOnMount={canRunJoyride && !isFetching && dataInitialized && treeData.length > 0}>
                                    <SortableTree
                                      treeData={treeData}
                                      onChange={onChange}
                                      nodeContentRenderer={CustomNodeContentRenderer}
                                      onMoveNode={onMoveNode}
                                      generateNodeProps={({ treeIndex }) => ({
                                        ...treeIndex === 0 && {
                                          className: 'joyride_teams',
                                          showActionsOnHover: !joyrideEnabled
                                        },
                                        onMembers: onMembersClick,
                                        onAdd: onAddNode,
                                        onEdit: onEditNode,
                                        onDelete: onDeleteNode,
                                        checkPermission,
                                      })}
                                      canNodeHaveChildren={() => true} />
                                  </JoyrideProvider>
                                </div>
                              )}
                            </AutoSizer>
                          </div>
                        )
                    }
                  </>
                )}
              </>
            )}
        </div>

      </div>

      <AddEditGroupModal
        modal={addEditModal}
        parentId={parentId}
        editData={editData} />

      <DeleteGroupModal
        modal={deleteModal}
        onDeleteConfirmed={onDeleteConfirmed} />

      <GroupMembersModal
        modal={membersModal}
        groupData={membersGroup}
        invalidate={invalidate} />

      <ActionModal
        modal={moveModal}
        title="Sync Warning"
        text={`You are currently syncing your both your hierarchy and your people from ${crmName}.`}
        subText={`If you continue editing groups manually, your groups will no longer update automatically when you make changes in ${crmName}.`}
        actionText="Continue"
        onCancel={onMoveCancel}
        onAction={onMoveConfirmed} />

    </>
  )
}

export default GroupsMain
