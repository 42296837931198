import React from 'react'

const ModalFooter = (props) => {
  const {
    children,
  } = props

  return (
    <div className="flex items-center justify-center py-6">
      {children}
    </div>
  )
}

export default ModalFooter
