import React, { useEffect } from 'react'
import { logout } from '../../lib/auth0'
import { useRoutes } from '../../context/routes'
import { useAuth } from '../../context/auth'

const Logout = (props) => {
  const { resetAuth } = useAuth()
  const { routes } = useRoutes()

  useEffect(() => {
    resetAuth()
    window.analytics.track('Logout')
    logout({ returnTo: `${window.location.origin}${routes.login}` })
  }, [])

  return <></>
}

export default Logout
