// source: src/services/hotspring/hotspring.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_realtime_query_pb = require('../../../src/common/realtime/query_pb.js');
goog.object.extend(proto, src_common_realtime_query_pb);
var src_common_actor_pb = require('../../../src/common/actor_pb.js');
goog.object.extend(proto, src_common_actor_pb);
goog.exportSymbol('proto.loupe.services.hotspring.BuildRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.BuildResponse', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.Empty', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.ErrorCode', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.GetQueryRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.GetUserQueryParametersRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.GetUserQueryParametersResponse', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.GroupedObjectFilter', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.MultiQueryResponse', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.QueryError', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.QueryFilter', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.QueryInterpreterMode', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.QueryOptions', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.QueryOrder', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.QueryRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.QueryResponse', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.QueryTier', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.RunFilteredQueriesRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.RunQueriesRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.RunQueryObjectDataRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.RunQueryObjectDataResponse', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.RunQueryRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.RunRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.SaveQueryRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.SaveQueryResponse', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.SaveUserQueryParametersRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.SaveUserQueryParametersResponse', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.SavedQueryParametersFilter', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.SavedQueryParametersOrder', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.SearchQueriesRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.SearchQueriesResponse', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.SearchUserQueryParametersRequest', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.SearchUserQueryParametersResponse', null, global);
goog.exportSymbol('proto.loupe.services.hotspring.TenantSettingParams', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.Empty.displayName = 'proto.loupe.services.hotspring.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.BuildRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.BuildRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.BuildRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.BuildRequest.displayName = 'proto.loupe.services.hotspring.BuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.BuildResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.BuildResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.BuildResponse.displayName = 'proto.loupe.services.hotspring.BuildResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.TenantSettingParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.TenantSettingParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.TenantSettingParams.displayName = 'proto.loupe.services.hotspring.TenantSettingParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.QueryError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.QueryError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.QueryError.displayName = 'proto.loupe.services.hotspring.QueryError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.QueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.QueryRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.QueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.QueryRequest.displayName = 'proto.loupe.services.hotspring.QueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.QueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.QueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.QueryResponse.displayName = 'proto.loupe.services.hotspring.QueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.MultiQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.MultiQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.MultiQueryResponse.displayName = 'proto.loupe.services.hotspring.MultiQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.RunRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.RunRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.RunRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.RunRequest.displayName = 'proto.loupe.services.hotspring.RunRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.RunQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.RunQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.RunQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.RunQueryRequest.displayName = 'proto.loupe.services.hotspring.RunQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.RunQueriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.RunQueriesRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.RunQueriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.RunQueriesRequest.displayName = 'proto.loupe.services.hotspring.RunQueriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.RunFilteredQueriesRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.RunFilteredQueriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.RunFilteredQueriesRequest.displayName = 'proto.loupe.services.hotspring.RunFilteredQueriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.GetQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.GetQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.GetQueryRequest.displayName = 'proto.loupe.services.hotspring.GetQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.SearchQueriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.SearchQueriesRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.SearchQueriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.SearchQueriesRequest.displayName = 'proto.loupe.services.hotspring.SearchQueriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.QueryFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.QueryFilter.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.QueryFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.QueryFilter.displayName = 'proto.loupe.services.hotspring.QueryFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.QueryOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.QueryOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.QueryOrder.displayName = 'proto.loupe.services.hotspring.QueryOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.SearchQueriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.SearchQueriesResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.SearchQueriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.SearchQueriesResponse.displayName = 'proto.loupe.services.hotspring.SearchQueriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.GetUserQueryParametersRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.GetUserQueryParametersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.GetUserQueryParametersRequest.displayName = 'proto.loupe.services.hotspring.GetUserQueryParametersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.GetUserQueryParametersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.GetUserQueryParametersResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.GetUserQueryParametersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.GetUserQueryParametersResponse.displayName = 'proto.loupe.services.hotspring.GetUserQueryParametersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.SearchUserQueryParametersRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.SearchUserQueryParametersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.SearchUserQueryParametersRequest.displayName = 'proto.loupe.services.hotspring.SearchUserQueryParametersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.SavedQueryParametersFilter.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.SavedQueryParametersFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.SavedQueryParametersFilter.displayName = 'proto.loupe.services.hotspring.SavedQueryParametersFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.SavedQueryParametersOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.SavedQueryParametersOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.SavedQueryParametersOrder.displayName = 'proto.loupe.services.hotspring.SavedQueryParametersOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.SearchUserQueryParametersResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.SearchUserQueryParametersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.SearchUserQueryParametersResponse.displayName = 'proto.loupe.services.hotspring.SearchUserQueryParametersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.SaveQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.SaveQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.SaveQueryRequest.displayName = 'proto.loupe.services.hotspring.SaveQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.SaveQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.SaveQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.SaveQueryResponse.displayName = 'proto.loupe.services.hotspring.SaveQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.SaveUserQueryParametersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.SaveUserQueryParametersRequest.displayName = 'proto.loupe.services.hotspring.SaveUserQueryParametersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.SaveUserQueryParametersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.SaveUserQueryParametersResponse.displayName = 'proto.loupe.services.hotspring.SaveUserQueryParametersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.hotspring.RunQueryObjectDataRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.hotspring.RunQueryObjectDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.RunQueryObjectDataRequest.displayName = 'proto.loupe.services.hotspring.RunQueryObjectDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.GroupedObjectFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.GroupedObjectFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.GroupedObjectFilter.displayName = 'proto.loupe.services.hotspring.GroupedObjectFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.RunQueryObjectDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.RunQueryObjectDataResponse.displayName = 'proto.loupe.services.hotspring.RunQueryObjectDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.hotspring.QueryOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.hotspring.QueryOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.hotspring.QueryOptions.displayName = 'proto.loupe.services.hotspring.QueryOptions';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.Empty}
 */
proto.loupe.services.hotspring.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.Empty;
  return proto.loupe.services.hotspring.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.Empty}
 */
proto.loupe.services.hotspring.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.BuildRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.BuildRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.BuildRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.BuildRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.BuildRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    namespace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    query: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paramsList: jspb.Message.toObjectList(msg.getParamsList(),
    src_common_realtime_query_pb.QueryParameter.toObject, includeInstance),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.BuildRequest}
 */
proto.loupe.services.hotspring.BuildRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.BuildRequest;
  return proto.loupe.services.hotspring.BuildRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.BuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.BuildRequest}
 */
proto.loupe.services.hotspring.BuildRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 5:
      var value = new src_common_realtime_query_pb.QueryParameter;
      reader.readMessage(value,src_common_realtime_query_pb.QueryParameter.deserializeBinaryFromReader);
      msg.addParams(value);
      break;
    case 6:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.BuildRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.BuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.BuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.BuildRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      src_common_realtime_query_pb.QueryParameter.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query_key = 1;
 * @return {string}
 */
proto.loupe.services.hotspring.BuildRequest.prototype.getQueryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.BuildRequest} returns this
 */
proto.loupe.services.hotspring.BuildRequest.prototype.setQueryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string namespace = 2;
 * @return {string}
 */
proto.loupe.services.hotspring.BuildRequest.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.BuildRequest} returns this
 */
proto.loupe.services.hotspring.BuildRequest.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.hotspring.BuildRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.BuildRequest} returns this
 */
proto.loupe.services.hotspring.BuildRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string query = 4;
 * @return {string}
 */
proto.loupe.services.hotspring.BuildRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.BuildRequest} returns this
 */
proto.loupe.services.hotspring.BuildRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated loupe.common.realtime.QueryParameter params = 5;
 * @return {!Array<!proto.loupe.common.realtime.QueryParameter>}
 */
proto.loupe.services.hotspring.BuildRequest.prototype.getParamsList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.QueryParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_realtime_query_pb.QueryParameter, 5));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.QueryParameter>} value
 * @return {!proto.loupe.services.hotspring.BuildRequest} returns this
*/
proto.loupe.services.hotspring.BuildRequest.prototype.setParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.realtime.QueryParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.QueryParameter}
 */
proto.loupe.services.hotspring.BuildRequest.prototype.addParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.realtime.QueryParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.BuildRequest} returns this
 */
proto.loupe.services.hotspring.BuildRequest.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};


/**
 * optional loupe.common.Actor actor = 6;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.BuildRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 6));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.BuildRequest} returns this
*/
proto.loupe.services.hotspring.BuildRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.BuildRequest} returns this
 */
proto.loupe.services.hotspring.BuildRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.BuildRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.BuildResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.BuildResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.BuildResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.BuildResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.BuildResponse}
 */
proto.loupe.services.hotspring.BuildResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.BuildResponse;
  return proto.loupe.services.hotspring.BuildResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.BuildResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.BuildResponse}
 */
proto.loupe.services.hotspring.BuildResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.BuildResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.BuildResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.BuildResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.BuildResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.loupe.services.hotspring.BuildResponse.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.BuildResponse} returns this
 */
proto.loupe.services.hotspring.BuildResponse.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.TenantSettingParams.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.TenantSettingParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.TenantSettingParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.TenantSettingParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    fiscalYearStartMonthIdx: jspb.Message.getFieldWithDefault(msg, 1, 0),
    salesPeriodMonthLength: jspb.Message.getFieldWithDefault(msg, 2, 0),
    utcOffsetSeconds: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.TenantSettingParams}
 */
proto.loupe.services.hotspring.TenantSettingParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.TenantSettingParams;
  return proto.loupe.services.hotspring.TenantSettingParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.TenantSettingParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.TenantSettingParams}
 */
proto.loupe.services.hotspring.TenantSettingParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFiscalYearStartMonthIdx(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSalesPeriodMonthLength(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtcOffsetSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.TenantSettingParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.TenantSettingParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.TenantSettingParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.TenantSettingParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiscalYearStartMonthIdx();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSalesPeriodMonthLength();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUtcOffsetSeconds();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 fiscal_year_start_month_idx = 1;
 * @return {number}
 */
proto.loupe.services.hotspring.TenantSettingParams.prototype.getFiscalYearStartMonthIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.hotspring.TenantSettingParams} returns this
 */
proto.loupe.services.hotspring.TenantSettingParams.prototype.setFiscalYearStartMonthIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 sales_period_month_length = 2;
 * @return {number}
 */
proto.loupe.services.hotspring.TenantSettingParams.prototype.getSalesPeriodMonthLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.hotspring.TenantSettingParams} returns this
 */
proto.loupe.services.hotspring.TenantSettingParams.prototype.setSalesPeriodMonthLength = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 utc_offset_seconds = 3;
 * @return {number}
 */
proto.loupe.services.hotspring.TenantSettingParams.prototype.getUtcOffsetSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.hotspring.TenantSettingParams} returns this
 */
proto.loupe.services.hotspring.TenantSettingParams.prototype.setUtcOffsetSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.QueryError.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.QueryError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.QueryError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.QueryError.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, 0),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    retryAfter: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.QueryError}
 */
proto.loupe.services.hotspring.QueryError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.QueryError;
  return proto.loupe.services.hotspring.QueryError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.QueryError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.QueryError}
 */
proto.loupe.services.hotspring.QueryError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.services.hotspring.ErrorCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRetryAfter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.QueryError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.QueryError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.QueryError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.QueryError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRetryAfter();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.services.hotspring.QueryError.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.QueryError} returns this
 */
proto.loupe.services.hotspring.QueryError.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ErrorCode code = 2;
 * @return {!proto.loupe.services.hotspring.ErrorCode}
 */
proto.loupe.services.hotspring.QueryError.prototype.getCode = function() {
  return /** @type {!proto.loupe.services.hotspring.ErrorCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.services.hotspring.ErrorCode} value
 * @return {!proto.loupe.services.hotspring.QueryError} returns this
 */
proto.loupe.services.hotspring.QueryError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.loupe.services.hotspring.QueryError.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.QueryError} returns this
 */
proto.loupe.services.hotspring.QueryError.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 retry_after = 4;
 * @return {number}
 */
proto.loupe.services.hotspring.QueryError.prototype.getRetryAfter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.hotspring.QueryError} returns this
 */
proto.loupe.services.hotspring.QueryError.prototype.setRetryAfter = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.QueryRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.QueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.QueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.QueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    namespace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    query: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paramsList: jspb.Message.toObjectList(msg.getParamsList(),
    src_common_realtime_query_pb.QueryParameter.toObject, includeInstance),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    tenantSettingParams: (f = msg.getTenantSettingParams()) && proto.loupe.services.hotspring.TenantSettingParams.toObject(includeInstance, f),
    tier: jspb.Message.getFieldWithDefault(msg, 8, 0),
    queryOptions: (f = msg.getQueryOptions()) && proto.loupe.services.hotspring.QueryOptions.toObject(includeInstance, f),
    preserveSort: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.QueryRequest}
 */
proto.loupe.services.hotspring.QueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.QueryRequest;
  return proto.loupe.services.hotspring.QueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.QueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.QueryRequest}
 */
proto.loupe.services.hotspring.QueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 5:
      var value = new src_common_realtime_query_pb.QueryParameter;
      reader.readMessage(value,src_common_realtime_query_pb.QueryParameter.deserializeBinaryFromReader);
      msg.addParams(value);
      break;
    case 6:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 7:
      var value = new proto.loupe.services.hotspring.TenantSettingParams;
      reader.readMessage(value,proto.loupe.services.hotspring.TenantSettingParams.deserializeBinaryFromReader);
      msg.setTenantSettingParams(value);
      break;
    case 8:
      var value = /** @type {!proto.loupe.services.hotspring.QueryTier} */ (reader.readEnum());
      msg.setTier(value);
      break;
    case 9:
      var value = new proto.loupe.services.hotspring.QueryOptions;
      reader.readMessage(value,proto.loupe.services.hotspring.QueryOptions.deserializeBinaryFromReader);
      msg.setQueryOptions(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreserveSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.QueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.QueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.QueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      src_common_realtime_query_pb.QueryParameter.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getTenantSettingParams();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.services.hotspring.TenantSettingParams.serializeBinaryToWriter
    );
  }
  f = message.getTier();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getQueryOptions();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.loupe.services.hotspring.QueryOptions.serializeBinaryToWriter
    );
  }
  f = message.getPreserveSort();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string query_key = 1;
 * @return {string}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.getQueryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
 */
proto.loupe.services.hotspring.QueryRequest.prototype.setQueryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string namespace = 2;
 * @return {string}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
 */
proto.loupe.services.hotspring.QueryRequest.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
 */
proto.loupe.services.hotspring.QueryRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string query = 4;
 * @return {string}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
 */
proto.loupe.services.hotspring.QueryRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated loupe.common.realtime.QueryParameter params = 5;
 * @return {!Array<!proto.loupe.common.realtime.QueryParameter>}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.getParamsList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.QueryParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_realtime_query_pb.QueryParameter, 5));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.QueryParameter>} value
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
*/
proto.loupe.services.hotspring.QueryRequest.prototype.setParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.realtime.QueryParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.QueryParameter}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.addParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.realtime.QueryParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
 */
proto.loupe.services.hotspring.QueryRequest.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};


/**
 * optional loupe.common.Actor actor = 6;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 6));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
*/
proto.loupe.services.hotspring.QueryRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
 */
proto.loupe.services.hotspring.QueryRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TenantSettingParams tenant_setting_params = 7;
 * @return {?proto.loupe.services.hotspring.TenantSettingParams}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.getTenantSettingParams = function() {
  return /** @type{?proto.loupe.services.hotspring.TenantSettingParams} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.TenantSettingParams, 7));
};


/**
 * @param {?proto.loupe.services.hotspring.TenantSettingParams|undefined} value
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
*/
proto.loupe.services.hotspring.QueryRequest.prototype.setTenantSettingParams = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
 */
proto.loupe.services.hotspring.QueryRequest.prototype.clearTenantSettingParams = function() {
  return this.setTenantSettingParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.hasTenantSettingParams = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional QueryTier tier = 8;
 * @return {!proto.loupe.services.hotspring.QueryTier}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.getTier = function() {
  return /** @type {!proto.loupe.services.hotspring.QueryTier} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.loupe.services.hotspring.QueryTier} value
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
 */
proto.loupe.services.hotspring.QueryRequest.prototype.setTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional QueryOptions query_options = 9;
 * @return {?proto.loupe.services.hotspring.QueryOptions}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.getQueryOptions = function() {
  return /** @type{?proto.loupe.services.hotspring.QueryOptions} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.QueryOptions, 9));
};


/**
 * @param {?proto.loupe.services.hotspring.QueryOptions|undefined} value
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
*/
proto.loupe.services.hotspring.QueryRequest.prototype.setQueryOptions = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
 */
proto.loupe.services.hotspring.QueryRequest.prototype.clearQueryOptions = function() {
  return this.setQueryOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.hasQueryOptions = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool preserve_sort = 10;
 * @return {boolean}
 */
proto.loupe.services.hotspring.QueryRequest.prototype.getPreserveSort = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.hotspring.QueryRequest} returns this
 */
proto.loupe.services.hotspring.QueryRequest.prototype.setPreserveSort = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.QueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.QueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.QueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.QueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: msg.getResult_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.QueryResponse}
 */
proto.loupe.services.hotspring.QueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.QueryResponse;
  return proto.loupe.services.hotspring.QueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.QueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.QueryResponse}
 */
proto.loupe.services.hotspring.QueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.QueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.QueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.QueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.QueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes result = 1;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.hotspring.QueryResponse.prototype.getResult = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes result = 1;
 * This is a type-conversion wrapper around `getResult()`
 * @return {string}
 */
proto.loupe.services.hotspring.QueryResponse.prototype.getResult_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getResult()));
};


/**
 * optional bytes result = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getResult()`
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.QueryResponse.prototype.getResult_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getResult()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.hotspring.QueryResponse} returns this
 */
proto.loupe.services.hotspring.QueryResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.MultiQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.MultiQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.MultiQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.MultiQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsMap: (f = msg.getResultsMap()) ? f.toObject(includeInstance, undefined) : [],
    errorsMap: (f = msg.getErrorsMap()) ? f.toObject(includeInstance, proto.loupe.services.hotspring.QueryError.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.MultiQueryResponse}
 */
proto.loupe.services.hotspring.MultiQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.MultiQueryResponse;
  return proto.loupe.services.hotspring.MultiQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.MultiQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.MultiQueryResponse}
 */
proto.loupe.services.hotspring.MultiQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getResultsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBytes, null, "", "");
         });
      break;
    case 2:
      var value = msg.getErrorsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.services.hotspring.QueryError.deserializeBinaryFromReader, "", new proto.loupe.services.hotspring.QueryError());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.MultiQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.MultiQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.MultiQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.MultiQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBytes);
  }
  f = message.getErrorsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.services.hotspring.QueryError.serializeBinaryToWriter);
  }
};


/**
 * map<string, bytes> results = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!(string|Uint8Array)>}
 */
proto.loupe.services.hotspring.MultiQueryResponse.prototype.getResultsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!(string|Uint8Array)>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.hotspring.MultiQueryResponse} returns this
 */
proto.loupe.services.hotspring.MultiQueryResponse.prototype.clearResultsMap = function() {
  this.getResultsMap().clear();
  return this;};


/**
 * map<string, QueryError> errors = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.services.hotspring.QueryError>}
 */
proto.loupe.services.hotspring.MultiQueryResponse.prototype.getErrorsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.services.hotspring.QueryError>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.loupe.services.hotspring.QueryError));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.hotspring.MultiQueryResponse} returns this
 */
proto.loupe.services.hotspring.MultiQueryResponse.prototype.clearErrorsMap = function() {
  this.getErrorsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.RunRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.RunRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.RunRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.RunRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.RunRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    namespace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    query: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paramsList: jspb.Message.toObjectList(msg.getParamsList(),
    src_common_realtime_query_pb.QueryParameter.toObject, includeInstance),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    tenantSettingParams: (f = msg.getTenantSettingParams()) && proto.loupe.services.hotspring.TenantSettingParams.toObject(includeInstance, f),
    tier: jspb.Message.getFieldWithDefault(msg, 8, 0),
    queryOptions: (f = msg.getQueryOptions()) && proto.loupe.services.hotspring.QueryOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.RunRequest}
 */
proto.loupe.services.hotspring.RunRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.RunRequest;
  return proto.loupe.services.hotspring.RunRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.RunRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.RunRequest}
 */
proto.loupe.services.hotspring.RunRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 5:
      var value = new src_common_realtime_query_pb.QueryParameter;
      reader.readMessage(value,src_common_realtime_query_pb.QueryParameter.deserializeBinaryFromReader);
      msg.addParams(value);
      break;
    case 6:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 7:
      var value = new proto.loupe.services.hotspring.TenantSettingParams;
      reader.readMessage(value,proto.loupe.services.hotspring.TenantSettingParams.deserializeBinaryFromReader);
      msg.setTenantSettingParams(value);
      break;
    case 8:
      var value = /** @type {!proto.loupe.services.hotspring.QueryTier} */ (reader.readEnum());
      msg.setTier(value);
      break;
    case 9:
      var value = new proto.loupe.services.hotspring.QueryOptions;
      reader.readMessage(value,proto.loupe.services.hotspring.QueryOptions.deserializeBinaryFromReader);
      msg.setQueryOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.RunRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.RunRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.RunRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.RunRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      src_common_realtime_query_pb.QueryParameter.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getTenantSettingParams();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.services.hotspring.TenantSettingParams.serializeBinaryToWriter
    );
  }
  f = message.getTier();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getQueryOptions();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.loupe.services.hotspring.QueryOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query_key = 1;
 * @return {string}
 */
proto.loupe.services.hotspring.RunRequest.prototype.getQueryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
 */
proto.loupe.services.hotspring.RunRequest.prototype.setQueryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string namespace = 2;
 * @return {string}
 */
proto.loupe.services.hotspring.RunRequest.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
 */
proto.loupe.services.hotspring.RunRequest.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.hotspring.RunRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
 */
proto.loupe.services.hotspring.RunRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string query = 4;
 * @return {string}
 */
proto.loupe.services.hotspring.RunRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
 */
proto.loupe.services.hotspring.RunRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated loupe.common.realtime.QueryParameter params = 5;
 * @return {!Array<!proto.loupe.common.realtime.QueryParameter>}
 */
proto.loupe.services.hotspring.RunRequest.prototype.getParamsList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.QueryParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_realtime_query_pb.QueryParameter, 5));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.QueryParameter>} value
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
*/
proto.loupe.services.hotspring.RunRequest.prototype.setParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.realtime.QueryParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.QueryParameter}
 */
proto.loupe.services.hotspring.RunRequest.prototype.addParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.realtime.QueryParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
 */
proto.loupe.services.hotspring.RunRequest.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};


/**
 * optional loupe.common.Actor actor = 6;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.RunRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 6));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
*/
proto.loupe.services.hotspring.RunRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
 */
proto.loupe.services.hotspring.RunRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TenantSettingParams tenant_setting_params = 7;
 * @return {?proto.loupe.services.hotspring.TenantSettingParams}
 */
proto.loupe.services.hotspring.RunRequest.prototype.getTenantSettingParams = function() {
  return /** @type{?proto.loupe.services.hotspring.TenantSettingParams} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.TenantSettingParams, 7));
};


/**
 * @param {?proto.loupe.services.hotspring.TenantSettingParams|undefined} value
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
*/
proto.loupe.services.hotspring.RunRequest.prototype.setTenantSettingParams = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
 */
proto.loupe.services.hotspring.RunRequest.prototype.clearTenantSettingParams = function() {
  return this.setTenantSettingParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunRequest.prototype.hasTenantSettingParams = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional QueryTier tier = 8;
 * @return {!proto.loupe.services.hotspring.QueryTier}
 */
proto.loupe.services.hotspring.RunRequest.prototype.getTier = function() {
  return /** @type {!proto.loupe.services.hotspring.QueryTier} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.loupe.services.hotspring.QueryTier} value
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
 */
proto.loupe.services.hotspring.RunRequest.prototype.setTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional QueryOptions query_options = 9;
 * @return {?proto.loupe.services.hotspring.QueryOptions}
 */
proto.loupe.services.hotspring.RunRequest.prototype.getQueryOptions = function() {
  return /** @type{?proto.loupe.services.hotspring.QueryOptions} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.QueryOptions, 9));
};


/**
 * @param {?proto.loupe.services.hotspring.QueryOptions|undefined} value
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
*/
proto.loupe.services.hotspring.RunRequest.prototype.setQueryOptions = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunRequest} returns this
 */
proto.loupe.services.hotspring.RunRequest.prototype.clearQueryOptions = function() {
  return this.setQueryOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunRequest.prototype.hasQueryOptions = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.RunQueryRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.RunQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.RunQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.RunQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    namespace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    query: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paramsList: jspb.Message.toObjectList(msg.getParamsList(),
    src_common_realtime_query_pb.QueryParameter.toObject, includeInstance),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    tenantSettingParams: (f = msg.getTenantSettingParams()) && proto.loupe.services.hotspring.TenantSettingParams.toObject(includeInstance, f),
    tier: jspb.Message.getFieldWithDefault(msg, 8, 0),
    queryOptions: (f = msg.getQueryOptions()) && proto.loupe.services.hotspring.QueryOptions.toObject(includeInstance, f),
    preserveSort: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.RunQueryRequest}
 */
proto.loupe.services.hotspring.RunQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.RunQueryRequest;
  return proto.loupe.services.hotspring.RunQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.RunQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.RunQueryRequest}
 */
proto.loupe.services.hotspring.RunQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 5:
      var value = new src_common_realtime_query_pb.QueryParameter;
      reader.readMessage(value,src_common_realtime_query_pb.QueryParameter.deserializeBinaryFromReader);
      msg.addParams(value);
      break;
    case 6:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 7:
      var value = new proto.loupe.services.hotspring.TenantSettingParams;
      reader.readMessage(value,proto.loupe.services.hotspring.TenantSettingParams.deserializeBinaryFromReader);
      msg.setTenantSettingParams(value);
      break;
    case 8:
      var value = /** @type {!proto.loupe.services.hotspring.QueryTier} */ (reader.readEnum());
      msg.setTier(value);
      break;
    case 9:
      var value = new proto.loupe.services.hotspring.QueryOptions;
      reader.readMessage(value,proto.loupe.services.hotspring.QueryOptions.deserializeBinaryFromReader);
      msg.setQueryOptions(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreserveSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.RunQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.RunQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.RunQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      src_common_realtime_query_pb.QueryParameter.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getTenantSettingParams();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.services.hotspring.TenantSettingParams.serializeBinaryToWriter
    );
  }
  f = message.getTier();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getQueryOptions();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.loupe.services.hotspring.QueryOptions.serializeBinaryToWriter
    );
  }
  f = message.getPreserveSort();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string query_key = 1;
 * @return {string}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.getQueryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.setQueryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string namespace = 2;
 * @return {string}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string query = 4;
 * @return {string}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated loupe.common.realtime.QueryParameter params = 5;
 * @return {!Array<!proto.loupe.common.realtime.QueryParameter>}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.getParamsList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.QueryParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_realtime_query_pb.QueryParameter, 5));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.QueryParameter>} value
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
*/
proto.loupe.services.hotspring.RunQueryRequest.prototype.setParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.realtime.QueryParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.QueryParameter}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.addParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.realtime.QueryParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};


/**
 * optional loupe.common.Actor actor = 6;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 6));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
*/
proto.loupe.services.hotspring.RunQueryRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TenantSettingParams tenant_setting_params = 7;
 * @return {?proto.loupe.services.hotspring.TenantSettingParams}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.getTenantSettingParams = function() {
  return /** @type{?proto.loupe.services.hotspring.TenantSettingParams} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.TenantSettingParams, 7));
};


/**
 * @param {?proto.loupe.services.hotspring.TenantSettingParams|undefined} value
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
*/
proto.loupe.services.hotspring.RunQueryRequest.prototype.setTenantSettingParams = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.clearTenantSettingParams = function() {
  return this.setTenantSettingParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.hasTenantSettingParams = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional QueryTier tier = 8;
 * @return {!proto.loupe.services.hotspring.QueryTier}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.getTier = function() {
  return /** @type {!proto.loupe.services.hotspring.QueryTier} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.loupe.services.hotspring.QueryTier} value
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.setTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional QueryOptions query_options = 9;
 * @return {?proto.loupe.services.hotspring.QueryOptions}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.getQueryOptions = function() {
  return /** @type{?proto.loupe.services.hotspring.QueryOptions} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.QueryOptions, 9));
};


/**
 * @param {?proto.loupe.services.hotspring.QueryOptions|undefined} value
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
*/
proto.loupe.services.hotspring.RunQueryRequest.prototype.setQueryOptions = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.clearQueryOptions = function() {
  return this.setQueryOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.hasQueryOptions = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool preserve_sort = 10;
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.getPreserveSort = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.hotspring.RunQueryRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryRequest.prototype.setPreserveSort = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.RunQueriesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.RunQueriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.RunQueriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.RunQueriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryKeysList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    namespace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paramsMap: (f = msg.getParamsMap()) ? f.toObject(includeInstance, proto.loupe.common.realtime.QueryParameters.toObject) : [],
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    tenantSettingParams: (f = msg.getTenantSettingParams()) && proto.loupe.services.hotspring.TenantSettingParams.toObject(includeInstance, f),
    tier: jspb.Message.getFieldWithDefault(msg, 7, 0),
    queryOptions: (f = msg.getQueryOptions()) && proto.loupe.services.hotspring.QueryOptions.toObject(includeInstance, f),
    preserveSort: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest}
 */
proto.loupe.services.hotspring.RunQueriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.RunQueriesRequest;
  return proto.loupe.services.hotspring.RunQueriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.RunQueriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest}
 */
proto.loupe.services.hotspring.RunQueriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addQueryKeys(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = msg.getParamsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.common.realtime.QueryParameters.deserializeBinaryFromReader, "", new proto.loupe.common.realtime.QueryParameters());
         });
      break;
    case 5:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 6:
      var value = new proto.loupe.services.hotspring.TenantSettingParams;
      reader.readMessage(value,proto.loupe.services.hotspring.TenantSettingParams.deserializeBinaryFromReader);
      msg.setTenantSettingParams(value);
      break;
    case 7:
      var value = /** @type {!proto.loupe.services.hotspring.QueryTier} */ (reader.readEnum());
      msg.setTier(value);
      break;
    case 8:
      var value = new proto.loupe.services.hotspring.QueryOptions;
      reader.readMessage(value,proto.loupe.services.hotspring.QueryOptions.deserializeBinaryFromReader);
      msg.setQueryOptions(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreserveSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.RunQueriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.RunQueriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.RunQueriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getParamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.common.realtime.QueryParameters.serializeBinaryToWriter);
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getTenantSettingParams();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loupe.services.hotspring.TenantSettingParams.serializeBinaryToWriter
    );
  }
  f = message.getTier();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getQueryOptions();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.loupe.services.hotspring.QueryOptions.serializeBinaryToWriter
    );
  }
  f = message.getPreserveSort();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * repeated string query_keys = 1;
 * @return {!Array<string>}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.getQueryKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.setQueryKeysList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.addQueryKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.clearQueryKeysList = function() {
  return this.setQueryKeysList([]);
};


/**
 * optional string namespace = 2;
 * @return {string}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, loupe.common.realtime.QueryParameters> params = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.common.realtime.QueryParameters>}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.getParamsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.common.realtime.QueryParameters>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.loupe.common.realtime.QueryParameters));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.clearParamsMap = function() {
  this.getParamsMap().clear();
  return this;};


/**
 * optional loupe.common.Actor actor = 5;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 5));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
*/
proto.loupe.services.hotspring.RunQueriesRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional TenantSettingParams tenant_setting_params = 6;
 * @return {?proto.loupe.services.hotspring.TenantSettingParams}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.getTenantSettingParams = function() {
  return /** @type{?proto.loupe.services.hotspring.TenantSettingParams} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.TenantSettingParams, 6));
};


/**
 * @param {?proto.loupe.services.hotspring.TenantSettingParams|undefined} value
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
*/
proto.loupe.services.hotspring.RunQueriesRequest.prototype.setTenantSettingParams = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.clearTenantSettingParams = function() {
  return this.setTenantSettingParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.hasTenantSettingParams = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional QueryTier tier = 7;
 * @return {!proto.loupe.services.hotspring.QueryTier}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.getTier = function() {
  return /** @type {!proto.loupe.services.hotspring.QueryTier} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.loupe.services.hotspring.QueryTier} value
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.setTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional QueryOptions query_options = 8;
 * @return {?proto.loupe.services.hotspring.QueryOptions}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.getQueryOptions = function() {
  return /** @type{?proto.loupe.services.hotspring.QueryOptions} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.QueryOptions, 8));
};


/**
 * @param {?proto.loupe.services.hotspring.QueryOptions|undefined} value
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
*/
proto.loupe.services.hotspring.RunQueriesRequest.prototype.setQueryOptions = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.clearQueryOptions = function() {
  return this.setQueryOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.hasQueryOptions = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool preserve_sort = 9;
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.getPreserveSort = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.hotspring.RunQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunQueriesRequest.prototype.setPreserveSort = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.repeatedFields_ = [1,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.RunFilteredQueriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryKeysList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    namespace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paramsMap: (f = msg.getParamsMap()) ? f.toObject(includeInstance, proto.loupe.common.realtime.QueryParameters.toObject) : [],
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.loupe.services.hotspring.QueryFilter.toObject, includeInstance),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    tenantSettingParams: (f = msg.getTenantSettingParams()) && proto.loupe.services.hotspring.TenantSettingParams.toObject(includeInstance, f),
    tier: jspb.Message.getFieldWithDefault(msg, 8, 0),
    queryOptions: (f = msg.getQueryOptions()) && proto.loupe.services.hotspring.QueryOptions.toObject(includeInstance, f),
    preserveSort: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.RunFilteredQueriesRequest;
  return proto.loupe.services.hotspring.RunFilteredQueriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addQueryKeys(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = msg.getParamsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.common.realtime.QueryParameters.deserializeBinaryFromReader, "", new proto.loupe.common.realtime.QueryParameters());
         });
      break;
    case 5:
      var value = new proto.loupe.services.hotspring.QueryFilter;
      reader.readMessage(value,proto.loupe.services.hotspring.QueryFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 6:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 7:
      var value = new proto.loupe.services.hotspring.TenantSettingParams;
      reader.readMessage(value,proto.loupe.services.hotspring.TenantSettingParams.deserializeBinaryFromReader);
      msg.setTenantSettingParams(value);
      break;
    case 8:
      var value = /** @type {!proto.loupe.services.hotspring.QueryTier} */ (reader.readEnum());
      msg.setTier(value);
      break;
    case 9:
      var value = new proto.loupe.services.hotspring.QueryOptions;
      reader.readMessage(value,proto.loupe.services.hotspring.QueryOptions.deserializeBinaryFromReader);
      msg.setQueryOptions(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreserveSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.RunFilteredQueriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getParamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.common.realtime.QueryParameters.serializeBinaryToWriter);
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.loupe.services.hotspring.QueryFilter.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getTenantSettingParams();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.services.hotspring.TenantSettingParams.serializeBinaryToWriter
    );
  }
  f = message.getTier();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getQueryOptions();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.loupe.services.hotspring.QueryOptions.serializeBinaryToWriter
    );
  }
  f = message.getPreserveSort();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * repeated string query_keys = 1;
 * @return {!Array<string>}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.getQueryKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.setQueryKeysList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.addQueryKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.clearQueryKeysList = function() {
  return this.setQueryKeysList([]);
};


/**
 * optional string namespace = 2;
 * @return {string}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, loupe.common.realtime.QueryParameters> params = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.common.realtime.QueryParameters>}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.getParamsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.common.realtime.QueryParameters>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.loupe.common.realtime.QueryParameters));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.clearParamsMap = function() {
  this.getParamsMap().clear();
  return this;};


/**
 * repeated QueryFilter filters = 5;
 * @return {!Array<!proto.loupe.services.hotspring.QueryFilter>}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.loupe.services.hotspring.QueryFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.hotspring.QueryFilter, 5));
};


/**
 * @param {!Array<!proto.loupe.services.hotspring.QueryFilter>} value
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
*/
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.services.hotspring.QueryFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.hotspring.QueryFilter}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.services.hotspring.QueryFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional loupe.common.Actor actor = 6;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 6));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
*/
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TenantSettingParams tenant_setting_params = 7;
 * @return {?proto.loupe.services.hotspring.TenantSettingParams}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.getTenantSettingParams = function() {
  return /** @type{?proto.loupe.services.hotspring.TenantSettingParams} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.TenantSettingParams, 7));
};


/**
 * @param {?proto.loupe.services.hotspring.TenantSettingParams|undefined} value
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
*/
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.setTenantSettingParams = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.clearTenantSettingParams = function() {
  return this.setTenantSettingParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.hasTenantSettingParams = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional QueryTier tier = 8;
 * @return {!proto.loupe.services.hotspring.QueryTier}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.getTier = function() {
  return /** @type {!proto.loupe.services.hotspring.QueryTier} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.loupe.services.hotspring.QueryTier} value
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.setTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional QueryOptions query_options = 9;
 * @return {?proto.loupe.services.hotspring.QueryOptions}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.getQueryOptions = function() {
  return /** @type{?proto.loupe.services.hotspring.QueryOptions} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.QueryOptions, 9));
};


/**
 * @param {?proto.loupe.services.hotspring.QueryOptions|undefined} value
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
*/
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.setQueryOptions = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.clearQueryOptions = function() {
  return this.setQueryOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.hasQueryOptions = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool preserve_sort = 10;
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.getPreserveSort = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.hotspring.RunFilteredQueriesRequest} returns this
 */
proto.loupe.services.hotspring.RunFilteredQueriesRequest.prototype.setPreserveSort = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.GetQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.GetQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.GetQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.GetQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    namespace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.GetQueryRequest}
 */
proto.loupe.services.hotspring.GetQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.GetQueryRequest;
  return proto.loupe.services.hotspring.GetQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.GetQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.GetQueryRequest}
 */
proto.loupe.services.hotspring.GetQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 3:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.GetQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.GetQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.GetQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.GetQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query_key = 1;
 * @return {string}
 */
proto.loupe.services.hotspring.GetQueryRequest.prototype.getQueryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.GetQueryRequest} returns this
 */
proto.loupe.services.hotspring.GetQueryRequest.prototype.setQueryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string namespace = 2;
 * @return {string}
 */
proto.loupe.services.hotspring.GetQueryRequest.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.GetQueryRequest} returns this
 */
proto.loupe.services.hotspring.GetQueryRequest.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.Actor actor = 3;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.GetQueryRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 3));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.GetQueryRequest} returns this
*/
proto.loupe.services.hotspring.GetQueryRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.GetQueryRequest} returns this
 */
proto.loupe.services.hotspring.GetQueryRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.GetQueryRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.SearchQueriesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.SearchQueriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.SearchQueriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SearchQueriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.loupe.services.hotspring.QueryFilter.toObject, includeInstance),
    order: (f = msg.getOrder()) && proto.loupe.services.hotspring.QueryOrder.toObject(includeInstance, f),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.SearchQueriesRequest}
 */
proto.loupe.services.hotspring.SearchQueriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.SearchQueriesRequest;
  return proto.loupe.services.hotspring.SearchQueriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.SearchQueriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.SearchQueriesRequest}
 */
proto.loupe.services.hotspring.SearchQueriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.hotspring.QueryFilter;
      reader.readMessage(value,proto.loupe.services.hotspring.QueryFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 2:
      var value = new proto.loupe.services.hotspring.QueryOrder;
      reader.readMessage(value,proto.loupe.services.hotspring.QueryOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 5:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.SearchQueriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.SearchQueriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SearchQueriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.hotspring.QueryFilter.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.hotspring.QueryOrder.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QueryFilter filters = 1;
 * @return {!Array<!proto.loupe.services.hotspring.QueryFilter>}
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.loupe.services.hotspring.QueryFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.hotspring.QueryFilter, 1));
};


/**
 * @param {!Array<!proto.loupe.services.hotspring.QueryFilter>} value
 * @return {!proto.loupe.services.hotspring.SearchQueriesRequest} returns this
*/
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.hotspring.QueryFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.hotspring.QueryFilter}
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.hotspring.QueryFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.SearchQueriesRequest} returns this
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional QueryOrder order = 2;
 * @return {?proto.loupe.services.hotspring.QueryOrder}
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.getOrder = function() {
  return /** @type{?proto.loupe.services.hotspring.QueryOrder} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.QueryOrder, 2));
};


/**
 * @param {?proto.loupe.services.hotspring.QueryOrder|undefined} value
 * @return {!proto.loupe.services.hotspring.SearchQueriesRequest} returns this
*/
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.SearchQueriesRequest} returns this
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.hotspring.SearchQueriesRequest} returns this
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string page_token = 4;
 * @return {string}
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.SearchQueriesRequest} returns this
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional loupe.common.Actor actor = 5;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 5));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.SearchQueriesRequest} returns this
*/
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.SearchQueriesRequest} returns this
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.SearchQueriesRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.QueryFilter.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.QueryFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.QueryFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.QueryFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.QueryFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    op: jspb.Message.getFieldWithDefault(msg, 2, 0),
    valueList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.QueryFilter}
 */
proto.loupe.services.hotspring.QueryFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.QueryFilter;
  return proto.loupe.services.hotspring.QueryFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.QueryFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.QueryFilter}
 */
proto.loupe.services.hotspring.QueryFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.realtime.QueryField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.realtime.ComparisonOp} */ (reader.readEnum());
      msg.setOp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.QueryFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.QueryFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.QueryFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.QueryFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getValueList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional loupe.common.realtime.QueryField field = 1;
 * @return {!proto.loupe.common.realtime.QueryField}
 */
proto.loupe.services.hotspring.QueryFilter.prototype.getField = function() {
  return /** @type {!proto.loupe.common.realtime.QueryField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.realtime.QueryField} value
 * @return {!proto.loupe.services.hotspring.QueryFilter} returns this
 */
proto.loupe.services.hotspring.QueryFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional loupe.common.realtime.ComparisonOp op = 2;
 * @return {!proto.loupe.common.realtime.ComparisonOp}
 */
proto.loupe.services.hotspring.QueryFilter.prototype.getOp = function() {
  return /** @type {!proto.loupe.common.realtime.ComparisonOp} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.realtime.ComparisonOp} value
 * @return {!proto.loupe.services.hotspring.QueryFilter} returns this
 */
proto.loupe.services.hotspring.QueryFilter.prototype.setOp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated string value = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.hotspring.QueryFilter.prototype.getValueList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.hotspring.QueryFilter} returns this
 */
proto.loupe.services.hotspring.QueryFilter.prototype.setValueList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.hotspring.QueryFilter} returns this
 */
proto.loupe.services.hotspring.QueryFilter.prototype.addValue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.QueryFilter} returns this
 */
proto.loupe.services.hotspring.QueryFilter.prototype.clearValueList = function() {
  return this.setValueList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.QueryOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.QueryOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.QueryOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.QueryOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.QueryOrder}
 */
proto.loupe.services.hotspring.QueryOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.QueryOrder;
  return proto.loupe.services.hotspring.QueryOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.QueryOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.QueryOrder}
 */
proto.loupe.services.hotspring.QueryOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.realtime.QueryField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.realtime.OrderDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.QueryOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.QueryOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.QueryOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.QueryOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional loupe.common.realtime.QueryField field = 1;
 * @return {!proto.loupe.common.realtime.QueryField}
 */
proto.loupe.services.hotspring.QueryOrder.prototype.getField = function() {
  return /** @type {!proto.loupe.common.realtime.QueryField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.realtime.QueryField} value
 * @return {!proto.loupe.services.hotspring.QueryOrder} returns this
 */
proto.loupe.services.hotspring.QueryOrder.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional loupe.common.realtime.OrderDirection direction = 2;
 * @return {!proto.loupe.common.realtime.OrderDirection}
 */
proto.loupe.services.hotspring.QueryOrder.prototype.getDirection = function() {
  return /** @type {!proto.loupe.common.realtime.OrderDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.realtime.OrderDirection} value
 * @return {!proto.loupe.services.hotspring.QueryOrder} returns this
 */
proto.loupe.services.hotspring.QueryOrder.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.SearchQueriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.SearchQueriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.SearchQueriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.SearchQueriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SearchQueriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    queriesList: jspb.Message.toObjectList(msg.getQueriesList(),
    src_common_realtime_query_pb.Query.toObject, includeInstance),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.SearchQueriesResponse}
 */
proto.loupe.services.hotspring.SearchQueriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.SearchQueriesResponse;
  return proto.loupe.services.hotspring.SearchQueriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.SearchQueriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.SearchQueriesResponse}
 */
proto.loupe.services.hotspring.SearchQueriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_realtime_query_pb.Query;
      reader.readMessage(value,src_common_realtime_query_pb.Query.deserializeBinaryFromReader);
      msg.addQueries(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.SearchQueriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.SearchQueriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.SearchQueriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SearchQueriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_realtime_query_pb.Query.serializeBinaryToWriter
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated loupe.common.realtime.Query queries = 1;
 * @return {!Array<!proto.loupe.common.realtime.Query>}
 */
proto.loupe.services.hotspring.SearchQueriesResponse.prototype.getQueriesList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.Query>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_realtime_query_pb.Query, 1));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.Query>} value
 * @return {!proto.loupe.services.hotspring.SearchQueriesResponse} returns this
*/
proto.loupe.services.hotspring.SearchQueriesResponse.prototype.setQueriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.realtime.Query=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.Query}
 */
proto.loupe.services.hotspring.SearchQueriesResponse.prototype.addQueries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.realtime.Query, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.SearchQueriesResponse} returns this
 */
proto.loupe.services.hotspring.SearchQueriesResponse.prototype.clearQueriesList = function() {
  return this.setQueriesList([]);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.loupe.services.hotspring.SearchQueriesResponse.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.SearchQueriesResponse} returns this
 */
proto.loupe.services.hotspring.SearchQueriesResponse.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.GetUserQueryParametersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.GetUserQueryParametersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    queryKeysList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersRequest}
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.GetUserQueryParametersRequest;
  return proto.loupe.services.hotspring.GetUserQueryParametersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.GetUserQueryParametersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersRequest}
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addQueryKeys(value);
      break;
    case 5:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.GetUserQueryParametersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.GetUserQueryParametersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQueryKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string query_keys = 4;
 * @return {!Array<string>}
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.getQueryKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.setQueryKeysList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.addQueryKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.clearQueryKeysList = function() {
  return this.setQueryKeysList([]);
};


/**
 * optional loupe.common.Actor actor = 5;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 5));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersRequest} returns this
*/
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.GetUserQueryParametersRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.GetUserQueryParametersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.GetUserQueryParametersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.GetUserQueryParametersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.GetUserQueryParametersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.GetUserQueryParametersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryParametersList: jspb.Message.toObjectList(msg.getQueryParametersList(),
    src_common_realtime_query_pb.UserQueryParameters.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersResponse}
 */
proto.loupe.services.hotspring.GetUserQueryParametersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.GetUserQueryParametersResponse;
  return proto.loupe.services.hotspring.GetUserQueryParametersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.GetUserQueryParametersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersResponse}
 */
proto.loupe.services.hotspring.GetUserQueryParametersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_realtime_query_pb.UserQueryParameters;
      reader.readMessage(value,src_common_realtime_query_pb.UserQueryParameters.deserializeBinaryFromReader);
      msg.addQueryParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.GetUserQueryParametersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.GetUserQueryParametersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.GetUserQueryParametersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.GetUserQueryParametersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_realtime_query_pb.UserQueryParameters.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.realtime.UserQueryParameters query_parameters = 1;
 * @return {!Array<!proto.loupe.common.realtime.UserQueryParameters>}
 */
proto.loupe.services.hotspring.GetUserQueryParametersResponse.prototype.getQueryParametersList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.UserQueryParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_realtime_query_pb.UserQueryParameters, 1));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.UserQueryParameters>} value
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersResponse} returns this
*/
proto.loupe.services.hotspring.GetUserQueryParametersResponse.prototype.setQueryParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.realtime.UserQueryParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.UserQueryParameters}
 */
proto.loupe.services.hotspring.GetUserQueryParametersResponse.prototype.addQueryParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.realtime.UserQueryParameters, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.GetUserQueryParametersResponse} returns this
 */
proto.loupe.services.hotspring.GetUserQueryParametersResponse.prototype.clearQueryParametersList = function() {
  return this.setQueryParametersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.SearchUserQueryParametersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.loupe.services.hotspring.SavedQueryParametersFilter.toObject, includeInstance),
    order: (f = msg.getOrder()) && proto.loupe.services.hotspring.SavedQueryParametersOrder.toObject(includeInstance, f),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.SearchUserQueryParametersRequest;
  return proto.loupe.services.hotspring.SearchUserQueryParametersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.hotspring.SavedQueryParametersFilter;
      reader.readMessage(value,proto.loupe.services.hotspring.SavedQueryParametersFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 2:
      var value = new proto.loupe.services.hotspring.SavedQueryParametersOrder;
      reader.readMessage(value,proto.loupe.services.hotspring.SavedQueryParametersOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 5:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.SearchUserQueryParametersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.hotspring.SavedQueryParametersFilter.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.hotspring.SavedQueryParametersOrder.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SavedQueryParametersFilter filters = 1;
 * @return {!Array<!proto.loupe.services.hotspring.SavedQueryParametersFilter>}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.loupe.services.hotspring.SavedQueryParametersFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.hotspring.SavedQueryParametersFilter, 1));
};


/**
 * @param {!Array<!proto.loupe.services.hotspring.SavedQueryParametersFilter>} value
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest} returns this
*/
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.hotspring.SavedQueryParametersFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.hotspring.SavedQueryParametersFilter}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.hotspring.SavedQueryParametersFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional SavedQueryParametersOrder order = 2;
 * @return {?proto.loupe.services.hotspring.SavedQueryParametersOrder}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.getOrder = function() {
  return /** @type{?proto.loupe.services.hotspring.SavedQueryParametersOrder} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.SavedQueryParametersOrder, 2));
};


/**
 * @param {?proto.loupe.services.hotspring.SavedQueryParametersOrder|undefined} value
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest} returns this
*/
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string page_token = 4;
 * @return {string}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional loupe.common.Actor actor = 5;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 5));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest} returns this
*/
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.SavedQueryParametersFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.SavedQueryParametersFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    op: jspb.Message.getFieldWithDefault(msg, 2, 0),
    valueList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.SavedQueryParametersFilter}
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.SavedQueryParametersFilter;
  return proto.loupe.services.hotspring.SavedQueryParametersFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.SavedQueryParametersFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.SavedQueryParametersFilter}
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.realtime.UserQueryParametersField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.realtime.ComparisonOp} */ (reader.readEnum());
      msg.setOp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.SavedQueryParametersFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.SavedQueryParametersFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getValueList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional loupe.common.realtime.UserQueryParametersField field = 1;
 * @return {!proto.loupe.common.realtime.UserQueryParametersField}
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.prototype.getField = function() {
  return /** @type {!proto.loupe.common.realtime.UserQueryParametersField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.realtime.UserQueryParametersField} value
 * @return {!proto.loupe.services.hotspring.SavedQueryParametersFilter} returns this
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional loupe.common.realtime.ComparisonOp op = 2;
 * @return {!proto.loupe.common.realtime.ComparisonOp}
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.prototype.getOp = function() {
  return /** @type {!proto.loupe.common.realtime.ComparisonOp} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.realtime.ComparisonOp} value
 * @return {!proto.loupe.services.hotspring.SavedQueryParametersFilter} returns this
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.prototype.setOp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated string value = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.prototype.getValueList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.hotspring.SavedQueryParametersFilter} returns this
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.prototype.setValueList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.hotspring.SavedQueryParametersFilter} returns this
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.prototype.addValue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.SavedQueryParametersFilter} returns this
 */
proto.loupe.services.hotspring.SavedQueryParametersFilter.prototype.clearValueList = function() {
  return this.setValueList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.SavedQueryParametersOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.SavedQueryParametersOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.SavedQueryParametersOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SavedQueryParametersOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.SavedQueryParametersOrder}
 */
proto.loupe.services.hotspring.SavedQueryParametersOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.SavedQueryParametersOrder;
  return proto.loupe.services.hotspring.SavedQueryParametersOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.SavedQueryParametersOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.SavedQueryParametersOrder}
 */
proto.loupe.services.hotspring.SavedQueryParametersOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.realtime.UserQueryParametersField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.realtime.OrderDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.SavedQueryParametersOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.SavedQueryParametersOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.SavedQueryParametersOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SavedQueryParametersOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional loupe.common.realtime.UserQueryParametersField field = 1;
 * @return {!proto.loupe.common.realtime.UserQueryParametersField}
 */
proto.loupe.services.hotspring.SavedQueryParametersOrder.prototype.getField = function() {
  return /** @type {!proto.loupe.common.realtime.UserQueryParametersField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.realtime.UserQueryParametersField} value
 * @return {!proto.loupe.services.hotspring.SavedQueryParametersOrder} returns this
 */
proto.loupe.services.hotspring.SavedQueryParametersOrder.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional loupe.common.realtime.OrderDirection direction = 2;
 * @return {!proto.loupe.common.realtime.OrderDirection}
 */
proto.loupe.services.hotspring.SavedQueryParametersOrder.prototype.getDirection = function() {
  return /** @type {!proto.loupe.common.realtime.OrderDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.realtime.OrderDirection} value
 * @return {!proto.loupe.services.hotspring.SavedQueryParametersOrder} returns this
 */
proto.loupe.services.hotspring.SavedQueryParametersOrder.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.SearchUserQueryParametersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.SearchUserQueryParametersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryParametersList: jspb.Message.toObjectList(msg.getQueryParametersList(),
    src_common_realtime_query_pb.UserQueryParameters.toObject, includeInstance),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersResponse}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.SearchUserQueryParametersResponse;
  return proto.loupe.services.hotspring.SearchUserQueryParametersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.SearchUserQueryParametersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersResponse}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_realtime_query_pb.UserQueryParameters;
      reader.readMessage(value,src_common_realtime_query_pb.UserQueryParameters.deserializeBinaryFromReader);
      msg.addQueryParameters(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.SearchUserQueryParametersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.SearchUserQueryParametersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_realtime_query_pb.UserQueryParameters.serializeBinaryToWriter
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated loupe.common.realtime.UserQueryParameters query_parameters = 1;
 * @return {!Array<!proto.loupe.common.realtime.UserQueryParameters>}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.prototype.getQueryParametersList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.UserQueryParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_realtime_query_pb.UserQueryParameters, 1));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.UserQueryParameters>} value
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersResponse} returns this
*/
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.prototype.setQueryParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.realtime.UserQueryParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.UserQueryParameters}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.prototype.addQueryParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.realtime.UserQueryParameters, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersResponse} returns this
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.prototype.clearQueryParametersList = function() {
  return this.setQueryParametersList([]);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.SearchUserQueryParametersResponse} returns this
 */
proto.loupe.services.hotspring.SearchUserQueryParametersResponse.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.SaveQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.SaveQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.SaveQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SaveQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: (f = msg.getQuery()) && src_common_realtime_query_pb.Query.toObject(includeInstance, f),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.SaveQueryRequest}
 */
proto.loupe.services.hotspring.SaveQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.SaveQueryRequest;
  return proto.loupe.services.hotspring.SaveQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.SaveQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.SaveQueryRequest}
 */
proto.loupe.services.hotspring.SaveQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_realtime_query_pb.Query;
      reader.readMessage(value,src_common_realtime_query_pb.Query.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    case 2:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.SaveQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.SaveQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.SaveQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SaveQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_realtime_query_pb.Query.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.realtime.Query query = 1;
 * @return {?proto.loupe.common.realtime.Query}
 */
proto.loupe.services.hotspring.SaveQueryRequest.prototype.getQuery = function() {
  return /** @type{?proto.loupe.common.realtime.Query} */ (
    jspb.Message.getWrapperField(this, src_common_realtime_query_pb.Query, 1));
};


/**
 * @param {?proto.loupe.common.realtime.Query|undefined} value
 * @return {!proto.loupe.services.hotspring.SaveQueryRequest} returns this
*/
proto.loupe.services.hotspring.SaveQueryRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.SaveQueryRequest} returns this
 */
proto.loupe.services.hotspring.SaveQueryRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.SaveQueryRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.Actor actor = 2;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.SaveQueryRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 2));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.SaveQueryRequest} returns this
*/
proto.loupe.services.hotspring.SaveQueryRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.SaveQueryRequest} returns this
 */
proto.loupe.services.hotspring.SaveQueryRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.SaveQueryRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.SaveQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.SaveQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.SaveQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SaveQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: (f = msg.getQuery()) && src_common_realtime_query_pb.Query.toObject(includeInstance, f),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.SaveQueryResponse}
 */
proto.loupe.services.hotspring.SaveQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.SaveQueryResponse;
  return proto.loupe.services.hotspring.SaveQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.SaveQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.SaveQueryResponse}
 */
proto.loupe.services.hotspring.SaveQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_realtime_query_pb.Query;
      reader.readMessage(value,src_common_realtime_query_pb.Query.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    case 2:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.SaveQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.SaveQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.SaveQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SaveQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_realtime_query_pb.Query.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.realtime.Query query = 1;
 * @return {?proto.loupe.common.realtime.Query}
 */
proto.loupe.services.hotspring.SaveQueryResponse.prototype.getQuery = function() {
  return /** @type{?proto.loupe.common.realtime.Query} */ (
    jspb.Message.getWrapperField(this, src_common_realtime_query_pb.Query, 1));
};


/**
 * @param {?proto.loupe.common.realtime.Query|undefined} value
 * @return {!proto.loupe.services.hotspring.SaveQueryResponse} returns this
*/
proto.loupe.services.hotspring.SaveQueryResponse.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.SaveQueryResponse} returns this
 */
proto.loupe.services.hotspring.SaveQueryResponse.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.SaveQueryResponse.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.Actor actor = 2;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.SaveQueryResponse.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 2));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.SaveQueryResponse} returns this
*/
proto.loupe.services.hotspring.SaveQueryResponse.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.SaveQueryResponse} returns this
 */
proto.loupe.services.hotspring.SaveQueryResponse.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.SaveQueryResponse.prototype.hasActor = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.SaveUserQueryParametersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.SaveUserQueryParametersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryParameters: (f = msg.getQueryParameters()) && src_common_realtime_query_pb.UserQueryParameters.toObject(includeInstance, f),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.SaveUserQueryParametersRequest}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.SaveUserQueryParametersRequest;
  return proto.loupe.services.hotspring.SaveUserQueryParametersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.SaveUserQueryParametersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.SaveUserQueryParametersRequest}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_realtime_query_pb.UserQueryParameters;
      reader.readMessage(value,src_common_realtime_query_pb.UserQueryParameters.deserializeBinaryFromReader);
      msg.setQueryParameters(value);
      break;
    case 2:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.SaveUserQueryParametersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.SaveUserQueryParametersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_realtime_query_pb.UserQueryParameters.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.realtime.UserQueryParameters query_parameters = 1;
 * @return {?proto.loupe.common.realtime.UserQueryParameters}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.prototype.getQueryParameters = function() {
  return /** @type{?proto.loupe.common.realtime.UserQueryParameters} */ (
    jspb.Message.getWrapperField(this, src_common_realtime_query_pb.UserQueryParameters, 1));
};


/**
 * @param {?proto.loupe.common.realtime.UserQueryParameters|undefined} value
 * @return {!proto.loupe.services.hotspring.SaveUserQueryParametersRequest} returns this
*/
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.prototype.setQueryParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.SaveUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.prototype.clearQueryParameters = function() {
  return this.setQueryParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.prototype.hasQueryParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.Actor actor = 2;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 2));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.SaveUserQueryParametersRequest} returns this
*/
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.SaveUserQueryParametersRequest} returns this
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.SaveUserQueryParametersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.SaveUserQueryParametersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryParameters: (f = msg.getQueryParameters()) && src_common_realtime_query_pb.UserQueryParameters.toObject(includeInstance, f),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.SaveUserQueryParametersResponse}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.SaveUserQueryParametersResponse;
  return proto.loupe.services.hotspring.SaveUserQueryParametersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.SaveUserQueryParametersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.SaveUserQueryParametersResponse}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_realtime_query_pb.UserQueryParameters;
      reader.readMessage(value,src_common_realtime_query_pb.UserQueryParameters.deserializeBinaryFromReader);
      msg.setQueryParameters(value);
      break;
    case 2:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.SaveUserQueryParametersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.SaveUserQueryParametersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_realtime_query_pb.UserQueryParameters.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.realtime.UserQueryParameters query_parameters = 1;
 * @return {?proto.loupe.common.realtime.UserQueryParameters}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.prototype.getQueryParameters = function() {
  return /** @type{?proto.loupe.common.realtime.UserQueryParameters} */ (
    jspb.Message.getWrapperField(this, src_common_realtime_query_pb.UserQueryParameters, 1));
};


/**
 * @param {?proto.loupe.common.realtime.UserQueryParameters|undefined} value
 * @return {!proto.loupe.services.hotspring.SaveUserQueryParametersResponse} returns this
*/
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.prototype.setQueryParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.SaveUserQueryParametersResponse} returns this
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.prototype.clearQueryParameters = function() {
  return this.setQueryParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.prototype.hasQueryParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.Actor actor = 2;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 2));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.SaveUserQueryParametersResponse} returns this
*/
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.SaveUserQueryParametersResponse} returns this
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.SaveUserQueryParametersResponse.prototype.hasActor = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.RunQueryObjectDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    namespace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paramsList: jspb.Message.toObjectList(msg.getParamsList(),
    src_common_realtime_query_pb.QueryParameter.toObject, includeInstance),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    groupFiltersList: jspb.Message.toObjectList(msg.getGroupFiltersList(),
    proto.loupe.services.hotspring.GroupedObjectFilter.toObject, includeInstance),
    offset: jspb.Message.getFieldWithDefault(msg, 7, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 8, 0),
    tenantSettingParams: (f = msg.getTenantSettingParams()) && proto.loupe.services.hotspring.TenantSettingParams.toObject(includeInstance, f),
    tier: jspb.Message.getFieldWithDefault(msg, 10, 0),
    queryOptions: (f = msg.getQueryOptions()) && proto.loupe.services.hotspring.QueryOptions.toObject(includeInstance, f),
    preserveSort: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.RunQueryObjectDataRequest;
  return proto.loupe.services.hotspring.RunQueryObjectDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = new src_common_realtime_query_pb.QueryParameter;
      reader.readMessage(value,src_common_realtime_query_pb.QueryParameter.deserializeBinaryFromReader);
      msg.addParams(value);
      break;
    case 5:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 6:
      var value = new proto.loupe.services.hotspring.GroupedObjectFilter;
      reader.readMessage(value,proto.loupe.services.hotspring.GroupedObjectFilter.deserializeBinaryFromReader);
      msg.addGroupFilters(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 9:
      var value = new proto.loupe.services.hotspring.TenantSettingParams;
      reader.readMessage(value,proto.loupe.services.hotspring.TenantSettingParams.deserializeBinaryFromReader);
      msg.setTenantSettingParams(value);
      break;
    case 10:
      var value = /** @type {!proto.loupe.services.hotspring.QueryTier} */ (reader.readEnum());
      msg.setTier(value);
      break;
    case 11:
      var value = new proto.loupe.services.hotspring.QueryOptions;
      reader.readMessage(value,proto.loupe.services.hotspring.QueryOptions.deserializeBinaryFromReader);
      msg.setQueryOptions(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreserveSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.RunQueryObjectDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      src_common_realtime_query_pb.QueryParameter.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getGroupFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.loupe.services.hotspring.GroupedObjectFilter.serializeBinaryToWriter
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTenantSettingParams();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.loupe.services.hotspring.TenantSettingParams.serializeBinaryToWriter
    );
  }
  f = message.getTier();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getQueryOptions();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.loupe.services.hotspring.QueryOptions.serializeBinaryToWriter
    );
  }
  f = message.getPreserveSort();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional string query_key = 1;
 * @return {string}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.getQueryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.setQueryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string namespace = 2;
 * @return {string}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated loupe.common.realtime.QueryParameter params = 4;
 * @return {!Array<!proto.loupe.common.realtime.QueryParameter>}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.getParamsList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.QueryParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_realtime_query_pb.QueryParameter, 4));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.QueryParameter>} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
*/
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.setParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.realtime.QueryParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.QueryParameter}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.addParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.realtime.QueryParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};


/**
 * optional loupe.common.Actor actor = 5;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 5));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
*/
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated GroupedObjectFilter group_filters = 6;
 * @return {!Array<!proto.loupe.services.hotspring.GroupedObjectFilter>}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.getGroupFiltersList = function() {
  return /** @type{!Array<!proto.loupe.services.hotspring.GroupedObjectFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.hotspring.GroupedObjectFilter, 6));
};


/**
 * @param {!Array<!proto.loupe.services.hotspring.GroupedObjectFilter>} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
*/
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.setGroupFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.services.hotspring.GroupedObjectFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.hotspring.GroupedObjectFilter}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.addGroupFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.services.hotspring.GroupedObjectFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.clearGroupFiltersList = function() {
  return this.setGroupFiltersList([]);
};


/**
 * optional int32 offset = 7;
 * @return {number}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 limit = 8;
 * @return {number}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional TenantSettingParams tenant_setting_params = 9;
 * @return {?proto.loupe.services.hotspring.TenantSettingParams}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.getTenantSettingParams = function() {
  return /** @type{?proto.loupe.services.hotspring.TenantSettingParams} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.TenantSettingParams, 9));
};


/**
 * @param {?proto.loupe.services.hotspring.TenantSettingParams|undefined} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
*/
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.setTenantSettingParams = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.clearTenantSettingParams = function() {
  return this.setTenantSettingParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.hasTenantSettingParams = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional QueryTier tier = 10;
 * @return {!proto.loupe.services.hotspring.QueryTier}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.getTier = function() {
  return /** @type {!proto.loupe.services.hotspring.QueryTier} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.loupe.services.hotspring.QueryTier} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.setTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional QueryOptions query_options = 11;
 * @return {?proto.loupe.services.hotspring.QueryOptions}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.getQueryOptions = function() {
  return /** @type{?proto.loupe.services.hotspring.QueryOptions} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.hotspring.QueryOptions, 11));
};


/**
 * @param {?proto.loupe.services.hotspring.QueryOptions|undefined} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
*/
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.setQueryOptions = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.clearQueryOptions = function() {
  return this.setQueryOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.hasQueryOptions = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool preserve_sort = 12;
 * @return {boolean}
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.getPreserveSort = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataRequest} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataRequest.prototype.setPreserveSort = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.GroupedObjectFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.GroupedObjectFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.GroupedObjectFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.GroupedObjectFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.GroupedObjectFilter}
 */
proto.loupe.services.hotspring.GroupedObjectFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.GroupedObjectFilter;
  return proto.loupe.services.hotspring.GroupedObjectFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.GroupedObjectFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.GroupedObjectFilter}
 */
proto.loupe.services.hotspring.GroupedObjectFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.GroupedObjectFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.GroupedObjectFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.GroupedObjectFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.GroupedObjectFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.loupe.services.hotspring.GroupedObjectFilter.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.GroupedObjectFilter} returns this
 */
proto.loupe.services.hotspring.GroupedObjectFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.loupe.services.hotspring.GroupedObjectFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.hotspring.GroupedObjectFilter} returns this
 */
proto.loupe.services.hotspring.GroupedObjectFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.RunQueryObjectDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.RunQueryObjectDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectData: msg.getObjectData_asB64(),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataResponse}
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.RunQueryObjectDataResponse;
  return proto.loupe.services.hotspring.RunQueryObjectDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.RunQueryObjectDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataResponse}
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setObjectData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.RunQueryObjectDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.RunQueryObjectDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bytes object_data = 1;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.prototype.getObjectData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes object_data = 1;
 * This is a type-conversion wrapper around `getObjectData()`
 * @return {string}
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.prototype.getObjectData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getObjectData()));
};


/**
 * optional bytes object_data = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getObjectData()`
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.prototype.getObjectData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getObjectData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataResponse} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.prototype.setObjectData = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataResponse} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.hotspring.RunQueryObjectDataResponse} returns this
 */
proto.loupe.services.hotspring.RunQueryObjectDataResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.hotspring.QueryOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.hotspring.QueryOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.hotspring.QueryOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.QueryOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    noQueryRewrite: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    interpreterMode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.hotspring.QueryOptions}
 */
proto.loupe.services.hotspring.QueryOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.hotspring.QueryOptions;
  return proto.loupe.services.hotspring.QueryOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.hotspring.QueryOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.hotspring.QueryOptions}
 */
proto.loupe.services.hotspring.QueryOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoQueryRewrite(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.services.hotspring.QueryInterpreterMode} */ (reader.readEnum());
      msg.setInterpreterMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.hotspring.QueryOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.hotspring.QueryOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.hotspring.QueryOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.hotspring.QueryOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNoQueryRewrite();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getInterpreterMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional bool no_query_rewrite = 1;
 * @return {boolean}
 */
proto.loupe.services.hotspring.QueryOptions.prototype.getNoQueryRewrite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.hotspring.QueryOptions} returns this
 */
proto.loupe.services.hotspring.QueryOptions.prototype.setNoQueryRewrite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional QueryInterpreterMode interpreter_mode = 2;
 * @return {!proto.loupe.services.hotspring.QueryInterpreterMode}
 */
proto.loupe.services.hotspring.QueryOptions.prototype.getInterpreterMode = function() {
  return /** @type {!proto.loupe.services.hotspring.QueryInterpreterMode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.services.hotspring.QueryInterpreterMode} value
 * @return {!proto.loupe.services.hotspring.QueryOptions} returns this
 */
proto.loupe.services.hotspring.QueryOptions.prototype.setInterpreterMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.loupe.services.hotspring.ErrorCode = {
  ERROR_CODE_UNSPECIFIED: 0,
  NOT_FOUND: 1,
  INVALID_PARAMETERS: 2,
  COMPILE_ERROR: 3,
  QUERY_ERROR: 4,
  TIMEOUT: 5,
  RATE_LIMIT: 6
};

/**
 * @enum {number}
 */
proto.loupe.services.hotspring.QueryTier = {
  QUERY_TIER_UNSPECIFIED: 0,
  REALTIME: 1,
  ELT: 2
};

/**
 * @enum {number}
 */
proto.loupe.services.hotspring.QueryInterpreterMode = {
  QUERYINTERPRETERMODE_UNSPECIFIED: 0,
  INTERPRET_FIRST: 1
};

goog.object.extend(exports, proto.loupe.services.hotspring);
