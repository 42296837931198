import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Popover } from '@material-ui/core'
import { useRoutes } from '../../context/routes'
import { useAuth } from '../../context/auth'
import { useTenantInfo } from '../../context/tenantInfo'
import { usePopover } from '../../hooks/usePopover'
import Avatar from '../common/avatar'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import { getClaims } from '../../lib/auth0'
import { InstanceSetupStatus } from '../../grpc/enums'
import { claimNames } from '../../constants/claimNames'
import { some } from 'lodash'

const canopyUrl = process.env.CANOPY_URL

const UserMenu = (props) => {
  const claims = getClaims()

  const { tenantContext, setTenantContext } = useAuth()

  const { isSandbox, tenantInfo } = useTenantInfo()

  const { checkPermissions } = usePermissions()
  const { routes, routeTenantId } = useRoutes()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessUser,
      permissionNames.CanAccessGroup,
      permissionNames.CanAccessGoal,
      permissionNames.CanAccessTenant
    )
  }, [checkPermissions])

  const imageUrl = useMemo(() => {
    return claims.picture || ''
  }, [claims])

  const canAccessApp = useMemo(() => {
    if (!canopyUrl) {
      return false
    }

    if (!isSandbox) {
      return true
    }

    const { setupStatus } = tenantInfo
    return setupStatus === InstanceSetupStatus.INSTANCE_SETUP_STATUS_READY
  }, [canopyUrl, InstanceSetupStatus, isSandbox, tenantInfo])

  const initials = useMemo(() => {
    const { given_name: givenName, family_name: familyName, name } = claims
    if (givenName && familyName) {
      return `${givenName.toUpperCase().charAt(0)}${familyName.toUpperCase().charAt(0)}`
    } else if (name) {
      return name.toUpperCase().charAt(0)
    }
    return '-'
  }, [claims])

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const onRemoveContext = useCallback(() => {
    setTenantContext(undefined)
    onClose()
  }, [])

  const onBackToCanopyClick = useCallback(() => {
    const claims = getClaims()
    const tenantContexts = claims[claimNames.tenantContexts] || []
    let url = canopyUrl
    if (routeTenantId && some(tenantContexts, (c) => c.tenant_id === routeTenantId)) {
      url += `/${routeTenantId}`
    }
    url += `/?nocache=${new Date().getTime()}`
    window.location = url
  }, [routeTenantId])

  return (
    <>
      {tenantContext && !routeTenantId
        ? (
          <button className="flex flex-shrink-0 focus:outline-none" onClick={handleClick}>
            <div className="flex items-center bg-gradient-green rounded-full pr-6" style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}>
              <Avatar
                className="w-8 h-8 text-size-16px"
                imageUrl={imageUrl}
                initials={initials} />
              <div className="ml-2 text-left leading-none">
                <div className="text-size-10px text-color-ffffff whitespace-no-wrap truncate uppercase tracking-widest font-weight-700">Context</div>
                <div className="text-size-10px text-color-ffffff whitespace-no-wrap truncate">{tenantContext.name}</div>
              </div>
            </div>
          </button>
        )
        : (
          <button className="flex flex-shrink-0 focus:outline-none" onClick={handleClick}>
            <Avatar
              className="w-10 h-10 text-size-18px"
              imageUrl={imageUrl}
              initials={initials} />
          </button>
        )}
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className="py-2">

          {/* Responsive Menu */}
          <div className="md:hidden bg-color-ffffff">
            {permissions.CanAccessUser
              && <Link to={routes.users}><div className="px-6 py-2 font-weight-400 focus:outline-none">Users</div></Link>}

            {permissions.CanAccessGroup
              && <Link to={routes.teams}><div className="px-6 py-2 font-weight-400 focus:outline-none">Groups</div></Link>}

            {permissions.CanAccessGoal
              && <Link to={routes.goals}><div className="px-6 py-2 font-weight-400 focus:outline-none">Goals</div></Link>}

            {permissions.CanAccessTenant
              && <Link to={routes.tenants}><div className="px-6 py-2 font-weight-400 focus:outline-none">Tenants</div></Link>}
          </div>

          {tenantContext && !routeTenantId && <button onClick={onRemoveContext} className="px-6 py-2 font-weight-400 focus:outline-none">Remove Tenant Context</button>}
          {canopyUrl && <a onClick={onBackToCanopyClick}><div className="px-6 py-2 font-weight-400 focus:outline-none cursor-pointer">Back to Outreach Commit</div></a>}
          <Link to={routes.logout}><div className="px-6 py-2 font-weight-400 focus:outline-none">Logout</div></Link>
        </div>
      </Popover>
    </>
  )
}

export default UserMenu
