import React, { useCallback, useMemo, useRef } from 'react'
import { useDebug } from './debug'

const CacheContext = React.createContext()

export function CacheProvider({ children }) {
  const { debug } = useDebug()

  const cache = useRef({})

  const hasCache = useCallback((context = '', key) => {
    return !!cache.current[context]?.[key]
  }, [cache])

  const getCache = useCallback((context = '', key) => {
    if (debug) {
      console.log(`getCache:${context}`, key, cache.current[context]?.[key])
    }
    return cache.current[context]?.[key]
  }, [debug, cache])

  const setCache = useCallback((context = '', key, value) => {
    if (!!context && !!key) {
      if (!cache.current[context]) {
        cache.current[context] = {}
      }
      cache.current[context][key] = value
      if (debug) {
        console.log(`setCache:${context}`, key, value, cache.current[context])
      }
    }
  }, [debug, cache])

  const clearCacheContext = useCallback((context = '') => {
    if (hasCache(context)) {
      cache.current[context] = {}
    }
  }, [hasCache])

  const contextValue = useMemo(() => {
    return {
      hasCache,
      getCache,
      setCache,
      clearCacheContext
    }
  }, [hasCache, getCache, setCache, clearCacheContext])

  return <CacheContext.Provider value={contextValue}>{children}</CacheContext.Provider>
}

export function useCache() {
  const context = React.useContext(CacheContext)
  if (context === undefined) {
    throw new Error('useCache must be used within a CacheProvider')
  }
  return context
}
