import React from 'react'
import ExclamationTriangle from '../icons/exclamationTriangle'

const GroupsError = (props) => {
  const {
    header
  } = props

  return (
    <div className="flex items-center h-full">
      <div className="text-center">
        <div className="flex justify-center mt-6 mb-8">
          <ExclamationTriangle fill="#5951FF" transform="scale(2.5)" />
        </div>
        <div className="text-size-30px font-bold text-color-91959f">Error</div>
        <div className="text-size-14px text-color-91959f">Your team data may be out of sync</div>
        <div onClick={() => window.location.reload()} className="my-4 text-size-14px text-color-5951FF cursor-pointer">Reload Page</div>
      </div>
    </div>
  )
}

export default GroupsError
