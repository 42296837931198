import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { useCanonicalObjectMapping } from '../../context/canonicalObjectMapping'
import { useIntegrationObjectTypes } from '../../context/integrationObjectTypes'
import { filter, find } from 'lodash'
import ExclamationTriangle from '../icons/exclamationTriangle'
import Tooltip from '../common/tooltip'
import { MappingStatusDetailLevel } from '../../grpc/enums'
import pluralize from 'pluralize'

const ObjectTabButtons = (props) => {
  const {
    isFetching
  } = props

  const { integrationObjectTypes } = useIntegrationObjectTypes()
  const { canonicalObjectMapping, selectedCrmObjectName, setSelectedCrmObjectName } = useCanonicalObjectMapping()

  const { crmObjectsList = [] } = canonicalObjectMapping

  const onTabSelected = useCallback((o) => {
    const { objectName } = o
    setSelectedCrmObjectName(objectName)
  }, [])

  const statusDetailsList = useMemo(() => {
    return canonicalObjectMapping.statusDetailsList || []
  }, [canonicalObjectMapping])

  const fieldValidations = useMemo(() => {
    return filter(statusDetailsList, (s) => {
      return selectedCrmObjectName === s.crmObject && s.level === MappingStatusDetailLevel.LEVEL_ERROR
    })
  }, [selectedCrmObjectName, statusDetailsList])

  const renderValidationIcon = useCallback(() => {
    const count = fieldValidations?.length ?? 0
    if (count > 0) {
      const validation = fieldValidations[0]
      const { level } = validation
      if (level === MappingStatusDetailLevel.LEVEL_ERROR) {
        const title = `${pluralize('field', count, true)} require${count === 1 ? 's' : ''} attention!`
        return (
          <Tooltip placement="top" arrow={true} title={title}>
            <div className="flex-shrink-0 flex-grow-0 mr-1">
              <ExclamationTriangle fill="#fb6c6a" />
            </div>
          </Tooltip>
        )
      }
    }
  }, [fieldValidations])

  return (
    <div className="flex flex-col my-4">
      <div className="flex flex-wrap items-center">

        {crmObjectsList.map((o, i) => {
          const isSelected = o.objectName === selectedCrmObjectName
          const obj = find(integrationObjectTypes, (iot) => iot.name === o.objectName)
          const label = obj?.label ?? o.label
          return (
            <div
              key={`ObjectTab-${i}`}
              onClick={() => onTabSelected(o)}
              className={classNames('flex flex-col py-1 mr-8 first:ml-0 whitespace-nowrap leading-tight select-none focus:outline-none',
                { 'pointer-events-none cursor-default': isFetching },
                { 'cursor-pointer': !isFetching })}>
              <div className={classNames('flex items-center text-size-20px',
                { 'text-color-09242f font-bold': isSelected },
                { 'text-color-818e93 font-weight-500': !isSelected })}>
                {renderValidationIcon()}
                {label || `(${o.objectName})`}
              </div>
              <div className={classNames('w-full rounded-full bg-color-5951FF', { invisible: !isSelected })} style={{ height: 4 }} />
            </div>
          )
        })}

      </div>

    </div>
  )
}

export default ObjectTabButtons
