// source: src/common/contact/contact.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.loupe.common.contact.Contact', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.contact.Contact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.contact.Contact.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.contact.Contact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.contact.Contact.displayName = 'proto.loupe.common.contact.Contact';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.contact.Contact.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.contact.Contact.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.contact.Contact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.contact.Contact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.contact.Contact.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    changeDate: (f = msg.getChangeDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    email: jspb.Message.getFieldWithDefault(msg, 8, ""),
    title: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lastModifiedDate: (f = msg.getLastModifiedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    photoUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    contactId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    role: jspb.Message.getFieldWithDefault(msg, 14, ""),
    isPrimary: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    versionsList: jspb.Message.toObjectList(msg.getVersionsList(),
    proto.loupe.common.contact.Contact.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.contact.Contact}
 */
proto.loupe.common.contact.Contact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.contact.Contact;
  return proto.loupe.common.contact.Contact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.contact.Contact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.contact.Contact}
 */
proto.loupe.common.contact.Contact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setChangeDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModifiedDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoUrl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpportunityId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPrimary(value);
      break;
    case 16:
      var value = new proto.loupe.common.contact.Contact;
      reader.readMessage(value,proto.loupe.common.contact.Contact.deserializeBinaryFromReader);
      msg.addVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.contact.Contact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.contact.Contact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.contact.Contact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.contact.Contact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChangeDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLastModifiedDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPhotoUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOpportunityId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getContactId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getIsPrimary();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.loupe.common.contact.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.contact.Contact.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_deleted = 2;
 * @return {boolean}
 */
proto.loupe.common.contact.Contact.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.loupe.common.contact.Contact.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp change_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.contact.Contact.prototype.getChangeDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.contact.Contact} returns this
*/
proto.loupe.common.contact.Contact.prototype.setChangeDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.clearChangeDate = function() {
  return this.setChangeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.contact.Contact.prototype.hasChangeDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.loupe.common.contact.Contact.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string first_name = 6;
 * @return {string}
 */
proto.loupe.common.contact.Contact.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.loupe.common.contact.Contact.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string email = 8;
 * @return {string}
 */
proto.loupe.common.contact.Contact.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string title = 9;
 * @return {string}
 */
proto.loupe.common.contact.Contact.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp last_modified_date = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.contact.Contact.prototype.getLastModifiedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.contact.Contact} returns this
*/
proto.loupe.common.contact.Contact.prototype.setLastModifiedDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.clearLastModifiedDate = function() {
  return this.setLastModifiedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.contact.Contact.prototype.hasLastModifiedDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string photo_url = 11;
 * @return {string}
 */
proto.loupe.common.contact.Contact.prototype.getPhotoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setPhotoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string opportunity_id = 12;
 * @return {string}
 */
proto.loupe.common.contact.Contact.prototype.getOpportunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string contact_id = 13;
 * @return {string}
 */
proto.loupe.common.contact.Contact.prototype.getContactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setContactId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string role = 14;
 * @return {string}
 */
proto.loupe.common.contact.Contact.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool is_primary = 15;
 * @return {boolean}
 */
proto.loupe.common.contact.Contact.prototype.getIsPrimary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.setIsPrimary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated Contact versions = 16;
 * @return {!Array<!proto.loupe.common.contact.Contact>}
 */
proto.loupe.common.contact.Contact.prototype.getVersionsList = function() {
  return /** @type{!Array<!proto.loupe.common.contact.Contact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.contact.Contact, 16));
};


/**
 * @param {!Array<!proto.loupe.common.contact.Contact>} value
 * @return {!proto.loupe.common.contact.Contact} returns this
*/
proto.loupe.common.contact.Contact.prototype.setVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.loupe.common.contact.Contact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.contact.Contact}
 */
proto.loupe.common.contact.Contact.prototype.addVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.loupe.common.contact.Contact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.contact.Contact} returns this
 */
proto.loupe.common.contact.Contact.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};


goog.object.extend(exports, proto.loupe.common.contact);
