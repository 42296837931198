import * as Sentry from '@sentry/browser'
import { get } from 'lodash'
import { claimNames } from '../constants/claimNames'
import { getClaims } from './auth0'

const tagName = process.env.TAG_NAME
const shortSHA = process.env.SHORT_SHA

export const captureException = (err, state, method) => {
  Sentry.withScope((scope) => {
    scope.setTag('app', 'Admin')

    const version = tagName || shortSHA
    version && scope.setExtra('appVersion', version)
    state && scope.setExtra('state', JSON.stringify(state))
    method && scope.setExtra('method', method)
    window.location && window.location.path && scope.setExtra('path', window.location.path)

    const ignoredErrors = ['timeout', 'login_required']
    const isIgnoredError = (err && err.code && ignoredErrors.includes(err.code)) || false
    if (!isIgnoredError) {
      process.env.NODE_ENV !== 'production' && console.log('captureException', { error: err, state, method })
      Sentry.captureException(err instanceof Error ? err : new Error(JSON.stringify(err)))
    }
  })
}

export const setUser = (claims, extra = {}) => {
  const c = claims || getClaims()
  const identity = {
    id: get(c, 'sub', ''),
    tenantId: c[claimNames.tenantId],
    tenantRegion: c[claimNames.tenantRegion],
    personId: c[claimNames.personId],
    email: get(c, 'email', ''),
    name: get(c, 'name', ''),
    firstName: get(c, 'given_name', ''),
    lastName: get(c, 'family_name', ''),
    username: get(c, 'username', ''),
    ...extra,
  }
  Sentry.setUser(identity)

  window.analytics?.identify(identity.personId, identity)
}
