import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toGetDataSyncIntervalRequest } from '../grpc/converters'
import { guid } from '../lib/guid'
import { useAuth } from './auth'

const DataSyncIntervalContext = React.createContext()

export function DataSyncIntervalProvider({ children }) {
  const { tenantId } = useAuth()

  const [key, setKey] = useState(guid())
  const [dataSyncIntervalInSec, setDataSyncIntervalInSec] = useState(3600)

  useGrpcEffect({
    request: toGetDataSyncIntervalRequest({
      tenantId,
    }),
    onSuccess: (obj) => {
      setDataSyncIntervalInSec(obj.baseIntervalSeconds)
    },
    grpcMethod: 'getDataSyncInterval',
    debug: false,
  }, [tenantId, key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      dataSyncIntervalInSec,
      setDataSyncIntervalInSec,
      key,
      invalidate,
    }
  }, [dataSyncIntervalInSec])

  return <DataSyncIntervalContext.Provider value={contextValue}>{children}</DataSyncIntervalContext.Provider>
}

export function useDataSyncInterval() {
  const context = React.useContext(DataSyncIntervalContext)
  if (context === undefined) {
    throw new Error('useDataSyncInterval must be used within a DataSyncIntervalProvider')
  }
  return context
}
