import React from 'react'
import Circle from '../icons/circle'
import CircleEmpty from '../icons/circleEmpty'
import { useObjectMappings } from '../../context/objectMappings'

const DataSyncSummary = (props) => {
  const { activeCount, errorCount, inactiveCount } = useObjectMappings()

  return (
    <div className="flex items-center mx-5 mt-6">
      <div className="flex items-center">
        <Circle fill="#fb6c6a" transform="scale(0.55)" />
        <div className="text-color-818e93 text-size-16px font-weight-500 mr-5" style={{ transform: 'translateY(1px)' }}>
          {errorCount}
          {' '}
          Errors
        </div>
      </div>
      <div className="flex items-center">
        <CircleEmpty fill="#818e93" transform="scale(0.55)" />
        <div className="text-color-818e93 text-size-16px font-weight-500 mr-5" style={{ transform: 'translateY(1px)' }}>
          {inactiveCount}
          {' '}
          Inactive
        </div>
      </div>
      <div className="flex items-center">
        <Circle fill="#1dcf83" transform="scale(0.55)" />
        <div id="joyride_dataSync_activeCount" className="text-color-818e93 text-size-16px font-weight-500 mr-5" style={{ transform: 'translateY(1px)' }}>
          {activeCount}
          {' '}
          Active
        </div>
      </div>
    </div>
  )
}

export default DataSyncSummary
