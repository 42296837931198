import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isDescendant } from './utils/tree-data-utils'
import classnames from './utils/classnames'
import DragHandle from '../icons/dragHandle'
import NodeMenu from './nodeMenu'
import AccountCircle from '../icons/accountCircle'
import { filter } from 'lodash'
import { BasicStatus } from '../../grpc/enums'
import { permissionNames } from '../../constants/permissionNames'

class CustomNodeContentRenderer extends Component {
  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      title,
      subtitle,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      buttons,
      className,
      style,
      didDrop,
      treeId,
      isOver, // Not needed, but preserved for other renderers
      parentNode, // Needed for dndManager
      rowDirection,
      onMembers,
      onAdd,
      onEdit,
      onDelete,
      checkPermission,
      showActionsOnHover,
      ...otherProps
    } = this.props

    const nodeTitle = title || node.title
    const nodeSubtitle = subtitle || node.subtitle
    const rowDirectionClass = rowDirection === 'rtl' ? 'custom__rtl' : null

    let handle
    if (canDrag && checkPermission(permissionNames.CanUpdateGroup)) {
      if (typeof node.children === 'function' && node.expanded) {
        // Show a loading symbol on the handle when the children are expanded
        //  and yet still defined by a function (a callback to fetch the children)
        handle = (
          <div className="custom__loadingHandle">
            <div className="custom__loadingCircle">
              {[...new Array(12)].map((_, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={classnames(
                    'custom__loadingCirclePoint',
                    rowDirectionClass
                  )}
                />
              ))}
            </div>
          </div>
        )
      } else {
        // Show the handle used to initiate a drag-and-drop
        handle = connectDragSource(<div className="custom__moveHandle flex items-center justify-center"><DragHandle className="flex-shrink-0" fill="#c9ced0" /></div>, {
          dropEffect: 'copy',
        })
      }
    }

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node)
    const isLandingPadActive = !didDrop && isDragging

    let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth }
    if (rowDirection === 'rtl') {
      buttonStyle = { right: -0.5 * scaffoldBlockPxWidth }
    }

    return (
      <div style={{ height: '100%' }} {...otherProps}>
        {toggleChildrenVisibility
          && node.children
          && (node.children.length > 0 || typeof node.children === 'function')
          && (
            <div>
              <button
                type="button"
                aria-label={node.expanded ? 'Collapse' : 'Expand'}
                className={classnames(
                  node.expanded ? 'custom__collapseButton' : 'custom__expandButton',
                  rowDirectionClass
                )}
                style={buttonStyle}
                onClick={() => toggleChildrenVisibility({
                  node,
                  path,
                  treeIndex,
                })} />

              {node.expanded && !isDragging && (
                <div
                  style={{ width: scaffoldBlockPxWidth }}
                  className={classnames('custom__lineChildren', rowDirectionClass)}
                />
              )}
            </div>
          )}

        <div className={classnames('custom__rowWrapper group', rowDirectionClass)}>

          {/* Set the row preview to be used during drag and drop */}
          {connectDragPreview(
            <div
              className={classnames(
                'custom__row',
                isLandingPadActive && 'custom__rowLandingPad',
                isLandingPadActive && !canDrop && 'custom__rowCancelPad',
                isSearchMatch && 'custom__rowSearchMatch',
                isSearchFocus && 'custom__rowSearchFocus',
                rowDirectionClass,
                className
              )}
              style={{
                opacity: isDraggedDescendant ? 0.5 : 1,
                ...style,
              }}>

              <div
                className={classnames(
                  'custom__rowContents',
                  !canDrag && 'custom__rowContentsDragDisabled',
                  rowDirectionClass
                )}>
                <div className={classnames('custom__rowLabel', rowDirectionClass)}>
                  <div className="flex items-center">
                    <span
                      className={classnames(
                        'custom__rowTitle',
                        node.subtitle && 'custom__rowTitleWithSubtitle'
                      )}>
                      {typeof nodeTitle === 'function'
                        ? nodeTitle({
                          node,
                          path,
                          treeIndex,
                        })
                        : nodeTitle}
                    </span>
                    <button
                      className="flex items-center focus:outline-none"
                      onClick={() => onMembers({ node, path, treeIndex })}>
                      <AccountCircle className="on-manage flex-shrink-0 ml-2 mr-1" fill="#c9ced0" />
                      <span
                        className="text-size-16px text-color-51636a font-weight-500"
                        style={{ transform: 'translateY(1px)' }}>
                        {filter(node.membersList, (m) => m.status === BasicStatus.ACTIVE).length}
                      </span>
                    </button>
                  </div>

                  {nodeSubtitle && (
                    <span className="custom__rowSubtitle">
                      {typeof nodeSubtitle === 'function'
                        ? nodeSubtitle({
                          node,
                          path,
                          treeIndex,
                        })
                        : nodeSubtitle}
                    </span>
                  )}
                </div>

                <div className={classnames('custom__rowToolbar', showActionsOnHover && 'invisible group-hover:visible')}>
                  <NodeMenu
                    node={node}
                    path={path}
                    treeIndex={treeIndex}
                    onAdd={onAdd}
                    onEdit={onEdit}
                    onDelete={onDelete} />
                  {buttons.map((btn, index) => (
                    <div
                      key={index} // eslint-disable-line react/no-array-index-key
                      className="custom__toolbarButton">
                      {btn}
                    </div>
                  ))}
                </div>
              </div>

              {handle}

            </div>
          )}
        </div>

      </div>
    )
  }
}

CustomNodeContentRenderer.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: '',
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  subtitle: null,
  rowDirection: 'ltr',
  showActionsOnHover: true
}

CustomNodeContentRenderer.propTypes = {
  node: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),
  showActionsOnHover: PropTypes.bool,

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  // Drop target
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,

  // rtl support
  rowDirection: PropTypes.string,
}

export default CustomNodeContentRenderer
