import { copyToClipboard } from '../../lib/clipboard'
import { debounce } from 'lodash'
import { permissionNames } from '../../constants/permissionNames'
import { toGetLinkedAccountAuthLinkRequest } from '../../grpc/converters'
import { useGrpcCallback } from '../../grpc'
import { useModal } from '../../hooks/useModal'
import { useNotification } from '../../hooks/useNotification'
import { usePermissions } from '../../context/permissions'
import { useTenants } from '../../context/tenants'
import { useTextField } from '../../hooks/useTextField'
import Button from '../common/button'
import EmptyState from '../common/emptyState'
import Groups from '../icons/groups'
import Header from '../header/header'
import LoadingState from '../common/loadingState'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo } from 'react'
import SaveTenantModal from './saveTenantModal'
import SearchBox from '../common/searchBox'
import Switch from '../common/switch'
import TextSelectList from '../common/textSelectList'
import TenantsList from './tenantsList'
import { regionOptions } from '../constants/regionOptions'

const TenantsMain = (props) => {
  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadTenant,
      permissionNames.CanCreateTenant
    )
  }, [checkPermissions])

  const { notifyError, notifySuccess } = useNotification()

  const search = useTextField()

  const {
    isFetching,
    setSearch,
    setShowSandboxes,
    showSandboxes,
    setPage,
    setRegion,
    tenants,
    total
  } = useTenants()

  const modal = useModal()

  const count = useMemo(() => {
    return tenants.length
  }, [tenants])

  const showEmptyState = useMemo(() => {
    return !isFetching && count === 0
  }, [isFetching, count])

  const debounceSearch = useMemo(() => {
    return debounce((searchText) => {
      setSearch(searchText)
    }, 350)
  }, [])

  useEffect(() => {
    debounceSearch(search.value)
  }, [search.value])

  const onCreate = useCallback(() => {
    modal.setData(undefined)
    modal.setOpen(true)
  }, [])

  const onEditTenant = useCallback((tenant) => {
    modal.setData(tenant)
    modal.setOpen(true)
  }, [])

  const getSalesforceLink = useGrpcCallback({
    onError: () => {
      notifyError('Error generating Salesforce auth link!')
    },
    onSuccess: (obj) => {
      const { authUrl } = obj
      if (authUrl) {
        copyToClipboard(authUrl)
        notifySuccess('Salesforce link copied to clipboard.')
      }
    },
    grpcMethod: 'getLinkedAccountAuthLink',
    grpcMethodName: 'getSalesforceLink',
    debug: false,
  }, [])

  const onSalesforceAuthLink = useCallback((tenant) => {
    const request = toGetLinkedAccountAuthLinkRequest({
      tenantId: tenant.id,
      provider: 'salesforce',
    })
    getSalesforceLink(request)
  }, [getSalesforceLink])

  const getHubspotLink = useGrpcCallback({
    onError: () => {
      notifyError('Error generating Hubspot auth link!')
    },
    onSuccess: (obj) => {
      const { authUrl } = obj
      if (authUrl) {
        copyToClipboard(authUrl)
        notifySuccess('Hubspot link copied to clipboard.')
      }
    },
    grpcMethod: 'getLinkedAccountAuthLink',
    grpcMethodName: 'getHubspotLink',
    debug: false,
  }, [])

  const onHubspotAuthLink = useCallback((tenant) => {
    const request = toGetLinkedAccountAuthLinkRequest({
      tenantId: tenant.id,
      provider: 'hubspot',
    })
    getHubspotLink(request)
  }, [getHubspotLink])

  const onChangeShowSandboxes = useCallback((e) => {
    setShowSandboxes(e.target.checked)
  }, [setShowSandboxes])

  const onChangeRegion = useCallback((event) => {
    setPage(1)
    setRegion(event.target.value)
  }, [setRegion])

  return (
    <div className="flex flex-col w-full h-screen">
      <Header
        title="Tenants" />

      <div className="flex-grow overflow-auto p-10">
        <div className="flex items-center justify-between mb-3">
          <div className="flex flex-items space-x-3">
            <div className="text-size-20px text-color-51636a font-weight-400">
              {pluralize('Tenant', total || 0, true)}
            </div>
            <Switch
              checked={showSandboxes}
              label="Sandboxes"
              onChange={onChangeShowSandboxes}
              size="small"
            />
          </div>
          {permissions.CanReadTenant && (
            <div className="flex items-center">
              <TextSelectList
                defaultValue={regionOptions[0]?.value}
                onChange={onChangeRegion}
                options={regionOptions}
                    />
              <SearchBox
                className="ml-2"
                value={search.value}
                onChange={search.onChange}
                onClear={search.reset}
                autoFocus={true} />
              <Button
                className="ml-2"
                size="xs"
                text="Create Tenant"
                onClick={onCreate} />
            </div>
          )}
        </div>

        {permissions.CanReadTenant && (
          <>
            {showEmptyState
              ? (
                <div className="flex justify-center my-10">
                  <EmptyState
                    iconControl={<Groups transform="scale(1.2)" />}
                    header="No Tenants"
                    subHeader="There are no tenants to display" />
                </div>
              )
              : (
                <>
                  {isFetching
                    ? (
                      <div className="flex justify-center my-10">
                        <LoadingState
                          header="Loading Tenants"
                          subHeader="Please wait..."
                          animate={true} />
                      </div>
                    )
                    : (
                      <TenantsList
                        onEditTenant={onEditTenant}
                        onSalesforceAuthLink={onSalesforceAuthLink}
                        onHubspotAuthLink={onHubspotAuthLink} />
                    )}
                </>
              )}
          </>
        )}

        <SaveTenantModal
          modal={modal} />

      </div>
    </div>
  )
}

export default TenantsMain
