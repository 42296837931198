import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toListCanonicalObjectFieldTypesRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'

const CanonicalObjectFieldTypesContext = React.createContext()

export function CanonicalObjectFieldTypesProvider({ children }) {
  const { notifyError } = useNotification()

  const [key, setKey] = useState(guid())
  const [canonicalObjectFieldTypes, setCanonicalObjectFieldTypes] = useState([])

  useGrpcEffect({
    request: toListCanonicalObjectFieldTypesRequest({}),
    onError: () => {
      notifyError('Error fetching canonical object field types!')
    },
    onSuccess: (obj) => {
      if (obj.fieldTypesList) {
        setCanonicalObjectFieldTypes(obj.fieldTypesList)
      }
    },
    grpcMethod: 'listCanonicalObjectFieldTypes',
    debug: false,
  }, [key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      canonicalObjectFieldTypes,
      setCanonicalObjectFieldTypes,
      key,
      invalidate,
    }
  }, [canonicalObjectFieldTypes])

  return <CanonicalObjectFieldTypesContext.Provider value={contextValue}>{children}</CanonicalObjectFieldTypesContext.Provider>
}

export function useCanonicalObjectFieldTypes() {
  const context = React.useContext(CanonicalObjectFieldTypesContext)
  if (context === undefined) {
    throw new Error('useCanonicalObjectFieldTypes must be used within a CanonicalObjectFieldTypesProvider')
  }
  return context
}
