// source: src/common/activity/activity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_nullable_nullable_pb = require('../../../src/common/nullable/nullable_pb.js');
goog.object.extend(proto, src_common_nullable_nullable_pb);
var src_common_person_person_pb = require('../../../src/common/person/person_pb.js');
goog.object.extend(proto, src_common_person_person_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.loupe.common.activity.Activity', null, global);
goog.exportSymbol('proto.loupe.common.activity.ActivityDirection', null, global);
goog.exportSymbol('proto.loupe.common.activity.ActivityType', null, global);
goog.exportSymbol('proto.loupe.common.activity.FullActivity', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.activity.Activity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.activity.Activity.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.activity.Activity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.activity.Activity.displayName = 'proto.loupe.common.activity.Activity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.activity.FullActivity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.activity.FullActivity.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.activity.FullActivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.activity.FullActivity.displayName = 'proto.loupe.common.activity.FullActivity';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.activity.Activity.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.activity.Activity.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.activity.Activity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.activity.Activity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.activity.Activity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownersList: jspb.Message.toObjectList(msg.getOwnersList(),
    src_common_person_person_pb.Person.toObject, includeInstance),
    participantsList: jspb.Message.toObjectList(msg.getParticipantsList(),
    src_common_person_person_pb.Person.toObject, includeInstance),
    direction: jspb.Message.getFieldWithDefault(msg, 4, 0),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    completedAt: (f = msg.getCompletedAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    subject: jspb.Message.getFieldWithDefault(msg, 11, ""),
    partitionTs: (f = msg.getPartitionTs()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.activity.Activity}
 */
proto.loupe.common.activity.Activity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.activity.Activity;
  return proto.loupe.common.activity.Activity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.activity.Activity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.activity.Activity}
 */
proto.loupe.common.activity.Activity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new src_common_person_person_pb.Person;
      reader.readMessage(value,src_common_person_person_pb.Person.deserializeBinaryFromReader);
      msg.addOwners(value);
      break;
    case 3:
      var value = new src_common_person_person_pb.Person;
      reader.readMessage(value,src_common_person_person_pb.Person.deserializeBinaryFromReader);
      msg.addParticipants(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.common.activity.ActivityDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.common.activity.ActivityType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 8:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setCompletedAt(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPartitionTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.activity.Activity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.activity.Activity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.activity.Activity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.activity.Activity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_person_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getParticipantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      src_common_person_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCompletedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPartitionTs();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.activity.Activity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.activity.Activity} returns this
 */
proto.loupe.common.activity.Activity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated loupe.common.person.Person owners = 2;
 * @return {!Array<!proto.loupe.common.person.Person>}
 */
proto.loupe.common.activity.Activity.prototype.getOwnersList = function() {
  return /** @type{!Array<!proto.loupe.common.person.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_person_person_pb.Person, 2));
};


/**
 * @param {!Array<!proto.loupe.common.person.Person>} value
 * @return {!proto.loupe.common.activity.Activity} returns this
*/
proto.loupe.common.activity.Activity.prototype.setOwnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.person.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.person.Person}
 */
proto.loupe.common.activity.Activity.prototype.addOwners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.person.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.activity.Activity} returns this
 */
proto.loupe.common.activity.Activity.prototype.clearOwnersList = function() {
  return this.setOwnersList([]);
};


/**
 * repeated loupe.common.person.Person participants = 3;
 * @return {!Array<!proto.loupe.common.person.Person>}
 */
proto.loupe.common.activity.Activity.prototype.getParticipantsList = function() {
  return /** @type{!Array<!proto.loupe.common.person.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_person_person_pb.Person, 3));
};


/**
 * @param {!Array<!proto.loupe.common.person.Person>} value
 * @return {!proto.loupe.common.activity.Activity} returns this
*/
proto.loupe.common.activity.Activity.prototype.setParticipantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.person.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.person.Person}
 */
proto.loupe.common.activity.Activity.prototype.addParticipants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.person.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.activity.Activity} returns this
 */
proto.loupe.common.activity.Activity.prototype.clearParticipantsList = function() {
  return this.setParticipantsList([]);
};


/**
 * optional ActivityDirection direction = 4;
 * @return {!proto.loupe.common.activity.ActivityDirection}
 */
proto.loupe.common.activity.Activity.prototype.getDirection = function() {
  return /** @type {!proto.loupe.common.activity.ActivityDirection} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.common.activity.ActivityDirection} value
 * @return {!proto.loupe.common.activity.Activity} returns this
 */
proto.loupe.common.activity.Activity.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ActivityType type = 5;
 * @return {!proto.loupe.common.activity.ActivityType}
 */
proto.loupe.common.activity.Activity.prototype.getType = function() {
  return /** @type {!proto.loupe.common.activity.ActivityType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.common.activity.ActivityType} value
 * @return {!proto.loupe.common.activity.Activity} returns this
 */
proto.loupe.common.activity.Activity.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.loupe.common.activity.Activity.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.activity.Activity} returns this
 */
proto.loupe.common.activity.Activity.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool completed = 7;
 * @return {boolean}
 */
proto.loupe.common.activity.Activity.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.activity.Activity} returns this
 */
proto.loupe.common.activity.Activity.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional loupe.common.nullable.NullTimestamp completed_at = 8;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.activity.Activity.prototype.getCompletedAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 8));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.activity.Activity} returns this
*/
proto.loupe.common.activity.Activity.prototype.setCompletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.activity.Activity} returns this
 */
proto.loupe.common.activity.Activity.prototype.clearCompletedAt = function() {
  return this.setCompletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.activity.Activity.prototype.hasCompletedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.activity.Activity.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.activity.Activity} returns this
*/
proto.loupe.common.activity.Activity.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.activity.Activity} returns this
 */
proto.loupe.common.activity.Activity.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.activity.Activity.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.activity.Activity.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.activity.Activity} returns this
*/
proto.loupe.common.activity.Activity.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.activity.Activity} returns this
 */
proto.loupe.common.activity.Activity.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.activity.Activity.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string subject = 11;
 * @return {string}
 */
proto.loupe.common.activity.Activity.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.activity.Activity} returns this
 */
proto.loupe.common.activity.Activity.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp partition_ts = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.activity.Activity.prototype.getPartitionTs = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.activity.Activity} returns this
*/
proto.loupe.common.activity.Activity.prototype.setPartitionTs = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.activity.Activity} returns this
 */
proto.loupe.common.activity.Activity.prototype.clearPartitionTs = function() {
  return this.setPartitionTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.activity.Activity.prototype.hasPartitionTs = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.activity.FullActivity.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.activity.FullActivity.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.activity.FullActivity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.activity.FullActivity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.activity.FullActivity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isClosed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isCompleted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ownersList: jspb.Message.toObjectList(msg.getOwnersList(),
    src_common_person_person_pb.Person.toObject, includeInstance),
    participantsList: jspb.Message.toObjectList(msg.getParticipantsList(),
    src_common_person_person_pb.Person.toObject, includeInstance),
    source: jspb.Message.getFieldWithDefault(msg, 9, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 10, ""),
    description: jspb.Message.getFieldWithDefault(msg, 11, ""),
    status: jspb.Message.getFieldWithDefault(msg, 12, ""),
    callType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    subType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    durationInSeconds: jspb.Message.getFieldWithDefault(msg, 15, 0),
    completedAt: (f = msg.getCompletedAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.activity.FullActivity}
 */
proto.loupe.common.activity.FullActivity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.activity.FullActivity;
  return proto.loupe.common.activity.FullActivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.activity.FullActivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.activity.FullActivity}
 */
proto.loupe.common.activity.FullActivity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsClosed(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCompleted(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.common.activity.ActivityType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {!proto.loupe.common.activity.ActivityDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 7:
      var value = new src_common_person_person_pb.Person;
      reader.readMessage(value,src_common_person_person_pb.Person.deserializeBinaryFromReader);
      msg.addOwners(value);
      break;
    case 8:
      var value = new src_common_person_person_pb.Person;
      reader.readMessage(value,src_common_person_person_pb.Person.deserializeBinaryFromReader);
      msg.addParticipants(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubType(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationInSeconds(value);
      break;
    case 16:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setCompletedAt(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.activity.FullActivity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.activity.FullActivity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.activity.FullActivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.activity.FullActivity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsClosed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsCompleted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getOwnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      src_common_person_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getParticipantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      src_common_person_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCallType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSubType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDurationInSeconds();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getCompletedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.activity.FullActivity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.loupe.common.activity.FullActivity.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_closed = 3;
 * @return {boolean}
 */
proto.loupe.common.activity.FullActivity.prototype.getIsClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setIsClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_completed = 4;
 * @return {boolean}
 */
proto.loupe.common.activity.FullActivity.prototype.getIsCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setIsCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional ActivityType type = 5;
 * @return {!proto.loupe.common.activity.ActivityType}
 */
proto.loupe.common.activity.FullActivity.prototype.getType = function() {
  return /** @type {!proto.loupe.common.activity.ActivityType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.common.activity.ActivityType} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional ActivityDirection direction = 6;
 * @return {!proto.loupe.common.activity.ActivityDirection}
 */
proto.loupe.common.activity.FullActivity.prototype.getDirection = function() {
  return /** @type {!proto.loupe.common.activity.ActivityDirection} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.loupe.common.activity.ActivityDirection} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated loupe.common.person.Person owners = 7;
 * @return {!Array<!proto.loupe.common.person.Person>}
 */
proto.loupe.common.activity.FullActivity.prototype.getOwnersList = function() {
  return /** @type{!Array<!proto.loupe.common.person.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_person_person_pb.Person, 7));
};


/**
 * @param {!Array<!proto.loupe.common.person.Person>} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
*/
proto.loupe.common.activity.FullActivity.prototype.setOwnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.loupe.common.person.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.person.Person}
 */
proto.loupe.common.activity.FullActivity.prototype.addOwners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.loupe.common.person.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.clearOwnersList = function() {
  return this.setOwnersList([]);
};


/**
 * repeated loupe.common.person.Person participants = 8;
 * @return {!Array<!proto.loupe.common.person.Person>}
 */
proto.loupe.common.activity.FullActivity.prototype.getParticipantsList = function() {
  return /** @type{!Array<!proto.loupe.common.person.Person>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_person_person_pb.Person, 8));
};


/**
 * @param {!Array<!proto.loupe.common.person.Person>} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
*/
proto.loupe.common.activity.FullActivity.prototype.setParticipantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.loupe.common.person.Person=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.person.Person}
 */
proto.loupe.common.activity.FullActivity.prototype.addParticipants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.loupe.common.person.Person, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.clearParticipantsList = function() {
  return this.setParticipantsList([]);
};


/**
 * optional string source = 9;
 * @return {string}
 */
proto.loupe.common.activity.FullActivity.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string subject = 10;
 * @return {string}
 */
proto.loupe.common.activity.FullActivity.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string description = 11;
 * @return {string}
 */
proto.loupe.common.activity.FullActivity.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string status = 12;
 * @return {string}
 */
proto.loupe.common.activity.FullActivity.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string call_type = 13;
 * @return {string}
 */
proto.loupe.common.activity.FullActivity.prototype.getCallType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setCallType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string sub_type = 14;
 * @return {string}
 */
proto.loupe.common.activity.FullActivity.prototype.getSubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setSubType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 duration_in_seconds = 15;
 * @return {number}
 */
proto.loupe.common.activity.FullActivity.prototype.getDurationInSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.setDurationInSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional loupe.common.nullable.NullTimestamp completed_at = 16;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.activity.FullActivity.prototype.getCompletedAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 16));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
*/
proto.loupe.common.activity.FullActivity.prototype.setCompletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.clearCompletedAt = function() {
  return this.setCompletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.activity.FullActivity.prototype.hasCompletedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.activity.FullActivity.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
*/
proto.loupe.common.activity.FullActivity.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.activity.FullActivity.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.activity.FullActivity.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.activity.FullActivity} returns this
*/
proto.loupe.common.activity.FullActivity.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.activity.FullActivity} returns this
 */
proto.loupe.common.activity.FullActivity.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.activity.FullActivity.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * @enum {number}
 */
proto.loupe.common.activity.ActivityDirection = {
  ZERODIRECTION: 0,
  NONE: 1,
  INCOMING: 2,
  OUTGOING: 3,
  BOTH: 4
};

/**
 * @enum {number}
 */
proto.loupe.common.activity.ActivityType = {
  ZEROTYPE: 0,
  UNKNOWN: 1,
  CALL: 2,
  MEETING: 3,
  MESSAGE: 4
};

goog.object.extend(exports, proto.loupe.common.activity);
