import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import LoginError from './loginError'
import { useRoutes } from '../../context/routes'

const AuthRedirect = (props) => {
  const location = useLocation()
  const search = queryString.parse(location.search)

  const { routes } = useRoutes()

  if (search.error) {
    if (search.error_description === 'INACTIVE_LICENSE') {
      return (
        <LoginError
          title="Invalid License"
          description="Your user account does not have access." />
      )
    } else if (search.error_description === 'USER_NOT_PROVISIONED') {
      return (
        <LoginError
          title="User Not Provisioned"
          description="Your user has not been configured to access Outreach Commit." />
      )
    } else {
      return (
        <LoginError
          title="Login Error"
          description={(search && search.error_description) || ''} />
      )
    }
  } else {
    let redirectTo = ''

    if (search?.sandbox) {
      redirectTo += `/${search.sandbox}`
    }

    if (search?.redirect) {
      redirectTo += search.redirect
    }

    if (search?.sandbox && redirectTo) {
      window.location.href = redirectTo
      return <></>
    }

    return <Redirect to={redirectTo || routes.default} />
  }
}

export default AuthRedirect
