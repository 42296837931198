import React, { useCallback, useState, useMemo } from 'react'
import SandboxMenu from './sandboxMenu'
import { useGrpcCallback } from '../../grpc'
import { toDeleteSandboxeRequest } from '../../grpc/converters'
import { useNotification } from '../../hooks/useNotification'
import Refresh from '../icons/refresh'
import classNames from 'classnames'
import { useSandboxes } from '../../context/sandboxes'
import { SandboxType } from '../../grpc/enums'
import { useAuth } from '../../context/auth'
import ActionModal from '../common/actionModal'
import { useModal } from '../../hooks/useModal'
import Circle from '../icons/circle'
import CircleEmpty from '../icons/circleEmpty'
import { secondsToDistanceToNow, secondsToFormattedDate } from '../../lib/dateFns'

const SandboxListItem = (props) => {
  const {
    modal,
    sandbox
  } = props

  const { tenantId } = useAuth()
  const { name, description, id, status, createdAt, initialType } = sandbox
  const deleteSandboxModal = useModal()

  const [fetching, setFetching] = useState(false)

  const { invalidate } = useSandboxes()
  const { notifySuccess, notifyError } = useNotification()

  const deleteRequest = toDeleteSandboxeRequest({
    tenantId,
    sandboxId: id
  })

  const deleteSandbox = useGrpcCallback({
    onError: () => {
      setFetching(false)
      notifyError('Unable to delete sandbox')
    },
    onSuccess: () => {
      setFetching(false)
      notifySuccess('Sandbox deleted!')
      invalidate()
    },
    onFetch: () => setFetching(true),
    grpcMethod: 'deleteSandbox',
    debug: false
  }, [notifySuccess, notifyError, invalidate])

  const deleteConfirmed = useCallback(() => {
    deleteSandbox(deleteRequest)
  }, [deleteSandbox, deleteRequest])

  const onDelete = useCallback(() => {
    deleteSandboxModal.setOpen(true)
  }, [])

  const type = useMemo(() => {
    switch (initialType) {
      case SandboxType.SANDBOX_TYPE_EMPTY:
        return 'Empty'
      case SandboxType.SANDBOX_TYPE_CLONE:
        return 'Production Clone'
      default:
    }
  }, [initialType])

  const statusIcon = useMemo(() => {
    switch (status) {
      case 'pending':
        return <CircleEmpty fill="#818e93" transform="scale(0.50)" />
      case 'active':
        return <Circle fill="#1dcf83" transform="scale(0.50)" />
      case 'error':
        return <Circle fill="#fb6c6a" transform="scale(0.50)" />
      default:
        return ''
    }
  }, [status])

  const statusLabel = useMemo(() => {
    switch (status) {
      case 'pending':
        return 'Pending'
      case 'active':
        return 'Active'
      case 'error':
        return 'Error'
      default:
        return ''
    }
  }, [status])

  return (
    <div className="tr hover:bg-color-51636a-05">
      <div className="td-menu">
        {fetching
          ? (<Refresh fill="#5951FF" className="animate-spin" />)
          : (<SandboxMenu onDelete={onDelete} modal={modal} sandbox={sandbox} />)}
      </div>
      <div
        className={classNames('td w-1/5', { 'text-color-c9ced0 italic': fetching })}>
        <div className="inline">{name}</div>
      </div>
      <div className={
        classNames(
          'td w-1/5 text-color-818e93 text-size-14px font-weight-300',
          { 'text-color-c9ced0 italic': fetching }
        )
      }>
        {description}
      </div>
      <div className="td w-1/5 flex items-center font-weight-300">
        {type}
      </div>
      <div className="td w-1/5 flex flex-col">
        <span className="font-weight-400">{secondsToFormattedDate(createdAt.seconds, false, 'MM/dd/yy')}</span>
        <span className="leading-none text-size-12px text-color-51636a font-weight-300">{secondsToDistanceToNow(createdAt.seconds, false)}</span>
      </div>
      <div className="td w-1/5 flex items-center">
        {statusIcon}
        <div className="text-color-818e93 text-size-12px font-weight-600" style={{ transform: 'translateY(1px)' }}>{statusLabel}</div>
      </div>

      <ActionModal
        modal={deleteSandboxModal}
        title="Warning"
        text="Are you sure you want to delete?"
        subText="This sandbox environment will be lost if you delete now. This cannot be undone."
        actionText="Delete"
        onAction={deleteConfirmed}
        onCancel={() => {
          deleteSandboxModal.setOpen(false)
        }} />
    </div>
  )
}

export default SandboxListItem
