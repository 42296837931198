import { permissionNames } from '../../constants/permissionNames'
import { toBustAuthCacheRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import { useGrpcCallback } from '../../grpc'
import { useJoyride } from '../../context/joyride'
import { useModal } from '../../hooks/useModal'
import { useNotification } from '../../hooks/useNotification'
import { usePermissions } from '../../context/permissions'
import { useSystemRoles } from '../../context/systemRoles'
import AddRoleModal from './addRoleModal'
import Badge from '../icons/badge'
import Button from '../common/button'
import EmptyState from '../common/emptyState'
import Header from '../header/header'
import LoadingState from '../common/loadingState'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import RolesList from './rolesList'
import { rolesGuide } from '../../constants/externalUrls'
import PageDescription from '../pageDescription/pageDescription'

const RolesMain = (props) => {
  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessInternalAdmin,
      permissionNames.CanReadSystemRole,
      permissionNames.CanCreateSystemRole
    )
  }, [checkPermissions])

  const { tenantId } = useAuth()

  const addRoleModal = useModal()

  const { isFetching, systemRoles } = useSystemRoles()

  const { notifyError, notifySuccess } = useNotification()

  const { enabled: joyrideEnabled, setJoyride, start: startJoyride } = useJoyride()

  const configureJoyride = useCallback(() => {
    setJoyride([
      {
        target: '#joyride_roles_add',
        title: 'Add a New Role',
        content: 'You can add a new role by clicking this button. You will need to select a base role to model the new role after.',
        offset: 0,
        placement: 'left'
      },
      {
        target: '#joyride_roles_rolesListItem .td-menu',
        title: 'Manage Roles',
        content: 'Manage role permissions or duplicate a role by clicking here.',
        offset: 0,
        placement: 'right'
      }
    ])
    startJoyride()
  }, [setJoyride, startJoyride])

  useEffect(() => {
    if (joyrideEnabled && !isFetching) {
      configureJoyride()
    }
  }, [configureJoyride, isFetching, joyrideEnabled])

  const count = useMemo(() => {
    return systemRoles.length
  }, [systemRoles])

  const showEmptyState = useMemo(() => {
    return !isFetching && count === 0
  }, [isFetching, count])

  const onNewRole = useCallback(() => {
    window.analytics.track('New Role Clicked')
    addRoleModal.setOpen(true)
  }, [addRoleModal])

  const [disableClearAuthCache, setDisableClearAuthCache] = useState(false)

  const bustAuthCache = useGrpcCallback({
    onError: () => {
      notifyError('Error clearing auth cache!')
    },
    onSuccess: () => {
      notifySuccess('Auth cache cleared!')
      setDisableClearAuthCache(true)
    },
    grpcMethod: 'bustAuthCache',
    debug: false,
  }, [])

  const onClearAuthCache = useCallback(() => {
    const request = toBustAuthCacheRequest({
      tenantId
    })
    bustAuthCache(request)
    window.analytics.track('Clear Auth Cache Clicked')
  }, [bustAuthCache, tenantId])

  return (
    <div className="flex flex-col w-full h-screen">
      <Header
        title="Roles" />

      <div className="flex-grow overflow-auto">
        <PageDescription title="Roles in Commit"
          text={`Roles in Commit function to control who has access to specific features within your Commit instance.${' '}
          The default roles are Admin, Manager, and Rep. However, new roles can be created from these defaults and configured with unique permissions to customize user access.`}
          link={rolesGuide} />

        <div className="px-10 pb-10">
          {permissions.CanReadSystemRole && (
            <>
              <div className="flex items-center justify-between mt-8 mb-3">
                <div className="text-size-20px text-color-51636a font-weight-400">{!isFetching ? pluralize('role', count, true) : ''}</div>
                <div className="flex items-center">
                  {permissions.CanCreateSystemRole && (
                    <Button
                      id="joyride_roles_add"
                      text="New Role"
                      onClick={onNewRole} />
                  )}
                  {permissions.CanAccessInternalAdmin && (
                    <Button
                      className="ml-2"
                      size="xs"
                      text="Clear Auth Cache"
                      onClick={onClearAuthCache}
                      disabled={disableClearAuthCache} />
                  )}
                </div>
              </div>

              {showEmptyState
                ? (
                  <div className="flex justify-center my-10">
                    <EmptyState
                      iconControl={<Badge transform="scale(2)" />}
                      header="No Roles"
                      subHeader="There are no roles to display" />
                  </div>
                )
                : (
                  <>
                    {isFetching
                      ? (
                        <div className="flex justify-center my-10">
                          <LoadingState
                            header="Loading Roles"
                            subHeader="Please wait..."
                            animate={true} />
                        </div>
                      )
                      : (
                        <RolesList />
                      )}
                  </>
                )}

              <AddRoleModal modal={addRoleModal} />

            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default RolesMain
