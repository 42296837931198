import React, { useMemo } from 'react'
import classNames from 'classnames'

const Button = (props) => {
  const {
    className,
    size = 'sm',
    text,
    textColor = '818e93',
    backgroundColor,
    onClick,
    disabled = false,
    variant,
    ...rest
  } = props

  const sizeClasses = useMemo(() => {
    if (size === 'xs') {
      return 'px-6 py-2 text-size-14px'
    } else {
      return 'px-10 py-2 text-size-14px'
    }
  }, [size])

  if (variant === 'text') {
    return (
      <button
        className={classNames(
          className,
          `text-color-${textColor} text-size-16px px-4 whitespace-nowrap focus:outline-none`,
          { 'opacity-25 pointer-events-none': disabled }
        )}
        onClick={onClick}
        {...rest}>
        {text}
      </button>
    )
  }

  if (variant === 'outline') {
    return (
      <button
        className={classNames(
          className,
          'text-color-5951FF text-size-16px rounded-full px-4 border-2 border-color-5951FF',
          { 'opacity-25 pointer-events-none': disabled }
        )}
        onClick={onClick}
        {...rest}>
        {text}
      </button>
    )
  }

  return (
    <button
      className={classNames(
        className,
        sizeClasses,
        'text-color-ffffff font-bold rounded-full shadow-btn whitespace-nowrap focus:outline-none',
        { 'opacity-25 pointer-events-none': disabled }
      )}
      style={backgroundColor ? { backgroundColor } : { backgroundColor: '#5951FF' }}
      onClick={onClick}
      {...rest}>
      {text}
    </button>
  )
}

export default Button
