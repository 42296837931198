// source: src/common/auth/auth.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.loupe.common.auth.Permission', null, global);
goog.exportSymbol('proto.loupe.common.auth.PermissionExtended', null, global);
goog.exportSymbol('proto.loupe.common.auth.PermissionSet', null, global);
goog.exportSymbol('proto.loupe.common.auth.PermissionSetExtended', null, global);
goog.exportSymbol('proto.loupe.common.auth.PermissionWrapper', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.auth.PermissionWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.auth.PermissionWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.auth.PermissionWrapper.displayName = 'proto.loupe.common.auth.PermissionWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.auth.PermissionSetExtended = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.auth.PermissionSetExtended.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.auth.PermissionSetExtended, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.auth.PermissionSetExtended.displayName = 'proto.loupe.common.auth.PermissionSetExtended';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.auth.PermissionExtended = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.auth.PermissionExtended, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.auth.PermissionExtended.displayName = 'proto.loupe.common.auth.PermissionExtended';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.auth.PermissionWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.auth.PermissionWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.auth.PermissionWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.auth.PermissionWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionSet: jspb.Message.getFieldWithDefault(msg, 1, 0),
    permission: jspb.Message.getFieldWithDefault(msg, 2, 0),
    permissionValue: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.auth.PermissionWrapper}
 */
proto.loupe.common.auth.PermissionWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.auth.PermissionWrapper;
  return proto.loupe.common.auth.PermissionWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.auth.PermissionWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.auth.PermissionWrapper}
 */
proto.loupe.common.auth.PermissionWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.auth.PermissionSet} */ (reader.readEnum());
      msg.setPermissionSet(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.auth.Permission} */ (reader.readEnum());
      msg.setPermission(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPermissionValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.auth.PermissionWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.auth.PermissionWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.auth.PermissionWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.auth.PermissionWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionSet();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPermissionValue();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional PermissionSet permission_set = 1;
 * @return {!proto.loupe.common.auth.PermissionSet}
 */
proto.loupe.common.auth.PermissionWrapper.prototype.getPermissionSet = function() {
  return /** @type {!proto.loupe.common.auth.PermissionSet} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.auth.PermissionSet} value
 * @return {!proto.loupe.common.auth.PermissionWrapper} returns this
 */
proto.loupe.common.auth.PermissionWrapper.prototype.setPermissionSet = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Permission permission = 2;
 * @return {!proto.loupe.common.auth.Permission}
 */
proto.loupe.common.auth.PermissionWrapper.prototype.getPermission = function() {
  return /** @type {!proto.loupe.common.auth.Permission} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.auth.Permission} value
 * @return {!proto.loupe.common.auth.PermissionWrapper} returns this
 */
proto.loupe.common.auth.PermissionWrapper.prototype.setPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 permission_value = 3;
 * @return {number}
 */
proto.loupe.common.auth.PermissionWrapper.prototype.getPermissionValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.auth.PermissionWrapper} returns this
 */
proto.loupe.common.auth.PermissionWrapper.prototype.setPermissionValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.auth.PermissionSetExtended.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.auth.PermissionSetExtended.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.auth.PermissionSetExtended.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.auth.PermissionSetExtended} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.auth.PermissionSetExtended.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionSet: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.loupe.common.auth.PermissionExtended.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.auth.PermissionSetExtended}
 */
proto.loupe.common.auth.PermissionSetExtended.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.auth.PermissionSetExtended;
  return proto.loupe.common.auth.PermissionSetExtended.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.auth.PermissionSetExtended} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.auth.PermissionSetExtended}
 */
proto.loupe.common.auth.PermissionSetExtended.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.auth.PermissionSet} */ (reader.readEnum());
      msg.setPermissionSet(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.loupe.common.auth.PermissionExtended;
      reader.readMessage(value,proto.loupe.common.auth.PermissionExtended.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.auth.PermissionSetExtended.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.auth.PermissionSetExtended.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.auth.PermissionSetExtended} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.auth.PermissionSetExtended.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionSet();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.loupe.common.auth.PermissionExtended.serializeBinaryToWriter
    );
  }
};


/**
 * optional PermissionSet permission_set = 1;
 * @return {!proto.loupe.common.auth.PermissionSet}
 */
proto.loupe.common.auth.PermissionSetExtended.prototype.getPermissionSet = function() {
  return /** @type {!proto.loupe.common.auth.PermissionSet} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.auth.PermissionSet} value
 * @return {!proto.loupe.common.auth.PermissionSetExtended} returns this
 */
proto.loupe.common.auth.PermissionSetExtended.prototype.setPermissionSet = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.loupe.common.auth.PermissionSetExtended.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.auth.PermissionSetExtended} returns this
 */
proto.loupe.common.auth.PermissionSetExtended.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.loupe.common.auth.PermissionSetExtended.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.auth.PermissionSetExtended} returns this
 */
proto.loupe.common.auth.PermissionSetExtended.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated PermissionExtended permissions = 4;
 * @return {!Array<!proto.loupe.common.auth.PermissionExtended>}
 */
proto.loupe.common.auth.PermissionSetExtended.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.loupe.common.auth.PermissionExtended>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.auth.PermissionExtended, 4));
};


/**
 * @param {!Array<!proto.loupe.common.auth.PermissionExtended>} value
 * @return {!proto.loupe.common.auth.PermissionSetExtended} returns this
*/
proto.loupe.common.auth.PermissionSetExtended.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.auth.PermissionExtended=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.auth.PermissionExtended}
 */
proto.loupe.common.auth.PermissionSetExtended.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.auth.PermissionExtended, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.auth.PermissionSetExtended} returns this
 */
proto.loupe.common.auth.PermissionSetExtended.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.auth.PermissionExtended.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.auth.PermissionExtended.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.auth.PermissionExtended} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.auth.PermissionExtended.toObject = function(includeInstance, msg) {
  var f, obj = {
    permission: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hasPermission: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.auth.PermissionExtended}
 */
proto.loupe.common.auth.PermissionExtended.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.auth.PermissionExtended;
  return proto.loupe.common.auth.PermissionExtended.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.auth.PermissionExtended} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.auth.PermissionExtended}
 */
proto.loupe.common.auth.PermissionExtended.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.auth.Permission} */ (reader.readEnum());
      msg.setPermission(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.auth.PermissionExtended.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.auth.PermissionExtended.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.auth.PermissionExtended} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.auth.PermissionExtended.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHasPermission();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional Permission permission = 1;
 * @return {!proto.loupe.common.auth.Permission}
 */
proto.loupe.common.auth.PermissionExtended.prototype.getPermission = function() {
  return /** @type {!proto.loupe.common.auth.Permission} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.auth.Permission} value
 * @return {!proto.loupe.common.auth.PermissionExtended} returns this
 */
proto.loupe.common.auth.PermissionExtended.prototype.setPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.loupe.common.auth.PermissionExtended.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.auth.PermissionExtended} returns this
 */
proto.loupe.common.auth.PermissionExtended.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.loupe.common.auth.PermissionExtended.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.auth.PermissionExtended} returns this
 */
proto.loupe.common.auth.PermissionExtended.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool has_permission = 4;
 * @return {boolean}
 */
proto.loupe.common.auth.PermissionExtended.prototype.getHasPermission = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.auth.PermissionExtended} returns this
 */
proto.loupe.common.auth.PermissionExtended.prototype.setHasPermission = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.auth.Permission = {
  ACCESS: 0,
  READ: 1,
  UPDATE: 2,
  CREATE: 3,
  DELETE: 4,
  MANAGE: 5,
  SHARE: 6
};

/**
 * @enum {number}
 */
proto.loupe.common.auth.PermissionSet = {
  INTERNALADMIN: 0,
  ADMIN: 1,
  SYNC: 2,
  TENANT: 3,
  LINKEDACCOUNT: 4,
  GROUP: 5,
  USER: 6,
  SIGNAL: 7,
  NOTIFICATION: 8,
  WEBAPP: 9,
  DEAL: 10,
  ANALYTIC: 11,
  SCENARIOPLANNER: 12,
  FORECAST: 13,
  NOTE: 14,
  GOAL: 15,
  SYSTEMROLE: 16,
  SANDBOX: 17
};

goog.object.extend(exports, proto.loupe.common.auth);
