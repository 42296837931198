import React from 'react'

const DoubleArrow = (props) => {
  const {
    fill = '#ffffff',
  } = props

  return (
    <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...{ ...props, fill }}>
      <path d="M3.91314 8.10391L0.249547 11.7807C-0.000842184 12.0443 -0.000842184 12.4528 0.249547 12.7164L3.91314 16.3931C4.32167 16.8148 5.0333 16.5117 5.0333 15.9319V13.5598H21.9535C22.6783 13.5598 23.2714 12.9667 23.2714 12.2419C23.2714 11.5171 22.6783 10.9241 21.9535 10.9241H5.0333V8.56516C5.0333 7.97213 4.32167 7.6822 3.91314 8.10391ZM23.035 3.87365L19.3714 0.196878C18.9629 -0.22483 18.2512 0.0782724 18.2512 0.658122V3.01705H1.31784C0.593028 3.01705 0 3.61008 0 4.33489C0 5.0597 0.593028 5.65273 1.31784 5.65273H18.2381V8.01166C18.2381 8.60469 18.9497 8.89462 19.3582 8.47291L23.0218 4.79614C23.2854 4.54575 23.2854 4.12404 23.035 3.87365Z" fill="#51636A" />
    </svg>
  )
}

export default DoubleArrow
