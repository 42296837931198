import React from 'react'
import classNames from 'classnames'

const Initials = (props) => {
  const {
    className = '',
    firstInitial = '',
    lastInitial = '',
  } = props

  return (
    <div
      style={{ width: 28, height: 28 }}
      className={classNames(className, 'flex items-center justify-center rounded-full flex-shrink-0 bg-color-93aafc text-size-14px text-color-ffffff font-weight-500')}>
      {`${firstInitial}${lastInitial}`}
    </div>
  )
}

export default Initials
