import React, { useRef, useCallback, useState, useMemo } from 'react'
import { Calendar } from 'react-date-range'
import { format, formatISO, parseISO } from 'date-fns'
import { Popover } from '@material-ui/core'
import ChevronDown from '../../icons/chevronDown'
import { standard } from '../../constants/standard'
import classNames from 'classnames'

const FieldDate = (props) => {
  const {
    value,
    onChange,
    maxDate,
    isTodayButtonDisabled
  } = props

  const fieldValue = useMemo(() => {
    return value?.[0]?.value
  }, [value])

  const initDate = useMemo(() => {
    if (fieldValue === standard.today) {
      return new Date()
    } else if (fieldValue) {
      return parseISO(fieldValue)
    }
  }, [fieldValue])

  const [date, setDate] = useState(initDate)
  const [previewDate, setPreviewDate] = useState(undefined)
  const [open, setOpen] = useState(false)

  const selectedDateDisplay = useMemo(() => {
    if (fieldValue === standard.today) {
      return 'Today'
    } else if (date) {
      return format(date, 'MM/dd/yyyy')
    }
  }, [fieldValue, date])

  const selectedDatePreview = useMemo(() => {
    if (!previewDate && !date) {
      return ''
    } else if (previewDate) {
      return format(previewDate, 'MM/dd/yyyy')
    } else if (fieldValue === standard.today) {
      return 'Today'
    } else if (date) {
      return format(date, 'MM/dd/yyyy')
    }
  }, [previewDate, date, fieldValue])

  const menu = useRef()

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onChangeInternal = useCallback((date) => {
    setDate(date)
    handleClose()
    onChange([{ value: formatISO(date, { representation: 'date' }) }])
  }, [handleClose, onChange])

  const onPreviewChange = useCallback((date) => {
    setPreviewDate(date)
  }, [])

  const onTodayClick = useCallback(() => {
    setDate(new Date())
    handleClose()
    onChange([{ value: standard.today }])
  }, [handleClose, onChange])

  return (
    <div className="range-fields flex-1">
      <button
        className="field field-select"
        ref={menu}
        onClick={handleOpen}>
        {date
          ? <span>{selectedDateDisplay}</span>
          : <span>Select</span>}
        <ChevronDown fill="#A6B2CF" className="ml-4" />
      </button>
      <Popover
        open={open}
        anchorEl={menu.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <div className="DateRangeFilter-DateRangeWrapper">
          <div
            className="px-3 flex items-center text-size-13px justify-between"
            style={{ transform: 'translateY(10px)' }}>
            <div className="text-size-14px font-weight-400">{selectedDatePreview}</div>
            <button
              onClick={onTodayClick}
              className={classNames('px-2 py-1 border border-color-91959f rounded',
                { 'border-color-5951FF text-color-5951FF': fieldValue === standard.today })}
              disabled={isTodayButtonDisabled}>
              Today
            </button>
          </div>
          <Calendar
            color="#5951FF"
            showDateDisplay={false}
            editableDateInputs={false}
            onChange={onChangeInternal}
            onPreviewChange={onPreviewChange}
            moveRangeOnFirstSelection={false}
            date={date}
            maxDate={maxDate} />
        </div>
      </Popover>
    </div>
  )
}

export default FieldDate
