import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { useAuth } from '../../context/auth'
import SideMenuItem from './sideMenuItem'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'
import ChevronDown from '../icons/chevronDown'
import { find, has } from 'lodash'
import useKeyPressEffect from '../../hooks/useKeyPressEffect'
import { useLoggedInUser } from '../../context/loggedInUser'
import { featureNames } from '../constants/featureNames'

const SystemAdminMenu = (props) => {
  const { checkPermissions } = usePermissions()
  const { routes } = useRoutes()
  const { settings, hasSettings } = useLoggedInUser()

  const { setTenantContext } = useAuth()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessTenant,
      permissionNames.CanAccessAudit,
      permissionNames.CanAccessForecasts,
      permissionNames.CanAccessInternalAdmin,
      permissionNames.CanAccessSync,
      permissionNames.CanAccessSystemRole,
      permissionNames.CanAccessAudit,
      permissionNames.CanAccessForecasts
    )
  }, [checkPermissions])

  const forecastManagementEnabled = useMemo(() => {
    const forecastManagementSetting = find(settings, (s) => s.key === featureNames.forecastManagement)
    return forecastManagementSetting?.payload?.enabled
  }, [settings])

  const history = useHistory()

  const tenantsMatch = useRouteMatch(routes.tenants)
  const analyticsSettingsMatch = useRouteMatch(routes.analyticsSettings)
  const dealGridMatch = useRouteMatch(routes.dealGridSettings)
  const forecastingSettingsMatch = useRouteMatch(routes.forecastingSettings)
  const scenarioPlannerSettingsMatch = useRouteMatch(routes.scenarioPlannerSettings)
  const auditMatch = useRouteMatch(routes.audit)
  const forecastsMatch = useRouteMatch(routes.forecasts)
  const outreachSettingsMatch = useRouteMatch(routes.outreachSettings)
  const hierarchyMatch = useRouteMatch(routes.hierarchy)

  const [open, setOpen] = useState(false)

  const handleClick = useCallback((action, route) => {
    if (route === routes.tenants) {
      setTenantContext(undefined, routes.tenants)
    }
    window.analytics.track('admin.sideMenuClicked', { action })
    history.push(route)
  }, [history])

  const toggleOpen = useCallback(() => {
    setOpen(!open)
    const openMenus = JSON.parse(sessionStorage.getItem('openMenus') || '{}')
    if (open) {
      delete openMenus.systemAdmin
    } else {
      openMenus.systemAdmin = true
    }
    setOpen(!open)
    sessionStorage.setItem('openMenus', JSON.stringify(openMenus))
  }, [open])

  useEffect(() => {
    const openMenus = JSON.parse(sessionStorage.getItem('openMenus') || '{}')
    if (has(openMenus, 'systemAdmin')) {
      setOpen(true)
    }
  }, [])

  useKeyPressEffect({
    targetKey: 't',
    onDoubleTap: () => {
      history.push(routes.tenants)
    }
  }, [])

  return (
    <>
      {permissions.CanAccessInternalAdmin
        && (
          <>
            <div
              className="flex items-center justify-between pl-6 pr-3 py-3 cursor-pointer select-none"
              onClick={toggleOpen}>
              <span className="sidemenu-group-header">System Admin</span>
              <div style={{ transform: 'translateY(-1px)' }}>
                <div style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                  <ChevronDown fill="#a0a8bb" />
                </div>
              </div>
            </div>
            {open && (
              <>
                {permissions.CanAccessTenant && (
                  <SideMenuItem
                    label="Tenants"
                    isCurrent={tenantsMatch}
                    onClick={handleClick.bind(this, 'Tenants', routes.tenants)} />
                )}

                <SideMenuItem
                  label="Features"
                  isCurrent={(analyticsSettingsMatch && analyticsSettingsMatch.isExact)
                    || (dealGridMatch && dealGridMatch.isExact)
                    || (forecastingSettingsMatch && forecastingSettingsMatch.isExact)
                    || (scenarioPlannerSettingsMatch && scenarioPlannerSettingsMatch.isExact)
                    || (outreachSettingsMatch && outreachSettingsMatch.isExact)
                    || (hierarchyMatch && hierarchyMatch.isExact)}
                  onClick={handleClick.bind(this, 'Features', routes.dealGridSettings)} />

                {hasSettings ? (
                  <>
                    {!forecastManagementEnabled && permissions.CanAccessForecasts && (
                      <SideMenuItem
                        label="Forecasts"
                        isCurrent={forecastsMatch}
                        onClick={handleClick.bind(this, 'Forecasts', routes.forecasts)} />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
    </>
  )
}

export default SystemAdminMenu
