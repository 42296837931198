import FieldSelect from './fields/fieldSelect'
import FieldMultiSelect from './fields/fieldMultiSelect'
import FieldInput from './fields/fieldInput'
import FieldMultiInput from './fields/fieldMultiInput'
import FieldInputRange from './fields/fieldInputRange'
import FieldDateRange from './fields/fieldDateRange'
import FieldDate from './fields/fieldDate'
import FieldEmpty from './fields/fieldEmpty'
import {
  FilterCombiner,
  ParameterType,
  FieldType
} from '../../protos/src/common/realtime/query_pb'
import { InputType } from './hooks'
import { v4 as guid } from 'uuid'

const getEmptyFilter = () => {
  return {
    id: guid(),
    type: ParameterType.PARAMETER_TYPE_FILTER
  }
}

const getEmptyGroup = () => {
  return {
    id: guid(),
    type: ParameterType.PARAMETER_TYPE_GROUP,
    operator: FilterCombiner.AND,
    conditions: [getEmptyFilter()]
  }
}

const getEmptyFilterGroup = () => {
  return {
    id: guid(),
    conditions: [getEmptyGroup()]
  }
}

const getConditionalOptions = (field = 'STRING') => {
  const { type, fieldType } = field
  const selectedType = Object.values(InputType)
    .find(({ key }) => key === type)

  if (!selectedType?.operators) {
    return []
  }

  return selectedType.operators
    .filter(({ fieldTypes }) => fieldTypes.includes(fieldType))
}

const getInputForField = (field, operator) => {
  const { fieldType: fieldTypeKey, valuesList = [], fieldReference } = field
  const { inputTypes } = operator

  if (inputTypes.includes(InputType.RANGE)) {
    const isDateType = [
      FieldType.FIELD_TYPE_DATE,
      FieldType.FIELD_TYPE_TIMESTAMP
    ]
      .includes(fieldTypeKey)
    return (isDateType)
      ? FieldDateRange
      : FieldInputRange
  }

  if (inputTypes.includes(InputType.SELECT)
    && (valuesList.length || fieldReference)) {
    return FieldSelect
  }

  if (inputTypes.includes(InputType.MULTI_SELECT)
    && (valuesList.length || fieldReference)) {
    return FieldMultiSelect
  }

  if (inputTypes.includes(InputType.MULTI_INPUT)
    && !valuesList.length) {
    return FieldMultiInput
  }

  if (inputTypes.includes(InputType.INPUT)) {
    if (fieldTypeKey === FieldType.FIELD_TYPE_DATE) {
      return FieldDate
    }

    return FieldInput
  }

  return FieldEmpty
}

const byId = (_id, stack) => {
  return stack.findIndex(({ id }) => id === _id)
}

export {
  getEmptyFilterGroup,
  getEmptyGroup,
  getEmptyFilter,
  getInputForField,
  getConditionalOptions,
  byId
}
