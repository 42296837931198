import { useState, useCallback, useMemo, useEffect } from 'react'

export const defaultProps = {
  defaultValue: '',
  validator: (v) => v && v.trim() !== '',
  validatorMessage: 'Required',
}

export function useTextField(props) {
  const {
    defaultValue = defaultProps.defaultValue,
    validator = defaultProps.validator,
    validatorMessage = defaultProps.validatorMessage,
    onReset,
  } = props || {}

  const [value, setValue] = useState(defaultValue)
  const [valueChanged, setValueChanged] = useState(false)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const escapedValue = useMemo(() => {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }, [value])

  const onChange = useCallback((e) => {
    setValue(e.target.value)
    setValueChanged(true)
  }, [])

  const isValid = useCallback(() => {
    setValueChanged(true)
    return validator(value)
  }, [value])

  const helperText = useMemo(() => {
    return !validator(value) && valueChanged ? validatorMessage : ''
  }, [value, valueChanged])

  const reset = useCallback(() => {
    setValue(defaultValue)
    setValueChanged(false)
    onReset && onReset()
  }, [defaultValue])

  return {
    reset,
    value,
    setValue,
    valueChanged,
    setValueChanged,
    onChange,
    isValid,
    helperText,
    escapedValue,
  }
}
