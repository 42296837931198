import React, { useCallback, useState } from 'react'
import Cancel from '../icons/cancel'
import Search from '../icons/search'
import classNames from 'classnames'
import useKeyPressEffect from '../../hooks/useKeyPressEffect'

const SearchBox = (props) => {
  const {
    inputWidth,
    className,
    value,
    onChange,
    onClear,
    placeholder = 'Search',
    autoFocus,
    disabled,
    enableKeyboard = true,
  } = props

  const [showHint, setShowHint] = useState(!autoFocus)

  const onInputFocus = useCallback(() => {
    setShowHint(false)
  }, [])

  const onInputBlur = useCallback(() => {
    setShowHint(!value.length && true)
  }, [value])

  const onClearInternal = useCallback(() => {
    onClear && onClear()
    setShowHint(true)
  }, [onClear])

  const setFocusOnSearch = useCallback(() => {
    if (!enableKeyboard) {
      return
    }
    const searchBox = document.getElementById('searchBox')
    searchBox?.focus()
  }, [enableKeyboard])

  useKeyPressEffect({
    targetKey: '/',
    onDown: setFocusOnSearch
  }, [setFocusOnSearch])

  return (
    <div className={className}>
      <div className="w-full relative">
        <div className="absolute flex justify-between w-full pointer-events-none">
          <div style={{ transform: 'translate(7px, 5px)' }}><Search className="flex-shrink-0" fill="#a0a8bb" /></div>
          {value.length > 0
            && (
              <button
                className="text-color-a0a8bb hover:text-color-151d49 focus:outline-none pointer-events-auto"
                onClick={onClearInternal}>
                <div style={{ transform: 'scale(0.75) translate(-3px, 6px)' }}><Cancel className="flex-shrink-0" fill="#a0a8bb" /></div>
              </button>
            )}
        </div>
        <input
          style={{ width: inputWidth }}
          id="searchBox"
          className={classNames('z-0 w-full outline-none border border-color-d6d9e6 rounded-full pl-8 pr-6 py-1', { 'pointer-events-none opacity-50': disabled })}
          autoComplete="off"
          onChange={onChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          value={value}
          type="text"
          placeholder={placeholder}
          autoFocus={autoFocus}
          disabled={disabled} />
        {enableKeyboard && (
          <div
            style={{ top: '7px', right: '12px', lineHeight: '18px' }}
            className={classNames(
              'absolute rounded text-center text-color-a0a8bb border border-color-a0a8bb font-bold w-5 h-5',
              { hidden: !showHint }
            )}>
            /
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchBox
