import {
  ForecastMode,
  ForecastPeriodType,
  ForecastConfigColumnDisplayOption,
  ForecastConfigColumnDisplayVisibilityOption
} from '../../grpc/enums'

export const forecastModes = [
  {
    label: 'Automatically',
    value: ForecastMode.AUTO,
    description: 'Deals are automatically calculated based on category.'
  },
  {
    label: 'Manually',
    value: ForecastMode.MANUAL,
    description: 'Deals must be manually added to the forecast.'
  },
]

export const forecastPeriodLengths = [
  {
    label: 'Organization Default',
    value: ForecastPeriodType.PERIOD
  },
  {
    label: 'Monthly',
    value: ForecastPeriodType.MONTH
  },
  {
    label: 'Quarterly',
    value: ForecastPeriodType.QUARTER
  },
  {
    label: 'Semi-annually',
    value: ForecastPeriodType.HALF_YEAR
  },
  {
    label: 'Annually',
    value: ForecastPeriodType.YEAR
  }
]

export const forecastConfigColumnDisplayOptions = [
  {
    label: 'Summary',
    value: ForecastConfigColumnDisplayOption.BANNER,
    description: 'Displays your company total at the top of the forecast.',
    config: [1, 0]
  },
  {
    label: 'Details',
    value: ForecastConfigColumnDisplayOption.GRID,
    description: 'Displays individual totals by team or people.',
    config: [0, 1]
  },
  {
    label: 'Summary & Details',
    value: ForecastConfigColumnDisplayOption.BANNER_GRID,
    description: 'Displays both company total and individual totals.',
    config: [1, 1]
  }
]

export const defaultForecastConfigColumnDisplayOption = forecastConfigColumnDisplayOptions.find((option) => option.value === ForecastConfigColumnDisplayOption.BANNER_GRID)

export const forecastConfigColumnDisplayVisibilityOptions = [
  {
    label: 'All',
    value: ForecastConfigColumnDisplayVisibilityOption.ALL
  },
  {
    label: 'Managers',
    value: ForecastConfigColumnDisplayVisibilityOption.MANAGER
  },
  {
    label: 'Reps',
    value: ForecastConfigColumnDisplayVisibilityOption.IC
  }
]
