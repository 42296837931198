import { useCallback, useEffect, useState } from 'react'

export const MessagesType = {
  EVENT: 0,
  DATA: 1,
  AUTH: 2
}

export const MessagesEvent = {
  UNKNOWN: 0,
  READY: 1,
  UNAUTHORIZED: 2
}

export function useMessages(targetOrigin) {
  const [contentWindow, setContentWindow] = useState(null)
  const [messages, setMessages] = useState([])
  const [isReady, setisReady] = useState(false)
  const [receiverReady, setReceiverReady] = useState(false)
  const [authRequest, setAuthRequest] = useState(null)

  const attach = useCallback((cw) => {
    setContentWindow(cw)
    setisReady(true)
  }, [])

  const clearAuthRequest = useCallback(() => {
    setAuthRequest(null)
  }, [])

  const interceptMessage = useCallback((data = {}) => {
    let messageData
    let type
    let body

    try {
      messageData = JSON.parse(data)
    } catch (_) {
      messageData = data
    } finally {
      ({ type, body } = messageData)
    }

    switch (type) {
      case MessagesType.EVENT:
        setReceiverReady(body === MessagesEvent.READY)
        break
      case MessagesType.AUTH:
        setAuthRequest(body)
        break
      case MessagesType.DATA:
        setMessages([...messages, body])
        break
      default:
        // malformed messages event, ignore
    }
  }, [messages])

  const onMessageReceived = useCallback(({ origin, data }) => {
    if (origin !== targetOrigin) {
      return
    }

    interceptMessage(data)
  }, [targetOrigin, interceptMessage])

  const postMessageInternal = useCallback((messageObject) => {
    contentWindow.postMessage(messageObject, targetOrigin)
  }, [contentWindow, targetOrigin])

  const postAuth = useCallback((auth) => {
    postMessageInternal({
      type: MessagesType.AUTH,
      body: auth
    })
  }, [postMessageInternal])

  const postEvent = useCallback((event) => {
    postMessageInternal({
      type: MessagesType.EVENT,
      body: event
    })
    if (event === MessagesEvent.UNAUTHORIZED) {
      clearAuthRequest()
    }
  }, [clearAuthRequest, postMessageInternal])

  const postMessage = useCallback((message) => {
    postMessageInternal({
      type: MessagesType.DATA,
      body: message
    })
  }, [postMessageInternal])

  useEffect(() => {
    window.addEventListener('message', onMessageReceived, false)
    return () => {
      window.removeEventListener('message', onMessageReceived, false)
    }
  }, [onMessageReceived])

  return {
    attach,
    authRequest,
    clearAuthRequest,
    isReady,
    receiverReady,
    messages,
    postAuth,
    postEvent,
    postMessage
  }
}
