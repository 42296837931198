import React from 'react'

const ModalError = (props) => {
  const {
    text,
  } = props

  return (
    <div className="flex justify-center bg-color-fa6d6b text-size-16px text-color-ffffff font-weight-600 py-2 mt-6">
      {text}
    </div>
  )
}

export default ModalError
