import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export function useBase64EncodedObjectParam(paramName) {
  const params = useParams()

  const object = useMemo(() => {
    const { data } = params
    try {
      const json = atob(params[paramName])
      const obj = JSON.parse(json)
      return obj
    } catch {
      return {}
    }
  }, [params])

  return {
    object,
  }
}
