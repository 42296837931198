import React from 'react'
import Label from './label'
import { useTextField } from '../../hooks/useTextField'

const FileUpload = (props) => {
  const {
    label,
    text = 'Select File',
    inputValue,
    accept,
    onChange,
  } = props

  const fileName = useTextField()

  return (
    <>
      <div className="flex justify-between">
        <Label text={label} />
        <label
          className="text-size-14px text-color-2e5bff font-weight-600 cursor-pointer">
          <input
            type="file"
            style={{ display: 'none' }}
            accept={accept}
            onChange={onChange} />
          {text}
        </label>
      </div>
      <input
        className="w-full px-3 py-2 mb-6 text-color-818e93 border border-color-d6d9e6 focus:outline-none rounded"
        value={inputValue}
        disabled={true} />
    </>
  )
}

export default FileUpload
