import React, { useCallback, useState } from 'react'
import classNames from 'classnames'

const Avatar = (props) => {
  const {
    className,
    imageUrl,
    initials,
  } = props

  const [showDefault, setShowDefault] = useState(false)

  const handleError = useCallback((e) => {
    setShowDefault(true)
  }, [])

  return (
    <>
      {showDefault || !imageUrl
        ? (
          <div className={classNames(className,
            'flex flex-shrink-0 flex-grow-0 items-center justify-center rounded-full bg-color-b8bbc8 text-color-151d49 font-bold leading-none')}>
            {initials}
          </div>
        )
        : (
          <img className={classNames(className,
            'flex flex-shrink-0 flex-grow-0 items-center justify-center rounded-full bg-color-b8bbc8 text-color-151d49 font-bold leading-none')}
            src={imageUrl}
            onError={handleError}
            style={{ objectFit: 'cover' }} />
        )}
    </>
  )
}

export default Avatar
