import { defaultGetNodeKey } from '@nosferatu500/react-sortable-tree'
import { walk } from './utils/tree-data-utils'

export function getNodeById(treeData, id) {
  let n = null
  walk({
    treeData,
    getNodeKey: defaultGetNodeKey,
    ignoreCollapsed: false,
    callback: ({ node }) => {
      if (node.group.id === id) {
        n = node
      }
    },
  })
  return n
}
