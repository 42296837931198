// source: src/common/pipeline/pipeline_data.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_pipeline_stage_progress_pb = require('../../../src/common/pipeline/stage_progress_pb.js');
goog.object.extend(proto, src_common_pipeline_stage_progress_pb);
goog.exportSymbol('proto.loupe.common.pipeline.PipelineData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.pipeline.PipelineData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.pipeline.PipelineData.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.pipeline.PipelineData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.pipeline.PipelineData.displayName = 'proto.loupe.common.pipeline.PipelineData';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.pipeline.PipelineData.repeatedFields_ = [5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.pipeline.PipelineData.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.pipeline.PipelineData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.pipeline.PipelineData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.pipeline.PipelineData.toObject = function(includeInstance, msg) {
  var f, obj = {
    endOfCycle: jspb.Message.getFieldWithDefault(msg, 1, 0),
    total: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lostDeals: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pushedDeals: jspb.Message.getFieldWithDefault(msg, 4, 0),
    stagesList: jspb.Message.toObjectList(msg.getStagesList(),
    src_common_pipeline_stage_progress_pb.StageProgress.toObject, includeInstance),
    teamId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    forecastCategoriesList: jspb.Message.toObjectList(msg.getForecastCategoriesList(),
    src_common_pipeline_stage_progress_pb.StageProgress.toObject, includeInstance),
    won: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    toQuota: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    active: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    quota: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    annualWon: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    annualQuota: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.pipeline.PipelineData}
 */
proto.loupe.common.pipeline.PipelineData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.pipeline.PipelineData;
  return proto.loupe.common.pipeline.PipelineData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.pipeline.PipelineData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.pipeline.PipelineData}
 */
proto.loupe.common.pipeline.PipelineData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndOfCycle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLostDeals(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPushedDeals(value);
      break;
    case 5:
      var value = new src_common_pipeline_stage_progress_pb.StageProgress;
      reader.readMessage(value,src_common_pipeline_stage_progress_pb.StageProgress.deserializeBinaryFromReader);
      msg.addStages(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamId(value);
      break;
    case 7:
      var value = new src_common_pipeline_stage_progress_pb.StageProgress;
      reader.readMessage(value,src_common_pipeline_stage_progress_pb.StageProgress.deserializeBinaryFromReader);
      msg.addForecastCategories(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWon(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setToQuota(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActive(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuota(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAnnualWon(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAnnualQuota(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.pipeline.PipelineData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.pipeline.PipelineData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.pipeline.PipelineData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.pipeline.PipelineData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEndOfCycle();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLostDeals();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPushedDeals();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getStagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      src_common_pipeline_stage_progress_pb.StageProgress.serializeBinaryToWriter
    );
  }
  f = message.getTeamId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getForecastCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      src_common_pipeline_stage_progress_pb.StageProgress.serializeBinaryToWriter
    );
  }
  f = message.getWon();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getToQuota();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getActive();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getQuota();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getAnnualWon();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getAnnualQuota();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
};


/**
 * optional int32 end_of_cycle = 1;
 * @return {number}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getEndOfCycle = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.setEndOfCycle = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double total = 2;
 * @return {number}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.setTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 lost_deals = 3;
 * @return {number}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getLostDeals = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.setLostDeals = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 pushed_deals = 4;
 * @return {number}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getPushedDeals = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.setPushedDeals = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated StageProgress stages = 5;
 * @return {!Array<!proto.loupe.common.pipeline.StageProgress>}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getStagesList = function() {
  return /** @type{!Array<!proto.loupe.common.pipeline.StageProgress>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_pipeline_stage_progress_pb.StageProgress, 5));
};


/**
 * @param {!Array<!proto.loupe.common.pipeline.StageProgress>} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
*/
proto.loupe.common.pipeline.PipelineData.prototype.setStagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.pipeline.StageProgress=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.pipeline.StageProgress}
 */
proto.loupe.common.pipeline.PipelineData.prototype.addStages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.pipeline.StageProgress, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.clearStagesList = function() {
  return this.setStagesList([]);
};


/**
 * optional string team_id = 6;
 * @return {string}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getTeamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated StageProgress forecast_categories = 7;
 * @return {!Array<!proto.loupe.common.pipeline.StageProgress>}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getForecastCategoriesList = function() {
  return /** @type{!Array<!proto.loupe.common.pipeline.StageProgress>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_pipeline_stage_progress_pb.StageProgress, 7));
};


/**
 * @param {!Array<!proto.loupe.common.pipeline.StageProgress>} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
*/
proto.loupe.common.pipeline.PipelineData.prototype.setForecastCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.loupe.common.pipeline.StageProgress=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.pipeline.StageProgress}
 */
proto.loupe.common.pipeline.PipelineData.prototype.addForecastCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.loupe.common.pipeline.StageProgress, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.clearForecastCategoriesList = function() {
  return this.setForecastCategoriesList([]);
};


/**
 * optional double won = 8;
 * @return {number}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getWon = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.setWon = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double to_quota = 9;
 * @return {number}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getToQuota = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.setToQuota = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double active = 10;
 * @return {number}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getActive = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.setActive = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double quota = 11;
 * @return {number}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getQuota = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.setQuota = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double annual_won = 12;
 * @return {number}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getAnnualWon = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.setAnnualWon = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double annual_quota = 13;
 * @return {number}
 */
proto.loupe.common.pipeline.PipelineData.prototype.getAnnualQuota = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.pipeline.PipelineData} returns this
 */
proto.loupe.common.pipeline.PipelineData.prototype.setAnnualQuota = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


goog.object.extend(exports, proto.loupe.common.pipeline);
