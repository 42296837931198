import { guid } from '../lib/guid'
import { MappingStatus } from '../grpc/enums'
import { toListCanonicalObjectMappingsRequest } from '../grpc/converters'
import { useAuth } from './auth'
import { useGrpcEffect } from '../grpc'
import { useNotification } from '../hooks/useNotification'
import React, { useCallback, useMemo, useState } from 'react'

const ObjectMappingsContext = React.createContext()

export function ObjectMappingsProvider({ children }) {
  const { tenantId } = useAuth()
  const { notifyError } = useNotification()

  const [key, setKey] = useState(guid())
  const [isFetching, setIsFetching] = useState(true)
  const [objectMappings, setObjectMappings] = useState([])

  useGrpcEffect({
    request: toListCanonicalObjectMappingsRequest({
      tenantId,
    }),
    onError: () => {
      setIsFetching(false)
      notifyError('Error fetching object mappings!')
    },
    onSuccess: (obj) => {
      setObjectMappings(obj.objectsList)
      setIsFetching(false)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'listCanonicalObjectMappings',
    debug: false,
  }, [tenantId, key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const activeCount = useMemo(() => {
    return objectMappings.filter((m) => m.status === MappingStatus.STATUS_ACTIVE).length
  }, [objectMappings])

  const errorCount = useMemo(() => {
    return objectMappings.filter((m) => m.status === MappingStatus.STATUS_ERROR).length
  }, [objectMappings])

  const inactiveCount = useMemo(() => {
    return objectMappings.filter((m) => m.status === MappingStatus.STATUS_INACTIVE).length
  }, [objectMappings])

  const inactiveRequiredCount = useMemo(() => {
    return objectMappings.filter((m) => !!m.required && m.status === MappingStatus.STATUS_INACTIVE).length
  }, [objectMappings])

  const contextValue = useMemo(() => {
    return {
      key,
      activeCount,
      errorCount,
      inactiveCount,
      inactiveRequiredCount,
      invalidate,
      isFetching,
      objectMappings,
      setObjectMappings,
    }
  }, [key, activeCount, errorCount, inactiveCount, invalidate, isFetching, objectMappings])

  return <ObjectMappingsContext.Provider value={contextValue}>{children}</ObjectMappingsContext.Provider>
}

export function useObjectMappings() {
  const context = React.useContext(ObjectMappingsContext)
  if (context === undefined) {
    throw new Error('useObjectMappings must be used within a ObjectMappingsProvider')
  }
  return context
}
