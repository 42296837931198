import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { Close } from '@getoutreach/react-icons'
import FieldSelect from './fields/fieldSelect'
import FieldEmpty from './fields/fieldEmpty'
import OperatorSelector from './operatorSelector'
import { useComparisonOp, useFieldType } from './hooks'
import { getInputForField } from './helpers'
import { useFilterSourceOptions } from '../../context/filterSourceOptions'
import { useForecastConfigValueSettings } from '../../context/forecastConfigValueSettings'
import { has } from 'lodash'
import { useTenantInfo } from '../../context/tenantInfo'

const ConditionRow = (props) => {
  const {
    index,
    userFilter,
    combiner,
    onCombinerChange
  } = props

  const { tenantInfo } = useTenantInfo()
  const { fiscalStartMonth, monthPeriodRange } = tenantInfo

  const { sourceOptions } = useFilterSourceOptions()
  const { setFilterValue, removeFilter } = useForecastConfigValueSettings()

  const { operators, getComparisonOp } = useComparisonOp()
  const { getFieldType, BOOL } = useFieldType()

  const { valuesList = [], comparisonOp } = userFilter

  const [selectedField, setSelectedField] = useState()
  const [selectedOperator, setSelectedOperator] = useState(getComparisonOp(comparisonOp))
  const [inputValue, setInputValue] = useState()

  const handleRowUpdate = useCallback(({ options = [], selectedOperatorOverride, selectedFieldOverride }) => {
    const f = selectedFieldOverride || selectedField || {}
    const { fieldType, field, source } = f

    const o = selectedOperatorOverride || selectedOperator || {}
    const { key: comparisonOp } = o

    const valuesList = options?.map(({ value }) => {
      if (typeof value === 'boolean') {
        return value.toString()
      }
      return value
    }) ?? []

    const newValue = {
      source,
      field,
      fieldType,
      comparisonOp,
      valuesList
    }

    if (has(options, '[0].queryDateRangeType')) {
      newValue.queryDateRangeType = options[0].queryDateRangeType
    }

    setFilterValue(newValue, userFilter)
  }, [
    selectedField,
    selectedOperator,
    userFilter,
    setFilterValue
  ])

  const fieldValues = useMemo(() => {
    return sourceOptions.map((so) => {
      const { source, fieldsList } = so
      return {
        source,
        label: source,
        valuesList: fieldsList.map((fl) => ({
          ...fl,
          key: fl?.field
        }))
      }
    })
  }, [sourceOptions])

  const options = useMemo(() => {
    if (!selectedField) {
      return Object.values(operators)
    }
    const { comparisonOps = [] } = getFieldType(selectedField) || {}
    return comparisonOps
  }, [selectedField, operators, getFieldType])

  const valueOptions = useMemo(() => {
    if (!selectedField) {
      return []
    }
    if (selectedField.fieldType === BOOL.key) {
      return BOOL.options
    }
    const { valuesList = [] } = selectedField
    return valuesList.map((v) => {
      const { value = '' } = v
      return {
        ...v,
        value: JSON.parse(value)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField])

  const FieldInput = useMemo(() => {
    if (!selectedField) {
      return FieldEmpty
    }

    if (!selectedOperator) {
      return FieldEmpty
    }

    const inputForField = getInputForField(selectedField, selectedOperator)
    if (inputForField) {
      return inputForField
    }

    return FieldEmpty
  }, [selectedField, selectedOperator])

  const handleFieldSelect = useCallback((selected) => {
    const [field] = selected
    setInputValue(null)
    setSelectedOperator(null)
    setSelectedField(field)
    handleRowUpdate({ selectedOperatorOverride: null, selectedFieldOverride: field })
  }, [handleRowUpdate])

  const handleOperatorSelect = useCallback((operator) => {
    const [opp] = operator
    setInputValue(null)
    setSelectedOperator(opp)
  }, [])

  const handleValueChange = useCallback((options) => {
    setInputValue(options)
    handleRowUpdate({ options })
  }, [handleRowUpdate])

  const handleOnDeleteInternal = useCallback(() => {
    removeFilter(userFilter)
    window.analytics.track('realtimeMetrics.filterModal.removeFilterClicked')
  }, [removeFilter, userFilter])

  useEffect(() => {
    if (!userFilter) {
      return
    }

    const { source, field, valuesList } = userFilter
    const { fieldsList = [] } = sourceOptions.find(({ source: s }) => s === source) ?? {}
    const sourceOptionField = fieldsList.find(({ field: f }) => f === field)

    if (!valuesList?.length) {
      setSelectedField((prev) => {
        const isNewField = prev?.field !== userFilter.field
        if (isNewField) {
          setInputValue(null)
          setSelectedOperator(null)
        }
        return {
          ...userFilter,
          ...sourceOptionField,
          ...isNewField && { comparisonOp: null },
          key: field
        }
      })
      return
    }

    if (sourceOptionField) {
      setSelectedField({
        ...userFilter,
        ...sourceOptionField,
        key: field
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilter, sourceOptions])

  useEffect(() => {
    if (!valueOptions?.length) {
      const mapped = valuesList.map((v) => ({ value: v, label: v }))
      setInputValue(mapped)
      return
    }

    const hasFieldReference = !!selectedField?.fieldReference
    if (!hasFieldReference) {
      const userInputValues = valuesList
        .map((vl) => {
          if (selectedField?.fieldType === BOOL.key) {
            return valueOptions.find(({ value }) => value === vl?.toLowerCase())
          } else {
            return valueOptions.find(({ value }) => value === vl)
          }
        })
        .filter((v) => v)
      if (userInputValues) {
        setInputValue([...userInputValues])
      } else {
        setInputValue(null)
      }
    }
  }, [valuesList, valueOptions, selectedField])

  const selectedDisplay = useMemo(() => {
    if (selectedField?.fieldReference) {
      return inputValue?.map(({ value }) => value).join(', ')
    } else {
      return inputValue?.map(({ label }) => label).join(', ')
    }
  }, [inputValue, selectedField])

  return (
    <div className="condition-row">
      <OperatorSelector
        empty="If"
        index={index}
        combiner={combiner}
        onChange={onCombinerChange} />
      <FieldSelect
        onChange={handleFieldSelect}
        getIsSelected={({ key }) => key === selectedField?.key}
        selectedDisplay={selectedField?.label}
        values={fieldValues} />
      {selectedField?.field ? (
        <FieldSelect
          onChange={handleOperatorSelect}
          getIsSelected={({ key }) => key === selectedOperator?.key}
          selectedDisplay={selectedOperator?.label}
          values={options} />
      ) : (
        <FieldEmpty />
      )}
      {selectedOperator ? (
        <FieldInput
          getIsSelected={({ value }) => {
            return inputValue?.find((iv) => iv?.value === value)
          }}
          format={selectedField?.format}
          selectedDisplay={selectedDisplay}
          value={inputValue}
          values={valueOptions}
          queryDateRangeType={selectedField?.queryDateRangeType ?? 0}
          onChange={handleValueChange}
          tenantStandardParams={{
            fiscalYearStartMonth: fiscalStartMonth,
            salesPeriodLength: monthPeriodRange
          }}
          fieldReference={selectedField?.fieldReference} />
      ) : (
        <FieldEmpty />
      )}
      <button className="ml-3" onClick={handleOnDeleteInternal}>
        <Close className="p-1" htmlColor="#818e93" />
      </button>
    </div>
  )
}

export default ConditionRow
