import React, { useCallback, useMemo, useState } from 'react'
import Modal from '../common/modal'
import ModalHeader from '../common/modalHeader'
import ModalError from '../common/modalError'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import Button from '../common/button'
import FileUpload from '../common/fileUpload'
import { toProcessSpreadsheetRequest, toUploadSpreadsheetRequest } from '../../grpc/converters'
import { useGrpcCallback } from '../../grpc'
import { useAuth } from '../../context/auth'
import { uploadFile } from '../../lib/gCloudStorage'
import UploadRefreshCheck from '../icons/uploadRefreshCheck'

const UploadGoalsModal = (props) => {
  const {
    modal,
    ...rest
  } = props

  const { open, handleClose } = modal

  const { tenantId } = useAuth()

  const [showError, setShowError] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [complete, setComplete] = useState(false)
  const [file, setFile] = useState(undefined)
  const [fileName, setFileName] = useState('')

  const handleCloseInternal = useCallback(() => {
    setShowError(false)
    setShowLoader(false)
    setUploading(false)
    setProcessing(false)
    setComplete(false)
    setFile(undefined)
    setFileName('')
    handleClose()
  }, [modal])

  const onError = useCallback(() => {
    setShowError(true)
    setShowLoader(false)
    setUploading(false)
    setProcessing(false)
    setComplete(false)
    setFile(undefined)
    setFileName('')
  }, [])

  const processGoalSpreadsheet = useGrpcCallback({
    onError,
    onSuccess: () => {
      setComplete(true)
    },
    onFetch: () => setProcessing(true),
    grpcMethod: 'processGoalSpreadsheet',
    debug: false,
  }, [])

  const uploadGoalSpreadsheet = useGrpcCallback({
    onError,
    onSuccess: (obj, { file: f }) => {
      const { fileId, signedUrl } = obj
      if (signedUrl) {
        uploadFile({
          url: signedUrl,
          contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          file: f,
          onError,
          onSuccess: () => {
            const request = toProcessSpreadsheetRequest({
              tenantId,
              fileId,
            })
            processGoalSpreadsheet(request)
          },
        })
      }
    },
    onFetch: () => setUploading(true),
    grpcMethod: 'uploadGoalSpreadsheet',
    debug: false,
  }, [tenantId, processGoalSpreadsheet])

  const onChange = useCallback(({ target }) => {
    const { files = [] } = target

    // Return early if no selection was made
    if (!files.length) {
      return
    }

    const name = files?.[0]?.name || '-'
    const reader = new FileReader()
    reader.onload = (args) => {
      setFile(args.target.result)
      setFileName(name)
    }
    reader.readAsArrayBuffer(files[0])
  }, [tenantId, uploadGoalSpreadsheet])

  const onUpload = useCallback(() => {
    setShowError(false)
    setShowLoader(true)
    const request = toUploadSpreadsheetRequest({
      tenantId,
    })
    uploadGoalSpreadsheet(request, { file })
  }, [tenantId, uploadGoalSpreadsheet, file])

  const loadingText = useMemo(() => {
    if (uploading && processing && !complete) {
      return {
        title: 'Processing',
        subTitle: <span>We are processing your uploaded goals file. Just a moment.</span>,
      }
    } else if (uploading && processing && complete) {
      return {
        title: 'Complete',
        subTitle: <span>Your goals file was successfully uploaded and applied. You can safely close this window.</span>,
      }
    } else {
      return {
        title: 'Uploading',
        subTitle: <span>Please wait while your goals file is uploaded. Do not close this window.</span>,
      }
    }
  }, [uploading, processing, complete])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="sm"
      open={open}
      {...rest}>

      <ModalHeader
        title="Upload Goals"
        onClose={handleCloseInternal} />

      <ModalBody>

        {showError && <ModalError text="Failed to upload goals, please try again." />}

        {showLoader
          ? (
            <div className="flex justify-center w-full h-full my-10">
              <div className="text-center">
                <div className="flex justify-center my-3">
                  <UploadRefreshCheck
                    upload={uploading}
                    refresh={processing}
                    check={complete} />
                </div>
                <div className="text-size-24px text-color-151d49 opacity-50 font-weight-600">{loadingText.title}</div>
                <div className="text-size-14px text-color-51636a font-weight-400 leading-none">{loadingText.subTitle}</div>
              </div>
            </div>
          )
          : (
            <div className="w-full h-full px-10 pt-10">
              <div className="text-size-16px text-color-51636a font-weight-400 leading-tight mb-4">
                If you've already downloaded and completed a goals file, you can upload it here by selecting the completed file on your local drive.
              </div>
              <FileUpload
                label="File"
                text="Select File"
                accept=".xlsx"
                inputValue={fileName}
                onChange={onChange} />
            </div>
          )}

      </ModalBody>

      <ModalFooter>
        {!showLoader
          ? (
            <Button
              size="xs"
              text="Upload"
              onClick={onUpload}
              disabled={file === undefined || uploading || processing || complete} />
          )
          : (
            <div />
          )}
      </ModalFooter>

    </Modal>
  )
}

export default UploadGoalsModal
