// source: src/common/setting/app/feature/forecasting/forecasting.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_forecast_v2_forecast_pb = require('../../../../../../src/common/forecast/v2/forecast_pb.js');
goog.object.extend(proto, src_common_forecast_v2_forecast_pb);
var src_common_realtime_query_pb = require('../../../../../../src/common/realtime/query_pb.js');
goog.object.extend(proto, src_common_realtime_query_pb);
goog.exportSymbol('proto.loupe.common.setting.feature.forecasting.CustomDataDefinition', null, global);
goog.exportSymbol('proto.loupe.common.setting.feature.forecasting.DisplayOption', null, global);
goog.exportSymbol('proto.loupe.common.setting.feature.forecasting.ForecastCategory', null, global);
goog.exportSymbol('proto.loupe.common.setting.feature.forecasting.Forecasting', null, global);
goog.exportSymbol('proto.loupe.common.setting.feature.forecasting.TotalCall', null, global);
goog.exportSymbol('proto.loupe.common.setting.feature.forecasting.VisibilityOption', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.setting.feature.forecasting.Forecasting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.setting.feature.forecasting.Forecasting.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.setting.feature.forecasting.Forecasting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.setting.feature.forecasting.Forecasting.displayName = 'proto.loupe.common.setting.feature.forecasting.Forecasting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.setting.feature.forecasting.TotalCall = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.setting.feature.forecasting.TotalCall, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.setting.feature.forecasting.TotalCall.displayName = 'proto.loupe.common.setting.feature.forecasting.TotalCall';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.setting.feature.forecasting.ForecastCategory.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.setting.feature.forecasting.ForecastCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.setting.feature.forecasting.ForecastCategory.displayName = 'proto.loupe.common.setting.feature.forecasting.ForecastCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.setting.feature.forecasting.CustomDataDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.setting.feature.forecasting.CustomDataDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.displayName = 'proto.loupe.common.setting.feature.forecasting.CustomDataDefinition';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.setting.feature.forecasting.Forecasting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.setting.feature.forecasting.Forecasting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    mode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    forceStrict: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    totalCall: (f = msg.getTotalCall()) && proto.loupe.common.setting.feature.forecasting.TotalCall.toObject(includeInstance, f),
    forecastCategoriesList: jspb.Message.toObjectList(msg.getForecastCategoriesList(),
    proto.loupe.common.setting.feature.forecasting.ForecastCategory.toObject, includeInstance),
    globalFilters: (f = msg.getGlobalFilters()) && src_common_realtime_query_pb.FilterWrapper.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.setting.feature.forecasting.Forecasting}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.setting.feature.forecasting.Forecasting;
  return proto.loupe.common.setting.feature.forecasting.Forecasting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.setting.feature.forecasting.Forecasting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.setting.feature.forecasting.Forecasting}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.forecast.v2.ForecastMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceStrict(value);
      break;
    case 5:
      var value = new proto.loupe.common.setting.feature.forecasting.TotalCall;
      reader.readMessage(value,proto.loupe.common.setting.feature.forecasting.TotalCall.deserializeBinaryFromReader);
      msg.setTotalCall(value);
      break;
    case 6:
      var value = new proto.loupe.common.setting.feature.forecasting.ForecastCategory;
      reader.readMessage(value,proto.loupe.common.setting.feature.forecasting.ForecastCategory.deserializeBinaryFromReader);
      msg.addForecastCategories(value);
      break;
    case 7:
      var value = new src_common_realtime_query_pb.FilterWrapper;
      reader.readMessage(value,src_common_realtime_query_pb.FilterWrapper.deserializeBinaryFromReader);
      msg.setGlobalFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.setting.feature.forecasting.Forecasting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.setting.feature.forecasting.Forecasting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getForceStrict();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTotalCall();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.loupe.common.setting.feature.forecasting.TotalCall.serializeBinaryToWriter
    );
  }
  f = message.getForecastCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.loupe.common.setting.feature.forecasting.ForecastCategory.serializeBinaryToWriter
    );
  }
  f = message.getGlobalFilters();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      src_common_realtime_query_pb.FilterWrapper.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.setting.feature.forecasting.Forecasting} returns this
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool enabled = 2;
 * @return {boolean}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.setting.feature.forecasting.Forecasting} returns this
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional loupe.common.forecast.v2.ForecastMode mode = 3;
 * @return {!proto.loupe.common.forecast.v2.ForecastMode}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.getMode = function() {
  return /** @type {!proto.loupe.common.forecast.v2.ForecastMode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.forecast.v2.ForecastMode} value
 * @return {!proto.loupe.common.setting.feature.forecasting.Forecasting} returns this
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool force_strict = 4;
 * @return {boolean}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.getForceStrict = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.setting.feature.forecasting.Forecasting} returns this
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.setForceStrict = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional TotalCall total_call = 5;
 * @return {?proto.loupe.common.setting.feature.forecasting.TotalCall}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.getTotalCall = function() {
  return /** @type{?proto.loupe.common.setting.feature.forecasting.TotalCall} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.setting.feature.forecasting.TotalCall, 5));
};


/**
 * @param {?proto.loupe.common.setting.feature.forecasting.TotalCall|undefined} value
 * @return {!proto.loupe.common.setting.feature.forecasting.Forecasting} returns this
*/
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.setTotalCall = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.setting.feature.forecasting.Forecasting} returns this
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.clearTotalCall = function() {
  return this.setTotalCall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.hasTotalCall = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated ForecastCategory forecast_categories = 6;
 * @return {!Array<!proto.loupe.common.setting.feature.forecasting.ForecastCategory>}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.getForecastCategoriesList = function() {
  return /** @type{!Array<!proto.loupe.common.setting.feature.forecasting.ForecastCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.setting.feature.forecasting.ForecastCategory, 6));
};


/**
 * @param {!Array<!proto.loupe.common.setting.feature.forecasting.ForecastCategory>} value
 * @return {!proto.loupe.common.setting.feature.forecasting.Forecasting} returns this
*/
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.setForecastCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.common.setting.feature.forecasting.ForecastCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.addForecastCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.common.setting.feature.forecasting.ForecastCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.setting.feature.forecasting.Forecasting} returns this
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.clearForecastCategoriesList = function() {
  return this.setForecastCategoriesList([]);
};


/**
 * optional loupe.common.realtime.FilterWrapper global_filters = 7;
 * @return {?proto.loupe.common.realtime.FilterWrapper}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.getGlobalFilters = function() {
  return /** @type{?proto.loupe.common.realtime.FilterWrapper} */ (
    jspb.Message.getWrapperField(this, src_common_realtime_query_pb.FilterWrapper, 7));
};


/**
 * @param {?proto.loupe.common.realtime.FilterWrapper|undefined} value
 * @return {!proto.loupe.common.setting.feature.forecasting.Forecasting} returns this
*/
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.setGlobalFilters = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.setting.feature.forecasting.Forecasting} returns this
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.clearGlobalFilters = function() {
  return this.setGlobalFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.setting.feature.forecasting.Forecasting.prototype.hasGlobalFilters = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.setting.feature.forecasting.TotalCall.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.setting.feature.forecasting.TotalCall.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.setting.feature.forecasting.TotalCall} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.setting.feature.forecasting.TotalCall.toObject = function(includeInstance, msg) {
  var f, obj = {
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    includeWon: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.setting.feature.forecasting.TotalCall}
 */
proto.loupe.common.setting.feature.forecasting.TotalCall.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.setting.feature.forecasting.TotalCall;
  return proto.loupe.common.setting.feature.forecasting.TotalCall.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.setting.feature.forecasting.TotalCall} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.setting.feature.forecasting.TotalCall}
 */
proto.loupe.common.setting.feature.forecasting.TotalCall.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeWon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.setting.feature.forecasting.TotalCall.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.setting.feature.forecasting.TotalCall.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.setting.feature.forecasting.TotalCall} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.setting.feature.forecasting.TotalCall.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIncludeWon();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.loupe.common.setting.feature.forecasting.TotalCall.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.setting.feature.forecasting.TotalCall} returns this
 */
proto.loupe.common.setting.feature.forecasting.TotalCall.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.common.setting.feature.forecasting.TotalCall.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.setting.feature.forecasting.TotalCall} returns this
 */
proto.loupe.common.setting.feature.forecasting.TotalCall.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool include_won = 3;
 * @return {boolean}
 */
proto.loupe.common.setting.feature.forecasting.TotalCall.prototype.getIncludeWon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.setting.feature.forecasting.TotalCall} returns this
 */
proto.loupe.common.setting.feature.forecasting.TotalCall.prototype.setIncludeWon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.setting.feature.forecasting.ForecastCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    readonly: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    includeCategoriesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    excludeTotalCall: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isCustom: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    customDataDefinition: (f = msg.getCustomDataDefinition()) && proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.toObject(includeInstance, f),
    display: jspb.Message.getFieldWithDefault(msg, 8, 0),
    visibility: jspb.Message.getFieldWithDefault(msg, 9, 0),
    autoCalculate: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    opportunityFilter: jspb.Message.getFieldWithDefault(msg, 11, ""),
    filters: (f = msg.getFilters()) && src_common_realtime_query_pb.FilterWrapper.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.setting.feature.forecasting.ForecastCategory;
  return proto.loupe.common.setting.feature.forecasting.ForecastCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadonly(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addIncludeCategories(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeTotalCall(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustom(value);
      break;
    case 7:
      var value = new proto.loupe.common.setting.feature.forecasting.CustomDataDefinition;
      reader.readMessage(value,proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.deserializeBinaryFromReader);
      msg.setCustomDataDefinition(value);
      break;
    case 8:
      var value = /** @type {!proto.loupe.common.setting.feature.forecasting.DisplayOption} */ (reader.readEnum());
      msg.setDisplay(value);
      break;
    case 9:
      var value = /** @type {!proto.loupe.common.setting.feature.forecasting.VisibilityOption} */ (reader.readEnum());
      msg.setVisibility(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoCalculate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpportunityFilter(value);
      break;
    case 12:
      var value = new src_common_realtime_query_pb.FilterWrapper;
      reader.readMessage(value,src_common_realtime_query_pb.FilterWrapper.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.setting.feature.forecasting.ForecastCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReadonly();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIncludeCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getExcludeTotalCall();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsCustom();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCustomDataDefinition();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.serializeBinaryToWriter
    );
  }
  f = message.getDisplay();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getVisibility();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getAutoCalculate();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getOpportunityFilter();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      src_common_realtime_query_pb.FilterWrapper.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool readonly = 3;
 * @return {boolean}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.getReadonly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.setReadonly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated string include_categories = 4;
 * @return {!Array<string>}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.getIncludeCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.setIncludeCategoriesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.addIncludeCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.clearIncludeCategoriesList = function() {
  return this.setIncludeCategoriesList([]);
};


/**
 * optional bool exclude_total_call = 5;
 * @return {boolean}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.getExcludeTotalCall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.setExcludeTotalCall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_custom = 6;
 * @return {boolean}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.getIsCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.setIsCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional CustomDataDefinition custom_data_definition = 7;
 * @return {?proto.loupe.common.setting.feature.forecasting.CustomDataDefinition}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.getCustomDataDefinition = function() {
  return /** @type{?proto.loupe.common.setting.feature.forecasting.CustomDataDefinition} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.setting.feature.forecasting.CustomDataDefinition, 7));
};


/**
 * @param {?proto.loupe.common.setting.feature.forecasting.CustomDataDefinition|undefined} value
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
*/
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.setCustomDataDefinition = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.clearCustomDataDefinition = function() {
  return this.setCustomDataDefinition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.hasCustomDataDefinition = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DisplayOption display = 8;
 * @return {!proto.loupe.common.setting.feature.forecasting.DisplayOption}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.getDisplay = function() {
  return /** @type {!proto.loupe.common.setting.feature.forecasting.DisplayOption} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.loupe.common.setting.feature.forecasting.DisplayOption} value
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.setDisplay = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional VisibilityOption visibility = 9;
 * @return {!proto.loupe.common.setting.feature.forecasting.VisibilityOption}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.getVisibility = function() {
  return /** @type {!proto.loupe.common.setting.feature.forecasting.VisibilityOption} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.loupe.common.setting.feature.forecasting.VisibilityOption} value
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.setVisibility = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional bool auto_calculate = 10;
 * @return {boolean}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.getAutoCalculate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.setAutoCalculate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string opportunity_filter = 11;
 * @return {string}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.getOpportunityFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.setOpportunityFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional loupe.common.realtime.FilterWrapper filters = 12;
 * @return {?proto.loupe.common.realtime.FilterWrapper}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.getFilters = function() {
  return /** @type{?proto.loupe.common.realtime.FilterWrapper} */ (
    jspb.Message.getWrapperField(this, src_common_realtime_query_pb.FilterWrapper, 12));
};


/**
 * @param {?proto.loupe.common.realtime.FilterWrapper|undefined} value
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
*/
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.setting.feature.forecasting.ForecastCategory} returns this
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.setting.feature.forecasting.ForecastCategory.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.setting.feature.forecasting.CustomDataDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_static: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    canopyDataUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.setting.feature.forecasting.CustomDataDefinition}
 */
proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.setting.feature.forecasting.CustomDataDefinition;
  return proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.setting.feature.forecasting.CustomDataDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.setting.feature.forecasting.CustomDataDefinition}
 */
proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStatic(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanopyDataUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.setting.feature.forecasting.CustomDataDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatic();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCanopyDataUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional double static = 1;
 * @return {number}
 */
proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.prototype.getStatic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.setting.feature.forecasting.CustomDataDefinition} returns this
 */
proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.prototype.setStatic = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string canopy_data_url = 2;
 * @return {string}
 */
proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.prototype.getCanopyDataUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.setting.feature.forecasting.CustomDataDefinition} returns this
 */
proto.loupe.common.setting.feature.forecasting.CustomDataDefinition.prototype.setCanopyDataUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.setting.feature.forecasting.DisplayOption = {
  ALL: 0,
  SELF: 1,
  BANNER: 2,
  GRID: 3
};

/**
 * @enum {number}
 */
proto.loupe.common.setting.feature.forecasting.VisibilityOption = {
  SHOW: 0,
  MANAGER: 1,
  IC: 2
};

goog.object.extend(exports, proto.loupe.common.setting.feature.forecasting);
