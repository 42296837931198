import React, { useCallback, useMemo } from 'react'
import { useCanonicalObject } from '../../context/canonicalObject'
import SearchBox from '../common/searchBox'
import Label from '../common/label'
import { useTextField } from '../../hooks/useTextField'
import EmptyState from '../common/emptyState'
import CircleEmpty from '../icons/circleEmpty'
import Delete from '../icons/delete'
import Edit from '../icons/edit'
import LoadingState from '../common/loadingState'
import { capitalize, filter } from 'lodash'
import { useGrpcCallback } from '../../grpc'
import { useNotification } from '../../hooks/useNotification'
import { useAuth } from '../../context/auth'
import { toDeleteCanonicalObjectFieldRequest } from '../../grpc/converters'
import ActionPopover from '../common/actionPopover'
import { usePopover } from '../../hooks/usePopover'
import { useCanonicalObjectMapping } from '../../context/canonicalObjectMapping'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const ManageFieldsList = (props) => {
  const {
    setEditData,
    setShowForm,
  } = props

  const { tenantId } = useAuth()

  const { checkPermission } = usePermissions()

  const search = useTextField()

  const deletePopover = usePopover()

  const { notifyError, notifySuccess } = useNotification()

  const { isFetching, canonicalObject, invalidate: invalidateCanonicalObject } = useCanonicalObject()
  const { invalidate: invalidateCanonicalObjectMapping } = useCanonicalObjectMapping()

  const fieldsList = useMemo(() => {
    return canonicalObject?.fieldsList ?? []
  }, [canonicalObject])

  const filteredFields = useMemo(() => {
    if (search.value) {
      return filter(fieldsList, (f) => {
        const regex = new RegExp(search.escapedValue.toLowerCase())
        return f.label && regex.test(f.label.toLowerCase())
      })
    }
    return fieldsList
  }, [fieldsList, search])

  const onAddExtensionFieldClick = useCallback(() => {
    window.analytics.track('Add Extension Field Clicked')
    setEditData(undefined)
    setShowForm(true)
  }, [])

  const onEditClick = useCallback((field) => {
    window.analytics.track('Edit Field Clicked', { fieldName: field?.label ?? '' })
    setEditData(field)
    setShowForm(true)
  }, [])

  const deleteCanonicalObjectField = useGrpcCallback({
    onError: () => {
      notifyError('Error deleting field!')
    },
    onSuccess: () => {
      notifySuccess('Field has been deleted.')
      invalidateCanonicalObject()
      invalidateCanonicalObjectMapping()
    },
    grpcMethod: 'deleteCanonicalObjectField',
    debug: false,
  }, [invalidateCanonicalObject, invalidateCanonicalObjectMapping])

  const onDeleteClick = useCallback((e, field) => {
    window.analytics.track('Delete Field Clicked', { field })
    deletePopover.setData(field)
    deletePopover.setAnchorEl(e.currentTarget)
  }, [])

  const onDeleteCancel = useCallback(() => {
    deletePopover.setData(undefined)
  }, [])

  const onDeleteConfirmed = useCallback(() => {
    const field = deletePopover.data
    const request = toDeleteCanonicalObjectFieldRequest({
      id: field.id,
      tenantId
    })
    deleteCanonicalObjectField(request)
  }, [deleteCanonicalObjectField, tenantId, deletePopover.data])

  return (
    <div className="w-full h-full pt-10">
      <div className="px-10 text-size-16px text-color-51636a font-weight-400 leading-tight mb-4">
        Below are listed the fields that are currently configured on this Commit object.
        {' '}
        You can rename a built-in field by clicking on the pencil icon next to each field. This effectively changes the label within Commit for end-users.
        {' '}
        If you have custom or additional fields you'd like to display in filters or on the pipeline views, you can add Extension Fields to achieve this.
      </div>

      <div className="flex justify-between px-10">
        <Label text="Fields" />
        {checkPermission(permissionNames.CanUpdateSync) && (
          <div onClick={onAddExtensionFieldClick} className="text-size-14px text-color-2e5bff font-weight-600 cursor-pointer focus:outline-none">
            Add Extension Field
          </div>
        )}
      </div>
      <div className="w-full">
        <div className="px-10">
          <SearchBox
            className="mt-2 mb-3"
            value={search.value}
            onChange={search.onChange}
            onClear={search.reset}
            autoFocus={true} />
        </div>

        {isFetching
          ? (
            <div className="flex items-center justify-center py-8">
              <LoadingState
                header="Loading Fields"
                subHeader="Please wait..."
                animate={true} />
            </div>
          )
          : (
            <>
              {fieldsList.length === 0
                ? (
                  <div className="flex items-center justify-center px-10 py-8">
                    <EmptyState
                      iconControl={(
                        <div className="flex items-center bg-color-5951FF rounded-full" style={{ width: 48, height: 48 }}>
                          <div style={{ transform: 'translateX(12px)' }}>
                            <CircleEmpty transform="scale(1.25)" />
                          </div>
                        </div>
                      )}
                      header="No Fields"
                      subHeader="Use the button above to add extension fields to this object" />
                  </div>
                )
                : (
                  <div className="flex flex-col px-6 pb-8">
                    {filteredFields.map((f, index) => {
                      const { id, label, originalLabel, fieldType, description, extensionField } = f
                      return (
                        <div
                          key={`Field-${id}-${index}`}
                          className="px-4 py-3 leading-tight hover:bg-color-51636a-05">
                          <div className="flex justify-between">
                            <div>
                              <div className="flex flex-wrap">
                                <div>{label}</div>
                                {originalLabel && label !== originalLabel
                                  && (
                                    <div className="text-color-818e93 ml-1">
                                      (
                                      {originalLabel}
                                      )
                                    </div>
                                  )}
                                <div className="text-color-818e93 ml-1">
                                  |
                                  {' '}
                                  {capitalize(fieldType)}
                                </div>
                              </div>
                              <div className="text-size-14px text-color-51636a">
                                {description || '-'}
                              </div>
                            </div>
                            <div className="flex items-center ml-2">
                              {checkPermission(permissionNames.CanUpdateSync) && (
                                <>
                                  <button
                                    className="focus:outline-none"
                                    onClick={() => onEditClick(f)}>
                                    <Edit fill="#a0a8bb" transform="scale(0.75)" />
                                  </button>
                                  {extensionField
                                    && (
                                      <button
                                        className="focus:outline-none"
                                        onClick={(e) => onDeleteClick(e, f)}>
                                        <Delete fill="#a0a8bb" transform="scale(0.75)" />
                                      </button>
                                    )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
            </>
          )}
      </div>

      <ActionPopover
        popover={deletePopover}
        text="Are you sure you want to delete this field?"
        subText="It can't be undone."
        actionText="Delete"
        onCancel={onDeleteCancel}
        onAction={onDeleteConfirmed}
        maxWidth={285} />
    </div>
  )
}

export default ManageFieldsList
