import React, { useCallback, useMemo, useState } from 'react'
import { grpcCodes } from '../grpc/grpcCodes'

const GroupsErrorContext = React.createContext()

export function GroupsErrorProvider({ children }) {
  const [groupsError, setGroupsError] = useState(false)

  const handleFailedPreconditionError = useCallback((err) => {
    if (err && err.code === grpcCodes.FAILED_PRECONDITION) {
      setGroupsError(true)
      return true
    }
  }, [])

  const contextValue = useMemo(() => {
    return {
      groupsError,
      setGroupsError,
      handleFailedPreconditionError,
    }
  }, [groupsError, handleFailedPreconditionError])

  return <GroupsErrorContext.Provider value={contextValue}>{children}</GroupsErrorContext.Provider>
}

export function useGroupsError() {
  const context = React.useContext(GroupsErrorContext)
  if (context === undefined) {
    throw new Error('useGroupsError must be used within a GroupsErrorProvider')
  }
  return context
}
