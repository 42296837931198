import { CanonicalObjectMappingProvider } from '../../context/canonicalObjectMapping'
import { IntegrationObjectTypesProvider } from '../../context/integrationObjectTypes'
import { JoyrideProvider } from '../../context/joyride'
import { ObjectMappingChangesProvider } from '../../context/objectMappingChanges'
import { useBase64EncodedObjectParam } from '../../hooks/useBase64EncodedObjectParam'
import { useSetup } from '../../context/setup'
import ObjectMappingMain from './objectMappingMain'
import React from 'react'

const ObjectMapping = (props) => {
  const { object: canonicalObject } = useBase64EncodedObjectParam('data')
  const { isComplete, isFetching } = useSetup()

  return (
    <CanonicalObjectMappingProvider objectName={canonicalObject.objectName}>
      <IntegrationObjectTypesProvider>
        <ObjectMappingChangesProvider>
          <JoyrideProvider enabled={!isFetching && !isComplete && canonicalObject.activeCount === 0}>
            <ObjectMappingMain canonicalObject={canonicalObject} />
          </JoyrideProvider>
        </ObjectMappingChangesProvider>
      </IntegrationObjectTypesProvider>
    </CanonicalObjectMappingProvider>
  )
}

export default ObjectMapping
