/* eslint-disable max-len */

import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import MuiTheme from './lib/muiTheme'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { init } from '@sentry/browser'
import { permissionNames } from './constants/permissionNames'
import { useRoutes } from './context/routes'
import { DebugProvider } from './context/debug'
import { CacheProvider } from './context/cache'
import { AuthProvider } from './context/auth'
import { PermissionsProvider } from './context/permissions'
import { TenantInfoProvider } from './context/tenantInfo'
import { LoggedInUserProvider } from './context/loggedInUser'
import { SetupProvider } from './context/setup'
import { UserPrefsProvider } from './context/userPrefs'
import SnackBar from './components/snackbar/snackbar'
import BlankTemplate from './components/templates/blankTemplate'
import MainTemplate from './components/templates/mainTemplate'
import ProtectedRoute from './components/routes/protectedRoute'
import Login from './components/auth/login'
import AuthRedirect from './components/auth/authRedirect'
import Logout from './components/auth/logout'
import Unauthorized from './components/auth/unauthorized'
import Tenants from './components/tenants/tenants'
import Role from './components/roles/role'
import Roles from './components/roles/roles'
import Sandbox from './components/sandbox/sandbox'
import Setup from './components/setup/setup'
import Sync from './components/setup/sync'
import Groups from './components/groups/groups'
import Users from './components/users/users'
import Goals from './components/goals/goals'
import Status from './components/status/status'
import DataSync from './components/dataSync/dataSync'
import DataSyncHistory from './components/dataSync/syncHistory'
import ObjectMapping from './components/objectMapping/objectMapping'
import Settings from './components/settings/settings'
import NotFound from './components/notFound/notFound'
import Default from './components/default/default'
import Forecast from './components/forecasts/forecast'
import Forecasts from './components/forecasts/forecasts'

if (process.env.TAG_NAME !== 'local') {
  init({ dsn: process.env.SENTRY_DSN })
}

const App = () => {
  const { routes } = useRoutes()

  return (
    <MuiThemeProvider theme={MuiTheme}>
      <SnackBar
        maxSnack={5}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <DebugProvider>
          <CacheProvider>
            <AuthProvider>
              <UserPrefsProvider>
                <PermissionsProvider>
                  <TenantInfoProvider>
                    <LoggedInUserProvider>
                      <SetupProvider>
                        <Router getUserConfirmation={() => {
                          /* empty callback to block the default browser prompt */
                        }}>
                          <Switch>
                            <Route path="/noop" component={() => <></>} />
                            <Route path={routes.login} component={Login} />
                            <Route path={routes.authRedirect} component={AuthRedirect} />
                            <Route path={routes.logout} component={Logout} />
                            <Route path={routes.unauthorized} component={Unauthorized} />
                            <Route path={routes.status} component={Status} />
                            <ProtectedRoute path={routes.setup} template={BlankTemplate} component={Setup} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessSync]} />
                            <ProtectedRoute path={routes.sync} template={BlankTemplate} component={Sync} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessSync]} />
                            <ProtectedRoute path={routes.tenants} template={MainTemplate} component={Tenants} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessTenant]} />
                            <ProtectedRoute path={routes.teams} template={MainTemplate} component={Groups} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessGroup]} />
                            <ProtectedRoute path={routes.users} template={MainTemplate} component={Users} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessUser]} />
                            <ProtectedRoute path={routes.role} template={MainTemplate} component={Role} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessSystemRole]} />
                            <ProtectedRoute path={routes.roles} template={MainTemplate} component={Roles} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessSystemRole]} />
                            <ProtectedRoute path={routes.goals} template={MainTemplate} component={Goals} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessGoal]} />
                            <ProtectedRoute path={routes.sandboxes} template={MainTemplate} component={Sandbox} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessGroup]} />
                            <ProtectedRoute path={routes.dataSyncHistory} template={MainTemplate} component={DataSyncHistory} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessSync]} />
                            <ProtectedRoute path={routes.dataSyncObjectMapping} template={MainTemplate} component={ObjectMapping} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessSync]} />
                            <ProtectedRoute path={routes.dataSync} template={MainTemplate} component={DataSync} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessSync]} />
                            <ProtectedRoute path={routes.features} template={MainTemplate} component={Settings} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessInternalAdmin]} />
                            <ProtectedRoute path={routes.forecast} template={MainTemplate} component={Forecast} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessForecasts]} />
                            <ProtectedRoute path={routes.forecasts} template={MainTemplate} component={Forecasts} checkPermissions={[permissionNames.CanAccessAdmin, permissionNames.CanAccessForecasts]} />
                            <ProtectedRoute path={routes.default} template={MainTemplate} component={Default} checkPermissions={[permissionNames.CanAccessAdmin]} />
                            <ProtectedRoute exact path={routes.default} template={MainTemplate} component={Default} checkPermissions={[permissionNames.CanAccessAdmin]} />
                            <Route component={NotFound} />
                          </Switch>
                        </Router>
                      </SetupProvider>
                    </LoggedInUserProvider>
                  </TenantInfoProvider>
                </PermissionsProvider>
              </UserPrefsProvider>
            </AuthProvider>
          </CacheProvider>
        </DebugProvider>
      </SnackBar>
    </MuiThemeProvider>
  )
}

export default App

/* eslint-enable max-len */
