import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toGetTenantInfoRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useAuth } from './auth'
import { InstanceSetupStatus } from '../grpc/enums'

const TenantInfoContext = React.createContext()

export function TenantInfoProvider({ children }) {
  const { tenantId } = useAuth()

  const { notifyError } = useNotification()

  const [key, setKey] = useState(guid())
  const [tenantInfo, setTenantInfo] = useState({})
  const [isFetching, setIsFetching] = useState(true)

  useGrpcEffect({
    request: toGetTenantInfoRequest({
      tenantId,
    }),
    onError: () => {
      setIsFetching(false)
      notifyError('Error fetching tenant info!')
      setTenantInfo({})
    },
    onSuccess: (obj) => {
      setTenantInfo(obj)
      setIsFetching(false)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getTenantInfo',
    debug: false,
  }, [tenantId, key])

  const invalidate = useCallback(() => {
    setKey(guid())
    setTenantInfo({})
  }, [])

  const tenantIsPending = useMemo(() => {
    const { setupStatus } = tenantInfo
    return setupStatus === InstanceSetupStatus.INSTANCE_SETUP_STATUS_PENDING
  }, [tenantInfo, InstanceSetupStatus])

  const tenantIsInitializing = useMemo(() => {
    const { setupStatus } = tenantInfo
    return setupStatus === InstanceSetupStatus.INSTANCE_SETUP_STATUS_INITIALIZING
  }, [tenantInfo, InstanceSetupStatus])

  const crmName = useMemo(() => {
    const { crmType } = tenantInfo
    if (crmType === 'hubspot') {
      return 'HubSpot'
    } else {
      return 'Salesforce'
    }
  }, [tenantInfo])

  const isSalesforce = useMemo(() => {
    const { crmType } = tenantInfo
    return crmType === 'salesforce'
  }, [tenantInfo])

  const isHubSpot = useMemo(() => {
    const { crmType } = tenantInfo
    return crmType === 'hubspot'
  }, [tenantInfo])

  const isSandbox = useMemo(() => {
    const { parentTenantId } = tenantInfo
    return !!parentTenantId
  }, [tenantInfo])

  const contextValue = useMemo(() => {
    return {
      tenantInfo,
      setTenantInfo,
      tenantIsPending,
      key,
      isFetching,
      invalidate,
      crmName,
      isSalesforce,
      isHubSpot,
      isSandbox,
      tenantIsInitializing
    }
  }, [
    tenantInfo,
    setTenantInfo,
    tenantIsPending,
    key,
    isFetching,
    invalidate,
    crmName,
    isSalesforce,
    isHubSpot,
    isSandbox,
    tenantIsInitializing
  ])

  return <TenantInfoContext.Provider value={contextValue}>{children}</TenantInfoContext.Provider>
}

export function useTenantInfo() {
  const context = React.useContext(TenantInfoContext)
  if (context === undefined) {
    throw new Error('useTenantInfo must be used within a TenantInfoProvider')
  }
  return context
}
