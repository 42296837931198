// source: src/services/tightbeam_global/tightbeam_global.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.loupe.services.tightbeam_global.Bento', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.CommitOrgSettings', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.Condition', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.ConditionArgument', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.ConditionExpression', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.ConditionExpression.ExpressionCase', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.ConditionGroup', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.ConditionGroup.GroupCase', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.ConjunctionGroup', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.CreateCommitTenantRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.CreateCommitTenantResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.CrmType', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.DisjunctionGroup', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.EventConditions', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.EventConditions.EVENT', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetAccessTokenRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetOrgRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetOrgRequest.IdCase', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetOrgResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetOutreachRolesRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetOutreachRolesResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetOutreachUsersRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.GetOutreachUsersResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.ListOrgFilters', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.ListOrgsRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.ListOrgsResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.MapOrgToTenantRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.MapOrgToTenantResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.Opportunity', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.OpportunityHealthFactor', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.Org', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.OrgBentoIdPair', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.OutreachPluginMappings', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.OutreachRole', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.OutreachSyncField', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.OutreachSyncObject', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.OutreachUser', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.PingBentoRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.PingBentoResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.PingRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.PingResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.SyncDirection', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest', null, global);
goog.exportSymbol('proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.Org = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.Org, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.Org.displayName = 'proto.loupe.services.tightbeam_global.Org';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.OrgBentoIdPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.OrgBentoIdPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.OrgBentoIdPair.displayName = 'proto.loupe.services.tightbeam_global.OrgBentoIdPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.Bento = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.Bento, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.Bento.displayName = 'proto.loupe.services.tightbeam_global.Bento';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.PingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.PingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.PingRequest.displayName = 'proto.loupe.services.tightbeam_global.PingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.PingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.PingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.PingResponse.displayName = 'proto.loupe.services.tightbeam_global.PingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.PingBentoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.PingBentoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.PingBentoRequest.displayName = 'proto.loupe.services.tightbeam_global.PingBentoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.PingBentoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.PingBentoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.PingBentoResponse.displayName = 'proto.loupe.services.tightbeam_global.PingBentoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.displayName = 'proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse.displayName = 'proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.MapOrgToTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.displayName = 'proto.loupe.services.tightbeam_global.MapOrgToTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.MapOrgToTenantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.MapOrgToTenantResponse.displayName = 'proto.loupe.services.tightbeam_global.MapOrgToTenantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.ListOrgsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.ListOrgsRequest.displayName = 'proto.loupe.services.tightbeam_global.ListOrgsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.ListOrgFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.ListOrgFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.ListOrgFilters.displayName = 'proto.loupe.services.tightbeam_global.ListOrgFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.ListOrgsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.ListOrgsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.ListOrgsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.ListOrgsResponse.displayName = 'proto.loupe.services.tightbeam_global.ListOrgsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetOrgRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.loupe.services.tightbeam_global.GetOrgRequest.oneofGroups_);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetOrgRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetOrgRequest.displayName = 'proto.loupe.services.tightbeam_global.GetOrgRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetOrgResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetOrgResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetOrgResponse.displayName = 'proto.loupe.services.tightbeam_global.GetOrgResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.displayName = 'proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.displayName = 'proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.Opportunity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.Opportunity.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.Opportunity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.Opportunity.displayName = 'proto.loupe.services.tightbeam_global.Opportunity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.OpportunityHealthFactor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.OpportunityHealthFactor.displayName = 'proto.loupe.services.tightbeam_global.OpportunityHealthFactor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.displayName = 'proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.displayName = 'proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.displayName = 'proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.OutreachSyncObject.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.OutreachSyncObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.OutreachSyncObject.displayName = 'proto.loupe.services.tightbeam_global.OutreachSyncObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.OutreachSyncField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.OutreachSyncField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.OutreachSyncField.displayName = 'proto.loupe.services.tightbeam_global.OutreachSyncField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetAccessTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetAccessTokenRequest.displayName = 'proto.loupe.services.tightbeam_global.GetAccessTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.EventConditions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.EventConditions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.EventConditions.displayName = 'proto.loupe.services.tightbeam_global.EventConditions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.ConditionGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.ConditionGroup.repeatedFields_, proto.loupe.services.tightbeam_global.ConditionGroup.oneofGroups_);
};
goog.inherits(proto.loupe.services.tightbeam_global.ConditionGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.ConditionGroup.displayName = 'proto.loupe.services.tightbeam_global.ConditionGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.ConjunctionGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.ConjunctionGroup.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.ConjunctionGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.ConjunctionGroup.displayName = 'proto.loupe.services.tightbeam_global.ConjunctionGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.ConditionExpression = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.loupe.services.tightbeam_global.ConditionExpression.oneofGroups_);
};
goog.inherits(proto.loupe.services.tightbeam_global.ConditionExpression, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.ConditionExpression.displayName = 'proto.loupe.services.tightbeam_global.ConditionExpression';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.DisjunctionGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.DisjunctionGroup.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.DisjunctionGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.DisjunctionGroup.displayName = 'proto.loupe.services.tightbeam_global.DisjunctionGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.ConditionArgument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.ConditionArgument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.ConditionArgument.displayName = 'proto.loupe.services.tightbeam_global.ConditionArgument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.Condition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.Condition.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.Condition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.Condition.displayName = 'proto.loupe.services.tightbeam_global.Condition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetOutreachRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.displayName = 'proto.loupe.services.tightbeam_global.GetOutreachRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetOutreachRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.displayName = 'proto.loupe.services.tightbeam_global.GetOutreachRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.OutreachRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.OutreachRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.OutreachRole.displayName = 'proto.loupe.services.tightbeam_global.OutreachRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.displayName = 'proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.displayName = 'proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.OutreachPluginMappings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.OutreachPluginMappings.displayName = 'proto.loupe.services.tightbeam_global.OutreachPluginMappings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetOutreachUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.displayName = 'proto.loupe.services.tightbeam_global.GetOutreachUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetOutreachUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.displayName = 'proto.loupe.services.tightbeam_global.GetOutreachUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.OutreachUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.OutreachUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.OutreachUser.displayName = 'proto.loupe.services.tightbeam_global.OutreachUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.CreateCommitTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.displayName = 'proto.loupe.services.tightbeam_global.CreateCommitTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.CreateCommitTenantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.CreateCommitTenantResponse.displayName = 'proto.loupe.services.tightbeam_global.CreateCommitTenantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.displayName = 'proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.displayName = 'proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.displayName = 'proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.CommitOrgSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.CommitOrgSettings.displayName = 'proto.loupe.services.tightbeam_global.CommitOrgSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse.displayName = 'proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.Org.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.Org.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.Org} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.Org.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    bento: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.Org}
 */
proto.loupe.services.tightbeam_global.Org.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.Org;
  return proto.loupe.services.tightbeam_global.Org.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.Org} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.Org}
 */
proto.loupe.services.tightbeam_global.Org.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortname(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBento(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.Org.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.Org.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.Org} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.Org.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBento();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string org_guid = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Org.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Org} returns this
 */
proto.loupe.services.tightbeam_global.Org.prototype.setOrgGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Org.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Org} returns this
 */
proto.loupe.services.tightbeam_global.Org.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string shortname = 3;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Org.prototype.getShortname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Org} returns this
 */
proto.loupe.services.tightbeam_global.Org.prototype.setShortname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool active = 4;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.Org.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.Org} returns this
 */
proto.loupe.services.tightbeam_global.Org.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string bento = 5;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Org.prototype.getBento = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Org} returns this
 */
proto.loupe.services.tightbeam_global.Org.prototype.setBento = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.OrgBentoIdPair.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.OrgBentoIdPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.OrgBentoIdPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OrgBentoIdPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bento: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.OrgBentoIdPair}
 */
proto.loupe.services.tightbeam_global.OrgBentoIdPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.OrgBentoIdPair;
  return proto.loupe.services.tightbeam_global.OrgBentoIdPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.OrgBentoIdPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.OrgBentoIdPair}
 */
proto.loupe.services.tightbeam_global.OrgBentoIdPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBento(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.OrgBentoIdPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.OrgBentoIdPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.OrgBentoIdPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OrgBentoIdPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBento();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string org_guid = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OrgBentoIdPair.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OrgBentoIdPair} returns this
 */
proto.loupe.services.tightbeam_global.OrgBentoIdPair.prototype.setOrgGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bento = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OrgBentoIdPair.prototype.getBento = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OrgBentoIdPair} returns this
 */
proto.loupe.services.tightbeam_global.OrgBentoIdPair.prototype.setBento = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.Bento.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.Bento.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.Bento} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.Bento.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bentoType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    environment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    region: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fqdn: jspb.Message.getFieldWithDefault(msg, 5, ""),
    channel: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dnsZone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    state: jspb.Message.getFieldWithDefault(msg, 8, ""),
    commitIgnore: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.Bento}
 */
proto.loupe.services.tightbeam_global.Bento.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.Bento;
  return proto.loupe.services.tightbeam_global.Bento.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.Bento} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.Bento}
 */
proto.loupe.services.tightbeam_global.Bento.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBentoType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnvironment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFqdn(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDnsZone(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCommitIgnore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.Bento.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.Bento.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.Bento} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.Bento.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBentoType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnvironment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFqdn();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDnsZone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCommitIgnore();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Bento.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Bento} returns this
 */
proto.loupe.services.tightbeam_global.Bento.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bento_type = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Bento.prototype.getBentoType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Bento} returns this
 */
proto.loupe.services.tightbeam_global.Bento.prototype.setBentoType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string environment = 3;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Bento.prototype.getEnvironment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Bento} returns this
 */
proto.loupe.services.tightbeam_global.Bento.prototype.setEnvironment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string region = 4;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Bento.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Bento} returns this
 */
proto.loupe.services.tightbeam_global.Bento.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string fqdn = 5;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Bento.prototype.getFqdn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Bento} returns this
 */
proto.loupe.services.tightbeam_global.Bento.prototype.setFqdn = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string channel = 6;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Bento.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Bento} returns this
 */
proto.loupe.services.tightbeam_global.Bento.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string dns_zone = 7;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Bento.prototype.getDnsZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Bento} returns this
 */
proto.loupe.services.tightbeam_global.Bento.prototype.setDnsZone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string state = 8;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Bento.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Bento} returns this
 */
proto.loupe.services.tightbeam_global.Bento.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool commit_ignore = 9;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.Bento.prototype.getCommitIgnore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.Bento} returns this
 */
proto.loupe.services.tightbeam_global.Bento.prototype.setCommitIgnore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.PingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.PingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.PingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.PingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.PingRequest}
 */
proto.loupe.services.tightbeam_global.PingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.PingRequest;
  return proto.loupe.services.tightbeam_global.PingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.PingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.PingRequest}
 */
proto.loupe.services.tightbeam_global.PingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.PingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.PingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.PingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.PingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.PingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.PingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.PingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.PingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.PingResponse}
 */
proto.loupe.services.tightbeam_global.PingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.PingResponse;
  return proto.loupe.services.tightbeam_global.PingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.PingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.PingResponse}
 */
proto.loupe.services.tightbeam_global.PingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.PingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.PingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.PingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.PingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.PingResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.PingResponse} returns this
 */
proto.loupe.services.tightbeam_global.PingResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.PingBentoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.PingBentoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.PingBentoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.PingBentoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bento: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.PingBentoRequest}
 */
proto.loupe.services.tightbeam_global.PingBentoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.PingBentoRequest;
  return proto.loupe.services.tightbeam_global.PingBentoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.PingBentoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.PingBentoRequest}
 */
proto.loupe.services.tightbeam_global.PingBentoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBento(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.PingBentoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.PingBentoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.PingBentoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.PingBentoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBento();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string bento = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.PingBentoRequest.prototype.getBento = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.PingBentoRequest} returns this
 */
proto.loupe.services.tightbeam_global.PingBentoRequest.prototype.setBento = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.PingBentoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.PingBentoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.PingBentoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.PingBentoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.PingBentoResponse}
 */
proto.loupe.services.tightbeam_global.PingBentoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.PingBentoResponse;
  return proto.loupe.services.tightbeam_global.PingBentoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.PingBentoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.PingBentoResponse}
 */
proto.loupe.services.tightbeam_global.PingBentoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.PingBentoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.PingBentoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.PingBentoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.PingBentoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.PingBentoResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.PingBentoResponse} returns this
 */
proto.loupe.services.tightbeam_global.PingBentoResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskToken: msg.getTaskToken_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    action: jspb.Message.getFieldWithDefault(msg, 3, ""),
    org: (f = msg.getOrg()) && proto.loupe.services.tightbeam_global.Org.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest;
  return proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTaskToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 4:
      var value = new proto.loupe.services.tightbeam_global.Org;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.Org.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskToken_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.loupe.services.tightbeam_global.Org.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes task_token = 1;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.getTaskToken = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes task_token = 1;
 * This is a type-conversion wrapper around `getTaskToken()`
 * @return {string}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.getTaskToken_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTaskToken()));
};


/**
 * optional bytes task_token = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTaskToken()`
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.getTaskToken_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTaskToken()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest} returns this
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.setTaskToken = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest} returns this
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string action = 3;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest} returns this
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Org org = 4;
 * @return {?proto.loupe.services.tightbeam_global.Org}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.getOrg = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.Org} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.tightbeam_global.Org, 4));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.Org|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest} returns this
*/
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest} returns this
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleRequest.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse;
  return proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool completed = 1;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse} returns this
 */
proto.loupe.services.tightbeam_global.ProcessOrgLifecycleResponse.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.MapOrgToTenantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgGuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bento: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.MapOrgToTenantRequest}
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.MapOrgToTenantRequest;
  return proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.MapOrgToTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.MapOrgToTenantRequest}
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBento(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.MapOrgToTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgGuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBento();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.MapOrgToTenantRequest} returns this
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string org_guid = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.MapOrgToTenantRequest} returns this
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.prototype.setOrgGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bento = 3;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.prototype.getBento = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.MapOrgToTenantRequest} returns this
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantRequest.prototype.setBento = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.MapOrgToTenantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.MapOrgToTenantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.MapOrgToTenantResponse}
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.MapOrgToTenantResponse;
  return proto.loupe.services.tightbeam_global.MapOrgToTenantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.MapOrgToTenantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.MapOrgToTenantResponse}
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.MapOrgToTenantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.MapOrgToTenantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.MapOrgToTenantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.ListOrgsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.ListOrgsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filters: (f = msg.getFilters()) && proto.loupe.services.tightbeam_global.ListOrgFilters.toObject(includeInstance, f),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.ListOrgsRequest}
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.ListOrgsRequest;
  return proto.loupe.services.tightbeam_global.ListOrgsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.ListOrgsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.ListOrgsRequest}
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 2:
      var value = new proto.loupe.services.tightbeam_global.ListOrgFilters;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.ListOrgFilters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.ListOrgsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.ListOrgsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.tightbeam_global.ListOrgFilters.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string search = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.ListOrgsRequest} returns this
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ListOrgFilters filters = 2;
 * @return {?proto.loupe.services.tightbeam_global.ListOrgFilters}
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.prototype.getFilters = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.ListOrgFilters} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.tightbeam_global.ListOrgFilters, 2));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.ListOrgFilters|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.ListOrgsRequest} returns this
*/
proto.loupe.services.tightbeam_global.ListOrgsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.ListOrgsRequest} returns this
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.ListOrgsRequest} returns this
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.ListOrgsRequest} returns this
 */
proto.loupe.services.tightbeam_global.ListOrgsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.ListOrgFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.ListOrgFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.toObject = function(includeInstance, msg) {
  var f, obj = {
    bento: (f = msg.getBento()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    state: (f = msg.getState()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isActive: (f = msg.getIsActive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.ListOrgFilters}
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.ListOrgFilters;
  return proto.loupe.services.tightbeam_global.ListOrgFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.ListOrgFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.ListOrgFilters}
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBento(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setState(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.ListOrgFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.ListOrgFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBento();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsActive();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue bento = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.getBento = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.ListOrgFilters} returns this
*/
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.setBento = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.ListOrgFilters} returns this
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.clearBento = function() {
  return this.setBento(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.hasBento = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue state = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.getState = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.ListOrgFilters} returns this
*/
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.setState = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.ListOrgFilters} returns this
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.clearState = function() {
  return this.setState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.hasState = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue is_active = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.getIsActive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.ListOrgFilters} returns this
*/
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.setIsActive = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.ListOrgFilters} returns this
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.clearIsActive = function() {
  return this.setIsActive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ListOrgFilters.prototype.hasIsActive = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.ListOrgsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.ListOrgsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.ListOrgsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.ListOrgsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ListOrgsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgsList: jspb.Message.toObjectList(msg.getOrgsList(),
    proto.loupe.services.tightbeam_global.Org.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.ListOrgsResponse}
 */
proto.loupe.services.tightbeam_global.ListOrgsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.ListOrgsResponse;
  return proto.loupe.services.tightbeam_global.ListOrgsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.ListOrgsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.ListOrgsResponse}
 */
proto.loupe.services.tightbeam_global.ListOrgsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.tightbeam_global.Org;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.Org.deserializeBinaryFromReader);
      msg.addOrgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.ListOrgsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.ListOrgsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.ListOrgsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ListOrgsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.tightbeam_global.Org.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Org orgs = 1;
 * @return {!Array<!proto.loupe.services.tightbeam_global.Org>}
 */
proto.loupe.services.tightbeam_global.ListOrgsResponse.prototype.getOrgsList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.Org>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.Org, 1));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.Org>} value
 * @return {!proto.loupe.services.tightbeam_global.ListOrgsResponse} returns this
*/
proto.loupe.services.tightbeam_global.ListOrgsResponse.prototype.setOrgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.Org=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.Org}
 */
proto.loupe.services.tightbeam_global.ListOrgsResponse.prototype.addOrgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.tightbeam_global.Org, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.ListOrgsResponse} returns this
 */
proto.loupe.services.tightbeam_global.ListOrgsResponse.prototype.clearOrgsList = function() {
  return this.setOrgsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.IdCase = {
  ID_NOT_SET: 0,
  TENANT_ID: 1,
  ORG_GUID: 2
};

/**
 * @return {proto.loupe.services.tightbeam_global.GetOrgRequest.IdCase}
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.prototype.getIdCase = function() {
  return /** @type {proto.loupe.services.tightbeam_global.GetOrgRequest.IdCase} */(jspb.Message.computeOneofCase(this, proto.loupe.services.tightbeam_global.GetOrgRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetOrgRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetOrgRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgGuid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetOrgRequest}
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetOrgRequest;
  return proto.loupe.services.tightbeam_global.GetOrgRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetOrgRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetOrgRequest}
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetOrgRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetOrgRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOrgRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.loupe.services.tightbeam_global.GetOrgRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.GetOrgRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.prototype.clearTenantId = function() {
  return jspb.Message.setOneofField(this, 1, proto.loupe.services.tightbeam_global.GetOrgRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.prototype.hasTenantId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string org_guid = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOrgRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.prototype.setOrgGuid = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.loupe.services.tightbeam_global.GetOrgRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.GetOrgRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.prototype.clearOrgGuid = function() {
  return jspb.Message.setOneofField(this, 2, proto.loupe.services.tightbeam_global.GetOrgRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.GetOrgRequest.prototype.hasOrgGuid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetOrgResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetOrgResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetOrgResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOrgResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    org: (f = msg.getOrg()) && proto.loupe.services.tightbeam_global.Org.toObject(includeInstance, f),
    bento: (f = msg.getBento()) && proto.loupe.services.tightbeam_global.Bento.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetOrgResponse}
 */
proto.loupe.services.tightbeam_global.GetOrgResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetOrgResponse;
  return proto.loupe.services.tightbeam_global.GetOrgResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetOrgResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetOrgResponse}
 */
proto.loupe.services.tightbeam_global.GetOrgResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.tightbeam_global.Org;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.Org.deserializeBinaryFromReader);
      msg.setOrg(value);
      break;
    case 2:
      var value = new proto.loupe.services.tightbeam_global.Bento;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.Bento.deserializeBinaryFromReader);
      msg.setBento(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetOrgResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetOrgResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetOrgResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOrgResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrg();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.tightbeam_global.Org.serializeBinaryToWriter
    );
  }
  f = message.getBento();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.tightbeam_global.Bento.serializeBinaryToWriter
    );
  }
};


/**
 * optional Org org = 1;
 * @return {?proto.loupe.services.tightbeam_global.Org}
 */
proto.loupe.services.tightbeam_global.GetOrgResponse.prototype.getOrg = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.Org} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.tightbeam_global.Org, 1));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.Org|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.GetOrgResponse} returns this
*/
proto.loupe.services.tightbeam_global.GetOrgResponse.prototype.setOrg = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.GetOrgResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOrgResponse.prototype.clearOrg = function() {
  return this.setOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.GetOrgResponse.prototype.hasOrg = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Bento bento = 2;
 * @return {?proto.loupe.services.tightbeam_global.Bento}
 */
proto.loupe.services.tightbeam_global.GetOrgResponse.prototype.getBento = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.Bento} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.tightbeam_global.Bento, 2));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.Bento|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.GetOrgResponse} returns this
*/
proto.loupe.services.tightbeam_global.GetOrgResponse.prototype.setBento = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.GetOrgResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOrgResponse.prototype.clearBento = function() {
  return this.setBento(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.GetOrgResponse.prototype.hasBento = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    crmIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest}
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest;
  return proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest}
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCrmIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCrmIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest} returns this
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string crm_ids = 2;
 * @return {!Array<string>}
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.prototype.getCrmIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest} returns this
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.prototype.setCrmIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest} returns this
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.prototype.addCrmIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest} returns this
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesRequest.prototype.clearCrmIdsList = function() {
  return this.setCrmIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    opportunitiesList: jspb.Message.toObjectList(msg.getOpportunitiesList(),
    proto.loupe.services.tightbeam_global.Opportunity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse}
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse;
  return proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse}
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.tightbeam_global.Opportunity;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.Opportunity.deserializeBinaryFromReader);
      msg.addOpportunities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpportunitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.tightbeam_global.Opportunity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Opportunity opportunities = 1;
 * @return {!Array<!proto.loupe.services.tightbeam_global.Opportunity>}
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.prototype.getOpportunitiesList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.Opportunity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.Opportunity, 1));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.Opportunity>} value
 * @return {!proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse} returns this
*/
proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.prototype.setOpportunitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.Opportunity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.Opportunity}
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.prototype.addOpportunities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.tightbeam_global.Opportunity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse} returns this
 */
proto.loupe.services.tightbeam_global.SearchOpportunitiesResponse.prototype.clearOpportunitiesList = function() {
  return this.setOpportunitiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.Opportunity.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.Opportunity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.Opportunity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.Opportunity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    crmId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    healthCategory: jspb.Message.getFieldWithDefault(msg, 3, ""),
    healthScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    healthFactorsList: jspb.Message.toObjectList(msg.getHealthFactorsList(),
    proto.loupe.services.tightbeam_global.OpportunityHealthFactor.toObject, includeInstance),
    mapLink: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mapNextSteps: jspb.Message.getFieldWithDefault(msg, 8, ""),
    mapNumberOfOverdueTasks: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    mapStatus: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lastKaiaMeeting: (f = msg.getLastKaiaMeeting()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.Opportunity}
 */
proto.loupe.services.tightbeam_global.Opportunity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.Opportunity;
  return proto.loupe.services.tightbeam_global.Opportunity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.Opportunity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.Opportunity}
 */
proto.loupe.services.tightbeam_global.Opportunity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHealthCategory(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHealthScore(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 6:
      var value = new proto.loupe.services.tightbeam_global.OpportunityHealthFactor;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.OpportunityHealthFactor.deserializeBinaryFromReader);
      msg.addHealthFactors(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMapLink(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMapNextSteps(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMapNumberOfOverdueTasks(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMapStatus(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastKaiaMeeting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.Opportunity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.Opportunity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.Opportunity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getCrmId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHealthCategory();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHealthScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHealthFactorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.loupe.services.tightbeam_global.OpportunityHealthFactor.serializeBinaryToWriter
    );
  }
  f = message.getMapLink();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMapNextSteps();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMapNumberOfOverdueTasks();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getMapStatus();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLastKaiaMeeting();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string crm_id = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.getCrmId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.setCrmId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string health_category = 3;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.getHealthCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.setHealthCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double health_score = 4;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.getHealthScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.setHealthScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
*/
proto.loupe.services.tightbeam_global.Opportunity.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated OpportunityHealthFactor health_factors = 6;
 * @return {!Array<!proto.loupe.services.tightbeam_global.OpportunityHealthFactor>}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.getHealthFactorsList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.OpportunityHealthFactor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.OpportunityHealthFactor, 6));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.OpportunityHealthFactor>} value
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
*/
proto.loupe.services.tightbeam_global.Opportunity.prototype.setHealthFactorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.addHealthFactors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.services.tightbeam_global.OpportunityHealthFactor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.clearHealthFactorsList = function() {
  return this.setHealthFactorsList([]);
};


/**
 * optional string map_link = 7;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.getMapLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.setMapLink = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string map_next_steps = 8;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.getMapNextSteps = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.setMapNextSteps = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double map_number_of_overdue_tasks = 9;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.getMapNumberOfOverdueTasks = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.setMapNumberOfOverdueTasks = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string map_status = 10;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.getMapStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.setMapStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp last_kaia_meeting = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.getLastKaiaMeeting = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
*/
proto.loupe.services.tightbeam_global.Opportunity.prototype.setLastKaiaMeeting = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.Opportunity} returns this
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.clearLastKaiaMeeting = function() {
  return this.setLastKaiaMeeting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.Opportunity.prototype.hasLastKaiaMeeting = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.OpportunityHealthFactor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    healthFactorId: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    polarity: jspb.Message.getFieldWithDefault(msg, 5, ""),
    primaryMetric: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    weight: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.OpportunityHealthFactor;
  return proto.loupe.services.tightbeam_global.OpportunityHealthFactor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHealthFactorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolarity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrimaryMetric(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.OpportunityHealthFactor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHealthFactorId();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPolarity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrimaryMetric();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor} returns this
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor} returns this
*/
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor} returns this
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double health_factor_id = 3;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.getHealthFactorId = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor} returns this
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.setHealthFactorId = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor} returns this
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string polarity = 5;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.getPolarity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor} returns this
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.setPolarity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double primary_metric = 6;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.getPrimaryMetric = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor} returns this
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.setPrimaryMetric = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor} returns this
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double weight = 8;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.OpportunityHealthFactor} returns this
 */
proto.loupe.services.tightbeam_global.OpportunityHealthFactor.prototype.setWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    externalBaseUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse;
  return proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalBaseUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExternalBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string external_base_url = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.prototype.getExternalBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncAuthTokenResponse.prototype.setExternalBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    crmType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest;
  return proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.services.tightbeam_global.CrmType} */ (reader.readEnum());
      msg.setCrmType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCrmType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string org_guid = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.prototype.setOrgGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CrmType crm_type = 2;
 * @return {!proto.loupe.services.tightbeam_global.CrmType}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.prototype.getCrmType = function() {
  return /** @type {!proto.loupe.services.tightbeam_global.CrmType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.services.tightbeam_global.CrmType} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsRequest.prototype.setCrmType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    proto.loupe.services.tightbeam_global.OutreachSyncObject.toObject, includeInstance),
    externalBaseUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sandbox: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    name: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse;
  return proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.tightbeam_global.OutreachSyncObject;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.OutreachSyncObject.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalBaseUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSandbox(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.tightbeam_global.OutreachSyncObject.serializeBinaryToWriter
    );
  }
  f = message.getExternalBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSandbox();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * repeated OutreachSyncObject objects = 1;
 * @return {!Array<!proto.loupe.services.tightbeam_global.OutreachSyncObject>}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.OutreachSyncObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.OutreachSyncObject, 1));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.OutreachSyncObject>} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse} returns this
*/
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.OutreachSyncObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.tightbeam_global.OutreachSyncObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};


/**
 * optional string external_base_url = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.getExternalBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.setExternalBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string external_id = 3;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool sandbox = 4;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.getSandbox = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.setSandbox = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool enabled = 5;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachSyncMappingsResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.OutreachSyncObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.OutreachSyncObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, ""),
    to: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.loupe.services.tightbeam_global.OutreachSyncField.toObject, includeInstance),
    fromConditionsList: jspb.Message.toObjectList(msg.getFromConditionsList(),
    proto.loupe.services.tightbeam_global.EventConditions.toObject, includeInstance),
    toConditionsList: jspb.Message.toObjectList(msg.getToConditionsList(),
    proto.loupe.services.tightbeam_global.EventConditions.toObject, includeInstance),
    direction: jspb.Message.getFieldWithDefault(msg, 6, 0),
    fromCreate: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    fromUpdate: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    toCreate: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    toUpdate: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.OutreachSyncObject;
  return proto.loupe.services.tightbeam_global.OutreachSyncObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.OutreachSyncObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTo(value);
      break;
    case 3:
      var value = new proto.loupe.services.tightbeam_global.OutreachSyncField;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.OutreachSyncField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 4:
      var value = new proto.loupe.services.tightbeam_global.EventConditions;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.EventConditions.deserializeBinaryFromReader);
      msg.addFromConditions(value);
      break;
    case 5:
      var value = new proto.loupe.services.tightbeam_global.EventConditions;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.EventConditions.deserializeBinaryFromReader);
      msg.addToConditions(value);
      break;
    case 6:
      var value = /** @type {!proto.loupe.services.tightbeam_global.SyncDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFromCreate(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFromUpdate(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToCreate(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.OutreachSyncObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.OutreachSyncObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.services.tightbeam_global.OutreachSyncField.serializeBinaryToWriter
    );
  }
  f = message.getFromConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.loupe.services.tightbeam_global.EventConditions.serializeBinaryToWriter
    );
  }
  f = message.getToConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.loupe.services.tightbeam_global.EventConditions.serializeBinaryToWriter
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getFromCreate();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFromUpdate();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getToCreate();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getToUpdate();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string from = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.getTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.setTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated OutreachSyncField fields = 3;
 * @return {!Array<!proto.loupe.services.tightbeam_global.OutreachSyncField>}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.OutreachSyncField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.OutreachSyncField, 3));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.OutreachSyncField>} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
*/
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.OutreachSyncField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncField}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.services.tightbeam_global.OutreachSyncField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * repeated EventConditions from_conditions = 4;
 * @return {!Array<!proto.loupe.services.tightbeam_global.EventConditions>}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.getFromConditionsList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.EventConditions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.EventConditions, 4));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.EventConditions>} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
*/
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.setFromConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.EventConditions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.EventConditions}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.addFromConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.services.tightbeam_global.EventConditions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.clearFromConditionsList = function() {
  return this.setFromConditionsList([]);
};


/**
 * repeated EventConditions to_conditions = 5;
 * @return {!Array<!proto.loupe.services.tightbeam_global.EventConditions>}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.getToConditionsList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.EventConditions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.EventConditions, 5));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.EventConditions>} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
*/
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.setToConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.EventConditions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.EventConditions}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.addToConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.services.tightbeam_global.EventConditions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.clearToConditionsList = function() {
  return this.setToConditionsList([]);
};


/**
 * optional SyncDirection direction = 6;
 * @return {!proto.loupe.services.tightbeam_global.SyncDirection}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.getDirection = function() {
  return /** @type {!proto.loupe.services.tightbeam_global.SyncDirection} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.loupe.services.tightbeam_global.SyncDirection} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool from_create = 7;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.getFromCreate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.setFromCreate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool from_update = 8;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.getFromUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.setFromUpdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool to_create = 9;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.getToCreate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.setToCreate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool to_update = 10;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.getToUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncObject} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncObject.prototype.setToUpdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.OutreachSyncField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.OutreachSyncField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, ""),
    to: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromUpdate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    toUpdate: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncField}
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.OutreachSyncField;
  return proto.loupe.services.tightbeam_global.OutreachSyncField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.OutreachSyncField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncField}
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFromUpdate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.OutreachSyncField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.OutreachSyncField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromUpdate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getToUpdate();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string from = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncField} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.prototype.getTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncField} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.prototype.setTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool from_update = 3;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.prototype.getFromUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncField} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.prototype.setFromUpdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool to_update = 4;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.prototype.getToUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachSyncField} returns this
 */
proto.loupe.services.tightbeam_global.OutreachSyncField.prototype.setToUpdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetAccessTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetAccessTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    crmType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    forceRefresh: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    includeUrl: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetAccessTokenRequest}
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetAccessTokenRequest;
  return proto.loupe.services.tightbeam_global.GetAccessTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetAccessTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetAccessTokenRequest}
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.services.tightbeam_global.CrmType} */ (reader.readEnum());
      msg.setCrmType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceRefresh(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetAccessTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetAccessTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCrmType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getForceRefresh();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIncludeUrl();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string org_guid = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetAccessTokenRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.prototype.setOrgGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CrmType crm_type = 2;
 * @return {!proto.loupe.services.tightbeam_global.CrmType}
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.prototype.getCrmType = function() {
  return /** @type {!proto.loupe.services.tightbeam_global.CrmType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.services.tightbeam_global.CrmType} value
 * @return {!proto.loupe.services.tightbeam_global.GetAccessTokenRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.prototype.setCrmType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool force_refresh = 3;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.prototype.getForceRefresh = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.GetAccessTokenRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.prototype.setForceRefresh = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool include_url = 4;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.prototype.getIncludeUrl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.GetAccessTokenRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetAccessTokenRequest.prototype.setIncludeUrl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.EventConditions.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.EventConditions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.EventConditions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.EventConditions.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: jspb.Message.getFieldWithDefault(msg, 1, 0),
    conditions: (f = msg.getConditions()) && proto.loupe.services.tightbeam_global.ConditionGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.EventConditions}
 */
proto.loupe.services.tightbeam_global.EventConditions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.EventConditions;
  return proto.loupe.services.tightbeam_global.EventConditions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.EventConditions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.EventConditions}
 */
proto.loupe.services.tightbeam_global.EventConditions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.services.tightbeam_global.EventConditions.EVENT} */ (reader.readEnum());
      msg.setEvent(value);
      break;
    case 2:
      var value = new proto.loupe.services.tightbeam_global.ConditionGroup;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.ConditionGroup.deserializeBinaryFromReader);
      msg.setConditions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.EventConditions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.EventConditions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.EventConditions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.EventConditions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getConditions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.tightbeam_global.ConditionGroup.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.loupe.services.tightbeam_global.EventConditions.EVENT = {
  UNKNOWN: 0,
  CREATED: 1,
  UPDATED: 2,
  DELETED: 3
};

/**
 * optional EVENT event = 1;
 * @return {!proto.loupe.services.tightbeam_global.EventConditions.EVENT}
 */
proto.loupe.services.tightbeam_global.EventConditions.prototype.getEvent = function() {
  return /** @type {!proto.loupe.services.tightbeam_global.EventConditions.EVENT} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.services.tightbeam_global.EventConditions.EVENT} value
 * @return {!proto.loupe.services.tightbeam_global.EventConditions} returns this
 */
proto.loupe.services.tightbeam_global.EventConditions.prototype.setEvent = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ConditionGroup conditions = 2;
 * @return {?proto.loupe.services.tightbeam_global.ConditionGroup}
 */
proto.loupe.services.tightbeam_global.EventConditions.prototype.getConditions = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.ConditionGroup} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.tightbeam_global.ConditionGroup, 2));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.ConditionGroup|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.EventConditions} returns this
*/
proto.loupe.services.tightbeam_global.EventConditions.prototype.setConditions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.EventConditions} returns this
 */
proto.loupe.services.tightbeam_global.EventConditions.prototype.clearConditions = function() {
  return this.setConditions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.EventConditions.prototype.hasConditions = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.ConditionGroup.repeatedFields_ = [3,4,7];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.loupe.services.tightbeam_global.ConditionGroup.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.GroupCase = {
  GROUP_NOT_SET: 0,
  CONJUNCTION: 1,
  DISJUNCTION: 2
};

/**
 * @return {proto.loupe.services.tightbeam_global.ConditionGroup.GroupCase}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.getGroupCase = function() {
  return /** @type {proto.loupe.services.tightbeam_global.ConditionGroup.GroupCase} */(jspb.Message.computeOneofCase(this, proto.loupe.services.tightbeam_global.ConditionGroup.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.ConditionGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.ConditionGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ConditionGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    conjunction: (f = msg.getConjunction()) && proto.loupe.services.tightbeam_global.ConjunctionGroup.toObject(includeInstance, f),
    disjunction: (f = msg.getDisjunction()) && proto.loupe.services.tightbeam_global.DisjunctionGroup.toObject(includeInstance, f),
    andList: jspb.Message.toObjectList(msg.getAndList(),
    proto.loupe.services.tightbeam_global.ConditionGroup.toObject, includeInstance),
    orList: jspb.Message.toObjectList(msg.getOrList(),
    proto.loupe.services.tightbeam_global.ConditionGroup.toObject, includeInstance),
    selector: jspb.Message.getFieldWithDefault(msg, 5, ""),
    operator: jspb.Message.getFieldWithDefault(msg, 6, ""),
    argsList: jspb.Message.toObjectList(msg.getArgsList(),
    proto.loupe.services.tightbeam_global.ConditionArgument.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.ConditionGroup;
  return proto.loupe.services.tightbeam_global.ConditionGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.ConditionGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.tightbeam_global.ConjunctionGroup;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.ConjunctionGroup.deserializeBinaryFromReader);
      msg.setConjunction(value);
      break;
    case 2:
      var value = new proto.loupe.services.tightbeam_global.DisjunctionGroup;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.DisjunctionGroup.deserializeBinaryFromReader);
      msg.setDisjunction(value);
      break;
    case 3:
      var value = new proto.loupe.services.tightbeam_global.ConditionGroup;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.ConditionGroup.deserializeBinaryFromReader);
      msg.addAnd(value);
      break;
    case 4:
      var value = new proto.loupe.services.tightbeam_global.ConditionGroup;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.ConditionGroup.deserializeBinaryFromReader);
      msg.addOr(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelector(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperator(value);
      break;
    case 7:
      var value = new proto.loupe.services.tightbeam_global.ConditionArgument;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.ConditionArgument.deserializeBinaryFromReader);
      msg.addArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.ConditionGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.ConditionGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ConditionGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConjunction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.tightbeam_global.ConjunctionGroup.serializeBinaryToWriter
    );
  }
  f = message.getDisjunction();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.tightbeam_global.DisjunctionGroup.serializeBinaryToWriter
    );
  }
  f = message.getAndList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.services.tightbeam_global.ConditionGroup.serializeBinaryToWriter
    );
  }
  f = message.getOrList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.loupe.services.tightbeam_global.ConditionGroup.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getArgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.loupe.services.tightbeam_global.ConditionArgument.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConjunctionGroup conjunction = 1;
 * @return {?proto.loupe.services.tightbeam_global.ConjunctionGroup}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.getConjunction = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.ConjunctionGroup} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.tightbeam_global.ConjunctionGroup, 1));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.ConjunctionGroup|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
*/
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.setConjunction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.loupe.services.tightbeam_global.ConditionGroup.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.clearConjunction = function() {
  return this.setConjunction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.hasConjunction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DisjunctionGroup disjunction = 2;
 * @return {?proto.loupe.services.tightbeam_global.DisjunctionGroup}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.getDisjunction = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.DisjunctionGroup} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.tightbeam_global.DisjunctionGroup, 2));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.DisjunctionGroup|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
*/
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.setDisjunction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.loupe.services.tightbeam_global.ConditionGroup.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.clearDisjunction = function() {
  return this.setDisjunction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.hasDisjunction = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ConditionGroup and = 3;
 * @return {!Array<!proto.loupe.services.tightbeam_global.ConditionGroup>}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.getAndList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.ConditionGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.ConditionGroup, 3));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.ConditionGroup>} value
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
*/
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.setAndList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.ConditionGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.addAnd = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.services.tightbeam_global.ConditionGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.clearAndList = function() {
  return this.setAndList([]);
};


/**
 * repeated ConditionGroup or = 4;
 * @return {!Array<!proto.loupe.services.tightbeam_global.ConditionGroup>}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.getOrList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.ConditionGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.ConditionGroup, 4));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.ConditionGroup>} value
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
*/
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.setOrList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.ConditionGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.addOr = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.services.tightbeam_global.ConditionGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.clearOrList = function() {
  return this.setOrList([]);
};


/**
 * optional string selector = 5;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.getSelector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.setSelector = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.clearSelector = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.hasSelector = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string operator = 6;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.getOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.setOperator = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.clearOperator = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.hasOperator = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated ConditionArgument args = 7;
 * @return {!Array<!proto.loupe.services.tightbeam_global.ConditionArgument>}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.getArgsList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.ConditionArgument>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.ConditionArgument, 7));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.ConditionArgument>} value
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
*/
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.setArgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.ConditionArgument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.ConditionArgument}
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.addArgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.loupe.services.tightbeam_global.ConditionArgument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.ConditionGroup} returns this
 */
proto.loupe.services.tightbeam_global.ConditionGroup.prototype.clearArgsList = function() {
  return this.setArgsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.ConjunctionGroup.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.ConjunctionGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.ConjunctionGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.ConjunctionGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ConjunctionGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    andList: jspb.Message.toObjectList(msg.getAndList(),
    proto.loupe.services.tightbeam_global.ConditionExpression.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.ConjunctionGroup}
 */
proto.loupe.services.tightbeam_global.ConjunctionGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.ConjunctionGroup;
  return proto.loupe.services.tightbeam_global.ConjunctionGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.ConjunctionGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.ConjunctionGroup}
 */
proto.loupe.services.tightbeam_global.ConjunctionGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.tightbeam_global.ConditionExpression;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.ConditionExpression.deserializeBinaryFromReader);
      msg.addAnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.ConjunctionGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.ConjunctionGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.ConjunctionGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ConjunctionGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAndList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.tightbeam_global.ConditionExpression.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConditionExpression and = 1;
 * @return {!Array<!proto.loupe.services.tightbeam_global.ConditionExpression>}
 */
proto.loupe.services.tightbeam_global.ConjunctionGroup.prototype.getAndList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.ConditionExpression>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.ConditionExpression, 1));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.ConditionExpression>} value
 * @return {!proto.loupe.services.tightbeam_global.ConjunctionGroup} returns this
*/
proto.loupe.services.tightbeam_global.ConjunctionGroup.prototype.setAndList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.ConditionExpression=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.ConditionExpression}
 */
proto.loupe.services.tightbeam_global.ConjunctionGroup.prototype.addAnd = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.tightbeam_global.ConditionExpression, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.ConjunctionGroup} returns this
 */
proto.loupe.services.tightbeam_global.ConjunctionGroup.prototype.clearAndList = function() {
  return this.setAndList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.loupe.services.tightbeam_global.ConditionExpression.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.loupe.services.tightbeam_global.ConditionExpression.ExpressionCase = {
  EXPRESSION_NOT_SET: 0,
  GROUP: 1,
  CONDITION: 2
};

/**
 * @return {proto.loupe.services.tightbeam_global.ConditionExpression.ExpressionCase}
 */
proto.loupe.services.tightbeam_global.ConditionExpression.prototype.getExpressionCase = function() {
  return /** @type {proto.loupe.services.tightbeam_global.ConditionExpression.ExpressionCase} */(jspb.Message.computeOneofCase(this, proto.loupe.services.tightbeam_global.ConditionExpression.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.ConditionExpression.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.ConditionExpression.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.ConditionExpression} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ConditionExpression.toObject = function(includeInstance, msg) {
  var f, obj = {
    group: (f = msg.getGroup()) && proto.loupe.services.tightbeam_global.ConditionGroup.toObject(includeInstance, f),
    condition: (f = msg.getCondition()) && proto.loupe.services.tightbeam_global.Condition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.ConditionExpression}
 */
proto.loupe.services.tightbeam_global.ConditionExpression.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.ConditionExpression;
  return proto.loupe.services.tightbeam_global.ConditionExpression.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.ConditionExpression} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.ConditionExpression}
 */
proto.loupe.services.tightbeam_global.ConditionExpression.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.tightbeam_global.ConditionGroup;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.ConditionGroup.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 2:
      var value = new proto.loupe.services.tightbeam_global.Condition;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.Condition.deserializeBinaryFromReader);
      msg.setCondition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.ConditionExpression.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.ConditionExpression.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.ConditionExpression} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ConditionExpression.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.tightbeam_global.ConditionGroup.serializeBinaryToWriter
    );
  }
  f = message.getCondition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.tightbeam_global.Condition.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConditionGroup group = 1;
 * @return {?proto.loupe.services.tightbeam_global.ConditionGroup}
 */
proto.loupe.services.tightbeam_global.ConditionExpression.prototype.getGroup = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.ConditionGroup} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.tightbeam_global.ConditionGroup, 1));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.ConditionGroup|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.ConditionExpression} returns this
*/
proto.loupe.services.tightbeam_global.ConditionExpression.prototype.setGroup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.loupe.services.tightbeam_global.ConditionExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.ConditionExpression} returns this
 */
proto.loupe.services.tightbeam_global.ConditionExpression.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ConditionExpression.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Condition condition = 2;
 * @return {?proto.loupe.services.tightbeam_global.Condition}
 */
proto.loupe.services.tightbeam_global.ConditionExpression.prototype.getCondition = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.Condition} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.tightbeam_global.Condition, 2));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.Condition|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.ConditionExpression} returns this
*/
proto.loupe.services.tightbeam_global.ConditionExpression.prototype.setCondition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.loupe.services.tightbeam_global.ConditionExpression.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.ConditionExpression} returns this
 */
proto.loupe.services.tightbeam_global.ConditionExpression.prototype.clearCondition = function() {
  return this.setCondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ConditionExpression.prototype.hasCondition = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.DisjunctionGroup.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.DisjunctionGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.DisjunctionGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.DisjunctionGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.DisjunctionGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    orList: jspb.Message.toObjectList(msg.getOrList(),
    proto.loupe.services.tightbeam_global.ConditionExpression.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.DisjunctionGroup}
 */
proto.loupe.services.tightbeam_global.DisjunctionGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.DisjunctionGroup;
  return proto.loupe.services.tightbeam_global.DisjunctionGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.DisjunctionGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.DisjunctionGroup}
 */
proto.loupe.services.tightbeam_global.DisjunctionGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.loupe.services.tightbeam_global.ConditionExpression;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.ConditionExpression.deserializeBinaryFromReader);
      msg.addOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.DisjunctionGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.DisjunctionGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.DisjunctionGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.DisjunctionGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.services.tightbeam_global.ConditionExpression.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConditionExpression or = 2;
 * @return {!Array<!proto.loupe.services.tightbeam_global.ConditionExpression>}
 */
proto.loupe.services.tightbeam_global.DisjunctionGroup.prototype.getOrList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.ConditionExpression>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.ConditionExpression, 2));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.ConditionExpression>} value
 * @return {!proto.loupe.services.tightbeam_global.DisjunctionGroup} returns this
*/
proto.loupe.services.tightbeam_global.DisjunctionGroup.prototype.setOrList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.ConditionExpression=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.ConditionExpression}
 */
proto.loupe.services.tightbeam_global.DisjunctionGroup.prototype.addOr = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.services.tightbeam_global.ConditionExpression, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.DisjunctionGroup} returns this
 */
proto.loupe.services.tightbeam_global.DisjunctionGroup.prototype.clearOrList = function() {
  return this.setOrList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.ConditionArgument.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.ConditionArgument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.ConditionArgument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ConditionArgument.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.ConditionArgument}
 */
proto.loupe.services.tightbeam_global.ConditionArgument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.ConditionArgument;
  return proto.loupe.services.tightbeam_global.ConditionArgument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.ConditionArgument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.ConditionArgument}
 */
proto.loupe.services.tightbeam_global.ConditionArgument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.ConditionArgument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.ConditionArgument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.ConditionArgument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.ConditionArgument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.ConditionArgument.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.ConditionArgument} returns this
 */
proto.loupe.services.tightbeam_global.ConditionArgument.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.ConditionArgument} returns this
 */
proto.loupe.services.tightbeam_global.ConditionArgument.prototype.clearValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.ConditionArgument.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.Condition.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.Condition.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.Condition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.Condition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.Condition.toObject = function(includeInstance, msg) {
  var f, obj = {
    selector: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operator: jspb.Message.getFieldWithDefault(msg, 2, ""),
    argsList: jspb.Message.toObjectList(msg.getArgsList(),
    proto.loupe.services.tightbeam_global.ConditionArgument.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.Condition}
 */
proto.loupe.services.tightbeam_global.Condition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.Condition;
  return proto.loupe.services.tightbeam_global.Condition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.Condition}
 */
proto.loupe.services.tightbeam_global.Condition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelector(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperator(value);
      break;
    case 3:
      var value = new proto.loupe.services.tightbeam_global.ConditionArgument;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.ConditionArgument.deserializeBinaryFromReader);
      msg.addArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.Condition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.Condition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.Condition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getArgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.services.tightbeam_global.ConditionArgument.serializeBinaryToWriter
    );
  }
};


/**
 * optional string selector = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Condition.prototype.getSelector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Condition} returns this
 */
proto.loupe.services.tightbeam_global.Condition.prototype.setSelector = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.Condition} returns this
 */
proto.loupe.services.tightbeam_global.Condition.prototype.clearSelector = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.Condition.prototype.hasSelector = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string operator = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.Condition.prototype.getOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.Condition} returns this
 */
proto.loupe.services.tightbeam_global.Condition.prototype.setOperator = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.Condition} returns this
 */
proto.loupe.services.tightbeam_global.Condition.prototype.clearOperator = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.Condition.prototype.hasOperator = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ConditionArgument args = 3;
 * @return {!Array<!proto.loupe.services.tightbeam_global.ConditionArgument>}
 */
proto.loupe.services.tightbeam_global.Condition.prototype.getArgsList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.ConditionArgument>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.ConditionArgument, 3));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.ConditionArgument>} value
 * @return {!proto.loupe.services.tightbeam_global.Condition} returns this
*/
proto.loupe.services.tightbeam_global.Condition.prototype.setArgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.ConditionArgument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.ConditionArgument}
 */
proto.loupe.services.tightbeam_global.Condition.prototype.addArgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.services.tightbeam_global.ConditionArgument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.Condition} returns this
 */
proto.loupe.services.tightbeam_global.Condition.prototype.clearArgsList = function() {
  return this.setArgsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    changedSince: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cursor: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRolesRequest}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetOutreachRolesRequest;
  return proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRolesRequest}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedSince(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChangedSince();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string org_guid = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRolesRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.prototype.setOrgGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string changed_since = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.prototype.getChangedSince = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRolesRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.prototype.setChangedSince = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cursor = 3;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRolesRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesRequest.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    proto.loupe.services.tightbeam_global.OutreachRole.toObject, includeInstance),
    cursor: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRolesResponse}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetOutreachRolesResponse;
  return proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRolesResponse}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.tightbeam_global.OutreachRole;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.OutreachRole.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.tightbeam_global.OutreachRole.serializeBinaryToWriter
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated OutreachRole roles = 1;
 * @return {!Array<!proto.loupe.services.tightbeam_global.OutreachRole>}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.OutreachRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.OutreachRole, 1));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.OutreachRole>} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRolesResponse} returns this
*/
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.OutreachRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.tightbeam_global.OutreachRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRolesResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRolesResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRolesResponse.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.OutreachRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.OutreachRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OutreachRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    outreachId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentOutreachId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    parentExternalId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    resourceType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    children: (f = msg.getChildren()) && proto.loupe.services.tightbeam_global.OutreachRole.toObject(includeInstance, f),
    outreachGlobalId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    outreachParentGlobalId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole}
 */
proto.loupe.services.tightbeam_global.OutreachRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.OutreachRole;
  return proto.loupe.services.tightbeam_global.OutreachRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.OutreachRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole}
 */
proto.loupe.services.tightbeam_global.OutreachRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentOutreachId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentExternalId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 9:
      var value = new proto.loupe.services.tightbeam_global.OutreachRole;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.OutreachRole.deserializeBinaryFromReader);
      msg.setChildren(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachGlobalId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachParentGlobalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.OutreachRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.OutreachRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OutreachRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutreachId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentOutreachId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getParentExternalId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getResourceType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getChildren();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.loupe.services.tightbeam_global.OutreachRole.serializeBinaryToWriter
    );
  }
  f = message.getOutreachGlobalId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOutreachParentGlobalId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string outreach_id = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.getOutreachId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole} returns this
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.setOutreachId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string external_id = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole} returns this
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string parent_outreach_id = 3;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.getParentOutreachId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole} returns this
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.setParentOutreachId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string parent_external_id = 4;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.getParentExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole} returns this
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.setParentExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string resource_type = 5;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.getResourceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole} returns this
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string created_at = 6;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole} returns this
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string updated_at = 7;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole} returns this
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole} returns this
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional OutreachRole children = 9;
 * @return {?proto.loupe.services.tightbeam_global.OutreachRole}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.getChildren = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.OutreachRole} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.tightbeam_global.OutreachRole, 9));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.OutreachRole|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole} returns this
*/
proto.loupe.services.tightbeam_global.OutreachRole.prototype.setChildren = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole} returns this
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.clearChildren = function() {
  return this.setChildren(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.hasChildren = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string outreach_global_id = 10;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.getOutreachGlobalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole} returns this
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.setOutreachGlobalId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string outreach_parent_global_id = 11;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.getOutreachParentGlobalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachRole} returns this
 */
proto.loupe.services.tightbeam_global.OutreachRole.prototype.setOutreachParentGlobalId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    crmType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pluginId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    externalObject: jspb.Message.getFieldWithDefault(msg, 4, ""),
    outreachObject: jspb.Message.getFieldWithDefault(msg, 5, ""),
    outreachIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest;
  return proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.services.tightbeam_global.CrmType} */ (reader.readEnum());
      msg.setCrmType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPluginId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalObject(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachObject(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addOutreachIds(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCrmType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPluginId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getExternalObject();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOutreachObject();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOutreachIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string org_guid = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.setOrgGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CrmType crm_type = 2;
 * @return {!proto.loupe.services.tightbeam_global.CrmType}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.getCrmType = function() {
  return /** @type {!proto.loupe.services.tightbeam_global.CrmType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.services.tightbeam_global.CrmType} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.setCrmType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 plugin_id = 3;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.getPluginId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.setPluginId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string external_object = 4;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.getExternalObject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.setExternalObject = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string outreach_object = 5;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.getOutreachObject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.setOutreachObject = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string outreach_ids = 6;
 * @return {!Array<string>}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.getOutreachIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.setOutreachIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.addOutreachIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.clearOutreachIdsList = function() {
  return this.setOutreachIdsList([]);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 offset = 8;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pluginMappingsList: jspb.Message.toObjectList(msg.getPluginMappingsList(),
    proto.loupe.services.tightbeam_global.OutreachPluginMappings.toObject, includeInstance),
    pluginId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse;
  return proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.tightbeam_global.OutreachPluginMappings;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.OutreachPluginMappings.deserializeBinaryFromReader);
      msg.addPluginMappings(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPluginId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPluginMappingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.tightbeam_global.OutreachPluginMappings.serializeBinaryToWriter
    );
  }
  f = message.getPluginId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated OutreachPluginMappings plugin_mappings = 1;
 * @return {!Array<!proto.loupe.services.tightbeam_global.OutreachPluginMappings>}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.prototype.getPluginMappingsList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.OutreachPluginMappings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.OutreachPluginMappings, 1));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.OutreachPluginMappings>} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse} returns this
*/
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.prototype.setPluginMappingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.OutreachPluginMappings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.OutreachPluginMappings}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.prototype.addPluginMappings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.tightbeam_global.OutreachPluginMappings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.prototype.clearPluginMappingsList = function() {
  return this.setPluginMappingsList([]);
};


/**
 * optional int32 plugin_id = 2;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.prototype.getPluginId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.prototype.setPluginId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachRecordMappingsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.OutreachPluginMappings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.OutreachPluginMappings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    externalName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    externalType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    outreachName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    outreachType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    outreachId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.OutreachPluginMappings}
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.OutreachPluginMappings;
  return proto.loupe.services.tightbeam_global.OutreachPluginMappings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.OutreachPluginMappings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.OutreachPluginMappings}
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutreachId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.OutreachPluginMappings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.OutreachPluginMappings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExternalName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternalType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOutreachName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOutreachType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOutreachId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachPluginMappings} returns this
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string external_name = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.getExternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachPluginMappings} returns this
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.setExternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string external_type = 3;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.getExternalType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachPluginMappings} returns this
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.setExternalType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string external_id = 4;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachPluginMappings} returns this
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string outreach_name = 5;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.getOutreachName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachPluginMappings} returns this
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.setOutreachName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string outreach_type = 6;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.getOutreachType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachPluginMappings} returns this
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.setOutreachType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 outreach_id = 7;
 * @return {number}
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.getOutreachId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachPluginMappings} returns this
 */
proto.loupe.services.tightbeam_global.OutreachPluginMappings.prototype.setOutreachId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    changedSince: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cursor: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachUsersRequest}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetOutreachUsersRequest;
  return proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachUsersRequest}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedSince(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChangedSince();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string org_guid = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachUsersRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.prototype.setOrgGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string role_id = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.prototype.getRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachUsersRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string changed_since = 3;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.prototype.getChangedSince = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachUsersRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.prototype.setChangedSince = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cursor = 4;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachUsersRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersRequest.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.loupe.services.tightbeam_global.OutreachUser.toObject, includeInstance),
    cursor: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachUsersResponse}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetOutreachUsersResponse;
  return proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachUsersResponse}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.tightbeam_global.OutreachUser;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.OutreachUser.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetOutreachUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.tightbeam_global.OutreachUser.serializeBinaryToWriter
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated OutreachUser users = 1;
 * @return {!Array<!proto.loupe.services.tightbeam_global.OutreachUser>}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.OutreachUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.OutreachUser, 1));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.OutreachUser>} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachUsersResponse} returns this
*/
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.OutreachUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.tightbeam_global.OutreachUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachUsersResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetOutreachUsersResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetOutreachUsersResponse.prototype.setCursor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.OutreachUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.OutreachUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OutreachUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    outreachId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    globalId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    outreachRoleId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    locked: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    profileId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    profileGlobalId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    profileName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    createdAt: jspb.Message.getFieldWithDefault(msg, 12, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser}
 */
proto.loupe.services.tightbeam_global.OutreachUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.OutreachUser;
  return proto.loupe.services.tightbeam_global.OutreachUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.OutreachUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser}
 */
proto.loupe.services.tightbeam_global.OutreachUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlobalId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachRoleId(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLocked(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileGlobalId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileName(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdmin(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.OutreachUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.OutreachUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.OutreachUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutreachId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGlobalId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOutreachRoleId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocked();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getProfileId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProfileGlobalId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProfileName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsAdmin();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string outreach_id = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getOutreachId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setOutreachId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string global_id = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getGlobalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setGlobalId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string outreach_role_id = 6;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getOutreachRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setOutreachRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool locked = 7;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getLocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setLocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string profile_id = 8;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getProfileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setProfileId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string profile_global_id = 9;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getProfileGlobalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setProfileGlobalId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string profile_name = 10;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getProfileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setProfileName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool is_admin = 11;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getIsAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setIsAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string created_at = 12;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string updated_at = 13;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.OutreachUser} returns this
 */
proto.loupe.services.tightbeam_global.OutreachUser.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.CreateCommitTenantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    crmType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isTestInstance: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    outreachOrg: (f = msg.getOutreachOrg()) && proto.loupe.services.tightbeam_global.OrgBentoIdPair.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.CreateCommitTenantRequest}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.CreateCommitTenantRequest;
  return proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.CreateCommitTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.CreateCommitTenantRequest}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTestInstance(value);
      break;
    case 4:
      var value = new proto.loupe.services.tightbeam_global.OrgBentoIdPair;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.OrgBentoIdPair.deserializeBinaryFromReader);
      msg.setOutreachOrg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.CreateCommitTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCrmType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsTestInstance();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOutreachOrg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.loupe.services.tightbeam_global.OrgBentoIdPair.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.CreateCommitTenantRequest} returns this
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string crm_type = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.prototype.getCrmType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.CreateCommitTenantRequest} returns this
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.prototype.setCrmType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_test_instance = 3;
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.prototype.getIsTestInstance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.tightbeam_global.CreateCommitTenantRequest} returns this
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.prototype.setIsTestInstance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional OrgBentoIdPair outreach_org = 4;
 * @return {?proto.loupe.services.tightbeam_global.OrgBentoIdPair}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.prototype.getOutreachOrg = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.OrgBentoIdPair} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.tightbeam_global.OrgBentoIdPair, 4));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.OrgBentoIdPair|undefined} value
 * @return {!proto.loupe.services.tightbeam_global.CreateCommitTenantRequest} returns this
*/
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.prototype.setOutreachOrg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.tightbeam_global.CreateCommitTenantRequest} returns this
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.prototype.clearOutreachOrg = function() {
  return this.setOutreachOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantRequest.prototype.hasOutreachOrg = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.CreateCommitTenantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.CreateCommitTenantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.CreateCommitTenantResponse}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.CreateCommitTenantResponse;
  return proto.loupe.services.tightbeam_global.CreateCommitTenantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.CreateCommitTenantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.CreateCommitTenantResponse}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.CreateCommitTenantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.CreateCommitTenantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.CreateCommitTenantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settingsKeyList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest}
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest;
  return proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest}
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSettingsKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettingsKeyList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string org_guid = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.prototype.setOrgGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string settings_key = 2;
 * @return {!Array<string>}
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.prototype.getSettingsKeyList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.prototype.setSettingsKeyList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.prototype.addSettingsKey = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsRequest.prototype.clearSettingsKeyList = function() {
  return this.setSettingsKeyList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgSettingsList: jspb.Message.toObjectList(msg.getOrgSettingsList(),
    proto.loupe.services.tightbeam_global.CommitOrgSettings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse}
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse;
  return proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse}
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.tightbeam_global.CommitOrgSettings;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.CommitOrgSettings.deserializeBinaryFromReader);
      msg.addOrgSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.tightbeam_global.CommitOrgSettings.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommitOrgSettings org_settings = 1;
 * @return {!Array<!proto.loupe.services.tightbeam_global.CommitOrgSettings>}
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.prototype.getOrgSettingsList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.CommitOrgSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.CommitOrgSettings, 1));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.CommitOrgSettings>} value
 * @return {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse} returns this
*/
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.prototype.setOrgSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.CommitOrgSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.CommitOrgSettings}
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.prototype.addOrgSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.tightbeam_global.CommitOrgSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse} returns this
 */
proto.loupe.services.tightbeam_global.GetCommitOrgSettingsResponse.prototype.clearOrgSettingsList = function() {
  return this.setOrgSettingsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orgSettingsList: jspb.Message.toObjectList(msg.getOrgSettingsList(),
    proto.loupe.services.tightbeam_global.CommitOrgSettings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest}
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest;
  return proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest}
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgGuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = new proto.loupe.services.tightbeam_global.CommitOrgSettings;
      reader.readMessage(value,proto.loupe.services.tightbeam_global.CommitOrgSettings.deserializeBinaryFromReader);
      msg.addOrgSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrgSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.services.tightbeam_global.CommitOrgSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string org_guid = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.prototype.getOrgGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.prototype.setOrgGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CommitOrgSettings org_settings = 3;
 * @return {!Array<!proto.loupe.services.tightbeam_global.CommitOrgSettings>}
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.prototype.getOrgSettingsList = function() {
  return /** @type{!Array<!proto.loupe.services.tightbeam_global.CommitOrgSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.tightbeam_global.CommitOrgSettings, 3));
};


/**
 * @param {!Array<!proto.loupe.services.tightbeam_global.CommitOrgSettings>} value
 * @return {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest} returns this
*/
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.prototype.setOrgSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.services.tightbeam_global.CommitOrgSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.tightbeam_global.CommitOrgSettings}
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.prototype.addOrgSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.services.tightbeam_global.CommitOrgSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest} returns this
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsRequest.prototype.clearOrgSettingsList = function() {
  return this.setOrgSettingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.CommitOrgSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.CommitOrgSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: msg.getValue_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.CommitOrgSettings}
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.CommitOrgSettings;
  return proto.loupe.services.tightbeam_global.CommitOrgSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.CommitOrgSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.CommitOrgSettings}
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.CommitOrgSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.CommitOrgSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.tightbeam_global.CommitOrgSettings} returns this
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes value = 2;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings.prototype.getValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes value = 2;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.tightbeam_global.CommitOrgSettings} returns this
 */
proto.loupe.services.tightbeam_global.CommitOrgSettings.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse}
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse;
  return proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse}
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.tightbeam_global.UpdateCommitOrgSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.loupe.services.tightbeam_global.CrmType = {
  CRM_TYPE_UNSPECIFIED: 0,
  CRM_TYPE_SALESFORCE: 1,
  CRM_TYPE_SALESFORCE_SANDBOX: 2
};

/**
 * @enum {number}
 */
proto.loupe.services.tightbeam_global.SyncDirection = {
  SYNC_DIRECTION_NONE: 0,
  SYNC_DIRECTION_OUTREACH: 1,
  SYNC_DIRECTION_CRM: 2,
  SYNC_DIRECTION_BIDIRECTIONAL: 3
};

goog.object.extend(exports, proto.loupe.services.tightbeam_global);
