// source: src/common/tenant/tenant.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_liftoff_liftoff_pb = require('../../../src/common/liftoff/liftoff_pb.js');
goog.object.extend(proto, src_common_liftoff_liftoff_pb);
var src_services_tightbeam_global_tightbeam_global_pb = require('../../../src/services/tightbeam_global/tightbeam_global_pb.js');
goog.object.extend(proto, src_services_tightbeam_global_tightbeam_global_pb);
goog.exportSymbol('proto.loupe.common.tenant.GroupSyncMetadata', null, global);
goog.exportSymbol('proto.loupe.common.tenant.GroupSyncStatus', null, global);
goog.exportSymbol('proto.loupe.common.tenant.LicenseTier', null, global);
goog.exportSymbol('proto.loupe.common.tenant.LicenseType', null, global);
goog.exportSymbol('proto.loupe.common.tenant.OrgSettings', null, global);
goog.exportSymbol('proto.loupe.common.tenant.SandboxType', null, global);
goog.exportSymbol('proto.loupe.common.tenant.Tenant', null, global);
goog.exportSymbol('proto.loupe.common.tenant.TenantStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.OrgSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.tenant.OrgSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.OrgSettings.displayName = 'proto.loupe.common.tenant.OrgSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.Tenant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.tenant.Tenant.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.tenant.Tenant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.Tenant.displayName = 'proto.loupe.common.tenant.Tenant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.tenant.GroupSyncMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.tenant.GroupSyncMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.tenant.GroupSyncMetadata.displayName = 'proto.loupe.common.tenant.GroupSyncMetadata';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.OrgSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.OrgSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.OrgSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.OrgSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    salesperiodlength: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fiscalyearstartmonth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    defaultcurrency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.OrgSettings}
 */
proto.loupe.common.tenant.OrgSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.OrgSettings;
  return proto.loupe.common.tenant.OrgSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.OrgSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.OrgSettings}
 */
proto.loupe.common.tenant.OrgSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSalesperiodlength(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFiscalyearstartmonth(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultcurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.OrgSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.OrgSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.OrgSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.OrgSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSalesperiodlength();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFiscalyearstartmonth();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDefaultcurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 SalesPeriodLength = 1;
 * @return {number}
 */
proto.loupe.common.tenant.OrgSettings.prototype.getSalesperiodlength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.tenant.OrgSettings} returns this
 */
proto.loupe.common.tenant.OrgSettings.prototype.setSalesperiodlength = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 FiscalYearStartMonth = 2;
 * @return {number}
 */
proto.loupe.common.tenant.OrgSettings.prototype.getFiscalyearstartmonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.tenant.OrgSettings} returns this
 */
proto.loupe.common.tenant.OrgSettings.prototype.setFiscalyearstartmonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string DefaultCurrency = 3;
 * @return {string}
 */
proto.loupe.common.tenant.OrgSettings.prototype.getDefaultcurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.OrgSettings} returns this
 */
proto.loupe.common.tenant.OrgSettings.prototype.setDefaultcurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Timezone = 4;
 * @return {string}
 */
proto.loupe.common.tenant.OrgSettings.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.OrgSettings} returns this
 */
proto.loupe.common.tenant.OrgSettings.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.tenant.Tenant.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.Tenant.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.Tenant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.Tenant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.Tenant.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    crmId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isTestInstance: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    parentTenantId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    groupSyncState: jspb.Message.getFieldWithDefault(msg, 7, 0),
    groupSyncMetadata: (f = msg.getGroupSyncMetadata()) && proto.loupe.common.tenant.GroupSyncMetadata.toObject(includeInstance, f),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    prelaunchState: (f = msg.getPrelaunchState()) && src_common_liftoff_liftoff_pb.LiftoffPrelaunchConfig.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 13, ""),
    initialType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    setupStatus: jspb.Message.getFieldWithDefault(msg, 15, 0),
    outreachOrg: (f = msg.getOutreachOrg()) && src_services_tightbeam_global_tightbeam_global_pb.Org.toObject(includeInstance, f),
    licenseType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    licenseTier: jspb.Message.getFieldWithDefault(msg, 18, 0),
    region: jspb.Message.getFieldWithDefault(msg, 19, ""),
    orgSettings: (f = msg.getOrgSettings()) && proto.loupe.common.tenant.OrgSettings.toObject(includeInstance, f),
    dataSyncSettings: msg.getDataSyncSettings_asB64(),
    terminateSync: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.Tenant}
 */
proto.loupe.common.tenant.Tenant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.Tenant;
  return proto.loupe.common.tenant.Tenant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.Tenant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.Tenant}
 */
proto.loupe.common.tenant.Tenant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTestInstance(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentTenantId(value);
      break;
    case 7:
      var value = /** @type {!proto.loupe.common.tenant.GroupSyncStatus} */ (reader.readEnum());
      msg.setGroupSyncState(value);
      break;
    case 8:
      var value = new proto.loupe.common.tenant.GroupSyncMetadata;
      reader.readMessage(value,proto.loupe.common.tenant.GroupSyncMetadata.deserializeBinaryFromReader);
      msg.setGroupSyncMetadata(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPermissions(values[i]);
      }
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 12:
      var value = new src_common_liftoff_liftoff_pb.LiftoffPrelaunchConfig;
      reader.readMessage(value,src_common_liftoff_liftoff_pb.LiftoffPrelaunchConfig.deserializeBinaryFromReader);
      msg.setPrelaunchState(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 14:
      var value = /** @type {!proto.loupe.common.tenant.SandboxType} */ (reader.readEnum());
      msg.setInitialType(value);
      break;
    case 15:
      var value = /** @type {!proto.loupe.common.liftoff.InstanceSetupStatus} */ (reader.readEnum());
      msg.setSetupStatus(value);
      break;
    case 16:
      var value = new src_services_tightbeam_global_tightbeam_global_pb.Org;
      reader.readMessage(value,src_services_tightbeam_global_tightbeam_global_pb.Org.deserializeBinaryFromReader);
      msg.setOutreachOrg(value);
      break;
    case 17:
      var value = /** @type {!proto.loupe.common.tenant.LicenseType} */ (reader.readEnum());
      msg.setLicenseType(value);
      break;
    case 18:
      var value = /** @type {!proto.loupe.common.tenant.LicenseTier} */ (reader.readEnum());
      msg.setLicenseTier(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 20:
      var value = new proto.loupe.common.tenant.OrgSettings;
      reader.readMessage(value,proto.loupe.common.tenant.OrgSettings.deserializeBinaryFromReader);
      msg.setOrgSettings(value);
      break;
    case 21:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDataSyncSettings(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminateSync(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.Tenant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.Tenant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.Tenant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.Tenant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCrmId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsTestInstance();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getParentTenantId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGroupSyncState();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getGroupSyncMetadata();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.loupe.common.tenant.GroupSyncMetadata.serializeBinaryToWriter
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPrelaunchState();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      src_common_liftoff_liftoff_pb.LiftoffPrelaunchConfig.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getInitialType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getSetupStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getOutreachOrg();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      src_services_tightbeam_global_tightbeam_global_pb.Org.serializeBinaryToWriter
    );
  }
  f = message.getLicenseType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getLicenseTier();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getOrgSettings();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.loupe.common.tenant.OrgSettings.serializeBinaryToWriter
    );
  }
  f = message.getDataSyncSettings_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      21,
      f
    );
  }
  f = message.getTerminateSync();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.tenant.Tenant.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.loupe.common.tenant.Tenant.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.loupe.common.tenant.Tenant.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string crm_id = 4;
 * @return {string}
 */
proto.loupe.common.tenant.Tenant.prototype.getCrmId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setCrmId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_test_instance = 5;
 * @return {boolean}
 */
proto.loupe.common.tenant.Tenant.prototype.getIsTestInstance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setIsTestInstance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string parent_tenant_id = 6;
 * @return {string}
 */
proto.loupe.common.tenant.Tenant.prototype.getParentTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setParentTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional GroupSyncStatus group_sync_state = 7;
 * @return {!proto.loupe.common.tenant.GroupSyncStatus}
 */
proto.loupe.common.tenant.Tenant.prototype.getGroupSyncState = function() {
  return /** @type {!proto.loupe.common.tenant.GroupSyncStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.loupe.common.tenant.GroupSyncStatus} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setGroupSyncState = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional GroupSyncMetadata group_sync_metadata = 8;
 * @return {?proto.loupe.common.tenant.GroupSyncMetadata}
 */
proto.loupe.common.tenant.Tenant.prototype.getGroupSyncMetadata = function() {
  return /** @type{?proto.loupe.common.tenant.GroupSyncMetadata} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.tenant.GroupSyncMetadata, 8));
};


/**
 * @param {?proto.loupe.common.tenant.GroupSyncMetadata|undefined} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
*/
proto.loupe.common.tenant.Tenant.prototype.setGroupSyncMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.clearGroupSyncMetadata = function() {
  return this.setGroupSyncMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.Tenant.prototype.hasGroupSyncMetadata = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated int64 permissions = 9;
 * @return {!Array<number>}
 */
proto.loupe.common.tenant.Tenant.prototype.getPermissionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.tenant.Tenant.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
*/
proto.loupe.common.tenant.Tenant.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.Tenant.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.tenant.Tenant.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
*/
proto.loupe.common.tenant.Tenant.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.Tenant.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional loupe.common.liftoff.LiftoffPrelaunchConfig prelaunch_state = 12;
 * @return {?proto.loupe.common.liftoff.LiftoffPrelaunchConfig}
 */
proto.loupe.common.tenant.Tenant.prototype.getPrelaunchState = function() {
  return /** @type{?proto.loupe.common.liftoff.LiftoffPrelaunchConfig} */ (
    jspb.Message.getWrapperField(this, src_common_liftoff_liftoff_pb.LiftoffPrelaunchConfig, 12));
};


/**
 * @param {?proto.loupe.common.liftoff.LiftoffPrelaunchConfig|undefined} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
*/
proto.loupe.common.tenant.Tenant.prototype.setPrelaunchState = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.clearPrelaunchState = function() {
  return this.setPrelaunchState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.Tenant.prototype.hasPrelaunchState = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string description = 13;
 * @return {string}
 */
proto.loupe.common.tenant.Tenant.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional SandboxType initial_type = 14;
 * @return {!proto.loupe.common.tenant.SandboxType}
 */
proto.loupe.common.tenant.Tenant.prototype.getInitialType = function() {
  return /** @type {!proto.loupe.common.tenant.SandboxType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.loupe.common.tenant.SandboxType} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setInitialType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional loupe.common.liftoff.InstanceSetupStatus setup_status = 15;
 * @return {!proto.loupe.common.liftoff.InstanceSetupStatus}
 */
proto.loupe.common.tenant.Tenant.prototype.getSetupStatus = function() {
  return /** @type {!proto.loupe.common.liftoff.InstanceSetupStatus} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.loupe.common.liftoff.InstanceSetupStatus} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setSetupStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional loupe.services.tightbeam_global.Org outreach_org = 16;
 * @return {?proto.loupe.services.tightbeam_global.Org}
 */
proto.loupe.common.tenant.Tenant.prototype.getOutreachOrg = function() {
  return /** @type{?proto.loupe.services.tightbeam_global.Org} */ (
    jspb.Message.getWrapperField(this, src_services_tightbeam_global_tightbeam_global_pb.Org, 16));
};


/**
 * @param {?proto.loupe.services.tightbeam_global.Org|undefined} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
*/
proto.loupe.common.tenant.Tenant.prototype.setOutreachOrg = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.clearOutreachOrg = function() {
  return this.setOutreachOrg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.Tenant.prototype.hasOutreachOrg = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional LicenseType license_type = 17;
 * @return {!proto.loupe.common.tenant.LicenseType}
 */
proto.loupe.common.tenant.Tenant.prototype.getLicenseType = function() {
  return /** @type {!proto.loupe.common.tenant.LicenseType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.loupe.common.tenant.LicenseType} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setLicenseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional LicenseTier license_tier = 18;
 * @return {!proto.loupe.common.tenant.LicenseTier}
 */
proto.loupe.common.tenant.Tenant.prototype.getLicenseTier = function() {
  return /** @type {!proto.loupe.common.tenant.LicenseTier} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.loupe.common.tenant.LicenseTier} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setLicenseTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional string region = 19;
 * @return {string}
 */
proto.loupe.common.tenant.Tenant.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional OrgSettings org_settings = 20;
 * @return {?proto.loupe.common.tenant.OrgSettings}
 */
proto.loupe.common.tenant.Tenant.prototype.getOrgSettings = function() {
  return /** @type{?proto.loupe.common.tenant.OrgSettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.tenant.OrgSettings, 20));
};


/**
 * @param {?proto.loupe.common.tenant.OrgSettings|undefined} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
*/
proto.loupe.common.tenant.Tenant.prototype.setOrgSettings = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.clearOrgSettings = function() {
  return this.setOrgSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.tenant.Tenant.prototype.hasOrgSettings = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional bytes data_sync_settings = 21;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.common.tenant.Tenant.prototype.getDataSyncSettings = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * optional bytes data_sync_settings = 21;
 * This is a type-conversion wrapper around `getDataSyncSettings()`
 * @return {string}
 */
proto.loupe.common.tenant.Tenant.prototype.getDataSyncSettings_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDataSyncSettings()));
};


/**
 * optional bytes data_sync_settings = 21;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDataSyncSettings()`
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.Tenant.prototype.getDataSyncSettings_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDataSyncSettings()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setDataSyncSettings = function(value) {
  return jspb.Message.setProto3BytesField(this, 21, value);
};


/**
 * optional string terminate_sync = 22;
 * @return {string}
 */
proto.loupe.common.tenant.Tenant.prototype.getTerminateSync = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.tenant.Tenant} returns this
 */
proto.loupe.common.tenant.Tenant.prototype.setTerminateSync = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.tenant.GroupSyncMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.tenant.GroupSyncMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.tenant.GroupSyncMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.GroupSyncMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    overwriteLegacyTeam: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.tenant.GroupSyncMetadata}
 */
proto.loupe.common.tenant.GroupSyncMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.tenant.GroupSyncMetadata;
  return proto.loupe.common.tenant.GroupSyncMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.tenant.GroupSyncMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.tenant.GroupSyncMetadata}
 */
proto.loupe.common.tenant.GroupSyncMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverwriteLegacyTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.tenant.GroupSyncMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.tenant.GroupSyncMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.tenant.GroupSyncMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.tenant.GroupSyncMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOverwriteLegacyTeam();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool overwrite_legacy_team = 1;
 * @return {boolean}
 */
proto.loupe.common.tenant.GroupSyncMetadata.prototype.getOverwriteLegacyTeam = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.tenant.GroupSyncMetadata} returns this
 */
proto.loupe.common.tenant.GroupSyncMetadata.prototype.setOverwriteLegacyTeam = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.tenant.GroupSyncStatus = {
  INACTIVE: 0,
  PEOPLEONLY: 1,
  ACTIVE: 2
};

/**
 * @enum {number}
 */
proto.loupe.common.tenant.TenantStatus = {
  STATUS_UNSPECIFIED: 0,
  STATUS_ACTIVE: 1,
  STATUS_DELETED: 2,
  STATUS_PENDING: 3,
  STATUS_EXPIRED: 4,
  STATUS_ARCHIVED: 5
};

/**
 * @enum {number}
 */
proto.loupe.common.tenant.SandboxType = {
  SANDBOX_TYPE_UNSPECIFIED: 0,
  SANDBOX_TYPE_EMPTY: 1,
  SANDBOX_TYPE_CLONE: 2,
  SANDBOX_TYPE_DEMO: 3
};

/**
 * @enum {number}
 */
proto.loupe.common.tenant.LicenseType = {
  LICENSE_TYPE_UNKNOWN: 0,
  LICENSE_TYPE_COMMIT_STANDALONE: 1,
  LICENSE_TYPE_CREATE_AND_CLOSE_HYBRID: 2,
  LICENSE_TYPE_CREATE_AND_CLOSE: 3
};

/**
 * @enum {number}
 */
proto.loupe.common.tenant.LicenseTier = {
  LICENSE_TIER_UNKNOWN: 0,
  LICENSE_TIER_STANDARD: 1,
  LICENSE_TIER_PLUS: 2,
  LICENSE_TIER_ENERPRISE: 3
};

goog.object.extend(exports, proto.loupe.common.tenant);
