import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toGetGoalCategoriesRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useAuth } from './auth'
import { orderBy } from 'lodash'

const GoalCategoriesContext = React.createContext()

export function GoalCategoriesProvider({ children }) {
  const { tenantId } = useAuth()

  const { notifyError } = useNotification()

  const [key, setKey] = useState(guid())
  const [goalCategories, setGoalCategories] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  useGrpcEffect({
    request: toGetGoalCategoriesRequest({
      tenantId,
    }),
    onError: (obj) => {
      setIsFetching(false)
      notifyError('Error fetching goal categories!')
    },
    onSuccess: (obj) => {
      setIsFetching(false)
      setGoalCategories(orderBy(obj.goalCategoriesList, (g) => g.name))
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getGoalCategories',
    debug: false,
  }, [tenantId, key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      goalCategories,
      setGoalCategories,
      key,
      invalidate,
    }
  }, [isFetching, goalCategories])

  return <GoalCategoriesContext.Provider value={contextValue}>{children}</GoalCategoriesContext.Provider>
}

export function useGoalCategories() {
  const context = React.useContext(GoalCategoriesContext)
  if (context === undefined) {
    throw new Error('useGoalCategories must be used within a GoalCategoriesProvider')
  }
  return context
}
