import React from 'react'

const AdminLogo = (props) => {
  return (
    <svg width="157" height="36" viewBox="0 0 157 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M60.99 27H65.64L57.93 6.99H52.59L44.85 27H49.53L50.79 23.55H59.73L60.99 27ZM55.26 10.68L58.56 19.86H51.93L55.26 10.68ZM77.3578 27H81.1978V6.99H77.3578V14.37C76.2178 12.9 74.5978 12.15 72.8878 12.15C69.1978 12.15 66.4678 15.03 66.4678 19.77C66.4678 24.6 69.2278 27.36 72.8878 27.36C74.6278 27.36 76.2178 26.58 77.3578 25.17V27ZM77.3578 22.32C76.6978 23.28 75.3478 23.97 74.0578 23.97C71.8978 23.97 70.3978 22.26 70.3978 19.77C70.3978 17.25 71.8978 15.54 74.0578 15.54C75.3478 15.54 76.6978 16.23 77.3578 17.19V22.32ZM102.946 27H106.786V16.53C106.786 13.56 105.196 12.15 102.526 12.15C100.306 12.15 98.3859 13.47 97.5459 14.79C97.0059 13.14 95.6559 12.15 93.5259 12.15C91.3059 12.15 89.3859 13.53 88.7859 14.4V12.51H84.9759V27H88.7859V17.25C89.3559 16.44 90.4659 15.54 91.7859 15.54C93.3459 15.54 93.9459 16.5 93.9459 17.85V27H97.7859V17.22C98.3259 16.44 99.4359 15.54 100.786 15.54C102.346 15.54 102.946 16.5 102.946 17.85V27ZM112.413 10.38C113.673 10.38 114.693 9.36 114.693 8.1C114.693 6.84 113.673 5.82 112.413 5.82C111.183 5.82 110.133 6.84 110.133 8.1C110.133 9.36 111.183 10.38 112.413 10.38ZM110.523 27H114.333V12.51H110.523V27ZM127.891 27H131.701V16.77C131.701 13.95 130.171 12.15 126.991 12.15C124.621 12.15 122.851 13.29 121.921 14.4V12.51H118.111V27H121.921V17.25C122.551 16.38 123.721 15.54 125.221 15.54C126.841 15.54 127.891 16.23 127.891 18.24V27Z" fill="#09242F" />
      <g transform="matrix(0.253599,0,0,0.253599,0,0)">
        <path d="M85.149,74.572C78.207,81.513 71.496,84.12 64.993,84.12C58.49,84.12 52.032,81.075 47.927,76.969C43.478,72.521 39.395,65.128 39.395,57.473C39.395,49.815 42.426,44.426 45.932,40.92C50.406,36.445 57.119,33.288 67.109,33.288C74.996,33.288 82.245,35.136 88.793,41.685C93.061,45.951 93.96,50.955 93.96,54.368C93.96,59.074 92.091,67.628 85.149,74.572ZM59.698,0C38.734,0 22.939,5.304 13.772,14.471C3.878,24.364 0,36.266 0,49.866C0,71.434 10.913,94.564 26.149,109.8C30.128,113.778 43.869,126 61.856,126C80.26,126 93.942,114.758 102.542,106.158C119.179,89.519 134.245,60.831 134.245,42.128C134.245,31.612 129.451,24.435 125.786,20.767C111.325,6.306 80.26,0 59.698,0Z" fill="rgb(89,82,255)"/>
    </g>
    </svg>
  )
}

export default AdminLogo
