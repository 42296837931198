import { OnboardingStep } from '../../grpc/enums'
import { permissionNames } from '../../constants/permissionNames'
import { useAuth } from '../../context/auth'
import { usePermissions } from '../../context/permissions'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { useSetup } from '../../context/setup'
import React, { useCallback, useMemo } from 'react'
import SideMenuItemStep from './sideMenuItemStep'
import SideMenuItemSteps from './sideMenuItemSteps'
import { useLoggedInUser } from '../../context/loggedInUser'
import { find } from 'lodash'
import { featureNames } from '../constants/featureNames'

const SideMenuSetup = (props) => {
  const { checkPermissions } = usePermissions()
  const { isStepComplete } = useSetup()
  const { routes } = useRoutes()
  const history = useHistory()

  const { tenantContext } = useAuth()
  const { settings, hasSettings } = useLoggedInUser()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanAccessGroup,
      permissionNames.CanAccessUser,
      permissionNames.CanAccessGoal,
      permissionNames.CanAccessSync,
      permissionNames.CanAccessSystemRole,
      permissionNames.CanAccessSandbox,
      permissionNames.CanAccessForecasts
    )
  }, [checkPermissions])

  const groupsMatch = useRouteMatch(routes.teams)
  const usersMatch = useRouteMatch(routes.users)
  const goalsMatch = useRouteMatch(routes.goals)
  const dataSyncMatch = useRouteMatch(routes.dataSync)
  const dataSyncHistoryMatch = useRouteMatch(routes.dataSyncHistory)
  const dataSyncObjectMappingMatch = useRouteMatch(routes.dataSyncObjectMapping)
  const rolesMatch = useRouteMatch(routes.roles)
  const sandboxMatch = useRouteMatch(routes.sandboxes)
  const forecastsMatch = useRouteMatch(routes.forecasts)

  const handleClick = useCallback((action, route) => {
    window.analytics.track('admin.sideMenuClicked', { action, setup: true })
    history.push(route)
  }, [history])

  const forecastManagementEnabled = useMemo(() => {
    const forecastManagementSetting = find(settings, (s) => s.key === featureNames.forecastManagement)
    return forecastManagementSetting?.payload?.enabled
  }, [settings])

  return (
    <SideMenuItemSteps>
      {permissions.CanAccessSync && (
        <SideMenuItemStep
          completed={isStepComplete(OnboardingStep.ONBOARDING_STEP_DATA_SYNC)}
          label="Data Sync"
          isCurrent={(dataSyncMatch && dataSyncMatch.isExact)
            || (dataSyncHistoryMatch && dataSyncHistoryMatch.isExact)
            || (dataSyncObjectMappingMatch && dataSyncObjectMappingMatch.isExact)}
          onClick={handleClick.bind(this, 'Data Sync', routes.dataSync)} />
      )}

      {permissions.CanAccessGroup && (
        <SideMenuItemStep
          completed={isStepComplete(OnboardingStep.ONBOARDING_STEP_TEAMS)}
          label="Teams"
          isCurrent={groupsMatch && groupsMatch.isExact}
          onClick={handleClick.bind(this, 'Teams', routes.teams)} />
      )}

      {permissions.CanAccessUser && (
        <SideMenuItemStep
          completed={isStepComplete(OnboardingStep.ONBOARDING_STEP_USERS)}
          label="Users"
          isCurrent={usersMatch && usersMatch.isExact}
          onClick={handleClick.bind(this, 'Users', routes.users)} />
      )}

      {permissions.CanAccessSystemRole && (
        <SideMenuItemStep
          completed={isStepComplete(OnboardingStep.ONBOARDING_STEP_ROLES)}
          label="Roles"
          isCurrent={rolesMatch && rolesMatch.isExact}
          onClick={handleClick.bind(this, 'Roles', routes.roles)} />
      )}

      {permissions.CanAccessGoal && (
        <SideMenuItemStep
          completed={isStepComplete(OnboardingStep.ONBOARDING_STEP_GOALS)}
          label="Goals"
          isCurrent={goalsMatch && goalsMatch.isExact}
          onClick={handleClick.bind(this, 'Goals', routes.goals)} />
      )}

      {hasSettings && forecastManagementEnabled && permissions.CanAccessForecasts && (
        <SideMenuItemStep
          completed={isStepComplete(OnboardingStep.ONBOARDING_STEP_FORECASTS)}
          label="Forecasts"
          isCurrent={forecastsMatch && forecastsMatch.isExact}
          onClick={handleClick.bind(this, 'Forecasts', routes.forecasts)} />
      )}

      {!tenantContext && permissions.CanAccessSandbox && (
        <SideMenuItemStep
          completed={isStepComplete(OnboardingStep.ONBOARDING_STEP_SANDBOX)}
          label="Sandboxes"
          isCurrent={sandboxMatch && sandboxMatch.isExact}
          onClick={handleClick.bind(this, 'Sandbox', routes.sandboxes)} />
      )}
    </SideMenuItemSteps>
  )
}

export default SideMenuSetup
