import React from 'react'
import { DealGridSettingsProvider } from '../../context/dealGridSettings'
import { ObjectDefinitionProvider } from '../../context/objectDefinition'
import DealGridSettingsMain from './dealGridSettingsMain'

const DealGridSettings = (props) => {
  return (
    <ObjectDefinitionProvider objectName="opportunity">
      <DealGridSettingsProvider>
        <DealGridSettingsMain />
      </DealGridSettingsProvider>
    </ObjectDefinitionProvider>
  )
}

export default DealGridSettings
