import { useMemo } from 'react'
import { useLoggedInUser } from '../context/loggedInUser'
import { useAuth } from '../context/auth'

export function useActor() {
  const { tenantId } = useAuth()
  const { loggedInUser } = useLoggedInUser()

  const actor = useMemo(() => {
    if (!tenantId) {
      return
    }
    if (!loggedInUser?.id) {
      return
    }
    return {
      tenantId,
      userId: loggedInUser.id
    }
  }, [loggedInUser, tenantId])

  return {
    actor
  }
}
