import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import appIcon from '../../assets/banner_icon.png'

function clearCacheAndReload() {
  if (window.caches) {
    window.caches.keys()
      .then((cacheNames) => {
        cacheNames.forEach((name) => window.caches.delete(name))
      })
      .then(() => {
        window.location.reload(true)
      })
  }
}

const tagName = process.env.TAG_NAME
const shortSHA = process.env.SHORT_SHA
const buildVersion = tagName || shortSHA

const VersionChecker = (props) => {
  const { key } = useLocation()

  const [showNewVersionUI, setShowNewVersionUI] = useState(false)

  useEffect(() => {
    setShowNewVersionUI(false)

    const abortController = new AbortController()

    fetch('/meta.json', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
      signal: abortController.signal,
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((r) => {
        const { body = {} } = r
        const { version } = body
        if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
          console.log(`build: ${buildVersion}`, `latest: ${version}`)
        }
        buildVersion && version && setShowNewVersionUI(buildVersion !== version)
      })
      .catch((err) => {
        process.env.NODE_ENV === 'local' && console.log('VersionChecker error', err)
      })

    return () => {
      abortController.abort()
    }
  }, [key])

  return (
    <>
      {showNewVersionUI
        && (
          <div className="slide-from-top flex items-center justify-center fixed mx-auto top-0 left-0 right-0 bg-color-ffffff shadow" style={{ width: 305, zIndex: 999999 }}>
            <div className="flex items-center justify-between py-4">
              <img src={appIcon} style={{ width: 37 }} className="mr-2" />
              <div className="font-normal text-size-15px">
                A new version is available.
                {' '}
                <a onClick={clearCacheAndReload} className="text-color-5951FF cursor-pointer underline">Reload</a>
              </div>
            </div>
          </div>
        )}
    </>
  )
}

export default VersionChecker
