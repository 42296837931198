import React, { useMemo } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { permissionNames } from '../../constants/permissionNames'
import { useRoutes } from '../../context/routes'
import { usePermissions } from '../../context/permissions'
import { useAuth } from '../../context/auth'
import { useTenantInfo } from '../../context/tenantInfo'
import { useNotification } from '../../hooks/useNotification'

const Default = (props) => {
  const { isFetching, checkPermission } = usePermissions()

  const { tenantContext } = useAuth()

  const {
    tenantIsPending,
    tenantIsInitializing } = useTenantInfo()

  const { routes } = useRoutes()

  const location = useLocation()

  const forceSetup = useMemo(() => {
    // See: [CSC-846] https://outreach-io.atlassian.net/browse/CSC-846?focusedCommentId=584610
    // Skip setup if impersonating
    if (tenantContext) {
      return false
    }
    // Otherwise, check tenant status and current route
    return tenantIsPending && location.pathname !== routes.setup
  }, [tenantIsPending, location, routes, tenantContext])

  const forceSync = useMemo(() => {
    // See: [CSC-846] https://outreach-io.atlassian.net/browse/CSC-846?focusedCommentId=584610
    // Skip setup if impersonating
    if (tenantContext) {
      return false
    }
    // Otherwise, check tenant status and current route
    return tenantIsInitializing && location.pathname !== routes.sync
  }, [tenantIsInitializing, location, routes, tenantContext])

  const { notifyError } = useNotification()

  if (isFetching) {
    return <></>
  } else if (forceSetup) {
    return <Redirect to={routes.setup} />
  } else if (forceSync) {
    return <Redirect to={routes.sync} />
  } else if (checkPermission(permissionNames.CanAccessSync)) {
    return <Redirect to={routes.dataSync} />
  } else if (checkPermission(permissionNames.CanAccessGroup)) {
    return <Redirect to={routes.teams} />
  } else if (checkPermission(permissionNames.CanAccessUser)) {
    return <Redirect to={routes.users} />
  } else if (checkPermission(permissionNames.CanAccessGoal)) {
    return <Redirect to={routes.goals} />
  } else if (checkPermission(permissionNames.CanAccessInternalAdmin) && checkPermission(permissionNames.CanAccessTenant)) {
    return <Redirect to={routes.tenants} />
  } else {
    notifyError('No permissions for a default route!')
    return <Redirect to={routes.unauthorized} />
  }
}

export default Default
