import React from 'react'
import { DataSyncHistoryProvider } from '../../context/dataSyncHistory'
import SyncHistoryMain from './syncHistoryMain'

const SyncHistory = (props) => {
  return (
    <DataSyncHistoryProvider>
      <SyncHistoryMain />
    </DataSyncHistoryProvider>
  )
}

export default SyncHistory
