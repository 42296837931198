import React, { useCallback } from 'react'
import ExclamationTriangle from '../icons/exclamationTriangle'

const ErrorWithReload = (props) => {
  const {
    header,
    reloadText = 'Reload Page',
    onReload
  } = props

  const onReloadClick = useCallback(() => {
    if (onReload) {
      onReload()
    } else {
      window.location.reload()
    }
  }, [onReload])

  return (
    <div className="flex items-center h-full">
      <div className="text-center">
        <div className="flex justify-center mt-6 mb-8">
          <ExclamationTriangle fill="#5951FF" transform="scale(2.5)" />
        </div>
        {header && <div className="text-size-30px font-bold text-color-91959f">{header}</div>}
        <div onClick={onReloadClick} className="text-size-14px text-color-5951FF cursor-pointer">{reloadText}</div>
      </div>
    </div>
  )
}

export default ErrorWithReload
