import React, { useMemo } from 'react'
import { useDataSyncStatus } from '../../context/dataSyncStatus'
import { DataSyncStatus as SyncStatus } from '../../grpc/enums'
import Error from '../icons/error'

const DataSyncStatus = (props) => {
  const { dataSyncStatus } = useDataSyncStatus()

  const status = useMemo(() => {
    switch (dataSyncStatus) {
      case SyncStatus.DATA_SYNC_STATUS_NOT_CONFIGURED:
        return {
          label: 'Not Configured',
          color: '#818e93',
        }
      case SyncStatus.DATA_SYNC_STATUS_PAUSED:
        return {
          label: 'Paused',
          color: '#818e93',
        }
      case SyncStatus.DATA_SYNC_STATUS_PENDING:
        return {
          label: 'Pending',
          color: '#818e93',
        }
      case SyncStatus.DATA_SYNC_STATUS_ACTIVE:
        return {
          label: 'Active',
          color: '#1dcf83',
        }
      case SyncStatus.DATA_SYNC_STATUS_ERROR:
        return {
          label: 'Error',
          color: '#fb6c6a',
          icon: <Error fill="#fb6c6a" />,
        }
      case SyncStatus.DATA_SYNC_STATUS_READY:
        return {
          label: 'Ready',
          color: '#818e93',
        }
      default:
        return {
          label: 'Unspecified',
          color: '#818e93',
        }
    }
  }, [dataSyncStatus])

  return (
    <div className="flex items-center ml-1">
      {status.icon
        && (
          <div className="ml-1">
            {status.icon}
          </div>
        )}
      <div
        className="text-size-24px font-weight-600 ml-2"
        style={{ color: status.color, transform: 'translateY(1px)' }}>
        {status.label}
      </div>
    </div>
  )
}

export default DataSyncStatus
