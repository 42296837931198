// source: src/services/mosaic/mosaic.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_canvas_canvas_pb = require('../../../src/common/canvas/canvas_pb.js');
goog.object.extend(proto, src_common_canvas_canvas_pb);
var src_common_canvas_canvas_tile_pb = require('../../../src/common/canvas/canvas_tile_pb.js');
goog.object.extend(proto, src_common_canvas_canvas_tile_pb);
var src_common_realtime_query_pb = require('../../../src/common/realtime/query_pb.js');
goog.object.extend(proto, src_common_realtime_query_pb);
goog.exportSymbol('proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest', null, global);
goog.exportSymbol('proto.loupe.services.mosaic.DeleteCanvasTileRequest', null, global);
goog.exportSymbol('proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest', null, global);
goog.exportSymbol('proto.loupe.services.mosaic.Empty', null, global);
goog.exportSymbol('proto.loupe.services.mosaic.GetCanvasRequest', null, global);
goog.exportSymbol('proto.loupe.services.mosaic.GetCanvasTileRequest', null, global);
goog.exportSymbol('proto.loupe.services.mosaic.UpsertCanvasRequest', null, global);
goog.exportSymbol('proto.loupe.services.mosaic.UpsertCanvasTileRequest', null, global);
goog.exportSymbol('proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mosaic.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mosaic.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mosaic.Empty.displayName = 'proto.loupe.services.mosaic.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mosaic.GetCanvasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mosaic.GetCanvasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mosaic.GetCanvasRequest.displayName = 'proto.loupe.services.mosaic.GetCanvasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mosaic.GetCanvasTileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mosaic.GetCanvasTileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mosaic.GetCanvasTileRequest.displayName = 'proto.loupe.services.mosaic.GetCanvasTileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mosaic.UpsertCanvasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mosaic.UpsertCanvasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mosaic.UpsertCanvasRequest.displayName = 'proto.loupe.services.mosaic.UpsertCanvasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mosaic.UpsertCanvasTileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mosaic.UpsertCanvasTileRequest.displayName = 'proto.loupe.services.mosaic.UpsertCanvasTileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mosaic.DeleteCanvasTileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mosaic.DeleteCanvasTileRequest.displayName = 'proto.loupe.services.mosaic.DeleteCanvasTileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.displayName = 'proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.displayName = 'proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.displayName = 'proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mosaic.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mosaic.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mosaic.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mosaic.Empty}
 */
proto.loupe.services.mosaic.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mosaic.Empty;
  return proto.loupe.services.mosaic.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mosaic.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mosaic.Empty}
 */
proto.loupe.services.mosaic.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mosaic.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mosaic.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mosaic.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mosaic.GetCanvasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mosaic.GetCanvasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mosaic.GetCanvasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.GetCanvasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    canvasKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mosaic.GetCanvasRequest}
 */
proto.loupe.services.mosaic.GetCanvasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mosaic.GetCanvasRequest;
  return proto.loupe.services.mosaic.GetCanvasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mosaic.GetCanvasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mosaic.GetCanvasRequest}
 */
proto.loupe.services.mosaic.GetCanvasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanvasKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mosaic.GetCanvasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mosaic.GetCanvasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mosaic.GetCanvasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.GetCanvasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanvasKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string canvas_key = 1;
 * @return {string}
 */
proto.loupe.services.mosaic.GetCanvasRequest.prototype.getCanvasKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.GetCanvasRequest} returns this
 */
proto.loupe.services.mosaic.GetCanvasRequest.prototype.setCanvasKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.mosaic.GetCanvasRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.GetCanvasRequest} returns this
 */
proto.loupe.services.mosaic.GetCanvasRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.loupe.services.mosaic.GetCanvasRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.GetCanvasRequest} returns this
 */
proto.loupe.services.mosaic.GetCanvasRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.loupe.services.mosaic.GetCanvasRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.GetCanvasRequest} returns this
 */
proto.loupe.services.mosaic.GetCanvasRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mosaic.GetCanvasTileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mosaic.GetCanvasTileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    canvasKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tileKey: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mosaic.GetCanvasTileRequest}
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mosaic.GetCanvasTileRequest;
  return proto.loupe.services.mosaic.GetCanvasTileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mosaic.GetCanvasTileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mosaic.GetCanvasTileRequest}
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanvasKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTileKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mosaic.GetCanvasTileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mosaic.GetCanvasTileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanvasKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTileKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string canvas_key = 1;
 * @return {string}
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.prototype.getCanvasKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.GetCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.prototype.setCanvasKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.GetCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.GetCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.GetCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tile_key = 5;
 * @return {string}
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.prototype.getTileKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.GetCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.GetCanvasTileRequest.prototype.setTileKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mosaic.UpsertCanvasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mosaic.UpsertCanvasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    canvas: (f = msg.getCanvas()) && src_common_canvas_canvas_pb.Canvas.toObject(includeInstance, f),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mosaic.UpsertCanvasRequest}
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mosaic.UpsertCanvasRequest;
  return proto.loupe.services.mosaic.UpsertCanvasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mosaic.UpsertCanvasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mosaic.UpsertCanvasRequest}
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_canvas_canvas_pb.Canvas;
      reader.readMessage(value,src_common_canvas_canvas_pb.Canvas.deserializeBinaryFromReader);
      msg.setCanvas(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mosaic.UpsertCanvasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mosaic.UpsertCanvasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanvas();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_canvas_canvas_pb.Canvas.serializeBinaryToWriter
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional loupe.common.canvas.Canvas canvas = 1;
 * @return {?proto.loupe.common.canvas.Canvas}
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.prototype.getCanvas = function() {
  return /** @type{?proto.loupe.common.canvas.Canvas} */ (
    jspb.Message.getWrapperField(this, src_common_canvas_canvas_pb.Canvas, 1));
};


/**
 * @param {?proto.loupe.common.canvas.Canvas|undefined} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasRequest} returns this
*/
proto.loupe.services.mosaic.UpsertCanvasRequest.prototype.setCanvas = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mosaic.UpsertCanvasRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.prototype.clearCanvas = function() {
  return this.setCanvas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.prototype.hasCanvas = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mosaic.UpsertCanvasTileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    canvasKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    canvasTile: (f = msg.getCanvasTile()) && src_common_canvas_canvas_tile_pb.CanvasTile.toObject(includeInstance, f),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileRequest}
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mosaic.UpsertCanvasTileRequest;
  return proto.loupe.services.mosaic.UpsertCanvasTileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileRequest}
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanvasKey(value);
      break;
    case 2:
      var value = new src_common_canvas_canvas_tile_pb.CanvasTile;
      reader.readMessage(value,src_common_canvas_canvas_tile_pb.CanvasTile.deserializeBinaryFromReader);
      msg.setCanvasTile(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mosaic.UpsertCanvasTileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanvasKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCanvasTile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_canvas_canvas_tile_pb.CanvasTile.serializeBinaryToWriter
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string canvas_key = 1;
 * @return {string}
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.getCanvasKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.setCanvasKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.canvas.CanvasTile canvas_tile = 2;
 * @return {?proto.loupe.common.canvas.CanvasTile}
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.getCanvasTile = function() {
  return /** @type{?proto.loupe.common.canvas.CanvasTile} */ (
    jspb.Message.getWrapperField(this, src_common_canvas_canvas_tile_pb.CanvasTile, 2));
};


/**
 * @param {?proto.loupe.common.canvas.CanvasTile|undefined} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} returns this
*/
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.setCanvasTile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.clearCanvasTile = function() {
  return this.setCanvasTile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.hasCanvasTile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string group_id = 4;
 * @return {string}
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_id = 5;
 * @return {string}
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasTileRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mosaic.DeleteCanvasTileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mosaic.DeleteCanvasTileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    canvasKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tileKey: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileRequest}
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mosaic.DeleteCanvasTileRequest;
  return proto.loupe.services.mosaic.DeleteCanvasTileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mosaic.DeleteCanvasTileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileRequest}
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanvasKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTileKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mosaic.DeleteCanvasTileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mosaic.DeleteCanvasTileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanvasKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTileKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string canvas_key = 1;
 * @return {string}
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.prototype.getCanvasKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.prototype.setCanvasKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tile_key = 5;
 * @return {string}
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.prototype.getTileKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileRequest} returns this
 */
proto.loupe.services.mosaic.DeleteCanvasTileRequest.prototype.setTileKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    canvasKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tileKey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userQueryParametersId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest}
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest;
  return proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest}
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanvasKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTileKey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserQueryParametersId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanvasKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTileKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserQueryParametersId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string canvas_key = 1;
 * @return {string}
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.getCanvasKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest} returns this
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.setCanvasKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest} returns this
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest} returns this
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest} returns this
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tile_key = 5;
 * @return {string}
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.getTileKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest} returns this
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.setTileKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string user_query_parameters_id = 6;
 * @return {string}
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.getUserQueryParametersId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest} returns this
 */
proto.loupe.services.mosaic.DeleteCanvasTileUserQueryParametersRequest.prototype.setUserQueryParametersId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    canvasKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tileKey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    parameters: (f = msg.getParameters()) && src_common_realtime_query_pb.UserQueryParameters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest}
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest;
  return proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest}
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanvasKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTileKey(value);
      break;
    case 6:
      var value = new src_common_realtime_query_pb.UserQueryParameters;
      reader.readMessage(value,src_common_realtime_query_pb.UserQueryParameters.deserializeBinaryFromReader);
      msg.setParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanvasKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTileKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getParameters();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_realtime_query_pb.UserQueryParameters.serializeBinaryToWriter
    );
  }
};


/**
 * optional string canvas_key = 1;
 * @return {string}
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.getCanvasKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.setCanvasKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tile_key = 5;
 * @return {string}
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.getTileKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.setTileKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional loupe.common.realtime.UserQueryParameters parameters = 6;
 * @return {?proto.loupe.common.realtime.UserQueryParameters}
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.getParameters = function() {
  return /** @type{?proto.loupe.common.realtime.UserQueryParameters} */ (
    jspb.Message.getWrapperField(this, src_common_realtime_query_pb.UserQueryParameters, 6));
};


/**
 * @param {?proto.loupe.common.realtime.UserQueryParameters|undefined} value
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} returns this
*/
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.setParameters = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest} returns this
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.clearParameters = function() {
  return this.setParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.mosaic.UpsertCanvasTileUserQueryParametersRequest.prototype.hasParameters = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    canvasKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tileKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userQueryParametersId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest}
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest;
  return proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest}
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanvasKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTileKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserQueryParametersId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanvasKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTileKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserQueryParametersId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string canvas_key = 1;
 * @return {string}
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.prototype.getCanvasKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest} returns this
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.prototype.setCanvasKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tile_key = 2;
 * @return {string}
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.prototype.getTileKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest} returns this
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.prototype.setTileKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest} returns this
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_query_parameters_id = 4;
 * @return {string}
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.prototype.getUserQueryParametersId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest} returns this
 */
proto.loupe.services.mosaic.AdjustOrgCanvasTileLayoutRequest.prototype.setUserQueryParametersId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.loupe.services.mosaic);
