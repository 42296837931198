import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '../common/modal'
import ModalHeader from '../common/modalHeader'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import { useTextField } from '../../hooks/useTextField'
import Button from '../common/button'
import AddEditFieldForm from './addEditFieldForm'
import { useCanonicalObject } from '../../context/canonicalObject'
import ManageFieldsList from './manageFieldsList'
import { useGrpcCallback } from '../../grpc'
import { useNotification } from '../../hooks/useNotification'
import { toUpsertCanonicalObjectFieldRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import { defaultFieldType, defaultFormat } from './constants'
import { useCanonicalObjectMapping } from '../../context/canonicalObjectMapping'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const ManageFieldsModal = (props) => {
  const {
    modal,
    ...rest
  } = props

  const { open, handleClose } = modal

  const { checkPermission } = usePermissions()

  const { tenantId } = useAuth()

  const { notifyError, notifySuccess } = useNotification()

  const { canonicalObject, invalidate: invalidateCanonicalObject } = useCanonicalObject()
  const { invalidate: invalidateCanonicalObjectMapping } = useCanonicalObjectMapping()

  const search = useTextField()
  const fieldName = useTextField()
  const description = useTextField()

  const [editData, setEditData] = useState(undefined)
  const [showForm, setShowForm] = useState(false)
  const [selectedFieldType, setSelectedFieldType] = useState(defaultFieldType.value)
  const [selectedFormat, setSelectedFormat] = useState('')
  const [invalidFields, setInvalidFields] = useState([])
  const [isSaving, setIsSaving] = useState(false)

  const setShowFormInternal = useCallback((show) => {
    setShowForm(show)
    if (!show) {
      setInvalidFields([])
    }
  }, [])

  useEffect(() => {
    if (open) {
      setEditData(undefined)
      setShowForm(false)
      setInvalidFields([])
      setIsSaving(false)
    }
  }, [open])

  useEffect(() => {
    if (showForm && !editData) {
      search.reset()
      fieldName.reset()
      description.reset()
      setSelectedFieldType(defaultFieldType.value)
    }
  }, [showForm, editData])

  const modalTitle = useMemo(() => {
    if (showForm) {
      if (editData) {
        return 'Edit Field'
      } else {
        return 'Add Extension Field'
      }
    } else {
      return 'Manage Fields'
    }
  }, [editData, showForm])

  const handleCloseInternal = useCallback(() => {
    handleClose()
  }, [handleClose])

  const upsertCanonicalObjectField = useGrpcCallback({
    onError: () => {
      notifyError('Error saving field!')
      setIsSaving(false)
    },
    onSuccess: () => {
      notifySuccess('Field has been saved.')
      invalidateCanonicalObject()
      invalidateCanonicalObjectMapping()
      setEditData(undefined)
      setShowForm(false)
      setInvalidFields([])
      setIsSaving(false)
    },
    grpcMethod: 'upsertCanonicalObjectField',
    debug: false,
  }, [])

  const upsertField = useCallback(() => {
    const invalid = []
    if (!fieldName.value.trim()) {
      invalid.push('fieldName')
    }
    if (selectedFieldType === defaultFieldType.value) {
      invalid.push('type')
    }
    setInvalidFields(invalid)

    if (invalid.length === 0) {
      const request = toUpsertCanonicalObjectFieldRequest({
        field: {
          ...editData !== undefined && { ...editData },
          ...!editData && { extensionField: true },
          ...selectedFormat && { format: selectedFormat === defaultFormat.value ? '' : selectedFormat },
          label: fieldName.value,
          fieldType: selectedFieldType,
          description: description.value,
          tenantId,
          canonicalObjectId: canonicalObject.id,
        }
      })
      upsertCanonicalObjectField(request)
    }
  }, [tenantId, editData, fieldName.value, description.value, selectedFieldType, selectedFormat, canonicalObject])

  const onClick = useCallback(() => {
    if (showForm) {
      upsertField()
    } else {
      handleCloseInternal()
    }
  }, [showForm, upsertField, handleCloseInternal])

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.code === 'Enter') {
        e.preventDefault()
        if (showForm) {
          upsertField()
        }
      }
    }
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [showForm, upsertField])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="sm"
      open={open}
      {...rest}>

      <ModalHeader
        title={modalTitle}
        onClose={handleCloseInternal} />

      <ModalBody>

        {showForm
          ? (
            <div className="w-full h-full px-10 pt-10">
              <AddEditFieldForm
                invalidFields={invalidFields}
                fieldName={fieldName}
                description={description}
                selectedFieldType={selectedFieldType}
                setSelectedFieldType={setSelectedFieldType}
                selectedFormat={selectedFormat}
                setSelectedFormat={setSelectedFormat}
                editData={editData}
                setShowForm={setShowFormInternal}
                upsertField={upsertField} />
            </div>
          )
          : (
            <ManageFieldsList
              setEditData={setEditData}
              setShowForm={setShowFormInternal} />
          )}

      </ModalBody>

      <ModalFooter>
        {checkPermission(permissionNames.CanUpdateSync) && (
          <Button
            text={showForm ? 'Save' : 'Done'}
            onClick={onClick}
            disabled={isSaving} />
        )}
      </ModalFooter>

    </Modal>
  )
}

export default ManageFieldsModal
