import React, { useRef, useState, useEffect, useCallback } from 'react'
import Modal from '../common/modal'
import ModalError from '../common/modalError'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import Button from '../common/button'
import { useModal } from '../../hooks/useModal'
import commitLogo from '../../assets/commit-logo.png'
import { useGrpcCallback } from '../../grpc'
import { useNotification } from '../../hooks/useNotification'
import { useTenantInfo } from '../../context/tenantInfo'
import { useAuth } from '../../context/auth'
import { toGetTenantLiftoffStatusRequest } from '../../grpc/converters'
import { ExportPart, InstanceSetupStatus } from '../../grpc/enums'
import { LinearProgress } from '@material-ui/core'
import { useRoutes } from '../../context/routes'

const partsList = [
  { label: 'Ingest', value: ExportPart.EXPORT_PART_INGEST },
  { label: 'Normalize', value: ExportPart.EXPORT_PART_NORMALIZE },
  { label: 'Transform', value: ExportPart.EXPORT_PART_TRANSFORM },
  { label: 'Index', value: ExportPart.EXPORT_PART_INDEX },
  { label: 'Signal', value: ExportPart.EXPORT_PART_SIGNAL },
  { label: 'Metric', value: ExportPart.EXPORT_PART_METRIC },
  { label: 'Notify', value: ExportPart.EXPORT_PART_NOTIFY },
  { label: 'Cache', value: ExportPart.EXPORT_PART_CACHE },
]

const SyncMain = () => {
  const { notifyError, notifySuccess } = useNotification()
  const { crmName, isSandbox } = useTenantInfo()
  const { tenantId } = useAuth()
  const { routes } = useRoutes()
  const authorizeModal = useModal()

  const longPollInterval = useRef()

  const [showError, setShowError] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [liftoffStatus, setLiftoffStatus] = useState(null)
  const [task, setTask] = useState('')
  const [subTask, setSubTask] = useState('')
  const [completed, setCompleted] = useState(0)

  const { handleClose } = authorizeModal

  const handleCloseInternal = useCallback(() => {
    setShowError(false)
    authorizeModal.setData(undefined)
    handleClose()
  }, [authorizeModal])

  const onReturn = useCallback(() => {
    if (isComplete) {
      window.location.href = routes.default
    } else {
      window.location.href = '/'
    }
  }, [routes, isComplete])

  const getStatus = useGrpcCallback({
    onError: () => {
      notifyError('Error getting sync status!')
    },
    onSuccess: ({ status }) => {
      setLiftoffStatus(status)
    },
    grpcMethod: 'getTenantLiftoffStatus',
    debug: false,
  }, [])

  const onGetStatus = useCallback(() => {
    const request = toGetTenantLiftoffStatusRequest({
      tenantId
    })
    getStatus(request)
  }, [getStatus, tenantId])

  useEffect(() => {
    if (isComplete) {
      clearInterval(longPollInterval.current)
      return
    }

    if (!longPollInterval.current) {
      onGetStatus()
      longPollInterval.current = window.setInterval(() => onGetStatus(), 5000)
    }
  }, [longPollInterval, isComplete])

  useEffect(() => {
    if (!liftoffStatus) {
      return
    }
    const { progress = {}, status } = liftoffStatus
    const { complete, total, description, name, subProgress = {} } = progress
    const { name: subTaskName = '' } = subProgress

    if (status === InstanceSetupStatus.INSTANCE_SETUP_STATUS_READY
     || status === InstanceSetupStatus.INSTANCE_SETUP_STATUS_ADMIN_READY) {
      setIsComplete(true)
      setTask(name)
      setSubTask(description)
      setCompleted(100)
      return
    }

    const percentComplete = ((parseInt(complete) + 1) / parseInt(total)) * 100

    setTask(`${name}: ${description}`)
    setSubTask(subTaskName)
    setCompleted(percentComplete)
  }, [liftoffStatus])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="sm"
      open={true}>

      <ModalBody>
        <div className="flex flex-1 flex-col items-start content-between px-24 py-12">
          <img src={commitLogo} style={{ height: 50 }} className="mb-16 self-center" />
          {showError && <ModalError text={showError} />}
          <h1 className="mb-8">{`Connecting to ${crmName}`}</h1>
          <h4 className="font-weight-500 text-size-20px">This could take awhile.</h4>
          <p className="text-color-51636a">You are welcome to close out of this screen. After your CRM is connected, access the admin sandbox to complete your data sync.</p>

        </div>
        <div className="flex flex-col px-24 pb-12">
          <div className="font-weight-500">{task}</div>
          <div className="mb-4">{subTask}</div>
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={completed} />
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          onClick={onReturn}
          className="mb-12"
          size="sm"
          disabled={!isSandbox && !isComplete}
          text={(isComplete) ? 'Continue to Admin' : 'Return to Admin'} />

      </ModalFooter>

    </Modal>
  )
}

export default SyncMain
