import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const SideMenuItemStepIcon = ({
  borderBottom,
  borderTop,
  children,
  disabled,
  ...props
}) => (

  <div className={
    classNames(
      'flex items-center justify-center font-weight-500 w-7 h-7 rounded-full mr-4 pt-025 relative text-color-ffffff',
      {
        'bg-color-5951FF': !disabled
      },
      { 'bg-color-c9ced0': disabled }
    )
  }
    {...props}>
    {borderTop && <span className={classNames('w-3px h-5 absolute bottom-100', { 'bg-color-5951FF': !disabled, 'bg-color-c9ced0': disabled })} />}
    {children}
    {borderBottom && <span className={classNames('w-3px h-5 absolute top-100', { 'bg-color-5951FF': !disabled, 'bg-color-c9ced0': disabled })} />}
  </div>
)

SideMenuItemStepIcon.defaultProps = {
  borderBottom: true,
  borderTop: true,
  children: null,
  disabled: true
}

SideMenuItemStepIcon.propTypes = {
  borderBottom: PropTypes.bool,
  borderTop: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool
}

export default SideMenuItemStepIcon
