import { find, noop } from 'lodash'
import { useDragAndDrop } from '../dragAndDrop/hooks'
import classNames from 'classnames'
import Tooltip from '../common/tooltip'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DragHandle } from '@getoutreach/react-icons'
import { useForecastingConfigColumn } from '../../context/forecastingConfigColumn'

const ForecastConfigColumn = (props) => {
  const {
    children,
    index,
    id,
    isNew,
    name,
    label,
    onMove,
    onDrop,
  } = props

  const { updateConfigColumn } = useForecastingConfigColumn()

  const [isEditing, setIsEditing] = useState(isNew)
  const [titleInternal, setTitleInternal] = useState(name)
  const [titleIsTruncated, setTitleIsTruncated] = useState(false)

  const { ref, handlerId, isDragging, dragLayer } = useDragAndDrop({
    key: id,
    index,
    type: 'ForecastConfigColumn',
    onMove,
    onDrop,
    horizontal: false,
  })

  const onTitleRef = useCallback((ref) => {
    if (!ref) {
      return
    }
    setTitleIsTruncated(ref.offsetWidth < ref.scrollWidth)
  }, [])

  const onChangeName = useCallback(({ target }) => {
    const { value } = target
    setTitleInternal(value)
  }, [])

  const onBlurName = useCallback((e) => {
    if (e.cancelled) {
      return
    }

    if (titleInternal === '') {
      setTitleInternal(name)
      setIsEditing(false)
      return
    }

    if (titleInternal === name) {
      setIsEditing(false)
      return
    }
    setIsEditing(false)
    updateConfigColumn({ name: titleInternal }, { saveAfterUpdate: true })
  }, [updateConfigColumn, titleInternal, name])

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      setTitleInternal(name)
      onBlurName({ cancelled: true })
    } else if (e.key === 'Enter') {
      onBlurName({ cancelled: false })
    }
  }, [onBlurName, name])

  useEffect(() => {
    setTitleInternal(name)
  }, [name])

  const getTitle = useCallback(() => {
    const title = (
      <div ref={onTitleRef} className="truncate" style={{ height: '20px' }} onClick={() => setIsEditing(true)}>
        {titleInternal}
      </div>
    )

    if (titleIsTruncated) {
      return (
        <Tooltip arrow placement="top" title={titleInternal}>
          <span className="ml-1 first:ml-0">
            {title}
          </span>
        </Tooltip>
      )
    }
    return title
  }, [titleIsTruncated, titleInternal, onTitleRef])

  return (
    <div ref={ref} data-handler-id={handlerId}>
      <div className={classNames(
        'px-5 py-3 mb-2 shadow-md border border-color-2e5bff-08 rounded group',
        { 'bg-color-ffffff': !dragLayer.isDragging },
        { 'border-color-eaeaea bg-color-eaeaea': isDragging },
        { 'border-color-d8d8d8': !isDragging }
      )}
      >
        <div className="flex items-center justify-between">
          {isDragging ? '\u00A0' : (
            <div className={
              classNames(
                'flex flex-col leading-none overflow-hidden',
                { 'flex-1': isEditing }
              )
            }>
              {isEditing
                ? (
                  <input
                    style={{ height: '20px' }}
                    className="outline-none"
                    type="text"
                    value={titleInternal}
                    onChange={onChangeName}
                    onBlur={onBlurName}
                    onKeyDown={onKeyDown}
                    autoFocus={isEditing}
                    placeholder={name} />
                )
                : getTitle()}
              <span className={classNames(
                'opacity-100 transform font-weight-400 text-color-51636a text-size-14px',
                { 'translate-y-4 opacity-0': isEditing }
              )}>
                {label}
              </span>
            </div>
          )}
          {!dragLayer.isDragging && !isEditing && (
            <div className="flex items-center">
              <div className="mr-2">{children}</div>
              <DragHandle className="cursor-pointer" htmlColor="#C9CED0" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export function dragRenderer({ data = {}, isDragging, item, itemType, clientOffset }) {
  const { forecastCategories = [] } = data
  const forecastCategory = find(forecastCategories, (d) => d.id === item.id)

  if (itemType === 'ForecastConfigColumn' && forecastCategory && clientOffset) {
    const { name } = forecastCategory
    const { x, y } = clientOffset
    const { size } = item || {}
    const { width = 0, height = 0 } = size
    return (
      <div style={{ width, height, transform: `translate(${x}px, ${y}px)` }}>
        <div
          className="group bg-color-ffffff flex items-center justify-between mt-3 px-5 py-2 border border-color-d8d8d8 rounded hover:bg-color-51636a-05"
          style={{ height: 58 }}>
          <div className="text-size-16px text-color-09242f font-weight-500 leading-tight">
            <div className="truncate">{name}</div>
          </div>
          <div className="cursor-pointer">
            <DragHandle htmlColor="#c9ced0" />
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default ForecastConfigColumn
