import React from 'react'
import { SnackbarProvider } from 'notistack'
import { makeStyles } from '@material-ui/core'
import CloseButton from './closeButton'

const useStyles = makeStyles((theme) => ({
  variantSuccess: { backgroundColor: '#1dcf83 !important' },
}))

const SnackBar = ({ children, ...rest }) => {
  const classes = useStyles()
  return (
    <SnackbarProvider
      classes={classes}
      action={(snackbarId) => (
        <CloseButton snackbarId={snackbarId} />
      )}
      {...rest}>
      {children}
    </SnackbarProvider>
  )
}

export default SnackBar
