import { orderBy } from 'lodash'
import React, { useMemo } from 'react'
import { useSystemRoles } from '../../context/systemRoles'
import RoleListItem from './roleListItem'

const RolesList = (props) => {
  const { systemRoles } = useSystemRoles()

  const orderedRoles = useMemo(() => {
    return orderBy(systemRoles, [
      (r) => r.isCustom,
      (r) => r?.name?.toLowerCase() ?? ''
    ])
  }, [systemRoles])

  return (
    <>
      <div className="flex-table">
        <div className="thead">
          <div className="tr">
            <div className="td-menu" />
            <div className="td w-1/5">Name</div>
            <div className="td w-4/5">Description</div>
          </div>
        </div>
        <div className="tbody">
          {orderedRoles.map((systemRole, index) => (
            <RoleListItem
              key={`RoleListItem-${systemRole.key}-${index}`}
              systemRole={systemRole}
              {...index === 0 && { id: 'joyride_roles_rolesListItem' }}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default RolesList
