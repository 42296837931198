import React from 'react'
import { OutreachSettingsProvider } from '../../context/outreachSettings'
import OutreachSettingsMain from './outreachSettingsMain'

const OutreachSettings = (props) => {
  return (
    <OutreachSettingsProvider>
      <OutreachSettingsMain />
    </OutreachSettingsProvider>
  )
}

export default OutreachSettings
