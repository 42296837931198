import { getAccessToken, getTokenSilently, getIdTokenClaims } from '../lib/auth0'
import { useDebug } from '../context/debug'
import { useAuth } from '../context/auth'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ApiServiceClient, getApiServiceClientWithPrefix } from './clients'
import { captureException } from '../lib/sentry'
import { cloneDeep, debounce, flatMapDeep, flatMapDepth, has } from 'lodash'
import { useRoutes } from '../context/routes'
import { grpcCodes } from './grpcCodes'
import { v4 as guid } from 'uuid'

let metadata = {}

function getHeaders(headers = {}) {
  const accessToken = getAccessToken()
  return {
    ...headers,
    ...accessToken && { Authorization: `Bearer ${accessToken}` },
    'service-route': 'admin',
    ...has(metadata, 'orchard.group.form_token') && { 'orchard.group.form_token': metadata['orchard.group.form_token'] },
  }
}

export function grpcInvoke({ request, onError, onSuccess, onFetch, grpcMethod, grpcMethodName, data = {}, debug, routes, headers, grpcPrefix, useApiRegion }) {
  const methodName = grpcMethodName || grpcMethod

  debug && console.log(`${methodName} request`, request.toObject(), getHeaders(headers))

  let serviceClient = ApiServiceClient(useApiRegion)

  if (grpcPrefix) {
    serviceClient = getApiServiceClientWithPrefix(grpcPrefix)
  }

  const serviceCall = serviceClient[grpcMethod].bind(serviceClient)

  const callback = (err, response, isRetry) => {
    if (err) {
      if (!isRetry && err.code === grpcCodes.UNAUTHENTICATED) {
        // eslint-disable-next-line no-use-before-define
        retryServiceCall()
      } else if (!isRetry && err.code === grpcCodes.PERMISSION_DENIED) {
        // eslint-disable-next-line no-use-before-define
        retryServiceCall()
      } else {
        if (err.code !== grpcCodes.NOT_FOUND) {
          captureException(err, { request: request ? request.toObject() : undefined }, methodName)
        }
        debug && console.log(`${methodName} error`, err, { ...getHeaders(headers), Authorization: '' })
        onError && onError(err)
      }
    } else {
      const obj = response.toObject()
      debug && console.log(`${methodName} success`, cloneDeep(obj))
      onSuccess && onSuccess(obj, data)
    }
  }

  const retryServiceCall = debounce(() => { // debounce the retry because grpc-web error handlers fire twice :(
    debug && console.log(`UNAUTHENTICATED: retry ${methodName}`)
    getTokenSilently({
      options: {
        ignoreCache: true
      },
      onSuccess: ({ accessToken }) => {
        getIdTokenClaims({
          onSuccess: () => {
            debug && console.log(`${methodName} retry-request`, request.toObject(), { ...getHeaders(headers), Authorization: `Bearer ${accessToken}` })
            serviceCall(request, { ...getHeaders(headers), Authorization: `Bearer ${accessToken}` }, (err, response) => callback(err, response, true))
          }
        })
      },
      onError: (err) => {
        captureException(err, undefined, 'retryServiceCall -> getTokenSilently')
        // if we failed to get a new token then logout
        window.location = `${window.location.origin}${routes.logout}`
      },
    })
  }, 100)

  try {
    onFetch && onFetch()
    return serviceCall(request, getHeaders(headers), callback)
  } catch (err) {
    captureException(err, { request: request ? request.toObject() : undefined }, methodName)
  }
}

export function useGrpcEffect({ headers = {}, request, onError, onSuccess, onFetch, grpcMethod, grpcMethodName, debug, useApiRegion = '' }, dependencyList = []) {
  const { debug: isDebug, grpcPrefix } = useDebug()
  const { isAuthenticated } = useAuth()
  const { routes, routeTenantId } = useRoutes()
  useEffect(() => {
    if (!isAuthenticated) {
      return
    }
    const h = (routeTenantId) ? { ...headers, tenantctxid: routeTenantId } : headers
    const call = grpcInvoke({ request, onError, onSuccess, onFetch, grpcMethod, grpcMethodName, debug: debug || isDebug, routes, headers: h, grpcPrefix, useApiRegion })
    call.on('status', (status) => { // callback for trailing metadata
      if (status.metadata) {
        metadata = {
          ...metadata,
          ...status.metadata,
        }
      }
    })
    return () => {
      call?.cancel?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, ...dependencyList])
}

export function useGrpcCallback({ headers = {}, onError, onSuccess, onFetch, grpcMethod, grpcMethodName, debug, useApiRegion = '' }, dependencyList = []) {
  const { debug: isDebug, grpcPrefix } = useDebug()
  const { isAuthenticated } = useAuth()
  const { routes, routeTenantId } = useRoutes()
  return useCallback((request, data) => {
    if (!isAuthenticated) {
      return
    }
    const h = (routeTenantId) ? { ...headers, tenantctxid: routeTenantId } : headers
    const call = grpcInvoke({ request, onError, onSuccess, onFetch, grpcMethod, grpcMethodName, data, debug: debug || isDebug, routes, headers: h, grpcPrefix, useApiRegion })
    call.on('status', (status) => { // callback for trailing metadata
      if (status.metadata) {
        metadata = {
          ...metadata,
          ...status.metadata,
        }
      }
    })
    return call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, ...dependencyList])
}

/**
 * Grpc Status State *
 * @prop {number} READY 0 - initialized but not started
 * @prop {number} FETCHING 1 - requests started
 * @prop {number} ERROR 2 - Request encountered an error ndcj sxs
 * @prop {number} CANCELLED 3 - Abort method called
 * @prop {number} SUCCESS 4 - Request completed with success
} */

export const GrpcStatus = {
  UNINITIALIZED: -1,
  READY: 0,
  FETCHING: 1,
  ERROR: 2,
  CANCELLED: 3,
  SUCCESS: 4,
}

export const getStatusForEventName = (name) => {
  switch (name) {
    case 'onSuccess':
      return GrpcStatus.SUCCESS
    case 'onFetching':
      return GrpcStatus.FETCHING
    case 'onError':
      return GrpcStatus.ERROR
    default:
      return GrpcStatus.READY
  }
}

/**
 * @param {Object} options
 * @param {boolean} options.attemptAll - Should continue past any failed requests
 * @param {boolean} options.debug - Set for all requests
 * @param {SuccessAllCallback} options.onSuccess - ALL requests completed, reports a status of all
 * @param {ErrorAllCallback} options.onError - Failed, after either the first or at the end (see "attemptAll"), reports a on status of all
 * @param {AbortAllCallback} options.onAbort - Called after canceling the remaining requests and, yep, reports status
 * @param {StatusChangeCallback} options.onStatusChange - Called when status changes returns GrpcStatus type
 * @param {*} options.dependencyList - Standard hook args
 * @returns {@callback[]} - An array full of callbacks
 * @returns {requestAllCallback} return[0]
 * @returns {abortRequestAllCallback} return[1]
*/

/**
 * Request All Callback, takes an array full of requests
 * @callback requestAllCallback
 * @param {requests[]} requests - Multi-dimensional array of request objects
 * @param {Object} request onError, onSuccess, onFetch, grpcMethod, grpcMethodName, data
 * @returns {GrpcServiceCalls[]} - Service call return object in shape of @requests
 */

export function useGrpcAll(
  {
    attemptAll = false,
    debug = false,
    onAbort,
    onError,
    onStatusChange,
    onSuccess,
    useApiRegion = ''
  },
  dependencyList = []
) {
  const { debug: isDebug, grpcPrefix } = useDebug()
  const { isAuthenticated } = useAuth()
  const { routes, routeTenantId } = useRoutes()
  const [error, setError] = useState()
  const [status, setStatus] = useState(GrpcStatus.READY)
  const cancelled = useRef(false)
  const completed = useRef(0)
  const pointer = useRef(0)
  const requestCollection = useRef([])
  const requestData = useRef({})
  const total = useRef(0)

  const headers = (routeTenantId) ? { tenantctxid: routeTenantId } : {}

  const reset = useCallback(() => {
    total.current = 0
    completed.current = 0
    pointer.current = 0
    setStatus(GrpcStatus.READY)
  }, [])

  const tidyResults = useCallback((results) => {
    return results.map((result) => {
      if (Array.isArray(result)) {
        return tidyResults(result)
      }
      const { responsePayload = [], requestObject } = result
      const { grpcMethod } = requestObject
      const [responseObject, data] = responsePayload
      return { grpcMethod, responseObject, data }
    })
  }, [])

  useEffect(() => {
    onStatusChange?.(status)

    if (status < 1) {
      return
    }

    const resultObject = [tidyResults(requestCollection.current), requestData.current]

    switch (status) {
      case GrpcStatus.SUCCESS:
        onSuccess?.(...resultObject)
        break
      case GrpcStatus.ERROR:
        onError?.(...resultObject)
        break
      case GrpcStatus.CANCELLED:
        onAbort?.(...resultObject)
        break
      default:
    }

    reset()
  }, [status, onSuccess, onError, onAbort, onStatusChange, error, reset, tidyResults])

  const invokeCalls = useCallback((requestObject = {}) => {
    if (cancelled.current
      || pointer.current === requestCollection.current.length
      || completed.current < total.current) {
      return
    }

    const collection = [].concat(requestCollection.current[pointer.current])
    pointer.current += 1

    if (!collection.length) {
      invokeCalls()
      return
    }

    total.current += collection.length
    collection.forEach(({ callInvoker }) => callInvoker(requestObject))
  }, [])

  const checkStatus = useCallback(() => {
    const pendingCalls = completed.current < total.current

    if (cancelled.current && !pendingCalls) {
      setStatus(GrpcStatus.CANCELLED)
      return
    }

    if (!pendingCalls) {
      setStatus(GrpcStatus.SUCCESS)
    }
  }, [])

  const findInCollection = useCallback((collection, _id) => {
    const arrs = []
    const item = collection.find((item, i) => {
      if (Array.isArray(item)) {
        arrs.push(item)
        return false
      }
      return item?._requestId === _id
    })

    if (item) {
      return item
    }

    let found
    for (let a = 0; a < arrs.length; a += 1) {
      found = findInCollection(arrs[a], _id)
      if (found) {
        break
      }
    }
    return found
  }, [])

  const onStatus = useCallback((eventName, requestId, eventProps) => {
    const request = findInCollection(requestCollection.current, requestId)

    if (!request) {
      console.log('no request!!!', requestId, requestCollection.current)
      return
    }

    request.status = getStatusForEventName(eventName)
    request.responsePayload = eventProps

    const resultsToDate = tidyResults(requestCollection.current)

    switch (request.status) {
      case GrpcStatus.ERROR:
        if (!attemptAll) {
          setStatus(GrpcStatus.ERROR)
          cancelled.current = true
          break
        }
        completed.current += 1
        invokeCalls(resultsToDate)
        break
      case GrpcStatus.SUCCESS:
        completed.current += 1
        invokeCalls(resultsToDate)
        break
      default:
    }

    checkStatus()
  }, [attemptAll, checkStatus, findInCollection, invokeCalls, tidyResults])

  const cancelCalls = useCallback(() => {
    cancelled.current = true
  }, [])

  const getRequest = (target) => {
    if (Array.isArray(target)) {
      return target.map(getRequest)
    }

    const _requestId = guid()
    const requestObject = target
    const onHandlers = ['onSuccess', 'onError', 'onFetch']

    onHandlers.forEach((handler) => {
      const origHandler = target[handler]
      requestObject[handler] = (...args) => {
        origHandler?.(...args)
        onStatus(handler, _requestId, args)
      }
    })

    const callInvoker = (res) => {
      const { request: _request } = requestObject
      let request = _request
      if (typeof _request === 'function') {
        request = _request(res)
      }

      const call = grpcInvoke({
        ...requestObject,
        request,
        debug: debug || isDebug,
        routes,
        headers,
        grpcPrefix,
        useApiRegion
      })
      // callback for trailing metadata
      call.on('status', (status) => {
        if (status.metadata) {
          metadata = {
            ...metadata,
            ...status.metadata,
          }
        }
      })
      return call
    }

    return {
      _requestId,
      callInvoker,
      requestObject,
      status: GrpcStatus.READY
    }
  }

  const grpcAllRequest = useCallback((requests = [], data = {}) => {
    if (!isAuthenticated) {
      setError('Not authenticated')
      setStatus(GrpcStatus.ERROR)
      return
    }

    requestData.current = data
    requestCollection.current = requests.map(getRequest)

    invokeCalls()

    return requestCollection.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, onStatus, ...dependencyList])

  return [grpcAllRequest, cancelCalls]
}
