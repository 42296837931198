import { Auth0Client } from '@auth0/auth0-spa-js'

let accessToken = ''
let idTokenClaims = {}

const auth0 = new Auth0Client({
  domain: process.env.AUTH0_DOMAIN,
  client_id: process.env.AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  audience: process.env.AUTH0_AUDIENCE,
  responseType: 'token id_token code',
  advancedOptions: {
    defaultScope: 'openid profile email offline_access',
  },
})

export function setAccessToken(t = '') {
  accessToken = t
}

export function getAccessToken() {
  return accessToken
}

export function setClaims(c = {}) {
  idTokenClaims = c
}

export function getClaims() {
  return idTokenClaims
}

export function authorizeSalesforce(redirectUri) {
  window.analytics.track('Salesforce Login')
  auth0.loginWithRedirect({ redirect_uri: redirectUri, connection: 'salesforce', prompt: 'login' })
}

export function authorizeGoogle(redirectUri) {
  window.analytics.track('Google Login')
  auth0.loginWithRedirect({ redirect_uri: redirectUri, connection: 'google-oauth2', prompt: 'login' })
}

export function authorizeOutreach(redirectUri, loginHint) {
  window.analytics.track('Outreach Login')
  auth0.loginWithRedirect({
    redirect_uri: redirectUri,
    connection: 'Commit-Proxy',
    login_hint: loginHint
  })
}

export function getTokenSilently({ onSuccess, onError, options = {} }) {
  auth0
    .getTokenSilently(options)
    .then((token) => {
      setAccessToken(token)
      onSuccess && onSuccess({ accessToken: token })
    })
    .catch((err) => {
      onError && onError(err)
    })
}

export function getIdTokenClaims({ onSuccess, onError }) {
  auth0
    .getIdTokenClaims()
    .then((claims) => {
      setClaims(claims)
      onSuccess && onSuccess({ idTokenClaims: claims })
    })
    .catch((err) => {
      onError && onError(err)
    })
}

export function logout({ returnTo }) {
  setAccessToken('')
  setClaims({})
  auth0.logout({ returnTo })
}
