import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toGetTenantDataSyncStatusRequest } from '../grpc/converters'
import { guid } from '../lib/guid'
import { useAuth } from './auth'

const DataSyncStatusContext = React.createContext()

export function DataSyncStatusProvider({ children }) {
  const { tenantId } = useAuth()

  const [key, setKey] = useState(guid())
  const [dataSyncStatus, setDataSyncStatus] = useState(-1)

  useGrpcEffect({
    request: toGetTenantDataSyncStatusRequest({
      tenantId,
    }),
    onSuccess: (obj) => {
      setDataSyncStatus(obj.status)
    },
    grpcMethod: 'getTenantDataSyncStatus',
    debug: false,
  }, [tenantId, key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      dataSyncStatus,
      setDataSyncStatus,
      key,
      invalidate,
    }
  }, [dataSyncStatus])

  return <DataSyncStatusContext.Provider value={contextValue}>{children}</DataSyncStatusContext.Provider>
}

export function useDataSyncStatus() {
  const context = React.useContext(DataSyncStatusContext)
  if (context === undefined) {
    throw new Error('useDataSyncStatus must be used within a DataSyncStatusProvider')
  }
  return context
}
