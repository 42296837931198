import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '../common/modal'
import ModalHeader from '../common/modalHeader'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import SearchBox from '../common/searchBox'
import Label from '../common/label'
import Switch from '../common/switch'
import { useTextField } from '../../hooks/useTextField'
import Button from '../common/button'
import { useGrpcCallback } from '../../grpc'
import { toCreateGroupRequest, toIdsRequest, toUpdateGroupRequest } from '../../grpc/converters'
import classNames from 'classnames'
import { useNotification } from '../../hooks/useNotification'
import { useEnterKeyCallback } from '../../hooks/useEnterKeyCallback'
import { useTreeData } from '../../context/treeData'
import Checkbox from '../common/checkbox'
import { flatten, filter, some, uniqBy } from 'lodash'
import EmptyState from '../common/emptyState'
import AccountCircle from '../icons/accountCircle'
import { useAuth } from '../../context/auth'
import { useSyncState } from '../../context/syncState'
import { usePopover } from '../../hooks/usePopover'
import ActionPopover from '../common/actionPopover'
import { GroupSyncStatus } from '../../grpc/enums'
import { useTenantInfo } from '../../context/tenantInfo'
import { useGroupsError } from '../../context/groupsError'
import VirtualizedList from '../virtualized/virtualizedList'
import { useVirtualizedList } from '../virtualized/useVirtualizedList'
import { Waypoint } from 'react-waypoint'
import { CircularProgress } from '@material-ui/core'
import { useCrmRoles } from './useCrmRoles'
import Tooltip from '../common/tooltip'

const AddEditGroupModal = (props) => {
  const {
    modal,
    parentId = '',
    editData,
  } = props

  const { tenantId } = useAuth()

  const { crmName, isSalesforce } = useTenantInfo()

  const { handleFailedPreconditionError } = useGroupsError()

  const { syncStatus } = useSyncState()

  const syncChangePopover = usePopover()
  const roleChangePopover = usePopover()

  const { open, handleClose } = modal

  const [synced, setSynced] = useState(false)

  const [selectedRoles, setSelectedRoles] = useState([])

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const name = useTextField()
  const search = useTextField()

  // state to hold the crm roles that are specifically fetched by id
  const [crmRolesByIds, setCrmRolesByIds] = useState([])

  // hook to fetch all available crm roles
  const { isFetching, crmRoles: availableCrmRoles, hasMore, nextPage } = useCrmRoles({ search: search.value, canFetch: open })

  const { notifyError } = useNotification()

  const { invalidate: invalidateTreeData, flattened } = useTreeData()

  const assignedCrmRoles = useMemo(() => {
    return flatten(flattened.map((item) => {
      const crmRoleIdsList = item?.node?.group?.crmRoleIdsList ?? []
      return crmRoleIdsList.map((id) => ({ id, assignedTo: item?.node?.title ?? '' }))
    }))
  }, [flattened])

  const { invalidate: invalidateSyncState } = useSyncState()

  const invalidate = useCallback(() => {
    invalidateTreeData()
    invalidateSyncState()
  }, [invalidateTreeData, invalidateSyncState])

  useEffect(() => {
    if (open) {
      if (editData && editData.group) {
        const { name: groupName, crmRoleIdsList = [] } = editData.group
        search.reset()
        name.setValue(groupName)
        setSynced(crmRoleIdsList.length > 0)
        setSelectedRoles(crmRoleIdsList)
        if (crmRoleIdsList.length > 0) {
          getCrmRolesByIds(toIdsRequest({ idsList: crmRoleIdsList, tenantId }))
        }
      } else {
        setSynced(false)
      }
      setIsButtonDisabled(false)
    }
  }, [open, editData])

  const onCheckboxChange = useCallback((e, role) => {
    if (e.target.checked) {
      setSelectedRoles([
        ...selectedRoles,
        role.id,
      ])
    } else {
      roleChangePopover.setData([
        ...filter(selectedRoles, (id) => id !== role.id),
      ])
      roleChangePopover.setAnchorEl(e.currentTarget)
    }
  }, [selectedRoles])

  const onCancelCheckboxChange = useCallback(() => {
    roleChangePopover.setAnchorEl(undefined)
  }, [])

  const onContinueCheckboxChange = useCallback(() => {
    if (roleChangePopover.data) {
      setSelectedRoles(roleChangePopover.data)
    }
  }, [roleChangePopover.data])

  const getCrmRolesByIds = useGrpcCallback({
    onSuccess: (data) => {
      const { rolesList = [] } = data
      setCrmRolesByIds(rolesList)
    },
    grpcMethod: 'getCRMRolesByIds',
    debug: false,
  }, [])

  const crmRoles = useMemo(() => {
    const crmRoleIdsList = editData?.group?.crmRoleIdsList ?? []
    const roles = [
      ...crmRoleIdsList.map((id) => ({ id, name: crmRolesByIds.find((r) => r.id === id)?.name ?? '' })),
      ...availableCrmRoles,
      ...hasMore ? [{ loadMore: true }] : []
    ].map((r) => {
      const selected = some(selectedRoles, (id) => id === r.id)
      const assigned = assignedCrmRoles.find((a) => a.id === r.id && !crmRoleIdsList.includes(a.id))
      return {
        ...r,
        selected,
        isAssigned: !selected && !!assigned,
        assignedTo: assigned?.assignedTo ?? ''
      }
    })
    return uniqBy(roles, (r) => r.id)
  }, [hasMore, editData, selectedRoles, availableCrmRoles, crmRolesByIds, assignedCrmRoles])

  const filteredCrmRoles = useMemo(() => {
    if (search.escapedValue) {
      return filter(crmRoles, (r) => {
        const regex = new RegExp(search.escapedValue.toLowerCase())
        return r.name && regex.test(r.name.toLowerCase())
      })
    }
    return crmRoles
  }, [crmRoles, search.escapedValue])

  const title = useMemo(() => {
    return `${editData ? 'Edit' : 'Add'} Team`
  }, [editData])

  const handleCloseInternal = useCallback(() => {
    name.reset()
    handleClose && handleClose()
    syncChangePopover.setData(undefined)
  }, [handleClose])

  const saveGroup = useGrpcCallback({
    onFetch: () => {
      setIsButtonDisabled(true)
    },
    onError: (err) => {
      if (handleFailedPreconditionError(err)) {
        return
      }
      handleCloseInternal()
      notifyError(`Error ${editData ? 'editing' : 'creating'} team!`)
    },
    onSuccess: () => {
      handleCloseInternal()
      invalidate()
    },
    grpcMethod: editData ? 'updateGroup' : 'createGroup',
    debug: false,
  }, [editData, handleFailedPreconditionError])

  const onSave = useCallback((e) => {
    e && e.preventDefault()

    if (isButtonDisabled || !open || !name.isValid()) {
      return
    }

    let crmRoleIdsList = []
    if (!synced) {
      crmRoleIdsList = []
    } else {
      crmRoleIdsList = selectedRoles
    }

    const request = editData
      ? toUpdateGroupRequest({
        tenantId,
        groupId: editData.group.id,
        group: {
          ...editData.group,
          name: name.value,
          crmRoleIdsList,
        },
      })
      : toCreateGroupRequest({
        tenantId,
        group: {
          name: name.value,
          status: 1,
          type: 1,
          parentId,
          crmRoleIds: [],
          crmRoleIdsList,
        },
      })
    saveGroup(request)
  }, [tenantId, saveGroup, parentId, name.value, editData, synced, selectedRoles, isButtonDisabled, open])

  useEnterKeyCallback(onSave)

  const onContinueConfirmed = useCallback((e) => {
    const newSync = !synced
    setSynced(newSync)

    if (newSync) {
      search.reset()
    }
  }, [synced])

  const onSyncChange = useCallback((e) => {
    const newSync = !synced
    if (!newSync && syncStatus === GroupSyncStatus.ACTIVE) {
      syncChangePopover.setData({
        text: `You are currently syncing your both your hierarchy and your people from ${crmName}.`,
        subText: `If you continue editing groups manually, your groups will no longer update automatically when you make changes in ${crmName}.`,
      })
      syncChangePopover.setAnchorEl(e.currentTarget)
    } else if (newSync && (syncStatus === GroupSyncStatus.INACTIVE || syncStatus === GroupSyncStatus.PEOPLEONLY)) {
      syncChangePopover.setData({
        text: `You are not currently syncing your hierarchy and your people from ${crmName}.`,
        subText: `If you continue, your current group structure will be overwritten and update automatically when you make changes in ${crmName}.`,
      })
      syncChangePopover.setAnchorEl(e.currentTarget)
    } else {
      setSynced(newSync)

      if (newSync) {
        search.reset()
      }
    }
  }, [syncStatus, synced, crmName])

  const handleWaypointEntered = useCallback(() => {
    nextPage()
  }, [nextPage])

  const renderRole = useCallback(({ rowRendererArgs, cellMeasurerArgs }) => {
    const { index, style } = rowRendererArgs
    const { measure, registerChild } = cellMeasurerArgs
    const role = filteredCrmRoles[index]
    const showWaypoint = hasMore && !isFetching && (role?.loadMore ?? false)
    const isAssigned = role?.isAssigned ?? false
    return (
      <div ref={registerChild} style={style}>
        {showWaypoint ? (
          <Waypoint onEnter={handleWaypointEntered}>
            <div className="flex justify-center py-2">
              <CircularProgress size={16} color="secondary" />
            </div>
          </Waypoint>
        ) : (
          <Tooltip
            arrow
            placement="left"
            title={isAssigned && role?.assignedTo ? `Assigned to ${role.assignedTo}` : ''}>
            <div>
              <Checkbox
                checked={role?.selected ?? false}
                onChange={(e) => onCheckboxChange(e, role)}
                label={role?.name ?? ''}
                labelProps={{ className: `text-size-16px ${isAssigned ? 'text-color-818e93' : 'text-color-09242f'} font-weight-400` }}
                disabled={isAssigned} />
            </div>
          </Tooltip>
        )}
      </div>
    )
  }, [isFetching, hasMore, filteredCrmRoles, onCheckboxChange, handleWaypointEntered])

  const { cellMeasurerCache, rowRenderer } = useVirtualizedList({
    defaultHeight: 42,
    rowRenderer: renderRole,
  })

  return (
    <Modal
      maxWidth="sm"
      handleClose={handleCloseInternal}
      open={open}>

      <ModalHeader
        title={title}
        onClose={handleCloseInternal} />

      <ModalBody>

        <div className="w-full h-full px-10 pt-10">
          <Label text="Name" />
          <input
            className={classNames('w-full px-3 py-2 mb-6 border border-color-d6d9e6 focus:outline-none rounded', { 'border-color-fa6d6b': name.helperText })}
            value={name.value}
            onChange={name.onChange}
            placeholder={name.helperText}
            autoFocus={true} />

          {isSalesforce
            && (
              <>
                <Label text="Auto Sync (CRM)" />
                <div className="text-size-16px text-color-51636a leading-tight">Automatically import and synchronize members of this team based on their roles in your CRM.</div>
                <div className="flex items-center justify-between">
                  <div className="text-size-16px text-color-09242f">{synced ? 'On' : 'Off'}</div>
                  <Switch
                    checked={synced}
                    onChange={onSyncChange} />
                  <ActionPopover
                    popover={syncChangePopover}
                    text={(syncChangePopover.data && syncChangePopover.data.text) || ''}
                    subText={(syncChangePopover.data && syncChangePopover.data.subText) || ''}
                    actionText="Continue"
                    onAction={onContinueConfirmed}
                    maxWidth={310} />
                </div>

                {synced
                  && (
                    <div className="w-full">
                      <SearchBox
                        className="my-2"
                        value={search.value}
                        onChange={search.onChange}
                        onClear={search.reset} />

                      <div className="flex flex-col mt-3 mb-2 pl-3 rounded border border-color-d6d9e6 overflow-hidden" style={{ height: 180, paddingRight: 1 }}>
                        {crmRoles.length === 0
                          ? (
                            <div className="flex items-center justify-center pt-4">
                              <EmptyState
                                iconControl={<AccountCircle fill="#5951FF" transform="scale(2)" />}
                                header="No Available Roles"
                                subHeader="All roles are currently synced to other groups" />
                            </div>
                          )
                          : (
                            <VirtualizedList
                              className="focus:outline-none"
                              deferredMeasurementCache={cellMeasurerCache}
                              rowHeight={cellMeasurerCache.rowHeight}
                              rowCount={filteredCrmRoles.length}
                              rowRenderer={rowRenderer} />
                          )}
                      </div>
                      <ActionPopover
                        popover={roleChangePopover}
                        text={`You are currently syncing your both your hierarchy and your people from ${crmName}.`}
                        subText={`If you continue editing groups manually, your groups will no longer update automatically when you make changes in ${crmName}.`}
                        actionText="Continue"
                        onCancel={onCancelCheckboxChange}
                        onAction={onContinueCheckboxChange}
                        maxWidth={310} />
                    </div>
                  )}
              </>
            )}

        </div>

      </ModalBody>

      <ModalFooter>
        <Button
          text={editData ? 'Save' : 'Create'}
          onClick={onSave}
          disabled={isButtonDisabled} />
      </ModalFooter>

    </Modal>
  )
}

export default AddEditGroupModal
