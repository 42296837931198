import React from 'react'
import Close from '../icons/close'

const ModalHeader = (props) => {
  const {
    title,
    subtitle,
    onClose,
  } = props

  return (
    <div className="flex items-start justify-between px-10 py-5">
      <div>
        <div className="text-size-22px text-color-151d49 font-bold whitespace-nowrap truncate">{title}</div>
        {subtitle && (
          <div className="text-size-18px text-color-51636a font-weight-400 leading-tight my-2">{subtitle}</div>
        )}
      </div>
      <button onClick={onClose} className="focus:outline-none mt-2">
        <Close className="flex-shrink-0" fill="151d49" />
      </button>
    </div>
  )
}

export default ModalHeader
