import React, { useMemo } from 'react'
import { secondsToDistanceToNow } from '../../lib/dateFns'
import { BulkExportStatus } from '../../grpc/enums'
import Circle from '../icons/circle'

const SyncHistoryListItem = (props) => {
  const {
    syncHistory,
  } = props

  const {
    updatedAt,
  } = syncHistory

  const status = useMemo(() => {
    switch (syncHistory.status) {
      case BulkExportStatus.BULK_EXPORT_STATUS_COMPLETE:
        return {
          label: 'Success',
          color: '#1dcf83',
        }
      case BulkExportStatus.BULK_EXPORT_STATUS_PENDING:
        return {
          label: 'Pending',
          color: '#818e93',
        }
      default:
        return {
          label: 'Fail',
          color: '#fb6c6a',
        }
    }
  }, [syncHistory])

  return (
    <div className="tr hover:bg-color-51636a-05 py-2">
      <div className="td w-48 leading-tight">
        {updatedAt.seconds > 0 && (
          <div className="text-color-09242f text-size-16px font-weight-600">
            {secondsToDistanceToNow(updatedAt.seconds, false)}
          </div>
        )}
      </div>
      <div className="td w-10 text-size-14px text-color-818e93 font-weight-400 truncate">
        <div className="flex items-center">
          <Circle fill={status.color} transform="scale(0.55)" />
          {status.label}
        </div>
      </div>
    </div>
  )
}

export default SyncHistoryListItem
