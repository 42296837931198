import { useState, useCallback, useMemo, useEffect } from 'react'
import { useGrpcCallback } from '../../grpc'
import { toGetSettingsCommonRequest } from '../../grpc/converters'
import { useObjectDefinition } from '../../context/objectDefinition'
import { find } from 'lodash'

export function useFindFieldDefinition({ to }) {
  let options = []
  const { objectDefinition = { fieldsList: [] } } = useObjectDefinition()
  const field = find(objectDefinition.fieldsList, (f) => f.to === to)
  if (field) {
    if (field.options && field.options.itemsList) {
      options = field.options.itemsList.map((item) => {
        let value = item.value
        try {
          value = JSON.parse(atob(item.value))
        } catch (err) {
          console.log(`JSON.parse error on ${item.value}`)
        }
        return {
          ...item,
          value
        }
      })
    }
  }
  return {
    field,
    options
  }
}

export const useSettingsList = ({ key, name }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [error, setError] = useState(null)
  const [settingsList, setSettingsList] = useState()

  const value = useMemo(() => {
    if (!settingsList) {
      return
    }

    const setting = settingsList.find((setting) => setting?.key === key)
    if (!setting) {
      return
    }
    try {
      const fromProto = JSON.parse(atob(setting?.payload))
      const { [name]: valueName } = fromProto ?? {}
      return valueName
    } catch (e) {
      console.log(e)
    }
  }, [settingsList, name, key])

  const getSettingInternal = useGrpcCallback({
    onError: (err) => {
      setIsFetching(false)
      setError(err)
    },
    onSuccess: ({ settingsList }) => {
      setSettingsList(settingsList)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getSettings',
    debug: false
  }, [])

  const getSetting = useCallback(() => {
    const request = toGetSettingsCommonRequest({
      type: 'app',
      key
    })
    getSettingInternal(request)
  }, [
    toGetSettingsCommonRequest,
    getSettingInternal,
    key
  ])

  useEffect(() => {
    getSetting()
  }, [key, name, getSetting])

  return useMemo(() => {
    return {
      value,
      error,
      getSetting,
      isFetching,
      settingsList
    }
  }, [
    value,
    error,
    getSetting,
    isFetching,
    settingsList
  ])
}
