import React from 'react'

const EmptyState = (props) => {
  const {
    iconControl,
    header,
    subHeader,
  } = props

  return (
    <div className="flex items-center h-full">
      <div className="text-center">
        {iconControl
          && (
            <div className="flex justify-center my-4">
              {iconControl}
            </div>
          )}
        {header && <div className="text-size-30px font-bold text-color-91959f">{header}</div>}
        {subHeader && <div className="text-size-14px font-weight-500 text-color-4e5d7e">{subHeader}</div>}
      </div>
    </div>
  )
}

export default EmptyState
