import { download } from './file'
import { captureException } from './sentry'

function DownloadException(message) {
  const err = new Error(message)
  return err
}
DownloadException.prototype = Object.create(Error.prototype)

function UploadException(message) {
  const err = new Error(message)
  return err
}
UploadException.prototype = Object.create(Error.prototype)

export function downloadFile({ url, contentType, fileName, onError, onSuccess }) {
  fetch(url, {
    method: 'get',
    headers: {
      ...contentType && { 'content-type': contentType },
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        onError && onError({ response })
        captureException(new DownloadException(`Error downloading file ${url}`), { response }, 'downloadFile')
      } else {
        return response.blob()
      }
    })
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob)
      download(blobUrl, fileName)
      window.URL.revokeObjectURL(blob)
      onSuccess && onSuccess()
    })
    .catch((err) => {
      onError && onError({ error: err })
      captureException(new DownloadException(`Error downloading file ${url}`), { error: err }, 'downloadFile')
    })
}

export function uploadFile({ url, contentType, file, onError, onSuccess }) {
  fetch(url, {
    method: 'put',
    headers: {
      ...contentType && { 'content-type': contentType },
    },
    body: file,
  })
    .then((response) => {
      if (response.status !== 200) {
        onError && onError({ response })
        captureException(new UploadException(`Error uploading file ${url}`), { response }, 'uploadFile')
      } else {
        onSuccess && onSuccess()
      }
    })
    .catch((err) => {
      onError && onError({ error: err })
      captureException(new UploadException(`Error uploading file ${url}`), { error: err }, 'uploadFile')
    })
}
