import { OnboardingStep } from '../../grpc/enums'
import { SandboxesProvider } from '../../context/sandboxes'
import { useSetup } from '../../context/setup'
import Onboarding from '../onboarding/onboarding'
import OnboardingCompleted from '../onboarding/onboardingCompleted'
import React, { useCallback, useState } from 'react'
import SandboxMain from './sandboxMain'

const Roles = (props) => {
  const { completeStep, isFetching, isStepComplete } = useSetup()
  const [showOnboardingCompleted, setShowOnboardingCompleted] = useState(false)

  const handleCompleteJoyride = useCallback(() => {
    completeStep(OnboardingStep.ONBOARDING_STEP_SANDBOXES)
  }, [completeStep])

  return (
    <SandboxesProvider>
      <Onboarding step={OnboardingStep.ONBOARDING_STEP_SANDBOXES} onComplete={() => setShowOnboardingCompleted(true)}>
        <SandboxMain />
        {!isFetching && !isStepComplete(OnboardingStep.ONBOARDING_STEP_SANDBOXES) && (
          <OnboardingCompleted run={showOnboardingCompleted} onComplete={handleCompleteJoyride} />
        )}
      </Onboarding>
    </SandboxesProvider>
  )
}

export default Roles
