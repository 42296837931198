import { Tooltip as MuiTooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const Tooltip = withStyles((theme) => ({
  arrow: {
    '&:before': {
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    },
    color: '#ffffff',
  },
  tooltip: {
    padding: 10,
    backgroundColor: '#ffffff',
    color: '#151d49',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    fontSize: 14,
  },
}))(MuiTooltip)

export default Tooltip
