import { GoalCategoriesProvider } from '../../context/goalCategories'
import { OnboardingStep } from '../../grpc/enums'
import GoalsMain from './goalsMain'
import Onboarding from '../onboarding/onboarding'
import React from 'react'

const Goals = (props) => {
  return (
    <GoalCategoriesProvider>
      <Onboarding step={OnboardingStep.ONBOARDING_STEP_GOALS}>
        <GoalsMain />
      </Onboarding>
    </GoalCategoriesProvider>
  )
}

export default Goals
