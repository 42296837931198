import React from 'react'
import classNames from 'classnames'

const Label = (props) => {
  const {
    className = '',
    subText,
    text,
  } = props

  return (
    <div
      className={
        classNames(className,
          { 'text-size-16px': !className.includes('text-size') },
          { 'text-color-09242f': !className.includes('text-color') },
          { 'font-weight-500': !className.includes('font-weight') },
          { 'leading-tight': !!subText })
      }
    >
      {text}
      {subText && <div className="font-weight-400 text-color-51636a">{subText}</div>}
    </div>
  )
}

export default Label
