import React, { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { useDataSyncHistory } from '../../context/dataSyncHistory'
import EmptyState from '../common/emptyState'
import LoadingState from '../common/loadingState'
import Header from '../header/header'
import Sync from '../icons/sync'
import SyncHistoryList from './syncHistoryList'

const SyncHistoryMain = (props) => {
  const history = useHistory()
  const { routes } = useRoutes()

  const { isFetching, dataSyncHistory } = useDataSyncHistory()

  const count = useMemo(() => {
    return dataSyncHistory.historyList?.length ?? 0
  }, [dataSyncHistory])

  const showEmptyState = useMemo(() => {
    return !isFetching && count === 0
  }, [isFetching, count])

  const breadcrumbControl = useMemo(() => {
    return (
      <div className="text-size-12px text-color-a6b2cf font-normal tracking-widest leading-none" style={{ transform: 'translateY(-4px)' }}>
        <Link to={routes.dataSync}>Data Sync</Link>
      </div>
    )
  }, [])

  return (
    <>
      <div className="flex flex-col w-full h-screen">
        <Header
          title="History"
          showBackButton={true}
          onBackClick={() => history.push(routes.dataSync)}
          breadcrumbControl={breadcrumbControl} />

        <div className="flex-grow overflow-auto">
          <div className="flex flex-col w-full">

            <div className="p-6 bg-color-ffffff border border-color-2e5bff-08 rounded-lg mx-10 mt-10">
              <div className="text-size-24px text-color-09242f font-weight-700">Sync History</div>
              <div className="text-size-15px text-color-51636a font-weight-400 leading-tight mt-4">
                Below are listed the most recent data sync events, along with the status of each sync.
                {' '}
                If you notice any issues, please reach out to your customer success manager or our support team.
              </div>
            </div>

            <div className="px-10 py-5">
              {showEmptyState
                ? (
                  <div className="flex justify-center my-10">
                    <EmptyState
                      iconControl={(
                        <div
                          className="bg-gradient-green rounded-full flex items-center justify-center"
                          style={{ width: 48, height: 48 }}>
                          <Sync transform="scale(1.4)" />
                        </div>
                      )}
                      header="No Sync History"
                      subHeader="There is no sync history to display" />
                  </div>
                )
                : (
                  <>
                    {isFetching
                      ? (
                        <div className="flex justify-center my-10">
                          <LoadingState
                            header="Loading Sync History"
                            subHeader="Please wait..."
                            animate={true} />
                        </div>
                      )
                      : (
                        <SyncHistoryList />
                      )}
                  </>
                )}
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default SyncHistoryMain
