import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { useGrpcCallback } from '../grpc'
import { toGetObjectReferenceFieldOptionsRequest } from '../grpc/converters'
import { debounce, uniqBy } from 'lodash'
import { useAuth } from './auth'

const ReferenceFieldOptionsContext = React.createContext()

export function ReferenceFieldOptionsListProvider({ limit = 20, isEnabled, fieldReference, search = '', children }) {
  const { tenantId } = useAuth()
  const [key, setKey] = useState(guid())
  const [referenceFieldOptions, setReferenceFieldOptions] = useState([])
  const [searchInternal, setSearchInternal] = useState({ search, key: guid() })
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const isProviderEnabled = useMemo(() => {
    return isEnabled && !!fieldReference?.objectName
  }, [isEnabled, fieldReference])

  const objectName = useMemo(() => {
    return fieldReference?.objectName ?? ''
  }, [fieldReference])

  const displayFieldName = useMemo(() => {
    return fieldReference?.displayFieldName ?? ''
  }, [fieldReference])

  const getObjectReferenceFieldOptions = useGrpcCallback({
    onSuccess: (obj) => {
      const { resultsList = [], totalCount = 0 } = obj
      setReferenceFieldOptions((prevValue) => {
        const options = uniqBy([
          ...prevValue,
          ...resultsList
        ], (o) => o.key)
        setHasMore(totalCount > 0 && options.length < totalCount)
        return options
      })
      setTotal(totalCount)
      setIsFetching(false)
    },
    onError: (err) => {
      setIsFetching(false)
    },
    onFetch: () => {
      setIsFetching(true)
    },
    grpcMethod: 'getObjectReferenceFieldOptions',
    debug: false
  }, [])

  const nextPage = useCallback(() => {
    setPage(page + 1)
  }, [page])

  const updateSearch = useCallback((search) => {
    setIsFetching(true)
    setReferenceFieldOptions([])
    setSearchInternal({ search, key: guid() })
    setPage(1)
  }, [])

  const debounceSearch = useMemo(() => {
    return debounce(updateSearch, 350)
  }, [updateSearch])

  useEffect(() => {
    debounceSearch(search)
    return () => {
      debounceSearch.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  useEffect(() => {
    if (isProviderEnabled) {
      const request = toGetObjectReferenceFieldOptionsRequest({
        referenceObjectKey: objectName,
        referenceObjectLabelField: displayFieldName,
        searchRequest: searchInternal.search,
        page,
        limit,
        tenantId
      })
      getObjectReferenceFieldOptions(request)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, limit, isProviderEnabled, objectName, displayFieldName, page, searchInternal, getObjectReferenceFieldOptions])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      referenceFieldOptions,
      total,
      hasMore,
      nextPage,
      updateSearch,
      invalidate
    }
  }, [isFetching, referenceFieldOptions, total, hasMore, nextPage, updateSearch, invalidate])

  return <ReferenceFieldOptionsContext.Provider value={contextValue}>{children}</ReferenceFieldOptionsContext.Provider>
}

export function useReferenceFieldOptionsList() {
  const context = React.useContext(ReferenceFieldOptionsContext)
  if (context === undefined) {
    throw new Error('useReferenceFieldOptionsList must be used within a ReferenceFieldOptionsListProvider')
  }
  return context
}
