import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Label from '../common/label'
import Checkbox from '../common/checkbox'
import Info from '../icons/info'
import Tooltip from '../common/tooltip'
import Button from '../common/button'
import { cloneDeep } from 'lodash'
import BlockNavigation from '../common/blockNavigation'
import { useGrpcCallback } from '../../grpc'
import { useNotification } from '../../hooks/useNotification'
import { toUpsertScenarioPlannerSettingRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import LoadingState from '../common/loadingState'
import { useScenarioPlannerSettings } from '../../context/scenarioPlannerSettings'
import ErrorWithReload from '../common/errorWithReload'

const ScenarioPlannerSettingsMain = (props) => {
  const { tenantId } = useAuth()

  const { notifySuccess, notifyError } = useNotification()

  const { isFetching, fetchError, settings, invalidate } = useScenarioPlannerSettings()

  const [isSaving, setIsSaving] = useState(false)
  const [upsertError, setUpsertError] = useState(false)
  const [originalSettings, setOriginalSettings] = useState({})
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    if (settings) {
      setOriginalSettings(cloneDeep(settings))
      const { enabled = false } = settings
      setIsEnabled(enabled)
    }
  }, [settings])

  const hasChanges = useMemo(() => {
    const { enabled = false } = originalSettings
    return enabled !== isEnabled
  }, [originalSettings, isEnabled])

  const upsertScenarioPlannerSetting = useGrpcCallback({
    onError: () => {
      setIsSaving(false)
      setUpsertError(true)
      notifyError('Error saving Scenario Planner settings!')
    },
    onSuccess: (obj) => {
      invalidate()
      setIsSaving(false)
      setUpsertError(false)
      notifySuccess('Scenario Planner settings were saved!')
    },
    onFetch: () => {
      setIsSaving(true)
      setUpsertError(false)
    },
    grpcMethod: 'upsertScenarioPlannerSetting',
    debug: false,
  }, [])

  const onSave = useCallback(() => {
    // console.log('onSave', settings, isEnabled)
    const request = toUpsertScenarioPlannerSettingRequest({
      tenantId,
      setting: {
        ...settings,
        enabled: isEnabled,
      },
    })
    upsertScenarioPlannerSetting(request)
  }, [tenantId, settings, isEnabled])

  return fetchError || upsertError
    ? (
      <div className="flex justify-center my-10">
        <ErrorWithReload header={fetchError ? 'Error Loading Settings' : 'Error Saving Settings'} />
      </div>
    ) : (
      <>
        {isFetching || isSaving
          ? (
            <div>
              <div className="flex justify-center my-10">
                <LoadingState
                  header={isSaving ? 'Saving' : 'Loading Settings'}
                  subHeader="Please wait..."
                  animate={true} />
              </div>
            </div>
          )
          : (
            <>
              <div className="flex items-center justify-between">
                <div className="text-size-24px text-color-09242f font-weight-700">Scenario Planner Settings</div>
                <Button
                  text="Save Changes"
                  onClick={onSave}
                  disabled={!hasChanges} />
              </div>
              <div className="text-size-15px text-color-51636a font-weight-400 leading-tight my-4">
                These settings control how the Scenario Planner feature in Commit is configured.
              </div>
              <div className="w-full md:w-7/12">
                <div className="mt-5 mb-5 first:mt-0 last:mb-0">
                  <div className="flex items-center">
                    <Label text="Enabled" />
                    <Tooltip placement="top" arrow={true} title="Enables the ability to use the Scenario Planner feature.">
                      <div style={{ transform: 'translateY(-2px)' }}><Info fill="#818e93" transform="scale(0.8)" /></div>
                    </Tooltip>
                  </div>
                  <Checkbox
                    checked={isEnabled}
                    onChange={(e) => setIsEnabled(e.target.checked)} />
                </div>
              </div>
            </>
          )}
        <BlockNavigation
          canBlock={hasChanges}
          promptTitle="Warning"
          promptText="Are you sure you want to leave?"
          promptSubText="You have unsaved changes that will be lost if you leave now. This cannot be undone."
          promptActionText="Leave" />
      </>
    )
}

export default ScenarioPlannerSettingsMain
