import { Check } from '@getoutreach/react-icons'
import { noop } from 'lodash'
import React from 'react'

const SideMenuItemSteps = ({ children }) => {
  const steps = React.Children.toArray(children).filter(Boolean)
  return steps.map((step, index) => {
    const completed = step?.props?.completed
    // If the current step and its previous sibling are not complete, disable the current step
    const disabled = index > 0 && !completed && !steps[index - 1]?.props?.completed

    return (
      React.cloneElement(step, {
        disabled,
        icon: completed ? <Check /> : <span>{index + 1}</span>,
        ...(index === 0) && { borderTop: false },
        ...(index === steps.length - 1) && { borderBottom: false }
      }))
  })
}

export default SideMenuItemSteps
