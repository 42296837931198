import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Label from '../common/label'
import Button from '../common/button'
import { cloneDeep } from 'lodash'
import BlockNavigation from '../common/blockNavigation'
import { useGrpcCallback } from '../../grpc'
import { useNotification } from '../../hooks/useNotification'
import { toUpsertHierarchySettingRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import LoadingState from '../common/loadingState'
import { useHierarchySettings } from '../../context/hierarchySettings'
import ErrorWithReload from '../common/errorWithReload'
import { HierarchyOption } from '../../grpc/enums'
import SelectList from '../common/selectList'

const hierarchySelectOptions = [
  { value: HierarchyOption.DEFAULT, label: 'Default' },
  { value: HierarchyOption.TERRITORY_MANAGEMENT, label: 'Territory Management' },
  { value: HierarchyOption.ENTERPRISE_TERRITORY_MANAGEMENT, label: 'Enterprise Territory Management' }
]

const HierarchySettingsMain = (props) => {
  const { tenantId } = useAuth()

  const { notifySuccess, notifyError } = useNotification()

  const { isFetching, fetchError, settings, invalidate } = useHierarchySettings()

  const [isSaving, setIsSaving] = useState(false)
  const [upsertError, setUpsertError] = useState(false)
  const [originalSettings, setOriginalSettings] = useState({})
  const [selectedHierarchyOption, setSelectedHierarchyOption] = useState(HierarchyOption.DEFAULT)

  useEffect(() => {
    if (settings) {
      setOriginalSettings(cloneDeep(settings))
      const {
        hierarchyOption = HierarchyOption.DEFAULT
      } = settings
      setSelectedHierarchyOption(hierarchyOption)
    }
  }, [settings])

  const onChange = useCallback((option) => {
    if (option) {
      setSelectedHierarchyOption(option.value)
    }
  }, [])

  const hasChanges = useMemo(() => {
    const {
      hierarchyOption = HierarchyOption.DEFAULT
    } = originalSettings
    return hierarchyOption !== selectedHierarchyOption
  }, [originalSettings, selectedHierarchyOption])

  const upsertHierarchySetting = useGrpcCallback({
    onError: () => {
      setIsSaving(false)
      setUpsertError(true)
      notifyError('Error saving Hierarchy settings!')
    },
    onSuccess: (obj) => {
      invalidate()
      setIsSaving(false)
      setUpsertError(false)
      notifySuccess('Hierarchy settings were saved!')
    },
    onFetch: () => {
      setIsSaving(true)
      setUpsertError(false)
    },
    grpcMethod: 'upsertHierarchySetting',
    debug: false
  }, [])

  const onSave = useCallback(() => {
    // console.log('onSave', settings, selectedHierarchyOption)
    const request = toUpsertHierarchySettingRequest({
      tenantId,
      setting: {
        ...settings,
        hierarchyOption: selectedHierarchyOption
      },
    })
    upsertHierarchySetting(request)
  }, [tenantId, upsertHierarchySetting, settings, selectedHierarchyOption])

  return fetchError || upsertError
    ? (
      <div className="flex justify-center my-10">
        <ErrorWithReload header={fetchError ? 'Error Loading Settings' : 'Error Saving Settings'} />
      </div>
    ) : (
      <>
        {isFetching || isSaving
          ? (
            <div>
              <div className="flex justify-center my-10">
                <LoadingState
                  header={isSaving ? 'Saving' : 'Loading Settings'}
                  subHeader="Please wait..."
                  animate={true} />
              </div>
            </div>
          )
          : (
            <>
              <div className="flex items-center justify-between">
                <div className="text-size-24px text-color-09242f font-weight-700">Hierarchy Settings</div>
                <Button
                  text="Save Changes"
                  onClick={onSave}
                  disabled={!hasChanges} />
              </div>
              <div className="text-size-15px text-color-51636a font-weight-400 leading-tight my-4">
                These settings control how the Hierarchy features are configured.
              </div>
              <div className="w-full md:w-7/12">
                <div className="mt-5 mb-5 first:mt-0 last:mb-0">
                  <div className="flex items-center">
                    <Label text="Hierarchy Option" />
                  </div>
                  <div className="mt-3">
                    <SelectList
                      disabled={isFetching}
                      value={selectedHierarchyOption}
                      onChange={onChange}
                      options={hierarchySelectOptions} />
                  </div>
                </div>
              </div>
            </>
          )}
        <BlockNavigation
          canBlock={hasChanges}
          promptTitle="Warning"
          promptText="Are you sure you want to leave?"
          promptSubText="You have unsaved changes that will be lost if you leave now. This cannot be undone."
          promptActionText="Leave" />
      </>
    )
}

export default HierarchySettingsMain
