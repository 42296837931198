import { Autocomplete } from '@material-ui/lab'
import { debounce, filter, find, orderBy, some } from 'lodash'
import { FilterOp, PersonField } from '../../grpc/enums'
import { grpcCodes } from '../../grpc/grpcCodes'
import { makeStyles } from '@material-ui/core/styles'
import { toCreatePersonWithGroupViewerRequest, toSearchPeopleRequest, toUpdatePersonWithGroupViewerRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import { useGrpcCallback } from '../../grpc'
import { useNotification } from '../../hooks/useNotification'
import { useSystemRoles } from '../../context/systemRoles'
import { useTenantInfo } from '../../context/tenantInfo'
import { useTextField } from '../../hooks/useTextField'
import { useTreeData } from '../../context/treeData'
import { useUsers } from '../../context/users'
import Button from '../common/button'
import Checkbox from '../common/checkbox'
import ChevronLeft from '../icons/chevronLeft'
import classNames from 'classnames'
import EditVisibilityControl from './editVisibilityControl'
import Info from '../icons/info'
import Label from '../common/label'
import Modal from '../common/modal'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import ModalHeader from '../common/modalHeader'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SearchBox from '../common/searchBox'
import Tooltip from '../common/tooltip'

const useAutocompleteStyles = makeStyles({
  option: {
    // hover
    '&[data-focus="true"]': {
      borderColor: 'transparent',
      backgroundColor: '#5951FF',
      color: '#ffffff',
    },
    // selected
    '&[aria-selected="true"]': {
      borderColor: 'transparent',
      backgroundColor: '#5951FF',
      color: '#ffffff',
    },
  },
})

const AddEditUserModal = (props) => {
  const {
    modal,
    editData,
  } = props

  const { tenantId } = useAuth()

  const { crmName } = useTenantInfo()

  const autoStyles = useAutocompleteStyles({})

  const { open, handleClose } = modal

  const { invalidate } = useUsers()

  const [showExternalUserForm, setShowExternalUserForm] = useState(false)

  const [viewableGroups, setViewableGroups] = useState([])

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const {
    flattened,
    treeData
  } = useTreeData()

  const name = useTextField()
  const firstName = useTextField()
  const lastName = useTextField()
  const email = useTextField()
  const search = useTextField()
  const teamSearch = useTextField()

  const [selectedUser, setSelectedUser] = useState(undefined)

  const [selectedRoles, setSelectedRoles] = useState([])

  const { notifyError } = useNotification()

  const { systemRoles } = useSystemRoles()

  useEffect(() => {
    if (open) {
      if (editData) {
        name.setValue(editData.name)
        setSelectedRoles(editData.roleIdsList)
      }
      setIsButtonDisabled(false)
    }
  }, [open, editData])

  useEffect(() => {
    if (open && editData && treeData) {
      const { groupViewerIdsList: groupIds } = editData
      const _viewableGroups = flattened
        .filter(({ node }) => groupIds.includes(node.group.id))
        .map(({ node }) => node)
      setViewableGroups(_viewableGroups)
    }
  }, [open, editData, treeData, flattened])

  const [searchedPeople, setSearchedPeople] = useState({ peopleList: [], total: 0 })

  const searchPeople = useGrpcCallback({
    onError: () => {
      notifyError('Error searching people!')
    },
    onSuccess: (data) => {
      setSearchedPeople(data)
    },
    grpcMethod: 'searchPeople',
    debug: false,
  }, [searchedPeople])

  const debounceSearchPeople = useMemo(() => {
    return debounce(searchPeople, 350)
  }, [searchPeople])

  const getOptionLabel = useCallback((o) => {
    return o.name || ''
  }, [])

  const getOptionDisabled = useCallback((o) => {
    return !o.email
  }, [])

  const renderOption = useCallback((option, { selected }) => {
    // eslint-disable-next-line no-shadow
    const { name, email } = option
    return (
      <div className="flex flex-col leading-tight">
        <div className="text-size-16px text-color-09242f font-weight-400">{name}</div>
        <div className="text-size-12px text-color-51636a font-weight-400">{email || '-'}</div>
      </div>
    )
  }, [])

  const showTeamVisibility = useMemo(() => {
    if (!editData) {
      return
    }

    if (editData?.groupId || editData?.groupViewerIdsList?.length) {
      return true
    }
  }, [editData])

  const getOptionSelected = useCallback((option, value) => {
    return option.id === value.id
  }, [])

  const onNameInputChange = useCallback((e, value) => {
    name.setValue(value)
    const request = toSearchPeopleRequest({
      tenantId,
      search: value,
      page: 1,
      pageSize: 100,
      filtersList: [
        {
          field: PersonField.ISPROVISIONED,
          op: FilterOp.EQ,
          valuesList: [
            btoa(JSON.stringify(false)),
          ],
        }
      ],
    })
    debounceSearchPeople(request)
  }, [tenantId, debounceSearchPeople])

  const onNameChange = useCallback((e, value) => {
    setSelectedUser(value)
  }, [debounceSearchPeople])

  const onCheckboxChange = useCallback((e, role) => {
    if (e.target.checked) {
      setSelectedRoles([
        ...selectedRoles,
        role.id,
      ])
    } else {
      setSelectedRoles([
        ...filter(selectedRoles, (id) => id !== role.id),
      ])
    }
  }, [selectedRoles])

  const filteredSystemRoles = useMemo(() => {
    const assignedRoleIds = (editData && editData.roleIdsList && editData.roleIdsList) || []
    const assignedRoles = filter(assignedRoleIds.map((id) => {
      return find(systemRoles, (r) => r.id === id) || { id, name: 'Internal' }
    }), (r) => r.name !== 'Internal')
    const unassignedRoles = filter(systemRoles, (r) => !assignedRoleIds.includes(r.id))
    const roles = [
      ...orderBy(assignedRoles, (o) => o.name),
      ...orderBy(unassignedRoles, (o) => o.name),
    ].map((r) => ({
      ...r,
      selected: some(selectedRoles, (id) => id === r.id),
    }))
    if (search.value) {
      return filter(roles, (r) => {
        const regex = new RegExp(search.escapedValue.toLowerCase())
        return r.name && regex.test(r.name.toLowerCase())
      })
    }
    return roles
  }, [editData, systemRoles, search.value, selectedRoles])

  const title = useMemo(() => {
    return `${editData ? 'Edit' : 'Add'} User`
  }, [editData])

  const handleCloseInternal = useCallback(() => {
    name.reset()
    search.reset()
    firstName.reset()
    lastName.reset()
    email.reset()
    search.reset()
    teamSearch.reset()
    handleClose && handleClose()
    setSelectedUser(undefined)
    setSelectedRoles([])
    setShowExternalUserForm(false)
    setViewableGroups([])
    setSearchedPeople({ peopleList: [], total: 0 })
  }, [handleClose])

  const createPerson = useGrpcCallback({
    onError: (err) => {
      if (err.code === grpcCodes.ALREADY_EXISTS) {
        notifyError('A user with that email already exists!')
      } else {
        handleCloseInternal()
        notifyError('Error creating user!')
      }
    },
    onSuccess: () => {
      handleCloseInternal()
      invalidate()
    },
    grpcMethod: 'createPersonWithGroupViewer',
    debug: false,
  }, [editData])

  const saveUser = useGrpcCallback({
    onFetch: () => {
      setIsButtonDisabled(true)
    },
    onError: () => {
      handleCloseInternal()
      notifyError(`Error ${editData ? 'editing' : 'creating'} user!`)
    },
    onSuccess: () => {
      handleCloseInternal()
      invalidate()
    },
    grpcMethod: 'updatePersonWithGroupViewer',
    debug: false,
  }, [editData])

  const onVisibilityUpdated = useCallback((selected) => {
    setViewableGroups(Array.from(selected.values()))
  }, [editData])

  const onRemoveViewableGroup = useCallback((id) => {
    setViewableGroups(viewableGroups.filter(({ group }) => group.id !== id))
  }, [viewableGroups])

  const onSave = useCallback((e) => {
    e && e.preventDefault()

    if (isButtonDisabled || !open) {
      return
    }

    if (showExternalUserForm) {
      const firstNameValid = firstName.isValid()
      const lastNameValid = lastName.isValid()
      const emailValid = email.isValid()
      if (!firstNameValid || !lastNameValid || !emailValid) {
        return
      }
    } else if (!name.isValid()) {
      return
    }

    if (selectedRoles.length === 0) {
      notifyError('User must have at least 1 role!')
      return
    }

    const groupViewerIdsList = viewableGroups.map(({ group }) => group.id)

    if (showExternalUserForm) {
      const request = toCreatePersonWithGroupViewerRequest({
        tenantId,
        person: {
          firstName: firstName.value.trim(),
          lastName: lastName.value.trim(),
          name: `${firstName.value} ${lastName.value}`,
          email: email.value,
          isProvisioned: true,
          roleIdsList: selectedRoles,
          status: 1,
        },
        groupViewerIdsList
      })
      createPerson(request)
    } else {
      const person = editData || selectedUser
      const personId = person.id
      const request = toUpdatePersonWithGroupViewerRequest({
        tenantId,
        personId,
        person: {
          ...person,
          isProvisioned: true,
          roleIdsList: selectedRoles,
        },
        onlyFieldsList: ['is_provisioned', 'role_ids'],
        groupViewerIdsList
      })
      saveUser(request)
    }
    // eslint-disable-next-line max-len
  }, [tenantId, createPerson, saveUser, selectedUser, name.value, firstName.value, lastName.value, email.value, editData, selectedRoles, showExternalUserForm, isButtonDisabled, open, viewableGroups])

  const onToggleExternalUserForm = useCallback((show) => {
    name.reset()
    search.reset()
    firstName.reset()
    lastName.reset()
    email.reset()
    setSelectedUser(undefined)
    setSelectedRoles([])
    setShowExternalUserForm(show)
    setSearchedPeople({ peopleList: [], total: 0 })
  }, [])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="sm"
      open={open}>

      <ModalHeader
        title={title}
        onClose={handleCloseInternal} />

      <ModalBody classes="overflow-x-hidden">

        <div className="w-2/1 flex transition-left relative duration-350">
          <div className="w-1/2 h-full px-10 pt-10 transition-left relative duration-350">

            {showExternalUserForm
              ? (
                <>
                  <div onClick={() => onToggleExternalUserForm(false)} className="flex items-center cursor-pointer focus:outline-none" style={{ transform: 'translateX(-8px)' }}>
                    <ChevronLeft fill="#09242f" />
                    <div className="text-size-14px text-color-09242f font-weight-500">Back</div>
                  </div>
                  <div className="text-size-16px text-color-51636a font-weight-400 mt-2 mb-5">
                    Manually create a user who can log into Outreach Commit. Users created here will need to be manually added to a team, even if automatic sync is enabled.
                  </div>
                  <Label text="First Name" />
                  <input
                    className={classNames('w-full px-3 py-2 mb-6 border border-color-d6d9e6 focus:outline-none rounded', { 'border-color-fa6d6b': firstName.helperText })}
                    value={firstName.value}
                    onChange={firstName.onChange}
                    placeholder={firstName.helperText} />
                  <Label text="Last Name" />
                  <input
                    className={classNames('w-full px-3 py-2 mb-6 border border-color-d6d9e6 focus:outline-none rounded', { 'border-color-fa6d6b': lastName.helperText })}
                    value={lastName.value}
                    onChange={lastName.onChange}
                    placeholder={lastName.helperText} />
                  <Label text="Email" />
                  <input
                    className={classNames('w-full px-3 py-2 mb-6 border border-color-d6d9e6 focus:outline-none rounded', { 'border-color-fa6d6b': email.helperText })}
                    value={email.value}
                    onChange={email.onChange}
                    placeholder={email.helperText} />
                </>
              )
              : (
                <>
                  <div className="flex justify-between">
                    <Label text="Name" />
                    {editData === undefined && (
                      <div onClick={() => onToggleExternalUserForm(true)} className="text-size-14px text-color-2e5bff font-weight-600 cursor-pointer focus:outline-none">
                        Not in
                        {' '}
                        {crmName}
                        ?
                      </div>
                    )}
                  </div>
                  <Autocomplete
                    classes={{
                      listbox: autoStyles.listbox,
                    }}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref} className="mb-6">
                        <input
                          {...{
                            ...params.inputProps,
                            ...name.valueChanged && { placeholder: 'Required' },
                            disabled: editData !== undefined,
                          }}
                          {...editData !== undefined && { value: editData.name }}
                          className={classNames('w-full px-3 py-2 border border-color-d6d9e6 focus:outline-none rounded',
                            { 'border-color-fa6d6b': name.helperText },
                            { 'text-color-818e93': editData !== undefined })}
                          type="text"
                          autoFocus={true} />
                      </div>
                    )}
                    noOptionsText="Search by name"
                    onInputChange={onNameInputChange}
                    onChange={onNameChange}
                    disabled={editData !== undefined}
                    options={searchedPeople.peopleList}
                    getOptionSelected={getOptionSelected}
                    getOptionLabel={getOptionLabel}
                    getOptionDisabled={getOptionDisabled}
                    renderOption={renderOption} />
                </>
              )}

            <Label text={(
              <>
                Role(s)
                <Tooltip arrow placement="right" title="Roles in Commit function to control who has access to specific features within your Commit instance.">
                  <Info htmlColor="#A6AEBE" transform="scale(0.8)" />
                </Tooltip>
              </>
            )}
            />
            <div className="w-full mb-5">
              <SearchBox
                className="my-2"
                value={search.value}
                enableKeyboard={false}
                onChange={search.onChange}
                onClear={search.reset} />
              <div className="flex flex-col mt-3 mb-2 px-3 py-2 rounded border border-color-d6d9e6 overflow-auto" style={{ height: 180 }}>
                {filteredSystemRoles.map((role, i) => (
                  <div key={`SystemRole-${role.id}-${i}`}>
                    <Checkbox
                      checked={role.selected}
                      onChange={(e) => onCheckboxChange(e, role)}
                      label={role.name}
                      labelProps={{ className: 'text-size-16px text-color-09242f font-weight-400' }} />
                  </div>
                ))}
              </div>
            </div>

            {showTeamVisibility && (
              <div className="flex flex-col justify-between">
                <Label text={(
                  <>
                    Team Visibility
                    <Tooltip arrow
                      placement="right"
                      title="Team Visibility functions to control team level access for individual users.
                      This is especially useful for individuals who have their own quota and also serve a team manager.">
                      <Info htmlColor="#A6AEBE" transform="scale(0.8)" />
                    </Tooltip>
                  </>
                )} />
                <SearchBox
                  className="my-2"
                  value={teamSearch.value}
                  enableKeyboard={false}
                  onChange={teamSearch.onChange}
                  onClear={teamSearch.reset} />
                <div className="flex flex-col mb-2 px-2 rounded border border-color-d6d9e6 overflow-auto" style={{ height: 180 }}>
                  <EditVisibilityControl
                    search={teamSearch.value}
                    user={editData}
                    visibilityUpdated={onVisibilityUpdated}
                    viewableGroups={viewableGroups} />
                </div>
              </div>
            )}
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          text={editData ? 'Save' : 'Add'}
          onClick={onSave}
          disabled={isButtonDisabled} />
      </ModalFooter>

    </Modal>
  )
}

export default AddEditUserModal
