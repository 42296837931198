import { createTheme } from '@material-ui/core'

export default createTheme({
  typography: {
    fontFamily: 'Proxima Nova',
  },
  palette: {
    primary: {
      main: '#02cf7d',
    },
    secondary: {
      main: '#5951FF',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true
    }
  }
})
