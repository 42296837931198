// source: src/common/liftoff/liftoff.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.loupe.common.liftoff.InstanceSetupStatus', null, global);
goog.exportSymbol('proto.loupe.common.liftoff.LiftoffOnboardingStep', null, global);
goog.exportSymbol('proto.loupe.common.liftoff.LiftoffPrelaunchConfig', null, global);
goog.exportSymbol('proto.loupe.common.liftoff.LiftoffPrelaunchStep', null, global);
goog.exportSymbol('proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig', null, global);
goog.exportSymbol('proto.loupe.common.liftoff.LiftoffStatus', null, global);
goog.exportSymbol('proto.loupe.common.liftoff.LiftoffStatusProgress', null, global);
goog.exportSymbol('proto.loupe.common.liftoff.OnboardingStep', null, global);
goog.exportSymbol('proto.loupe.common.liftoff.PrelaunchStep', null, global);
goog.exportSymbol('proto.loupe.common.liftoff.PrelaunchStepStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.liftoff.LiftoffPrelaunchConfig.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.liftoff.LiftoffPrelaunchConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.liftoff.LiftoffPrelaunchConfig.displayName = 'proto.loupe.common.liftoff.LiftoffPrelaunchConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.liftoff.LiftoffPrelaunchStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.liftoff.LiftoffPrelaunchStep.displayName = 'proto.loupe.common.liftoff.LiftoffPrelaunchStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.displayName = 'proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.liftoff.LiftoffStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.liftoff.LiftoffStatus.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.liftoff.LiftoffStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.liftoff.LiftoffStatus.displayName = 'proto.loupe.common.liftoff.LiftoffStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.liftoff.LiftoffStatusProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.liftoff.LiftoffStatusProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.liftoff.LiftoffStatusProgress.displayName = 'proto.loupe.common.liftoff.LiftoffStatusProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.liftoff.LiftoffOnboardingStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.liftoff.LiftoffOnboardingStep.displayName = 'proto.loupe.common.liftoff.LiftoffOnboardingStep';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.liftoff.LiftoffPrelaunchConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.liftoff.LiftoffPrelaunchConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepsList: jspb.Message.toObjectList(msg.getStepsList(),
    proto.loupe.common.liftoff.LiftoffPrelaunchStep.toObject, includeInstance),
    onboardingList: jspb.Message.toObjectList(msg.getOnboardingList(),
    proto.loupe.common.liftoff.LiftoffOnboardingStep.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchConfig}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.liftoff.LiftoffPrelaunchConfig;
  return proto.loupe.common.liftoff.LiftoffPrelaunchConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.liftoff.LiftoffPrelaunchConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchConfig}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.liftoff.LiftoffPrelaunchStep;
      reader.readMessage(value,proto.loupe.common.liftoff.LiftoffPrelaunchStep.deserializeBinaryFromReader);
      msg.addSteps(value);
      break;
    case 2:
      var value = new proto.loupe.common.liftoff.LiftoffOnboardingStep;
      reader.readMessage(value,proto.loupe.common.liftoff.LiftoffOnboardingStep.deserializeBinaryFromReader);
      msg.addOnboarding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.liftoff.LiftoffPrelaunchConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.liftoff.LiftoffPrelaunchConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.liftoff.LiftoffPrelaunchStep.serializeBinaryToWriter
    );
  }
  f = message.getOnboardingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.common.liftoff.LiftoffOnboardingStep.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LiftoffPrelaunchStep steps = 1;
 * @return {!Array<!proto.loupe.common.liftoff.LiftoffPrelaunchStep>}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.prototype.getStepsList = function() {
  return /** @type{!Array<!proto.loupe.common.liftoff.LiftoffPrelaunchStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.liftoff.LiftoffPrelaunchStep, 1));
};


/**
 * @param {!Array<!proto.loupe.common.liftoff.LiftoffPrelaunchStep>} value
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchConfig} returns this
*/
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.prototype.setStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.liftoff.LiftoffPrelaunchStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchStep}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.prototype.addSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.liftoff.LiftoffPrelaunchStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchConfig} returns this
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.prototype.clearStepsList = function() {
  return this.setStepsList([]);
};


/**
 * repeated LiftoffOnboardingStep onboarding = 2;
 * @return {!Array<!proto.loupe.common.liftoff.LiftoffOnboardingStep>}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.prototype.getOnboardingList = function() {
  return /** @type{!Array<!proto.loupe.common.liftoff.LiftoffOnboardingStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.liftoff.LiftoffOnboardingStep, 2));
};


/**
 * @param {!Array<!proto.loupe.common.liftoff.LiftoffOnboardingStep>} value
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchConfig} returns this
*/
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.prototype.setOnboardingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.liftoff.LiftoffOnboardingStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.liftoff.LiftoffOnboardingStep}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.prototype.addOnboarding = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.liftoff.LiftoffOnboardingStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchConfig} returns this
 */
proto.loupe.common.liftoff.LiftoffPrelaunchConfig.prototype.clearOnboardingList = function() {
  return this.setOnboardingList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.liftoff.LiftoffPrelaunchStep.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.liftoff.LiftoffPrelaunchStep} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.toObject = function(includeInstance, msg) {
  var f, obj = {
    step: jspb.Message.getFieldWithDefault(msg, 1, 0),
    config: (f = msg.getConfig()) && proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchStep}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.liftoff.LiftoffPrelaunchStep;
  return proto.loupe.common.liftoff.LiftoffPrelaunchStep.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.liftoff.LiftoffPrelaunchStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchStep}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.liftoff.PrelaunchStep} */ (reader.readEnum());
      msg.setStep(value);
      break;
    case 2:
      var value = new proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig;
      reader.readMessage(value,proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.liftoff.PrelaunchStepStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.liftoff.LiftoffPrelaunchStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.liftoff.LiftoffPrelaunchStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStep();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional PrelaunchStep step = 1;
 * @return {!proto.loupe.common.liftoff.PrelaunchStep}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.prototype.getStep = function() {
  return /** @type {!proto.loupe.common.liftoff.PrelaunchStep} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.liftoff.PrelaunchStep} value
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchStep} returns this
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.prototype.setStep = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional LiftoffPrelaunchStepConfig config = 2;
 * @return {?proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.prototype.getConfig = function() {
  return /** @type{?proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig, 2));
};


/**
 * @param {?proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig|undefined} value
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchStep} returns this
*/
proto.loupe.common.liftoff.LiftoffPrelaunchStep.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchStep} returns this
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrelaunchStepStatus status = 3;
 * @return {!proto.loupe.common.liftoff.PrelaunchStepStatus}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.liftoff.PrelaunchStepStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.liftoff.PrelaunchStepStatus} value
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchStep} returns this
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string status_message = 4;
 * @return {string}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchStep} returns this
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStep.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    notifyOnComplete: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    configMap: (f = msg.getConfigMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig;
  return proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotifyOnComplete(value);
      break;
    case 2:
      var value = msg.getConfigMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotifyOnComplete();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getConfigMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional bool notify_on_complete = 1;
 * @return {boolean}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.prototype.getNotifyOnComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig} returns this
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.prototype.setNotifyOnComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * map<string, string> config = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.prototype.getConfigMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig} returns this
 */
proto.loupe.common.liftoff.LiftoffPrelaunchStepConfig.prototype.clearConfigMap = function() {
  this.getConfigMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.liftoff.LiftoffStatus.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.liftoff.LiftoffStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.liftoff.LiftoffStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.liftoff.LiftoffStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.liftoff.LiftoffStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    progress: (f = msg.getProgress()) && proto.loupe.common.liftoff.LiftoffStatusProgress.toObject(includeInstance, f),
    onboardingList: jspb.Message.toObjectList(msg.getOnboardingList(),
    proto.loupe.common.liftoff.LiftoffOnboardingStep.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.liftoff.LiftoffStatus}
 */
proto.loupe.common.liftoff.LiftoffStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.liftoff.LiftoffStatus;
  return proto.loupe.common.liftoff.LiftoffStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.liftoff.LiftoffStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.liftoff.LiftoffStatus}
 */
proto.loupe.common.liftoff.LiftoffStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.liftoff.InstanceSetupStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    case 3:
      var value = new proto.loupe.common.liftoff.LiftoffStatusProgress;
      reader.readMessage(value,proto.loupe.common.liftoff.LiftoffStatusProgress.deserializeBinaryFromReader);
      msg.setProgress(value);
      break;
    case 4:
      var value = new proto.loupe.common.liftoff.LiftoffOnboardingStep;
      reader.readMessage(value,proto.loupe.common.liftoff.LiftoffOnboardingStep.deserializeBinaryFromReader);
      msg.addOnboarding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.liftoff.LiftoffStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.liftoff.LiftoffStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.liftoff.LiftoffStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.liftoff.LiftoffStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProgress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.loupe.common.liftoff.LiftoffStatusProgress.serializeBinaryToWriter
    );
  }
  f = message.getOnboardingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.loupe.common.liftoff.LiftoffOnboardingStep.serializeBinaryToWriter
    );
  }
};


/**
 * optional InstanceSetupStatus status = 1;
 * @return {!proto.loupe.common.liftoff.InstanceSetupStatus}
 */
proto.loupe.common.liftoff.LiftoffStatus.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.liftoff.InstanceSetupStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.liftoff.InstanceSetupStatus} value
 * @return {!proto.loupe.common.liftoff.LiftoffStatus} returns this
 */
proto.loupe.common.liftoff.LiftoffStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string status_message = 2;
 * @return {string}
 */
proto.loupe.common.liftoff.LiftoffStatus.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.liftoff.LiftoffStatus} returns this
 */
proto.loupe.common.liftoff.LiftoffStatus.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LiftoffStatusProgress progress = 3;
 * @return {?proto.loupe.common.liftoff.LiftoffStatusProgress}
 */
proto.loupe.common.liftoff.LiftoffStatus.prototype.getProgress = function() {
  return /** @type{?proto.loupe.common.liftoff.LiftoffStatusProgress} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.liftoff.LiftoffStatusProgress, 3));
};


/**
 * @param {?proto.loupe.common.liftoff.LiftoffStatusProgress|undefined} value
 * @return {!proto.loupe.common.liftoff.LiftoffStatus} returns this
*/
proto.loupe.common.liftoff.LiftoffStatus.prototype.setProgress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.liftoff.LiftoffStatus} returns this
 */
proto.loupe.common.liftoff.LiftoffStatus.prototype.clearProgress = function() {
  return this.setProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.liftoff.LiftoffStatus.prototype.hasProgress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated LiftoffOnboardingStep onboarding = 4;
 * @return {!Array<!proto.loupe.common.liftoff.LiftoffOnboardingStep>}
 */
proto.loupe.common.liftoff.LiftoffStatus.prototype.getOnboardingList = function() {
  return /** @type{!Array<!proto.loupe.common.liftoff.LiftoffOnboardingStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.liftoff.LiftoffOnboardingStep, 4));
};


/**
 * @param {!Array<!proto.loupe.common.liftoff.LiftoffOnboardingStep>} value
 * @return {!proto.loupe.common.liftoff.LiftoffStatus} returns this
*/
proto.loupe.common.liftoff.LiftoffStatus.prototype.setOnboardingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.liftoff.LiftoffOnboardingStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.liftoff.LiftoffOnboardingStep}
 */
proto.loupe.common.liftoff.LiftoffStatus.prototype.addOnboarding = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.liftoff.LiftoffOnboardingStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.liftoff.LiftoffStatus} returns this
 */
proto.loupe.common.liftoff.LiftoffStatus.prototype.clearOnboardingList = function() {
  return this.setOnboardingList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.liftoff.LiftoffStatusProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.liftoff.LiftoffStatusProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0),
    complete: jspb.Message.getFieldWithDefault(msg, 4, 0),
    subProgress: (f = msg.getSubProgress()) && proto.loupe.common.liftoff.LiftoffStatusProgress.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.liftoff.LiftoffStatusProgress}
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.liftoff.LiftoffStatusProgress;
  return proto.loupe.common.liftoff.LiftoffStatusProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.liftoff.LiftoffStatusProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.liftoff.LiftoffStatusProgress}
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setComplete(value);
      break;
    case 5:
      var value = new proto.loupe.common.liftoff.LiftoffStatusProgress;
      reader.readMessage(value,proto.loupe.common.liftoff.LiftoffStatusProgress.deserializeBinaryFromReader);
      msg.setSubProgress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.liftoff.LiftoffStatusProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.liftoff.LiftoffStatusProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getComplete();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSubProgress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.loupe.common.liftoff.LiftoffStatusProgress.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.liftoff.LiftoffStatusProgress} returns this
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.liftoff.LiftoffStatusProgress} returns this
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.liftoff.LiftoffStatusProgress} returns this
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 complete = 4;
 * @return {number}
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.getComplete = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.liftoff.LiftoffStatusProgress} returns this
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.setComplete = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional LiftoffStatusProgress sub_progress = 5;
 * @return {?proto.loupe.common.liftoff.LiftoffStatusProgress}
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.getSubProgress = function() {
  return /** @type{?proto.loupe.common.liftoff.LiftoffStatusProgress} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.liftoff.LiftoffStatusProgress, 5));
};


/**
 * @param {?proto.loupe.common.liftoff.LiftoffStatusProgress|undefined} value
 * @return {!proto.loupe.common.liftoff.LiftoffStatusProgress} returns this
*/
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.setSubProgress = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.liftoff.LiftoffStatusProgress} returns this
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.clearSubProgress = function() {
  return this.setSubProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.liftoff.LiftoffStatusProgress.prototype.hasSubProgress = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.liftoff.LiftoffOnboardingStep.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.liftoff.LiftoffOnboardingStep} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.toObject = function(includeInstance, msg) {
  var f, obj = {
    step: jspb.Message.getFieldWithDefault(msg, 1, 0),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.liftoff.LiftoffOnboardingStep}
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.liftoff.LiftoffOnboardingStep;
  return proto.loupe.common.liftoff.LiftoffOnboardingStep.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.liftoff.LiftoffOnboardingStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.liftoff.LiftoffOnboardingStep}
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.liftoff.OnboardingStep} */ (reader.readEnum());
      msg.setStep(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.liftoff.LiftoffOnboardingStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.liftoff.LiftoffOnboardingStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStep();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional OnboardingStep step = 1;
 * @return {!proto.loupe.common.liftoff.OnboardingStep}
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.prototype.getStep = function() {
  return /** @type {!proto.loupe.common.liftoff.OnboardingStep} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.liftoff.OnboardingStep} value
 * @return {!proto.loupe.common.liftoff.LiftoffOnboardingStep} returns this
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.prototype.setStep = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.liftoff.LiftoffOnboardingStep} returns this
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.liftoff.LiftoffOnboardingStep} returns this
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool completed = 4;
 * @return {boolean}
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.liftoff.LiftoffOnboardingStep} returns this
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 order = 5;
 * @return {number}
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.liftoff.LiftoffOnboardingStep} returns this
 */
proto.loupe.common.liftoff.LiftoffOnboardingStep.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.liftoff.InstanceSetupStatus = {
  INSTANCE_SETUP_STATUS_UNSPECIFIED: 0,
  INSTANCE_SETUP_STATUS_PENDING: 1,
  INSTANCE_SETUP_STATUS_INITIALIZING: 2,
  INSTANCE_SETUP_STATUS_READY: 3,
  INSTANCE_SETUP_STATUS_ERRORED: 4,
  INSTANCE_SETUP_STATUS_ADMIN_READY: 5
};

/**
 * @enum {number}
 */
proto.loupe.common.liftoff.PrelaunchStepStatus = {
  PRELAUNCH_STEP_STATUS_UNSPECIFIED: 0,
  PRELAUNCH_STEP_STATUS_PENDING: 1,
  PRELAUNCH_STEP_STATUS_COMPLETE: 2,
  PRELAUNCH_STEP_STATUS_ERROR: 3
};

/**
 * @enum {number}
 */
proto.loupe.common.liftoff.PrelaunchStep = {
  PRELAUNCH_STEP_UNSPECIFIED: 0,
  PRELAUNCH_STEP_INIT_MAPPINGS: 1,
  PRELAUNCH_STEP_INGEST: 2,
  PRELAUNCH_STEP_NORMALIZE: 3,
  PRELAUNCH_STEP_SYNC_USERS: 4,
  PRELAUNCH_STEP_INIT_BLUEPRINTS: 5,
  PRELAUNCH_STEP_ACTIVATE_FEATURES: 6,
  PRELAUNCH_STEP_AUTH_CRM: 7,
  PRELAUNCH_STEP_RUN_ETL: 8,
  PRELAUNCH_STEP_FORECAST_TABLES: 9,
  PRELAUNCH_STEP_GEYSER_TABLES: 10
};

/**
 * @enum {number}
 */
proto.loupe.common.liftoff.OnboardingStep = {
  ONBOARDING_STEP_UNSPECIFIED: 0,
  ONBOARDING_STEP_DATA_SYNC: 1,
  ONBOARDING_STEP_TEAMS: 2,
  ONBOARDING_STEP_USERS: 3,
  ONBOARDING_STEP_ROLES: 4,
  ONBOARDING_STEP_GOALS: 5,
  ONBOARDING_STEP_SANDBOXES: 6,
  ONBOARDING_STEP_FORECASTS: 7
};

goog.object.extend(exports, proto.loupe.common.liftoff);
