import React from 'react'
import classNames from 'classnames'
import { find } from 'lodash'
import { useDragAndDrop } from '../dragAndDrop/hooks'
import Delete from '../icons/delete'
import DragHandle from '../icons/dragHandle'

const FieldListItem = (props) => {
  const {
    field,
    index,
    onMove,
    onDelete,
  } = props

  const { ref, handlerId, isDragging, dragLayer } = useDragAndDrop({
    key: field.value,
    index,
    type: 'FieldListItem',
    onMove,
    horizontal: false,
  })

  return (
    <div ref={ref} data-handler-id={handlerId}>
      <div className={classNames('group flex items-center justify-between mt-3 px-3 py-2 border rounded',
        { 'hover:bg-color-51636a-05': !dragLayer.isDragging },
        { 'border-color-eaeaea bg-color-eaeaea': isDragging },
        { 'border-color-d8d8d8': !isDragging })}>
        <div className="text-size-16px text-color-51636a font-weight-400 truncate">
          {isDragging ? '\u00A0' : field.label}
        </div>
        {!dragLayer.isDragging && (
          <div className="flex items-center">
            <div
              onClick={onDelete}
              className="mx-2 cursor-pointer invisible group-hover:visible focus:outline-none ">
              <Delete fill="#c9ced0" />
            </div>
            <div className="cursor-pointer">
              <DragHandle fill="#c9ced0" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export function dragRenderer({ data = [], isDragging, item, itemType, clientOffset }) {
  const obj = find(data, (d) => d.value === item.id)
  if (itemType === 'FieldListItem' && obj && clientOffset) {
    const { label } = obj
    const { x, y } = clientOffset
    const { size } = item || {}
    const { width = 0, height = 0 } = size
    return (
      <div style={{ width, height, transform: `translate(${x}px, ${y}px)` }}>
        <div className="group bg-color-ffffff flex items-center justify-between mt-3 px-3 py-2 border border-color-d8d8d8 rounded hover:bg-color-51636a-05">
          <div className="text-size-16px text-color-51636a font-weight-400 truncate">
            {label}
          </div>
          <div className="cursor-pointer"><DragHandle fill="#c9ced0" /></div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default FieldListItem
