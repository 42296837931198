import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '../common/modal'
import ModalHeader from '../common/modalHeader'
import ModalError from '../common/modalError'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import Button from '../common/button'
import { useTenantInfo } from '../../context/tenantInfo'
import { addDays, addYears, endOfDay, format, fromUnixTime, getUnixTime, parse, } from 'date-fns'
import { useAuth } from '../../context/auth'
import { downloadFile } from '../../lib/gCloudStorage'
import { useGrpcCallback } from '../../grpc'
import { toGenerateSpreadsheetRequest } from '../../grpc/converters'
import RefreshDownloadCheck from '../icons/refreshDownloadCheck'
import SelectList from '../common/selectList'

const dateRangeOptions = [
  { label: '-- Select a Date Range --', value: 0 },
  { label: 'This Fiscal Year', value: 1 },
  { label: 'Last Fiscal Year', value: 2 },
  { label: 'Next Fiscal Year', value: 3 },
]

const DownloadGoalsModal = (props) => {
  const {
    modal,
    ...rest
  } = props

  const { open, handleClose } = modal

  const { tenantId } = useAuth()

  const { tenantInfo } = useTenantInfo()

  const [showError, setShowError] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [generating, setGenerating] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [selectedDateRange, setSelectedDateRange] = useState(0)

  useEffect(() => {
    if (open) {
      setSelectedDateRange(0)
    }
  }, [open])

  const onChange = useCallback((option) => {
    if (option) {
      setSelectedDateRange(option.value)
    }
  }, [])

  const handleCloseInternal = useCallback(() => {
    setShowError(false)
    setShowLoader(false)
    setGenerating(false)
    setDownloading(false)
    setComplete(false)
    modal.setData(undefined)
    handleClose()
  }, [modal])

  const onError = useCallback(() => {
    setShowError(true)
    setShowLoader(false)
    setGenerating(false)
    setDownloading(false)
    setComplete(false)
  }, [])

  const generateGoalSpreadsheet = useGrpcCallback({
    onError,
    onSuccess: (obj, data) => {
      const { signedUrl } = obj
      if (signedUrl) {
        setDownloading(true)
        downloadFile({
          url: signedUrl,
          contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileName: `${data.name}.xlsx`,
          onError,
          onSuccess: () => {
            setComplete(true)
          },
        })
      }
    },
    onFetch: () => setGenerating(true),
    grpcMethod: 'generateGoalSpreadsheet',
    debug: false,
  }, [tenantId])

  const onDownload = useCallback(() => {
    const { currentFiscalStart } = tenantInfo
    let startDate
    let endDate
    if (currentFiscalStart.seconds) {
      const fiscalStart = fromUnixTime(currentFiscalStart.seconds)
      // manipulate the dates as ISO formatted dates
      const fiscalStartISO = fiscalStart.toISOString().split('T')[0]
      const fiscalStartISODate = parse(fiscalStartISO, 'yyyy-MM-dd', new Date())
      if (selectedDateRange === 1) {
        startDate = format(fiscalStartISODate, 'yyyy-MM-dd')
        endDate = format(addDays(addYears(fiscalStartISODate, 1), -1), 'yyyy-MM-dd')
      } else if (selectedDateRange === 2) {
        startDate = format(addYears(fiscalStartISODate, -1), 'yyyy-MM-dd')
        endDate = format(addDays(addYears(fiscalStartISODate, 0), -1), 'yyyy-MM-dd')
      } else if (selectedDateRange === 3) {
        startDate = format(addYears(fiscalStartISODate, 1), 'yyyy-MM-dd')
        endDate = format(addDays(addYears(fiscalStartISODate, 2), -1), 'yyyy-MM-dd')
      }
    }
    if (startDate && endDate) {
      // because we are parsing here, these dates will have the timezone offset
      const s = parse(startDate, 'yyyy-MM-dd', new Date())
      const e = endOfDay(parse(endDate, 'yyyy-MM-dd', new Date()))
      setShowError(false)
      setShowLoader(true)
      const goalCategory = modal.data
      const request = toGenerateSpreadsheetRequest({
        tenantId,
        key: goalCategory.key,
        startDate: {
          // remove the timezone offset when generating the unix time
          seconds: getUnixTime(s) - (s.getTimezoneOffset() * 60),
          nanos: 0,
        },
        endDate: {
          // remove the timezone offset when generating the unix time
          seconds: getUnixTime(e) - (e.getTimezoneOffset() * 60),
          nanos: 0,
        },
      })
      generateGoalSpreadsheet(request, { name: goalCategory.name })
    }
  }, [tenantId, generateGoalSpreadsheet, selectedDateRange, modal.data, tenantInfo])

  const title = useMemo(() => {
    return modal.data ? `Download ${modal.data.name}` : 'Download'
  }, [modal.data])

  const loadingText = useMemo(() => {
    if (generating && downloading && !complete) {
      return {
        title: 'Downloading',
        subTitle: <span>Generating and downloading your goals file. Just a moment.</span>,
      }
    } else if (generating && downloading && complete) {
      return {
        title: 'Complete',
        subTitle: <span>Your goals file has been successfully generated and downloaded.</span>,
      }
    } else {
      return {
        title: 'Generating',
        subTitle: <span>We are generating your goals file. Just a moment.</span>,
      }
    }
  }, [generating, downloading, complete])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="sm"
      open={open}
      {...rest}>

      <ModalHeader
        title={title}
        onClose={handleCloseInternal} />

      <ModalBody>

        {showError && <ModalError text="Failed to download goals, please try again." />}

        {showLoader
          ? (
            <div className="flex justify-center w-full h-full my-10">
              <div className="text-center">
                <div className="flex justify-center my-3">
                  <RefreshDownloadCheck
                    refresh={generating}
                    download={downloading}
                    check={complete} />
                </div>
                <div className="text-size-24px text-color-151d49 opacity-50 font-weight-600">{loadingText.title}</div>
                <div className="text-size-14px text-color-51636a font-weight-400 leading-none">{loadingText.subTitle}</div>
              </div>
            </div>
          )
          : (
            <div className="w-full h-full px-10 pt-10">
              <div className="text-size-16px text-color-51636a font-weight-400 leading-tight mb-4">Select the period of time for which you'd like to update your teams' goals.</div>
              <div className="text-size-16px text-color-51636a font-weight-500 leading-loose">Date Range</div>
              <SelectList
                value={selectedDateRange}
                onChange={onChange}
                options={dateRangeOptions} />
            </div>
          )}

      </ModalBody>

      <ModalFooter>
        {!showLoader
          ? (
            <Button
              size="xs"
              text="Download"
              onClick={onDownload}
              disabled={selectedDateRange === 0 || showLoader} />
          )
          : (
            <div />
          )}
      </ModalFooter>

    </Modal>
  )
}

export default DownloadGoalsModal
