import React, { useRef, useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '../common/modal'
import ModalHeader from '../common/modalHeader'
import ModalError from '../common/modalError'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import Button from '../common/button'
import { useGrpcCallback } from '../../grpc'
import { SandboxType } from '../../grpc/enums'
import { useSandboxes } from '../../context/sandboxes'
import { getTokenSilently, getIdTokenClaims } from '../../lib/auth0'
import LoadingState from '../common/loadingState'
import SelectList from '../common/selectList'
import { toCreateSandboxRequest, toUpdateSandboxRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import { useTenantInfo } from '../../context/tenantInfo'
import Label from '../common/label'
import { Popper } from '@material-ui/core'
import Checkbox from '../common/checkbox'
import classNames from 'classnames'

const AddSandboxModal = (props) => {
  const {
    modal
  } = props

  const { sandboxId = 0 } = modal?.data ?? {}

  const { tenantId } = useAuth()
  const { sandboxes, invalidate } = useSandboxes()
  const { isSalesforce } = useTenantInfo()

  const { open, handleClose } = modal

  const [showError, setShowError] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [sandboxName, setSandboxName] = useState('')
  const [description, setDescription] = useState('')
  const [editSandbox, setEditSandbox] = useState(null)
  const [isTestInstance, setIsTestInstance] = useState(false)

  const [selectedSandbox, setSelectedSandbox] = useState(SandboxType.SANDBOX_TYPE_UNSPECIFIED)
  const [validError, setValidError] = useState(null)

  const inputRef = useRef()

  useEffect(() => {
    if (open) {
      const toEdit = sandboxes.find(({ id }) => id === sandboxId)
      if (toEdit) {
        setSandboxName(toEdit?.name)
        setDescription(toEdit?.description)
        setSelectedSandbox(toEdit?.initialType)
        setEditSandbox(toEdit)
        setIsTestInstance(toEdit?.isTestInstance)
      }
    } else {
      setSandboxName('')
      setDescription('')
      setShowError(false)
      setEditSandbox(null)
      setSelectedSandbox(SandboxType.SANDBOX_TYPE_UNSPECIFIED)
    }
  }, [open, sandboxId, sandboxes])

  const sandboxOptions = useMemo(() => {
    return [
      {
        value: SandboxType.SANDBOX_TYPE_UNSPECIFIED,
        label: 'Select'
      },
      {
        value: SandboxType.SANDBOX_TYPE_EMPTY,
        label: 'Empty'
      },
      {
        value: SandboxType.SANDBOX_TYPE_CLONE,
        label: 'Production Clone'
      }
    ]
  }, [])

  const onChange = useCallback((option) => {
    if (option) {
      setSelectedSandbox(option.value)
    }
  }, [])

  const nameIsUnique = useCallback((_name) => {
    const sandboxNames = sandboxes.map(({ name }) => name.toLowerCase())
    return !sandboxNames.includes(_name?.toLowerCase?.())
  }, [sandboxes])

  const onChangeName = useCallback(({ target }) => {
    setSandboxName(target.value)
    if (target.value !== editSandbox?.name && !nameIsUnique(target.value)) {
      setValidError('Sandbox name already exists')
      return
    }
    setValidError(null)
  }, [nameIsUnique, editSandbox])

  const onChangeDescription = useCallback(({ target }) => {
    setDescription(target.value)
  }, [])

  const handleCloseInternal = useCallback(() => {
    setShowError(false)
    setValidError(null)
    modal.setData(undefined)
    handleClose()
  }, [modal])

  const saveDisabled = useMemo(() => {
    if (!editSandbox) {
      return selectedSandbox === SandboxType.SANDBOX_TYPE_UNSPECIFIED
      || !sandboxName
      || showLoader
      || !nameIsUnique(sandboxName)
    }

    return !sandboxName
      || showLoader
      || (sandboxName !== editSandbox?.name && !nameIsUnique(sandboxName))
  }, [selectedSandbox, sandboxName, showLoader, nameIsUnique, editSandbox])

  const createSandbox = useGrpcCallback({
    onError: (e) => {
      setShowError('Unable to create sandbox')
      setShowLoader(false)
    },
    onSuccess: () => {
      setShowLoader(false)
      getTokenSilently({
        options: {
          ignoreCache: true
        },
        onSuccess: getIdTokenClaims
      })
      handleCloseInternal()
      invalidate()
    },
    onFetch: () => {
      setShowLoader(true)
    },
    grpcMethod: 'createSandbox',
    debug: false,
  }, [handleCloseInternal, invalidate, getTokenSilently, getIdTokenClaims])

  const updateSandbox = useGrpcCallback({
    onError: (e) => {
      setShowError('Unable to update sandbox')
      setShowLoader(false)
    },
    onSuccess: () => {
      setShowLoader(false)
      handleCloseInternal()
      invalidate()
    },
    onFetch: () => {
      setShowLoader(true)
    },
    grpcMethod: 'updateSandbox',
    debug: false,
  }, [handleCloseInternal, invalidate])

  const onAdd = useCallback(() => {
    const request = toCreateSandboxRequest({
      tenantId,
      name: sandboxName,
      description,
      type: selectedSandbox,
      isTestInstance
    })
    createSandbox(request)
  }, [createSandbox, tenantId, sandboxName, description, selectedSandbox, isTestInstance])

  const onUpdate = useCallback(() => {
    const request = toUpdateSandboxRequest({
      tenantId,
      name: sandboxName,
      description,
      sandboxId,
    })
    updateSandbox(request)
  }, [updateSandbox, toUpdateSandboxRequest, tenantId, sandboxName, description, sandboxId])

  const title = useMemo(() => {
    return (editSandbox) ? 'Edit Sandbox' : 'Add Sandbox'
  }, [editSandbox])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="sm"
      open={open}>

      <ModalHeader
        title={title}
        onClose={handleCloseInternal} />

      <ModalBody>
        {showError && <ModalError text={showError} />}

        {showLoader
          ? (
            <div className="flex justify-center w-full h-full my-10">
              <div className="text-center">
                <div className="flex justify-center my-3">
                  <LoadingState
                    header="Creating Sandbox"
                    subHeader="Please wait..."
                    animate={true} />
                </div>
              </div>
            </div>
          )
          : (
            <div className="w-full h-full px-10 pt-10">

              <div className="flex items-center">
                <Label text="Sandbox Name" />
                <span className="text-color-fb6c6a pl-1">*</span>
              </div>
              <input
                autoFocus={true}
                ref={inputRef}
                className="border border-color-d6d9e6 rounded w-full p-2 mb-6"
                value={sandboxName}
                placeholder="Name"
                onChange={onChangeName} />
              <Popper
                style={{
                  zIndex: 10000
                }}
                className="bg-color-ffffff shadow-lg rounded"
                open={!!validError}
                anchorEl={inputRef.current}
                placement="bottom-start">
                <div className="p-4">{validError}</div>
              </Popper>

              <div className="flex items-center">
                <Label text="Type" />
                <span className="text-color-fb6c6a pl-1">*</span>
              </div>
              <div className="mb-4">
                <SelectList
                  disabled={!!editSandbox}
                  value={selectedSandbox}
                  onChange={onChange}
                  options={sandboxOptions} />
              </div>

              {isSalesforce && (
                <div className="mb-4">
                  <Checkbox
                    disabled={!!editSandbox}
                    checked={isTestInstance}
                    onChange={({ target }) => setIsTestInstance(target.checked)}
                    label="Use Salesforce sandbox"
                    labelProps={{ className: classNames('text-size-16px text-color-09242f font-weight-400', { 'text-color-c9ced0': editSandbox }) }} />
                </div>
              )}

              <div className="text-size-16px text-color-09242F font-weight-500 leading-loose">Description</div>
              <textarea
                className="border border-color-d6d9e6 rounded w-full p-2"
                value={description}
                onChange={onChangeDescription}
                placeholder="(optional)" />

            </div>
          )}

      </ModalBody>

      <ModalFooter>
        <Button
          size="xs"
          text="Save"
          onClick={() => {
            return editSandbox ? onUpdate() : onAdd()
          }}
          disabled={saveDisabled} />
      </ModalFooter>

    </Modal>
  )
}

export default AddSandboxModal
