import { forEach, has } from 'lodash'
import { protoFromObject } from './protoFromObject'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb.js'
import { BoolValue, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import {
  CheckPermissionsRequest,
  GetDataSyncIntervalRequest,
  GetGroupSyncStateRequest,
  IndividualFeaturePermissionCheck,
  ListIntegrationObjectPropertiesRequest,
  ListIntegrationObjectTypesRequest,
  UpdateSandboxRequest,
  DeleteSandboxRequest,
  ListSandboxesRequest,
  PermissionCheckObject,
  SetDataSyncIntervalRequest,
  GetPipelineSettingRequest,
  UpsertPipelineSettingRequest,
  GetReportingSettingRequest,
  UpsertReportingSettingRequest,
  GetReportingOptionsSettingRequest,
  UpsertReportingOptionsSettingRequest,
  GetScenarioPlannerSettingRequest,
  UpsertScenarioPlannerSettingRequest,
  GetForecastingSettingRequest,
  UpsertForecastingSettingRequest,
  CreatePersonWithGroupViewerRequest,
  UpdatePersonWithGroupViewerRequest,
  GetTenantLicenseInfoRequest,
  GetObjectMappingsSourceOptionsRequest,
  GetWritebackSettingRequest,
  UpsertWritebackSettingRequest,
  GetOutreachSettingRequest,
  UpsertOutreachSettingRequest,
  GetHierarchySettingRequest,
  UpsertHierarchySettingRequest,
  GetObjectReferenceFieldOptionsRequest,
  ResolveObjectReferenceFieldOptionsContainer,
  ResolveObjectReferenceFieldOptionsRequest,
} from '../protos/src/services/api/api_pb'
import { GetTenantLiftoffStatusRequest, UpdateOnboardingStateRequest } from '../protos/src/services/liftoff/liftoff_pb'
import { CRMRole } from '../protos/src/common/orchard/crm_role_pb'
import { Group } from '../protos/src/common/orchard/group_pb'
import { Person } from '../protos/src/common/orchard/person_pb'
import {
  PermissionExtended,
  PermissionSetExtended
} from '../protos/src/common/auth/auth_pb'
import {
  SystemRole,
  SystemRoleExtended
} from '../protos/src/common/orchard/system_role_pb'
import {
  CreateSandboxRequest,
  CreateTenantRequest,
  GetTenantDataSyncHistoryRequest,
  GetTenantsRequest,
  GetTenantDataSyncStatusRequest,
  GetTenantInfoRequest,
  GetTenantByIdRequest,
  GetTenantObjectRequest,
  GetLinkedAccountAuthLinkRequest,
  TriggerDataSyncRequest,
  UpdateTenantRequest,
  UpdateTenantStatusRequest
} from '../protos/src/services/tenant/tenant_pb'
import { GetSettingsCommonRequest } from '../protos/src/services/setting/setting_pb'
import {
  GenerateSpreadsheetRequest,
  GetGoalCategoriesRequest,
  ProcessSpreadsheetRequest,
  UploadSpreadsheetRequest,
} from '../protos/src/services/goal-manager/goal-manager_pb'
import {
  CreateGroupRequest,
  CreatePersonRequest,
  GetCRMRolesRequest,
  GetGroupSubTreeRequest,
  GetSystemRolesRequest,
  GetUnsyncedCRMRolesRequest,
  IdRequest,
  IsHierarchySyncedRequest,
  PersonFilter,
  ResetHierarchyRequest,
  SearchPeopleRequest,
  SyncRequest,
  UpdateGroupRequest,
  UpdatePersonRequest,
  IdsRequest,
} from '../protos/src/services/orchard/orchard_pb'
import {
  DeleteCanonicalObjectFieldRequest,
  GetCanonicalObjectRequest,
  GetCanonicalObjectMappingRequest,
  ListCanonicalObjectMappingsRequest,
  ListCanonicalObjectFieldTypesRequest,
  UpsertCanonicalObjectMappingRequest,
  UpsertCanonicalObjectFieldRequest,
  ValidateCanonicalObjectFieldMappingRequest,
} from '../protos/src/services/mapping/mapping_pb'
import {
  CanonicalObjectMapping,
  CanonicalObjectField,
  CrmObject,
  FieldMapping,
  FieldMappingPermission,
  FieldOption,
  MappingStatusDetail,
} from '../protos/src/common/mapping/mapping_pb'
import { Pipeline } from '../protos/src/common/setting/app/feature/pipeline_pb'
import { Reporting } from '../protos/src/common/setting/app/feature/reporting_pb'
import { FilterFieldOption, ReportingOptionsSetting } from '../protos/src/common/setting/app/feature/reporting_options_pb'
import { ScenarioPlanner } from '../protos/src/common/setting/app/feature/scenario_planner_pb'
import { OutreachSettings } from '../protos/src/common/setting/app/feature/outreach_settings_pb'
import { Hierarchy } from '../protos/src/common/setting/app/feature/hierarchy_pb'
import {
  CustomDataDefinition,
  ForecastCategory,
  Forecasting,
  TotalCall,
} from '../protos/src/common/setting/app/feature/forecasting/forecasting_pb'
import { BustAuthCacheRequest } from '../protos/src/services/bouncer/bouncer_pb'
import { ListOrgsRequest, ListOrgFilters, OrgBentoIdPair } from '../protos/src/services/tightbeam_global/tightbeam_global_pb'
import {
  AssignForecastConfigToPivotGroupsRequest,
  DuplicateForecastConfigRequest,
  ForecastConfig,
  ForecastConfigColumn,
  ForecastConfigCustomQuerySettings,
  ForecastConfigDisplayFormula,
  ForecastConfigPivotGroup,
  ForecastConfigValueSettings,
  ForecastConfigGoalSettings,
  ForecastConfigObjectSettings,
  GetForecastConfigRequest,
  ListForecastConfigColumnsRequest,
  ListForecastConfigsRequest,
  PublishForecastConfigDraftRequest,
  RemoveForecastConfigFromPivotGroupsRequest,
  ReorderForecastConfigColumnsContainer,
  ReorderForecastConfigColumnsRequest,
  ReorderForecastConfigsRequest,
  SaveForecastConfigColumnRequest,
  SaveForecastConfigRequest,
  SetForecastConfigStatusRequest
} from '../protos/src/services/forecast/v3/forecast_pb'
import {
  Actor
} from '../protos/src/common/actor_pb'
import {
  SourceOptions,
  FieldOptions,
  LabelValue,
  QueryParameters,
  QueryParameter,
  ParameterOptions,
  FilterParameter,
  FilterWrapper,
  Filter,
  TimeWindowParameter,
  TimeWindowRange,
  TimeWindowInterval,
  OrderParameter,
  Order,
  GroupParameter,
  Group as FilterGroup,
  ValueParameter,
  ResolvedQueryParameter,
  Query as RQuery,
  FieldDefinition,
  QueryContract,
  QueryDependencies,
  UserQueryParameters
} from '../protos/src/common/realtime/query_pb'

export const toTimestamp = (obj) => {
  const newObj = new Timestamp()
  has(obj, 'seconds') && newObj.setSeconds(obj.seconds)
  has(obj, 'nanos') && newObj.setNanos(obj.nanos)
  return newObj
}

export const toGetGroupSubTreeRequest = (obj) => {
  const newObj = protoFromObject(GetGroupSubTreeRequest, obj)
  return newObj
}

export const toGroup = (obj) => {
  const newObj = protoFromObject(Group, obj)
  has(obj, 'createdAt') && newObj.setCreatedAt(toTimestamp(obj.createdAt))
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  return newObj
}

export const toCreateGroupRequest = (obj) => {
  const newObj = protoFromObject(CreateGroupRequest, obj)
  has(obj, 'group') && newObj.setGroup(toGroup(obj.group))
  return newObj
}

export const toUpdateGroupRequest = (obj) => {
  const newObj = protoFromObject(UpdateGroupRequest, obj)
  has(obj, 'group') && newObj.setGroup(toGroup(obj.group))
  return newObj
}

export const toIdRequest = (obj) => {
  const newObj = protoFromObject(IdRequest, obj)
  return newObj
}

export const toGetCRMRolesRequest = (obj) => {
  const newObj = protoFromObject(GetCRMRolesRequest, obj)
  return newObj
}

export const toGetUnsyncedCRMRolesRequest = (obj) => {
  const newObj = protoFromObject(GetUnsyncedCRMRolesRequest, obj)
  return newObj
}

export const toPersonFilter = (obj) => {
  const newObj = protoFromObject(PersonFilter, obj)
  return newObj
}

export const toSearchPeopleRequest = (obj) => {
  const newObj = protoFromObject(SearchPeopleRequest, obj)
  has(obj, 'filtersList') && newObj.setFiltersList(obj.filtersList.map((f) => toPersonFilter(f)))
  return newObj
}

export const toSystemRole = (obj) => {
  const newObj = protoFromObject(SystemRole, obj)
  has(obj, 'createdAt') && newObj.setCreatedAt(toTimestamp(obj.createdAt))
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  return newObj
}

export const toPermissionExtended = (obj) => {
  const newObj = protoFromObject(PermissionExtended, obj)
  return newObj
}

export const toPermissionSetExtended = (obj) => {
  const newObj = protoFromObject(PermissionSetExtended, obj)
  has(obj, 'permissionsList') && newObj.setPermissionsList(obj.permissionsList.map((p) => toPermissionExtended(p)))
  return newObj
}

export const toSystemRoleExtended = (obj) => {
  const newObj = protoFromObject(SystemRoleExtended, obj)
  has(obj, 'role') && newObj.setRole(toSystemRole(obj.role))
  has(obj, 'extendedPermissions') && newObj.setExtendedPermissionsList(obj.extendedPermissions.map((p) => toPermissionSetExtended(p)))
  return newObj
}

export const toCRMRole = (obj) => {
  const newObj = protoFromObject(CRMRole, obj)
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  return newObj
}

export const toPerson = (obj) => {
  const newObj = protoFromObject(Person, obj)
  has(obj, 'rolesList') && newObj.setRolesList(obj.rolesList.map((r) => toSystemRole(r)))
  has(obj, 'crmRolesList') && newObj.setCrmRolesList(obj.crmRolesList.map((r) => toCRMRole(r)))
  has(obj, 'createdAt') && newObj.setCreatedAt(toTimestamp(obj.createdAt))
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  return newObj
}

export const toUpdatePersonRequest = (obj) => {
  const newObj = protoFromObject(UpdatePersonRequest, obj)
  has(obj, 'person') && newObj.setPerson(toPerson(obj.person))
  return newObj
}

export const toCreatePersonWithGroupViewerRequest = (obj) => {
  const newObj = protoFromObject(CreatePersonWithGroupViewerRequest, obj)
  has(obj, 'person') && newObj.setPerson(toPerson(obj.person))
  return newObj
}

export const toUpdatePersonWithGroupViewerRequest = (obj) => {
  const newObj = protoFromObject(UpdatePersonWithGroupViewerRequest, obj)
  has(obj, 'person') && newObj.setPerson(toPerson(obj.person))
  return newObj
}

export const toGetSystemRolesRequest = (obj) => {
  const newObj = protoFromObject(GetSystemRolesRequest, obj)
  return newObj
}

export const toCreatePersonRequest = (obj) => {
  const newObj = protoFromObject(CreatePersonRequest, obj)
  has(obj, 'person') && newObj.setPerson(toPerson(obj.person))
  return newObj
}

export const toIsHierarchySyncedRequest = (obj) => {
  const newObj = protoFromObject(IsHierarchySyncedRequest, obj)
  return newObj
}

export const toGetGoalCategoriesRequest = (obj) => {
  const newObj = protoFromObject(GetGoalCategoriesRequest, obj)
  return newObj
}

export const toGenerateSpreadsheetRequest = (obj) => {
  const newObj = protoFromObject(GenerateSpreadsheetRequest, obj)
  has(obj, 'startDate') && newObj.setStartDate(toTimestamp(obj.startDate))
  has(obj, 'endDate') && newObj.setEndDate(toTimestamp(obj.endDate))
  return newObj
}

export const toResetHierarchyRequest = (obj) => {
  const newObj = protoFromObject(ResetHierarchyRequest, obj)
  return newObj
}

export const toSyncRequest = (obj) => {
  const newObj = protoFromObject(SyncRequest, obj)
  has(obj, 'syncSince') && newObj.setSyncSince(toTimestamp(obj.syncSince))
  return newObj
}

export const toUploadSpreadsheetRequest = (obj) => {
  const newObj = protoFromObject(UploadSpreadsheetRequest, obj)
  return newObj
}

export const toProcessSpreadsheetRequest = (obj) => {
  const newObj = protoFromObject(ProcessSpreadsheetRequest, obj)
  return newObj
}

export const toIndividualFeaturePermissionCheck = (obj) => {
  const newObj = protoFromObject(IndividualFeaturePermissionCheck, obj)
  return newObj
}

export const toPermissionCheckObject = (obj) => {
  const newObj = protoFromObject(PermissionCheckObject, obj)
  has(obj, 'checksList') && newObj.setChecksList(obj.checksList.map((c) => toIndividualFeaturePermissionCheck(c)))
  return newObj
}

export const toCheckPermissionsRequest = (obj) => {
  const newObj = protoFromObject(CheckPermissionsRequest, obj)
  if (has(obj, 'valsMap')) {
    const valsMap = newObj.getValsMap()
    forEach(obj.valsMap, (v) => {
      if (v.length > 1) {
        valsMap.set(v[0], toPermissionCheckObject(v[1]))
      }
    })
  }
  return newObj
}

export const toGetTenantsRequest = (obj) => {
  const newObj = protoFromObject(GetTenantsRequest, obj)
  return newObj
}

export const toGetTenantInfoRequest = (obj) => {
  const newObj = protoFromObject(GetTenantInfoRequest, obj)
  return newObj
}

export const toGetTenantLicenseInfoRequest = (obj) => {
  const newObj = protoFromObject(GetTenantLicenseInfoRequest, obj)
  return newObj
}

export const toGetTenantByIdRequest = (obj) => {
  const newObj = protoFromObject(GetTenantByIdRequest, obj)
  return newObj
}

export const toGetGroupSyncStateRequest = (obj) => {
  const newObj = protoFromObject(GetGroupSyncStateRequest, obj)
  return newObj
}

export const toListCanonicalObjectMappingsRequest = (obj) => {
  const newObj = protoFromObject(ListCanonicalObjectMappingsRequest, obj)
  return newObj
}

export const toGetTenantDataSyncStatusRequest = (obj) => {
  const newObj = protoFromObject(GetTenantDataSyncStatusRequest, obj)
  return newObj
}

export const toUpdateTenantStatusRequest = (obj) => {
  const newObj = protoFromObject(UpdateTenantStatusRequest, obj)
  return newObj
}

export const toTriggerDataSyncRequest = (obj) => {
  const newObj = protoFromObject(TriggerDataSyncRequest, obj)
  return newObj
}

export const toGetTenantDataSyncHistoryRequest = (obj) => {
  const newObj = protoFromObject(GetTenantDataSyncHistoryRequest, obj)
  return newObj
}

export const toGetDataSyncIntervalRequest = (obj) => {
  const newObj = protoFromObject(GetDataSyncIntervalRequest, obj)
  return newObj
}

export const toSetDataSyncIntervalRequest = (obj) => {
  const newObj = protoFromObject(SetDataSyncIntervalRequest, obj)
  return newObj
}

export const toGetCanonicalObjectRequest = (obj) => {
  const newObj = protoFromObject(GetCanonicalObjectRequest, obj)
  return newObj
}

export const toGetCanonicalObjectMappingRequest = (obj) => {
  const newObj = protoFromObject(GetCanonicalObjectMappingRequest, obj)
  return newObj
}

export const toListIntegrationObjectTypesRequest = (obj) => {
  const newObj = protoFromObject(ListIntegrationObjectTypesRequest, obj)
  return newObj
}

export const toListIntegrationObjectPropertiesRequest = (obj) => {
  const newObj = protoFromObject(ListIntegrationObjectPropertiesRequest, obj)
  return newObj
}

export const toFieldOption = (obj) => {
  const newObj = protoFromObject(FieldOption, obj)
  return newObj
}

export const toFieldMappingPermission = (obj) => {
  const newObj = protoFromObject(FieldMappingPermission, obj)
  return newObj
}

export const toFieldMapping = (obj) => {
  const newObj = protoFromObject(FieldMapping, obj)
  has(obj, 'permissions') && newObj.setPermissions(toFieldMappingPermission(obj.permissions))
  has(obj, 'optionsList') && newObj.setOptionsList(obj.optionsList.map((o) => toFieldOption(o)))
  return newObj
}

export const toCrmObject = (obj) => {
  const newObj = protoFromObject(CrmObject, obj)
  has(obj, 'fieldsList') && newObj.setFieldsList(obj.fieldsList.map((o) => toFieldMapping(o)))
  return newObj
}

export const toMappingStatusDetail = (obj) => {
  const newObj = protoFromObject(MappingStatusDetail, obj)
  return newObj
}

export const toCanonicalObjectMapping = (obj) => {
  const newObj = protoFromObject(CanonicalObjectMapping, obj)
  has(obj, 'statusDetails') && newObj.setStatusDetails(toMappingStatusDetail(obj.statusDetails))
  has(obj, 'createdAt') && newObj.setCreatedAt(toTimestamp(obj.createdAt))
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  has(obj, 'crmObjectsList') && newObj.setCrmObjectsList(obj.crmObjectsList.map((o) => toCrmObject(o)))
  return newObj
}

export const toUpsertCanonicalObjectMappingRequest = (obj) => {
  const newObj = protoFromObject(UpsertCanonicalObjectMappingRequest, obj)
  has(obj, 'objectMap') && newObj.setObjectMap(toCanonicalObjectMapping(obj.objectMap))
  return newObj
}

export const toValidateCanonicalObjectFieldMappingRequest = (obj) => {
  const newObj = protoFromObject(ValidateCanonicalObjectFieldMappingRequest, obj)
  has(obj, 'fieldMap') && newObj.setFieldMap(toFieldMapping(obj.fieldMap))
  return newObj
}

export const toListCanonicalObjectFieldTypesRequest = (obj) => {
  const newObj = protoFromObject(ListCanonicalObjectFieldTypesRequest, obj)
  return newObj
}

export const toCanonicalObjectField = (obj) => {
  const newObj = protoFromObject(CanonicalObjectField, obj)
  has(obj, 'createdAt') && newObj.setCreatedAt(toTimestamp(obj.createdAt))
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  return newObj
}

export const toUpsertCanonicalObjectFieldRequest = (obj) => {
  const newObj = protoFromObject(UpsertCanonicalObjectFieldRequest, obj)
  has(obj, 'field') && newObj.setField(toCanonicalObjectField(obj.field))
  return newObj
}

export const toDeleteCanonicalObjectFieldRequest = (obj) => {
  const newObj = protoFromObject(DeleteCanonicalObjectFieldRequest, obj)
  return newObj
}

export const toOrgBentoIdPair = (obj) => {
  const newObj = protoFromObject(OrgBentoIdPair, obj)
  return newObj
}

export const toCreateTenantRequest = (obj) => {
  const newObj = protoFromObject(CreateTenantRequest, obj)
  has(obj, 'outreachOrg') && newObj.setOutreachOrg(toOrgBentoIdPair(obj.outreachOrg))
  return newObj
}

export const toUpdateTenantRequest = (obj) => {
  const newObj = protoFromObject(UpdateTenantRequest, obj)
  has(obj, 'outreachOrg') && newObj.setOutreachOrg(toOrgBentoIdPair(obj.outreachOrg))
  return newObj
}

export const toGetLinkedAccountAuthLinkRequest = (obj) => {
  const newObj = protoFromObject(GetLinkedAccountAuthLinkRequest, obj)
  return newObj
}

export const toGetPipelineSettingRequest = (obj) => {
  const newObj = protoFromObject(GetPipelineSettingRequest, obj)
  return newObj
}

export const toPipeline = (obj) => {
  const newObj = protoFromObject(Pipeline, obj)
  return newObj
}

export const toUpsertPipelineSettingRequest = (obj) => {
  const newObj = protoFromObject(UpsertPipelineSettingRequest, obj)
  has(obj, 'setting') && newObj.setSetting(toPipeline(obj.setting))
  return newObj
}

export const toGetTenantObjectRequest = (obj) => {
  const newObj = protoFromObject(GetTenantObjectRequest, obj)
  return newObj
}

export const toGetReportingSettingRequest = (obj) => {
  const newObj = protoFromObject(GetReportingSettingRequest, obj)
  return newObj
}

export const toReporting = (obj) => {
  const newObj = protoFromObject(Reporting, obj)
  return newObj
}

export const toUpsertReportingSettingRequest = (obj) => {
  const newObj = protoFromObject(UpsertReportingSettingRequest, obj)
  has(obj, 'setting') && newObj.setSetting(toReporting(obj.setting))
  return newObj
}

export const toGetReportingOptionsSettingRequest = (obj) => {
  const newObj = protoFromObject(GetReportingOptionsSettingRequest, obj)
  return newObj
}

export const toFilterFieldOption = (obj) => {
  const newObj = protoFromObject(FilterFieldOption, obj)
  return newObj
}

export const toReportingOptionsSetting = (obj) => {
  const newObj = protoFromObject(ReportingOptionsSetting, obj)
  has(obj, 'filterFieldOptionsList') && newObj.setFilterFieldOptionsList(obj.filterFieldOptionsList.map((f) => toFilterFieldOption(f)))
  return newObj
}

export const toUpsertReportingOptionsSettingRequest = (obj) => {
  const newObj = protoFromObject(UpsertReportingOptionsSettingRequest, obj)
  has(obj, 'setting') && newObj.setSetting(toReportingOptionsSetting(obj.setting))
  return newObj
}

export const toGetScenarioPlannerSettingRequest = (obj) => {
  const newObj = protoFromObject(GetScenarioPlannerSettingRequest, obj)
  return newObj
}

export const toScenarioPlanner = (obj) => {
  const newObj = protoFromObject(ScenarioPlanner, obj)
  return newObj
}

export const toUpsertScenarioPlannerSettingRequest = (obj) => {
  const newObj = protoFromObject(UpsertScenarioPlannerSettingRequest, obj)
  has(obj, 'setting') && newObj.setSetting(toScenarioPlanner(obj.setting))
  return newObj
}

export const toGetForecastingSettingRequest = (obj) => {
  const newObj = protoFromObject(GetForecastingSettingRequest, obj)
  return newObj
}

export const toTotalCall = (obj) => {
  const newObj = protoFromObject(TotalCall, obj)
  return newObj
}

export const toCustomDataDefinition = (obj) => {
  const newObj = protoFromObject(CustomDataDefinition, obj)
  return newObj
}

export const toForecastCategory = (obj) => {
  const newObj = protoFromObject(ForecastCategory, obj)
  has(obj, 'customDataDefinition') && newObj.setCustomDataDefinition(toCustomDataDefinition(obj.customDataDefinition))
  return newObj
}

export const toForecasting = (obj) => {
  const newObj = protoFromObject(Forecasting, obj)
  has(obj, 'totalCall') && newObj.setTotalCall(toTotalCall(obj.totalCall))
  has(obj, 'forecastCategoriesList') && newObj.setForecastCategoriesList(obj.forecastCategoriesList.map((f) => toForecastCategory(f)))
  return newObj
}

export const toUpsertForecastingSettingRequest = (obj) => {
  const newObj = protoFromObject(UpsertForecastingSettingRequest, obj)
  has(obj, 'setting') && newObj.setSetting(toForecasting(obj.setting))
  return newObj
}

export const toListSandboxesRequest = (obj) => {
  const newObj = protoFromObject(ListSandboxesRequest, obj)
  return newObj
}

export const toUpdateSandboxRequest = (obj) => {
  const newObj = protoFromObject(UpdateSandboxRequest, obj)
  return newObj
}

export const toDeleteSandboxeRequest = (obj) => {
  const newObj = protoFromObject(DeleteSandboxRequest, obj)
  return newObj
}

export const toCreateSandboxRequest = (obj) => {
  const newObj = protoFromObject(CreateSandboxRequest, obj)
  return newObj
}

export const toGetSettingsCommonRequest = (obj) => {
  const newObj = protoFromObject(GetSettingsCommonRequest, obj)
  return newObj
}

export const toGetTenantLiftoffStatusRequest = (obj) => {
  const newObj = protoFromObject(GetTenantLiftoffStatusRequest, obj)
  return newObj
}

export const toUpdateOnboardingStateRequest = (obj) => {
  const newObj = protoFromObject(UpdateOnboardingStateRequest, obj)
  return newObj
}

export const toBustAuthCacheRequest = (obj) => {
  const newObj = protoFromObject(BustAuthCacheRequest, obj)
  return newObj
}

export const toFilters = (obj) => {
  const newObj = new ListOrgFilters()
  has(obj, 'bento') && newObj.setBento(new StringValue(obj.bento))
  has(obj, 'state') && newObj.setState(new StringValue(obj.state))
  has(obj, 'isActive') && newObj.setIsActive(new BoolValue(obj.isActive))
  return newObj
}

export const toListOrgsRequest = (obj) => {
  const newObj = protoFromObject(ListOrgsRequest, obj)
  has(obj, 'filters') && newObj.setFilters(toFilters(obj.filters))
  return newObj
}

export const toTimeWindowRange = (obj) => {
  const newObj = protoFromObject(TimeWindowRange, obj)
  return newObj
}

export const toTimeWindowInterval = (obj) => {
  const newObj = protoFromObject(TimeWindowInterval, obj)
  return newObj
}

export const toTimeWindowParameter = (obj) => {
  const newObj = protoFromObject(TimeWindowParameter, obj)
  newObj.setRange(toTimeWindowRange(obj?.range ?? {}))
  newObj.setInterval(toTimeWindowInterval(obj?.interval ?? {}))
  return newObj
}

export const toOrder = (obj) => {
  const newObj = protoFromObject(Order, obj)
  return newObj
}

export const toOrderParameter = (obj) => {
  const newObj = protoFromObject(OrderParameter, obj)
  has(obj, 'ordersList') && newObj.setOrdersList(obj.ordersList.map((o) => toOrder(o)))
  return newObj
}

export const toFilterGroup = (obj) => {
  const newObj = protoFromObject(FilterGroup, obj)
  return newObj
}

export const toGroupParameter = (obj) => {
  const newObj = protoFromObject(GroupParameter, obj)
  has(obj, 'groupsList') && newObj.setGroupsList(obj.groupsList.map((g) => toFilterGroup(g)))
  return newObj
}

export const toValueParameter = (obj) => {
  const newObj = protoFromObject(ValueParameter, obj)
  return newObj
}

export const toFieldDefinition = (obj) => {
  const newObj = protoFromObject(FieldDefinition, obj)
  has(obj, 'nestedList') && newObj.setNestedList(obj.nestedList.map((f) => toFieldDefinition(f)))
  return newObj
}

export const toQueryContract = (obj) => {
  const newObj = protoFromObject(QueryContract, obj)
  has(obj, 'fieldsList') && newObj.setFieldsList(obj.fieldsList.map((f) => toFieldDefinition(f)))
  return newObj
}

export const toQueryDependencies = (obj) => {
  const newObj = protoFromObject(QueryDependencies, obj)
  if (has(obj, 'queryVersionsMap')) {
    const queryVersionsMap = newObj.getQueryVersionsMap()
    forEach(obj.queryVersionsMap, (m) => {
      if (m.length > 1) {
        queryVersionsMap.set(m[0], m[1])
      }
    })
  }
  return newObj
}

export const toLabelValue = (obj) => {
  const newObj = protoFromObject(LabelValue, obj)
  return newObj
}

export const toFieldOptions = (obj) => {
  const newObj = protoFromObject(FieldOptions, obj)
  has(obj, 'values') && newObj.setValuesList(obj.values.map((v) => toLabelValue(v)))
  return newObj
}

export const toSourceOptions = (obj) => {
  const newObj = protoFromObject(SourceOptions, obj)
  has(obj, 'fields') && newObj.setFieldsList(obj.fields.map((fo) => toFieldOptions(fo)))
  return newObj
}

export const toParameterOptions = (obj) => {
  const newObj = protoFromObject(ParameterOptions, obj)
  has(obj, 'sourcesList') && newObj.setSourcesList(obj.sourcesList.map((s) => toSourceOptions(s)))
  return newObj
}

export const toFilter = (obj) => {
  const newObj = protoFromObject(Filter, obj)
  return newObj
}

export const toFilterWrapper = (obj) => {
  const newObj = protoFromObject(FilterWrapper, obj)
  has(obj, 'filter') && obj.filter !== undefined && newObj.setFilter(toFilter(obj.filter))
  has(obj, 'nestedList') && newObj.setNestedList(obj.nestedList.map((f) => toFilterWrapper(f)))
  return newObj
}

export const toFilterParameter = (obj) => {
  if (obj === undefined) {
    return undefined
  }
  const newObj = protoFromObject(FilterParameter, obj)
  has(obj, 'filters') && newObj.setFilters(toFilterWrapper(obj.filters))
  return newObj
}

export const toQuery = (obj) => {
  const newObj = protoFromObject(RQuery, obj)
  has(obj, 'contract') && newObj.setContract(toQueryContract(obj.contract))
  has(obj, 'availableParameters') && newObj.setAvailableParameters(toQueryParameters(obj.availableParameters))
  has(obj, 'dependencies') && newObj.setDependencies(toQueryDependencies(obj.dependencies))
  if (has(obj, 'metadataMap')) {
    const metadataMap = newObj.getMetadataMap()
    forEach(obj.metadataMap, (m) => {
      if (m.length > 1) {
        metadataMap.set(m[0], m[1])
      }
    })
  }
  has(obj, 'createdAt') && newObj.setCreatedAt(toTimestamp(obj.createdAt))
  has(obj, 'updatedAt') && newObj.setUpdatedAt(toTimestamp(obj.updatedAt))
  return newObj
}

export const toResolvedQueryParameter = (obj) => {
  const newObj = protoFromObject(ResolvedQueryParameter, obj)
  has(obj, 'queriesList') && newObj.setQueriesList(obj.queriesList.map((q) => toQuery(q)))
  return newObj
}

export const toQueryParameter = (obj) => {
  const newObj = protoFromObject(QueryParameter, obj)
  has(obj, 'options') && newObj.setOptions(toParameterOptions(obj.options))
  has(obj, 'filter') && newObj.setFilter(toFilterParameter(obj.filter))
  has(obj, 'timeWindow') && newObj.setTimeWindow(toTimeWindowParameter(obj.timeWindow))
  has(obj, 'order') && newObj.setOrder(toOrderParameter(obj.order))
  has(obj, 'group') && newObj.setGroup(toGroupParameter(obj.group))
  has(obj, 'value') && newObj.setValue(toValueParameter(obj.value))
  has(obj, 'resolvedQuery') && newObj.setResolvedQuery(toResolvedQueryParameter(obj.resolvedQuery))
  return newObj
}

export const toQueryParameters = (obj) => {
  const newObj = protoFromObject(QueryParameters, obj)
  has(obj, 'parametersList') && newObj.setParametersList(obj.parametersList.map((p) => toQueryParameter(p)))
  return newObj
}

export const toActor = (obj) => {
  const newObj = protoFromObject(Actor, obj)
  has(obj, 'impersonator') && newObj.setImpersonator(toActor(obj.impersonator))
  return newObj
}

export const toForecastConfigGoalSettings = (obj) => {
  const newObj = protoFromObject(ForecastConfigGoalSettings, obj)
  return newObj
}

export const toForecastConfig = (obj) => {
  const newObj = protoFromObject(ForecastConfig, obj)
  has(obj, 'columnsList') && newObj.setColumnsList(obj.columnsList.map((p) => toForecastConfigColumn(p)))
  has(obj, 'objectSettings') && newObj.setObjectSettings(toForecastConfigObjectSettings(obj.objectSettings))
  has(obj, 'goalSettings') && newObj.setGoalSettings(toForecastConfigGoalSettings(obj.goalSettings))
  return newObj
}

export const toAssignForecastConfigToPivotGroupsRequest = (obj) => {
  const newObj = protoFromObject(AssignForecastConfigToPivotGroupsRequest, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  has(obj, 'groupsList') && newObj.setGroupsList(obj.groupsList.map((p) => toForecastConfigPivotGroup(p)))
  return newObj
}

export const toReorderForecastConfigColumnsContainer = (obj) => {
  const newObj = protoFromObject(ReorderForecastConfigColumnsContainer, obj)
  return newObj
}

export const toReorderForecastConfigColumnsRequest = (obj) => {
  const newObj = protoFromObject(ReorderForecastConfigColumnsRequest, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  has(obj, 'itemsList') && newObj.setItemsList(obj.itemsList.map(toReorderForecastConfigColumnsContainer))
  return newObj
}

export const toReorderForecastConfigsRequest = (obj) => {
  const newObj = protoFromObject(ReorderForecastConfigsRequest, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  return newObj
}

export const toRemoveForecastConfigFromPivotGroupsRequest = (obj) => {
  const newObj = protoFromObject(RemoveForecastConfigFromPivotGroupsRequest, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  return newObj
}

export const toSaveForecastConfigRequest = (obj) => {
  const newObj = protoFromObject(SaveForecastConfigRequest, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  has(obj, 'forecastConfig') && newObj.setForecastConfig(toForecastConfig(obj.forecastConfig))
  return newObj
}

export const toSetForecastConfigStatusRequest = (obj) => {
  const newObj = protoFromObject(SetForecastConfigStatusRequest, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  return newObj
}

export const toForecastConfigCustomQuerySettings = (obj) => {
  const newObj = protoFromObject(ForecastConfigCustomQuerySettings, obj)
  has(newObj, 'queryParameters') && newObj.setQueryParameters(toQueryParameters(newObj.queryParameters))
  return newObj
}

export const toForecastConfigValueSettings = (obj) => {
  const newObj = protoFromObject(ForecastConfigValueSettings, obj)
  has(obj, 'filter') && newObj.setFilter(toFilterParameter(obj.filter))
  has(obj, 'customQuerySettings') && newObj.setCustomQuerySettings(toForecastConfigCustomQuerySettings(obj.customQuerySettings))
  has(obj, 'objectValueSettings') && newObj.setObjectValueSettings(toForecastConfigObjectSettings(obj.objectValueSettings))
  return newObj
}

export const toForecastConfigObjectSettings = (obj) => {
  const newObj = protoFromObject(ForecastConfigObjectSettings, obj)
  return newObj
}

export const toForecastConfigColumn = (obj) => {
  const newObj = protoFromObject(ForecastConfigColumn, obj)
  has(obj, 'goalSettings') && newObj.setGoalSettings(toForecastConfigGoalSettings(obj.goalSettings))
  has(obj, 'forecastConfigValueSettings') && newObj.setForecastConfigValueSettings(toForecastConfigValueSettings(obj.forecastConfigValueSettings))
  has(obj, 'displayFormula') && newObj.setDisplayFormula(toForecastConfigDisplayFormula(obj.displayFormula))
  has(obj, 'forecastConfigValueSettings') && newObj.setForecastConfigValueSettings(toForecastConfigValueSettings(obj.forecastConfigValueSettings))
  return newObj
}

export const toForecastConfigDisplayFormula = (obj) => {
  const newObj = protoFromObject(ForecastConfigDisplayFormula, obj)
  if (has(obj, 'variableMappingMap')) {
    const varsMap = newObj.getVariableMappingMap()
    forEach(obj.variableMappingMap, (v) => {
      if (v.length > 1) {
        varsMap.set(v[0], v[1])
      }
    })
  }
  return newObj
}

export const toForecastConfigPivotGroup = (obj) => {
  const newObj = protoFromObject(ForecastConfigPivotGroup, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  return newObj
}

export const toSaveForecastConfigColumnRequest = (obj) => {
  const newObj = protoFromObject(SaveForecastConfigColumnRequest, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  has(obj, 'forecastConfigColumn') && newObj.setForecastConfigColumn(toForecastConfigColumn(obj.forecastConfigColumn))
  return newObj
}

export const toGetForecastConfigRequest = (obj) => {
  const newObj = protoFromObject(GetForecastConfigRequest, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  return newObj
}

export const toGetObjectMappingsSourceOptionsRequest = (obj) => {
  const newObj = protoFromObject(GetObjectMappingsSourceOptionsRequest, obj)
  has(obj, 'requestedOptions') && newObj.setRequestedOptionsList(obj.requestedOptions.map((ro) => toSourceOptions(ro)))
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  return newObj
}

export const toListForecastConfigsRequest = (obj) => {
  const newObj = protoFromObject(ListForecastConfigsRequest, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  return newObj
}

export const toPublishForecastConfigDraftRequest = (obj) => {
  const newObj = protoFromObject(PublishForecastConfigDraftRequest, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  has(obj, 'forecastConfig') && newObj.setForecastConfig(toForecastConfig(obj.forecastConfig))
  return newObj
}

export const toListForecastConfigColumnsRequest = (obj) => {
  const newObj = protoFromObject(ListForecastConfigColumnsRequest, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  return newObj
}

export const toDuplicateForecastConfigRequest = (obj) => {
  const newObj = protoFromObject(DuplicateForecastConfigRequest, obj)
  has(obj, 'actor') && newObj.setActor(toActor(obj.actor))
  has(obj, 'forecastConfig') && newObj.setForecastConfig(toForecastConfig(obj.forecastConfig))
  return newObj
}

export const toGetWritebackSettingRequest = (obj) => {
  const newObj = protoFromObject(GetWritebackSettingRequest, obj)
  return newObj
}

export const toUpsertWritebackSettingRequest = (obj) => {
  const newObj = protoFromObject(UpsertWritebackSettingRequest, obj)
  return newObj
}

export const toGetOutreachSettingRequest = (obj) => {
  const newObj = protoFromObject(GetOutreachSettingRequest, obj)
  return newObj
}

export const toOutreachSettings = (obj) => {
  const newObj = protoFromObject(OutreachSettings, obj)
  return newObj
}

export const toUpsertOutreachSettingRequest = (obj) => {
  const newObj = protoFromObject(UpsertOutreachSettingRequest, obj)
  has(obj, 'setting') && newObj.setSetting(toOutreachSettings(obj.setting))
  return newObj
}

export const toGetHierarchySettingRequest = (obj) => {
  const newObj = protoFromObject(GetHierarchySettingRequest, obj)
  return newObj
}

export const toHierarchy = (obj) => {
  const newObj = protoFromObject(Hierarchy, obj)
  return newObj
}

export const toUpsertHierarchySettingRequest = (obj) => {
  const newObj = protoFromObject(UpsertHierarchySettingRequest, obj)
  has(obj, 'setting') && newObj.setSetting(toHierarchy(obj.setting))
  return newObj
}

export const toGetObjectReferenceFieldOptionsRequest = (obj) => {
  const newObj = protoFromObject(GetObjectReferenceFieldOptionsRequest, obj)
  return newObj
}

export const toResolveObjectReferenceFieldOptionsContainer = (obj) => {
  const newObj = protoFromObject(ResolveObjectReferenceFieldOptionsContainer, obj)
  return newObj
}

export const toResolveObjectReferenceFieldOptionsRequest = (obj) => {
  const newObj = protoFromObject(ResolveObjectReferenceFieldOptionsRequest, obj)
  has(obj, 'itemsList') && newObj.setItemsList(obj.itemsList.map(toResolveObjectReferenceFieldOptionsContainer))
  return newObj
}

export const toIdsRequest = (obj) => {
  const newObj = protoFromObject(IdsRequest, obj)
  return newObj
}
