import React, { useCallback } from 'react'
import FilterWrapper from './filterWrapper'
import { useFilterSourceOptions } from '../../context/filterSourceOptions'
import { useForecastConfigValueSettings } from '../../context/forecastConfigValueSettings'

const FilterParameter = (props) => {
  const { sourceOptions } = useFilterSourceOptions()

  const { filters, addGroup } = useForecastConfigValueSettings()

  const handleAddFilter = useCallback(() => {
    addGroup(filters)
    window.analytics.track('realtimeMetrics.filterModal.addFilterClicked')
  }, [filters, addGroup])

  return (
    <>
      {filters?.nestedList?.length
        ? (
          <FilterWrapper
            filters={filters}
            sourceOptions={sourceOptions} />
        )
        : (
          <div className="filters-actions self-start">
            {sourceOptions.length
              ? (
                <button
                  onClick={handleAddFilter}>
                  + Add Filter
                </button>
              )
              : <span>No source options available for this parameter</span>}
          </div>
        )}
    </>
  )
}

export default FilterParameter
