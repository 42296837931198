import { DataSyncIntervalProvider } from '../../context/dataSyncInterval'
import { DataSyncStatusProvider } from '../../context/dataSyncStatus'
import { JoyrideProvider } from '../../context/joyride'
import { ObjectMappingsProvider } from '../../context/objectMappings'
import { OnboardingStep } from '../../grpc/enums'
import { useSetup } from '../../context/setup'
import DataSyncMain from './dataSyncMain'
import React from 'react'

const DataSync = (props) => {
  const { isFetching, isStepComplete } = useSetup()

  return (
    <DataSyncStatusProvider>
      <DataSyncIntervalProvider>
        <ObjectMappingsProvider>
          <JoyrideProvider enabled={!isFetching && !isStepComplete(OnboardingStep.ONBOARDING_STEP_DATA_SYNC)}>
            <DataSyncMain />
          </JoyrideProvider>
        </ObjectMappingsProvider>
      </DataSyncIntervalProvider>
    </DataSyncStatusProvider>
  )
}

export default DataSync
