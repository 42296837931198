import React, { useCallback, useMemo } from 'react'
import FieldListItem from './fieldListItem'
import { useCanonicalObjectMapping } from '../../context/canonicalObjectMapping'
import { cloneDeep, filter, find } from 'lodash'
import { useObjectMappingChanges } from '../../context/objectMappingChanges'
import { useGrpcCallback } from '../../grpc'
import { toValidateCanonicalObjectFieldMappingRequest } from '../../grpc/converters'
import { useNotification } from '../../hooks/useNotification'
import { MappingStatusDetailLevel } from '../../grpc/enums'
import { useTenantInfo } from '../../context/tenantInfo'
import { useIntegrationObjectProperties } from '../../context/integrationObjectProperties'
import ErrorWithReload from '../common/errorWithReload'

const FieldList = (props) => {
  const {
    onEnter,
    onExited,
    search,
  } = props

  const { tenantInfo } = useTenantInfo()
  const { canonicalObjectMapping, setCanonicalObjectMapping, selectedCrmObjectName } = useCanonicalObjectMapping()
  const { updateObjectMappingField, updateWriteBackPermissions } = useObjectMappingChanges()
  const { fetchError, invalidate } = useIntegrationObjectProperties()

  const { notifyError } = useNotification()

  const selectedCrmObject = useMemo(() => {
    const { crmObjectsList = [] } = canonicalObjectMapping
    return find(crmObjectsList, (o) => o.objectName === selectedCrmObjectName)
  }, [canonicalObjectMapping, selectedCrmObjectName])

  const objectWritable = useMemo(() => {
    const { canWriteBack } = tenantInfo
    return canWriteBack && (canonicalObjectMapping?.writable ?? false)
  }, [tenantInfo, canonicalObjectMapping])

  const visibleFields = useMemo(() => {
    const { fieldsList = [] } = selectedCrmObject || {}
    if (search.value) {
      return filter(fieldsList, (f) => {
        const regex = new RegExp(search.escapedValue.toLowerCase())
        return f.label && regex.test(f.label.toLowerCase())
      })
    }
    return fieldsList
  }, [selectedCrmObject, search])

  const validateCanonicalObjectFieldMapping = useGrpcCallback({
    onError: () => {
      notifyError('Error validating field!')
    },
    onSuccess: (obj, data) => {
      const objectMapping = cloneDeep(canonicalObjectMapping)
      if (obj.status === MappingStatusDetailLevel.LEVEL_OK) {
        if (objectMapping.statusDetailsList.length > 0 && obj.statusDetailsList.length > 0) {
          objectMapping.statusDetailsList = filter(objectMapping.statusDetailsList, (s) => s.field !== obj.statusDetailsList[0].field)
          setCanonicalObjectMapping(objectMapping)
        }
      } else if ([MappingStatusDetailLevel.LEVEL_INFO, MappingStatusDetailLevel.LEVEL_WARN, MappingStatusDetailLevel.LEVEL_ERROR].includes(obj.status)) {
        obj.statusDetailsList = obj.statusDetailsList.map((s) => {
          s.crmObject = selectedCrmObjectName
          return s
        })
        if (canonicalObjectMapping.statusDetailsList) {
          if (objectMapping.statusDetailsList.length > 0 && obj.statusDetailsList.length > 0) {
            objectMapping.statusDetailsList = filter(objectMapping.statusDetailsList, (s) => s.field !== obj.statusDetailsList[0].field)
          }
          objectMapping.statusDetailsList.push(...obj.statusDetailsList)
          setCanonicalObjectMapping(objectMapping)
        }
      }
    },
    grpcMethod: 'validateCanonicalObjectFieldMapping',
    debug: false,
  }, [canonicalObjectMapping, selectedCrmObjectName])

  const onFieldChange = useCallback(({ field, option }) => {
    const { data = {} } = option || {}
    updateObjectMappingField({
      objectName: selectedCrmObjectName,
      fieldName: field.toName,
      mappedField: data,
    })
    const { name = '', type = '' } = data
    const request = toValidateCanonicalObjectFieldMappingRequest({
      fieldMap: {
        ...field,
        fromName: name,
        fromType: type,
      }
    })
    validateCanonicalObjectFieldMapping(request)
  }, [updateObjectMappingField, selectedCrmObjectName, validateCanonicalObjectFieldMapping])

  const onWriteBackChange = useCallback(({ field, option }) => {
    updateWriteBackPermissions({
      objectName: selectedCrmObjectName,
      fieldName: field.toName,
      data: option.data,
    })
  }, [updateWriteBackPermissions, selectedCrmObjectName])

  return fetchError
    ? (
      <div className="flex justify-center my-10">
        <ErrorWithReload
          header={`Error Loading ${selectedCrmObjectName}`}
          reloadText="Reload"
          onReload={() => invalidate()} />
      </div>
    ) : (
      <div className="flex-table">
        <div className="thead">
          <div className="tr">
            <div className="td w-10 text-color-818e93">Field</div>
            <div className="td w-10" />
            <div className="td w-10">CRM Field</div>
            <div className="td w-10 text-color-818e93">Required</div>
            <div className="td w-10 text-color-818e93">Type</div>
            <div className="td w-20 text-color-818e93">Description</div>
            {objectWritable && <div className="td w-10">Write-Back to CRM</div>}
          </div>
        </div>
        <div className="tbody">
          {visibleFields.map((field, index) => {
            return (
              <FieldListItem
                key={`FieldListItem-${field.id}-${index}`}
                objectWritable={objectWritable}
                field={field}
                onFieldChange={onFieldChange}
                onWriteBackChange={onWriteBackChange}
                {...index === 0 && { joyrideStepId: 'joyride_objectMapping_2', onEnter, onExited }} />
            )
          })}
        </div>
      </div>
    )
}

export default FieldList
