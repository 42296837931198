import PropTypes from 'prop-types'
import React from 'react'
import SideMenuItem from './sideMenuItem'
import SideMenuItemStepIcon from './sideMenuItemStepIcon'

const SideMenuItemStep = ({ borderBottom, borderTop, disabled, icon, ...props }) => (
  <SideMenuItem
    icon={<SideMenuItemStepIcon borderBottom={borderBottom} borderTop={borderTop} disabled={disabled}>{icon}</SideMenuItemStepIcon>}
    disabled={disabled}
    {...(disabled) && {
      textColor: 'text-color-c9ced0',
      tooltip: 'This page is not available until you complete the prior steps.'
    }}
    {...props} />
)

SideMenuItemStep.defaultProps = {
  borderBottom: true,
  borderTop: true,
  disabled: true,
  icon: null,
  isCurrent: false,
}

SideMenuItemStep.propTypes = {
  borderBottom: PropTypes.bool,
  borderTop: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  isCurrent: PropTypes.bool
}

export default SideMenuItemStep
