import { DisplayOption, ForecastMode, VisibilityOption } from '../../grpc/enums'

export const wonCategory = {
  key: 'Won',
  label: 'Won',
  value: 'Won',
}

export const customFields = [
  { label: 'Status', value: 'canopy_status', to: 'canopy_status' },
  { label: 'Last Updated', value: '_internalUpdatedAt', to: '_internalUpdatedAt' },
]

export const forecastModes = [
  {
    label: 'Auto',
    value: ForecastMode.AUTO,
    description: ''
  },
  {
    label: 'Manual',
    value: ForecastMode.MANUAL,
    description: ''
  },
]

export const displayOptions = [
  {
    label: 'All',
    value: DisplayOption.ALL,
    description: ''
  },
  {
    label: 'Self',
    value: DisplayOption.SELF,
    description: ''
  },
  {
    label: 'Banner',
    value: DisplayOption.BANNER,
    description: ''
  },
  {
    label: 'Grid',
    value: DisplayOption.GRID,
    description: ''
  },
]

export const visibilityOptions = [
  {
    label: 'Show',
    value: VisibilityOption.SHOW,
    description: ''
  },
  {
    label: 'Manager',
    value: VisibilityOption.MANAGER,
    description: ''
  },
  {
    label: 'Individual Contributor',
    value: VisibilityOption.IC,
    description: ''
  },
]

export const groupByOptions = [
  {
    label: 'Forecast Category',
    value: 'forecastCategory',
  },
  {
    label: 'Stage Name',
    value: 'stageName',
    disabled: true,
  },
]

export const dateRangeOptions = [
  {
    label: 'Current Week',
    value: 'CURRENT_WEEK',
  },
  {
    label: 'Previous Week',
    value: 'PREVIOUS_WEEK',
  },
  {
    label: 'Current Month',
    value: 'CURRENT_MONTH',
  },
  {
    label: 'Previous Month',
    value: 'PREVIOUS_MONTH',
  },
  {
    label: 'Current Quarter',
    value: 'CURRENT_QUARTER',
  },
  {
    label: 'Previous Quarter',
    value: 'PREVIOUS_QUARTER',
  },
  {
    label: 'Custom',
    value: 'CUSTOM',
    disabled: true,
  },
]
