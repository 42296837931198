// source: src/common/insight/insight_result.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_insight_numeric_series_metadata_pb = require('../../../src/common/insight/numeric_series_metadata_pb.js');
goog.object.extend(proto, src_common_insight_numeric_series_metadata_pb);
var src_common_insight_string_series_metadata_pb = require('../../../src/common/insight/string_series_metadata_pb.js');
goog.object.extend(proto, src_common_insight_string_series_metadata_pb);
var src_common_insight_numeric_metadata_pb = require('../../../src/common/insight/numeric_metadata_pb.js');
goog.object.extend(proto, src_common_insight_numeric_metadata_pb);
var src_common_insight_string_metadata_pb = require('../../../src/common/insight/string_metadata_pb.js');
goog.object.extend(proto, src_common_insight_string_metadata_pb);
var src_common_insight_date_metadata_pb = require('../../../src/common/insight/date_metadata_pb.js');
goog.object.extend(proto, src_common_insight_date_metadata_pb);
var src_common_insight_bool_metadata_pb = require('../../../src/common/insight/bool_metadata_pb.js');
goog.object.extend(proto, src_common_insight_bool_metadata_pb);
var src_common_insight_json_metadata_pb = require('../../../src/common/insight/json_metadata_pb.js');
goog.object.extend(proto, src_common_insight_json_metadata_pb);
goog.exportSymbol('proto.loupe.common.insight.InsightResult', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.insight.InsightResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.insight.InsightResult.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.insight.InsightResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.insight.InsightResult.displayName = 'proto.loupe.common.insight.InsightResult';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.insight.InsightResult.repeatedFields_ = [4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.insight.InsightResult.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.insight.InsightResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.insight.InsightResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.insight.InsightResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    controlType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    numericSeriesMetadataList: jspb.Message.toObjectList(msg.getNumericSeriesMetadataList(),
    src_common_insight_numeric_series_metadata_pb.NumericSeriesMetadata.toObject, includeInstance),
    stringSeriesMetadataList: jspb.Message.toObjectList(msg.getStringSeriesMetadataList(),
    src_common_insight_string_series_metadata_pb.StringSeriesMetadata.toObject, includeInstance),
    numericMetadataList: jspb.Message.toObjectList(msg.getNumericMetadataList(),
    src_common_insight_numeric_metadata_pb.NumericMetadata.toObject, includeInstance),
    stringMetadataList: jspb.Message.toObjectList(msg.getStringMetadataList(),
    src_common_insight_string_metadata_pb.StringMetadata.toObject, includeInstance),
    dateMetadataList: jspb.Message.toObjectList(msg.getDateMetadataList(),
    src_common_insight_date_metadata_pb.DateMetadata.toObject, includeInstance),
    boolMetadataList: jspb.Message.toObjectList(msg.getBoolMetadataList(),
    src_common_insight_bool_metadata_pb.BoolMetadata.toObject, includeInstance),
    jsonMetadataList: jspb.Message.toObjectList(msg.getJsonMetadataList(),
    src_common_insight_json_metadata_pb.JsonMetadata.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.insight.InsightResult}
 */
proto.loupe.common.insight.InsightResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.insight.InsightResult;
  return proto.loupe.common.insight.InsightResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.insight.InsightResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.insight.InsightResult}
 */
proto.loupe.common.insight.InsightResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlType(value);
      break;
    case 4:
      var value = new src_common_insight_numeric_series_metadata_pb.NumericSeriesMetadata;
      reader.readMessage(value,src_common_insight_numeric_series_metadata_pb.NumericSeriesMetadata.deserializeBinaryFromReader);
      msg.addNumericSeriesMetadata(value);
      break;
    case 5:
      var value = new src_common_insight_string_series_metadata_pb.StringSeriesMetadata;
      reader.readMessage(value,src_common_insight_string_series_metadata_pb.StringSeriesMetadata.deserializeBinaryFromReader);
      msg.addStringSeriesMetadata(value);
      break;
    case 6:
      var value = new src_common_insight_numeric_metadata_pb.NumericMetadata;
      reader.readMessage(value,src_common_insight_numeric_metadata_pb.NumericMetadata.deserializeBinaryFromReader);
      msg.addNumericMetadata(value);
      break;
    case 7:
      var value = new src_common_insight_string_metadata_pb.StringMetadata;
      reader.readMessage(value,src_common_insight_string_metadata_pb.StringMetadata.deserializeBinaryFromReader);
      msg.addStringMetadata(value);
      break;
    case 8:
      var value = new src_common_insight_date_metadata_pb.DateMetadata;
      reader.readMessage(value,src_common_insight_date_metadata_pb.DateMetadata.deserializeBinaryFromReader);
      msg.addDateMetadata(value);
      break;
    case 9:
      var value = new src_common_insight_bool_metadata_pb.BoolMetadata;
      reader.readMessage(value,src_common_insight_bool_metadata_pb.BoolMetadata.deserializeBinaryFromReader);
      msg.addBoolMetadata(value);
      break;
    case 10:
      var value = new src_common_insight_json_metadata_pb.JsonMetadata;
      reader.readMessage(value,src_common_insight_json_metadata_pb.JsonMetadata.deserializeBinaryFromReader);
      msg.addJsonMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.insight.InsightResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.insight.InsightResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.insight.InsightResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.insight.InsightResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getControlType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNumericSeriesMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      src_common_insight_numeric_series_metadata_pb.NumericSeriesMetadata.serializeBinaryToWriter
    );
  }
  f = message.getStringSeriesMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      src_common_insight_string_series_metadata_pb.StringSeriesMetadata.serializeBinaryToWriter
    );
  }
  f = message.getNumericMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      src_common_insight_numeric_metadata_pb.NumericMetadata.serializeBinaryToWriter
    );
  }
  f = message.getStringMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      src_common_insight_string_metadata_pb.StringMetadata.serializeBinaryToWriter
    );
  }
  f = message.getDateMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      src_common_insight_date_metadata_pb.DateMetadata.serializeBinaryToWriter
    );
  }
  f = message.getBoolMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      src_common_insight_bool_metadata_pb.BoolMetadata.serializeBinaryToWriter
    );
  }
  f = message.getJsonMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      src_common_insight_json_metadata_pb.JsonMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.loupe.common.insight.InsightResult.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.insight.InsightResult} returns this
 */
proto.loupe.common.insight.InsightResult.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.loupe.common.insight.InsightResult.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.insight.InsightResult} returns this
 */
proto.loupe.common.insight.InsightResult.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string control_type = 3;
 * @return {string}
 */
proto.loupe.common.insight.InsightResult.prototype.getControlType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.insight.InsightResult} returns this
 */
proto.loupe.common.insight.InsightResult.prototype.setControlType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated NumericSeriesMetadata numeric_series_metadata = 4;
 * @return {!Array<!proto.loupe.common.insight.NumericSeriesMetadata>}
 */
proto.loupe.common.insight.InsightResult.prototype.getNumericSeriesMetadataList = function() {
  return /** @type{!Array<!proto.loupe.common.insight.NumericSeriesMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_insight_numeric_series_metadata_pb.NumericSeriesMetadata, 4));
};


/**
 * @param {!Array<!proto.loupe.common.insight.NumericSeriesMetadata>} value
 * @return {!proto.loupe.common.insight.InsightResult} returns this
*/
proto.loupe.common.insight.InsightResult.prototype.setNumericSeriesMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.insight.NumericSeriesMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.insight.NumericSeriesMetadata}
 */
proto.loupe.common.insight.InsightResult.prototype.addNumericSeriesMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.insight.NumericSeriesMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.insight.InsightResult} returns this
 */
proto.loupe.common.insight.InsightResult.prototype.clearNumericSeriesMetadataList = function() {
  return this.setNumericSeriesMetadataList([]);
};


/**
 * repeated StringSeriesMetadata string_series_metadata = 5;
 * @return {!Array<!proto.loupe.common.insight.StringSeriesMetadata>}
 */
proto.loupe.common.insight.InsightResult.prototype.getStringSeriesMetadataList = function() {
  return /** @type{!Array<!proto.loupe.common.insight.StringSeriesMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_insight_string_series_metadata_pb.StringSeriesMetadata, 5));
};


/**
 * @param {!Array<!proto.loupe.common.insight.StringSeriesMetadata>} value
 * @return {!proto.loupe.common.insight.InsightResult} returns this
*/
proto.loupe.common.insight.InsightResult.prototype.setStringSeriesMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.insight.StringSeriesMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.insight.StringSeriesMetadata}
 */
proto.loupe.common.insight.InsightResult.prototype.addStringSeriesMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.insight.StringSeriesMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.insight.InsightResult} returns this
 */
proto.loupe.common.insight.InsightResult.prototype.clearStringSeriesMetadataList = function() {
  return this.setStringSeriesMetadataList([]);
};


/**
 * repeated NumericMetadata numeric_metadata = 6;
 * @return {!Array<!proto.loupe.common.insight.NumericMetadata>}
 */
proto.loupe.common.insight.InsightResult.prototype.getNumericMetadataList = function() {
  return /** @type{!Array<!proto.loupe.common.insight.NumericMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_insight_numeric_metadata_pb.NumericMetadata, 6));
};


/**
 * @param {!Array<!proto.loupe.common.insight.NumericMetadata>} value
 * @return {!proto.loupe.common.insight.InsightResult} returns this
*/
proto.loupe.common.insight.InsightResult.prototype.setNumericMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.common.insight.NumericMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.insight.NumericMetadata}
 */
proto.loupe.common.insight.InsightResult.prototype.addNumericMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.common.insight.NumericMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.insight.InsightResult} returns this
 */
proto.loupe.common.insight.InsightResult.prototype.clearNumericMetadataList = function() {
  return this.setNumericMetadataList([]);
};


/**
 * repeated StringMetadata string_metadata = 7;
 * @return {!Array<!proto.loupe.common.insight.StringMetadata>}
 */
proto.loupe.common.insight.InsightResult.prototype.getStringMetadataList = function() {
  return /** @type{!Array<!proto.loupe.common.insight.StringMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_insight_string_metadata_pb.StringMetadata, 7));
};


/**
 * @param {!Array<!proto.loupe.common.insight.StringMetadata>} value
 * @return {!proto.loupe.common.insight.InsightResult} returns this
*/
proto.loupe.common.insight.InsightResult.prototype.setStringMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.loupe.common.insight.StringMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.insight.StringMetadata}
 */
proto.loupe.common.insight.InsightResult.prototype.addStringMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.loupe.common.insight.StringMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.insight.InsightResult} returns this
 */
proto.loupe.common.insight.InsightResult.prototype.clearStringMetadataList = function() {
  return this.setStringMetadataList([]);
};


/**
 * repeated DateMetadata date_metadata = 8;
 * @return {!Array<!proto.loupe.common.insight.DateMetadata>}
 */
proto.loupe.common.insight.InsightResult.prototype.getDateMetadataList = function() {
  return /** @type{!Array<!proto.loupe.common.insight.DateMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_insight_date_metadata_pb.DateMetadata, 8));
};


/**
 * @param {!Array<!proto.loupe.common.insight.DateMetadata>} value
 * @return {!proto.loupe.common.insight.InsightResult} returns this
*/
proto.loupe.common.insight.InsightResult.prototype.setDateMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.loupe.common.insight.DateMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.insight.DateMetadata}
 */
proto.loupe.common.insight.InsightResult.prototype.addDateMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.loupe.common.insight.DateMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.insight.InsightResult} returns this
 */
proto.loupe.common.insight.InsightResult.prototype.clearDateMetadataList = function() {
  return this.setDateMetadataList([]);
};


/**
 * repeated BoolMetadata bool_metadata = 9;
 * @return {!Array<!proto.loupe.common.insight.BoolMetadata>}
 */
proto.loupe.common.insight.InsightResult.prototype.getBoolMetadataList = function() {
  return /** @type{!Array<!proto.loupe.common.insight.BoolMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_insight_bool_metadata_pb.BoolMetadata, 9));
};


/**
 * @param {!Array<!proto.loupe.common.insight.BoolMetadata>} value
 * @return {!proto.loupe.common.insight.InsightResult} returns this
*/
proto.loupe.common.insight.InsightResult.prototype.setBoolMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.loupe.common.insight.BoolMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.insight.BoolMetadata}
 */
proto.loupe.common.insight.InsightResult.prototype.addBoolMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.loupe.common.insight.BoolMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.insight.InsightResult} returns this
 */
proto.loupe.common.insight.InsightResult.prototype.clearBoolMetadataList = function() {
  return this.setBoolMetadataList([]);
};


/**
 * repeated JsonMetadata json_metadata = 10;
 * @return {!Array<!proto.loupe.common.insight.JsonMetadata>}
 */
proto.loupe.common.insight.InsightResult.prototype.getJsonMetadataList = function() {
  return /** @type{!Array<!proto.loupe.common.insight.JsonMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_insight_json_metadata_pb.JsonMetadata, 10));
};


/**
 * @param {!Array<!proto.loupe.common.insight.JsonMetadata>} value
 * @return {!proto.loupe.common.insight.InsightResult} returns this
*/
proto.loupe.common.insight.InsightResult.prototype.setJsonMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.loupe.common.insight.JsonMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.insight.JsonMetadata}
 */
proto.loupe.common.insight.InsightResult.prototype.addJsonMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.loupe.common.insight.JsonMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.insight.InsightResult} returns this
 */
proto.loupe.common.insight.InsightResult.prototype.clearJsonMetadataList = function() {
  return this.setJsonMetadataList([]);
};


goog.object.extend(exports, proto.loupe.common.insight);
