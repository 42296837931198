import { useEffect, useRef, useCallback } from 'react'

const useKeyPressEffect = ({
  targetKey,
  onDown,
  onUp,
  onDoubleTap,
  preventDefault = true,
  doubleTimeout = 300,
  eventTarget = []
}, dependencyList = []) => {
  const keyPressTimeout = useRef()

  const isValIdTarget = useCallback((_target) => {
    if ([window.document.body, ...eventTarget].find((t) => {
      if (typeof t === 'string') {
        return document.querySelector(t) === _target
      }
      return t === _target
    })) {
      return true
    }
  }, [eventTarget])

  const monitorDouble = useCallback(() => {
    if (keyPressTimeout.current) {
      keyPressTimeout.current = null
      onDoubleTap?.()
    } else {
      keyPressTimeout.current = window.setTimeout(() => {
        clearTimeout(keyPressTimeout.current)
        keyPressTimeout.current = null
      }, doubleTimeout)
    }
  }, [keyPressTimeout.current, doubleTimeout, onDoubleTap])

  const downHandler = useCallback((e) => {
    const { key, target } = e

    if (key !== targetKey) {
      return
    }

    if (!isValIdTarget(target)) {
      return
    }

    if (preventDefault) {
      e.preventDefault()
    }

    onDown?.(e)
  }, [onDown, targetKey, preventDefault, isValIdTarget])

  const upHandler = useCallback((e) => {
    const { key, target } = e

    if (key !== targetKey) {
      return
    }

    if (!isValIdTarget(target)) {
      return
    }

    if (preventDefault) {
      e.preventDefault()
    }

    onUp?.(e)
    if (onDoubleTap) {
      monitorDouble(e)
    }
  }, [onUp, monitorDouble, targetKey, preventDefault, isValIdTarget])

  useEffect(() => {
    window.removeEventListener('keydown', downHandler)
    window.removeEventListener('keyup', upHandler)

    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [upHandler, downHandler, ...dependencyList])
}

export default useKeyPressEffect
