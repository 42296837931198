import { useCallback, useEffect, useMemo, useState } from 'react'
import { debounce, uniqBy } from 'lodash'
import { useGrpcCallback } from '../../grpc'
import { guid } from '../../lib/guid'
import { useAuth } from '../../context/auth'
import { toGetCRMRolesRequest } from '../../grpc/converters'

const pageSize = 100

export function useCrmRoles({ search = '', canFetch = false }) {
  const { tenantId } = useAuth()
  const [key, setKey] = useState(guid())
  const [crmRoles, setCrmRoles] = useState([])
  const [searchInternal, setSearchInternal] = useState({ search, key: guid() })
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const fetch = useGrpcCallback({
    onSuccess: (obj) => {
      const { crmRolesList = [], total = 0 } = obj
      setCrmRoles((prevValue) => {
        const roles = uniqBy([
          ...prevValue,
          ...crmRolesList
        ], (o) => o.id)
        setHasMore(total > 0 && roles.length < total)
        return roles
      })
      setTotal(total)
      setIsFetching(false)
    },
    onError: (err) => {
      setIsFetching(false)
    },
    onFetch: () => {
      setIsFetching(true)
    },
    grpcMethod: 'getCRMRoles',
    debug: false
  }, [])

  const nextPage = useCallback(() => {
    setPage(Math.min(page + 1, Math.ceil(total / pageSize)))
  }, [page, total])

  const updateSearch = useCallback((search) => {
    setIsFetching(true)
    setCrmRoles([])
    setSearchInternal({ search, key: guid() })
    setPage(1)
  }, [])

  const debounceSearch = useMemo(() => {
    return debounce(updateSearch, 350)
  }, [updateSearch])

  useEffect(() => {
    debounceSearch(search)
    return () => {
      debounceSearch.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  useEffect(() => {
    if (canFetch) {
      const request = toGetCRMRolesRequest({
        tenantId,
        ...searchInternal.search && { search: searchInternal.search },
        page,
        pageSize,
      })
      fetch(request)
    }
  }, [canFetch, key, searchInternal, page, tenantId, fetch])

  return {
    isFetching,
    crmRoles,
    total,
    hasMore,
    nextPage,
    updateSearch,
    invalidate
  }
}
