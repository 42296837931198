import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react'
import { useGrpcCallback } from '../grpc'
import { toSaveForecastConfigColumnRequest } from '../grpc/converters'
import { ForecastConfigStatus } from '../grpc/enums'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useActor } from '../hooks/useActor'
import { useForecastingConfig } from './forecastingConfig'
import { isEqual, cloneDeep } from 'lodash'

const ForecastingConfigColumnContext = React.createContext()

export function ForecastingConfigColumnProvider({ column, configId, children }) {
  const { actor } = useActor()
  const { notifyError } = useNotification()
  const { setConfigColumns, invalidate: invalidateConfig } = useForecastingConfig()

  const [isFetching, setIsFetching] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [key, setKey] = useState(guid())
  const [configColumn, setConfigColumn] = useState({})
  const [didSave, setDidSave] = useState(false)
  const [lastSaved, setLastSaved] = useState({})
  const [lastSavedAt, setLastSavedAt] = useState(null)
  const [isInvalidFormula, setIsInvalidFormula] = useState(false)

  useEffect(() => {
    setConfigColumn(column)
    setLastSaved(column)
  }, [column])

  const isValidColumn = useMemo(() => {
    return !!configColumn?.name
      && !isInvalidFormula
      && (!configColumn.displayFormula
        || (configColumn.displayFormula?.latexFormula === ''
          && (!configColumn.displayFormula?.variableMappingMap || configColumn.displayFormula?.variableMappingMap?.length === 0))
        || (configColumn.displayFormula?.latexFormula !== '' && configColumn.displayFormula?.variableMappingMap?.length > 0))
  }, [configColumn, isInvalidFormula])

  const cannotBePublished = useMemo(() => {
    if (configColumn) {
      return (!configColumn.displayFormula?.latexFormula || configColumn.displayFormula?.latexFormula === '')
        && (configColumn.displayFormula?.variableMappingMap?.length > 0)
        && (!configColumn.forecastConfigValueSettings || configColumn?.forecastConfigValueSettings?.filter?.filters?.nestedList.length === 0)
    }
  }, [configColumn])

  const lastSavedAtRef = useRef(new Date())
  useEffect(() => {
    if (lastSavedAt > lastSavedAtRef.current) {
      setDidSave(true)

      // Flip this back after a couple seconds
      setTimeout(() => {
        setDidSave(false)
      }, [1500])
    }
  }, [lastSavedAt])

  const hasUnsavedChanges = useMemo(() => {
    return !isEqual(lastSaved, configColumn)
  }, [lastSaved, configColumn])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const saveConfigColumnRequest = useGrpcCallback({
    onError: () => {
      setIsFetching(false)
      setFetchError(true)
      notifyError('Error Saving Column Settings!')
    },
    onSuccess: (obj) => {
      setConfigColumn(obj)
      setLastSaved(cloneDeep(obj))
      setLastSavedAt(new Date())
      setIsFetching(false)
      setFetchError(false)

      setConfigColumns((columns) => {
        return columns.reduce((prev, curr) => {
          if (curr.id === obj.id) {
            return [...prev, obj]
          }

          return [...prev, curr]
        }, [])
      })
      invalidateConfig()
    },
    onFetch: () => {
      setIsFetching(true)
      setFetchError(false)
    },
    grpcMethod: 'saveForecastConfigColumn',
    debug: false
  }, [key, setConfigColumns])

  const saveConfigColumn = useCallback((updatedColumn) => {
    const colToSave = updatedColumn ?? configColumn
    const request = toSaveForecastConfigColumnRequest({
      actor,
      forecastConfigColumn: {
        forecastConfigId: configId,
        ...colToSave,
        status: ForecastConfigStatus.ENABLED
      }
    })
    saveConfigColumnRequest(request)
  }, [saveConfigColumnRequest, configColumn, configId, actor])

  const updateConfigColumn = useCallback((props, options = {}) => {
    const { saveAfterUpdate = false } = options

    const updatedColumn = {
      ...configColumn,
      ...props,
      forecastConfigValueSettings: { ...configColumn.forecastConfigValueSettings, ...props.forecastConfigValueSettings }
    }
    setConfigColumn(updatedColumn)
    if (saveAfterUpdate) {
      saveConfigColumn(updatedColumn)
    }
  }, [configColumn, saveConfigColumn])

  const contextValue = useMemo(() => {
    return {
      didSave,
      isFetching,
      fetchError,
      configColumn,
      key,
      hasUnsavedChanges,
      isValidColumn,
      cannotBePublished,
      setIsInvalidFormula,
      invalidate,
      saveConfigColumn,
      updateConfigColumn,
    }
  }, [
    didSave,
    isFetching,
    fetchError,
    configColumn,
    key,
    hasUnsavedChanges,
    isValidColumn,
    setIsInvalidFormula,
    cannotBePublished,
    invalidate,
    saveConfigColumn,
    updateConfigColumn,
  ])

  return <ForecastingConfigColumnContext.Provider value={contextValue}>{children}</ForecastingConfigColumnContext.Provider>
}

export function useForecastingConfigColumn() {
  const context = React.useContext(ForecastingConfigColumnContext)
  if (context === undefined) {
    throw new Error('useForecastingConfigColumn must be used within a ForecastingConfigColumnProvider')
  }
  return context
}
