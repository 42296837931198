import { TextField, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(() => ({
  select: {
    '&:focus': {
      background: 'none'
    }
  }
}))

const TextSelectList = ({ options, ...props }) => {
  const classes = useStyles()

  return (
    <TextField
      InputProps={{ disableUnderline: true }}
      SelectProps={{ classes: { select: classes.select } }}
      select
      {...props}
    >
      {options.map((option, index) => (
        <MenuItem
          key={`MenuItem-${option.value}-${index}`}
          value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default TextSelectList
