import { SvgIcon } from '@material-ui/core'
import React from 'react'

const IndeterminateCheck = (props, ref) => {
  return (
    <SvgIcon height="24px" viewBox="0 0 24 24" width="24px" {...props} ref={ref}>
      <g><rect fill="none" height="24" width="24" /></g>
      <g><g><g><path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M17,13H7v-2h10V13z" /></g></g></g>
    </SvgIcon>
  )
}

export default React.forwardRef(IndeterminateCheck)
