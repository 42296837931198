import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toGetTenantDataSyncHistoryRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useAuth } from './auth'

const DataSyncHistoryContext = React.createContext()

const pageSize = 10

export function DataSyncHistoryProvider({ children }) {
  const { tenantId } = useAuth()

  const { notifyError } = useNotification()

  const [key, setKey] = useState(guid())
  const [isFetching, setIsFetching] = useState(true)
  const [dataSyncHistory, setDataSyncHistory] = useState({ historyList: [], total: 0 })
  const [page, setPage] = useState(1)

  useGrpcEffect({
    request: toGetTenantDataSyncHistoryRequest({
      tenantId,
      page,
      pageSize,
    }),
    onError: () => {
      setIsFetching(false)
      notifyError('Error fetching data sync history!')
    },
    onSuccess: (obj) => {
      setIsFetching(false)
      setDataSyncHistory(obj)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getTenantDataSyncHistory',
    debug: false,
  }, [tenantId, key, page])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      dataSyncHistory,
      setDataSyncHistory,
      page,
      setPage,
      pageSize,
      key,
      invalidate,
    }
  }, [isFetching, page, dataSyncHistory])

  return <DataSyncHistoryContext.Provider value={contextValue}>{children}</DataSyncHistoryContext.Provider>
}

export function useDataSyncHistory() {
  const context = React.useContext(DataSyncHistoryContext)
  if (context === undefined) {
    throw new Error('useDataSyncHistory must be used within a DataSyncHistoryProvider')
  }
  return context
}
