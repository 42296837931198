// source: src/services/integration-invoker/integration-invoker.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_integration_object_object_pb = require('../../../src/common/integration/object/object_pb.js');
goog.object.extend(proto, src_common_integration_object_object_pb);
var src_common_integration_type_type_pb = require('../../../src/common/integration/type/type_pb.js');
goog.object.extend(proto, src_common_integration_type_type_pb);
var src_common_identity_identity_pb = require('../../../src/common/identity/identity_pb.js');
goog.object.extend(proto, src_common_identity_identity_pb);
var src_common_integration_auth_auth_pb = require('../../../src/common/integration/auth/auth_pb.js');
goog.object.extend(proto, src_common_integration_auth_auth_pb);
var src_common_integration_pager_pager_pb = require('../../../src/common/integration/pager/pager_pb.js');
goog.object.extend(proto, src_common_integration_pager_pager_pb);
var src_common_integration_sorter_sorter_pb = require('../../../src/common/integration/sorter/sorter_pb.js');
goog.object.extend(proto, src_common_integration_sorter_sorter_pb);
var src_common_integration_searcher_searcher_pb = require('../../../src/common/integration/searcher/searcher_pb.js');
goog.object.extend(proto, src_common_integration_searcher_searcher_pb);
var src_common_tenant_mapping_v2_object_mapping_pb = require('../../../src/common/tenant/mapping/v2/object_mapping_pb.js');
goog.object.extend(proto, src_common_tenant_mapping_v2_object_mapping_pb);
goog.exportSymbol('proto.loupe.services.integration_invoker.DeleteRequest', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.DeleteResponse', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.DescribeManyRequest', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.DescribeManyResponse', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.DescribeRequest', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.DescribeResponse', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.GetObjectDescriptionResponse', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.GetRequest', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.GetResponse', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.InsertRequest', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.InsertResponse', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.IntegrationIdentity', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.ListObjectsRequest', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.ListObjectsResponse', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.ListRequest', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.ListResponse', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.NextRequest', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.PatchRequest', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.PatchResponse', null, global);
goog.exportSymbol('proto.loupe.services.integration_invoker.SearchRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.IntegrationIdentity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.IntegrationIdentity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.IntegrationIdentity.displayName = 'proto.loupe.services.integration_invoker.IntegrationIdentity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.GetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.GetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.GetRequest.displayName = 'proto.loupe.services.integration_invoker.GetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.GetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.GetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.GetResponse.displayName = 'proto.loupe.services.integration_invoker.GetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.ListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.ListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.ListRequest.displayName = 'proto.loupe.services.integration_invoker.ListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.ListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.integration_invoker.ListResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.integration_invoker.ListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.ListResponse.displayName = 'proto.loupe.services.integration_invoker.ListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.SearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.SearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.SearchRequest.displayName = 'proto.loupe.services.integration_invoker.SearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.NextRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.NextRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.NextRequest.displayName = 'proto.loupe.services.integration_invoker.NextRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.DescribeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.DescribeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.DescribeRequest.displayName = 'proto.loupe.services.integration_invoker.DescribeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.DescribeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.DescribeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.DescribeResponse.displayName = 'proto.loupe.services.integration_invoker.DescribeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.DescribeManyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.integration_invoker.DescribeManyRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.integration_invoker.DescribeManyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.DescribeManyRequest.displayName = 'proto.loupe.services.integration_invoker.DescribeManyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.DescribeManyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.integration_invoker.DescribeManyResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.integration_invoker.DescribeManyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.DescribeManyResponse.displayName = 'proto.loupe.services.integration_invoker.DescribeManyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.InsertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.integration_invoker.InsertRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.integration_invoker.InsertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.InsertRequest.displayName = 'proto.loupe.services.integration_invoker.InsertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.InsertResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.integration_invoker.InsertResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.integration_invoker.InsertResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.InsertResponse.displayName = 'proto.loupe.services.integration_invoker.InsertResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.PatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.integration_invoker.PatchRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.integration_invoker.PatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.PatchRequest.displayName = 'proto.loupe.services.integration_invoker.PatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.PatchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.integration_invoker.PatchResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.integration_invoker.PatchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.PatchResponse.displayName = 'proto.loupe.services.integration_invoker.PatchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.DeleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.integration_invoker.DeleteRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.integration_invoker.DeleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.DeleteRequest.displayName = 'proto.loupe.services.integration_invoker.DeleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.DeleteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.DeleteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.DeleteResponse.displayName = 'proto.loupe.services.integration_invoker.DeleteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.GetObjectDescriptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.displayName = 'proto.loupe.services.integration_invoker.GetObjectDescriptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.displayName = 'proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.displayName = 'proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.ListObjectsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.ListObjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.ListObjectsRequest.displayName = 'proto.loupe.services.integration_invoker.ListObjectsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.integration_invoker.ListObjectsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.integration_invoker.ListObjectsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.integration_invoker.ListObjectsResponse.displayName = 'proto.loupe.services.integration_invoker.ListObjectsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.IntegrationIdentity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.toObject = function(includeInstance, msg) {
  var f, obj = {
    integrationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    identity: (f = msg.getIdentity()) && src_common_identity_identity_pb.Identity.toObject(includeInstance, f),
    authInfo: (f = msg.getAuthInfo()) && src_common_integration_auth_auth_pb.AuthInfo.toObject(includeInstance, f),
    mappingsMap: (f = msg.getMappingsMap()) ? f.toObject(includeInstance, proto.loupe.common.tenant.mapping.v2.ObjectMapping.toObject) : [],
    isTestInstance: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.IntegrationIdentity;
  return proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.IntegrationIdentity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.integration.type.IntegrationType} */ (reader.readEnum());
      msg.setIntegrationType(value);
      break;
    case 2:
      var value = new src_common_identity_identity_pb.Identity;
      reader.readMessage(value,src_common_identity_identity_pb.Identity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 3:
      var value = new src_common_integration_auth_auth_pb.AuthInfo;
      reader.readMessage(value,src_common_integration_auth_auth_pb.AuthInfo.deserializeBinaryFromReader);
      msg.setAuthInfo(value);
      break;
    case 4:
      var value = msg.getMappingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.common.tenant.mapping.v2.ObjectMapping.deserializeBinaryFromReader, "", new proto.loupe.common.tenant.mapping.v2.ObjectMapping());
         });
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTestInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.IntegrationIdentity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntegrationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_identity_identity_pb.Identity.serializeBinaryToWriter
    );
  }
  f = message.getAuthInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_integration_auth_auth_pb.AuthInfo.serializeBinaryToWriter
    );
  }
  f = message.getMappingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.common.tenant.mapping.v2.ObjectMapping.serializeBinaryToWriter);
  }
  f = message.getIsTestInstance();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional loupe.common.integration.type.IntegrationType integration_type = 1;
 * @return {!proto.loupe.common.integration.type.IntegrationType}
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.getIntegrationType = function() {
  return /** @type {!proto.loupe.common.integration.type.IntegrationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.integration.type.IntegrationType} value
 * @return {!proto.loupe.services.integration_invoker.IntegrationIdentity} returns this
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.setIntegrationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional loupe.common.identity.Identity identity = 2;
 * @return {?proto.loupe.common.identity.Identity}
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.common.identity.Identity} */ (
    jspb.Message.getWrapperField(this, src_common_identity_identity_pb.Identity, 2));
};


/**
 * @param {?proto.loupe.common.identity.Identity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.IntegrationIdentity} returns this
*/
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.IntegrationIdentity} returns this
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional loupe.common.integration.auth.AuthInfo auth_info = 3;
 * @return {?proto.loupe.common.integration.auth.AuthInfo}
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.getAuthInfo = function() {
  return /** @type{?proto.loupe.common.integration.auth.AuthInfo} */ (
    jspb.Message.getWrapperField(this, src_common_integration_auth_auth_pb.AuthInfo, 3));
};


/**
 * @param {?proto.loupe.common.integration.auth.AuthInfo|undefined} value
 * @return {!proto.loupe.services.integration_invoker.IntegrationIdentity} returns this
*/
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.setAuthInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.IntegrationIdentity} returns this
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.clearAuthInfo = function() {
  return this.setAuthInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.hasAuthInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<string, loupe.common.tenant.mapping.v2.ObjectMapping> mappings = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.common.tenant.mapping.v2.ObjectMapping>}
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.getMappingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.common.tenant.mapping.v2.ObjectMapping>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.loupe.common.tenant.mapping.v2.ObjectMapping));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.integration_invoker.IntegrationIdentity} returns this
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.clearMappingsMap = function() {
  this.getMappingsMap().clear();
  return this;};


/**
 * optional bool is_test_instance = 5;
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.getIsTestInstance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.integration_invoker.IntegrationIdentity} returns this
 */
proto.loupe.services.integration_invoker.IntegrationIdentity.prototype.setIsTestInstance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.GetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.GetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.GetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.GetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.GetRequest}
 */
proto.loupe.services.integration_invoker.GetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.GetRequest;
  return proto.loupe.services.integration_invoker.GetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.GetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.GetRequest}
 */
proto.loupe.services.integration_invoker.GetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.GetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.GetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.GetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.GetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.GetRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.GetRequest} returns this
*/
proto.loupe.services.integration_invoker.GetRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.GetRequest} returns this
 */
proto.loupe.services.integration_invoker.GetRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.GetRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string object_key = 2;
 * @return {string}
 */
proto.loupe.services.integration_invoker.GetRequest.prototype.getObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.integration_invoker.GetRequest} returns this
 */
proto.loupe.services.integration_invoker.GetRequest.prototype.setObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.loupe.services.integration_invoker.GetRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.integration_invoker.GetRequest} returns this
 */
proto.loupe.services.integration_invoker.GetRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.GetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.GetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.GetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.GetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    object: (f = msg.getObject()) && src_common_integration_object_object_pb.IntegrationObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.GetResponse}
 */
proto.loupe.services.integration_invoker.GetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.GetResponse;
  return proto.loupe.services.integration_invoker.GetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.GetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.GetResponse}
 */
proto.loupe.services.integration_invoker.GetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = new src_common_integration_object_object_pb.IntegrationObject;
      reader.readMessage(value,src_common_integration_object_object_pb.IntegrationObject.deserializeBinaryFromReader);
      msg.setObject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.GetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.GetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.GetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.GetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_integration_object_object_pb.IntegrationObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.GetResponse.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.GetResponse} returns this
*/
proto.loupe.services.integration_invoker.GetResponse.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.GetResponse} returns this
 */
proto.loupe.services.integration_invoker.GetResponse.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.GetResponse.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.integration.object.IntegrationObject object = 2;
 * @return {?proto.loupe.common.integration.object.IntegrationObject}
 */
proto.loupe.services.integration_invoker.GetResponse.prototype.getObject = function() {
  return /** @type{?proto.loupe.common.integration.object.IntegrationObject} */ (
    jspb.Message.getWrapperField(this, src_common_integration_object_object_pb.IntegrationObject, 2));
};


/**
 * @param {?proto.loupe.common.integration.object.IntegrationObject|undefined} value
 * @return {!proto.loupe.services.integration_invoker.GetResponse} returns this
*/
proto.loupe.services.integration_invoker.GetResponse.prototype.setObject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.GetResponse} returns this
 */
proto.loupe.services.integration_invoker.GetResponse.prototype.clearObject = function() {
  return this.setObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.GetResponse.prototype.hasObject = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.ListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.ListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.ListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pager: (f = msg.getPager()) && src_common_integration_pager_pager_pb.IntegrationPager.toObject(includeInstance, f),
    sorter: (f = msg.getSorter()) && src_common_integration_sorter_sorter_pb.IntegrationSorter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.ListRequest}
 */
proto.loupe.services.integration_invoker.ListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.ListRequest;
  return proto.loupe.services.integration_invoker.ListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.ListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.ListRequest}
 */
proto.loupe.services.integration_invoker.ListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectKey(value);
      break;
    case 3:
      var value = new src_common_integration_pager_pager_pb.IntegrationPager;
      reader.readMessage(value,src_common_integration_pager_pager_pb.IntegrationPager.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    case 4:
      var value = new src_common_integration_sorter_sorter_pb.IntegrationSorter;
      reader.readMessage(value,src_common_integration_sorter_sorter_pb.IntegrationSorter.deserializeBinaryFromReader);
      msg.setSorter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.ListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.ListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.ListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_integration_pager_pager_pb.IntegrationPager.serializeBinaryToWriter
    );
  }
  f = message.getSorter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_integration_sorter_sorter_pb.IntegrationSorter.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.ListRequest} returns this
*/
proto.loupe.services.integration_invoker.ListRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.ListRequest} returns this
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string object_key = 2;
 * @return {string}
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.getObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.integration_invoker.ListRequest} returns this
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.setObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.integration.pager.IntegrationPager pager = 3;
 * @return {?proto.loupe.common.integration.pager.IntegrationPager}
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.getPager = function() {
  return /** @type{?proto.loupe.common.integration.pager.IntegrationPager} */ (
    jspb.Message.getWrapperField(this, src_common_integration_pager_pager_pb.IntegrationPager, 3));
};


/**
 * @param {?proto.loupe.common.integration.pager.IntegrationPager|undefined} value
 * @return {!proto.loupe.services.integration_invoker.ListRequest} returns this
*/
proto.loupe.services.integration_invoker.ListRequest.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.ListRequest} returns this
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.hasPager = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional loupe.common.integration.sorter.IntegrationSorter sorter = 4;
 * @return {?proto.loupe.common.integration.sorter.IntegrationSorter}
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.getSorter = function() {
  return /** @type{?proto.loupe.common.integration.sorter.IntegrationSorter} */ (
    jspb.Message.getWrapperField(this, src_common_integration_sorter_sorter_pb.IntegrationSorter, 4));
};


/**
 * @param {?proto.loupe.common.integration.sorter.IntegrationSorter|undefined} value
 * @return {!proto.loupe.services.integration_invoker.ListRequest} returns this
*/
proto.loupe.services.integration_invoker.ListRequest.prototype.setSorter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.ListRequest} returns this
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.clearSorter = function() {
  return this.setSorter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.ListRequest.prototype.hasSorter = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.integration_invoker.ListResponse.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.ListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.ListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.ListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pager: (f = msg.getPager()) && src_common_integration_pager_pager_pb.IntegrationPager.toObject(includeInstance, f),
    searcher: (f = msg.getSearcher()) && src_common_integration_searcher_searcher_pb.IntegrationSearcher.toObject(includeInstance, f),
    sorter: (f = msg.getSorter()) && src_common_integration_sorter_sorter_pb.IntegrationSorter.toObject(includeInstance, f),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    src_common_integration_object_object_pb.IntegrationObject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.ListResponse}
 */
proto.loupe.services.integration_invoker.ListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.ListResponse;
  return proto.loupe.services.integration_invoker.ListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.ListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.ListResponse}
 */
proto.loupe.services.integration_invoker.ListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectKey(value);
      break;
    case 3:
      var value = new src_common_integration_pager_pager_pb.IntegrationPager;
      reader.readMessage(value,src_common_integration_pager_pager_pb.IntegrationPager.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    case 4:
      var value = new src_common_integration_searcher_searcher_pb.IntegrationSearcher;
      reader.readMessage(value,src_common_integration_searcher_searcher_pb.IntegrationSearcher.deserializeBinaryFromReader);
      msg.setSearcher(value);
      break;
    case 5:
      var value = new src_common_integration_sorter_sorter_pb.IntegrationSorter;
      reader.readMessage(value,src_common_integration_sorter_sorter_pb.IntegrationSorter.deserializeBinaryFromReader);
      msg.setSorter(value);
      break;
    case 6:
      var value = new src_common_integration_object_object_pb.IntegrationObject;
      reader.readMessage(value,src_common_integration_object_object_pb.IntegrationObject.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.ListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.ListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.ListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_integration_pager_pager_pb.IntegrationPager.serializeBinaryToWriter
    );
  }
  f = message.getSearcher();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_integration_searcher_searcher_pb.IntegrationSearcher.serializeBinaryToWriter
    );
  }
  f = message.getSorter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_integration_sorter_sorter_pb.IntegrationSorter.serializeBinaryToWriter
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      src_common_integration_object_object_pb.IntegrationObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.ListResponse} returns this
*/
proto.loupe.services.integration_invoker.ListResponse.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.ListResponse} returns this
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string object_key = 2;
 * @return {string}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.getObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.integration_invoker.ListResponse} returns this
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.setObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.integration.pager.IntegrationPager pager = 3;
 * @return {?proto.loupe.common.integration.pager.IntegrationPager}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.getPager = function() {
  return /** @type{?proto.loupe.common.integration.pager.IntegrationPager} */ (
    jspb.Message.getWrapperField(this, src_common_integration_pager_pager_pb.IntegrationPager, 3));
};


/**
 * @param {?proto.loupe.common.integration.pager.IntegrationPager|undefined} value
 * @return {!proto.loupe.services.integration_invoker.ListResponse} returns this
*/
proto.loupe.services.integration_invoker.ListResponse.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.ListResponse} returns this
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.hasPager = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional loupe.common.integration.searcher.IntegrationSearcher searcher = 4;
 * @return {?proto.loupe.common.integration.searcher.IntegrationSearcher}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.getSearcher = function() {
  return /** @type{?proto.loupe.common.integration.searcher.IntegrationSearcher} */ (
    jspb.Message.getWrapperField(this, src_common_integration_searcher_searcher_pb.IntegrationSearcher, 4));
};


/**
 * @param {?proto.loupe.common.integration.searcher.IntegrationSearcher|undefined} value
 * @return {!proto.loupe.services.integration_invoker.ListResponse} returns this
*/
proto.loupe.services.integration_invoker.ListResponse.prototype.setSearcher = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.ListResponse} returns this
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.clearSearcher = function() {
  return this.setSearcher(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.hasSearcher = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional loupe.common.integration.sorter.IntegrationSorter sorter = 5;
 * @return {?proto.loupe.common.integration.sorter.IntegrationSorter}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.getSorter = function() {
  return /** @type{?proto.loupe.common.integration.sorter.IntegrationSorter} */ (
    jspb.Message.getWrapperField(this, src_common_integration_sorter_sorter_pb.IntegrationSorter, 5));
};


/**
 * @param {?proto.loupe.common.integration.sorter.IntegrationSorter|undefined} value
 * @return {!proto.loupe.services.integration_invoker.ListResponse} returns this
*/
proto.loupe.services.integration_invoker.ListResponse.prototype.setSorter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.ListResponse} returns this
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.clearSorter = function() {
  return this.setSorter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.hasSorter = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated loupe.common.integration.object.IntegrationObject results = 6;
 * @return {!Array<!proto.loupe.common.integration.object.IntegrationObject>}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.loupe.common.integration.object.IntegrationObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_integration_object_object_pb.IntegrationObject, 6));
};


/**
 * @param {!Array<!proto.loupe.common.integration.object.IntegrationObject>} value
 * @return {!proto.loupe.services.integration_invoker.ListResponse} returns this
*/
proto.loupe.services.integration_invoker.ListResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.common.integration.object.IntegrationObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.integration.object.IntegrationObject}
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.common.integration.object.IntegrationObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.integration_invoker.ListResponse} returns this
 */
proto.loupe.services.integration_invoker.ListResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.SearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.SearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.SearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pager: (f = msg.getPager()) && src_common_integration_pager_pager_pb.IntegrationPager.toObject(includeInstance, f),
    searcher: (f = msg.getSearcher()) && src_common_integration_searcher_searcher_pb.IntegrationSearcher.toObject(includeInstance, f),
    sorter: (f = msg.getSorter()) && src_common_integration_sorter_sorter_pb.IntegrationSorter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.SearchRequest}
 */
proto.loupe.services.integration_invoker.SearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.SearchRequest;
  return proto.loupe.services.integration_invoker.SearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.SearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.SearchRequest}
 */
proto.loupe.services.integration_invoker.SearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectKey(value);
      break;
    case 3:
      var value = new src_common_integration_pager_pager_pb.IntegrationPager;
      reader.readMessage(value,src_common_integration_pager_pager_pb.IntegrationPager.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    case 4:
      var value = new src_common_integration_searcher_searcher_pb.IntegrationSearcher;
      reader.readMessage(value,src_common_integration_searcher_searcher_pb.IntegrationSearcher.deserializeBinaryFromReader);
      msg.setSearcher(value);
      break;
    case 5:
      var value = new src_common_integration_sorter_sorter_pb.IntegrationSorter;
      reader.readMessage(value,src_common_integration_sorter_sorter_pb.IntegrationSorter.deserializeBinaryFromReader);
      msg.setSorter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.SearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.SearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.SearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_integration_pager_pager_pb.IntegrationPager.serializeBinaryToWriter
    );
  }
  f = message.getSearcher();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_integration_searcher_searcher_pb.IntegrationSearcher.serializeBinaryToWriter
    );
  }
  f = message.getSorter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_integration_sorter_sorter_pb.IntegrationSorter.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.SearchRequest} returns this
*/
proto.loupe.services.integration_invoker.SearchRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.SearchRequest} returns this
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string object_key = 2;
 * @return {string}
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.getObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.integration_invoker.SearchRequest} returns this
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.setObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.integration.pager.IntegrationPager pager = 3;
 * @return {?proto.loupe.common.integration.pager.IntegrationPager}
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.getPager = function() {
  return /** @type{?proto.loupe.common.integration.pager.IntegrationPager} */ (
    jspb.Message.getWrapperField(this, src_common_integration_pager_pager_pb.IntegrationPager, 3));
};


/**
 * @param {?proto.loupe.common.integration.pager.IntegrationPager|undefined} value
 * @return {!proto.loupe.services.integration_invoker.SearchRequest} returns this
*/
proto.loupe.services.integration_invoker.SearchRequest.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.SearchRequest} returns this
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.hasPager = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional loupe.common.integration.searcher.IntegrationSearcher searcher = 4;
 * @return {?proto.loupe.common.integration.searcher.IntegrationSearcher}
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.getSearcher = function() {
  return /** @type{?proto.loupe.common.integration.searcher.IntegrationSearcher} */ (
    jspb.Message.getWrapperField(this, src_common_integration_searcher_searcher_pb.IntegrationSearcher, 4));
};


/**
 * @param {?proto.loupe.common.integration.searcher.IntegrationSearcher|undefined} value
 * @return {!proto.loupe.services.integration_invoker.SearchRequest} returns this
*/
proto.loupe.services.integration_invoker.SearchRequest.prototype.setSearcher = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.SearchRequest} returns this
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.clearSearcher = function() {
  return this.setSearcher(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.hasSearcher = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional loupe.common.integration.sorter.IntegrationSorter sorter = 5;
 * @return {?proto.loupe.common.integration.sorter.IntegrationSorter}
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.getSorter = function() {
  return /** @type{?proto.loupe.common.integration.sorter.IntegrationSorter} */ (
    jspb.Message.getWrapperField(this, src_common_integration_sorter_sorter_pb.IntegrationSorter, 5));
};


/**
 * @param {?proto.loupe.common.integration.sorter.IntegrationSorter|undefined} value
 * @return {!proto.loupe.services.integration_invoker.SearchRequest} returns this
*/
proto.loupe.services.integration_invoker.SearchRequest.prototype.setSorter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.SearchRequest} returns this
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.clearSorter = function() {
  return this.setSorter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.SearchRequest.prototype.hasSorter = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.NextRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.NextRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.NextRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pager: (f = msg.getPager()) && src_common_integration_pager_pager_pb.IntegrationPager.toObject(includeInstance, f),
    searcher: (f = msg.getSearcher()) && src_common_integration_searcher_searcher_pb.IntegrationSearcher.toObject(includeInstance, f),
    sorter: (f = msg.getSorter()) && src_common_integration_sorter_sorter_pb.IntegrationSorter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.NextRequest}
 */
proto.loupe.services.integration_invoker.NextRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.NextRequest;
  return proto.loupe.services.integration_invoker.NextRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.NextRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.NextRequest}
 */
proto.loupe.services.integration_invoker.NextRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectKey(value);
      break;
    case 3:
      var value = new src_common_integration_pager_pager_pb.IntegrationPager;
      reader.readMessage(value,src_common_integration_pager_pager_pb.IntegrationPager.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    case 4:
      var value = new src_common_integration_searcher_searcher_pb.IntegrationSearcher;
      reader.readMessage(value,src_common_integration_searcher_searcher_pb.IntegrationSearcher.deserializeBinaryFromReader);
      msg.setSearcher(value);
      break;
    case 5:
      var value = new src_common_integration_sorter_sorter_pb.IntegrationSorter;
      reader.readMessage(value,src_common_integration_sorter_sorter_pb.IntegrationSorter.deserializeBinaryFromReader);
      msg.setSorter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.NextRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.NextRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.NextRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_integration_pager_pager_pb.IntegrationPager.serializeBinaryToWriter
    );
  }
  f = message.getSearcher();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_integration_searcher_searcher_pb.IntegrationSearcher.serializeBinaryToWriter
    );
  }
  f = message.getSorter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_integration_sorter_sorter_pb.IntegrationSorter.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.NextRequest} returns this
*/
proto.loupe.services.integration_invoker.NextRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.NextRequest} returns this
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string object_key = 2;
 * @return {string}
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.getObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.integration_invoker.NextRequest} returns this
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.setObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.integration.pager.IntegrationPager pager = 3;
 * @return {?proto.loupe.common.integration.pager.IntegrationPager}
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.getPager = function() {
  return /** @type{?proto.loupe.common.integration.pager.IntegrationPager} */ (
    jspb.Message.getWrapperField(this, src_common_integration_pager_pager_pb.IntegrationPager, 3));
};


/**
 * @param {?proto.loupe.common.integration.pager.IntegrationPager|undefined} value
 * @return {!proto.loupe.services.integration_invoker.NextRequest} returns this
*/
proto.loupe.services.integration_invoker.NextRequest.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.NextRequest} returns this
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.hasPager = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional loupe.common.integration.searcher.IntegrationSearcher searcher = 4;
 * @return {?proto.loupe.common.integration.searcher.IntegrationSearcher}
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.getSearcher = function() {
  return /** @type{?proto.loupe.common.integration.searcher.IntegrationSearcher} */ (
    jspb.Message.getWrapperField(this, src_common_integration_searcher_searcher_pb.IntegrationSearcher, 4));
};


/**
 * @param {?proto.loupe.common.integration.searcher.IntegrationSearcher|undefined} value
 * @return {!proto.loupe.services.integration_invoker.NextRequest} returns this
*/
proto.loupe.services.integration_invoker.NextRequest.prototype.setSearcher = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.NextRequest} returns this
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.clearSearcher = function() {
  return this.setSearcher(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.hasSearcher = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional loupe.common.integration.sorter.IntegrationSorter sorter = 5;
 * @return {?proto.loupe.common.integration.sorter.IntegrationSorter}
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.getSorter = function() {
  return /** @type{?proto.loupe.common.integration.sorter.IntegrationSorter} */ (
    jspb.Message.getWrapperField(this, src_common_integration_sorter_sorter_pb.IntegrationSorter, 5));
};


/**
 * @param {?proto.loupe.common.integration.sorter.IntegrationSorter|undefined} value
 * @return {!proto.loupe.services.integration_invoker.NextRequest} returns this
*/
proto.loupe.services.integration_invoker.NextRequest.prototype.setSorter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.NextRequest} returns this
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.clearSorter = function() {
  return this.setSorter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.NextRequest.prototype.hasSorter = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.DescribeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.DescribeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.DescribeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.DescribeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.DescribeRequest}
 */
proto.loupe.services.integration_invoker.DescribeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.DescribeRequest;
  return proto.loupe.services.integration_invoker.DescribeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.DescribeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.DescribeRequest}
 */
proto.loupe.services.integration_invoker.DescribeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.DescribeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.DescribeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.DescribeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.DescribeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.DescribeRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.DescribeRequest} returns this
*/
proto.loupe.services.integration_invoker.DescribeRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.DescribeRequest} returns this
 */
proto.loupe.services.integration_invoker.DescribeRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.DescribeRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string object_key = 2;
 * @return {string}
 */
proto.loupe.services.integration_invoker.DescribeRequest.prototype.getObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.integration_invoker.DescribeRequest} returns this
 */
proto.loupe.services.integration_invoker.DescribeRequest.prototype.setObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.DescribeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.DescribeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.DescribeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.DescribeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    rawDescription: msg.getRawDescription_asB64(),
    parsedDescription: (f = msg.getParsedDescription()) && src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.DescribeResponse}
 */
proto.loupe.services.integration_invoker.DescribeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.DescribeResponse;
  return proto.loupe.services.integration_invoker.DescribeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.DescribeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.DescribeResponse}
 */
proto.loupe.services.integration_invoker.DescribeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRawDescription(value);
      break;
    case 3:
      var value = new src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping;
      reader.readMessage(value,src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.deserializeBinaryFromReader);
      msg.setParsedDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.DescribeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.DescribeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.DescribeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.DescribeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getRawDescription_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getParsedDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.DescribeResponse.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.DescribeResponse} returns this
*/
proto.loupe.services.integration_invoker.DescribeResponse.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.DescribeResponse} returns this
 */
proto.loupe.services.integration_invoker.DescribeResponse.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.DescribeResponse.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes raw_description = 2;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.integration_invoker.DescribeResponse.prototype.getRawDescription = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes raw_description = 2;
 * This is a type-conversion wrapper around `getRawDescription()`
 * @return {string}
 */
proto.loupe.services.integration_invoker.DescribeResponse.prototype.getRawDescription_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRawDescription()));
};


/**
 * optional bytes raw_description = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRawDescription()`
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.DescribeResponse.prototype.getRawDescription_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRawDescription()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.integration_invoker.DescribeResponse} returns this
 */
proto.loupe.services.integration_invoker.DescribeResponse.prototype.setRawDescription = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional loupe.common.tenant.mapping.v2.ObjectMapping parsed_description = 3;
 * @return {?proto.loupe.common.tenant.mapping.v2.ObjectMapping}
 */
proto.loupe.services.integration_invoker.DescribeResponse.prototype.getParsedDescription = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.ObjectMapping} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping, 3));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.ObjectMapping|undefined} value
 * @return {!proto.loupe.services.integration_invoker.DescribeResponse} returns this
*/
proto.loupe.services.integration_invoker.DescribeResponse.prototype.setParsedDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.DescribeResponse} returns this
 */
proto.loupe.services.integration_invoker.DescribeResponse.prototype.clearParsedDescription = function() {
  return this.setParsedDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.DescribeResponse.prototype.hasParsedDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.DescribeManyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.DescribeManyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectKeysList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.DescribeManyRequest}
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.DescribeManyRequest;
  return proto.loupe.services.integration_invoker.DescribeManyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.DescribeManyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.DescribeManyRequest}
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addObjectKeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.DescribeManyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.DescribeManyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.DescribeManyRequest} returns this
*/
proto.loupe.services.integration_invoker.DescribeManyRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.DescribeManyRequest} returns this
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string object_keys = 2;
 * @return {!Array<string>}
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.prototype.getObjectKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.integration_invoker.DescribeManyRequest} returns this
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.prototype.setObjectKeysList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.integration_invoker.DescribeManyRequest} returns this
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.prototype.addObjectKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.integration_invoker.DescribeManyRequest} returns this
 */
proto.loupe.services.integration_invoker.DescribeManyRequest.prototype.clearObjectKeysList = function() {
  return this.setObjectKeysList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.DescribeManyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.DescribeManyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    rawDescriptionList: msg.getRawDescriptionList_asB64(),
    parsedDescriptionList: jspb.Message.toObjectList(msg.getParsedDescriptionList(),
    src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.DescribeManyResponse}
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.DescribeManyResponse;
  return proto.loupe.services.integration_invoker.DescribeManyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.DescribeManyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.DescribeManyResponse}
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addRawDescription(value);
      break;
    case 3:
      var value = new src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping;
      reader.readMessage(value,src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.deserializeBinaryFromReader);
      msg.addParsedDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.DescribeManyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.DescribeManyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getRawDescriptionList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      2,
      f
    );
  }
  f = message.getParsedDescriptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.DescribeManyResponse} returns this
*/
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.DescribeManyResponse} returns this
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated bytes raw_description = 2;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.getRawDescriptionList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * repeated bytes raw_description = 2;
 * This is a type-conversion wrapper around `getRawDescriptionList()`
 * @return {!Array<string>}
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.getRawDescriptionList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getRawDescriptionList()));
};


/**
 * repeated bytes raw_description = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRawDescriptionList()`
 * @return {!Array<!Uint8Array>}
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.getRawDescriptionList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getRawDescriptionList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.loupe.services.integration_invoker.DescribeManyResponse} returns this
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.setRawDescriptionList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.integration_invoker.DescribeManyResponse} returns this
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.addRawDescription = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.integration_invoker.DescribeManyResponse} returns this
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.clearRawDescriptionList = function() {
  return this.setRawDescriptionList([]);
};


/**
 * repeated loupe.common.tenant.mapping.v2.ObjectMapping parsed_description = 3;
 * @return {!Array<!proto.loupe.common.tenant.mapping.v2.ObjectMapping>}
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.getParsedDescriptionList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.mapping.v2.ObjectMapping>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping, 3));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.mapping.v2.ObjectMapping>} value
 * @return {!proto.loupe.services.integration_invoker.DescribeManyResponse} returns this
*/
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.setParsedDescriptionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.tenant.mapping.v2.ObjectMapping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.mapping.v2.ObjectMapping}
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.addParsedDescription = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.tenant.mapping.v2.ObjectMapping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.integration_invoker.DescribeManyResponse} returns this
 */
proto.loupe.services.integration_invoker.DescribeManyResponse.prototype.clearParsedDescriptionList = function() {
  return this.setParsedDescriptionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.integration_invoker.InsertRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.InsertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.InsertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.InsertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.InsertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    src_common_integration_object_object_pb.IntegrationObject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.InsertRequest}
 */
proto.loupe.services.integration_invoker.InsertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.InsertRequest;
  return proto.loupe.services.integration_invoker.InsertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.InsertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.InsertRequest}
 */
proto.loupe.services.integration_invoker.InsertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = new src_common_integration_object_object_pb.IntegrationObject;
      reader.readMessage(value,src_common_integration_object_object_pb.IntegrationObject.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.InsertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.InsertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.InsertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.InsertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_integration_object_object_pb.IntegrationObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.InsertRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.InsertRequest} returns this
*/
proto.loupe.services.integration_invoker.InsertRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.InsertRequest} returns this
 */
proto.loupe.services.integration_invoker.InsertRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.InsertRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated loupe.common.integration.object.IntegrationObject objects = 2;
 * @return {!Array<!proto.loupe.common.integration.object.IntegrationObject>}
 */
proto.loupe.services.integration_invoker.InsertRequest.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.loupe.common.integration.object.IntegrationObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_integration_object_object_pb.IntegrationObject, 2));
};


/**
 * @param {!Array<!proto.loupe.common.integration.object.IntegrationObject>} value
 * @return {!proto.loupe.services.integration_invoker.InsertRequest} returns this
*/
proto.loupe.services.integration_invoker.InsertRequest.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.integration.object.IntegrationObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.integration.object.IntegrationObject}
 */
proto.loupe.services.integration_invoker.InsertRequest.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.integration.object.IntegrationObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.integration_invoker.InsertRequest} returns this
 */
proto.loupe.services.integration_invoker.InsertRequest.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.integration_invoker.InsertResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.InsertResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.InsertResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.InsertResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.InsertResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    src_common_integration_object_object_pb.IntegrationObject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.InsertResponse}
 */
proto.loupe.services.integration_invoker.InsertResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.InsertResponse;
  return proto.loupe.services.integration_invoker.InsertResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.InsertResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.InsertResponse}
 */
proto.loupe.services.integration_invoker.InsertResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = new src_common_integration_object_object_pb.IntegrationObject;
      reader.readMessage(value,src_common_integration_object_object_pb.IntegrationObject.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.InsertResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.InsertResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.InsertResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.InsertResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_integration_object_object_pb.IntegrationObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.InsertResponse.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.InsertResponse} returns this
*/
proto.loupe.services.integration_invoker.InsertResponse.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.InsertResponse} returns this
 */
proto.loupe.services.integration_invoker.InsertResponse.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.InsertResponse.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated loupe.common.integration.object.IntegrationObject results = 2;
 * @return {!Array<!proto.loupe.common.integration.object.IntegrationObject>}
 */
proto.loupe.services.integration_invoker.InsertResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.loupe.common.integration.object.IntegrationObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_integration_object_object_pb.IntegrationObject, 2));
};


/**
 * @param {!Array<!proto.loupe.common.integration.object.IntegrationObject>} value
 * @return {!proto.loupe.services.integration_invoker.InsertResponse} returns this
*/
proto.loupe.services.integration_invoker.InsertResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.integration.object.IntegrationObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.integration.object.IntegrationObject}
 */
proto.loupe.services.integration_invoker.InsertResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.integration.object.IntegrationObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.integration_invoker.InsertResponse} returns this
 */
proto.loupe.services.integration_invoker.InsertResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.integration_invoker.PatchRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.PatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.PatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.PatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.PatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    src_common_integration_object_object_pb.IntegrationObject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.PatchRequest}
 */
proto.loupe.services.integration_invoker.PatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.PatchRequest;
  return proto.loupe.services.integration_invoker.PatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.PatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.PatchRequest}
 */
proto.loupe.services.integration_invoker.PatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = new src_common_integration_object_object_pb.IntegrationObject;
      reader.readMessage(value,src_common_integration_object_object_pb.IntegrationObject.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.PatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.PatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.PatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.PatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_integration_object_object_pb.IntegrationObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.PatchRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.PatchRequest} returns this
*/
proto.loupe.services.integration_invoker.PatchRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.PatchRequest} returns this
 */
proto.loupe.services.integration_invoker.PatchRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.PatchRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated loupe.common.integration.object.IntegrationObject objects = 2;
 * @return {!Array<!proto.loupe.common.integration.object.IntegrationObject>}
 */
proto.loupe.services.integration_invoker.PatchRequest.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.loupe.common.integration.object.IntegrationObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_integration_object_object_pb.IntegrationObject, 2));
};


/**
 * @param {!Array<!proto.loupe.common.integration.object.IntegrationObject>} value
 * @return {!proto.loupe.services.integration_invoker.PatchRequest} returns this
*/
proto.loupe.services.integration_invoker.PatchRequest.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.integration.object.IntegrationObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.integration.object.IntegrationObject}
 */
proto.loupe.services.integration_invoker.PatchRequest.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.integration.object.IntegrationObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.integration_invoker.PatchRequest} returns this
 */
proto.loupe.services.integration_invoker.PatchRequest.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.integration_invoker.PatchResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.PatchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.PatchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.PatchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.PatchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    src_common_integration_object_object_pb.IntegrationObject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.PatchResponse}
 */
proto.loupe.services.integration_invoker.PatchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.PatchResponse;
  return proto.loupe.services.integration_invoker.PatchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.PatchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.PatchResponse}
 */
proto.loupe.services.integration_invoker.PatchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = new src_common_integration_object_object_pb.IntegrationObject;
      reader.readMessage(value,src_common_integration_object_object_pb.IntegrationObject.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.PatchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.PatchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.PatchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.PatchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_integration_object_object_pb.IntegrationObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.PatchResponse.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.PatchResponse} returns this
*/
proto.loupe.services.integration_invoker.PatchResponse.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.PatchResponse} returns this
 */
proto.loupe.services.integration_invoker.PatchResponse.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.PatchResponse.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated loupe.common.integration.object.IntegrationObject results = 2;
 * @return {!Array<!proto.loupe.common.integration.object.IntegrationObject>}
 */
proto.loupe.services.integration_invoker.PatchResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.loupe.common.integration.object.IntegrationObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_integration_object_object_pb.IntegrationObject, 2));
};


/**
 * @param {!Array<!proto.loupe.common.integration.object.IntegrationObject>} value
 * @return {!proto.loupe.services.integration_invoker.PatchResponse} returns this
*/
proto.loupe.services.integration_invoker.PatchResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.integration.object.IntegrationObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.integration.object.IntegrationObject}
 */
proto.loupe.services.integration_invoker.PatchResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.integration.object.IntegrationObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.integration_invoker.PatchResponse} returns this
 */
proto.loupe.services.integration_invoker.PatchResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.integration_invoker.DeleteRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.DeleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.DeleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.DeleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.DeleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    idsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.DeleteRequest}
 */
proto.loupe.services.integration_invoker.DeleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.DeleteRequest;
  return proto.loupe.services.integration_invoker.DeleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.DeleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.DeleteRequest}
 */
proto.loupe.services.integration_invoker.DeleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.DeleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.DeleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.DeleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.DeleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.DeleteRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.DeleteRequest} returns this
*/
proto.loupe.services.integration_invoker.DeleteRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.DeleteRequest} returns this
 */
proto.loupe.services.integration_invoker.DeleteRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.DeleteRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string object_key = 2;
 * @return {string}
 */
proto.loupe.services.integration_invoker.DeleteRequest.prototype.getObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.integration_invoker.DeleteRequest} returns this
 */
proto.loupe.services.integration_invoker.DeleteRequest.prototype.setObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string ids = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.integration_invoker.DeleteRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.integration_invoker.DeleteRequest} returns this
 */
proto.loupe.services.integration_invoker.DeleteRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.integration_invoker.DeleteRequest} returns this
 */
proto.loupe.services.integration_invoker.DeleteRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.integration_invoker.DeleteRequest} returns this
 */
proto.loupe.services.integration_invoker.DeleteRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.DeleteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.DeleteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.DeleteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.DeleteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    affectedCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.DeleteResponse}
 */
proto.loupe.services.integration_invoker.DeleteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.DeleteResponse;
  return proto.loupe.services.integration_invoker.DeleteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.DeleteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.DeleteResponse}
 */
proto.loupe.services.integration_invoker.DeleteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAffectedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.DeleteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.DeleteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.DeleteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.DeleteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getAffectedCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.DeleteResponse.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.DeleteResponse} returns this
*/
proto.loupe.services.integration_invoker.DeleteResponse.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.DeleteResponse} returns this
 */
proto.loupe.services.integration_invoker.DeleteResponse.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.DeleteResponse.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 affected_count = 2;
 * @return {number}
 */
proto.loupe.services.integration_invoker.DeleteResponse.prototype.getAffectedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.integration_invoker.DeleteResponse} returns this
 */
proto.loupe.services.integration_invoker.DeleteResponse.prototype.setAffectedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    raw: msg.getRaw_asB64(),
    parsed: (f = msg.getParsed()) && src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse}
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.GetObjectDescriptionResponse;
  return proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse}
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectKey(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRaw(value);
      break;
    case 4:
      var value = new src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping;
      reader.readMessage(value,src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.deserializeBinaryFromReader);
      msg.setParsed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRaw_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getParsed();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse} returns this
*/
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse} returns this
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string object_key = 2;
 * @return {string}
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.getObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse} returns this
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.setObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes raw = 3;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.getRaw = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes raw = 3;
 * This is a type-conversion wrapper around `getRaw()`
 * @return {string}
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.getRaw_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRaw()));
};


/**
 * optional bytes raw = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRaw()`
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.getRaw_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRaw()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse} returns this
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.setRaw = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional loupe.common.tenant.mapping.v2.ObjectMapping parsed = 4;
 * @return {?proto.loupe.common.tenant.mapping.v2.ObjectMapping}
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.getParsed = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.ObjectMapping} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping, 4));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.ObjectMapping|undefined} value
 * @return {!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse} returns this
*/
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.setParsed = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.GetObjectDescriptionResponse} returns this
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.clearParsed = function() {
  return this.setParsed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.GetObjectDescriptionResponse.prototype.hasParsed = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    raw: msg.getRaw_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest;
  return proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectKey(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRaw(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRaw_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest} returns this
*/
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest} returns this
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string object_key = 2;
 * @return {string}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.prototype.getObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest} returns this
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.prototype.setObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes raw = 3;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.prototype.getRaw = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes raw = 3;
 * This is a type-conversion wrapper around `getRaw()`
 * @return {string}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.prototype.getRaw_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRaw()));
};


/**
 * optional bytes raw = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRaw()`
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.prototype.getRaw_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRaw()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest} returns this
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaRequest.prototype.setRaw = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parsed: (f = msg.getParsed()) && src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse;
  return proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectKey(value);
      break;
    case 3:
      var value = new src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping;
      reader.readMessage(value,src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.deserializeBinaryFromReader);
      msg.setParsed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParsed();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse} returns this
*/
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse} returns this
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string object_key = 2;
 * @return {string}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.prototype.getObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse} returns this
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.prototype.setObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.tenant.mapping.v2.ObjectMapping parsed = 3;
 * @return {?proto.loupe.common.tenant.mapping.v2.ObjectMapping}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.prototype.getParsed = function() {
  return /** @type{?proto.loupe.common.tenant.mapping.v2.ObjectMapping} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_mapping_v2_object_mapping_pb.ObjectMapping, 3));
};


/**
 * @param {?proto.loupe.common.tenant.mapping.v2.ObjectMapping|undefined} value
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse} returns this
*/
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.prototype.setParsed = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse} returns this
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.prototype.clearParsed = function() {
  return this.setParsed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.ParseObjectRawSchemaResponse.prototype.hasParsed = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.ListObjectsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.ListObjectsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.ListObjectsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.ListObjectsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.ListObjectsRequest}
 */
proto.loupe.services.integration_invoker.ListObjectsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.ListObjectsRequest;
  return proto.loupe.services.integration_invoker.ListObjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.ListObjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.ListObjectsRequest}
 */
proto.loupe.services.integration_invoker.ListObjectsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.ListObjectsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.ListObjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.ListObjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.ListObjectsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.ListObjectsRequest.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.ListObjectsRequest} returns this
*/
proto.loupe.services.integration_invoker.ListObjectsRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.ListObjectsRequest} returns this
 */
proto.loupe.services.integration_invoker.ListObjectsRequest.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.ListObjectsRequest.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.integration_invoker.ListObjectsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.integration_invoker.ListObjectsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.integration_invoker.ListObjectsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.ListObjectsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: (f = msg.getIdentity()) && proto.loupe.services.integration_invoker.IntegrationIdentity.toObject(includeInstance, f),
    objectsMap: (f = msg.getObjectsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.integration_invoker.ListObjectsResponse}
 */
proto.loupe.services.integration_invoker.ListObjectsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.integration_invoker.ListObjectsResponse;
  return proto.loupe.services.integration_invoker.ListObjectsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.integration_invoker.ListObjectsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.integration_invoker.ListObjectsResponse}
 */
proto.loupe.services.integration_invoker.ListObjectsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.integration_invoker.IntegrationIdentity;
      reader.readMessage(value,proto.loupe.services.integration_invoker.IntegrationIdentity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 2:
      var value = msg.getObjectsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.integration_invoker.ListObjectsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.integration_invoker.ListObjectsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.integration_invoker.ListObjectsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.integration_invoker.ListObjectsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.integration_invoker.IntegrationIdentity.serializeBinaryToWriter
    );
  }
  f = message.getObjectsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional IntegrationIdentity identity = 1;
 * @return {?proto.loupe.services.integration_invoker.IntegrationIdentity}
 */
proto.loupe.services.integration_invoker.ListObjectsResponse.prototype.getIdentity = function() {
  return /** @type{?proto.loupe.services.integration_invoker.IntegrationIdentity} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.integration_invoker.IntegrationIdentity, 1));
};


/**
 * @param {?proto.loupe.services.integration_invoker.IntegrationIdentity|undefined} value
 * @return {!proto.loupe.services.integration_invoker.ListObjectsResponse} returns this
*/
proto.loupe.services.integration_invoker.ListObjectsResponse.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.integration_invoker.ListObjectsResponse} returns this
 */
proto.loupe.services.integration_invoker.ListObjectsResponse.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.integration_invoker.ListObjectsResponse.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<string, string> objects = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.services.integration_invoker.ListObjectsResponse.prototype.getObjectsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.integration_invoker.ListObjectsResponse} returns this
 */
proto.loupe.services.integration_invoker.ListObjectsResponse.prototype.clearObjectsMap = function() {
  this.getObjectsMap().clear();
  return this;};


goog.object.extend(exports, proto.loupe.services.integration_invoker);
