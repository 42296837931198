// source: src/common/opportunity/opportunity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_contact_contact_pb = require('../../../src/common/contact/contact_pb.js');
goog.object.extend(proto, src_common_contact_contact_pb);
var src_common_opportunity_lineitem_line_item_pb = require('../../../src/common/opportunity/lineitem/line_item_pb.js');
goog.object.extend(proto, src_common_opportunity_lineitem_line_item_pb);
var src_common_opportunity_change_change_pb = require('../../../src/common/opportunity/change/change_pb.js');
goog.object.extend(proto, src_common_opportunity_change_change_pb);
var src_common_person_person_pb = require('../../../src/common/person/person_pb.js');
goog.object.extend(proto, src_common_person_person_pb);
var src_common_crmmetadata_crm_metadata_pb = require('../../../src/common/crmmetadata/crm_metadata_pb.js');
goog.object.extend(proto, src_common_crmmetadata_crm_metadata_pb);
goog.exportSymbol('proto.loupe.common.opportunity.Opportunity', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.opportunity.Opportunity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.opportunity.Opportunity.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.opportunity.Opportunity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.opportunity.Opportunity.displayName = 'proto.loupe.common.opportunity.Opportunity';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.opportunity.Opportunity.repeatedFields_ = [27,28,29,38];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.opportunity.Opportunity.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.opportunity.Opportunity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.opportunity.Opportunity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.opportunity.Opportunity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    teamid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    accountId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    recordTypeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    stageName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    probability: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    closeDate: (f = msg.getCloseDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 12, ""),
    leadSource: jspb.Message.getFieldWithDefault(msg, 13, ""),
    isClosed: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    isWon: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    forecastCategory: jspb.Message.getFieldWithDefault(msg, 16, ""),
    forecastCategoryName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    pricebook2Id: jspb.Message.getFieldWithDefault(msg, 18, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    createdDate: (f = msg.getCreatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 21, ""),
    lastModifiedDate: (f = msg.getLastModifiedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastActivityDate: (f = msg.getLastActivityDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    contractLength: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    nextStep: jspb.Message.getFieldWithDefault(msg, 25, ""),
    website: jspb.Message.getFieldWithDefault(msg, 26, ""),
    stagesList: (f = jspb.Message.getRepeatedField(msg, 27)) == null ? undefined : f,
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    src_common_opportunity_lineitem_line_item_pb.LineItem.toObject, includeInstance),
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    src_common_contact_contact_pb.Contact.toObject, includeInstance),
    change: (f = msg.getChange()) && src_common_opportunity_change_change_pb.Change.toObject(includeInstance, f),
    insightCount: jspb.Message.getFieldWithDefault(msg, 31, 0),
    status: jspb.Message.getFieldWithDefault(msg, 32, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 33, 0.0),
    currentPeriod: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    owner: (f = msg.getOwner()) && src_common_person_person_pb.Person.toObject(includeInstance, f),
    isforecasted: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    forecastbucket: jspb.Message.getFieldWithDefault(msg, 37, ""),
    versionsList: jspb.Message.toObjectList(msg.getVersionsList(),
    proto.loupe.common.opportunity.Opportunity.toObject, includeInstance),
    crmMetadata: (f = msg.getCrmMetadata()) && src_common_crmmetadata_crm_metadata_pb.CrmMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.opportunity.Opportunity}
 */
proto.loupe.common.opportunity.Opportunity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.opportunity.Opportunity;
  return proto.loupe.common.opportunity.Opportunity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.opportunity.Opportunity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.opportunity.Opportunity}
 */
proto.loupe.common.opportunity.Opportunity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordTypeId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStageName(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProbability(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCloseDate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeadSource(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsClosed(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsWon(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastCategory(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastCategoryName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPricebook2Id(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedDate(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModifiedDate(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastActivityDate(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContractLength(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextStep(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.addStages(value);
      break;
    case 28:
      var value = new src_common_opportunity_lineitem_line_item_pb.LineItem;
      reader.readMessage(value,src_common_opportunity_lineitem_line_item_pb.LineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 29:
      var value = new src_common_contact_contact_pb.Contact;
      reader.readMessage(value,src_common_contact_contact_pb.Contact.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    case 30:
      var value = new src_common_opportunity_change_change_pb.Change;
      reader.readMessage(value,src_common_opportunity_change_change_pb.Change.deserializeBinaryFromReader);
      msg.setChange(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInsightCount(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCurrentPeriod(value);
      break;
    case 35:
      var value = new src_common_person_person_pb.Person;
      reader.readMessage(value,src_common_person_person_pb.Person.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsforecasted(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastbucket(value);
      break;
    case 38:
      var value = new proto.loupe.common.opportunity.Opportunity;
      reader.readMessage(value,proto.loupe.common.opportunity.Opportunity.deserializeBinaryFromReader);
      msg.addVersions(value);
      break;
    case 39:
      var value = new src_common_crmmetadata_crm_metadata_pb.CrmMetadata;
      reader.readMessage(value,src_common_crmmetadata_crm_metadata_pb.CrmMetadata.deserializeBinaryFromReader);
      msg.setCrmMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.opportunity.Opportunity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.opportunity.Opportunity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.opportunity.Opportunity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.opportunity.Opportunity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTeamid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRecordTypeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStageName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getProbability();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getCloseDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLeadSource();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIsClosed();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsWon();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getForecastCategory();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getForecastCategoryName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPricebook2Id();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCreatedDate();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getLastModifiedDate();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastActivityDate();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getContractLength();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getNextStep();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getStagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      27,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      src_common_opportunity_lineitem_line_item_pb.LineItem.serializeBinaryToWriter
    );
  }
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      29,
      f,
      src_common_contact_contact_pb.Contact.serializeBinaryToWriter
    );
  }
  f = message.getChange();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      src_common_opportunity_change_change_pb.Change.serializeBinaryToWriter
    );
  }
  f = message.getInsightCount();
  if (f !== 0) {
    writer.writeInt64(
      31,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      33,
      f
    );
  }
  f = message.getCurrentPeriod();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      src_common_person_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getIsforecasted();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getForecastbucket();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      38,
      f,
      proto.loupe.common.opportunity.Opportunity.serializeBinaryToWriter
    );
  }
  f = message.getCrmMetadata();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      src_common_crmmetadata_crm_metadata_pb.CrmMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string teamId = 2;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getTeamid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setTeamid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_deleted = 3;
 * @return {boolean}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string account_id = 4;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string record_type_id = 5;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getRecordTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setRecordTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string stage_name = 8;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getStageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setStageName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double amount = 9;
 * @return {number}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double probability = 10;
 * @return {number}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getProbability = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setProbability = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp close_date = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getCloseDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
*/
proto.loupe.common.opportunity.Opportunity.prototype.setCloseDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.clearCloseDate = function() {
  return this.setCloseDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.opportunity.Opportunity.prototype.hasCloseDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string type = 12;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string lead_source = 13;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getLeadSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setLeadSource = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool is_closed = 14;
 * @return {boolean}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getIsClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setIsClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool is_won = 15;
 * @return {boolean}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getIsWon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setIsWon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string forecast_category = 16;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getForecastCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setForecastCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string forecast_category_name = 17;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getForecastCategoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setForecastCategoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string pricebook2_id = 18;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getPricebook2Id = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setPricebook2Id = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string owner_id = 19;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional google.protobuf.Timestamp created_date = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getCreatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
*/
proto.loupe.common.opportunity.Opportunity.prototype.setCreatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.clearCreatedDate = function() {
  return this.setCreatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.opportunity.Opportunity.prototype.hasCreatedDate = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string created_by_id = 21;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional google.protobuf.Timestamp last_modified_date = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getLastModifiedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
*/
proto.loupe.common.opportunity.Opportunity.prototype.setLastModifiedDate = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.clearLastModifiedDate = function() {
  return this.setLastModifiedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.opportunity.Opportunity.prototype.hasLastModifiedDate = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.Timestamp last_activity_date = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getLastActivityDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
*/
proto.loupe.common.opportunity.Opportunity.prototype.setLastActivityDate = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.clearLastActivityDate = function() {
  return this.setLastActivityDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.opportunity.Opportunity.prototype.hasLastActivityDate = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional double contract_length = 24;
 * @return {number}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getContractLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setContractLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional string next_step = 25;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getNextStep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setNextStep = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string website = 26;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * repeated string stages = 27;
 * @return {!Array<string>}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getStagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 27));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setStagesList = function(value) {
  return jspb.Message.setField(this, 27, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.addStages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 27, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.clearStagesList = function() {
  return this.setStagesList([]);
};


/**
 * repeated lineitem.LineItem line_items = 28;
 * @return {!Array<!proto.loupe.common.opportunity.lineitem.LineItem>}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.loupe.common.opportunity.lineitem.LineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_opportunity_lineitem_line_item_pb.LineItem, 28));
};


/**
 * @param {!Array<!proto.loupe.common.opportunity.lineitem.LineItem>} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
*/
proto.loupe.common.opportunity.Opportunity.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.loupe.common.opportunity.lineitem.LineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.opportunity.lineitem.LineItem}
 */
proto.loupe.common.opportunity.Opportunity.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.loupe.common.opportunity.lineitem.LineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * repeated loupe.common.contact.Contact contacts = 29;
 * @return {!Array<!proto.loupe.common.contact.Contact>}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.loupe.common.contact.Contact>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_contact_contact_pb.Contact, 29));
};


/**
 * @param {!Array<!proto.loupe.common.contact.Contact>} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
*/
proto.loupe.common.opportunity.Opportunity.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 29, value);
};


/**
 * @param {!proto.loupe.common.contact.Contact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.contact.Contact}
 */
proto.loupe.common.opportunity.Opportunity.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 29, opt_value, proto.loupe.common.contact.Contact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};


/**
 * optional change.Change change = 30;
 * @return {?proto.loupe.common.opportunity.change.Change}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getChange = function() {
  return /** @type{?proto.loupe.common.opportunity.change.Change} */ (
    jspb.Message.getWrapperField(this, src_common_opportunity_change_change_pb.Change, 30));
};


/**
 * @param {?proto.loupe.common.opportunity.change.Change|undefined} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
*/
proto.loupe.common.opportunity.Opportunity.prototype.setChange = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.clearChange = function() {
  return this.setChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.opportunity.Opportunity.prototype.hasChange = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional int64 insight_count = 31;
 * @return {number}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getInsightCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setInsightCount = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional string status = 32;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional double score = 33;
 * @return {number}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 33, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 33, value);
};


/**
 * optional bool current_period = 34;
 * @return {boolean}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getCurrentPeriod = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setCurrentPeriod = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional loupe.common.person.Person owner = 35;
 * @return {?proto.loupe.common.person.Person}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getOwner = function() {
  return /** @type{?proto.loupe.common.person.Person} */ (
    jspb.Message.getWrapperField(this, src_common_person_person_pb.Person, 35));
};


/**
 * @param {?proto.loupe.common.person.Person|undefined} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
*/
proto.loupe.common.opportunity.Opportunity.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.opportunity.Opportunity.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional bool isForecasted = 36;
 * @return {boolean}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getIsforecasted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setIsforecasted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional string forecastBucket = 37;
 * @return {string}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getForecastbucket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.setForecastbucket = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * repeated Opportunity versions = 38;
 * @return {!Array<!proto.loupe.common.opportunity.Opportunity>}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getVersionsList = function() {
  return /** @type{!Array<!proto.loupe.common.opportunity.Opportunity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.opportunity.Opportunity, 38));
};


/**
 * @param {!Array<!proto.loupe.common.opportunity.Opportunity>} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
*/
proto.loupe.common.opportunity.Opportunity.prototype.setVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 38, value);
};


/**
 * @param {!proto.loupe.common.opportunity.Opportunity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.opportunity.Opportunity}
 */
proto.loupe.common.opportunity.Opportunity.prototype.addVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 38, opt_value, proto.loupe.common.opportunity.Opportunity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};


/**
 * optional loupe.common.crmmetadata.CrmMetadata crm_metadata = 39;
 * @return {?proto.loupe.common.crmmetadata.CrmMetadata}
 */
proto.loupe.common.opportunity.Opportunity.prototype.getCrmMetadata = function() {
  return /** @type{?proto.loupe.common.crmmetadata.CrmMetadata} */ (
    jspb.Message.getWrapperField(this, src_common_crmmetadata_crm_metadata_pb.CrmMetadata, 39));
};


/**
 * @param {?proto.loupe.common.crmmetadata.CrmMetadata|undefined} value
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
*/
proto.loupe.common.opportunity.Opportunity.prototype.setCrmMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.opportunity.Opportunity} returns this
 */
proto.loupe.common.opportunity.Opportunity.prototype.clearCrmMetadata = function() {
  return this.setCrmMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.opportunity.Opportunity.prototype.hasCrmMetadata = function() {
  return jspb.Message.getField(this, 39) != null;
};


goog.object.extend(exports, proto.loupe.common.opportunity);
