// source: src/services/forecast/v3/forecast.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_forecast_v3_forecast_pb = require('../../../../src/common/forecast/v3/forecast_pb.js');
goog.object.extend(proto, src_common_forecast_v3_forecast_pb);
var src_common_nullable_nullable_pb = require('../../../../src/common/nullable/nullable_pb.js');
goog.object.extend(proto, src_common_nullable_nullable_pb);
var src_common_filter_filter_pb = require('../../../../src/common/filter/filter_pb.js');
goog.object.extend(proto, src_common_filter_filter_pb);
var src_common_actor_pb = require('../../../../src/common/actor_pb.js');
goog.object.extend(proto, src_common_actor_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_realtime_query_pb = require('../../../../src/common/realtime/query_pb.js');
goog.object.extend(proto, src_common_realtime_query_pb);
goog.exportSymbol('proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.AutoCalculateSetting', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.Empty', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.EnsureSchemaRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfig', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigColumn', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigColumnDisplayOption', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigColumnDisplayVisibilityOption', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigGoalSettings', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigObjectSettings', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigPivotGroup', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigPublishStatus', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigSearchContainer', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigStatus', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigValueFormat', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastConfigValueSettings', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastFilter', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastPeriod', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastPeriodType', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastSort', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastTeamSettings', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ForecastView', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.GetByIdRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.GetForecastConfigRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.GetForecastRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.GetForecastResponse', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.Goal', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ListForecastConfigsRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ListForecastConfigsResponse', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.RehydrateRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.SaveForecastConfigRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.SaveForecastRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.SearchRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.SearchResponse', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.SetCallsRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.TeamForecast', null, global);
goog.exportSymbol('proto.loupe.services.forecast.v3.UpdateObjectsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.Empty.displayName = 'proto.loupe.services.forecast.v3.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.GetByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.GetByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.GetByIdRequest.displayName = 'proto.loupe.services.forecast.v3.GetByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.GetForecastRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.GetForecastRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.GetForecastRequest.displayName = 'proto.loupe.services.forecast.v3.GetForecastRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastPeriod.displayName = 'proto.loupe.services.forecast.v3.ForecastPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.ForecastTeamSettings.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastTeamSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastTeamSettings.displayName = 'proto.loupe.services.forecast.v3.ForecastTeamSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.GetForecastResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.GetForecastResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.GetForecastResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.GetForecastResponse.displayName = 'proto.loupe.services.forecast.v3.GetForecastResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.ForecastView.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastView.displayName = 'proto.loupe.services.forecast.v3.ForecastView';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.Goal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.Goal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.Goal.displayName = 'proto.loupe.services.forecast.v3.Goal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.TeamForecast = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.TeamForecast, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.TeamForecast.displayName = 'proto.loupe.services.forecast.v3.TeamForecast';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.SearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.SearchRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.SearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.SearchRequest.displayName = 'proto.loupe.services.forecast.v3.SearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.ForecastFilter.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastFilter.displayName = 'proto.loupe.services.forecast.v3.ForecastFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastSort.displayName = 'proto.loupe.services.forecast.v3.ForecastSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.SearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.SearchResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.SearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.SearchResponse.displayName = 'proto.loupe.services.forecast.v3.SearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.UpdateObjectsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.UpdateObjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.UpdateObjectsRequest.displayName = 'proto.loupe.services.forecast.v3.UpdateObjectsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.SetCallsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.SetCallsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.SetCallsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.SetCallsRequest.displayName = 'proto.loupe.services.forecast.v3.SetCallsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.SaveForecastRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.SaveForecastRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.SaveForecastRequest.displayName = 'proto.loupe.services.forecast.v3.SaveForecastRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.RehydrateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.RehydrateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.RehydrateRequest.displayName = 'proto.loupe.services.forecast.v3.RehydrateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.EnsureSchemaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.EnsureSchemaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.EnsureSchemaRequest.displayName = 'proto.loupe.services.forecast.v3.EnsureSchemaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastConfigObjectSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.displayName = 'proto.loupe.services.forecast.v3.ForecastConfigObjectSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastConfigGoalSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastConfigGoalSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.displayName = 'proto.loupe.services.forecast.v3.ForecastConfigGoalSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.displayName = 'proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastConfigValueSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastConfigValueSettings.displayName = 'proto.loupe.services.forecast.v3.ForecastConfigValueSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.displayName = 'proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastConfigPivotGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.displayName = 'proto.loupe.services.forecast.v3.ForecastConfigPivotGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.ForecastConfig.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastConfig.displayName = 'proto.loupe.services.forecast.v3.ForecastConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastConfigColumn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastConfigColumn.displayName = 'proto.loupe.services.forecast.v3.ForecastConfigColumn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ForecastConfigSearchContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ForecastConfigSearchContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.displayName = 'proto.loupe.services.forecast.v3.ForecastConfigSearchContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.SaveForecastConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.SaveForecastConfigRequest.displayName = 'proto.loupe.services.forecast.v3.SaveForecastConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.displayName = 'proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ListForecastConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ListForecastConfigsRequest.displayName = 'proto.loupe.services.forecast.v3.ListForecastConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.displayName = 'proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.GetForecastConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.GetForecastConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.GetForecastConfigRequest.displayName = 'proto.loupe.services.forecast.v3.GetForecastConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.displayName = 'proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.displayName = 'proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ListForecastConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.ListForecastConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ListForecastConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ListForecastConfigsResponse.displayName = 'proto.loupe.services.forecast.v3.ListForecastConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.displayName = 'proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.displayName = 'proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.displayName = 'proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.displayName = 'proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.displayName = 'proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.displayName = 'proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.displayName = 'proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.displayName = 'proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.displayName = 'proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.displayName = 'proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.displayName = 'proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.displayName = 'proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.displayName = 'proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.displayName = 'proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.Empty}
 */
proto.loupe.services.forecast.v3.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.Empty;
  return proto.loupe.services.forecast.v3.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.Empty}
 */
proto.loupe.services.forecast.v3.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.GetByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.GetByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.GetByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.GetByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.GetByIdRequest}
 */
proto.loupe.services.forecast.v3.GetByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.GetByIdRequest;
  return proto.loupe.services.forecast.v3.GetByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.GetByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.GetByIdRequest}
 */
proto.loupe.services.forecast.v3.GetByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.GetByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.GetByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.GetByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.GetByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.GetByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.GetByIdRequest} returns this
 */
proto.loupe.services.forecast.v3.GetByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.Actor actor = 2;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.GetByIdRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 2));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.GetByIdRequest} returns this
*/
proto.loupe.services.forecast.v3.GetByIdRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.GetByIdRequest} returns this
 */
proto.loupe.services.forecast.v3.GetByIdRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.GetByIdRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.GetForecastRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.GetForecastRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.GetForecastRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerRole: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    period: (f = msg.getPeriod()) && proto.loupe.services.forecast.v3.ForecastPeriod.toObject(includeInstance, f),
    teamSettings: (f = msg.getTeamSettings()) && proto.loupe.services.forecast.v3.ForecastTeamSettings.toObject(includeInstance, f),
    config: (f = msg.getConfig()) && proto.loupe.services.forecast.v3.ForecastConfig.toObject(includeInstance, f),
    tzOffset: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.GetForecastRequest;
  return proto.loupe.services.forecast.v3.GetForecastRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.GetForecastRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.Role} */ (reader.readEnum());
      msg.setOwnerRole(value);
      break;
    case 3:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 4:
      var value = new proto.loupe.services.forecast.v3.ForecastPeriod;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastPeriod.deserializeBinaryFromReader);
      msg.setPeriod(value);
      break;
    case 5:
      var value = new proto.loupe.services.forecast.v3.ForecastTeamSettings;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastTeamSettings.deserializeBinaryFromReader);
      msg.setTeamSettings(value);
      break;
    case 6:
      var value = new proto.loupe.services.forecast.v3.ForecastConfig;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTzOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.GetForecastRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.GetForecastRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.GetForecastRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerRole();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getPeriod();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.loupe.services.forecast.v3.ForecastPeriod.serializeBinaryToWriter
    );
  }
  f = message.getTeamSettings();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.loupe.services.forecast.v3.ForecastTeamSettings.serializeBinaryToWriter
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loupe.services.forecast.v3.ForecastConfig.serializeBinaryToWriter
    );
  }
  f = message.getTzOffset();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string owner_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest} returns this
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.Role owner_role = 2;
 * @return {!proto.loupe.common.Role}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.getOwnerRole = function() {
  return /** @type {!proto.loupe.common.Role} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.Role} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest} returns this
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.setOwnerRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional loupe.common.Actor actor = 3;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 3));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest} returns this
*/
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest} returns this
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ForecastPeriod period = 4;
 * @return {?proto.loupe.services.forecast.v3.ForecastPeriod}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.getPeriod = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastPeriod} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastPeriod, 4));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastPeriod|undefined} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest} returns this
*/
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.setPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest} returns this
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.clearPeriod = function() {
  return this.setPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.hasPeriod = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ForecastTeamSettings team_settings = 5;
 * @return {?proto.loupe.services.forecast.v3.ForecastTeamSettings}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.getTeamSettings = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastTeamSettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastTeamSettings, 5));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastTeamSettings|undefined} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest} returns this
*/
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.setTeamSettings = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest} returns this
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.clearTeamSettings = function() {
  return this.setTeamSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.hasTeamSettings = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ForecastConfig config = 6;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfig}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.getConfig = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfig} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfig, 6));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfig|undefined} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest} returns this
*/
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest} returns this
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 tz_offset = 7;
 * @return {number}
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.getTzOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastRequest} returns this
 */
proto.loupe.services.forecast.v3.GetForecastRequest.prototype.setTzOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cursor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customPeriodStart: jspb.Message.getFieldWithDefault(msg, 3, ""),
    customPeriodEnd: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastPeriod}
 */
proto.loupe.services.forecast.v3.ForecastPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastPeriod;
  return proto.loupe.services.forecast.v3.ForecastPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastPeriod}
 */
proto.loupe.services.forecast.v3.ForecastPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.services.forecast.v3.ForecastPeriodType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCursor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomPeriodStart(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomPeriodEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCursor();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCustomPeriodStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustomPeriodEnd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ForecastPeriodType type = 1;
 * @return {!proto.loupe.services.forecast.v3.ForecastPeriodType}
 */
proto.loupe.services.forecast.v3.ForecastPeriod.prototype.getType = function() {
  return /** @type {!proto.loupe.services.forecast.v3.ForecastPeriodType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastPeriodType} value
 * @return {!proto.loupe.services.forecast.v3.ForecastPeriod} returns this
 */
proto.loupe.services.forecast.v3.ForecastPeriod.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 cursor = 2;
 * @return {number}
 */
proto.loupe.services.forecast.v3.ForecastPeriod.prototype.getCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v3.ForecastPeriod} returns this
 */
proto.loupe.services.forecast.v3.ForecastPeriod.prototype.setCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string custom_period_start = 3;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastPeriod.prototype.getCustomPeriodStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastPeriod} returns this
 */
proto.loupe.services.forecast.v3.ForecastPeriod.prototype.setCustomPeriodStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string custom_period_end = 4;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastPeriod.prototype.getCustomPeriodEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastPeriod} returns this
 */
proto.loupe.services.forecast.v3.ForecastPeriod.prototype.setCustomPeriodEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastTeamSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastTeamSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    simplify: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    useManagerNames: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    levelChildGroupsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    levelMembersList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastTeamSettings}
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastTeamSettings;
  return proto.loupe.services.forecast.v3.ForecastTeamSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastTeamSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastTeamSettings}
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSimplify(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseManagerNames(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addLevelChildGroups(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addLevelMembers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastTeamSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastTeamSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSimplify();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getUseManagerNames();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getLevelChildGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getLevelMembersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional bool simplify = 1;
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.getSimplify = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v3.ForecastTeamSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.setSimplify = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool use_manager_names = 2;
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.getUseManagerNames = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v3.ForecastTeamSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.setUseManagerNames = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated string level_child_groups = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.getLevelChildGroupsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v3.ForecastTeamSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.setLevelChildGroupsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastTeamSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.addLevelChildGroups = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.ForecastTeamSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.clearLevelChildGroupsList = function() {
  return this.setLevelChildGroupsList([]);
};


/**
 * repeated string level_members = 4;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.getLevelMembersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v3.ForecastTeamSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.setLevelMembersList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastTeamSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.addLevelMembers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.ForecastTeamSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastTeamSettings.prototype.clearLevelMembersList = function() {
  return this.setLevelMembersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.GetForecastResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.GetForecastResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.GetForecastResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.GetForecastResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.GetForecastResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    viewsList: jspb.Message.toObjectList(msg.getViewsList(),
    proto.loupe.services.forecast.v3.ForecastView.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.GetForecastResponse}
 */
proto.loupe.services.forecast.v3.GetForecastResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.GetForecastResponse;
  return proto.loupe.services.forecast.v3.GetForecastResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.GetForecastResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.GetForecastResponse}
 */
proto.loupe.services.forecast.v3.GetForecastResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.forecast.v3.ForecastView;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastView.deserializeBinaryFromReader);
      msg.addViews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.GetForecastResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.GetForecastResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.GetForecastResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.GetForecastResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.forecast.v3.ForecastView.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ForecastView views = 1;
 * @return {!Array<!proto.loupe.services.forecast.v3.ForecastView>}
 */
proto.loupe.services.forecast.v3.GetForecastResponse.prototype.getViewsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v3.ForecastView>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v3.ForecastView, 1));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v3.ForecastView>} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastResponse} returns this
*/
proto.loupe.services.forecast.v3.GetForecastResponse.prototype.setViewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastView=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastView}
 */
proto.loupe.services.forecast.v3.GetForecastResponse.prototype.addViews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.forecast.v3.ForecastView, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.GetForecastResponse} returns this
 */
proto.loupe.services.forecast.v3.GetForecastResponse.prototype.clearViewsList = function() {
  return this.setViewsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.ForecastView.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastView.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastConfigId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    current: (f = msg.getCurrent()) && src_common_forecast_v3_forecast_pb.Forecast.toObject(includeInstance, f),
    previous: (f = msg.getPrevious()) && src_common_forecast_v3_forecast_pb.Forecast.toObject(includeInstance, f),
    diff: (f = msg.getDiff()) && src_common_forecast_v3_forecast_pb.ForecastDiff.toObject(includeInstance, f),
    goal: (f = msg.getGoal()) && proto.loupe.services.forecast.v3.Goal.toObject(includeInstance, f),
    teamForecastsList: jspb.Message.toObjectList(msg.getTeamForecastsList(),
    proto.loupe.services.forecast.v3.TeamForecast.toObject, includeInstance),
    ownerType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    forecastConfig: (f = msg.getForecastConfig()) && proto.loupe.services.forecast.v3.ForecastConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastView}
 */
proto.loupe.services.forecast.v3.ForecastView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastView;
  return proto.loupe.services.forecast.v3.ForecastView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastView}
 */
proto.loupe.services.forecast.v3.ForecastView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new src_common_forecast_v3_forecast_pb.Forecast;
      reader.readMessage(value,src_common_forecast_v3_forecast_pb.Forecast.deserializeBinaryFromReader);
      msg.setCurrent(value);
      break;
    case 5:
      var value = new src_common_forecast_v3_forecast_pb.Forecast;
      reader.readMessage(value,src_common_forecast_v3_forecast_pb.Forecast.deserializeBinaryFromReader);
      msg.setPrevious(value);
      break;
    case 6:
      var value = new src_common_forecast_v3_forecast_pb.ForecastDiff;
      reader.readMessage(value,src_common_forecast_v3_forecast_pb.ForecastDiff.deserializeBinaryFromReader);
      msg.setDiff(value);
      break;
    case 7:
      var value = new proto.loupe.services.forecast.v3.Goal;
      reader.readMessage(value,proto.loupe.services.forecast.v3.Goal.deserializeBinaryFromReader);
      msg.setGoal(value);
      break;
    case 8:
      var value = new proto.loupe.services.forecast.v3.TeamForecast;
      reader.readMessage(value,proto.loupe.services.forecast.v3.TeamForecast.deserializeBinaryFromReader);
      msg.addTeamForecasts(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 11:
      var value = new proto.loupe.services.forecast.v3.ForecastConfig;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfig.deserializeBinaryFromReader);
      msg.setForecastConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastConfigId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_forecast_v3_forecast_pb.Forecast.serializeBinaryToWriter
    );
  }
  f = message.getPrevious();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_forecast_v3_forecast_pb.Forecast.serializeBinaryToWriter
    );
  }
  f = message.getDiff();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_forecast_v3_forecast_pb.ForecastDiff.serializeBinaryToWriter
    );
  }
  f = message.getGoal();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.services.forecast.v3.Goal.serializeBinaryToWriter
    );
  }
  f = message.getTeamForecastsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.loupe.services.forecast.v3.TeamForecast.serializeBinaryToWriter
    );
  }
  f = message.getOwnerType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getForecastConfig();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.loupe.services.forecast.v3.ForecastConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string forecast_config_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.getForecastConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.setForecastConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional loupe.common.forecast.v3.Forecast current = 4;
 * @return {?proto.loupe.common.forecast.v3.Forecast}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.getCurrent = function() {
  return /** @type{?proto.loupe.common.forecast.v3.Forecast} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_v3_forecast_pb.Forecast, 4));
};


/**
 * @param {?proto.loupe.common.forecast.v3.Forecast|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
*/
proto.loupe.services.forecast.v3.ForecastView.prototype.setCurrent = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.clearCurrent = function() {
  return this.setCurrent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.hasCurrent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional loupe.common.forecast.v3.Forecast previous = 5;
 * @return {?proto.loupe.common.forecast.v3.Forecast}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.getPrevious = function() {
  return /** @type{?proto.loupe.common.forecast.v3.Forecast} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_v3_forecast_pb.Forecast, 5));
};


/**
 * @param {?proto.loupe.common.forecast.v3.Forecast|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
*/
proto.loupe.services.forecast.v3.ForecastView.prototype.setPrevious = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.clearPrevious = function() {
  return this.setPrevious(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.hasPrevious = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional loupe.common.forecast.v3.ForecastDiff diff = 6;
 * @return {?proto.loupe.common.forecast.v3.ForecastDiff}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.getDiff = function() {
  return /** @type{?proto.loupe.common.forecast.v3.ForecastDiff} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_v3_forecast_pb.ForecastDiff, 6));
};


/**
 * @param {?proto.loupe.common.forecast.v3.ForecastDiff|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
*/
proto.loupe.services.forecast.v3.ForecastView.prototype.setDiff = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.clearDiff = function() {
  return this.setDiff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.hasDiff = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Goal goal = 7;
 * @return {?proto.loupe.services.forecast.v3.Goal}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.getGoal = function() {
  return /** @type{?proto.loupe.services.forecast.v3.Goal} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.Goal, 7));
};


/**
 * @param {?proto.loupe.services.forecast.v3.Goal|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
*/
proto.loupe.services.forecast.v3.ForecastView.prototype.setGoal = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.clearGoal = function() {
  return this.setGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.hasGoal = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated TeamForecast team_forecasts = 8;
 * @return {!Array<!proto.loupe.services.forecast.v3.TeamForecast>}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.getTeamForecastsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v3.TeamForecast>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v3.TeamForecast, 8));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v3.TeamForecast>} value
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
*/
proto.loupe.services.forecast.v3.ForecastView.prototype.setTeamForecastsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.loupe.services.forecast.v3.TeamForecast=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.TeamForecast}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.addTeamForecasts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.loupe.services.forecast.v3.TeamForecast, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.clearTeamForecastsList = function() {
  return this.setTeamForecastsList([]);
};


/**
 * optional string owner_type = 9;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.getOwnerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.setOwnerType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string owner_id = 10;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional ForecastConfig forecast_config = 11;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfig}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.getForecastConfig = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfig} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfig, 11));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfig|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
*/
proto.loupe.services.forecast.v3.ForecastView.prototype.setForecastConfig = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastView} returns this
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.clearForecastConfig = function() {
  return this.setForecastConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastView.prototype.hasForecastConfig = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.Goal.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.Goal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.Goal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.Goal.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseline: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    actual: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.Goal}
 */
proto.loupe.services.forecast.v3.Goal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.Goal;
  return proto.loupe.services.forecast.v3.Goal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.Goal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.Goal}
 */
proto.loupe.services.forecast.v3.Goal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaseline(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActual(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.Goal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.Goal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.Goal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.Goal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseline();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getActual();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double baseline = 1;
 * @return {number}
 */
proto.loupe.services.forecast.v3.Goal.prototype.getBaseline = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v3.Goal} returns this
 */
proto.loupe.services.forecast.v3.Goal.prototype.setBaseline = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double actual = 2;
 * @return {number}
 */
proto.loupe.services.forecast.v3.Goal.prototype.getActual = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v3.Goal} returns this
 */
proto.loupe.services.forecast.v3.Goal.prototype.setActual = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.TeamForecast.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.TeamForecast.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.TeamForecast} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.TeamForecast.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    view: (f = msg.getView()) && proto.loupe.services.forecast.v3.ForecastView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.TeamForecast}
 */
proto.loupe.services.forecast.v3.TeamForecast.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.TeamForecast;
  return proto.loupe.services.forecast.v3.TeamForecast.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.TeamForecast} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.TeamForecast}
 */
proto.loupe.services.forecast.v3.TeamForecast.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 2:
      var value = new proto.loupe.services.forecast.v3.ForecastView;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastView.deserializeBinaryFromReader);
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.TeamForecast.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.TeamForecast.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.TeamForecast} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.TeamForecast.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getView();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.forecast.v3.ForecastView.serializeBinaryToWriter
    );
  }
};


/**
 * optional string owner_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.TeamForecast.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.TeamForecast} returns this
 */
proto.loupe.services.forecast.v3.TeamForecast.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ForecastView view = 2;
 * @return {?proto.loupe.services.forecast.v3.ForecastView}
 */
proto.loupe.services.forecast.v3.TeamForecast.prototype.getView = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastView} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastView, 2));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastView|undefined} value
 * @return {!proto.loupe.services.forecast.v3.TeamForecast} returns this
*/
proto.loupe.services.forecast.v3.TeamForecast.prototype.setView = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.TeamForecast} returns this
 */
proto.loupe.services.forecast.v3.TeamForecast.prototype.clearView = function() {
  return this.setView(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.TeamForecast.prototype.hasView = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.SearchRequest.repeatedFields_ = [6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.SearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.SearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    searchText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.loupe.services.forecast.v3.ForecastFilter.toObject, includeInstance),
    sortOptionsList: jspb.Message.toObjectList(msg.getSortOptionsList(),
    proto.loupe.services.forecast.v3.ForecastSort.toObject, includeInstance),
    forecastConfigIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    period: (f = msg.getPeriod()) && proto.loupe.services.forecast.v3.ForecastPeriod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.SearchRequest}
 */
proto.loupe.services.forecast.v3.SearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.SearchRequest;
  return proto.loupe.services.forecast.v3.SearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.SearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.SearchRequest}
 */
proto.loupe.services.forecast.v3.SearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 2:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchText(value);
      break;
    case 6:
      var value = new proto.loupe.services.forecast.v3.ForecastFilter;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 7:
      var value = new proto.loupe.services.forecast.v3.ForecastSort;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastSort.deserializeBinaryFromReader);
      msg.addSortOptions(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addForecastConfigIds(value);
      break;
    case 9:
      var value = new proto.loupe.services.forecast.v3.ForecastPeriod;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastPeriod.deserializeBinaryFromReader);
      msg.setPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.SearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.SearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSearchText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.loupe.services.forecast.v3.ForecastFilter.serializeBinaryToWriter
    );
  }
  f = message.getSortOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.loupe.services.forecast.v3.ForecastSort.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfigIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getPeriod();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.loupe.services.forecast.v3.ForecastPeriod.serializeBinaryToWriter
    );
  }
};


/**
 * optional string owner_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.Actor actor = 2;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 2));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
*/
proto.loupe.services.forecast.v3.SearchRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 page = 4;
 * @return {number}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string search_text = 5;
 * @return {string}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.getSearchText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.setSearchText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated ForecastFilter filters = 6;
 * @return {!Array<!proto.loupe.services.forecast.v3.ForecastFilter>}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v3.ForecastFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v3.ForecastFilter, 6));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v3.ForecastFilter>} value
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
*/
proto.loupe.services.forecast.v3.SearchRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastFilter}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.services.forecast.v3.ForecastFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * repeated ForecastSort sort_options = 7;
 * @return {!Array<!proto.loupe.services.forecast.v3.ForecastSort>}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.getSortOptionsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v3.ForecastSort>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v3.ForecastSort, 7));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v3.ForecastSort>} value
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
*/
proto.loupe.services.forecast.v3.SearchRequest.prototype.setSortOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastSort=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastSort}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.addSortOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.loupe.services.forecast.v3.ForecastSort, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.clearSortOptionsList = function() {
  return this.setSortOptionsList([]);
};


/**
 * repeated string forecast_config_ids = 8;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.getForecastConfigIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.setForecastConfigIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.addForecastConfigIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.clearForecastConfigIdsList = function() {
  return this.setForecastConfigIdsList([]);
};


/**
 * optional ForecastPeriod period = 9;
 * @return {?proto.loupe.services.forecast.v3.ForecastPeriod}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.getPeriod = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastPeriod} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastPeriod, 9));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastPeriod|undefined} value
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
*/
proto.loupe.services.forecast.v3.SearchRequest.prototype.setPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.SearchRequest} returns this
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.clearPeriod = function() {
  return this.setPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.SearchRequest.prototype.hasPeriod = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.ForecastFilter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: msg.getValuesList_asB64(),
    compareop: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastFilter}
 */
proto.loupe.services.forecast.v3.ForecastFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastFilter;
  return proto.loupe.services.forecast.v3.ForecastFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastFilter}
 */
proto.loupe.services.forecast.v3.ForecastFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addValues(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.common.filter.FilterOperation} */ (reader.readEnum());
      msg.setCompareop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      2,
      f
    );
  }
  f = message.getCompareop();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastFilter.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastFilter} returns this
 */
proto.loupe.services.forecast.v3.ForecastFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated bytes values = 2;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.loupe.services.forecast.v3.ForecastFilter.prototype.getValuesList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * repeated bytes values = 2;
 * This is a type-conversion wrapper around `getValuesList()`
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v3.ForecastFilter.prototype.getValuesList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getValuesList()));
};


/**
 * repeated bytes values = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValuesList()`
 * @return {!Array<!Uint8Array>}
 */
proto.loupe.services.forecast.v3.ForecastFilter.prototype.getValuesList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getValuesList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.loupe.services.forecast.v3.ForecastFilter} returns this
 */
proto.loupe.services.forecast.v3.ForecastFilter.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastFilter} returns this
 */
proto.loupe.services.forecast.v3.ForecastFilter.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.ForecastFilter} returns this
 */
proto.loupe.services.forecast.v3.ForecastFilter.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional loupe.common.filter.FilterOperation compareOp = 5;
 * @return {!proto.loupe.common.filter.FilterOperation}
 */
proto.loupe.services.forecast.v3.ForecastFilter.prototype.getCompareop = function() {
  return /** @type {!proto.loupe.common.filter.FilterOperation} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.common.filter.FilterOperation} value
 * @return {!proto.loupe.services.forecast.v3.ForecastFilter} returns this
 */
proto.loupe.services.forecast.v3.ForecastFilter.prototype.setCompareop = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastSort.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    asc: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastSort}
 */
proto.loupe.services.forecast.v3.ForecastSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastSort;
  return proto.loupe.services.forecast.v3.ForecastSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastSort}
 */
proto.loupe.services.forecast.v3.ForecastSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAsc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAsc();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastSort.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastSort} returns this
 */
proto.loupe.services.forecast.v3.ForecastSort.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool asc = 2;
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastSort.prototype.getAsc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v3.ForecastSort} returns this
 */
proto.loupe.services.forecast.v3.ForecastSort.prototype.setAsc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.SearchResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.SearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.SearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.SearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastsList: jspb.Message.toObjectList(msg.getForecastsList(),
    src_common_forecast_v3_forecast_pb.Forecast.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.SearchResponse}
 */
proto.loupe.services.forecast.v3.SearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.SearchResponse;
  return proto.loupe.services.forecast.v3.SearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.SearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.SearchResponse}
 */
proto.loupe.services.forecast.v3.SearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_forecast_v3_forecast_pb.Forecast;
      reader.readMessage(value,src_common_forecast_v3_forecast_pb.Forecast.deserializeBinaryFromReader);
      msg.addForecasts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.SearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.SearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.SearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_forecast_v3_forecast_pb.Forecast.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated loupe.common.forecast.v3.Forecast forecasts = 1;
 * @return {!Array<!proto.loupe.common.forecast.v3.Forecast>}
 */
proto.loupe.services.forecast.v3.SearchResponse.prototype.getForecastsList = function() {
  return /** @type{!Array<!proto.loupe.common.forecast.v3.Forecast>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_forecast_v3_forecast_pb.Forecast, 1));
};


/**
 * @param {!Array<!proto.loupe.common.forecast.v3.Forecast>} value
 * @return {!proto.loupe.services.forecast.v3.SearchResponse} returns this
*/
proto.loupe.services.forecast.v3.SearchResponse.prototype.setForecastsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.forecast.v3.Forecast=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.v3.Forecast}
 */
proto.loupe.services.forecast.v3.SearchResponse.prototype.addForecasts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.forecast.v3.Forecast, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.SearchResponse} returns this
 */
proto.loupe.services.forecast.v3.SearchResponse.prototype.clearForecastsList = function() {
  return this.setForecastsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.loupe.services.forecast.v3.SearchResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v3.SearchResponse} returns this
 */
proto.loupe.services.forecast.v3.SearchResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.UpdateObjectsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.UpdateObjectsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    idsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.UpdateObjectsRequest}
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.UpdateObjectsRequest;
  return proto.loupe.services.forecast.v3.UpdateObjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.UpdateObjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.UpdateObjectsRequest}
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastId(value);
      break;
    case 2:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.forecast.v3.ForecastObjectType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.UpdateObjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.UpdateObjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string forecast_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.getForecastId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.UpdateObjectsRequest} returns this
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.setForecastId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.Actor actor = 2;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 2));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.UpdateObjectsRequest} returns this
*/
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.UpdateObjectsRequest} returns this
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional loupe.common.forecast.v3.ForecastObjectType type = 3;
 * @return {!proto.loupe.common.forecast.v3.ForecastObjectType}
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.getType = function() {
  return /** @type {!proto.loupe.common.forecast.v3.ForecastObjectType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.forecast.v3.ForecastObjectType} value
 * @return {!proto.loupe.services.forecast.v3.UpdateObjectsRequest} returns this
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string ids = 4;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v3.UpdateObjectsRequest} returns this
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.UpdateObjectsRequest} returns this
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.UpdateObjectsRequest} returns this
 */
proto.loupe.services.forecast.v3.UpdateObjectsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.SetCallsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.SetCallsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.SetCallsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.SetCallsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SetCallsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    callsList: jspb.Message.toObjectList(msg.getCallsList(),
    src_common_forecast_v3_forecast_pb.ForecastCall.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.SetCallsRequest}
 */
proto.loupe.services.forecast.v3.SetCallsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.SetCallsRequest;
  return proto.loupe.services.forecast.v3.SetCallsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.SetCallsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.SetCallsRequest}
 */
proto.loupe.services.forecast.v3.SetCallsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastId(value);
      break;
    case 2:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 3:
      var value = new src_common_forecast_v3_forecast_pb.ForecastCall;
      reader.readMessage(value,src_common_forecast_v3_forecast_pb.ForecastCall.deserializeBinaryFromReader);
      msg.addCalls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.SetCallsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.SetCallsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.SetCallsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SetCallsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getCallsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      src_common_forecast_v3_forecast_pb.ForecastCall.serializeBinaryToWriter
    );
  }
};


/**
 * optional string forecast_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.SetCallsRequest.prototype.getForecastId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.SetCallsRequest} returns this
 */
proto.loupe.services.forecast.v3.SetCallsRequest.prototype.setForecastId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.Actor actor = 2;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.SetCallsRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 2));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.SetCallsRequest} returns this
*/
proto.loupe.services.forecast.v3.SetCallsRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.SetCallsRequest} returns this
 */
proto.loupe.services.forecast.v3.SetCallsRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.SetCallsRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated loupe.common.forecast.v3.ForecastCall calls = 3;
 * @return {!Array<!proto.loupe.common.forecast.v3.ForecastCall>}
 */
proto.loupe.services.forecast.v3.SetCallsRequest.prototype.getCallsList = function() {
  return /** @type{!Array<!proto.loupe.common.forecast.v3.ForecastCall>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_forecast_v3_forecast_pb.ForecastCall, 3));
};


/**
 * @param {!Array<!proto.loupe.common.forecast.v3.ForecastCall>} value
 * @return {!proto.loupe.services.forecast.v3.SetCallsRequest} returns this
*/
proto.loupe.services.forecast.v3.SetCallsRequest.prototype.setCallsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.forecast.v3.ForecastCall=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.v3.ForecastCall}
 */
proto.loupe.services.forecast.v3.SetCallsRequest.prototype.addCalls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.forecast.v3.ForecastCall, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.SetCallsRequest} returns this
 */
proto.loupe.services.forecast.v3.SetCallsRequest.prototype.clearCallsList = function() {
  return this.setCallsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.SaveForecastRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.SaveForecastRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    forecast: (f = msg.getForecast()) && src_common_forecast_v3_forecast_pb.Forecast.toObject(includeInstance, f),
    teamSettings: (f = msg.getTeamSettings()) && proto.loupe.services.forecast.v3.ForecastTeamSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastRequest}
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.SaveForecastRequest;
  return proto.loupe.services.forecast.v3.SaveForecastRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.SaveForecastRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastRequest}
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = new src_common_forecast_v3_forecast_pb.Forecast;
      reader.readMessage(value,src_common_forecast_v3_forecast_pb.Forecast.deserializeBinaryFromReader);
      msg.setForecast(value);
      break;
    case 3:
      var value = new proto.loupe.services.forecast.v3.ForecastTeamSettings;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastTeamSettings.deserializeBinaryFromReader);
      msg.setTeamSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.SaveForecastRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.SaveForecastRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getForecast();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_forecast_v3_forecast_pb.Forecast.serializeBinaryToWriter
    );
  }
  f = message.getTeamSettings();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.loupe.services.forecast.v3.ForecastTeamSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.SaveForecastRequest} returns this
*/
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.forecast.v3.Forecast forecast = 2;
 * @return {?proto.loupe.common.forecast.v3.Forecast}
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.getForecast = function() {
  return /** @type{?proto.loupe.common.forecast.v3.Forecast} */ (
    jspb.Message.getWrapperField(this, src_common_forecast_v3_forecast_pb.Forecast, 2));
};


/**
 * @param {?proto.loupe.common.forecast.v3.Forecast|undefined} value
 * @return {!proto.loupe.services.forecast.v3.SaveForecastRequest} returns this
*/
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.setForecast = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.clearForecast = function() {
  return this.setForecast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.hasForecast = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ForecastTeamSettings team_settings = 3;
 * @return {?proto.loupe.services.forecast.v3.ForecastTeamSettings}
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.getTeamSettings = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastTeamSettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastTeamSettings, 3));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastTeamSettings|undefined} value
 * @return {!proto.loupe.services.forecast.v3.SaveForecastRequest} returns this
*/
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.setTeamSettings = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastRequest} returns this
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.clearTeamSettings = function() {
  return this.setTeamSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.SaveForecastRequest.prototype.hasTeamSettings = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.RehydrateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.RehydrateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.RehydrateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.RehydrateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.RehydrateRequest}
 */
proto.loupe.services.forecast.v3.RehydrateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.RehydrateRequest;
  return proto.loupe.services.forecast.v3.RehydrateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.RehydrateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.RehydrateRequest}
 */
proto.loupe.services.forecast.v3.RehydrateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.RehydrateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.RehydrateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.RehydrateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.RehydrateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.RehydrateRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.RehydrateRequest} returns this
 */
proto.loupe.services.forecast.v3.RehydrateRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.EnsureSchemaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.EnsureSchemaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.EnsureSchemaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.EnsureSchemaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.EnsureSchemaRequest}
 */
proto.loupe.services.forecast.v3.EnsureSchemaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.EnsureSchemaRequest;
  return proto.loupe.services.forecast.v3.EnsureSchemaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.EnsureSchemaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.EnsureSchemaRequest}
 */
proto.loupe.services.forecast.v3.EnsureSchemaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.EnsureSchemaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.EnsureSchemaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.EnsureSchemaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.EnsureSchemaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.EnsureSchemaRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.EnsureSchemaRequest} returns this
 */
proto.loupe.services.forecast.v3.EnsureSchemaRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigObjectSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownershipField: jspb.Message.getFieldWithDefault(msg, 2, ""),
    periodEvaluationField: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rollupField: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rollupFieldFormat: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rollupFieldFormatEnum: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigObjectSettings}
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastConfigObjectSettings;
  return proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigObjectSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigObjectSettings}
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnershipField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodEvaluationField(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRollupField(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRollupFieldFormat(value);
      break;
    case 6:
      var value = /** @type {!proto.loupe.services.forecast.v3.ForecastConfigValueFormat} */ (reader.readEnum());
      msg.setRollupFieldFormatEnum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigObjectSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnershipField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPeriodEvaluationField();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRollupField();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRollupFieldFormat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRollupFieldFormatEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string object_name = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigObjectSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ownership_field = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.getOwnershipField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigObjectSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.setOwnershipField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string period_evaluation_field = 3;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.getPeriodEvaluationField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigObjectSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.setPeriodEvaluationField = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string rollup_field = 4;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.getRollupField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigObjectSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.setRollupField = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string rollup_field_format = 5;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.getRollupFieldFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigObjectSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.setRollupFieldFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ForecastConfigValueFormat rollup_field_format_enum = 6;
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigValueFormat}
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.getRollupFieldFormatEnum = function() {
  return /** @type {!proto.loupe.services.forecast.v3.ForecastConfigValueFormat} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigValueFormat} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigObjectSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.prototype.setRollupFieldFormatEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigGoalSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    goalFormat: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigGoalSettings}
 */
proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastConfigGoalSettings;
  return proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigGoalSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigGoalSettings}
 */
proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoalKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoalFormat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigGoalSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGoalFormat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string goal_key = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.prototype.getGoalKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigGoalSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.prototype.setGoalKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string goal_format = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.prototype.getGoalFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigGoalSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.prototype.setGoalFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotspringQueryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rawQuery: jspb.Message.getFieldWithDefault(msg, 2, ""),
    queryParameters: (f = msg.getQueryParameters()) && src_common_realtime_query_pb.QueryParameters.toObject(includeInstance, f),
    pb_static: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    canopyDataUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings}
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings;
  return proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings}
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotspringQueryKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawQuery(value);
      break;
    case 3:
      var value = new src_common_realtime_query_pb.QueryParameters;
      reader.readMessage(value,src_common_realtime_query_pb.QueryParameters.deserializeBinaryFromReader);
      msg.setQueryParameters(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStatic(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanopyDataUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotspringQueryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRawQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQueryParameters();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_realtime_query_pb.QueryParameters.serializeBinaryToWriter
    );
  }
  f = message.getStatic();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCanopyDataUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string hotspring_query_key = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.getHotspringQueryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.setHotspringQueryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string raw_query = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.getRawQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.setRawQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.realtime.QueryParameters query_parameters = 3;
 * @return {?proto.loupe.common.realtime.QueryParameters}
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.getQueryParameters = function() {
  return /** @type{?proto.loupe.common.realtime.QueryParameters} */ (
    jspb.Message.getWrapperField(this, src_common_realtime_query_pb.QueryParameters, 3));
};


/**
 * @param {?proto.loupe.common.realtime.QueryParameters|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.setQueryParameters = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.clearQueryParameters = function() {
  return this.setQueryParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.hasQueryParameters = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double static = 4;
 * @return {number}
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.getStatic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.setStatic = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string canopy_data_url = 5;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.getCanopyDataUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.prototype.setCanopyDataUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastConfigValueSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigValueSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectValueSettings: (f = msg.getObjectValueSettings()) && proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && src_common_realtime_query_pb.FilterParameter.toObject(includeInstance, f),
    customQuerySettings: (f = msg.getCustomQuerySettings()) && proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigValueSettings}
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastConfigValueSettings;
  return proto.loupe.services.forecast.v3.ForecastConfigValueSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigValueSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigValueSettings}
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigObjectSettings;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.deserializeBinaryFromReader);
      msg.setObjectValueSettings(value);
      break;
    case 2:
      var value = new src_common_realtime_query_pb.FilterParameter;
      reader.readMessage(value,src_common_realtime_query_pb.FilterParameter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.deserializeBinaryFromReader);
      msg.setCustomQuerySettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastConfigValueSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigValueSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectValueSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_realtime_query_pb.FilterParameter.serializeBinaryToWriter
    );
  }
  f = message.getCustomQuerySettings();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional ForecastConfigObjectSettings object_value_settings = 1;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfigObjectSettings}
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.getObjectValueSettings = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfigObjectSettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigObjectSettings, 1));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfigObjectSettings|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigValueSettings} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.setObjectValueSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigValueSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.clearObjectValueSettings = function() {
  return this.setObjectValueSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.hasObjectValueSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.realtime.FilterParameter filter = 2;
 * @return {?proto.loupe.common.realtime.FilterParameter}
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.getFilter = function() {
  return /** @type{?proto.loupe.common.realtime.FilterParameter} */ (
    jspb.Message.getWrapperField(this, src_common_realtime_query_pb.FilterParameter, 2));
};


/**
 * @param {?proto.loupe.common.realtime.FilterParameter|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigValueSettings} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigValueSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ForecastConfigCustomQuerySettings custom_query_settings = 3;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings}
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.getCustomQuerySettings = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings, 3));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfigCustomQuerySettings|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigValueSettings} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.setCustomQuerySettings = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigValueSettings} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.clearCustomQuerySettings = function() {
  return this.setCustomQuerySettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigValueSettings.prototype.hasCustomQuerySettings = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.toObject = function(includeInstance, msg) {
  var f, obj = {
    latexFormula: jspb.Message.getFieldWithDefault(msg, 1, ""),
    variableMappingMap: (f = msg.getVariableMappingMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula}
 */
proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula;
  return proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula}
 */
proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatexFormula(value);
      break;
    case 2:
      var value = msg.getVariableMappingMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatexFormula();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVariableMappingMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string latex_formula = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.prototype.getLatexFormula = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.prototype.setLatexFormula = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> variable_mapping = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.prototype.getVariableMappingMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.prototype.clearVariableMappingMap = function() {
  this.getVariableMappingMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    forecastConfigId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    excludeChildren: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    excludeAncestors: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup}
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastConfigPivotGroup;
  return proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup}
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeChildren(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeAncestors(value);
      break;
    case 6:
      var value = /** @type {!proto.loupe.services.forecast.v3.ForecastConfigStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getForecastConfigId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExcludeChildren();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getExcludeAncestors();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string forecast_config_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.getForecastConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.setForecastConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool exclude_children = 4;
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.getExcludeChildren = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.setExcludeChildren = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool exclude_ancestors = 5;
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.getExcludeAncestors = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.setExcludeAncestors = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional ForecastConfigStatus status = 6;
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigStatus}
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.getStatus = function() {
  return /** @type {!proto.loupe.services.forecast.v3.ForecastConfigStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigStatus} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.ForecastConfig.repeatedFields_ = [14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    objectSettings: (f = msg.getObjectSettings()) && proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.toObject(includeInstance, f),
    goalSettings: (f = msg.getGoalSettings()) && proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.toObject(includeInstance, f),
    periodLength: jspb.Message.getFieldWithDefault(msg, 8, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 11, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 13, ""),
    columnsList: jspb.Message.toObjectList(msg.getColumnsList(),
    proto.loupe.services.forecast.v3.ForecastConfigColumn.toObject, includeInstance),
    pivotGroupsList: jspb.Message.toObjectList(msg.getPivotGroupsList(),
    proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.toObject, includeInstance),
    statusEnabledAt: (f = msg.getStatusEnabledAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    statusDisabledAt: (f = msg.getStatusDisabledAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    mode: jspb.Message.getFieldWithDefault(msg, 18, 0),
    columnsCount: jspb.Message.getFieldWithDefault(msg, 19, 0),
    pivotGroupsCount: jspb.Message.getFieldWithDefault(msg, 20, 0),
    draftParentId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    hasPublishableChanges: jspb.Message.getBooleanFieldWithDefault(msg, 22, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig}
 */
proto.loupe.services.forecast.v3.ForecastConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastConfig;
  return proto.loupe.services.forecast.v3.ForecastConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig}
 */
proto.loupe.services.forecast.v3.ForecastConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.services.forecast.v3.ForecastConfigStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigObjectSettings;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.deserializeBinaryFromReader);
      msg.setObjectSettings(value);
      break;
    case 7:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigGoalSettings;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.deserializeBinaryFromReader);
      msg.setGoalSettings(value);
      break;
    case 8:
      var value = /** @type {!proto.loupe.services.forecast.v3.ForecastPeriodType} */ (reader.readEnum());
      msg.setPeriodLength(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 14:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigColumn;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigColumn.deserializeBinaryFromReader);
      msg.addColumns(value);
      break;
    case 15:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigPivotGroup;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.deserializeBinaryFromReader);
      msg.addPivotGroups(value);
      break;
    case 16:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setStatusEnabledAt(value);
      break;
    case 17:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setStatusDisabledAt(value);
      break;
    case 18:
      var value = /** @type {!proto.loupe.common.forecast.v3.ForecastMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setColumnsCount(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPivotGroupsCount(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setDraftParentId(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPublishableChanges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getObjectSettings();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigObjectSettings.serializeBinaryToWriter
    );
  }
  f = message.getGoalSettings();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.serializeBinaryToWriter
    );
  }
  f = message.getPeriodLength();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigColumn.serializeBinaryToWriter
    );
  }
  f = message.getPivotGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.serializeBinaryToWriter
    );
  }
  f = message.getStatusEnabledAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatusDisabledAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getColumnsCount();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getPivotGroupsCount();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getDraftParentId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getHasPublishableChanges();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ForecastConfigStatus status = 4;
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigStatus}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getStatus = function() {
  return /** @type {!proto.loupe.services.forecast.v3.ForecastConfigStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigStatus} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ForecastConfigObjectSettings object_settings = 6;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfigObjectSettings}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getObjectSettings = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfigObjectSettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigObjectSettings, 6));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfigObjectSettings|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setObjectSettings = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.clearObjectSettings = function() {
  return this.setObjectSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.hasObjectSettings = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ForecastConfigGoalSettings goal_settings = 7;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfigGoalSettings}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getGoalSettings = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfigGoalSettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigGoalSettings, 7));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfigGoalSettings|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setGoalSettings = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.clearGoalSettings = function() {
  return this.setGoalSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.hasGoalSettings = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ForecastPeriodType period_length = 8;
 * @return {!proto.loupe.services.forecast.v3.ForecastPeriodType}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getPeriodLength = function() {
  return /** @type {!proto.loupe.services.forecast.v3.ForecastPeriodType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastPeriodType} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setPeriodLength = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int32 sort = 9;
 * @return {number}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string created_by = 11;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string updated_by = 13;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated ForecastConfigColumn columns = 14;
 * @return {!Array<!proto.loupe.services.forecast.v3.ForecastConfigColumn>}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getColumnsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v3.ForecastConfigColumn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigColumn, 14));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v3.ForecastConfigColumn>} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setColumnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigColumn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.addColumns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.loupe.services.forecast.v3.ForecastConfigColumn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.clearColumnsList = function() {
  return this.setColumnsList([]);
};


/**
 * repeated ForecastConfigPivotGroup pivot_groups = 15;
 * @return {!Array<!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup>}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getPivotGroupsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigPivotGroup, 15));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup>} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setPivotGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.addPivotGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.loupe.services.forecast.v3.ForecastConfigPivotGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.clearPivotGroupsList = function() {
  return this.setPivotGroupsList([]);
};


/**
 * optional loupe.common.nullable.NullTimestamp status_enabled_at = 16;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getStatusEnabledAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 16));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setStatusEnabledAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.clearStatusEnabledAt = function() {
  return this.setStatusEnabledAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.hasStatusEnabledAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional loupe.common.nullable.NullTimestamp status_disabled_at = 17;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getStatusDisabledAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 17));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setStatusDisabledAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.clearStatusDisabledAt = function() {
  return this.setStatusDisabledAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.hasStatusDisabledAt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional loupe.common.forecast.v3.ForecastMode mode = 18;
 * @return {!proto.loupe.common.forecast.v3.ForecastMode}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getMode = function() {
  return /** @type {!proto.loupe.common.forecast.v3.ForecastMode} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.loupe.common.forecast.v3.ForecastMode} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional int32 columns_count = 19;
 * @return {number}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getColumnsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setColumnsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 pivot_groups_count = 20;
 * @return {number}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getPivotGroupsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setPivotGroupsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string draft_parent_id = 21;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getDraftParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setDraftParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional bool has_publishable_changes = 22;
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.getHasPublishableChanges = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfig.prototype.setHasPublishableChanges = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastConfigColumn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigColumn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastConfigId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    autoCalculate: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    rollUp: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    forecastConfigValueSettings: (f = msg.getForecastConfigValueSettings()) && proto.loupe.services.forecast.v3.ForecastConfigValueSettings.toObject(includeInstance, f),
    goalSettings: (f = msg.getGoalSettings()) && proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.toObject(includeInstance, f),
    displayFormula: (f = msg.getDisplayFormula()) && proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.toObject(includeInstance, f),
    sort: jspb.Message.getFieldWithDefault(msg, 12, 0),
    isManaged: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 15, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 17, ""),
    statusEnabledAt: (f = msg.getStatusEnabledAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    statusDisabledAt: (f = msg.getStatusDisabledAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    displayOption: jspb.Message.getFieldWithDefault(msg, 20, 0),
    visibilityOption: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastConfigColumn;
  return proto.loupe.services.forecast.v3.ForecastConfigColumn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigColumn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.services.forecast.v3.ForecastConfigStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoCalculate(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRollUp(value);
      break;
    case 9:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigValueSettings;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigValueSettings.deserializeBinaryFromReader);
      msg.setForecastConfigValueSettings(value);
      break;
    case 10:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigGoalSettings;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.deserializeBinaryFromReader);
      msg.setGoalSettings(value);
      break;
    case 11:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.deserializeBinaryFromReader);
      msg.setDisplayFormula(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsManaged(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 18:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setStatusEnabledAt(value);
      break;
    case 19:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setStatusDisabledAt(value);
      break;
    case 20:
      var value = /** @type {!proto.loupe.services.forecast.v3.ForecastConfigColumnDisplayOption} */ (reader.readEnum());
      msg.setDisplayOption(value);
      break;
    case 21:
      var value = /** @type {!proto.loupe.services.forecast.v3.ForecastConfigColumnDisplayVisibilityOption} */ (reader.readEnum());
      msg.setVisibilityOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastConfigColumn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigColumn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastConfigId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReadOnly();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAutoCalculate();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getRollUp();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getForecastConfigValueSettings();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigValueSettings.serializeBinaryToWriter
    );
  }
  f = message.getGoalSettings();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigGoalSettings.serializeBinaryToWriter
    );
  }
  f = message.getDisplayFormula();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getIsManaged();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getStatusEnabledAt();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatusDisabledAt();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getDisplayOption();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getVisibilityOption();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
};


/**
 * optional string forecast_config_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getForecastConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setForecastConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ForecastConfigStatus status = 4;
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigStatus}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getStatus = function() {
  return /** @type {!proto.loupe.services.forecast.v3.ForecastConfigStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigStatus} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool read_only = 6;
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool auto_calculate = 7;
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getAutoCalculate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setAutoCalculate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool roll_up = 8;
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getRollUp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setRollUp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional ForecastConfigValueSettings forecast_config_value_settings = 9;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfigValueSettings}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getForecastConfigValueSettings = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfigValueSettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigValueSettings, 9));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfigValueSettings|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setForecastConfigValueSettings = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.clearForecastConfigValueSettings = function() {
  return this.setForecastConfigValueSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.hasForecastConfigValueSettings = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ForecastConfigGoalSettings goal_settings = 10;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfigGoalSettings}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getGoalSettings = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfigGoalSettings} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigGoalSettings, 10));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfigGoalSettings|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setGoalSettings = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.clearGoalSettings = function() {
  return this.setGoalSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.hasGoalSettings = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ForecastConfigDisplayFormula display_formula = 11;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getDisplayFormula = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula, 11));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfigDisplayFormula|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setDisplayFormula = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.clearDisplayFormula = function() {
  return this.setDisplayFormula(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.hasDisplayFormula = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 sort = 12;
 * @return {number}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool is_managed = 13;
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getIsManaged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setIsManaged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string created_by = 15;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string updated_by = 17;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional loupe.common.nullable.NullTimestamp status_enabled_at = 18;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getStatusEnabledAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 18));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setStatusEnabledAt = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.clearStatusEnabledAt = function() {
  return this.setStatusEnabledAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.hasStatusEnabledAt = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional loupe.common.nullable.NullTimestamp status_disabled_at = 19;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getStatusDisabledAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 19));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
*/
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setStatusDisabledAt = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.clearStatusDisabledAt = function() {
  return this.setStatusDisabledAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.hasStatusDisabledAt = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional ForecastConfigColumnDisplayOption display_option = 20;
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumnDisplayOption}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getDisplayOption = function() {
  return /** @type {!proto.loupe.services.forecast.v3.ForecastConfigColumnDisplayOption} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigColumnDisplayOption} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setDisplayOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional ForecastConfigColumnDisplayVisibilityOption visibility_option = 21;
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumnDisplayVisibilityOption}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.getVisibilityOption = function() {
  return /** @type {!proto.loupe.services.forecast.v3.ForecastConfigColumnDisplayVisibilityOption} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigColumnDisplayVisibilityOption} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigColumn.prototype.setVisibilityOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigSearchContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    searchText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigSearchContainer}
 */
proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ForecastConfigSearchContainer;
  return proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigSearchContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigSearchContainer}
 */
proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.services.forecast.v3.ForecastConfigStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigSearchContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSearchText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ForecastConfigStatus status = 1;
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigStatus}
 */
proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.prototype.getStatus = function() {
  return /** @type {!proto.loupe.services.forecast.v3.ForecastConfigStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigStatus} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigSearchContainer} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string search_text = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.prototype.getSearchText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigSearchContainer} returns this
 */
proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.prototype.setSearchText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.SaveForecastConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.SaveForecastConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    forecastConfig: (f = msg.getForecastConfig()) && proto.loupe.services.forecast.v3.ForecastConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastConfigRequest}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.SaveForecastConfigRequest;
  return proto.loupe.services.forecast.v3.SaveForecastConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.SaveForecastConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastConfigRequest}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = new proto.loupe.services.forecast.v3.ForecastConfig;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfig.deserializeBinaryFromReader);
      msg.setForecastConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.SaveForecastConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.SaveForecastConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.forecast.v3.ForecastConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.SaveForecastConfigRequest} returns this
*/
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastConfigRequest} returns this
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ForecastConfig forecast_config = 2;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfig}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.prototype.getForecastConfig = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfig} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfig, 2));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfig|undefined} value
 * @return {!proto.loupe.services.forecast.v3.SaveForecastConfigRequest} returns this
*/
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.prototype.setForecastConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastConfigRequest} returns this
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.prototype.clearForecastConfig = function() {
  return this.setForecastConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigRequest.prototype.hasForecastConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    forecastConfigColumn: (f = msg.getForecastConfigColumn()) && proto.loupe.services.forecast.v3.ForecastConfigColumn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest;
  return proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigColumn;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigColumn.deserializeBinaryFromReader);
      msg.setForecastConfigColumn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfigColumn();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigColumn.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest} returns this
*/
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest} returns this
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ForecastConfigColumn forecast_config_column = 2;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfigColumn}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.prototype.getForecastConfigColumn = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfigColumn} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigColumn, 2));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfigColumn|undefined} value
 * @return {!proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest} returns this
*/
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.prototype.setForecastConfigColumn = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest} returns this
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.prototype.clearForecastConfigColumn = function() {
  return this.setForecastConfigColumn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.SaveForecastConfigColumnRequest.prototype.hasForecastConfigColumn = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ListForecastConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    filters: (f = msg.getFilters()) && proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsRequest}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ListForecastConfigsRequest;
  return proto.loupe.services.forecast.v3.ListForecastConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsRequest}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigSearchContainer;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ListForecastConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsRequest} returns this
*/
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsRequest} returns this
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ForecastConfigSearchContainer filters = 2;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfigSearchContainer}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.prototype.getFilters = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfigSearchContainer} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigSearchContainer, 2));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfigSearchContainer|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsRequest} returns this
*/
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsRequest} returns this
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filters: (f = msg.getFilters()) && proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest;
  return proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigSearchContainer;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest} returns this
*/
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest} returns this
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest} returns this
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ForecastConfigSearchContainer filters = 3;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfigSearchContainer}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.prototype.getFilters = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfigSearchContainer} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigSearchContainer, 3));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfigSearchContainer|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest} returns this
*/
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest} returns this
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsByGroupIdRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.GetForecastConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.GetForecastConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.GetForecastConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.GetForecastConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    forecastConfigId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.GetForecastConfigRequest}
 */
proto.loupe.services.forecast.v3.GetForecastConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.GetForecastConfigRequest;
  return proto.loupe.services.forecast.v3.GetForecastConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.GetForecastConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.GetForecastConfigRequest}
 */
proto.loupe.services.forecast.v3.GetForecastConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.GetForecastConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.GetForecastConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.GetForecastConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.GetForecastConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfigId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.GetForecastConfigRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastConfigRequest} returns this
*/
proto.loupe.services.forecast.v3.GetForecastConfigRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.GetForecastConfigRequest} returns this
 */
proto.loupe.services.forecast.v3.GetForecastConfigRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.GetForecastConfigRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string forecast_config_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.GetForecastConfigRequest.prototype.getForecastConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastConfigRequest} returns this
 */
proto.loupe.services.forecast.v3.GetForecastConfigRequest.prototype.setForecastConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    forecastConfigId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filters: (f = msg.getFilters()) && proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest;
  return proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigId(value);
      break;
    case 3:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigSearchContainer;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfigId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigSearchContainer.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest} returns this
*/
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest} returns this
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string forecast_config_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.prototype.getForecastConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest} returns this
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.prototype.setForecastConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ForecastConfigSearchContainer filters = 3;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfigSearchContainer}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.prototype.getFilters = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfigSearchContainer} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigSearchContainer, 3));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfigSearchContainer|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest} returns this
*/
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest} returns this
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    forecastConfigId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    forecastConfigColumnId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest}
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest;
  return proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest}
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigColumnId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfigId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getForecastConfigColumnId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest} returns this
*/
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest} returns this
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string forecast_config_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.prototype.getForecastConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest} returns this
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.prototype.setForecastConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string forecast_config_column_id = 3;
 * @return {string}
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.prototype.getForecastConfigColumnId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest} returns this
 */
proto.loupe.services.forecast.v3.GetForecastConfigColumnRequest.prototype.setForecastConfigColumnId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.ListForecastConfigsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ListForecastConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ListForecastConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    configsList: jspb.Message.toObjectList(msg.getConfigsList(),
    proto.loupe.services.forecast.v3.ForecastConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsResponse}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ListForecastConfigsResponse;
  return proto.loupe.services.forecast.v3.ListForecastConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsResponse}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.forecast.v3.ForecastConfig;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfig.deserializeBinaryFromReader);
      msg.addConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ListForecastConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ListForecastConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.forecast.v3.ForecastConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ForecastConfig configs = 1;
 * @return {!Array<!proto.loupe.services.forecast.v3.ForecastConfig>}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsResponse.prototype.getConfigsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v3.ForecastConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfig, 1));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v3.ForecastConfig>} value
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsResponse} returns this
*/
proto.loupe.services.forecast.v3.ListForecastConfigsResponse.prototype.setConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig}
 */
proto.loupe.services.forecast.v3.ListForecastConfigsResponse.prototype.addConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.forecast.v3.ForecastConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigsResponse} returns this
 */
proto.loupe.services.forecast.v3.ListForecastConfigsResponse.prototype.clearConfigsList = function() {
  return this.setConfigsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    configColumnsList: jspb.Message.toObjectList(msg.getConfigColumnsList(),
    proto.loupe.services.forecast.v3.ForecastConfigColumn.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse;
  return proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigColumn;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigColumn.deserializeBinaryFromReader);
      msg.addConfigColumns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigColumn.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ForecastConfigColumn config_columns = 1;
 * @return {!Array<!proto.loupe.services.forecast.v3.ForecastConfigColumn>}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.prototype.getConfigColumnsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v3.ForecastConfigColumn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigColumn, 1));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v3.ForecastConfigColumn>} value
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse} returns this
*/
proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.prototype.setConfigColumnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigColumn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigColumn}
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.prototype.addConfigColumns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.forecast.v3.ForecastConfigColumn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse} returns this
 */
proto.loupe.services.forecast.v3.ListForecastConfigColumnsResponse.prototype.clearConfigColumnsList = function() {
  return this.setConfigColumnsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest}
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest;
  return proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest}
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = new proto.loupe.services.forecast.v3.ForecastConfigPivotGroup;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.services.forecast.v3.ForecastConfigPivotGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest} returns this
*/
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest} returns this
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ForecastConfigPivotGroup groups = 2;
 * @return {!Array<!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup>}
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfigPivotGroup, 2));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup>} value
 * @return {!proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest} returns this
*/
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigPivotGroup}
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.services.forecast.v3.ForecastConfigPivotGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest} returns this
 */
proto.loupe.services.forecast.v3.AssignForecastConfigToPivotGroupsRequest.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    forecastConfigId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest}
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest;
  return proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest}
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfigId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest} returns this
*/
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest} returns this
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string forecast_config_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.prototype.getForecastConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest} returns this
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.prototype.setForecastConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string group_ids = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.prototype.getGroupIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest} returns this
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.prototype.setGroupIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest} returns this
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.prototype.addGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest} returns this
 */
proto.loupe.services.forecast.v3.RemoveForecastConfigFromPivotGroupsRequest.prototype.clearGroupIdsList = function() {
  return this.setGroupIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    forecastConfigId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest}
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest;
  return proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest}
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigId(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.services.forecast.v3.ForecastConfigStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfigId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest} returns this
*/
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest} returns this
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string forecast_config_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.prototype.getForecastConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest} returns this
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.prototype.setForecastConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ForecastConfigStatus status = 3;
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigStatus}
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.loupe.services.forecast.v3.ForecastConfigStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigStatus} value
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest} returns this
 */
proto.loupe.services.forecast.v3.SetForecastConfigStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    forecastConfigId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    forecastConfigColumnId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest}
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest;
  return proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest}
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigColumnId(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.services.forecast.v3.ForecastConfigStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfigId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getForecastConfigColumnId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest} returns this
*/
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest} returns this
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string forecast_config_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.prototype.getForecastConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest} returns this
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.prototype.setForecastConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string forecast_config_column_id = 3;
 * @return {string}
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.prototype.getForecastConfigColumnId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest} returns this
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.prototype.setForecastConfigColumnId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ForecastConfigStatus status = 4;
 * @return {!proto.loupe.services.forecast.v3.ForecastConfigStatus}
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.loupe.services.forecast.v3.ForecastConfigStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfigStatus} value
 * @return {!proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest} returns this
 */
proto.loupe.services.forecast.v3.SetForecastConfigColumnStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    forecastConfigIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest;
  return proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addForecastConfigIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfigIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest} returns this
*/
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest} returns this
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string forecast_config_ids = 2;
 * @return {!Array<string>}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.prototype.getForecastConfigIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest} returns this
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.prototype.setForecastConfigIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest} returns this
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.prototype.addForecastConfigIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest} returns this
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigsRequest.prototype.clearForecastConfigIdsList = function() {
  return this.setForecastConfigIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest;
  return proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = new proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest} returns this
*/
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest} returns this
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ReorderForecastConfigColumnsContainer items = 2;
 * @return {!Array<!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer>}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer, 2));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer>} value
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest} returns this
*/
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest} returns this
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastConfigId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    forecastConfigColumnId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer;
  return proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastConfigColumnId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastConfigId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getForecastConfigColumnId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string forecast_config_id = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.prototype.getForecastConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer} returns this
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.prototype.setForecastConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string forecast_config_column_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.prototype.getForecastConfigColumnId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer} returns this
 */
proto.loupe.services.forecast.v3.ReorderForecastConfigColumnsContainer.prototype.setForecastConfigColumnId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    forecastConfig: (f = msg.getForecastConfig()) && proto.loupe.services.forecast.v3.ForecastConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest}
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest;
  return proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest}
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = new proto.loupe.services.forecast.v3.ForecastConfig;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfig.deserializeBinaryFromReader);
      msg.setForecastConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.forecast.v3.ForecastConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest} returns this
*/
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest} returns this
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ForecastConfig forecast_config = 2;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfig}
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.prototype.getForecastConfig = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfig} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfig, 2));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfig|undefined} value
 * @return {!proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest} returns this
*/
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.prototype.setForecastConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest} returns this
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.prototype.clearForecastConfig = function() {
  return this.setForecastConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.PublishForecastConfigDraftRequest.prototype.hasForecastConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    forecastConfig: (f = msg.getForecastConfig()) && proto.loupe.services.forecast.v3.ForecastConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest}
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest;
  return proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest}
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = new proto.loupe.services.forecast.v3.ForecastConfig;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfig.deserializeBinaryFromReader);
      msg.setForecastConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.forecast.v3.ForecastConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest} returns this
*/
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest} returns this
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ForecastConfig forecast_config = 2;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfig}
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.prototype.getForecastConfig = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfig} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfig, 2));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfig|undefined} value
 * @return {!proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest} returns this
*/
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.prototype.setForecastConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest} returns this
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.prototype.clearForecastConfig = function() {
  return this.setForecastConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.DuplicateForecastConfigRequest.prototype.hasForecastConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    searchRequest: (f = msg.getSearchRequest()) && proto.loupe.services.forecast.v3.SearchRequest.toObject(includeInstance, f),
    forecastConfig: (f = msg.getForecastConfig()) && proto.loupe.services.forecast.v3.ForecastConfig.toObject(includeInstance, f),
    groupName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest;
  return proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = new proto.loupe.services.forecast.v3.SearchRequest;
      reader.readMessage(value,proto.loupe.services.forecast.v3.SearchRequest.deserializeBinaryFromReader);
      msg.setSearchRequest(value);
      break;
    case 3:
      var value = new proto.loupe.services.forecast.v3.ForecastConfig;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfig.deserializeBinaryFromReader);
      msg.setForecastConfig(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getSearchRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.services.forecast.v3.SearchRequest.serializeBinaryToWriter
    );
  }
  f = message.getForecastConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.loupe.services.forecast.v3.ForecastConfig.serializeBinaryToWriter
    );
  }
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} returns this
*/
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} returns this
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SearchRequest search_request = 2;
 * @return {?proto.loupe.services.forecast.v3.SearchRequest}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.getSearchRequest = function() {
  return /** @type{?proto.loupe.services.forecast.v3.SearchRequest} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.SearchRequest, 2));
};


/**
 * @param {?proto.loupe.services.forecast.v3.SearchRequest|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} returns this
*/
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.setSearchRequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} returns this
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.clearSearchRequest = function() {
  return this.setSearchRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.hasSearchRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ForecastConfig forecast_config = 3;
 * @return {?proto.loupe.services.forecast.v3.ForecastConfig}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.getForecastConfig = function() {
  return /** @type{?proto.loupe.services.forecast.v3.ForecastConfig} */ (
    jspb.Message.getWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfig, 3));
};


/**
 * @param {?proto.loupe.services.forecast.v3.ForecastConfig|undefined} value
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} returns this
*/
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.setForecastConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} returns this
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.clearForecastConfig = function() {
  return this.setForecastConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.hasForecastConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string group_name = 4;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest} returns this
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryRequest.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse;
  return proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse} returns this
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.prototype.getFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse} returns this
 */
proto.loupe.services.forecast.v3.ExportSubmissionHistoryResponse.prototype.setFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f),
    oldTenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newTenantId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest;
  return proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
  f = message.getOldTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewTenantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional loupe.common.Actor actor = 1;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 1));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest} returns this
*/
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest} returns this
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string old_tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.prototype.getOldTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest} returns this
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.prototype.setOldTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string new_tenant_id = 3;
 * @return {string}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.prototype.getNewTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest} returns this
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxRequest.prototype.setNewTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    configsList: jspb.Message.toObjectList(msg.getConfigsList(),
    proto.loupe.services.forecast.v3.ForecastConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse;
  return proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.forecast.v3.ForecastConfig;
      reader.readMessage(value,proto.loupe.services.forecast.v3.ForecastConfig.deserializeBinaryFromReader);
      msg.addConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.forecast.v3.ForecastConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ForecastConfig configs = 1;
 * @return {!Array<!proto.loupe.services.forecast.v3.ForecastConfig>}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.prototype.getConfigsList = function() {
  return /** @type{!Array<!proto.loupe.services.forecast.v3.ForecastConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.forecast.v3.ForecastConfig, 1));
};


/**
 * @param {!Array<!proto.loupe.services.forecast.v3.ForecastConfig>} value
 * @return {!proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse} returns this
*/
proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.prototype.setConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.forecast.v3.ForecastConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.forecast.v3.ForecastConfig}
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.prototype.addConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.forecast.v3.ForecastConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse} returns this
 */
proto.loupe.services.forecast.v3.CopyForecastToSandboxResponse.prototype.clearConfigsList = function() {
  return this.setConfigsList([]);
};


/**
 * @enum {number}
 */
proto.loupe.services.forecast.v3.ForecastConfigStatus = {
  UNKNOWN: 0,
  PENDING: 1,
  ENABLED: 2,
  DISABLED: 3,
  DELETED: 4
};

/**
 * @enum {number}
 */
proto.loupe.services.forecast.v3.ForecastConfigPublishStatus = {
  UNKNOWN_PUBLISH_STATUS: 0,
  DRAFT_PUBLISH_STATUS: 1,
  PUBLISHED_PUBLISH_STATUS: 2
};

/**
 * @enum {number}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumnDisplayOption = {
  ALL: 0,
  BANNER: 1,
  GRID: 2,
  BANNER_GRID: 3
};

/**
 * @enum {number}
 */
proto.loupe.services.forecast.v3.ForecastConfigColumnDisplayVisibilityOption = {
  ALL: 0,
  MANAGER: 1,
  IC: 2
};

/**
 * @enum {number}
 */
proto.loupe.services.forecast.v3.ForecastPeriodType = {
  CUSTOM: 0,
  WEEK: 1,
  MONTH: 2,
  QUARTER: 3,
  PERIOD: 4,
  HALF_YEAR: 5,
  YEAR: 6
};

/**
 * @enum {number}
 */
proto.loupe.services.forecast.v3.AutoCalculateSetting = {
  AUTO: 0,
  MANUAL: 1
};

/**
 * @enum {number}
 */
proto.loupe.services.forecast.v3.ForecastConfigValueFormat = {
  UNSPECIFIED: 0,
  NUMBER: 1,
  CURRENCY: 2,
  PERCENT: 3,
  COVERAGE: 4,
  SHORT_CURRENCY: 5
};

goog.object.extend(exports, proto.loupe.services.forecast.v3);
