import React, { useMemo } from 'react'
import { useReferenceFieldOptionsLabels } from '../../../context/referenceFieldOptionsLabels'
import ChevronDown from '../../icons/chevronDown'

const FieldSelectButton = (props) => {
  const {
    buttonRef,
    fullWidth = false,
    selectedDisplay = '',
    handleOpen,
    lazyLoad = false
  } = props

  const { referenceFields } = useReferenceFieldOptionsLabels()

  const displayValue = useMemo(() => {
    if (lazyLoad) {
      const options = [
        ...referenceFields
      ]
      const selectedValues = [...selectedDisplay.split(', ')]
      const mappedValues = selectedValues
        .map((v) => options.find((lv) => lv.key === v))
        .filter((mv) => mv !== undefined)
        .map((mv) => mv.label)
      if (mappedValues.length > 1) {
        return `${mappedValues[0]} + ${mappedValues.slice(1).length} more`
      } else if (mappedValues.length === 1) {
        return mappedValues[0]
      }
    } else {
      return selectedDisplay
    }
  }, [selectedDisplay, lazyLoad, referenceFields])

  return (
    <button
      className="field field-select"
      ref={buttonRef}
      onClick={handleOpen}>
      {displayValue
        ? <div style={{ maxWidth: !fullWidth && buttonRef.current ? `${buttonRef.current.clientWidth - 40}px` : '100%' }} className="overflow-ellip">{displayValue}</div>
        : <span>Select</span>}
      <ChevronDown fill="#A6B2CF" className="ml-4" />
    </button>
  )
}

export default FieldSelectButton
