import { Help } from '@getoutreach/react-icons'
import { Link } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { useSetup } from '../../context/setup'
import AdminLogo from '../icons/adminLogo'
import AppVersion from '../common/appVersion'
import React, { useMemo } from 'react'
import SideMenuDefault from './sideMenuDefault'
import SideMenuSetup from './sideMenuSetup'
import SystemAdminMenu from './systemAdminMenu'

const SideMenu = (props) => {
  const { isComplete, isFetching } = useSetup()
  const { routes } = useRoutes()

  const SideMenuComponent = useMemo(() => {
    if (isFetching) {
      return null
    } else if (!isComplete) {
      return <SideMenuSetup />
    } else {
      return <SideMenuDefault />
    }
  }, [isComplete, isFetching])

  return (
    <div className="sidemenu w-full min-h-full hidden md:inline md:w-1/5 z-sideMenu" style={{ minWidth: 200 }}>
      <div className="flex flex-col h-full">

        <div className="w-full" style={{ minHeight: 93 }}>
          <div className="flex flex-shrink-0 flex-row items-center px-6 w-full bg-color-ffffff" style={{ paddingTop: 30 }}>
            <Link to={routes.default}>
              <AdminLogo />
            </Link>
          </div>
        </div>

        <div className="flex-grow overflow-auto">
          {SideMenuComponent}
          <SystemAdminMenu />
        </div>

        <div className="w-full flex flex-col font-normal justify-end py-8 px-6 mt-auto">
          <a
            className="flex items-center text-size-15px text-color-5951FF mb-4 whitespace-nowrap"
            href="https://support.outreach.io/hc/en-us/signin?utm_source=or&utm_medium=dd&utm_campaign=pltfm"
            target="_blank"
            rel="noreferrer">
            <Help />
            <span className="ml-6">
              Support Portal
            </span>
          </a>
          <AppVersion className="text-size-13px text-color-a6b2cf font-normal" />
        </div>

      </div>
    </div>
  )
}

export default SideMenu
