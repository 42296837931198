import React, { useCallback, useMemo, useState } from 'react'
import Modal from '../common/modal'
import ModalHeader from '../common/modalHeader'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import SearchBox from '../common/searchBox'
import More from '../icons/more'
import { useTextField } from '../../hooks/useTextField'
import Button from '../common/button'
import Initials from '../common/initials'
import EmptyState from '../common/emptyState'
import pluralize from 'pluralize'
import { AutoSizer, List } from 'react-virtualized'
import { useVirtualizedList } from '../virtualized/useVirtualizedList'
import Delete from '../icons/delete'
import Plus from '../icons/plus'
import { useGrpcCallback } from '../../grpc'
import { toIdsRequest, toSearchPeopleRequest, toUpdatePersonRequest } from '../../grpc/converters'
import { debounce, filter, find, orderBy } from 'lodash'
import { Waypoint } from 'react-waypoint'
import { useNotification } from '../../hooks/useNotification'
import AccountCircle from '../icons/accountCircle'
import { usePopover } from '../../hooks/usePopover'
import ActionPopover from '../common/actionPopover'
import { useAuth } from '../../context/auth'
import classNames from 'classnames'
import { useSyncState } from '../../context/syncState'
import { BasicStatus, GroupSyncStatus } from '../../grpc/enums'
import { useGroupsError } from '../../context/groupsError'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const searchPageSize = 10
const rowHeight = 51

const GroupMembersModal = (props) => {
  const {
    modal,
    groupData,
    invalidate,
  } = props

  // state to hold the crm roles that are specifically fetched by id
  const [crmRolesByIds, setCrmRolesByIds] = useState([])

  const { checkPermission } = usePermissions()

  const { tenantId } = useAuth()

  const { syncStatus } = useSyncState()

  const { handleFailedPreconditionError } = useGroupsError()

  const { open, handleClose } = modal

  const deletePopover = usePopover()

  const { notifyError } = useNotification()

  const search = useTextField()

  const addPopover = usePopover()

  const title = useMemo(() => {
    return (groupData && groupData.title) || ''
  }, [groupData])

  const users = useMemo(() => {
    const membersList = (groupData && groupData.membersList) || []
    return orderBy(membersList, [(o) => o.status, (o) => o.name.toLowerCase()], ['desc', 'asc'])
  }, [groupData])

  const count = useMemo(() => {
    return filter(users, (u) => u.status === BasicStatus.ACTIVE).length
  }, [users])

  const handleCloseInternal = useCallback(() => {
    search.reset()
    handleClose && handleClose()
  }, [handleClose])

  const onDone = useCallback((e) => {
    e && e.preventDefault()
    handleCloseInternal && handleCloseInternal()
  }, [])

  const [searchedPeople, setSearchedPeople] = useState({ peopleList: [], total: 0 })

  const searchPeople = useGrpcCallback({
    onError: (err) => {
      if (handleFailedPreconditionError(err)) {
        return
      }
      notifyError('Error searching people!')
    },
    onSuccess: (data) => {
      setSearchedPeople(data)
    },
    grpcMethod: 'searchPeople',
    debug: false,
  }, [searchedPeople, handleFailedPreconditionError])

  const searchPeoplePaged = useGrpcCallback({
    onError: (err) => {
      if (handleFailedPreconditionError(err)) {
        return
      }
      notifyError('Error searching people!')
    },
    onSuccess: (data) => {
      setSearchedPeople({
        peopleList: [
          ...searchedPeople.peopleList,
          ...data.peopleList,
        ],
        total: data.total,
      })
    },
    grpcMethod: 'searchPeople',
    debug: false,
  }, [searchedPeople, handleFailedPreconditionError])

  const debounceSearchPeople = useMemo(() => {
    return debounce(searchPeople, 350)
  }, [searchPeople])

  const onSearchChange = useCallback((e) => {
    search.onChange(e)
    const request = toSearchPeopleRequest({
      tenantId,
      search: e.target.value,
      page: 1,
      pageSize: searchPageSize,
    })
    debounceSearchPeople(request)
  }, [tenantId, debounceSearchPeople])

  const handleWaypointEntered = useCallback(() => {
    const { peopleList } = searchedPeople
    const request = toSearchPeopleRequest({
      tenantId,
      search: search.value,
      page: (peopleList.length / searchPageSize) + 1,
      pageSize: searchPageSize,
    })
    searchPeoplePaged(request)
  }, [tenantId, searchPeople, search.value, searchedPeople])

  const renderedUsers = useMemo(() => {
    if (search.value) {
      const { peopleList, total } = searchedPeople
      const enrichedPeopleList = peopleList.map((u) => {
        let isMember = false
        if (find(users, (user) => user.id === u.id)) {
          isMember = true
        }
        return {
          ...u,
          isMember,
        }
      })
      if (enrichedPeopleList.length < total) {
        return [
          ...enrichedPeopleList,
          { showMore: true },
        ]
      } else {
        return enrichedPeopleList
      }
    }
    return users.map((u) => {
      return {
        ...u,
        isMember: true,
      }
    })
  }, [groupData, users, search.value, searchedPeople])

  const updatePerson = useGrpcCallback({
    onError: () => {
      notifyError('Error updating person!')
    },
    onSuccess: () => {
      invalidate()
    },
    grpcMethod: 'updatePerson',
    debug: false,
  }, [invalidate])

  const onDelete = useCallback((e, user) => {
    deletePopover.setData(user)
    deletePopover.setAnchorEl(e.currentTarget)
  }, [])

  const onDeleteCancel = useCallback(() => {
    deletePopover.setData(undefined)
  }, [])

  const onDeleteConfirmed = useCallback(() => {
    const user = deletePopover.data
    const request = toUpdatePersonRequest({
      tenantId,
      personId: user.id,
      person: {
        ...user,
        groupId: '',
      },
      onlyFieldsList: ['group_id'],
    })
    updatePerson(request)
  }, [tenantId, deletePopover.data])

  const getCrmRolesByIds = useGrpcCallback({
    onSuccess: (data) => {
      const { rolesList = [] } = data
      setCrmRolesByIds(rolesList)
    },
    grpcMethod: 'getCRMRolesByIds',
    debug: false,
  }, [])

  const onAdd = useCallback((e, user) => {
    if (user && user.groupId) {
      addPopover.setData(user)
      addPopover.setAnchorEl(e.currentTarget)
      if (user?.crmRoleIdsList && user.crmRoleIdsList.length > 0) {
        getCrmRolesByIds(toIdsRequest({ idsList: user?.crmRoleIdsList ?? [], tenantId }))
      }
      return
    }
    const request = toUpdatePersonRequest({
      tenantId,
      personId: user.id,
      person: {
        ...user,
        groupId: groupData.group.id,
      },
      onlyFieldsList: ['group_id'],
    })
    updatePerson(request)
  }, [tenantId, groupData, addPopover.data])

  const onAddConfirmed = useCallback(() => {
    const user = addPopover.data
    onAdd(null, {
      ...user,
      groupId: null
    })
  }, [onAdd])

  const onAddCancel = useCallback(() => {
    addPopover.setData(undefined)
  }, [])

  const getCurrentGroup = useCallback(() => {
    return crmRolesByIds?.[0]?.name ?? ''
  }, [crmRolesByIds])

  const userLocked = useCallback((crmRoleIdsList) => {
    return syncStatus === GroupSyncStatus.ACTIVE && crmRoleIdsList.length > 0
  }, [syncStatus])

  const renderUser = useCallback(({ rowRendererArgs, cellMeasurerArgs }) => {
    const { index, style } = rowRendererArgs
    const { registerChild } = cellMeasurerArgs
    const user = renderedUsers[index]
    const { name, email, firstName, lastName, showMore, crmRoleIdsList = [], isMember } = user
    const isUserLocked = userLocked(crmRoleIdsList)
    const isUserInactive = user.status === GroupSyncStatus.INACTIVE
    const canUpdateGroup = checkPermission(permissionNames.CanUpdateGroup)
    return (
      <div ref={registerChild} style={style}>
        {showMore
          ? (
            <Waypoint onEnter={handleWaypointEntered}>
              <div className="flex justify-center" style={{ height: rowHeight }}>
                <More className="flex-shrink-0" fill="#a0a8bb" />
              </div>
            </Waypoint>
          )
          : (
            <div className="flex items-center justify-between px-2 py-2" style={{ height: rowHeight }}>
              <div className="flex items-center">
                <Initials
                  firstInitial={firstName.charAt(0)}
                  lastInitial={lastName.charAt(0)} />
                <div className={classNames('flex flex-col leading-tight ml-3', { 'opacity-50': isUserInactive })}>
                  <div className="text-size-16px text-color-09242f font-weight-400">{name}</div>
                  <div className="text-size-12px text-color-51636a font-weight-400">{email}</div>
                </div>
              </div>
              {!isUserLocked && canUpdateGroup
                && (
                  <>
                    {isMember
                      ? (
                        <button
                          className="focus:outline-none"
                          onClick={(e) => onDelete(e, user)}>
                          <Delete className="flex-shrink-0" fill="#a0a8bb" transform="scale(0.85)" />
                        </button>
                      )
                      : (
                        <>
                          <button
                            className="focus:outline-none"
                            onClick={(e) => onAdd(e, user)}>
                            <Plus className="flex-shrink-0" fill="#a0a8bb" transform="scale(0.85)" />
                          </button>
                        </>
                      )}
                  </>
                )}
            </div>
          )}
      </div>
    )
  }, [renderedUsers, handleWaypointEntered, onDelete, onAdd, userLocked, checkPermission])

  const { cellMeasurerCache, rowRenderer } = useVirtualizedList({
    defaultHeight: rowHeight,
    rowRenderer: renderUser,
  })

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="sm"
      open={open}>

      <ModalHeader
        title="Members"
        onClose={handleCloseInternal} />

      <ModalBody>

        <div className="w-full h-full px-8 pt-10">

          <div className="flex items-center justify-between leading-none mx-2">
            <div className="text-size-18px text-color-09242f font-weight-700 pr-2 truncate">{title}</div>
            <div className="text-size-16px text-color-51636a whitespace-nowrap">
              {count}
              {' '}
              Active
              {' '}
              {pluralize('Member', count)}
            </div>
          </div>

          <SearchBox
            className="mt-2 mb-4 mx-2"
            value={search.value}
            onChange={onSearchChange}
            onClear={search.reset} />

          <div className="h-full overflow-auto" style={{ minHeight: 255 }}>
            <AutoSizer>
              {({ width, height }) => (
                <div style={{ width, height }}>
                  {renderedUsers.length === 0
                    ? (
                      <div className="flex items-center justify-center pt-10">
                        <EmptyState
                          iconControl={<AccountCircle fill="#5951FF" transform="scale(2)" />}
                          header="No Members"
                          subHeader={search.value ? 'Modify your search and try again' : 'Use the search bar above to add members to the group'} />
                      </div>
                    )
                    : (
                      <List
                        className="focus:outline-none"
                        width={width}
                        height={height}
                        deferredMeasurementCache={cellMeasurerCache}
                        rowHeight={cellMeasurerCache.rowHeight}
                        rowCount={renderedUsers.length}
                        rowRenderer={rowRenderer} />
                    )}
                </div>
              )}
            </AutoSizer>
          </div>
        </div>

      </ModalBody>

      <ActionPopover
        popover={deletePopover}
        text="Are you sure you want to delete this group member?"
        subText="It can't be undone."
        actionText="Delete"
        onCancel={onDeleteCancel}
        onAction={onDeleteConfirmed}
        maxWidth={285} />

      <ActionPopover
        popover={addPopover}
        text="This person is already a member of another team."
        subText={`Adding them to ${title} will remove them from ${getCurrentGroup()}.`}
        actionText="Add"
        onCancel={onAddCancel}
        onAction={onAddConfirmed}
        maxWidth={285} />

      <ModalFooter>
        <Button
          text="Done"
          onClick={onDone} />
      </ModalFooter>

    </Modal>
  )
}

export default GroupMembersModal
