import ChevronDown from '../../icons/chevronDown'
import React from 'react'

const FieldEmpty = (props) => {
  return (
    <div className="field text-color-c9ced0">
      Select
      <ChevronDown fill="#C9CED0" className="ml-4" />
    </div>
  )
}

export default FieldEmpty
