// source: src/common/forecast/forecast_item.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_person_person_pb = require('../../../src/common/person/person_pb.js');
goog.object.extend(proto, src_common_person_person_pb);
var src_common_opportunity_opportunity_pb = require('../../../src/common/opportunity/opportunity_pb.js');
goog.object.extend(proto, src_common_opportunity_opportunity_pb);
var src_common_nullable_nullable_pb = require('../../../src/common/nullable/nullable_pb.js');
goog.object.extend(proto, src_common_nullable_nullable_pb);
goog.exportSymbol('proto.loupe.common.forecast.ForecastItem', null, global);
goog.exportSymbol('proto.loupe.common.forecast.ForecastItemList', null, global);
goog.exportSymbol('proto.loupe.common.forecast.ForecastItemStatus', null, global);
goog.exportSymbol('proto.loupe.common.forecast.ForecastItemType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.ForecastItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.forecast.ForecastItem.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.forecast.ForecastItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.ForecastItem.displayName = 'proto.loupe.common.forecast.ForecastItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.forecast.ForecastItemList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.forecast.ForecastItemList.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.forecast.ForecastItemList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.forecast.ForecastItemList.displayName = 'proto.loupe.common.forecast.ForecastItemList';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.forecast.ForecastItem.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.ForecastItem.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.ForecastItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.ForecastItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.ForecastItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    forecastEntryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: (f = msg.getAmount()) && src_common_nullable_nullable_pb.NullFloat64.toObject(includeInstance, f),
    forecastCategoryId: (f = msg.getForecastCategoryId()) && src_common_nullable_nullable_pb.NullString.toObject(includeInstance, f),
    recordTypeId: (f = msg.getRecordTypeId()) && src_common_nullable_nullable_pb.NullString.toObject(includeInstance, f),
    override: (f = msg.getOverride()) && proto.loupe.common.forecast.ForecastItem.toObject(includeInstance, f),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.loupe.common.forecast.ForecastItem.toObject, includeInstance),
    ownerId: (f = msg.getOwnerId()) && src_common_nullable_nullable_pb.NullString.toObject(includeInstance, f),
    latestChange: (f = msg.getLatestChange()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    owner: (f = msg.getOwner()) && src_common_person_person_pb.Person.toObject(includeInstance, f),
    opportunity: (f = msg.getOpportunity()) && src_common_opportunity_opportunity_pb.Opportunity.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 15, 0),
    prev: (f = msg.getPrev()) && proto.loupe.common.forecast.ForecastItem.toObject(includeInstance, f),
    next: (f = msg.getNext()) && proto.loupe.common.forecast.ForecastItem.toObject(includeInstance, f),
    note: jspb.Message.getFieldWithDefault(msg, 18, ""),
    rawAmount: (f = msg.getRawAmount()) && src_common_nullable_nullable_pb.NullFloat64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.ForecastItem}
 */
proto.loupe.common.forecast.ForecastItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.ForecastItem;
  return proto.loupe.common.forecast.ForecastItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.ForecastItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.ForecastItem}
 */
proto.loupe.common.forecast.ForecastItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setForecastEntryId(value);
      break;
    case 3:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 6:
      var value = new src_common_nullable_nullable_pb.NullFloat64;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullFloat64.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 7:
      var value = new src_common_nullable_nullable_pb.NullString;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullString.deserializeBinaryFromReader);
      msg.setForecastCategoryId(value);
      break;
    case 8:
      var value = new src_common_nullable_nullable_pb.NullString;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullString.deserializeBinaryFromReader);
      msg.setRecordTypeId(value);
      break;
    case 9:
      var value = new proto.loupe.common.forecast.ForecastItem;
      reader.readMessage(value,proto.loupe.common.forecast.ForecastItem.deserializeBinaryFromReader);
      msg.setOverride(value);
      break;
    case 10:
      var value = new proto.loupe.common.forecast.ForecastItem;
      reader.readMessage(value,proto.loupe.common.forecast.ForecastItem.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    case 11:
      var value = new src_common_nullable_nullable_pb.NullString;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullString.deserializeBinaryFromReader);
      msg.setOwnerId(value);
      break;
    case 12:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setLatestChange(value);
      break;
    case 13:
      var value = new src_common_person_person_pb.Person;
      reader.readMessage(value,src_common_person_person_pb.Person.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 14:
      var value = new src_common_opportunity_opportunity_pb.Opportunity;
      reader.readMessage(value,src_common_opportunity_opportunity_pb.Opportunity.deserializeBinaryFromReader);
      msg.setOpportunity(value);
      break;
    case 15:
      var value = /** @type {!proto.loupe.common.forecast.ForecastItemStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 16:
      var value = new proto.loupe.common.forecast.ForecastItem;
      reader.readMessage(value,proto.loupe.common.forecast.ForecastItem.deserializeBinaryFromReader);
      msg.setPrev(value);
      break;
    case 17:
      var value = new proto.loupe.common.forecast.ForecastItem;
      reader.readMessage(value,proto.loupe.common.forecast.ForecastItem.deserializeBinaryFromReader);
      msg.setNext(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 19:
      var value = new src_common_nullable_nullable_pb.NullFloat64;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullFloat64.deserializeBinaryFromReader);
      msg.setRawAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.ForecastItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.ForecastItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.ForecastItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.ForecastItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getForecastEntryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_nullable_nullable_pb.NullFloat64.serializeBinaryToWriter
    );
  }
  f = message.getForecastCategoryId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      src_common_nullable_nullable_pb.NullString.serializeBinaryToWriter
    );
  }
  f = message.getRecordTypeId();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      src_common_nullable_nullable_pb.NullString.serializeBinaryToWriter
    );
  }
  f = message.getOverride();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.loupe.common.forecast.ForecastItem.serializeBinaryToWriter
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.loupe.common.forecast.ForecastItem.serializeBinaryToWriter
    );
  }
  f = message.getOwnerId();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      src_common_nullable_nullable_pb.NullString.serializeBinaryToWriter
    );
  }
  f = message.getLatestChange();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      src_common_person_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getOpportunity();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      src_common_opportunity_opportunity_pb.Opportunity.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getPrev();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.loupe.common.forecast.ForecastItem.serializeBinaryToWriter
    );
  }
  f = message.getNext();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.loupe.common.forecast.ForecastItem.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getRawAmount();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      src_common_nullable_nullable_pb.NullFloat64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string forecast_entry_id = 2;
 * @return {string}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getForecastEntryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.setForecastEntryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.nullable.NullTimestamp created_at = 3;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getCreatedAt = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 3));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastItem.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string item_id = 5;
 * @return {string}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional loupe.common.nullable.NullFloat64 amount = 6;
 * @return {?proto.loupe.common.nullable.NullFloat64}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getAmount = function() {
  return /** @type{?proto.loupe.common.nullable.NullFloat64} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullFloat64, 6));
};


/**
 * @param {?proto.loupe.common.nullable.NullFloat64|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastItem.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional loupe.common.nullable.NullString forecast_category_id = 7;
 * @return {?proto.loupe.common.nullable.NullString}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getForecastCategoryId = function() {
  return /** @type{?proto.loupe.common.nullable.NullString} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullString, 7));
};


/**
 * @param {?proto.loupe.common.nullable.NullString|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setForecastCategoryId = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearForecastCategoryId = function() {
  return this.setForecastCategoryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastItem.prototype.hasForecastCategoryId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional loupe.common.nullable.NullString record_type_id = 8;
 * @return {?proto.loupe.common.nullable.NullString}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getRecordTypeId = function() {
  return /** @type{?proto.loupe.common.nullable.NullString} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullString, 8));
};


/**
 * @param {?proto.loupe.common.nullable.NullString|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setRecordTypeId = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearRecordTypeId = function() {
  return this.setRecordTypeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastItem.prototype.hasRecordTypeId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ForecastItem override = 9;
 * @return {?proto.loupe.common.forecast.ForecastItem}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getOverride = function() {
  return /** @type{?proto.loupe.common.forecast.ForecastItem} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.forecast.ForecastItem, 9));
};


/**
 * @param {?proto.loupe.common.forecast.ForecastItem|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setOverride = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearOverride = function() {
  return this.setOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastItem.prototype.hasOverride = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated ForecastItem children = 10;
 * @return {!Array<!proto.loupe.common.forecast.ForecastItem>}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.loupe.common.forecast.ForecastItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.forecast.ForecastItem, 10));
};


/**
 * @param {!Array<!proto.loupe.common.forecast.ForecastItem>} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.loupe.common.forecast.ForecastItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.ForecastItem}
 */
proto.loupe.common.forecast.ForecastItem.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.loupe.common.forecast.ForecastItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};


/**
 * optional loupe.common.nullable.NullString owner_id = 11;
 * @return {?proto.loupe.common.nullable.NullString}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getOwnerId = function() {
  return /** @type{?proto.loupe.common.nullable.NullString} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullString, 11));
};


/**
 * @param {?proto.loupe.common.nullable.NullString|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setOwnerId = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearOwnerId = function() {
  return this.setOwnerId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastItem.prototype.hasOwnerId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional loupe.common.nullable.NullTimestamp latest_change = 12;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getLatestChange = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 12));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setLatestChange = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearLatestChange = function() {
  return this.setLatestChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastItem.prototype.hasLatestChange = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional loupe.common.person.Person owner = 13;
 * @return {?proto.loupe.common.person.Person}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getOwner = function() {
  return /** @type{?proto.loupe.common.person.Person} */ (
    jspb.Message.getWrapperField(this, src_common_person_person_pb.Person, 13));
};


/**
 * @param {?proto.loupe.common.person.Person|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastItem.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional loupe.common.opportunity.Opportunity opportunity = 14;
 * @return {?proto.loupe.common.opportunity.Opportunity}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getOpportunity = function() {
  return /** @type{?proto.loupe.common.opportunity.Opportunity} */ (
    jspb.Message.getWrapperField(this, src_common_opportunity_opportunity_pb.Opportunity, 14));
};


/**
 * @param {?proto.loupe.common.opportunity.Opportunity|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setOpportunity = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearOpportunity = function() {
  return this.setOpportunity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastItem.prototype.hasOpportunity = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ForecastItemStatus status = 15;
 * @return {!proto.loupe.common.forecast.ForecastItemStatus}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.forecast.ForecastItemStatus} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.loupe.common.forecast.ForecastItemStatus} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional ForecastItem prev = 16;
 * @return {?proto.loupe.common.forecast.ForecastItem}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getPrev = function() {
  return /** @type{?proto.loupe.common.forecast.ForecastItem} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.forecast.ForecastItem, 16));
};


/**
 * @param {?proto.loupe.common.forecast.ForecastItem|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setPrev = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearPrev = function() {
  return this.setPrev(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastItem.prototype.hasPrev = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional ForecastItem next = 17;
 * @return {?proto.loupe.common.forecast.ForecastItem}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getNext = function() {
  return /** @type{?proto.loupe.common.forecast.ForecastItem} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.forecast.ForecastItem, 17));
};


/**
 * @param {?proto.loupe.common.forecast.ForecastItem|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setNext = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearNext = function() {
  return this.setNext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastItem.prototype.hasNext = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string note = 18;
 * @return {string}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional loupe.common.nullable.NullFloat64 raw_amount = 19;
 * @return {?proto.loupe.common.nullable.NullFloat64}
 */
proto.loupe.common.forecast.ForecastItem.prototype.getRawAmount = function() {
  return /** @type{?proto.loupe.common.nullable.NullFloat64} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullFloat64, 19));
};


/**
 * @param {?proto.loupe.common.nullable.NullFloat64|undefined} value
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
*/
proto.loupe.common.forecast.ForecastItem.prototype.setRawAmount = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.forecast.ForecastItem} returns this
 */
proto.loupe.common.forecast.ForecastItem.prototype.clearRawAmount = function() {
  return this.setRawAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.forecast.ForecastItem.prototype.hasRawAmount = function() {
  return jspb.Message.getField(this, 19) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.forecast.ForecastItemList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.forecast.ForecastItemList.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.forecast.ForecastItemList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.forecast.ForecastItemList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.ForecastItemList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.loupe.common.forecast.ForecastItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.forecast.ForecastItemList}
 */
proto.loupe.common.forecast.ForecastItemList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.forecast.ForecastItemList;
  return proto.loupe.common.forecast.ForecastItemList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.forecast.ForecastItemList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.forecast.ForecastItemList}
 */
proto.loupe.common.forecast.ForecastItemList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.forecast.ForecastItem;
      reader.readMessage(value,proto.loupe.common.forecast.ForecastItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.forecast.ForecastItemList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.forecast.ForecastItemList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.forecast.ForecastItemList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.forecast.ForecastItemList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.forecast.ForecastItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ForecastItem items = 1;
 * @return {!Array<!proto.loupe.common.forecast.ForecastItem>}
 */
proto.loupe.common.forecast.ForecastItemList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.loupe.common.forecast.ForecastItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.forecast.ForecastItem, 1));
};


/**
 * @param {!Array<!proto.loupe.common.forecast.ForecastItem>} value
 * @return {!proto.loupe.common.forecast.ForecastItemList} returns this
*/
proto.loupe.common.forecast.ForecastItemList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.forecast.ForecastItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.forecast.ForecastItem}
 */
proto.loupe.common.forecast.ForecastItemList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.forecast.ForecastItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.forecast.ForecastItemList} returns this
 */
proto.loupe.common.forecast.ForecastItemList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * @enum {number}
 */
proto.loupe.common.forecast.ForecastItemType = {
  FORECAST: 0,
  OPPORTUNITY: 1
};

/**
 * @enum {number}
 */
proto.loupe.common.forecast.ForecastItemStatus = {
  OPEN: 0,
  PENDING: 1,
  REVIEWED: 2,
  DELETED: 3
};

goog.object.extend(exports, proto.loupe.common.forecast);
