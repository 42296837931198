import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DateRange } from 'react-date-range'
import classNames from 'classnames'
import {
  addWeeks,
  addMonths,
  addQuarters,
  addYears,
  subWeeks,
  subMonths,
  subQuarters,
  subYears,
  endOfWeek,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns'
import { remove, values } from 'lodash'
import { QueryDateRangeType } from '../../grpc/enums'

const defaultRangeSelection = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
}

const DateRangePicker = (props) => {
  const {
    editableDateInputs = false,
    moveRangeOnFirstSelection = true,
    rangeSelection,
    fiscalYearStartMonth = 1,
    salesPeriodLength = 3,
    onChange,
    showDefinedRanges = true
  } = props

  const definedRangeOptions = useMemo(() => {
    const options = values(datePickerDefinedRanges)
    if (salesPeriodLength !== 3) {
      remove(options, (o) => [
        datePickerDefinedRanges.THIS_QUARTER.key,
        datePickerDefinedRanges.NEXT_QUARTER.key,
        datePickerDefinedRanges.LAST_QUARTER.key
      ].includes(o.key))
    }
    return options
  }, [salesPeriodLength])

  const [selectedRanges, setSelectedRanges] = useState(defaultRangeSelection)

  useEffect(() => {
    if (rangeSelection) {
      setSelectedRanges(rangeSelection)
    } else {
      setSelectedRanges(defaultRangeSelection)
    }
  }, [rangeSelection])

  const onChangeInternal = useCallback(({ selection, isDefinedRange }) => {
    const { key, startDate, endDate, queryDateRangeType } = selection
    const s = {
      key,
      startDate,
      endDate,
      queryDateRangeType: isDefinedRange ? queryDateRangeType : QueryDateRangeType.QUERY_DATE_RANGE_TYPE__CUSTOM
    }
    setSelectedRanges(s)
    onChange && onChange(s)
  }, [onChange])

  const onDateRangeClick = useCallback((option) => {
    switch (option?.key) {
      case datePickerDefinedRanges.THIS_WEEK.key:
        onChangeInternal({
          selection: {
            startDate: startOfWeek(now()),
            endDate: endOfWeek(now()),
            key: 'selection',
            queryDateRangeType: datePickerDefinedRanges.THIS_WEEK.key
          },
          isDefinedRange: true
        })
        break
      case datePickerDefinedRanges.NEXT_WEEK.key:
        onChangeInternal({
          selection: {
            startDate: addWeeks(startOfWeek(now()), 1),
            endDate: addWeeks(endOfWeek(now()), 1),
            key: 'selection',
            queryDateRangeType: datePickerDefinedRanges.NEXT_WEEK.key
          },
          isDefinedRange: true
        })
        break
      case datePickerDefinedRanges.LAST_WEEK.key:
        onChangeInternal({
          selection: {
            startDate: subWeeks(startOfWeek(now()), 1),
            endDate: subWeeks(endOfWeek(now()), 1),
            key: 'selection',
            queryDateRangeType: datePickerDefinedRanges.LAST_WEEK.key
          },
          isDefinedRange: true
        })
        break
      case datePickerDefinedRanges.THIS_MONTH.key:
        onChangeInternal({
          selection: {
            startDate: startOfMonth(now()),
            endDate: endOfMonth(now()),
            key: 'selection',
            queryDateRangeType: datePickerDefinedRanges.THIS_MONTH.key
          },
          isDefinedRange: true
        })
        break
      case datePickerDefinedRanges.NEXT_MONTH.key:
        onChangeInternal({
          selection: {
            startDate: startOfMonth(addMonths(now(), 1)),
            endDate: endOfMonth(addMonths(now(), 1)),
            key: 'selection',
            queryDateRangeType: datePickerDefinedRanges.NEXT_MONTH.key
          },
          isDefinedRange: true
        })
        break
      case datePickerDefinedRanges.LAST_MONTH.key:
        onChangeInternal({
          selection: {
            startDate: startOfMonth(subMonths(now(), 1)),
            endDate: endOfMonth(subMonths(now(), 1)),
            key: 'selection',
            queryDateRangeType: datePickerDefinedRanges.LAST_MONTH.key
          },
          isDefinedRange: true
        })
        break
      case datePickerDefinedRanges.THIS_QUARTER.key:
        onChangeInternal({
          selection: {
            ...adjustRangeForFiscalYearStartMonth({
              startDate: startOfQuarter(now(fiscalYearStartMonth)),
              endDate: endOfQuarter(now(fiscalYearStartMonth)),
            }, fiscalYearStartMonth),
            key: 'selection',
            queryDateRangeType: datePickerDefinedRanges.THIS_QUARTER.key
          },
          isDefinedRange: true
        })
        break
      case datePickerDefinedRanges.NEXT_QUARTER.key:
        onChangeInternal({
          selection: {
            ...adjustRangeForFiscalYearStartMonth({
              startDate: startOfQuarter(addQuarters(now(fiscalYearStartMonth), 1)),
              endDate: endOfQuarter(addQuarters(now(fiscalYearStartMonth), 1)),
            }, fiscalYearStartMonth),
            key: 'selection',
            queryDateRangeType: datePickerDefinedRanges.NEXT_QUARTER.key
          },
          isDefinedRange: true
        })
        break
      case datePickerDefinedRanges.LAST_QUARTER.key:
        onChangeInternal({
          selection: {
            ...adjustRangeForFiscalYearStartMonth({
              startDate: startOfQuarter(subQuarters(now(fiscalYearStartMonth), 1)),
              endDate: endOfQuarter(subQuarters(now(fiscalYearStartMonth), 1)),
            }, fiscalYearStartMonth),
            key: 'selection',
            queryDateRangeType: datePickerDefinedRanges.LAST_QUARTER.key
          },
          isDefinedRange: true
        })
        break
      case datePickerDefinedRanges.THIS_FISCAL_YEAR.key:
        onChangeInternal({
          selection: {
            ...adjustRangeForFiscalYearStartMonth({
              startDate: startOfYear(now(fiscalYearStartMonth)),
              endDate: endOfYear(now(fiscalYearStartMonth)),
            }, fiscalYearStartMonth),
            key: 'selection',
            queryDateRangeType: datePickerDefinedRanges.THIS_FISCAL_YEAR.key
          },
          isDefinedRange: true
        })
        break
      case datePickerDefinedRanges.NEXT_FISCAL_YEAR.key:
        onChangeInternal({
          selection: {
            ...adjustRangeForFiscalYearStartMonth({
              startDate: startOfYear(addYears(now(fiscalYearStartMonth), 1)),
              endDate: endOfYear(addYears(now(fiscalYearStartMonth), 1)),
            }, fiscalYearStartMonth),
            key: 'selection',
            queryDateRangeType: datePickerDefinedRanges.NEXT_FISCAL_YEAR.key
          },
          isDefinedRange: true
        })
        break
      case datePickerDefinedRanges.LAST_FISCAL_YEAR.key:
        onChangeInternal({
          selection: {
            ...adjustRangeForFiscalYearStartMonth({
              startDate: startOfYear(subYears(now(fiscalYearStartMonth), 1)),
              endDate: endOfYear(subYears(now(fiscalYearStartMonth), 1)),
            }, fiscalYearStartMonth),
            key: 'selection',
            queryDateRangeType: datePickerDefinedRanges.LAST_FISCAL_YEAR.key
          },
          isDefinedRange: true
        })
        break
      default:
        onChangeInternal({
          selection: defaultRangeSelection,
          isDefinedRange: true
        })
    }
  }, [onChangeInternal, fiscalYearStartMonth])

  return (
    <div className="flex rounded overflow-hidden" style={{ minHeight: 380 }}>
      {showDefinedRanges && definedRangeOptions.length > 0 && (
        <div className="flex flex-col justify-between bg-color-ffffff border-r border-color-e0e7ff">
          <div className="flex flex-col">
            {definedRangeOptions.map((option, i) => (
              <div
                key={`date-range-option-${i}`}
                className={classNames('font-weight-400 cursor-pointer px-2 py-1 mt-1 text-size-12px text-color-09242f leading-snug',
                  { 'font-weight-700': option.key === selectedRanges.queryDateRangeType })}
                onClick={() => onDateRangeClick(option)}>
                {option.label}
              </div>
            ))}
          </div>
          <div
            className="font-weight-400 cursor-pointer p-2 text-size-12px mt-auto"
            onClick={() => onDateRangeClick()}>
            Clear
          </div>
        </div>
      )}
      <div className="flex flex-col">
        <DateRange
          rangeColors={['#5951FF']}
          editableDateInputs={editableDateInputs}
          moveRangeOnFirstSelection={moveRangeOnFirstSelection}
          ranges={[selectedRanges]}
          onChange={onChangeInternal}
        />
      </div>
    </div>
  )
}

export default DateRangePicker

function now(fiscalYearStartMonth = 1) {
  return subMonths(new Date(), fiscalYearStartMonth - 1)
}

function adjustRangeForFiscalYearStartMonth(range, fiscalYearStartMonth) {
  const { startDate, endDate } = range
  return {
    startDate: addMonths(startDate, fiscalYearStartMonth - 1),
    endDate: endOfMonth(addMonths(endDate, fiscalYearStartMonth - 1))
  }
}

export const datePickerDefinedRanges = {
  THIS_WEEK: { key: 1, label: 'This Week' },
  NEXT_WEEK: { key: 2, label: 'Next Week' },
  LAST_WEEK: { key: 3, label: 'Last Week' },
  THIS_MONTH: { key: 4, label: 'This Month' },
  NEXT_MONTH: { key: 5, label: 'Next Month' },
  LAST_MONTH: { key: 6, label: 'Last Month' },
  THIS_QUARTER: { key: 7, label: 'This Quarter' },
  NEXT_QUARTER: { key: 8, label: 'Next Quarter' },
  LAST_QUARTER: { key: 9, label: 'Last Quarter' },
  THIS_FISCAL_YEAR: { key: 10, label: 'This Fiscal Year' },
  NEXT_FISCAL_YEAR: { key: 11, label: 'Next Fiscal Year' },
  LAST_FISCAL_YEAR: { key: 12, label: 'Last Fiscal Year' }
}
