function toTreeNode(node) {
  const { group, childrenList, membersList, } = node
  return {
    title: group.name,
    children: childrenList.map((child) => toTreeNode(child)),
    group,
    membersList,
  }
}

export function toTreeData(data) {
  return data.rootsList.map((root) => {
    const { subTree } = root
    return toTreeNode(subTree)
  })
}
