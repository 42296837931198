import React, { useCallback, useMemo } from 'react'
import Button from '../common/button'
import { secondsToDistanceToNow } from '../../lib/dateFns'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const GoalListItem = (props) => {
  const {
    goalCategory,
    downloadModal,
    uploadModal,
    ...rest
  } = props

  const { name, updatedAt } = goalCategory

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanReadGoal,
      permissionNames.CanUpdateGoal
    )
  }, [checkPermissions])

  const onDownload = useCallback(() => {
    downloadModal.setData(goalCategory)
    downloadModal.setOpen(true)
    window.analytics.track('Goal Download Clicked')
  }, [goalCategory])

  const onUpload = useCallback(() => {
    window.analytics.track('Goal Upload Clicked')
    uploadModal.setOpen(true)
  }, [])

  return (
    <div className="tr hover:bg-color-51636a-05" {...rest}>
      <div className="td w-48 leading-tight">
        <div>{name}</div>
        {updatedAt.seconds > 0 && (
          <div className="text-color-818e93 text-size-14px font-weight-300">
            Last uploaded
            {' '}
            {secondsToDistanceToNow(updatedAt.seconds, false)}
          </div>
        )}
      </div>
      <div className="flex flex-row td w-10 justify-end">
        <Button
          className="download-btn"
          size="xs"
          variant="text"
          textColor="5951FF"
          text="Download Template"
          onClick={onDownload} />
        {permissions.CanUpdateGoal && (
          <Button
            id="joyride_goals_uploadGoalBtn"
            text="Upload"
            onClick={onUpload} />

        )}
      </div>
    </div>
  )
}

export default GoalListItem
