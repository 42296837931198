import { find } from 'lodash'
import { useDragAndDrop } from '../dragAndDrop/hooks'
import classNames from 'classnames'
import React from 'react'
import { DragHandle } from '@getoutreach/react-icons'

const ForecastSortItem = (props) => {
  const {
    children,
    index,
    id,
    name,
    onMove,
    onDrop,
  } = props

  const { ref, handlerId, isDragging, dragLayer } = useDragAndDrop({
    key: id,
    index,
    type: 'ForecastConfig',
    onMove,
    onDrop,
    horizontal: false,
  })

  return (
    <div ref={ref} data-handler-id={handlerId}>
      <div
        style={{ height: 50 }}
        className={classNames(
          'flex px-5 py-3 mb-2 shadow-md border border-color-2e5bff-08 rounded group',
          { 'bg-color-ffffff': !dragLayer.isDragging },
          { 'border-color-eaeaea bg-color-eaeaea': isDragging }
        )}>
        <div className="flex flex-1 items-center justify-between">
          {isDragging ? '\u00A0' : (
            <div className="flex flex-col leading-none overflow-hidden">
              <div>{name}</div>
            </div>
          )}
          {!dragLayer.isDragging && (
            <div className="flex flexc-1 items-center">
              <div className="mr-2">{children}</div>
              <DragHandle className="cursor-pointer" htmlColor="#C9CED0" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export function dragRenderer({ data = {}, isDragging, item, itemType, clientOffset }) {
  const config = find(data, (d) => d.id === item.id)

  if (itemType === 'ForecastConfig' && config && clientOffset) {
    const { name } = config
    const { x, y } = clientOffset
    const { size } = item || {}
    const { width = 0, height = 0 } = size
    return (
      <div style={{ width, height, transform: `translate(${x}px, ${y}px)` }}>
        <div
          className="group bg-color-ffffff flex items-center justify-between mt-3 px-5 py-2 border border-color-d8d8d8 rounded hover:bg-color-51636a-05"
          style={{ height: 50 }}>
          <div className="text-size-16px text-color-09242f font-weight-500 truncate leading-tight">
            <div>{name}</div>
          </div>
          <div className="cursor-pointer">
            <DragHandle htmlColor="#c9ced0" />
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default ForecastSortItem
