import React, { useCallback } from 'react'
import ConditionRow from './conditionRow'
import OperatorSelector from './operatorSelector'
import { useForecastConfigValueSettings } from '../../context/forecastConfigValueSettings'

const FilterWrapper = (props) => {
  const {
    filters,
    depth = 0,
    index = 0,
    parentWrapper,
    sourceOptions
  } = props

  const {
    addFilter,
    addGroup,
    setCombiner
  } = useForecastConfigValueSettings()

  const { filter, nestedList = [] } = filters

  const parentCombiner = parentWrapper?.combiner || 0

  const handleCombinerChange = useCallback((newCombiner) => {
    if (parentWrapper) {
      setCombiner(newCombiner, parentWrapper)
    }
    window.analytics.track('realtimeMetrics.filterModal.andOrClicked', { value: newCombiner })
  }, [parentWrapper, setCombiner])

  const handleAddFilter = useCallback(() => {
    addFilter(nestedList)
    window.analytics.track('realtimeMetrics.filterModal.addFilterClicked')
  }, [nestedList, addFilter])

  const handleAddGroup = useCallback(() => {
    addGroup(nestedList)
    window.analytics.track('realtimeMetrics.filterModal.addGroupClicked')
  }, [nestedList, addGroup])

  return (
    <>
      {filter && (
        <ConditionRow
          index={index}
          userFilter={filter}
          combiner={parentCombiner}
          onCombinerChange={handleCombinerChange} />
      )}
      {!!nestedList.length && (
        <div className="nested-filter">
          <OperatorSelector
            index={index}
            onChange={handleCombinerChange}
            combiner={parentCombiner} />
          <div className="group">
            <div className="group-conditions">
              {nestedList.map((n, i) => (
                <FilterWrapper
                  key={`FilterWrapper-${depth}-${index}-${i}-${nestedList.length}-${n.key}`}
                  filters={n}
                  depth={depth + 1}
                  index={i}
                  sourceOptions={sourceOptions}
                  parentWrapper={filters} />
              ))}
            </div>
            {sourceOptions.length
              ? (
                <div className="filters-actions ml-14">
                  {!nestedList.find(({ nestedList: nl }) => nl.length)
                    && (
                      <button
                        onClick={handleAddFilter}
                        className="selected">
                        + Add Filter
                      </button>
                    )}
                  <button
                    onClick={handleAddGroup}
                    className="selected">
                    + Add Group
                  </button>
                </div>
              )
              : <span>No source options available for this parameter</span>}
          </div>
        </div>
      )}
    </>
  )
}

export default FilterWrapper
