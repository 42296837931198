import React, { useCallback } from 'react'
import Select from './select'
import { CircularProgress, MenuItem } from '@material-ui/core'
import { find } from 'lodash'

const SelectList = (props) => {
  const {
    value,
    onChange,
    options = [],
    labelClassName,
    disabled = false,
    isFetching = false,
    ...rest
  } = props

  const onChangeInternal = useCallback((e) => {
    onChange && onChange(find(options, (o) => o.value === e.target.value))
  }, [options, onChange])

  return (
    <Select
      disableUnderline={true}
      fullWidth={true}
      value={isFetching ? 'isFetching' : value}
      onChange={onChangeInternal}
      disabled={isFetching || disabled}
      {...rest}>
      {isFetching ? (
        <MenuItem key="Loading"
          value="isFetching">
          <CircularProgress indeterminate="true" size={12} color="secondary" />
        </MenuItem>
      )
        : options.map((option, index) => (
          <MenuItem
            key={`MenuItem-${option.value}-${index}`}
            disabled={option?.disabled}
            value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
  )
}

export default SelectList
