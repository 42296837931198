import { JoyrideProvider } from '../../context/joyride'
import JoyrideModal from '../joyride/joyrideModal'
import JoyrideSubtitle from '../joyride/joyrideSubtitle'
import React from 'react'

const OnboardingCompleted = (props) => {
  return (
    <JoyrideProvider
      steps={[
        {
          target: 'body',
          modalTitle: 'You’re all set!',
          title: 'Now you can get started with Outreach Commit.',
          // eslint-disable-next-line max-len
          content: '',
          disableBeacon: true,
          placement: 'center',
          styles: {
            overlay: {
              background: 'rgba(0, 0, 0, 0.3)',
              zIndex: 300
            }
          },
          tooltipComponent: (props) => (
            <JoyrideModal {...props}>
              <JoyrideSubtitle>
                If you need additional support, be sure to visit the
                {' '}
                <a className="text-color-5951FF" href="https://support.outreach.io/hc/en-us/signin?utm_source=or&utm_medium=dd&utm_campaign=pltfm" target="_blank" rel="noopener noreferrer">support portal</a>
                {' '}
                or check out our
                {' '}
                <a className="text-color-5951FF" href="https://support.outreach.io/hc/en-us/categories/4596078084891-Outreach-Commit" target="_blank" rel="noopener noreferrer">knowledge base articles</a>
                .
              </JoyrideSubtitle>
            </JoyrideModal>
          )
        }
      ]}
      {...props}
    />
  )
}

export default OnboardingCompleted
