import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect, useGrpcCallback } from '../grpc'
import { toGetSystemRolesRequest, toIdRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { find, orderBy } from 'lodash'
import { useAuth } from './auth'

const SystemRolesContext = React.createContext()

export function SystemRolesProvider({ children }) {
  const { tenantId } = useAuth()

  const { notifyError } = useNotification()

  const [key, setKey] = useState(guid())
  const [systemRoles, setSystemRoles] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [roleDetail, setRoleDetail] = useState(null)

  const getRole = useGrpcCallback({
    onFetch: () => {
      setRoleDetail(null)
      setIsFetching(true)
    },
    onSuccess: (obj) => {
      setRoleDetail(obj)
      setIsFetching(false)
    },
    onError: (obj) => {
      setIsFetching(false)
      notifyError('Error fetching role!')
    },
    grpcMethod: 'getSystemRole',
    debug: false
  }, [])

  const getRoleDetail = useCallback((id) => {
    const request = toIdRequest({
      id,
      tenantId
    })
    getRole(request)
  }, [getRole, tenantId])

  useGrpcEffect({
    request: toGetSystemRolesRequest({
      tenantId,
      page: 1,
      pageSize: 50,
    }),
    onFetch: () => setIsFetching(true),
    onSuccess: (obj) => {
      setSystemRoles(orderBy(obj.systemRolesList, (o) => o.name))
      setIsFetching(false)
    },
    onError: (obj) => {
      setIsFetching(false)
      notifyError('Error fetching roles!')
    },
    grpcMethod: 'getSystemRoles',
    debug: false,
  }, [tenantId, key])

  const getById = useCallback((id) => {
    return find(systemRoles, (role) => role.id === id)
  }, [systemRoles])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      systemRoles,
      setSystemRoles,
      getById,
      key,
      invalidate,
      roleDetail,
      getRoleDetail
    }
  }, [
    isFetching,
    systemRoles,
    setSystemRoles,
    getById,
    key,
    invalidate,
    roleDetail,
    getRoleDetail
  ])

  return <SystemRolesContext.Provider value={contextValue}>{children}</SystemRolesContext.Provider>
}

export function useSystemRoles() {
  const context = React.useContext(SystemRolesContext)
  if (context === undefined) {
    throw new Error('useSystemRoles must be used within a SystemRolesProvider')
  }
  return context
}
