import { isDescendant } from './utils/tree-data-utils'
import Checkbox from '../common/checkbox'
import classnames from './utils/classnames'
import React, { Component } from 'react'

class VisibilityNodeRenderer extends Component {
  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      title,
      subtitle,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      buttons,
      className,
      style,
      didDrop,
      treeId,
      isOver, // Not needed, but preserved for other renderers
      parentNode, // Needed for dndManager
      rowDirection,
      onMembers,
      onAdd,
      onEdit,
      onDelete,
      onSelected,
      selected,
      defaultAssigned,
      defaultAssignedHierarchy = [],
      checkPermission,
      ...otherProps
    } = this.props

    const nodeTitle = title || node.title
    const nodeSubtitle = subtitle || node.subtitle
    const rowDirectionClass = null

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node)
    const isLandingPadActive = !didDrop && isDragging

    let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth }
    if (rowDirection === 'rtl') {
      buttonStyle = { right: -0.5 * scaffoldBlockPxWidth }
    }

    const isDefaultOrDescendant = path.includes(defaultAssigned)

    const isInDefaultAssignedHierarchy = defaultAssignedHierarchy.includes(node?.group?.id)

    const isSelectedOrDescendant = [...selected.values()].find((s) => {
      return path.includes(s.group.id)
    })

    const assignedAndNotInHierarchy = !!defaultAssigned && !isInDefaultAssignedHierarchy

    return (
      <div {...otherProps}>
        {toggleChildrenVisibility
          && node.children
          && (node.children.length > 0 || typeof node.children === 'function')
          && (
            <>
              <button
                type="button"
                aria-label={node.expanded ? 'Collapse' : 'Expand'}
                className={classnames(
                  node.expanded ? 'custom__collapseButton' : 'custom__expandButton',
                  rowDirectionClass
                )}
                style={buttonStyle}
                onClick={() => toggleChildrenVisibility({
                  node,
                  path,
                  treeIndex,
                })} />
            </>
          )}

        <div className={classnames('custom__rowWrapper group', rowDirectionClass)}>
          {connectDragPreview(
            <div
              className={classnames(
                'custom__row',
                isLandingPadActive && 'custom__rowLandingPad',
                isLandingPadActive && !canDrop && 'custom__rowCancelPad',
                isSearchMatch && 'custom__rowSearchMatch',
                isSearchFocus && 'custom__rowSearchFocus',
                rowDirectionClass,
                className
              )}
              style={{
                opacity: isDraggedDescendant ? 0.5 : 1,
                ...style,
              }}>

              <div
                className={classnames(
                  'custom__rowContents',
                  !canDrag && 'custom__rowContentsDragDisabled',
                  rowDirectionClass
                )}>
                <div className={
                  classnames(
                    'custom__rowLabel',
                    rowDirectionClass,
                    { disabled: (isDefaultOrDescendant || assignedAndNotInHierarchy) }
                  )
                }>
                  <Checkbox
                    disabled={isDefaultOrDescendant || assignedAndNotInHierarchy}
                    checked={isDefaultOrDescendant || !!isSelectedOrDescendant}
                    onChange={() => onSelected(node, parentNode)}
                    label={typeof nodeTitle === 'function'
                      ? nodeTitle({
                        node,
                        path,
                        treeIndex,
                      })
                      : nodeTitle}
                    labelProps={{
                      className: classnames(
                        'text-size-16px text-color-09242f font-weight-400',
                        (isDefaultOrDescendant || assignedAndNotInHierarchy) && 'text-color-c9ced0'
                      )
                    }} />

                  {nodeSubtitle && (
                    <span className="custom__rowSubtitle">
                      {typeof nodeSubtitle === 'function'
                        ? nodeSubtitle({
                          node,
                          path,
                          treeIndex,
                        })
                        : nodeSubtitle}
                    </span>
                  )}
                </div>
              </div>

            </div>
          )}
        </div>

      </div>
    )
  }
}

export default VisibilityNodeRenderer
