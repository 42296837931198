import React from 'react'

const OutreachLogo = (props) => {
  return (
    <svg width="34" height="32" viewBox="0 0 135 126" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M85.149,74.572C78.207,81.513 71.496,84.12 64.993,84.12C58.49,84.12 52.032,81.075 47.927,76.969C43.478,72.521 39.395,65.128 39.395,57.473C39.395,49.815 42.426,44.426 45.932,40.92C50.406,36.445 57.119,33.288 67.109,33.288C74.996,33.288 82.245,35.136 88.793,41.685C93.061,45.951 93.96,50.955 93.96,54.368C93.96,59.074 92.091,67.628 85.149,74.572ZM59.698,0C38.734,0 22.939,5.304 13.772,14.471C3.878,24.364 0,36.266 0,49.866C0,71.434 10.913,94.564 26.149,109.8C30.128,113.778 43.869,126 61.856,126C80.26,126 93.942,114.758 102.542,106.158C119.179,89.519 134.245,60.831 134.245,42.128C134.245,31.612 129.451,24.435 125.786,20.767C111.325,6.306 80.26,0 59.698,0Z" fill="rgb(89,82,255)" />
    </svg>
  )
}

export default OutreachLogo
