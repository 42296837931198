import React, { useCallback } from 'react'
import { useRoutes } from '../../context/routes'
import OutreachLogo from '../icons/outreachLogo'

const NotFound = () => {
  const { baseRoutes, routeTenantId } = useRoutes()

  const onBackClick = useCallback(() => {
    let url = ''
    if (routeTenantId) {
      url += `/${routeTenantId}`
    }
    url += baseRoutes.default
    window.location = url
  }, [routeTenantId])

  return (
    <div className="w-screen h-screen bg-color-fafafa">
      <div className="flex justify-center p-10">
        <div className="text-center rounded-lg bg-gradient-white-to-blue border border-color-2e5bff-08 md:w-1/2 p-8">
          <div className="flex justify-center items-center flex-shrink">
            <OutreachLogo />
            <h1 className="flex-shrink ml-2">Page Not Found</h1>
          </div>
          <div className="text-center text-color-4e5d7e mt-6">
            Sorry, the page you are trying to access does not exist.
            <br />
            <a className="whitespace-nowrap underline-dashed focus:outline-none cursor-pointer" onClick={onBackClick}>Back to Outreach Commit</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
