import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGrpcCallback, useGrpcEffect } from '../grpc'
import { toGetSettingsCommonRequest } from '../grpc/converters'
import { guid } from '../lib/guid'
import { DataRequest } from '../protos/src/common/data_request_pb'
import { grpcCodes } from '../grpc/grpcCodes'
import { useNotification } from '../hooks/useNotification'
import { useAuth } from './auth'
import { useTenantInfo } from './tenantInfo'
import { setUser } from '../lib/sentry'
import { useRoutes } from './routes'
import { forEach } from 'lodash'

const LoggedInUserContext = React.createContext()

export function LoggedInUserProvider({ children }) {
  const { tenantContext, tenantId } = useAuth()
  const { tenantInfo } = useTenantInfo()
  const { routeTenantId } = useRoutes()

  const { notifyError } = useNotification()

  const [key, setKey] = useState(guid())
  const [loggedInUser, setLoggedInUser] = useState({})
  const [settings, setSettings] = useState([])
  const [hasSettings, setHasSettings] = useState(false)

  useGrpcEffect({
    request: new DataRequest(),
    onError: (err) => {
      notifyError('Error fetching user!')
      if (err.code === grpcCodes.NOT_FOUND) {
        process.env.NODE_ENV === 'local' && console.log('getSelf returned NOT_FOUND')
      }
      setLoggedInUser({})
    },
    onSuccess: (obj) => setLoggedInUser(obj),
    grpcMethod: 'getSelf',
    debug: false,
  }, [key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const getSettings = useGrpcCallback({
    onSuccess: ({ settingsList }) => {
      forEach(settingsList, (s) => {
        try {
          s.payload = JSON.parse(atob(s.payload))
        } catch (err) { }
      })
      setSettings(settingsList)
      setHasSettings(true)
    },
    onFetch: () => {
      setSettings([])
    },
    grpcMethod: 'getSettings',
    debug: false
  }, [tenantId])

  useEffect(() => {
    const { name } = tenantInfo
    const { id } = loggedInUser
    if (id && name) {
      const isSandbox = routeTenantId !== undefined
      const companyName = tenantContext && tenantContext.id && !isSandbox ? 'InternalAdmin' : name
      setUser(undefined, { companyName, role: loggedInUser.role, isSandbox })
    }
  }, [tenantInfo, tenantContext, loggedInUser, routeTenantId])

  useEffect(() => {
    if (loggedInUser?.id && tenantId) {
      const request = toGetSettingsCommonRequest({
        type: 'app',
        key: 'feature.*',
        tenantId
      })
      getSettings(request)
    }
  }, [loggedInUser, tenantId, getSettings])

  const contextValue = useMemo(() => {
    return {
      loggedInUser,
      setLoggedInUser,
      settings,
      hasSettings,
      key,
      invalidate,
    }
  }, [loggedInUser, settings, hasSettings])

  return <LoggedInUserContext.Provider value={contextValue}>{children}</LoggedInUserContext.Provider>
}

export function useLoggedInUser() {
  const context = React.useContext(LoggedInUserContext)
  if (context === undefined) {
    throw new Error('useLoggedInUser must be used within an LoggedInUserProvider')
  }
  return context
}
