import { Checkbox } from '@material-ui/core'
import classNames from 'classnames'
import React, { useEffect } from 'react'

const FieldSelectItem = (props) => {
  const { label, measure, multi = false, nested = false, onClick, selected } = props

  useEffect(() => {
    measure && measure()
  }, [label])

  return (
    <div
      className={classNames('select-item hover:bg-color-edeeee', { nested, 'bg-color-edeeee-60': !multi && selected })}
      onClick={onClick}>
      {multi && (
        <Checkbox
          checked={!!selected}
          className="checkbox"
          style={{
            transform: 'scale(0.8)',
            color: '#1d4dcf'
          }} />
      )}
      {label}
    </div>
  )
}

export default FieldSelectItem
