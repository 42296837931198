// source: src/common/orchard/person.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_orchard_system_role_pb = require('../../../src/common/orchard/system_role_pb.js');
goog.object.extend(proto, src_common_orchard_system_role_pb);
var src_common_orchard_crm_role_pb = require('../../../src/common/orchard/crm_role_pb.js');
goog.object.extend(proto, src_common_orchard_crm_role_pb);
goog.exportSymbol('proto.loupe.common.orchard.Person', null, global);
goog.exportSymbol('proto.loupe.common.orchard.PersonField', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.orchard.Person = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.orchard.Person.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.orchard.Person, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.orchard.Person.displayName = 'proto.loupe.common.orchard.Person';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.orchard.Person.repeatedFields_ = [10,11,12,13,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.orchard.Person.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.orchard.Person.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.orchard.Person} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.orchard.Person.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    photoUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    managerId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    roleIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    src_common_orchard_system_role_pb.SystemRole.toObject, includeInstance),
    crmRoleIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    crmRolesList: jspb.Message.toObjectList(msg.getCrmRolesList(),
    src_common_orchard_crm_role_pb.CRMRole.toObject, includeInstance),
    isProvisioned: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    isSynced: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    status: jspb.Message.getFieldWithDefault(msg, 16, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 18, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 20, ""),
    type: jspb.Message.getFieldWithDefault(msg, 21, ""),
    groupViewerIdsList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    outreachId: jspb.Message.getFieldWithDefault(msg, 23, ""),
    isOutreachAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    outreachProfileId: jspb.Message.getFieldWithDefault(msg, 25, ""),
    outreachProfileName: jspb.Message.getFieldWithDefault(msg, 26, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.common.orchard.Person.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.orchard.Person;
  return proto.loupe.common.orchard.Person.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.orchard.Person} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.orchard.Person}
 */
proto.loupe.common.orchard.Person.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoleIds(value);
      break;
    case 11:
      var value = new src_common_orchard_system_role_pb.SystemRole;
      reader.readMessage(value,src_common_orchard_system_role_pb.SystemRole.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addCrmRoleIds(value);
      break;
    case 13:
      var value = new src_common_orchard_crm_role_pb.CRMRole;
      reader.readMessage(value,src_common_orchard_crm_role_pb.CRMRole.deserializeBinaryFromReader);
      msg.addCrmRoles(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsProvisioned(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSynced(value);
      break;
    case 16:
      var value = /** @type {!proto.loupe.common.orchard.BasicStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupViewerIds(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachId(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOutreachAdmin(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachProfileId(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachProfileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.orchard.Person.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.orchard.Person.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.orchard.Person} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.orchard.Person.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhotoUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getManagerId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRoleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      src_common_orchard_system_role_pb.SystemRole.serializeBinaryToWriter
    );
  }
  f = message.getCrmRoleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getCrmRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      src_common_orchard_crm_role_pb.CRMRole.serializeBinaryToWriter
    );
  }
  f = message.getIsProvisioned();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsSynced();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getGroupViewerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getOutreachId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getIsOutreachAdmin();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getOutreachProfileId();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getOutreachProfileName();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string photo_url = 7;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getPhotoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setPhotoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string manager_id = 8;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getManagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setManagerId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string group_id = 9;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string role_ids = 10;
 * @return {!Array<string>}
 */
proto.loupe.common.orchard.Person.prototype.getRoleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setRoleIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.addRoleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.clearRoleIdsList = function() {
  return this.setRoleIdsList([]);
};


/**
 * repeated SystemRole roles = 11;
 * @return {!Array<!proto.loupe.common.orchard.SystemRole>}
 */
proto.loupe.common.orchard.Person.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.SystemRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_system_role_pb.SystemRole, 11));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.SystemRole>} value
 * @return {!proto.loupe.common.orchard.Person} returns this
*/
proto.loupe.common.orchard.Person.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.loupe.common.orchard.SystemRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.common.orchard.Person.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.loupe.common.orchard.SystemRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * repeated string crm_role_ids = 12;
 * @return {!Array<string>}
 */
proto.loupe.common.orchard.Person.prototype.getCrmRoleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setCrmRoleIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.addCrmRoleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.clearCrmRoleIdsList = function() {
  return this.setCrmRoleIdsList([]);
};


/**
 * repeated CRMRole crm_roles = 13;
 * @return {!Array<!proto.loupe.common.orchard.CRMRole>}
 */
proto.loupe.common.orchard.Person.prototype.getCrmRolesList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.CRMRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_crm_role_pb.CRMRole, 13));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.CRMRole>} value
 * @return {!proto.loupe.common.orchard.Person} returns this
*/
proto.loupe.common.orchard.Person.prototype.setCrmRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.loupe.common.orchard.CRMRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.CRMRole}
 */
proto.loupe.common.orchard.Person.prototype.addCrmRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.loupe.common.orchard.CRMRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.clearCrmRolesList = function() {
  return this.setCrmRolesList([]);
};


/**
 * optional bool is_provisioned = 14;
 * @return {boolean}
 */
proto.loupe.common.orchard.Person.prototype.getIsProvisioned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setIsProvisioned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool is_synced = 15;
 * @return {boolean}
 */
proto.loupe.common.orchard.Person.prototype.getIsSynced = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setIsSynced = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional BasicStatus status = 16;
 * @return {!proto.loupe.common.orchard.BasicStatus}
 */
proto.loupe.common.orchard.Person.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.orchard.BasicStatus} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.loupe.common.orchard.BasicStatus} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.orchard.Person.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.orchard.Person} returns this
*/
proto.loupe.common.orchard.Person.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.orchard.Person.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string created_by = 18;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.orchard.Person.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.orchard.Person} returns this
*/
proto.loupe.common.orchard.Person.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.orchard.Person.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string updated_by = 20;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string type = 21;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated string group_viewer_ids = 22;
 * @return {!Array<string>}
 */
proto.loupe.common.orchard.Person.prototype.getGroupViewerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setGroupViewerIdsList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.addGroupViewerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.clearGroupViewerIdsList = function() {
  return this.setGroupViewerIdsList([]);
};


/**
 * optional string outreach_id = 23;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getOutreachId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setOutreachId = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional bool is_outreach_admin = 24;
 * @return {boolean}
 */
proto.loupe.common.orchard.Person.prototype.getIsOutreachAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setIsOutreachAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional string outreach_profile_id = 25;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getOutreachProfileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setOutreachProfileId = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string outreach_profile_name = 26;
 * @return {string}
 */
proto.loupe.common.orchard.Person.prototype.getOutreachProfileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.Person} returns this
 */
proto.loupe.common.orchard.Person.prototype.setOutreachProfileName = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.orchard.PersonField = {
  ID: 0,
  TENANTID: 1,
  NAME: 2,
  FIRSTNAME: 3,
  LASTNAME: 4,
  EMAIL: 5,
  MANAGERID: 6,
  GROUPID: 7,
  ROLEIDS: 8,
  CRMROLEIDS: 9,
  ISPROVISIONED: 10,
  ISSYNCED: 11,
  STATUS: 12,
  CREATEDAT: 13,
  CREATEDBY: 14,
  UPDATEDAT: 15,
  UPDATEDBY: 16,
  TYPE: 17
};

goog.object.extend(exports, proto.loupe.common.orchard);
