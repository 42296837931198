// source: src/common/insight/control.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_insight_data_format_pb = require('../../../src/common/insight/data_format_pb.js');
goog.object.extend(proto, src_common_insight_data_format_pb);
goog.exportSymbol('proto.loupe.common.insight.Control', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.insight.Control = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.insight.Control.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.insight.Control, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.insight.Control.displayName = 'proto.loupe.common.insight.Control';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.insight.Control.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.insight.Control.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.insight.Control.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.insight.Control} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.insight.Control.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataRef: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataFormattingList: jspb.Message.toObjectList(msg.getDataFormattingList(),
    src_common_insight_data_format_pb.DataFormat.toObject, includeInstance),
    controlsList: jspb.Message.toObjectList(msg.getControlsList(),
    proto.loupe.common.insight.Control.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.insight.Control}
 */
proto.loupe.common.insight.Control.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.insight.Control;
  return proto.loupe.common.insight.Control.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.insight.Control} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.insight.Control}
 */
proto.loupe.common.insight.Control.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataRef(value);
      break;
    case 4:
      var value = new src_common_insight_data_format_pb.DataFormat;
      reader.readMessage(value,src_common_insight_data_format_pb.DataFormat.deserializeBinaryFromReader);
      msg.addDataFormatting(value);
      break;
    case 5:
      var value = new proto.loupe.common.insight.Control;
      reader.readMessage(value,proto.loupe.common.insight.Control.deserializeBinaryFromReader);
      msg.addControls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.insight.Control.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.insight.Control.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.insight.Control} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.insight.Control.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataRef();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataFormattingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      src_common_insight_data_format_pb.DataFormat.serializeBinaryToWriter
    );
  }
  f = message.getControlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.loupe.common.insight.Control.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.insight.Control.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.insight.Control} returns this
 */
proto.loupe.common.insight.Control.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.loupe.common.insight.Control.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.insight.Control} returns this
 */
proto.loupe.common.insight.Control.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string data_ref = 3;
 * @return {string}
 */
proto.loupe.common.insight.Control.prototype.getDataRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.insight.Control} returns this
 */
proto.loupe.common.insight.Control.prototype.setDataRef = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DataFormat data_formatting = 4;
 * @return {!Array<!proto.loupe.common.insight.DataFormat>}
 */
proto.loupe.common.insight.Control.prototype.getDataFormattingList = function() {
  return /** @type{!Array<!proto.loupe.common.insight.DataFormat>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_insight_data_format_pb.DataFormat, 4));
};


/**
 * @param {!Array<!proto.loupe.common.insight.DataFormat>} value
 * @return {!proto.loupe.common.insight.Control} returns this
*/
proto.loupe.common.insight.Control.prototype.setDataFormattingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.loupe.common.insight.DataFormat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.insight.DataFormat}
 */
proto.loupe.common.insight.Control.prototype.addDataFormatting = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.loupe.common.insight.DataFormat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.insight.Control} returns this
 */
proto.loupe.common.insight.Control.prototype.clearDataFormattingList = function() {
  return this.setDataFormattingList([]);
};


/**
 * repeated Control controls = 5;
 * @return {!Array<!proto.loupe.common.insight.Control>}
 */
proto.loupe.common.insight.Control.prototype.getControlsList = function() {
  return /** @type{!Array<!proto.loupe.common.insight.Control>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.insight.Control, 5));
};


/**
 * @param {!Array<!proto.loupe.common.insight.Control>} value
 * @return {!proto.loupe.common.insight.Control} returns this
*/
proto.loupe.common.insight.Control.prototype.setControlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.insight.Control=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.insight.Control}
 */
proto.loupe.common.insight.Control.prototype.addControls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.insight.Control, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.insight.Control} returns this
 */
proto.loupe.common.insight.Control.prototype.clearControlsList = function() {
  return this.setControlsList([]);
};


goog.object.extend(exports, proto.loupe.common.insight);
