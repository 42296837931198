import { FilterSourceOptionsProvider } from '../../../context/filterSourceOptions'
import { ForecastConfigValueSettingsProvider } from '../../../context/forecastConfigValueSettings'
import { useForecastingConfigColumn } from '../../../context/forecastingConfigColumn'
import { useModal } from '../../../hooks/useModal'
import ActionModal from '../../common/actionModal'
import Button from '../../common/button'
import classNames from 'classnames'
import ForecastConfigCategoriesModalDisplay from './display'
import ForecastConfigCategoriesModalFilter from './filter'
import ForecastConfigCategoriesModalFormulas from './formulas'
import ForecastConfigCategoriesModalGeneral from './general'
import LoadingStateIcon from '../../common/loadingStateIcon'
import Modal from '../../common/modal'
import ModalBody from '../../common/modalBody'
import ModalError from '../../common/modalError'
import ModalFooter from '../../common/modalFooter'
import ModalHeader from '../../common/modalHeader'
import React, { useCallback, useEffect, useState } from 'react'
import { CanonicalObjectMappingProvider } from '../../../context/canonicalObjectMapping'

const ForecastConfigCategoriesModal = (props) => {
  const {
    modal
  } = props

  const actionModal = useModal()

  const { open, handleClose, data = {} } = modal
  const { selectGroup } = data

  const {
    isValidColumn,
    didSave,
    hasUnsavedChanges,
    isFetching: showLoader,
    saveConfigColumn
  } = useForecastingConfigColumn()

  const [showError, setShowError] = useState(false)
  const [settingGroups, setSettingGroups] = useState([
    { key: 'general', name: 'General', selected: true },
    { key: 'filters', name: 'Filters' },
    { key: 'formulas', name: 'Formulas' },
    { key: 'display', name: 'Display' }
  ])

  const onSettingGroupSelected = useCallback((key) => {
    const _settingGroups = settingGroups.map((sg) => {
      return {
        ...sg,
        selected: key === sg.key
      }
    })
    setSettingGroups(_settingGroups)
  }, [settingGroups])

  useEffect(() => {
    if (open && selectGroup) {
      onSettingGroupSelected(selectGroup)
      modal.setData({ ...modal.data, selectGroup: null })
    }
  }, [onSettingGroupSelected, selectGroup, open, modal])

  const closeModal = useCallback(() => {
    setShowError(false)
    onSettingGroupSelected('general')
    handleClose()
    modal.setData({ column: {} })
  }, [modal, handleClose, onSettingGroupSelected])

  const handleCloseInternal = useCallback(() => {
    if (hasUnsavedChanges) {
      actionModal.setOpen(true)
      return
    }
    closeModal()
  }, [hasUnsavedChanges, closeModal, actionModal])

  const onLeaveCancelInternal = useCallback(() => {
    actionModal.setOpen(false)
  }, [actionModal])

  const onLeaveConfirmedInternal = useCallback(() => {
    closeModal()
  }, [closeModal])

  const onCreate = useCallback(() => {
    saveConfigColumn()
  }, [saveConfigColumn])

  const renderModalContent = useCallback(() => {
    const selectedGroup = settingGroups.find(({ selected }) => selected)

    return (
      <div className={classNames('flex-1 border-l border-color-e1e1e5 pl-4', { 'pointer-events-none': didSave })}>
        {selectedGroup.key === 'general' && (
          <CanonicalObjectMappingProvider objectName="opportunity">
            <ForecastConfigCategoriesModalGeneral />
          </CanonicalObjectMappingProvider>
        )}
        {selectedGroup.key === 'filters' && (
          <ForecastConfigValueSettingsProvider>
            <ForecastConfigCategoriesModalFilter />
          </ForecastConfigValueSettingsProvider>
        )}
        {selectedGroup.key === 'formulas' && (
          <ForecastConfigCategoriesModalFormulas />
        )}
        {selectedGroup.key === 'display' && (
          <ForecastConfigCategoriesModalDisplay />
        )}
      </div>
    )
  }, [didSave, settingGroups])

  const renderModalSidebar = useCallback(() => {
    const classnames = 'pl-4 py-2 border-l border-color-e1e1e5 hover:border-color-5951FF font-color-51636a'

    return (
      <div className="flex flex-col items-start w-36">
        {settingGroups.map(({ key, name, selected }) => (
          <button
            key={key}
            className={classNames(classnames, { 'border-color-5951FF': selected })}
            onClick={() => onSettingGroupSelected(key)}>
            {name}
          </button>
        ))}
      </div>
    )
  }, [settingGroups, onSettingGroupSelected])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="md"
      open={open}>

      <ModalHeader
        title="Metric Settings"
        subtitle="Name your metric, choose whether it's editable, apply filters, and more."
        onClose={handleCloseInternal} />

      <ModalBody maxHeight="calc(100vh - 260px)">

        {showError && <ModalError text="Failed to upload goals, please try again." />}

        <div className={classNames('w-full h-full px-10 flex flex-row', { 'pointer-events-none': showLoader })}>
          {renderModalSidebar()}
          <FilterSourceOptionsProvider isModalOpen={open}>
            {renderModalContent()}
          </FilterSourceOptionsProvider>
        </div>

      </ModalBody>

      <ModalFooter>
        <Button
          size="xs"
          text="Save"
          onClick={onCreate}
          disabled={showLoader || !isValidColumn || !hasUnsavedChanges}
          {...showLoader && {
            text: (
              <div className="flex items-center">
                <LoadingStateIcon animate dense style={{ width: 18, height: 18, marginRight: 4 }} />
                {' '}
                Saving
              </div>
            )
          }}
          {...didSave && {
            text: 'Saved'
          }}
        />
      </ModalFooter>

      <ActionModal
        modal={actionModal}
        title="Discard your changes?"
        text="You have unsaved changes that will be lost if you leave now. This cannot be undone."
        cancelText="Keep Working"
        onCancel={onLeaveCancelInternal}
        actionText="Discard"
        onAction={onLeaveConfirmedInternal}
        slim
      />

    </Modal>
  )
}

export default ForecastConfigCategoriesModal
