import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGrpcCallback } from '../grpc'
import { toSearchPeopleRequest } from '../grpc/converters'
import { FilterOp, PersonField } from '../grpc/enums'
import { guid } from '../lib/guid'
import { useAuth } from './auth'

const UsersContext = React.createContext()

const pageSize = 10

export function UsersProvider({ children }) {
  const { tenantId } = useAuth()

  const [key, setKey] = useState(guid())
  const [users, setUsers] = useState({ peopleList: [], total: 0 })
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [isFetching, setIsFetching] = useState(true)
  const [roleId, setRoleId] = useState(undefined)

  const searchPeople = useGrpcCallback({
    onError: (obj) => {
      setIsFetching(false)
    },
    onSuccess: (obj) => {
      setIsFetching(false)
      setUsers(obj)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'searchPeople',
    debug: false,
  })

  useEffect(() => {
    if (search === '' || search.length > 2) {
      const filtersList = [
        {
          field: PersonField.ISPROVISIONED,
          op: FilterOp.EQ,
          valuesList: [
            btoa(JSON.stringify(true)),
          ],
        },
        {
          field: PersonField.STATUS,
          op: FilterOp.EQ,
          valuesList: [
            btoa(JSON.stringify('active')),
          ],
        },
      ]

      if (roleId) {
        filtersList.push({
          field: PersonField.ROLEIDS,
          op: FilterOp.EQANY,
          valuesList: [
            btoa(JSON.stringify(roleId)),
          ]
        })
      }

      const request = toSearchPeopleRequest({
        filtersList,
        page,
        pageSize,
        search,
        tenantId,
      })
      searchPeople(request)
    }
  }, [tenantId, key, page, roleId, search])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      invalidate,
      isFetching,
      key,
      page,
      pageSize,
      roleId,
      search,
      setPage,
      setRoleId,
      setSearch,
      setUsers,
      users,
    }
  }, [users])

  return <UsersContext.Provider value={contextValue}>{children}</UsersContext.Provider>
}

export function useUsers() {
  const context = React.useContext(UsersContext)
  if (context === undefined) {
    throw new Error('useUsers must be used within a UsersProvider')
  }
  return context
}
