import React from 'react'

const Goals = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="40" height="40" rx="20" fill="url(#paint0_linear)" />
      <circle cx="20" cy="20" r="12" stroke="white" strokeWidth="3" />
      <circle cx="20" cy="20" r="5.28" stroke="white" strokeWidth="3" />
      <defs>
        <linearGradient id="paint0_linear" x1="40" y1="40" x2="40" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5951FF" />
          <stop offset="1" stopColor="#5951FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Goals
