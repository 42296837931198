import React from 'react'

const ModalBody = (props) => {
  const {
    children,
    maxHeight = 'calc(100vh - 207px)',
    classes = ''
  } = props

  return (
    <div className={`font-normal overflow-auto ${classes}`} style={{ maxHeight }}>
      {children}
    </div>
  )
}

export default ModalBody
