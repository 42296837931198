import { ForecastingConfigProvider } from '../../context/forecastingConfig'
import { GroupsErrorProvider } from '../../context/groupsError'
import { TreeDataProvider } from '../../context/treeData'
import { CanonicalObjectMappingProvider } from '../../context/canonicalObjectMapping'
import ForecastMain from './forecastMain'
import React from 'react'

const Forecast = (props) => {
  return (
    <GroupsErrorProvider>
      <TreeDataProvider>
        <ForecastingConfigProvider>
          <CanonicalObjectMappingProvider objectName="opportunity">
            <ForecastMain />
          </CanonicalObjectMappingProvider>
        </ForecastingConfigProvider>
      </TreeDataProvider>
    </GroupsErrorProvider>
  )
}

export default Forecast
