import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useSystemRoles } from '../../context/systemRoles'
import EmptyState from '../common/emptyState'
import Header from '../header/header'
import Badge from '../icons/badge'
import { useRoutes } from '../../context/routes'
import Button from '../common/button'
import LoadingState from '../common/loadingState'
import EditableText from '../common/editableText'
import PermissionsList from './permissionsList'
import BlockNavigation from '../common/blockNavigation'
import { useNotification } from '../../hooks/useNotification'
import { useGrpcCallback } from '../../grpc'
import { toSystemRoleExtended } from '../../grpc/converters'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const RoleMain = (props) => {
  const { checkPermissions } = usePermissions()
  const { routes } = useRoutes()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanUpdateSystemRole
    )
  }, [checkPermissions])

  const {
    systemRoles,
    isFetching,
    getRoleDetail,
    roleDetail
  } = useSystemRoles()

  const { notifyError, notifySuccess } = useNotification()

  const [name, setName] = useState('\u00A0')
  const [description, setDescription] = useState('')
  const [isCustom, setIsCustom] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [permissionsList, setPermissionsList] = useState([])
  const [fetching, setFetching] = useState(false)
  const [showCollapsed, setShowCollapsed] = useState(false)
  const [showExpanded, setShowExpanded] = useState(false)

  const { roleId } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (roleDetail) {
      const { extendedPermissionsList } = roleDetail
      setPermissionsList(extendedPermissionsList)
    }
  }, [roleDetail])

  const role = useMemo(() => {
    if (roleDetail) {
      const { role } = roleDetail
      return role
    }
  }, [roleDetail])

  useEffect(() => {
    if (role) {
      setName(role.name)
      setDescription(role.description)
      setIsCustom(role.isCustom)
    }
  }, [role])

  useEffect(() => {
    getRoleDetail(roleId)
  }, [roleId])

  const count = useMemo(() => {
    return permissionsList.length || 0
  }, [permissionsList])

  const showEmptyState = useMemo(() => {
    return !isFetching && count === 0
  }, [isFetching, count])

  const collapseAll = useCallback(() => {
    setShowCollapsed(true)
    setShowExpanded(false)
  }, [])

  const expandAll = useCallback(() => {
    setShowCollapsed(false)
    setShowExpanded(true)
  }, [])

  const onNameChange = useCallback((_name) => {
    setName(_name)
    setHasChanges(true)
  }, [])

  const onDescriptionChange = useCallback((_description) => {
    setDescription(_description)
    setHasChanges(true)
  }, [])

  const updateRole = useGrpcCallback({
    onError: () => {
      setFetching(false)
      notifyError('Unable to save role changes')
    },
    onSuccess: () => {
      setFetching(false)
      notifySuccess('Changes saved!')
      setHasChanges(false)
    },
    onFetch: () => {
      setFetching(true)
    },
    grpcMethod: 'updateSystemRole',
    debug: false
  }, [notifyError, notifySuccess])

  const onPermissionChange = useCallback((permission) => {
    const { permissionSet } = permission
    const _permissionsList = permissionsList.map((p) => {
      if (p.permissionSet === permissionSet) {
        return {
          ...permission
        }
      }
      return p
    })
    setPermissionsList(_permissionsList)
    setHasChanges(true)
  }, [permissionsList])

  const onSaveRole = useCallback(() => {
    const request = toSystemRoleExtended({
      role: {
        ...role,
        name,
        description
      },
      extendedPermissions: permissionsList
    })
    updateRole(request)
  }, [
    role,
    permissionsList,
    name,
    description,
    updateRole,
    toSystemRoleExtended
  ])

  const breadcrumbControl = useMemo(() => {
    return (
      <div className="text-size-12px text-color-a6b2cf font-normal tracking-widest leading-none" style={{ transform: 'translateY(-4px)' }}>
        <Link to={routes.roles}>Roles</Link>
      </div>
    )
  }, [])

  const nameIsUnique = useCallback((_name) => {
    const systemRoleNames = systemRoles.map(({ name }) => name.toLowerCase())
    return _name === role.name || !systemRoleNames.includes(_name?.toLowerCase?.())
  }, [systemRoles, role])

  return (
    <div className="flex flex-col w-full h-screen">
      <Header
        showBackButton={true}
        onBackClick={() => history.push(routes.roles)}
        breadcrumbControl={breadcrumbControl}
        title={name} />
      <div className="flex-grow overflow-auto p-10">

        <div className="p-6 bg-color-ffffff border border-color-2e5bff-08 rounded-lg">
          <div className="text-size-24px text-color-09242f font-weight-700">Permissions in Commit</div>
          <div className="text-size-15px text-color-51636a font-weight-400 leading-tight mt-4">
            Below is the list of permissions configured for this role, grouped by feature area.
            {' '}
            The management of these permissions govern both access and functionality within each feature area.
          </div>
        </div>

        <div className="py-4">
          <EditableText
            text={name}
            validate={nameIsUnique}
            invalidNote="Role name already exists"
            required={true}
            disabled={!isCustom || !permissions.CanUpdateSystemRole}
            onComplete={onNameChange}
            className="text-size-24px text-color-09242f font-weight-700" />
          <EditableText
            placeholder="Add a description"
            text={description}
            disabled={!isCustom || !permissions.CanUpdateSystemRole}
            onComplete={onDescriptionChange}
            className="text-size-18px font-weight-400 leading-tight mt-2" />
        </div>

        <div className="flex items-center justify-between mt-8 mb-3">
          <div className="flex">
            <button
              className="ml-2 mr-2 first:ml-0 text-size-16px text-color-51636a font-weight-400 focus:outline-none"
              disabled={showExpanded}
              onClick={expandAll}>
              Expand All
            </button>
            <div className="mx-1 text-size-16px text-color-c9ced0 font-weight-400">|</div>
            <button
              className="ml-2 mr-2 first:ml-0 text-size-16px text-color-51636a font-weight-400 focus:outline-none"
              disabled={showCollapsed}
              onClick={collapseAll}>
              Collapse All
            </button>
          </div>
          {permissions.CanUpdateSystemRole && (
            <Button
              disabled={!isCustom || !hasChanges || fetching}
              text={fetching ? 'Saving ...' : 'Save'}
              onClick={onSaveRole} />
          )}
        </div>

        {showEmptyState
          ? (
            <div className="flex justify-center my-10">
              <EmptyState
                iconControl={<Badge transform="scale(2)" />}
                header="No Role Permissions"
                subHeader="There are no permissions to display" />
            </div>
          )
          : (
            <>
              {isFetching
                ? (
                  <div className="flex justify-center my-10">
                    <LoadingState
                      header="Loading Role"
                      subHeader="Please wait..."
                      animate={true} />
                  </div>
                )
                : (
                  <PermissionsList
                    overrideCollapsed={showCollapsed}
                    overrideExpanded={showExpanded}
                    canEdit={isCustom}
                    onChange={onPermissionChange}
                    permissionsList={permissionsList} />
                )}
            </>
          )}

        <BlockNavigation
          canBlock={hasChanges}
          promptTitle="Warning"
          promptText="Are you sure you want to leave?"
          promptSubText="You have unsaved changes that will be lost if you leave now. This cannot be undone."
          promptActionText="Leave" />

      </div>
    </div>
  )
}

export default RoleMain
