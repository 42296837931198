import { ForecastConfigStatus } from '../../grpc/enums'
import { useForecastingConfigs } from '../../context/forecastingConfigs'
import { Link } from 'react-router-dom'
import { useModal } from '../../hooks/useModal'
import { useRoutes } from '../../context/routes'
import { secondsToDistanceToNow } from '../../lib/dateFns'
import ActionModal from '../common/actionModal'
import Button from '../common/button'
import Circle from '../icons/circle'
import CircleEmpty from '../icons/circleEmpty'
import classNames from 'classnames'
import Tooltip from '../common/tooltip'
import Info from '../icons/info'
import ForecastListItemMenu from './forecastListItemMenu'
import React, { useCallback, useMemo } from 'react'

const ForecastListItem = (props) => {
  const {
    config,
    ...rest
  } = props

  const {
    name,
    status,
    statusDisabledAt,
    columnsCount,
    pivotGroupsCount,
    hasPublishableChanges } = config

  const modal = useModal()
  const { routes } = useRoutes()

  const { publishConfig } = useForecastingConfigs()

  const lastPublishedAt = useMemo(() => {
    if (config?.status !== ForecastConfigStatus.ENABLED
      || !config?.updatedAt?.seconds) {
      return ''
    }
    return secondsToDistanceToNow(config.updatedAt.seconds, false)
  }, [config])

  const isActiveIsh = useMemo(() => {
    return status === ForecastConfigStatus.PENDING || status === ForecastConfigStatus.ENABLED
  }, [status])

  const canPublish = useMemo(() => {
    return isActiveIsh && (columnsCount > 0 && pivotGroupsCount > 0)
  }, [columnsCount, pivotGroupsCount, isActiveIsh])

  const onClickPublish = useCallback(() => {
    modal.setOpen(true)
  }, [modal])

  const onPublishInternal = useCallback((e) => {
    publishConfig({
      ...config,
      status: ForecastConfigStatus.ENABLED,
      sort: 999
    })
    window.analytics.track('Publish Forecast Clicked')
    modal.setOpen(false)
  }, [config, modal, publishConfig])

  const onCancel = useCallback(() => {
    modal.setOpen(false)
  }, [modal])

  const getActivationErrorMessage = useMemo(() => {
    if (columnsCount === 0 && pivotGroupsCount > 0) {
      return 'This forecast must have a metric created before it can be published.'
    } else if (columnsCount > 0 && pivotGroupsCount === 0) {
      return 'This forecast must have a group assigned before it can be published.'
    } else if (columnsCount === 0 && pivotGroupsCount === 0) {
      return 'This forecast must have a metric created and a group assigned before it can be published.'
    }
  }, [columnsCount, pivotGroupsCount])

  const renderPublishButton = useCallback(() => {
    return (
      <div className="pr-5">
        <div className="flex justify-end">
          <Button
            disabled={!hasPublishableChanges}
            variant="outline"
            size="xs"
            text="Publish"
            onClick={onClickPublish} />
        </div>
      </div>
    )
  }, [hasPublishableChanges, onClickPublish])

  return (
    <div className={classNames('tr h-12 hover:bg-color-51636a-05', { 'opacity-50': status === ForecastConfigStatus.ARCHIVED })} {...rest}>
      <ForecastListItemMenu
        config={config}
        disabled={status === ForecastConfigStatus.DISABLED && statusDisabledAt.valid} />
      <div className="td w-1/4">
        <div className="-ml-1 truncate" title={name}>
          <Link to={routes.forecast.replace(':forecastId', config?.id)}>{name}</Link>
          {(isActiveIsh && !canPublish) && (
            <Tooltip
              arrow
              placement="right"
              title={getActivationErrorMessage}>
              <Info htmlColor="#5951FF" transform="scale(0.8)" />
            </Tooltip>
          )}
        </div>
      </div>
      <div className="td w-1/4">
        <div className="-ml-3">
          {status === ForecastConfigStatus.ENABLED && (
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row">
                <Circle fill="#1dcf83" transform="scale(0.55)" />
                <div className="text-color-818e93 text-size-16px font-weight-500 mr-5" style={{ transform: 'translateY(1px)' }}>
                  {' '}
                  Active
                </div>
              </div>
            </div>
          )}
          {(status === ForecastConfigStatus.DISABLED && statusDisabledAt.valid) && (
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row">
                <CircleEmpty fill="#818e93" transform="scale(0.55)" />
                <div className="text-color-818e93 text-size-16px font-weight-500 mr-5" style={{ transform: 'translateY(1px)' }}>
                  {' '}
                  Archived
                </div>
              </div>
            </div>
          )}
          {(status === ForecastConfigStatus.PENDING) && (
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row">
                <CircleEmpty fill="#818e93" transform="scale(0.55)" />
                <div className="text-color-818e93 text-size-16px font-weight-500 mr-5" style={{ transform: 'translateY(1px)' }}>
                  {' '}
                  Pending
                </div>
              </div>
            </div>
          )}
          {status === ForecastConfigStatus.ARCHIVED && (
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row">
                <Circle fill="#818E93" transform="scale(0.55)" />
                <div className="text-color-818e93 text-size-16px font-weight-500 mr-5" style={{ transform: 'translateY(1px)' }}>
                  {' '}
                  Archived
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="td w-1/4 font-weight-400 text-color-818e93 text-size-13px">
        {lastPublishedAt}
      </div>
      <div className="td w-1/4">
        {isActiveIsh && (
          <>
            {renderPublishButton()}
          </>
        )}
      </div>
      <ActionModal
        slim
        modal={modal}
        title="Publish this forecast?"
        text={(
          <>
            This forecast will be visible to any team you’ve selected.
            <br />
            <br />
            Once a forecast is active, most settings for it can’t be edited, and it can only be deleted or archived.
          </>
        )}
        actionText="Publish"
        onCancel={onCancel}
        onAction={onPublishInternal}
      />
    </div>
  )
}

export default ForecastListItem
