import React, { useCallback, useMemo } from 'react'
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized'

export function useVirtualizedList({ defaultHeight, rowRenderer }) {
  const cellMeasurerCache = useMemo(() => {
    return new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: defaultHeight || 0,
    })
  }, [])

  const rowRendererWrapper = useCallback((rowRendererArgs) => {
    const { index, key, parent } = rowRendererArgs
    return (
      <CellMeasurer
        key={key}
        cache={cellMeasurerCache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}>
        {(cellMeasurerArgs) => rowRenderer({ rowRendererArgs, cellMeasurerArgs })}
      </CellMeasurer>
    )
  }, [rowRenderer])

  return {
    cellMeasurerCache,
    rowRenderer: rowRendererWrapper,
  }
}
