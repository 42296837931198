import { Radio as MuiRadio } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const Radio = withStyles((theme) => ({
  root: {
    padding: 0
  },
}))((props) => (
  <MuiRadio disableRipple size="small" {...props} />
))

export default Radio
