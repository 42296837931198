// source: src/common/orchard/system_role.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_common_auth_auth_pb = require('../../../src/common/auth/auth_pb.js');
goog.object.extend(proto, src_common_auth_auth_pb);
goog.exportSymbol('proto.loupe.common.orchard.BasicStatus', null, global);
goog.exportSymbol('proto.loupe.common.orchard.RolePermissions', null, global);
goog.exportSymbol('proto.loupe.common.orchard.SystemRole', null, global);
goog.exportSymbol('proto.loupe.common.orchard.SystemRoleExtended', null, global);
goog.exportSymbol('proto.loupe.common.orchard.SystemRoleType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.orchard.SystemRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.orchard.SystemRole.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.orchard.SystemRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.orchard.SystemRole.displayName = 'proto.loupe.common.orchard.SystemRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.orchard.SystemRoleExtended = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.orchard.SystemRoleExtended.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.orchard.SystemRoleExtended, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.orchard.SystemRoleExtended.displayName = 'proto.loupe.common.orchard.SystemRoleExtended';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.orchard.SystemRole.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.orchard.SystemRole.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.orchard.SystemRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.orchard.SystemRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.orchard.SystemRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    priority: jspb.Message.getFieldWithDefault(msg, 7, 0),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 10, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 12, ""),
    baseRoleId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    isCustom: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.common.orchard.SystemRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.orchard.SystemRole;
  return proto.loupe.common.orchard.SystemRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.orchard.SystemRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.common.orchard.SystemRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.common.orchard.SystemRoleType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPermissions(values[i]);
      }
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriority(value);
      break;
    case 8:
      var value = /** @type {!proto.loupe.common.orchard.BasicStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseRoleId(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.orchard.SystemRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.orchard.SystemRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.orchard.SystemRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.orchard.SystemRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBaseRoleId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIsCustom();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.orchard.SystemRole.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.common.orchard.SystemRole.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.loupe.common.orchard.SystemRole.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.loupe.common.orchard.SystemRole.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional SystemRoleType type = 5;
 * @return {!proto.loupe.common.orchard.SystemRoleType}
 */
proto.loupe.common.orchard.SystemRole.prototype.getType = function() {
  return /** @type {!proto.loupe.common.orchard.SystemRoleType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.common.orchard.SystemRoleType} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated int64 permissions = 6;
 * @return {!Array<number>}
 */
proto.loupe.common.orchard.SystemRole.prototype.getPermissionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * optional int32 priority = 7;
 * @return {number}
 */
proto.loupe.common.orchard.SystemRole.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional BasicStatus status = 8;
 * @return {!proto.loupe.common.orchard.BasicStatus}
 */
proto.loupe.common.orchard.SystemRole.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.orchard.BasicStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.loupe.common.orchard.BasicStatus} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.orchard.SystemRole.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
*/
proto.loupe.common.orchard.SystemRole.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.orchard.SystemRole.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string created_by = 10;
 * @return {string}
 */
proto.loupe.common.orchard.SystemRole.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.orchard.SystemRole.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
*/
proto.loupe.common.orchard.SystemRole.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.orchard.SystemRole.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string updated_by = 12;
 * @return {string}
 */
proto.loupe.common.orchard.SystemRole.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string base_role_id = 13;
 * @return {string}
 */
proto.loupe.common.orchard.SystemRole.prototype.getBaseRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.setBaseRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool is_custom = 14;
 * @return {boolean}
 */
proto.loupe.common.orchard.SystemRole.prototype.getIsCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.orchard.SystemRole} returns this
 */
proto.loupe.common.orchard.SystemRole.prototype.setIsCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.orchard.SystemRoleExtended.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.orchard.SystemRoleExtended.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.orchard.SystemRoleExtended.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.orchard.SystemRoleExtended} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.orchard.SystemRoleExtended.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: (f = msg.getRole()) && proto.loupe.common.orchard.SystemRole.toObject(includeInstance, f),
    extendedPermissionsList: jspb.Message.toObjectList(msg.getExtendedPermissionsList(),
    src_common_auth_auth_pb.PermissionSetExtended.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.orchard.SystemRoleExtended}
 */
proto.loupe.common.orchard.SystemRoleExtended.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.orchard.SystemRoleExtended;
  return proto.loupe.common.orchard.SystemRoleExtended.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.orchard.SystemRoleExtended} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.orchard.SystemRoleExtended}
 */
proto.loupe.common.orchard.SystemRoleExtended.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.orchard.SystemRole;
      reader.readMessage(value,proto.loupe.common.orchard.SystemRole.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 2:
      var value = new src_common_auth_auth_pb.PermissionSetExtended;
      reader.readMessage(value,src_common_auth_auth_pb.PermissionSetExtended.deserializeBinaryFromReader);
      msg.addExtendedPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.orchard.SystemRoleExtended.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.orchard.SystemRoleExtended.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.orchard.SystemRoleExtended} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.orchard.SystemRoleExtended.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.common.orchard.SystemRole.serializeBinaryToWriter
    );
  }
  f = message.getExtendedPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_auth_auth_pb.PermissionSetExtended.serializeBinaryToWriter
    );
  }
};


/**
 * optional SystemRole role = 1;
 * @return {?proto.loupe.common.orchard.SystemRole}
 */
proto.loupe.common.orchard.SystemRoleExtended.prototype.getRole = function() {
  return /** @type{?proto.loupe.common.orchard.SystemRole} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.orchard.SystemRole, 1));
};


/**
 * @param {?proto.loupe.common.orchard.SystemRole|undefined} value
 * @return {!proto.loupe.common.orchard.SystemRoleExtended} returns this
*/
proto.loupe.common.orchard.SystemRoleExtended.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.orchard.SystemRoleExtended} returns this
 */
proto.loupe.common.orchard.SystemRoleExtended.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.orchard.SystemRoleExtended.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated loupe.common.auth.PermissionSetExtended extended_permissions = 2;
 * @return {!Array<!proto.loupe.common.auth.PermissionSetExtended>}
 */
proto.loupe.common.orchard.SystemRoleExtended.prototype.getExtendedPermissionsList = function() {
  return /** @type{!Array<!proto.loupe.common.auth.PermissionSetExtended>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_auth_auth_pb.PermissionSetExtended, 2));
};


/**
 * @param {!Array<!proto.loupe.common.auth.PermissionSetExtended>} value
 * @return {!proto.loupe.common.orchard.SystemRoleExtended} returns this
*/
proto.loupe.common.orchard.SystemRoleExtended.prototype.setExtendedPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.auth.PermissionSetExtended=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.auth.PermissionSetExtended}
 */
proto.loupe.common.orchard.SystemRoleExtended.prototype.addExtendedPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.auth.PermissionSetExtended, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.orchard.SystemRoleExtended} returns this
 */
proto.loupe.common.orchard.SystemRoleExtended.prototype.clearExtendedPermissionsList = function() {
  return this.setExtendedPermissionsList([]);
};


/**
 * @enum {number}
 */
proto.loupe.common.orchard.BasicStatus = {
  INACTIVE: 0,
  ACTIVE: 1
};

/**
 * @enum {number}
 */
proto.loupe.common.orchard.SystemRoleType = {
  UNKNOWN: 0,
  INTERNAL: 1,
  MANAGER: 2,
  IC: 3
};

/**
 * @enum {number}
 */
proto.loupe.common.orchard.RolePermissions = {
  NONE: 0
};

goog.object.extend(exports, proto.loupe.common.orchard);
