import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGrpcCallback, useGrpcEffect } from '../grpc'
import { toListIntegrationObjectTypesRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useAuth } from './auth'
import { useTenantInfo } from './tenantInfo'

const IntegrationObjectTypesContext = React.createContext()

export function IntegrationObjectTypesProvider({ children }) {
  const { tenantId } = useAuth()
  const { tenantInfo } = useTenantInfo()
  const { notifyError } = useNotification()

  const [key, setKey] = useState(guid())
  const [integrationObjectTypes, setIntegrationObjectTypes] = useState([])

  const listIntegrationObjectTypes = useGrpcCallback({
    onError: () => {
      notifyError('Error fetching integration object types!')
    },
    onSuccess: (obj) => {
      setIntegrationObjectTypes(obj.objectTypesList)
    },
    grpcMethod: 'listIntegrationObjectTypes',
    debug: false,
  }, [])

  useEffect(() => {
    const { crmType } = tenantInfo
    if (!crmType) {
      return
    }
    const request = toListIntegrationObjectTypesRequest({
      tenantId,
      crmType,
    })
    listIntegrationObjectTypes(request)
  }, [tenantId, tenantInfo, key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      integrationObjectTypes,
      setIntegrationObjectTypes,
      key,
      invalidate,
    }
  }, [integrationObjectTypes])

  return <IntegrationObjectTypesContext.Provider value={contextValue}>{children}</IntegrationObjectTypesContext.Provider>
}

export function useIntegrationObjectTypes() {
  const context = React.useContext(IntegrationObjectTypesContext)
  if (context === undefined) {
    throw new Error('useIntegrationObjectTypes must be used within a IntegrationObjectTypesProvider')
  }
  return context
}
