import React from 'react'
import classNames from 'classnames'

const tagName = process.env.TAG_NAME
const shortSHA = process.env.SHORT_SHA
const version = tagName || shortSHA

const AppVersion = (props) => {
  const {
    className,
  } = props

  return (
    <div className={classNames(className)}>
      {version && (
        <div>
          {version}
        </div>
      )}
    </div>
  )
}

export default AppVersion
