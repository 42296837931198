import { useJoyride } from '../../context/joyride'
import { useModal } from '../../hooks/useModal'
import { useSandboxes } from '../../context/sandboxes'
import AddSandboxModal from './addSandboxModal'
import Button from '../common/button'
import EmptyState from '../common/emptyState'
import Handyman from '../icons/handyman'
import Header from '../header/header'
import LoadingState from '../common/loadingState'
import React, { useCallback, useEffect, useMemo } from 'react'
import SandboxList from './sandboxList'
import { sandboxesGuide } from '../../constants/externalUrls'
import PageDescription from '../pageDescription/pageDescription'

const SandboxMain = (props) => {
  const addRoleModal = useModal()

  const { isFetching, sandboxes, maxSandboxes } = useSandboxes()

  const { enabled: joyrideEnabled, setJoyride, start: startJoyride } = useJoyride()

  const configureJoyride = useCallback(() => {
    setJoyride([
      {
        target: '#joyride_sandboxes_add',
        title: 'Add a Sandbox Here',
        content: 'If needed, you can add a sandbox by clicking this button. ',
        offset: 0,
        placement: 'left'
      },
      {
        target: '#joyride_sandboxes_total',
        title: 'Sandbox Total',
        content: 'This is where you can see how many sandboxes you have and how many are available to you.',
        offset: 0,
        placement: 'right'
      }
    ])
    startJoyride()
  }, [setJoyride, startJoyride])

  useEffect(() => {
    if (joyrideEnabled && !isFetching) {
      configureJoyride()
    }
  }, [configureJoyride, isFetching, joyrideEnabled])

  const count = useMemo(() => {
    return sandboxes.length
  }, [sandboxes])

  const showEmptyState = useMemo(() => {
    return !isFetching && count === 0
  }, [isFetching, count])

  const onAddSandbox = useCallback(() => {
    window.analytics.track('Add Sandbox Clicked')
    addRoleModal.setOpen(true)
  }, [addRoleModal])

  return (
    <div className="flex flex-col w-full h-screen">
      <Header
        title="Sandboxes" />
      <div className="flex-grow overflow-auto">
        <PageDescription title="Sandbox Environment"
          text="This enables you to test configuration changes in a sandboxed environment that won’t impact your production Commit environment."
          link={sandboxesGuide} />

        <div className="px-10 pb-10">
          <div className="flex items-center justify-between mt-8 mb-3">
            <div id="joyride_sandboxes_total" className="text-size-20px text-color-51636a font-weight-400">
              {!isFetching
                ? (
                  <>
                    {`Using ${count} of ${maxSandboxes} sandboxes`}
                  </>
                )
                : ''}
            </div>
            <Button
              id="joyride_sandboxes_add"
              disabled={count === maxSandboxes}
              text="Add Sandbox"
              onClick={onAddSandbox} />
          </div>

          {showEmptyState
            ? (
              <div className="flex justify-center my-10">
                <EmptyState
                  iconControl={<Handyman transform="scale(2)" />}
                  header="No Sandboxes"
                  subHeader="There are no sandboxes to display" />
              </div>
            )
            : (
              <>
                {isFetching
                  ? (
                    <div className="flex justify-center my-10">
                      <LoadingState
                        header="Loading Sandboxes"
                        subHeader="Please wait..."
                        animate={true} />
                    </div>
                  )
                  : (
                    <SandboxList modal={addRoleModal} />
                  )}
              </>
            )}

          <AddSandboxModal modal={addRoleModal} />
        </div>
      </div>
    </div>
  )
}

export default SandboxMain
