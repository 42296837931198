import React from 'react'
import { useDragLayer } from 'react-dnd'

const CustomDragLayer = (props) => {
  const {
    itemRenderer,
    data, // pass through data for the renderer
    zIndex = 10000,
  } = props

  const { itemType, isDragging, item, clientOffset } = useDragLayer((monitor) => {
    return {
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      clientOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }
  })

  if (!isDragging) {
    return null
  }
  return (
    <div className="fixed pointer-events-none left-0 top-0 w-full h-full" style={{ zIndex }}>
      {itemRenderer && itemRenderer({ data, isDragging, item, itemType, clientOffset })}
    </div>
  )
}

export default CustomDragLayer
