import React, { useCallback, useMemo, useState } from 'react'
import { guid } from '../lib/guid'
import { validate } from 'uuid'

const RoutesContext = React.createContext()

export function RoutesProvider({ children }) {
  const { pathname } = window.location

  const [key, setKey] = useState(guid())

  const baseRoutes = useMemo(() => ({
    noop: '/noop',
    unauthorized: '/unauthorized',
    login: '/login',
    logout: '/logout',
    authRedirect: '/auth-redirect',
    default: '/',
    tenants: '/tenants',
    teams: '/teams',
    users: '/users',
    role: '/roles/:roleId',
    roles: '/roles',
    sandboxes: '/sandboxes',
    goals: '/goals',
    status: '/status',
    dataSync: '/data-sync',
    dataSyncHistory: '/data-sync/history',
    dataSyncObjectMapping: '/data-sync/:name/:data',
    forecast: '/forecasts/:forecastId',
    forecasts: '/forecasts',
    features: '/features',
    analyticsSettings: '/features/analytics',
    dealGridSettings: '/features/deal-grid',
    forecastingSettings: '/features/forecasting',
    scenarioPlannerSettings: '/features/scenario-planner',
    outreachSettings: '/features/outreach',
    hierarchy: '/features/hierarchy',
    setup: '/setup',
    sync: '/sync',
  }), [])

  const routeTenantId = useMemo(() => {
    const [, root = ''] = pathname.split('/')

    const isGuid = validate(root)

    const regexp = new RegExp(`^/${root}`)
    const basePaths = Object.values(baseRoutes)
    const rootPathExists = basePaths.find((basePath) => basePath.match(regexp))

    if (!rootPathExists && isGuid) {
      return root
    }
  }, [pathname, baseRoutes])

  const routes = useMemo(() => {
    // route matches original, no tenantId supplied
    if (!routeTenantId) {
      return baseRoutes
    }

    const prefixedRoutes = {}
    Object.keys(baseRoutes).forEach((routeName) => {
      switch (routeName) {
        case 'login':
        case 'logout':
          prefixedRoutes[routeName] = `${baseRoutes[routeName]}`
          break
        default:
          prefixedRoutes[routeName] = `/${routeTenantId}${baseRoutes[routeName]}`
      }
    })

    return prefixedRoutes
  }, [baseRoutes, routeTenantId])

  const invalidate = useCallback((route = '/') => {
    setKey(guid())
    window.location.replace(route)
  }, [])

  const contextValue = useMemo(() => {
    return {
      baseRoutes,
      routes,
      routeTenantId,
      invalidate
    }
  }, [baseRoutes, routes, routeTenantId, invalidate])

  return <RoutesContext.Provider value={contextValue}>{children}</RoutesContext.Provider>
}

export function useRoutes() {
  const context = React.useContext(RoutesContext)
  if (context === undefined) {
    throw new Error('useRoutes must be used within a RoutesProvider')
  }
  return context
}
