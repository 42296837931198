import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ChevronDown from '../icons/chevronDown'
import PermissionListItemFlag from './permissionListItemFlag'
import { filter } from 'lodash'
import { usePermissions } from '../../context/permissions'
import { permissionNames } from '../../constants/permissionNames'

const PermissionListItem = (props) => {
  const {
    canEdit = false,
    onChange,
    overrideCollapsed,
    overrideExpanded,
    permission
  } = props

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanUpdateSystemRole
    )
  }, [checkPermissions])

  const { name, description, permissionsList = [] } = permission

  const [open, setOpen] = useState(false)

  const count = useMemo(() => {
    return filter(permissionsList, (p) => p.hasPermission).length
  }, [permissionsList])

  const onOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  useEffect(() => {
    if (overrideExpanded) {
      setOpen(true)
    }
  }, [overrideExpanded])

  useEffect(() => {
    if (overrideCollapsed) {
      setOpen(false)
    }
  }, [overrideCollapsed])

  const onChangeInternal = useCallback((flag) => {
    const { permission: flagPermission } = flag
    onChange({
      ...permission,
      permissionsList: permissionsList.map((p) => {
        if (p.permission === flagPermission) {
          return flag
        }
        return p
      })
    })
  }, [permission, permissionsList, onChange])

  return (
    <>
      <button onClick={onOpen} className="tr hover:bg-color-51636a-05">
        <div className="td-menu">
          <div
            style={{ padding: 6, minWidth: 24, minHeight: 24 }}
            className={classNames('ml-2 flex flex-shrink-0 flex-grow-0',
              'items-center justify-center rounded-full',
              'text-size-12px text-color-ffffff font-weight-500',
              'leading-none cursor-default',
              { 'bg-color-5951FF': canEdit },
              { 'bg-color-c9ced0': !canEdit })}>
            <div style={{ transform: 'translate(0px, 1px)' }}>{count}</div>
          </div>
        </div>
        <div className="td w-1/5 text-left font-bold">{name}</div>
        <div className="td w-3/5 text-color-818e93 text-left text-size-14px font-weight-300">
          <div style={{ paddingLeft: 8 }}>{description}</div>
        </div>
        <div className="td flex items-center justify-center">
          <div
            style={{
              transition: 'transform .3s ease-in-out',
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
            }}>
            <ChevronDown fill="#000" transform="scale(1.5)" />
          </div>
        </div>
      </button>
      {open && (
        permissionsList.map((p) => (
          <PermissionListItemFlag
            open={open}
            onChange={onChangeInternal}
            canEdit={canEdit && permissions.CanUpdateSystemRole}
            key={`permission-flag-${p.permission}`}
            flag={p} />
        ))
      )}
    </>
  )
}

export default PermissionListItem
