import { Select as MuiSelect } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ChevronDown from '../icons/chevronDown'
import React from 'react'

const Select = withStyles((theme) => ({
  root: {
    '&:focus': {
      background: 'transparent',
      borderRadius: '4px',
    },
  },
  icon: {
    fill: '#A6B2CF',
    margin: theme.spacing(0, 0.5)
  },
  select: {
    paddingLeft: 12,
    paddingTop: 10,
    paddingBottom: 10,
    border: '1px solid #d6d9e6',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
  },
}))((props) => (
  <MuiSelect IconComponent={ChevronDown} {...props} />
))

export default Select
