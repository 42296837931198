import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Label from '../common/label'
import Checkbox from '../common/checkbox'
import Info from '../icons/info'
import Plus from '../icons/plus'
import Tooltip from '../common/tooltip'
import Button from '../common/button'
import SearchBox from '../common/searchBox'
import FieldListItem, { dragRenderer } from './fieldListItem'
import CustomDragLayer from '../dragAndDrop/customDragLayer'
import { useDealGridSettings } from '../../context/dealGridSettings'
import { useObjectDefinition } from '../../context/objectDefinition'
import { useTenantInfo } from '../../context/tenantInfo'
import { cloneDeep, filter, find, orderBy } from 'lodash'
import { customFields } from './constants'
import objectHash from 'object-hash'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { usePopover } from '../../hooks/usePopover'
import { useTextField } from '../../hooks/useTextField'
import { Menu, MenuItem } from '@material-ui/core'
import BlockNavigation from '../common/blockNavigation'
import { useGrpcCallback } from '../../grpc'
import { useNotification } from '../../hooks/useNotification'
import { toUpsertPipelineSettingRequest, toUpsertWritebackSettingRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import LoadingState from '../common/loadingState'
import ErrorWithReload from '../common/errorWithReload'

const DealGridSettingsMain = (props) => {
  const { tenantId } = useAuth()

  const menuPopover = usePopover()
  const search = useTextField()

  const { notifySuccess, notifyError } = useNotification()

  const { isFetching: isFetchingObjectDefinition, objectDefinition } = useObjectDefinition()
  const { isFetching: isFetchingDealGridSettings, fetchError, settings, invalidate } = useDealGridSettings()
  const { invalidate: invalidateTenantInfo } = useTenantInfo()

  const isFetching = useMemo(() => {
    return isFetchingObjectDefinition || isFetchingDealGridSettings
  }, [isFetchingObjectDefinition, isFetchingDealGridSettings])

  const [upsertError, setUpsertError] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [originalSettings, setOriginalSettings] = useState({})
  const [isEnabled, setIsEnabled] = useState(false)
  const [isWritebackEnabled, setIsWritebackEnabled] = useState(false)
  const [fieldsList, setFieldsList] = useState([])

  useEffect(() => {
    if (objectDefinition && objectDefinition.fieldsList && settings) {
      setOriginalSettings(cloneDeep(settings))
      const lookupFields = [
        ...customFields,
        ...objectDefinition.fieldsList,
      ]
      const { enabled = false, writebackEnabled = false, primaryFieldsList = [] } = settings
      setIsEnabled(enabled)
      setIsWritebackEnabled(writebackEnabled)
      setFieldsList(primaryFieldsList.map((field) => {
        const f = find(lookupFields, (f) => f.to === field) || { label: field }
        return {
          label: f.label,
          value: field,
        }
      }))
    }
  }, [objectDefinition, settings])

  const onMove = useCallback((dragIndex, hoverIndex) => {
    const dragItem = fieldsList[dragIndex]
    const hoverItem = fieldsList[hoverIndex]
    setFieldsList((fields) => {
      const updatedFieldsList = [...fields]
      updatedFieldsList[dragIndex] = hoverItem
      updatedFieldsList[hoverIndex] = dragItem
      return updatedFieldsList
    })
  }, [fieldsList])

  const changes = useMemo(() => {
    const { enabled = false, writebackEnabled = false, primaryFieldsList = [] } = originalSettings
    const fieldOrderChanged = objectHash({ fieldsList: primaryFieldsList }) !== objectHash({ fieldsList: fieldsList.map((f) => f.value) })
    return {
      fieldOrderChanged,
      enabled: enabled !== isEnabled,
      writebackEnabled: writebackEnabled !== isWritebackEnabled,
    }
  }, [originalSettings, isEnabled, isWritebackEnabled, fieldsList])

  const hasChanges = useMemo(() => {
    return Object.keys(changes).some((key) => !!changes[key])
  }, [changes])

  const onAddColumn = useCallback((e) => {
    search.reset()
    menuPopover.setAnchorEl(e.currentTarget)
  }, [])

  const onMenuClose = useCallback(() => {
    menuPopover.setAnchorEl(null)
    search.reset()
  }, [])

  const menuItems = useMemo(() => {
    if (objectDefinition && objectDefinition.fieldsList) {
      const allFields = orderBy([
        ...customFields,
        ...objectDefinition.fieldsList,
      ], (f) => f.label)
      const fields = fieldsList.map((f) => f.value)
      return filter(allFields, (f) => !fields.includes(f.to))
    }
    return []
  }, [objectDefinition, fieldsList])

  const filteredMenuItems = useMemo(() => {
    if (search.value) {
      return filter(menuItems, (f) => {
        const regex = new RegExp(search.escapedValue.toLowerCase())
        return f.label && regex.test(f.label.toLowerCase())
      })
    }
    return menuItems
  }, [menuItems, search])

  const onKeyDown = useCallback((e) => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        break
      default:
        e.stopPropagation()
    }
  }, [])

  const onMenuItemClick = useCallback((option) => {
    menuPopover.setAnchorEl(null)
    const { label, to } = option
    setFieldsList([
      ...fieldsList,
      { label, value: to }
    ])
  }, [fieldsList])

  const onDelete = useCallback((field) => {
    const { value } = field
    setFieldsList(filter(fieldsList, (f) => f.value !== value))
  }, [fieldsList])

  const upsertWritebackSettingCallback = useGrpcCallback({
    onError: () => {
      setIsSaving(false)
      setUpsertError(true)
      notifyError('Error saving Deal Grid settings!')
    },
    onSuccess: (obj) => {
      invalidate()
      invalidateTenantInfo()
      setIsSaving(false)
      setUpsertError(false)
      notifySuccess('Deal grid settings were saved!')
    },
    onFetch: () => {
      setIsSaving(true)
      setUpsertError(false)
    },
    grpcMethod: 'upsertWritebackSetting',
    debug: false,
  }, [])

  const upsertWritebackSetting = useCallback(() => {
    const request = toUpsertWritebackSettingRequest({
      tenantId,
      enabled: isWritebackEnabled,
      pipelineEnabled: isWritebackEnabled
    })

    upsertWritebackSettingCallback(request)
  }, [isWritebackEnabled, tenantId, upsertWritebackSettingCallback])

  const upsertPipelineSetting = useGrpcCallback({
    onError: () => {
      setIsSaving(false)
      setUpsertError(true)
      notifyError('Error saving Deal Grid settings!')
    },
    onSuccess: (obj) => {
      if (changes.writebackEnabled) {
        upsertWritebackSetting()
        return
      }

      invalidate()
      setIsSaving(false)
      setUpsertError(false)
      notifySuccess('Deal grid settings were saved!')
    },
    onFetch: () => {
      setIsSaving(true)
      setUpsertError(false)
    },
    grpcMethod: 'upsertPipelineSetting',
    debug: false,
  }, [changes.writebackEnabled, upsertWritebackSetting])

  const onSave = useCallback(() => {
    // If only writebackEnabled was changed, we only need to fire this upsert
    if (changes.writebackEnabled && !changes.enabled && !changes.fieldOrderChanged) {
      upsertWritebackSetting()
      return
    }

    const request = toUpsertPipelineSettingRequest({
      tenantId,
      setting: {
        ...settings,
        enabled: isEnabled,
        primaryFieldsList: fieldsList.map((f) => f.value),
      },
    })
    upsertPipelineSetting(request)
  }, [tenantId, settings, isEnabled, fieldsList, changes, upsertWritebackSetting])

  return fetchError || upsertError
    ? (
      <div className="flex justify-center my-10">
        <ErrorWithReload header={fetchError ? 'Error Loading Settings' : 'Error Saving Settings'} />
      </div>
    ) : (
      <>
        {isFetching || isSaving
          ? (
            <div>
              <div className="flex justify-center my-10">
                <LoadingState
                  header={isSaving ? 'Saving' : 'Loading Settings'}
                  subHeader="Please wait..."
                  animate={true} />
              </div>
            </div>
          )
          : (
            <>
              <div className="flex items-center justify-between">
                <div className="text-size-24px text-color-09242f font-weight-700">Deal Grid Settings</div>
                <Button
                  text="Save Changes"
                  onClick={onSave}
                  disabled={!hasChanges} />
              </div>
              <div className="text-size-15px text-color-51636a font-weight-400 leading-tight my-4">
                These settings can be customized to control which columns show up in the deal grid throughout all of Commit.
              </div>
              <div className="w-full md:w-7/12">
                <div className="mt-5 mb-5 first:mt-0 last:mb-0">
                  <div className="flex items-center">
                    <Label text="Enabled" />
                    <Tooltip placement="top" arrow={true} title="Enables the ability to use the Deal Grid feature.">
                      <div style={{ transform: 'translateY(-2px)' }}><Info fill="#818e93" transform="scale(0.8)" /></div>
                    </Tooltip>
                  </div>
                  <Checkbox
                    checked={isEnabled}
                    onChange={(e) => setIsEnabled(e.target.checked)} />
                </div>
                <div className="mt-5 mb-5 first:mt-0 last:mb-0">
                  <div className="flex items-center">
                    <Label text="Enable Deal Writeback" />
                    <Tooltip placement="top" arrow={true} title="Deal attributes can be modified within the deal grid if checked.">
                      <div style={{ transform: 'translateY(-2px)' }}><Info fill="#818e93" transform="scale(0.8)" /></div>
                    </Tooltip>
                  </div>
                  <Checkbox
                    checked={isWritebackEnabled}
                    onChange={(e) => setIsWritebackEnabled(e.target.checked)} />
                </div>

                <div className="mt-5 mb-5 first:mt-0 last:mb-0">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Label text="Deal Grid Columns" />
                      <Tooltip
                        placement="top"
                        arrow={true}
                        title="The columns to show within the deal grid interface.  The columns are displayed in the order they are configured here.">
                        <div style={{ transform: 'translateY(-2px)' }}><Info fill="#818e93" transform="scale(0.8)" /></div>
                      </Tooltip>
                    </div>
                    <button onClick={onAddColumn} className="focus:outline-none">
                      <Plus fill="#c9ced0" />
                    </button>
                    <Menu
                      anchorEl={menuPopover.anchorEl}
                      open={menuPopover.open}
                      autoFocus={false}
                      getContentAnchorEl={null}
                      PaperProps={{
                        style: {
                          maxHeight: 500,
                        },
                      }}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      onClose={onMenuClose}
                      TransitionProps={{ timeout: 0 }}>
                      <div className="px-2 pt-1 pb-2 focus:outline-none" onKeyDown={onKeyDown}>
                        <SearchBox
                          autoFocus={true}
                          value={search.value}
                          onChange={search.onChange}
                          onClear={search.reset} />
                      </div>
                      {filteredMenuItems.length === 0
                        ? <MenuItem disabled={true}>Search returned 0 results</MenuItem>
                        : filteredMenuItems.map((option) => (
                          <MenuItem
                            key={`FieldOption-${option.to}`}
                            // {...selectedIntegrationObjectProperty && { selected: option.value === selectedIntegrationObjectProperty.value }}
                            onClick={() => onMenuItemClick(option)}>
                            <div className="flex flex-col leading-tight">
                              {option.label}
                            </div>
                          </MenuItem>
                        ))}
                    </Menu>
                  </div>

                  <div className="flex items-center justify-between mt-3 px-3 py-2 border rounded border-color-d8d8d8 opacity-50">
                    <div className="text-size-16px text-color-51636a font-weight-400 truncate">Name</div>
                  </div>
                  <div className="flex items-center justify-between mt-3 px-3 py-2 border rounded border-color-d8d8d8 opacity-50">
                    <div className="text-size-16px text-color-51636a font-weight-400 truncate">Signals</div>
                  </div>

                  <DndProvider backend={HTML5Backend}>
                    <CustomDragLayer
                      itemRenderer={dragRenderer}
                      data={fieldsList} />
                    {fieldsList.map((field, index) => (
                      <FieldListItem
                        key={`FieldListItem-${field.value}-${field.label}`}
                        field={field}
                        index={index}
                        onMove={onMove}
                        onDelete={() => onDelete(field)} />
                    ))}
                  </DndProvider>

                </div>

              </div>
            </>
          )}
        <BlockNavigation
          canBlock={hasChanges}
          promptTitle="Warning"
          promptText="Are you sure you want to leave?"
          promptSubText="You have unsaved changes that will be lost if you leave now. This cannot be undone."
          promptActionText="Leave" />
      </>
    )
}

export default DealGridSettingsMain
