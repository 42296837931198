import React, { useCallback, useMemo, useState } from 'react'
import { useGrpcEffect } from '../grpc'
import { toGetTenantObjectRequest } from '../grpc/converters'
import { useNotification } from '../hooks/useNotification'
import { guid } from '../lib/guid'
import { useAuth } from './auth'
import { orderBy } from 'lodash'

const ObjectDefinitionExtendedContext = React.createContext()

export function ObjectDefinitionExtendedProvider({ objectName = 'opportunity', children }) {
  const { tenantId } = useAuth()

  const { notifyError } = useNotification()

  const [isFetching, setIsFetching] = useState(true)
  const [key, setKey] = useState(guid())
  const [objectDefinitionExtended, setObjectDefinitionExtended] = useState(undefined)

  useGrpcEffect({
    request: toGetTenantObjectRequest({
      tenantId,
      objectName,
    }),
    onError: () => {
      setIsFetching(false)
      notifyError('Error fetching object definition!')
    },
    onSuccess: (obj) => {
      if (obj.objectDefinition) {
        if (obj.objectDefinition.fieldsList) {
          obj.objectDefinition.fieldsList = orderBy(obj.objectDefinition.fieldsList, (f) => f.label)
        }
        setObjectDefinitionExtended(obj.objectDefinition)
      }
      setIsFetching(false)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getIntegrationObjectDefinitionExtended',
    debug: false,
  }, [tenantId, objectName, key])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      isFetching,
      objectDefinitionExtended,
      setObjectDefinitionExtended,
      key,
      invalidate,
    }
  }, [isFetching, objectDefinitionExtended])

  return <ObjectDefinitionExtendedContext.Provider value={contextValue}>{children}</ObjectDefinitionExtendedContext.Provider>
}

export function useObjectDefinitionExtended() {
  const context = React.useContext(ObjectDefinitionExtendedContext)
  if (context === undefined) {
    throw new Error('useObjectDefinitionExtended must be used within a ObjectDefinitionExtendedProvider')
  }
  return context
}
