import { toggleExpandedForAll } from '../tree/utils/tree-data-utils'
import { useForecastingConfig } from '../../context/forecastingConfig'
import { useTreeData } from '../../context/treeData'
import ForecastVisibilityNodeRenderer from '../tree/forecastVisibilityNodeRenderer'
import React, { useMemo, useEffect, useCallback, useState } from 'react'
import SortableTree from '@nosferatu500/react-sortable-tree'

const ForecastVisibilityControl = (props) => {
  const {
    search,
    pivotGroupsList,
    invalidateKey
  } = props

  const {
    flattened,
    dataInitialized,
    treeData,
    searchInTree,
    setTreeData
  } = useTreeData()

  const { setPivotGroups } = useForecastingConfig()

  const [selected, setSelected] = useState(new Map())

  const initialSelected = useMemo(() => {
    if (!pivotGroupsList?.length) {
      return pivotGroupsList
    }
    return pivotGroupsList
      .map(({ groupId }) => {
        const n = flattened.find(({ node }) => node?.group?.id === groupId)
        if (!n) {
          return
        }

        const { node, path: p = [] } = n

        const path = p.map((index) => {
          const n = flattened[index]
          return n?.node?.group?.id ?? index
        })
        return [groupId, { ...node, path }]
      })
      .filter((s) => s)
  }, [invalidateKey, pivotGroupsList, flattened])

  const onSelected = useCallback(({ node, path }) => {
    const elder = [...selected.values()].find(({ path }) => path.includes(node.group.id))

    if (elder && elder.group.id !== node.group.id) {
      selected.delete(elder.group.id)
    }

    if (!selected.delete(node.group.id)) {
      selected.set(node.group.id, { ...node, path })
    }
    const _selected = new Map(selected)

    setSelected(_selected)
    setPivotGroups(_selected)
  }, [selected, setPivotGroups])

  useEffect(() => {
    setSelected(new Map(initialSelected))
  }, [initialSelected])

  const onChange = useCallback((data) => {
    setTreeData(data)
  }, [])

  const getTree = useMemo(() => {
    if (!search.length) {
      return treeData
    }

    const newTree = searchInTree(search, treeData[0])
    return [newTree]
  }, [treeData, search])

  useEffect(() => {
    if (dataInitialized) {
      setTreeData(toggleExpandedForAll({ treeData, expanded: true }))
    }
  }, [dataInitialized])

  return (
    <div
      className="flex-grow overflow-auto flex flex-col">
      <div className="relative flex-1 visibility-node-renderer">
        <SortableTree
          treeData={getTree}
          canDrag={false}
          onChange={onChange}
          generateNodeProps={() => ({
            selected,
            onSelected
          })}
          theme={{
            nodeContentRenderer: ForecastVisibilityNodeRenderer,
            scaffoldBlockPxWidth: 25,
            rowHeight: 36,
            slideRegionSize: 50,
          }}
          style={{ marginLeft: -25, }}
          getNodeKey={({ node, treeIndex }) => node?.group?.id || treeIndex}
          canNodeHaveChildren={() => true} />
      </div>
    </div>
  )
}

export default ForecastVisibilityControl
