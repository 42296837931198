import { useSnackbar } from 'notistack'

export function useNotification() {
  // https://iamhosseindhv.com/notistack/api
  const { enqueueSnackbar } = useSnackbar()

  function notify(key, options) {
    enqueueSnackbar(key, options)
  }

  function notifySuccess(key, options) {
    enqueueSnackbar(key, { variant: 'success', ...options })
  }

  function notifyError(key, options) {
    enqueueSnackbar(key, { variant: 'error', ...options })
  }

  return {
    notify,
    notifySuccess,
    notifyError,
  }
}
