import React from 'react'
import { useSandboxes } from '../../context/sandboxes'
import SandboxListItem from './sandboxListItem'

const SandboxList = (props) => {
  const { modal } = props
  const { sandboxes } = useSandboxes()

  return (
    <>
      <div className="flex-table">
        <div className="thead">
          <div className="tr">
            <div className="td-menu" />
            <div className="td w-1/5">Name</div>
            <div className="td w-1/5">Description</div>
            <div className="td w-1/5">Type</div>
            <div className="td w-1/5">Created Date</div>
            <div className="td w-1/5">Sync Status</div>
          </div>
        </div>
        <div className="tbody">
          {sandboxes.map((sandbox, i) => (
            <SandboxListItem
              modal={modal}
              key={`SandboxListItem-${sandbox.key}-${i}`}
              sandbox={sandbox} />
          ))}
        </div>
      </div>
    </>
  )
}

export default SandboxList
