import { AutoSizer, List } from 'react-virtualized'
import React from 'react'

const AutocompleteListBox = (props, ref) => {
  const { children, itemSize, role, ...other } = props
  const itemCount = Array.isArray(children) ? children.length : 0

  return (
    <div ref={ref}>
      <div className="w-full" style={{ minHeight: 250 }} {...other}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              height={height}
              width={width}
              rowHeight={itemSize}
              overscanCount={5}
              rowCount={itemCount}
              rowRenderer={(props) => {
                return React.cloneElement(children[props.index], {
                  style: props.style
                })
              }}
              role={role}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  )
}

export default React.forwardRef(AutocompleteListBox)
