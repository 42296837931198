export const permissionNames = {
  CanAccessInternalAdmin: 'CanAccessInternalAdmin',
  CanAccessAdmin: 'CanAccessAdmin',

  CanAccessTenant: 'CanAccessTenant',
  CanReadTenant: 'CanReadTenant',
  CanUpdateTenant: 'CanUpdateTenant',
  CanCreateTenant: 'CanCreateTenant',

  CanAccessGroup: 'CanAccessGroup',
  CanReadGroup: 'CanReadGroup',
  CanUpdateGroup: 'CanUpdateGroup',
  CanCreateGroup: 'CanCreateGroup',
  CanDeleteGroup: 'CanDeleteGroup',

  CanAccessGoal: 'CanAccessGoal',
  CanReadGoal: 'CanReadGoal',
  CanUpdateGoal: 'CanUpdateGoal',

  CanAccessUser: 'CanAccessUser',
  CanReadUser: 'CanReadUser',
  CanUpdateUser: 'CanUpdateUser',
  CanCreateUser: 'CanCreateUser',
  CanDeleteUser: 'CanDeleteUser',

  CanAccessSync: 'CanAccessSync',
  CanReadSync: 'CanReadSync',
  CanUpdateSync: 'CanUpdateSync',
  CanCreateSync: 'CanCreateSync',

  CanAccessSystemRole: 'CanAccessSystemRole',
  CanReadSystemRole: 'CanReadSystemRole',
  CanUpdateSystemRole: 'CanUpdateSystemRole',
  CanCreateSystemRole: 'CanCreateSystemRole',
  CanDeleteSystemRole: 'CanDeleteSystemRole',

  CanAccessSandbox: 'CanAccessSandbox',

  CanAccessForecasts: 'CanAccessForecasts',
  CanReadForecasts: 'CanReadForecasts',
  CanUpdateForecasts: 'CanUpdateForecasts',
  CanCreateForecasts: 'CanCreateForecasts',
  CanManageForecasts: 'CanManageForecasts',
}
