import { permissionNames } from '../../constants/permissionNames'
import { usePermissions } from '../../context/permissions'
import { usePopover } from '../../hooks/usePopover'
import Delete from '../icons/delete'
import Edit from '../icons/edit'
import MoreVertical from '../icons/moreVertical'
import Popover from '../common/popover'
import React, { useCallback, useMemo } from 'react'

const UserMenu = (props) => {
  const {
    user,
    onEdit,
    onDelete,
  } = props

  const { checkPermissions } = usePermissions()

  const permissions = useMemo(() => {
    return checkPermissions(
      permissionNames.CanUpdateUser,
      permissionNames.CanDeleteUser
    )
  }, [checkPermissions])

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const onEditInternal = useCallback((e) => {
    window.analytics.track('Edit User Clicked')
    onEdit && onEdit(e, user)
    onClose()
  }, [onEdit, user])

  const onDeleteInternal = useCallback((e) => {
    window.analytics.track('Deactivate User Clicked')
    onDelete && onDelete(e, user)
    onClose()
  }, [onDelete, user])

  return (
    <>
      <button onClick={handleClick}>
        <MoreVertical fill="#151d49" />
      </button>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        placement="right">
        <div className="py-3">
          {permissions.CanUpdateUser && (
            <div onClick={onEditInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
              <div>
                <Edit fill="#09242f" transform="scale(0.75)" />
              </div>
              <div className="flex flex-col leading-tight ml-2">
                <div className="text-size-15px text-color-151d49 font-weight-400">Edit User</div>
                <div className="text-size-13px text-color-4e5d7e font-weight-400">Change details about this user</div>
              </div>
            </div>
          )}
          {permissions.CanDeleteUser && (
            <div onClick={onDeleteInternal} className="flex items-center px-5 py-3 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
              <div>
                <Delete fill="#09242f" transform="scale(0.75)" />
              </div>
              <div className="flex flex-col leading-tight ml-2">
                <div className="text-size-15px text-color-151d49 font-weight-400">Deactivate User</div>
                <div className="text-size-13px text-color-4e5d7e font-weight-400">Remove this license, but not the data</div>
              </div>
            </div>
          )}
        </div>
      </Popover>
    </>
  )
}

export default UserMenu
