import React from 'react'
import VersionChecker from '../common/versionChecker'
import AppVersion from '../common/appVersion'

const BlankTemplate = ({ component: Component, dock: Dock, ...rest }) => {
  return (
    <div className="flex flex-wrap items-stretch md:min-h-screen">
      <VersionChecker />
      <div className="flex flex-1 flex-col justify-end items-center">
        <Component {...rest} />
        <AppVersion className="text-color-e0e7ff text-size-12px p-12" />
      </div>
    </div>
  )
}

export default BlankTemplate
