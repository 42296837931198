import React from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import Header from '../header/header'
import DealGridSettings from './dealGridSettings'
import classNames from 'classnames'
import AnalyticsSettings from './analyticsSettings'
import ScenarioPlannerSettings from './scenarioPlannerSettings'
import ForecastingSettings from './forecastingSettings'
import OutreachSettings from './outreachSettings'
import HierarchySettings from './hierarchySettings'

const classes = 'mt-4 mb-4 first:mt-0 text-size-16px text-color-51636a font-weight-400 whitespace-nowrap cursor-pointer'

const Settings = (props) => {
  const history = useHistory()
  const { routes } = useRoutes()

  const analyticsSettingsMatch = useRouteMatch(routes.analyticsSettings)
  const dealGridSettingsMatch = useRouteMatch(routes.dealGridSettings)
  const forecastingSettingsMatch = useRouteMatch(routes.forecastingSettings)
  const scenarioPlannerSettingsMatch = useRouteMatch(routes.scenarioPlannerSettings)
  const outreachSettingsMatch = useRouteMatch(routes.outreachSettings)
  const hierarchyMatch = useRouteMatch(routes.hierarchy)

  return (
    <div className="flex flex-col w-full h-screen">
      <Header title="Features" />
      <div className="flex-grow overflow-auto p-10">
        <div className="flex flex-row">
          <div className="py-2">
            <div
              onClick={() => history.push(routes.analyticsSettings)}
              className={classNames(classes,
                { 'text-color-5951FF font-weight-500': analyticsSettingsMatch && analyticsSettingsMatch.isExact })}>
              Analytics
            </div>
            <div
              onClick={() => history.push(routes.dealGridSettings)}
              className={classNames(classes,
                { 'text-color-5951FF font-weight-500': dealGridSettingsMatch && dealGridSettingsMatch.isExact })}>
              Deal Grid
            </div>
            <div
              onClick={() => history.push(routes.forecastingSettings)}
              className={classNames(classes,
                { 'text-color-5951FF font-weight-500': forecastingSettingsMatch && forecastingSettingsMatch.isExact })}>
              Forecasting
            </div>
            <div
              onClick={() => history.push(routes.scenarioPlannerSettings)}
              className={classNames(classes,
                { 'text-color-5951FF font-weight-500': scenarioPlannerSettingsMatch && scenarioPlannerSettingsMatch.isExact })}>
              Scenario Planner
            </div>
            <div
              onClick={() => history.push(routes.outreachSettings)}
              className={classNames(classes,
                { 'text-color-5951FF font-weight-500': outreachSettingsMatch && outreachSettingsMatch.isExact })}>
              Outreach
            </div>
            {/* <div
              onClick={() => history.push(routes.hierarchy)}
              className={classNames(classes,
                { 'text-color-5951FF font-weight-500': hierarchyMatch && hierarchyMatch.isExact })}>
              Hierarchy
            </div> */}
          </div>
          <div className="ml-8 p-6 w-full bg-color-ffffff border border-color-2e5bff-08 rounded-lg">
            <Switch>
              <Route path={routes.analyticsSettings} component={AnalyticsSettings} />
              <Route path={routes.dealGridSettings} component={DealGridSettings} />
              <Route path={routes.forecastingSettings} component={ForecastingSettings} />
              <Route path={routes.scenarioPlannerSettings} component={ScenarioPlannerSettings} />
              <Route path={routes.outreachSettings} component={OutreachSettings} />
              {/* <Route path={routes.hierarchy} component={HierarchySettings} /> */}
              <Route path={routes.features} component={DealGridSettings} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
