// source: src/common/search/search.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_filter_filter_pb = require('../../../src/common/filter/filter_pb.js');
goog.object.extend(proto, src_common_filter_filter_pb);
var src_common_search_enum_enum_pb = require('../../../src/common/search/enum/enum_pb.js');
goog.object.extend(proto, src_common_search_enum_enum_pb);
var src_common_search_item_item_pb = require('../../../src/common/search/item/item_pb.js');
goog.object.extend(proto, src_common_search_item_item_pb);
goog.exportSymbol('proto.loupe.common.search.SearchContainer', null, global);
goog.exportSymbol('proto.loupe.common.search.SearchContainerChildren', null, global);
goog.exportSymbol('proto.loupe.common.search.SearchContainerNode', null, global);
goog.exportSymbol('proto.loupe.common.search.SearchPaginationContainer', null, global);
goog.exportSymbol('proto.loupe.common.search.SearchSortItem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.search.SearchContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.search.SearchContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.search.SearchContainer.displayName = 'proto.loupe.common.search.SearchContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.search.SearchContainerChildren = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.search.SearchContainerChildren.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.search.SearchContainerChildren, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.search.SearchContainerChildren.displayName = 'proto.loupe.common.search.SearchContainerChildren';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.search.SearchContainerNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.search.SearchContainerNode.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.search.SearchContainerNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.search.SearchContainerNode.displayName = 'proto.loupe.common.search.SearchContainerNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.search.SearchPaginationContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.search.SearchPaginationContainer.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.search.SearchPaginationContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.search.SearchPaginationContainer.displayName = 'proto.loupe.common.search.SearchPaginationContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.search.SearchSortItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.search.SearchSortItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.search.SearchSortItem.displayName = 'proto.loupe.common.search.SearchSortItem';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.search.SearchContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.search.SearchContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.search.SearchContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.SearchContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && proto.loupe.common.search.SearchContainerNode.toObject(includeInstance, f),
    children: (f = msg.getChildren()) && proto.loupe.common.search.SearchContainerChildren.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.search.SearchContainer}
 */
proto.loupe.common.search.SearchContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.search.SearchContainer;
  return proto.loupe.common.search.SearchContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.search.SearchContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.search.SearchContainer}
 */
proto.loupe.common.search.SearchContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.search.SearchContainerNode;
      reader.readMessage(value,proto.loupe.common.search.SearchContainerNode.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = new proto.loupe.common.search.SearchContainerChildren;
      reader.readMessage(value,proto.loupe.common.search.SearchContainerChildren.deserializeBinaryFromReader);
      msg.setChildren(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.search.SearchContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.search.SearchContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.search.SearchContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.SearchContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.loupe.common.search.SearchContainerNode.serializeBinaryToWriter
    );
  }
  f = message.getChildren();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.loupe.common.search.SearchContainerChildren.serializeBinaryToWriter
    );
  }
};


/**
 * optional SearchContainerNode node = 1;
 * @return {?proto.loupe.common.search.SearchContainerNode}
 */
proto.loupe.common.search.SearchContainer.prototype.getNode = function() {
  return /** @type{?proto.loupe.common.search.SearchContainerNode} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.search.SearchContainerNode, 1));
};


/**
 * @param {?proto.loupe.common.search.SearchContainerNode|undefined} value
 * @return {!proto.loupe.common.search.SearchContainer} returns this
*/
proto.loupe.common.search.SearchContainer.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.search.SearchContainer} returns this
 */
proto.loupe.common.search.SearchContainer.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.search.SearchContainer.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SearchContainerChildren children = 2;
 * @return {?proto.loupe.common.search.SearchContainerChildren}
 */
proto.loupe.common.search.SearchContainer.prototype.getChildren = function() {
  return /** @type{?proto.loupe.common.search.SearchContainerChildren} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.search.SearchContainerChildren, 2));
};


/**
 * @param {?proto.loupe.common.search.SearchContainerChildren|undefined} value
 * @return {!proto.loupe.common.search.SearchContainer} returns this
*/
proto.loupe.common.search.SearchContainer.prototype.setChildren = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.search.SearchContainer} returns this
 */
proto.loupe.common.search.SearchContainer.prototype.clearChildren = function() {
  return this.setChildren(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.search.SearchContainer.prototype.hasChildren = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.search.SearchContainerChildren.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.search.SearchContainerChildren.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.search.SearchContainerChildren.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.search.SearchContainerChildren} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.SearchContainerChildren.toObject = function(includeInstance, msg) {
  var f, obj = {
    combineAction: jspb.Message.getFieldWithDefault(msg, 1, 0),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.loupe.common.search.SearchContainer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.search.SearchContainerChildren}
 */
proto.loupe.common.search.SearchContainerChildren.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.search.SearchContainerChildren;
  return proto.loupe.common.search.SearchContainerChildren.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.search.SearchContainerChildren} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.search.SearchContainerChildren}
 */
proto.loupe.common.search.SearchContainerChildren.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.search.enum.SearchCombineAction} */ (reader.readEnum());
      msg.setCombineAction(value);
      break;
    case 2:
      var value = new proto.loupe.common.search.SearchContainer;
      reader.readMessage(value,proto.loupe.common.search.SearchContainer.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.search.SearchContainerChildren.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.search.SearchContainerChildren.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.search.SearchContainerChildren} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.SearchContainerChildren.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCombineAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.common.search.SearchContainer.serializeBinaryToWriter
    );
  }
};


/**
 * optional enum.SearchCombineAction combine_action = 1;
 * @return {!proto.loupe.common.search.enum.SearchCombineAction}
 */
proto.loupe.common.search.SearchContainerChildren.prototype.getCombineAction = function() {
  return /** @type {!proto.loupe.common.search.enum.SearchCombineAction} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.search.enum.SearchCombineAction} value
 * @return {!proto.loupe.common.search.SearchContainerChildren} returns this
 */
proto.loupe.common.search.SearchContainerChildren.prototype.setCombineAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated SearchContainer values = 2;
 * @return {!Array<!proto.loupe.common.search.SearchContainer>}
 */
proto.loupe.common.search.SearchContainerChildren.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.loupe.common.search.SearchContainer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.search.SearchContainer, 2));
};


/**
 * @param {!Array<!proto.loupe.common.search.SearchContainer>} value
 * @return {!proto.loupe.common.search.SearchContainerChildren} returns this
*/
proto.loupe.common.search.SearchContainerChildren.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.search.SearchContainer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.search.SearchContainer}
 */
proto.loupe.common.search.SearchContainerChildren.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.search.SearchContainer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.search.SearchContainerChildren} returns this
 */
proto.loupe.common.search.SearchContainerChildren.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.search.SearchContainerNode.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.search.SearchContainerNode.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.search.SearchContainerNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.search.SearchContainerNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.SearchContainerNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && src_common_search_item_item_pb.SearchItemFieldActual.toObject(includeInstance, f),
    valuesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    operation: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.search.SearchContainerNode}
 */
proto.loupe.common.search.SearchContainerNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.search.SearchContainerNode;
  return proto.loupe.common.search.SearchContainerNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.search.SearchContainerNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.search.SearchContainerNode}
 */
proto.loupe.common.search.SearchContainerNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_search_item_item_pb.SearchItemFieldActual;
      reader.readMessage(value,src_common_search_item_item_pb.SearchItemFieldActual.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.filter.FilterOperation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.search.SearchContainerNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.search.SearchContainerNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.search.SearchContainerNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.SearchContainerNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_search_item_item_pb.SearchItemFieldActual.serializeBinaryToWriter
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional item.SearchItemFieldActual item = 1;
 * @return {?proto.loupe.common.search.item.SearchItemFieldActual}
 */
proto.loupe.common.search.SearchContainerNode.prototype.getItem = function() {
  return /** @type{?proto.loupe.common.search.item.SearchItemFieldActual} */ (
    jspb.Message.getWrapperField(this, src_common_search_item_item_pb.SearchItemFieldActual, 1));
};


/**
 * @param {?proto.loupe.common.search.item.SearchItemFieldActual|undefined} value
 * @return {!proto.loupe.common.search.SearchContainerNode} returns this
*/
proto.loupe.common.search.SearchContainerNode.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.search.SearchContainerNode} returns this
 */
proto.loupe.common.search.SearchContainerNode.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.search.SearchContainerNode.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string values = 2;
 * @return {!Array<string>}
 */
proto.loupe.common.search.SearchContainerNode.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.search.SearchContainerNode} returns this
 */
proto.loupe.common.search.SearchContainerNode.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.search.SearchContainerNode} returns this
 */
proto.loupe.common.search.SearchContainerNode.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.search.SearchContainerNode} returns this
 */
proto.loupe.common.search.SearchContainerNode.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional loupe.common.filter.FilterOperation operation = 3;
 * @return {!proto.loupe.common.filter.FilterOperation}
 */
proto.loupe.common.search.SearchContainerNode.prototype.getOperation = function() {
  return /** @type {!proto.loupe.common.filter.FilterOperation} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.filter.FilterOperation} value
 * @return {!proto.loupe.common.search.SearchContainerNode} returns this
 */
proto.loupe.common.search.SearchContainerNode.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.search.SearchPaginationContainer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.search.SearchPaginationContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.search.SearchPaginationContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.search.SearchPaginationContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.SearchPaginationContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortList: jspb.Message.toObjectList(msg.getSortList(),
    proto.loupe.common.search.SearchSortItem.toObject, includeInstance),
    take: jspb.Message.getFieldWithDefault(msg, 2, 0),
    skip: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.search.SearchPaginationContainer}
 */
proto.loupe.common.search.SearchPaginationContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.search.SearchPaginationContainer;
  return proto.loupe.common.search.SearchPaginationContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.search.SearchPaginationContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.search.SearchPaginationContainer}
 */
proto.loupe.common.search.SearchPaginationContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.common.search.SearchSortItem;
      reader.readMessage(value,proto.loupe.common.search.SearchSortItem.deserializeBinaryFromReader);
      msg.addSort(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTake(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSkip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.search.SearchPaginationContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.search.SearchPaginationContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.search.SearchPaginationContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.SearchPaginationContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.common.search.SearchSortItem.serializeBinaryToWriter
    );
  }
  f = message.getTake();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSkip();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated SearchSortItem sort = 1;
 * @return {!Array<!proto.loupe.common.search.SearchSortItem>}
 */
proto.loupe.common.search.SearchPaginationContainer.prototype.getSortList = function() {
  return /** @type{!Array<!proto.loupe.common.search.SearchSortItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.search.SearchSortItem, 1));
};


/**
 * @param {!Array<!proto.loupe.common.search.SearchSortItem>} value
 * @return {!proto.loupe.common.search.SearchPaginationContainer} returns this
*/
proto.loupe.common.search.SearchPaginationContainer.prototype.setSortList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.search.SearchSortItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.search.SearchSortItem}
 */
proto.loupe.common.search.SearchPaginationContainer.prototype.addSort = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.search.SearchSortItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.search.SearchPaginationContainer} returns this
 */
proto.loupe.common.search.SearchPaginationContainer.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int64 take = 2;
 * @return {number}
 */
proto.loupe.common.search.SearchPaginationContainer.prototype.getTake = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.search.SearchPaginationContainer} returns this
 */
proto.loupe.common.search.SearchPaginationContainer.prototype.setTake = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 skip = 3;
 * @return {number}
 */
proto.loupe.common.search.SearchPaginationContainer.prototype.getSkip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.search.SearchPaginationContainer} returns this
 */
proto.loupe.common.search.SearchPaginationContainer.prototype.setSkip = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.search.SearchSortItem.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.search.SearchSortItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.search.SearchSortItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.SearchSortItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && src_common_search_item_item_pb.SearchItemFieldActual.toObject(includeInstance, f),
    desc: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.search.SearchSortItem}
 */
proto.loupe.common.search.SearchSortItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.search.SearchSortItem;
  return proto.loupe.common.search.SearchSortItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.search.SearchSortItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.search.SearchSortItem}
 */
proto.loupe.common.search.SearchSortItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_search_item_item_pb.SearchItemFieldActual;
      reader.readMessage(value,src_common_search_item_item_pb.SearchItemFieldActual.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDesc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.search.SearchSortItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.search.SearchSortItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.search.SearchSortItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.search.SearchSortItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_search_item_item_pb.SearchItemFieldActual.serializeBinaryToWriter
    );
  }
  f = message.getDesc();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional item.SearchItemFieldActual item = 1;
 * @return {?proto.loupe.common.search.item.SearchItemFieldActual}
 */
proto.loupe.common.search.SearchSortItem.prototype.getItem = function() {
  return /** @type{?proto.loupe.common.search.item.SearchItemFieldActual} */ (
    jspb.Message.getWrapperField(this, src_common_search_item_item_pb.SearchItemFieldActual, 1));
};


/**
 * @param {?proto.loupe.common.search.item.SearchItemFieldActual|undefined} value
 * @return {!proto.loupe.common.search.SearchSortItem} returns this
*/
proto.loupe.common.search.SearchSortItem.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.search.SearchSortItem} returns this
 */
proto.loupe.common.search.SearchSortItem.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.search.SearchSortItem.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool desc = 2;
 * @return {boolean}
 */
proto.loupe.common.search.SearchSortItem.prototype.getDesc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.search.SearchSortItem} returns this
 */
proto.loupe.common.search.SearchSortItem.prototype.setDesc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


goog.object.extend(exports, proto.loupe.common.search);
