// source: src/common/notes/notes.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_person_person_pb = require('../../../src/common/person/person_pb.js');
goog.object.extend(proto, src_common_person_person_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.loupe.common.notes.Attachment', null, global);
goog.exportSymbol('proto.loupe.common.notes.Mention', null, global);
goog.exportSymbol('proto.loupe.common.notes.Note', null, global);
goog.exportSymbol('proto.loupe.common.notes.NoteStatus', null, global);
goog.exportSymbol('proto.loupe.common.notes.NoteVisibility', null, global);
goog.exportSymbol('proto.loupe.common.notes.ResourceType', null, global);
goog.exportSymbol('proto.loupe.common.notes.ShareItem', null, global);
goog.exportSymbol('proto.loupe.common.notes.ShareItemType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.notes.Note = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.notes.Note.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.notes.Note, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.notes.Note.displayName = 'proto.loupe.common.notes.Note';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.notes.Attachment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.notes.Attachment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.notes.Attachment.displayName = 'proto.loupe.common.notes.Attachment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.notes.Mention = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.notes.Mention, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.notes.Mention.displayName = 'proto.loupe.common.notes.Mention';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.notes.ShareItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.notes.ShareItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.notes.ShareItem.displayName = 'proto.loupe.common.notes.ShareItem';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.notes.Note.repeatedFields_ = [5,6,7,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.notes.Note.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.notes.Note.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.notes.Note} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.notes.Note.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    raw: jspb.Message.getFieldWithDefault(msg, 4, ""),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    proto.loupe.common.notes.Attachment.toObject, includeInstance),
    mentionsList: jspb.Message.toObjectList(msg.getMentionsList(),
    proto.loupe.common.notes.Mention.toObject, includeInstance),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    owner: (f = msg.getOwner()) && src_common_person_person_pb.Person.toObject(includeInstance, f),
    visibility: jspb.Message.getFieldWithDefault(msg, 11, 0),
    shareListList: jspb.Message.toObjectList(msg.getShareListList(),
    proto.loupe.common.notes.ShareItem.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.notes.Note}
 */
proto.loupe.common.notes.Note.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.notes.Note;
  return proto.loupe.common.notes.Note.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.notes.Note} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.notes.Note}
 */
proto.loupe.common.notes.Note.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRaw(value);
      break;
    case 5:
      var value = new proto.loupe.common.notes.Attachment;
      reader.readMessage(value,proto.loupe.common.notes.Attachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    case 6:
      var value = new proto.loupe.common.notes.Mention;
      reader.readMessage(value,proto.loupe.common.notes.Mention.deserializeBinaryFromReader);
      msg.addMentions(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 8:
      var value = /** @type {!proto.loupe.common.notes.NoteStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 10:
      var value = new src_common_person_person_pb.Person;
      reader.readMessage(value,src_common_person_person_pb.Person.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 11:
      var value = /** @type {!proto.loupe.common.notes.NoteVisibility} */ (reader.readEnum());
      msg.setVisibility(value);
      break;
    case 12:
      var value = new proto.loupe.common.notes.ShareItem;
      reader.readMessage(value,proto.loupe.common.notes.ShareItem.deserializeBinaryFromReader);
      msg.addShareList(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.notes.Note.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.notes.Note.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.notes.Note} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.notes.Note.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRaw();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.loupe.common.notes.Attachment.serializeBinaryToWriter
    );
  }
  f = message.getMentionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.loupe.common.notes.Mention.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      src_common_person_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getVisibility();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getShareListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.loupe.common.notes.ShareItem.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.notes.Note.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.common.notes.Note.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.loupe.common.notes.Note.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string raw = 4;
 * @return {string}
 */
proto.loupe.common.notes.Note.prototype.getRaw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.setRaw = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Attachment attachments = 5;
 * @return {!Array<!proto.loupe.common.notes.Attachment>}
 */
proto.loupe.common.notes.Note.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.loupe.common.notes.Attachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.notes.Attachment, 5));
};


/**
 * @param {!Array<!proto.loupe.common.notes.Attachment>} value
 * @return {!proto.loupe.common.notes.Note} returns this
*/
proto.loupe.common.notes.Note.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.loupe.common.notes.Attachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.notes.Attachment}
 */
proto.loupe.common.notes.Note.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.loupe.common.notes.Attachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};


/**
 * repeated Mention mentions = 6;
 * @return {!Array<!proto.loupe.common.notes.Mention>}
 */
proto.loupe.common.notes.Note.prototype.getMentionsList = function() {
  return /** @type{!Array<!proto.loupe.common.notes.Mention>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.notes.Mention, 6));
};


/**
 * @param {!Array<!proto.loupe.common.notes.Mention>} value
 * @return {!proto.loupe.common.notes.Note} returns this
*/
proto.loupe.common.notes.Note.prototype.setMentionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.common.notes.Mention=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.notes.Mention}
 */
proto.loupe.common.notes.Note.prototype.addMentions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.common.notes.Mention, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.clearMentionsList = function() {
  return this.setMentionsList([]);
};


/**
 * repeated string tags = 7;
 * @return {!Array<string>}
 */
proto.loupe.common.notes.Note.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional NoteStatus status = 8;
 * @return {!proto.loupe.common.notes.NoteStatus}
 */
proto.loupe.common.notes.Note.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.notes.NoteStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.loupe.common.notes.NoteStatus} value
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string owner_id = 9;
 * @return {string}
 */
proto.loupe.common.notes.Note.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional loupe.common.person.Person owner = 10;
 * @return {?proto.loupe.common.person.Person}
 */
proto.loupe.common.notes.Note.prototype.getOwner = function() {
  return /** @type{?proto.loupe.common.person.Person} */ (
    jspb.Message.getWrapperField(this, src_common_person_person_pb.Person, 10));
};


/**
 * @param {?proto.loupe.common.person.Person|undefined} value
 * @return {!proto.loupe.common.notes.Note} returns this
*/
proto.loupe.common.notes.Note.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.notes.Note.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NoteVisibility visibility = 11;
 * @return {!proto.loupe.common.notes.NoteVisibility}
 */
proto.loupe.common.notes.Note.prototype.getVisibility = function() {
  return /** @type {!proto.loupe.common.notes.NoteVisibility} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.loupe.common.notes.NoteVisibility} value
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.setVisibility = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * repeated ShareItem share_list = 12;
 * @return {!Array<!proto.loupe.common.notes.ShareItem>}
 */
proto.loupe.common.notes.Note.prototype.getShareListList = function() {
  return /** @type{!Array<!proto.loupe.common.notes.ShareItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.notes.ShareItem, 12));
};


/**
 * @param {!Array<!proto.loupe.common.notes.ShareItem>} value
 * @return {!proto.loupe.common.notes.Note} returns this
*/
proto.loupe.common.notes.Note.prototype.setShareListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.loupe.common.notes.ShareItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.notes.ShareItem}
 */
proto.loupe.common.notes.Note.prototype.addShareList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.loupe.common.notes.ShareItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.clearShareListList = function() {
  return this.setShareListList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.notes.Note.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.notes.Note} returns this
*/
proto.loupe.common.notes.Note.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.notes.Note.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.notes.Note.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.notes.Note} returns this
*/
proto.loupe.common.notes.Note.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.notes.Note} returns this
 */
proto.loupe.common.notes.Note.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.notes.Note.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.notes.Attachment.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.notes.Attachment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.notes.Attachment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.notes.Attachment.toObject = function(includeInstance, msg) {
  var f, obj = {
    noteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    resourceRaw: msg.getResourceRaw_asB64(),
    label: jspb.Message.getFieldWithDefault(msg, 5, ""),
    link: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.notes.Attachment}
 */
proto.loupe.common.notes.Attachment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.notes.Attachment;
  return proto.loupe.common.notes.Attachment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.notes.Attachment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.notes.Attachment}
 */
proto.loupe.common.notes.Attachment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceId(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.notes.ResourceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setResourceRaw(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.notes.Attachment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.notes.Attachment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.notes.Attachment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.notes.Attachment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNoteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getResourceRaw_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string note_id = 1;
 * @return {string}
 */
proto.loupe.common.notes.Attachment.prototype.getNoteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Attachment} returns this
 */
proto.loupe.common.notes.Attachment.prototype.setNoteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string resource_id = 2;
 * @return {string}
 */
proto.loupe.common.notes.Attachment.prototype.getResourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Attachment} returns this
 */
proto.loupe.common.notes.Attachment.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ResourceType type = 3;
 * @return {!proto.loupe.common.notes.ResourceType}
 */
proto.loupe.common.notes.Attachment.prototype.getType = function() {
  return /** @type {!proto.loupe.common.notes.ResourceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.notes.ResourceType} value
 * @return {!proto.loupe.common.notes.Attachment} returns this
 */
proto.loupe.common.notes.Attachment.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bytes resource_raw = 4;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.common.notes.Attachment.prototype.getResourceRaw = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes resource_raw = 4;
 * This is a type-conversion wrapper around `getResourceRaw()`
 * @return {string}
 */
proto.loupe.common.notes.Attachment.prototype.getResourceRaw_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getResourceRaw()));
};


/**
 * optional bytes resource_raw = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getResourceRaw()`
 * @return {!Uint8Array}
 */
proto.loupe.common.notes.Attachment.prototype.getResourceRaw_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getResourceRaw()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.common.notes.Attachment} returns this
 */
proto.loupe.common.notes.Attachment.prototype.setResourceRaw = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional string label = 5;
 * @return {string}
 */
proto.loupe.common.notes.Attachment.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Attachment} returns this
 */
proto.loupe.common.notes.Attachment.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string link = 6;
 * @return {string}
 */
proto.loupe.common.notes.Attachment.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Attachment} returns this
 */
proto.loupe.common.notes.Attachment.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.notes.Attachment.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.notes.Attachment} returns this
*/
proto.loupe.common.notes.Attachment.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.notes.Attachment} returns this
 */
proto.loupe.common.notes.Attachment.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.notes.Attachment.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.notes.Attachment.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.notes.Attachment} returns this
*/
proto.loupe.common.notes.Attachment.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.notes.Attachment} returns this
 */
proto.loupe.common.notes.Attachment.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.notes.Attachment.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.notes.Mention.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.notes.Mention.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.notes.Mention} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.notes.Mention.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    noteId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resourceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    resourceRaw: msg.getResourceRaw_asB64(),
    label: jspb.Message.getFieldWithDefault(msg, 6, ""),
    link: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.notes.Mention}
 */
proto.loupe.common.notes.Mention.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.notes.Mention;
  return proto.loupe.common.notes.Mention.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.notes.Mention} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.notes.Mention}
 */
proto.loupe.common.notes.Mention.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoteId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceId(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.common.notes.ResourceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setResourceRaw(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.notes.Mention.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.notes.Mention.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.notes.Mention} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.notes.Mention.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNoteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResourceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getResourceRaw_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.notes.Mention.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Mention} returns this
 */
proto.loupe.common.notes.Mention.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string note_id = 2;
 * @return {string}
 */
proto.loupe.common.notes.Mention.prototype.getNoteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Mention} returns this
 */
proto.loupe.common.notes.Mention.prototype.setNoteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string resource_id = 3;
 * @return {string}
 */
proto.loupe.common.notes.Mention.prototype.getResourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Mention} returns this
 */
proto.loupe.common.notes.Mention.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ResourceType type = 4;
 * @return {!proto.loupe.common.notes.ResourceType}
 */
proto.loupe.common.notes.Mention.prototype.getType = function() {
  return /** @type {!proto.loupe.common.notes.ResourceType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.common.notes.ResourceType} value
 * @return {!proto.loupe.common.notes.Mention} returns this
 */
proto.loupe.common.notes.Mention.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bytes resource_raw = 5;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.common.notes.Mention.prototype.getResourceRaw = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes resource_raw = 5;
 * This is a type-conversion wrapper around `getResourceRaw()`
 * @return {string}
 */
proto.loupe.common.notes.Mention.prototype.getResourceRaw_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getResourceRaw()));
};


/**
 * optional bytes resource_raw = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getResourceRaw()`
 * @return {!Uint8Array}
 */
proto.loupe.common.notes.Mention.prototype.getResourceRaw_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getResourceRaw()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.common.notes.Mention} returns this
 */
proto.loupe.common.notes.Mention.prototype.setResourceRaw = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional string label = 6;
 * @return {string}
 */
proto.loupe.common.notes.Mention.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Mention} returns this
 */
proto.loupe.common.notes.Mention.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string link = 7;
 * @return {string}
 */
proto.loupe.common.notes.Mention.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.Mention} returns this
 */
proto.loupe.common.notes.Mention.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.notes.Mention.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.notes.Mention} returns this
*/
proto.loupe.common.notes.Mention.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.notes.Mention} returns this
 */
proto.loupe.common.notes.Mention.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.notes.Mention.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.notes.Mention.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.notes.Mention} returns this
*/
proto.loupe.common.notes.Mention.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.notes.Mention} returns this
 */
proto.loupe.common.notes.Mention.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.notes.Mention.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.notes.ShareItem.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.notes.ShareItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.notes.ShareItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.notes.ShareItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    noteId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sharedWithId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.notes.ShareItem}
 */
proto.loupe.common.notes.ShareItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.notes.ShareItem;
  return proto.loupe.common.notes.ShareItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.notes.ShareItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.notes.ShareItem}
 */
proto.loupe.common.notes.ShareItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoteId(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.notes.ShareItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSharedWithId(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.common.notes.NoteStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.notes.ShareItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.notes.ShareItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.notes.ShareItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.notes.ShareItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNoteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSharedWithId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.notes.ShareItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.ShareItem} returns this
 */
proto.loupe.common.notes.ShareItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string note_id = 2;
 * @return {string}
 */
proto.loupe.common.notes.ShareItem.prototype.getNoteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.ShareItem} returns this
 */
proto.loupe.common.notes.ShareItem.prototype.setNoteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ShareItemType type = 3;
 * @return {!proto.loupe.common.notes.ShareItemType}
 */
proto.loupe.common.notes.ShareItem.prototype.getType = function() {
  return /** @type {!proto.loupe.common.notes.ShareItemType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.notes.ShareItemType} value
 * @return {!proto.loupe.common.notes.ShareItem} returns this
 */
proto.loupe.common.notes.ShareItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string shared_with_id = 4;
 * @return {string}
 */
proto.loupe.common.notes.ShareItem.prototype.getSharedWithId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.notes.ShareItem} returns this
 */
proto.loupe.common.notes.ShareItem.prototype.setSharedWithId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional NoteStatus status = 5;
 * @return {!proto.loupe.common.notes.NoteStatus}
 */
proto.loupe.common.notes.ShareItem.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.notes.NoteStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.common.notes.NoteStatus} value
 * @return {!proto.loupe.common.notes.ShareItem} returns this
 */
proto.loupe.common.notes.ShareItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.notes.NoteStatus = {
  DELETED: 0,
  ACTIVE: 1,
  PENDING: 2
};

/**
 * @enum {number}
 */
proto.loupe.common.notes.NoteVisibility = {
  HIDDEN: 0,
  OWNERONLY: 1,
  TEAMONLY: 2,
  SHAREDLIST: 3,
  ALL: 4
};

/**
 * @enum {number}
 */
proto.loupe.common.notes.ResourceType = {
  UNKNOWN: 0,
  PERSON: 1,
  OPPORTUNITY: 2,
  SIGNAL: 3,
  GROUP: 4,
  FORECASTENTRY: 5,
  FORECASTITEM: 6,
  REPORT: 7,
  FILE: 8
};

/**
 * @enum {number}
 */
proto.loupe.common.notes.ShareItemType = {
  NONE: 0,
  USER: 1,
  TEAM: 2
};

goog.object.extend(exports, proto.loupe.common.notes);
