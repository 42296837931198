// source: src/common/search/enum/enum.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.loupe.common.search.enum.SearchCombineAction', null, global);
goog.exportSymbol('proto.loupe.common.search.enum.SearchFieldType', null, global);
/**
 * @enum {number}
 */
proto.loupe.common.search.enum.SearchFieldType = {
  STRING: 0,
  BYTES: 1,
  INTEGER: 2,
  FLOAT: 3,
  BOOLEAN: 4,
  TIMESTAMP: 5,
  RECORD: 6,
  DATE: 7,
  TIME: 8,
  DATETIME: 9,
  NUMERIC: 10,
  GEOGRAPHY: 11,
  REPEATED: 12
};

/**
 * @enum {number}
 */
proto.loupe.common.search.enum.SearchCombineAction = {
  COMPARE: 0,
  AND: 1,
  OR: 2
};

goog.object.extend(exports, proto.loupe.common.search.enum);
