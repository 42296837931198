import React, { useMemo } from 'react'
import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core'
import IndeterminateCheck from '../icons/indeterminateCheck'

const Checkbox = (props) => {
  const {
    checked,
    onChange,
    disabled = false,
    label,
    intermediate = false,
    labelProps = {
      className: ''
    },
  } = props

  const customIcons = useMemo(() => {
    if (intermediate) {
      return {
        checkedIcon: (<IndeterminateCheck />)
      }
    }
    return {}
  }, [intermediate])

  return (
    <FormControlLabel
      disabled={disabled}
      control={(
        <MuiCheckbox
          checked={checked}
          onChange={onChange}
          {...customIcons}
          color="secondary" />
      )}
      label={<span className={labelProps.className}>{label}</span>} />
  )
}

export default Checkbox
