import React from 'react'
import SyncMain from './syncMain'
import { DataSyncStatusProvider } from '../../context/dataSyncStatus'

const Sync = (props) => {
  return (
    <DataSyncStatusProvider>
      <SyncMain />
    </DataSyncStatusProvider>
  )
}

export default Sync
