import { CanonicalObjectFieldTypesProvider } from '../../context/canonicalObjectFieldTypes'
import { CanonicalObjectProvider } from '../../context/canonicalObject'
import { cloneDeep, findIndex, forEach, forOwn, has, keys } from 'lodash'
import { IntegrationObjectPropertiesProvider } from '../../context/integrationObjectProperties'
import { MappingStatus } from '../../grpc/enums'
import { permissionNames } from '../../constants/permissionNames'
import { toUpsertCanonicalObjectMappingRequest } from '../../grpc/converters'
import { useCanonicalObjectMapping } from '../../context/canonicalObjectMapping'
import { useGrpcCallback } from '../../grpc'
import { useHistory, Link } from 'react-router-dom'
import { useJoyride } from '../../context/joyride'
import { useModal } from '../../hooks/useModal'
import { useNotification } from '../../hooks/useNotification'
import { useObjectMappingChanges } from '../../context/objectMappingChanges'
import { usePermissions } from '../../context/permissions'
import { useRoutes } from '../../context/routes'
import { useTextField } from '../../hooks/useTextField'
import { useUserPrefs } from '../../context/userPrefs'
import BlockNavigation from '../common/blockNavigation'
import Button from '../common/button'
import classNames from 'classnames'
import CrmObjectMapper from './crmObjectMapper'
import FieldList from './fieldList'
import Header from '../header/header'
import ManageFieldsModal from './manageFieldsModal'
import MapCrmObjectsModal from './mapCrmObjectsModal'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo } from 'react'
import SearchBox from '../common/searchBox'

const ObjectMappingMain = (props) => {
  const { canonicalObject = {} } = props
  const { label, objectName } = canonicalObject

  const { checkPermission } = usePermissions()
  const { routes } = useRoutes()

  const { enabled: joyrideEnabled, index: joyrideIndex, setJoyride, start: startJoyride, stop: stopJoyride } = useJoyride()
  const { getPref, savePref } = useUserPrefs()

  const history = useHistory()
  const search = useTextField()
  const { notifyError, notifySuccess } = useNotification()

  const { isFetching, canonicalObjectMapping, selectedCrmObjectName, invalidate, setStatusDetailsList } = useCanonicalObjectMapping()
  const { objectMappingChanges, setObjectMappingChanges, writeBackPermissionChanges, setWriteBackPermissionChanges } = useObjectMappingChanges()

  const pauseJoyride = useCallback(() => {
    if (joyrideEnabled) {
      stopJoyride()
    }
  }, [joyrideEnabled, stopJoyride])

  const resumeJoyride = useCallback(() => {
    if (joyrideEnabled) {
      startJoyride()
    }
  }, [joyrideEnabled, startJoyride])

  const handleSavePref = useCallback((pref) => {
    savePref('joyride', pref, true)
  }, [savePref])

  const configureJoyride = useCallback(() => {
    const steps = []

    if (!getPref('joyride', 'hasSeenObjectMapping.objectMapping')) {
      steps.push({
        title: 'Manage object mapping',
        target: '#joyride_objectMapping_0',
        content: 'Use this link to manage which of the CRM objects will map to this Commit object.',
        continuous: false,
        disableBeacon: true,
        onLoad: handleSavePref.bind(this, 'hasSeenObjectMapping.objectMapping'),
        offset: 0,
        placement: 'right',
        spotlightClicks: true,
      })
    }

    if (!getPref('joyride', 'hasSeenObjectMapping.manageFields')) {
      steps.push({
        title: 'Edit and add fields',
        target: '#joyride_objectMapping_1',
        content: 'Use this link to update existing fields and to add custom or additional fields.',
        continuous: false,
        disableBeacon: true,
        onLoad: handleSavePref.bind(this, 'hasSeenObjectMapping.manageFields'),
        offset: 0,
        placement: 'right',
        spotlightClicks: true,
      })
    }

    if (!getPref('joyride', 'hasSeenObjectMapping.modifyCRmField')) {
      steps.push({
        title: 'Modify CRM Field',
        target: '#joyride_objectMapping_2',
        content: 'If needed, you may click here to change which CRM field is mapped to the corresponding Commit field.',
        continuous: false,
        disableBeacon: true,
        onLoad: handleSavePref.bind(this, 'hasSeenObjectMapping.modifyCRmField'),
        offset: 20,
        placement: 'right',
        spotlightClicks: true,
      })
    }

    if (!getPref('joyride', 'hasSeenObjectMapping.saveMapping')) {
      steps.push({
        title: 'Save mapping',
        target: '#joyride_objectMapping_3',
        content: 'Once you’ve taken a look at an object’s field mapping to make sure everything is accurate, complete the mapping by clicking Save. ',
        continuous: false,
        disableBeacon: true,
        onLoad: handleSavePref.bind(this, 'hasSeenObjectMapping.saveMapping'),
        offset: -10,
        placement: 'left',
        spotlightClicks: true,
      })
    }

    if (!getPref('joyride', 'hasSeenObjectMapping.returnToDataSync')) {
      steps.push({
        title: 'Return to the Data Sync page',
        target: '#joyride_objectMapping_4',
        content: 'Once you’ve saved the object mapping, you can review your full object list from the Data Sync page.',
        disableBeacon: true,
        hideProgress: true,
        onLoad: handleSavePref.bind(this, 'hasSeenObjectMapping.returnToDataSync'),
        offset: 0,
        placement: 'bottom',
      })
    }

    setJoyride(steps)
    setTimeout(() => {
      startJoyride()
    }, 0)
  }, [handleSavePref, getPref, setJoyride, startJoyride])

  useEffect(() => {
    if (!isFetching && joyrideEnabled && canonicalObjectMapping?.status === MappingStatus.STATUS_INACTIVE) {
      configureJoyride()
    }
  }, [canonicalObjectMapping?.status, configureJoyride, joyrideEnabled, isFetching])

  const hasChanges = useMemo(() => {
    return keys(objectMappingChanges).length > 0 || keys(writeBackPermissionChanges).length > 0
  }, [objectMappingChanges, writeBackPermissionChanges])

  const crmObjectsModal = useModal()
  const manageFieldsModal = useModal()

  const breadcrumbControl = useMemo(() => {
    return (
      <div className="text-size-12px text-color-a6b2cf font-normal tracking-widest leading-none" style={{ transform: 'translateY(-4px)' }}>
        <Link to={routes.dataSync}>Data Sync</Link>
      </div>
    )
  }, [])

  const onManageCrmObjectsClick = useCallback(() => {
    window.analytics.track('Manage CRM Objects Clicked')
    crmObjectsModal.setOpen(true)
  }, [])

  const onManageFieldsClick = useCallback(() => {
    window.analytics.track('Manage Fields Clicked')
    manageFieldsModal.setOpen(true)
  }, [])

  const upsertCanonicalObjectMapping = useGrpcCallback({
    onError: () => {
      notifyError('Error saving object mapping!')
      resumeJoyride()
    },
    onSuccess: (obj) => {
      const { status, statusDetailsList = [] } = obj
      const count = statusDetailsList.length
      if (status === MappingStatus.STATUS_ERROR) {
        setStatusDetailsList(statusDetailsList)
        notifyError(`${pluralize('field', statusDetailsList.length, true)} require${count === 1 ? 's' : ''} attention!`)
      } else {
        notifySuccess(`${label} mapping has been saved!`)
        invalidate()
        setObjectMappingChanges({})
        setWriteBackPermissionChanges({})
      }
      resumeJoyride()
    },
    grpcMethod: 'upsertCanonicalObjectMapping',
    debug: false,
  }, [label, resumeJoyride, setStatusDetailsList])

  const onSave = useCallback(() => {
    const objectMap = cloneDeep(canonicalObjectMapping)
    const { crmObjectsList = [] } = objectMap
    forEach(crmObjectsList, (crmObject) => {
      const { objectName = '', fieldsList = [] } = crmObject
      if (has(objectMappingChanges, objectName)) {
        forOwn(objectMappingChanges[objectName], (value, key) => {
          const { name = '', format = '', type = '' } = value || {}
          const fieldIndex = findIndex(fieldsList, (f) => f.toName === key)
          if (fieldIndex !== -1) {
            fieldsList[fieldIndex] = {
              ...fieldsList[fieldIndex],
              ...{ fromName: name, fromType: type, format },
            }
          }
        })
      }
      if (has(writeBackPermissionChanges, objectName)) {
        forOwn(writeBackPermissionChanges[objectName], (value, key) => {
          const { canView = true, canEdit = false, canManagerEdit = false } = value || {}
          const fieldIndex = findIndex(fieldsList, (f) => f.toName === key)
          if (fieldIndex !== -1) {
            fieldsList[fieldIndex].permissions = {
              ...fieldsList[fieldIndex].permissions,
              canView,
              canEdit,
              canManagerEdit,
            }
          }
        })
      }
    })
    const request = toUpsertCanonicalObjectMappingRequest({
      objectMap,
    })
    upsertCanonicalObjectMapping(request)

    if (joyrideIndex > 0) {
      pauseJoyride()
    }
  }, [canonicalObjectMapping, joyrideIndex, objectMappingChanges, pauseJoyride, writeBackPermissionChanges, upsertCanonicalObjectMapping])

  return (
    <>
      <div className="flex flex-col w-full h-screen">
        <Header
          title={label}
          showBackButton={true}
          onBackClick={() => history.push(routes.dataSync)}
          breadcrumbControl={breadcrumbControl} />

        <div className="flex-grow overflow-auto">
          <div className="flex flex-col w-full">

            <div className="p-6 bg-color-ffffff border border-color-2e5bff-08 rounded-lg mx-10 mt-10">
              <div className="text-size-24px text-color-09242f font-weight-700">Field Mapping</div>
              <div className="text-size-15px text-color-51636a font-weight-400 leading-tight mt-4">
                Configure this object by selecting the fields from your CRM that should map to the fields in Outreach Commit.
                {' '}
                For some objects, like Activity, you may want to map multiple objects in the CRM to a single object (like Activity).
                {' '}
                In this case, click Manage CRM Objects and choose the additional object(s) you'd like to map.
                {' '}
                When you map multiple objects, Commit will automatically merge records from each CRM object into the single Commit object.
                {' '}
                You can also add additional extension fields onto the object in Commit by clicking Manage Fields and adding a new field to map to a specific fields from the CRM.
              </div>
            </div>

            <div className="flex items-center justify-between mx-10 mt-8 mb-3">
              <div>
                <div className="flex items-center">
                  <button
                    id="joyride_objectMapping_0"
                    onClick={onManageCrmObjectsClick}
                    className="ml-2 mr-2 first:ml-0 text-size-16px text-color-51636a font-weight-400 focus:outline-none">
                    Manage CRM Objects
                  </button>
                  <div className="mx-1 text-size-16px text-color-c9ced0 font-weight-400">|</div>
                  <button
                    id="joyride_objectMapping_1"
                    onClick={onManageFieldsClick}
                    className={classNames('ml-2 mr-2 first:ml-0 text-size-16px text-color-51636a font-weight-400 focus:outline-none')}>
                    Manage Fields
                  </button>
                </div>
              </div>
              <div className="flex items-center">
                <SearchBox
                  className="ml-2"
                  value={search.value}
                  onChange={search.onChange}
                  onClear={search.reset} />
                {checkPermission(permissionNames.CanUpdateSync) && (
                  <Button
                    id="joyride_objectMapping_3"
                    className="ml-2"
                    size="xs"
                    text="Save"
                    onClick={onSave}
                    disabled={isFetching} />
                )}
              </div>
            </div>

            <IntegrationObjectPropertiesProvider objectName={selectedCrmObjectName}>
              <CrmObjectMapper>
                <FieldList search={search} onEnter={pauseJoyride} onExited={resumeJoyride} />
              </CrmObjectMapper>
              <MapCrmObjectsModal modal={crmObjectsModal} TransitionProps={{ onEnter: pauseJoyride, onExited: resumeJoyride }} />
            </IntegrationObjectPropertiesProvider>

          </div>
        </div>
      </div>

      <CanonicalObjectFieldTypesProvider>
        <CanonicalObjectProvider objectName={objectName}>
          <ManageFieldsModal modal={manageFieldsModal} TransitionProps={{ onEnter: pauseJoyride, onExited: resumeJoyride }} />
        </CanonicalObjectProvider>
      </CanonicalObjectFieldTypesProvider>

      <BlockNavigation
        canBlock={hasChanges}
        promptTitle="Warning"
        promptText="Are you sure you want to leave?"
        promptSubText="You have unsaved changes that will be lost if you leave now. This cannot be undone."
        promptActionText="Leave" />
    </>
  )
}

export default ObjectMappingMain
