import React, { useCallback, useMemo, useState } from 'react'
import { defaultGetNodeKey, defaultSearchMethod } from '@nosferatu500/react-sortable-tree'
import { toTreeData } from '../components/tree/toTreeData'
import { getTreeFromFlatData, changeNodeAtPath, getFlatDataFromTree, walk, find } from '../components/tree/utils/tree-data-utils'
import { useGrpcEffect } from '../grpc'
import { toGetGroupSubTreeRequest } from '../grpc/converters'
import { guid } from '../lib/guid'
import { filter } from 'lodash'
import { useAuth } from './auth'
import { useNotification } from '../hooks/useNotification'
import { useGroupsError } from './groupsError'

let expandedNodes = []

const TreeDataContext = React.createContext()

export function TreeDataProvider({ children }) {
  const { tenantId } = useAuth()

  const { notifyError } = useNotification()

  const [dataInitialized, setDataInitialized] = useState(false)
  const [isFetching, setIsFetching] = useState([])
  const [key, setKey] = useState(guid())
  const [treeData, setTreeData] = useState([])

  const { handleFailedPreconditionError } = useGroupsError()

  const setTreeDataInternal = useCallback((newTreeData, useExpandedCache) => {
    if (useExpandedCache) {
      // set expanded state using cache data
      walk({
        treeData: newTreeData,
        getNodeKey: defaultGetNodeKey,
        ignoreCollapsed: false,
        callback: (item) => {
          if (expandedNodes.includes(item.node.group.id)) {
            item.node.expanded = true
          }
        },
      })
      setTreeData(newTreeData)
    } else {
      setTreeData(newTreeData)
    }

    // cache expanded state for all nodes
    const flattened = getFlatDataFromTree({
      treeData: newTreeData,
      getNodeKey: defaultGetNodeKey,
      ignoreCollapsed: false,
    })
    expandedNodes = filter(flattened, (item) => item.node.expanded).map((item) => item.node.group.id)
  }, [])

  const searchInTree = useCallback((s = '', tree) => {
    const search = s.trim().toLowerCase()
    if (!search) {
      return
    }
    const {
      children: cl = [],
      group: g = {}
    } = tree

    const { name: groupName = '' } = g

    const matches = (str, sea) => {
      return str.toLowerCase().includes(sea)
    }

    const cls = cl.map((c) => searchInTree(search, c))
      .filter((c) => c.children.length || c.group?.id)
    const children = cls.filter((c) => {
      const { group: _g, children: _cl } = c
      return (_g?.name || _cl.length) ? searchInTree(search, c) : []
    })
    const groupNameMatch = matches(groupName, search)
    const showGroup = children.length || groupNameMatch
    const group = (showGroup && Object.keys(g).length) ? g : undefined

    return {
      ...tree,
      children,
      group
    }
  }, [])

  const flattened = useMemo(() => {
    return getFlatDataFromTree({
      treeData,
      getNodeKey: defaultGetNodeKey,
      ignoreCollapsed: false,
    })
  }, [treeData])

  const expandGroupById = useCallback(({ treeData: data, node, path, treeIndex }) => {
    node.expanded = true
    const newTreeData = changeNodeAtPath({
      treeData: data,
      newNode: node,
      path,
      getNodeKey: defaultGetNodeKey,
    })
    setTreeDataInternal(newTreeData, false)
  }, [])

  const request = toGetGroupSubTreeRequest({
    tenantId,
    maxDepth: -1,
    hydrateUsers: true,
  })

  useGrpcEffect({
    request,
    onError: (err) => {
      if (handleFailedPreconditionError(err)) {
        return
      }
      notifyError('Error loading groups!')
      setTreeDataInternal([], true)
      setIsFetching(false)
    },
    onSuccess: (data) => {
      setTreeDataInternal(toTreeData(data), true)
      setIsFetching(false)
      setDataInitialized(true)
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getGroupSubTree',
    debug: false,
  }, [tenantId, key])

  const invalidate = useCallback((resetDataInitialized = false) => {
    if (resetDataInitialized) {
      setDataInitialized(false)
    }
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      dataInitialized,
      isFetching,
      flattened,
      treeData,
      setTreeData: setTreeDataInternal,
      key,
      invalidate,
      searchInTree,
      expandGroupById,
    }
  }, [dataInitialized, isFetching, flattened, treeData])

  return <TreeDataContext.Provider value={contextValue}>{children}</TreeDataContext.Provider>
}

export function useTreeData() {
  const context = React.useContext(TreeDataContext)
  if (context === undefined) {
    throw new Error('useTreeData must be used within a TreeDataProvider')
  }
  return context
}
