import React, { useCallback } from 'react'
import { AutoSizer, List } from 'react-virtualized'

const VirtualizedList = (props) => {
  const {
    className,
    deferredMeasurementCache,
    rowHeight,
    rowCount,
    rowRenderer,
    onRef
  } = props

  const setRef = useCallback((r) => {
    onRef?.(r)
  }, [onRef])

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          ref={setRef}
          className={className}
          width={width}
          height={height}
          deferredMeasurementCache={deferredMeasurementCache}
          rowHeight={rowHeight}
          rowCount={rowCount}
          rowRenderer={rowRenderer} />
      )}
    </AutoSizer>
  )
}

export default VirtualizedList
