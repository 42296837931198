import React, { useState, useEffect, useCallback } from 'react'
import Modal from '../common/modal'
import ModalError from '../common/modalError'
import ModalBody from '../common/modalBody'
import ModalFooter from '../common/modalFooter'
import Button from '../common/button'
import { useModal } from '../../hooks/useModal'
import commitLogo from '../../assets/commit-logo.png'
import { toGetLinkedAccountAuthLinkRequest } from '../../grpc/converters'
import { useAuth } from '../../context/auth'
import { useGrpcCallback } from '../../grpc'
import { useNotification } from '../../hooks/useNotification'
import { useTenantInfo } from '../../context/tenantInfo'
import { copyToClipboard } from '../../lib/clipboard'
import { ExportPart } from '../../grpc/enums'
import Refresh from '../icons/refresh'

const SetupMain = () => {
  const { notifyError, notifySuccess } = useNotification()
  const { tenantId } = useAuth()
  const { tenantInfo, crmName } = useTenantInfo()
  const { crmType } = tenantInfo
  const authorizeModal = useModal()

  const [showError, setShowError] = useState(false)
  const [fetching, setFetching] = useState(false)

  const { handleClose } = authorizeModal

  const handleCloseInternal = useCallback(() => {
    setShowError(false)
    authorizeModal.setData(undefined)
    handleClose()
  }, [authorizeModal])

  const getCrmAuthLink = useGrpcCallback({
    onError: () => {
      notifyError(`Error generating ${crmName} auth link!`)
      setFetching(false)
    },
    onSuccess: (obj) => {
      const { authUrl } = obj
      if (authUrl) {
        window.location.href = authUrl
        copyToClipboard(authUrl)
      }
    },
    onFetch: () => setFetching(true),
    grpcMethod: 'getLinkedAccountAuthLink',
    debug: false,
  }, [crmName])

  const onCrmAuthLink = useCallback(() => {
    const request = toGetLinkedAccountAuthLinkRequest({
      tenantId,
      provider: crmType,
    })
    getCrmAuthLink(request)
  }, [getCrmAuthLink, tenantId, crmType])

  return (
    <Modal
      handleClose={handleCloseInternal}
      maxWidth="sm"
      open={true}>

      <ModalBody>
        <div className="flex flex-1 flex-col items-start content-between px-24 py-12">
          <img src={commitLogo} style={{ height: 50 }} className="mb-16 self-center" />
          {showError && <ModalError text={showError} />}
          <h1 className="mb-8">Welcome!</h1>
          <h4 className="font-weight-500 text-size-20px">Let's get started.</h4>
          <p className="text-color-51636a">
            {`The first step to getting up and running with Commit is to connect your CRM.
              From there you will further configure CRM field mappings, then create Teams and Users.
              Connect to Salesforce to initiate this sandbox instance.`}
          </p>
        </div>

      </ModalBody>

      <ModalFooter>
        <Button
          onClick={onCrmAuthLink}
          className="mb-12"
          size="sm"
          text={fetching
            ? (<Refresh fill="#FFFFFF" className="animate-spin" />)
            : `Connect to ${crmName}`} />

      </ModalFooter>

    </Modal>
  )
}

export default SetupMain
