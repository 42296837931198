import React, { useCallback } from 'react'
import { useDataSyncHistory } from '../../context/dataSyncHistory'
import SyncHistoryListItem from './syncHistoryListItem'
import Paging from '../common/paging'
import numeral from 'numeral'

const SyncHistoryList = () => {
  const { dataSyncHistory, page, setPage, pageSize } = useDataSyncHistory()

  const { historyList = [], total } = dataSyncHistory

  const onSetPage = useCallback((currentPage) => {
    setPage(currentPage)
  }, [])

  return (
    <>
      <div className="flex-table">
        <div className="thead">
          <div className="tr">
            <div className="td w-48">Date / Time</div>
            <div className="td w-10">Status</div>
          </div>
        </div>
        <div className="tbody">
          {historyList.map((syncHistory, i) => (
            <SyncHistoryListItem
              key={`SyncHistoryListItem-${syncHistory.id}-${i}`}
              syncHistory={syncHistory} />
          ))}
        </div>
      </div>
      <Paging
        className="flex w-full items-center justify-between py-3"
        textClassName="text-color-151d49 text-size-15px font-weight-600"
        navigationClassName="flex items-center"
        total={total}
        limit={pageSize}
        page={page}
        onSetPage={onSetPage}
        textFormatter={(start, end, totalPages) => `${numeral(start).format('0,0')} - ${numeral(end).format('0,0')} of ${numeral(totalPages).format('0,0')}`} />
    </>
  )
}

export default SyncHistoryList
