// source: src/services/api/api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var src_common_quota_quota_pb = require('../../../src/common/quota/quota_pb.js');
goog.object.extend(proto, src_common_quota_quota_pb);
var src_common_actor_pb = require('../../../src/common/actor_pb.js');
goog.object.extend(proto, src_common_actor_pb);
var src_common_empty_message_pb = require('../../../src/common/empty_message_pb.js');
goog.object.extend(proto, src_common_empty_message_pb);
var src_common_data_request_pb = require('../../../src/common/data_request_pb.js');
goog.object.extend(proto, src_common_data_request_pb);
var src_common_person_people_list_pb = require('../../../src/common/person/people_list_pb.js');
goog.object.extend(proto, src_common_person_people_list_pb);
var src_common_person_person_pb = require('../../../src/common/person/person_pb.js');
goog.object.extend(proto, src_common_person_person_pb);
var src_common_tenant_tenant_pb = require('../../../src/common/tenant/tenant_pb.js');
goog.object.extend(proto, src_common_tenant_tenant_pb);
var src_common_organization_organization_pb = require('../../../src/common/organization/organization_pb.js');
goog.object.extend(proto, src_common_organization_organization_pb);
var src_common_opportunity_opportunity_list_pb = require('../../../src/common/opportunity/opportunity_list_pb.js');
goog.object.extend(proto, src_common_opportunity_opportunity_list_pb);
var src_common_opportunity_oppsetting_oppsetting_pb = require('../../../src/common/opportunity/oppsetting/oppsetting_pb.js');
goog.object.extend(proto, src_common_opportunity_oppsetting_oppsetting_pb);
var src_common_insight_insights_list_pb = require('../../../src/common/insight/insights_list_pb.js');
goog.object.extend(proto, src_common_insight_insights_list_pb);
var src_common_pipeline_pipeline_data_container_pb = require('../../../src/common/pipeline/pipeline_data_container_pb.js');
goog.object.extend(proto, src_common_pipeline_pipeline_data_container_pb);
var src_common_notification_notification_pb = require('../../../src/common/notification/notification_pb.js');
goog.object.extend(proto, src_common_notification_notification_pb);
var src_services_setting_setting_pb = require('../../../src/services/setting/setting_pb.js');
goog.object.extend(proto, src_services_setting_setting_pb);
var src_common_setting_settings_list_pb = require('../../../src/common/setting/settings_list_pb.js');
goog.object.extend(proto, src_common_setting_settings_list_pb);
var src_common_signal_signal_pb = require('../../../src/common/signal/signal_pb.js');
goog.object.extend(proto, src_common_signal_signal_pb);
var src_services_forecast_v2_forecast_pb = require('../../../src/services/forecast/v2/forecast_pb.js');
goog.object.extend(proto, src_services_forecast_v2_forecast_pb);
var src_common_forecast_v2_forecast_pb = require('../../../src/common/forecast/v2/forecast_pb.js');
goog.object.extend(proto, src_common_forecast_v2_forecast_pb);
var src_services_forecast_v3_forecast_pb = require('../../../src/services/forecast/v3/forecast_pb.js');
goog.object.extend(proto, src_services_forecast_v3_forecast_pb);
var src_common_forecast_v3_forecast_pb = require('../../../src/common/forecast/v3/forecast_pb.js');
goog.object.extend(proto, src_common_forecast_v3_forecast_pb);
var src_common_nullable_nullable_pb = require('../../../src/common/nullable/nullable_pb.js');
goog.object.extend(proto, src_common_nullable_nullable_pb);
var src_common_reporting_pipeline_report_pb = require('../../../src/common/reporting/pipeline_report_pb.js');
goog.object.extend(proto, src_common_reporting_pipeline_report_pb);
var src_services_reporting_reporting_pb = require('../../../src/services/reporting/reporting_pb.js');
goog.object.extend(proto, src_services_reporting_reporting_pb);
var src_services_tenant_tenant_pb = require('../../../src/services/tenant/tenant_pb.js');
goog.object.extend(proto, src_services_tenant_tenant_pb);
var src_services_mapping_mapping_pb = require('../../../src/services/mapping/mapping_pb.js');
goog.object.extend(proto, src_services_mapping_mapping_pb);
var src_services_crm$data$access_crm$data$access_pb = require('../../../src/services/crm-data-access/crm-data-access_pb.js');
goog.object.extend(proto, src_services_crm$data$access_crm$data$access_pb);
var src_services_data$resolver_data$resolver_pb = require('../../../src/services/data-resolver/data-resolver_pb.js');
goog.object.extend(proto, src_services_data$resolver_data$resolver_pb);
var src_services_orchard_orchard_pb = require('../../../src/services/orchard/orchard_pb.js');
goog.object.extend(proto, src_services_orchard_orchard_pb);
var src_services_liftoff_liftoff_pb = require('../../../src/services/liftoff/liftoff_pb.js');
goog.object.extend(proto, src_services_liftoff_liftoff_pb);
var src_common_orchard_group_pb = require('../../../src/common/orchard/group_pb.js');
goog.object.extend(proto, src_common_orchard_group_pb);
var src_common_orchard_person_pb = require('../../../src/common/orchard/person_pb.js');
goog.object.extend(proto, src_common_orchard_person_pb);
var src_common_orchard_system_role_pb = require('../../../src/common/orchard/system_role_pb.js');
goog.object.extend(proto, src_common_orchard_system_role_pb);
var src_common_orchard_crm_role_pb = require('../../../src/common/orchard/crm_role_pb.js');
goog.object.extend(proto, src_common_orchard_crm_role_pb);
var src_services_notes_notes_pb = require('../../../src/services/notes/notes_pb.js');
goog.object.extend(proto, src_services_notes_notes_pb);
var src_services_activity_activity_pb = require('../../../src/services/activity/activity_pb.js');
goog.object.extend(proto, src_services_activity_activity_pb);
var src_services_bouncer_bouncer_pb = require('../../../src/services/bouncer/bouncer_pb.js');
goog.object.extend(proto, src_services_bouncer_bouncer_pb);
var src_common_notes_notes_pb = require('../../../src/common/notes/notes_pb.js');
goog.object.extend(proto, src_common_notes_notes_pb);
var src_common_integration_object_fieldsetting_fieldsetting_pb = require('../../../src/common/integration/object/fieldsetting/fieldsetting_pb.js');
goog.object.extend(proto, src_common_integration_object_fieldsetting_fieldsetting_pb);
var src_services_integration$invoker_integration$invoker_pb = require('../../../src/services/integration-invoker/integration-invoker_pb.js');
goog.object.extend(proto, src_services_integration$invoker_integration$invoker_pb);
var src_common_integration_type_type_pb = require('../../../src/common/integration/type/type_pb.js');
goog.object.extend(proto, src_common_integration_type_type_pb);
var src_common_search_search_pb = require('../../../src/common/search/search_pb.js');
goog.object.extend(proto, src_common_search_search_pb);
var src_common_search_item_item_pb = require('../../../src/common/search/item/item_pb.js');
goog.object.extend(proto, src_common_search_item_item_pb);
var src_common_gml_gml_pb = require('../../../src/common/gml/gml_pb.js');
goog.object.extend(proto, src_common_gml_gml_pb);
var src_common_filter_filter_pb = require('../../../src/common/filter/filter_pb.js');
goog.object.extend(proto, src_common_filter_filter_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var src_services_goal$manager_goal$manager_pb = require('../../../src/services/goal-manager/goal-manager_pb.js');
goog.object.extend(proto, src_services_goal$manager_goal$manager_pb);
var src_common_auth_auth_pb = require('../../../src/common/auth/auth_pb.js');
goog.object.extend(proto, src_common_auth_auth_pb);
var src_common_setting_app_feature_hierarchy_pb = require('../../../src/common/setting/app/feature/hierarchy_pb.js');
goog.object.extend(proto, src_common_setting_app_feature_hierarchy_pb);
var src_common_setting_app_feature_outreach_settings_pb = require('../../../src/common/setting/app/feature/outreach_settings_pb.js');
goog.object.extend(proto, src_common_setting_app_feature_outreach_settings_pb);
var src_common_setting_app_feature_pipeline_pb = require('../../../src/common/setting/app/feature/pipeline_pb.js');
goog.object.extend(proto, src_common_setting_app_feature_pipeline_pb);
var src_common_setting_app_feature_reporting_pb = require('../../../src/common/setting/app/feature/reporting_pb.js');
goog.object.extend(proto, src_common_setting_app_feature_reporting_pb);
var src_common_setting_app_feature_reporting_options_pb = require('../../../src/common/setting/app/feature/reporting_options_pb.js');
goog.object.extend(proto, src_common_setting_app_feature_reporting_options_pb);
var src_common_setting_app_feature_scenario_planner_pb = require('../../../src/common/setting/app/feature/scenario_planner_pb.js');
goog.object.extend(proto, src_common_setting_app_feature_scenario_planner_pb);
var src_common_setting_app_feature_forecasting_forecasting_pb = require('../../../src/common/setting/app/feature/forecasting/forecasting_pb.js');
goog.object.extend(proto, src_common_setting_app_feature_forecasting_forecasting_pb);
var src_common_canvas_canvas_pb = require('../../../src/common/canvas/canvas_pb.js');
goog.object.extend(proto, src_common_canvas_canvas_pb);
var src_common_realtime_query_pb = require('../../../src/common/realtime/query_pb.js');
goog.object.extend(proto, src_common_realtime_query_pb);
var src_common_canvas_canvas_tile_pb = require('../../../src/common/canvas/canvas_tile_pb.js');
goog.object.extend(proto, src_common_canvas_canvas_tile_pb);
var src_services_mosaic_mosaic_pb = require('../../../src/services/mosaic/mosaic_pb.js');
goog.object.extend(proto, src_services_mosaic_mosaic_pb);
var src_services_hotspring_hotspring_pb = require('../../../src/services/hotspring/hotspring_pb.js');
goog.object.extend(proto, src_services_hotspring_hotspring_pb);
var src_services_snoop_snoop_pb = require('../../../src/services/snoop/snoop_pb.js');
goog.object.extend(proto, src_services_snoop_snoop_pb);
var src_services_tightbeam_global_tightbeam_global_pb = require('../../../src/services/tightbeam_global/tightbeam_global_pb.js');
goog.object.extend(proto, src_services_tightbeam_global_tightbeam_global_pb);
var src_services_dscds_cc_cc_pb = require('../../../src/services/dscds/cc/cc_pb.js');
goog.object.extend(proto, src_services_dscds_cc_cc_pb);
goog.exportSymbol('proto.loupe.services.api.CheckPermissionsRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.CheckPermissionsResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.CommitChange', null, global);
goog.exportSymbol('proto.loupe.services.api.CommitField', null, global);
goog.exportSymbol('proto.loupe.services.api.CommitOpportunity', null, global);
goog.exportSymbol('proto.loupe.services.api.CreatePersonWithGroupViewerRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.CreatePersonWithGroupViewerResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.DeleteSandboxRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.DeleteSandboxResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.DeliverNoteRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.ExportSearchResultsFields', null, global);
goog.exportSymbol('proto.loupe.services.api.ExportSearchResultsRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.ExportSearchResultsResult', null, global);
goog.exportSymbol('proto.loupe.services.api.GetChurnAndContractionRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetChurnAndContractionResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetDataSyncIntervalRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetDataSyncIntervalResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetForecastRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetForecastingSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetForecastingSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetGroupSyncStateRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetGroupSyncStateResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetHierarchySettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetHierarchySettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetOpportunityChangeHistoryRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetOpportunityChangeHistoryResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetOutreachSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetOutreachSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetPipelineReportOptionsRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetPipelineSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetPipelineSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetReportingOptionsSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetReportingOptionsSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetReportingSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetReportingSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetSandboxRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetSandboxResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetSavedFiltersByKeyRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetScenarioPlannerSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetScenarioPlannerSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetSearchItemByIDRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetTenantIntegrationsResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetTenantLicenseInfoRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetTenantLicenseInfoResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetTenantOrganizationRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetTenantOrganizationResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetUserHasActivitySyncedRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetUserHasActivitySyncedResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetUserLastReportInputRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetWeightedForecastAmountsRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetWeightedForecastAmountsResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.GetWritebackSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.GetWritebackSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.IndividualFeaturePermissionCheck', null, global);
goog.exportSymbol('proto.loupe.services.api.IntegrationObjectType', null, global);
goog.exportSymbol('proto.loupe.services.api.ListIntegrationObjectPropertiesRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.ListIntegrationObjectPropertiesResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.ListIntegrationObjectTypesRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.ListIntegrationObjectTypesResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.ListSandboxesRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.ListSandboxesResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.NlgTestRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.NlgTestResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair', null, global);
goog.exportSymbol('proto.loupe.services.api.PassportFeatureResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.PatchSearchItemErrType', null, global);
goog.exportSymbol('proto.loupe.services.api.PatchSearchItemRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.PatchSearchItemResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.PatchSearchItemStatus', null, global);
goog.exportSymbol('proto.loupe.services.api.PermissionCheckObject', null, global);
goog.exportSymbol('proto.loupe.services.api.RawForecastCategoriesForUser', null, global);
goog.exportSymbol('proto.loupe.services.api.RealtimeQueryRequestContainer', null, global);
goog.exportSymbol('proto.loupe.services.api.RealtimeQueryResponseContainer', null, global);
goog.exportSymbol('proto.loupe.services.api.RealtimeQueryStatus', null, global);
goog.exportSymbol('proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer', null, global);
goog.exportSymbol('proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult', null, global);
goog.exportSymbol('proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.RunRealtimeQueriesRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.RunRealtimeQueriesResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.SearchRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.SetDataSyncIntervalRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.SetDataSyncIntervalResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.SignalDataRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.SimpleIntegrationObjectProperty', null, global);
goog.exportSymbol('proto.loupe.services.api.TestSearchQueryRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.TestSearchQueryResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.UpdatePersonWithGroupViewerRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.UpdatePersonWithGroupViewerResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.UpdateSandboxRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.UpdateSandboxResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertForecastingSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertForecastingSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertHierarchySettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertHierarchySettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertOutreachSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertOutreachSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertPipelineSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertPipelineSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertReportingOptionsSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertReportingOptionsSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertReportingSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertReportingSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertSavedFiltersByKeyRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertScenarioPlannerSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertScenarioPlannerSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertUserQuotasRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertUserQuotasResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertWritebackSettingRequest', null, global);
goog.exportSymbol('proto.loupe.services.api.UpsertWritebackSettingResponse', null, global);
goog.exportSymbol('proto.loupe.services.api.WeightedForecastAmount', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetTenantOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetTenantOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetTenantOrganizationRequest.displayName = 'proto.loupe.services.api.GetTenantOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetTenantOrganizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetTenantOrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetTenantOrganizationResponse.displayName = 'proto.loupe.services.api.GetTenantOrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.SignalDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.SignalDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.SignalDataRequest.displayName = 'proto.loupe.services.api.SignalDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertUserQuotasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.UpsertUserQuotasRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.UpsertUserQuotasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertUserQuotasRequest.displayName = 'proto.loupe.services.api.UpsertUserQuotasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertUserQuotasResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertUserQuotasResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertUserQuotasResponse.displayName = 'proto.loupe.services.api.UpsertUserQuotasResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetForecastRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetForecastRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetForecastRequest.displayName = 'proto.loupe.services.api.GetForecastRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetPipelineReportOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetPipelineReportOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetPipelineReportOptionsRequest.displayName = 'proto.loupe.services.api.GetPipelineReportOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetUserLastReportInputRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetUserLastReportInputRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetUserLastReportInputRequest.displayName = 'proto.loupe.services.api.GetUserLastReportInputRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.displayName = 'proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.displayName = 'proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.RawForecastCategoriesForUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.RawForecastCategoriesForUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.RawForecastCategoriesForUser.displayName = 'proto.loupe.services.api.RawForecastCategoriesForUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.NlgTestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.NlgTestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.NlgTestRequest.displayName = 'proto.loupe.services.api.NlgTestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.NlgTestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.NlgTestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.NlgTestResponse.displayName = 'proto.loupe.services.api.NlgTestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.DeliverNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.DeliverNoteRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.DeliverNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.DeliverNoteRequest.displayName = 'proto.loupe.services.api.DeliverNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.PassportFeatureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.PassportFeatureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.PassportFeatureResponse.displayName = 'proto.loupe.services.api.PassportFeatureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.displayName = 'proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.displayName = 'proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetTenantIntegrationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetTenantIntegrationsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetTenantIntegrationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetTenantIntegrationsResponse.displayName = 'proto.loupe.services.api.GetTenantIntegrationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.SearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.SearchRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.SearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.SearchRequest.displayName = 'proto.loupe.services.api.SearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetSearchItemByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetSearchItemByIDRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetSearchItemByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetSearchItemByIDRequest.displayName = 'proto.loupe.services.api.GetSearchItemByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.PatchSearchItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.PatchSearchItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.PatchSearchItemResponse.displayName = 'proto.loupe.services.api.PatchSearchItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.PatchSearchItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.PatchSearchItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.PatchSearchItemRequest.displayName = 'proto.loupe.services.api.PatchSearchItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.TestSearchQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.TestSearchQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.TestSearchQueryRequest.displayName = 'proto.loupe.services.api.TestSearchQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.TestSearchQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.TestSearchQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.TestSearchQueryResponse.displayName = 'proto.loupe.services.api.TestSearchQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetWeightedForecastAmountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetWeightedForecastAmountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetWeightedForecastAmountsRequest.displayName = 'proto.loupe.services.api.GetWeightedForecastAmountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.WeightedForecastAmount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.WeightedForecastAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.WeightedForecastAmount.displayName = 'proto.loupe.services.api.WeightedForecastAmount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetWeightedForecastAmountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetWeightedForecastAmountsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetWeightedForecastAmountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetWeightedForecastAmountsResponse.displayName = 'proto.loupe.services.api.GetWeightedForecastAmountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetSavedFiltersByKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetSavedFiltersByKeyRequest.displayName = 'proto.loupe.services.api.GetSavedFiltersByKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertSavedFiltersByKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.displayName = 'proto.loupe.services.api.UpsertSavedFiltersByKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetUserHasActivitySyncedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetUserHasActivitySyncedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetUserHasActivitySyncedRequest.displayName = 'proto.loupe.services.api.GetUserHasActivitySyncedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetUserHasActivitySyncedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetUserHasActivitySyncedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetUserHasActivitySyncedResponse.displayName = 'proto.loupe.services.api.GetUserHasActivitySyncedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.IndividualFeaturePermissionCheck.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.IndividualFeaturePermissionCheck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.IndividualFeaturePermissionCheck.displayName = 'proto.loupe.services.api.IndividualFeaturePermissionCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.PermissionCheckObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.PermissionCheckObject.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.PermissionCheckObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.PermissionCheckObject.displayName = 'proto.loupe.services.api.PermissionCheckObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.CheckPermissionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.CheckPermissionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.CheckPermissionsRequest.displayName = 'proto.loupe.services.api.CheckPermissionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.CheckPermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.CheckPermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.CheckPermissionsResponse.displayName = 'proto.loupe.services.api.CheckPermissionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetGroupSyncStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetGroupSyncStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetGroupSyncStateRequest.displayName = 'proto.loupe.services.api.GetGroupSyncStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetGroupSyncStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetGroupSyncStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetGroupSyncStateResponse.displayName = 'proto.loupe.services.api.GetGroupSyncStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetDataSyncIntervalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetDataSyncIntervalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetDataSyncIntervalRequest.displayName = 'proto.loupe.services.api.GetDataSyncIntervalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetDataSyncIntervalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetDataSyncIntervalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetDataSyncIntervalResponse.displayName = 'proto.loupe.services.api.GetDataSyncIntervalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.SetDataSyncIntervalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.SetDataSyncIntervalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.SetDataSyncIntervalRequest.displayName = 'proto.loupe.services.api.SetDataSyncIntervalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.SetDataSyncIntervalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.SetDataSyncIntervalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.SetDataSyncIntervalResponse.displayName = 'proto.loupe.services.api.SetDataSyncIntervalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.ListIntegrationObjectPropertiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.displayName = 'proto.loupe.services.api.ListIntegrationObjectPropertiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.ListIntegrationObjectPropertiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.displayName = 'proto.loupe.services.api.ListIntegrationObjectPropertiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.SimpleIntegrationObjectProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.SimpleIntegrationObjectProperty.displayName = 'proto.loupe.services.api.SimpleIntegrationObjectProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ListIntegrationObjectTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.ListIntegrationObjectTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ListIntegrationObjectTypesRequest.displayName = 'proto.loupe.services.api.ListIntegrationObjectTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ListIntegrationObjectTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.ListIntegrationObjectTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.ListIntegrationObjectTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ListIntegrationObjectTypesResponse.displayName = 'proto.loupe.services.api.ListIntegrationObjectTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.IntegrationObjectType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.IntegrationObjectType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.IntegrationObjectType.displayName = 'proto.loupe.services.api.IntegrationObjectType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ExportSearchResultsResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.ExportSearchResultsResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ExportSearchResultsResult.displayName = 'proto.loupe.services.api.ExportSearchResultsResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ExportSearchResultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.ExportSearchResultsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.ExportSearchResultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ExportSearchResultsRequest.displayName = 'proto.loupe.services.api.ExportSearchResultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ExportSearchResultsFields = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.ExportSearchResultsFields, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ExportSearchResultsFields.displayName = 'proto.loupe.services.api.ExportSearchResultsFields';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetPipelineSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetPipelineSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetPipelineSettingRequest.displayName = 'proto.loupe.services.api.GetPipelineSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetPipelineSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetPipelineSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetPipelineSettingResponse.displayName = 'proto.loupe.services.api.GetPipelineSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertPipelineSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertPipelineSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertPipelineSettingRequest.displayName = 'proto.loupe.services.api.UpsertPipelineSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertPipelineSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertPipelineSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertPipelineSettingResponse.displayName = 'proto.loupe.services.api.UpsertPipelineSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetReportingSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetReportingSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetReportingSettingRequest.displayName = 'proto.loupe.services.api.GetReportingSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetReportingSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetReportingSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetReportingSettingResponse.displayName = 'proto.loupe.services.api.GetReportingSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertReportingSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertReportingSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertReportingSettingRequest.displayName = 'proto.loupe.services.api.UpsertReportingSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertReportingSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertReportingSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertReportingSettingResponse.displayName = 'proto.loupe.services.api.UpsertReportingSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetReportingOptionsSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetReportingOptionsSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetReportingOptionsSettingRequest.displayName = 'proto.loupe.services.api.GetReportingOptionsSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetReportingOptionsSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetReportingOptionsSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetReportingOptionsSettingResponse.displayName = 'proto.loupe.services.api.GetReportingOptionsSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertReportingOptionsSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertReportingOptionsSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertReportingOptionsSettingRequest.displayName = 'proto.loupe.services.api.UpsertReportingOptionsSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertReportingOptionsSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertReportingOptionsSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertReportingOptionsSettingResponse.displayName = 'proto.loupe.services.api.UpsertReportingOptionsSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetScenarioPlannerSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetScenarioPlannerSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetScenarioPlannerSettingRequest.displayName = 'proto.loupe.services.api.GetScenarioPlannerSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetScenarioPlannerSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetScenarioPlannerSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetScenarioPlannerSettingResponse.displayName = 'proto.loupe.services.api.GetScenarioPlannerSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertScenarioPlannerSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.displayName = 'proto.loupe.services.api.UpsertScenarioPlannerSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertScenarioPlannerSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.displayName = 'proto.loupe.services.api.UpsertScenarioPlannerSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetForecastingSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetForecastingSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetForecastingSettingRequest.displayName = 'proto.loupe.services.api.GetForecastingSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetForecastingSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetForecastingSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetForecastingSettingResponse.displayName = 'proto.loupe.services.api.GetForecastingSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertForecastingSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertForecastingSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertForecastingSettingRequest.displayName = 'proto.loupe.services.api.UpsertForecastingSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertForecastingSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertForecastingSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertForecastingSettingResponse.displayName = 'proto.loupe.services.api.UpsertForecastingSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpdateSandboxRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpdateSandboxRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpdateSandboxRequest.displayName = 'proto.loupe.services.api.UpdateSandboxRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpdateSandboxResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpdateSandboxResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpdateSandboxResponse.displayName = 'proto.loupe.services.api.UpdateSandboxResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.DeleteSandboxRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.DeleteSandboxRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.DeleteSandboxRequest.displayName = 'proto.loupe.services.api.DeleteSandboxRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.DeleteSandboxResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.DeleteSandboxResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.DeleteSandboxResponse.displayName = 'proto.loupe.services.api.DeleteSandboxResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ListSandboxesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.ListSandboxesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ListSandboxesRequest.displayName = 'proto.loupe.services.api.ListSandboxesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ListSandboxesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.ListSandboxesResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.ListSandboxesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ListSandboxesResponse.displayName = 'proto.loupe.services.api.ListSandboxesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetSandboxRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetSandboxRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetSandboxRequest.displayName = 'proto.loupe.services.api.GetSandboxRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetSandboxResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetSandboxResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetSandboxResponse.displayName = 'proto.loupe.services.api.GetSandboxResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.CreatePersonWithGroupViewerRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.CreatePersonWithGroupViewerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.CreatePersonWithGroupViewerRequest.displayName = 'proto.loupe.services.api.CreatePersonWithGroupViewerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.CreatePersonWithGroupViewerResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.CreatePersonWithGroupViewerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.CreatePersonWithGroupViewerResponse.displayName = 'proto.loupe.services.api.CreatePersonWithGroupViewerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.UpdatePersonWithGroupViewerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.displayName = 'proto.loupe.services.api.UpdatePersonWithGroupViewerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.UpdatePersonWithGroupViewerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.displayName = 'proto.loupe.services.api.UpdatePersonWithGroupViewerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetTenantLicenseInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetTenantLicenseInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetTenantLicenseInfoRequest.displayName = 'proto.loupe.services.api.GetTenantLicenseInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetTenantLicenseInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetTenantLicenseInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetTenantLicenseInfoResponse.displayName = 'proto.loupe.services.api.GetTenantLicenseInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.RunRealtimeQueriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.RunRealtimeQueriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.RunRealtimeQueriesRequest.displayName = 'proto.loupe.services.api.RunRealtimeQueriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.RealtimeQueryRequestContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.RealtimeQueryRequestContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.RealtimeQueryRequestContainer.displayName = 'proto.loupe.services.api.RealtimeQueryRequestContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.RunRealtimeQueriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.RunRealtimeQueriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.RunRealtimeQueriesResponse.displayName = 'proto.loupe.services.api.RunRealtimeQueriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.RealtimeQueryResponseContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.RealtimeQueryResponseContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.RealtimeQueryResponseContainer.displayName = 'proto.loupe.services.api.RealtimeQueryResponseContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.displayName = 'proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.displayName = 'proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.displayName = 'proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.displayName = 'proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.displayName = 'proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.displayName = 'proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.displayName = 'proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.displayName = 'proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.displayName = 'proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetWritebackSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetWritebackSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetWritebackSettingRequest.displayName = 'proto.loupe.services.api.GetWritebackSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetWritebackSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetWritebackSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetWritebackSettingResponse.displayName = 'proto.loupe.services.api.GetWritebackSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertWritebackSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertWritebackSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertWritebackSettingRequest.displayName = 'proto.loupe.services.api.UpsertWritebackSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertWritebackSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertWritebackSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertWritebackSettingResponse.displayName = 'proto.loupe.services.api.UpsertWritebackSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetOutreachSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetOutreachSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetOutreachSettingRequest.displayName = 'proto.loupe.services.api.GetOutreachSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetOutreachSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetOutreachSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetOutreachSettingResponse.displayName = 'proto.loupe.services.api.GetOutreachSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertOutreachSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertOutreachSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertOutreachSettingRequest.displayName = 'proto.loupe.services.api.UpsertOutreachSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertOutreachSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertOutreachSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertOutreachSettingResponse.displayName = 'proto.loupe.services.api.UpsertOutreachSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetHierarchySettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetHierarchySettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetHierarchySettingRequest.displayName = 'proto.loupe.services.api.GetHierarchySettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetHierarchySettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetHierarchySettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetHierarchySettingResponse.displayName = 'proto.loupe.services.api.GetHierarchySettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertHierarchySettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertHierarchySettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertHierarchySettingRequest.displayName = 'proto.loupe.services.api.UpsertHierarchySettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.UpsertHierarchySettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.UpsertHierarchySettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.UpsertHierarchySettingResponse.displayName = 'proto.loupe.services.api.UpsertHierarchySettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetChurnAndContractionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.GetChurnAndContractionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetChurnAndContractionRequest.displayName = 'proto.loupe.services.api.GetChurnAndContractionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetChurnAndContractionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetChurnAndContractionResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetChurnAndContractionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetChurnAndContractionResponse.displayName = 'proto.loupe.services.api.GetChurnAndContractionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetOpportunityChangeHistoryRequest.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetOpportunityChangeHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetOpportunityChangeHistoryRequest.displayName = 'proto.loupe.services.api.GetOpportunityChangeHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.GetOpportunityChangeHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.GetOpportunityChangeHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.GetOpportunityChangeHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.GetOpportunityChangeHistoryResponse.displayName = 'proto.loupe.services.api.GetOpportunityChangeHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.CommitOpportunity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.CommitOpportunity.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.CommitOpportunity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.CommitOpportunity.displayName = 'proto.loupe.services.api.CommitOpportunity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.CommitField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.services.api.CommitField.repeatedFields_, null);
};
goog.inherits(proto.loupe.services.api.CommitField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.CommitField.displayName = 'proto.loupe.services.api.CommitField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.services.api.CommitChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.services.api.CommitChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.services.api.CommitChange.displayName = 'proto.loupe.services.api.CommitChange';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetTenantOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetTenantOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetTenantOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetTenantOrganizationRequest}
 */
proto.loupe.services.api.GetTenantOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetTenantOrganizationRequest;
  return proto.loupe.services.api.GetTenantOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetTenantOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetTenantOrganizationRequest}
 */
proto.loupe.services.api.GetTenantOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetTenantOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetTenantOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetTenantOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetTenantOrganizationRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetTenantOrganizationRequest} returns this
 */
proto.loupe.services.api.GetTenantOrganizationRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetTenantOrganizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetTenantOrganizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetTenantOrganizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantOrganizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && src_common_organization_organization_pb.Organization.toObject(includeInstance, f),
    periodStart: jspb.Message.getFieldWithDefault(msg, 2, ""),
    periodEnd: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetTenantOrganizationResponse}
 */
proto.loupe.services.api.GetTenantOrganizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetTenantOrganizationResponse;
  return proto.loupe.services.api.GetTenantOrganizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetTenantOrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetTenantOrganizationResponse}
 */
proto.loupe.services.api.GetTenantOrganizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_organization_organization_pb.Organization;
      reader.readMessage(value,src_common_organization_organization_pb.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodStart(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetTenantOrganizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetTenantOrganizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetTenantOrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantOrganizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_organization_organization_pb.Organization.serializeBinaryToWriter
    );
  }
  f = message.getPeriodStart();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPeriodEnd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional loupe.common.organization.Organization organization = 1;
 * @return {?proto.loupe.common.organization.Organization}
 */
proto.loupe.services.api.GetTenantOrganizationResponse.prototype.getOrganization = function() {
  return /** @type{?proto.loupe.common.organization.Organization} */ (
    jspb.Message.getWrapperField(this, src_common_organization_organization_pb.Organization, 1));
};


/**
 * @param {?proto.loupe.common.organization.Organization|undefined} value
 * @return {!proto.loupe.services.api.GetTenantOrganizationResponse} returns this
*/
proto.loupe.services.api.GetTenantOrganizationResponse.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetTenantOrganizationResponse} returns this
 */
proto.loupe.services.api.GetTenantOrganizationResponse.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetTenantOrganizationResponse.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string period_start = 2;
 * @return {string}
 */
proto.loupe.services.api.GetTenantOrganizationResponse.prototype.getPeriodStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetTenantOrganizationResponse} returns this
 */
proto.loupe.services.api.GetTenantOrganizationResponse.prototype.setPeriodStart = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string period_end = 3;
 * @return {string}
 */
proto.loupe.services.api.GetTenantOrganizationResponse.prototype.getPeriodEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetTenantOrganizationResponse} returns this
 */
proto.loupe.services.api.GetTenantOrganizationResponse.prototype.setPeriodEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.SignalDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.SignalDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.SignalDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.SignalDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skip: jspb.Message.getFieldWithDefault(msg, 2, 0),
    take: jspb.Message.getFieldWithDefault(msg, 3, 0),
    group: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.SignalDataRequest}
 */
proto.loupe.services.api.SignalDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.SignalDataRequest;
  return proto.loupe.services.api.SignalDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.SignalDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.SignalDataRequest}
 */
proto.loupe.services.api.SignalDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSkip(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTake(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.SignalDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.SignalDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.SignalDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.SignalDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkip();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTake();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGroup();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.api.SignalDataRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.SignalDataRequest} returns this
 */
proto.loupe.services.api.SignalDataRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 skip = 2;
 * @return {number}
 */
proto.loupe.services.api.SignalDataRequest.prototype.getSkip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.SignalDataRequest} returns this
 */
proto.loupe.services.api.SignalDataRequest.prototype.setSkip = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 take = 3;
 * @return {number}
 */
proto.loupe.services.api.SignalDataRequest.prototype.getTake = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.SignalDataRequest} returns this
 */
proto.loupe.services.api.SignalDataRequest.prototype.setTake = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool group = 4;
 * @return {boolean}
 */
proto.loupe.services.api.SignalDataRequest.prototype.getGroup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.api.SignalDataRequest} returns this
 */
proto.loupe.services.api.SignalDataRequest.prototype.setGroup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.UpsertUserQuotasRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertUserQuotasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertUserQuotasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertUserQuotasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertUserQuotasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    quotasList: jspb.Message.toObjectList(msg.getQuotasList(),
    src_common_quota_quota_pb.Quota.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertUserQuotasRequest}
 */
proto.loupe.services.api.UpsertUserQuotasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertUserQuotasRequest;
  return proto.loupe.services.api.UpsertUserQuotasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertUserQuotasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertUserQuotasRequest}
 */
proto.loupe.services.api.UpsertUserQuotasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_quota_quota_pb.Quota;
      reader.readMessage(value,src_common_quota_quota_pb.Quota.deserializeBinaryFromReader);
      msg.addQuotas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertUserQuotasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertUserQuotasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertUserQuotasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertUserQuotasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuotasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_quota_quota_pb.Quota.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.quota.Quota quotas = 1;
 * @return {!Array<!proto.loupe.common.quota.Quota>}
 */
proto.loupe.services.api.UpsertUserQuotasRequest.prototype.getQuotasList = function() {
  return /** @type{!Array<!proto.loupe.common.quota.Quota>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_quota_quota_pb.Quota, 1));
};


/**
 * @param {!Array<!proto.loupe.common.quota.Quota>} value
 * @return {!proto.loupe.services.api.UpsertUserQuotasRequest} returns this
*/
proto.loupe.services.api.UpsertUserQuotasRequest.prototype.setQuotasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.quota.Quota=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.quota.Quota}
 */
proto.loupe.services.api.UpsertUserQuotasRequest.prototype.addQuotas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.quota.Quota, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.UpsertUserQuotasRequest} returns this
 */
proto.loupe.services.api.UpsertUserQuotasRequest.prototype.clearQuotasList = function() {
  return this.setQuotasList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertUserQuotasResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertUserQuotasResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertUserQuotasResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertUserQuotasResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertUserQuotasResponse}
 */
proto.loupe.services.api.UpsertUserQuotasResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertUserQuotasResponse;
  return proto.loupe.services.api.UpsertUserQuotasResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertUserQuotasResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertUserQuotasResponse}
 */
proto.loupe.services.api.UpsertUserQuotasResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertUserQuotasResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertUserQuotasResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertUserQuotasResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertUserQuotasResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetForecastRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetForecastRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetForecastRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetForecastRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    redisScore: jspb.Message.getFieldWithDefault(msg, 1, 0),
    redisRank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentChange: (f = msg.getCurrentChange()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    includePrevious: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetForecastRequest}
 */
proto.loupe.services.api.GetForecastRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetForecastRequest;
  return proto.loupe.services.api.GetForecastRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetForecastRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetForecastRequest}
 */
proto.loupe.services.api.GetForecastRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRedisScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRedisRank(value);
      break;
    case 3:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setCurrentChange(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludePrevious(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetForecastRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetForecastRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetForecastRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetForecastRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedisScore();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRedisRank();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrentChange();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getIncludePrevious();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 redis_score = 1;
 * @return {number}
 */
proto.loupe.services.api.GetForecastRequest.prototype.getRedisScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.GetForecastRequest} returns this
 */
proto.loupe.services.api.GetForecastRequest.prototype.setRedisScore = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 redis_rank = 2;
 * @return {number}
 */
proto.loupe.services.api.GetForecastRequest.prototype.getRedisRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.GetForecastRequest} returns this
 */
proto.loupe.services.api.GetForecastRequest.prototype.setRedisRank = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional loupe.common.nullable.NullTimestamp current_change = 3;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.services.api.GetForecastRequest.prototype.getCurrentChange = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 3));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.services.api.GetForecastRequest} returns this
*/
proto.loupe.services.api.GetForecastRequest.prototype.setCurrentChange = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetForecastRequest} returns this
 */
proto.loupe.services.api.GetForecastRequest.prototype.clearCurrentChange = function() {
  return this.setCurrentChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetForecastRequest.prototype.hasCurrentChange = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool include_previous = 4;
 * @return {boolean}
 */
proto.loupe.services.api.GetForecastRequest.prototype.getIncludePrevious = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.api.GetForecastRequest} returns this
 */
proto.loupe.services.api.GetForecastRequest.prototype.setIncludePrevious = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetPipelineReportOptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetPipelineReportOptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetPipelineReportOptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetPipelineReportOptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    canonicalOnly: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetPipelineReportOptionsRequest}
 */
proto.loupe.services.api.GetPipelineReportOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetPipelineReportOptionsRequest;
  return proto.loupe.services.api.GetPipelineReportOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetPipelineReportOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetPipelineReportOptionsRequest}
 */
proto.loupe.services.api.GetPipelineReportOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanonicalOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetPipelineReportOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetPipelineReportOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetPipelineReportOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetPipelineReportOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanonicalOnly();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool canonical_only = 1;
 * @return {boolean}
 */
proto.loupe.services.api.GetPipelineReportOptionsRequest.prototype.getCanonicalOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.api.GetPipelineReportOptionsRequest} returns this
 */
proto.loupe.services.api.GetPipelineReportOptionsRequest.prototype.setCanonicalOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetUserLastReportInputRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetUserLastReportInputRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetUserLastReportInputRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetUserLastReportInputRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetUserLastReportInputRequest}
 */
proto.loupe.services.api.GetUserLastReportInputRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetUserLastReportInputRequest;
  return proto.loupe.services.api.GetUserLastReportInputRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetUserLastReportInputRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetUserLastReportInputRequest}
 */
proto.loupe.services.api.GetUserLastReportInputRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetUserLastReportInputRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetUserLastReportInputRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetUserLastReportInputRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetUserLastReportInputRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    redisScore: jspb.Message.getFieldWithDefault(msg, 1, 0),
    redisRank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentChange: (f = msg.getCurrentChange()) && src_common_nullable_nullable_pb.NullTimestamp.toObject(includeInstance, f),
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest;
  return proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRedisScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRedisRank(value);
      break;
    case 3:
      var value = new src_common_nullable_nullable_pb.NullTimestamp;
      reader.readMessage(value,src_common_nullable_nullable_pb.NullTimestamp.deserializeBinaryFromReader);
      msg.setCurrentChange(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedisScore();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRedisRank();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrentChange();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_nullable_nullable_pb.NullTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional int64 redis_score = 1;
 * @return {number}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.getRedisScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} returns this
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.setRedisScore = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 redis_rank = 2;
 * @return {number}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.getRedisRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} returns this
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.setRedisRank = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional loupe.common.nullable.NullTimestamp current_change = 3;
 * @return {?proto.loupe.common.nullable.NullTimestamp}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.getCurrentChange = function() {
  return /** @type{?proto.loupe.common.nullable.NullTimestamp} */ (
    jspb.Message.getWrapperField(this, src_common_nullable_nullable_pb.NullTimestamp, 3));
};


/**
 * @param {?proto.loupe.common.nullable.NullTimestamp|undefined} value
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} returns this
*/
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.setCurrentChange = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} returns this
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.clearCurrentChange = function() {
  return this.setCurrentChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.hasCurrentChange = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string user_ids = 4;
 * @return {!Array<string>}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} returns this
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} returns this
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest} returns this
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastCategoriesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    userForecastCategoriesSummaryMap: (f = msg.getUserForecastCategoriesSummaryMap()) ? f.toObject(includeInstance, proto.loupe.services.api.RawForecastCategoriesForUser.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse;
  return proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addForecastCategories(value);
      break;
    case 2:
      var value = msg.getUserForecastCategoriesSummaryMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.services.api.RawForecastCategoriesForUser.deserializeBinaryFromReader, "", new proto.loupe.services.api.RawForecastCategoriesForUser());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getUserForecastCategoriesSummaryMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.services.api.RawForecastCategoriesForUser.serializeBinaryToWriter);
  }
};


/**
 * repeated string forecast_categories = 1;
 * @return {!Array<string>}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.prototype.getForecastCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse} returns this
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.prototype.setForecastCategoriesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse} returns this
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.prototype.addForecastCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse} returns this
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.prototype.clearForecastCategoriesList = function() {
  return this.setForecastCategoriesList([]);
};


/**
 * map<string, RawForecastCategoriesForUser> user_forecast_categories_summary = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.services.api.RawForecastCategoriesForUser>}
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.prototype.getUserForecastCategoriesSummaryMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.services.api.RawForecastCategoriesForUser>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.loupe.services.api.RawForecastCategoriesForUser));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse} returns this
 */
proto.loupe.services.api.GetRawForecastCategoriesForUsersResponse.prototype.clearUserForecastCategoriesSummaryMap = function() {
  this.getUserForecastCategoriesSummaryMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.RawForecastCategoriesForUser.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.RawForecastCategoriesForUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.RawForecastCategoriesForUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.RawForecastCategoriesForUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastCategoryAmountsMap: (f = msg.getForecastCategoryAmountsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.RawForecastCategoriesForUser}
 */
proto.loupe.services.api.RawForecastCategoriesForUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.RawForecastCategoriesForUser;
  return proto.loupe.services.api.RawForecastCategoriesForUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.RawForecastCategoriesForUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.RawForecastCategoriesForUser}
 */
proto.loupe.services.api.RawForecastCategoriesForUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getForecastCategoryAmountsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readDouble, null, "", 0.0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.RawForecastCategoriesForUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.RawForecastCategoriesForUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.RawForecastCategoriesForUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.RawForecastCategoriesForUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastCategoryAmountsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeDouble);
  }
};


/**
 * map<string, double> forecast_category_amounts = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.loupe.services.api.RawForecastCategoriesForUser.prototype.getForecastCategoryAmountsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.api.RawForecastCategoriesForUser} returns this
 */
proto.loupe.services.api.RawForecastCategoriesForUser.prototype.clearForecastCategoryAmountsMap = function() {
  this.getForecastCategoryAmountsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.NlgTestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.NlgTestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.NlgTestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.NlgTestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: jspb.Message.getFieldWithDefault(msg, 1, ""),
    datajson: msg.getDatajson_asB64(),
    flags: msg.getFlags_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.NlgTestRequest}
 */
proto.loupe.services.api.NlgTestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.NlgTestRequest;
  return proto.loupe.services.api.NlgTestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.NlgTestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.NlgTestRequest}
 */
proto.loupe.services.api.NlgTestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplate(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDatajson(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFlags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.NlgTestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.NlgTestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.NlgTestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.NlgTestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDatajson_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getFlags_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string template = 1;
 * @return {string}
 */
proto.loupe.services.api.NlgTestRequest.prototype.getTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.NlgTestRequest} returns this
 */
proto.loupe.services.api.NlgTestRequest.prototype.setTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes dataJson = 2;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.api.NlgTestRequest.prototype.getDatajson = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes dataJson = 2;
 * This is a type-conversion wrapper around `getDatajson()`
 * @return {string}
 */
proto.loupe.services.api.NlgTestRequest.prototype.getDatajson_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDatajson()));
};


/**
 * optional bytes dataJson = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDatajson()`
 * @return {!Uint8Array}
 */
proto.loupe.services.api.NlgTestRequest.prototype.getDatajson_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDatajson()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.api.NlgTestRequest} returns this
 */
proto.loupe.services.api.NlgTestRequest.prototype.setDatajson = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes flags = 3;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.api.NlgTestRequest.prototype.getFlags = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes flags = 3;
 * This is a type-conversion wrapper around `getFlags()`
 * @return {string}
 */
proto.loupe.services.api.NlgTestRequest.prototype.getFlags_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFlags()));
};


/**
 * optional bytes flags = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFlags()`
 * @return {!Uint8Array}
 */
proto.loupe.services.api.NlgTestRequest.prototype.getFlags_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFlags()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.api.NlgTestRequest} returns this
 */
proto.loupe.services.api.NlgTestRequest.prototype.setFlags = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.NlgTestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.NlgTestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.NlgTestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.NlgTestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    payload: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.NlgTestResponse}
 */
proto.loupe.services.api.NlgTestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.NlgTestResponse;
  return proto.loupe.services.api.NlgTestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.NlgTestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.NlgTestResponse}
 */
proto.loupe.services.api.NlgTestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.NlgTestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.NlgTestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.NlgTestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.NlgTestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string payload = 1;
 * @return {string}
 */
proto.loupe.services.api.NlgTestResponse.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.NlgTestResponse} returns this
 */
proto.loupe.services.api.NlgTestResponse.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.DeliverNoteRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.DeliverNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.DeliverNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.DeliverNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    note: (f = msg.getNote()) && src_common_notes_notes_pb.Note.toObject(includeInstance, f),
    recipientsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    deliveryType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customMessage: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sender: (f = msg.getSender()) && src_common_person_person_pb.Person.toObject(includeInstance, f),
    txId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.DeliverNoteRequest}
 */
proto.loupe.services.api.DeliverNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.DeliverNoteRequest;
  return proto.loupe.services.api.DeliverNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.DeliverNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.DeliverNoteRequest}
 */
proto.loupe.services.api.DeliverNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_notes_notes_pb.Note;
      reader.readMessage(value,src_common_notes_notes_pb.Note.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRecipients(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.notification.NotificationType} */ (reader.readEnum());
      msg.setDeliveryType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomMessage(value);
      break;
    case 6:
      var value = new src_common_person_person_pb.Person;
      reader.readMessage(value,src_common_person_person_pb.Person.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.DeliverNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.DeliverNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.DeliverNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNote();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_notes_notes_pb.Note.serializeBinaryToWriter
    );
  }
  f = message.getRecipientsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDeliveryType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      src_common_person_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getTxId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional loupe.common.notes.Note note = 1;
 * @return {?proto.loupe.common.notes.Note}
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.getNote = function() {
  return /** @type{?proto.loupe.common.notes.Note} */ (
    jspb.Message.getWrapperField(this, src_common_notes_notes_pb.Note, 1));
};


/**
 * @param {?proto.loupe.common.notes.Note|undefined} value
 * @return {!proto.loupe.services.api.DeliverNoteRequest} returns this
*/
proto.loupe.services.api.DeliverNoteRequest.prototype.setNote = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.DeliverNoteRequest} returns this
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.clearNote = function() {
  return this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.hasNote = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string recipients = 2;
 * @return {!Array<string>}
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.getRecipientsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.api.DeliverNoteRequest} returns this
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.setRecipientsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.DeliverNoteRequest} returns this
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.addRecipients = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.DeliverNoteRequest} returns this
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.clearRecipientsList = function() {
  return this.setRecipientsList([]);
};


/**
 * optional loupe.common.notification.NotificationType delivery_type = 3;
 * @return {!proto.loupe.common.notification.NotificationType}
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.getDeliveryType = function() {
  return /** @type {!proto.loupe.common.notification.NotificationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.notification.NotificationType} value
 * @return {!proto.loupe.services.api.DeliverNoteRequest} returns this
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.setDeliveryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.DeliverNoteRequest} returns this
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string custom_message = 5;
 * @return {string}
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.getCustomMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.DeliverNoteRequest} returns this
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.setCustomMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional loupe.common.person.Person sender = 6;
 * @return {?proto.loupe.common.person.Person}
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.getSender = function() {
  return /** @type{?proto.loupe.common.person.Person} */ (
    jspb.Message.getWrapperField(this, src_common_person_person_pb.Person, 6));
};


/**
 * @param {?proto.loupe.common.person.Person|undefined} value
 * @return {!proto.loupe.services.api.DeliverNoteRequest} returns this
*/
proto.loupe.services.api.DeliverNoteRequest.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.DeliverNoteRequest} returns this
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.hasSender = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string tx_id = 7;
 * @return {string}
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.getTxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.DeliverNoteRequest} returns this
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.setTxId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string tenant_id = 8;
 * @return {string}
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.DeliverNoteRequest} returns this
 */
proto.loupe.services.api.DeliverNoteRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.PassportFeatureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.PassportFeatureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.PassportFeatureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.PassportFeatureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    personId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    feature: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.PassportFeatureResponse}
 */
proto.loupe.services.api.PassportFeatureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.PassportFeatureResponse;
  return proto.loupe.services.api.PassportFeatureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.PassportFeatureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.PassportFeatureResponse}
 */
proto.loupe.services.api.PassportFeatureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.PassportFeatureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.PassportFeatureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.PassportFeatureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.PassportFeatureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPersonId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFeature();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.PassportFeatureResponse.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.PassportFeatureResponse} returns this
 */
proto.loupe.services.api.PassportFeatureResponse.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.api.PassportFeatureResponse.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.PassportFeatureResponse} returns this
 */
proto.loupe.services.api.PassportFeatureResponse.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string person_id = 3;
 * @return {string}
 */
proto.loupe.services.api.PassportFeatureResponse.prototype.getPersonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.PassportFeatureResponse} returns this
 */
proto.loupe.services.api.PassportFeatureResponse.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string feature = 4;
 * @return {string}
 */
proto.loupe.services.api.PassportFeatureResponse.prototype.getFeature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.PassportFeatureResponse} returns this
 */
proto.loupe.services.api.PassportFeatureResponse.prototype.setFeature = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limitObjectKeysList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest}
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest;
  return proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest}
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addLimitObjectKeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitObjectKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string limit_object_keys = 1;
 * @return {!Array<string>}
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.prototype.getLimitObjectKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest} returns this
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.prototype.setLimitObjectKeysList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest} returns this
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.prototype.addLimitObjectKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest} returns this
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsRequest.prototype.clearLimitObjectKeysList = function() {
  return this.setLimitObjectKeysList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    src_common_integration_object_fieldsetting_fieldsetting_pb.IntegrationFilterDisplayObject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse}
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse;
  return proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse}
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_integration_object_fieldsetting_fieldsetting_pb.IntegrationFilterDisplayObject;
      reader.readMessage(value,src_common_integration_object_fieldsetting_fieldsetting_pb.IntegrationFilterDisplayObject.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_integration_object_fieldsetting_fieldsetting_pb.IntegrationFilterDisplayObject.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject result = 1;
 * @return {!Array<!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject>}
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.prototype.getResultList = function() {
  return /** @type{!Array<!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_integration_object_fieldsetting_fieldsetting_pb.IntegrationFilterDisplayObject, 1));
};


/**
 * @param {!Array<!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject>} value
 * @return {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse} returns this
*/
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject}
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.integration.object.fieldsetting.IntegrationFilterDisplayObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse} returns this
 */
proto.loupe.services.api.GetTenantIntegrationObjectFilterOptionsResponse.prototype.clearResultList = function() {
  return this.setResultList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetTenantIntegrationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetTenantIntegrationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetTenantIntegrationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetTenantIntegrationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantIntegrationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    integrationsList: jspb.Message.toObjectList(msg.getIntegrationsList(),
    src_common_integration_type_type_pb.IntegrationContainer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetTenantIntegrationsResponse}
 */
proto.loupe.services.api.GetTenantIntegrationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetTenantIntegrationsResponse;
  return proto.loupe.services.api.GetTenantIntegrationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetTenantIntegrationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetTenantIntegrationsResponse}
 */
proto.loupe.services.api.GetTenantIntegrationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_integration_type_type_pb.IntegrationContainer;
      reader.readMessage(value,src_common_integration_type_type_pb.IntegrationContainer.deserializeBinaryFromReader);
      msg.addIntegrations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetTenantIntegrationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetTenantIntegrationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetTenantIntegrationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantIntegrationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntegrationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_integration_type_type_pb.IntegrationContainer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.integration.type.IntegrationContainer integrations = 1;
 * @return {!Array<!proto.loupe.common.integration.type.IntegrationContainer>}
 */
proto.loupe.services.api.GetTenantIntegrationsResponse.prototype.getIntegrationsList = function() {
  return /** @type{!Array<!proto.loupe.common.integration.type.IntegrationContainer>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_integration_type_type_pb.IntegrationContainer, 1));
};


/**
 * @param {!Array<!proto.loupe.common.integration.type.IntegrationContainer>} value
 * @return {!proto.loupe.services.api.GetTenantIntegrationsResponse} returns this
*/
proto.loupe.services.api.GetTenantIntegrationsResponse.prototype.setIntegrationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.integration.type.IntegrationContainer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.integration.type.IntegrationContainer}
 */
proto.loupe.services.api.GetTenantIntegrationsResponse.prototype.addIntegrations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.integration.type.IntegrationContainer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetTenantIntegrationsResponse} returns this
 */
proto.loupe.services.api.GetTenantIntegrationsResponse.prototype.clearIntegrationsList = function() {
  return this.setIntegrationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.SearchRequest.repeatedFields_ = [3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.SearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.SearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.SearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.SearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: (f = msg.getQuery()) && src_common_search_search_pb.SearchContainer.toObject(includeInstance, f),
    paging: (f = msg.getPaging()) && src_common_search_search_pb.SearchPaginationContainer.toObject(includeInstance, f),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    objectName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    changeSince: (f = msg.getChangeSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    baseFiltersList: jspb.Message.toObjectList(msg.getBaseFiltersList(),
    src_common_realtime_query_pb.FilterParameter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.SearchRequest}
 */
proto.loupe.services.api.SearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.SearchRequest;
  return proto.loupe.services.api.SearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.SearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.SearchRequest}
 */
proto.loupe.services.api.SearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_search_search_pb.SearchContainer;
      reader.readMessage(value,src_common_search_search_pb.SearchContainer.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    case 2:
      var value = new src_common_search_search_pb.SearchPaginationContainer;
      reader.readMessage(value,src_common_search_search_pb.SearchPaginationContainer.deserializeBinaryFromReader);
      msg.setPaging(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFields(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setChangeSince(value);
      break;
    case 6:
      var value = new src_common_realtime_query_pb.FilterParameter;
      reader.readMessage(value,src_common_realtime_query_pb.FilterParameter.deserializeBinaryFromReader);
      msg.addBaseFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.SearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.SearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.SearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.SearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_search_search_pb.SearchContainer.serializeBinaryToWriter
    );
  }
  f = message.getPaging();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_search_search_pb.SearchPaginationContainer.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getChangeSince();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBaseFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      src_common_realtime_query_pb.FilterParameter.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.search.SearchContainer query = 1;
 * @return {?proto.loupe.common.search.SearchContainer}
 */
proto.loupe.services.api.SearchRequest.prototype.getQuery = function() {
  return /** @type{?proto.loupe.common.search.SearchContainer} */ (
    jspb.Message.getWrapperField(this, src_common_search_search_pb.SearchContainer, 1));
};


/**
 * @param {?proto.loupe.common.search.SearchContainer|undefined} value
 * @return {!proto.loupe.services.api.SearchRequest} returns this
*/
proto.loupe.services.api.SearchRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.SearchRequest} returns this
 */
proto.loupe.services.api.SearchRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.SearchRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional loupe.common.search.SearchPaginationContainer paging = 2;
 * @return {?proto.loupe.common.search.SearchPaginationContainer}
 */
proto.loupe.services.api.SearchRequest.prototype.getPaging = function() {
  return /** @type{?proto.loupe.common.search.SearchPaginationContainer} */ (
    jspb.Message.getWrapperField(this, src_common_search_search_pb.SearchPaginationContainer, 2));
};


/**
 * @param {?proto.loupe.common.search.SearchPaginationContainer|undefined} value
 * @return {!proto.loupe.services.api.SearchRequest} returns this
*/
proto.loupe.services.api.SearchRequest.prototype.setPaging = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.SearchRequest} returns this
 */
proto.loupe.services.api.SearchRequest.prototype.clearPaging = function() {
  return this.setPaging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.SearchRequest.prototype.hasPaging = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string fields = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.api.SearchRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.api.SearchRequest} returns this
 */
proto.loupe.services.api.SearchRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.SearchRequest} returns this
 */
proto.loupe.services.api.SearchRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.SearchRequest} returns this
 */
proto.loupe.services.api.SearchRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional string object_name = 4;
 * @return {string}
 */
proto.loupe.services.api.SearchRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.SearchRequest} returns this
 */
proto.loupe.services.api.SearchRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp change_since = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.api.SearchRequest.prototype.getChangeSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.api.SearchRequest} returns this
*/
proto.loupe.services.api.SearchRequest.prototype.setChangeSince = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.SearchRequest} returns this
 */
proto.loupe.services.api.SearchRequest.prototype.clearChangeSince = function() {
  return this.setChangeSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.SearchRequest.prototype.hasChangeSince = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated loupe.common.realtime.FilterParameter base_filters = 6;
 * @return {!Array<!proto.loupe.common.realtime.FilterParameter>}
 */
proto.loupe.services.api.SearchRequest.prototype.getBaseFiltersList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.FilterParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_realtime_query_pb.FilterParameter, 6));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.FilterParameter>} value
 * @return {!proto.loupe.services.api.SearchRequest} returns this
*/
proto.loupe.services.api.SearchRequest.prototype.setBaseFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.common.realtime.FilterParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.FilterParameter}
 */
proto.loupe.services.api.SearchRequest.prototype.addBaseFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.common.realtime.FilterParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.SearchRequest} returns this
 */
proto.loupe.services.api.SearchRequest.prototype.clearBaseFiltersList = function() {
  return this.setBaseFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetSearchItemByIDRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetSearchItemByIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetSearchItemByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetSearchItemByIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    objectName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    changeSince: (f = msg.getChangeSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetSearchItemByIDRequest}
 */
proto.loupe.services.api.GetSearchItemByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetSearchItemByIDRequest;
  return proto.loupe.services.api.GetSearchItemByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetSearchItemByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetSearchItemByIDRequest}
 */
proto.loupe.services.api.GetSearchItemByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFields(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setChangeSince(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetSearchItemByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetSearchItemByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetSearchItemByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChangeSince();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetSearchItemByIDRequest} returns this
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string fields = 2;
 * @return {!Array<string>}
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.api.GetSearchItemByIDRequest} returns this
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.GetSearchItemByIDRequest} returns this
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetSearchItemByIDRequest} returns this
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional string object_name = 3;
 * @return {string}
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetSearchItemByIDRequest} returns this
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp change_since = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.getChangeSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.api.GetSearchItemByIDRequest} returns this
*/
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.setChangeSince = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetSearchItemByIDRequest} returns this
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.clearChangeSince = function() {
  return this.setChangeSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetSearchItemByIDRequest.prototype.hasChangeSince = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.PatchSearchItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.PatchSearchItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.PatchSearchItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && src_common_search_item_item_pb.SearchItem.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    diff: (f = msg.getDiff()) && src_common_search_item_item_pb.SearchItem.toObject(includeInstance, f),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.PatchSearchItemResponse}
 */
proto.loupe.services.api.PatchSearchItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.PatchSearchItemResponse;
  return proto.loupe.services.api.PatchSearchItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.PatchSearchItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.PatchSearchItemResponse}
 */
proto.loupe.services.api.PatchSearchItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_search_item_item_pb.SearchItem;
      reader.readMessage(value,src_common_search_item_item_pb.SearchItem.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.services.api.PatchSearchItemStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new src_common_search_item_item_pb.SearchItem;
      reader.readMessage(value,src_common_search_item_item_pb.SearchItem.deserializeBinaryFromReader);
      msg.setDiff(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.services.api.PatchSearchItemErrType} */ (reader.readEnum());
      msg.setErrorType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.PatchSearchItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.PatchSearchItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.PatchSearchItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_search_item_item_pb.SearchItem.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDiff();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_search_item_item_pb.SearchItem.serializeBinaryToWriter
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional loupe.common.search.item.SearchItem item = 1;
 * @return {?proto.loupe.common.search.item.SearchItem}
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.getItem = function() {
  return /** @type{?proto.loupe.common.search.item.SearchItem} */ (
    jspb.Message.getWrapperField(this, src_common_search_item_item_pb.SearchItem, 1));
};


/**
 * @param {?proto.loupe.common.search.item.SearchItem|undefined} value
 * @return {!proto.loupe.services.api.PatchSearchItemResponse} returns this
*/
proto.loupe.services.api.PatchSearchItemResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.PatchSearchItemResponse} returns this
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PatchSearchItemStatus status = 2;
 * @return {!proto.loupe.services.api.PatchSearchItemStatus}
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.getStatus = function() {
  return /** @type {!proto.loupe.services.api.PatchSearchItemStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.services.api.PatchSearchItemStatus} value
 * @return {!proto.loupe.services.api.PatchSearchItemResponse} returns this
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional loupe.common.search.item.SearchItem diff = 3;
 * @return {?proto.loupe.common.search.item.SearchItem}
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.getDiff = function() {
  return /** @type{?proto.loupe.common.search.item.SearchItem} */ (
    jspb.Message.getWrapperField(this, src_common_search_item_item_pb.SearchItem, 3));
};


/**
 * @param {?proto.loupe.common.search.item.SearchItem|undefined} value
 * @return {!proto.loupe.services.api.PatchSearchItemResponse} returns this
*/
proto.loupe.services.api.PatchSearchItemResponse.prototype.setDiff = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.PatchSearchItemResponse} returns this
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.clearDiff = function() {
  return this.setDiff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.hasDiff = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string error_message = 4;
 * @return {string}
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.PatchSearchItemResponse} returns this
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional PatchSearchItemErrType error_type = 5;
 * @return {!proto.loupe.services.api.PatchSearchItemErrType}
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.getErrorType = function() {
  return /** @type {!proto.loupe.services.api.PatchSearchItemErrType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.services.api.PatchSearchItemErrType} value
 * @return {!proto.loupe.services.api.PatchSearchItemResponse} returns this
 */
proto.loupe.services.api.PatchSearchItemResponse.prototype.setErrorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.PatchSearchItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.PatchSearchItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.PatchSearchItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.PatchSearchItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && src_common_search_item_item_pb.SearchItem.toObject(includeInstance, f),
    objectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    changeSince: (f = msg.getChangeSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.PatchSearchItemRequest}
 */
proto.loupe.services.api.PatchSearchItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.PatchSearchItemRequest;
  return proto.loupe.services.api.PatchSearchItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.PatchSearchItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.PatchSearchItemRequest}
 */
proto.loupe.services.api.PatchSearchItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_search_item_item_pb.SearchItem;
      reader.readMessage(value,src_common_search_item_item_pb.SearchItem.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setChangeSince(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.PatchSearchItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.PatchSearchItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.PatchSearchItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.PatchSearchItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_search_item_item_pb.SearchItem.serializeBinaryToWriter
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChangeSince();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.search.item.SearchItem item = 1;
 * @return {?proto.loupe.common.search.item.SearchItem}
 */
proto.loupe.services.api.PatchSearchItemRequest.prototype.getItem = function() {
  return /** @type{?proto.loupe.common.search.item.SearchItem} */ (
    jspb.Message.getWrapperField(this, src_common_search_item_item_pb.SearchItem, 1));
};


/**
 * @param {?proto.loupe.common.search.item.SearchItem|undefined} value
 * @return {!proto.loupe.services.api.PatchSearchItemRequest} returns this
*/
proto.loupe.services.api.PatchSearchItemRequest.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.PatchSearchItemRequest} returns this
 */
proto.loupe.services.api.PatchSearchItemRequest.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.PatchSearchItemRequest.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string object_name = 2;
 * @return {string}
 */
proto.loupe.services.api.PatchSearchItemRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.PatchSearchItemRequest} returns this
 */
proto.loupe.services.api.PatchSearchItemRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp change_since = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.api.PatchSearchItemRequest.prototype.getChangeSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.api.PatchSearchItemRequest} returns this
*/
proto.loupe.services.api.PatchSearchItemRequest.prototype.setChangeSince = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.PatchSearchItemRequest} returns this
 */
proto.loupe.services.api.PatchSearchItemRequest.prototype.clearChangeSince = function() {
  return this.setChangeSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.PatchSearchItemRequest.prototype.hasChangeSince = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.TestSearchQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.TestSearchQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.TestSearchQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.TestSearchQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    params: msg.getParams_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.TestSearchQueryRequest}
 */
proto.loupe.services.api.TestSearchQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.TestSearchQueryRequest;
  return proto.loupe.services.api.TestSearchQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.TestSearchQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.TestSearchQueryRequest}
 */
proto.loupe.services.api.TestSearchQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.TestSearchQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.TestSearchQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.TestSearchQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.TestSearchQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParams_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.services.api.TestSearchQueryRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.TestSearchQueryRequest} returns this
 */
proto.loupe.services.api.TestSearchQueryRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.api.TestSearchQueryRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.TestSearchQueryRequest} returns this
 */
proto.loupe.services.api.TestSearchQueryRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes params = 3;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.api.TestSearchQueryRequest.prototype.getParams = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes params = 3;
 * This is a type-conversion wrapper around `getParams()`
 * @return {string}
 */
proto.loupe.services.api.TestSearchQueryRequest.prototype.getParams_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getParams()));
};


/**
 * optional bytes params = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getParams()`
 * @return {!Uint8Array}
 */
proto.loupe.services.api.TestSearchQueryRequest.prototype.getParams_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getParams()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.api.TestSearchQueryRequest} returns this
 */
proto.loupe.services.api.TestSearchQueryRequest.prototype.setParams = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.TestSearchQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.TestSearchQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.TestSearchQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.TestSearchQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: msg.getData_asB64(),
    presentation: (f = msg.getPresentation()) && src_common_gml_gml_pb.GML.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.TestSearchQueryResponse}
 */
proto.loupe.services.api.TestSearchQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.TestSearchQueryResponse;
  return proto.loupe.services.api.TestSearchQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.TestSearchQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.TestSearchQueryResponse}
 */
proto.loupe.services.api.TestSearchQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 4:
      var value = new src_common_gml_gml_pb.GML;
      reader.readMessage(value,src_common_gml_gml_pb.GML.deserializeBinaryFromReader);
      msg.setPresentation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.TestSearchQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.TestSearchQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.TestSearchQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.TestSearchQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getPresentation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_gml_gml_pb.GML.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes data = 1;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.api.TestSearchQueryResponse.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes data = 1;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.loupe.services.api.TestSearchQueryResponse.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.loupe.services.api.TestSearchQueryResponse.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.api.TestSearchQueryResponse} returns this
 */
proto.loupe.services.api.TestSearchQueryResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional loupe.common.gml.GML presentation = 4;
 * @return {?proto.loupe.common.gml.GML}
 */
proto.loupe.services.api.TestSearchQueryResponse.prototype.getPresentation = function() {
  return /** @type{?proto.loupe.common.gml.GML} */ (
    jspb.Message.getWrapperField(this, src_common_gml_gml_pb.GML, 4));
};


/**
 * @param {?proto.loupe.common.gml.GML|undefined} value
 * @return {!proto.loupe.services.api.TestSearchQueryResponse} returns this
*/
proto.loupe.services.api.TestSearchQueryResponse.prototype.setPresentation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.TestSearchQueryResponse} returns this
 */
proto.loupe.services.api.TestSearchQueryResponse.prototype.clearPresentation = function() {
  return this.setPresentation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.TestSearchQueryResponse.prototype.hasPresentation = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetWeightedForecastAmountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetWeightedForecastAmountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetWeightedForecastAmountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetWeightedForecastAmountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetWeightedForecastAmountsRequest}
 */
proto.loupe.services.api.GetWeightedForecastAmountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetWeightedForecastAmountsRequest;
  return proto.loupe.services.api.GetWeightedForecastAmountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetWeightedForecastAmountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetWeightedForecastAmountsRequest}
 */
proto.loupe.services.api.GetWeightedForecastAmountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetWeightedForecastAmountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetWeightedForecastAmountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetWeightedForecastAmountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetWeightedForecastAmountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetWeightedForecastAmountsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetWeightedForecastAmountsRequest} returns this
 */
proto.loupe.services.api.GetWeightedForecastAmountsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.loupe.services.api.GetWeightedForecastAmountsRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetWeightedForecastAmountsRequest} returns this
 */
proto.loupe.services.api.GetWeightedForecastAmountsRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.WeightedForecastAmount.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.WeightedForecastAmount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.WeightedForecastAmount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.WeightedForecastAmount.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    winRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    total: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    weightedTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.WeightedForecastAmount}
 */
proto.loupe.services.api.WeightedForecastAmount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.WeightedForecastAmount;
  return proto.loupe.services.api.WeightedForecastAmount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.WeightedForecastAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.WeightedForecastAmount}
 */
proto.loupe.services.api.WeightedForecastAmount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWinRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotal(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWeightedTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.WeightedForecastAmount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.WeightedForecastAmount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.WeightedForecastAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.WeightedForecastAmount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWinRate();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getWeightedTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.loupe.services.api.WeightedForecastAmount.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.WeightedForecastAmount} returns this
 */
proto.loupe.services.api.WeightedForecastAmount.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double win_rate = 2;
 * @return {number}
 */
proto.loupe.services.api.WeightedForecastAmount.prototype.getWinRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.WeightedForecastAmount} returns this
 */
proto.loupe.services.api.WeightedForecastAmount.prototype.setWinRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total = 3;
 * @return {number}
 */
proto.loupe.services.api.WeightedForecastAmount.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.WeightedForecastAmount} returns this
 */
proto.loupe.services.api.WeightedForecastAmount.prototype.setTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double weighted_total = 4;
 * @return {number}
 */
proto.loupe.services.api.WeightedForecastAmount.prototype.getWeightedTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.WeightedForecastAmount} returns this
 */
proto.loupe.services.api.WeightedForecastAmount.prototype.setWeightedTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetWeightedForecastAmountsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetWeightedForecastAmountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetWeightedForecastAmountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetWeightedForecastAmountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetWeightedForecastAmountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.loupe.services.api.WeightedForecastAmount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetWeightedForecastAmountsResponse}
 */
proto.loupe.services.api.GetWeightedForecastAmountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetWeightedForecastAmountsResponse;
  return proto.loupe.services.api.GetWeightedForecastAmountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetWeightedForecastAmountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetWeightedForecastAmountsResponse}
 */
proto.loupe.services.api.GetWeightedForecastAmountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.api.WeightedForecastAmount;
      reader.readMessage(value,proto.loupe.services.api.WeightedForecastAmount.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetWeightedForecastAmountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetWeightedForecastAmountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetWeightedForecastAmountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetWeightedForecastAmountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.api.WeightedForecastAmount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WeightedForecastAmount categories = 1;
 * @return {!Array<!proto.loupe.services.api.WeightedForecastAmount>}
 */
proto.loupe.services.api.GetWeightedForecastAmountsResponse.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.loupe.services.api.WeightedForecastAmount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.api.WeightedForecastAmount, 1));
};


/**
 * @param {!Array<!proto.loupe.services.api.WeightedForecastAmount>} value
 * @return {!proto.loupe.services.api.GetWeightedForecastAmountsResponse} returns this
*/
proto.loupe.services.api.GetWeightedForecastAmountsResponse.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.api.WeightedForecastAmount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.WeightedForecastAmount}
 */
proto.loupe.services.api.GetWeightedForecastAmountsResponse.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.api.WeightedForecastAmount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetWeightedForecastAmountsResponse} returns this
 */
proto.loupe.services.api.GetWeightedForecastAmountsResponse.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetSavedFiltersByKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetSavedFiltersByKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    key: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetSavedFiltersByKeyRequest}
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetSavedFiltersByKeyRequest;
  return proto.loupe.services.api.GetSavedFiltersByKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetSavedFiltersByKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetSavedFiltersByKeyRequest}
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetSavedFiltersByKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetSavedFiltersByKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetSavedFiltersByKeyRequest} returns this
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetSavedFiltersByKeyRequest} returns this
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetSavedFiltersByKeyRequest} returns this
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string key = 4;
 * @return {string}
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetSavedFiltersByKeyRequest} returns this
 */
proto.loupe.services.api.GetSavedFiltersByKeyRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    key: jspb.Message.getFieldWithDefault(msg, 4, ""),
    setting: (f = msg.getSetting()) && src_common_filter_filter_pb.SavedFilters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest}
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertSavedFiltersByKeyRequest;
  return proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest}
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 5:
      var value = new src_common_filter_filter_pb.SavedFilters;
      reader.readMessage(value,src_common_filter_filter_pb.SavedFilters.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_filter_filter_pb.SavedFilters.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest} returns this
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest} returns this
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest} returns this
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string key = 4;
 * @return {string}
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest} returns this
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional loupe.common.filter.SavedFilters setting = 5;
 * @return {?proto.loupe.common.filter.SavedFilters}
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.filter.SavedFilters} */ (
    jspb.Message.getWrapperField(this, src_common_filter_filter_pb.SavedFilters, 5));
};


/**
 * @param {?proto.loupe.common.filter.SavedFilters|undefined} value
 * @return {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest} returns this
*/
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertSavedFiltersByKeyRequest} returns this
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertSavedFiltersByKeyRequest.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetUserHasActivitySyncedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetUserHasActivitySyncedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetUserHasActivitySyncedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetUserHasActivitySyncedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetUserHasActivitySyncedRequest}
 */
proto.loupe.services.api.GetUserHasActivitySyncedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetUserHasActivitySyncedRequest;
  return proto.loupe.services.api.GetUserHasActivitySyncedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetUserHasActivitySyncedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetUserHasActivitySyncedRequest}
 */
proto.loupe.services.api.GetUserHasActivitySyncedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetUserHasActivitySyncedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetUserHasActivitySyncedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetUserHasActivitySyncedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetUserHasActivitySyncedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetUserHasActivitySyncedRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetUserHasActivitySyncedRequest} returns this
 */
proto.loupe.services.api.GetUserHasActivitySyncedRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.loupe.services.api.GetUserHasActivitySyncedRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetUserHasActivitySyncedRequest} returns this
 */
proto.loupe.services.api.GetUserHasActivitySyncedRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetUserHasActivitySyncedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetUserHasActivitySyncedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetUserHasActivitySyncedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetUserHasActivitySyncedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSynced: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetUserHasActivitySyncedResponse}
 */
proto.loupe.services.api.GetUserHasActivitySyncedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetUserHasActivitySyncedResponse;
  return proto.loupe.services.api.GetUserHasActivitySyncedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetUserHasActivitySyncedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetUserHasActivitySyncedResponse}
 */
proto.loupe.services.api.GetUserHasActivitySyncedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSynced(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetUserHasActivitySyncedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetUserHasActivitySyncedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetUserHasActivitySyncedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetUserHasActivitySyncedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSynced();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_synced = 1;
 * @return {boolean}
 */
proto.loupe.services.api.GetUserHasActivitySyncedResponse.prototype.getIsSynced = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.api.GetUserHasActivitySyncedResponse} returns this
 */
proto.loupe.services.api.GetUserHasActivitySyncedResponse.prototype.setIsSynced = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.IndividualFeaturePermissionCheck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.IndividualFeaturePermissionCheck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionSet: jspb.Message.getFieldWithDefault(msg, 1, 0),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.IndividualFeaturePermissionCheck}
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.IndividualFeaturePermissionCheck;
  return proto.loupe.services.api.IndividualFeaturePermissionCheck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.IndividualFeaturePermissionCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.IndividualFeaturePermissionCheck}
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.auth.PermissionSet} */ (reader.readEnum());
      msg.setPermissionSet(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.loupe.common.auth.Permission>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPermissions(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.IndividualFeaturePermissionCheck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.IndividualFeaturePermissionCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionSet();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional loupe.common.auth.PermissionSet permission_set = 1;
 * @return {!proto.loupe.common.auth.PermissionSet}
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.prototype.getPermissionSet = function() {
  return /** @type {!proto.loupe.common.auth.PermissionSet} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.auth.PermissionSet} value
 * @return {!proto.loupe.services.api.IndividualFeaturePermissionCheck} returns this
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.prototype.setPermissionSet = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated loupe.common.auth.Permission permissions = 2;
 * @return {!Array<!proto.loupe.common.auth.Permission>}
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.prototype.getPermissionsList = function() {
  return /** @type {!Array<!proto.loupe.common.auth.Permission>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.loupe.common.auth.Permission>} value
 * @return {!proto.loupe.services.api.IndividualFeaturePermissionCheck} returns this
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.loupe.common.auth.Permission} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.IndividualFeaturePermissionCheck} returns this
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.IndividualFeaturePermissionCheck} returns this
 */
proto.loupe.services.api.IndividualFeaturePermissionCheck.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.PermissionCheckObject.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.PermissionCheckObject.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.PermissionCheckObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.PermissionCheckObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.PermissionCheckObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    checksList: jspb.Message.toObjectList(msg.getChecksList(),
    proto.loupe.services.api.IndividualFeaturePermissionCheck.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.PermissionCheckObject}
 */
proto.loupe.services.api.PermissionCheckObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.PermissionCheckObject;
  return proto.loupe.services.api.PermissionCheckObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.PermissionCheckObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.PermissionCheckObject}
 */
proto.loupe.services.api.PermissionCheckObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.loupe.services.api.IndividualFeaturePermissionCheck;
      reader.readMessage(value,proto.loupe.services.api.IndividualFeaturePermissionCheck.deserializeBinaryFromReader);
      msg.addChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.PermissionCheckObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.PermissionCheckObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.PermissionCheckObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.PermissionCheckObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.services.api.IndividualFeaturePermissionCheck.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IndividualFeaturePermissionCheck checks = 2;
 * @return {!Array<!proto.loupe.services.api.IndividualFeaturePermissionCheck>}
 */
proto.loupe.services.api.PermissionCheckObject.prototype.getChecksList = function() {
  return /** @type{!Array<!proto.loupe.services.api.IndividualFeaturePermissionCheck>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.api.IndividualFeaturePermissionCheck, 2));
};


/**
 * @param {!Array<!proto.loupe.services.api.IndividualFeaturePermissionCheck>} value
 * @return {!proto.loupe.services.api.PermissionCheckObject} returns this
*/
proto.loupe.services.api.PermissionCheckObject.prototype.setChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.services.api.IndividualFeaturePermissionCheck=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.IndividualFeaturePermissionCheck}
 */
proto.loupe.services.api.PermissionCheckObject.prototype.addChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.services.api.IndividualFeaturePermissionCheck, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.PermissionCheckObject} returns this
 */
proto.loupe.services.api.PermissionCheckObject.prototype.clearChecksList = function() {
  return this.setChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.CheckPermissionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.CheckPermissionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.CheckPermissionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CheckPermissionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    valsMap: (f = msg.getValsMap()) ? f.toObject(includeInstance, proto.loupe.services.api.PermissionCheckObject.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.CheckPermissionsRequest}
 */
proto.loupe.services.api.CheckPermissionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.CheckPermissionsRequest;
  return proto.loupe.services.api.CheckPermissionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.CheckPermissionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.CheckPermissionsRequest}
 */
proto.loupe.services.api.CheckPermissionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getValsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.services.api.PermissionCheckObject.deserializeBinaryFromReader, "", new proto.loupe.services.api.PermissionCheckObject());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.CheckPermissionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.CheckPermissionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.CheckPermissionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CheckPermissionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.services.api.PermissionCheckObject.serializeBinaryToWriter);
  }
};


/**
 * map<string, PermissionCheckObject> vals = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.services.api.PermissionCheckObject>}
 */
proto.loupe.services.api.CheckPermissionsRequest.prototype.getValsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.services.api.PermissionCheckObject>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.loupe.services.api.PermissionCheckObject));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.api.CheckPermissionsRequest} returns this
 */
proto.loupe.services.api.CheckPermissionsRequest.prototype.clearValsMap = function() {
  this.getValsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.CheckPermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.CheckPermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.CheckPermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CheckPermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsMap: (f = msg.getResultsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.CheckPermissionsResponse}
 */
proto.loupe.services.api.CheckPermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.CheckPermissionsResponse;
  return proto.loupe.services.api.CheckPermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.CheckPermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.CheckPermissionsResponse}
 */
proto.loupe.services.api.CheckPermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getResultsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.CheckPermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.CheckPermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.CheckPermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CheckPermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
};


/**
 * map<string, bool> results = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.loupe.services.api.CheckPermissionsResponse.prototype.getResultsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.api.CheckPermissionsResponse} returns this
 */
proto.loupe.services.api.CheckPermissionsResponse.prototype.clearResultsMap = function() {
  this.getResultsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetGroupSyncStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetGroupSyncStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetGroupSyncStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetGroupSyncStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetGroupSyncStateRequest}
 */
proto.loupe.services.api.GetGroupSyncStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetGroupSyncStateRequest;
  return proto.loupe.services.api.GetGroupSyncStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetGroupSyncStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetGroupSyncStateRequest}
 */
proto.loupe.services.api.GetGroupSyncStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetGroupSyncStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetGroupSyncStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetGroupSyncStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetGroupSyncStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetGroupSyncStateRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetGroupSyncStateRequest} returns this
 */
proto.loupe.services.api.GetGroupSyncStateRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetGroupSyncStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetGroupSyncStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetGroupSyncStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetGroupSyncStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetGroupSyncStateResponse}
 */
proto.loupe.services.api.GetGroupSyncStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetGroupSyncStateResponse;
  return proto.loupe.services.api.GetGroupSyncStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetGroupSyncStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetGroupSyncStateResponse}
 */
proto.loupe.services.api.GetGroupSyncStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.loupe.common.tenant.GroupSyncStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetGroupSyncStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetGroupSyncStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetGroupSyncStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetGroupSyncStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional loupe.common.tenant.GroupSyncStatus status = 1;
 * @return {!proto.loupe.common.tenant.GroupSyncStatus}
 */
proto.loupe.services.api.GetGroupSyncStateResponse.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.tenant.GroupSyncStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.loupe.common.tenant.GroupSyncStatus} value
 * @return {!proto.loupe.services.api.GetGroupSyncStateResponse} returns this
 */
proto.loupe.services.api.GetGroupSyncStateResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetDataSyncIntervalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetDataSyncIntervalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetDataSyncIntervalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetDataSyncIntervalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetDataSyncIntervalRequest}
 */
proto.loupe.services.api.GetDataSyncIntervalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetDataSyncIntervalRequest;
  return proto.loupe.services.api.GetDataSyncIntervalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetDataSyncIntervalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetDataSyncIntervalRequest}
 */
proto.loupe.services.api.GetDataSyncIntervalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetDataSyncIntervalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetDataSyncIntervalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetDataSyncIntervalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetDataSyncIntervalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetDataSyncIntervalRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetDataSyncIntervalRequest} returns this
 */
proto.loupe.services.api.GetDataSyncIntervalRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetDataSyncIntervalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetDataSyncIntervalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetDataSyncIntervalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetDataSyncIntervalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseIntervalSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetDataSyncIntervalResponse}
 */
proto.loupe.services.api.GetDataSyncIntervalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetDataSyncIntervalResponse;
  return proto.loupe.services.api.GetDataSyncIntervalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetDataSyncIntervalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetDataSyncIntervalResponse}
 */
proto.loupe.services.api.GetDataSyncIntervalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBaseIntervalSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetDataSyncIntervalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetDataSyncIntervalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetDataSyncIntervalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetDataSyncIntervalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseIntervalSeconds();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 base_interval_seconds = 1;
 * @return {number}
 */
proto.loupe.services.api.GetDataSyncIntervalResponse.prototype.getBaseIntervalSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.GetDataSyncIntervalResponse} returns this
 */
proto.loupe.services.api.GetDataSyncIntervalResponse.prototype.setBaseIntervalSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.SetDataSyncIntervalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.SetDataSyncIntervalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.SetDataSyncIntervalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.SetDataSyncIntervalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseIntervalSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.SetDataSyncIntervalRequest}
 */
proto.loupe.services.api.SetDataSyncIntervalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.SetDataSyncIntervalRequest;
  return proto.loupe.services.api.SetDataSyncIntervalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.SetDataSyncIntervalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.SetDataSyncIntervalRequest}
 */
proto.loupe.services.api.SetDataSyncIntervalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBaseIntervalSeconds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.SetDataSyncIntervalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.SetDataSyncIntervalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.SetDataSyncIntervalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.SetDataSyncIntervalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseIntervalSeconds();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 base_interval_seconds = 1;
 * @return {number}
 */
proto.loupe.services.api.SetDataSyncIntervalRequest.prototype.getBaseIntervalSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.SetDataSyncIntervalRequest} returns this
 */
proto.loupe.services.api.SetDataSyncIntervalRequest.prototype.setBaseIntervalSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.api.SetDataSyncIntervalRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.SetDataSyncIntervalRequest} returns this
 */
proto.loupe.services.api.SetDataSyncIntervalRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.SetDataSyncIntervalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.SetDataSyncIntervalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.SetDataSyncIntervalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.SetDataSyncIntervalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.SetDataSyncIntervalResponse}
 */
proto.loupe.services.api.SetDataSyncIntervalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.SetDataSyncIntervalResponse;
  return proto.loupe.services.api.SetDataSyncIntervalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.SetDataSyncIntervalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.SetDataSyncIntervalResponse}
 */
proto.loupe.services.api.SetDataSyncIntervalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.SetDataSyncIntervalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.SetDataSyncIntervalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.SetDataSyncIntervalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.SetDataSyncIntervalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ListIntegrationObjectPropertiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    crmType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    crmObjectName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ListIntegrationObjectPropertiesRequest}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ListIntegrationObjectPropertiesRequest;
  return proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ListIntegrationObjectPropertiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ListIntegrationObjectPropertiesRequest}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmObjectName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ListIntegrationObjectPropertiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCrmType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCrmObjectName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ListIntegrationObjectPropertiesRequest} returns this
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string crm_type = 2;
 * @return {string}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.prototype.getCrmType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ListIntegrationObjectPropertiesRequest} returns this
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.prototype.setCrmType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string crm_object_name = 3;
 * @return {string}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.prototype.getCrmObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ListIntegrationObjectPropertiesRequest} returns this
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesRequest.prototype.setCrmObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ListIntegrationObjectPropertiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.loupe.services.api.SimpleIntegrationObjectProperty.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ListIntegrationObjectPropertiesResponse}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ListIntegrationObjectPropertiesResponse;
  return proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ListIntegrationObjectPropertiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ListIntegrationObjectPropertiesResponse}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.api.SimpleIntegrationObjectProperty;
      reader.readMessage(value,proto.loupe.services.api.SimpleIntegrationObjectProperty.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ListIntegrationObjectPropertiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.api.SimpleIntegrationObjectProperty.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SimpleIntegrationObjectProperty properties = 1;
 * @return {!Array<!proto.loupe.services.api.SimpleIntegrationObjectProperty>}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.loupe.services.api.SimpleIntegrationObjectProperty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.api.SimpleIntegrationObjectProperty, 1));
};


/**
 * @param {!Array<!proto.loupe.services.api.SimpleIntegrationObjectProperty>} value
 * @return {!proto.loupe.services.api.ListIntegrationObjectPropertiesResponse} returns this
*/
proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.api.SimpleIntegrationObjectProperty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.SimpleIntegrationObjectProperty}
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.api.SimpleIntegrationObjectProperty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.ListIntegrationObjectPropertiesResponse} returns this
 */
proto.loupe.services.api.ListIntegrationObjectPropertiesResponse.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.SimpleIntegrationObjectProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.SimpleIntegrationObjectProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    format: jspb.Message.getFieldWithDefault(msg, 4, ""),
    writable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.SimpleIntegrationObjectProperty}
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.SimpleIntegrationObjectProperty;
  return proto.loupe.services.api.SimpleIntegrationObjectProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.SimpleIntegrationObjectProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.SimpleIntegrationObjectProperty}
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWritable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.SimpleIntegrationObjectProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.SimpleIntegrationObjectProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWritable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.SimpleIntegrationObjectProperty} returns this
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.SimpleIntegrationObjectProperty} returns this
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.SimpleIntegrationObjectProperty} returns this
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string format = 4;
 * @return {string}
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.SimpleIntegrationObjectProperty} returns this
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool writable = 5;
 * @return {boolean}
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.prototype.getWritable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.api.SimpleIntegrationObjectProperty} returns this
 */
proto.loupe.services.api.SimpleIntegrationObjectProperty.prototype.setWritable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ListIntegrationObjectTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ListIntegrationObjectTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ListIntegrationObjectTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ListIntegrationObjectTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    crmType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ListIntegrationObjectTypesRequest}
 */
proto.loupe.services.api.ListIntegrationObjectTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ListIntegrationObjectTypesRequest;
  return proto.loupe.services.api.ListIntegrationObjectTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ListIntegrationObjectTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ListIntegrationObjectTypesRequest}
 */
proto.loupe.services.api.ListIntegrationObjectTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ListIntegrationObjectTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ListIntegrationObjectTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ListIntegrationObjectTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ListIntegrationObjectTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCrmType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.ListIntegrationObjectTypesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ListIntegrationObjectTypesRequest} returns this
 */
proto.loupe.services.api.ListIntegrationObjectTypesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string crm_type = 2;
 * @return {string}
 */
proto.loupe.services.api.ListIntegrationObjectTypesRequest.prototype.getCrmType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ListIntegrationObjectTypesRequest} returns this
 */
proto.loupe.services.api.ListIntegrationObjectTypesRequest.prototype.setCrmType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.ListIntegrationObjectTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ListIntegrationObjectTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ListIntegrationObjectTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ListIntegrationObjectTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ListIntegrationObjectTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectTypesList: jspb.Message.toObjectList(msg.getObjectTypesList(),
    proto.loupe.services.api.IntegrationObjectType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ListIntegrationObjectTypesResponse}
 */
proto.loupe.services.api.ListIntegrationObjectTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ListIntegrationObjectTypesResponse;
  return proto.loupe.services.api.ListIntegrationObjectTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ListIntegrationObjectTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ListIntegrationObjectTypesResponse}
 */
proto.loupe.services.api.ListIntegrationObjectTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.api.IntegrationObjectType;
      reader.readMessage(value,proto.loupe.services.api.IntegrationObjectType.deserializeBinaryFromReader);
      msg.addObjectTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ListIntegrationObjectTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ListIntegrationObjectTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ListIntegrationObjectTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ListIntegrationObjectTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.api.IntegrationObjectType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IntegrationObjectType object_types = 1;
 * @return {!Array<!proto.loupe.services.api.IntegrationObjectType>}
 */
proto.loupe.services.api.ListIntegrationObjectTypesResponse.prototype.getObjectTypesList = function() {
  return /** @type{!Array<!proto.loupe.services.api.IntegrationObjectType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.api.IntegrationObjectType, 1));
};


/**
 * @param {!Array<!proto.loupe.services.api.IntegrationObjectType>} value
 * @return {!proto.loupe.services.api.ListIntegrationObjectTypesResponse} returns this
*/
proto.loupe.services.api.ListIntegrationObjectTypesResponse.prototype.setObjectTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.api.IntegrationObjectType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.IntegrationObjectType}
 */
proto.loupe.services.api.ListIntegrationObjectTypesResponse.prototype.addObjectTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.api.IntegrationObjectType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.ListIntegrationObjectTypesResponse} returns this
 */
proto.loupe.services.api.ListIntegrationObjectTypesResponse.prototype.clearObjectTypesList = function() {
  return this.setObjectTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.IntegrationObjectType.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.IntegrationObjectType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.IntegrationObjectType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.IntegrationObjectType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.IntegrationObjectType}
 */
proto.loupe.services.api.IntegrationObjectType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.IntegrationObjectType;
  return proto.loupe.services.api.IntegrationObjectType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.IntegrationObjectType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.IntegrationObjectType}
 */
proto.loupe.services.api.IntegrationObjectType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.IntegrationObjectType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.IntegrationObjectType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.IntegrationObjectType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.IntegrationObjectType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.services.api.IntegrationObjectType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.IntegrationObjectType} returns this
 */
proto.loupe.services.api.IntegrationObjectType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.services.api.IntegrationObjectType.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.IntegrationObjectType} returns this
 */
proto.loupe.services.api.IntegrationObjectType.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ExportSearchResultsResult.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ExportSearchResultsResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ExportSearchResultsResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ExportSearchResultsResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ExportSearchResultsResult}
 */
proto.loupe.services.api.ExportSearchResultsResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ExportSearchResultsResult;
  return proto.loupe.services.api.ExportSearchResultsResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ExportSearchResultsResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ExportSearchResultsResult}
 */
proto.loupe.services.api.ExportSearchResultsResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ExportSearchResultsResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ExportSearchResultsResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ExportSearchResultsResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ExportSearchResultsResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.loupe.services.api.ExportSearchResultsResult.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ExportSearchResultsResult} returns this
 */
proto.loupe.services.api.ExportSearchResultsResult.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_id = 2;
 * @return {string}
 */
proto.loupe.services.api.ExportSearchResultsResult.prototype.getFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ExportSearchResultsResult} returns this
 */
proto.loupe.services.api.ExportSearchResultsResult.prototype.setFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.ExportSearchResultsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ExportSearchResultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ExportSearchResultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ExportSearchResultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: (f = msg.getQuery()) && src_common_search_search_pb.SearchContainer.toObject(includeInstance, f),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.loupe.services.api.ExportSearchResultsFields.toObject, includeInstance),
    paging: (f = msg.getPaging()) && src_common_search_search_pb.SearchPaginationContainer.toObject(includeInstance, f),
    objectName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ExportSearchResultsRequest}
 */
proto.loupe.services.api.ExportSearchResultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ExportSearchResultsRequest;
  return proto.loupe.services.api.ExportSearchResultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ExportSearchResultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ExportSearchResultsRequest}
 */
proto.loupe.services.api.ExportSearchResultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_search_search_pb.SearchContainer;
      reader.readMessage(value,src_common_search_search_pb.SearchContainer.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.loupe.services.api.ExportSearchResultsFields;
      reader.readMessage(value,proto.loupe.services.api.ExportSearchResultsFields.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 3:
      var value = new src_common_search_search_pb.SearchPaginationContainer;
      reader.readMessage(value,src_common_search_search_pb.SearchPaginationContainer.deserializeBinaryFromReader);
      msg.setPaging(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ExportSearchResultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ExportSearchResultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ExportSearchResultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_search_search_pb.SearchContainer.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.services.api.ExportSearchResultsFields.serializeBinaryToWriter
    );
  }
  f = message.getPaging();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_search_search_pb.SearchPaginationContainer.serializeBinaryToWriter
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional loupe.common.search.SearchContainer query = 1;
 * @return {?proto.loupe.common.search.SearchContainer}
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.getQuery = function() {
  return /** @type{?proto.loupe.common.search.SearchContainer} */ (
    jspb.Message.getWrapperField(this, src_common_search_search_pb.SearchContainer, 1));
};


/**
 * @param {?proto.loupe.common.search.SearchContainer|undefined} value
 * @return {!proto.loupe.services.api.ExportSearchResultsRequest} returns this
*/
proto.loupe.services.api.ExportSearchResultsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.ExportSearchResultsRequest} returns this
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ExportSearchResultsFields fields = 2;
 * @return {!Array<!proto.loupe.services.api.ExportSearchResultsFields>}
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.loupe.services.api.ExportSearchResultsFields>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.api.ExportSearchResultsFields, 2));
};


/**
 * @param {!Array<!proto.loupe.services.api.ExportSearchResultsFields>} value
 * @return {!proto.loupe.services.api.ExportSearchResultsRequest} returns this
*/
proto.loupe.services.api.ExportSearchResultsRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.services.api.ExportSearchResultsFields=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.ExportSearchResultsFields}
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.services.api.ExportSearchResultsFields, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.ExportSearchResultsRequest} returns this
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional loupe.common.search.SearchPaginationContainer paging = 3;
 * @return {?proto.loupe.common.search.SearchPaginationContainer}
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.getPaging = function() {
  return /** @type{?proto.loupe.common.search.SearchPaginationContainer} */ (
    jspb.Message.getWrapperField(this, src_common_search_search_pb.SearchPaginationContainer, 3));
};


/**
 * @param {?proto.loupe.common.search.SearchPaginationContainer|undefined} value
 * @return {!proto.loupe.services.api.ExportSearchResultsRequest} returns this
*/
proto.loupe.services.api.ExportSearchResultsRequest.prototype.setPaging = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.ExportSearchResultsRequest} returns this
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.clearPaging = function() {
  return this.setPaging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.hasPaging = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string object_name = 4;
 * @return {string}
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ExportSearchResultsRequest} returns this
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string currency_code = 5;
 * @return {string}
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ExportSearchResultsRequest} returns this
 */
proto.loupe.services.api.ExportSearchResultsRequest.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ExportSearchResultsFields.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ExportSearchResultsFields.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ExportSearchResultsFields} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ExportSearchResultsFields.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ExportSearchResultsFields}
 */
proto.loupe.services.api.ExportSearchResultsFields.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ExportSearchResultsFields;
  return proto.loupe.services.api.ExportSearchResultsFields.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ExportSearchResultsFields} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ExportSearchResultsFields}
 */
proto.loupe.services.api.ExportSearchResultsFields.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ExportSearchResultsFields.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ExportSearchResultsFields.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ExportSearchResultsFields} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ExportSearchResultsFields.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.services.api.ExportSearchResultsFields.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ExportSearchResultsFields} returns this
 */
proto.loupe.services.api.ExportSearchResultsFields.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.services.api.ExportSearchResultsFields.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ExportSearchResultsFields} returns this
 */
proto.loupe.services.api.ExportSearchResultsFields.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetPipelineSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetPipelineSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetPipelineSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetPipelineSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetPipelineSettingRequest}
 */
proto.loupe.services.api.GetPipelineSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetPipelineSettingRequest;
  return proto.loupe.services.api.GetPipelineSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetPipelineSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetPipelineSettingRequest}
 */
proto.loupe.services.api.GetPipelineSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetPipelineSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetPipelineSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetPipelineSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetPipelineSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetPipelineSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetPipelineSettingRequest} returns this
 */
proto.loupe.services.api.GetPipelineSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetPipelineSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetPipelineSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetPipelineSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetPipelineSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_pipeline_pb.Pipeline.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetPipelineSettingResponse}
 */
proto.loupe.services.api.GetPipelineSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetPipelineSettingResponse;
  return proto.loupe.services.api.GetPipelineSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetPipelineSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetPipelineSettingResponse}
 */
proto.loupe.services.api.GetPipelineSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_setting_app_feature_pipeline_pb.Pipeline;
      reader.readMessage(value,src_common_setting_app_feature_pipeline_pb.Pipeline.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetPipelineSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetPipelineSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetPipelineSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetPipelineSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_setting_app_feature_pipeline_pb.Pipeline.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.setting.feature.Pipeline setting = 1;
 * @return {?proto.loupe.common.setting.feature.Pipeline}
 */
proto.loupe.services.api.GetPipelineSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.Pipeline} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_pipeline_pb.Pipeline, 1));
};


/**
 * @param {?proto.loupe.common.setting.feature.Pipeline|undefined} value
 * @return {!proto.loupe.services.api.GetPipelineSettingResponse} returns this
*/
proto.loupe.services.api.GetPipelineSettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetPipelineSettingResponse} returns this
 */
proto.loupe.services.api.GetPipelineSettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetPipelineSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertPipelineSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertPipelineSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertPipelineSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertPipelineSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_pipeline_pb.Pipeline.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertPipelineSettingRequest}
 */
proto.loupe.services.api.UpsertPipelineSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertPipelineSettingRequest;
  return proto.loupe.services.api.UpsertPipelineSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertPipelineSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertPipelineSettingRequest}
 */
proto.loupe.services.api.UpsertPipelineSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_pipeline_pb.Pipeline;
      reader.readMessage(value,src_common_setting_app_feature_pipeline_pb.Pipeline.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertPipelineSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertPipelineSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertPipelineSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertPipelineSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_pipeline_pb.Pipeline.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertPipelineSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertPipelineSettingRequest} returns this
 */
proto.loupe.services.api.UpsertPipelineSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.Pipeline setting = 2;
 * @return {?proto.loupe.common.setting.feature.Pipeline}
 */
proto.loupe.services.api.UpsertPipelineSettingRequest.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.Pipeline} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_pipeline_pb.Pipeline, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.Pipeline|undefined} value
 * @return {!proto.loupe.services.api.UpsertPipelineSettingRequest} returns this
*/
proto.loupe.services.api.UpsertPipelineSettingRequest.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertPipelineSettingRequest} returns this
 */
proto.loupe.services.api.UpsertPipelineSettingRequest.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertPipelineSettingRequest.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertPipelineSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertPipelineSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertPipelineSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertPipelineSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_pipeline_pb.Pipeline.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertPipelineSettingResponse}
 */
proto.loupe.services.api.UpsertPipelineSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertPipelineSettingResponse;
  return proto.loupe.services.api.UpsertPipelineSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertPipelineSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertPipelineSettingResponse}
 */
proto.loupe.services.api.UpsertPipelineSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_pipeline_pb.Pipeline;
      reader.readMessage(value,src_common_setting_app_feature_pipeline_pb.Pipeline.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertPipelineSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertPipelineSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertPipelineSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertPipelineSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_pipeline_pb.Pipeline.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertPipelineSettingResponse.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertPipelineSettingResponse} returns this
 */
proto.loupe.services.api.UpsertPipelineSettingResponse.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.Pipeline setting = 2;
 * @return {?proto.loupe.common.setting.feature.Pipeline}
 */
proto.loupe.services.api.UpsertPipelineSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.Pipeline} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_pipeline_pb.Pipeline, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.Pipeline|undefined} value
 * @return {!proto.loupe.services.api.UpsertPipelineSettingResponse} returns this
*/
proto.loupe.services.api.UpsertPipelineSettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertPipelineSettingResponse} returns this
 */
proto.loupe.services.api.UpsertPipelineSettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertPipelineSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetReportingSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetReportingSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetReportingSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetReportingSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetReportingSettingRequest}
 */
proto.loupe.services.api.GetReportingSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetReportingSettingRequest;
  return proto.loupe.services.api.GetReportingSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetReportingSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetReportingSettingRequest}
 */
proto.loupe.services.api.GetReportingSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetReportingSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetReportingSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetReportingSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetReportingSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetReportingSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetReportingSettingRequest} returns this
 */
proto.loupe.services.api.GetReportingSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetReportingSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetReportingSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetReportingSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetReportingSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_reporting_pb.Reporting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetReportingSettingResponse}
 */
proto.loupe.services.api.GetReportingSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetReportingSettingResponse;
  return proto.loupe.services.api.GetReportingSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetReportingSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetReportingSettingResponse}
 */
proto.loupe.services.api.GetReportingSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_setting_app_feature_reporting_pb.Reporting;
      reader.readMessage(value,src_common_setting_app_feature_reporting_pb.Reporting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetReportingSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetReportingSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetReportingSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetReportingSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_setting_app_feature_reporting_pb.Reporting.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.setting.feature.Reporting setting = 1;
 * @return {?proto.loupe.common.setting.feature.Reporting}
 */
proto.loupe.services.api.GetReportingSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.Reporting} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_reporting_pb.Reporting, 1));
};


/**
 * @param {?proto.loupe.common.setting.feature.Reporting|undefined} value
 * @return {!proto.loupe.services.api.GetReportingSettingResponse} returns this
*/
proto.loupe.services.api.GetReportingSettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetReportingSettingResponse} returns this
 */
proto.loupe.services.api.GetReportingSettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetReportingSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertReportingSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertReportingSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertReportingSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertReportingSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_reporting_pb.Reporting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertReportingSettingRequest}
 */
proto.loupe.services.api.UpsertReportingSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertReportingSettingRequest;
  return proto.loupe.services.api.UpsertReportingSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertReportingSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertReportingSettingRequest}
 */
proto.loupe.services.api.UpsertReportingSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_reporting_pb.Reporting;
      reader.readMessage(value,src_common_setting_app_feature_reporting_pb.Reporting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertReportingSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertReportingSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertReportingSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertReportingSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_reporting_pb.Reporting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertReportingSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertReportingSettingRequest} returns this
 */
proto.loupe.services.api.UpsertReportingSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.Reporting setting = 2;
 * @return {?proto.loupe.common.setting.feature.Reporting}
 */
proto.loupe.services.api.UpsertReportingSettingRequest.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.Reporting} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_reporting_pb.Reporting, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.Reporting|undefined} value
 * @return {!proto.loupe.services.api.UpsertReportingSettingRequest} returns this
*/
proto.loupe.services.api.UpsertReportingSettingRequest.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertReportingSettingRequest} returns this
 */
proto.loupe.services.api.UpsertReportingSettingRequest.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertReportingSettingRequest.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertReportingSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertReportingSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertReportingSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertReportingSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_reporting_pb.Reporting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertReportingSettingResponse}
 */
proto.loupe.services.api.UpsertReportingSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertReportingSettingResponse;
  return proto.loupe.services.api.UpsertReportingSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertReportingSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertReportingSettingResponse}
 */
proto.loupe.services.api.UpsertReportingSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_reporting_pb.Reporting;
      reader.readMessage(value,src_common_setting_app_feature_reporting_pb.Reporting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertReportingSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertReportingSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertReportingSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertReportingSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_reporting_pb.Reporting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertReportingSettingResponse.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertReportingSettingResponse} returns this
 */
proto.loupe.services.api.UpsertReportingSettingResponse.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.Reporting setting = 2;
 * @return {?proto.loupe.common.setting.feature.Reporting}
 */
proto.loupe.services.api.UpsertReportingSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.Reporting} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_reporting_pb.Reporting, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.Reporting|undefined} value
 * @return {!proto.loupe.services.api.UpsertReportingSettingResponse} returns this
*/
proto.loupe.services.api.UpsertReportingSettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertReportingSettingResponse} returns this
 */
proto.loupe.services.api.UpsertReportingSettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertReportingSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetReportingOptionsSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetReportingOptionsSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetReportingOptionsSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetReportingOptionsSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetReportingOptionsSettingRequest}
 */
proto.loupe.services.api.GetReportingOptionsSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetReportingOptionsSettingRequest;
  return proto.loupe.services.api.GetReportingOptionsSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetReportingOptionsSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetReportingOptionsSettingRequest}
 */
proto.loupe.services.api.GetReportingOptionsSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetReportingOptionsSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetReportingOptionsSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetReportingOptionsSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetReportingOptionsSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetReportingOptionsSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetReportingOptionsSettingRequest} returns this
 */
proto.loupe.services.api.GetReportingOptionsSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetReportingOptionsSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetReportingOptionsSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetReportingOptionsSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetReportingOptionsSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetReportingOptionsSettingResponse}
 */
proto.loupe.services.api.GetReportingOptionsSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetReportingOptionsSettingResponse;
  return proto.loupe.services.api.GetReportingOptionsSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetReportingOptionsSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetReportingOptionsSettingResponse}
 */
proto.loupe.services.api.GetReportingOptionsSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting;
      reader.readMessage(value,src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetReportingOptionsSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetReportingOptionsSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetReportingOptionsSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetReportingOptionsSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.setting.feature.ReportingOptionsSetting setting = 1;
 * @return {?proto.loupe.common.setting.feature.ReportingOptionsSetting}
 */
proto.loupe.services.api.GetReportingOptionsSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.ReportingOptionsSetting} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting, 1));
};


/**
 * @param {?proto.loupe.common.setting.feature.ReportingOptionsSetting|undefined} value
 * @return {!proto.loupe.services.api.GetReportingOptionsSettingResponse} returns this
*/
proto.loupe.services.api.GetReportingOptionsSettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetReportingOptionsSettingResponse} returns this
 */
proto.loupe.services.api.GetReportingOptionsSettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetReportingOptionsSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertReportingOptionsSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertReportingOptionsSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertReportingOptionsSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertReportingOptionsSettingRequest}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertReportingOptionsSettingRequest;
  return proto.loupe.services.api.UpsertReportingOptionsSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertReportingOptionsSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertReportingOptionsSettingRequest}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting;
      reader.readMessage(value,src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertReportingOptionsSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertReportingOptionsSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertReportingOptionsSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertReportingOptionsSettingRequest} returns this
 */
proto.loupe.services.api.UpsertReportingOptionsSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.ReportingOptionsSetting setting = 2;
 * @return {?proto.loupe.common.setting.feature.ReportingOptionsSetting}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingRequest.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.ReportingOptionsSetting} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.ReportingOptionsSetting|undefined} value
 * @return {!proto.loupe.services.api.UpsertReportingOptionsSettingRequest} returns this
*/
proto.loupe.services.api.UpsertReportingOptionsSettingRequest.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertReportingOptionsSettingRequest} returns this
 */
proto.loupe.services.api.UpsertReportingOptionsSettingRequest.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingRequest.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertReportingOptionsSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertReportingOptionsSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertReportingOptionsSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertReportingOptionsSettingResponse}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertReportingOptionsSettingResponse;
  return proto.loupe.services.api.UpsertReportingOptionsSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertReportingOptionsSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertReportingOptionsSettingResponse}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting;
      reader.readMessage(value,src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertReportingOptionsSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertReportingOptionsSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertReportingOptionsSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingResponse.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertReportingOptionsSettingResponse} returns this
 */
proto.loupe.services.api.UpsertReportingOptionsSettingResponse.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.ReportingOptionsSetting setting = 2;
 * @return {?proto.loupe.common.setting.feature.ReportingOptionsSetting}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.ReportingOptionsSetting} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_reporting_options_pb.ReportingOptionsSetting, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.ReportingOptionsSetting|undefined} value
 * @return {!proto.loupe.services.api.UpsertReportingOptionsSettingResponse} returns this
*/
proto.loupe.services.api.UpsertReportingOptionsSettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertReportingOptionsSettingResponse} returns this
 */
proto.loupe.services.api.UpsertReportingOptionsSettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertReportingOptionsSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetScenarioPlannerSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetScenarioPlannerSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetScenarioPlannerSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetScenarioPlannerSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetScenarioPlannerSettingRequest}
 */
proto.loupe.services.api.GetScenarioPlannerSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetScenarioPlannerSettingRequest;
  return proto.loupe.services.api.GetScenarioPlannerSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetScenarioPlannerSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetScenarioPlannerSettingRequest}
 */
proto.loupe.services.api.GetScenarioPlannerSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetScenarioPlannerSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetScenarioPlannerSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetScenarioPlannerSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetScenarioPlannerSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetScenarioPlannerSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetScenarioPlannerSettingRequest} returns this
 */
proto.loupe.services.api.GetScenarioPlannerSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetScenarioPlannerSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetScenarioPlannerSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetScenarioPlannerSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetScenarioPlannerSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetScenarioPlannerSettingResponse}
 */
proto.loupe.services.api.GetScenarioPlannerSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetScenarioPlannerSettingResponse;
  return proto.loupe.services.api.GetScenarioPlannerSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetScenarioPlannerSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetScenarioPlannerSettingResponse}
 */
proto.loupe.services.api.GetScenarioPlannerSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner;
      reader.readMessage(value,src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetScenarioPlannerSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetScenarioPlannerSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetScenarioPlannerSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetScenarioPlannerSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.setting.feature.ScenarioPlanner setting = 1;
 * @return {?proto.loupe.common.setting.feature.ScenarioPlanner}
 */
proto.loupe.services.api.GetScenarioPlannerSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.ScenarioPlanner} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner, 1));
};


/**
 * @param {?proto.loupe.common.setting.feature.ScenarioPlanner|undefined} value
 * @return {!proto.loupe.services.api.GetScenarioPlannerSettingResponse} returns this
*/
proto.loupe.services.api.GetScenarioPlannerSettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetScenarioPlannerSettingResponse} returns this
 */
proto.loupe.services.api.GetScenarioPlannerSettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetScenarioPlannerSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertScenarioPlannerSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertScenarioPlannerSettingRequest}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertScenarioPlannerSettingRequest;
  return proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertScenarioPlannerSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertScenarioPlannerSettingRequest}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner;
      reader.readMessage(value,src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertScenarioPlannerSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertScenarioPlannerSettingRequest} returns this
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.ScenarioPlanner setting = 2;
 * @return {?proto.loupe.common.setting.feature.ScenarioPlanner}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.ScenarioPlanner} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.ScenarioPlanner|undefined} value
 * @return {!proto.loupe.services.api.UpsertScenarioPlannerSettingRequest} returns this
*/
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertScenarioPlannerSettingRequest} returns this
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingRequest.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertScenarioPlannerSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertScenarioPlannerSettingResponse}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertScenarioPlannerSettingResponse;
  return proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertScenarioPlannerSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertScenarioPlannerSettingResponse}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner;
      reader.readMessage(value,src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertScenarioPlannerSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertScenarioPlannerSettingResponse} returns this
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.ScenarioPlanner setting = 2;
 * @return {?proto.loupe.common.setting.feature.ScenarioPlanner}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.ScenarioPlanner} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_scenario_planner_pb.ScenarioPlanner, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.ScenarioPlanner|undefined} value
 * @return {!proto.loupe.services.api.UpsertScenarioPlannerSettingResponse} returns this
*/
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertScenarioPlannerSettingResponse} returns this
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertScenarioPlannerSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetForecastingSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetForecastingSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetForecastingSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetForecastingSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetForecastingSettingRequest}
 */
proto.loupe.services.api.GetForecastingSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetForecastingSettingRequest;
  return proto.loupe.services.api.GetForecastingSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetForecastingSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetForecastingSettingRequest}
 */
proto.loupe.services.api.GetForecastingSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetForecastingSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetForecastingSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetForecastingSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetForecastingSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetForecastingSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetForecastingSettingRequest} returns this
 */
proto.loupe.services.api.GetForecastingSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetForecastingSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetForecastingSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetForecastingSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetForecastingSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetForecastingSettingResponse}
 */
proto.loupe.services.api.GetForecastingSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetForecastingSettingResponse;
  return proto.loupe.services.api.GetForecastingSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetForecastingSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetForecastingSettingResponse}
 */
proto.loupe.services.api.GetForecastingSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting;
      reader.readMessage(value,src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetForecastingSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetForecastingSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetForecastingSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetForecastingSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.setting.feature.forecasting.Forecasting setting = 1;
 * @return {?proto.loupe.common.setting.feature.forecasting.Forecasting}
 */
proto.loupe.services.api.GetForecastingSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.forecasting.Forecasting} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting, 1));
};


/**
 * @param {?proto.loupe.common.setting.feature.forecasting.Forecasting|undefined} value
 * @return {!proto.loupe.services.api.GetForecastingSettingResponse} returns this
*/
proto.loupe.services.api.GetForecastingSettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetForecastingSettingResponse} returns this
 */
proto.loupe.services.api.GetForecastingSettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetForecastingSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertForecastingSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertForecastingSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertForecastingSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertForecastingSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertForecastingSettingRequest}
 */
proto.loupe.services.api.UpsertForecastingSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertForecastingSettingRequest;
  return proto.loupe.services.api.UpsertForecastingSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertForecastingSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertForecastingSettingRequest}
 */
proto.loupe.services.api.UpsertForecastingSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting;
      reader.readMessage(value,src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertForecastingSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertForecastingSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertForecastingSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertForecastingSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertForecastingSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertForecastingSettingRequest} returns this
 */
proto.loupe.services.api.UpsertForecastingSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.forecasting.Forecasting setting = 2;
 * @return {?proto.loupe.common.setting.feature.forecasting.Forecasting}
 */
proto.loupe.services.api.UpsertForecastingSettingRequest.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.forecasting.Forecasting} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.forecasting.Forecasting|undefined} value
 * @return {!proto.loupe.services.api.UpsertForecastingSettingRequest} returns this
*/
proto.loupe.services.api.UpsertForecastingSettingRequest.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertForecastingSettingRequest} returns this
 */
proto.loupe.services.api.UpsertForecastingSettingRequest.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertForecastingSettingRequest.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertForecastingSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertForecastingSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertForecastingSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertForecastingSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertForecastingSettingResponse}
 */
proto.loupe.services.api.UpsertForecastingSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertForecastingSettingResponse;
  return proto.loupe.services.api.UpsertForecastingSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertForecastingSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertForecastingSettingResponse}
 */
proto.loupe.services.api.UpsertForecastingSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting;
      reader.readMessage(value,src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertForecastingSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertForecastingSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertForecastingSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertForecastingSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertForecastingSettingResponse.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertForecastingSettingResponse} returns this
 */
proto.loupe.services.api.UpsertForecastingSettingResponse.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.forecasting.Forecasting setting = 2;
 * @return {?proto.loupe.common.setting.feature.forecasting.Forecasting}
 */
proto.loupe.services.api.UpsertForecastingSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.forecasting.Forecasting} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_forecasting_forecasting_pb.Forecasting, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.forecasting.Forecasting|undefined} value
 * @return {!proto.loupe.services.api.UpsertForecastingSettingResponse} returns this
*/
proto.loupe.services.api.UpsertForecastingSettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertForecastingSettingResponse} returns this
 */
proto.loupe.services.api.UpsertForecastingSettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertForecastingSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpdateSandboxRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpdateSandboxRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpdateSandboxRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpdateSandboxRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sandboxId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpdateSandboxRequest}
 */
proto.loupe.services.api.UpdateSandboxRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpdateSandboxRequest;
  return proto.loupe.services.api.UpdateSandboxRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpdateSandboxRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpdateSandboxRequest}
 */
proto.loupe.services.api.UpdateSandboxRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSandboxId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpdateSandboxRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpdateSandboxRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpdateSandboxRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpdateSandboxRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSandboxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpdateSandboxRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpdateSandboxRequest} returns this
 */
proto.loupe.services.api.UpdateSandboxRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sandbox_id = 2;
 * @return {string}
 */
proto.loupe.services.api.UpdateSandboxRequest.prototype.getSandboxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpdateSandboxRequest} returns this
 */
proto.loupe.services.api.UpdateSandboxRequest.prototype.setSandboxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.loupe.services.api.UpdateSandboxRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpdateSandboxRequest} returns this
 */
proto.loupe.services.api.UpdateSandboxRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.loupe.services.api.UpdateSandboxRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpdateSandboxRequest} returns this
 */
proto.loupe.services.api.UpdateSandboxRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpdateSandboxResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpdateSandboxResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpdateSandboxResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpdateSandboxResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sandbox: (f = msg.getSandbox()) && src_common_tenant_tenant_pb.Tenant.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpdateSandboxResponse}
 */
proto.loupe.services.api.UpdateSandboxResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpdateSandboxResponse;
  return proto.loupe.services.api.UpdateSandboxResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpdateSandboxResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpdateSandboxResponse}
 */
proto.loupe.services.api.UpdateSandboxResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_tenant_tenant_pb.Tenant;
      reader.readMessage(value,src_common_tenant_tenant_pb.Tenant.deserializeBinaryFromReader);
      msg.setSandbox(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpdateSandboxResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpdateSandboxResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpdateSandboxResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpdateSandboxResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSandbox();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_tenant_tenant_pb.Tenant.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.tenant.Tenant sandbox = 1;
 * @return {?proto.loupe.common.tenant.Tenant}
 */
proto.loupe.services.api.UpdateSandboxResponse.prototype.getSandbox = function() {
  return /** @type{?proto.loupe.common.tenant.Tenant} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_tenant_pb.Tenant, 1));
};


/**
 * @param {?proto.loupe.common.tenant.Tenant|undefined} value
 * @return {!proto.loupe.services.api.UpdateSandboxResponse} returns this
*/
proto.loupe.services.api.UpdateSandboxResponse.prototype.setSandbox = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpdateSandboxResponse} returns this
 */
proto.loupe.services.api.UpdateSandboxResponse.prototype.clearSandbox = function() {
  return this.setSandbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpdateSandboxResponse.prototype.hasSandbox = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.DeleteSandboxRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.DeleteSandboxRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.DeleteSandboxRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.DeleteSandboxRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sandboxId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.DeleteSandboxRequest}
 */
proto.loupe.services.api.DeleteSandboxRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.DeleteSandboxRequest;
  return proto.loupe.services.api.DeleteSandboxRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.DeleteSandboxRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.DeleteSandboxRequest}
 */
proto.loupe.services.api.DeleteSandboxRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSandboxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.DeleteSandboxRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.DeleteSandboxRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.DeleteSandboxRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.DeleteSandboxRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSandboxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.DeleteSandboxRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.DeleteSandboxRequest} returns this
 */
proto.loupe.services.api.DeleteSandboxRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sandbox_id = 2;
 * @return {string}
 */
proto.loupe.services.api.DeleteSandboxRequest.prototype.getSandboxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.DeleteSandboxRequest} returns this
 */
proto.loupe.services.api.DeleteSandboxRequest.prototype.setSandboxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.DeleteSandboxResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.DeleteSandboxResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.DeleteSandboxResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.DeleteSandboxResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.DeleteSandboxResponse}
 */
proto.loupe.services.api.DeleteSandboxResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.DeleteSandboxResponse;
  return proto.loupe.services.api.DeleteSandboxResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.DeleteSandboxResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.DeleteSandboxResponse}
 */
proto.loupe.services.api.DeleteSandboxResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.DeleteSandboxResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.DeleteSandboxResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.DeleteSandboxResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.DeleteSandboxResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ListSandboxesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ListSandboxesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ListSandboxesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ListSandboxesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ListSandboxesRequest}
 */
proto.loupe.services.api.ListSandboxesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ListSandboxesRequest;
  return proto.loupe.services.api.ListSandboxesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ListSandboxesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ListSandboxesRequest}
 */
proto.loupe.services.api.ListSandboxesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ListSandboxesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ListSandboxesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ListSandboxesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ListSandboxesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.ListSandboxesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ListSandboxesRequest} returns this
 */
proto.loupe.services.api.ListSandboxesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.ListSandboxesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ListSandboxesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ListSandboxesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ListSandboxesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ListSandboxesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sandboxesList: jspb.Message.toObjectList(msg.getSandboxesList(),
    src_common_tenant_tenant_pb.Tenant.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ListSandboxesResponse}
 */
proto.loupe.services.api.ListSandboxesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ListSandboxesResponse;
  return proto.loupe.services.api.ListSandboxesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ListSandboxesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ListSandboxesResponse}
 */
proto.loupe.services.api.ListSandboxesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_tenant_tenant_pb.Tenant;
      reader.readMessage(value,src_common_tenant_tenant_pb.Tenant.deserializeBinaryFromReader);
      msg.addSandboxes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ListSandboxesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ListSandboxesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ListSandboxesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ListSandboxesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSandboxesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_tenant_tenant_pb.Tenant.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.tenant.Tenant sandboxes = 1;
 * @return {!Array<!proto.loupe.common.tenant.Tenant>}
 */
proto.loupe.services.api.ListSandboxesResponse.prototype.getSandboxesList = function() {
  return /** @type{!Array<!proto.loupe.common.tenant.Tenant>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_tenant_tenant_pb.Tenant, 1));
};


/**
 * @param {!Array<!proto.loupe.common.tenant.Tenant>} value
 * @return {!proto.loupe.services.api.ListSandboxesResponse} returns this
*/
proto.loupe.services.api.ListSandboxesResponse.prototype.setSandboxesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.tenant.Tenant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.tenant.Tenant}
 */
proto.loupe.services.api.ListSandboxesResponse.prototype.addSandboxes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.tenant.Tenant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.ListSandboxesResponse} returns this
 */
proto.loupe.services.api.ListSandboxesResponse.prototype.clearSandboxesList = function() {
  return this.setSandboxesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetSandboxRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetSandboxRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetSandboxRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetSandboxRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sandboxId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetSandboxRequest}
 */
proto.loupe.services.api.GetSandboxRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetSandboxRequest;
  return proto.loupe.services.api.GetSandboxRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetSandboxRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetSandboxRequest}
 */
proto.loupe.services.api.GetSandboxRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSandboxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetSandboxRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetSandboxRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetSandboxRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetSandboxRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSandboxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetSandboxRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetSandboxRequest} returns this
 */
proto.loupe.services.api.GetSandboxRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sandbox_id = 2;
 * @return {string}
 */
proto.loupe.services.api.GetSandboxRequest.prototype.getSandboxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetSandboxRequest} returns this
 */
proto.loupe.services.api.GetSandboxRequest.prototype.setSandboxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetSandboxResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetSandboxResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetSandboxResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetSandboxResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sandbox: (f = msg.getSandbox()) && src_common_tenant_tenant_pb.Tenant.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetSandboxResponse}
 */
proto.loupe.services.api.GetSandboxResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetSandboxResponse;
  return proto.loupe.services.api.GetSandboxResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetSandboxResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetSandboxResponse}
 */
proto.loupe.services.api.GetSandboxResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_tenant_tenant_pb.Tenant;
      reader.readMessage(value,src_common_tenant_tenant_pb.Tenant.deserializeBinaryFromReader);
      msg.setSandbox(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetSandboxResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetSandboxResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetSandboxResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetSandboxResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSandbox();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_tenant_tenant_pb.Tenant.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.tenant.Tenant sandbox = 1;
 * @return {?proto.loupe.common.tenant.Tenant}
 */
proto.loupe.services.api.GetSandboxResponse.prototype.getSandbox = function() {
  return /** @type{?proto.loupe.common.tenant.Tenant} */ (
    jspb.Message.getWrapperField(this, src_common_tenant_tenant_pb.Tenant, 1));
};


/**
 * @param {?proto.loupe.common.tenant.Tenant|undefined} value
 * @return {!proto.loupe.services.api.GetSandboxResponse} returns this
*/
proto.loupe.services.api.GetSandboxResponse.prototype.setSandbox = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetSandboxResponse} returns this
 */
proto.loupe.services.api.GetSandboxResponse.prototype.clearSandbox = function() {
  return this.setSandbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetSandboxResponse.prototype.hasSandbox = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.CreatePersonWithGroupViewerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    person: (f = msg.getPerson()) && src_common_orchard_person_pb.Person.toObject(includeInstance, f),
    groupViewerIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.CreatePersonWithGroupViewerRequest;
  return proto.loupe.services.api.CreatePersonWithGroupViewerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupViewerIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.CreatePersonWithGroupViewerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getGroupViewerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.orchard.Person person = 2;
 * @return {?proto.loupe.common.orchard.Person}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.prototype.getPerson = function() {
  return /** @type{?proto.loupe.common.orchard.Person} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_person_pb.Person, 2));
};


/**
 * @param {?proto.loupe.common.orchard.Person|undefined} value
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest} returns this
*/
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string group_viewer_ids = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.prototype.getGroupViewerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.prototype.setGroupViewerIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.prototype.addGroupViewerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.CreatePersonWithGroupViewerRequest.prototype.clearGroupViewerIdsList = function() {
  return this.setGroupViewerIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.CreatePersonWithGroupViewerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.CreatePersonWithGroupViewerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    person: (f = msg.getPerson()) && src_common_orchard_person_pb.Person.toObject(includeInstance, f),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    src_common_orchard_group_pb.Group.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerResponse}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.CreatePersonWithGroupViewerResponse;
  return proto.loupe.services.api.CreatePersonWithGroupViewerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.CreatePersonWithGroupViewerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerResponse}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    case 2:
      var value = new src_common_orchard_group_pb.Group;
      reader.readMessage(value,src_common_orchard_group_pb.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.CreatePersonWithGroupViewerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.CreatePersonWithGroupViewerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_orchard_group_pb.Group.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.Person person = 1;
 * @return {?proto.loupe.common.orchard.Person}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.prototype.getPerson = function() {
  return /** @type{?proto.loupe.common.orchard.Person} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_person_pb.Person, 1));
};


/**
 * @param {?proto.loupe.common.orchard.Person|undefined} value
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerResponse} returns this
*/
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerResponse} returns this
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated loupe.common.orchard.Group groups = 2;
 * @return {!Array<!proto.loupe.common.orchard.Group>}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_group_pb.Group, 2));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Group>} value
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerResponse} returns this
*/
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.orchard.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Group}
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.orchard.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.CreatePersonWithGroupViewerResponse} returns this
 */
proto.loupe.services.api.CreatePersonWithGroupViewerResponse.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    personId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    person: (f = msg.getPerson()) && src_common_orchard_person_pb.Person.toObject(includeInstance, f),
    onlyFieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    groupViewerIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpdatePersonWithGroupViewerRequest;
  return proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonId(value);
      break;
    case 3:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addOnlyFields(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupViewerIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPersonId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getOnlyFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getGroupViewerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string person_id = 2;
 * @return {string}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.getPersonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional loupe.common.orchard.Person person = 3;
 * @return {?proto.loupe.common.orchard.Person}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.getPerson = function() {
  return /** @type{?proto.loupe.common.orchard.Person} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_person_pb.Person, 3));
};


/**
 * @param {?proto.loupe.common.orchard.Person|undefined} value
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} returns this
*/
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string only_fields = 4;
 * @return {!Array<string>}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.getOnlyFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.setOnlyFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.addOnlyFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.clearOnlyFieldsList = function() {
  return this.setOnlyFieldsList([]);
};


/**
 * repeated string group_viewer_ids = 5;
 * @return {!Array<string>}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.getGroupViewerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.setGroupViewerIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.addGroupViewerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerRequest} returns this
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerRequest.prototype.clearGroupViewerIdsList = function() {
  return this.setGroupViewerIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpdatePersonWithGroupViewerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    person: (f = msg.getPerson()) && src_common_orchard_person_pb.Person.toObject(includeInstance, f),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    src_common_orchard_group_pb.Group.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerResponse}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpdatePersonWithGroupViewerResponse;
  return proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpdatePersonWithGroupViewerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerResponse}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_orchard_person_pb.Person;
      reader.readMessage(value,src_common_orchard_person_pb.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    case 2:
      var value = new src_common_orchard_group_pb.Group;
      reader.readMessage(value,src_common_orchard_group_pb.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpdatePersonWithGroupViewerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_orchard_person_pb.Person.serializeBinaryToWriter
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      src_common_orchard_group_pb.Group.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.orchard.Person person = 1;
 * @return {?proto.loupe.common.orchard.Person}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.prototype.getPerson = function() {
  return /** @type{?proto.loupe.common.orchard.Person} */ (
    jspb.Message.getWrapperField(this, src_common_orchard_person_pb.Person, 1));
};


/**
 * @param {?proto.loupe.common.orchard.Person|undefined} value
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerResponse} returns this
*/
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerResponse} returns this
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated loupe.common.orchard.Group groups = 2;
 * @return {!Array<!proto.loupe.common.orchard.Group>}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.loupe.common.orchard.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_orchard_group_pb.Group, 2));
};


/**
 * @param {!Array<!proto.loupe.common.orchard.Group>} value
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerResponse} returns this
*/
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.common.orchard.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.orchard.Group}
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.common.orchard.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.UpdatePersonWithGroupViewerResponse} returns this
 */
proto.loupe.services.api.UpdatePersonWithGroupViewerResponse.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetTenantLicenseInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetTenantLicenseInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetTenantLicenseInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantLicenseInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetTenantLicenseInfoRequest}
 */
proto.loupe.services.api.GetTenantLicenseInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetTenantLicenseInfoRequest;
  return proto.loupe.services.api.GetTenantLicenseInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetTenantLicenseInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetTenantLicenseInfoRequest}
 */
proto.loupe.services.api.GetTenantLicenseInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetTenantLicenseInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetTenantLicenseInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetTenantLicenseInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantLicenseInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetTenantLicenseInfoRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetTenantLicenseInfoRequest} returns this
 */
proto.loupe.services.api.GetTenantLicenseInfoRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetTenantLicenseInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetTenantLicenseInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetTenantLicenseInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantLicenseInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    filledSeats: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetTenantLicenseInfoResponse}
 */
proto.loupe.services.api.GetTenantLicenseInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetTenantLicenseInfoResponse;
  return proto.loupe.services.api.GetTenantLicenseInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetTenantLicenseInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetTenantLicenseInfoResponse}
 */
proto.loupe.services.api.GetTenantLicenseInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFilledSeats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetTenantLicenseInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetTenantLicenseInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetTenantLicenseInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetTenantLicenseInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilledSeats();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 filled_seats = 1;
 * @return {number}
 */
proto.loupe.services.api.GetTenantLicenseInfoResponse.prototype.getFilledSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.GetTenantLicenseInfoResponse} returns this
 */
proto.loupe.services.api.GetTenantLicenseInfoResponse.prototype.setFilledSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.RunRealtimeQueriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.RunRealtimeQueriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    personId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    realtimeQueriesMap: (f = msg.getRealtimeQueriesMap()) ? f.toObject(includeInstance, proto.loupe.services.api.RealtimeQueryRequestContainer.toObject) : [],
    tenantStandardParams: (f = msg.getTenantStandardParams()) && src_common_canvas_canvas_pb.CanvasTenantStandardParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.RunRealtimeQueriesRequest}
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.RunRealtimeQueriesRequest;
  return proto.loupe.services.api.RunRealtimeQueriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.RunRealtimeQueriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.RunRealtimeQueriesRequest}
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonId(value);
      break;
    case 4:
      var value = msg.getRealtimeQueriesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.services.api.RealtimeQueryRequestContainer.deserializeBinaryFromReader, "", new proto.loupe.services.api.RealtimeQueryRequestContainer());
         });
      break;
    case 5:
      var value = new src_common_canvas_canvas_pb.CanvasTenantStandardParams;
      reader.readMessage(value,src_common_canvas_canvas_pb.CanvasTenantStandardParams.deserializeBinaryFromReader);
      msg.setTenantStandardParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.RunRealtimeQueriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.RunRealtimeQueriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPersonId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRealtimeQueriesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.services.api.RealtimeQueryRequestContainer.serializeBinaryToWriter);
  }
  f = message.getTenantStandardParams();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      src_common_canvas_canvas_pb.CanvasTenantStandardParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.RunRealtimeQueriesRequest} returns this
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.RunRealtimeQueriesRequest} returns this
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string person_id = 3;
 * @return {string}
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.getPersonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.RunRealtimeQueriesRequest} returns this
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, RealtimeQueryRequestContainer> realtime_queries = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.services.api.RealtimeQueryRequestContainer>}
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.getRealtimeQueriesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.services.api.RealtimeQueryRequestContainer>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.loupe.services.api.RealtimeQueryRequestContainer));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.api.RunRealtimeQueriesRequest} returns this
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.clearRealtimeQueriesMap = function() {
  this.getRealtimeQueriesMap().clear();
  return this;};


/**
 * optional loupe.common.canvas.CanvasTenantStandardParams tenant_standard_params = 5;
 * @return {?proto.loupe.common.canvas.CanvasTenantStandardParams}
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.getTenantStandardParams = function() {
  return /** @type{?proto.loupe.common.canvas.CanvasTenantStandardParams} */ (
    jspb.Message.getWrapperField(this, src_common_canvas_canvas_pb.CanvasTenantStandardParams, 5));
};


/**
 * @param {?proto.loupe.common.canvas.CanvasTenantStandardParams|undefined} value
 * @return {!proto.loupe.services.api.RunRealtimeQueriesRequest} returns this
*/
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.setTenantStandardParams = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.RunRealtimeQueriesRequest} returns this
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.clearTenantStandardParams = function() {
  return this.setTenantStandardParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.RunRealtimeQueriesRequest.prototype.hasTenantStandardParams = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.RealtimeQueryRequestContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.RealtimeQueryRequestContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    params: (f = msg.getParams()) && src_common_realtime_query_pb.QueryParameters.toObject(includeInstance, f),
    nlgMap: (f = msg.getNlgMap()) ? f.toObject(includeInstance, undefined) : [],
    gml: (f = msg.getGml()) && src_common_gml_gml_pb.GML.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.RealtimeQueryRequestContainer}
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.RealtimeQueryRequestContainer;
  return proto.loupe.services.api.RealtimeQueryRequestContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.RealtimeQueryRequestContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.RealtimeQueryRequestContainer}
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryKey(value);
      break;
    case 2:
      var value = new src_common_realtime_query_pb.QueryParameters;
      reader.readMessage(value,src_common_realtime_query_pb.QueryParameters.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    case 3:
      var value = msg.getNlgMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBytes, null, "", "");
         });
      break;
    case 4:
      var value = new src_common_gml_gml_pb.GML;
      reader.readMessage(value,src_common_gml_gml_pb.GML.deserializeBinaryFromReader);
      msg.setGml(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.RealtimeQueryRequestContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.RealtimeQueryRequestContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_realtime_query_pb.QueryParameters.serializeBinaryToWriter
    );
  }
  f = message.getNlgMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBytes);
  }
  f = message.getGml();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      src_common_gml_gml_pb.GML.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query_key = 1;
 * @return {string}
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.getQueryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.RealtimeQueryRequestContainer} returns this
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.setQueryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.realtime.QueryParameters params = 2;
 * @return {?proto.loupe.common.realtime.QueryParameters}
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.getParams = function() {
  return /** @type{?proto.loupe.common.realtime.QueryParameters} */ (
    jspb.Message.getWrapperField(this, src_common_realtime_query_pb.QueryParameters, 2));
};


/**
 * @param {?proto.loupe.common.realtime.QueryParameters|undefined} value
 * @return {!proto.loupe.services.api.RealtimeQueryRequestContainer} returns this
*/
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.RealtimeQueryRequestContainer} returns this
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * map<string, bytes> nlg = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!(string|Uint8Array)>}
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.getNlgMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!(string|Uint8Array)>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.api.RealtimeQueryRequestContainer} returns this
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.clearNlgMap = function() {
  this.getNlgMap().clear();
  return this;};


/**
 * optional loupe.common.gml.GML gml = 4;
 * @return {?proto.loupe.common.gml.GML}
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.getGml = function() {
  return /** @type{?proto.loupe.common.gml.GML} */ (
    jspb.Message.getWrapperField(this, src_common_gml_gml_pb.GML, 4));
};


/**
 * @param {?proto.loupe.common.gml.GML|undefined} value
 * @return {!proto.loupe.services.api.RealtimeQueryRequestContainer} returns this
*/
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.setGml = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.RealtimeQueryRequestContainer} returns this
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.clearGml = function() {
  return this.setGml(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.RealtimeQueryRequestContainer.prototype.hasGml = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.RunRealtimeQueriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.RunRealtimeQueriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.RunRealtimeQueriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.RunRealtimeQueriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responsesMap: (f = msg.getResponsesMap()) ? f.toObject(includeInstance, proto.loupe.services.api.RealtimeQueryResponseContainer.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.RunRealtimeQueriesResponse}
 */
proto.loupe.services.api.RunRealtimeQueriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.RunRealtimeQueriesResponse;
  return proto.loupe.services.api.RunRealtimeQueriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.RunRealtimeQueriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.RunRealtimeQueriesResponse}
 */
proto.loupe.services.api.RunRealtimeQueriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getResponsesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.services.api.RealtimeQueryResponseContainer.deserializeBinaryFromReader, "", new proto.loupe.services.api.RealtimeQueryResponseContainer());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.RunRealtimeQueriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.RunRealtimeQueriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.RunRealtimeQueriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.RunRealtimeQueriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponsesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.services.api.RealtimeQueryResponseContainer.serializeBinaryToWriter);
  }
};


/**
 * map<string, RealtimeQueryResponseContainer> responses = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.services.api.RealtimeQueryResponseContainer>}
 */
proto.loupe.services.api.RunRealtimeQueriesResponse.prototype.getResponsesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.services.api.RealtimeQueryResponseContainer>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.loupe.services.api.RealtimeQueryResponseContainer));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.api.RunRealtimeQueriesResponse} returns this
 */
proto.loupe.services.api.RunRealtimeQueriesResponse.prototype.clearResponsesMap = function() {
  this.getResponsesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.RealtimeQueryResponseContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.RealtimeQueryResponseContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, 0),
    responsePayload: msg.getResponsePayload_asB64(),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    nlgResponseMap: (f = msg.getNlgResponseMap()) ? f.toObject(includeInstance, undefined) : [],
    gmlResponse: (f = msg.getGmlResponse()) && src_common_gml_gml_pb.GML.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.RealtimeQueryResponseContainer}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.RealtimeQueryResponseContainer;
  return proto.loupe.services.api.RealtimeQueryResponseContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.RealtimeQueryResponseContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.RealtimeQueryResponseContainer}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryKey(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.services.hotspring.ErrorCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setResponsePayload(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    case 5:
      var value = /** @type {!proto.loupe.services.api.RealtimeQueryStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = msg.getNlgResponseMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 7:
      var value = new src_common_gml_gml_pb.GML;
      reader.readMessage(value,src_common_gml_gml_pb.GML.deserializeBinaryFromReader);
      msg.setGmlResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.RealtimeQueryResponseContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.RealtimeQueryResponseContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getResponsePayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getNlgResponseMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getGmlResponse();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      src_common_gml_gml_pb.GML.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query_key = 1;
 * @return {string}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.getQueryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.RealtimeQueryResponseContainer} returns this
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.setQueryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.services.hotspring.ErrorCode code = 2;
 * @return {!proto.loupe.services.hotspring.ErrorCode}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.getCode = function() {
  return /** @type {!proto.loupe.services.hotspring.ErrorCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.services.hotspring.ErrorCode} value
 * @return {!proto.loupe.services.api.RealtimeQueryResponseContainer} returns this
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bytes response_payload = 3;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.getResponsePayload = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes response_payload = 3;
 * This is a type-conversion wrapper around `getResponsePayload()`
 * @return {string}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.getResponsePayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getResponsePayload()));
};


/**
 * optional bytes response_payload = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getResponsePayload()`
 * @return {!Uint8Array}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.getResponsePayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getResponsePayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.api.RealtimeQueryResponseContainer} returns this
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.setResponsePayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional string error_message = 4;
 * @return {string}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.RealtimeQueryResponseContainer} returns this
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional RealtimeQueryStatus status = 5;
 * @return {!proto.loupe.services.api.RealtimeQueryStatus}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.getStatus = function() {
  return /** @type {!proto.loupe.services.api.RealtimeQueryStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.loupe.services.api.RealtimeQueryStatus} value
 * @return {!proto.loupe.services.api.RealtimeQueryResponseContainer} returns this
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * map<string, string> nlg_response = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.getNlgResponseMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.services.api.RealtimeQueryResponseContainer} returns this
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.clearNlgResponseMap = function() {
  this.getNlgResponseMap().clear();
  return this;};


/**
 * optional loupe.common.gml.GML gml_response = 7;
 * @return {?proto.loupe.common.gml.GML}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.getGmlResponse = function() {
  return /** @type{?proto.loupe.common.gml.GML} */ (
    jspb.Message.getWrapperField(this, src_common_gml_gml_pb.GML, 7));
};


/**
 * @param {?proto.loupe.common.gml.GML|undefined} value
 * @return {!proto.loupe.services.api.RealtimeQueryResponseContainer} returns this
*/
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.setGmlResponse = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.RealtimeQueryResponseContainer} returns this
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.clearGmlResponse = function() {
  return this.setGmlResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.RealtimeQueryResponseContainer.prototype.hasGmlResponse = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestedOptionsList: jspb.Message.toObjectList(msg.getRequestedOptionsList(),
    src_common_realtime_query_pb.SourceOptions.toObject, includeInstance),
    actor: (f = msg.getActor()) && src_common_actor_pb.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest;
  return proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_realtime_query_pb.SourceOptions;
      reader.readMessage(value,src_common_realtime_query_pb.SourceOptions.deserializeBinaryFromReader);
      msg.addRequestedOptions(value);
      break;
    case 2:
      var value = new src_common_actor_pb.Actor;
      reader.readMessage(value,src_common_actor_pb.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestedOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_realtime_query_pb.SourceOptions.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_actor_pb.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.realtime.SourceOptions requested_options = 1;
 * @return {!Array<!proto.loupe.common.realtime.SourceOptions>}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.prototype.getRequestedOptionsList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.SourceOptions>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_realtime_query_pb.SourceOptions, 1));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.SourceOptions>} value
 * @return {!proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest} returns this
*/
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.prototype.setRequestedOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.realtime.SourceOptions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.SourceOptions}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.prototype.addRequestedOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.realtime.SourceOptions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest} returns this
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.prototype.clearRequestedOptionsList = function() {
  return this.setRequestedOptionsList([]);
};


/**
 * optional loupe.common.Actor actor = 2;
 * @return {?proto.loupe.common.Actor}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.prototype.getActor = function() {
  return /** @type{?proto.loupe.common.Actor} */ (
    jspb.Message.getWrapperField(this, src_common_actor_pb.Actor, 2));
};


/**
 * @param {?proto.loupe.common.Actor|undefined} value
 * @return {!proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest} returns this
*/
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.prototype.setActor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest} returns this
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.prototype.clearActor = function() {
  return this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsRequest.prototype.hasActor = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    src_common_realtime_query_pb.SourceOptions.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse;
  return proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_realtime_query_pb.SourceOptions;
      reader.readMessage(value,src_common_realtime_query_pb.SourceOptions.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_common_realtime_query_pb.SourceOptions.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.common.realtime.SourceOptions results = 1;
 * @return {!Array<!proto.loupe.common.realtime.SourceOptions>}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.loupe.common.realtime.SourceOptions>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_common_realtime_query_pb.SourceOptions, 1));
};


/**
 * @param {!Array<!proto.loupe.common.realtime.SourceOptions>} value
 * @return {!proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse} returns this
*/
proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.common.realtime.SourceOptions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.realtime.SourceOptions}
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.common.realtime.SourceOptions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse} returns this
 */
proto.loupe.services.api.GetObjectMappingsSourceOptionsResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    referenceObjectKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    referenceObjectLabelField: jspb.Message.getFieldWithDefault(msg, 2, ""),
    searchRequest: jspb.Message.getFieldWithDefault(msg, 3, ""),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tenantId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest;
  return proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceObjectKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceObjectLabelField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchRequest(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferenceObjectKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReferenceObjectLabelField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSearchRequest();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string reference_object_key = 1;
 * @return {string}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.getReferenceObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest} returns this
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.setReferenceObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reference_object_label_field = 2;
 * @return {string}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.getReferenceObjectLabelField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest} returns this
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.setReferenceObjectLabelField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string search_request = 3;
 * @return {string}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.getSearchRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest} returns this
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.setSearchRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 page = 4;
 * @return {number}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest} returns this
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 limit = 5;
 * @return {number}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest} returns this
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string tenant_id = 6;
 * @return {string}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest} returns this
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse;
  return proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair;
      reader.readMessage(value,proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated ObjectReferenceFieldKeyLabelPair results = 1;
 * @return {!Array<!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair>}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair, 1));
};


/**
 * @param {!Array<!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair>} value
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse} returns this
*/
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse} returns this
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse} returns this
 */
proto.loupe.services.api.GetObjectReferenceFieldOptionsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.toObject, includeInstance),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest;
  return proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer;
      reader.readMessage(value,proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.serializeBinaryToWriter
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated ResolveObjectReferenceFieldOptionsContainer items = 1;
 * @return {!Array<!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer>}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer, 1));
};


/**
 * @param {!Array<!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer>} value
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest} returns this
*/
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest} returns this
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest} returns this
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    referenceObjectKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    referenceObjectLabelField: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keysList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer;
  return proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceObjectKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceObjectLabelField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferenceObjectKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReferenceObjectLabelField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string reference_object_key = 1;
 * @return {string}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.prototype.getReferenceObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer} returns this
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.prototype.setReferenceObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reference_object_label_field = 2;
 * @return {string}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.prototype.getReferenceObjectLabelField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer} returns this
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.prototype.setReferenceObjectLabelField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string keys = 3;
 * @return {!Array<string>}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.prototype.getKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer} returns this
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.prototype.setKeysList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer} returns this
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.prototype.addKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer} returns this
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainer.prototype.clearKeysList = function() {
  return this.setKeysList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse;
  return proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult;
      reader.readMessage(value,proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResolveObjectReferenceFieldOptionsContainerResult results = 1;
 * @return {!Array<!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult>}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult, 1));
};


/**
 * @param {!Array<!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult>} value
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse} returns this
*/
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse} returns this
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    referenceObjectKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    referenceObjectLabelField: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resolvedItemsList: jspb.Message.toObjectList(msg.getResolvedItemsList(),
    proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult;
  return proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceObjectKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceObjectLabelField(value);
      break;
    case 3:
      var value = new proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair;
      reader.readMessage(value,proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.deserializeBinaryFromReader);
      msg.addResolvedItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferenceObjectKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReferenceObjectLabelField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResolvedItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.serializeBinaryToWriter
    );
  }
};


/**
 * optional string reference_object_key = 1;
 * @return {string}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.prototype.getReferenceObjectKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult} returns this
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.prototype.setReferenceObjectKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reference_object_label_field = 2;
 * @return {string}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.prototype.getReferenceObjectLabelField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult} returns this
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.prototype.setReferenceObjectLabelField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ObjectReferenceFieldKeyLabelPair resolved_items = 3;
 * @return {!Array<!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair>}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.prototype.getResolvedItemsList = function() {
  return /** @type{!Array<!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair, 3));
};


/**
 * @param {!Array<!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair>} value
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult} returns this
*/
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.prototype.setResolvedItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair}
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.prototype.addResolvedItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult} returns this
 */
proto.loupe.services.api.ResolveObjectReferenceFieldOptionsContainerResult.prototype.clearResolvedItemsList = function() {
  return this.setResolvedItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair}
 */
proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair;
  return proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair}
 */
proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair} returns this
 */
proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair} returns this
 */
proto.loupe.services.api.ObjectReferenceFieldKeyLabelPair.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetWritebackSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetWritebackSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetWritebackSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetWritebackSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetWritebackSettingRequest}
 */
proto.loupe.services.api.GetWritebackSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetWritebackSettingRequest;
  return proto.loupe.services.api.GetWritebackSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetWritebackSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetWritebackSettingRequest}
 */
proto.loupe.services.api.GetWritebackSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetWritebackSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetWritebackSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetWritebackSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetWritebackSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetWritebackSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetWritebackSettingRequest} returns this
 */
proto.loupe.services.api.GetWritebackSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetWritebackSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetWritebackSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetWritebackSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetWritebackSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    pipelineEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetWritebackSettingResponse}
 */
proto.loupe.services.api.GetWritebackSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetWritebackSettingResponse;
  return proto.loupe.services.api.GetWritebackSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetWritebackSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetWritebackSettingResponse}
 */
proto.loupe.services.api.GetWritebackSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPipelineEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetWritebackSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetWritebackSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetWritebackSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetWritebackSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPipelineEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.loupe.services.api.GetWritebackSettingResponse.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.api.GetWritebackSettingResponse} returns this
 */
proto.loupe.services.api.GetWritebackSettingResponse.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool pipeline_enabled = 2;
 * @return {boolean}
 */
proto.loupe.services.api.GetWritebackSettingResponse.prototype.getPipelineEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.api.GetWritebackSettingResponse} returns this
 */
proto.loupe.services.api.GetWritebackSettingResponse.prototype.setPipelineEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertWritebackSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertWritebackSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertWritebackSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertWritebackSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    pipelineEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertWritebackSettingRequest}
 */
proto.loupe.services.api.UpsertWritebackSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertWritebackSettingRequest;
  return proto.loupe.services.api.UpsertWritebackSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertWritebackSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertWritebackSettingRequest}
 */
proto.loupe.services.api.UpsertWritebackSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPipelineEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertWritebackSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertWritebackSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertWritebackSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertWritebackSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPipelineEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertWritebackSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertWritebackSettingRequest} returns this
 */
proto.loupe.services.api.UpsertWritebackSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool enabled = 2;
 * @return {boolean}
 */
proto.loupe.services.api.UpsertWritebackSettingRequest.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.api.UpsertWritebackSettingRequest} returns this
 */
proto.loupe.services.api.UpsertWritebackSettingRequest.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool pipeline_enabled = 3;
 * @return {boolean}
 */
proto.loupe.services.api.UpsertWritebackSettingRequest.prototype.getPipelineEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.api.UpsertWritebackSettingRequest} returns this
 */
proto.loupe.services.api.UpsertWritebackSettingRequest.prototype.setPipelineEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertWritebackSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertWritebackSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertWritebackSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertWritebackSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    pipelineEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertWritebackSettingResponse}
 */
proto.loupe.services.api.UpsertWritebackSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertWritebackSettingResponse;
  return proto.loupe.services.api.UpsertWritebackSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertWritebackSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertWritebackSettingResponse}
 */
proto.loupe.services.api.UpsertWritebackSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPipelineEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertWritebackSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertWritebackSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertWritebackSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertWritebackSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPipelineEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.loupe.services.api.UpsertWritebackSettingResponse.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.api.UpsertWritebackSettingResponse} returns this
 */
proto.loupe.services.api.UpsertWritebackSettingResponse.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool pipeline_enabled = 2;
 * @return {boolean}
 */
proto.loupe.services.api.UpsertWritebackSettingResponse.prototype.getPipelineEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.services.api.UpsertWritebackSettingResponse} returns this
 */
proto.loupe.services.api.UpsertWritebackSettingResponse.prototype.setPipelineEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetOutreachSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetOutreachSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetOutreachSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetOutreachSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetOutreachSettingRequest}
 */
proto.loupe.services.api.GetOutreachSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetOutreachSettingRequest;
  return proto.loupe.services.api.GetOutreachSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetOutreachSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetOutreachSettingRequest}
 */
proto.loupe.services.api.GetOutreachSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetOutreachSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetOutreachSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetOutreachSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetOutreachSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetOutreachSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetOutreachSettingRequest} returns this
 */
proto.loupe.services.api.GetOutreachSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetOutreachSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetOutreachSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetOutreachSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetOutreachSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_outreach_settings_pb.OutreachSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetOutreachSettingResponse}
 */
proto.loupe.services.api.GetOutreachSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetOutreachSettingResponse;
  return proto.loupe.services.api.GetOutreachSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetOutreachSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetOutreachSettingResponse}
 */
proto.loupe.services.api.GetOutreachSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_setting_app_feature_outreach_settings_pb.OutreachSettings;
      reader.readMessage(value,src_common_setting_app_feature_outreach_settings_pb.OutreachSettings.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetOutreachSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetOutreachSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetOutreachSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetOutreachSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_setting_app_feature_outreach_settings_pb.OutreachSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.setting.feature.OutreachSettings setting = 1;
 * @return {?proto.loupe.common.setting.feature.OutreachSettings}
 */
proto.loupe.services.api.GetOutreachSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.OutreachSettings} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_outreach_settings_pb.OutreachSettings, 1));
};


/**
 * @param {?proto.loupe.common.setting.feature.OutreachSettings|undefined} value
 * @return {!proto.loupe.services.api.GetOutreachSettingResponse} returns this
*/
proto.loupe.services.api.GetOutreachSettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetOutreachSettingResponse} returns this
 */
proto.loupe.services.api.GetOutreachSettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetOutreachSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertOutreachSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertOutreachSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertOutreachSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertOutreachSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_outreach_settings_pb.OutreachSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertOutreachSettingRequest}
 */
proto.loupe.services.api.UpsertOutreachSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertOutreachSettingRequest;
  return proto.loupe.services.api.UpsertOutreachSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertOutreachSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertOutreachSettingRequest}
 */
proto.loupe.services.api.UpsertOutreachSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_outreach_settings_pb.OutreachSettings;
      reader.readMessage(value,src_common_setting_app_feature_outreach_settings_pb.OutreachSettings.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertOutreachSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertOutreachSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertOutreachSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertOutreachSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_outreach_settings_pb.OutreachSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertOutreachSettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertOutreachSettingRequest} returns this
 */
proto.loupe.services.api.UpsertOutreachSettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.OutreachSettings setting = 2;
 * @return {?proto.loupe.common.setting.feature.OutreachSettings}
 */
proto.loupe.services.api.UpsertOutreachSettingRequest.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.OutreachSettings} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_outreach_settings_pb.OutreachSettings, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.OutreachSettings|undefined} value
 * @return {!proto.loupe.services.api.UpsertOutreachSettingRequest} returns this
*/
proto.loupe.services.api.UpsertOutreachSettingRequest.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertOutreachSettingRequest} returns this
 */
proto.loupe.services.api.UpsertOutreachSettingRequest.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertOutreachSettingRequest.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertOutreachSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertOutreachSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertOutreachSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertOutreachSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_outreach_settings_pb.OutreachSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertOutreachSettingResponse}
 */
proto.loupe.services.api.UpsertOutreachSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertOutreachSettingResponse;
  return proto.loupe.services.api.UpsertOutreachSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertOutreachSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertOutreachSettingResponse}
 */
proto.loupe.services.api.UpsertOutreachSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_outreach_settings_pb.OutreachSettings;
      reader.readMessage(value,src_common_setting_app_feature_outreach_settings_pb.OutreachSettings.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertOutreachSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertOutreachSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertOutreachSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertOutreachSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_outreach_settings_pb.OutreachSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertOutreachSettingResponse.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertOutreachSettingResponse} returns this
 */
proto.loupe.services.api.UpsertOutreachSettingResponse.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.OutreachSettings setting = 2;
 * @return {?proto.loupe.common.setting.feature.OutreachSettings}
 */
proto.loupe.services.api.UpsertOutreachSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.OutreachSettings} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_outreach_settings_pb.OutreachSettings, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.OutreachSettings|undefined} value
 * @return {!proto.loupe.services.api.UpsertOutreachSettingResponse} returns this
*/
proto.loupe.services.api.UpsertOutreachSettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertOutreachSettingResponse} returns this
 */
proto.loupe.services.api.UpsertOutreachSettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertOutreachSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetHierarchySettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetHierarchySettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetHierarchySettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetHierarchySettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetHierarchySettingRequest}
 */
proto.loupe.services.api.GetHierarchySettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetHierarchySettingRequest;
  return proto.loupe.services.api.GetHierarchySettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetHierarchySettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetHierarchySettingRequest}
 */
proto.loupe.services.api.GetHierarchySettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetHierarchySettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetHierarchySettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetHierarchySettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetHierarchySettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetHierarchySettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetHierarchySettingRequest} returns this
 */
proto.loupe.services.api.GetHierarchySettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetHierarchySettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetHierarchySettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetHierarchySettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetHierarchySettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_hierarchy_pb.Hierarchy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetHierarchySettingResponse}
 */
proto.loupe.services.api.GetHierarchySettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetHierarchySettingResponse;
  return proto.loupe.services.api.GetHierarchySettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetHierarchySettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetHierarchySettingResponse}
 */
proto.loupe.services.api.GetHierarchySettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_common_setting_app_feature_hierarchy_pb.Hierarchy;
      reader.readMessage(value,src_common_setting_app_feature_hierarchy_pb.Hierarchy.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetHierarchySettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetHierarchySettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetHierarchySettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetHierarchySettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      src_common_setting_app_feature_hierarchy_pb.Hierarchy.serializeBinaryToWriter
    );
  }
};


/**
 * optional loupe.common.setting.feature.Hierarchy setting = 1;
 * @return {?proto.loupe.common.setting.feature.Hierarchy}
 */
proto.loupe.services.api.GetHierarchySettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.Hierarchy} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_hierarchy_pb.Hierarchy, 1));
};


/**
 * @param {?proto.loupe.common.setting.feature.Hierarchy|undefined} value
 * @return {!proto.loupe.services.api.GetHierarchySettingResponse} returns this
*/
proto.loupe.services.api.GetHierarchySettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetHierarchySettingResponse} returns this
 */
proto.loupe.services.api.GetHierarchySettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetHierarchySettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertHierarchySettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertHierarchySettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertHierarchySettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertHierarchySettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_hierarchy_pb.Hierarchy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertHierarchySettingRequest}
 */
proto.loupe.services.api.UpsertHierarchySettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertHierarchySettingRequest;
  return proto.loupe.services.api.UpsertHierarchySettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertHierarchySettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertHierarchySettingRequest}
 */
proto.loupe.services.api.UpsertHierarchySettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_hierarchy_pb.Hierarchy;
      reader.readMessage(value,src_common_setting_app_feature_hierarchy_pb.Hierarchy.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertHierarchySettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertHierarchySettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertHierarchySettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertHierarchySettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_hierarchy_pb.Hierarchy.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertHierarchySettingRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertHierarchySettingRequest} returns this
 */
proto.loupe.services.api.UpsertHierarchySettingRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.Hierarchy setting = 2;
 * @return {?proto.loupe.common.setting.feature.Hierarchy}
 */
proto.loupe.services.api.UpsertHierarchySettingRequest.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.Hierarchy} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_hierarchy_pb.Hierarchy, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.Hierarchy|undefined} value
 * @return {!proto.loupe.services.api.UpsertHierarchySettingRequest} returns this
*/
proto.loupe.services.api.UpsertHierarchySettingRequest.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertHierarchySettingRequest} returns this
 */
proto.loupe.services.api.UpsertHierarchySettingRequest.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertHierarchySettingRequest.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.UpsertHierarchySettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.UpsertHierarchySettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.UpsertHierarchySettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertHierarchySettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setting: (f = msg.getSetting()) && src_common_setting_app_feature_hierarchy_pb.Hierarchy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.UpsertHierarchySettingResponse}
 */
proto.loupe.services.api.UpsertHierarchySettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.UpsertHierarchySettingResponse;
  return proto.loupe.services.api.UpsertHierarchySettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.UpsertHierarchySettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.UpsertHierarchySettingResponse}
 */
proto.loupe.services.api.UpsertHierarchySettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = new src_common_setting_app_feature_hierarchy_pb.Hierarchy;
      reader.readMessage(value,src_common_setting_app_feature_hierarchy_pb.Hierarchy.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.UpsertHierarchySettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.UpsertHierarchySettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.UpsertHierarchySettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.UpsertHierarchySettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      src_common_setting_app_feature_hierarchy_pb.Hierarchy.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.UpsertHierarchySettingResponse.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.UpsertHierarchySettingResponse} returns this
 */
proto.loupe.services.api.UpsertHierarchySettingResponse.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional loupe.common.setting.feature.Hierarchy setting = 2;
 * @return {?proto.loupe.common.setting.feature.Hierarchy}
 */
proto.loupe.services.api.UpsertHierarchySettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.loupe.common.setting.feature.Hierarchy} */ (
    jspb.Message.getWrapperField(this, src_common_setting_app_feature_hierarchy_pb.Hierarchy, 2));
};


/**
 * @param {?proto.loupe.common.setting.feature.Hierarchy|undefined} value
 * @return {!proto.loupe.services.api.UpsertHierarchySettingResponse} returns this
*/
proto.loupe.services.api.UpsertHierarchySettingResponse.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.UpsertHierarchySettingResponse} returns this
 */
proto.loupe.services.api.UpsertHierarchySettingResponse.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.UpsertHierarchySettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetChurnAndContractionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetChurnAndContractionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetChurnAndContractionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetChurnAndContractionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetChurnAndContractionRequest}
 */
proto.loupe.services.api.GetChurnAndContractionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetChurnAndContractionRequest;
  return proto.loupe.services.api.GetChurnAndContractionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetChurnAndContractionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetChurnAndContractionRequest}
 */
proto.loupe.services.api.GetChurnAndContractionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetChurnAndContractionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetChurnAndContractionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetChurnAndContractionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetChurnAndContractionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenant_id = 1;
 * @return {string}
 */
proto.loupe.services.api.GetChurnAndContractionRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetChurnAndContractionRequest} returns this
 */
proto.loupe.services.api.GetChurnAndContractionRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.loupe.services.api.GetChurnAndContractionRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.GetChurnAndContractionRequest} returns this
 */
proto.loupe.services.api.GetChurnAndContractionRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetChurnAndContractionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetChurnAndContractionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetChurnAndContractionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetChurnAndContractionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetChurnAndContractionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outputsList: jspb.Message.toObjectList(msg.getOutputsList(),
    src_services_dscds_cc_cc_pb.CCOutput.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetChurnAndContractionResponse}
 */
proto.loupe.services.api.GetChurnAndContractionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetChurnAndContractionResponse;
  return proto.loupe.services.api.GetChurnAndContractionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetChurnAndContractionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetChurnAndContractionResponse}
 */
proto.loupe.services.api.GetChurnAndContractionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new src_services_dscds_cc_cc_pb.CCOutput;
      reader.readMessage(value,src_services_dscds_cc_cc_pb.CCOutput.deserializeBinaryFromReader);
      msg.addOutputs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetChurnAndContractionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetChurnAndContractionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetChurnAndContractionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetChurnAndContractionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      src_services_dscds_cc_cc_pb.CCOutput.serializeBinaryToWriter
    );
  }
};


/**
 * repeated loupe.services.dscds.cc.CCOutput outputs = 1;
 * @return {!Array<!proto.loupe.services.dscds.cc.CCOutput>}
 */
proto.loupe.services.api.GetChurnAndContractionResponse.prototype.getOutputsList = function() {
  return /** @type{!Array<!proto.loupe.services.dscds.cc.CCOutput>} */ (
    jspb.Message.getRepeatedWrapperField(this, src_services_dscds_cc_cc_pb.CCOutput, 1));
};


/**
 * @param {!Array<!proto.loupe.services.dscds.cc.CCOutput>} value
 * @return {!proto.loupe.services.api.GetChurnAndContractionResponse} returns this
*/
proto.loupe.services.api.GetChurnAndContractionResponse.prototype.setOutputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.dscds.cc.CCOutput=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.dscds.cc.CCOutput}
 */
proto.loupe.services.api.GetChurnAndContractionResponse.prototype.addOutputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.dscds.cc.CCOutput, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetChurnAndContractionResponse} returns this
 */
proto.loupe.services.api.GetChurnAndContractionResponse.prototype.clearOutputsList = function() {
  return this.setOutputsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetOpportunityChangeHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    opportunityIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetOpportunityChangeHistoryRequest;
  return proto.loupe.services.api.GetOpportunityChangeHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addOpportunityIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFields(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetOpportunityChangeHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpportunityIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string opportunity_ids = 1;
 * @return {!Array<string>}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.getOpportunityIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} returns this
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.setOpportunityIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} returns this
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.addOpportunityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} returns this
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.clearOpportunityIdsList = function() {
  return this.setOpportunityIdsList([]);
};


/**
 * repeated string fields = 2;
 * @return {!Array<string>}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} returns this
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} returns this
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} returns this
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional google.protobuf.Timestamp start_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} returns this
*/
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} returns this
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} returns this
*/
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryRequest} returns this
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.GetOpportunityChangeHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.GetOpportunityChangeHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.GetOpportunityChangeHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetOpportunityChangeHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    opportunitiesList: jspb.Message.toObjectList(msg.getOpportunitiesList(),
    proto.loupe.services.api.CommitOpportunity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryResponse}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.GetOpportunityChangeHistoryResponse;
  return proto.loupe.services.api.GetOpportunityChangeHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.GetOpportunityChangeHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryResponse}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.loupe.services.api.CommitOpportunity;
      reader.readMessage(value,proto.loupe.services.api.CommitOpportunity.deserializeBinaryFromReader);
      msg.addOpportunities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.GetOpportunityChangeHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.GetOpportunityChangeHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.GetOpportunityChangeHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpportunitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.loupe.services.api.CommitOpportunity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommitOpportunity opportunities = 1;
 * @return {!Array<!proto.loupe.services.api.CommitOpportunity>}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryResponse.prototype.getOpportunitiesList = function() {
  return /** @type{!Array<!proto.loupe.services.api.CommitOpportunity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.api.CommitOpportunity, 1));
};


/**
 * @param {!Array<!proto.loupe.services.api.CommitOpportunity>} value
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryResponse} returns this
*/
proto.loupe.services.api.GetOpportunityChangeHistoryResponse.prototype.setOpportunitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.loupe.services.api.CommitOpportunity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.CommitOpportunity}
 */
proto.loupe.services.api.GetOpportunityChangeHistoryResponse.prototype.addOpportunities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.loupe.services.api.CommitOpportunity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.GetOpportunityChangeHistoryResponse} returns this
 */
proto.loupe.services.api.GetOpportunityChangeHistoryResponse.prototype.clearOpportunitiesList = function() {
  return this.setOpportunitiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.CommitOpportunity.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.CommitOpportunity.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.CommitOpportunity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.CommitOpportunity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CommitOpportunity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldList: jspb.Message.toObjectList(msg.getFieldList(),
    proto.loupe.services.api.CommitField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.CommitOpportunity}
 */
proto.loupe.services.api.CommitOpportunity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.CommitOpportunity;
  return proto.loupe.services.api.CommitOpportunity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.CommitOpportunity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.CommitOpportunity}
 */
proto.loupe.services.api.CommitOpportunity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.loupe.services.api.CommitField;
      reader.readMessage(value,proto.loupe.services.api.CommitField.deserializeBinaryFromReader);
      msg.addField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.CommitOpportunity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.CommitOpportunity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.CommitOpportunity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CommitOpportunity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.services.api.CommitField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.services.api.CommitOpportunity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.CommitOpportunity} returns this
 */
proto.loupe.services.api.CommitOpportunity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated CommitField field = 2;
 * @return {!Array<!proto.loupe.services.api.CommitField>}
 */
proto.loupe.services.api.CommitOpportunity.prototype.getFieldList = function() {
  return /** @type{!Array<!proto.loupe.services.api.CommitField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.api.CommitField, 2));
};


/**
 * @param {!Array<!proto.loupe.services.api.CommitField>} value
 * @return {!proto.loupe.services.api.CommitOpportunity} returns this
*/
proto.loupe.services.api.CommitOpportunity.prototype.setFieldList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.services.api.CommitField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.CommitField}
 */
proto.loupe.services.api.CommitOpportunity.prototype.addField = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.services.api.CommitField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.CommitOpportunity} returns this
 */
proto.loupe.services.api.CommitOpportunity.prototype.clearFieldList = function() {
  return this.setFieldList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.services.api.CommitField.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.CommitField.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.CommitField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.CommitField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CommitField.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    changesList: jspb.Message.toObjectList(msg.getChangesList(),
    proto.loupe.services.api.CommitChange.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.CommitField}
 */
proto.loupe.services.api.CommitField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.CommitField;
  return proto.loupe.services.api.CommitField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.CommitField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.CommitField}
 */
proto.loupe.services.api.CommitField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.loupe.services.api.CommitChange;
      reader.readMessage(value,proto.loupe.services.api.CommitChange.deserializeBinaryFromReader);
      msg.addChanges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.CommitField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.CommitField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.CommitField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CommitField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.loupe.services.api.CommitChange.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.loupe.services.api.CommitField.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.services.api.CommitField} returns this
 */
proto.loupe.services.api.CommitField.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated CommitChange changes = 2;
 * @return {!Array<!proto.loupe.services.api.CommitChange>}
 */
proto.loupe.services.api.CommitField.prototype.getChangesList = function() {
  return /** @type{!Array<!proto.loupe.services.api.CommitChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.services.api.CommitChange, 2));
};


/**
 * @param {!Array<!proto.loupe.services.api.CommitChange>} value
 * @return {!proto.loupe.services.api.CommitField} returns this
*/
proto.loupe.services.api.CommitField.prototype.setChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.loupe.services.api.CommitChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.services.api.CommitChange}
 */
proto.loupe.services.api.CommitField.prototype.addChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.loupe.services.api.CommitChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.services.api.CommitField} returns this
 */
proto.loupe.services.api.CommitField.prototype.clearChangesList = function() {
  return this.setChangesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.services.api.CommitChange.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.services.api.CommitChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.services.api.CommitChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CommitChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldValue: msg.getOldValue_asB64(),
    newValue: msg.getNewValue_asB64(),
    changedOn: (f = msg.getChangedOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    direction: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.services.api.CommitChange}
 */
proto.loupe.services.api.CommitChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.services.api.CommitChange;
  return proto.loupe.services.api.CommitChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.services.api.CommitChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.services.api.CommitChange}
 */
proto.loupe.services.api.CommitChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOldValue(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNewValue(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setChangedOn(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.services.api.CommitChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.services.api.CommitChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.services.api.CommitChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.services.api.CommitChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getNewValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getChangedOn();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDirection();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bytes old_value = 1;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.api.CommitChange.prototype.getOldValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes old_value = 1;
 * This is a type-conversion wrapper around `getOldValue()`
 * @return {string}
 */
proto.loupe.services.api.CommitChange.prototype.getOldValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOldValue()));
};


/**
 * optional bytes old_value = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOldValue()`
 * @return {!Uint8Array}
 */
proto.loupe.services.api.CommitChange.prototype.getOldValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOldValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.api.CommitChange} returns this
 */
proto.loupe.services.api.CommitChange.prototype.setOldValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes new_value = 2;
 * @return {!(string|Uint8Array)}
 */
proto.loupe.services.api.CommitChange.prototype.getNewValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes new_value = 2;
 * This is a type-conversion wrapper around `getNewValue()`
 * @return {string}
 */
proto.loupe.services.api.CommitChange.prototype.getNewValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNewValue()));
};


/**
 * optional bytes new_value = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNewValue()`
 * @return {!Uint8Array}
 */
proto.loupe.services.api.CommitChange.prototype.getNewValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNewValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.loupe.services.api.CommitChange} returns this
 */
proto.loupe.services.api.CommitChange.prototype.setNewValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp changed_on = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.services.api.CommitChange.prototype.getChangedOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.services.api.CommitChange} returns this
*/
proto.loupe.services.api.CommitChange.prototype.setChangedOn = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.services.api.CommitChange} returns this
 */
proto.loupe.services.api.CommitChange.prototype.clearChangedOn = function() {
  return this.setChangedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.services.api.CommitChange.prototype.hasChangedOn = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 direction = 4;
 * @return {number}
 */
proto.loupe.services.api.CommitChange.prototype.getDirection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.services.api.CommitChange} returns this
 */
proto.loupe.services.api.CommitChange.prototype.setDirection = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.loupe.services.api.PatchSearchItemStatus = {
  COMPLETE: 0,
  STALE: 1,
  NOT_AVAILABLE: 2,
  REINDEXING: 3
};

/**
 * @enum {number}
 */
proto.loupe.services.api.PatchSearchItemErrType = {
  NONE: 0,
  VALIDATION: 1,
  REQUIRED_FIELDS: 2,
  INTERNAL: 3
};

/**
 * @enum {number}
 */
proto.loupe.services.api.RealtimeQueryStatus = {
  SUCCESS: 0,
  FAILURE: 1
};

goog.object.extend(exports, proto.loupe.services.api);
