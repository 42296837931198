import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useRoutes } from '../../context/routes'
import { useAuth } from '../../context/auth'
import OutreachLogo from '../icons/outreachLogo'

const Unauthorized = () => {
  const { resetAuth } = useAuth()
  const history = useHistory()

  const { routes } = useRoutes()

  const onLoginClick = useCallback(() => {
    resetAuth()
    history.push(routes.logout)
  }, [])

  return (
    <div className="w-screen h-screen bg-color-fafafa">
      <div className="flex justify-center p-10">
        <div className="text-center rounded-lg bg-gradient-white-to-blue border border-color-2e5bff-08 md:w-1/2 p-8">
          <div className="flex justify-center items-center flex-shrink">
            <OutreachLogo />
            <h1 className="flex-shrink ml-2">Unauthorized</h1>
          </div>
          <div className="text-left text-color-4e5d7e mt-6">
            You do not have the proper access to view this site.
            {' '}
            <a className="whitespace-nowrap underline-dashed focus:outline-none cursor-pointer" onClick={onLoginClick}>Login</a>
            {' '}
            and try this page again when your permissions are fixed.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Unauthorized
