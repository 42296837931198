import { usePopover } from '../../hooks/usePopover'
import MoreVertical from '../icons/moreVertical'
import Popover from '../common/popover'
import React, { useCallback } from 'react'
import classNames from 'classnames'

const ForecastConfigMenu = (props) => {
  const {
    canPublish,
    hasChanges,
    onDelete,
    onDiscardChanges,
    onPublish
  } = props

  const { anchorEl, setAnchorEl, open, onClose } = usePopover()

  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget)
  }, [setAnchorEl])

  const onDeleteInternal = useCallback((e) => {
    window.analytics.track('Delete Forecast Config Clicked')
    onClose()
    onDelete?.(e)
  }, [onClose, onDelete])

  const onDiscardChangesInternal = useCallback((e) => {
    if (!hasChanges) {
      return
    }
    onClose()
    onDiscardChanges?.(e)
  }, [hasChanges, onClose, onDiscardChanges])

  const onPublishInternal = useCallback((e) => {
    if (!canPublish) {
      return
    }
    onClose()
    onPublish?.(e)
  }, [onClose, onPublish, canPublish])

  return (
    <>
      <button onClick={handleClick}>
        <MoreVertical fill="#151d49" />
      </button>
      <Popover
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        placement="bottom-end">
        <div className="py-1">
          <div
            onClick={onPublishInternal}
            className={
              classNames(
                'flex items-center px-4 py-1 focus:outline-none hover:bg-color-51636a-05',
                { 'cursor-pointer': canPublish }
              )
            }>
            <div className={classNames('text-size-14px text-color-4e5d7e font-weight-400', { 'opacity-50': !canPublish })}>Publish</div>
          </div>
          <div
            onClick={onDiscardChangesInternal}
            className={
              classNames(
                'flex items-center px-4 py-1 focus:outline-none hover:bg-color-51636a-05',
                { 'cursor-pointer': hasChanges }
              )
            }>
            <div className={classNames('text-size-14px text-color-4e5d7e font-weight-400', { 'opacity-50': !hasChanges })}>Discard Changes</div>
          </div>
          <div
            onClick={onDeleteInternal}
            className="flex items-center px-4 py-1 cursor-pointer focus:outline-none hover:bg-color-51636a-05">
            <div className="text-size-14px text-color-fb6c6a font-weight-400">Delete</div>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default ForecastConfigMenu
