// source: src/common/param/query_param.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.loupe.common.param.Flavor', null, global);
goog.exportSymbol('proto.loupe.common.param.QueryParam', null, global);
goog.exportSymbol('proto.loupe.common.param.QueryParamComparison', null, global);
goog.exportSymbol('proto.loupe.common.param.QueryParamDataType', null, global);
goog.exportSymbol('proto.loupe.common.param.QueryParamOp', null, global);
goog.exportSymbol('proto.loupe.common.param.QueryParamType', null, global);
goog.exportSymbol('proto.loupe.common.param.QueryParams', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.param.QueryParamComparison = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.param.QueryParamComparison.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.param.QueryParamComparison, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.param.QueryParamComparison.displayName = 'proto.loupe.common.param.QueryParamComparison';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.param.QueryParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.param.QueryParam.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.param.QueryParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.param.QueryParam.displayName = 'proto.loupe.common.param.QueryParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.param.QueryParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.param.QueryParams.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.param.QueryParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.param.QueryParams.displayName = 'proto.loupe.common.param.QueryParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.param.Flavor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.param.Flavor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.param.Flavor.displayName = 'proto.loupe.common.param.Flavor';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.param.QueryParamComparison.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.param.QueryParamComparison.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.param.QueryParamComparison.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.param.QueryParamComparison} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.param.QueryParamComparison.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    op: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valuesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    weight: jspb.Message.getFieldWithDefault(msg, 4, 0),
    multiplierStatement: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.param.QueryParamComparison}
 */
proto.loupe.common.param.QueryParamComparison.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.param.QueryParamComparison;
  return proto.loupe.common.param.QueryParamComparison.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.param.QueryParamComparison} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.param.QueryParamComparison}
 */
proto.loupe.common.param.QueryParamComparison.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMultiplierStatement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.param.QueryParamComparison.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.param.QueryParamComparison.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.param.QueryParamComparison} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.param.QueryParamComparison.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMultiplierStatement();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.loupe.common.param.QueryParamComparison.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.param.QueryParamComparison} returns this
 */
proto.loupe.common.param.QueryParamComparison.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string op = 2;
 * @return {string}
 */
proto.loupe.common.param.QueryParamComparison.prototype.getOp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.param.QueryParamComparison} returns this
 */
proto.loupe.common.param.QueryParamComparison.prototype.setOp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string values = 3;
 * @return {!Array<string>}
 */
proto.loupe.common.param.QueryParamComparison.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.loupe.common.param.QueryParamComparison} returns this
 */
proto.loupe.common.param.QueryParamComparison.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.param.QueryParamComparison} returns this
 */
proto.loupe.common.param.QueryParamComparison.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.param.QueryParamComparison} returns this
 */
proto.loupe.common.param.QueryParamComparison.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional int64 weight = 4;
 * @return {number}
 */
proto.loupe.common.param.QueryParamComparison.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.param.QueryParamComparison} returns this
 */
proto.loupe.common.param.QueryParamComparison.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string multiplier_statement = 5;
 * @return {string}
 */
proto.loupe.common.param.QueryParamComparison.prototype.getMultiplierStatement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.param.QueryParamComparison} returns this
 */
proto.loupe.common.param.QueryParamComparison.prototype.setMultiplierStatement = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.param.QueryParam.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.param.QueryParam.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.param.QueryParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.param.QueryParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.param.QueryParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    comparisonList: jspb.Message.toObjectList(msg.getComparisonList(),
    proto.loupe.common.param.QueryParamComparison.toObject, includeInstance),
    value: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.param.QueryParam}
 */
proto.loupe.common.param.QueryParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.param.QueryParam;
  return proto.loupe.common.param.QueryParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.param.QueryParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.param.QueryParam}
 */
proto.loupe.common.param.QueryParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.loupe.common.param.QueryParamComparison;
      reader.readMessage(value,proto.loupe.common.param.QueryParamComparison.deserializeBinaryFromReader);
      msg.addComparison(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.param.QueryParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.param.QueryParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.param.QueryParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.param.QueryParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getComparisonList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.loupe.common.param.QueryParamComparison.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.loupe.common.param.QueryParam.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.param.QueryParam} returns this
 */
proto.loupe.common.param.QueryParam.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field_name = 2;
 * @return {string}
 */
proto.loupe.common.param.QueryParam.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.param.QueryParam} returns this
 */
proto.loupe.common.param.QueryParam.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.loupe.common.param.QueryParam.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.param.QueryParam} returns this
 */
proto.loupe.common.param.QueryParam.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.loupe.common.param.QueryParam.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.param.QueryParam} returns this
 */
proto.loupe.common.param.QueryParam.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.loupe.common.param.QueryParam.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.param.QueryParam} returns this
 */
proto.loupe.common.param.QueryParam.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated QueryParamComparison comparison = 6;
 * @return {!Array<!proto.loupe.common.param.QueryParamComparison>}
 */
proto.loupe.common.param.QueryParam.prototype.getComparisonList = function() {
  return /** @type{!Array<!proto.loupe.common.param.QueryParamComparison>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.param.QueryParamComparison, 6));
};


/**
 * @param {!Array<!proto.loupe.common.param.QueryParamComparison>} value
 * @return {!proto.loupe.common.param.QueryParam} returns this
*/
proto.loupe.common.param.QueryParam.prototype.setComparisonList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.loupe.common.param.QueryParamComparison=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.param.QueryParamComparison}
 */
proto.loupe.common.param.QueryParam.prototype.addComparison = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.loupe.common.param.QueryParamComparison, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.param.QueryParam} returns this
 */
proto.loupe.common.param.QueryParam.prototype.clearComparisonList = function() {
  return this.setComparisonList([]);
};


/**
 * optional string value = 7;
 * @return {string}
 */
proto.loupe.common.param.QueryParam.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.param.QueryParam} returns this
 */
proto.loupe.common.param.QueryParam.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.param.QueryParams.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.param.QueryParams.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.param.QueryParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.param.QueryParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.param.QueryParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    paramsMap: (f = msg.getParamsMap()) ? f.toObject(includeInstance, proto.loupe.common.param.QueryParam.toObject) : [],
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    flavorsList: jspb.Message.toObjectList(msg.getFlavorsList(),
    proto.loupe.common.param.Flavor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.param.QueryParams}
 */
proto.loupe.common.param.QueryParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.param.QueryParams;
  return proto.loupe.common.param.QueryParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.param.QueryParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.param.QueryParams}
 */
proto.loupe.common.param.QueryParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getParamsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.common.param.QueryParam.deserializeBinaryFromReader, "", new proto.loupe.common.param.QueryParam());
         });
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new proto.loupe.common.param.Flavor;
      reader.readMessage(value,proto.loupe.common.param.Flavor.deserializeBinaryFromReader);
      msg.addFlavors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.param.QueryParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.param.QueryParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.param.QueryParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.param.QueryParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.common.param.QueryParam.serializeBinaryToWriter);
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlavorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.common.param.Flavor.serializeBinaryToWriter
    );
  }
};


/**
 * map<string, QueryParam> params = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.common.param.QueryParam>}
 */
proto.loupe.common.param.QueryParams.prototype.getParamsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.common.param.QueryParam>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.loupe.common.param.QueryParam));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.param.QueryParams} returns this
 */
proto.loupe.common.param.QueryParams.prototype.clearParamsMap = function() {
  this.getParamsMap().clear();
  return this;};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.common.param.QueryParams.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.param.QueryParams} returns this
 */
proto.loupe.common.param.QueryParams.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Flavor flavors = 3;
 * @return {!Array<!proto.loupe.common.param.Flavor>}
 */
proto.loupe.common.param.QueryParams.prototype.getFlavorsList = function() {
  return /** @type{!Array<!proto.loupe.common.param.Flavor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.param.Flavor, 3));
};


/**
 * @param {!Array<!proto.loupe.common.param.Flavor>} value
 * @return {!proto.loupe.common.param.QueryParams} returns this
*/
proto.loupe.common.param.QueryParams.prototype.setFlavorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.param.Flavor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.param.Flavor}
 */
proto.loupe.common.param.QueryParams.prototype.addFlavors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.param.Flavor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.param.QueryParams} returns this
 */
proto.loupe.common.param.QueryParams.prototype.clearFlavorsList = function() {
  return this.setFlavorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.param.Flavor.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.param.Flavor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.param.Flavor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.param.Flavor.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paramsMap: (f = msg.getParamsMap()) ? f.toObject(includeInstance, proto.loupe.common.param.QueryParam.toObject) : [],
    sort: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.param.Flavor}
 */
proto.loupe.common.param.Flavor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.param.Flavor;
  return proto.loupe.common.param.Flavor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.param.Flavor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.param.Flavor}
 */
proto.loupe.common.param.Flavor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = msg.getParamsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.loupe.common.param.QueryParam.deserializeBinaryFromReader, "", new proto.loupe.common.param.QueryParam());
         });
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.param.Flavor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.param.Flavor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.param.Flavor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.param.Flavor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.loupe.common.param.QueryParam.serializeBinaryToWriter);
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.loupe.common.param.Flavor.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.param.Flavor} returns this
 */
proto.loupe.common.param.Flavor.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, QueryParam> params = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.loupe.common.param.QueryParam>}
 */
proto.loupe.common.param.Flavor.prototype.getParamsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.loupe.common.param.QueryParam>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.loupe.common.param.QueryParam));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.loupe.common.param.Flavor} returns this
 */
proto.loupe.common.param.Flavor.prototype.clearParamsMap = function() {
  this.getParamsMap().clear();
  return this;};


/**
 * optional int64 sort = 3;
 * @return {number}
 */
proto.loupe.common.param.Flavor.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.loupe.common.param.Flavor} returns this
 */
proto.loupe.common.param.Flavor.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.param.QueryParamType = {
  VALUE: 0,
  COMPARISON: 1
};

/**
 * @enum {number}
 */
proto.loupe.common.param.QueryParamOp = {
  EQ: 0,
  NEQ: 1,
  LT: 2,
  GT: 3,
  GE: 4,
  LE: 5,
  BETWEEN: 6,
  OUTSIDE: 7,
  IN: 8,
  NIN: 9,
  REGEX: 10,
  NREGEX: 11
};

/**
 * @enum {number}
 */
proto.loupe.common.param.QueryParamDataType = {
  INT: 0,
  FLOAT: 1,
  STRING: 2,
  BOOL: 3,
  DATE: 4,
  TIMESTAMP: 5,
  CUSTOM: 6
};

goog.object.extend(exports, proto.loupe.common.param);
