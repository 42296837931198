import { useCallback, useMemo } from 'react'

export class CircularDependencyError extends Error {
  constructor(dependencies, ...params) {
    super(...params)

    this.name = 'CircularDependencyError'
    this.dependencies = dependencies
  }
}

export const useDependencySearch = (configColumns) => {
  const hasDependency = useCallback((node, dependency, reverse = false) => {
    if (!node?.displayFormula) {
      return false
    }

    const { variableMappingMap = [] } = node.displayFormula
    const nodeDependencies = new Map()

    const collectionMap = new Map(configColumns.map((c) => [c.id, c]))

    variableMappingMap.forEach(([mapKey, mapValue]) => {
      nodeDependencies.set(mapValue, collectionMap.get(mapValue))
    })

    if (!nodeDependencies.size) {
      return false
    }

    if (nodeDependencies.has(dependency.id)) {
      if (dependency.id === node.id) {
        return false
      }
      // check for circle
      if (reverse) {
        throw new CircularDependencyError([node, dependency])
      }
      hasDependency(dependency, node, true)
      return true
    }

    const subDependencies = [...nodeDependencies].find(([key, val]) => {
      if (val.id !== node.id) {
        return hasDependency(val, dependency)
      }
    })

    return subDependencies || false
  }, [configColumns])

  const isDependency = useCallback((node) => {
    return configColumns.filter((col) => hasDependency(col, node))
  }, [configColumns, hasDependency])

  return useMemo(() => ({
    hasDependency,
    isDependency
  }), [hasDependency, isDependency])
}
