// source: src/common/mapping/mapping.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.loupe.common.mapping.CanonicalObject', null, global);
goog.exportSymbol('proto.loupe.common.mapping.CanonicalObjectField', null, global);
goog.exportSymbol('proto.loupe.common.mapping.CanonicalObjectFieldReference', null, global);
goog.exportSymbol('proto.loupe.common.mapping.CanonicalObjectMapping', null, global);
goog.exportSymbol('proto.loupe.common.mapping.CanonicalObjectSource', null, global);
goog.exportSymbol('proto.loupe.common.mapping.CrmObject', null, global);
goog.exportSymbol('proto.loupe.common.mapping.FieldMapping', null, global);
goog.exportSymbol('proto.loupe.common.mapping.FieldMappingPermission', null, global);
goog.exportSymbol('proto.loupe.common.mapping.FieldOption', null, global);
goog.exportSymbol('proto.loupe.common.mapping.FieldReference', null, global);
goog.exportSymbol('proto.loupe.common.mapping.MappingStatus', null, global);
goog.exportSymbol('proto.loupe.common.mapping.MappingStatusDetail', null, global);
goog.exportSymbol('proto.loupe.common.mapping.MappingStatusDetailLevel', null, global);
goog.exportSymbol('proto.loupe.common.mapping.SyncStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.mapping.CanonicalObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.mapping.CanonicalObject.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.mapping.CanonicalObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.mapping.CanonicalObject.displayName = 'proto.loupe.common.mapping.CanonicalObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.mapping.CanonicalObjectField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.mapping.CanonicalObjectField.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.mapping.CanonicalObjectField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.mapping.CanonicalObjectField.displayName = 'proto.loupe.common.mapping.CanonicalObjectField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.mapping.CanonicalObjectFieldReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.mapping.CanonicalObjectFieldReference.displayName = 'proto.loupe.common.mapping.CanonicalObjectFieldReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.mapping.MappingStatusDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.mapping.MappingStatusDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.mapping.MappingStatusDetail.displayName = 'proto.loupe.common.mapping.MappingStatusDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.mapping.CanonicalObjectMapping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.mapping.CanonicalObjectMapping.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.mapping.CanonicalObjectMapping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.mapping.CanonicalObjectMapping.displayName = 'proto.loupe.common.mapping.CanonicalObjectMapping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.mapping.CrmObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.mapping.CrmObject.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.mapping.CrmObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.mapping.CrmObject.displayName = 'proto.loupe.common.mapping.CrmObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.mapping.FieldMapping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.loupe.common.mapping.FieldMapping.repeatedFields_, null);
};
goog.inherits(proto.loupe.common.mapping.FieldMapping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.mapping.FieldMapping.displayName = 'proto.loupe.common.mapping.FieldMapping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.mapping.FieldMappingPermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.mapping.FieldMappingPermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.mapping.FieldMappingPermission.displayName = 'proto.loupe.common.mapping.FieldMappingPermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.mapping.FieldOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.mapping.FieldOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.mapping.FieldOption.displayName = 'proto.loupe.common.mapping.FieldOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.loupe.common.mapping.FieldReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.loupe.common.mapping.FieldReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.loupe.common.mapping.FieldReference.displayName = 'proto.loupe.common.mapping.FieldReference';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.mapping.CanonicalObject.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.mapping.CanonicalObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.mapping.CanonicalObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.CanonicalObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    source: jspb.Message.getFieldWithDefault(msg, 3, 0),
    label: jspb.Message.getFieldWithDefault(msg, 4, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    managed: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 11, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 13, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.loupe.common.mapping.CanonicalObjectField.toObject, includeInstance),
    writable: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.mapping.CanonicalObject}
 */
proto.loupe.common.mapping.CanonicalObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.mapping.CanonicalObject;
  return proto.loupe.common.mapping.CanonicalObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.mapping.CanonicalObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.mapping.CanonicalObject}
 */
proto.loupe.common.mapping.CanonicalObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.mapping.CanonicalObjectSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManaged(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 14:
      var value = new proto.loupe.common.mapping.CanonicalObjectField;
      reader.readMessage(value,proto.loupe.common.mapping.CanonicalObjectField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWritable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.mapping.CanonicalObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.mapping.CanonicalObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.CanonicalObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getManaged();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.loupe.common.mapping.CanonicalObjectField.serializeBinaryToWriter
    );
  }
  f = message.getWritable();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CanonicalObjectSource source = 3;
 * @return {!proto.loupe.common.mapping.CanonicalObjectSource}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getSource = function() {
  return /** @type {!proto.loupe.common.mapping.CanonicalObjectSource} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.mapping.CanonicalObjectSource} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string object_name = 5;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool required = 7;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool visible = 8;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool managed = 9;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getManaged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.setManaged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
*/
proto.loupe.common.mapping.CanonicalObject.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string created_by = 11;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
*/
proto.loupe.common.mapping.CanonicalObject.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string updated_by = 13;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated CanonicalObjectField fields = 14;
 * @return {!Array<!proto.loupe.common.mapping.CanonicalObjectField>}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.loupe.common.mapping.CanonicalObjectField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.mapping.CanonicalObjectField, 14));
};


/**
 * @param {!Array<!proto.loupe.common.mapping.CanonicalObjectField>} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
*/
proto.loupe.common.mapping.CanonicalObject.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.loupe.common.mapping.CanonicalObjectField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.mapping.CanonicalObjectField}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.loupe.common.mapping.CanonicalObjectField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional bool writable = 15;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObject.prototype.getWritable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObject} returns this
 */
proto.loupe.common.mapping.CanonicalObject.prototype.setWritable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.mapping.CanonicalObjectField.repeatedFields_ = [19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.mapping.CanonicalObjectField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.mapping.CanonicalObjectField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.CanonicalObjectField.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    canonicalObjectId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fieldName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fieldType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    format: jspb.Message.getFieldWithDefault(msg, 6, ""),
    label: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    managed: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    custom: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 14, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 16, ""),
    extensionField: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    originalLabel: jspb.Message.getFieldWithDefault(msg, 18, ""),
    referencesList: jspb.Message.toObjectList(msg.getReferencesList(),
    proto.loupe.common.mapping.CanonicalObjectFieldReference.toObject, includeInstance),
    syncStatus: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.mapping.CanonicalObjectField}
 */
proto.loupe.common.mapping.CanonicalObjectField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.mapping.CanonicalObjectField;
  return proto.loupe.common.mapping.CanonicalObjectField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.mapping.CanonicalObjectField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.mapping.CanonicalObjectField}
 */
proto.loupe.common.mapping.CanonicalObjectField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanonicalObjectId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManaged(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustom(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExtensionField(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalLabel(value);
      break;
    case 19:
      var value = new proto.loupe.common.mapping.CanonicalObjectFieldReference;
      reader.readMessage(value,proto.loupe.common.mapping.CanonicalObjectFieldReference.deserializeBinaryFromReader);
      msg.addReferences(value);
      break;
    case 20:
      var value = /** @type {!proto.loupe.common.mapping.SyncStatus} */ (reader.readEnum());
      msg.setSyncStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.mapping.CanonicalObjectField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.mapping.CanonicalObjectField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.CanonicalObjectField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCanonicalObjectId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFieldType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getManaged();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCustom();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getExtensionField();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getOriginalLabel();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.loupe.common.mapping.CanonicalObjectFieldReference.serializeBinaryToWriter
    );
  }
  f = message.getSyncStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string canonical_object_id = 3;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getCanonicalObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setCanonicalObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string field_name = 4;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string field_type = 5;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getFieldType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setFieldType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string format = 6;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string label = 7;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool required = 9;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool visible = 10;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool managed = 11;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getManaged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setManaged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool custom = 12;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
*/
proto.loupe.common.mapping.CanonicalObjectField.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string created_by = 14;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
*/
proto.loupe.common.mapping.CanonicalObjectField.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string updated_by = 16;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool extension_field = 17;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getExtensionField = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setExtensionField = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional string original_label = 18;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getOriginalLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setOriginalLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * repeated CanonicalObjectFieldReference references = 19;
 * @return {!Array<!proto.loupe.common.mapping.CanonicalObjectFieldReference>}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getReferencesList = function() {
  return /** @type{!Array<!proto.loupe.common.mapping.CanonicalObjectFieldReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.mapping.CanonicalObjectFieldReference, 19));
};


/**
 * @param {!Array<!proto.loupe.common.mapping.CanonicalObjectFieldReference>} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
*/
proto.loupe.common.mapping.CanonicalObjectField.prototype.setReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.loupe.common.mapping.CanonicalObjectFieldReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.mapping.CanonicalObjectFieldReference}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.addReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.loupe.common.mapping.CanonicalObjectFieldReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.clearReferencesList = function() {
  return this.setReferencesList([]);
};


/**
 * optional SyncStatus sync_status = 20;
 * @return {!proto.loupe.common.mapping.SyncStatus}
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.getSyncStatus = function() {
  return /** @type {!proto.loupe.common.mapping.SyncStatus} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.loupe.common.mapping.SyncStatus} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectField} returns this
 */
proto.loupe.common.mapping.CanonicalObjectField.prototype.setSyncStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.mapping.CanonicalObjectFieldReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.mapping.CanonicalObjectFieldReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayFieldId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    relationshipName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fieldName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    displayFieldName: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.mapping.CanonicalObjectFieldReference}
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.mapping.CanonicalObjectFieldReference;
  return proto.loupe.common.mapping.CanonicalObjectFieldReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.mapping.CanonicalObjectFieldReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.mapping.CanonicalObjectFieldReference}
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayFieldId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelationshipName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayFieldName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.mapping.CanonicalObjectFieldReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.mapping.CanonicalObjectFieldReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayFieldId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRelationshipName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDisplayFieldName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string object_id = 1;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.getObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectFieldReference} returns this
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.setObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field_id = 2;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectFieldReference} returns this
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_field_id = 3;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.getDisplayFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectFieldReference} returns this
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.setDisplayFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string relationship_name = 4;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.getRelationshipName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectFieldReference} returns this
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.setRelationshipName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string object_name = 5;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectFieldReference} returns this
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string field_name = 6;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectFieldReference} returns this
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string display_field_name = 7;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.getDisplayFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectFieldReference} returns this
 */
proto.loupe.common.mapping.CanonicalObjectFieldReference.prototype.setDisplayFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.mapping.MappingStatusDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.mapping.MappingStatusDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.mapping.MappingStatusDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.MappingStatusDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    crmObject: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    level: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.mapping.MappingStatusDetail}
 */
proto.loupe.common.mapping.MappingStatusDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.mapping.MappingStatusDetail;
  return proto.loupe.common.mapping.MappingStatusDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.mapping.MappingStatusDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.mapping.MappingStatusDetail}
 */
proto.loupe.common.mapping.MappingStatusDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmObject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.loupe.common.mapping.MappingStatusDetailLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.mapping.MappingStatusDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.mapping.MappingStatusDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.mapping.MappingStatusDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.MappingStatusDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCrmObject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string crm_object = 1;
 * @return {string}
 */
proto.loupe.common.mapping.MappingStatusDetail.prototype.getCrmObject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.MappingStatusDetail} returns this
 */
proto.loupe.common.mapping.MappingStatusDetail.prototype.setCrmObject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field = 2;
 * @return {string}
 */
proto.loupe.common.mapping.MappingStatusDetail.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.MappingStatusDetail} returns this
 */
proto.loupe.common.mapping.MappingStatusDetail.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.loupe.common.mapping.MappingStatusDetail.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.MappingStatusDetail} returns this
 */
proto.loupe.common.mapping.MappingStatusDetail.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MappingStatusDetailLevel level = 4;
 * @return {!proto.loupe.common.mapping.MappingStatusDetailLevel}
 */
proto.loupe.common.mapping.MappingStatusDetail.prototype.getLevel = function() {
  return /** @type {!proto.loupe.common.mapping.MappingStatusDetailLevel} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.loupe.common.mapping.MappingStatusDetailLevel} value
 * @return {!proto.loupe.common.mapping.MappingStatusDetail} returns this
 */
proto.loupe.common.mapping.MappingStatusDetail.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.mapping.CanonicalObjectMapping.repeatedFields_ = [11,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.mapping.CanonicalObjectMapping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.mapping.CanonicalObjectMapping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.CanonicalObjectMapping.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    source: jspb.Message.getFieldWithDefault(msg, 3, 0),
    label: jspb.Message.getFieldWithDefault(msg, 4, ""),
    objectName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    managed: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    statusDetailsList: jspb.Message.toObjectList(msg.getStatusDetailsList(),
    proto.loupe.common.mapping.MappingStatusDetail.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 13, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 15, ""),
    crmObjectsList: jspb.Message.toObjectList(msg.getCrmObjectsList(),
    proto.loupe.common.mapping.CrmObject.toObject, includeInstance),
    writable: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.mapping.CanonicalObjectMapping;
  return proto.loupe.common.mapping.CanonicalObjectMapping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.mapping.CanonicalObjectMapping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 3:
      var value = /** @type {!proto.loupe.common.mapping.CanonicalObjectSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManaged(value);
      break;
    case 10:
      var value = /** @type {!proto.loupe.common.mapping.MappingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = new proto.loupe.common.mapping.MappingStatusDetail;
      reader.readMessage(value,proto.loupe.common.mapping.MappingStatusDetail.deserializeBinaryFromReader);
      msg.addStatusDetails(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 16:
      var value = new proto.loupe.common.mapping.CrmObject;
      reader.readMessage(value,proto.loupe.common.mapping.CrmObject.deserializeBinaryFromReader);
      msg.addCrmObjects(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWritable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.mapping.CanonicalObjectMapping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.mapping.CanonicalObjectMapping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.CanonicalObjectMapping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getManaged();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getStatusDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.loupe.common.mapping.MappingStatusDetail.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCrmObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.loupe.common.mapping.CrmObject.serializeBinaryToWriter
    );
  }
  f = message.getWritable();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenant_id = 2;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CanonicalObjectSource source = 3;
 * @return {!proto.loupe.common.mapping.CanonicalObjectSource}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getSource = function() {
  return /** @type {!proto.loupe.common.mapping.CanonicalObjectSource} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.loupe.common.mapping.CanonicalObjectSource} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string object_name = 5;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool required = 7;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool visible = 8;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool managed = 9;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getManaged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setManaged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional MappingStatus status = 10;
 * @return {!proto.loupe.common.mapping.MappingStatus}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getStatus = function() {
  return /** @type {!proto.loupe.common.mapping.MappingStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.loupe.common.mapping.MappingStatus} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * repeated MappingStatusDetail status_details = 11;
 * @return {!Array<!proto.loupe.common.mapping.MappingStatusDetail>}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getStatusDetailsList = function() {
  return /** @type{!Array<!proto.loupe.common.mapping.MappingStatusDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.mapping.MappingStatusDetail, 11));
};


/**
 * @param {!Array<!proto.loupe.common.mapping.MappingStatusDetail>} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
*/
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setStatusDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.loupe.common.mapping.MappingStatusDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.mapping.MappingStatusDetail}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.addStatusDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.loupe.common.mapping.MappingStatusDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.clearStatusDetailsList = function() {
  return this.setStatusDetailsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
*/
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string created_by = 13;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
*/
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string updated_by = 15;
 * @return {string}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated CrmObject crm_objects = 16;
 * @return {!Array<!proto.loupe.common.mapping.CrmObject>}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getCrmObjectsList = function() {
  return /** @type{!Array<!proto.loupe.common.mapping.CrmObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.mapping.CrmObject, 16));
};


/**
 * @param {!Array<!proto.loupe.common.mapping.CrmObject>} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
*/
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setCrmObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.loupe.common.mapping.CrmObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.mapping.CrmObject}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.addCrmObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.loupe.common.mapping.CrmObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.clearCrmObjectsList = function() {
  return this.setCrmObjectsList([]);
};


/**
 * optional bool writable = 17;
 * @return {boolean}
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.getWritable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.CanonicalObjectMapping} returns this
 */
proto.loupe.common.mapping.CanonicalObjectMapping.prototype.setWritable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.mapping.CrmObject.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.mapping.CrmObject.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.mapping.CrmObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.mapping.CrmObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.CrmObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    source: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.loupe.common.mapping.FieldMapping.toObject, includeInstance),
    label: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.mapping.CrmObject}
 */
proto.loupe.common.mapping.CrmObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.mapping.CrmObject;
  return proto.loupe.common.mapping.CrmObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.mapping.CrmObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.mapping.CrmObject}
 */
proto.loupe.common.mapping.CrmObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 2:
      var value = /** @type {!proto.loupe.common.mapping.CanonicalObjectSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 3:
      var value = new proto.loupe.common.mapping.FieldMapping;
      reader.readMessage(value,proto.loupe.common.mapping.FieldMapping.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.mapping.CrmObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.mapping.CrmObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.mapping.CrmObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.CrmObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.loupe.common.mapping.FieldMapping.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string object_name = 1;
 * @return {string}
 */
proto.loupe.common.mapping.CrmObject.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CrmObject} returns this
 */
proto.loupe.common.mapping.CrmObject.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CanonicalObjectSource source = 2;
 * @return {!proto.loupe.common.mapping.CanonicalObjectSource}
 */
proto.loupe.common.mapping.CrmObject.prototype.getSource = function() {
  return /** @type {!proto.loupe.common.mapping.CanonicalObjectSource} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.loupe.common.mapping.CanonicalObjectSource} value
 * @return {!proto.loupe.common.mapping.CrmObject} returns this
 */
proto.loupe.common.mapping.CrmObject.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated FieldMapping fields = 3;
 * @return {!Array<!proto.loupe.common.mapping.FieldMapping>}
 */
proto.loupe.common.mapping.CrmObject.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.loupe.common.mapping.FieldMapping>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.mapping.FieldMapping, 3));
};


/**
 * @param {!Array<!proto.loupe.common.mapping.FieldMapping>} value
 * @return {!proto.loupe.common.mapping.CrmObject} returns this
*/
proto.loupe.common.mapping.CrmObject.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.loupe.common.mapping.FieldMapping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.mapping.FieldMapping}
 */
proto.loupe.common.mapping.CrmObject.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.loupe.common.mapping.FieldMapping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.mapping.CrmObject} returns this
 */
proto.loupe.common.mapping.CrmObject.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.loupe.common.mapping.CrmObject.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.CrmObject} returns this
 */
proto.loupe.common.mapping.CrmObject.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.loupe.common.mapping.FieldMapping.repeatedFields_ = [14,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.mapping.FieldMapping.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.mapping.FieldMapping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.mapping.FieldMapping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.FieldMapping.toObject = function(includeInstance, msg) {
  var f, obj = {
    toName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    toType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fromType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    format: jspb.Message.getFieldWithDefault(msg, 5, ""),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    managed: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    custom: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    label: jspb.Message.getFieldWithDefault(msg, 10, ""),
    description: jspb.Message.getFieldWithDefault(msg, 11, ""),
    writable: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    permissions: (f = msg.getPermissions()) && proto.loupe.common.mapping.FieldMappingPermission.toObject(includeInstance, f),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.loupe.common.mapping.FieldOption.toObject, includeInstance),
    extensionField: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    referencesList: jspb.Message.toObjectList(msg.getReferencesList(),
    proto.loupe.common.mapping.FieldReference.toObject, includeInstance),
    syncStatus: jspb.Message.getFieldWithDefault(msg, 17, 0),
    outreachName: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.mapping.FieldMapping}
 */
proto.loupe.common.mapping.FieldMapping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.mapping.FieldMapping;
  return proto.loupe.common.mapping.FieldMapping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.mapping.FieldMapping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.mapping.FieldMapping}
 */
proto.loupe.common.mapping.FieldMapping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManaged(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustom(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWritable(value);
      break;
    case 13:
      var value = new proto.loupe.common.mapping.FieldMappingPermission;
      reader.readMessage(value,proto.loupe.common.mapping.FieldMappingPermission.deserializeBinaryFromReader);
      msg.setPermissions(value);
      break;
    case 14:
      var value = new proto.loupe.common.mapping.FieldOption;
      reader.readMessage(value,proto.loupe.common.mapping.FieldOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExtensionField(value);
      break;
    case 16:
      var value = new proto.loupe.common.mapping.FieldReference;
      reader.readMessage(value,proto.loupe.common.mapping.FieldReference.deserializeBinaryFromReader);
      msg.addReferences(value);
      break;
    case 17:
      var value = /** @type {!proto.loupe.common.mapping.SyncStatus} */ (reader.readEnum());
      msg.setSyncStatus(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutreachName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.mapping.FieldMapping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.mapping.FieldMapping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.mapping.FieldMapping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.FieldMapping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFromType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getManaged();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCustom();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getWritable();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getPermissions();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.loupe.common.mapping.FieldMappingPermission.serializeBinaryToWriter
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.loupe.common.mapping.FieldOption.serializeBinaryToWriter
    );
  }
  f = message.getExtensionField();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.loupe.common.mapping.FieldReference.serializeBinaryToWriter
    );
  }
  f = message.getSyncStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getOutreachName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string to_name = 1;
 * @return {string}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getToName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setToName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string from_name = 2;
 * @return {string}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getFromName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setFromName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string to_type = 3;
 * @return {string}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getToType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setToType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string from_type = 4;
 * @return {string}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getFromType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setFromType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string format = 5;
 * @return {string}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool required = 6;
 * @return {boolean}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool visible = 7;
 * @return {boolean}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool managed = 8;
 * @return {boolean}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getManaged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setManaged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool custom = 9;
 * @return {boolean}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string label = 10;
 * @return {string}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string description = 11;
 * @return {string}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool writable = 12;
 * @return {boolean}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getWritable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setWritable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional FieldMappingPermission permissions = 13;
 * @return {?proto.loupe.common.mapping.FieldMappingPermission}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getPermissions = function() {
  return /** @type{?proto.loupe.common.mapping.FieldMappingPermission} */ (
    jspb.Message.getWrapperField(this, proto.loupe.common.mapping.FieldMappingPermission, 13));
};


/**
 * @param {?proto.loupe.common.mapping.FieldMappingPermission|undefined} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
*/
proto.loupe.common.mapping.FieldMapping.prototype.setPermissions = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.clearPermissions = function() {
  return this.setPermissions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.loupe.common.mapping.FieldMapping.prototype.hasPermissions = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated FieldOption options = 14;
 * @return {!Array<!proto.loupe.common.mapping.FieldOption>}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.loupe.common.mapping.FieldOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.mapping.FieldOption, 14));
};


/**
 * @param {!Array<!proto.loupe.common.mapping.FieldOption>} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
*/
proto.loupe.common.mapping.FieldMapping.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.loupe.common.mapping.FieldOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.mapping.FieldOption}
 */
proto.loupe.common.mapping.FieldMapping.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.loupe.common.mapping.FieldOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional bool extension_field = 15;
 * @return {boolean}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getExtensionField = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setExtensionField = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated FieldReference references = 16;
 * @return {!Array<!proto.loupe.common.mapping.FieldReference>}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getReferencesList = function() {
  return /** @type{!Array<!proto.loupe.common.mapping.FieldReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.loupe.common.mapping.FieldReference, 16));
};


/**
 * @param {!Array<!proto.loupe.common.mapping.FieldReference>} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
*/
proto.loupe.common.mapping.FieldMapping.prototype.setReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.loupe.common.mapping.FieldReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.loupe.common.mapping.FieldReference}
 */
proto.loupe.common.mapping.FieldMapping.prototype.addReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.loupe.common.mapping.FieldReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.clearReferencesList = function() {
  return this.setReferencesList([]);
};


/**
 * optional SyncStatus sync_status = 17;
 * @return {!proto.loupe.common.mapping.SyncStatus}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getSyncStatus = function() {
  return /** @type {!proto.loupe.common.mapping.SyncStatus} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.loupe.common.mapping.SyncStatus} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setSyncStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional string outreach_name = 18;
 * @return {string}
 */
proto.loupe.common.mapping.FieldMapping.prototype.getOutreachName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldMapping} returns this
 */
proto.loupe.common.mapping.FieldMapping.prototype.setOutreachName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.mapping.FieldMappingPermission.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.mapping.FieldMappingPermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.mapping.FieldMappingPermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.FieldMappingPermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    canView: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    canEdit: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    canManagerEdit: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.mapping.FieldMappingPermission}
 */
proto.loupe.common.mapping.FieldMappingPermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.mapping.FieldMappingPermission;
  return proto.loupe.common.mapping.FieldMappingPermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.mapping.FieldMappingPermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.mapping.FieldMappingPermission}
 */
proto.loupe.common.mapping.FieldMappingPermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanView(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanEdit(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanManagerEdit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.mapping.FieldMappingPermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.mapping.FieldMappingPermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.mapping.FieldMappingPermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.FieldMappingPermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanView();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCanEdit();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCanManagerEdit();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool can_view = 1;
 * @return {boolean}
 */
proto.loupe.common.mapping.FieldMappingPermission.prototype.getCanView = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.FieldMappingPermission} returns this
 */
proto.loupe.common.mapping.FieldMappingPermission.prototype.setCanView = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool can_edit = 2;
 * @return {boolean}
 */
proto.loupe.common.mapping.FieldMappingPermission.prototype.getCanEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.FieldMappingPermission} returns this
 */
proto.loupe.common.mapping.FieldMappingPermission.prototype.setCanEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool can_manager_edit = 3;
 * @return {boolean}
 */
proto.loupe.common.mapping.FieldMappingPermission.prototype.getCanManagerEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.loupe.common.mapping.FieldMappingPermission} returns this
 */
proto.loupe.common.mapping.FieldMappingPermission.prototype.setCanManagerEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.mapping.FieldOption.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.mapping.FieldOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.mapping.FieldOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.FieldOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.mapping.FieldOption}
 */
proto.loupe.common.mapping.FieldOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.mapping.FieldOption;
  return proto.loupe.common.mapping.FieldOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.mapping.FieldOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.mapping.FieldOption}
 */
proto.loupe.common.mapping.FieldOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.mapping.FieldOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.mapping.FieldOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.mapping.FieldOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.FieldOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.loupe.common.mapping.FieldOption.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldOption} returns this
 */
proto.loupe.common.mapping.FieldOption.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.loupe.common.mapping.FieldOption.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldOption} returns this
 */
proto.loupe.common.mapping.FieldOption.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.loupe.common.mapping.FieldReference.prototype.toObject = function(opt_includeInstance) {
  return proto.loupe.common.mapping.FieldReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.loupe.common.mapping.FieldReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.FieldReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayFieldName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    relationshipName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.loupe.common.mapping.FieldReference}
 */
proto.loupe.common.mapping.FieldReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.loupe.common.mapping.FieldReference;
  return proto.loupe.common.mapping.FieldReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.loupe.common.mapping.FieldReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.loupe.common.mapping.FieldReference}
 */
proto.loupe.common.mapping.FieldReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayFieldName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelationshipName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.loupe.common.mapping.FieldReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.loupe.common.mapping.FieldReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.loupe.common.mapping.FieldReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.loupe.common.mapping.FieldReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayFieldName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRelationshipName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string object_name = 1;
 * @return {string}
 */
proto.loupe.common.mapping.FieldReference.prototype.getObjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldReference} returns this
 */
proto.loupe.common.mapping.FieldReference.prototype.setObjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field_name = 2;
 * @return {string}
 */
proto.loupe.common.mapping.FieldReference.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldReference} returns this
 */
proto.loupe.common.mapping.FieldReference.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_field_name = 3;
 * @return {string}
 */
proto.loupe.common.mapping.FieldReference.prototype.getDisplayFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldReference} returns this
 */
proto.loupe.common.mapping.FieldReference.prototype.setDisplayFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string relationship_name = 4;
 * @return {string}
 */
proto.loupe.common.mapping.FieldReference.prototype.getRelationshipName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.loupe.common.mapping.FieldReference} returns this
 */
proto.loupe.common.mapping.FieldReference.prototype.setRelationshipName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.loupe.common.mapping.MappingStatus = {
  STATUS_UNSPECIFIED: 0,
  STATUS_INACTIVE: 1,
  STATUS_ACTIVE: 2,
  STATUS_ERROR: 3
};

/**
 * @enum {number}
 */
proto.loupe.common.mapping.MappingStatusDetailLevel = {
  LEVEL_UNSPECIFIED: 0,
  LEVEL_INFO: 1,
  LEVEL_WARN: 2,
  LEVEL_ERROR: 3,
  LEVEL_OK: 4
};

/**
 * @enum {number}
 */
proto.loupe.common.mapping.CanonicalObjectSource = {
  SOURCE_UNSPECIFIED: 0,
  SOURCE_SALESFORCE: 1,
  SOURCE_HUBSPOT: 2
};

/**
 * @enum {number}
 */
proto.loupe.common.mapping.SyncStatus = {
  SYNC_STATUS_DONE: 0,
  SYNC_STATUS_NEARLINE_DATA: 1,
  SYNC_STATUS_NEARLINE_SCHEMA: 2,
  SYNC_STATUS_DATALAKE: 3
};

goog.object.extend(exports, proto.loupe.common.mapping);
