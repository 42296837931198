import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import { useRoutes } from '../../context/routes'
import { getTokenSilently, getIdTokenClaims, setAccessToken, setClaims } from '../../lib/auth0'
import { every } from 'lodash'
import { usePermissions } from '../../context/permissions'
import { useTenantInfo } from '../../context/tenantInfo'
import { captureException, setUser } from '../../lib/sentry'
import queryString from 'query-string'

const ProtectedRoute = ({ template: Template, component: Component, checkPermissions = [], ...rest }) => {
  const { isAuthenticated, setIsAuthenticated, tenantContext } = useAuth()

  const { tenantIsPending, tenantIsInitializing } = useTenantInfo()

  const { routes, routeTenantId } = useRoutes()

  const { isFetching: isFetchingPermissions, permissions, checkPermission } = usePermissions()

  const location = useLocation()

  const [isCheckingSession, setIsCheckingSession] = useState(true)

  const canAccess = useMemo(() => {
    return every(checkPermissions, (p) => checkPermission(p))
  }, [checkPermissions, permissions, checkPermission])

  const isFetching = useMemo(() => {
    return isFetchingPermissions || isCheckingSession
  }, [isFetchingPermissions, isCheckingSession])

  const redirectTo = useMemo(() => {
    let redirectUrl = routes.login
    const searchParams = {}

    if (location.pathname !== '/') {
      if (routeTenantId) {
        const [, basePath = '/'] = location.pathname.split(`/${routeTenantId}`)
        searchParams.redirect = basePath
      } else {
        searchParams.redirect = location.pathname
      }
    }

    if (routeTenantId) {
      searchParams.sandbox = routeTenantId
    }

    if (Object.keys(searchParams).length) {
      redirectUrl += `?${queryString.stringify(searchParams)}`
    }

    redirectUrl += encodeURIComponent(location.hash)

    return redirectUrl
  }, [location, routeTenantId])

  const forceSetup = useMemo(() => {
    // See: [CSC-846] https://outreach-io.atlassian.net/browse/CSC-846?focusedCommentId=584610
    // Skip setup if impersonating
    if (tenantContext) {
      return false
    }
    // Otherwise, check tenant status and current route
    return tenantIsPending && rest?.path !== routes.setup
  }, [tenantIsPending, rest, routes, tenantContext])

  const forceSync = useMemo(() => {
    // See: [CSC-846] https://outreach-io.atlassian.net/browse/CSC-846?focusedCommentId=584610
    // Skip setup if impersonating
    if (tenantContext) {
      return false
    }
    // Otherwise, check tenant status and current route
    return tenantIsInitializing && rest?.path !== routes.sync
  }, [tenantIsInitializing, rest, routes, tenantContext])

  useEffect(() => {
    getTokenSilently({
      onSuccess: ({ accessToken }) => {
        getIdTokenClaims({
          onSuccess: ({ idTokenClaims }) => {
            setAccessToken(accessToken)

            setClaims(idTokenClaims)

            setUser(idTokenClaims)
            setIsAuthenticated(true)
            setIsCheckingSession(false)
          },
          onError: (err) => {
            setIsAuthenticated(false)
            setIsCheckingSession(false)
            captureException(err, undefined, 'getIdTokenClaims')
          },
        })
      },
      onError: (err) => {
        setIsAuthenticated(false)
        setIsCheckingSession(false)
      },
    })
  }, [])

  const render = useCallback((props) => {
    if (isFetching) {
      return <></>
    }
    if (isAuthenticated) {
      if (forceSetup) {
        return <Redirect to={routes.setup} />
      } else if (forceSync) {
        return <Redirect to={routes.sync} />
      } else if (canAccess) {
        return <Template component={Component} {...props} />
      } else {
        return <Redirect to={routes.unauthorized} />
      }
    } else {
      return <Redirect to={redirectTo} />
    }
  }, [isAuthenticated, isFetching, canAccess, redirectTo, forceSetup, forceSync])

  return <Route {...rest} render={render} />
}

export default ProtectedRoute
