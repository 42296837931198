import React from 'react'
import { SystemRolesProvider } from '../../context/systemRoles'
import RoleMain from './roleMain'

const Roles = (props) => {
  return (
    <SystemRolesProvider>
      <RoleMain />
    </SystemRolesProvider>
  )
}

export default Roles
